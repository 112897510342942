import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { Header } from '@/components/molecules/settings/mobile-request/header'
import { MenuType, SideBar } from '@/components/molecules/settings/mobile-request/side-bar'
import { ProductTable } from './product-table'
import { CategoryTable } from './category-table'
import { TagTable } from './tag-table'
import { SalesHoursTable } from './sales-hours-table'
import { NotificationTable } from './notification-table'
import dayjs, { Dayjs } from 'dayjs'

type Props = {
  onChangeData: () => void
  onChangeMenu: () => void
  listenCancel: Dayjs | undefined
  listenSave: Dayjs | undefined
}

export const RequestPanel: React.FC<Props> = ({ onChangeData, onChangeMenu, listenCancel, listenSave }) => {
  const [activeMenu, setActiveMenu] = useState<MenuType>(MenuType.Product)
  const [isShowNewButton, setIsShowNewButton] = useState(false)
  const [doShowNewModal, setDoShowNewModal] = useState<Dayjs>()

  const openNewModal = () => {
    setDoShowNewModal(dayjs())
  }

  useEffect(() => {
    setIsShowNewButton(activeMenu !== MenuType.Notification)
  }, [activeMenu])

  return (
    <>
      <div css={contentStyle}>
        <Header onNewAction={openNewModal} isShowNewButton={isShowNewButton} />
        <div css={subContentStyle}>
          <SideBar
            onChangeMenu={menu => {
              setActiveMenu(menu)
              onChangeMenu()
              setDoShowNewModal(undefined)
            }}
          />
          {activeMenu === MenuType.Product && (
            <ProductTable onChange={onChangeData} listenOpenModal={doShowNewModal} listenCancel={listenCancel} listenSave={listenSave} />
          )}
          {activeMenu === MenuType.Category && (
            <CategoryTable onChange={onChangeData} listenOpenModal={doShowNewModal} listenCancel={listenCancel} listenSave={listenSave} />
          )}
          {activeMenu === MenuType.Tag && (
            <TagTable onChange={onChangeData} listenOpenModal={doShowNewModal} listenCancel={listenCancel} listenSave={listenSave} />
          )}
          {activeMenu === MenuType.SalesHours && (
            <SalesHoursTable onChange={onChangeData} listenOpenModal={doShowNewModal} listenCancel={listenCancel} listenSave={listenSave} />
          )}
          {activeMenu === MenuType.Notification && (
            <NotificationTable onChange={onChangeData} listenCancel={listenCancel} listenSave={listenSave} />
          )}
        </div>
      </div>
    </>
  )
}

const contentStyle = css({
  height: '100%',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 0px 6px #0000001A',
})

const subContentStyle = css({
  display: 'flex',
  height: 'calc(100% - 50px)',
  overflow: 'auto',
})
