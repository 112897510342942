import { errorHandler } from '@/libs/errors'
import { AxiosError } from 'axios'

export const useErrorHandler = () => {
  const isOutOfStockError = (error: Error): boolean => {
    if (!(error as AxiosError)?.isAxiosError) {
      return false
    }
    const errorDetail = (error as AxiosError).response?.data?.errors
    if (!errorDetail) {
      return false
    }
    const expectErrorMessage = 'Room is out of stock.'
    const isOutOfStockOfRoom = errorDetail.rooms?.length && errorDetail.rooms[0] === expectErrorMessage
    const isOutOfStockOfReservation = errorDetail.reservation?.length && errorDetail.reservation[0] === expectErrorMessage
    return isOutOfStockOfRoom || isOutOfStockOfReservation
  }

  return { errorHandler, isOutOfStockError }
}
