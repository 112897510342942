import React, { useContext, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/core'
import { TableHeader, TableBody, TableRow } from '@/components/atoms/settings/table'
import { useTranslation } from 'react-i18next'
import { getSmartLockDevices } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { RemoteLockClient, RemoteLockContextCreator } from '@/contexts/remote-lock'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import { LoaderContextCreator } from '@/contexts/loader'
import { Control, Controller, useFieldArray } from 'react-hook-form'
import { ListType } from '@/components/pages/setting/smart-key/space'
import { Device, LockType } from '@/models/lock-setting'

type Props = {
  control: Control<{ assign: ListType[] }, any>
  onChange: () => void
}

export const LinkSpaceToRemoteLock: React.FC<Props> = ({ control, onChange: parentOnChange }) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { isLoading, setIsLoading } = useContext(LoaderContextCreator())
  const remoteLockClient = useContext<RemoteLockClient>(RemoteLockContextCreator())

  const [remoteLockDeviceList, setRemoteLockDeviceList] = useState<Device[]>()

  const assignForm = useFieldArray({
    control,
    name: 'assign',
  })

  const fetchDevice = async () => {
    if (!remoteLockClient.isConnected) {
      return
    }
    try {
      setIsLoading(true)
      await getSmartLockDevices({ lockType: LockType.RemoteLock }).then(setRemoteLockDeviceList)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const deviceIdAndSerial = useMemo(() => {
    const result: { [key: string]: string } = {}
    remoteLockDeviceList?.forEach(device => {
      result[device.id] = device.serialNumber || ''
    })
    return result
  }, [remoteLockDeviceList])

  useEffect(() => {
    if (isLoading) {
      return
    }
    fetchDevice()
  }, [remoteLockClient.isConnected])

  return (
    <>
      <TableHeader>
        <div css={assetNameColStyle}>{t('Asset')}</div>
        <div css={spaceNameColStyle}>{t('Space name')}</div>
        <div css={keyNumberColStyle}>{t('Device name')}</div>
        <div css={keyNumberColStyle}>{t('Serial number')}</div>
      </TableHeader>
      <TableBody>
        {!remoteLockClient.isConnected && <div css={disableTableStyle}></div>}
        {assignForm.fields.map((space, i) => (
          <Controller
            key={`${space.spaceId}-${space.spaceIndex}`}
            name={`assign.${i}.remoteDeviceId`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TableRow css={css({ '> div': { fontWeight: 500 } })}>
                <div css={assetNameColStyle}>
                  {assignForm.fields[i].assetName === assignForm.fields[i - 1]?.assetName ? '' : assignForm.fields[i].assetName}
                </div>
                <div css={spaceNameColStyle}>{`${space.spaceName} - ${space.spaceIndex}`}</div>
                <div css={keyNumberColStyle}>
                  <Select
                    value={remoteLockDeviceList?.length ? value : ''}
                    onChange={selectVal => {
                      onChange(selectVal)
                      parentOnChange()
                    }}
                    customStyle={css({ paddingBottom: 0 })}
                  >
                    <AntSelect.Option value="">-</AntSelect.Option>
                    {remoteLockDeviceList?.map(device => (
                      <AntSelect.Option key={device.id} value={device.id}>
                        {device.name}
                      </AntSelect.Option>
                    ))}
                  </Select>
                </div>
                <div css={keyNumberColStyle}>{(value && deviceIdAndSerial[value]) || '-'}</div>
              </TableRow>
            )}
          />
        ))}
      </TableBody>
    </>
  )
}

const assetNameColStyle = css({
  padding: '0 32px',
  width: 200,
  overflowWrap: 'anywhere',
})
const spaceNameColStyle = css({
  flexGrow: 1,
  overflowWrap: 'anywhere',
  width: 'min-content',
  paddingRight: 32,
})
const keyNumberColStyle = css({
  width: 300,
  paddingRight: 32,
})

const disableTableStyle = css({
  height: '-webkit-fill-available',
  width: '100%',
  position: 'absolute',
  backgroundColor: 'rgba(0,0,0,0.2)',
  zIndex: 10,
})
