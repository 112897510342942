import { env } from '@/libs/env'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { fetchPaymentSettingByHotelId } from '@/apis/aipass'

export const useCardToken = () => {
  const { t } = useTranslation()
  const makeCardToken = async (
    hotelId: string,
    card: {
      cardNumber: string
      cardExpire: string
      securityCode: string
      cardHolder: string
    },
  ): Promise<string> => {
    const settingResponse = await fetchPaymentSettingByHotelId(hotelId)
    const token_api_key = settingResponse?.settings?.card_api_token || env('AIPASS_VERITRANS_API_KEY')
    console.log({ settingResponse, token_api_key })

    const url = 'https://api.veritrans.co.jp/4gtoken'
    const requestParams = {
      token_api_key,
      card_number: card.cardNumber,
      card_expire: card.cardExpire,
      security_code: card.securityCode,
      cardholder_name: card.cardHolder,
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    }
    try {
      const response = await axios.post(url, requestParams, { headers, withCredentials: true })
      return response.data.token
    } catch (e) {
      console.warn(e)
      throw Error(t('Your payment was declined by your card issuer Please connect your card issuer'))
    }
  }
  return { makeCardToken }
}
