import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/button'

type EditRoomButtonProps = {
  updateGuestRoom: () => Promise<void>
  onClickCancel: () => void
}

export const EditRoomButton: React.FC<EditRoomButtonProps> = ({ updateGuestRoom, onClickCancel }) => {
  const { t } = useTranslation()
  return (
    <div css={saveButtonHasRoomManagerPluginStyle}>
      <Button
        buttonType={3}
        width={100}
        height={32}
        fontSize={12}
        marginRight={16}
        fontWeight={'bold'}
        onClick={() => onClickCancel()}>
        {t('Cancel')}
      </Button>
      <Button width={58} height={32} buttonType={1} fontSize={12} onClick={() => updateGuestRoom()}>
        {t('Save')}
      </Button>
    </div>
  )
}

const saveButtonHasRoomManagerPluginStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px 24px',
  borderTop: '1px solid #f2f2f2',
})
