import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useBrowserContextCommunication from 'react-window-communication-hook'

import MobileHeader from '@/components/organisms/mobile-header'
import MenuItem from './menu-item'

// libis
import { signout } from '@/apis/auth0'

const MobileMenu = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [communicationState, postMessage] = useBrowserContextCommunication('')

  const _logout = async () => {
    // Delete localStorage Auth and context auth
    // localStorage.removeItem('idToken')
    postMessage('logout')
    await signout()
  }

  useEffect(() => {
    // Log out the management screen of all tabs when logging out (to avoid duplicate logins)
    if (communicationState.lastMessage === 'logout') {
      signout()
    }
  }, [postMessage])

  return (
    <div css={mobileContainerStyle}>
      <MobileHeader isHome={true} />
      <MenuItem
        onClick={() => history.push({ pathname: '/qr-checkin/camera' })}
        title={t('Checkin')}
        imgSrc={require('@/static/images/mobile-menu/icon_checkin.svg')}
        imgAlt="checkin"
      />

      <div css={mobileLogoutLinkStyle} onClick={() => _logout()}>
        {t('Logout')}
      </div>
    </div>
  )
}

export default MobileMenu

const mobileContainerStyle = css({ height: '100vh', backgroundColor: '#F2F2F2' })
const mobileLogoutLinkStyle = css({
  display: 'flex',
  fontFamily: 'Noto Sans JP',
  justifyContent: 'center',
  fontSize: '12px',
  color: '#F2A40B',
  marginTop: '30px',
  letterSpacing: '2.4px',
})
