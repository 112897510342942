import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form'
import { AssetSpaceDetailFormValue } from '@/components/organisms/settings/guest-app/space-detail-modal'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import { SalesDepartmentMasterType, SalesSubjectType } from '@/models/sales-manager/sales'
import { fetchSalesDepartment, fetchSalesSubject } from '@/apis/aipass'
import { SpaceDetailType } from '@/models/guest-app/asset'
import { Cascader } from '@/components/atoms/cascader'
import { groupBy } from 'lodash'

type Props = {
  control: Control<AssetSpaceDetailFormValue, any>
  detail: SpaceDetailType | null
  setValue: UseFormSetValue<AssetSpaceDetailFormValue>
}

export const InputSalesSubject: React.FC<Props> = ({ control, setValue, detail }) => {
  const { t } = useTranslation()
  const watchSalesDepartmentId = useWatch({ control, name: 'salesDepartmentId' })
  const [salesDepartmentMasters, setSalesDepartmentMasters] = useState<SalesDepartmentMasterType[]>([])
  const [salesSubjectMasters, setSalesSubjectMasters] = useState<SalesSubjectType[]>([])

  const initializeComponent = async (opt?: { noIndicator: boolean }) => {
    await Promise.all([
      fetchSalesDepartment().then(res => setSalesDepartmentMasters((res || []).filter(r => r.relatedSubjectMasterCount))),
      fetchSalesSubject().then(res => setSalesSubjectMasters(res?.salesSubjects || [])),
    ])
  }

  const findDefaultSubjectMaster = (anySubjectMasterId: string) => {
    const anySubjectMaster = salesSubjectMasters.find(master => master.id === anySubjectMasterId)
    if (!anySubjectMaster) {
      return undefined
    }
    const subjectMasterGroups = salesSubjectMasters.filter(
      master => master.departmentCode === anySubjectMaster.departmentCode && master.subjectCode === anySubjectMaster?.subjectCode,
    )
    const defaultSubject = subjectMasterGroups.find(master => !(master.subSubjectCode && master.subSubjectCode !== '-'))
    return defaultSubject || subjectMasterGroups[0]
  }

  const valueToCascader = (value: string | undefined) => {
    if (!value) {
      return undefined
    }
    const master = findDefaultSubjectMaster(value)
    if (!master) {
      return undefined
    }
    return [master.id, value]
  }

  useEffect(() => {
    initializeComponent()
  }, [])

  const cascadeSubjectMasterOptions = useMemo(() => {
    const subSubjectGroups = groupBy(
      salesSubjectMasters.filter(subject => subject.departmentId === watchSalesDepartmentId),
      subjectMaster => findDefaultSubjectMaster(subjectMaster.id)?.id,
    )

    const options = Object.keys(subSubjectGroups).map(groupKey => {
      const defaultSubjectMaster = findDefaultSubjectMaster(groupKey) as SalesSubjectType
      const children = subSubjectGroups[groupKey]
        .filter(subjectMaster => subjectMaster.subSubjectCode && subjectMaster.subSubjectCode !== '-')
        .map(subjectMaster => ({
          value: subjectMaster.id,
          label: `${subjectMaster.subSubjectCode}/${subjectMaster.subSubjectName}`,
        }))
      return {
        value: defaultSubjectMaster.id,
        label: `${defaultSubjectMaster.subjectCode}/${defaultSubjectMaster.subjectName}`,
        children: children.length > 0 ? children : undefined,
      }
    })

    const isNotInMaster =
      detail?.salesSubject?.salesSubjectMasterId &&
      !salesSubjectMasters.find(master => master.id === detail?.salesSubject?.salesSubjectMasterId)
    if (isNotInMaster) {
      options.push({
        value: detail!.salesSubject!.salesSubjectMasterId,
        label: `${detail!.salesSubject!.salesSubjectMasterCode}/${detail!.salesSubject!.salesSubjectMasterName}`,
        children: undefined,
      })
    }

    return options
  }, [watchSalesDepartmentId, salesSubjectMasters])

  return (
    <div css={inputAreaStyle}>
      <div style={{ paddingRight: 8, width: '50%' }}>
        <div css={inputTitleTextStyle}>
          {t('Department ID')}/{t('Department name')}
        </div>
        <Controller
          name="salesDepartmentId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              onChange={e => {
                setValue('salesSubjectMasterId', '')
                onChange(e)
              }}
              customStyle={css({ paddingBottom: 24 })}
            >
              <AntSelect.Option value="">{t('Dont choose')}</AntSelect.Option>
              {salesDepartmentMasters.map(salesDepartment => (
                <AntSelect.Option key={salesDepartment.id} value={salesDepartment.id}>
                  {salesDepartment.departmentCode}/{salesDepartment.departmentName}
                </AntSelect.Option>
              ))}
              {detail?.salesSubject?.salesDepartmentId &&
                !salesDepartmentMasters.find(master => master.id === detail?.salesSubject?.salesDepartmentId) && (
                  <AntSelect.Option value={detail?.salesSubject?.salesDepartmentId}>
                    {detail?.salesSubject?.salesDepartmentCode}/{detail?.salesSubject?.salesDepartmentName}
                  </AntSelect.Option>
                )}
            </Select>
          )}
        />
      </div>
      {!!watchSalesDepartmentId && (
        <div style={{ paddingLeft: 8, width: '50%' }}>
          <div css={inputTitleTextStyle}>
            {t('Course ID')}/{t('Subject name')}
            <div css={requireLabelTextStyle}>※</div>
          </div>
          <Controller
            name="salesSubjectMasterId"
            control={control}
            rules={{ required: t('Please select subjects') }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Cascader
                placeholder={t('Please select')}
                options={cascadeSubjectMasterOptions}
                onChange={value => {
                  onChange(value[1] || value[0])
                }}
                error={error?.message}
                customStyle={css({ paddingBottom: 24 })}
                value={valueToCascader(value)}
                displayRender={label => (label.length > 1 ? `${label[0]} (${label[1]})` : label)}
                popupPlacement="bottomRight"
              />
            )}
          />
        </div>
      )}
    </div>
  )
}

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputAreaStyle = css({
  display: 'flex',
})
