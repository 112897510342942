import React, { useEffect, useMemo, useState } from 'react'
import { deleteCustomerTagMaster, fetchCustomerTagMasters, updateCustomerTagMasterDisplayOrder } from '@/apis/aipass'
import { Button } from '@/components/atoms/button'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { EditFooter } from '@/components/organisms/edit-footer'
import {
  HEADER_HEIGHT,
  dashboardPageContainerStyle,
  dashboardPageHeaderStyle,
  tableBodyTdStyle,
  tableHeaderThStyle,
} from '@/constants/layout'
import { CustomerTagMasterType } from '@/models/customer-manager/customer-tag'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { TagModal } from './tag-modal'
import { draggableIconStyle, useDraggableDom } from '@/components/molecules/settings/use-draggable-dom'

export const CustomerManager: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [customerTagMasterList, setCustomerTagMasterList] = useState<CustomerTagMasterType[]>([])
  const [deleteTagIds, setDeleteTagIds] = useState<string[]>([])
  const [editTagInfo, setEditTagInfo] = useState<CustomerTagMasterType>()
  const [isShowFooter, setIsShowFooter] = useState(false)

  const windows = useWindowSize()
  const { t } = useTranslation()

  const changeDisplayOrder = (newList: CustomerTagMasterType[]) => {
    setCustomerTagMasterList(newList)
    setIsShowFooter(true)
  }
  const { dragStart, dragOver, dragDrop } = useDraggableDom({ list: customerTagMasterList, onChange: changeDisplayOrder })

  const deleteTag = async (e: React.MouseEvent<HTMLElement>, tag: CustomerTagMasterType) => {
    e.stopPropagation()

    setDeleteTagIds([...deleteTagIds, tag.id])
    setIsShowFooter(true)
  }

  const setCustomerTagMasters = async () => {
    await fetchCustomerTagMasters().then(res => res?.length && setCustomerTagMasterList(res))
  }

  const initialView = async () => {
    setLoading(true)
    await setCustomerTagMasters()
    setLoading(false)
  }

  const onCancel = () => {
    setShowModal(false)
    setEditTagInfo(undefined)
  }

  const onClose = async () => {
    await initialView()
    onCancel()
  }

  const onCloseFooter = async () => {
    setIsShowFooter(false)
    setDeleteTagIds([])
    await setCustomerTagMasters()
  }

  const onSave = async () => {
    setLoading(true)
    if (deleteTagIds.length) {
      if (window.confirm(t('The currently selected tag in the customer information will be deleted. \nDo you want to delete it?'))) {
        await deleteCustomerTagMaster(deleteTagIds)
      }
    } else {
      await updateCustomerTagMasterDisplayOrder(customerTagMasterList.map(customerTagMaster => customerTagMaster.id))
    }

    await setCustomerTagMasters()
    onCloseFooter()
    setLoading(false)
  }

  useEffect(() => {
    initialView()
  }, [])

  const containerHeight = useMemo(() => {
    const height = windows.height - HEADER_HEIGHT - 24 * 2
    return isShowFooter ? height - 59 : height
  }, [windows.height, isShowFooter])

  const footerContent =
    isShowFooter ? <EditFooter onSave={() => onSave()} onCancel={() => onCloseFooter()} /> : undefined
  return (
    <>
      <SettingsLayout loading={loading} footerContent={footerContent}>
        <div css={{ background: '#fff', boxShadow: '0px 0px 6px #0000001a', borderRadius: 5, height: containerHeight }}>
          <div css={[dashboardPageHeaderStyle, { height: 50 }]}>
            <div css={headerDetailStyle}>{t('CustomerTag')}</div>
            <div>
              <Button buttonType={1} width={84} height={34} fontSize={12} marginRight={16} onClick={() => setShowModal(true)}>
                {t('Add new')}
              </Button>
            </div>
          </div>
            {!!customerTagMasterList?.length && (
              <div css={{ width: '100%', borderCollapse: 'collapse', height: 'calc(100% - 50px)' }}>
                <div
                  className="header"
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    background: '#F5F5F5',
                    color: '#676767',
                    fontSize: 12,
                    fontWeight: 'bold',
                    padding: '8px',
                    whiteSpace: 'nowrap',
                    height: 32,
                    position: 'sticky',
                  }}
                >
                  <div css={titleStyle}>{t('Tag name')}</div>
                  <div>{t('Color')}</div>
                </div>
                <div className="body" css={{ overflow: 'auto', height: 'calc(100% - 32px)' }}>
                  {customerTagMasterList.map(tagMaster => {
                    if (deleteTagIds.includes(tagMaster.id)) return null
                    return (
                      <div
                        draggable
                        id={tagMaster.id}
                        onDragStart={dragStart}
                        onDragOver={dragOver}
                        onDrop={dragDrop}
                        onClick={() =>
                          setEditTagInfo({
                            id: tagMaster.id,
                            tag: {
                              customerTagMasterId: tagMaster.id,
                              bgColor: tagMaster.tag.bgColor,
                              fontColor: tagMaster.tag.fontColor,
                              borderColor: tagMaster.tag.borderColor,
                              colorName: tagMaster.tag.colorName,
                              name: tagMaster.tag.name,
                            },
                          })
                        }
                        css={[
                          draggableIconStyle,
                          { display: 'flex', alignItems: 'center', borderBottom: '1px solid #eee', padding: 8, fontSize: 14, height: 62 },
                        ]}
                      >
                        <div css={[titleStyle, { fontWeight: 'bold' }]}>{tagMaster.tag.name}</div>
                        <div>
                          <div css={[colorStyle, { background: tagMaster.tag.bgColor, borderColor: tagMaster.tag.borderColor }]} />
                        </div>
                        <div css={[{ textAlign: 'right', paddingRight: 32, marginLeft: 'auto' }]}>
                          <img
                            onClick={e => deleteTag(e, tagMaster)}
                            src={require('@/static/images/delete_orange.svg')}
                            css={{ width: 26, height: 26, cursor: 'pointer' }}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            {!customerTagMasterList?.length && !loading && (
              <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <h1>{t('No data available')}</h1>
              </div>
            )}
        </div>
      </SettingsLayout>
      {(showModal || editTagInfo) && <TagModal editTagInfo={editTagInfo} onClose={onClose} onCancel={onCancel} />}
    </>
  )
}

const headerDetailStyle = css({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: '16px 0',
  color: '#272727',
})

const colorStyle = css({
  width: 24,
  height: 24,
  border: '2px solid',
  borderRadius: 5,
})

const customerTdStyle = css(tableBodyTdStyle, {
  height: 62,
})

const titleStyle = css({
  width: 338,
  paddingLeft: 60,
})
