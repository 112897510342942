import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// apis
import { fetchReservationChannelCode, fetchSalesAccountsReceivable, updateSalesAccountsReceivable } from '@/apis/aipass'


// models
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'
import { ChannelCodeType } from '@/models/reservation-channel-code'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { EditFooter } from '@/components/organisms/edit-footer'
import { SettingAccountsReceivableEditForm } from '@/components/organisms/settings/sales-manager/accounts-receivable/edit-form'

type ContainerProps = {}

export const SettingSalesManagerAccountsReceivableEdit: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const initialAccountsReceivableSettings: AccountsReceivableSettingType[] = [
    {
      closingDate: 0,
      channelCode: '',
      channelName: '',
      code: '',
      id: '',
      name: '',
    },
  ]
  const [accountsReceivableSettings, setAccountsReceivableSettings] =
    useState<AccountsReceivableSettingType[]>(initialAccountsReceivableSettings)
  const [accountsReceivableList, setAccountsReceivableList] = useState<any>()
  const [channelLists, setChannelLists] = useState<ChannelCodeType[]>([])
  const [deteledAccountsReceivableSettings, setDeteledAccountsReceivableSettings] = useState<{ id: string }[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()
  const location = useLocation()

  const onChangeState = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index) => {
    const value = e.target.value
    const name = e.target.name
    const newAccountsReceivableSettings = [...accountsReceivableSettings]
    newAccountsReceivableSettings[index][name] = value
    setAccountsReceivableSettings(newAccountsReceivableSettings)
  }

  const deleteAccountsReceivableSetting = (id, index) => {
    // Remove deleted elements from the displayed array
    const newAccountsReceivableSettings = [...accountsReceivableSettings]
    newAccountsReceivableSettings.splice(index, 1)
    if (id) {
      const newDeteledAccountsReceivableSettings: { id: string }[] = [...deteledAccountsReceivableSettings, { id: id }]
      setDeteledAccountsReceivableSettings(newDeteledAccountsReceivableSettings)
    }
    setAccountsReceivableSettings(newAccountsReceivableSettings)
  }

  const addAccountsReceivableSetting = () => {
    const newAccountsReceivableSetting: AccountsReceivableSettingType = {
      closingDate: 0,
      channelCode: '',
      channelName: '',
      code: '',
      id: '',
      name: '',
    }
    setAccountsReceivableSettings([...accountsReceivableSettings, newAccountsReceivableSetting])
  }

  const onSaveAccountsReceivableSettings = () => {
    setIsLoading(true)
    const fixedAccountsReceivableSettings = JSON.parse(JSON.stringify(accountsReceivableSettings))
    // Compare with data before change and delete from fixed array if the contents are the same
    accountsReceivableList.forEach(accountsReceivable => {
      const fixedAccountsReceivableIndex = fixedAccountsReceivableSettings.findIndex(item => item?.id === accountsReceivable.id)
      if (fixedAccountsReceivableIndex === -1) return
      const fixedAccountsReceivable = fixedAccountsReceivableSettings[fixedAccountsReceivableIndex]
      if (
        accountsReceivable.code === fixedAccountsReceivable.code &&
        accountsReceivable.name === fixedAccountsReceivable.name &&
        accountsReceivable.channelCode === fixedAccountsReceivable.channelCode &&
        accountsReceivable.closingDate === fixedAccountsReceivable.closingDate
      ) {
        fixedAccountsReceivableSettings.splice(fixedAccountsReceivableIndex, 1)
      }
    })
    // Join id of deleted data
    updateSalesAccountsReceivable({
      accountsReceivables: fixedAccountsReceivableSettings,
      accountsReceivablesToDelete: deteledAccountsReceivableSettings,
    })
      .then(() => {
        history.push({ pathname: '/setting/sales-manager/accounts-receivable', search: location.search })
      })
      .catch(error => {
        setIsLoading(false)
        errorHandler(error)
      })
  }

  const _fetchSalesAccountsReceivable = async () => {
    setIsLoading(true)
    await fetchSalesAccountsReceivable()
      .then(res => {
        setAccountsReceivableSettings(res?.accountsReceivables)
        // Accounts receivable list before change
        setAccountsReceivableList(JSON.parse(JSON.stringify(res?.accountsReceivables)))
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  const _fetchReservationChannelCode = async () => {
    setIsLoading(true)
    await fetchReservationChannelCode()
      .then(res => {
        setChannelLists(res?.channelCodes)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  useEffect(() => {
    _fetchSalesAccountsReceivable()
    _fetchReservationChannelCode()
  }, [])

  const footerContent = <EditFooter onSave={onSaveAccountsReceivableSettings} onCancel={history.goBack} />
  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <div css={settingAccountsReceivableEditContainerStyle}>
        <div css={settingAccountsReceivableEditHeaderStyle}>
          <p>{t('Accounts receivable')}</p>
        </div>
        {accountsReceivableSettings && (
          <SettingAccountsReceivableEditForm
            accountsReceivableSettings={accountsReceivableSettings}
            onChangeState={onChangeState}
            deleteAccountsReceivableSetting={deleteAccountsReceivableSetting}
            addAccountsReceivableSetting={addAccountsReceivableSetting}
            channelLists={channelLists}
          />
        )}
      </div>
    </SettingsLayout>
  )
}

const settingAccountsReceivableEditContainerStyle = css({
  height: '100%',
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
  background: '#FFF',
  padding: '0 0 23px',
})

const settingAccountsReceivableEditHeaderStyle = css({
  height: 50,
  padding: '16px 32px',
  borderBottom: '1px solid #F2F2F2',
  p: {
    fontSize: 18,
    fontWeight: 'bold',
  },
})
