import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { useWindowSize } from 'react-use'

// constants
import { listContainerStyle } from '@/constants/layout'

// apis
import { fetchCustomCheckin, storeCustomCheckin, updateCustomCheckin } from '@/apis/aipass'

// libs
import { CustomCheckinTranslateText } from '@/libs/translate-text'

// components
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { EditFooter } from '@/components/organisms/edit-footer'
import { Editor, editorOptions } from '@/components/organisms/editor'

export const SettingCustomCheckinEdit: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = require('query-string').parse(window.location.search)
  const customCheckinId = params.id
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [jpTitle, setJpTitle] = useState<string>('')
  const [jpContent, setJpContent] = useState<string>('')
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [inputType, setInputType] = useState<'none' | 'textArea' | 'selectBox'>('none')
  const [jpInputValue, setJpInputValue] = useState<any>([''])
  const [mode, setMode] = useState<'create' | 'update'>('update')
  const [isRequired, setIsRequired] = useState<boolean>(false)
  const windows = useWindowSize()

  const _fetchCustomCheckin = async customCheckinId => {
    await fetchCustomCheckin(customCheckinId)
      .then(res => {
        setJpTitle(res.customCheckin.jpTitle)
        setJpContent(res.customCheckin.jpContent)
        setInputType(res.customCheckin.inputType)
        setJpInputValue(res.customCheckin.jpInputValue)
        setIsRequired(Boolean(res.customCheckin.isRequired))
      })
      .catch(error => {
        console.log(error)
      })
  }

  const onChangeState = (e: React.ChangeEvent<HTMLInputElement>, index) => {
    const _inputValue = [...jpInputValue]
    const value = e.target.value
    _inputValue[index] = value
    setJpInputValue(_inputValue)
  }

  const deleteInputValue = index => {
    const _inputValue = [...jpInputValue]
    const newInputValue = _inputValue.filter((_, i) => i !== index)
    setJpInputValue(newInputValue)
  }

  const addInputValue = () => {
    const newInputValue = ''
    setJpInputValue([...jpInputValue, newInputValue])
  }

  const onChangeIsRequired = () => {
    setIsRequired(!isRequired)
  }

  const switchButton = (inputType: 'none' | 'textArea' | 'selectBox') => {
    switch (inputType) {
      case 'none':
        setInputType('none')
        break
      case 'textArea':
        setInputType('textArea')
        break
      case 'selectBox':
        setInputType('selectBox')
        if (jpInputValue?.length === 0) {
          setJpInputValue([''])
        }
        break
    }
  }

  const _updateCustomCheckin = async inputValue => {
    const jpContent = stateToHTML(editorState.getCurrentContent(), editorOptions).replace(/\r?\n/g, '</br>')
    await CustomCheckinTranslateText(
      jpTitle,
      jpContent,
      inputType,
      inputValue.filter(v => !!v),
      customCheckinId,
      isRequired,
    ).then(data => {
      if (mode === 'create') {
        storeCustomCheckin(data)
          .then(() => {
            setIsLoading(false)
            history.push({ pathname: '/setting/custom-checkin' })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        updateCustomCheckin(data)
          .then(() => {
            setIsLoading(false)
            history.push({ pathname: '/setting/custom-checkin' })
          })
          .catch(error => {
            console.log(error)
          })
      }
    })
  }

  const onSubmit = async () => {
    setIsLoading(true)
    // Empty choice value if not a choice formula
    if (inputType !== 'selectBox') {
      _updateCustomCheckin([''])
    } else {
      _updateCustomCheckin(jpInputValue)
    }
  }

  useEffect(() => {
    if (customCheckinId) {
      _fetchCustomCheckin(customCheckinId)
    }
    if (!customCheckinId) {
      setMode('create')
    }
  }, [])

  const footerContent = <EditFooter onCancel={history.goBack} onSave={onSubmit} />
  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <div
        css={editTopContainerStyle}
        style={{
          maxHeight: windows.height - 162,
        }}
      >
        <div css={editFormContainerStyle}>
          <label htmlFor="title" css={editFormLabelStyle}>
            {t('Title')}
          </label>
          <input type="text" id="title" value={jpTitle} onChange={e => setJpTitle(e.target.value)} css={editFormInputStyle} />
        </div>
        <div css={editorContainerStyle}>
          <label htmlFor="title" css={editFormLabelStyle}>
            {t('Content')}
          </label>
          <Editor jpContent={jpContent} editorState={editorState} setEditorState={setEditorState} usedIn={'custom-checkin'} />
        </div>
        <div css={editFormContainerStyle}>
          <label htmlFor="title" css={editFormLabelStyle}>
            {t('Customer input field')}
          </label>
          <div css={radioWrapperStyle}>
            <div css={radioItemStyle} onClick={() => switchButton('none')}>
              <img src={require(`@/static/images/radio_${inputType === 'none' ? 'on' : 'off'}.svg`)} css={radioStyle} />
              <p>{t('None')}</p>
            </div>
            <div css={radioItemStyle} onClick={() => switchButton('textArea')}>
              <img src={require(`@/static/images/radio_${inputType === 'textArea' ? 'on' : 'off'}.svg`)} css={radioStyle} />
              <p>{t('Free description')}</p>
            </div>
            <div css={radioItemStyle} onClick={() => switchButton('selectBox')}>
              <img src={require(`@/static/images/radio_${inputType === 'selectBox' ? 'on' : 'off'}.svg`)} css={radioStyle} />
              <p>{t('Selection formula')}</p>
            </div>
          </div>
          {inputType === 'textArea' && (
            <div css={optionWrapperStyle}>
              <label>
                <input
                  alt="checkbox"
                  type="image"
                  css={inputCheckboxStyle}
                  onClick={() => onChangeIsRequired()}
                  src={require(`@/static/images/check-box${isRequired === true ? '' : '_off'}.svg`)}
                />
                <span css={labelStyle}>{t('Required')}</span>
              </label>
            </div>
          )}
          {inputType === 'selectBox' && (
            <div css={optionWrapperStyle}>
              <ol css={formStyle}>
                {jpInputValue &&
                  jpInputValue.map((v, index) => (
                    <li key={index}>
                      <div className="jpInputValue">
                        <input
                          name="jpInputValue"
                          type="text"
                          placeholder={t('Enter item')}
                          css={inputStyle}
                          value={v}
                          onChange={e => onChangeState(e, index)}
                        />
                        {index > 0 && (
                          <div css={deleteButtonStyle} onClick={() => deleteInputValue(index)}>
                            <img src={require('@/static/images/delete_yellow.svg')} alt={t('Delete')} />
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                <div css={addStyle} onClick={addInputValue}>
                  {t('Add choice')}
                </div>
              </ol>
              <div css={borderStyle}>
                <label>
                  <input
                    alt="checkbox"
                    type="image"
                    css={inputCheckboxStyle}
                    onClick={() => onChangeIsRequired()}
                    src={require(`@/static/images/check-box${isRequired === true ? '' : '_off'}.svg`)}
                  />
                  <span css={labelStyle}>{t('Required')}</span>
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
    </SettingsLayout>
  )
}
const editFormContainerStyle = css({
  margin: '24px 32px',
})

const editFormLabelStyle = css({
  display: 'block',
  color: '#676767',
  fontSize: 12,
  fontWeight: 'bold',
  marginBottom: 12,
})

const editFormInputStyle = css({
  width: '100%',
  height: 32,
  border: '1px solid #ccc',
  borderRadius: 22,
  padding: '0 16px',
})

const editorContainerStyle = css({
  margin: '24px 32px 0',
  position: 'relative',
  height: '55vh',
})

const editTopContainerStyle = css(listContainerStyle, {
  width: '100%',
  height: '100%',
  overflow: 'scroll',
  overflowX: 'auto',
  overflowY: 'scroll',
  background: '#FFF',
  paddingTop: 32,
  marginBottom: 16,
})

const radioWrapperStyle = css({
  overflow: 'auto',
})
const radioStyle = css({
  width: 26,
})

const radioItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginRight: 61,
  float: 'left',
  color: '#272727',
  fontSize: 14,
  '&:hover': {
    cursor: 'pointer',
  },
  img: {
    marginRight: 14,
    width: 13,
    height: 13,
  },
})

const formStyle = css({
  display: 'inline-block',
  paddingTop: 24,
  paddingLeft: 7,
  marginLeft: 32,
  li: {
    listStyleType: 'decimal',
    // display: 'flex',
    padding: '0 0 16px 0',
    div: {
      display: 'flex',
      paddingRight: 16,
    },
  },
})

const deleteButtonStyle = css({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  img: {
    width: 30,
    height: 30,
  },
})

const addStyle = css({
  color: '#F2A40B',
  fontSize: 14,
  marginBottom: 24,
})

const inputStyle = css({
  width: 296,
  padding: '5px 0 5px 16px',
  fontSize: 14,
  borderRadius: '16px',
  border: '1px solid #CCC',
  '::placeholder': {
    color: '#CCCCCC',
  },
})

const optionWrapperStyle = css({
  border: '1px solid #CCC',
  borderRadius: 7,
  display: 'block',
  justifyContent: 'flex-start',
  margin: '15px 0',
  width: 400,
  img: {
    width: 20,
    height: 20,
  },
})

const inputCheckboxStyle = css({
  margin: '10px 0px 10px 15px',
  width: 20,
  height: 20,
})

const borderStyle = css({
  borderTop: '1px solid #CCC',
})

const labelStyle = css({
  fontSize: 14,
  textAlign: 'left',
  color: '#272727',
  letterSpacing: '1.4px',
  marginLeft: '10px',
})
