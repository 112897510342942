import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Button } from '@/components/atoms'
import { selfCheckinFetchGuestRoomAssign } from '@/apis/aipass'
import { SelfCheckinHotelInfo } from '../hotel-info'
import { SelfCheckinHeader } from '@/components/molecules'
import dayjs from 'dayjs'

type Props = {
  hotelId: string
  checkinId: string
  basicInfo: {
    hotelNameLatin: string
    telephone: string
    frontOpenTime: string
    frontCloseTime: string
  }
  onClickTop: () => void
}
export const AssignRoom: React.FC<Props> = ({ onClickTop, ...props }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const [roomInfo, setRoomInfo] = useState<any>([])

  const backGroundGrayHeight = () => {
    return roomInfo && roomInfo.length ? containerHeightPercent : containerHeightViewHeight
  }

  const fetchGuestRoomAssignInfo = async () => {
    await selfCheckinFetchGuestRoomAssign({
      hotelId: props.hotelId,
      checkinId: props.checkinId,
    })
      .then(res => {
        const assignRooms = props.checkinId ? Object.values(res.rooms).flat() : res.rooms
        assignRooms.sort((a, b) => {
          if (a.assignCheckinDate === b.assignCheckinDate) {
            return 0
          }
          return dayjs(a.assignCheckinDate).isBefore(dayjs(b.assignCheckinDate)) ? -1 : 1
        })
        setRoomInfo(assignRooms)
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    fetchGuestRoomAssignInfo()
  }, [])

  return (
    <>
      <SelfCheckinHeader title={t('Room information')} />
      <div css={backGroundGrayHeight}>
        <div css={mainStyle}>
          <div css={listContainerStyle}>
            <div css={descriptionStyle}>
              <Trans i18nKey="Please take a picture of your room and key number">
                Please <span style={{ color: '#F2A40B' }}>take a picture</span> of your room and key number
              </Trans>
            </div>
            <p css={sendMailNotificationStyle}>
              {t('If you have already entered your e-mail address, room information will be sent to you')}
            </p>
            {roomInfo &&
              roomInfo.map((item, index) => (
                <div css={roomInfoListContainerStyle} key={index}>
                  <div className="flexContainer" style={{ marginTop: '40px' }}>
                    <div css={smallTextStyle}>{t('Room type')}</div>
                    <div css={roomNumberStyle}>
                      {language === 'ja' && (item?.typeNameJa || '-')}
                      {(language === 'en' || language === 'zh' || language === 'ko') && (item?.typeNameEn || '-')}
                    </div>
                  </div>

                  <div className="flexContainer" style={{ marginTop: '16px' }}>
                    <div css={smallTextStyle}>{t('Room number')}</div>
                    <div css={roomNumberStyle}>{item?.roomNumber || '-'}</div>
                  </div>

                  <div className="flexContainer" style={{ margin: '16px 0 39px 0' }}>
                    <div css={smallTextStyle}>{t('Key number')}</div>
                    <div css={roomNumberStyle}>{item?.keyNumber || '-'}</div>
                  </div>
                </div>
              ))}
            {(!roomInfo || roomInfo?.length === 0) && (
              <div css={roomInfoListContainerStyle}>
                <div className="flexContainer" style={{ marginTop: '40px' }}>
                  <div css={smallTextStyle}>{t('Room type')}</div>
                  <div css={roomNumberStyle}>-</div>
                </div>

                <div className="flexContainer" style={{ marginTop: '16px' }}>
                  <div css={smallTextStyle}>{t('Room number')}</div>
                  <div css={roomNumberStyle}>-</div>
                </div>

                <div className="flexContainer" style={{ margin: '16px 0 39px 0' }}>
                  <div css={smallTextStyle}>{t('Key number')}</div>
                  <div css={roomNumberStyle}>-</div>
                </div>
              </div>
            )}
            <div css={roomGuidanceConfirmStyleButton}>
              <Button buttonType={3} width={308} height={48} onClick={onClickTop} fontSize={18} fontWeight={'normal'}>
                {t('Room and key number confirmed')}
              </Button>
            </div>
          </div>
        </div>
        <SelfCheckinHotelInfo
          telephone={props.basicInfo.telephone}
          frontOpenTime={props.basicInfo.frontOpenTime}
          frontCloseTime={props.basicInfo.frontCloseTime}
          position={'static'}
          usedIn="roomGuidance"
        />
      </div>
    </>
  )
}

const mainStyle = css({
  padding: '115px 0 0px 0',
  '@media(max-width: 1080px)': {
    padding: '100px 0 0px 0',
  },
})

const descriptionStyle = css({
  padding: '40px 0 8px 0',
  color: '#272727',
  fontWeight: 'bold',
  fontSize: 20,
  lineHeight: '29px',
  letterSpacing: '3px',
  textAlign: 'center',
})

const roomInfoListContainerStyle = css({
  border: '3px solid #CCCCCC',
  borderRadius: 5,
  background: '#F5F5F5',
  margin: '0 53px 24px 52px',
  '.flexContainer': {
    display: 'flex',
  },
})

const smallTextStyle = css({
  width: '196px',
  paddingLeft: '80px',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#676767',
  letterSpacing: '1.4px',
  lineHeight: '36px',
})

const roomNumberStyle = css({
  fontSize: '24px',
  fontWeight: 'bold',
  letterSpacing: '1.2px',
  lineHeight: '36px',
  textAlign: 'left',
  color: '#f2a40b',
})

const listContainerStyle = css({
  borderRadius: 5,
  background: '#FFF',
  boxShadow: '0px 0px 6px #0000001a',
  minHeight: 453,
  padding: '0px 0 20px 0',
  margin: '0 0 30px 0',
  '@media(max-width: 1080px)': {
    minHeight: 408,
  },
})

const roomGuidanceConfirmStyleButton = css({
  display: 'flex',
  justifyContent: 'center',
})

const sendMailNotificationStyle = css({
  textAlign: 'center',
  color: '#676767',
  marginBottom: '32px',
  fontSize: 14,
  letterSpacing: '1.4px',
  lineHeight: '20px',
})

const containerHeightPercent = css({
  margin: '0 auto',
  maxWidth: 1000,
  height: '100%',
  padding: '20px 0 40px 0',
  '@media(max-width: 1080px)': {
    maxWidth: 874,
  },
})

const containerHeightViewHeight = css({
  margin: '0 auto',
  maxWidth: 1000,
  padding: '20px 0 40px 0',
  '@media(max-width: 1080px)': {
    maxWidth: 874,
  },
  height: '100vh',
})
