import { TagStyle } from '@/models/customer-manager/customer-tag'
import { ApprovedStatusType } from '@/models/reservation-approved-status'

export const RoomStatusType = {
  empty: 1,
  checkIn: 2,
  stay: 3,
  checkOut: 4,
  cleaning: 5,
} as const
export type RoomStatusType = (typeof RoomStatusType)[keyof typeof RoomStatusType]

export type RoomIndicatorResponseType = {
  total: TotalType
  roomsEachFloor: RoomListType[]
}

export type TotalType = {
  emptyRoom: number
  checkin: number
  stay: number
  checkoutScheduled: number
  roomNotClean: number
}

export type RoomListType = {
  floorId: string
  floorName: string
  floorNameEn: string
  rooms: Array<RoomType | undefined>
}

export type ReservationType = {
  guestName: string
  currentNights: number
  totalNights: number
  hasUnpaidSales: boolean
  checkInDate: string
  checkOutDate: string
  approvedStatus: ApprovedStatusType
  reservationId: string
  checkInId: string | undefined
  notes: { memo: string; category: string }[]
  customerTags: TagStyle[]
}

export type RoomType = {
  roomId: string
  roomTypeId: string
  roomNumber: number
  roomName: string
  roomNameEn: string
  roomShortName: string
  roomStatus: RoomStatusType
  isAway: boolean
  prevReservation: ReservationType | undefined
  currentReservation: ReservationType | undefined
  nextReservation: ReservationType | undefined
}
