export const reservation = {
  通知種別: 'DataClassification',
  販売先コード: 'SalesOfficeCompanyCode',
  販売先名: 'SalesOfficeCompanyName',
  販売先販売店名: '',
  販売先販売店コード: 'ChannelCode',
  販売先販売店担当者名: '',
  販売先販売店担当者Email: '',
  販売先販売店電話番号: '',
  販売先営業所FAX番号: '',
  販売先販売店住所都道府県: '',
  販売先予約番号: 'TravelAgencyBookingNumber',
  予約受信日: 'TravelAgencyBookingDate',
  通知番号: '',
  '団体名または代表者氏名(半角)': 'GuestOrGroupNameKanaName',
  '団体名または代表者氏名 漢字': 'GuestOrGroupNameKanjiName',
  チェックイン日: 'CheckInDate',
  チェックイン時間: 'checkinTime',
  チェックアウト日: 'CheckOutDate',
  チェックアウト時刻: 'checkoutTime',
  泊数: 'Nights',
  入込方法: '',
  送客区分: '',
  利用客室合計数: 'TotalRoomCount',
  お客様総合計人数: 'NumberOfPeopleTotal',
  '大人人員(男性)合計': 'NumberOfPeopleMale',
  '大人人員(女性)合計': 'NumberOfPeopleFemale',
  子供A人数合計: 'NumberOfPeopleChildA',
  子供B人数合計: 'NumberOfPeopleChildB',
  子供C人数合計: 'NumberOfPeopleChildC',
  子供D人数合計: 'NumberOfPeopleChildD',
  添乗員人数合計: '',
  参加形態: '',
  プラン名: 'PackagePlanName',
  プランコード: 'PackagePlanCode',
  泊食条件: 'SpecificMealCondition',
  料金単価区分: '',
  税サ区分: '',
  '合計宿泊料金(総額)': 'TotalAccommodationCharge',
  合計その他料金: '',
  団体または代表者番号: 'PhoneNumber',
  団体または代表者Email: 'Email',
  団体または代表者郵便番号: '',
  団体または代表者住所: 'Address',
  大人人数: '',
  '予約者・会員名漢字': 'UserName',
  '予約者・会員名カタカナ': 'UserKana',
  '予約者・会員電話番号': 'UserTel',
  '予約者・会員Email': 'UserMailAddr',
  '予約者・会員郵便番号': '',
  '予約者・会員住所': 'UserAddr',
  '予約者・会員会社': '',
  '予約者・会員所属部署': '',
  '予約者・会員番号': '',
  'ポイント割引・補助金額': 'PointsDiscount',
  付与ポイント: '',
  充当ポイント: '',
  事前決済情報: 'SettlementDiv',
  '利用日（１）': 'roomDate1',
  '室タイプ（１）': 'roomType1',
  '室数（１）': 'roomCount1',
  '室料金（１）': 'roomRate1',
  '料金（大人男）（１）': 'PerMaleRate1',
  '料金（大人女）（１）': 'PerFemaleRate1',
  '料金（子Ａ）（１）': 'PerChildA70Rate1',
  '料金（子Ｂ）（１）': 'PerChildB50Rate1',
  '料金（子Ｃ）（１）': 'PerChildC30Rate1',
  '料金（子Ｄ）（１）': 'PerChildDRate1',
  '利用日（２）': 'roomDate2',
  '室タイプ（２）': 'roomType2',
  '室数（２）': 'roomCount2',
  '室料金（２）': 'roomRate2',
  '料金（大人男）（２）': 'PerMaleRate2',
  '料金（大人女）（２）': 'PerFemaleRate2',
  '料金（子Ａ）（２）': 'PerChildA70Rate2',
  '料金（子Ｂ）（２）': 'PerChildB50Rate2',
  '料金（子Ｃ）（２）': 'PerChildC30Rate2',
  '料金（子Ｄ）（２）': 'PerChildDRate2',
  '利用日（３）': 'roomDate3',
  '室タイプ（３）': 'roomType3',
  '室数（３）': '',
  '室料金（３）': '',
  '料金（大人男）（３）': '',
  '料金（大人女）（３）': '',
  '料金（子Ａ）（３）': '',
  '料金（子Ｂ）（３）': '',
  '料金（子Ｃ）（３）': '',
  '料金（子Ｄ）（３）': '',
  '利用日（４）': 'roomDate4',
  '室タイプ（４）': 'roomType4',
  '室数（４）': '',
  '室料金（４）': '',
  '料金（大人男）（４）': '',
  '料金（大人女）（４）': '',
  '料金（子Ａ）（４）': '',
  '料金（子Ｂ）（４）': '',
  '料金（子Ｃ）（４）': '',
  '料金（子Ｄ）（４）': '',
  '利用日（５）': 'roomDate5',
  '室タイプ（５）': 'roomType5',
  '室数（５）': '',
  '室料金（５）': '',
  '料金（大人男）（５）': '',
  '料金（大人女）（５）': '',
  '料金（子Ａ）（５）': '',
  '料金（子Ｂ）（５）': '',
  '料金（子Ｃ）（５）': '',
  '料金（子Ｄ）（５）': '',
  '利用日（６）': 'roomDate6',
  '室タイプ（６）': 'roomType6',
  '室数（６）': '',
  '室料金（６）': '',
  '料金（大人男）（６）': '',
  '料金（大人女）（６）': '',
  '料金（子Ａ）（６）': '',
  '料金（子Ｂ）（６）': '',
  '料金（子Ｃ）（６）': '',
  '料金（子Ｄ）（６）': '',
  '利用日（７）': 'roomDate7',
  '室タイプ（７）': 'roomType7',
  '室数（７）': '',
  '室料金（７）': '',
  '料金（大人男）（７）': '',
  '料金（大人女）（７）': '',
  '料金（子Ａ）（７）': '',
  '料金（子Ｂ）（７）': '',
  '料金（子Ｃ）（７）': '',
  '料金（子Ｄ）（７）': '',
  '利用日（８）': 'roomDate8',
  '室タイプ（８）': 'roomType8',
  '室数（８）': '',
  '室料金（８）': '',
  '料金（大人男）（８）': '',
  '料金（大人女）（８）': '',
  '料金（子Ａ）（８）': '',
  '料金（子Ｂ）（８）': '',
  '料金（子Ｃ）（８）': '',
  '料金（子Ｄ）（８）': '',
  '利用日（９）': 'roomDate9',
  '室タイプ（９）': 'roomType9',
  '室数（９）': '',
  '室料金（９）': '',
  '料金（大人男）（９）': '',
  '料金（大人女）（９）': '',
  '料金（子Ａ）（９）': '',
  '料金（子Ｂ）（９）': '',
  '料金（子Ｃ）（９）': '',
  '料金（子Ｄ）（９）': '',
  '利用日（１０）': 'roomDate10',
  '室タイプ（１０）': 'roomType10',
  '室数（１０）': '',
  '室料金（１０）': '',
  '料金（大人男）（１０）': '',
  '料金（大人女）（１０）': '',
  '料金（子Ａ）（１０）': '',
  '料金（子Ｂ）（１０）': '',
  '料金（子Ｃ）（１０）': '',
  '料金（子Ｄ）（１０）': '',
  '利用日（１１）': 'roomDate11',
  '室タイプ（１１）': 'roomType11',
  '室数（１１）': '',
  '室料金（１１）': '',
  '料金（大人男）（１１）': '',
  '料金（大人女）（１１）': '',
  '料金（子Ａ）（１１）': '',
  '料金（子Ｂ）（１１）': '',
  '料金（子Ｃ）（１１）': '',
  '料金（子Ｄ）（１１）': '',
  '利用日（１２）': 'roomDate12',
  '室タイプ（１２）': 'roomType12',
  '室数（１２）': '',
  '室料金（１２）': '',
  '料金（大人男）（１２）': '',
  '料金（大人女）（１２）': '',
  '料金（子Ａ）（１２）': '',
  '料金（子Ｂ）（１２）': '',
  '料金（子Ｃ）（１２）': '',
  '料金（子Ｄ）（１２）': '',
  オプション名1: 'optionName1',
  オプション単価1: 'optionRate1',
  オプション数1: 'optionCount1',
  オプション名2: 'optionName2',
  オプション単価2: 'optionRate2',
  オプション数2: 'optionCount2',
  オプション名3: 'optionName3',
  オプション単価3: 'optionRate3',
  オプション数3: 'optionCount3',
  オプション名4: 'optionName4',
  オプション単価4: 'optionRate4',
  オプション数4: 'optionCount4',
  オプション名5: 'optionName5',
  オプション単価5: 'optionRate5',
  オプション数5: 'optionCount5',
  備考: 'OtherServiceInformation',
  リンカーン販売先コード: '',
  '販売先コード（初期値）': '',
}
