import React from 'react'
import Lottie from 'react-lottie'
import { SerializedStyles, css } from '@emotion/core'

type LoadingProps = {
  isLoading: boolean
  customCss?: SerializedStyles
}

export const LoadingContent: React.FC<LoadingProps> = ({ customCss, isLoading }) => {
  const animationData = require('@/static/images/json/loading.json')

  return (
    <React.Fragment>
      {isLoading && (
        <div css={css([loadingOverlayStyle, customCss])}>
          <Lottie height="80px" width="80px" options={{ animationData: animationData, autoplay: true, loop: true }} />
        </div>
      )}
    </React.Fragment>
  )
}

const loadingOverlayStyle = css({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.8)',
  opacity: 0.7,
  alignItems: 'center',
  display: 'flex',
  zIndex: 999,
})
