import React, { useState } from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// components
import { sectionHeaderStyle } from '@/components/pages/dashboard/_index/style'
import { Button } from '@/components/atoms/button'
import { DetailedReserchPanel } from '@/components/pages/sales-manager/receipt-history/detailed-reserch-panel'

// libs
import { capitalize } from '@/libs/text'
import { InputField } from '@/components/molecules/input-field'

// apis
import { updateReceiptHistory } from '@/apis/aipass'

type Props = {
  receiptHistory: any
  detailedReserch: any
  onClickReserch: () => void
  setDetailedReserch: (e: any) => void
}

export const ReceiptHistoryList: React.FC<Props> = ({ receiptHistory, detailedReserch, onClickReserch, setDetailedReserch }) => {
  const windows = useWindowSize()
  const { t, i18n } = useTranslation()
  const [isDropDownReserchPanelOpen, setIsDropDownReserchPanelOpen] = useState<boolean>(false)

  // Checkbox when enable status update (single) is selected
  const handleEnableCheckboxChange = (e) => {
    if (e) {
      const isEnabled = e.target.value == 1 ? 0 : 1
      const enabledElement = document.getElementById(e.target.id)
      const enabledElementImage = require(`@/static/images/check-box${isEnabled == 1 ? '' : '_off'}.svg`)
      updateReceiptHistory(e.target.id, isEnabled).then(res => {
        if (res?.message === 'success') {
          enabledElement?.setAttribute('value', String(isEnabled))
          enabledElement?.setAttribute('src', enabledElementImage)
        }
      })
    }
  }

  return (
    <div
      css={mainContainerStyle}
      style={{
        minHeight: windows.height - 105,
      }}>
      <div css={headerContainerStyle}>
        <div css={headerStyle}>{capitalize(t('issue history'))}</div>
        <div>
          <Button
            buttonType={4}
            width={i18n.language === 'en' ? 118 : 90}
            height={32}
            fontSize={12}
            onClick={() => setIsDropDownReserchPanelOpen(!isDropDownReserchPanelOpen)}>
            {t('Detailed search')}
          </Button>
          {isDropDownReserchPanelOpen && (
            <DetailedReserchPanel
              onClickReserch={onClickReserch}
              setIsDropDownReserchPanelOpen={() => setIsDropDownReserchPanelOpen(!isDropDownReserchPanelOpen)}
              detailedReserch={detailedReserch}
              setDetailedReserch={setDetailedReserch}
            />
          )}
        </div>
      </div>
      <table css={tableStyle}>
        <thead>
          <tr css={tableHeadStyle}>
            <th css={tableHeadColStyle} scope="col">
              {capitalize(t('enable'))}
            </th>
            <th css={tableHeadColStyle} scope="col">
              {capitalize(t('created at'))}
            </th>
            <th css={tableHeadColStyle} scope="col">
              {capitalize(t('file name'))}
            </th>
            <th css={tableHeadColStyle} scope="col">
              {capitalize(t('link'))}
            </th>
          </tr>
        </thead>
        <tbody>
          {receiptHistory?.map((item: any) => (
            <tr key={item.createdAt}>
              <td css={tableBodyColStyle}>
                <input
                  id={item.id}
                  alt="receiptHistoryCheckboxList"
                  value={item.isEnabled}
                  type="image"
                  src={require(`@/static/images/check-box${
                    item.isEnabled == 1 ? '' : '_off'
                  }.svg`)}
                  onClick={e => {
                    e.preventDefault()
                    handleEnableCheckboxChange(e)
                  }}
                />
              </td>
              <td css={tableBodyColStyle} scope="row">
                {dayjs(item.createdAt).format('MM月DD日 HH:mm')}
              </td>
              <td css={tableBodyColStyle}>{item.fileName}</td>
              <td css={tableBodyColLinkStyle}>
                {item.url ? (
                  <a href={item.url} css={downloadColorStyle} target="_blank">
                    ダウンロード
                  </a>
                ) : (
                  '作成中'
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const mainContainerStyle = css({
  width: '100%',
  borderRadius: 5,
  boxShadow: '0px 0px 6px #0000001a',
  background: '#FFF',
  overflow: 'hidden',
})

const headerStyle = css(sectionHeaderStyle, {
  height: 50,
  marginLeft: 28,
})

const headerContainerStyle = css({
  display: 'flex',
  justifyContent: 'flex-start',
  backgroundColor: '#FFF',
  alignItems: 'center',
})

const tableStyle = css({
  width: '100%',
  padding: '0 32px',
})

const tableHeadStyle = css({
  backgroundColor: '#F5F5F5',
})

const tableHeadColStyle = css({
  textAlign: 'left',
  color: '#676767',
  fontSize: '12px',
  fontWeight: 'bold',
  padding: '8px 32px',
})

const tableBodyColStyle = css({
  padding: '16px 32px',
  borderBottom: '1px solid #f2f2f2',
  fontSize: 14,
})

const tableBodyColLinkStyle = css(tableBodyColStyle, {
  fontWeight: 'bold',
  fontSize: 12,
})

const downloadColorStyle = css({
  color: '#F2A40B',
})
