import React, { useContext, useState, useEffect, Dispatch } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// contexts
import { AccountContext } from '@/contexts/account'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle } from '@/constants/layout'

// apis
import { fetchAdminDashboard, fetchDashboardOrders } from '@/apis/aipass'

// libs
import { setHasMobileRequestPlugin } from '@/libs/plugins'

//models
import { DashboardOrderType } from '@/models/mobile-request/order'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { BusinessStatus } from '@/components/pages/dashboard/_index/business-status'
import { Memo } from '@/components/pages/dashboard/_index/memo'
import { Request } from '@/components/pages/dashboard/_index/request'
import { ReviewInfo } from '@/components/pages/dashboard/_index/review-info'
import { MessageBoard } from '@/components/pages/dashboard/_index/message-board'
import dayjs, { Dayjs } from 'dayjs'

type ContainerProps = {}

type Props = {
  date: Dayjs
  setDate: Dispatch<React.SetStateAction<Dayjs>>
  isLoading: boolean
  setIsLoading: (v: boolean) => void
  dashboardValue: any
  hasMobileRequestPlugin: boolean | undefined
  orders: DashboardOrderType[] | undefined
}

export const Dashboard: React.FC<ContainerProps> = () => {
  const [date, setDate] = useState(dayjs())
  const { account, plugins } = useContext<any>(AccountContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dashboardValue, setDashboardValue] = useState([])
  const [orders, setOrders] = useState<DashboardOrderType[]>()
  // 全体掲示板
  const hasMobileRequestPlugin = setHasMobileRequestPlugin(plugins)

  const _refresh = async () => {
    setIsLoading(true)
    _fetchAdminDashboard()
  }

  const _fetchAdminDashboard = async () => {
    await fetchAdminDashboard(date).then(res => {
      setDashboardValue(res)
      setIsLoading(false)
    })
  }

  const _fetchDashboardOrders = () => {
    fetchDashboardOrders().then((res: DashboardOrderType[]) => {
      setOrders(res)
    })
  }

  useEffect(() => {
    if (account && account?.hotel) {
      _refresh()
      _fetchDashboardOrders()
    }
  }, [account, date])

  return (
    <UI
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      dashboardValue={dashboardValue}
      hasMobileRequestPlugin={hasMobileRequestPlugin}
      orders={orders}
      date={date}
      setDate={setDate}
    />
  )
}

export const UI: React.FC<ContainerProps & Props> = ({ isLoading, dashboardValue, hasMobileRequestPlugin, orders, date, setDate }) => {
  const { t } = useTranslation()
  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header title={t('Dashboard')} />
        <div className="dashboardMainContainerStyle" css={dashboardMainContainerStyle}>
          <div css={leftContainerStyle}>
            <BusinessStatus dailyTotal={dashboardValue?.dailyTotal} date={date} setDate={setDate} />
            {hasMobileRequestPlugin && <Request orders={orders} />}
            <Memo dashboardValue={dashboardValue} />
            {/* <CustomerTrafficByTime /> */}
          </div>
          <div css={rightContainerStyle}>
            <MessageBoard />
            <ReviewInfo dashboardValue={dashboardValue} />
          </div>
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const dashboardMainContainerStyle = css(mainContainerStyle, {
  display: 'flex',
  overflowX: 'scroll',
  height: 'calc(100vh - 60px)',
})

const leftContainerStyle = css({
  flex: 'auto',
  width: '66.5%',
  minWidth: 695,
  marginRight: 16,
})

const rightContainerStyle = css({
  width: 340,
  minWidth: 340,
})
