import React, { useState, useEffect, CSSProperties, useRef, Dispatch, SetStateAction, ClipboardEvent } from 'react'
import { css } from '@emotion/core'
import {
  EditorState,
  RichUtils,
  ContentState,
  DraftStyleMap,
  AtomicBlockUtils,
  ContentBlock,
  genKey,
  EntityInstance,
  convertFromRaw,
  convertToRaw,
  getDefaultKeyBinding,
  DraftHandleValue,
} from 'draft-js'
import DrtaftEditor, { composeDecorators } from '@draft-js-plugins/editor'
import createImagePlugin from '@draft-js-plugins/image'
import createFocusPlugin from '@draft-js-plugins/focus'
import createVideoPlugin from '@draft-js-plugins/video'
import '@draft-js-plugins/focus/lib/plugin.css'
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop'
import 'draft-js/dist/Draft.css'
import { EditorLinkPopup, EditorLinkPopupForm } from '@/components/molecules/settings/editor/edtor-link-popup'
import { RenderConfig, stateToHTML } from 'draft-js-export-html'
import { EditorImagePopup } from '@/components/molecules/settings/editor/editor-image-popup'
import CustomDecorator, { linkEntityStyle, hrEntityStyle, iframeEntityStyle } from '@/components/molecules/settings/editor/custom-decorator'
import { EditorButtonPopup, EditorButtonPopupForm } from '@/components/molecules/settings/editor/edtor-button-popup'
import { DesignCustomButtonToEditor, DesignCustomButtonToHtml } from '@/components/molecules/settings/editor/design-custom-button'
import * as Immutable from 'immutable'
import {
  changeBlockData,
  changeSelectorToCurrent,
  insertBlock,
  insertTextInSelectedState,
  isActiveStyleType,
  isFocusedLastBlock,
  isSelectingWord,
  removeBlock,
  getFocusBlock,
  splitBlock,
} from '@/components/molecules/settings/editor/helper'
import { CustomBlockquoteDataType, CustomBlockquoteToHtml } from '@/components/molecules/settings/editor/custom-blockquote'
import htmlToDraft from 'html-to-draftjs'
import { EditorVideoPopup } from '@/components/molecules/settings/editor/edtor-video-popup'

type EditorProps = {
  htmlContent?: string
  rawContent: string | undefined
  onChange: (raw: string, html: string) => void
  style?: CSSProperties
  id?: string
  headerElement?: JSX.Element
  isShowVideoIcon?: boolean
  placeholder?: string
}

export const StyleType = {
  H1: 'header-one',
  H2: 'header-two',
  Bold: 'BOLD',
  Underline: 'UNDERLINE',
  Ul: 'unordered-list-item',
  Ol: 'ordered-list-item',
  Link: 'LINK',
  Image: 'IMAGE',
  Button: 'CUSTOM_BUTTON',
  TagBlock: 'blockquote',
  Hr: 'HORIZONTAL_RULE',
  Iframe: 'draft-js-video-plugin-video',
} as const
export type StyleType = (typeof StyleType)[keyof typeof StyleType]

const styleMap: DraftStyleMap = {
  [StyleType.Bold]: {
    fontWeight: 'bold',
  },
  [StyleType.Underline]: {
    textDecoration: 'underline',
  },
}

const focusPlugin = createFocusPlugin()
const blockDndPlugin = createBlockDndPlugin()
const videoPlugin = createVideoPlugin({
  theme: {
    video: 'custom-video-wrapper',
    iframeContainer: 'custom-iframe-container',
    iframe: 'custom-iframe',
    invalidVideoSrc: 'custom-invalid-video-src',
  },
})
const imagePlugin = createImagePlugin({
  decorator: composeDecorators(focusPlugin.decorator, blockDndPlugin.decorator),
})
const editorPlugins = [focusPlugin, imagePlugin, blockDndPlugin, videoPlugin]

export const Editor: React.FC<EditorProps> = ({
  htmlContent,
  rawContent,
  onChange: parentOnChange,
  style: customStyle,
  id,
  headerElement,
  placeholder,
  isShowVideoIcon = true,
}) => {
  const [isButtonInputOpen, setIsButtonInputOpen] = useState<boolean>(false)
  const [isLinkInputOpen, setIsLinkInputOpen] = useState<boolean>(false)
  const [isVideoLinkInputOpen, setIsVideoLinkInputOpen] = useState<boolean>(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [isFocusedEditor, setIsFocusedEditor] = useState<boolean>(false)
  const editorRef = useRef<HTMLDivElement>(null)
  const [editingButtonProps, setEditingButtonProps] = useState<{
    blockKey: string
    data: EditorButtonPopupForm
  }>()

  const richUtilAction: Partial<Record<StyleType, CallableFunction>> = {
    [StyleType.H1]: RichUtils.toggleBlockType,
    [StyleType.H2]: RichUtils.toggleBlockType,
    [StyleType.Bold]: RichUtils.toggleInlineStyle,
    [StyleType.Underline]: RichUtils.toggleInlineStyle,
    [StyleType.Ul]: RichUtils.toggleBlockType,
    [StyleType.Ol]: RichUtils.toggleBlockType,
  }

  const convertToHtmlOption = {
    blockRenderers: {
      [StyleType.Button]: block => DesignCustomButtonToHtml(block.getData().toObject()),
      [StyleType.TagBlock]: block => CustomBlockquoteToHtml(block, editorState),
    },
    entityStyleFn: (entity: EntityInstance): RenderConfig | undefined => {
      const entityType = entity.getType()
      const data = entity.getData()
      if (entityType === StyleType.Link) {
        return linkEntityStyle(data.url)
      }
      if (entityType === StyleType.Hr) {
        return hrEntityStyle()
      }
      if (entityType === StyleType.Iframe) {
        return iframeEntityStyle(data.src)
      }
    },
  }

  const onEditorLink = (formValue: EditorLinkPopupForm) => {
    const { contentState, newEditorState } = isSelectingWord(editorState)
      ? {
          contentState: editorState.getCurrentContent(),
          newEditorState: editorState,
        }
      : insertTextInSelectedState(editorState, formValue.name)

    const linkEntity = contentState.createEntity(StyleType.Link, 'MUTABLE', { url: formValue.url })
    const entityKey = linkEntity.getLastCreatedEntityKey()
    let editorStateWithLink = EditorState.set(newEditorState, { currentContent: linkEntity })
    editorStateWithLink = RichUtils.toggleLink(editorStateWithLink, editorStateWithLink.getSelection(), entityKey)
    editorStateWithLink = changeSelectorToCurrent(editorStateWithLink)
    onChange(editorStateWithLink)
  }

  const onEditorVideo = (embedUrl: string) => {
    const addVideoState = videoPlugin.addVideo(editorState, { src: embedUrl })
    onChange(addVideoState)
  }

  const onEditorImage = (uploadFileLink: string) => {
    const contentState = editorState.getCurrentContent()
    const imageEntityKey = contentState
      .createEntity(StyleType.Image, 'MUTABLE', {
        alt: '',
        src: uploadFileLink,
      })
      .getLastCreatedEntityKey()
    onChange(AtomicBlockUtils.insertAtomicBlock(editorState, imageEntityKey, ' '))
  }

  const onEditorButton = (formValue: EditorButtonPopupForm, blockKey: string | undefined) => {
    if (blockKey) {
      onChange(changeBlockData(editorState, blockKey, formValue))
      return
    }

    const newBlock = new ContentBlock({
      key: genKey(),
      type: StyleType.Button,
      text: formValue.name,
      characterList: Immutable.List(),
      depth: 0,
      data: Immutable.Map(formValue),
    })

    const emptyBlock = new ContentBlock({
      key: genKey(),
      type: 'unstyled',
      text: '',
      characterList: Immutable.List(),
    })

    onChange(insertBlock(editorState, [newBlock, emptyBlock]))
  }

  const scrollToEndIfLastBlock = (state: EditorState) => {
    if (isFocusedLastBlock(state) && editorRef?.current) {
      editorRef.current.scrollTop = editorRef.current.scrollHeight
    }
  }

  const onClickEditorTool = (styleType: StyleType) => {
    const callableRichUtil = richUtilAction[styleType]
    if (!callableRichUtil) {
      return
    }
    const newEditorState = callableRichUtil(editorState, styleType)
    onChange(newEditorState)
  }

  const onChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState)
    if (isFocusedEditor) {
      const htmlText = stateToHTML(newEditorState.getCurrentContent(), convertToHtmlOption)
      const rawData = JSON.stringify(convertToRaw(newEditorState.getCurrentContent()))
      parentOnChange(rawData, htmlText.replaceAll('<figure><hr>&nbsp;</hr></figure>', '<hr />'))
    }
  }

  const insertTagBlockElement = (data: CustomBlockquoteDataType) => {
    let updatingState = splitBlock(editorState)

    const tagBlockEntity = updatingState.getCurrentContent().createEntity(StyleType.TagBlock, 'MUTABLE', data)
    updatingState = EditorState.set(updatingState, { currentContent: tagBlockEntity })
    const tagBlockEntityKey = tagBlockEntity.getLastCreatedEntityKey()

    const insertedState = insertTextInSelectedState(updatingState, data.defaultText, tagBlockEntityKey)
    updatingState = insertedState.newEditorState
    updatingState = RichUtils.toggleBlockType(updatingState, StyleType.TagBlock)
    updatingState = changeSelectorToCurrent(updatingState)

    updatingState = splitBlock(updatingState)
    updatingState = RichUtils.toggleBlockType(updatingState, 'unstyled')

    onChange(updatingState)
  }

  const blockRenderer = (block: ContentBlock) => {
    if (block.getType() === StyleType.Button) {
      return {
        component: DesignCustomButtonToEditor({
          onClick: (blockKey, data) => {
            setIsButtonInputOpen(true)
            setEditingButtonProps({ blockKey, data })
          },
        }),
        editable: false,
      }
    }
    return null
  }

  useEffect(() => {
    if (editorState) {
      scrollToEndIfLastBlock(editorState)
    }
  }, [editorState])

  useEffect(() => {
    if (!id) {
      return
    }
    let convertedContent: ContentState
    if (rawContent && rawContent !== '{}') {
      convertedContent = convertFromRaw(JSON.parse(rawContent))
    } else {
      const replaceBrToLBContent = htmlContent
        ?.replaceAll(/\r\n/g, '\n')
        .replaceAll(/<\/?br>/g, '\n')
        .split('\n')
        .map(line => (line.length === 0 ? '<p></p>' : line))
        .join('\n')
      const { contentBlocks, entityMap } = htmlToDraft(replaceBrToLBContent || '', (nodeName, node) => {
        if (nodeName === 'hr') {
          return {
            type: StyleType.Hr,
            mutability: 'IMMUTABLE',
          }
        }
      })
      convertedContent = ContentState.createFromBlockArray(contentBlocks, entityMap)
    }
    setEditorState(EditorState.createWithContent(convertedContent, new CustomDecorator()))
  }, [id])

  const handleKeyBinding = e => {
    if (e.key !== 'Backspace' && e.key !== 'Delete') {
      return getDefaultKeyBinding(e)
    }

    const focusKey = editorState.getSelection().getFocusKey()
    const focusBlock = editorState.getCurrentContent().getBlockMap().get(focusKey)
    if (!focusBlock) {
      return getDefaultKeyBinding(e)
    }

    const fetchDeleteTargetBlock = (isBefore: boolean) => {
      if (isBefore) {
        const beforeBlock = editorState
          .getCurrentContent()
          .getBlockMap()
          .takeUntil(v => v === focusBlock)
          .last()
        if (beforeBlock?.getType() === StyleType.Button && focusBlock.getText() === '') {
          return beforeBlock
        }
      } else {
        const afterBlocks = editorState
          .getCurrentContent()
          .getBlockMap()
          .skipUntil(v => v === focusBlock)
          .slice(0, 2)

        const afterBlock = afterBlocks.first()
        if (afterBlock?.getType() === StyleType.Button) {
          return afterBlock
        }
        const isEmptyAfterBlock = afterBlock?.getText() === ''
        const secondBlock = afterBlocks.last()
        if (isEmptyAfterBlock && secondBlock?.getType() === StyleType.Button) {
          return afterBlock
        }
      }
    }
    const deleteBlock = fetchDeleteTargetBlock(e.key === 'Backspace')
    if (deleteBlock) {
      onChange(removeBlock(editorState, deleteBlock.getKey()))
      return 'disabled'
    }
    return getDefaultKeyBinding(e)
  }

  const handleReturn = (): DraftHandleValue => {
    if (getFocusBlock(editorState)?.getType() === StyleType.TagBlock) {
      onChange(RichUtils.insertSoftNewline(editorState))
      return 'handled'
    }
    return 'not-handled'
  }

  const castCopiedText = (data: string) => {
    let result
    try {
      result = JSON.parse(data)
    } catch {
      return ''
    }

    return result as CustomBlockquoteDataType
  }

  const onPaste = async (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault()

    if (getFocusBlock(editorState)?.getType() === StyleType.TagBlock) {
      return
    }

    const copiedText = await navigator.clipboard.readText()
    const castedItem = castCopiedText(copiedText)
    if (!castedItem) {
      return
    }

    insertTagBlockElement(castedItem)
  }

  return (
    <div style={{ height: '100%', position: 'sticky', zIndex: 10, ...customStyle }}>
      <div css={editorToolContainerStyle}>
        <img
          src={require(`@/static/images/icon_h1.svg`)}
          className={isActiveStyleType(editorState, StyleType.H1) ? 'active-type' : ''}
          css={textediterToolItemStyle}
          onClick={() => onClickEditorTool(StyleType.H1)}
        />
        <img
          src={require(`@/static/images/icon_h2.svg`)}
          className={isActiveStyleType(editorState, StyleType.H2) ? 'active-type' : ''}
          css={textediterToolItemStyle}
          onClick={() => onClickEditorTool(StyleType.H2)}
        />
        <img
          src={require(`@/static/images/textediter_bold.svg`)}
          className={isActiveStyleType(editorState, StyleType.Bold) ? 'active-type' : ''}
          css={textediterToolItemStyle}
          onClick={() => onClickEditorTool(StyleType.Bold)}
        />
        <img
          src={require(`@/static/images/textediter_underline.svg`)}
          className={isActiveStyleType(editorState, StyleType.Underline) ? 'active-type' : ''}
          css={textediterToolItemStyle}
          onClick={() => onClickEditorTool(StyleType.Underline)}
        />
        <img
          src={require(`@/static/images/textediter_list.svg`)}
          className={isActiveStyleType(editorState, StyleType.Ul) ? 'active-type' : ''}
          css={textediterToolItemStyle}
          onClick={() => onClickEditorTool(StyleType.Ul)}
        />
        <img
          src={require(`@/static/images/textediter_numberlist.svg`)}
          className={isActiveStyleType(editorState, StyleType.Ol) ? 'active-type' : ''}
          css={textediterToolItemStyle}
          onClick={() => onClickEditorTool(StyleType.Ol)}
        />
        <div className="has-popup" css={textediterToolItemStyle}>
          <img src={require(`@/static/images/icon_button1.svg`)} onClick={() => setIsButtonInputOpen(!isButtonInputOpen)} />
          {isButtonInputOpen && (
            <EditorButtonPopup
              onSave={val => {
                setIsButtonInputOpen(false)
                onEditorButton(val, editingButtonProps?.blockKey)
                setEditingButtonProps(undefined)
              }}
              onCancel={() => {
                setIsButtonInputOpen(false)
                setEditingButtonProps(undefined)
              }}
              form={editingButtonProps?.data}
            />
          )}
        </div>
        <div className="has-popup" css={textediterToolItemStyle}>
          <img src={require(`@/static/images/textediter_link.svg`)} onClick={() => setIsLinkInputOpen(!isVideoLinkInputOpen)} />

          {isLinkInputOpen && (
            <EditorLinkPopup
              onSave={val => {
                setIsLinkInputOpen(false)
                onEditorLink(val)
              }}
              onCancel={() => setIsLinkInputOpen(false)}
              isSelectingWord={isSelectingWord(editorState)}
            />
          )}
        </div>
        <div className="has-popup" css={textediterToolItemStyle}>
          <EditorImagePopup onChange={onEditorImage} />
        </div>
        {isShowVideoIcon && (
          <div className="has-popup" css={textediterToolItemStyle}>
            <img src={require(`@/static/images/textediter_video.svg`)} onClick={() => setIsVideoLinkInputOpen(!isLinkInputOpen)} />
            {isVideoLinkInputOpen && (
              <EditorVideoPopup
                onSave={val => {
                  setIsVideoLinkInputOpen(false)
                  onEditorVideo(val.url)
                }}
                onCancel={() => setIsVideoLinkInputOpen(false)}
              />
            )}
          </div>
        )}

        {headerElement && <div className="right-element">{headerElement}</div>}
      </div>
      <div css={editorStyle} ref={editorRef} onPaste={onPaste}>
        <DrtaftEditor
          placeholder={placeholder}
          editorState={editorState}
          customStyleMap={styleMap}
          onChange={onChange}
          plugins={editorPlugins}
          blockRendererFn={blockRenderer}
          onFocus={() => setIsFocusedEditor(true)}
          keyBindingFn={handleKeyBinding}
          handleReturn={handleReturn}
        />
      </div>
    </div>
  )
}

const editorToolContainerStyle = css({
  width: '100%',
  backgroundColor: '#f2f2f2',
  border: '1px solid #cccccc',
  borderBottom: 'none',
  borderRadius: '12px 12px 0 0',
  padding: '10px 14px',
  img: {
    cursor: 'pointer',
  },
  '.right-element': {
    marginLeft: 'auto',
  },
  height: 38,
  display: 'flex',
  alignItems: 'center',
})

const editorStyle = css({
  border: '1px solid #cccccc',
  borderRadius: '0 0 12px 12px',
  borderTop: 'none',
  padding: '16px 24px',
  fontSize: 14,
  lineHeight: 1.5,
  // sub toolbar
  height: 'calc(100% - 38px)',
  width: '100%',
  overflow: 'scroll',
  position: 'relative',
  overflowX: 'auto',
  overflowY: 'scroll',
  span: {
    fontWeight: 'unset',
  },
  h1: {
    fontSize: 20,
    fontWeight: 'bold',
    margin: '16px 0 8px',
    span: {
      fontWeight: 'bold',
    },
  },
  h2: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: '16px 0 8px',
    span: {
      fontWeight: 'bold',
    },
  },
  'ul ,ol': {
    margin: '0 32px',
  },
  img: {
    width: '100%',
    maxWidth: 800,
  },
  blockquote: {
    margin: '12px 0',
    padding: 16,
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    background: '#F5F5F5',
  },
  '.custom-iframe': {
    width: '100%',
    borderRadius: 20,
    aspectRatio: '1.78 / 1',
  },
})

const textediterToolItemStyle = css({
  width: 28,
  height: 28,
  marginRight: 6,
  ':hover': {
    backgroundColor: '#FFF',
    borderRadius: '50%',
  },
  '&.active-type': {
    backgroundColor: '#FFF',
    borderRadius: '50%',
  },
  '&.has-popup': {
    display: 'flex',
    position: 'sticky',
    zIndex: 3,
  },
  color: '#676767',
  letterSpacing: 0.52,
  fontSize: 13,
  fontWeight: 'bold',
  textAlign: 'center',
  lineHeight: '24px',
})
