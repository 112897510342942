import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

type PagingProps = {
  page: number
  total: number
  setPage: (v: number) => void
  limit?: number
  isDisabled?: boolean
  onChange?: (page: number) => void
}

export const AccommodationPaging: React.FC<PagingProps> = ({ page, total, setPage, limit = 50, isDisabled = false, onChange }) => {
  const { t } = useTranslation()

  const currentRange = (): { start: number; end: number } => {
    return {
      start: 1 + (page - 1) * limit,
      end: Math.min(page * limit, total),
    }
  }

  const hasPrev = (): boolean => {
    return currentRange().start !== 1
  }

  const hasNext = (): boolean => {
    return currentRange().end !== total
  }

  return (
    <>
      <div css={pagingWrapperStyle}>
        <p>{`${total === 0 ? '0 / 0' : `${currentRange().start}-${currentRange().end} / ${total}`} ${t('Rows')}`}</p>
        {hasPrev() && !isDisabled ? (
          <img
            onClick={() => {
              setPage(page - 1)
              onChange && onChange(page - 1)
            }}
            src={require('@/static/images/arrow_left.svg')}
            css={pagingStyle}
          />
        ) : (
          <img src={require('@/static/images/arrow_disabled_left.svg')} css={disabledPagingStyle} />
        )}
        {hasNext() && !isDisabled ? (
          <img
            onClick={() => {
              setPage(page + 1)
              onChange && onChange(page + 1)
            }}
            src={require('@/static/images/arrow_right.svg')}
            css={pagingStyle}
          />
        ) : (
          <img src={require('@/static/images/arrow_disabled_right.svg')} css={disabledPagingStyle} />
        )}
      </div>
    </>
  )
}

const pagingWrapperStyle = css({
  fontWeight: 'bold',
  fontSize: 12,
  lineHeight: '23px',
  color: '#676767',
  display: 'flex',
  alignItems: 'center',
  marginLeft: 24,
  p: {
    marginRight: 16,
  },
  img: {
    width: 30,
    height: 30,
  },
})

const pagingStyle = css({
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#f2f2f2',
    borderRadius: '50%',
  },
})

const disabledPagingStyle = css({
  '&:hover': {
    cursor: 'defalut',
  },
})
