import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import parse from 'html-react-parser'
import dayjs from 'dayjs'

// constants
import { SINGLE_CHOICE, MULTIPLE_CHOICE } from '@/constants/type-select-reservations'
import { RadioButton } from '@/components/atoms/radio-button'
require('dayjs/locale/ko')
require('dayjs/locale/zh')

export type Props = {
  reservation: any
  selectType: number
  isCheckedListReservation: (item) => boolean
  handleCheckListResvartion: (e: any, item: any) => void
  checkedSingleResvertion: string
  handleCheckSingleReservation: (item: any) => void
}

export const ReservationDetail: React.FC<Props> = ({
  reservation,
  selectType,
  handleCheckListResvartion,
  isCheckedListReservation,
  checkedSingleResvertion,
  handleCheckSingleReservation,
}) => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const _formatDate = item => {
    return dayjs(item).locale(lang).format('MM/DD(ddd)')
  }

  const autoFitWidth = () => {
    if (lang === 'ja') {
      return 'jaWidth'
    }
    if (lang === 'en') {
      return 'enWidth'
    }
    if (lang === 'ko') {
      return 'koWidth'
    }
    if (lang === 'zh') {
      return 'zhWidth'
    }
  }
  return (
    <div>
      {selectType == MULTIPLE_CHOICE && (
        <div css={containerStyle} onClick={e => handleCheckListResvartion(e, reservation.reservationId)}>
          <div css={headerStyle}>
            <input
              id={reservation.reservationId}
              type="image"
              alt="checkbox"
              value={reservation.reservationId}
              css={inputCheckboxStyle}
              src={require(`@/static/images/check-box${isCheckedListReservation(reservation.reservationId) ? '' : '_off'}.svg`)}
            />
            <label css={labelStyle} htmlFor={reservation.reservationId}>
              <p css={date}>
                {_formatDate(reservation.checkinDate)}
                <span>
                  {reservation.nights}
                  {t('Night')}
                </span>
              </p>
            </label>
          </div>
          <div css={infoWrapperStyle}>
            <div css={infoStyle}>
              <h4 css={title} className={autoFitWidth()}>
                {t('Plan')}
              </h4>
              <span css={description}>{lang === 'ja' ? reservation.plan : reservation.planEn}</span>
            </div>
            <div css={infoStyle}>
              <h4 css={title} className={autoFitWidth()}>
                {t('Room')}
              </h4>
              <div css={reservationRoom}>
                {reservation.reservationRoom &&
                  reservation.reservationRoom.map((item, index) => (
                    <span key={index}>
                      {(lang === 'ja' ? item.typeNameJa : item.typeNameEn) + ' / ' + item.roomNum + `${t('room counter')}`}
                    </span>
                  ))}
              </div>
            </div>
            <div css={infoStyle}>
              <h4 css={title} className={autoFitWidth()}>
                {t('Number of guests')}
              </h4>
              <span css={description}>
                {reservation.paxTotal} {lang === 'ja' ? t('Person') : ''}
              </span>
            </div>
            <div css={infoStyle}>
              <h4 css={title} className={autoFitWidth()}>
                {t('Reservation holder')}
              </h4>
              <span css={description}>{reservation.userName}</span>
            </div>
            <div css={infoStyle}>
              <h4 css={title} className={autoFitWidth()}>
                {t('Confirmed guest')}
              </h4>
              <span css={description}>{reservation.guestName}</span>
            </div>
          </div>
          <div css={footerStyle}>
            <span style={{ marginRight: '8px' }}>{t('Reservation ID')}</span>
            <span>{reservation.reservationId}</span>
          </div>
        </div>
      )}
      {selectType == SINGLE_CHOICE && (
        <div css={containerStyle} onClick={() => handleCheckSingleReservation(reservation[0].checkinId)}>
          <div css={singleChoiceHeaderStyle}>
            <RadioButton
              value={checkedSingleResvertion}
              item={{
                value: reservation[0].checkinId,
                label: reservation[0].guestName,
              }}
              id={reservation[0].checkinId}
              checked={checkedSingleResvertion === reservation[0].checkinId}
              onChange={() => handleCheckSingleReservation(reservation[0].checkinId)}
              style={{ radioSize: 28 }}
              itemCss={radioButtonStyle}
            />
          </div>
          {!!reservation?.length &&
            reservation.map(rsv => (
              <div css={wrapperStyle} key={rsv.reservationId}>
                <div css={leftColumnStyle}>
                  <p css={date}>
                    {_formatDate(rsv.checkinDate)}
                    <span>
                      {rsv.nights}
                      {t('Night')}
                    </span>
                  </p>
                </div>
                <div css={rightColumnStyle}>
                  <div css={rightColumnInfoStyle}>
                    <h4 css={title} className={autoFitWidth()}>
                      {t('Plan')}
                    </h4>
                    <span css={description}>{lang === 'ja' ? rsv.plan : rsv.planEn}</span>
                  </div>
                  <div css={rightColumnInfoStyle}>
                    <h4 css={title} className={autoFitWidth()}>
                      {t('Room')}
                    </h4>
                    <div css={reservationRoom}>
                      {rsv.reservationRoom &&
                        rsv.reservationRoom.map((item, index) => (
                          <span key={index}>
                            {(lang === 'ja' ? item.typeNameJa : item.typeNameEn) + ' / ' + item.roomNum + `${t('room counter')}`}
                          </span>
                        ))}
                    </div>
                  </div>
                  <div css={rightColumnInfoStyle}>
                    <h4 css={title} className={autoFitWidth()}>
                      {t('Reservation ID')}
                    </h4>
                    <div css={reservationRoom}>
                      <span>{rsv.reservationId}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

const containerStyle = css({
  width: '100%',
  backgroundColor: '#fff',
  textAlign: 'left',
  paddingBottom: '30',
  borderRadius: '5px',
})

const headerStyle = css({
  textAlign: 'left',
  height: '72px',
  borderBottom: '1px solid #ccc',
  padding: '24px 32px 21px 32px',
  display: 'flex',
  alignItems: 'center',
})

const singleChoiceHeaderStyle = css({
  textAlign: 'left',
  height: '72px',
  padding: '24px 32px 21px 32px',
  display: 'flex',
  alignItems: 'center',
})

const wrapperStyle = css({
  borderTop: '1px solid #ccc',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

const leftColumnStyle = css({
  padding: '24px 0px 21px 40px',
})

const rightColumnStyle = css({
  borderLeft: '1px solid #ccc',
  margin: '24px 24px 27px 40px',
  padding: '0px 24px 0px 40px',
})

const rightColumnInfoStyle = css({
  display: 'flex',
  color: '#272727',
  justifyContent: 'flex-start',
  alignItems: 'baseline',
  paddingBottom: 12,
  '.jaWidth': {
    minWidth: 100,
  },
  '.enWidth': {
    minWidth: 172,
  },
  '.zhWidth': {
    minWidth: 114,
  },
  '.koWidth': {
    minWidth: 93,
  },
  '&:last-child': {
    paddingBottom: 0,
  },
})

const date = css({
  textAlign: 'left',
  color: '#272727',
  fontSize: 21,
  fontWeight: 'bold',
  letterSpacing: '2.1px',
  span: {
    marginLeft: 13,
  },
})

const inputCheckboxStyle = css({
  width: 25,
  height: 25,
})

const labelStyle = css({
  fontSize: 21,
  textAlign: 'left',
  color: '#272727',
  marginLeft: '35px',
})

const radioButtonStyle = css({
  label: {
    fontSize: '21px',
    letterSpacing: '1.7px',
  },
})

const infoWrapperStyle = css({
  padding: '24px 24px 27px 24px',
})

const infoStyle = css({
  display: 'flex',
  color: '#272727',
  justifyContent: 'flex-start',
  alignItems: 'baseline',
  paddingBottom: 12,
  '.jaWidth': {
    minWidth: 70,
  },
  '.enWidth': {
    minWidth: 172,
  },
  '.zhWidth': {
    minWidth: 114,
  },
  '.koWidth': {
    minWidth: 93,
  },
  '&:last-child': {
    paddingBottom: 0,
  },
})

const title = css({
  fontWeight: 'bold',
  fontSize: 14,
  letterSpacing: '1.4px',
  width: 'fit-content',
  lineHeight: '20px',
})
const description = css({
  fontSize: 16,
  letterSpacing: '1.6px',
  fontWeight: 'normal',
  lineHeight: '20px',
})

const reservationRoom = css({
  display: 'flex',
  flexDirection: 'column',
  span: {
    fontSize: 16,
    letterSpacing: '1.6px',
    fontWeight: 'normal',
    lineHeight: '20px',
    marginBottom: '7px',
  },
})

const footerStyle = css({
  height: '46px',
  borderTop: '1px solid #ccc',
  padding: '13px 24px',
  span: {
    lineHeight: '20px',
    fontSize: '14px',
    letterSpacing: '1.4px',
    color: '#272727',
  },
})
