import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { SerializedStyles, css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useHistory, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { useErrorHandler } from '@/hooks/use-error-handler'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { sectionHeaderStyle } from '@/components/pages/dashboard/_index/style'

// constants
import { wholeContainerStyle, mainColumnStyle, listHeaderGrayStyle, listContentsWrapperStyle } from '@/constants/layout'
import { SideMenu } from '@/components/organisms/side-menu'
import { Header } from '@/components/organisms/header'
import { AccountContext } from '@/contexts/account'

// apis
import { fetchUploadCsvHistory } from '@/apis/aipass'

// models
import { FileMessageType, CsvUploadHistoryResponseType } from '@/models/csv-upload-history/csv-upload-history'

export const UploadHistory: React.FC = () => {
  const { t } = useTranslation()
  const windows = useWindowSize()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [uploadCsvHistory, setUploadCsvHistory] = useState<CsvUploadHistoryResponseType[]>([])
  const { account } = useContext<any>(AccountContext)
  const history = useHistory()
  const location = useLocation()
  const { state } = useLocation<{ fromEntireSearch: boolean }>()
  const { errorHandler } = useErrorHandler()
  const csvUploadingListStorageKey = 'csvUploadingList'
  const [csvUploadingList, setCsvUploadingList] = useState<{ [id: string]: number }>(
    JSON.parse(localStorage.getItem(csvUploadingListStorageKey) || '{}'),
  )
  const [uploading, setUploading] = useState<CsvUploadHistoryResponseType[]>([])
  const [initialCsvUploadingList] = useState<{ [id: string]: number }>(JSON.parse(localStorage.getItem(csvUploadingListStorageKey) || '{}'))
  const [startTime] = useState<dayjs.Dayjs>(dayjs())

  // Get CSV Upload list
  const _fetchUploadCsvHistory = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const res = await fetchUploadCsvHistory()
      setUploadCsvHistory(res)
      const uploading = res?.filter(upload => !upload?.uploadStatus) ?? []
      !!uploading.length ? setUploading(uploading) : null
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const _updateProgress = (): void => {
    try {
      uploading?.map(upload => {
        const initialStorageUploadingSecond = initialCsvUploadingList[upload.id] || 0
        const diffSecond = Number(dayjs().diff(startTime, 'second'))
        const second = Math.min(Number(initialStorageUploadingSecond) + diffSecond, 99)
        csvUploadingList[upload.id] = second
      })
      const changeCsvUploadList = { ...csvUploadingList }
      setCsvUploadingList(changeCsvUploadList)
      localStorage.setItem(csvUploadingListStorageKey, JSON.stringify(changeCsvUploadList))
    } catch (error) {
      errorHandler(error)
    }
  }

  const _uploadStatusMessageType = (id: string, uploadStatus: boolean, errorMessage: string): string => {
    if (errorMessage) {
      const errors = JSON.parse(errorMessage).map(error => error?.line)
      const errorStrings = errors[0] !== 0 ? errors.map(e => e + t('Error line')).join(', ') : t('Failed to upload csv')
      return `${t('Upload file type error')} (${errorStrings})`
    }
    if (!uploadStatus) {
      const percent = csvUploadingList[id] || 0
      return t('Upload file type execution') + '...(' + percent + '%)'
    }
    return t('Upload file type complete')
  }

  const _uploadStatusStyle = (uploadStatus: boolean, errorMessage: string): SerializedStyles => {
    if (errorMessage) {
      return dataStatusErrorTypeStyle
    }
    return uploadStatus ? dataStatusCompleteTypeStyle : dataStatusTypeStyle
  }

  // Back to accommodation list
  const goBack = () => {
    history.push({
      pathname: state?.fromEntireSearch ? '/entire-search' : '/accommodation-management',
      search: location.search,
    })
  }

  useEffect(() => {
    if (account && account?.hotel) {
      _fetchUploadCsvHistory()
    }
  }, [account])

  useEffect(() => {
    const intervalId = setInterval(async () => {
      await _fetchUploadCsvHistory()
      _updateProgress()
      if (!uploading?.length) {
        localStorage.removeItem(csvUploadingListStorageKey)
        clearInterval(intervalId)
      }
    }, 30000)
  }, [uploading])

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div css={mainColumnStyle}>
        <Header title={t('Accommodation management')} isBack goBack={goBack} />
        <div
          css={settingMainContainerStyle}
          style={{
            minHeight: windows.height - 180,
          }}
        >
          <div
            css={mainContainerStyle}
            style={{
              minHeight: windows.height - 105,
            }}
          >
            <div css={headerContainerStyle}>
              <div css={headerStyle}>{t('Import history')}</div>
            </div>
            <ul css={listHeaderStyle}>
              <li css={dataCreateDateStyle}>{t('Creation date')}</li>
              <li css={dataFileTypeStyle}>{t('Upload file type')}</li>
              <li css={dataFileNameStyle}>{t('Upload file name')}</li>
              <li css={dataStatusTypeStyle}>{t('Upload status')}</li>
            </ul>
            <div
              css={listContentsWrapperStyle}
              style={{
                maxHeight: windows.height - 180,
              }}
            >
              <div css={customListContentsStyle}>
                {uploadCsvHistory &&
                  uploadCsvHistory.map(v => {
                    return (
                      <div key={v.id} css={customListItemUnreadStyle}>
                        <div css={dataCreateDateStyle}>{dayjs(v.createdAt).format('MM月DD日 HH:mm')}</div>
                        <div css={dataFileTypeStyle}>{t(FileMessageType[v.fileType])}</div>
                        <div css={dataFileNameStyle}>{v.fileName}</div>
                        <div css={_uploadStatusStyle(v.uploadStatus, v.errorMessage)}>
                          {_uploadStatusMessageType(v.id, v.uploadStatus, v.errorMessage)}
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const settingMainContainerStyle = css({
  padding: 24,
})

const mainContainerStyle = css({
  width: '100%',
  borderRadius: 5,
  boxShadow: '0px 0px 6px #0000001a',
  background: '#FFF',
  overflow: 'hidden',
})

const headerStyle = css(sectionHeaderStyle, {
  height: 50,
  marginLeft: 28,
})

const headerContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#FFF',
})

const listHeaderStyle = css(listHeaderGrayStyle, {
  padding: '0 32px',
})

const headTitleStyle = css({
  display: 'flex',
  alignItems: 'center',
})

const dataCreateDateStyle = css(headTitleStyle, {
  width: '12%',
})

const dataFileTypeStyle = css(headTitleStyle, {
  width: '8%',
})

const dataFileNameStyle = css(headTitleStyle, {
  marginRight: 32,
  width: '20%',
})

const dataStatusTypeStyle = css(headTitleStyle, {
  fontWeight: 'bold',
  width: '60%',
})

const dataStatusCompleteTypeStyle = css(headTitleStyle, {
  fontWeight: 'bold',
  width: '60%',
  color: '#F2A40B',
})

const dataStatusErrorTypeStyle = css(headTitleStyle, {
  fontWeight: 'bold',
  width: '60%',
  color: '#F44F42',
})

const customListContentsStyle = css({
  fontSize: 14,
})

const customListItemUnreadStyle = css({
  padding: '16px 32px',
  display: 'flex',
  borderBottom: '1px solid #f2f2f2',
  width: '100%',
  alignItems: 'center',
})
