import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { AccountContext } from '@/contexts/account'
import {
  listContainerStyle,
  listContentsWrapperStyle,
  listContentsStyle,
  HEADER_HEIGHT,
  MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL,
  CONTAINER_WITH_HEADER_BUTTON_LIST_MARGIN,
  TAB_HEIGHT,
  GRAY_LABEL_HEIGHT,
  LIST_FOOTER_HEIGHT,
} from '@/constants/layout'
import {
  settingHeaderContainerStyle,
  listWrapperStyle,
  listStyle,
  hideListStyle,
  deleteStyle,
} from '@/components/pages/setting/guest-room/room/layout'
import { getAdminGuestRoomNumber, deleteAdminGuestRoomNumber, insertAdminGuestRoomNumberCsv } from '@/apis/aipass'
import { GuestRoomTab } from '@/components/pages/setting/guest-room/tab'
import { GuestRoomNumberType, GuestRoomNumberCsvType } from '@/models/guest-room-number'
import { SettingGuestRoomLabel } from '@/components/pages/setting/guest-room/room/_room/label'
import { SettingGuestRoomEditButtons } from '@/components/pages/setting/guest-room/room/_room/edit-buttons'
import { EditFooter } from '@/components/organisms/edit-footer'
import { RoomNumberModal } from '@/components/pages/setting/guest-room/room/_room/modal/room-number-modal'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { Hint } from '@/components/atoms/hint'
import { css } from '@emotion/core'

type Csv = {
  guestRoomNumbersCsv: GuestRoomNumberCsvType[]
}

export const SettingGuestRoomNumber: React.FC = () => {
  const windows = useWindowSize()
  const { t, i18n } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { account } = useContext<any>(AccountContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showFooter, setShowFooter] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [deleteRoomIds, setDeleteRoomIds] = useState<string[]>([])
  const [guestRoomNumbers, setGuestRoomNumbers] = useState<GuestRoomNumberType[]>([])
  const [nextDisplayOrder, setNextDisplayOrder] = useState<string>()
  const [nextAssignOrder, setNextAssignOrder] = useState<string>()
  const [showDetailRoom, setShowDetailRoom] = useState<GuestRoomNumberType>()

  const fetchGuestRoomNumber = async () => {
    setIsLoading(true)
    const res = await getAdminGuestRoomNumber()
    setGuestRoomNumbers(res)
    setIsLoading(false)
    setNextDisplayOrder(`${Number(res.slice(-1)[0]?.displayOrder || 0) + 1}`)
    setNextAssignOrder(`${Number(res.slice(-1)[0]?.assignOrder || 0) + 1}`)
  }

  const onFileLoad = async data => {
    try {
      setIsLoading(true)
      const uploadData: Csv = {
        guestRoomNumbersCsv: data.map(function (value) {
          return {
            displayOrder: value.表示順,
            assignOrder: value.アサイン順,
            roomNumber: value.客室番号,
            roomTypeName: t(value.客室タイプ),
            roomFloorName: t(value.フロア),
            capacity: t(value.人数),
          }
        }),
      }
      const res = await insertAdminGuestRoomNumberCsv(uploadData.guestRoomNumbersCsv)
      if (res && res?.errRows) {
        const errRows = res.errRows.filter(value => value)
        if (errRows.length) {
          alert(t('Row {{row}} could not be captured', { row: errRows.join(',') }))
        }
      }
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
      fetchGuestRoomNumber()
    }
  }

  const onCLickDelete = async (guestRoomId: string) => {
    setShowFooter(true)
    setDeleteRoomIds(deleteRoomIds => [...deleteRoomIds, guestRoomId])
  }

  const onClickFooterSaveButton = async () => {
    try {
      setIsLoading(true)
      if (deleteRoomIds) {
        await deleteAdminGuestRoomNumber(deleteRoomIds)
      }
      setShowFooter(false)
      setDeleteRoomIds([])
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
      fetchGuestRoomNumber()
    }
  }

  const onClickFooterCancelButton = () => {
    setShowFooter(false)
    setDeleteRoomIds([])
  }

  const listItems = [
    {
      label: t('Display order'),
      width: 90,
    },
    {
      label: t('Assign sequence'),
      width: 116,
      style: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
      },
      child: (
        <Hint
          customCss={css({
            '.help-popup': {
              width: 380,
              padding: '13px 16px',
              color: '#676767',
              fontSize: 12,
              fontWeight: 'bold',
              letterSpacing: 1.2,
              lineHeight: '18px',
            },
          })}
        >
          <div>自動アサイン時に使用します。アサインの優先順位が同じ場合は、同じ数字で登録してください。</div>
        </Hint>
      ),
    },
    {
      label: t('Room number'),
      width: 133,
    },
    {
      label: t('Room type'),
      width: 294,
    },
    {
      label: t('Floor'),
      width: 133,
    },
    {
      label: t('Capacity'),
      width: 'auto',
    },
  ]

  const footerContent = showFooter ? <EditFooter onSave={onClickFooterSaveButton} onCancel={onClickFooterCancelButton} /> : undefined

  useEffect(() => {
    if (account && account?.hotel) {
      fetchGuestRoomNumber()
    }
  }, [account])

  return (
    <>
      {showCreateModal && (
        <RoomNumberModal
          detail={showDetailRoom}
          nextDisplayOrder={nextDisplayOrder || '1'}
          nextAssignOrder={nextAssignOrder || '1'}
          onSave={() => fetchGuestRoomNumber()}
          onClose={() => {
            setShowCreateModal(false)
            setShowDetailRoom(undefined)
          }}
        />
      )}
      <SettingsLayout loading={isLoading} footerContent={footerContent}>
        <>
          <div css={settingHeaderContainerStyle}>
            <GuestRoomTab currentTab="roomNumber" />
          </div>
          <div css={[listContainerStyle]}>
            <SettingGuestRoomEditButtons onFileLoad={onFileLoad} onClickAddButton={() => setShowCreateModal(true)} />
            <SettingGuestRoomLabel listItems={listItems} />
            <div
              css={[listContentsWrapperStyle]}
              style={
                showFooter
                  ? {
                      maxHeight:
                        windows.height -
                        HEADER_HEIGHT -
                        MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL -
                        CONTAINER_WITH_HEADER_BUTTON_LIST_MARGIN -
                        TAB_HEIGHT -
                        GRAY_LABEL_HEIGHT -
                        LIST_FOOTER_HEIGHT,
                    }
                  : {
                      maxHeight:
                        windows.height -
                        HEADER_HEIGHT -
                        MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL -
                        CONTAINER_WITH_HEADER_BUTTON_LIST_MARGIN -
                        TAB_HEIGHT -
                        GRAY_LABEL_HEIGHT,
                    }
              }
            >
              <div css={listContentsStyle}>
                {guestRoomNumbers &&
                  guestRoomNumbers.map((value, index) => {
                    if (deleteRoomIds.includes(value.id)) {
                      return <div key={index} css={hideListStyle} />
                    } else {
                      return (
                        <div key={index} css={listWrapperStyle}>
                          <div
                            css={listStyle}
                            onClick={() => {
                              setShowDetailRoom(value)
                              setShowCreateModal(true)
                            }}
                          >
                            <div style={{ width: listItems[0].width }}>{value.displayOrder}</div>
                            <div style={{ width: listItems[1].width }}>{value.assignOrder}</div>
                            <div style={{ width: listItems[2].width }}>{value.roomNumber}</div>
                            <div style={{ width: listItems[3].width }}>{i18n.language === 'ja' ? value.typeNameJa : value.typeNameEn}</div>
                            <div style={{ width: listItems[4].width }}>
                              {i18n.language === 'ja' ? value.floorNameJa : value.floorNameEn}
                            </div>
                            <div style={{ width: listItems[5].width }}>
                              {value.capacity === null || value.capacity === undefined ? '-' : value.capacity}
                            </div>
                          </div>
                          <img
                            css={deleteStyle}
                            src={require('@/static/images/room_delete_gold.svg')}
                            onClick={() => onCLickDelete(value.id)}
                          />
                        </div>
                      )
                    }
                  })}
              </div>
            </div>
          </div>
        </>
      </SettingsLayout>
    </>
  )
}
