import React, { useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// constants
import { wholeContainerStyle, mainColumnStyle, headerBarRightWrapperStyle, headerBarLeftWrapperStyle } from '@/constants/layout'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { SideMenu } from '@/components/organisms/side-menu'
import { Header } from '@/components/organisms/header'
import { SalesManagerTab } from '@/components/pages/sales-manager/tab'
import { DateRangeField } from '@/components/molecules/date-range-field'
import { Button } from '@/components/atoms'
import { SalesTable } from '@/components/pages/sales-manager/business-forms/_index/sales-table'

// apis
import { fetchBusinessForms, fetchBusinessFormsCsv } from '@/apis/aipass'

// libs
import { csvDownload } from '@/libs/csv-download'

// models
import { BusinessFormsType } from '@/models/sales-manager/business-forms'

type ContainerProps = {}

type Props = {
  businessForms: BusinessFormsType | undefined
  beginDate: string
  endDate: string
  numberOfRooms: string
  onChangeNumberOfRooms: (e) => void
  changeRangeDate: (beginDate, endDate) => void
  isDropDownOpen: boolean
  setIsDropDownOpen: (open: boolean) => void
  downloadTotalCsv: () => void
  downloadDailyCsv: () => void
  checkCompletedInput: () => void
  isLoading: boolean
}

export const SalesManagerBusinessForms: React.FC = () => {
  const { t } = useTranslation()
  const [businessForms, setBusinessForms] = useState<BusinessFormsType>()
  const [numberOfRooms, setNumberOfRooms] = useState<string>('')
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)
  const [beginDate, setBeginDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onChangeNumberOfRooms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumberOfRooms(e.target.value)
  }

  const _changeRangeDate = (beginDate, endDate) => {
    setBeginDate(beginDate)
    setEndDate(endDate)
  }

  const _downloadTotalCsv = async () => {
    const formatedBeginDate = `${beginDate} 00:00:00`
    const formatedEndDate = `${endDate} 23:59:59`
    await fetchBusinessFormsCsv(formatedBeginDate, formatedEndDate, numberOfRooms, 'total')
      .then(res => {
        const data = res?.businessFormsCsv
        const fileName = 'business-forms-total.csv'
        csvDownload(data, fileName)
        setIsLoading(false)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const _downloadDailyCsv = async () => {
    const formatedBeginDate = `${beginDate} 00:00:00`
    const formatedEndDate = `${endDate} 23:59:59`
    await fetchBusinessFormsCsv(formatedBeginDate, formatedEndDate, numberOfRooms, 'daily')
      .then(res => {
        const data = res?.businessFormsCsv
        const fileName = 'business-forms-daily.csv'
        csvDownload(data, fileName)
        setIsLoading(false)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const checkCompletedInput = () => {
    if (beginDate && endDate && numberOfRooms) {
      _fetchBusinessForms()
    } else {
      window.alert(t('Please enter dates and number of rooms'))
    }
  }

  const _fetchBusinessForms = async () => {
    setIsLoading(true)
    const formatedBeginDate = `${beginDate} 00:00:00`
    const formatedEndDate = `${endDate} 23:59:59`
    await fetchBusinessForms(formatedBeginDate, formatedEndDate, numberOfRooms)
      .then(res => {
        if (res !== undefined) {
          setBusinessForms(res.total)
        }
        setIsLoading(false)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  return (
    <UI
      businessForms={businessForms}
      beginDate={beginDate}
      endDate={endDate}
      numberOfRooms={numberOfRooms}
      onChangeNumberOfRooms={onChangeNumberOfRooms}
      changeRangeDate={_changeRangeDate}
      isDropDownOpen={isDropDownOpen}
      setIsDropDownOpen={setIsDropDownOpen}
      downloadTotalCsv={_downloadTotalCsv}
      downloadDailyCsv={_downloadDailyCsv}
      checkCompletedInput={checkCompletedInput}
      isLoading={isLoading}
    />
  )
}

export const UI: React.FC<ContainerProps & Props> = ({
  businessForms,
  beginDate,
  endDate,
  numberOfRooms,
  onChangeNumberOfRooms,
  changeRangeDate,
  isDropDownOpen,
  setIsDropDownOpen,
  downloadTotalCsv,
  downloadDailyCsv,
  checkCompletedInput,
  isLoading,
}) => {
  const { t } = useTranslation()
  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div css={mainColumnStyle}>
        <Header title={t('Sales management')} />
        <div css={containerStyle}>
          <div css={mainContainerStyle}>
            <div css={headerContainerStyle}>
              <SalesManagerTab currentTab="business-forms" />
            </div>
            <div css={listContainerStyle}>
              <div css={formWrapperStyle}>
                <div css={headerBarLeftWrapperStyle}>
                  <div css={dateStyle}>
                    <DateRangeField beginDate={beginDate} endDate={endDate} changeRangeDate={changeRangeDate} />
                  </div>
                  <div css={inputWrapperStyle}>
                    <p css={labelStyle}>{t('Number of rooms')}</p>
                    <input
                      name="quantity"
                      type="text"
                      css={inputStyle}
                      value={numberOfRooms}
                      onChange={e => onChangeNumberOfRooms(e)}
                      placeholder="9999"
                    />
                  </div>
                  <Button width={79} height={32} buttonType={1} onClick={checkCompletedInput}>
                    {t('Create')}
                  </Button>
                </div>
                <div css={rightButtonWrapperStyle}>
                  <Button
                    buttonType={4}
                    width={185}
                    height={32}
                    fontSize={12}
                    marginRight={12}
                    icon={require('@/static/images/download.svg')}
                    onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                  >
                    {t('CSV download')} <img src={require('@/static/images/select_gray.svg')} css={dropDownIcon} />
                  </Button>
                  {isDropDownOpen && (
                    <div css={dropDownStyle}>
                      <ul>
                        <li
                          css={dropDownContentStyle}
                          onClick={() => {
                            downloadTotalCsv()
                            setIsDropDownOpen(false)
                          }}
                        >
                          <p css={dropDownTitleStyle}>{t('Totalling')}</p>
                        </li>
                        <li
                          css={dropDownContentStyle}
                          onClick={() => {
                            downloadDailyCsv()
                            setIsDropDownOpen(false)
                          }}
                        >
                          <p css={dropDownTitleStyle}>{t('Daily')}</p>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <SalesTable businessForms={businessForms} />
            </div>
          </div>
        </div>
        <LoadingFull isLoading={isLoading} />
      </div>
    </div>
  )
}

const rightButtonWrapperStyle = css(headerBarRightWrapperStyle, {
  position: 'relative',
})

const labelStyle = css({
  textAlign: 'center',
  color: '#272727',
  fontSize: 14,
  marginRight: 16,
})

const formWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const dateStyle = css({
  marginRight: 16,
})

const inputWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
})

const inputStyle = css({
  appearance: 'none',
  padding: 0,
  paddingLeft: 20,
  color: '#272727',
  fontSize: 14,
  width: 88,
  height: 32,
  border: '1px solid #ccc',
  borderRadius: 21,
  marginRight: 16,
  '::placeholder': {
    color: '#ccc',
  },
})

// CSV download
const dropDownStyle = css({
  width: 160,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 35,
  right: 11,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  fontSize: 15,
  color: '#272727',
})

const dropDownIcon = css({
  marginLeft: 8,
})

const containerStyle = css({
  display: 'flex',
  padding: 0,
  minWidth: '1000px',
})

const mainContainerStyle = css({
  width: '100%',
  padding: 24,
})

const listContainerStyle = css({
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: '0 5px 5px 5px',
  background: '#FFF',
  padding: 32,
  position: 'relative',
})

const headerContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})
