import React, { createContext, useEffect, useState } from 'react'
import { getAssetList } from '@/apis/aipass'
import { AssetType, isButton1Asset, isButton2Asset, isCouponAsset, isFreeAsset, isImageAsset, isMapAsset, isNewsAsset, isPulldownAsset, isRequestAsset, isSpaceAsset } from '@/models/guest-app/asset'
import { useParams } from 'react-router-dom'
import { FreeAssetList } from '@/components/organisms/settings/guest-app/free-asset-list'
import { PulldownAssetDetail } from '@/components/organisms/settings/guest-app/pulldown-asset-detail'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { GuestAppTab } from '@/components/molecules/settings/guest-app-tab'
import { EditFooter } from '@/components/organisms/edit-footer'
import dayjs, { Dayjs } from 'dayjs'
import { MapAssetTemplate } from '@/components/organisms/settings/guest-app/map-asset-template'
import { NewsAssetList } from '@/components/organisms/settings/guest-app/news-asset-list'
import { ButtonAssetDetail } from '@/components/organisms/settings/guest-app/button-asset-detail'
import { CouponAssetList } from '@/components/organisms/settings/guest-app/coupon-asset-list'
import { ImageAssetList } from '@/components/organisms/settings/guest-app/image-asset-list'
import { RequestAssetTemplate } from '@/components/organisms/settings/guest-app/request-asset-template'
import { SpaceAssetList } from '@/components/organisms/settings/guest-app/space-asset-list'

export const AssetDetailContext = createContext<{
  doReload: () => void
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  listenDoSave?: Dayjs
  listenDoCancel?: Dayjs
}>({
  doReload: () => {},
  setIsEditing: () => {},
  setIsLoading: () => {},
})

export const SettingGuestAppAssetDetail: React.FC = () => {
  const [asset, setAsset] = useState<AssetType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShowFooter, setIsShowFooter] = useState<boolean>(false)
  const [listenDoSave, fireDoSave] = useState<Dayjs>()
  const [listenDoCancel, fireDoCancel] = useState<Dayjs>()
  const { id: urlId } = useParams<{ id: string }>()

  const initialView = async () => {
    fireDoSave(undefined)
    fireDoCancel(undefined)
    setIsShowFooter(false)

    const assets = await getAssetList()
    setAsset(assets.find(asset => asset.id === urlId))
  }

  const doSave = async () => {
    fireDoSave(dayjs)
  }

  const doCancel = () => {
    fireDoCancel(dayjs)
  }

  useEffect(() => {
    initialView()
  }, [])

  const footerContent = isShowFooter ? <EditFooter onSave={doSave} onCancel={doCancel} /> : undefined
  return (
    <AssetDetailContext.Provider
      value={{
        doReload: initialView,
        setIsEditing: setIsShowFooter,
        setIsLoading,
        listenDoSave,
        listenDoCancel,
      }}
    >
      <SettingsLayout loading={isLoading} footerContent={footerContent}>
        <TabContainer tabComponent={<GuestAppTab currentTab="Asset" />} isShowFooter={isShowFooter}>
          {isFreeAsset(asset) && <FreeAssetList asset={asset} />}
          {isPulldownAsset(asset) && <PulldownAssetDetail asset={asset} />}
          {isMapAsset(asset) && <MapAssetTemplate asset={asset} />}
          {isNewsAsset(asset) && <NewsAssetList asset={asset} />}
          {isButton1Asset(asset) && <ButtonAssetDetail asset={asset} />}
          {isButton2Asset(asset) && <ButtonAssetDetail asset={asset} />}
          {isImageAsset(asset) && <ImageAssetList asset={asset} />}
          {isRequestAsset(asset) && <RequestAssetTemplate asset={asset} />}
          {isCouponAsset(asset) && <CouponAssetList asset={asset} />}
          {isSpaceAsset(asset) && <SpaceAssetList asset={asset} />}
        </TabContainer>
      </SettingsLayout>
    </AssetDetailContext.Provider>
  )
}
