import React, { useState } from 'react'
import { SingleDatePicker, CalendarDay } from 'react-dates'
import { useTranslation } from 'react-i18next'

// model
import { GuestRoomAssignResponseType } from '@/models/room-manager/response/guest-room-assign'
import moment from 'moment'

type DatePickerProps = {
  date: moment.Moment | null
  setDate: React.Dispatch<React.SetStateAction<moment.Moment>> | React.Dispatch<React.SetStateAction<moment.Moment | null>>
  type: 'select' | 'checkin' | 'checkout'
  setRoomManagerTopSelectDate:
    | React.Dispatch<React.SetStateAction<moment.Moment>>
    | React.Dispatch<React.SetStateAction<moment.Moment | null>>
  fetchSelectableGuestRoom?: (
    beginDate: moment.Moment,
    endDate: moment.Moment,
    editRooms?: GuestRoomAssignResponseType['rooms'],
    roomIndex?: number | undefined,
  ) => void
  otherDate?: moment.Moment
  readOnly?: boolean
  setOtherDate?: React.Dispatch<React.SetStateAction<moment.Moment>> | React.Dispatch<React.SetStateAction<moment.Moment | null>>
  setAssignPanelEditMode?: React.Dispatch<React.SetStateAction<boolean>>
  disabled?: boolean
}

export const DatePicker: React.FC<DatePickerProps> = ({
  date,
  setDate,
  type,
  setRoomManagerTopSelectDate,
  fetchSelectableGuestRoom,
  otherDate,
  readOnly,
  setOtherDate,
  setAssignPanelEditMode,
  disabled,
}) => {
  const { t } = useTranslation()
  const [calendarFocused, setCalendarFocused] = useState<boolean>(false)

  const onDateChange = (date: moment.Moment) => {
    switch (type) {
      case 'select':
        // setDate in this case is setRoomManagerTopSelectDate
        setDate(date)
        break
      case 'checkin':
        // setDate in this case is setReservationCheckinDate
        setDate(date)
        // For check-ins, also update the top date
        setRoomManagerTopSelectDate(date)
        // Also convert checkout date to next date of checkin
        setOtherDate!(moment(date).add(1, 'days'))
        // Update selectable dates
        fetchSelectableGuestRoom!(date, moment(date).add(1, 'days')!)
        setAssignPanelEditMode!(true)
        break
      case 'checkout':
        // setDate in this case is setReservationCheckoutDate
        // The check-in date must not exceed the check-out date, so issue an alert
        if (moment(otherDate!).format('YYYY-MM-DD') > moment(date).format('YYYY-MM-DD')) {
          window.alert(t('Please set the check-out date after the check-in date'))
          return
        }
        setDate(date)
        // Update selectable dates
        fetchSelectableGuestRoom!(otherDate!, date)
        setAssignPanelEditMode!(true)
        break
    }
  }
  return (
    <>
      <SingleDatePicker
        date={date}
        onDateChange={changeDate => onDateChange(changeDate!)}
        focused={calendarFocused}
        onFocusChange={v => setCalendarFocused(Boolean(v.focused))}
        numberOfMonths={1}
        isOutsideRange={() => false}
        displayFormat={type === 'select' ? t('MM-DD-YYYY') : t('MM-DD')}
        monthFormat={t('YYYY-MM')}
        id="date"
        hideKeyboardShortcutsPanel={true}
        customInputIcon={<img src={require('@/static/images/calendar_icon.svg')} width={14} />}
        readOnly={readOnly}
        navPrev={
          <div className="monthPrev">
            <img
              alt="arrow"
              src={require('@/static/images/icon_caalender_left.svg')}
              onMouseOver={e => (e.currentTarget.src = require('@/static/images/icon_caalender_left.svg'))}
              onMouseOut={e => (e.currentTarget.src = require('@/static/images/icon_caalender_left.svg'))}
            />
          </div>
        }
        disabled={disabled}
        navNext={
          <div className="monthNext">
            <img
              alt="arrow"
              src={require('@/static/images/icon_caalender_right.svg')}
              onMouseOver={e => (e.currentTarget.src = require('@/static/images/icon_caalender_right.svg'))}
              onMouseOut={e => (e.currentTarget.src = require('@/static/images/icon_caalender_right.svg'))}
            />
          </div>
        }
        renderCalendarDay={props => (
          <CalendarDay
            {...props}
            renderDayContents={day => (
              <div className="day">
                <span>{day?.format('D')}</span>
              </div>
            )}
          />
        )}
      />
    </>
  )
}
