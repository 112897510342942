import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms'
import { Controller, useForm } from 'react-hook-form'
import { InputField } from '@/components/molecules/input-field'

export type EditorVideoPopupForm = {
  url: string
}

type Props = {
  onSave: (form: EditorVideoPopupForm) => void
  onCancel: () => void
}

export const EditorVideoPopup: React.FC<Props> = ({ onSave, onCancel }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<EditorVideoPopupForm>()

  return (
    <div css={popupStyle}>
      <div className="input-area">
        <span className="input-label">{t('Video Link')}</span>
        <Controller
          name="url"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, name } }) => (
            <InputField marginBottom={0} placeholder="URL" value={value} name={name} handleChangeData={onChange} />
          )}
        />
      </div>
      <div className="button-area">
        <Button buttonType={3} width={97} height={28} fontSize={12} marginRight={12} onClick={() => onCancel()}>
          {t('Cancel')}
        </Button>
        <Button buttonType={isValid ? 1 : 5} width={110} height={28} fontSize={12} onClick={handleSubmit(onSave)} isDisabled={!isValid}>
          {t('Video Embedding')}
        </Button>
      </div>
    </div>
  )
}

const popupStyle = css({
  width: 475,
  minWidth: 475,
  borderRadius: 5,
  background: '#ffffff',
  filter: 'drop-shadow(0px 3px 10px #00000029)',
  padding: 24,
  position: 'absolute',
  right: 'calc(-475px / 3 + 25px)',
  top: 45,
  '&::before': {
    content: "''",
    position: 'absolute',
    top: -25,
    left: 'calc(68% - 10px)',
    marginLeft: 4.1,
    border: '10px solid transparent',
    borderBottom: '19px solid #ffffff',
  },
  '.input-area': {
    display: 'flex',
    flexFlow: 'column',
    paddingBottom: 24,
    textAlign: 'left',
  },
  '.input-label': {
    paddingBottom: 12,
    lineHeight: 1,
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: 0.6,
    color: '#676767',
  },
  '.button-area': {
    display: 'flex',
    justifyContent: 'end',
  },
})
