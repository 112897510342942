type accompanyList = {
  name: string
  age: string
  nationality: string
}

type Room = {
  roomTypeId: string
  typeName: string
  roomNumber: string
}

export const dividedAccompany = (list: accompanyList[] = []) => {
  if (list.length === 0 || list[0].name === '' || list[0].name === 'undefined') {
    return '-'
  }
  return `${list.map(v => v.name).join('、')}`
}

export const dividedTypeName = (rooms: Room[] = []) => {
  if (rooms.length === 0 || rooms[0].typeName === '') {
    return '-'
  }
  return `${rooms.map(v => v.typeName).join('、')}`
}

export const dividedRoomNumber = (rooms: Room[] = []) => {
  if (rooms.length === 0 || rooms[0].roomNumber === '' || rooms[0].roomNumber === undefined) {
    return '-'
  }
  return `${rooms.map(v => v.roomNumber).join('、')}`
}
