import { pdf } from '@react-pdf/renderer'

export const pdfDownload = (element: React.ReactElement, fileName: string) => {
  pdf(element)
    .toBlob()
    .then(res => {
      const downloadLink = document.createElement('a')
      const blob = new Blob([res], { type: 'application/pdf' })

      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = fileName

      downloadLink.click()

      URL.revokeObjectURL(url)
    })
}

export const b64DecodeUnicode = str => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
}
