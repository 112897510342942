import React, { useState, useEffect, useMemo } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { ToolBar } from '@/components/molecules/sales-manager/dashboard/tool-bar'
import { Graph } from '@/components/molecules/sales-manager/dashboard/graph'
import { SummaryBlock } from '@/components/molecules/sales-manager/dashboard/summary-block'
import { getSalesManagerDashboard } from '@/apis/aipass'
import { DashboardSalesType, DashboardType } from '@/models/sales-manager/dashboard'
import dayjs, { Dayjs } from 'dayjs'
import { useHistory } from 'react-router-dom'
import { Hint } from '@/components/atoms/hint'

type Props = {
  initialViewMonth: Dayjs
  initialViewMode: DashboardType
}

export const SalesDashboard: React.FC<Props> = ({ initialViewMonth, initialViewMode }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [dashboardSales, setDashboardSales] = useState<DashboardSalesType | undefined>()
  const [selectedMonth, setSelectedMonth] = useState<Dayjs>(initialViewMonth)
  const [selectedMode, setSelectedMode] = useState<DashboardType>(initialViewMode)

  const chartData = useMemo(() => {
    return dashboardSales?.details.map(summary => ({
      ...summary,
      price: Math.round((summary.price / 10000) * 10) / 10,
      salesDate: dayjs(summary.salesDate).format('D日'),
      salesMonth: dayjs(summary.salesDate).format('YYYY/M'),
    }))
  }, [dashboardSales])

  useEffect(() => {
    setDashboardSales(undefined)
  }, [selectedMode])

  useEffect(() => {
    const searchParams = { mode: selectedMode, targetDay: selectedMonth }
    getSalesManagerDashboard(searchParams).then(res => setDashboardSales(res))
    history.replace({
      pathname: '',
      search: new URLSearchParams({
        mode: searchParams.mode,
        targetDay: searchParams.targetDay.format('YYYY-MM-DD'),
      }).toString(),
    })
  }, [selectedMode, selectedMonth])

  return (
    <>
      <div style={{ padding: '24px 32px 32px 32px' }}>
        <ToolBar
          openSalesManagerId={dashboardSales?.openSalesManagerId}
          month={selectedMonth}
          mode={selectedMode}
          onChangeMode={setSelectedMode}
          onChangeMonth={setSelectedMonth}
        />
        <Graph mode={selectedMode} details={chartData || []} />
        <SummaryBlock mode={selectedMode} summary={dashboardSales?.summary} diff={dashboardSales?.diff} per={dashboardSales?.per} />
      </div>
      <div css={detailTableStyle}>
        <div className="header">
          <div css={tableRowStyle} className="row">
            <div>{t('Date')}</div>
            <div>
              {t('Sales')}
              <Hint
                customCss={css({
                  paddingLeft: 8,
                  '.help-popup': { left: 'calc(8px + 8px + 14px)' },
                })}
              >
                <div>{t('Actual sales are counted before the previous day and booking sales are counted after the day of the event.')}</div>
              </Hint>
            </div>
            <div>{t('Discount')}</div>
            <div>{t('Number of rooms sold')}</div>
            <div>{t('Number of guests')}</div>
            <div>{t('OCC')}</div>
            <div>{t('ADR')}</div>
            <div>{t('RevPAR')}</div>
            <div>
              {t('Closed')}
              {selectedMode === DashboardType.Monthly && (
                <Hint
                  customCss={css({
                    paddingLeft: 7,
                    '.help-popup': { left: -220 },
                  })}
                >
                  <div>{t('A check mark will appear if the last day of the month is closed.')}</div>
                </Hint>
              )}
            </div>
          </div>
        </div>
        <div className="body">
          {dashboardSales?.details.map((detail, i) => (
            <div css={tableRowStyle} className="row" key={i}>
              {selectedMode === DashboardType.Daily ? (
                <div
                  style={{
                    display: 'flex',
                    padding: 0,
                    textAlign: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '70%' }}>
                    {detail.salesManagerId ? (
                      <a href={`/sales-manager/detail?id=${detail.salesManagerId}&mode=daily`}>
                        {dayjs(detail.salesDate).format(t('M-D'))}
                      </a>
                    ) : (
                      dayjs(detail.salesDate).format(t('M-D'))
                    )}
                  </div>
                  <div className="vertical-divide"></div>
                  <div style={{ width: '30%' }}>{dayjs(detail.salesDate).format('ddd')}</div>
                </div>
              ) : (
                <div style={{ textAlign: 'left' }}>
                  {detail.salesManagerId ? (
                    <a href={`/sales-manager/detail?id=${detail.salesManagerId}&mode=month`}>
                      {dayjs(detail.salesDate).format(t('YYYY-M'))}
                    </a>
                  ) : (
                    dayjs(detail.salesDate).format(t('YYYY-M'))
                  )}
                </div>
              )}
              <div>¥{detail.price.toLocaleString()}</div>
              <div>¥{detail.discount.toLocaleString()}</div>
              <div>{detail.soldRoomsCount.toLocaleString()}室</div>
              <div>{detail.guestCount.toLocaleString()}人</div>
              <div>{detail.OCCRate}%</div>
              <div>¥{detail.ADR.toLocaleString()}</div>
              <div>¥{detail.revPAR.toLocaleString()}</div>
              <div>{detail.isConfirmed && <img src={require('@/static/images/icon_checkin.svg')} />}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

const tableRowStyle = css({
  display: 'flex',
  alignItems: 'center',
  borderTop: '1px solid #F2F2F2',
  ':last-of-type': { borderBottom: '1px solid #F2F2F2' },
  '> div': {
    padding: '0 16px',
    width: 'calc(100% / 8)',
    borderLeft: '1px solid #F2F2F2',
    height: '100%',
    alignContent: 'center',
  },
  '> div:first-of-type': { minWidth: 128, maxWidth: 128 },
  '> div:last-of-type': { minWidth: 82, maxWidth: 82, borderRight: '1px solid #F2F2F2' },
})

const detailTableStyle = css({
  width: '100%',
  a: { color: '#F2A40B' },
  '.header': {
    background: '#f5f5f5',
    '.row': {
      display: 'flex',
      alignItems: 'center',
      height: 32,
      '> div': {
        color: '#676767',
        fontSize: 12,
        fontWeight: 'bold',
        letterSpacing: 1.2,
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  '.body': {
    '.row': {
      height: 46,
      '> div': {
        color: '#272727',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.7,
        textAlign: 'right',
      },
      '> div:last-of-type': { textAlign: 'center' },
    },
  },
  '.vertical-divide': {
    height: '100%',
    border: '0.5px solid #F2F2F2',
  },
  '.help-popup': {
    width: 220,
    padding: '8px 16px',
    lineHeight: '21px',
    top: 'calc((58px - 14px) / -2)',
  },
})
