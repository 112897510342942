import { t } from 'i18next'

export const DefaultThemeType = {
  White: 'white',
  Black: 'black',
} as const
export type DefaultThemeType = (typeof DefaultThemeType)[keyof typeof DefaultThemeType]

export const themeTypeLabel: Record<DefaultThemeType, string> = {
  [DefaultThemeType.White]: t('WhiteBase'),
  [DefaultThemeType.Black]: t('BlackBase'),
}

export const ButtonRoleType = {
  Primary: 'primary',
  Secondary: 'secondary',
} as const
export type ButtonRoleType = (typeof ButtonRoleType)[keyof typeof ButtonRoleType]

export type DesignType = {
  theme: ThemeType
  background: BackgroundType
  button: ButtonType[]
  form: FormType
  text: TextType
  icon: IconType
}
export type ThemeType = {
  brandColor: string
  type: DefaultThemeType
}
export type BackgroundType = {
  backgroundColor: string
  cardColor: string
}
export type ButtonType = {
  type: ButtonRoleType
  backgroundColor: string
  borderColor: string
  textColor: string
  borderRadius: string
}
export type FormType = {
  backgroundColor: string
  borderColor: string
  textColor: string
  borderRadius: string
}
export type TextType = {
  fontFamily: string
  headlineColor: string
  textColor: string
  linkColor: string
}
export type IconType = {
  color: string
}
