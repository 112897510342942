import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'

// constants
import { listContentsWrapperStyle } from '@/constants/layout'

// components
import { CsvButton } from '@/components/pages/setting/around-spot/_around-spot/csv-button'

type ContainerProps = {
  aroundSpot: any
  categoryName: (any) => any
  csvData: any
  parseOptions: any
  onFileLoad: (data) => void
}

type Props = {}

export const FacilityAroundSpotList: React.FC<ContainerProps & Props> = ({
  aroundSpot,
  categoryName,
  csvData,
  parseOptions,
  onFileLoad,
}) => {
  const { t } = useTranslation()
  const windows = useWindowSize()
  return (
    <div
      css={mainContainerStyle}
      style={{
        minHeight: windows.height - 103,
      }}
    >
      <div css={mainHeaderContainerStyle}>
        <div css={detailsHeaderTitleStyle}>{t('Tourist information list')}</div>
      </div>
      <CsvButton csvData={csvData} parseOptions={parseOptions} onFileLoad={onFileLoad} />
      <div css={customeListContainerStyle}>
        <ul css={customListHeaderStyle}>
          <li css={headIdStyle}>ID</li>
          <li css={headFacilityNameStyle}>{t('Name of facility')}</li>
          <li css={headCategoryStyle}>{t('Category')}</li>
          <li css={headPhoneStyle}>{t('Phone')}</li>
          <li css={headAddressStyle}>{t('Address')}</li>
          <li css={headCommentStyle}>{t('Comment')}</li>
        </ul>
        <div
          css={listContentsWrapperStyle}
          style={{
            maxHeight: windows.height - 235,
          }}
        >
          <div css={customListContentsStyle}>
            {aroundSpot &&
              aroundSpot.map((v: any, index) => {
                return (
                  <div key={index} css={customListItemUnreadStyle} onClick={() => {}}>
                    <div css={dataIdStyle}>{v.spotId}</div>
                    <div css={dataFacilityNameStyle}>
                      <div css={facilityNameStyle}>{v.facilityName}</div>
                    </div>
                    <div css={dataCategoryStyle}>{categoryName(v.category)}</div>
                    <div css={dataPhoneStyle}>{v.telephone}</div>
                    <div css={dataAddressStyle}>
                      <div css={addressStyle}>{v.address}</div>
                    </div>
                    <div css={dataCommentStyle}>
                      <div css={commentStyle}>{v.comment}</div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
// list head
const headIdStyle = css({
  display: 'flex',
  alignItems: 'left',
  width: 49,
  minWidth: 49,
  color: '#676767',
  fontSize: 12,
  fontWeight: 'normal',
  whiteSpace: 'nowrap',
})

const headFacilityNameStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: 182,
  minWidth: 182,
  color: '#676767',
  fontSize: 12,
  fontWeight: 'normal',
  whiteSpace: 'nowrap',
})

const headCategoryStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: 73,
  minWidth: 73,
  color: '#676767',
  fontSize: 12,
  fontWeight: 'normal',
  whiteSpace: 'nowrap',
})

const headPhoneStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: 114,
  minWidth: 114,
  color: '#676767',
  fontSize: 12,
  fontWeight: 'normal',
  whiteSpace: 'nowrap',
})

const headAddressStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: 250,
  minWidth: 250,
  color: '#676767',
  fontSize: 12,
  fontWeight: 'normal',
  whiteSpace: 'nowrap',
})

const headCommentStyle = css({
  display: 'flex',
  alignItems: 'center',
  color: '#676767',
  fontSize: 12,
  fontWeight: 'normal',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
})

const customeListContainerStyle = css({
  borderRadius: 5,
  borderTop: '1px solid #f2f2f2',
})

const customListHeaderStyle = css({
  textAlign: 'left',
  color: '#676767',
  fontSize: 12,
  display: 'flex',
  backgroundColor: '#FFF',
  borderBottom: '1px solid #f2f2f2',
  background: '#F5F5F5',
  padding: '9.5px 32px',
  overflow: 'hidden',
  fontWeight: 'bold',
})

// list data
const dataIdStyle = css(headIdStyle, {
  fontSize: 14,
  fontWeight: 'normal',
  color: '#272727',
})

const dataFacilityNameStyle = css(headFacilityNameStyle, {})
const facilityNameStyle = css({
  fontSize: 14,
  fontWeight: 'normal',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: '#272727',
})

const dataCategoryStyle = css(headCategoryStyle, {
  fontSize: 14,
  fontWeight: 'normal',
  color: '#272727',
})

const dataPhoneStyle = css(headPhoneStyle, {
  fontSize: 14,
  fontWeight: 'normal',
  color: '#272727',
})

const dataAddressStyle = css(headAddressStyle, {})

const addressStyle = css({
  fontSize: 14,
  fontWeight: 'normal',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: '#272727',
})

const dataCommentStyle = css(headCommentStyle, {})

const commentStyle = css({
  fontSize: 14,
  fontWeight: 'normal',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: '#272727',
})

const mainContainerStyle = css({
  width: '100%',
  borderRadius: 5,
  boxShadow: '0px 0px 6px #0000001a',
  background: '#FFF',
})

const mainHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const detailsHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
})

const customListContentsStyle = css({
  fontSize: 14,
})

const customListItemUnreadStyle = css({
  padding: '17.5px 32px',
  cursor: 'pointer',
  display: 'flex',
  borderBottom: '1px solid #f2f2f2',
  fontWeight: 'bold',
  width: '100%',
  alignItems: 'center',
  '&:hover': {
    boxShadow: '0px 3px 6px #0000000f',
    position: 'relative',
  },
})
