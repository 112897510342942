import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { InputField } from '@/components/molecules/input-field'
import { fetchAdminAccount, updateMobileRequestEmailOrder } from '@/apis/aipass'
import { useTranslation } from 'react-i18next'
import { Dayjs } from 'dayjs'
import { NotificationInputType } from '@/models/mobile-request'
import { LoadingFull } from '@/components/molecules/loading-full'
import { useForm, Controller } from 'react-hook-form'

type Props = {
  onChange: () => void
  listenCancel: Dayjs | undefined
  listenSave: Dayjs | undefined
}

export const NotificationTable: React.FC<Props> = ({ onChange, listenCancel, listenSave }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const emailPattern = {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: t('Email invalid'),
  }

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<NotificationInputType>({
    defaultValues: { email_order: '', email_order_another: '' },
  })

  const saveNotificationMail = async (formValues: NotificationInputType) => {
    try {
      setIsLoading(true)
      await updateMobileRequestEmailOrder(formValues)
    } catch {
      alert(t('Communication failed'))
    } finally {
      setIsLoading(false)
    }
  }

  const callFetchAdminAccount = async () => {
    try {
      setIsLoading(true)
      const res = await fetchAdminAccount()
      reset({
        email_order: res?.hotel?.emailOrder || '',
        email_order_another: res?.hotel?.emailOrderAnother || '',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (listenCancel) callFetchAdminAccount()
  }, [listenCancel])

  useEffect(() => {
    if (listenSave) handleSubmit(saveNotificationMail)()
  }, [listenSave])

  useEffect(() => {
    callFetchAdminAccount()
  }, [])

  return (
    <>
      <div css={contentStyle}>
        <div css={inputTitleTextStyle}>{t('Email address')}</div>

        <div css={inputAreaStyle}>
          <div css={inputLabelTextStyle}>1.</div>
          <Controller
            name="email_order"
            control={control}
            rules={{ pattern: emailPattern }}
            render={({ field }) => (
              <InputField
                marginBottom={0}
                width="427px"
                placeholder="aipass@aipass.inc"
                value={field.value}
                handleChangeData={e => {
                  field.onChange(e)
                  onChange()
                }}
                error={errors.email_order?.message}
              />
            )}
          />
        </div>

        <div css={inputAreaStyle}>
          <div css={inputLabelTextStyle}>2.</div>

          <Controller
            name="email_order_another"
            control={control}
            rules={{ pattern: emailPattern }}
            render={({ field }) => (
              <InputField
                marginBottom={0}
                width="427px"
                placeholder="aipass@aipass.inc"
                value={field.value}
                handleChangeData={e => {
                  field.onChange(e)
                  onChange()
                }}
                error={errors.email_order_another?.message}
              />
            )}
          />
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </>
  )
}

const contentStyle = css({
  padding: 32,
})

const inputAreaStyle = css({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: 16,
})

const inputTitleTextStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '1.2px',
  color: '#676767',
  paddingBottom: 16,
})

const inputLabelTextStyle = css({
  paddingRight: 16,
  color: '#272727',
  fontSize: 14,
})
