import { selfCheckinFetchGuestRoomAssign } from '@/apis/aipass'

export type RoomInformationType = {
  reservationID: string
  roomData: {
    typeNameJa: string
    roomNumber: string
    keyNumber: string
    guestRoomAssignId: string
  }
}

export const useAssignKey = () => {
  const fetchRoomKey = async ({ hotelId, checkinId, reservationIds }: { hotelId: string; checkinId: string; reservationIds: string[] }) => {
    const roomInfoRes = await selfCheckinFetchGuestRoomAssign({
      hotelId,
      checkinId,
    })
    const flatRoomInfo: any = Object.values(roomInfoRes.rooms).flat()

    const roomInformation: RoomInformationType[] = []

    reservationIds.forEach(reservationId => {
      const roomsByReserId = flatRoomInfo.filter(r => r.reservationId === reservationId && r.keyNumber && r.keyNumber !== '-')
      if (!roomsByReserId.length) {
        return
      }
      const roomData = roomsByReserId.map(i => {
        return {
          typeNameJa: i.typeNameJa,
          roomNumber: i.roomNumber,
          keyNumber: i.keyNumber,
          guestRoomAssignId: i.guestRoomAssignId,
        }
      })
      const roomInfoByReser = {
        reservationID: reservationId,
        roomData,
      }
      roomInformation.push(roomInfoByReser)
    })
    return roomInformation
  }

  return { fetchRoomKey }
}
