import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { TextareaField } from '@/components/molecules/textarea-field'
import { css } from '@emotion/core'
import { InputField } from '@/components/molecules/input-field'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { SalesHourInputType, SalesHourType } from '@/models/mobile-request'
import { postMobileRequestSalesHours, putMobileRequestSalesHours } from '@/apis/aipass'
import { Select } from 'antd'
import dayjs from 'dayjs'
import { useForm, Controller, useFieldArray } from 'react-hook-form'

type Props = {
  editing?: SalesHourType
  nextOrder: number
  onSave: () => void
  onClose: () => void
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateSaleHourModal = ({ editing, nextOrder, onSave, onClose, setLoading }: Props) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<SalesHourInputType>({
    defaultValues: {
      name: editing?.name || '',
      hours: editing?.hours || [],
      order: editing?.order || nextOrder,
    },
  })
  const hourInput = useFieldArray({
    rules: { required: true },
    control,
    name: 'hours',
  })

  const save = async (formValues: SalesHourInputType) => {
    if (isSubmitting) return
    try {
      if (editing) {
        await putMobileRequestSalesHours(editing.id, formValues)
      } else {
        await postMobileRequestSalesHours(formValues)
      }
      onSave()
      onClose()
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const computedSaleTimeOptions = (): string[] => {
    const options: string[] = []
    for (let i = 0; i < 48; i++) {
      options.push(
        dayjs()
          .startOf('d')
          .add(30 * i, 'm')
          .format('HH:mm'),
      )
    }
    return options
  }

  return (
    <Modal customCss={modalStyle}>
      <ModalHeader customCss={modalHeaderStyle}>{t('MobileRequest.Create sales hour')}</ModalHeader>
      <ModalBody customCss={modalBodyStyle}>
        <div css={bodyContentStyle}>
          <div>
            <div css={inputTitleTextStyle}>
              {t('Title')}
              <div css={requireLabelTextStyle}>※</div>
            </div>
            <div css={[textareaStyle, { textarea: { height: 67 } }]}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, name } }) => (
                  <TextareaField marginBottom={0} placeholder={t('Title name')} value={value} name={name} handleChangeData={onChange} />
                )}
              />
            </div>
          </div>

          <div css={inputAreaStyle}>
            <div css={inputTitleTextStyle}>
              {t('MobileRequest.Sales hours')}
              <div css={requireLabelTextStyle}>※</div>
            </div>
            {hourInput.fields.map((hour, index) => (
              <div css={saleTimeAreaStyle} key={hour.id}>
                <Controller
                  name={`hours.${index}.from`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      css={selectBoxStyle}
                      suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                      value={value}
                      onChange={onChange}
                    >
                      {computedSaleTimeOptions().map(time => (
                        <Select.Option key={`from.${index}.${time}`} value={time}>
                          {time}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
                <p style={{ fontSize: 12, color: '#676767', padding: '0 8px' }}>〜</p>
                <Controller
                  name={`hours.${index}.to`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      css={selectBoxStyle}
                      suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                      value={value}
                      onChange={onChange}
                    >
                      {computedSaleTimeOptions().map(time => (
                        <Select.Option key={`to.${index}.${time}`} value={time}>
                          {time}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />

                <div>
                  <img
                    style={{ cursor: 'pointer' }}
                    src={require('@/static/images/mobile_request/delete_orange.svg')}
                    alt=""
                    onClick={() => hourInput.remove(index)}
                  />
                </div>
              </div>
            ))}
            <div
              css={addItemStyle}
              onClick={() =>
                hourInput.append({
                  from: '00:00',
                  to: '00:00',
                })
              }
            >
              <img src={require('@/static/images/plus_yellow.svg')} alt={t('Add icon yellow')} />
              <p>{t('MobileRequest.Add sales hours')}</p>
            </div>
          </div>

          <div css={inputAreaStyle}>
            <div style={{ paddingRight: 8, width: '50%' }}>
              <div css={inputTitleTextStyle}>{t('Display order')}</div>
              <Controller
                name="order"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <InputField marginBottom={0} placeholder="00000" type="number" value={value} name={name} handleChangeData={onChange} />
                )}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter customCss={modalFooterStyle}>
        <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(save)} isDisabled={!isValid}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = css({
  top: '5%',
  left: 'calc(50% - 300px)',
  minHeight: 565,
  maxHeight: 565,
  minWidth: 600,
  width: 600,
})

const modalHeaderStyle = css({
  height: '60px',
  boxShadow: 'none',
  color: '#272727',
})

const modalBodyStyle = css({
  padding: 32,
  height: 530,
})

const modalFooterStyle = css({
  height: '60px',
  display: 'flex',
})

const bodyContentStyle = css({
  padding: '32px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #CCCCCC',
  borderRadius: '5px',
  width: '100%',
  minHeight: '381px',
})

const textareaStyle = css({
  textarea: {
    marginBottom: 0,
    padding: '16px',
    lineHeight: '16px',
    letterSpacing: '1.4px',
    ':focus': {
      padding: '15px',
    },
  },
})

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputAreaStyle = css({
  paddingTop: 24,
})

const saleTimeAreaStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '50%',
  paddingBottom: '8px',
})

const addItemStyle = css({
  marginTop: 8,
  width: '50%',
  cursor: 'pointer',
  display: 'flex',
  height: 36,
  backgroundColor: '#FAFAFA',
  justifyContent: 'center',
  border: '1px dashed #CCCCCC',
  flex: 1,
  p: {
    lineHeight: '36px',
    color: '#F2A40B',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 10.8,
  },
  img: {
    width: 20,
  },
  ':hover': {
    background: '#F2F2F2',
  },
})

const selectBoxStyle = css({
  width: 92,
  '> div': {
    borderRadius: '16px',
    border: '1px solid #CCCCCC',
    boxShadow: 'none !important',
    borderColor: '#CCCCCC !important',
  },
})
