import React, { useEffect, useState } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { dashboardPageContainerStyle } from '@/constants/layout'
import { DetailContentForm, formatFormData } from '@/components/organisms/settings/mail-delivery/detail-content-form'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormFooter } from '@/components/organisms/settings/mail-delivery/form-footer'
import { DateExecution, MailDeliveryContentInput, isReservation } from '@/models/mail-delivery/mail-delivery-content'
import { fetchReservationChannelCode, saveMailDeliveryContent, sendTestMailDeliveryContent } from '@/apis/aipass'
import { errorHandler } from '@/libs/errors'
import { useHistory } from 'react-router-dom'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { SentTestMailDialog } from '@/components/organisms/settings/mail-delivery/sent-test-mail-dialog'
import { ChannelCodeType } from '@/models/reservation-channel-code'

export const MailDeliveryContentNew: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [sentEmail, setSentEmail] = useState('')
  const [channelCodes, setChannelCodes] = useState<ChannelCodeType[]>([])

  const { t } = useTranslation()

  const history = useHistory()

  const useFormReturn = useForm<MailDeliveryContentInput>({
    defaultValues: {
      deliveryDate: {
        dateExecution: DateExecution.Ago,
      },
      reservationStatuses: [
        ApprovedStatusType.Reservation,
        ApprovedStatusType.Checkin,
        ApprovedStatusType.Stay,
        ApprovedStatusType.Checkout,
      ],
    },
  })
  const { handleSubmit, setValue } = useFormReturn

  const onSubmit: SubmitHandler<MailDeliveryContentInput> = async submitData => {
    const formattedData = formatFormData(submitData)

    try {
      setLoading(true)
      const result = await saveMailDeliveryContent(formattedData)

      if (isReservation(submitData.status)) {
        return history.push({ pathname: `/setting/mail-delivery` })
      } else {
        return history.push({ pathname: `/setting/mail-delivery-content/${result.id}` })
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const onSendTest = async (email: string) => {
    try {
      setLoading(true)
      await sendTestMailDeliveryContent(email, useFormReturn.getValues())
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
      setSentEmail(email)
    }
  }

  const _fetchReservationChannelCode = () => {
    fetchReservationChannelCode().then(res => {
      if (res) {
        const channelCodes = res.channelCodes.map(({ id }) => id)
        setValue('channelCodes', ['-', ...channelCodes])
        setChannelCodes(channelCodes)
      }
    })
  }

  useEffect(() => {
    _fetchReservationChannelCode()
  }, [])

  return (
    <>
      <SettingsLayout
        loading={loading}
        footerContent={
          <FormFooter
            onSave={handleSubmit(onSubmit)}
            onCancel={() => history.push({ pathname: `/setting/mail-delivery` })}
            onSendTest={onSendTest}
            useFormReturn={useFormReturn}
          />
        }
      >
        <div css={dashboardPageContainerStyle}>
          <div css={headerSettingStaffStyle}>
            <div css={headerDetailStyle}>{t('Delivery Details')}</div>
          </div>

          <DetailContentForm useFormReturn={useFormReturn} channelCodes={channelCodes} />
        </div>
      </SettingsLayout>
      <SentTestMailDialog email={sentEmail} onClose={() => setSentEmail('')} />
    </>
  )
}

const headerSettingStaffStyle = css({
  height: '50px',
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const headerDetailStyle = css({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: '16px 0',
  color: '#272727',
})
