import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import { AgeFromDateString } from 'age-calculator'

// models
import { CustomerType } from '@/models/customer-manager/customer'
import { customerOccupation } from '@/libs/occupations'

type PersonInfoProps = {
  customer: CustomerType | undefined
  onClickEditCustomerInfo: () => void
}

export const PersonInfo: React.FC<PersonInfoProps> = ({ customer, onClickEditCustomerInfo = () => {} }) => {
  const { t } = useTranslation()

  let displayAddress = ''
  if (customer?.address) {
    displayAddress = customer?.address
  } else {
    displayAddress = '-'
  }

  return (
    <>
      <div css={listImageSectionStyle}>
        <img css={imageStyle} src={customer?.picture ? customer?.picture : require('@/static/images/user.svg')} />
      </div>

      <div css={listNameSectionStyle}>
        <ruby>{customer?.nameKana ? customer?.nameKana : '-'}</ruby>
        <div css={nameStyle}>{customer?.name ? customer?.name : '-'}</div>
        <div css={userAgeStyle}>
          <div>{customer?.gender ? (customer?.gender === 'M' ? t('Male') : t('Female')) : '-'} / </div>
          <div>{customer?.birthDate ? `${new AgeFromDateString(customer?.birthDate).age} ${t('Age')}` : '-'}</div>
        </div>
      </div>

      <div css={listSectionStyle}>
        <div css={itemWrapperStyle}>
          <img src={require('@/static/images/birthday.svg')} />
          <p>{customer?.birthDate ? dayjs(customer?.birthDate).format('YYYY/MM/DD') : '-'}</p>
        </div>
        <div css={itemWrapperStyle}>
          <img src={require('@/static/images/nationality.svg')} />
          <p>
            {customer?.nationality ? (customer?.nationality === 'JPN' ? t('Japanese nationality') : t('Foreign nationality')) : '-'} /{' '}
            {customer?.nationality ? (customer?.nationality === 'JPN' ? t('Japan') : customer?.nationality) : '-'}
          </p>
        </div>
        <div css={itemWrapperStyle}>
          <img src={require('@/static/images/access.svg')} />
          <p>{displayAddress}</p>
        </div>
      </div>

      <div css={listSectionStyle}>
        <div css={itemWrapperStyle}>
          <img src={require('@/static/images/phone.svg')} />
          <p>{customer?.telephone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1$2$3')}</p>
        </div>
        <div css={itemWrapperStyle}>
          <img src={require('@/static/images/mail.svg')} />
          <p>{customer?.email ? customer?.email : '-'}</p>
        </div>
        <div css={itemWrapperStyle}>
          <img src={require('@/static/images/business.svg')} />
          <p>{customerOccupation(customer)}</p>
        </div>
      </div>

      <div css={editIconStyle} onClick={onClickEditCustomerInfo}></div>
    </>
  )
}

const sectionStyle = css({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#FFF',
})

const listImageSectionStyle = css(sectionStyle, {
  padding: 0,
})

const listNameSectionStyle = css(sectionStyle, {
  ruby: {
    fontSize: 12,
    lineHeight: '29px',
  },
  width: '20%',
})

const listSectionStyle = css(sectionStyle, {
  borderLeft: '1px solid #F2F2F2',
  width: '30%',
})

const editIconStyle = css({
  display: 'block',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  boxShadow: '0px 2px 6px #0000003D',
  borderRadius: '50px',
  width: '30px',
  height: '30px',
  backgroundImage: `url(${require('@/static/images/edit.svg')})`,
  cursor: 'pointer',
})

const textStyle = css({
  color: '#272727',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const itemWrapperStyle = css(textStyle, {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 14,
  padding: '0 0 0 10px',
  img: {
    marginRight: 16,
    width: 18,
  },
  p: {
    fontSize: 14,
    height: 20,
    lineHeight: '21px',
  },
  span: {
    color: '#F2A40B',
  },
  ':nth-child(3n)': {
    marginBottom: 0,
  },
})

const imageStyle = css({
  margin: '0 auto',
  display: 'block',
  width: 58,
  borderRadius: '50%',
})

const nameStyle = css(textStyle, {
  // textAlign: 'center',
  fontWeight: 'bold',
  fontSize: 18,
})

const userAgeStyle = css({
  display: 'flex',
  marginTop: 12,
  color: '#676767',
  fontSize: 12,
})
