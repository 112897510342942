import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

type Props = {
  label: string
  onAdd: () => void
}

export const AddFormFieldButton: React.FC<Props> = ({ onAdd, label }) => {
  const { t } = useTranslation()
  return (
    <div css={addItemStyle} onClick={onAdd}>
      <img src={require('@/static/images/plus_yellow.svg')} alt={t('Add icon yellow')} />
      <p>{label}</p>
    </div>
  )
}

const addItemStyle = css({
  marginTop: 8,
  cursor: 'pointer',
  display: 'flex',
  height: 36,
  backgroundColor: '#FAFAFA',
  justifyContent: 'center',
  border: '1px dashed #CCCCCC',
  flex: 1,
  p: {
    lineHeight: '36px',
    color: '#F2A40B',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 10.8,
  },
  img: {
    width: 20,
  },
  ':hover': {
    background: '#F2F2F2',
  },
})
