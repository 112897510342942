import { css } from '@emotion/core'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

type MobileHeaderProps = {
  isHome: boolean
  title?: string
  reload?: () => void
  goBack?: () => void
}

const MobileHeader = ({ isHome, title, reload, goBack }: MobileHeaderProps) => {
  const history = useHistory()

  useEffect(() => {
    if (!localStorage.getItem('idToken')) {
      history.replace({
        pathname: '/',
      })
    }
  }, [])

  return (
    <div css={isHome ? mobileHeaderHomeStyle : mobileHeaderNotHomeStyle}>
      {isHome ? (
        <img css={mobileHeaderLogoStyle} src={require('@/static/images/cleaning_manager/mobile/logo.svg')} alt="logo" />
      ) : (
        <>
          <img
            css={mobileBackIconStyle}
            src={require('@/static/images/cleaning_manager/mobile/icon_back.svg')}
            alt="back"
            onClick={goBack}
          />
          <div css={mobileHeaderTitleStyle}>{title}</div>
          {reload ? (
            <img
              css={mobileReloadIconStyle}
              src={require('@/static/images/cleaning_manager/icon_reload.svg')}
              alt="reload"
              onClick={() => reload()}
            />
          ) : (
            <p css={mobileReloadIconStyle}></p>
          )}
        </>
      )}
    </div>
  )
}

export default MobileHeader

const mobileHeaderStyle = css({
  height: '55px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 2px 3px #00000014',
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  top: 0,
})

const mobileHeaderHomeStyle = css(mobileHeaderStyle, {
  marginBottom: '16px',
})

const mobileHeaderNotHomeStyle = css(mobileHeaderStyle, {
  justifyContent: 'space-between',
})

const mobileHeaderLogoStyle = css({ height: '30px', width: '75px', marginLeft: '32px' })
const mobileHeaderIconStyle = css({ height: '30px', width: '30px' })
const mobileBackIconStyle = css(mobileHeaderIconStyle, { marginLeft: '17px' })
const mobileReloadIconStyle = css(mobileHeaderIconStyle, { marginRight: '17px' })
const mobileHeaderTitleStyle = css({
  fontFamily: 'Noto Sans JP',
  fontWeight: 'bold',
  fontSize: '16px',
  lineHeight: '59px',
  letterSpacing: '1.6px',
})
