import { t } from 'i18next'

export type GuestRoomAssignResponseType = {
  rooms: ({
    guestRoomAssignId: string | null
    assignGuestName?: string | null
    assignCheckinDate: string | null
    assignCheckoutDate: string | null
    checkinTime?: string | null
    lastCheckinDatetime?: string
    checkoutTime?: string | null
    guestRoomId: string | null
    roomNumber: string | null
    keyNumber: string | null
    roomTypeId: string | null
    typeNameJa: string | null
    typeNameEn: string | null
    reservationId: string | null
    reservationUserName: string | null
    reservationGuestName?: string | null
    salesOfficeCompanyCodeId?: string | null
    isNetSales?: number | null
    reservationCheckinDate?: string | null
    reservationCheckoutDate?: string | null
    checkinId?: string | null
    isRepresentative?: number | null
    accessGuestId?: string | null
    issueType?: string | null
  } & PaxFullObject)[]
}

export type PaxFullObject = {
  paxChildA?: string | number
  paxChildB?: string | number
  paxChildC?: string | number
  paxChildD?: string | number
  paxChildE?: string | number
  paxChildF?: string | number
  paxChildOther?: string | number
  paxFemale?: string | number
  paxMale?: string | number
  pax?: string | number
}

export const GuestRoomAssignType = {
  all: t('All'),
  assigned: t('Assigned'),
  notAssigned: t('Not assigned'),
}
