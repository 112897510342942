import { useEffect, useState } from 'react'

interface VisualViewport {
  height: number
  offsetTop: number
}

const getVisualViewport = (): VisualViewport => ({
  height: window.visualViewport?.height || 0,
  offsetTop: window.visualViewport?.offsetTop || 0,
})

export const useVisualViewport = () => {
  const [visualViewport, setVisualViewport] = useState<VisualViewport>(getVisualViewport())

  useEffect(() => {
    const handleResize = () => {
      setVisualViewport(getVisualViewport())
    }
    window.visualViewport?.addEventListener('resize', handleResize)
 
    return () => window.visualViewport?.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      setVisualViewport(getVisualViewport())
    }
    window.visualViewport?.addEventListener('scroll', handleScroll)

    return () => window.visualViewport?.removeEventListener('scroll', handleScroll)
  }, [])

  return { visualViewport }
}
