import React, { useContext, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// apis
import { fetchSmaregiTransactions } from '@/apis/aipass'

// components
import { TransactionDetailModal } from '@/components/organisms/sales-manager/transaction-detail-modal'

// models
import { TransactionType } from '@/models/smaregi/transaction'
import { Button } from '@/components/atoms'
import { ButtonType } from '@/components/atoms/button'
import { TransactionLinkModal } from './transaction-link-modal'
import { LoaderContextCreator } from '@/contexts/loader'

type Props = {
  salesDate: string
  transactionId: string | undefined
}

export const Transaction: React.FC<Props> = ({ salesDate, transactionId }) => {
  const { t } = useTranslation()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const [isShowDetailModal, setIsShowDetailModal] = useState<boolean>(false)
  const [isShowLinkModal, setIsShowLinkModal] = useState<boolean>(false)
  const [isParsedTransactionId, setIsParsedTransactionId] = useState<boolean>(false)
  const [transactions, setTransactions] = useState<TransactionType[]>([])
  const [showTransactionDetail, setShowTransactionDetail] = useState<TransactionType>()

  const showDetailModal = (transaction: TransactionType): void => {
    setShowTransactionDetail(transaction)
    setIsShowDetailModal(true)
  }

  const showLinkModal = (transaction: TransactionType): void => {
    setShowTransactionDetail(transaction)
    setIsShowLinkModal(true)
  }

  const onCloseModal = async (isUpdated: boolean): Promise<void> => {
    setIsShowLinkModal(false)
    setIsShowDetailModal(false)
    transactionId = undefined
    if (isUpdated) {
      setIsLoading(true)
      await initializeFetching()
      setIsLoading(false)
    } else {
      initializeFetching()
    }
  }

  const initializeFetching = async () => {
    try {
      const transactions: TransactionType[] = await fetchSmaregiTransactions({ transactionAt: dayjs(salesDate) })
      setTransactions(transactions)
    } catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if (!salesDate) {
      return
    }
    setIsParsedTransactionId(false)
    initializeFetching()
  }, [salesDate])

  useEffect(() => {
    if (!transactionId || isParsedTransactionId) {
      return
    }
    const transactionPropToOpen = transactions.find(t => t.id === transactionId)
    if (transactionPropToOpen) {
      setIsParsedTransactionId(true)
      showDetailModal(transactionPropToOpen)
    }
  }, [transactionId, transactions])

  return (
    <div css={salesMainContainerStyle}>
      <div css={containerTitleStyle}>{t('Transaction')}</div>
      <div css={containerItemStyle}>
        <div css={itemIdStyle}>{t('Transaction ID')}</div>
        <div css={itemDateStyle}>{t('Registration date and time')}</div>
        <div css={itemSalesStyle}>{t('Sales')}</div>
        <div css={itemPaymentStyle}>{t('Payment')}</div>
        <div css={itemAmountCollectedStyle}>{t('Amount to be collected')}</div>
        <div css={itemRoomNumberStyle}>{t('Room number')}</div>
        <div css={itemActionStyle}></div>
      </div>
      <div>
        {transactions.map(transaction => {
          return (
            <div key={transaction.id} css={containerItemContentStyle} onClick={() => showDetailModal(transaction)}>
              <div css={itemIdStyle}>{transaction.id}</div>
              <div css={itemDateStyle}>{dayjs(transaction.transactionAt).format(t('MM-DD HH:mm'))}</div>
              <div css={itemSalesStyle}>¥{transaction.price.toLocaleString()}</div>
              <div css={itemPaymentStyle}>¥{transaction.paid.toLocaleString()}</div>
              <div css={itemAmountCollectedStyle}>¥{transaction.unpaid.toLocaleString()}</div>
              <div css={itemRoomNumberStyle}>{transaction.roomNumber || '-'}</div>
              <div css={itemActionStyle}>
                <Button
                  width={82}
                  height={32}
                  marginRight={0}
                  buttonType={ButtonType.Secondary}
                  customCss={css({ border: '1px solid #cccccc', fontSize: 12, letterSpacing: 0.6 })}
                  onClick={e => {
                    e.stopPropagation()
                    showLinkModal(transaction)
                  }}
                >
                  {t('Rooming')}
                </Button>
              </div>
            </div>
          )
        })}
        {transactions.length === 0 && (
          <div
            style={{
              padding: '16px 32px',
              color: '#272727',
              fontSize: 14,
            }}
          >
            {t('No transactions')}
          </div>
        )}
      </div>
      {isShowDetailModal && showTransactionDetail && (
        <TransactionDetailModal
          contractId={showTransactionDetail.contractId}
          transactionId={showTransactionDetail.id}
          transactionHeadId={showTransactionDetail.transactionHeadId}
          transactionSide={showTransactionDetail.transactionSide}
          onClose={onCloseModal}
        />
      )}
      {isShowLinkModal && showTransactionDetail && (
        <TransactionLinkModal
          transactionId={showTransactionDetail.id}
          transactionHeadId={showTransactionDetail.transactionHeadId}
          onClose={onCloseModal}
        />
      )}
    </div>
  )
}

const salesMainContainerStyle = css({
  width: '100%',
  background: '#FFFFFF',
  boxShadow: '0px 0px 6px #0000001A',
  border: '1px solid #F2F2F2',
  borderRadius: 5,
  marginRight: 16,
  marginBottom: 16,
})

const containerTitleStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: 50,
  fontSize: 18,
  fontWeight: 'bold',
  padding: '0 32px',
})

const containerItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#F5F5F5',
  height: 32,
  paddingLeft: 32,
  paddingRight: 32,
  width: '100%',

  fontSize: 12,
  letterSpacing: 1.2,
  color: '#676767',
  fontWeight: 'bold',
})

const containerItemContentStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: 48,
  paddingLeft: 32,
  paddingRight: 32,
  borderBottom: '1px solid #F2F2F2',

  fontSize: 14,
  letterSpacing: 0.7,
  color: '#272727',
  fontWeight: 'normal',
  cursor: 'pointer',
})

const itemIdStyle = css({ minWidth: 326 })
const itemDateStyle = css({ minWidth: 138 })
const itemSalesStyle = css({ minWidth: 126 })
const itemPaymentStyle = css({ minWidth: 126 })
const itemAmountCollectedStyle = css({ minWidth: 126 })
const itemRoomNumberStyle = css({ flexGrow: 1 })
const itemActionStyle = css({ minWidth: 82 })
