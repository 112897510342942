import React from 'react'
import { css } from '@emotion/core'

type Props = {}

export const Table: React.FC<Props> = ({ children }) => {
  return <table css={contentStyle}>{children}</table>
}

export const THead: React.FC = ({ children }) => {
  return <thead css={headerStyle}>{children}</thead>
}

export const TBody: React.FC = ({ children }) => {
  return <tbody css={bodyStyle}>{children}</tbody>
}

const contentStyle = css({
  width: '100%',
  height: 'max-content',
  flex: 1,
  tableLayout: 'fixed',
})

const headerStyle = css({
  height: 32,
  backgroundColor: '#F2F2F2',
  position: 'sticky',
  top: 0,
  left: 0,
  th: {
    color: '#676767',
    fontWeight: 'bold',
    fontSize: '12px',
    letterSpacing: '1.2px',
    verticalAlign: 'middle',
  },
  'th:first-of-type': {
    paddingLeft: '32px',
  },
  'th:last-child': {
    paddingRight: '32px',
  },
})
const bodyStyle = css({
  tr: {
    height: 62,
    borderBottom: '1px solid #F2F2F2',
    cursor: 'pointer',
  },
  td: {
    color: '#272727',
    fontWeight: 'bold',
    fontSize: '14px',
    letterSpacing: '1.4px',
    verticalAlign: 'middle',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingRight: 24,
  },
  'td:first-of-type': {
    paddingLeft: '32px',
  },
  'td:last-child': {
    paddingRight: '32px',
  },
})
