// apis
import { updateDeviceSendHistory } from '@/apis/aipass'
import { DeviceSendHistory } from '@/models/device/device-send-history'

export const sendPrinter = async (deviceSendHistory: DeviceSendHistory): Promise<void> => {
  const ipAddress = deviceSendHistory?.ipAddress
  const port = deviceSendHistory?.port
  const deviceId = deviceSendHistory?.deviceId
  const orders = deviceSendHistory?.orders
  const room = deviceSendHistory?.orders[0]?.room
  const guestName = deviceSendHistory?.orders[0]?.guestName
  const orderDate = new Date(deviceSendHistory?.orders[0]?.orderDate)?.toLocaleString()
  const ePosDev = new (window as any).epson.ePOSDevice()
  const createDeviceOptions = { crypto: false, buffer: false }
  const connectOption = { eposprint: true }
  let createdDevice

  console.log({ deviceSendHistory })
  try {
    const connectData = await new Promise(resolve => ePosDev.connect(ipAddress, port, resolve, connectOption))
    if (connectData !== 'OK' && connectData !== 'SSL_CONNECT_OK') {
      throw new Error(`connect error. message=${connectData as any}, ipAddress=${ipAddress}, port=${port}`)
    }

    const { deviceObj, code } = await new Promise<{ deviceObj: any; code: any }>(resolve =>
      ePosDev.createDevice(deviceId, ePosDev.DEVICE_TYPE_PRINTER, createDeviceOptions, (deviceObj, code) => resolve({ deviceObj, code })),
    )
    createdDevice = deviceObj

    if (deviceObj === null || code !== 'OK') {
      throw new Error(`createDevice error. code=${code as any}`)
    }

    deviceObj.timeout = 60000
    await new Promise<void>((resolve, reject) => {
      deviceObj.onreceive = response => (!response?.success ? reject(response?.code) : resolve())
      deviceObj.onerror = err => reject(`print error. status=${err?.status}`)
      print(deviceObj)
    })
  } catch (e) {
    console.error({ e })
    await printerSendErrorLog(e instanceof Error ? e.message : 'unknown error')
  } finally {
    if (createdDevice) {
      await new Promise<void>(resolve =>
        ePosDev.deleteDevice(createdDevice, () => {
          resolve()
        }),
      )
    }
    ePosDev?.disconnect()
  }

  function print(printer) {
    // create print object
    orders?.forEach(order => {
      printer.addTextLang('ja')
      printer.addTextSize(2, 2)
      printer.addText(`${room}号室\n`)
      printer.addTextSize(1, 1)
      printer.addFeedUnit(10)
      printer.addTextAlign(printer.ALIGN_LEFT)
      printer.addText(`${guestName}\t      ${orderDate}\n`)
      printer.addTextAlign(printer.ALIGN_LEFT)
      printer.addFeedUnit(30)
      printer.addTextSize(2, 2)
      printer.addText(`${order?.quantity}  `)
      printer.addTextSize(1, 2)
      printer.addText(`${order?.productName}\n`)
      printer.addFeedLine(1)
      printer.addCut(printer.CUT_FEED)
    })
    printer.send()
  }

  async function printerSendErrorLog(errorMessage: string) {
    // send log
    await updateDeviceSendHistory({
      deviceSendHistoryId: deviceSendHistory?.deviceSendHistoryId,
      errorMessage: errorMessage ?? null,
    })
  }
}
