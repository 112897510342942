import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms'
import { NoticeLoading } from '@/components/molecules/notice-loading'
import { UpdateGuestRoomStatusForCleaningManagerRequestType, fetchRoomDetail, updateGuestRoomStatusForCleaningManager } from '@/apis/aipass'
import { CleaningStatusType, ReservationType, RoomType, RoomUsageStatusType } from '@/constants/cleaning-manager'
import dayjs from 'dayjs'
import { TextareaField } from '@/components/molecules/textarea-field'
import { InputMultipleImage } from './input-multiple-image'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { hasCleaningMemo, isCleaningMemo } from '@/models/reservation/reservation-note'

type RoomDetailProps = {
  roomId: string
  setIsOpenRoomDetailModal: (arg0: boolean) => void
  selectedDate: any
  reloadRoomList: () => void
}

const RoomDetail = ({ roomId, setIsOpenRoomDetailModal, selectedDate, reloadRoomList }: RoomDetailProps) => {
  const { t } = useTranslation()
  const [room, setRoom] = useState<RoomType>()
  const [isLoading, setIsLoading] = useState(false)
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    register,
    formState: { isValid, isDirty },
  } = useForm<Required<UpdateGuestRoomStatusForCleaningManagerRequestType>>({
    defaultValues: {
      guestRoomId: roomId,
      dateOf: selectedDate,
      staffReport: '',
      instruction: '',
      cleaningStatus: CleaningStatusType.Unused,
      roomUsageStatus: RoomUsageStatusType.Unused,
      images: [],
    },
  })
  const watchInstruction = useWatch({ control, name: 'instruction' })
  const watchStaffReport = useWatch({ control, name: 'staffReport' })

  useEffect(() => {
    setIsLoading(true)
    fetchRoomDetail(roomId, selectedDate.format('YYYY-MM-DD'))
      .then(data => {
        setRoom(data)
      })
      .catch(e => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!room) {
      return
    }
    reset({
      ...getValues(),
      staffReport: room.staffReport,
      instruction: room.adminComment,
      cleaningStatus: room.cleaningStatus,
      roomUsageStatus: room.roomUsageStatus,
      images: room.photos,
    })
  }, [room])

  const save = (val: UpdateGuestRoomStatusForCleaningManagerRequestType) => {
    updateGuestRoomStatusForCleaningManager(val).then(() => {
      reloadRoomList()
    })
  }

  const computedChildPaxText = (roomPax: ReservationType['roomPax']): string => {
    return (
      [
        roomPax.childA ? t('cleaningManager.ChildA', { pax: roomPax.childA }) : undefined,
        roomPax.childB ? t('cleaningManager.ChildB', { pax: roomPax.childB }) : undefined,
        roomPax.childC ? t('cleaningManager.ChildC', { pax: roomPax.childC }) : undefined,
        roomPax.childD ? t('cleaningManager.ChildD', { pax: roomPax.childD }) : undefined,
        roomPax.childE ? t('cleaningManager.ChildE', { pax: roomPax.childE }) : undefined,
        roomPax.childF ? t('cleaningManager.ChildF', { pax: roomPax.childF }) : undefined,
        roomPax.childOther ? t('cleaningManager.ChildOther', { pax: roomPax.childOther }) : undefined,
      ]
        .filter(t => !!t)
        .join(t('cleaningManager.PaxSeparate')) || '-'
    )
  }

  const computedAdultPaxText = (roomPax: ReservationType['roomPax']): string => {
    return (
      [
        roomPax.male ? t('cleaningManager.Male', { pax: roomPax.male }) : undefined,
        roomPax.female ? t('cleaningManager.Female', { pax: roomPax.female }) : undefined,
      ]
        .filter(t => !!t)
        .join(t('cleaningManager.PaxSeparate')) || '-'
    )
  }

  const roomCheckinAt = room?.nextReservation?.roomCheckinAt ? dayjs(room?.nextReservation?.roomCheckinAt) : null
  const cleanedAt = room?.cleanedAt ? dayjs(room?.cleanedAt) : null

  return (
    <Modal customCss={modalStyle}>
      <ModalHeader customCss={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{room?.roomNumber}</div>
        <div css={subTextStyle} style={{ paddingTop: 0, paddingLeft: 12 }}>
          {t('cleaningManager.Cleaned')} : {cleanedAt ? cleanedAt.format(t('cleaningManager.MM-DD HH:mm')) : '-'}
        </div>
      </ModalHeader>
      <ModalBody customCss={modalBodyStyle}>
        {isLoading ? (
          <NoticeLoading isLoading={isLoading} />
        ) : (
          <div css={bodyContentStyle}>
            <div css={contentLeftBoxStyle}>
              <div style={{ paddingBottom: 24 }}>
                <div css={[areaTitleTextStyle, inlineContent]}>
                  {t('cleaningManager.Cleaning Instructions')}
                  {(watchInstruction ?? '').length > 0 && (
                    <img src={require('@/static/images/cleaning_manager/icon_instruction.svg')} alt="" style={{ paddingLeft: '4px' }} />
                  )}
                </div>
                <div css={textareaStyle}>
                  <Controller
                    control={control}
                    name="instruction"
                    render={({ field: { onChange, value, name } }) => (
                      <TextareaField
                        name={name}
                        value={value}
                        handleChangeData={onChange}
                        marginBottom={0}
                        placeholder=""
                        autoSize={true}
                      />
                    )}
                  />
                </div>
              </div>

              <div>
                <div css={[areaTitleTextStyle, { paddingBottom: 18 }]}>{t('cleaningManager.Guest Information')}</div>
                <div css={inlineContent} style={{ alignItems: 'flex-start' }}>
                  <img src={require('@/static/images/cleaning_manager/icon_date.svg')} alt="" css={iconStyle} />
                  <div css={reservationDetailStyle}>
                    <div css={areaTitleTextStyle} style={{ paddingBottom: '4px' }}>
                      {roomCheckinAt
                        ? `${roomCheckinAt.format(t('cleaningManager.MM-DD(ddd)'))} ${t('cleaningManager.Night', {
                            nights: room?.nextReservation?.nights,
                          })} ${` - ${selectedDate.startOf('day').diff(roomCheckinAt.startOf('day'), 'day') + 1}泊目`}`
                        : '-'}
                    </div>
                    <div css={subTextStyle}>
                      {roomCheckinAt ? `${t('cleaningManager.Estimated Time of Arrival')} : ${roomCheckinAt.format('HH:mm')}` : '-'}
                    </div>
                  </div>
                </div>

                <div css={inlineContent} style={{ alignItems: 'flex-start', paddingTop: 16 }}>
                  <img src={require('@/static/images/reservation-note/icon_cleaning.svg')} alt="" css={iconStyle} />
                  <div css={reservationDetailStyle}>
                    <div css={areaTitleTextStyle}>
                      {room?.nextReservation?.notes.find(({ category }) => isCleaningMemo(category))?.memo
                        ? t('cleaningManager.Cleaning Remarks')
                        : '-'}
                      {hasCleaningMemo(room?.nextReservation?.notes) && (
                        <img src={require('@/static/images/cleaning_manager/icon_instruction.svg')} alt="" style={{ paddingLeft: '4px' }} />
                      )}
                    </div>
                    <p style={{ lineHeight: '1.5', fontSize: 14 }}>
                      {room?.nextReservation?.notes.find(({ category }) => isCleaningMemo(category))?.memo ?? '-'}
                    </p>
                  </div>
                </div>

                <div css={inlineContent} style={{ alignItems: 'flex-start', paddingTop: 16 }}>
                  <img src={require('@/static/images/cleaning_manager/icon_people.svg')} alt="" css={iconStyle} />
                  <div css={reservationDetailStyle}>
                    <div css={areaTitleTextStyle} style={{ paddingBottom: '4px' }}>
                      {room?.nextReservation?.roomPax ? t('cleaningManager.Persons', { pax: room?.nextReservation?.roomPax.total }) : '-'}
                    </div>
                    <div css={subTextStyle}>
                      {room?.nextReservation?.roomPax ? `${t('Adult')} : ${computedAdultPaxText(room.nextReservation.roomPax)}` : '-'}
                    </div>
                    <div css={subTextStyle}>
                      {room?.nextReservation?.roomPax ? `${t('Child')} : ${computedChildPaxText(room.nextReservation.roomPax)}` : '-'}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div css={contentRightBoxStyle}>
              <div style={{ display: 'flex', gap: 16, paddingBottom: 24 }}>
                <div css={inputContentStyle}>
                  <div css={areaTitleTextStyle}>{t('cleaningManager.Cleaning Status')}</div>
                  <div>
                    <select {...register('cleaningStatus')} css={assignSelectStyle}>
                      <option value={CleaningStatusType.NotCleaning}>{t('cleaningManager.Dirty')}</option>
                      <option value={CleaningStatusType.Cleaned}>{t('cleaningManager.Clean')}</option>
                      <option value={CleaningStatusType.Inspected}>{t('cleaningManager.Inspected')}</option>
                      <option value={CleaningStatusType.DoNotDisturb}>{t('cleaningManager.Do not Disturb')}</option>
                      <option value={CleaningStatusType.NotRequired}>{t('cleaningManager.No Cleaning')}</option>
                      <option value={CleaningStatusType.Unused}>-</option>
                    </select>
                  </div>
                </div>

                <div css={inputContentStyle}>
                  <div css={areaTitleTextStyle}>{t('cleaningManager.Room Status')}</div>
                  <div css={inlineContent}>
                    <select {...register('roomUsageStatus')} css={assignSelectStyle}>
                      <option value={RoomUsageStatusType.Unused}>{t('cleaningManager.Vacant')}</option>
                      <option value={RoomUsageStatusType.Stay}>{t('cleaningManager.Occupied')}</option>
                      <option value={RoomUsageStatusType.Away}>{t('cleaningManager.Out')}</option>
                      <option value={RoomUsageStatusType.CheckOutScheduled}>{t('cleaningManager.Checkout Soon')}</option>
                      <option value={RoomUsageStatusType.CheckedOut}>{t('cleaningManager.Done Checkout')}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div style={{ paddingBottom: 24 }}>
                <div css={[areaTitleTextStyle, inlineContent]}>
                  {t('cleaningManager.Cleaning Report')}
                  {(watchStaffReport || '').length > 0 && (
                    <img src={require('@/static/images/cleaning_manager/icon_comment.svg')} alt="" style={{ paddingLeft: '4px' }} />
                  )}
                </div>
                <div css={textareaStyle}>
                  <Controller
                    control={control}
                    name="staffReport"
                    render={({ field: { onChange, value, name } }) => (
                      <TextareaField
                        name={name}
                        value={value}
                        handleChangeData={onChange}
                        marginBottom={0}
                        placeholder=""
                        autoSize={true}
                      />
                    )}
                  />
                </div>
              </div>

              <div>
                <div css={areaTitleTextStyle}>{t('Picture')}</div>
                <div css={inlineContent}>
                  <InputMultipleImage control={control} />
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter customCss={modalFooterStyle}>
        <Button buttonType={3} onClick={() => setIsOpenRoomDetailModal(false)} customCss={buttonStyle}>
          {t('Cancel')}
        </Button>
        <Button
          onClick={handleSubmit(save)}
          buttonType={isValid && isDirty ? 1 : 5}
          customCss={buttonStyle}
          isDisabled={!(isValid && isDirty)}
        >
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RoomDetail

const modalStyle = css({
  height: 'max-content',
  top: '10%',
  minWidth: 'auto',
  width: '80%',
  left: '10%',
  right: '10%',
})

const modalBodyStyle = css({
  padding: '24px 32px',
  height: '442px',
})

const modalFooterStyle = css({
  height: '68px',
  display: 'flex',
  justifyContent: 'space-between',
})

const buttonStyle = css({
  width: '166px !important',
  height: '36px !important',
})

const bodyContentStyle = css({
  padding: '32px',
  minHeight: '100%',
  backgroundColor: '#ffffff',
  borderRadius: '5px',
  display: 'flex',
})

const contentLeftBoxStyle = css({
  width: '50%',
  paddingRight: '32px',
})

const contentRightBoxStyle = css({
  width: '50%',
  borderLeft: '1px solid #CCCCCC',
  paddingLeft: '32px',
})

const areaTitleTextStyle = css({
  fontSize: 14,
  fontWeight: 'bold',
  letterSpacing: '0.7px',
  color: '#272727',
  paddingBottom: 12,
})

const subTextStyle = css({
  fontSize: 12,
  paddingTop: '6px',
  letterSpacing: '0.6px',
  color: '#676767',
})

const assignSelectStyle = css({
  width: 'max-content',
  height: '32px',
  color: '#272727',
  border: '1px solid #CCCCCC',
  borderRadius: '16px',
  padding: '6px 32px 6px 20px',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/arrow_down_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '90% center',
  backgroundColor: '#FFFFFF',
  display: 'block',
  fontSize: '14px',
})

const inlineContent = css({
  display: 'flex',
  alignItems: 'center',
})

const textareaStyle = css({
  textarea: {
    height: '70px',
    minHeight: '70px',
    marginBottom: 0,
    borderRadius: '5px',
    padding: '12px',
    font: '14px',
    letterSpacing: '1.4px',
    lineHeight: '22px',
    ':focus': {
      padding: '11px',
    },
  },
})
const reservationDetailStyle = css({
  paddingLeft: '16px',
})

const iconStyle = css({
  height: '18px',
  width: '18px',
  marginTop: '-2px',
})

const inputContentStyle = css({
  flex: '0 0 50%',
  select: {
    width: '100%',
  },
})
