import React, { useContext, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Select } from 'antd'
import { omit } from 'lodash'
import moment, { Moment } from 'moment'

import { Label } from '@/components/molecules/label'
import { Button } from '@/components/atoms'
import { DateField } from '@/components/molecules/date-field'
import { InputField } from '@/components/molecules/input-field'
// import { times } from '@/libs/times'
import { EmployeeType, OperationLogFiltersType } from '@/models/operation-log'
import { AccountContext } from '@/contexts/account'
import { FEATURES_LIST, TABLES_LIST } from '@/constants/operation-log'
import { getHotelEmployeeList } from '@/apis/aipass'
import { errorHandler } from '@/libs/errors'

type OperationLogSearchProps = {
  onChange: (data: OperationLogFiltersType) => void
}

type OperationLogFiltersInputType = OperationLogFiltersType & {
  beginDate?: Moment
  beginTime?: string
  endDate?: Moment
  endTime?: string
  staff?: string
  employee?: string
  reservationId?: string
  features?: string
  table?: string
}

export const OperationLogSearch: React.FC<OperationLogSearchProps> = ({ onChange }) => {
  const { t, i18n } = useTranslation()
  const { employeeStaff } = useContext<any>(AccountContext)
  const { control, handleSubmit, reset } = useForm<OperationLogFiltersInputType>()
  const [employeeList, setEmployeeList] = useState<EmployeeType[]>([])
  const [times] = useState([
    {
      name: '指定なし',
      value: '',
    },
    ...[...Array(48)].map((v, i) => {
      const hour = Math.floor(i / 2)
      const minute = i % 2 === 0 ? '00' : '30'
      const time = `${hour}:${minute}`
      return { value: time, name: time }
    }),
  ])

  const onClear = () => {
    reset()
  }

  const onSearch = (data: OperationLogFiltersInputType) => {
    onChange({
      ...omit(data, ['beginDate', 'beginTime', 'endDate', 'endTime']),
      beginDate: data.beginDate ? moment(data.beginDate).format('YYYY-MM-DD') + (data.beginTime ? ` ${data.beginTime}:00` : '') : undefined,
      endDate: data.endDate ? moment(data.endDate).format('YYYY-MM-DD') + (data.endTime ? ` ${data.endTime}:00` : ' 23:59') : undefined,
    })
  }

  const fetchHotelEmployeeList = async () => {
    try {
      const employeeList = await getHotelEmployeeList()

      setEmployeeList(employeeList.data)
    } catch (error) {
      errorHandler(error)
    }
  }

  const getLogTranslation = (key: string) => {
    if (i18n.exists(`OperationLog.${key}`)) return t(`OperationLog.${key}`)

    return t(key)
  }

  useEffect(() => {
    fetchHotelEmployeeList()
  }, [])

  return (
    <div css={dropDownResearchPanelStyle}>
      <div css={{ display: 'flex', margin: 24 }}>
        <div css={{ flex: 1 }}>
          <div css={{ margin: 8 }}>
            <Label label={t('Date and time of operation')} />
            <div css={{ display: 'flex', alignItems: 'center', margin: '0 -8px' }}>
              <Controller
                control={control}
                name="beginDate"
                render={({ field: { onChange, value } }) => (
                  <div css={{ margin: '0 8px' }}>
                    <DateField value={value} onChange={onChange} placeholder={t('Date')} />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="beginTime"
                render={({ field: { onChange, value } }) => (
                  <div css={{ margin: '0 8px' }}>
                    <Select
                      css={selectBoxStyle}
                      suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                      value={value}
                      onChange={onChange}
                    >
                      {times.map((item, index) => (
                        <Select.Option key={index} value={item['value']}>
                          {item['name'] === '指定なし' ? '-' : item['name']}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                )}
              />
              <div css={{ margin: '0 8px' }}>~</div>
              <Controller
                control={control}
                name="endDate"
                render={({ field: { onChange, value } }) => (
                  <div css={{ margin: '0 8px' }}>
                    <DateField value={value} onChange={onChange} placeholder={t('Date')} />
                  </div>
                )}
              />
              <Controller
                control={control}
                name="endTime"
                render={({ field: { onChange, value } }) => (
                  <div css={{ margin: '0 8px' }}>
                    <Select
                      css={selectBoxStyle}
                      suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                      value={value}
                      onChange={onChange}
                    >
                      {times.map((item, index) => (
                        <Select.Option key={index} value={item['value']}>
                          {item['name'] === '指定なし' ? '-' : item['name']}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                )}
              />
            </div>
          </div>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <Controller
              control={control}
              name="staff"
              render={({ field: { onChange, value } }) => (
                <div css={{ flex: 1, margin: 8 }}>
                  <Label label={t('Staff')} />
                  <Select
                    css={[selectBoxStyle, { width: '100%' }]}
                    suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                    value={value}
                    onChange={onChange}
                  >
                    <Select.Option value={undefined}>-</Select.Option>
                    {employeeStaff.map(staff => (
                      <Select.Option key={staff.id} value={staff.id}>
                        {staff.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
            />
            <Controller
              control={control}
              name="employee"
              render={({ field: { onChange, value } }) => (
                <div css={{ flex: 1, margin: 8 }}>
                  <Label label={t('Account')} />
                  <Select
                    css={[selectBoxStyle, { width: '100%' }]}
                    suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                    value={value}
                    onChange={onChange}
                  >
                    <Select.Option value={undefined}>-</Select.Option>
                    {employeeList.map(v => (
                      <Select.Option key={v.id} value={v.id}>
                        {v.name || v.email}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
            />
          </div>
        </div>

        <div css={{ flex: 1 }}>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <Controller
              control={control}
              name="reservationId"
              render={({ field: { onChange, value } }) => (
                <div css={{ flex: 1, margin: 8 }}>
                  <InputField value={value} handleChangeData={onChange} label={t('Reservation ID')} marginBottom={0} width="100%" />
                </div>
              )}
            />
            <div css={{ flex: 1, margin: 8 }} />
          </div>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <Controller
              control={control}
              name="features"
              render={({ field: { onChange, value } }) => (
                <div css={{ flex: 1, margin: 8 }}>
                  <Label label={t('Features')} />
                  <Select
                    css={[selectBoxStyle, { width: '100%' }]}
                    suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                    value={value}
                    onChange={onChange}
                  >
                    <Select.Option value={undefined}>-</Select.Option>
                    {FEATURES_LIST.map(v => (
                      <Select.Option key={v.value} value={v.value}>
                        {getLogTranslation(v.label)}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
            />
            <Controller
              control={control}
              name="table"
              render={({ field: { onChange, value } }) => (
                <div css={{ flex: 1, margin: 8 }}>
                  <Label label={t('Table')} />
                  <Select
                    css={[selectBoxStyle, { width: '100%' }]}
                    suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                    value={value}
                    onChange={onChange}
                  >
                    <Select.Option value={undefined}>-</Select.Option>
                    {TABLES_LIST.map(v => (
                      <Select.Option key={v} value={v}>
                        {getLogTranslation(v)}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
            />
          </div>
        </div>
      </div>

      <div css={bottomResearchPanelStyle}>
        <div css={assignButtonContainerStyle}>
          <Button width={92} height={32} buttonType={3} fontSize={12} onClick={onClear}>
            {t('Clear')}
          </Button>
          <Button width={93} height={32} buttonType={1} fontSize={12} marginLeft={16} onClick={handleSubmit(onSearch)}>
            {t('Search')}
          </Button>
        </div>
      </div>
    </div>
  )
}

const dropDownResearchPanelStyle = css({
  width: '100%',
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 50,
  left: 0,
  boxShadow: '0px 3px 10px #0000003d',
})

const bottomResearchPanelStyle = css({
  borderTop: '1px solid rgb(242, 242, 242)',
  display: 'flex',
  padding: '16px 32px',
})

const assignButtonContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const selectBoxStyle = css({
  width: 92,
  '> div': {
    borderRadius: '16px',
    border: '1px solid #CCCCCC',
    boxShadow: 'none !important',
    borderColor: '#CCCCCC !important',
  },
})
