import React, { createContext, useState, useContext, useEffect } from 'react'

// apis
import {
  fetchAdminAccount,
  fetchFacilityBasicInfo,
  fetchPlugins,
  fetchDeviceSendHistory,
  getEmployeeHotels,
  getEmployeeStaff,
} from '@/apis/aipass'

// components
import { AuthContext } from '@/contexts/auth'
import { useErrorHandler } from '@/hooks/use-error-handler'

// utils
import { sendPrinter } from '@/utils/device'

// libs
import { setHasPrinterPlugin } from '@/libs/plugins'

// models
import { DeviceType, SendType } from '@/models/device/device-setting'
import { DeviceSendHistory } from '@/models/device/device-send-history'

export const AccountContext = createContext({})

export const AccountProvider = ({ children }) => {
  const { errorHandler } = useErrorHandler()
  const { auth } = useContext<any>(AuthContext)

  // FIXME: type definition later
  const [account, setAccount] = useState<any>()
  const [basicInfo, setBasicInfo] = useState<any>()
  const [plugins, setPlugins] = useState<any>()
  const [employeeHotels, setEmployeeHotels] = useState<any>([])
  const [employeeStaff, setEmployeeStaff] = useState<any>([])
  const hasPrinterPlugin = setHasPrinterPlugin(plugins)
  const path = location.pathname

  useEffect(() => {
    if (auth) {
      _fetchAdminAccount()
    }
  }, [auth])

  useEffect(() => {
    if (account && account?.hotel) {
      _fetchFacilityBasicInfo()
    }
  }, [account])

  useEffect(() => {
    if (account) {
      _fetchPlugins()
      _fetchHotels()
      fetchEmployeeStaff()
    }
  }, [account])

  useEffect(() => {
    const printerSubscribeId = sessionStorage.getItem('printerSubscribeId')
    if (printerSubscribeId) {
      clearInterval(Number(sessionStorage.getItem('printerSubscribeId')))
    }
    sessionStorage.setItem(
      'printerSubscribeId',
      setInterval(() => {
        // print(30 seconds)
        _fetchDeviceSendHistory()
      }, 30000) as unknown as string,
    )
  }, [path, hasPrinterPlugin])

  const _fetchAdminAccount = async () => {
    const account = await fetchAdminAccount()
    if (account && account.hotel) {
      setAccount(account)
    }
  }

  const _fetchFacilityBasicInfo = async () => {
    const res = await fetchFacilityBasicInfo()
    setBasicInfo(res?.basicInfo)
  }

  const _fetchPlugins = async () => {
    await fetchPlugins().then(res => {
      setPlugins(res?.plugins)
    })
  }

  const _fetchDeviceSendHistory = async () => {
    const printerSettingIds = localStorage?.printerSettingIds ? JSON.parse(localStorage.printerSettingIds) : []
    if (!printerSettingIds.length) {
      return
    }

    const deviceSendHistory = await fetchDeviceSendHistory(printerSettingIds)
    if (!deviceSendHistory?.length) {
      return
    }

    for (let i = 0; i < deviceSendHistory.length; i += 1) {
      if (deviceSendHistory[i].deviceType === DeviceType.printer && deviceSendHistory[i].sendType === SendType.order) {
        await sendPrinter(deviceSendHistory[i])
      }
    }
  }

  const _fetchHotels = async () => {
    await getEmployeeHotels()
      .then(setEmployeeHotels)
      .catch(error => errorHandler(error))
  }

  const fetchEmployeeStaff = async () => {
    await getEmployeeStaff()
      .then(setEmployeeStaff)
      .catch(error => errorHandler(error))
  }

  return (
    <AccountContext.Provider
      value={{
        account,
        // language,
        basicInfo,
        // setLanguage,
        plugins,
        setPlugins,

        employeeHotels,
        employeeStaff,
        fetchEmployeeStaff,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}
