import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

type SearchCustomerLinkButtonProps = {
  setIsOpenModal: (v: boolean) => void
}

export const SearchCustomerLinkButton: React.FC<SearchCustomerLinkButtonProps> = ({ setIsOpenModal }) => {
  const { t } = useTranslation()
  return (
    <>
      <div css={SectionStyle}>
        <div css={itemWrapperStyle}>
          <div css={nameStyle}>{t('Related customers')}</div>
        </div>
        <div css={searchLinkWrapperStyle}>
          <div css={searchLinkStyle} onClick={() => setIsOpenModal(true)}>
            <img src={require('@/static/images/customer-search.svg')} alt={t('Add icon red')} css={addIconStyle} />
            <p>{t('Customer search')}</p>
          </div>
        </div>
      </div>
    </>
  )
}

const textStyle = css({
  color: '#272727',
})

const SectionStyle = css({
  backgroundColor: '#FFF',
  padding: '24px 32px',
  position: 'relative',
  borderRadius: 5,
  height: 228,
})

const itemWrapperStyle = css(textStyle, {
  display: 'flex',
  marginBottom: 14,
})

const nameStyle = css({
  color: '#676767',
  fontWeight: 'bold',
  fontSize: 12,
})

const searchLinkWrapperStyle = css({
  width: '100%',
})

const searchLinkStyle = css({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  backgroundColor: '#F5F5F5',
  border: '1px dashed #CCCCCC',
  padding: '13px 22px',
  borderRadius: 10,
  p: {
    lineHeight: '36px',
    color: '#F2A40B',
    fontSize: 14,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
})

const addIconStyle = css({
  width: 20,
  height: 20,
  marginLeft: 5,
})
