import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

// Validation when saving room assignment
export const validUpdateGuestRoom = (editGuestRooms): boolean => {
  let validated = true
  validated = _checkDuplicatedGuestRoom(editGuestRooms)

  // Check if room number is selected when editing
  editGuestRooms.map(_room => {
    // If room type and room number are not selected
    if (!_room.typeNameJa && typeof _room.roomNumber !== 'string') {
      alert('部屋タイプと部屋番号を選択してください。')
      validated = false
    } else if (typeof _room.roomNumber !== 'string') {
      // If no room number is selected
      alert('部屋番号を選択してください。')
      validated = false
    } else if (!_room.typeNameJa) {
      // No room type selected
      alert('部屋タイプを選択してください。')
      validated = false
    } else if (_room.roomNumber === '部屋番号を選択') {
      // When the room type is "select room number"
      alert('宿泊日を変更した場合は、部屋番号を選び直してください。')
      validated = false
    }
  })
  return validated
}

// Room duplication check
const _checkDuplicatedGuestRoom = (editGuestRooms): boolean => {
  const duplicateRooms: any = []

  for (let index = 0; index < editGuestRooms.length; index++) {
    const guestRoomAssign = editGuestRooms[index]
    if (
      guestRoomAssign.roomNumber === '' ||
      guestRoomAssign.roomNumber === '指定なし' ||
      guestRoomAssign.roomNumber === '部屋番号を選択' ||
      duplicateRooms.some(v => v.roomNumber === guestRoomAssign.roomNumber)
    ) {
      continue
    }

    for (let i = index + 1; i < editGuestRooms.length; i++) {
      const guestRoomAssign2 = editGuestRooms[i]
      if (
        guestRoomAssign2.roomNumber === guestRoomAssign.roomNumber &&
        (guestRoomAssign2.assignCheckinDate === guestRoomAssign.assignCheckinDate ||
          guestRoomAssign2.assignCheckoutDate === guestRoomAssign.assignCheckoutDate ||
          (dayjs(guestRoomAssign2.assignCheckinDate).isBetween(guestRoomAssign.assignCheckinDate, guestRoomAssign.assignCheckoutDate) &&
            dayjs(guestRoomAssign2.assignCheckoutDate).isBetween(guestRoomAssign.assignCheckinDate, guestRoomAssign.assignCheckoutDate)) ||
          (dayjs(guestRoomAssign.assignCheckinDate).isBetween(guestRoomAssign2.assignCheckinDate, guestRoomAssign2.assignCheckoutDate) &&
            dayjs(guestRoomAssign.assignCheckoutDate).isBetween(guestRoomAssign2.assignCheckinDate, guestRoomAssign2.assignCheckoutDate)))
      ) {
        duplicateRooms.push(guestRoomAssign2)
      }
    }
  }

  if (duplicateRooms.length === 0) {
    return true
  }

  const duplicateEditRoomsNumber = duplicateRooms.map(v => v.roomNumber)

  alert(`部屋番号が重複しています。 部屋番号：${duplicateEditRoomsNumber.join('、')}`)

  return false
}
