/**
 * Auth0 Api Module
 * @see https://auth0.com/
 * @see https://www.npmjs.com/package/auth0-js
 */
import * as Auth0 from 'auth0-js'
import { env } from '@/libs/env'

type AuthOptions = {
  domain: string
  clientID: string
  responseType?: string
  responseMode?: string
  redirectUri?: string
  scope?: string
  audience?: string
  leeway?: number
  plugins?: any[]
  _disableDeprecationWarnings?: boolean
  _sendTelemetry?: boolean
  _telemetryInfo?: any
  __tryLocalStorageFirst?: boolean
}

/**
 * サインイン
 * 成功した場合、指定されたリダイレクト先に遷移します
 * @param email
 * @param password
 * @return Promise
 */
export const signin = (email, password) => {
  return new Promise((resolve, reject) => {
    const { domain, clientID, responseType, redirectUri } = _buildWebAuthParam()
    const webAuth: Auth0.WebAuth = new Auth0.WebAuth({
      domain,
      clientID,
      responseType,
      redirectUri,
    })
    webAuth.login(
      {
        realm: env('AUTH_DBNAME'),
        email: email,
        password: password,
      },
      (error, result) => {
        if (!error) {
          resolve(result)
        } else {
          console.error(error)
          // reject(translate('メールアドレスもしくはパスワードが間違っています'))
          reject('メールアドレスもしくはパスワードが間違っています')
        }
      },
    )
  })
}

/**
 * QRチェックイン用サインイン
 * 成功した場合、指定されたリダイレクト先に遷移します
 * @param email
 * @param password
 * @return Promise
 */
export const qrSignin = (email, password) => {
  return new Promise((resolve, reject) => {
    const { domain, clientID, responseType, redirectUri } = _buildWebAuthParamForQrCheckin()
    const webAuth: Auth0.WebAuth = new Auth0.WebAuth({
      domain,
      clientID,
      responseType,
      redirectUri,
    })
    webAuth.login(
      {
        realm: env('AUTH_DBNAME'),
        email: email,
        password: password,
      },
      (error, result) => {
        if (!error) {
          resolve(result)
        } else {
          console.error(error)
          // reject(translate('メールアドレスもしくはパスワードが間違っています'))
          reject('メールアドレスもしくはパスワードが間違っています')
        }
      },
    )
  })
}

/**
 * 顧客一覧のパスワード認証
 * 成功した場合、顧客一覧に遷移します
 * @param email
 * @param password
 * @return Promise
 */
export const customerCertification = (email, password) => {
  return new Promise((resolve, reject) => {
    const { domain, clientID, responseType, redirectUri } = _buildWebAuthParamForCustomer()
    const webAuth: Auth0.WebAuth = new Auth0.WebAuth({
      domain,
      clientID,
      responseType,
      redirectUri,
    })
    webAuth.login(
      {
        realm: env('AUTH_DBNAME'),
        email: email,
        password: password,
      },
      (error, result) => {
        if (!error) {
          resolve(result)
        } else {
          console.error(error)
          reject('パスワードが間違っています')
        }
      },
    )
  })
}

/**
 * 免許証画像表示のパスワード認証
 * 成功した場合、免許証画像を表示
 * @param email
 * @param password
 * @param page
 * @param checkinId
 * @param imageKey
 * @return Promise
 */
export const showDriversLicenseAuth = (email, password, page, checkinId, imageKey) => {
  return new Promise((resolve, reject) => {
    const { domain, clientID, responseType } = _buildWebAuthParamForShowDriversLicense()
    const webAuth: Auth0.WebAuth = new Auth0.WebAuth({
      domain,
      clientID,
      responseType,
      redirectUri: `${window.location.origin}/${page}/redirect`,
      state: `checkinId=${checkinId}&imageKey=${imageKey}&page=${page}`,
    })
    webAuth.login(
      {
        realm: env('AUTH_DBNAME'),
        email: email,
        password: password,
      },
      (error, result) => {
        if (!error) {
          resolve(result)
        } else {
          console.error(error)
          reject('パスワードが間違っています')
        }
      },
    )
  })
}

/**
 * サインアウト
 * @return Promise
 */
export const signout = () => {
  return new Promise(() => {
    const { domain, clientID, redirectUri } = _buildWebAuthParam()
    const webAuth: Auth0.WebAuth = new Auth0.WebAuth({
      domain,
      clientID,
    })
    localStorage.removeItem('idToken')
    const isSelfCheckinPage = window.location.pathname.search(/^\/self-checkin/) === 0

    const returnTo = !isSelfCheckinPage ? redirectUri : window.location.pathname
    webAuth.logout({ returnTo })
  })
}

/**
 * QRチェックイン用サインアウト
 * @return Promise
 */
export const qrSignout = () => {
  return new Promise(() => {
    const { domain, clientID, redirectUri } = _buildWebAuthParamForQrCheckin()
    const webAuth: Auth0.WebAuth = new Auth0.WebAuth({
      domain,
      clientID,
    })
    webAuth.logout({ returnTo: redirectUri })
    localStorage.removeItem('idToken')
  })
}

/**
 * トークン解析
 * @return Promise
 */
export const parseToken = () => {
  return new Promise((resolve, reject) => {
    const { domain, clientID } = _buildWebAuthParam()
    const webAuth: Auth0.WebAuth = new Auth0.WebAuth({
      domain,
      clientID,
    })
    webAuth.parseHash((error, result) => {
      if (result) {
        resolve(result)
      } else {
        console.error(error)
        reject(error)
      }
    })
  })
}

/**
 * トークン更新
 * @return Promise
 */
export const refreshToken = () => {
  return new Promise((resolve, reject) => {
    const { domain, clientID, responseType, redirectUri } = _buildWebAuthParam()
    console.log('refreshToken: domain', domain)
    console.log('refreshToken: clientID', clientID)
    console.log('refreshToken: responseType', responseType)
    console.log('refreshToken: redirectUri', redirectUri)
    const webAuth: Auth0.WebAuth = new Auth0.WebAuth({
      domain,
      clientID,
      responseType,
      redirectUri,
    })
    webAuth.checkSession({}, (error, result) => {
      if (!error) {
        resolve(result)
      } else {
        console.error(error)
        reject(error)
      }
    })
  })
}

/**
 * パラメータ構築
 * @private
 */
const _buildWebAuthParam = (): AuthOptions => {
  const { origin } = window.location
  return {
    domain: env('AUTH_DOMAIN') as string,
    clientID: env('AUTH_CLIENT_ID') as string,
    responseType: 'token id_token',
    redirectUri: `${origin}/`,
  }
}

/**
 * パラメータ構築(QRチェックイン用)
 * @private
 */
const _buildWebAuthParamForQrCheckin = (): AuthOptions => {
  const { origin } = window.location
  return {
    domain: env('AUTH_DOMAIN') as string,
    clientID: env('AUTH_CLIENT_ID') as string,
    responseType: 'token id_token',
    redirectUri: `${origin}/qr-checkin`,
  }
}

/**
 * パラメータ構築(顧客管理ページ用)
 * @private
 */
const _buildWebAuthParamForCustomer = (): AuthOptions => {
  const { origin } = window.location
  return {
    domain: env('AUTH_DOMAIN') as string,
    clientID: env('AUTH_CLIENT_ID') as string,
    responseType: 'token id_token',
    redirectUri: `${origin}/customer/list`,
  }
}

/**
 * パラメータ構築(免許証画像表示用)
 * @private
 */
const _buildWebAuthParamForShowDriversLicense = (): AuthOptions => {
  return {
    domain: env('AUTH_DOMAIN') as string,
    clientID: env('AUTH_CLIENT_ID') as string,
    responseType: 'token id_token',
  }
}
