import React, { useContext, useEffect, useState } from 'react'
import { AssetType, PulldownDetailType } from '@/models/guest-app/asset'
import { ContainerHeader } from '@/components/molecules/settings/container-header'
import { ContainerSidePanelBody } from '@/components/molecules/settings/container-side-panel-body'
import { Editor } from '@/components/organisms/settings/editor'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { updateAsset, updateAssetDetail } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

type Props = {
  asset: AssetType & { details: PulldownDetailType[] }
}

export type AssetPulldownDetailFormValue = {
  title: string
  description: string
  rawDescription: string
}

export const PulldownAssetDetail: React.FC<Props> = ({ asset }) => {
  const { t } = useTranslation()
  const [editorViewId, setEditorViewId] = useState<string>(uuid())
  const [formValue, setFormValue] = useState<AssetPulldownDetailFormValue>({
    title: asset.title,
    description: asset.details[0].description,
    rawDescription: asset.details[0].rawDescription,
  })
  const { doReload, listenDoSave, listenDoCancel, setIsLoading, setIsEditing } = useContext(AssetDetailContext)
  const { errorHandler } = useErrorHandler()

  const saveFormValue = async () => {
    try {
      setIsLoading(true)
      await updateAsset(asset.id, { title: formValue.title })
      await updateAssetDetail(asset.id, asset.details[0].id, asset.formatType, formValue)
      doReload()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (listenDoSave) saveFormValue()
  }, [listenDoSave])

  useEffect(() => {
    if (listenDoCancel) doReload()
  }, [listenDoCancel])

  useEffect(() => {
    if (!asset) {
      return
    }
    setFormValue({
      title: asset.title,
      description: asset.details[0].description,
      rawDescription: asset.details[0].rawDescription,
    })
    setEditorViewId(uuid())
  }, [asset])

  return (
    <>
      <ContainerHeader
        goBack
        title={formValue.title}
        onChangeTitle={(newTitle: string) => {
          setFormValue({ ...formValue, title: newTitle })
          setIsEditing(true)
        }}
      />
      <ContainerSidePanelBody
        menus={[
          {
            title: t('GuestAppSetting.Contents'),
            path: '',
            isActive: true,
          },
        ]}
      >
        <div style={{ padding: 32, height: '100%' }}>
          <Editor
            id={editorViewId}
            htmlContent={formValue.description}
            rawContent={formValue.rawDescription}
            onChange={(raw: string, html: string) => {
              setFormValue({ ...formValue, description: html, rawDescription: raw })
              setIsEditing(true)
            }}
          />
        </div>
      </ContainerSidePanelBody>
    </>
  )
}
