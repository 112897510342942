import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import i18n from '@/i18n'

// models
import { facilityStatusInfoType } from '@/models/facility'

type facilityInfoListProps = {
  statusInfo: facilityStatusInfoType | undefined
  onClick: () => void
  onChange: (value: any) => void
}

export const FacilityInfoList: React.FC<facilityInfoListProps> = ({ statusInfo, onClick, onChange }) => {
  const { t } = useTranslation()
  const listName = () => {
    if (statusInfo?.pageIndex === '1') return t('Check-in/out')
    if (statusInfo?.pageIndex === '3') return 'Wi-Fi'
    if (statusInfo?.pageIndex === '7') return t('Breakfast/dinner venue')
    if (statusInfo?.pageIndex === '8') return t('Bathhouse')
    if (statusInfo?.pageIndex === '9') return t('Facility')
    if (statusInfo?.pageIndex === '5') return t('Floor information')
    if (statusInfo?.pageIndex === '2') return t('Access')
    if (statusInfo?.pageIndex === '4') return t('News')
    if (statusInfo?.pageIndex === '6') return t('Terms and Conditions')
    if (statusInfo?.pageIndex === '10') return t('Check-in/out')
  }

  const listId = () => {
    if (statusInfo?.pageIndex === '1') return 'basicInfo'
    if (statusInfo?.pageIndex === '3') return 'wifiInfo'
    if (statusInfo?.pageIndex === '7') return 'mealInfo'
    if (statusInfo?.pageIndex === '8') return 'bathInfo'
    if (statusInfo?.pageIndex === '9') return 'equipmentInfo'
    if (statusInfo?.pageIndex === '5') return 'floorInfo'
    if (statusInfo?.pageIndex === '2') return 'accessInfo'
    if (statusInfo?.pageIndex === '4') return 'guideInfo'
    if (statusInfo?.pageIndex === '6') return 'termsInfo'
    if (statusInfo?.pageIndex === '10') return 'checkinOut'
  }

  return (
    <div css={facilityInfoListContainerStyle}>
      <div css={facilityInfoListNameStyle} onClick={() => onClick()}>
        {listName()}
      </div>
      <div css={facilityInfoListRightContainerStyle}>
        <input
          type="checkbox"
          id={listId()}
          css={facilityCheckboxStyle}
          onChange={onChange}
          checked={statusInfo?.viewStatus ? true : false}
        />
        <label htmlFor={listId()} css={facilityCheckboxLabelStyle} />
      </div>
    </div>
  )
}

const facilityInfoListContainerStyle = css({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 64,
  borderBottom: '1px solid #F2F2F2',
  padding: '0 32px',
  ':hover': {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.06)',
  },
})

const facilityInfoListNameStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  width: '100%',
  padding: '25px 0px',
  height: '100%',
})

const facilityInfoListRightContainerStyle = css({
  display: 'flex',
  img: {
    display: 'block',
    marginLeft: 27,
    padding: 4,
    ':hover': {
      borderRadius: '50%',
      background: '#F2F2F2',
      cursor: 'pointer',
    },
  },
})

export const facilityCheckboxStyle = css({
  display: 'none',
  ':checked': {
    '+label': {
      backgroundColor: '#f2a40b',
      border: 'none',
      position: 'relative',

      '&::before': {
        content: `'${i18n.t('Display')}'`,
        color: '#fff',
        fontSize: 12,
        letterSpacing: 1.2,
        left: 15,
        position: 'absolute',
      },
      '&::after': {
        top: 3,
        left: 47,
        backgroundColor: '#fff',
      },
    },
  },
})

export const facilityCheckboxLabelStyle = css({
  width: 73,
  height: 28,
  background: '#F2F2F2',
  position: 'relative',
  display: 'inline-block',
  borderRadius: 14,
  transition: '0.4s',
  boxSizing: 'border-box',
  border: '1px solid #ccc',
  cursor: 'pointer',

  '&::before': {
    content: `'${i18n.t('Hidden')}'`,
    fontWeight: 'bold',
    color: '#a3a3a3',
    fontSize: 11,
    letterSpacing: 0.55,
    left: 30,
    position: 'absolute',
    top: 7,
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    width: 22,
    height: 22,
    borderRadius: '100%',
    top: 2,
    left: 4,
    zIndex: 2,
    background: '#fff',
    transition: '0.4s',
    boxShadow: '0px 2px 3px #00000029',
  },
})
