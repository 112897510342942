import React, { useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// api
import { fetchCheckinSalesDetailPdf } from '@/apis/aipass'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { Button } from '@/components/atoms/button'

//libs
import { b64DecodeUnicode } from '@/libs/pdf/pdf-download'
type ReceiptPopupProps = {
  isReceiptModalOpen: boolean
  setIsReceiptModalOpen: (v: boolean) => void
  customer: any
  reservationId?: string
}

export const ReceiptPopup: React.FC<ReceiptPopupProps> = ({ isReceiptModalOpen, setIsReceiptModalOpen, customer, reservationId }) => {
  const { t } = useTranslation()
  const customerId = customer?.id
  const customerName = customer?.guest?.name
  const customerCompany = customer?.guest?.company
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [name, setName] = useState(customerName ? customerName : '')
  const [provision, setProvision] = useState('ご宿泊代として')
  const [showPresets, setShowPresets] = useState(false)

  const _downloadSalesPdf = () => {
    setIsLoading(true)

    // upload to server
    const params = { reservationId, checkinId: customerId, guestName: name, provision }

    fetchCheckinSalesDetailPdf(params).then(res => {
      if (res.data) {
        const url = window.URL.createObjectURL(res.data)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', res.fileName ? b64DecodeUnicode(res.fileName) : 'sales') //or any other extension
        document.body.appendChild(link)
        link.click()
        setIsLoading(false)
        setIsReceiptModalOpen(false)
      }
    })
  }

  const onSaveData = () => {
    setDisabled(true)
    _downloadSalesPdf()
  }

  const clickPeceiptDropDown = () => {
    setShowPresets(!showPresets)
  }

  const onClickPreset = value => {
    setName(value)
    setShowPresets(false)
  }

  return (
    <React.Fragment>
      <div css={modalWrapperStyle}>
        <div css={modalContentStyle}>
          <div css={modalTitleWrapperStyle}>
            <p css={modalTitleStyle}>{t('Receipt issue')}</p>
          </div>
          <div css={mainContentStyle}>
            <div css={inputWrapperStyle}>
              <span css={titleInputStyle}>{t('Your name')}</span>
              <div css={{ position: 'relative' }}>
                <div css={{ position: 'relative', border: '1px solid #CCCCCC', borderRadius: 16, overflow: 'hidden' }}>
                  <input
                    name="text"
                    type="text"
                    css={[nameInputStyle, { border: 'none' }]}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder="山田 太郎"
                  />
                  {customerCompany && (
                    <div
                      onClick={() => clickPeceiptDropDown()}
                      css={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderLeft: '1px solid #ccc',
                        width: 32,
                        backgroundColor: '#eee',
                      }}
                    >
                      <img src={require('@/static/images/arrow_yellow.svg')} />
                    </div>
                  )}
                </div>
                {showPresets && (
                  <div
                    css={{
                      position: 'absolute',
                      right: 0,
                      left: 0,
                      top: '32px',
                      backgroundColor: '#fff',
                      borderRadius: 5,
                      boxShadow: '0px 3px 10px #0000003d',
                      padding: '8px 0',
                      marginTop: 8,
                    }}
                  >
                    <div onClick={() => onClickPreset(customerName)} css={{ padding: '8px 16px', cursor: 'pointer' }}>
                      {customerName}
                    </div>
                    <div onClick={() => onClickPreset(customerCompany)} css={{ padding: '8px 16px', cursor: 'pointer' }}>
                      {customerCompany}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div css={inputWrapperStyle}>
              <span css={titleInputStyle}>{t('Proviso')}</span>
              <input
                name="text"
                type="text"
                css={provisionInputStyle}
                value={provision}
                onChange={e => setProvision(e.target.value)}
                placeholder={t('As accommodation fee')}
              />
            </div>
          </div>
          <div>
            <div css={buttomButtonStyle}>
              <Button
                width={110}
                height={38}
                buttonType={3}
                fontSize={14}
                marginRight={16}
                fontWeight={'normal'}
                onClick={() => setIsReceiptModalOpen(!isReceiptModalOpen)}
              >
                {t('Cancel')}
              </Button>
              <Button
                width={126}
                fontWeight={'normal'}
                height={38}
                buttonType={1}
                fontSize={14}
                isDisabled={disabled}
                onClick={() => onSaveData()}
              >
                {t('Download')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </React.Fragment>
  )
}

// Modal
const modalWrapperStyle = css({
  width: '100vw',
  height: '100vh',
  background: 'rgba(39, 39, 39, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 103,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'scroll',
})
const mainContentStyle = css({
  marginTop: 60,
  height: 128,
  background: '#F2F2F2 0% 0% no-repeat padding-box',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px 32px',
})

const inputWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
})

const titleInputStyle = css({
  fontSize: 12,
  color: '#676767',
  letterSpacing: 0.6,
  width: 66,
  textAlign: 'left',
})

const inputStyle = css({
  height: 32,
  padding: '8px 16px',
  border: '1px solid #ccc',
  borderRadius: 16,
  textAlign: 'left',
  appearance: 'none',
  '::placeholder': {
    color: '#ccc',
  },
})

const nameInputStyle = css(inputStyle, {
  width: 302,
})

const provisionInputStyle = css(inputStyle, {
  width: 550,
})
const modalContentStyle = css({
  width: 680,
  maxHeight: 241,
  background: '#fff',
  borderRadius: 5,
  marginBottom: 40,
})

const modalTitleWrapperStyle = css({
  width: 'inherit',
  paddingLeft: 32,
  position: 'absolute',
  'z-index': '10000',
  backgroundColor: 'white',
  borderRadius: '5px 5px 0 0',
})

const modalTitleStyle = css({
  color: '#272727',
  fontSize: 18,
  fontWeight: 'bold',
  lineHeight: '60px',
  letterSpacing: '0.9px',
})

const buttomButtonStyle = css({
  display: 'flex',
  padding: '11px 32px',
  justifyContent: 'flex-end',
  backgroundColor: '#FFFFFF',
  borderRadius: '0px 0px 5px 5px',
})
