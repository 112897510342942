import React, { useState, useContext, useEffect } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { CheckinTabContainer } from '@/components/organisms/settings/checkin-tab-container'
import { AccountContext } from '@/contexts/account'
import { useHistory } from 'react-router-dom'
import * as Api from '@/apis/aipass'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'
import { ToggleButton } from '@/components/molecules/settings/toggle-button'
import * as CustomCheckinModel from '@/models/self-checkin/custom-checkin'

export const CustomCheckin: React.FC = () => {
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()
  const [questions, setQuestions] = useState<CustomCheckinModel.CustomCheckinType[]>([])

  const toggleViewStatus = async (changedItem: CustomCheckinModel.CustomCheckinType) => {
    setIsLoading(true)
    await Api.updateViewStatus({ ...changedItem, viewStatus: changedItem.viewStatus ^ 1 })
    await fetchCustomCheckin()
    setIsLoading(false)
  }

  const deleteCustomCheckin = async (id: string) => {
    if (window.confirm(t('Do you really want to delete this'))) {
      setIsLoading(true)
      await Api.deleteCustomCheckin(id)
      await fetchCustomCheckin()
      setIsLoading(false)
    }
  }
  const fetchCustomCheckin = async () => {
    const res = await Api.fetchCustomCheckin()
    setQuestions(res?.customCheckin || [])
  }

  useEffect(() => {
    if (account && account?.hotel) {
      fetchCustomCheckin()
    }
  }, [account])

  return (
    <SettingsLayout loading={isLoading}>
      <TabContainer tabComponent={<CheckinTabContainer currentTab="Custom" />}>
        <>
          <div css={tableBodyStyle}>
            {questions.map(question => (
              <div
                key={question.customCheckinId}
                css={customCheckinInfoListContainerStyle}
                onClick={() => history.push(`/setting/checkin/custom-checkin/${question.customCheckinId}`)}
              >
                <div css={customCheckinInfoListNameStyle}>{question.jpTitle}</div>
                <div css={customCheckinInfoListRightContainerStyle}>
                  <ToggleButton value={question?.viewStatus ? true : false} onChange={() => toggleViewStatus(question)} />
                  <DeleteIcon onClick={() => deleteCustomCheckin(question.customCheckinId)} style={{ marginLeft: 25 }} />
                </div>
              </div>
            ))}
          </div>
          <div css={addButtonStyle} onClick={() => history.push('/setting/checkin/custom-checkin/new')}>
            <img src={require('@/static/images/link_add.svg')} alt={t('Add icon red')} />
            <p>{t('Add item')}</p>
          </div>
        </>
      </TabContainer>
    </SettingsLayout>
  )
}

const tableFooterHeight = 36
const tableBodyStyle = css({
  height: `calc(100% - ${tableFooterHeight + 48}px)`,
  overflow: 'auto',
})

const customCheckinInfoListContainerStyle = css({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 64,
  borderBottom: '1px solid #F2F2F2',
  padding: '0 32px',
  ':hover': {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.06)',
  },
})

const customCheckinInfoListNameStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  width: '100%',
  padding: '25px 0px',
  height: '100%',
})

const customCheckinInfoListRightContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
})

const addButtonStyle = css({
  cursor: 'pointer',
  display: 'flex',
  height: tableFooterHeight,
  backgroundColor: '#FAFAFA',
  justifyContent: 'center',
  border: '1px dashed #CCCCCC',
  p: {
    lineHeight: '36px',
    color: '#FF5500',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
  margin: 24,
  img: { width: 20 },
  ':hover': { background: '#F2F2F2' },
})
