import React, { useCallback } from 'react'
import { css } from '@emotion/core'
import { EditorButtonPopupForm } from './edtor-button-popup'
import { ContentBlock, ContentState } from 'draft-js'

type Props = {
  blockKey: string
  formValue: EditorButtonPopupForm
  onClick: (blockKey, data: EditorButtonPopupForm) => void
  design?: {}
}

const className = 'custom-design-button'

export const DesignCustomButton: React.FC<Props> = ({ blockKey, formValue, onClick, design }) => {
  const style = useCallback(() => {
    return css({
      background: '#A59785',
      color: '#FFFFFF',
      borderRadius: 6,
      height: 48,
      display: 'block',
      textAlign: 'center',
      lineHeight: '48px',
      ':active,:hover': {
        color: '#FFFFFF',
      },
      margin: '32px 0',
    })
  }, [design])

  return (
    <div>
      <a
        href={formValue.linkType === 'URL' ? formValue.url : formValue.filePath}
        css={style}
        target="_blank"
        rel="noreferrer"
        className={className}
        onMouseDown={e => {
          e.stopPropagation()
          onClick(blockKey, formValue)
        }}
      >
        {formValue.name}
      </a>
    </div>
  )
}
export const DesignCustomButtonToHtml = (blockData: EditorButtonPopupForm) => {
  return `<div>
    <a 
      href="${blockData.linkType === 'URL' ? blockData.url : blockData.filePath}" 
      target="_blank" 
      rel="noreferrer" 
      class="${className}"
    >
      ${blockData.name}
    </a>
  </div>`
}

export const DesignCustomButtonToEditor = ({ onClick }: { onClick: (blockKey, data) => void }) => {
  const drawComponent = (props: { contentState: ContentState; block: ContentBlock }) => {
    const blockValue = props.block.getData().toObject() as EditorButtonPopupForm
    return <DesignCustomButton blockKey={props.block.getKey()} formValue={blockValue} onClick={onClick} />
  }
  return drawComponent
}
