import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// components
import { sectionHeaderStyle } from '@/components/pages/dashboard/_index/style'

// constants
import { simpleTableStyle } from '@/constants/layout'

// models
import { TransactionType } from '@/models/smaregi/transaction'

type DiscountProps = {
  transaction: TransactionType | undefined
}

export const Discount: React.FC<DiscountProps> = ({
  transaction
}) => {
  const { t } = useTranslation()

  return (
    <div css={containerStyle}>
      <div css={headerContainerStyle}>
        <div css={headerStyle}>{t('Discount')}</div>
      </div>
      <table css={tableStyle}>
        <thead>
          <tr>
            <th css={{ width: '10.83%' }}>{t('Date')}</th>
            <th css={{ width: '18.36%' }}>{t('Discount item')}</th>
            <th css={{ width: '18.36%' }}>{t('Eligible payment')}</th>
            <th css={{ width: '9.58%' }}>{t('Amount of money')}</th>
            <th css={{ width: '18.36%' }}>{t('Accounts receivable')}</th>
          </tr>
        </thead>
        <tbody>
          {transaction?.sales?.filter((sale) => sale.isDiscount).map((discount, index) => (
            <tr key={index}>
              <td>{dayjs(discount.salesAt).format(t('MM-DD'))}</td>
              <td>{discount.saleSubjectName}</td>
              <td>{discount.advanceCompanyName || discount.paymentMethodMasterName || ''}</td>
              <td>{Number(discount.unitPrice).toLocaleString()}</td>
              <td className="accountsReceivable">{discount.accountReceivableMasterName || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const containerStyle = css({
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  border: '1px solid #cccccc',
  width: '74%',
  minWidth: 600,
  height: 'fit-content',
  overflow: 'hidden',
})

const headerStyle = css(sectionHeaderStyle, {
  height: 43.5,
  marginLeft: 28,
  fontSize: 14,
  fontWeight: 'bold',
  letterSpacing: '2.1px',
})

const headerContainerStyle = css({
  marginRight: 32,
  display: 'flex',
  justifyContent: 'space-between',
})

const tableStyle = css(simpleTableStyle, {
  'tbody td': {
    padding: '21px 8px',
  }
})
