import React from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

// models
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'
import { ChannelCodeType } from '@/models/reservation-channel-code'

type Props = {
  accountsReceivableSettings: AccountsReceivableSettingType[]
  onChangeState: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index) => void
  deleteAccountsReceivableSetting: (id, index) => void
  addAccountsReceivableSetting: () => void
  channelLists: ChannelCodeType[]
}

export const SettingAccountsReceivableEditForm: React.FC<Props> = ({
  accountsReceivableSettings,
  onChangeState,
  deleteAccountsReceivableSetting,
  addAccountsReceivableSetting,
  channelLists,
}) => {
  const windows = useWindowSize()
  const { t } = useTranslation()

  return (
    <>
      <div css={accountsReceivableSettingEditHeaderStyle}>
        <div css={codeWidthStyle}>{t('Receivable ID')}</div>
        <div css={nameWidthStyle}>{t('Accounts receivable name')}</div>
        <div css={channelCodeWidthStyle}>{t('Channel name')}</div>
        <div css={closingDateidthStyle}>{t('Closing date')}</div>
      </div>
      <div
        css={scrollStyle}
        style={{
          height: windows.height - 270,
        }}
      >
        <ul css={accountsReceivableSettingEditFormStyle}>
          {accountsReceivableSettings &&
            accountsReceivableSettings.map((accountsReceivableSetting, index) => (
              <li key={index}>
                <div css={codeWidthStyle}>
                  <input
                    name="code"
                    type="text"
                    placeholder="0000"
                    css={inputStyle}
                    value={accountsReceivableSetting.code}
                    onChange={e => onChangeState(e, index)}
                  />
                </div>
                <div css={nameWidthStyle}>
                  <input
                    name="name"
                    type="text"
                    placeholder={t('Rakuten Travel')}
                    css={inputStyle}
                    value={accountsReceivableSetting.name}
                    onChange={e => onChangeState(e, index)}
                  />
                </div>
                <div css={channelCodeWidthStyle}>
                  <select
                    css={selectBoxStyle}
                    name="channelCode"
                    value={accountsReceivableSetting.channelCode ? accountsReceivableSetting.channelCode : ''}
                    onChange={e => onChangeState(e, index)}
                  >
                    <option value="">-</option>
                    {channelLists &&
                      channelLists.map((channelList, index) => (
                        <option key={index} value={channelList.code!}>
                          {channelList.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div css={closingDateidthStyle}>
                  <select
                    css={selectBoxStyle}
                    name="closingDate"
                    value={accountsReceivableSetting.closingDate ? accountsReceivableSetting.closingDate : ''}
                    onChange={e => onChangeState(e, index)}
                  >
                    <option value="0">{t('The last day')}</option>
                    <option value="25">{t('{{count}}th', { count: 25 })}</option>
                    <option value="20">{t('{{count}}th', { count: 20 })}</option>
                    <option value="15">{t('{{count}}th', { count: 15 })}</option>
                    <option value="10">{t('{{count}}th', { count: 10 })}</option>
                    <option value="5">{t('{{count}}th', { count: 5 })}</option>
                  </select>
                </div>
                <div css={deleteButtonStyle} onClick={() => deleteAccountsReceivableSetting(accountsReceivableSetting.id, index)}>
                  <img src={require('@/static/images/delete_yellow.svg')} alt={t('Delete')} />
                </div>
              </li>
            ))}
        </ul>
        <div css={plusButtonRowStyle} onClick={addAccountsReceivableSetting}>
          <div>
            <img src={require('@/static/images/plus.svg')} alt={t('Add subject')} />
            <p>{t('Add subject')}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export const codeWidthStyle = css({
  width: '11%',
  fontSize: 14,
})

const nameWidthStyle = css({
  width: '35%',
  fontSize: 14,
})

const channelCodeWidthStyle = css({
  width: '35%',
  fontSize: 14,
})

const closingDateidthStyle = css({
  width: '14%',
})

const accountsReceivableSettingEditHeaderStyle = css({
  background: '#F5F5F5',
  color: '#676767',
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  padding: '10px 32px',
})

const accountsReceivableSettingEditFormStyle = css({
  padding: '24px 32px 12px',
  li: {
    position: 'relative',
    display: 'flex',
    padding: '0 0 16px 0',
    div: {
      display: 'flex',
      paddingRight: 16,
      '&.code': codeWidthStyle,
      '&.name': nameWidthStyle,
      '&.channelCode': channelCodeWidthStyle,
      '&.closingDate': closingDateidthStyle,
    },
    input: {
      '&::placeholder': {
        color: '#CCCCCC',
      },
    },
  },
})

const inputStyle = css({
  width: '100%',
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  padding: '0 16px',
})

const selectBoxStyle = css(inputStyle, {
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '95% center',
  backgroundSize: 9,
  display: 'block',
  padding: '0 32px 0 16px',
})

const deleteButtonStyle = css({
  position: 'absolute',
  right: 0,
  paddingRight: 0,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  img: {
    width: 30,
    height: 30,
  },
})

const plusButtonRowStyle = css({
  padding: '0px 32px 0',
  div: {
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    cursor: 'pointer',
    p: {
      color: '#F2A40B',
      fontSize: 12,
      paddingLeft: 10,
    },
    img: {
      width: 20,
      height: 20,
    },
  },
})

const scrollStyle = css({
  'overflow-y': 'scroll',
  height: '68vh',
})
