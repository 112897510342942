import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form'
import { AssetSpaceDetailFormValue } from '@/components/organisms/settings/guest-app/space-detail-modal'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import uuid from 'react-uuid'
import { useDayjsRange } from '@/hooks/use-dayjs-range'
import { Hint } from '@/components/atoms/hint'

type Props = {
  control: Control<AssetSpaceDetailFormValue, any>
  errors: FieldErrors<AssetSpaceDetailFormValue>
}

export const InputUsageHours: React.FC<Props> = ({ control, errors }) => {
  const { t } = useTranslation()
  const { dayjsRange } = useDayjsRange()
  const watchUsageTimeStartHour = useWatch({ control, name: 'chargePrice.usageTime.startHour' })
  const watchUsageTimeStartMinute = useWatch({ control, name: 'chargePrice.usageTime.startMinute' })
  const watchUsageTimeEndHour = useWatch({ control, name: 'chargePrice.usageTime.endHour' })
  const watchUsageTimeEndMinute = useWatch({ control, name: 'chargePrice.usageTime.endMinute' })

  const makeSelectHourOption = (times: { value: string; label: string }[]) => {
    const pickedValue: string[] = []
    return (times || [])
      .map(d => {
        const item = d.value.split(':')[0]
        return { label: `${Number(item)}`, value: item }
      })
      .filter(d => {
        if (pickedValue.includes(d.value)) {
          return false
        }
        pickedValue.push(d.value)
        return true
      })
  }

  const makeSelectMinutesOption = (times: { value: string; label: string }[], hour: string) => {
    return (times || [])
      .filter(d => d.value.split(':')[0] === hour)
      .map(d => {
        const item = d.value.split(':')[1]
        return { label: `${Number(item)}`, value: item }
      })
  }

  const defaultTimeLists = () => {
    const range = dayjsRange({ interval: 15, unit: 'minute' })
    return [
      ...range.map(r => ({
        label: r.format('HH:mm'),
        value: r.format('HH:mm'),
      })),
      { label: '24:00', value: '24:00' },
    ]
  }

  const watchUsageTimeStart = useMemo(() => {
    if (!watchUsageTimeEndHour) {
      return defaultTimeLists()
    }
    return defaultTimeLists().filter(time => {
      return (
        Number(watchUsageTimeEndHour) * 60 + Number(watchUsageTimeEndMinute || 0) >=
        Number(time.value.split(':')[0]) * 60 + Number(time.value.split(':')[1])
      )
    })
  }, [watchUsageTimeEndHour, watchUsageTimeEndMinute])

  const watchUsageTimeEnd = useMemo(() => {
    if (!watchUsageTimeStartHour) {
      return defaultTimeLists()
    }
    return defaultTimeLists().filter(time => {
      return (
        Number(watchUsageTimeStartHour) * 60 + Number(watchUsageTimeStartMinute || 0) <=
        Number(time.value.split(':')[0]) * 60 + Number(time.value.split(':')[1])
      )
    })
  }, [watchUsageTimeStartHour, watchUsageTimeStartMinute])

  return (
    <>
      <div css={inputTitleTextStyle}>
        {t('Usage time')}
        <Hint
          src={require('@/static/images/space/help_time.svg')}
          customCss={css({
            paddingLeft: 8,
            position: 'static',
            '.help-image': { top: 0 },
          })}
        />
      </div>

      <div css={inputAreaStyle}>
        <div className="label">{t('Min')}</div>
        <div className="select-box">
          <Controller
            name="chargePrice.usageTime.startHour"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select placeholder="0" placeHolderColor="#bfbfbf" value={value} onChange={onChange} customStyle={css({ paddingBottom: 12 })}>
                {makeSelectHourOption(watchUsageTimeStart).map(({ value, label }) => (
                  <AntSelect.Option key={uuid()} value={value}>
                    {label}
                  </AntSelect.Option>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="unit">{t('Hours')}</div>
        <div className="select-box">
          <Controller
            name="chargePrice.usageTime.startMinute"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select placeholder="0" placeHolderColor="#bfbfbf" value={value} onChange={onChange} customStyle={css({ paddingBottom: 12 })}>
                {makeSelectMinutesOption(watchUsageTimeStart, watchUsageTimeStartHour).map(({ value, label }) => (
                  <AntSelect.Option key={uuid()} value={value}>
                    {label}
                  </AntSelect.Option>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="unit">{t('Minutes')}</div>
      </div>

      <div css={inputAreaStyle}>
        <div className="label">{t('Max')}</div>
        <div className="select-box">
          <Controller
            name="chargePrice.usageTime.endHour"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select placeholder="0" placeHolderColor="#bfbfbf" value={value} onChange={onChange} customStyle={css({ paddingBottom: 16 })}>
                {makeSelectHourOption(watchUsageTimeEnd).map(({ value, label }) => (
                  <AntSelect.Option key={uuid()} value={value}>
                    {label}
                  </AntSelect.Option>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="unit">{t('Hours')}</div>
        <div className="select-box">
          <Controller
            name="chargePrice.usageTime.endMinute"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select placeholder="0" placeHolderColor="#bfbfbf" value={value} onChange={onChange} customStyle={css({ paddingBottom: 16 })}>
                {makeSelectMinutesOption(watchUsageTimeEnd, watchUsageTimeEndHour).map(({ value, label }) => (
                  <AntSelect.Option key={uuid()} value={value}>
                    {label}
                  </AntSelect.Option>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="unit">{t('Minutes')}</div>
      </div>
    </>
  )
}

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
  alignItems: 'center',
})

const inputAreaStyle = css({
  display: 'flex',
  '.label, .unit': {
    fontSize: 12,
    letterSpacing: 0.6,
    color: '#676767',
    paddingRight: 8,
    lineHeight: '32px',
  },
  '.label': { fontWeight: 'bold' },
  '.select-box': { margin: '0 8px', width: 67 },
})
