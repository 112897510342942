import React, { createContext } from 'react'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const setAuth = data => {
    if (data?.idToken) {
      localStorage.setItem('idToken', data.idToken)
    } else {
      localStorage.removeItem('idToken')
    }
  }

  return (
    <AuthContext.Provider
      value={{
        auth: localStorage.getItem('idToken'),
        setAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
