import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'

type Props = {
  to: string
  title: string
  activePattern?: string
}
export const SettingSideMenuItem: React.FC<Props> = ({ to, title, activePattern }) => {
  const history = useHistory()
  const location = useLocation()
  const [isActiveLink, setIsActiveLink] = useState<boolean>(false)

  useEffect(() => {
    setIsActiveLink(window.location.pathname.match(new RegExp(activePattern || to || '')) !== null)
  }, [activePattern, to])

  return (
    <div
      css={isActiveLink ? linkWrapperActiveStyle : linkWrapperStyle}
      onClick={() => history.push({ pathname: to, search: location.search })}
    >
      <div css={isActiveLink ? linkContentsActiveStyle : linkContentsStyle}>{title}</div>
    </div>
  )
}

const linkWrapperStyle = css({
  height: 44,
  display: 'flex',
  padding: '0 16px 0 13px',
  cursor: 'pointer',
  ':hover': {
    background: '#f2f2f2',
  },
})

const linkWrapperActiveStyle = css(linkWrapperStyle, {
  background: '#f2f2f2',
  padding: '0 16px 0 10px',
})

const linkContentsStyle = css({
  lineHeight: '44px',
  marginLeft: 12,
  fontWeight: 'bold',
  color: '#676767',
  fontSize: 12,
  justifyContent: 'space-between',
  display: 'flex',
  width: '100%',
})

const linkContentsActiveStyle = css(linkContentsStyle, {
  color: '#F2A40B',
})
