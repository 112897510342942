import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

export const ErrorNotice = () => {
  const { t } = useTranslation()
  return (
    <div css={containerStyle}>
      <span css={messageStyle}>{t('There is no reservation information Check it again please')}</span>
    </div>
  )
}

const containerStyle = css({
  width: '100%',
  backgroundColor: '#fff',
  padding: '30px',
  borderRadius: '5px',
  height: 'auto',
  textAlign: 'center',
})

const messageStyle = css({
  fontSize: '16px',
})
