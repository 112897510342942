import React, { useState, useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import dayjs from 'dayjs'

// contexts
import { AccountContext } from '@/contexts/account'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle, tableHeaderThStyle } from '@/constants/layout'

// apis
import {
  createCustomerTag,
  fetchCustomerHistory,
  fetchCustomerInfo,
  updateAdminCheckinList,
  updateCustomerInfo,
  updateCustomerTag,
} from '@/apis/aipass'

// libs
import { setHasRepeatPlugin } from '@/libs/plugins'
import { convertToSeparatedName } from '@/libs/convertToSeparatedName'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { itemWrapperStyle, PersonInfo } from '@/components/organisms/customer/_detail/person-info'
import { Button } from '@/components/atoms/button'
import { EditCustomerInfoModal } from '@/components/organisms/customer/_detail/edit-customer-info-modal'
import { CustomerList } from '@/components/pages/customer-manager/_detail/customer-list'

// models
import { CustomerReservationType, CustomerType } from '@/models/customer-manager/customer'
import { GuestInput, GuestInputErrors } from '@/models/guest-input'
import { CustomerTagType } from '@/models/customer-manager/customer-tag'
import { CustomerTagInput } from '@/components/molecules/customer/customer-tag-input'

export const CustomerDetail: React.FC = () => {
  const guestInputInitialData: GuestInput = {
    isJapanese: 'isJapanese',
    nationality: '',
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    birthDate: '',
    gender: 'F',
    address: '',
    telephone: '',
    email: '',
    occupation: '',
    company: '',
    otherOccupation: '',
  }
  const guestInputErrorsInitialData: GuestInputErrors = {
    nationality: null,
    firstName: null,
    lastName: null,
    firstNameKana: null,
    lastNameKana: null,
    birthDate: null,
    address: null,
    telephone: null,
    email: null,
    occupation: null,
    company: null,
    otherOccupation: null,
  }
  const windows = useWindowSize()
  const { t } = useTranslation()
  const { plugins, account } = useContext<any>(AccountContext)
  const history = useHistory()
  const params = useParams<any>()
  const { id } = params
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // Customer information
  const [customer, setCustomer] = useState<CustomerType>()

  // Edit customer information
  const [guestInput, setGuestInput] = useState<GuestInput>(guestInputInitialData)
  const [guestInputErrors, setGuestInputErrors] = useState<GuestInputErrors>(guestInputErrorsInitialData)
  const [showInput, setShowInput] = useState<boolean>(false)
  const [isOpenEditCustomerInfoModal, setIsOpenEditCustomerInfoModal] = useState<boolean>(false)

  // Accommodation history list
  const [reservations, setReservations] = useState<CustomerReservationType[]>([])

  // Editing tags (after binding)
  const [isEditTag, setIsEditTag] = useState<boolean>(false)
  // Tag value (after binding)
  const [tags, setTags] = useState<string[]>([])

  // Whether it is a repeat management plugin
  const hasRepeatPlugin = setHasRepeatPlugin(plugins)

  const onSelect = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target
    setGuestInput(guestInput => ({ ...guestInput, [name]: value }))
    if (name === 'occupation' && value === 'その他') {
      setShowInput(true)
      return
    } else if (name === 'occupation' && value !== 'その他') {
      setShowInput(false)
      return
    }

    if (value !== '') {
      setGuestInputErrors(guestInputErrors => ({ ...guestInputErrors, [name]: null }))
      return
    }
  }

  const onChangeGuestInfo = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target
    setGuestInput(guestInput => ({ ...guestInput, [name]: value }))
  }

  const onChangeBirthDate = (value: string): void => {
    setGuestInput(guestInput => ({ ...guestInput, birthDate: value }))

    if (value !== '') {
      setGuestInputErrors(guestInputErrors => ({ ...guestInputErrors, birthDate: null }))
    }
  }

  const onClickModalCancel = (): void => {
    setIsOpenEditCustomerInfoModal(false)
    setGuestInputErrors(guestInputErrorsInitialData)
  }

  const onClickModalSave = (): void => {
    // Validation for phone number (half-width numbers only)
    if (/[^0-9]/g.test(guestInput.telephone)) {
      window.alert(t('Please enter the phone number in half-width numbers'))
      return
    }
    _updateCustomerInfo()
  }

  const _updateCustomerInfo = async () => {
    setIsLoading(true)
    const {
      nationality,
      firstName,
      lastName,
      lastNameKana,
      firstNameKana,
      birthDate,
      gender,
      address,
      telephone,
      email,
      occupation,
      company,
      otherOccupation,
    } = guestInput
    const customerVariables = {
      name: lastName || firstName ? `${lastName} ${firstName}` : '',
      nameKana: lastNameKana || firstNameKana ? `${lastNameKana} ${firstNameKana}` : '',
      birthDate: birthDate ? dayjs(birthDate).format('YYYY-MM-DD') : null,
      nationality: nationality,
      gender,
      postalCode: null,
      address,
      telephone: telephone ? telephone : '0',
      email: email ? email : null,
      occupation,
      company,
      otherOccupation,
      picture: customer?.picture,
      hotelCuicinUserId: customer?.id,
    }
    await updateCustomerInfo(customerVariables)
      .then(() => {
        setIsOpenEditCustomerInfoModal(false)
        _fetchCustomerInfo()
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const _fetchCustomerInfo = async () => {
    setIsLoading(true)
    await fetchCustomerInfo(id)
      .then(res => {
        setCustomer(res)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const _fetchCustomerHistory = async () => {
    setIsLoading(true)
    await fetchCustomerHistory(id)
      .then(res => {
        if (!res?.reservations?.length) return

        setReservations(
          res.reservations.reduce((sum, reservation) => {
            const existsReservation = sum.find(r => r.accommodationId === reservation.accommodationId)

            if (existsReservation) {
              if (!existsReservation['relatedReservations']) existsReservation['relatedReservations'] = [reservation]
              else existsReservation['relatedReservations'].push(reservation)

              return sum
            } else {
              return [...sum, reservation]
            }
          }, []),
        )
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // Entering tags
  const onChangeTag = v => {
    setIsEditTag(true)
    setTags(v)
  }

  const onClickCancelTag = () => {
    setIsEditTag(false)
    setTags(customer?.tagInfo?.tags?.map(tagInfo => tagInfo.id) || [])
  }

  // Tag update
  const onClickSaveTag = async () => {
    if (!customer) {
      return
    }

    setIsLoading(true)
  }

  useEffect(() => {
    if (account && plugins) {
      _fetchCustomerInfo()
      _fetchCustomerHistory()
    }
  }, [account, plugins])

  useEffect(() => {
    if (customer) {
      updateAdminCheckinList({ id: customer.id, mode: 'customerRead' }).catch(() => {
        console.log(t('Communication failed'))
      })
      const [customerFirstName, customerLastName] = convertToSeparatedName({ name: customer?.name || '' })
      const [customerFirstNameKana, customerLastNameKana] = convertToSeparatedName({ name: customer?.nameKana || '' })

      setGuestInput({
        isJapanese: customer.nationality !== 'JPN' ? 'isNonJapanese' : 'isJapanese',
        nationality: customer.nationality || 'JPN',
        lastName: customerLastName || '',
        firstName: customerFirstName || '',
        lastNameKana: customerLastNameKana || '',
        firstNameKana: customerFirstNameKana || '',
        birthDate: customer.birthDate ? customer.birthDate : '',
        gender: customer.gender,
        address: customer.address ? customer.address : '',
        telephone: customer.telephone ? customer.telephone : '',
        email: customer.email ? customer.email : '',
        occupation: customer.occupation ? customer.occupation : '',
        company: customer.company ? customer.company : '',
        otherOccupation: customer.otherOccupation ? customer.otherOccupation : '',
      })
      setTags(customer?.tagInfo?.tags?.map(tagInfo => tagInfo.id) || [])
    }
    if (customer?.occupation === 'その他') {
      setShowInput(true)
    }
  }, [customer])

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div css={mainColumnStyle}>
        <Header title={t('Customer management')} goBack={() => history.goBack()} isBack />
        <div css={mainContainerStyle}>
          <div css={containerWrapperStyle}>
            <div css={leftContainerStyle}>
              <PersonInfo customer={customer} onClickEditCustomerInfo={() => setIsOpenEditCustomerInfoModal(true)} />
            </div>
            <div css={rightContainerStyle}>
              <div css={itemWrapperStyle}>
                <img src={require('@/static/images/stay.svg')} />
                {hasRepeatPlugin ? (
                  <p>
                    {t('Number of nights stayed')} <span>{customer?.stayCount}</span>
                  </p>
                ) : (
                  <p>-</p>
                )}
              </div>
              <div css={itemWrapperStyle}>
                <div css={inputWrapperStyle}>{customer && <CustomerTagInput customerId={customer.id} customerTags={tags} />}</div>
              </div>
              {isEditTag && (
                <div css={editButtonWrapperStyle}>
                  <Button width={100} height={32} buttonType={3} fontSize={12} marginRight={16} onClick={onClickCancelTag}>
                    {t('Cancel')}
                  </Button>
                  <Button width={58} height={32} buttonType={1} fontSize={12} onClick={onClickSaveTag}>
                    {t('Save')}
                  </Button>
                </div>
              )}
            </div>
          </div>

          <div css={[listContainerStyle]}>
            <div css={listHeaderStyle}>
              <div css={listTitleStyle}>{t('Accommodation history')}</div>
            </div>
            <div
              css={{
                overflow: 'auto',
                height: windows.height - 350,
                backgroundColor: '#fff',
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
              }}
            >
              <table css={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th css={[tableHeaderThStyle, { paddingLeft: 32, width: 200 }]}>{t('Date of stay')}</th>
                    <th css={[tableHeaderThStyle, { width: 140 }]}>{t('Accommodation ID')}</th>
                    <th css={[tableHeaderThStyle, { width: 100 }]}>{t('Number of people')}</th>
                    <th css={[tableHeaderThStyle, { width: 200 }]}>{t('Plan name')}</th>
                    <th css={[tableHeaderThStyle, { width: 300 }]}>{t('Room type / Number of rooms')}</th>
                    <th css={[tableHeaderThStyle, { width: 24 }]}>{t('Evaluation')}</th>
                    <th css={[tableHeaderThStyle, { width: 100 }]}>{t('Earnings')}</th>
                    <th css={[tableHeaderThStyle, { width: 340 }]}>{t('Memo')}</th>
                  </tr>
                </thead>
                <tbody css={{ overflowY: 'auto' }}>
                  {reservations &&
                    reservations?.map((reservation, index) => (
                      <CustomerList key={reservation.accommodationId} reservation={reservation} index={index} />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          {isOpenEditCustomerInfoModal && (
            <EditCustomerInfoModal
              showInput={showInput}
              guestInput={guestInput}
              guestInputErrors={guestInputErrors}
              onChangeGuestInfo={onChangeGuestInfo}
              onSelect={onSelect}
              onChangeBirthDate={onChangeBirthDate}
              onClickModalCancel={onClickModalCancel}
              onClickModalSave={onClickModalSave}
            />
          )}
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const containerWrapperStyle = css({
  display: 'flex',
})

const sectionStyle = css({
  width: '30%',
  background: '#FFFFFF',
  boxShadow: '0px 0px 6px #0000001A',
  display: 'flex',
  borderRadius: 5,
  padding: '24px 16px 24px 32px',
})

const leftContainerStyle = css(sectionStyle, {
  marginRight: 16,
  width: '70%',
  justifyContent: 'space-between',
  overflow: 'hidden',
})

const rightContainerStyle = css(sectionStyle, {
  width: '30%',
  flexDirection: 'column',
  backgroundColor: '#FFF',
  justifyContent: 'center',
})

const listContainerStyle = css({
  marginTop: 16,
  boxShadow: '0px 0px 6px #0000001A',
})

const listHeaderStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 32px',
  height: 50,
  alignItems: 'center',
  backgroundColor: '#FFF',
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
})

const listTitleStyle = css({
  color: '#272727',
  fontSize: 18,
  fontWeight: 'bold',
  padding: '8px 0',
})

const inputStyle = css({
  padding: '9px 16px',
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  fontSize: 14,
  lineHeight: '14px',
  minHeight: 88,
  width: '100%',
  '::placeholder': {
    color: '#CCCCCC',
  },
})

const inputWrapperStyle = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const editButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
})
