export const DeviceType = {
  printer: 1,
}
export type DeviceType = typeof DeviceType[keyof typeof DeviceType]

export const SendType = {
  order: 1,
  selfCheckin: 2,
}
export type SendType = typeof SendType[keyof typeof SendType]

export type deviceSettingResponseType = {
  deviceSettings: deviceSettingsType[]
}

export type deviceSettingsType = {
  id: string
  hotelId: string
  deviceId: string
  deviceType: DeviceType
  sendType: SendType
  deviceName: string
  ipAddress: string
  port: number
  createdAt: Date
  updatedAt: Date
}