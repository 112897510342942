import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// apis
import { fetchSalesPayment, updateSalesPayment } from '@/apis/aipass'

// components
import { SettingPaymentEditForm } from '@/components/organisms/settings/sales-manager/payment/edit-form'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { EditFooter } from '@/components/organisms/edit-footer'

type ContainerProps = {}

export const SettingSalesManagerPaymentEdit: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()

  const [paymentSettings, setPaymentSettings] = useState<any>()
  const [paymentList, setPaymentList] = useState<any>()
  const [deteledPaymentSettings, setDeteledPaymentSettings] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()
  const location = useLocation()

  const onChangeState = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index) => {
    const value = e.target.value
    const name = e.target.name
    const newPaymentSettings = [...paymentSettings]
    newPaymentSettings[index][name] = value
    setPaymentSettings(newPaymentSettings)
  }

  const deletePaymentSetting = (id: number, index: number) => {
    // Remove deleted elements from the displayed array
    const newPaymentSettings = [...paymentSettings]
    newPaymentSettings.splice(index, 1)
    if (id) {
      const newDeteledPaymentSettings = [...deteledPaymentSettings, { id: id }]
      setDeteledPaymentSettings(newDeteledPaymentSettings)
    }
    setPaymentSettings(newPaymentSettings)
  }

  const addPaymentSetting = () => {
    const newPaymentSetting = {
      methodCode: '',
      name: '',
      commissionRate: '',
      isAccountsReceivable: '',
      tax: '',
      taxClass: '',
      displayOrder: Math.max(...(paymentSettings || []).map(p => p.displayOrder)) + 1,
    }
    setPaymentSettings([...paymentSettings, newPaymentSetting])
  }

  const onSavePaymentSettings = () => {
    setIsLoading(true)
    let fixedPaymentSettings = JSON.parse(JSON.stringify(paymentSettings))
    // Compare with data before change and delete from fixed array if the contents are the same
    paymentList.forEach(payment => {
      const fixedPaymentIndex = fixedPaymentSettings.findIndex(item => item?.id === payment.id)
      if (fixedPaymentIndex === -1) return

      const fixedPayment = fixedPaymentSettings[fixedPaymentIndex]
      if (
        payment.name === fixedPayment.name &&
        payment.commissionRate === fixedPayment.commissionRate &&
        payment.methodCode === fixedPayment.methodCode &&
        payment.isAccountsReceivable === fixedPayment.isAccountsReceivable &&
        payment.tax === fixedPayment.tax &&
        payment.taxClass === fixedPayment.taxClass &&
        payment.displayOrder === fixedPayment.displayOrder
      ) {
        fixedPaymentSettings.splice(fixedPaymentIndex, 1)
      }
    })

    fixedPaymentSettings = fixedPaymentSettings.map(fixePaymentSetting => {
      fixePaymentSetting.commissionRate = Number(fixePaymentSetting.commissionRate)
      fixePaymentSetting.tax = Number(fixePaymentSetting.tax)
      if (fixePaymentSetting.isAccountsReceivable) {
        fixePaymentSetting.isAccountsReceivable = Number(fixePaymentSetting.isAccountsReceivable)
      }
      return fixePaymentSetting
    })

    // Join id of deleted data
    fixedPaymentSettings = [...fixedPaymentSettings, ...deteledPaymentSettings]
    updateSalesPayment(fixedPaymentSettings)
      .then(() => {
        history.push({ pathname: '/setting/sales-manager/payment', search: location.search })
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const _fetchSalesPayment = async () => {
    setIsLoading(true)
    await fetchSalesPayment()
      .then(res => {
        if (!res?.salesPayment) {
          console.warn('salesPayment is empty')

          setPaymentSettings([])
          setPaymentList([])
          return
        }

        setPaymentSettings(res?.salesPayment)
        // Deposit setting list before change
        setPaymentList(JSON.parse(JSON.stringify(res?.salesPayment)))
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  useEffect(() => {
    _fetchSalesPayment()
  }, [])

  const footerContent = <EditFooter onSave={onSavePaymentSettings} onCancel={history.goBack} />

  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <>
        <div css={settingPaymentEditContainerStyle}>
          <div css={settingPaymentEditHeaderStyle}>
            <p>{t('Payment')}</p>
          </div>
          {paymentSettings && (
            <SettingPaymentEditForm
              paymentSettings={paymentSettings}
              onChangeState={onChangeState}
              deletePaymentSetting={deletePaymentSetting}
              addPaymentSetting={addPaymentSetting}
            />
          )}
        </div>
      </>
    </SettingsLayout>
  )
}

const settingPaymentEditContainerStyle = css({
  height: '100%',
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
  background: '#FFF',
})

const settingPaymentEditHeaderStyle = css({
  height: 50,
  padding: '16px 32px',
  borderBottom: '1px solid #F2F2F2',
  p: {
    fontSize: 18,
    fontWeight: 'bold',
  },
})
