import React from 'react'
import { SettingTab } from '@/components/molecules/settings/setting-tab'
import { listContainerStyle } from '@/constants/layout'
import { css } from '@emotion/core'

export const PrinterTab: React.FC<{ currentTab: 'Self check-in' | 'Request' }> = ({ currentTab, children }) => {
  const tabs = [
    {
      name: 'Self check-in',
      path: '/setting/printer/self-checkin',
    },
    {
      name: 'Request',
      path: '/setting/printer/request',
    },
  ]

  return (
    <SettingTab currentTab={currentTab} tabs={tabs} customTabStyle={css({ width: 160 })}>
      <div css={topImageContainerStyle}>{children}</div>
    </SettingTab>
  )
}

const topImageContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 'auto',
  marginBottom: -10,
})
