import React from 'react'
import { css } from '@emotion/core'

type facilityInfoListProps = {
  statusInfo: string
  onChange: (value: any) => void
  name: string
  value: number
  changeState: (e, i) => void
  index: number
  iconStatus: any
  iconStatusName: string
}

export const IconSettingList: React.FC<facilityInfoListProps> = ({ name, changeState, index, iconStatus, iconStatusName }) => {
  return (
    <>
      <div css={facilityInfoListContainerStyle}>
        <div css={facilityInfoListNameStyle}>{iconStatusName}</div>
        <div css={facilityInfoListRightContainerStyle}>
          <input
            name={name}
            value={iconStatus}
            type="checkbox"
            id={name}
            css={facilityCheckboxStyle}
            checked={!!iconStatus}
            onChange={e => changeState(e, index)}
          />
          <label htmlFor={name} css={facilityCheckboxLabelStyle} />
        </div>
      </div>
    </>
  )
}

const facilityInfoListContainerStyle = css({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 64,
  borderBottom: '1px solid #F2F2F2',
  padding: '0 32px',
})

const facilityInfoListNameStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  width: '100%',
  padding: '25px 0px',
  height: '100%',
})

const facilityInfoListRightContainerStyle = css({
  display: 'flex',
  img: {
    display: 'block',
    marginLeft: 27,
    padding: 4,
    ':hover': {
      borderRadius: '50%',
      background: '#F2F2F2',
      cursor: 'pointer',
    },
  },
})

export const facilityCheckboxStyle = css({
  display: 'none',
  ':checked': {
    '+label': {
      backgroundColor: '#f2a40b',
      border: 'none',
      position: 'relative',

      '&::before': {
        content: "'表示'",
        color: '#fff',
        fontSize: 12,
        letterSpacing: 1.2,
        left: 15,
        position: 'absolute',
      },
      '&::after': {
        top: 3,
        left: 47,
        backgroundColor: '#fff',
      },
    },
  },
})

export const facilityCheckboxLabelStyle = css({
  width: 73,
  height: 28,
  background: '#F2F2F2',
  position: 'relative',
  display: 'inline-block',
  borderRadius: 14,
  transition: '0.4s',
  boxSizing: 'border-box',
  border: '1px solid #ccc',
  cursor: 'pointer',

  '&::before': {
    content: "'非表示'",
    fontWeight: 'bold',
    color: '#a3a3a3',
    fontSize: 11,
    letterSpacing: 0.55,
    left: 30,
    position: 'absolute',
    top: 7,
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    width: 22,
    height: 22,
    borderRadius: '100%',
    top: 2,
    left: 4,
    zIndex: 2,
    background: '#fff',
    transition: '0.4s',
    boxShadow: '0px 2px 3px #00000029',
  },
})
