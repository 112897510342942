import React from 'react'
import { css, CSSObject } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import { GuestRoomAssignResponseType } from '@/models/guest-room/guest-room-assign'

type roomTypeSelectProps = {
  room: GuestRoomAssignResponseType['rooms'][0]
  roomIndex: number
  guestRoomTypeList: (
    roomInfo: GuestRoomAssignResponseType['rooms'][0],
  ) => {
    id: number
    name: string
    value: string
  }[]
  onEditRoomType: (event: React.ChangeEvent<HTMLSelectElement>, selectIndex: number) => void
  isEdit: boolean
  cssStyles?: CSSObject
}

export const RoomTypeSelect: React.FC<roomTypeSelectProps> = ({
  room,
  roomIndex,
  guestRoomTypeList,
  onEditRoomType,
  isEdit,
  cssStyles,
}) => {
  const { t, i18n } = useTranslation()
  const addRoomSelectboxStyle = css({
    color: '#272727',
    padding: '0 10px',
    height: 32,
    border: '1px solid #CCCCCC',
    borderRadius: 17,
    marginRight: 16,
    appearance: 'none',
    backgroundImage: isEdit ? `url(${require('@/static/images/select.svg')})` : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '95% center',
    backgroundSize: 10,
    maxWidth: `calc(100% - 16px)`,
  })

  const addRoomTypeSelectboxStyle = css(addRoomSelectboxStyle, {
    backgroundPosition: '96% center',
  })

  console.log({ cssStyles })

  return (
    <>
      <select
        name={`roomType[${roomIndex}]`}
        css={[addRoomTypeSelectboxStyle, cssStyles]}
        value={room && room.typeNameJa ? (i18n.language === 'ja' ? room.typeNameJa : room.typeNameEn ?? '') : String(t('Please select'))}
        onChange={event => onEditRoomType(event, roomIndex)}
      >
        {guestRoomTypeList(room).map(roomTypeInfo => (
          <option key={roomTypeInfo.id} value={roomTypeInfo.value}>
            {roomTypeInfo.name}
          </option>
        ))}
      </select>
    </>
  )
}
