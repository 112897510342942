import React, { useMemo } from 'react'
import { css } from '@emotion/core'
import uuid from 'react-uuid'
import i18n from '@/i18n'

type Props = {
  domId?: string
  value: boolean
  onChange: () => void
  label?: { on: string; off: string }
}
export const ToggleButton: React.FC<Props> = ({ domId = uuid(), value, onChange, label }) => {
  const buttonStyle = useMemo(() => {
    return css({
      display: 'flex',
      img: {
        display: 'block',
        marginLeft: 27,
        padding: 4,
        ':hover': {
          borderRadius: '50%',
          background: '#F2F2F2',
          cursor: 'pointer',
        },
      },
      '.input': {
        display: 'none',
        ':checked': {
          '+label': {
            backgroundColor: '#f2a40b',
            border: 'none',
            position: 'relative',

            '&::before': {
              content: `'${label?.on || i18n.t('Display')}'`,
              color: '#fff',
              fontSize: 12,
              letterSpacing: 1.2,
              left: 15,
              position: 'absolute',
            },
            '&::after': {
              top: 3,
              left: 47,
              backgroundColor: '#fff',
            },
          },
        },
      },
      '.label': {
        width: 73,
        height: 28,
        background: '#F2F2F2',
        position: 'relative',
        display: 'inline-block',
        borderRadius: 14,
        transition: '0.4s',
        boxSizing: 'border-box',
        border: '1px solid #ccc',
        cursor: 'pointer',

        '&::before': {
          content: `'${label?.off || i18n.t('Hidden')}'`,
          fontWeight: 'bold',
          color: '#a3a3a3',
          fontSize: 11,
          letterSpacing: 0.55,
          left: 30,
          position: 'absolute',
          top: 7,
        },
        '&::after': {
          content: "''",
          position: 'absolute',
          width: 22,
          height: 22,
          borderRadius: '100%',
          top: 2,
          left: 4,
          zIndex: 2,
          background: '#fff',
          transition: '0.4s',
          boxShadow: '0px 2px 3px #00000029',
        },
      },
    })
  }, [label])
  return (
    <div css={buttonStyle}>
      <input type="checkbox" id={domId} className="input" onClick={e => e.stopPropagation()} onChange={onChange} checked={value} />
      <label htmlFor={domId} className="label" onClick={e => e.stopPropagation()} />
    </div>
  )
}
