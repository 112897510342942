import React from 'react'
import { PieChart, Pie, Cell } from 'recharts'
import { css } from '@emotion/core'

type OverAllNpsProps = {
  tab: any
  renderPlus: () => void
  renderRightGray: () => void
  renderMinus: () => void
  renderLeftGray: () => void
  renderValue: () => void
  renderArrow: () => void
  overallNpsValue: any
  data: any
  overallNpsRatio: any
}

export const OverAllNps: React.FC<OverAllNpsProps> = ({
  tab,
  renderPlus,
  renderRightGray,
  renderMinus,
  renderLeftGray,
  renderValue,
  renderArrow,
  overallNpsValue,
  data,
  overallNpsRatio,
}) => {
  return (
    <>
      {tab === 'overall' && (
        <div css={overallStyle}>
          <div css={innerStyle}>
            <div css={valueStyle}>
              <div css={chartStyle}>
                {renderPlus()}
                {renderRightGray()}
                {renderMinus()}
                {renderLeftGray()}
                {renderValue()}
                {renderArrow()}
                {overallNpsValue >= -90 && <p css={scaleMinStyle}>-100</p>}
                {overallNpsValue !== 0 && <p css={scaleMiddleStyle}>0</p>}
                {overallNpsValue <= 90 && <p css={scaleMaxStyle}>+100</p>}
              </div>
            </div>
            <div css={lineStyle}>
              <div css={straightStyle}></div>
              <div css={diagonalStyle}></div>
              <div css={straightStyle}></div>
            </div>
            <div css={ratioStyle}>
              <div css={chartStyle}>
                <PieChart width={70} height={70}>
                  <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    startAngle={450}
                    endAngle={90}
                    labelLine={false}
                    outerRadius={35}
                    dataKey="value"
                    strokeWidth={0}
                    isAnimationActive={false}
                  >
                    {overallNpsRatio.map((nps, index) => (
                      <Cell key={`cell-${index}`} fill={nps.pieColor || nps.color} />
                    ))}
                  </Pie>
                </PieChart>
              </div>
              <div css={labelStyle}>
                <ul css={labelUlStyle}>
                  {overallNpsRatio.map((nps, index) => (
                    <li key={index} css={labelLiStyle}>
                      <p style={{ color: nps.color }}>{nps.name}</p>
                      <p style={{ color: nps.color }}>{`${nps.value}%`}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

// overallStyle
const overallStyle = css({})

const innerStyle = css({
  width: 267,
  margin: '0 auto',
})

const valueStyle = css({
  width: '100%',
  height: 96,
  padding: '0 23px',
  margin: '28px 0 24px 0',
  overflow: 'hidden',
})

const chartStyle = css({
  position: 'relative',
})

const scaleBaseStyle = css({
  position: 'absolute',
  fontSize: 11,
  color: '#CCCCCC',
  fontWeight: 'bold',
  letterSpacing: 0.55,
})

const scaleMinStyle = css(scaleBaseStyle, {
  top: 85,
  left: 0,
})

const scaleMiddleStyle = css(scaleBaseStyle, {
  top: 0,
  left: 106,
})

const scaleMaxStyle = css(scaleBaseStyle, {
  top: 85,
  left: 193,
})

const lineStyle = css({
  display: 'flex',
  alignItems: 'baseline',
})

const straightStyle = css({
  width: 140,
  height: 7,
  borderBottom: '1px #F2F2F2 solid',
})

const diagonalStyle = css({
  width: 10,
  height: 10,
  marginRight: 4,
  border: '1px #F2F2F2 solid',
  borderWidth: '1px 0 0 1px',
  transform: 'rotate(45deg)',
  transformOrigin: 'bottom left',
})

const ratioStyle = css({
  width: '100%',
  paddingTop: 24,
  display: 'flex',
})

const labelStyle = css({
  margin: '5px 0 0 22px',
})

const labelUlStyle = css({
  listStyle: 'none',
})

const labelLiStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  width: 170,
  paddingLeft: 21,
  p: {
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: 0.6,
  },
  '&+li': {
    marginTop: 12,
  },
  '&::before': {
    width: 9,
    height: 9,
    borderRadius: '50%',
    content: "''",
    position: 'absolute',
    top: 1,
    left: 0,
  },
  '&:nth-of-type(1)': {
    '&::before': {
      background: '#CCCCCC',
    },
  },
  '&:nth-of-type(2)': {
    '&::before': {
      background: '#EBD41D',
    },
  },
  '&:nth-of-type(3)': {
    '&::before': {
      background: '#F2A40B',
    },
  },
})
