import React from 'react'

interface CustomerTagProps {
  fontColor: string
  bgColor: string
  name: string
}

export const CustomerTag: React.FC<CustomerTagProps> = ({ fontColor, bgColor, name }) => {
  return (
    <span style={{ background: bgColor, color: fontColor, fontSize: 10, fontWeight: 'bold', padding: '6px 8px', height: 24, borderRadius: 12 }}>
      {name}
    </span>
  )
}
