import React from 'react'
import { CompositeDecorator } from 'draft-js'
import { StyleType } from '@/components/organisms/settings/editor'

const className = 'custom-design-link'

const linkComponent = (props: any) => {
  const style = { color: '#A69785', textDecoration: 'underline' }
  const { url } = props.contentState.getEntity(props.entityKey).getData()
  return (
    <a href={url} style={style}>
      {props.children}
    </a>
  )
}
const hrComponent = () => {
  return <hr></hr>
}

const renderComponent = (props: any) => {
  const entity = props.contentState.getEntity(props.entityKey)
  if (entity.type === StyleType.Link) {
    return linkComponent(props)
  }
  if (entity.type === StyleType.Hr) {
    return hrComponent()
  }
  return <></>
}

const findEntities = () => {
  return (contentBlock, callback, contentState) =>
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity()
      const decoratorType = [StyleType.Link, StyleType.Hr]
      return entityKey !== null && decoratorType.includes(contentState?.getEntity(entityKey)?.getType())
    }, callback)
}
export default class CustomDecorator extends CompositeDecorator {
  constructor() {
    super([
      {
        strategy: findEntities(),
        component: renderComponent,
      },
    ])
  }
}

export const linkEntityStyle = (url: string) => ({
  element: 'a',
  attributes: {
    href: url,
    target: '_blank',
    rel: 'noreferrer',
    class: className,
  },
  style: {},
})

export const hrEntityStyle = () => ({
  element: 'hr',
})
export const iframeEntityStyle = (src: string) => {
  let convertedSrc = src
  // YouTube
  const youtubePattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const youtubeMatch = src.match(youtubePattern)

  if (youtubeMatch && youtubeMatch[2]) {
    convertedSrc = `https://www.youtube.com/embed/${youtubeMatch[2]}`
  }

  // Vimeo
  const vimeoMatch = src.match(/(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:.*#|.*\/videos\/)?([0-9]+)/)
  if (vimeoMatch && vimeoMatch[1]) {
    convertedSrc = `https://player.vimeo.com/video/${vimeoMatch[1]}`
  }

  return {
    element: 'iframe',
    attributes: {
      src: `${convertedSrc}?playsinline=1&rel=0`,
      allow: 'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
      allowfullscreen: true,
      referrerpolicy: 'strict-origin-when-cross-origin',
    },
    style: {
      width: '100%',
      borderRadius: '20px',
      aspectRatio: '1.78 / 1',
    },
  }
}
