import React, { useState, useRef, Dispatch, SetStateAction, useContext } from 'react'
import 'draft-js/dist/Draft.css'
import { useTranslation } from 'react-i18next'
import { useFileUpload } from '@/hooks/use-file-upload'
import { AccountContext } from '@/contexts/account'
import uuid from 'react-uuid'

type Props = {
  onChange: (value: string) => void
}

export const EditorImagePopup: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation()
  const inputFileRef = useRef<HTMLInputElement>(null)

  const { account } = useContext<any>(AccountContext)
  const { upload: fileUpload } = useFileUpload()

  const allowImageMineType = ['image/jpeg', 'image/png']

  const onFileUpload = async (file: string) => {
    const uploadedPath = await fileUpload({
      hotelId: account.employee.hotelId,
      file: {
        name: `${uuid()}.jpeg`,
        type: 'image/jpeg',
      },
      fileData: file,
    })
    onChange(uploadedPath)
  }

  const loadImage = (file: File): Promise<HTMLImageElement> => {
    return new Promise(resolve => {
      const img = new Image()
      img.src = URL.createObjectURL(file)
      img.onload = () => {
        resolve(img)
      }
    })
  }

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files?.length) {
      return
    }
    if (!allowImageMineType.includes(files[0].type)) {
      window.alert(t('The only image formats that can be used are jpg and png'))
      return
    }
    const file = files[0]

    const img = await loadImage(file)
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')
    if (!ctx) return
    ctx.drawImage(img, 0, 0)
    onFileUpload(canvas.toDataURL('image/jpeg'))
  }

  return (
    <>
      <img
        src={require(`@/static/images/icon_file.svg`)}
        onClick={() => {
          if (inputFileRef.current) {
            inputFileRef.current.value = ''
            inputFileRef.current.click()
          }
        }}
      />
      <input type="file" ref={inputFileRef} onChange={e => handleChangeFile(e)} style={{ display: 'none' }} />
    </>
  )
}
