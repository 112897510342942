import React, { useState } from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle } from '@/constants/layout'

// components
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { SettingSideMenu } from '@/components/organisms/setting-side-menu'

import { HEADER_HEIGHT, TAB_HEIGHT } from '@/constants/layout'
import { RequestPanel } from '@/components/organisms/settings/mobile-request/request-panel'
import { Footer } from '@/components/molecules/settings/mobile-request/footer'
import dayjs, { Dayjs } from 'dayjs'

export const SettingMobileRequest: React.FC = () => {
  const { t } = useTranslation()
  const [showFooter, setShowFooter] = useState(false)
  const [doCancel, setDoCancel] = useState<Dayjs>()
  const [doSave, setDoSave] = useState<Dayjs>()
  const windows = useWindowSize()

  const clearEvent = () => {
    setDoSave(undefined)
    setDoCancel(undefined)
  }

  return (
    <>
      <div css={wholeContainerStyle}>
        <SideMenu />
        <div className="mainColumn" css={mainColumnStyle}>
          <Header title={t('Setting')} isPublicPageLink />
          <div className="mainContainer" css={settingContainerStyle}>
            <SettingSideMenu />
            <div css={settingMainContainerStyle} style={{ height: windows.height - HEADER_HEIGHT }}>
              <div
                css={settingMainWrapperStyle}
                style={showFooter ? { height: windows.height - HEADER_HEIGHT - TAB_HEIGHT } : { height: windows.height - HEADER_HEIGHT }}
              >
                <RequestPanel
                  onChangeData={() => {
                    setShowFooter(true)
                  }}
                  onChangeMenu={() => {
                    setShowFooter(false)
                    clearEvent()
                  }}
                  listenCancel={doCancel}
                  listenSave={doSave}
                />
              </div>
              {showFooter && (
                <Footer
                  onSave={e => {
                    setDoSave(dayjs())
                    setShowFooter(false)
                  }}
                  onCancel={e => {
                    setDoCancel(dayjs())
                    setShowFooter(false)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const settingContainerStyle = css(mainContainerStyle, {
  display: 'flex',
  padding: 0,
})

const settingMainContainerStyle = css({
  width: 'calc(100% - 180px)',
  overflow: 'hidden',
})
const settingMainWrapperStyle = css({
  padding: 24,
})
