import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import moment from 'moment'

// constants
import {
  mainContainerHeaderBarStyle,
  headerBarLeftWrapperStyle,
  dashboardPageHeaderStyle,
  dashboardPageContainerStyle,
  tableHeaderThStyle,
  tableBodyTdStyle,
} from '@/constants/layout'

import { useErrorHandler } from '@/hooks/use-error-handler'
import { fetchUsedCouponsList } from '@/apis/aipass'
import { UsedCouponItemType } from '@/models/coupon'
import { DashboardPageLayout } from '@/components/layouts/dashboard-page-layout'
import { DateRangeField } from '@/components/molecules/date-range-field'
import { ReloadButton } from '@/components/atoms/reload-button'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { NoticeLoading } from '@/components/molecules/notice-loading'

export const CouponsManager: React.FC = () => {
  const limit = 50
  const windows = useWindowSize()
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [loading, setLoading] = useState<boolean>(true)
  const [total, setTotal] = useState<number>(0)
  const [hasNext, setHasNext] = useState<boolean>(false)
  const [usedCouponsList, setUsedCouponsList] = useState<UsedCouponItemType[]>()
  const [beginDate, setBeginDate] = useState<string>(
    moment()
      .subtract(1, 'week')
      .format('YYYY-MM-DD'),
  )
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DD'))
  const [page, setPage] = useState<number>(1)

  const loadUsedCouponsList = async () => {
    try {
      setLoading(true)

      const res = await fetchUsedCouponsList({ endDate, beginDate, page })

      setUsedCouponsList([]) // To reset the table position to the top
      setUsedCouponsList(res.data || [])
      setTotal(res.meta?.total || 0)
      setHasNext(res.meta?.current_page < res.meta?.last_page)
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const changeRangeDate = (beginDate, endDate) => {
    setBeginDate(beginDate)
    setEndDate(endDate)
  }

  const refresh = () => {
    loadUsedCouponsList()
  }

  useEffect(() => {
    loadUsedCouponsList()
  }, [endDate, beginDate, page])

  return (
    <DashboardPageLayout title={t('Coupons')}>
      <>
        <NoticeLoading isLoading={loading} />
        <div css={mainContainerHeaderBarStyle}>
          <div css={headerBarLeftWrapperStyle}>
            <DateRangeField beginDate={beginDate} endDate={endDate} changeRangeDate={changeRangeDate} />
            <ReloadButton marginLeft={12} onClick={() => refresh()} />
          </div>
        </div>
        <div css={dashboardPageContainerStyle}>
          <div css={[dashboardPageHeaderStyle, { height: 50 }]}>
            <div css={{ fontWeight: 'bold' }}>{t('Usage history')}</div>
            <AccommodationPaging page={Number(page)} setPage={setPage} total={total} />
          </div>
          <div css={{ overflow: 'auto', height: windows.height - 236, marginBottom: 16 }}>
            {!!usedCouponsList?.length && (
              <table css={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th css={[tableHeaderThStyle, { paddingLeft: 28 }]}>{t('ID')}</th>
                    <th css={tableHeaderThStyle}>{t('Coupon')}</th>
                    <th css={tableHeaderThStyle}>{t('User ID')}</th>
                    <th css={tableHeaderThStyle}>{t('Date and time of use')}</th>
                  </tr>
                </thead>
                <tbody>
                  {usedCouponsList?.map(item => (
                    <tr key={item.usedCode}>
                      <td css={[couponTableBodyTdStyle, { paddingLeft: 28, width: 100, whiteSpace: 'nowrap' }]}>{item.couponCode}</td>
                      <td css={[couponTableBodyTdStyle, { position: 'relative', width: '100%' }]}>
                        <div
                          css={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 16,
                          }}
                        >
                          {item.title}
                        </div>
                      </td>
                      <td css={[couponTableBodyTdStyle, { width: 100, whiteSpace: 'nowrap' }]}>{item.usedCode}</td>
                      <td css={[couponTableBodyTdStyle, { whiteSpace: 'nowrap' }]}>
                        {`${moment(item.usedAt).format('YYYY-MM-DD HH:mm')}`}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {!usedCouponsList?.length && !loading && (
              <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <h1>{t('No data available')}</h1>
              </div>
            )}
          </div>
        </div>
      </>
    </DashboardPageLayout>
  )
}

const couponTableBodyTdStyle = css([
  tableBodyTdStyle,
  {
    padding: '16px 8px',
    fontWeight: 'bold',
  },
])
