import React, { useContext } from 'react'
import { css } from '@emotion/core'
import moment from 'moment'

import { AssignTag } from '@/components/pages/room-manager/_daily/_body/assign-tag'
import { StatusBar } from '@/components/pages/room-manager/_daily/_body/status-bar'

import { ApprovedStatus } from '@/models/reservation-approved-status'
import { DailyProps } from '@/components/pages/room-manager/_daily/daily-props'
import { PluginCode, checkActivePlugin } from '@/libs/plugins'
import { AccountContext } from '@/contexts/account'

type DailyListBodyProps = Pick<
  DailyProps,
  | 'guestRooms'
  | 'today'
  | 'dates'
  | 'editGuestRooms'
  | 'setChangeUrl'
  | 'roomAssignActiveId'
  | 'beginDate'
  | 'endDate'
  | 'setIsBar'
  | 'setIsLoading'
  | 'hiddenRoomNameList'
  | 'setIsClickStatusBar'
  | 'siteControllerStock'
  | 'clearRoomAssign'
  | 'clickToFreeDate'
>

export const DailyListBody: React.FC<DailyListBodyProps> = ({
  guestRooms,
  today,
  dates,
  editGuestRooms,
  setChangeUrl,
  roomAssignActiveId,
  beginDate,
  endDate,
  setIsBar,
  hiddenRoomNameList,
  setIsClickStatusBar,
  siteControllerStock,
  clearRoomAssign,
  clickToFreeDate,
}) => {
  const { plugins } = useContext<any>(AccountContext)
  const isEnabledNeppan =
    checkActivePlugin(plugins, PluginCode.ReservationNeppan) || checkActivePlugin(plugins, PluginCode.ReservationNeppanTwoWay)

  const get2wayStock = (stock): string => {
    if (stock.ota) {
      return `(${stock.ota.remainingCount})`
    }
    if (isEnabledNeppan) {
      return ''
    }
    return '(-)'
  }

  const sortRooms = (guestRooms: { [roomNumber: string]: { displayOrder: number; roomNumber: string } }) => {
    const rooms = Object.values(guestRooms)
    rooms.sort((a, b) => a.displayOrder - b.displayOrder)
    return rooms.map(r => r.roomNumber)
  }

  return (
    <>
      {/* Get the room type (or floor) from guestRooms */}
      {guestRooms &&
        siteControllerStock &&
        Object.keys(guestRooms).map((group, guestRoomIndex) => {
          if (!group) return null
          return (
            <div key={`guestRoom_${guestRoomIndex}`} css={dailyListContainerStyle} id={`dailyList_${group}`}>
              <div css={roomListFloorWrapperStyle}>
                {siteControllerStock[group] &&
                  dates.map((date, i) => {
                    const stock = siteControllerStock[group][date.daysForStock]

                    return (
                      <div
                        key={`date${i}`}
                        css={roomListFloorStockStyle}
                        style={{
                          ...(stock?.ota?.isNoSales ? { color: '#fff' } : {}),
                          ...(!stock?.ota || stock.ota.remainingCount == stock.remainingCount
                            ? {}
                            : { color: '#F47110', backgroundColor: '#FEF3F0' }),
                          ...(stock?.ota?.isNoSales ? { backgroundColor: '#ccc' } : {}),
                        }}
                      >
                        {stock && (
                          <div key={i + stock.adjustmentDate}>
                            {stock.remainingCount ?? '-'}
                            <span style={{ fontSize: 10 }}>{get2wayStock(stock)}</span>
                          </div>
                        )}
                      </div>
                    )
                  })}
                {!siteControllerStock[group] &&
                  dates.map((_, i) => {
                    return (
                      <div key={`date-${i}`} css={roomListFloorStyle}>
                        -
                      </div>
                    )
                  })}
              </div>
              {/* Extract room number using room type (or floor number) */}
              {sortRooms(guestRooms[group]).map(roomNumber => {
                const onClickToDate = date => {
                  if (
                    !guestRooms[group][roomNumber]?.statuses?.length ||
                    !guestRooms[group][roomNumber]?.statuses?.some(s =>
                      moment(date.days).isBetween(s.assignCheckinDays, s.assignCheckoutDays, undefined, '[)'),
                    )
                  ) {
                    clickToFreeDate({ date, group, roomNumber })
                  }
                }

                if (roomNumber === '指定なし') return null

                return (
                  !hiddenRoomNameList.includes(group) && (
                    <ul key={`guestRoomsGroup${roomNumber}`} css={dayListBlockWrapperStyle}>
                      {/* map date to room number */}
                      {dates.map((date, dateIndex) => {
                        return (
                          <li
                            css={date.days === today ? dayListBlockTodayStyle : dayListBlockStyle}
                            key={`guestRoomsGroupDate${dateIndex}`}
                            onClick={() => onClickToDate(date)}
                          >
                            {guestRooms[group][roomNumber].statuses &&
                              guestRooms[group][roomNumber].statuses.map((statusData, statusIndex) => {
                                // Tags (assignStatus: CamelCase because we are assigning status on the front (with _mergeEditGuestRooms in daily.tsx))
                                if (
                                  statusData.assignStatus === null ||
                                  (statusData.assignStatus &&
                                    [
                                      ApprovedStatus.Reservation,
                                      ApprovedStatus.Checkin,
                                      ApprovedStatus.Stay,
                                      ApprovedStatus.Checkout,
                                      'preparation',
                                    ].indexOf(statusData.assignStatus) !== -1)
                                ) {
                                  return (
                                    <React.Fragment key={`guestRoomsGroupDateAssignTag${dateIndex}_${statusIndex}`}>
                                      <AssignTag
                                        statusData={statusData}
                                        statusIndex={statusIndex}
                                        date={date}
                                        beginDate={beginDate}
                                        endDate={endDate}
                                      />
                                    </React.Fragment>
                                  )
                                }
                                // plans deleted by update
                                if (
                                  statusData.status &&
                                  ['plans', 'reservation', 'checkin', 'stay', 'checkout', 'preparation'].indexOf(statusData.status) !== -1
                                ) {
                                  return (
                                    <React.Fragment key={`guestRoomsGroupDateStatusBar${dateIndex}_${statusIndex}`}>
                                      <StatusBar
                                        statusData={statusData}
                                        statusIndex={statusIndex}
                                        date={date}
                                        roomAssignActiveId={roomAssignActiveId}
                                        roomNumber={roomNumber}
                                        editGuestRooms={editGuestRooms}
                                        setChangeUrl={setChangeUrl}
                                        setIsBar={setIsBar}
                                        beginDate={beginDate}
                                        endDate={endDate}
                                        setIsClickStatusBar={setIsClickStatusBar}
                                        clearRoomAssign={clearRoomAssign}
                                      />
                                    </React.Fragment>
                                  )
                                }
                              })}
                          </li>
                        )
                      })}
                    </ul>
                  )
                )
              })}
            </div>
          )
        })}
    </>
  )
}

const roomListFloorWrapperStyle = css({
  display: 'flex',
})

const roomListFloorStockStyle = css({
  borderBottom: '1px solid #E3E3E3',
  background: '#FAFAFA',
  fontSize: 12,
  letterSpacing: 0.6,
  fontWeight: 'bold',
  padding: '8px 0',
  height: 47,
  width: 111,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#676767',
})

const dailyListContainerStyle = css({
  overflow: 'hidden',
})
const roomListFloorStyle = css({
  width: '100%',
  borderBottom: '1px solid #E3E3E3',
  background: '#FAFAFA',
  fontSize: 12,
  letterSpacing: 0.6,
  fontWeight: 'bold',
  height: 47,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const dayListBlockWrapperStyle = css({
  display: 'flex',
  ':last-of-type > li': {
    borderBottom: '1px solid #E3E3E3',
  },
})

const dayListBlockStyle = css({
  borderRight: '1px solid #F2F2F2',
  height: 42,
  background: '#fff',
  borderBottom: '1px solid #F2F2F2',
  width: 111,
  position: 'relative',
})

const dayListBlockTodayStyle = css(dayListBlockStyle, {
  background: '#FFFDE3',
})
