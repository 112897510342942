import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

//libs
import { CASH, CREDIT_CARD } from '@/models/self-checkin/payment-method'
import { numberWithCommas } from '@/libs/formater'

type Props = {
  paymentInfo: any
  editPaymentInfo: () => void
}

export const PaymentInfo: React.FC<Props> = ({ paymentInfo, editPaymentInfo }) => {
  const { t } = useTranslation()

  return (
    <div css={informationStyle}>
      <h5 className="subtitle">{t('Payment details')}</h5>
      <div css={editableIcon} onClick={editPaymentInfo}></div>

      <div css={itemInfo} style={{ marginTop: '32px' }}>
        <h6>{t('Amount billed')}</h6>
        <p>¥{numberWithCommas(paymentInfo.totalAmount)}</p>
      </div>

      <div css={itemInfo} style={{ marginTop: '24px' }}>
        <h6>{t('Payment options')}</h6>
        {paymentInfo.paymentMethod === CASH && <p>{t(`SelfCheckinPayment.PayAtTheFrontDesk`)}</p>}
        {paymentInfo.paymentMethod === CREDIT_CARD && <p>{paymentInfo.cardInfo.cardNumber.replace(/.(?=.{4})/g, '*')}</p>}
      </div>
    </div>
  )
}

const informationStyle = css({
  width: '100%',
  maxWidth: '874px',
  position: 'relative',
  backgroundColor: '#fff',
  borderRadius: '10px',
  padding: '52px 51px',
  margin: 'auto',

  '.subtitle': {
    fontSize: '21px',
    letterSpacing: '2.1px',
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: '32px',
  },
})

const editableIcon = css({
  width: '40px',
  height: '40px',
  backgroundColor: '#f2f2f2',
  borderRadius: '50%',
  backgroundImage: `url(${require('@/static/images/edit.svg')})`,
  position: 'absolute',
  top: '35px',
  right: '35px',
  cursor: 'pointer',
})

const itemInfo = css({
  h6: {
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '1.8px',
  },

  p: {
    fontSize: '18px',
    fontWeight: 'normal',
    letterSpacing: '1.8px',
    lineHeight: '27px',
    marginTop: '7px',
  },
})
