import React, { Dispatch, SetStateAction } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

//api
import { adjustSales } from '@/apis/aipass'

import { TransactionType } from '@/models/smaregi/transaction'
import { AdjustmentPaymentForm } from './adjustment-payment-form'
import { NOT_ENOUGH_BALANCE_CODE } from '@/errors/company-advance-money-error'

type AdjustmentProps = {
  transaction: TransactionType | undefined
  onAdjustSales: () => void
  setIsLoading: Dispatch<SetStateAction<boolean>>
  onClose: (isUpdated: boolean) => void
}

export const Adjustment: React.FC<AdjustmentProps> = ({ transaction, onAdjustSales, setIsLoading, onClose }) => {
  const { t } = useTranslation()

  const _onAdjustSales = ({ salesPaymentId, accountsReceivableId, companyId }) => {
    const transactionSaleIds = transaction?.sales?.map(sale => sale.salesId)
    setIsLoading(true)
    adjustSales(salesPaymentId, undefined, undefined, companyId, accountsReceivableId, transactionSaleIds)
      .then(() => {
        onAdjustSales()
      })
      .catch(error => {
        if (error?.response?.data?.errorCode === NOT_ENOUGH_BALANCE_CODE) {
          alert(t('Insufficient balance Please check again'))
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div css={containerStyle}>
      <table css={salesItemStyle}>
        <tbody>
          <tr>
            <td css={headStyle}>{t('Total sales')}</td>
            <td css={rightHeadStyle}>¥{Number(transaction?.price).toLocaleString()}</td>
          </tr>
          <tr>
            <td css={bodyStyle}>{t('Consumption tax')}</td>
            <td css={rightBodyStyle}>¥{Number(transaction?.tax).toLocaleString()}</td>
          </tr>
          <tr>
            <td css={bodyStyle}>{t('Receipt total')}</td>
            <td css={rightBodyStyle}>¥{Number(transaction?.paid).toLocaleString()}</td>
          </tr>
          <>
            {transaction?.totalByPayment?.map((payment, index) => (
              <tr key={index}>
                <td css={discountStyle}>{payment.name}</td>
                <td css={rightBodyStyle}>¥{payment.paid.toLocaleString()}</td>
              </tr>
            ))}
          </>
          <tr>
            <td css={[discountStyle, bottomStyle]}>{t('Discount')}</td>
            <td css={[rightBodyStyle, bottomStyle]}>¥{Number(transaction?.discountPrice).toLocaleString()}</td>
          </tr>
          <tr>
            <td css={footStyle}>{t('Amount to be collected')}</td>
            <td css={transaction?.unpaid ? isUnpaidRightFootStyle : rightFootStyle}>¥{Number(transaction?.unpaid).toLocaleString()}</td>
          </tr>
        </tbody>
      </table>

      <AdjustmentPaymentForm
        onAdjustSales={_onAdjustSales}
        transaction={transaction}
        onClose={onClose}
      />
    </div>
  )
}

const containerStyle = css({
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  border: '1px solid #cccccc',
  width: '26%',
  minWidth: 256,
  height: 'fit-content',
  padding: '32px 26px',
  overflow: 'hidden',
})

const salesItemStyle = css({
  fontSize: 14,
  width: '100%',
})

const headStyle = css({
  width: '70%',
  color: '#272727',
  fontWeight: 'bold',
  paddingBottom: 16,
})

const bodyStyle = css({
  width: '70%',
  color: '#676767',
  margin: '16px 0',
  paddingBottom: 16,
})

const rightHeadStyle = css(headStyle, {
  textAlign: 'right',
})

const rightBodyStyle = css(bodyStyle, {
  textAlign: 'right',
})

const discountStyle = css(bodyStyle, {
  paddingLeft: 28,
})

const bottomStyle = css({
  paddingBottom: 24,
})

const footStyle = css({
  borderTop: '1px solid #F2F2F2',
  paddingTop: 24,
  paddingBottom: 16,
  fontWeight: 'bold',
})

const isUnpaidRightFootStyle = css(footStyle, {
  textAlign: 'right',
  color: '#F2A40B',
})

const rightFootStyle = css(footStyle, {
  textAlign: 'right',
})
