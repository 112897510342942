import React, { useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button, ButtonType } from '@/components/atoms/button'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import { fetchReservation, putSmaregiTransactionLinkReservation } from '@/apis/aipass'
import { LoadingContent } from '@/components/molecules/loading-content'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { RadioButton } from '@/components/atoms/radio-button'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { EntireSearchContentInitial } from '@/models/entire-search'
import { ReservationStatusType } from '@/models/reservation-reservation-status'
import dayjs, { Dayjs } from 'dayjs'
import uuid from 'react-uuid'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'

type Props = {
  transactionId: string
  transactionHeadId: string
  onClose: (isUpdated: boolean) => void
}

type ReservationRow = {
  reservationId: string
  userName: string
  userNameKana: string
  guestName: string
  guestNameKana: string
  checkinDate: Dayjs
  roomNumbers: string[]
  nights: number
}

type FormType = {
  roomNumber: string
  reservationId: string
}

export const TransactionLinkModal: React.FC<Props> = ({ transactionId, transactionHeadId, onClose }) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [roomNumbersWithReservation, setRoomNumbersWithReservation] = useState<string[]>()
  const [searchReservations, setSearchReservations] = useState<ReservationRow[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm<FormType>({
    mode: 'onChange',
  })
  const watchRoomNumber = useWatch({ control, name: 'roomNumber' })

  const initializeFetching = async () => {
    if (!transactionHeadId) {
      return
    }
    setIsLoading(true)

    const res = await fetchReservation({
      ...EntireSearchContentInitial,
      approvedStatuses: [ApprovedStatusType.Stay],
      page: 1,
      limit: 500,
      reservationStatuses: [ReservationStatusType.NewReport, ReservationStatusType.ModificationReport],
    })
    const reservations = res?.reservations || []
    const pickRoomNumbers: string[] = []
    const reservationWithRoom = reservations
      .filter(r => r.rooms?.length > 0)
      .map((r): ReservationRow => {
        return {
          reservationId: r.reservationId,
          userName: r.name,
          userNameKana: r.nameKana,
          guestName: r.guestName,
          guestNameKana: r.guestNameKana,
          checkinDate: dayjs(r.checkinDate),
          roomNumbers: r.rooms.map((room: { roomNumber: string; roomTypeId: string; typeName: string }) => {
            if (!pickRoomNumbers.includes(room.roomNumber)) {
              pickRoomNumbers.push(room.roomNumber)
            }
            return room.roomNumber
          }),
          nights: r.nights,
        }
      })
    setSearchReservations(reservationWithRoom)
    pickRoomNumbers.sort()
    setRoomNumbersWithReservation(pickRoomNumbers)
    setIsLoading(false)
  }

  const onConfirm = async (value: FormType) => {
    try {
      setIsLoading(true)
      await putSmaregiTransactionLinkReservation(transactionId, value)
      setIsCompleted(true)
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    initializeFetching()
  }, [transactionHeadId])

  const selectableSearchReservations = useMemo(() => {
    if (!watchRoomNumber) {
      return searchReservations
    }
    return searchReservations?.filter(r => r.roomNumbers.includes(watchRoomNumber))
  }, [searchReservations, watchRoomNumber])

  return (
    <>
      {isCompleted ? (
        <Modal customCss={completeModalStyle}>
          <div
            css={css({ display: 'flex', flexDirection: 'row-reverse', padding: '20px 20px 0 0', '.icon': { width: '40px !important' } })}
          >
            <DeleteIcon onClick={() => onClose(true)} />
          </div>
          <div css={css({ flexGrow: 1, width: '100%', marginTop: -10 })}>
            <div css={css({ textAlign: 'center', fontWeight: 'bold', fontSize: 23, letterSpacing: 1.15, color: '#272727' })}>
              {t('Rooms have been attached')}
            </div>
            <div css={css({ textAlign: 'center', fontWeight: 'bold', fontSize: 14, letterSpacing: 0.7, color: '#272727', paddingTop: 12 })}>
              {t('Subjects have been moved to the reservation details')}
            </div>
          </div>
        </Modal>
      ) : (
        <Modal customCss={modalStyle}>
          <LoadingContent isLoading={isLoading} />

          <ModalHeader customCss={modalHeaderStyle}>
            <div>{t('Search your reservation')}</div>
          </ModalHeader>
          <ModalBody customCss={modalBodyStyle}>
            <div style={{ width: 307 }}>
              <div css={inputTitleTextStyle}> {t('Room number')}</div>
              <Controller
                name="roomNumber"
                control={control}
                rules={{ required: t('Please select a room number') }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    placeholder={t('Please select')}
                    value={value}
                    onChange={e => {
                      setValue('reservationId', '')
                      onChange(e)
                    }}
                    error={error?.message}
                    customStyle={css({ paddingBottom: 16 })}
                  >
                    {roomNumbersWithReservation?.map(num => (
                      <AntSelect.Option key={uuid()} value={num}>
                        {num}
                      </AntSelect.Option>
                    ))}
                  </Select>
                )}
              />
            </div>
            <div css={reservationSearchListStyle}>
              <table>
                <thead>
                  <tr>
                    <th className="col-radio"></th>
                    <th className="col-reservation-user">{t('Reservation person')}</th>
                    <th className="col-reservation-guest">{t('Guest')}</th>
                    <th className="col-stay-date">{t('Date of stay')}</th>
                  </tr>
                </thead>
                <tbody>
                  {selectableSearchReservations?.map(r => (
                    <tr
                      key={uuid()}
                      onClick={() => {
                        setValue('reservationId', r.reservationId)
                        trigger('reservationId')
                      }}
                    >
                      <Controller
                        name="reservationId"
                        control={control}
                        rules={{ required: 'aaa' }}
                        render={({ field: { onChange, value } }) => (
                          <td className="col-radio">
                            <RadioButton
                              id={r.reservationId}
                              checked={value === r.reservationId}
                              item={{ value: r.reservationId, label: '' }}
                              onChange={onChange}
                              style={{ labelMargin: 0, radioSize: 18 }}
                            />
                          </td>
                        )}
                      />
                      <td className="col-reservation-user">
                        <div className="text-kana">{r.userNameKana}</div>
                        <div className="text-name">{r.userName}</div>
                      </td>
                      <td className="col-reservation-guest">
                        <div className="text-kana">{r.guestNameKana}</div>
                        <div className="text-name">{r.guestName}</div>
                      </td>
                      <td className="col-stay-date">
                        <div className="text-name">{`${r.checkinDate.format(t('MM-DD'))} ${r.nights}${t('Night')}`}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </ModalBody>
          <ModalFooter customCss={modalFooterStyle}>
            <Button buttonType={ButtonType.Secondary} height="36px" width="110px" marginRight={16} onClick={() => onClose(false)}>
              {t('Back')}
            </Button>
            <Button
              buttonType={isValid ? ButtonType.Primary : ButtonType.PrimaryDisabled}
              height="36px"
              width="110px"
              marginRight={16}
              onClick={handleSubmit(onConfirm)}
            >
              {t('Confirm')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

const completeModalStyle = css({
  top: 'calc((100% - 149px) / 2)',
  left: 'calc((100% - 600px) / 2)',
  height: 149,
  width: 600,
})

const modalStyle = css({
  top: '5%',
  left: 'calc((100% - 680px) / 2)',
  height: 555,
  minWidth: 680,
  width: 680,
})

const modalHeaderStyle = css({
  textAlign: 'center',
  color: '#272727',
  display: 'flex',
  justifyContent: 'space-between',
  height: 60,
})

const modalBodyStyle = css({
  padding: '24px 32px',
  height: 'calc(100% - 2px)',
  display: 'flex',
  flexDirection: 'column',
})

const modalFooterStyle = css({
  height: '60px',
  display: 'flex',
  justifyContent: 'end',
})

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const reservationSearchListStyle = css({
  flexGrow: 1,
  border: '1px solid #CCCCCC',
  borderRadius: 5,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  overflowY: 'auto',
  overflowX: 'hidden',
  overscrollBehavior: 'contain',
  table: { borderCollapse: 'separate' },
  thead: {
    lineHeight: '32px',
    fontSize: 12,
    th: {
      borderBottom: '1px solid #F2F2F2',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      position: 'sticky',
      zIndex: 1,
      top: 0,
    },
  },
  tbody: {
    tr: {
      height: 64,
      lineHeight: '21px',
      cursor: 'pointer',
      td: {
        padding: '11px 0',
        alignContent: 'center',
        borderBottom: '1px solid #F2F2F2',
      },
      '.col-reservation-user': {
        fontWeight: 'bold',
      },
      '.text-kana, .text-name': {
        width: 'inherit',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '.text-kana': {
        letterSpacing: 0.48,
        fontSize: 12,
        color: '#676767',
      },
      '.text-name': {
        letterSpacing: 0.56,
        fontSize: 14,
        color: '#272727',
      },
    },
  },
  '.col-radio': { width: 56 },
  'td.col-radio': {
    display: 'flex',
    justifyContent: 'center',
    height: 65,
  },
  '.col-reservation-user': { width: 210 },
  '.col-reservation-guest': { width: 210 },
  '.col-stay-date': { width: 139 },
})
