import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { GuestAppTab } from '@/components/molecules/settings/guest-app-tab'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { Button } from '@/components/atoms'
export const DesignCm: React.FC = () => {
  const { t } = useTranslation()
  return (
    <TabContainer tabComponent={<GuestAppTab currentTab="Design" />}>
      <div css={contentStyle}>
        <div className="title">
          <img src={require(`@/static/images/custom-design/icon_pallet.svg`)} />
          <div>ブランドイメージに合わせてカスタマイズ</div>
        </div>
        <img className="example-theme" src={require('@/static/images/custom-design/image.png')} />
        <div className="footer">
          <div>ホテルのブランドイメージに合わせて色やフォントのデザインを変更できます</div>
          <a href="https://aipass.jp/application/#plan" target="_blank" rel="noreferrer">
            <Button buttonType={1} width={232} height={38} fontSize={16} marginLeft={0} marginTop={0} marginBottom={0}>
              {t('今すぐアップグレード')}
            </Button>
          </a>
        </div>
      </div>
    </TabContainer>
  )
}

const contentStyle = css({
  padding: '54px 60px',
  display: 'flex',
  flexFlow: 'column',
  '.title': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '> img': {
      width: 28,
      height: 28,
    },
    '> div': {
      paddingLeft: 14,
      fontSize: 24,
      fontWeight: 'bold',
      letterSpacing: 1.2,
      color: '#272727',
    },
  },
  '.example-theme': {
    marginTop: 24,
    height: 432,
    objectFit: 'scale-down',
  },
  '.footer': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 24,
    '>div': {
      fontSize: 16,
      fontWeight: 'bold',
      letterSpacing: 0.8,
      color: '#676767',
    },
  },
})
