import * as React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Controller, UseFormReturn, useWatch } from 'react-hook-form'
import { CardInfo } from '.'

export type Props = {
  cardForm: UseFormReturn<CardInfo, any>
}

export const CreditCardForm: React.FC<Props> = ({ cardForm }) => {
  const { t } = useTranslation()
  const [isComposition, setIsComposition] = React.useState(false)
  const watchCardHolder = useWatch({ control: cardForm.control, name: 'holderName' })

  const formatCreditCardNumber = (data): string => {
    const regexp = /^(\d{1,4})?(\d{1,4})?(\d{1,4})?(\d{1,4})?$/
    const digits = data.value.replace(/[^0-9]/g, '')
    let formattedCardNumber: any = regexp.exec(digits) ?? []
    formattedCardNumber = formattedCardNumber
      .slice(1)
      .filter(e => e)
      .join(' ')
    return formattedCardNumber
  }

  const numberOnly = (data, maxLength): string => {
    const digits = data.value.replace(/[^0-9]/g, '')
    return digits.slice(0, maxLength)
  }

  const alphabetsOnly = (e): void => {
    const inputChar = e.target.value
    cardForm.setValue('holderName', isComposition ? inputChar : inputChar.replace(/[^a-zA-Z ]/g, '').toUpperCase())
  }

  const validMessageForExpired = (): string | undefined => {
    return cardForm.formState.errors.expiredMonth?.message || cardForm.formState.errors.expiredYear?.message
  }

  return (
    <div css={creditCardWrapper}>
      <div>
        <p css={labelStyle}>
          {t('Card number')} <span>*</span>
        </p>
        <Controller
          name="cardNumber"
          control={cardForm.control}
          rules={{ required: 'Required field has not been entered' }}
          render={({ field, fieldState }) => (
            <>
              <input
                type="tel"
                value={field.value}
                css={inputStyle}
                maxLength={19}
                style={fieldState.error ? { borderColor: '#e30000' } : {}}
                placeholder="1234 1234 1234 1234"
                onChange={e => {
                  field.onChange(formatCreditCardNumber(e.target))
                }}
                onBlur={field.onBlur}
              />
              {fieldState.error?.message && <p css={errorStyle}>{t(fieldState.error.message)}</p>}
            </>
          )}
        />
        <img css={cardBrandImage} src={require('@/static/images/self-checkin-payment/payment_logo.jpg')} alt={t('Delete')} />
      </div>
      <div css={inputFieldStyle}>
        <p css={labelStyle}>
          {t('Expiration date')} <span>*</span>
        </p>
        <div css={dateWrapperStyle}>
          <div>
            <Controller
              name="expiredMonth"
              control={cardForm.control}
              rules={{
                required: 'Required field has not been entered',
                minLength: { value: 1, message: 'Please confirm your input' },
                maxLength: { value: 2, message: 'Please confirm your input' },
              }}
              render={({ field, fieldState }) => (
                <input
                  type="tel"
                  css={dateInputStyle}
                  maxLength={2}
                  style={fieldState.error ? { borderColor: '#e30000' } : {}}
                  value={field.value}
                  placeholder="MM"
                  onChange={e => field.onChange(numberOnly(e.target, 2))}
                  onBlur={field.onBlur}
                />
              )}
            />
          </div>
          <div css={slash}>/</div>
          <div>
            <Controller
              name="expiredYear"
              control={cardForm.control}
              rules={{
                required: 'Required field has not been entered',
                minLength: { value: 1, message: 'Please confirm your input' },
                maxLength: { value: 2, message: 'Please confirm your input' },
              }}
              render={({ field, fieldState }) => (
                <input
                  type="tel"
                  css={dateInputStyle}
                  maxLength={2}
                  style={fieldState.error ? { borderColor: '#e30000' } : {}}
                  value={field.value}
                  placeholder="YY"
                  onChange={e => field.onChange(numberOnly(e.target, 2))}
                  onBlur={field.onBlur}
                />
              )}
            />
          </div>
        </div>
        {validMessageForExpired() && <p css={errorStyle}>{t(validMessageForExpired()!)}</p>}
      </div>
      <div css={inputFieldStyle}>
        <p css={labelStyle}>
          {t('Cardholders Name')} <span>*</span>
        </p>

        <Controller
          name="holderName"
          control={cardForm.control}
          rules={{ required: 'Required field has not been entered' }}
          render={({ field, fieldState }) => (
            <>
              <input
                onCompositionStart={e => {
                  setIsComposition(true)
                }}
                onCompositionEnd={e => {
                  setIsComposition(false)
                  cardForm.setValue(
                    'holderName',
                    watchCardHolder
                      ?.replace(/[Ａ-Ｚａ-ｚ]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
                      ?.replace(/[^a-zA-Z ]/g, '')
                      ?.toUpperCase(),
                  )
                }}
                value={field.value}
                css={inputStyle}
                style={fieldState.error ? { borderColor: '#e30000' } : {}}
                placeholder={t('John Doe at Credit')}
                onChange={e => alphabetsOnly(e)}
                onBlur={field.onBlur}
              />
              {fieldState.error?.message && <p css={errorStyle}>{t(fieldState.error?.message)}</p>}
            </>
          )}
        />
      </div>
      <div css={inputFieldStyle}>
        <p css={labelStyle}>
          {t('CVC')} <span>*</span>
        </p>
        <Controller
          name="cvc"
          control={cardForm.control}
          rules={{
            required: 'Required field has not been entered',
            minLength: { value: 3, message: 'Please confirm your input' },
            maxLength: { value: 4, message: 'Please confirm your input' },
          }}
          render={({ field, fieldState }) => (
            <>
              <input
                type="tel"
                value={field.value}
                maxLength={4}
                css={securityInputStyle}
                style={fieldState.error ? { borderColor: '#e30000' } : {}}
                placeholder="000"
                onChange={e => field.onChange(numberOnly(e.target, 4))}
                onBlur={field.onBlur}
              />
              {fieldState.error?.message && <p css={errorStyle}>{t(fieldState.error?.message)}</p>}
            </>
          )}
        />
      </div>
    </div>
  )
}

const creditCardWrapper = css({
  padding: '45px 52px 0 52px',
})
const labelStyle = css({
  fontWeight: 'bold',
  fontSize: 18,
  color: '#272727',
  paddingBottom: 14,
  display: 'block',
})
const cardBrandImage = css({
  marginTop: 16,
  height: 33,
  width: 255,
})

const inputStyle = css({
  border: '1px solid #CCCCCC',
  borderRadius: '40px',
  fontSize: '21px',
  height: '61px',
  margin: '0',
  lineHeight: '1',
  padding: '0 20px',
  textAlign: 'left',
  width: '100%',
  '&::placeholder': {
    color: '#CCCCCC',
    fontWeight: 'normal',
  },
})

const dateWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
})
const inputFieldStyle = css({
  paddingTop: 32,
})
const dateInputStyle = css([
  inputStyle,
  {
    width: 98,
    textAlign: 'center',
  },
])

const securityInputStyle = css([
  inputStyle,
  {
    width: 112,
    textAlign: 'center',
  },
])

const slash = css({
  fontSize: '22px',
  color: '#272727',
  padding: '0 16px',
})

const errorStyle = css({
  color: '#e30000',
  marginTop: 10,
})
