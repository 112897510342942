import React, { useEffect, useRef, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { LanguageType } from '@/i18n'

type GeneralNoteProps = {
  generalNoteContent: string
}

export const GeneralNote = ({ generalNoteContent }: GeneralNoteProps) => {
  const { t, i18n } = useTranslation()
  const [hasMoreText, setHasMoreText] = useState<boolean>(false)
  const [isOpenNoteAccordion, setIsOpenNoteAccordion] = useState<boolean>(false)
  const noteRef = useRef<HTMLParagraphElement>(null)
  const defaultNoteLine = 3

  const initAccordion = () => {
    if (!noteRef || !noteRef.current) {
      return
    }
    const realNoteLineHeight = Number(getComputedStyle(noteRef.current).getPropertyValue('line-height').replace('px', ''))
    setHasMoreText(noteRef.current.scrollHeight > defaultNoteLine * realNoteLineHeight)
    setIsOpenNoteAccordion(false)
  }

  useEffect(() => {
    initAccordion()
  }, [noteRef, generalNoteContent])

  return (
    <div css={containerStyle}>
      <div css={i18n.language === LanguageType.en ? headerNoteStyleEn : headerNoteStyle}>{t('cleaningManager.Notice')}</div>
      <div css={isOpenNoteAccordion ? noteBlockOpenStyle : noteBlockCloseStyle}>
        <p ref={noteRef}>{generalNoteContent}</p>
      </div>
      {hasMoreText &&
        (isOpenNoteAccordion ? (
          <div css={accordionTextStyle} onClick={() => setIsOpenNoteAccordion(false)}>
            <p>{t('Close up')}</p>
            <img
              style={{ transform: 'rotate(180deg)' }}
              src={require(`@/static/images/self-checkin-payment/arrow.svg`)}
              alt="up-arrow"
              width="16"
            />
          </div>
        ) : (
          <div css={accordionTextStyle} onClick={() => setIsOpenNoteAccordion(true)}>
            <p>{t('Show all')}</p>
            <img src={require(`@/static/images/self-checkin-payment/arrow.svg`)} alt="down-arrow" width="16" />
          </div>
        ))}
    </div>
  )
}

const containerStyle = css({
  borderRadius: '0 10px 10px 10px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
})

const headerNoteStyle = css({
  padding: '16px 0 8px 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'center',
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 'bold',
  letterSpacing: '2.1px',
  color: '#676767',
})
const headerNoteStyleEn = css(headerNoteStyle, {
  letterSpacing: '0.9px',
})

const noteBlockCloseStyle = css({
  letterSpacing: '1.4px',
  display: 'flex',
  margin: '0 auto',
  padding: '0 24px',
  width: '100%',
  height: '66px',
  p: {
    height: '100%',
    overflow: 'hidden',
    whiteSpace: 'pre-line',
    fontSize: 14,
    lineHeight: '22px',
  },
})

const noteBlockOpenStyle = css(noteBlockCloseStyle, {
  height: '100%',
})

const accordionTextStyle = css({
  padding: '10px 0',
  alignSelf: 'center',
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
  position: 'relative',
  p: {
    color: '#f2a40b',
    fontSize: 14,
    lineHeight: '20px',
  },
})
