import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { FormatType } from '@/models/guest-app/asset'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'
import { Controller, useForm } from 'react-hook-form'
import { TextareaField } from '@/components/molecules/textarea-field'
import { AssetFormatRadio } from '@/components/molecules/settings/asset/asset-format-radio'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { createAsset, getEnabledAssetType } from '@/apis/aipass'

type Props = {
  onClose: () => void
  doReload: () => Promise<void>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export type AssetFormValue = {
  title: string
  formatType: FormatType
}

export const AddAssetModal: React.FC<Props> = ({ onClose, doReload, setIsLoading }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AssetFormValue>()
  const { errorHandler } = useErrorHandler()
  const [enabledAssetTypes, setEnabledAssetTypes] = useState<FormatType[]>([])

  const onSave = async (formValue: AssetFormValue) => {
    try {
      setIsLoading(true)
      await createAsset(formValue)
      await doReload()
      onClose()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }
  const onCancel = () => {
    onClose()
  }

  useEffect(() => {
    getEnabledAssetType().then(types => setEnabledAssetTypes(types))
  }, [])

  return (
    <Modal customCss={modalStyle}>
      <ModalHeader>{t('GuestAppSetting.CreateAsset')}</ModalHeader>
      <ModalBody>
        <div css={modalBodyStyle}>
          <div>
            <div className="title">
              {t('Title')}
              <div className="require-icon">※</div>
            </div>
            <Controller
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              name="title"
              render={({ field: { onChange, value, name } }) => (
                <TextareaField
                  name={name}
                  value={value}
                  handleChangeData={onChange}
                  placeholder={t('Title name')}
                  rows={2}
                  marginBottom={24}
                  css={css({ textarea: { marginBottom: 0 } })}
                  error={errors.title?.message}
                />
              )}
            />
          </div>

          <div>
            <div className="title">
              {t('Format')}
              <div className="require-icon">※</div>
            </div>
            <Controller
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              name="formatType"
              render={({ field: { onChange, value } }) => (
                <div className="input-asset-format-group">
                  <AssetFormatRadio
                    type={FormatType.Pulldown}
                    label={t('Pulldown')}
                    value={value}
                    onChange={onChange}
                    disabled={!enabledAssetTypes.includes(FormatType.Pulldown)}
                  />
                  <AssetFormatRadio
                    type={FormatType.Free}
                    label={t('Free')}
                    value={value}
                    onChange={onChange}
                    disabled={!enabledAssetTypes.includes(FormatType.Free)}
                  />
                  <AssetFormatRadio
                    type={FormatType.Map}
                    label={t('MAP')}
                    value={value}
                    onChange={onChange}
                    disabled={!enabledAssetTypes.includes(FormatType.Map)}
                  />
                  <AssetFormatRadio
                    type={FormatType.Button1}
                    label={`${t('Button')}(1)`}
                    value={value}
                    onChange={onChange}
                    disabled={!enabledAssetTypes.includes(FormatType.Button1)}
                  />
                  <AssetFormatRadio
                    type={FormatType.Button2}
                    label={`${t('Button')}(2)`}
                    value={value}
                    onChange={onChange}
                    disabled={!enabledAssetTypes.includes(FormatType.Button2)}
                  />
                  <AssetFormatRadio
                    type={FormatType.Image}
                    label={t('ImageButton')}
                    value={value}
                    onChange={onChange}
                    disabled={!enabledAssetTypes.includes(FormatType.Image)}
                  />
                  <AssetFormatRadio
                    type={FormatType.News}
                    label={t('News')}
                    value={value}
                    onChange={onChange}
                    disabled={!enabledAssetTypes.includes(FormatType.News)}
                  />
                  <AssetFormatRadio
                    type={FormatType.Request}
                    label={t('GuestAppSetting.Request')}
                    value={value}
                    onChange={onChange}
                    disabled={!enabledAssetTypes.includes(FormatType.Request)}
                  />
                  <AssetFormatRadio
                    type={FormatType.Coupon}
                    label={t('Coupon')}
                    value={value}
                    onChange={onChange}
                    disabled={!enabledAssetTypes.includes(FormatType.Coupon)}
                  />
                  <AssetFormatRadio
                    type={FormatType.Space}
                    label={t('Space')}
                    value={value}
                    onChange={onChange}
                    disabled={!enabledAssetTypes.includes(FormatType.Space)}
                  />
                </div>
              )}
            />
            {!!errors.formatType?.message && (
              <div css={{ fontSize: 10, color: 'rgba(255,0,0,0.5) !important', marginTop: 4, position: 'absolute' }}>
                {errors.formatType?.message}
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
          {t('Cancel')}
        </Button>
        <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(onSave)}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = {
  width: 600,
  minWidth: 600,
  height: 529,
  left: 'calc((100% - 600px) / 2)',
}

const modalBodyStyle = css({
  padding: 31,
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  height: '100%',
  display: 'flex',
  flexFlow: 'column',
  '*': { color: '#676767' },
  '.title': {
    display: 'flex',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: '0.6px',
    paddingBottom: 12,
  },
  '.require-icon': {
    fontSize: 8,
    fontWeight: 'bold',
    letterSpacing: '0.4px',
    paddingLeft: 8,
  },
  textarea: {
    height: 67,
  },
  '.input-asset-format-group': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
})
