import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

type TabProps = {
  currentTab: 'sales' | 'business-forms' | 'advance-money' | 'accounting'
}

export const SalesManagerTab: React.FC<TabProps> = ({ currentTab }) => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <div css={tabContainerStyle}>
      <div
        css={currentTab === 'sales' ? tabActiveStyle : tabStyle}
        onClick={() => history.push({ pathname: '/sales-manager', search: location.search })}
      >
        {t('Earnings')}
      </div>
      <div
        css={currentTab === 'business-forms' ? tabActiveStyle : tabStyle}
        onClick={() => history.push({ pathname: '/sales-manager/business-forms', search: location.search })}
      >
        {t('Report')}
      </div>
      <div
        css={currentTab === 'advance-money' ? tabActiveStyle : tabStyle}
        onClick={() => history.push({ pathname: '/sales-manager/advance-money' })}
      >
        {t('Advance payment')}
      </div>
      <div
        css={currentTab === 'accounting' ? tabActiveStyle : tabStyle}
        onClick={() => history.push({ pathname: '/sales-manager/sales-accounting' })}
      >
        {t('Management accounting')}
      </div>
    </div>
  )
}
const centerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const tabContainerStyle = css({
  height: 45,
  display: 'flex',
})

const tabStyle = css(centerStyle, {
  width: 134,
  height: 45,
  borderRadius: '5px 5px 0 0',
  background: '#F5F5F5',
  boxShadow: '0px 0px 6px #0000001A',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#676767',
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.8,
  },
})

const tabActiveStyle = css(centerStyle, {
  width: 134,
  height: 41,
  borderRadius: '5px 5px 0 0',
  background: '#fff',
  boxShadow: '0px 0px 6px #0000001A',
  position: 'relative',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#F2A40B',
  '&::after': {
    position: 'absolute',
    bottom: -4,
    left: 0,
    height: 6,
    width: '100%',
    content: '""',
    background: '#fff',
    zIndex: 3,
  },
})
