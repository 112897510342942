import React from 'react'
import moment from 'moment'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// models
import { SalesDiscountType } from '@/models/sales'

type ConfirmedDiscountProps = {
  index: number
  discount: SalesDiscountType
  advanceCompany?: any
}

export const ConfirmedDiscount: React.FC<ConfirmedDiscountProps> = ({ index, discount, advanceCompany }) => {
  const { t } = useTranslation()
  return (
    <tr key={index} css={listWrapperStyle}>
      <td>{discount.salesDate && moment(discount.salesDate).format(t('MM-DD'))}</td>
      <td>{discount.salesDiscountName}</td>
      <td>
        {`${
          discount.accommodationId?.slice(-5)?.toLocaleUpperCase() || discount.reservationId?.slice(-5)?.toLocaleUpperCase() || '-'
        }/${discount?.roomNumber || '-'}`}
      </td>
      <td>
        {discount.companyId
          ? advanceCompany.paymentCompanies.find(item => item?.id === discount.companyId)?.name
          : discount.salesPaymentName}
      </td>
      <td>{Number(discount.salesSubjectPrice).toLocaleString()}</td>
      <td className="accountsReceivable">{discount.salesAccountsReceivableName}</td>
      <td></td>
    </tr>
  )
}

const listWrapperStyle = css({
  ':hover': {
    boxShadow: 'none',
    cursor: 'default',
  },
  'div.accountsReceivable': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  td: {
    backgroundColor: '#F6F6F6',
    height: 57,
  },
})
