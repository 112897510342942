import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// contexts
import { AccountContext } from '@/contexts/account'

// libs
import {
  setHasSalesManagerPlugin,
  setHasCustomCheckinPlugin,
  setHasMobileRequestPlugin,
  setHasSelfCheckinPlugin,
  setHasPaymentPlugin,
  checkActivePlugin,
  setHasCleaningManagerPlugin,
  setHasPrinterPlugin,
  setHasSmartKeyPlugin,
  hasAnyReservationPlugin,
} from '@/libs/plugins'
import { SettingSideMenuItem } from '../molecules/setting-side-menu-item'

type SideMenuProps = {
  pathName?: string
}

export const SettingSideMenu: React.FC<SideMenuProps> = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const [pathName, setPathName] = useState<string>('')
  const { plugins } = useContext<any>(AccountContext)
  // Plugin judgment
  const hasSalesManagerPlugin = setHasSalesManagerPlugin(plugins)
  const hasCustomCheckinPlugin = setHasCustomCheckinPlugin(plugins)
  const hasMobileRequestPlugin = setHasMobileRequestPlugin(plugins)
  const hasSelfCheckinPlugin = setHasSelfCheckinPlugin(plugins)
  const hasPaymentPlugin = setHasPaymentPlugin(plugins)
  const hasSmartKeyPlugin = setHasSmartKeyPlugin(plugins)
  const couponPluginActive = checkActivePlugin(plugins, 'CU')
  const hasPrinterPlugin = setHasPrinterPlugin(plugins)
  const hasCleaningPluginActive = setHasCleaningManagerPlugin(plugins)
  const mailDeliveryPluginActive = checkActivePlugin(plugins, 'MD')

  useEffect(() => {
    setPathName(window.location.pathname)
  }, [window.location])

  const isSettingAccount = () => {
    return pathName.indexOf('/setting/account') !== -1
  }

  const isSettingTopPage = () => {
    return pathName.indexOf('/setting/top-page') !== -1
  }

  const isSettingFacility = () => {
    return pathName.indexOf('/setting/facility/') !== -1
  }

  const isSettingAroundSpot = () => {
    return pathName.indexOf('/setting/around-spot') !== -1
  }

  const isSettingGuestRoom = () => {
    return pathName.indexOf('/setting/guest-room') !== -1
  }

  const isSettingSalesManager = () => {
    return pathName.indexOf('/setting/sales-manager') !== -1
  }

  const isSettingCustomCheckin = () => {
    return pathName.indexOf('/setting/custom-checkin') !== -1 || pathName.indexOf('/setting/custom-checkin/edit') !== -1
  }

  const isSettingMobileRequest = () => {
    return pathName.indexOf('/setting/mobile-request') !== -1
  }

  const isSettingSelfCheckin = () => {
    return pathName.indexOf('/setting/self-checkin') !== -1
  }

  const isSettingReservation = () => {
    return pathName.indexOf('/setting/reservation') !== -1
  }

  const isSettingCustomerManager = () => {
    return pathName.indexOf('/setting/customer-manager') !== -1
  }

  const isSettingPayment = () => {
    return pathName.indexOf('/setting/payment') !== -1
  }

  const isSettingCoupons = () => {
    return pathName.indexOf('/setting/coupons') !== -1
  }

  const isSettingStaff = () => {
    return pathName.indexOf('/setting/staff') !== -1
  }

  const isSettingCleaningManager = () => {
    return pathName.indexOf('/setting/cleaning-manager') !== -1
  }

  const isSettingMailDelivery = () => {
    return pathName.indexOf('/setting/mail-delivery') !== -1
  }

  return (
    <React.Fragment>
      <div css={sideMenuContainerStyle}>
        <div
          css={isSettingAccount() ? linkWrapperActiveStyle : linkWrapperStyle}
          onClick={() => history.push({ pathname: '/setting/account', search: location.search })}
        >
          <div css={isSettingAccount() ? linkContentsActiveStyle : linkContentsStyle}>{t('Account')}</div>
        </div>
        <div
          css={isSettingTopPage() ? linkWrapperActiveStyle : linkWrapperStyle}
          onClick={() => history.push({ pathname: '/setting/top-page', search: location.search })}
        >
          <div css={isSettingTopPage() ? linkContentsActiveStyle : linkContentsStyle}>{t('Top page')}</div>
        </div>
        <div
          css={isSettingFacility() ? linkWrapperActiveStyle : linkWrapperStyle}
          onClick={() => history.push({ pathname: '/setting/facility/list', search: location.search })}
        >
          <div css={isSettingFacility() ? linkContentsActiveStyle : linkContentsStyle}>{t('Facility guidance')}</div>
        </div>
        <div
          css={isSettingAroundSpot() ? linkWrapperActiveStyle : linkWrapperStyle}
          onClick={() => history.push({ pathname: '/setting/around-spot', search: location.search })}
        >
          <div css={isSettingAroundSpot() ? linkContentsActiveStyle : linkContentsStyle}>{t('Tourist information')}</div>
        </div>
        <div
          css={isSettingGuestRoom() ? linkWrapperActiveStyle : linkWrapperStyle}
          onClick={() => history.push({ pathname: '/setting/guest-room-number', search: location.search })}
        >
          <div css={isSettingGuestRoom() ? linkContentsActiveStyle : linkContentsStyle}>{t('Guest room')}</div>
        </div>
        {hasSmartKeyPlugin && (
          <SettingSideMenuItem to="/setting/smart-key/room" title={t('Smart Key')} activePattern="/setting/smart-key" />
        )}

        {hasSalesManagerPlugin && (
          <div
            css={isSettingSalesManager() ? linkWrapperActiveStyle : linkWrapperStyle}
            onClick={() => history.push({ pathname: '/setting/sales-manager/sales', search: location.search })}
          >
            <div css={isSettingSalesManager() ? linkContentsActiveStyle : linkContentsStyle}>{t('Sales management')}</div>
          </div>
        )}
        {hasCustomCheckinPlugin && (
          <div
            css={isSettingCustomCheckin() ? linkWrapperActiveStyle : linkWrapperStyle}
            onClick={() => history.push({ pathname: '/setting/custom-checkin', search: location.search })}
          >
            <div css={isSettingCustomCheckin() ? linkContentsActiveStyle : linkContentsStyle}>{t('Check-in items')}</div>
          </div>
        )}
        {hasMobileRequestPlugin && (
          <div
            css={isSettingMobileRequest() ? linkWrapperActiveStyle : linkWrapperStyle}
            onClick={() => history.push({ pathname: '/setting/mobile-request', search: location.search })}
          >
            <div css={isSettingMobileRequest() ? linkContentsActiveStyle : linkContentsStyle}>{t('Request')}</div>
          </div>
        )}
        {hasSelfCheckinPlugin && (
          <div
            css={isSettingSelfCheckin() ? linkWrapperActiveStyle : linkWrapperStyle}
            onClick={() => history.push({ pathname: '/setting/self-checkin', search: location.search })}
          >
            <div css={isSettingSelfCheckin() ? linkContentsActiveStyle : linkContentsStyle}>{t('Self check-in')}</div>
          </div>
        )}
        {hasAnyReservationPlugin(plugins) && (
          <div
            css={isSettingReservation() ? linkWrapperActiveStyle : linkWrapperStyle}
            onClick={() => history.push({ pathname: '/setting/reservation/market-segment', search: location.search })}
          >
            <div css={isSettingReservation() ? linkContentsActiveStyle : linkContentsStyle}>{t('Reservation linkage')}</div>
          </div>
        )}
        <div
          css={isSettingCustomerManager() ? linkWrapperActiveStyle : linkWrapperStyle}
          onClick={() => history.push({ pathname: '/setting/customer-manager', search: location.search })}
        >
          <div css={isSettingCustomerManager() ? linkContentsActiveStyle : linkContentsStyle}>{t('Customer manger')}</div>
        </div>
        {hasPaymentPlugin && (
          <div
            css={isSettingPayment() ? linkWrapperActiveStyle : linkWrapperStyle}
            onClick={() => history.push({ pathname: '/setting/payment', search: location.search })}
          >
            <div css={isSettingPayment() ? linkContentsActiveStyle : linkContentsStyle}>{t('Settlement')}</div>
          </div>
        )}
        {couponPluginActive && (
          <div
            css={isSettingCoupons() ? linkWrapperActiveStyle : linkWrapperStyle}
            onClick={() => history.push({ pathname: '/setting/coupons', search: location.search })}
          >
            <div css={isSettingCoupons() ? linkContentsActiveStyle : linkContentsStyle}>{t('Coupons')}</div>
          </div>
        )}
        {hasPrinterPlugin && <SettingSideMenuItem to="/setting/printer/self-checkin" title={t('Printer')} />}
        <div
          css={isSettingStaff() ? linkWrapperActiveStyle : linkWrapperStyle}
          onClick={() => history.push({ pathname: '/setting/staff', search: location.search })}
        >
          <div css={isSettingStaff() ? linkContentsActiveStyle : linkContentsStyle}>{t('Staff')}</div>
        </div>
        {hasCleaningPluginActive && (
          <div
            css={isSettingCleaningManager() ? linkWrapperActiveStyle : linkWrapperStyle}
            onClick={() => history.push({ pathname: '/setting/cleaning-manager', search: location.search })}
          >
            <div css={isSettingCleaningManager() ? linkContentsActiveStyle : linkContentsStyle}>{t('Cleaning-management')}</div>
          </div>
        )}
        {mailDeliveryPluginActive && (
          <div
            css={isSettingMailDelivery() ? linkWrapperActiveStyle : linkWrapperStyle}
            onClick={() => history.push({ pathname: '/setting/mail-delivery', search: location.search })}
          >
            <div css={isSettingMailDelivery() ? linkContentsActiveStyle : linkContentsStyle}>{t('Mail delivery')}</div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const sideMenuContainerStyle = css({
  minWidth: 180,
  zIndex: 1,
  background: 'white',
  position: 'sticky',
  top: 55,
  boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
  height: 'calc(100vh - 55px)',
  overflow: 'auto',
})

const linkWrapperStyle = css({
  height: 44,
  display: 'flex',
  padding: '0 16px 0 13px',
  cursor: 'pointer',
  ':hover': {
    background: '#f2f2f2',
  },
})

const linkWrapperActiveStyle = css(linkWrapperStyle, {
  background: '#f2f2f2',
  padding: '0 16px 0 10px',
})

const linkContentsStyle = css({
  lineHeight: '44px',
  marginLeft: 12,
  fontWeight: 'bold',
  color: '#676767',
  fontSize: 12,
  justifyContent: 'space-between',
  display: 'flex',
  width: '100%',
})

const linkContentsActiveStyle = css(linkContentsStyle, {
  color: '#F2A40B',
})
