import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

type Props = {
  name: string
  kana: string
  tel: string
  mail: string
  address: string
  userOrGuest: 'user' | 'guest'
}

export const ReservationUserGuest: React.FC<Props> = ({ userOrGuest, kana, name, tel, mail, address }) => {
  const { t } = useTranslation()
  return (
    <>
      <div css={reservationUserStyle}>
        <div css={reservationNameTitle}>{userOrGuest === 'user' ? t('Reservation name') : t('Guest name')}</div>
        <div css={kanaStyle}>{kana}</div>
        <div css={nameStyle}>{name}</div>
        <div css={detailCustomerSubBlockStyle}>
          <p css={tellStyle}>
            {t('Phone number')}: {tel}
          </p>
          <p css={mailStyle}>
            {t('Email')}: {mail}
          </p>
          <p css={addressStyle}>
            {t('Address')}: {address}
          </p>
        </div>
      </div>
    </>
  )
}

const detailNumberOfPeopleSubBlockStyle = css({
  display: 'flex',
  flexDirection: 'column',
})

const detailCustomerSubBlockStyle = css(detailNumberOfPeopleSubBlockStyle, {})

const reservationUserStyle = css({
  flex: 1,
  padding: 24,
})

const listSubTextStyle = css({
  fontSize: 14,
  color: '#272727',
  fontWeight: 'normal',
  paddingTop: 12,
  paddingLeft: 30,
})

const listSubCustomerTextStyle = css(listSubTextStyle, {
  paddingTop: 12,
  lineHeight: 1.4,
})

const reservationNameTitle = css({
  color: '#DD9914',
  margin: '0 0 11px 0',
  fontWeight: 'normal',
  fontSize: 12,
})

const kanaStyle = css({
  color: '#676767',
  fontSize: 12,
  margin: '0 0 6px 0',
})

const nameStyle = css({
  color: '#272727',
  fontSize: 18,
  fontWeight: 'bold',
  margin: '0 0 8px 0',
})

const tellStyle = css(listSubCustomerTextStyle, {
  backgroundImage: `url(${require('@/static/images/phone.svg')})`,
  backgroundPosition: '0% 83%',
  backgroundRepeat: 'no-repeat',
})

const mailStyle = css(listSubCustomerTextStyle, {
  backgroundImage: `url(${require('@/static/images/mail.svg')})`,
  backgroundPosition: '0% 83%',
  backgroundRepeat: 'no-repeat',
})

const addressStyle = css(listSubCustomerTextStyle, {
  backgroundImage: `url(${require('@/static/images/access.svg')})`,
  backgroundPosition: '0% 83%',
  backgroundRepeat: 'no-repeat',
})
