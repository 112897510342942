export enum ReportTypes {
  salesRoomDaily = 'salesRoomDaily',
  salesOtherDaily = 'salesOtherDaily',
  depositsDaily = 'depositsDaily',
  dailyOperations = 'dailyOperations',
  dailyDepartments = 'dailyDepartments',
  refundsDaily = 'refundsDaily',
  salesMonthly = 'salesMonthly',
  actualOperationsMonthly = 'actualOperationsMonthly',
  depositsMonthly = 'depositsMonthly',
  salesBySubjectsMonthly = 'salesBySubjectsMonthly',
  salesMonthlyByChannel = 'salesMonthlyByChannel',
}

export enum ReportPeriods {
  day = 'day',
  month = 'month',
}
