import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/ja'
import 'react-dates/initialize'

// components
import { Button } from '@/components/atoms/button'
import { DatePicker } from '@/components/organisms/reservation/_detail-edit-info/date-picker'

// models
import { AdvanceMoneySearchType, SelectBalanceType } from '@/models/sales-manager/advance-money'

type ContainerProps = {
  searchContent: AdvanceMoneySearchType
  onChange: (content: AdvanceMoneySearchType) => void
  onClear: () => void
  onSearch: () => void
}

export const DetailSearchPanel: React.FC<ContainerProps> = props => {
  const { t } = useTranslation()

  const setBeginPaymentDate = date => {
    props.onChange({ ...props.searchContent, beginPaymentDate: date })
  }

  const setEndPaymentDate = date => {
    props.onChange({ ...props.searchContent, endPaymentDate: date })
  }

  const setBeginWithdrawalDate = date => {
    props.onChange({ ...props.searchContent, beginWithdrawalDate: date })
  }

  const setEndWithdrawalDate = date => {
    props.onChange({ ...props.searchContent, endWithdrawalDate: date })
  }

  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    props.onChange({ ...props.searchContent, [name]: value })
  }

  return (
    <div css={dropDownSearchPanelStyle}>
      <div css={topSearchPanelStyle}>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Payment date')}</div>
          <div css={assignDateStyle}>
            <DatePicker date={props.searchContent?.beginPaymentDate} onDateChange={setBeginPaymentDate} placeHolder={t('Full date')} />
            <span css={wavyLineStyle}>~</span>
            <DatePicker date={props.searchContent?.endPaymentDate} onDateChange={setEndPaymentDate} placeHolder={t('Full date')} />
          </div>
        </div>

        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Withdrawal date')}</div>
          <div css={assignDateStyle}>
            <DatePicker
              date={props.searchContent?.beginWithdrawalDate}
              onDateChange={setBeginWithdrawalDate}
              placeHolder={t('Full date')}
            />
            <span css={wavyLineStyle}>~</span>
            <DatePicker date={props.searchContent?.endWithdrawalDate} onDateChange={setEndWithdrawalDate} placeHolder={t('Full date')} />
          </div>
        </div>

        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Balance')}</div>
          <select name="balance" css={assignSelectStyle} value={props.searchContent?.balance} onChange={handleContentChange}>
            <option value={SelectBalanceType.ALL}>{t('All')}</option>
            <option value={SelectBalanceType.WITH_BALANCE}>{t('With balance')}</option>
            <option value={SelectBalanceType.NO_BALANCE}>{t('No balance')}</option>
          </select>
        </div>

        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Payee')}</div>
          <input
            css={filedInputStyle}
            placeholder={t('Company name')}
            name="companyName"
            value={props.searchContent?.companyName}
            onChange={handleContentChange}
          />
        </div>
      </div>

      <div css={bottomSearchPanelStyle}>
        <div css={assignButtonContainerStyle}>
          <Button width={92} height={32} buttonType={3} fontSize={12} onClick={props.onClear}>
            {t('Clear')}
          </Button>
          <Button width={93} height={32} buttonType={1} fontSize={12} marginLeft={16} onClick={props.onSearch}>
            {t('Search')}
          </Button>
        </div>
      </div>
    </div>
  )
}

const dropDownSearchPanelStyle = css({
  width: '100%',
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 70,
  left: 0,
  border: '1px solid #f2f2f2',
  boxShadow: '0px 3px 4px #0000001a',
  borderRadius: 2,
})

const topSearchPanelStyle = css({
  display: 'flex',
  marginTop: '32px',
  borderBottom: '1px solid rgb(242, 242, 242)',
  paddingBottom: '28px',
})

const bottomSearchPanelStyle = css({
  display: 'flex',
  margin: '16px 31px',
})

const fieldBlockStyle = css({
  marginLeft: '24px',
  ':first-of-type': {
    marginLeft: '32px',
  },
})

const fieldTitleStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  color: '#676767',
  marginBottom: '12px',
})

const filedInputStyle = css({
  width: '265px',
  height: 32,
  padding: '8px 16px',
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  textAlign: 'left',
  backgroundSize: 10,
  '::placeholder': {
    color: '#CCCCCC',
    fontSize: 14,
  },
})

const assignButtonContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const assignDateStyle = css({
  width: '400px',
})

const assignSelectStyle = css({
  width: 93,
  height: 32,
  color: '#272727',
  fontSize: 14,
  border: '1px solid #CCCCCC',
  fontWeight: 'normal',
  borderRadius: 19,
  paddingLeft: 16,
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/arrow_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '95% center',
  backgroundSize: 10,
  display: 'block',
})

const wavyLineStyle = css({
  padding: '0 7px',
})
