import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Button } from '@/components/atoms/button'
import { bool } from 'aws-sdk/clients/signer'

type Props = {
  isShowNewButton: bool
  onNewAction?: () => void
}

export const Header: React.FC<Props> = ({ isShowNewButton, onNewAction }) => {
  const { t } = useTranslation()

  return (
    <div css={contentStyle}>
      <div css={titleTextStyle}>{t('Request')}</div>

      {isShowNewButton && (
        <Button buttonType={1} width={84} height={32} fontSize={12} onClick={onNewAction}>
          {t('Add new')}
        </Button>
      )}
    </div>
  )
}

const contentStyle = css({
  backgroundColor: '#FFFFFF',
  height: 50,
  display: 'flex',
  alignItems: 'center',
  padding: '0 32px',
  justifyContent: 'space-between',
  borderBottom: '1px solid #F2F2F2',
})

const titleTextStyle = css({
  fontWeight: 'bold',
  fontSize: '16px',
  letterSpacing: '0.64px',
  color: '#272727',
})
