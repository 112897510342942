import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { TextareaField } from '@/components/molecules/textarea-field'
import { css } from '@emotion/core'
import { InputField } from '@/components/molecules/input-field'
import { Select } from 'antd'
import { InputImage } from '@/components/molecules/settings/input-image'
import {
  CategoryType,
  ProductInputType,
  ProductType,
  SalesDepartmentType,
  SalesHourType,
  SalesSubjectType,
  TagType,
} from '@/models/mobile-request'
import { useErrorHandler } from '@/hooks/use-error-handler'
import {
  fetchMobileRequestCategories,
  fetchMobileRequestSalesHours,
  fetchMobileRequestTags,
  fetchSalesDepartmentForRequest,
  fetchSalesSubjectForProduct,
  postMobileRequestProducts,
  putMobileRequestProducts,
} from '@/apis/aipass'
import { LoadingContent } from '@/components/molecules/loading-content'
import { useForm, Controller } from 'react-hook-form'

type Props = {
  editing?: ProductType
  nextOrder: number
  onSave: () => void
  onClose: () => void
}

export const CreateProductModal = ({ editing, nextOrder, onSave, onClose }: Props) => {
  const { t, i18n } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    watch,
  } = useForm<Partial<ProductInputType>>({
    defaultValues: {
      name: editing?.name.ja || '',
      description: editing?.description.ja || '',
      imagePath: editing?.imagePath || '',
      price: editing?.price || undefined,
      stock: editing?.stock || undefined,
      categoryId: editing?.category?.id || undefined,
      tagIds: editing?.tags?.map(tag => tag.id) || undefined,
      salesHourId: editing?.salesHour?.id || undefined,
      salesDepartmentMasterId: editing?.salesDepartmentMasterId || undefined,
      salesSubjectMasterId: editing?.salesSubjectMasterId || undefined,
      order: editing?.order || nextOrder,
    },
  })
  const watchSalesDepartmentMasterId = watch('salesDepartmentMasterId')
  const [tags, setTags] = useState<TagType[]>([])
  const [salesHours, setSalesHours] = useState<SalesHourType[]>([])
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [salesDepartments, setSalesDepartments] = useState<SalesDepartmentType[]>([])
  const [salesSubjects, setSalesSubjects] = useState<SalesSubjectType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isInitializing, setIsInitializing] = useState<boolean>(true)
  const [isShowCropModal, setIsShowCropModal] = useState<boolean>(false)

  const computedIsInclude = (
    objects: CategoryType[] | SalesDepartmentType[] | SalesSubjectType[],
    id: string | number | undefined,
  ): boolean => {
    return objects.findIndex((o: CategoryType | SalesDepartmentType | SalesSubjectType) => o.id === id) !== -1
  }

  const save = async (formValues: Partial<ProductInputType>) => {
    if (formValues.salesDepartmentMasterId === undefined) {
      if (!window.confirm(t('Department ID and course ID are not registered Customer must account for it'))) {
        return
      }
    }
    try {
      setIsLoading(true)
      if (editing) {
        await putMobileRequestProducts(editing.id, formValues as ProductInputType)
      } else {
        await postMobileRequestProducts(formValues as ProductInputType)
      }
      onSave()
      onClose()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const loadMasterData = async () => {
    await Promise.all([
      fetchMobileRequestCategories().then(r => setCategories(r || [])),
      fetchMobileRequestSalesHours().then(r => setSalesHours(r || [])),
      fetchMobileRequestTags().then(r => setTags(r || [])),
      fetchSalesDepartmentForRequest().then(r => setSalesDepartments(r || [])),
      watchSalesDepartmentMasterId && fetchSalesSubjectForProduct(watchSalesDepartmentMasterId).then(r => setSalesSubjects(r || [])),
    ])
    setIsInitializing(false)
  }

  const changedSalesDepartment = async (value: string) => {
    setValue('salesDepartmentMasterId', value, { shouldDirty: true, shouldValidate: true })
    setValue('salesSubjectMasterId', '', { shouldDirty: true, shouldValidate: true })
    setSalesSubjects((await fetchSalesSubjectForProduct(value)) || [])
  }

  useEffect(() => {
    loadMasterData()
  }, [])

  return (
    <Modal customCss={modalStyle} style={{ visibility: isShowCropModal ? 'hidden' : 'visible' }}>
      <LoadingContent isLoading={isLoading || isInitializing} />
      <ModalHeader customCss={modalHeaderStyle}>{t('MobileRequest.Create request')}</ModalHeader>
      <ModalBody customCss={modalBodyStyle}>
        <div css={bodyContentStyle}>
          <div css={contentLeftBoxStyle}>
            <div>
              <div css={inputTitleTextStyle}>
                {t('Title')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <div css={[textareaStyle, { textarea: { height: 67 } }]}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name } }) => (
                    <TextareaField marginBottom={0} placeholder={t('Title name')} value={value} name={name} handleChangeData={onChange} />
                  )}
                />
              </div>
            </div>

            <div css={inputAreaStyle}>
              <div style={{ paddingRight: 8, width: '50%' }}>
                <div css={inputTitleTextStyle}>
                  {t('Category')}
                  <div css={requireLabelTextStyle}>※</div>
                </div>
                <Controller
                  name="categoryId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      css={selectBoxStyle}
                      suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                      placeholder={t('Please select')}
                      value={!isInitializing ? value : undefined}
                      onChange={onChange}
                    >
                      {!computedIsInclude(categories, value) && (
                        <Select.Option disabled value={value}>
                          {editing?.category?.name[i18n.language]}
                        </Select.Option>
                      )}
                      {categories.map(category => (
                        <Select.Option key={category.id} value={category.id}>
                          {category.name[i18n.language]}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
              </div>
              <div style={{ paddingLeft: 8, width: '50%' }}>
                <div css={inputTitleTextStyle}>{t('Tag')}</div>
                <Controller
                  name="tagIds"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      css={selectBoxStyle}
                      mode="multiple"
                      showSearch={false}
                      placeholder={t('Please select')}
                      value={!isInitializing ? value : undefined}
                      onChange={onChange}
                    >
                      {tags.map(tag => (
                        <Select.Option key={tag.id} value={tag.id}>
                          {tag.name[i18n.language]}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>

            <Controller
              name="imagePath"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <InputImage customStyle={inputAreaStyle} value={value} onChange={onChange} onShowModal={setIsShowCropModal} />
              )}
            />
            <div css={inputAreaStyle}>
              <div style={{ paddingRight: 8, width: '50%' }}>
                <div css={inputTitleTextStyle}>{t('Display order')}</div>
                <Controller
                  name="order"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <InputField marginBottom={0} placeholder="00000" type="number" value={value} name={name} handleChangeData={onChange} />
                  )}
                />
              </div>
            </div>
          </div>

          <div css={contentRightBoxStyle}>
            <div>
              <div css={inputTitleTextStyle}>{t('Content')}</div>
              <div css={[textareaStyle, { textarea: { height: 99 } }]}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <TextareaField marginBottom={0} placeholder={t('Content')} value={value} name={name} handleChangeData={onChange} />
                  )}
                />
              </div>
            </div>

            <div css={inputAreaStyle}>
              <div style={{ paddingRight: 8, width: '50%' }}>
                <div css={inputTitleTextStyle}>
                  {t('Amount (tax included)')}
                  <div css={requireLabelTextStyle}>※</div>
                </div>
                <Controller
                  name="price"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name } }) => (
                    <InputField marginBottom={0} placeholder="00000" type="number" value={value} name={name} handleChangeData={onChange} />
                  )}
                />
              </div>
              <div style={{ paddingLeft: 8, width: '50%' }}>
                <div css={inputTitleTextStyle}>{t('Stock')}</div>
                <Controller
                  name="stock"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <InputField marginBottom={0} placeholder="00000" type="number" value={value} name={name} handleChangeData={onChange} />
                  )}
                />
              </div>
            </div>

            <div css={inputAreaStyle}>
              <div style={{ paddingRight: 8, width: '50%' }}>
                <div css={inputTitleTextStyle}>{t('MobileRequest.Sales hours')}</div>
                <Controller
                  name="salesHourId"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      css={selectBoxStyle}
                      suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                      placeholder={t('Please select')}
                      value={!isInitializing ? value : undefined}
                      onChange={onChange}
                    >
                      <Select.Option value={''}>{t('Dont choose')}</Select.Option>
                      {salesHours.map(salesHour => (
                        <Select.Option key={salesHour.id} value={salesHour.id}>
                          {salesHour.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </div>

            <div css={inputAreaStyle}>
              <div style={{ paddingRight: 8, width: '50%' }}>
                <div css={inputTitleTextStyle}>
                  {t('Department ID')}/{t('Department name')}
                </div>
                <Controller
                  name="salesDepartmentMasterId"
                  control={control}
                  render={({ field: { value } }) => (
                    <Select
                      css={selectBoxStyle}
                      suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                      placeholder={t('Please select')}
                      value={!isInitializing ? value : undefined}
                      onChange={changedSalesDepartment}
                    >
                      {!computedIsInclude(salesDepartments, value) && (
                        <Select.Option disabled value={value}>
                          {editing?.salesDepartmentMasterCode}/{editing?.salesDepartmentMasterName}
                        </Select.Option>
                      )}
                      <Select.Option value="">{t('Dont choose')}</Select.Option>
                      {salesDepartments.map(salesDepartment => (
                        <Select.Option key={salesDepartment.id} value={salesDepartment.id}>
                          {salesDepartment.departmentCode}/{salesDepartment.departmentName}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
              </div>
              {!!watchSalesDepartmentMasterId && (
                <div style={{ paddingLeft: 8, width: '50%' }}>
                  <div css={inputTitleTextStyle}>
                    {t('Course ID')}/ {t('Subject name')}
                    <div css={requireLabelTextStyle}>※</div>
                  </div>
                  <Controller
                    name="salesSubjectMasterId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        css={selectBoxStyle}
                        suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                        placeholder={t('Please select')}
                        value={!isInitializing ? value : undefined}
                        onChange={onChange}
                      >
                        {!computedIsInclude(salesSubjects, value) && (
                          <Select.Option disabled value={value}>
                            {editing?.salesSubjectMasterCode}/{editing?.salesSubjectMasterName}
                          </Select.Option>
                        )}
                        {salesSubjects.map(salesSubject => (
                          <Select.Option key={salesSubject.id} value={salesSubject.id}>
                            {salesSubject.subjectCode}/{salesSubject.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter customCss={modalFooterStyle}>
        <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(save)} isDisabled={!isValid}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = css({
  top: '5%',
  left: '10%',
  right: '10%',
  minHeight: '650px',
  maxHeight: '650px',
  width: '80%',
})

const modalHeaderStyle = css({
  height: '60px',
  boxShadow: 'none',
  color: '#272727',
})

const modalBodyStyle = css({
  height: 530,
})

const modalFooterStyle = css({
  height: '60px',
  display: 'flex',
})

const bodyContentStyle = css({
  padding: '32px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #CCCCCC',
  borderRadius: '5px',
  width: '100%',
  minHeight: 466,
  display: 'flex',
})

const contentLeftBoxStyle = css({
  width: '50%',
  paddingRight: '32px',
})

const contentRightBoxStyle = css({
  width: '50%',
  borderLeft: '1px solid #F2F2F2',
  paddingLeft: '32px',
})

const textareaStyle = css({
  textarea: {
    marginBottom: 0,
    padding: '16px',
    lineHeight: '16px',
    letterSpacing: '1.4px',
    ':focus': {
      padding: '15px',
    },
  },
})

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputAreaStyle = css({
  paddingTop: 24,
  display: 'flex',
})

const selectBoxStyle = css({
  width: '100%',
  '> div': {
    borderRadius: '16px',
    border: '1px solid #CCCCCC',
    boxShadow: 'none !important',
    borderColor: '#CCCCCC !important',
  },
})
