import React from 'react'
import { Stats } from './stats'
import { Calendar } from './calendar'
import { css } from '@emotion/core'

type DayStatsProps = {
  selectedDate: any
  setSelectedDate: (arg0: any) => void
  roomStats: {
    totalRoomDoNotDisturb: number
    totalRoomNotClean: number
    totalRoomNotCleanWithStay: number
    totalRoomNotCleanWithOut: number
    totalRoomCleaned: number
    totalRoomInspected: number
  }
}

export const DayStats = ({ selectedDate, setSelectedDate, roomStats }: DayStatsProps) => {
  return (
    <div css={dayStatsLayoutWrapper}>
      <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      <Stats roomStats={roomStats} />
    </div>
  )
}

const dayStatsLayoutWrapper = css({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  height: '100%',
})
