import React, { useState } from 'react'
import { SingleDatePicker, CalendarDay } from 'react-dates'
import { useTranslation } from 'react-i18next'

// model
import { GuestRoomAssignResponseType } from '@/models/guest-room/guest-room-assign'
import moment from 'moment'

type DatePickerProps = {
  room: GuestRoomAssignResponseType['rooms'][0]
  dateType: 'checkin' | 'checkout'
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>
}

export const DatePicker: React.FC<DatePickerProps> = ({ room, dateType, setIsEdit }) => {
  const { t } = useTranslation()
  const [calendarFocused, setCalendarFocused] = useState<boolean>(false)

  const getDate = () => {
    switch (dateType) {
      case 'checkin':
        return moment(room.assignCheckinDate)
      case 'checkout':
        return moment(room.assignCheckoutDate)
    }
  }
  const onDateChange = (date: moment.Moment | null) => {
    if (!date) return

    switch (dateType) {
      case 'checkin':
        room.assignCheckinDate = date?.format('YYYY-MM-DD HH:mm:ss')
        setIsEdit(true)
        break
      case 'checkout':
        room.assignCheckoutDate = date?.format('YYYY-MM-DD HH:mm:ss')
        setIsEdit(true)
        break
    }
  }
  return (
    <>
      <SingleDatePicker
        date={getDate()}
        onDateChange={onDateChange}
        focused={calendarFocused}
        onFocusChange={v => setCalendarFocused(Boolean(v.focused))}
        numberOfMonths={1}
        isOutsideRange={() => false}
        displayFormat={t('MM-DD')}
        monthFormat={t('MMM-YY')}
        id="date"
        hideKeyboardShortcutsPanel={true}
        navPrev={
          <div className="monthPrev">
            <img
              alt="arrow"
              src={require('@/static/images/icon_caalender_left.svg')}
              onMouseOver={e => (e.currentTarget.src = require('@/static/images/icon_caalender_left.svg'))}
              onMouseOut={e => (e.currentTarget.src = require('@/static/images/icon_caalender_left.svg'))}
            />
          </div>
        }
        navNext={
          <div className="monthNext">
            <img
              alt="arrow"
              src={require('@/static/images/icon_caalender_right.svg')}
              onMouseOver={e => (e.currentTarget.src = require('@/static/images/icon_caalender_right.svg'))}
              onMouseOut={e => (e.currentTarget.src = require('@/static/images/icon_caalender_right.svg'))}
            />
          </div>
        }
        renderCalendarDay={props => (
          <CalendarDay
            {...props}
            renderDayContents={day => (
              <div className="day">
                <span>{day?.format('D')}</span>
              </div>
            )}
          />
        )}
      />
    </>
  )
}
