import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useHistory, useLocation } from 'react-router-dom'

// components
import { CsvDownloadHistoryList } from '@/components/pages/accommodation-management/download-history/_download-history/csv-download-history-list'

// constants
import { wholeContainerStyle, mainColumnStyle } from '@/constants/layout'
import { SideMenu } from '@/components/organisms/side-menu'
import { Header } from '@/components/organisms/header'
import { AccountContext } from '@/contexts/account'

// apis
import { fetchDownloadCsvHistory } from '@/apis/aipass'

export const DownloadHistory: React.FC = () => {
  const [downloadCsvHistory, setDownloadCsvHistory] = useState<any>([])
  const { account } = useContext<any>(AccountContext)
  const history = useHistory()
  const location = useLocation()
  const { state } = useLocation<{ fromEntireSearch: boolean }>()
  const { t } = useTranslation()
  const windows = useWindowSize()

  // Get CSV download list
  const _fetchDownloadCsvHistory = () => {
    fetchDownloadCsvHistory().then(res => {
      setDownloadCsvHistory(res)
    })
  }

  // Back to accommodation list
  const goBack = () => {
    if (state?.fromEntireSearch) {
      history.push({
        pathname: '/entire-search',
        search: location.search,
      })
    } else {
      history.push({
        pathname: '/accommodation-management',
        search: location.search,
      })
    }
  }

  let fetchInterval: NodeJS.Timeout
  useEffect(() => {
    if (account && account?.hotel) {
      _fetchDownloadCsvHistory()
      if (fetchInterval) {
        clearInterval(fetchInterval)
      }
      fetchInterval = setInterval(() => _fetchDownloadCsvHistory(), 5000)
      return () => clearInterval(fetchInterval)
    }
  }, [account])

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div css={mainColumnStyle}>
        <Header title={t('Accommodation management')} isBack goBack={goBack} />
        <div
          css={settingMainContainerStyle}
          style={{
            minHeight: windows.height - 180,
          }}
        >
          <CsvDownloadHistoryList downloadCsvHistory={downloadCsvHistory} />
        </div>
      </div>
    </div>
  )
}

const settingMainContainerStyle = css({
  padding: 24,
})
