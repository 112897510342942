import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { css } from '@emotion/core'

// apis
import { fetchFacilityBasicInfo, updateFacilityBasicInfo } from '@/apis/aipass'

// constants
import { listContainerStyle } from '@/constants/layout'

// components
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { EditFooter } from '@/components/organisms/edit-footer'
import { ToggleButton } from '@/components/molecules/settings/toggle-button'

export const SettingAccountReceiptEdit: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation<any>()
  const history = useHistory()
  const { hotelStatus } = location.state

  //Hooks
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFormError, setIsFormError] = useState<boolean>(false)
  const [hotelName, setHotelName] = useState(hotelStatus.hotelName)
  const [hotelNameLatin, setHotelNameLatin] = useState(hotelStatus.hotelNameLatin)
  const [logoImageId, setLogoImageId] = useState(hotelStatus.logoImageId)
  const [extImageId, setExtImageId] = useState(hotelStatus.extImageId)
  const [telephone, setFrontTelephone] = useState<string>('')
  const [checkinBeginTime, setCheckinBeginTime] = useState<string>('')
  const [checkinEndTime, setCheckinEndTime] = useState<string>('')
  const [checkoutBeginTime, setCheckoutBeginTime] = useState<string>('')
  const [checkoutEndTime, setCheckoutEndTime] = useState<string>('')
  const [frontOpenTime, setFrontOpenTime] = useState<string>('')
  const [frontCloseTime, setFrontCloseTime] = useState<string>('')
  const [receiptHotelName, setReceiptHotelName] = useState<string>('')
  const [receiptAddress, setReceiptAddress] = useState<string>('')
  const [receiptRegistrationNumber, setReceiptRegistrationNumber] = useState<string>('')
  const [receiptTelephone, setReceiptTelephone] = useState<string>('')
  const [receiptNotPlanBreak, setReceiptNotPlanBreak] = useState<boolean>(false)

  // Hotel detail update process
  const onSaveData = () => {
    if (isFormError) {
      console.log(t('Failed to save'))
    } else {
      _updateFacilityBasicInfo()
      setIsLoading(true)
    }
  }

  const _updateFacilityBasicInfo = async () => {
    const { pageId } = hotelStatus
    const data = {
      pageId,
      hotelName,
      hotelNameLatin,
      telephone,
      checkinBeginTime,
      checkinEndTime,
      checkoutBeginTime,
      checkoutEndTime,
      frontOpenTime,
      frontCloseTime,
      logoImageId,
      extImageId,
      receiptHotelName,
      receiptAddress,
      receiptTelephone,
      receiptRegistrationNumber,
      receiptPlanBreak: !receiptNotPlanBreak,
    }
    await updateFacilityBasicInfo(data)
      .then(() => {
        setIsLoading(false)
        history.push({ pathname: '/setting/account' })
      })
      .catch(() => {
        console.log(t('Failed to save'))
      })
  }

  useEffect(() => {
    _fetchFacilityBasicInfo()
  }, [])

  const _fetchFacilityBasicInfo = async () => {
    await fetchFacilityBasicInfo()
      .then(res => {
        if (res !== undefined) {
          setFrontTelephone(res?.basicInfo.telephone)
          setCheckinBeginTime(res?.basicInfo.checkinBeginTime)
          setCheckinEndTime(res?.basicInfo.checkinEndTime)
          setCheckoutBeginTime(res?.basicInfo.checkoutBeginTime)
          setCheckoutEndTime(res?.basicInfo.checkoutEndTime)
          setFrontOpenTime(res?.basicInfo.frontOpenTime)
          setFrontCloseTime(res?.basicInfo.frontCloseTime)
          setHotelName(res?.basicInfo.hotelName)
          setHotelNameLatin(res?.basicInfo.hotelNameLatin)
          setLogoImageId(res?.basicInfo.logoImageId)
          setExtImageId(res?.basicInfo.extImageId)
          setReceiptHotelName(res?.basicInfo.receiptHotelName)
          setReceiptAddress(res?.basicInfo.receiptAddress)
          setReceiptTelephone(res?.basicInfo.receiptTelephone)
          setReceiptRegistrationNumber(res?.basicInfo.receiptRegistrationNumber)
          setReceiptNotPlanBreak(!res?.basicInfo.receiptPlanBreak)
          setIsLoading(false)
        }
      })
      .catch(() => {
        console.log(t('Failed to save'))
      })
  }

  const footerContent = <EditFooter onCancel={history.goBack} onSave={onSaveData} />
  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <div css={hotelAccountDetailsContainerStyle}>
        <div css={detailsHeaderContainerStyle}>
          <div css={detailsHeaderTitleStyle}>{t('Receipt')}</div>
        </div>
        <div css={detailsMainContainerStyle}>
          <ul css={detailsMainLeftContainerStyle}>
            <li css={facilityNameList}>
              <div css={listDetailTitle}>{t('A name of a hotel')}</div>
              <input
                css={hotelInputContentsStyle}
                type="text"
                placeholder={'HOTEL CUICIN'}
                value={receiptHotelName ? receiptHotelName : ''}
                onChange={e =>
                  e.target.value
                    ? (setReceiptHotelName(e.target.value), setIsFormError(false))
                    : (setReceiptHotelName(''), setIsFormError(true))
                }
              />
            </li>
            <li css={facilityNameList}>
              <div css={listDetailTitle}>{t('Phone number')}</div>
              <input
                type="text"
                css={hotelInputContentsStyle}
                placeholder={'08012345678'}
                value={receiptTelephone}
                onChange={e => setReceiptTelephone(e.target.value)}
              />
            </li>
            <li css={facilityNameList}>
              <div css={listDetailTitle}>{t('Address')}</div>
              <input
                css={hotelInputContentsStyle}
                type="text"
                placeholder={t('1-1 Chiyoda Chiyoda-ku')}
                value={receiptAddress || ''}
                onChange={e => (e.target.value ? setReceiptAddress(e.target.value) : setReceiptAddress(''))}
              />
            </li>
            <li css={facilityNameList}>
              <div css={listDetailTitle}>{t('Registration number')}</div>
              <input
                css={hotelInputContentsStyle}
                type="text"
                maxLength={14}
                placeholder="T1234567891234"
                value={receiptRegistrationNumber || ''}
                onChange={e => (e.target.value ? setReceiptRegistrationNumber(e.target.value) : setReceiptRegistrationNumber(''))}
              />
            </li>
            <li css={facilityNameList}>
              <div css={listDetailTitle}>{t('Displayed by plan name and subject set name')}</div>
              <ToggleButton
                value={receiptNotPlanBreak}
                onChange={() => setReceiptNotPlanBreak(!receiptNotPlanBreak)}
              />
            </li>
          </ul>
        </div>
      </div>
    </SettingsLayout>
  )
}

// topStyle
const hotelAccountDetailsContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 410,
})

const detailsHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
})

const detailsHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
})

const detailsMainContainerStyle = css({
  display: 'flex',
  minHeight: 360,
  borderBottom: '1px solid #F2F2F2',
})

const detailsMainLeftContainerStyle = css({
  width: '80%',
  marginBottom: 36,
})

const hotelInputContentsStyle = css({
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  height: 32,
  width: '90%',
  padding: '0 16px',
  marginBottom: 5,
  '::placeholder': {
    color: '#CCCCCC',
  },
})

const hotelLogoList = css({
  margin: '35px 0px 0 35px',
})
const facilityNameList = css(hotelLogoList)
const listDetailTitle = css({
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#676767',
  margin: '10px 0 10px 0',
})
