import React from 'react'
import { SettingTab } from '@/components/molecules/settings/setting-tab'
import { listContainerStyle } from '@/constants/layout'
import { css } from '@emotion/core'

export const GuestAppTab: React.FC<{ currentTab: 'Asset' | 'Design' }> = ({ currentTab, children }) => {
  return (
    <SettingTab
      currentTab={currentTab}
      tabs={[
        {
          name: 'Asset',
          path: '/setting/guest-app/asset',
        },
        {
          name: 'Design',
          path: '/setting/guest-app/design',
        },
      ]}
      customTabStyle={css({ width: 160 })}
    >
      <div css={topImageContainerStyle}>{children}</div>
    </SettingTab>
  )
}

const topImageContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 'auto',
  marginBottom: -10,
})
