import { t } from 'i18next'
import { InvoiceAccount } from './admin-account'

export const InvoiceStatusType = {
  InProgress: 'inProgress',
  Expired: 'expired',
  Completed: 'completed',
  Cancel: 'cancel',
} as const
export type InvoiceStatusType = (typeof InvoiceStatusType)[keyof typeof InvoiceStatusType]

export const InvoiceStatusValues: Record<InvoiceStatusType, string> = {
  [InvoiceStatusType.InProgress]: t('In progress'),
  [InvoiceStatusType.Expired]: t('Not collected'),
  [InvoiceStatusType.Completed]: t('Collected'),
  [InvoiceStatusType.Cancel]: t('Cancelled'),
}

export type SalesType = {
  salesId: string
  hotelId: string
  reservationId: string
  accommodationId: string
  saleItem: {
    salesDate: string
    unitPrice: number
    quantity: number
    taxRate: number
    isDiscount: boolean
    subjectName: string
  }
  isIssued: boolean
}

export type ReservationType = {
  reservationId: string
  accommodationId: string
  reservationUserName: string
  reservationUserNameKana: string
  guestName: string
  guestNameKana: string
  checkinDate: string
}

export type InvoiceType = {
  id: string
  paymentDate: string
  status: InvoiceStatusType
  totalAmount: number
  fileName: string
  filePath: string
  invoiceNumber: string
  createdAt: string
  completedAt: string | undefined
  hotelAccount: InvoiceAccount & {
    registrationNumber: string
  }
  invoiceSaleItems: SalesType[]
}

export type InvoiceSearchType = {
  filterStatus?: InvoiceStatusType[]
  paymentDateFrom?: string
  paymentDateTo?: string
  page?: number
}
