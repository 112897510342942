import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// components
import { Button } from '@/components/atoms'

// models
import { GuestRoomAssignResponseType, PaxFullObject } from '@/models/guest-room/guest-room-assign'
import { AdminReservationResponseType } from '@/models/reservation/admin-reservation'

// constant
import { MiwaLockStatus } from '@/constants/miwa-lock'
import { PaxModal } from '@/components/organisms/customer/pax-modal'

export type RoomPropType =
  | GuestRoomAssignResponseType['rooms'][0]
  | ({
      typeNameJa: string
      assignCheckinDate: string
      assignCheckoutDate: string
      checkinTime?: string
      checkoutTime?: string
      lastCheckinDatetime?: string
      assignGuestName?: string
      reservationGuestName?: string
      roomNumber?: string
      guestRoomId?: string
      guestRoomAssignId?: string
      issueType?: string
    } & PaxFullObject)

type NotEditFormItemProps = {
  reservation: AdminReservationResponseType['reservations']
  room: RoomPropType
  roomIndex: number
  hasSmartKeyPlugin: boolean
  hasMiwaLockPlugin: boolean
  miwaButtonType: (issueType: string) => number
  onOpenKeyCardReleaseModal: (room: any) => void
  getGuestRoomKeyNumber: (room: any) => string
  onEditRoomAssignGuestName: (event: any, index: number) => void
  onEditRoomAssignPax: (event: any, index: number, type?: string) => void
  onEditPaxFull: (value: PaxFullObject, index: number) => void
  isCurrentDateSameOrBeforeCheckoutDate: boolean
  hideEdit?: boolean
}

export const NotEditFormItem: React.FC<NotEditFormItemProps> = ({
  reservation,
  room,
  roomIndex,
  miwaButtonType,
  hasSmartKeyPlugin,
  hasMiwaLockPlugin,
  onOpenKeyCardReleaseModal,
  getGuestRoomKeyNumber,
  onEditRoomAssignGuestName,
  onEditRoomAssignPax,
  onEditPaxFull,
  isCurrentDateSameOrBeforeCheckoutDate,
  hideEdit,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation<any>()

  return (
    <>
      <tr key={roomIndex}>
        <td>{room?.guestRoomAssignId?.slice(-4).toUpperCase()}</td>
        <td
          onClick={() =>
            history.push({
              pathname: '/room-manager/daily',
              search: `reservationId=${reservation.reservationId}`,
              state: { ...location.state },
            })
          }
        >
          <div>
            {room.assignCheckinDate ? dayjs(room.assignCheckinDate).format(t('MM-DD')) : '-'} 〜
            {room.assignCheckoutDate ? dayjs(room.assignCheckoutDate).format(t('MM-DD')) : '-'}
          </div>
        </td>
        <td>
          {!hideEdit ? (
            <input
              css={inputGuestNameStyle}
              // TODO: Delete reservationGuestName as soon as assign_guest_name in guest_room_assign table is modified
              value={room.assignGuestName ? room.assignGuestName : room.reservationGuestName ? room.reservationGuestName : ''}
              onChange={event => onEditRoomAssignGuestName(event, roomIndex)}
            />
          ) : (
            <>{room.assignGuestName ? room.assignGuestName : room.reservationGuestName ? room.reservationGuestName : '-'}</>
          )}
        </td>
        <td>
          <div css={paxContainer}>
            {!hideEdit ? (
              <input
                css={[inputCapacityStyle, { pointerEvents: 'none' }]}
                // TODO: Delete reservationGuestName as soon as assign_guest_name in guest_room_assign table is modified
                value={room.pax ? Number(room.pax).toString() : '0'}
                onChange={event => onEditRoomAssignPax(event, roomIndex)}
                type="number"
                min={0}
              />
            ) : (
              <>{room.pax || '0'}</>
            )}
            {!hideEdit && <PaxModal room={room} disabled={hideEdit} roomIndex={roomIndex} onEditPaxFull={onEditPaxFull} />}
          </div>
        </td>
        <td
          onClick={() =>
            history.push({
              pathname: '/room-manager/daily',
              search: `reservationId=${reservation.reservationId}`,
              state: { ...location.state },
            })
          }
        >
          {room.typeNameJa ?? '-'}
        </td>
        <td
          onClick={() =>
            history.push({
              pathname: '/room-manager/daily',
              search: `reservationId=${reservation.reservationId}`,
              state: { ...location.state },
            })
          }
        >
          {room.roomNumber ?? '-'}
        </td>
        {hasSmartKeyPlugin ? (
          hasMiwaLockPlugin && room?.issueType ? (
            <td>
              <Button
                width={72}
                height={32}
                fontSize={12}
                buttonType={miwaButtonType(room?.issueType)}
                isDisabled={!isCurrentDateSameOrBeforeCheckoutDate}
                onClick={() => onOpenKeyCardReleaseModal(room)}
              >
                {room?.issueType === MiwaLockStatus.ISSUE ? t('Issue') : t('Reissue')}
              </Button>
            </td>
          ) : (
            <td
              onClick={() =>
                history.push({
                  pathname: '/room-manager/daily',
                  search: `reservationId=${reservation.reservationId}`,
                  state: { ...location.state },
                })
              }
            >
              {getGuestRoomKeyNumber(room)}
            </td>
          )
        ) : (
          <td>-</td>
        )}
      </tr>
    </>
  )
}

const inputGuestNameStyle = css({
  color: '#272727',
  padding: '0 10px',
  height: 32,
  borderRadius: 17,
  marginRight: 16,
  appearance: 'none',
  border: '1px solid #CCC',
  width: 'calc(100% - 16px)',
})

const inputCapacityStyle = css(inputGuestNameStyle, {
  width: 64,
})

const paxContainer = css({
  '.pax-modal': {
    display: 'none',
    position: 'absolute',
  },
  ':hover .pax-modal': {
    display: 'block',
  },
})
