import React from 'react'
import { tableHeaderThStyle, tableBodyTdStyleRead, tableBodyTdStyleUnread, tableBodyTdStyle } from '@/constants/layout'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { dividedRoomNumber, dividedTypeName } from '@/libs/divide-list'
import { guestNameUnreadEnStyle, guestNameUnreadJaStyle } from '@/components/pages/accommodation-management/list-layout'
import { Link } from 'react-router-dom'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { useWindowSize } from 'react-use'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import 'react-dates/initialize'

type Props = {
  reservations: [any]
}

export const List = ({ reservations }: Props) => {
  const { t, i18n } = useTranslation()
  const windows = useWindowSize()

  const goToDetailFromEntireSearch = (id: string, reservationId: string, approvedStatus: number, checkinId: string) => {
    let tabType

    if (approvedStatus === ApprovedStatusType.Reservation) {
      tabType = 'reservation'
    } else if (approvedStatus === ApprovedStatusType.Checkin) {
      id = checkinId
      tabType = 'checkin'
    } else if (approvedStatus === ApprovedStatusType.Stay) {
      id = checkinId
      tabType = 'stay'
    } else if (approvedStatus === ApprovedStatusType.Checkout) {
      id = checkinId
      tabType = 'checkout'
    }

    const queryString = require('query-string')
    const queryParams = queryString.parse(window.location.search)
    delete queryParams['reservationId']

    return {
      pathname: `/${tabType}/${id}`,
      search: `${queryString.stringify(queryParams)}&reservationId=${reservationId}`,
      state: {
        fromEntireSearch: true,
      },
    }
  }

  return (
    <>
      <div css={{ overflow: 'auto', height: windows.height - 218 }}>
        <table css={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th css={[tableHeaderThStyle, { paddingLeft: 32 }]}>{t('Reservation person')}</th>
              <th css={tableHeaderThStyle}>{t('Guest')}</th>
              <th css={tableHeaderThStyle}>{t('Reservation ID')}</th>
              <th css={tableHeaderThStyle}>{t('Room type')}</th>
              <th css={tableHeaderThStyle}>{t('Room number')}</th>
              <th css={tableHeaderThStyle}>{t('Status')}</th>
              <th css={tableHeaderThStyle}>{t('Date of stay')}</th>
            </tr>
          </thead>
          <tbody>
            {reservations &&
              reservations.map((v: any, index) => {
                return (
                  <Link
                    css={[
                      v.reservationRead ? tableBodyTdStyleRead : tableBodyTdStyleUnread,
                      { display: 'table-row', ':hover': { color: '#000' } },
                    ]}
                    key={index}
                    to={goToDetailFromEntireSearch(v.id, v.reservationId, v.approvedStatus, v.checkinId)}
                  >
                    <td css={[tableBodyTdStyle, { paddingLeft: 32 }]}>
                      <div css={i18n.language === 'en' ? guestNameUnreadEnStyle : guestNameUnreadJaStyle}>
                        <div css={SubscriberNameKanaStyle}>{v.nameKana ? v.nameKana : '-'}</div>
                        <div css={SubscriberNameStyle}>{v.name ? v.name : '-'}</div>
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle, { paddingLeft: 8 }]}>
                      <div css={i18n.language === 'en' ? guestNameUnreadEnStyle : guestNameUnreadJaStyle}>
                        <div css={SubscriberNameKanaStyle}>{v.guestNameKana ? v.guestNameKana : '-'}</div>
                        <div css={SubscriberNameStyle}>{v.guestName ? v.guestName : '-'}</div>
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 200 }]}>
                      <div css={!v.reservationRead ? unreadStyle : readStyle}>
                        <div css={reservationIdStyle}>{v.reservationId ? v.reservationId : '-'}</div>
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 200 }]}>
                      <div css={[roomTypeStyle, !v.reservationRead ? unreadDataRoomNumberStyle : readDataRoomNumberStyle]}>
                        {dividedTypeName(v.rooms)}
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 150 }]}>
                      <div css={roomNumberStyle}>
                        {v.rooms.length !== 0 ? (
                          <div css={!v.reservationRead ? unreadDataRoomNumberStyle : readDataRoomNumberStyle}>
                            {dividedRoomNumber(v.rooms)}
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle]}>
                      <div css={statusTagStyle}>
                        {v.approvedStatus === 0 && <div css={statusTagOrangeStyle}>{t('Reserve')}</div>}
                        {v.approvedStatus === 1 && <div css={statusTagRedStyle}>{t('Checkin')}</div>}
                        {v.approvedStatus === 2 && <div css={statusTagBlueStyle}>{t('During your stay')}</div>}
                        {v.approvedStatus === 3 && <div css={statusTagGreenStyle}>{t('Checkout')}</div>}
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle]}>
                      <div css={!v.reservationRead ? unreadStyle : readStyle}>
                        {v.checkinDate === null ? (
                          '-'
                        ) : (
                          <>
                            {dayjs(v.checkinDate).format(t('YYYY-MM-DD'))}
                            <span style={{ paddingLeft: 5 }}>
                              {v.nights ?? 0}
                              {t('Night')}
                            </span>
                          </>
                        )}
                      </div>
                    </td>
                  </Link>
                )
              })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const dataTextStyle = css({
  paddingRight: 16,
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const SubscriberNameKanaStyle = css(dataTextStyle, {
  fontSize: 12,
  letterSpacing: 0.48,
  lineHeight: 1.5,
  color: '#676767',
})

const SubscriberNameStyle = css(dataTextStyle, {
  fontSize: 14,
  lineHeight: 1.5,
})

const reservationIdStyle = css(dataTextStyle, {
  fontSize: 14,
  lineHeight: 2.5,
  color: '#272727',
})

const roomTypeStyle = css(dataTextStyle, {
  fontSize: 14,
  lineHeight: 2.5,
  color: '#272727',
})

const dataRoomNumberStyle = css(dataTextStyle, {})

const roomNumberStyle = css(dataTextStyle, {
  fontSize: 14,
  lineHeight: 2.5,
  color: '#272727',
  minWidth: 100,
})

const statusTagStyle = css({
  paddingRight: 32,
  fontSize: 12,
  width: 145,
  minWidth: 145,
})

const statusTagOrangeStyle = css({
  textAlign: 'center',
  border: '1px solid #F2A40B',
  borderRadius: 5,
  color: '#F2A40B',
  padding: '4px 10px',
})

const statusTagRedStyle = css(statusTagOrangeStyle, { color: '#f47110', borderColor: '#f47110' })
const statusTagBlueStyle = css(statusTagOrangeStyle, { color: '#3E85CC', borderColor: '#3E85CC' })
const statusTagGreenStyle = css(statusTagOrangeStyle, { color: '#7dc931', borderColor: '#7dc931' })

const unreadStyle = css({
  fontWeight: 'bold',
})

const readStyle = css({
  fontWeight: 'normal',
})

const unreadDataRoomNumberStyle = css(unreadStyle, dataRoomNumberStyle)

const readDataRoomNumberStyle = css(readStyle, dataRoomNumberStyle)
