import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { css } from '@emotion/core'

//components
import { DatePicker } from '@/components/organisms/customer/sales-manager/date-picker'

// constants
import { deleteStyle } from '@/constants/layout'

// models
import { DiscountSettingType, PaymentSettingType, SalesDiscountType } from '@/models/sales'
import { ReservationType } from '@/models/reservation'
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'
import { Cascader, Select } from 'antd'
import {
  getRoomCascadeOptionsByReservations,
  findRoomCascadeOptionByGuestRoomAssignId,
  roomCascadeOptionsLabelRender,
} from '@/utils/reservations'

type NotConfirmedDiscountProps = {
  index: number
  discount: SalesDiscountType
  salesDiscounts: DiscountSettingType[]
  salesDiscountPayments: PaymentSettingType[]
  confirmedDay: string
  isEditMode: boolean
  changeNotConfirmedDate: (date, index) => void
  changeNotConfirmedState: (e, index) => void
  deleteDiscount: (index) => void
  setIsEditMode: (boolean) => void
  currentTabReservationId?: string
  accountsReceivableSettings: AccountsReceivableSettingType[]
  reservations?: ReservationType[]
  reservation?: ReservationType
  advanceCompany?: any
  salesDiscountPaymentsGroupBySalesDate?: any
}

export const NotConfirmedDiscount: React.FC<NotConfirmedDiscountProps> = ({
  index,
  discount,
  salesDiscounts = [],
  salesDiscountPayments = [],
  changeNotConfirmedDate,
  confirmedDay,
  isEditMode,
  changeNotConfirmedState,
  deleteDiscount,
  setIsEditMode,
  currentTabReservationId,
  accountsReceivableSettings,
  reservations = [],
  reservation,
  advanceCompany,
  salesDiscountPaymentsGroupBySalesDate,
}) => {
  const [salesPaymentOptions, setSalesPaymentOptions] = useState<any[]>([{ value: '', label: '' }])

  useEffect(() => {
    const newAdvanceCompany = advanceCompany?.paymentCompanies
      ?.filter(item => salesDiscountPaymentsGroupBySalesDate?.[discount?.salesDate]?.some(v => v.company_id === item?.id && v.is_paid))
      .map(item => ({ value: item?.id, label: item?.name }))

    const salesPaymentOptionsArr = salesDiscountPayments.map(item => {
      if (
        Object.keys(advanceCompany?.paymentMethod).length &&
        item?.id === advanceCompany?.paymentMethod?.id &&
        salesDiscountPaymentsGroupBySalesDate?.[discount?.salesDate]?.some(v => v.sales_payment_id === item?.id && v.is_paid)
      ) {
        return { value: item?.id, label: item?.name, children: newAdvanceCompany }
      } else if (item?.name === '-') {
        return { value: item?.id, label: item?.name }
      } else if (salesDiscountPaymentsGroupBySalesDate?.[discount?.salesDate]?.some(v => v.sales_payment_id === item?.id && v.is_paid)) {
        return { value: item?.id, label: item?.name }
      } else {
        return null
      }
    })

    setSalesPaymentOptions(salesPaymentOptionsArr.filter(v => !!v))
  }, [discount.salesDate, salesDiscountPayments])

  const displayRender = label => label[label.length - 1]

  const salesDiscountIds: string[] = []
  salesDiscounts.map(salesDiscount => salesDiscountIds.push(salesDiscount.id!))

  const salesDiscountPaymentIds: string[] = []
  salesDiscountPayments.map(salesPayment => salesPayment && salesDiscountPaymentIds.push(salesPayment.id))

  const roomsOptions = getRoomCascadeOptionsByReservations({ reservation, reservations })

  return (
    <tr>
      <td>
        <div css={datePickerWrapperStyle} onClick={() => setIsEditMode(true)}>
          <DatePicker
            date={discount.salesDate ? moment(discount.salesDate) : null}
            setDate={async date => {
              await changeNotConfirmedDate(date, index)
              if (
                !salesDiscountPaymentsGroupBySalesDate[discount.salesDate]?.some(
                  v => v.sales_payment_id === discount.salesPaymentId && v.is_paid,
                )
              ) {
                const eventChangeSalesPaymentId = {
                  target: { value: salesPaymentOptions[0].value, name: 'salesPaymentId' },
                }

                const eventChangeCompanyId = {
                  target: { value: '', name: 'companyId' },
                }

                await changeNotConfirmedState(eventChangeSalesPaymentId, index)
                await changeNotConfirmedState(eventChangeCompanyId, index)
              } else {
                if (
                  advanceCompany?.paymentMethod?.id === discount.salesPaymentId &&
                  !salesDiscountPaymentsGroupBySalesDate[discount.salesDate]?.some(
                    v => String(v.company_id ?? '') === discount.companyId && v.is_paid,
                  )
                ) {
                  const eventChangeSalesPaymentId = {
                    target: { value: salesPaymentOptions[0].value, name: 'salesPaymentId' },
                  }

                  const eventChangeCompanyId = {
                    target: { value: '', name: 'companyId' },
                  }

                  await changeNotConfirmedState(eventChangeSalesPaymentId, index)
                  await changeNotConfirmedState(eventChangeCompanyId, index)
                }
              }
            }}
            confirmedDay={confirmedDay}
          />
        </div>
      </td>
      <td>
        <select
          name="salesDiscountId"
          css={isEditMode ? editModeLargeSelectBoxStyle : largeSelectBoxStyle}
          onClick={() => setIsEditMode(true)}
          defaultValue={discount.salesDiscountId}
          onChange={e => {
            changeNotConfirmedState(e, index)
          }}
        >
          {/* Displayed only when discount item is deleted */}
          {salesDiscountIds.indexOf(discount.salesDiscountId) === -1 && (
            <option selected={discount.salesDiscountId === ''} value="">
              {discount.salesDiscountName}
            </option>
          )}
          {salesDiscounts?.map(salesDiscount => (
            <option key={salesDiscount.id} selected={discount.salesDiscountId === salesDiscount.id} value={salesDiscount.id}>
              {salesDiscount.name}
            </option>
          ))}
        </select>
      </td>
      <td>
        <Cascader
          options={roomsOptions}
          expandTrigger="hover"
          className="cascader-customize"
          value={findRoomCascadeOptionByGuestRoomAssignId({
            guestRoomAssignId: discount.guestRoomAssignId,
            reservationId: discount.reservationId ? discount.reservationId : currentTabReservationId || '',
            roomsOptions,
          })}
          displayRender={roomCascadeOptionsLabelRender}
          popupClassName="popup-cascader-customize"
          onChange={async value => {
            setIsEditMode(true)

            changeNotConfirmedState(
              {
                target: { value: value[0], name: 'reservationId' },
              },
              index,
            )
            changeNotConfirmedState(
              {
                target: { value: value[1], name: 'guestRoomAssignId' },
              },
              index,
            )
          }}
          allowClear={false}
          suffixIcon={<span></span>}
          changeOnSelect
        />
      </td>

      <td>
        <Cascader
          options={salesPaymentOptions}
          expandTrigger="hover"
          className="cascader-customize"
          value={discount.companyId ? [discount.salesPaymentId, discount.companyId] : [discount.salesPaymentId]}
          displayRender={displayRender}
          popupClassName="popup-cascader-customize"
          onChange={async value => {
            setIsEditMode(true)
            const eventChangeSalesPaymentId = {
              target: { value: value[0], name: 'salesPaymentId' },
            }

            const eventChangeCompanyId = {
              target: { value: value[1] ? value[1] : '', name: 'companyId' },
            }
            await changeNotConfirmedState(eventChangeSalesPaymentId, index)
            changeNotConfirmedState(eventChangeCompanyId, index)
          }}
          allowClear={false}
          suffixIcon={<span></span>}
        />
      </td>

      <td>
        <input
          name="salesSubjectPrice"
          css={unitPriceStyle}
          onClick={() => setIsEditMode(true)}
          type="number"
          value={discount.salesSubjectPrice}
          onChange={e => changeNotConfirmedState(e, index)}
          placeholder="0000"
          className="unitPriceStyle"
        />
      </td>
      <td>
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={true}
          suffixIcon={isEditMode ? <img src={require('@/static/images/arrow_down_yellow.svg')} /> : <></>}
          onChange={value => {
            setIsEditMode(true)
            changeNotConfirmedState(
              {
                target: { value, name: 'salesAccountsReceivableMasterId' },
              },
              index,
            )
          }}
          value={discount.salesAccountsReceivableMasterId || ''}
        >
          <Select.Option value="">-</Select.Option>
          {accountsReceivableSettings.map((accountsReceivable, index) => (
            <Select.Option key={index} value={accountsReceivable.id}>
              {accountsReceivable.name}
            </Select.Option>
          ))}
          {discount.salesAccountsReceivableMasterId !== '' &&
            !accountsReceivableSettings.some(
              accountsReceivable => accountsReceivable.id === discount.salesAccountsReceivableMasterId,
            ) && (
              <option value={discount.salesAccountsReceivableMasterId || ''} selected>
                {discount.salesAccountsReceivableName}
              </option>
            )}
        </Select>
      </td>
      <td>
        {isEditMode && (
          <div css={deleteStyle} className="delete-none-0">
            <img onClick={() => deleteDiscount(index)} src={require('@/static/images/delete_yellow.svg')} />
          </div>
        )}
      </td>
    </tr>
  )
}

const datePickerWrapperStyle = css({
  width: 80,
})

const selectBoxStyle = css({
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  appearance: 'none',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  display: 'block',
  paddingLeft: 14,
  paddingRight: 14,
  marginRight: 10,
})

const middleInputStyle = css(selectBoxStyle, {
  marginRight: '2.5%',
  width: '8.5%',
  backgroundImage: 'none',
  '::placeholder': {
    color: '#ccc',
  },
})

export const unitPriceStyle = css(middleInputStyle, {
  marginLeft: 12,
})

export const largeSelectBoxStyle = css(selectBoxStyle, {
  marginRight: '1.5%',
  width: '90%',
  backgroundPosition: '95% center',
})

export const xlargeSelectBoxStyle = css(selectBoxStyle, {
  width: 'calc(20%)',
  margin: '0 2% 0 1.5%',
  backgroundPosition: '95% center',
})

const editModoSelectBoxStyle = css(selectBoxStyle, {
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
})

const editModeLargeSelectBoxStyle = css(editModoSelectBoxStyle, largeSelectBoxStyle)
