import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'
import { FrontSupport } from '@/components/organisms/self-checkin/complete/front-support'
import { DeviceType, SendType } from '@/models/device/device-setting'
import { useEposPrinter } from '@/hooks/use-epos-printer'
import { FrontSupportWithPrinter } from '@/components/organisms/self-checkin/complete/front-support-with-printer'
import { checkinEposPrinterIdStorage } from '@/components/organisms/self-checkin/complete/checkin-receipt'
import { SelfCheckinBasicInfoPluginType } from '@/models/self-checkin/results'
import { AssignRoomWithPrinter } from '@/components/organisms/self-checkin/complete/assign-room-with-printer'
import { AssignRoom } from '@/components/organisms/self-checkin/complete/assign-room'
import { RoomInformationType } from '@/hooks/use-assign-key'
import { selfCheckinFetchGuestRoomAssign } from '@/apis/aipass'

export type SelfCheckinCompleteState = {
  hotelId: string
  checkinId: string
  isFrontDescGuidance?: boolean
  roomInformation?: RoomInformationType[]
  basicInfo: SelfCheckinBasicInfoPluginType['basicInfo']
  payment?: {
    paymentMethod: 'CREDIT_CARD' | 'DIRECT'
    totalAmount: number
    paymentAmount: number
    totalAmountAt10Per: number
    totalAmountAt8Per: number
    totalTaxAt10Per: number
    totalTaxAt8Per: number
    receiptName: string | undefined
  }
}

export const SelfCheckinComplete: React.FC<{}> = () => {
  const history = useHistory()
  const { state } = useLocation<SelfCheckinCompleteState>()
  const [eventListeners, setEventListeners] = useState<Array<{ type: keyof WindowEventMap; listener: EventListenerOrEventListenerObject }>>(
    [],
  )
  const [isPageInit, setIsPageInit] = useState<boolean>(false)
  const [existsUndefinedKeyRoom, setExistsUndefinedKeyRoom] = useState<boolean>(false)
  const [existsPrinter, setExistsPrinter] = useState<boolean>(false)
  const eposPrinter = useEposPrinter(checkinEposPrinterIdStorage)

  console.log({ state })
  const goToTopPage = () => {
    history.push({
      pathname: `/self-checkin`,
      search: `?hotelId=${state.hotelId}`,
    })
  }

  const onClickTop = () => {
    eventListeners.map(eventListener => window.removeEventListener(eventListener.type, eventListener.listener))
    goToTopPage()
  }

  const initPageStatus = async () => {
    setExistsPrinter(await eposPrinter.existsPrinterByType(DeviceType.printer, SendType.selfCheckin))
    const roomInfoRes = await selfCheckinFetchGuestRoomAssign({
      hotelId: state.hotelId,
      checkinId: state.checkinId,
    })
    const flatRoomInfo: any = Object.values(roomInfoRes.rooms).flat()
    setExistsUndefinedKeyRoom(flatRoomInfo.some(room => !room.keyNumber || room.keyNumber === '-'))

    setIsPageInit(true)
  }

  const hasUnpaid: boolean = useMemo(() => {
    return state.payment?.paymentMethod === 'DIRECT' && state.payment.totalAmount > 0
  }, [state])

  useEffect(() => {
    window.addEventListener('popstate', goToTopPage, { once: true })
    setEventListeners([...eventListeners, { type: 'popstate', listener: goToTopPage }])
  }, [])

  useEffect(() => {
    const timeId = setTimeout(() => goToTopPage(), 60000)
    return () => clearTimeout(timeId)
  }, [])

  useEffect(() => {
    initPageStatus()
  }, [])

  if (!isPageInit) {
    return <></>
  }

  if (existsPrinter) {
    return (
      <div css={containerStyle}>
        {!hasUnpaid && state.roomInformation?.length && !existsUndefinedKeyRoom ? (
          <AssignRoomWithPrinter onClickTop={onClickTop} hotelId={state.hotelId} checkinId={state.checkinId} basicInfo={state.basicInfo} />
        ) : (
          <FrontSupportWithPrinter onClickTop={onClickTop} />
        )}
      </div>
    )
  }
  return (
    <div css={containerStyle}>
      {!state.isFrontDescGuidance || state.roomInformation?.length ? (
        <AssignRoom onClickTop={onClickTop} hotelId={state.hotelId} checkinId={state.checkinId} basicInfo={state.basicInfo} />
      ) : (
        <FrontSupport onClickTop={onClickTop} />
      )}
    </div>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  '*': {
    fontFamily: 'Noto Sans JP',
  },
})
