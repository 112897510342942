import React from 'react'
import { SerializedStyles, css } from '@emotion/core'
import { HEADER_HEIGHT } from '@/constants/layout'

type Props = {
  tabComponent: JSX.Element
  customStyle?: SerializedStyles
  isShowFooter?: boolean
}

export const TabContainer: React.FC<Props> = ({ tabComponent, customStyle, children, isShowFooter }) => {
  // subtract TAB HEIGHT - PADDING SPACE
  const containerHeight = `calc(100vh - ${HEADER_HEIGHT}px - 45px - 24px * 2 ${isShowFooter ? '- 59px' : ''})`

  return (
    <>
      {tabComponent}
      <div css={[containerStyle, customStyle, css({ height: containerHeight })]}>{children}</div>
    </>
  )
}

const containerStyle = css({
  width: '100%',
  background: 'white',
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: '0px 5px 5px',
  position: 'relative',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
})
