import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { fetchDeviceSetting } from '@/apis/aipass'
import { Checkbox } from '@/components/atoms/checkbox'
import { deviceSettingsType, SendType } from '@/models/device/device-setting'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { PrinterTab } from '@/components/molecules/settings/printer-tab'

export const SettingPrinterRequest: React.FC = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [printerSettings, setPrinterSettings] = useState<deviceSettingsType[]>([])
  const [printerSettingIds, setPrinterSettingIds] = useState<string[]>([])

  const fetchPrinterSetting = async () => {
    setIsLoading(true)
    const deviceSettings = (await fetchDeviceSetting()) || []
    setPrinterSettings(deviceSettings.filter(device => device.sendType === SendType.order))
    setIsLoading(false)
  }

  const onChangCheckBox = (changeId: string) => {
    const isChecked = printerSettingIds.includes(changeId)
    const checkedPrinterIds = isChecked ? printerSettingIds.filter(id => changeId !== id) : [...printerSettingIds, changeId]
    setPrinterSettingIds(checkedPrinterIds)
    localStorage.setItem('printerSettingIds', JSON.stringify(checkedPrinterIds))
  }

  useEffect(() => {
    const printerIds = localStorage.printerSettingIds ? JSON.parse(localStorage.printerSettingIds) : []
    setPrinterSettingIds(printerIds)
    fetchPrinterSetting()
  }, [])

  return (
    <SettingsLayout loading={isLoading}>
      <TabContainer tabComponent={<PrinterTab currentTab={'Request'} />}>
        <div css={headerContainerStyle}>
          <span>{t('Printer connection should only be selected on one aipass screen, do not select on more than one screen.')}</span>
        </div>
        <div css={infoHeaderContainerStyle}>
          <div>{t('Printer name')}</div>
        </div>
        <div css={mainContainerStyle}>
          {printerSettings.map(device => {
            return (
              <div key={device.id} css={listContainerStyle}>
                <div css={optionWrapperStyle}>
                  <Checkbox value={printerSettingIds.includes(device.id)} onChange={() => onChangCheckBox(device.id)} />
                </div>
                <div css={listNameStyle}>{device.deviceName}</div>
              </div>
            )
          })}
        </div>
      </TabContainer>
    </SettingsLayout>
  )
}

const optionWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-start',
  img: {
    width: 20,
    height: 20,
  },
})

const headerContainerStyle = css({
  height: 70,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  alignItems: 'center',
  span: {
    fontSize: 14,
    color: '#272727',
    letterSpacing: 0.56,
  },
})

const mainContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  color: '#272727',
  width: '100%',
  flexWrap: 'wrap',
})

const listContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 64,
  borderBottom: '1px solid #F2F2F2',
  padding: '0 32px',
})

const listNameStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  width: '100%',
  padding: '25px 0px 25px 25px',
  height: '100%',
  letterSpacing: '1.4px',
})

const infoHeaderContainerStyle = css({
  background: '#F5F5F5',
  height: 32,
  padding: '0 78px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 12,
  color: '#676767',
})
