import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

type Props = {}
export const ErrorModal: React.FC<Props> = () => {
  const { t } = useTranslation()
  return (
    <div css={modalWrapperStyle}>
      {t('Cant detect the QR code')}
      <br />
      {t('Please try again')}
    </div>
  )
}

const modalWrapperStyle = css({
  backgroundColor: '#FF2700',
  padding: '16px 0px',
  letterSpacing: 2.8,
  lineHeight: '1.5',
  fontSize: 14,
  color: '#fff',
  position: 'sticky',
  textAlign: 'center',
  zIndex: 3,
})
