export const TransactionSideType = {
  PMS: 'pms',
  Smaregi: 'smaregi',
} as const
export type TransactionSideType = typeof TransactionSideType[keyof typeof TransactionSideType]

export type TransactionType = {
  id: string
  contractId: string
  transactionHeadId: string
  transactionAt: string
  transactionSide: TransactionSideType
  price: number
  discountPrice: number
  tax: number
  paid: number
  unpaid: number
  roomNumber: string
  sales?: Array<TransactionSaleType>
  totalByPayment?: Array<TransactionTotalByPayment>
}

export type TransactionSaleType = {
  salesId: string
  salesAt: string
  saleSubjectName: string
  saleSubSubjectName: string
  unitPrice: number
  salesPrice: number
  quantity: number
  paid: number
  isPaid: boolean
  isDiscount: boolean
  paymentMethodMasterId: string | undefined
  paymentMethodMasterName: string | undefined
  accountReceivableMasterId: string | undefined
  accountReceivableMasterName: string | undefined
  advanceCompanyId: string | undefined
  advanceCompanyName: string | undefined
}

export type TransactionTotalByPayment = {
  paid: number
  name: string
}
