import React, { useState } from 'react'
import { DayPickerRangeController, CalendarDay } from 'react-dates'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import moment from 'moment'
import 'react-dates/initialize'

type DateRangeFieldProps = {
  beginDate: string
  endDate: string
  changeRangeDate: (beginDate, endDate) => void
}

export const DateRangeFixedCalender: React.FC<DateRangeFieldProps> = ({ beginDate, endDate, changeRangeDate }) => {
  const { t } = useTranslation()
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>('startDate')

  const _onClose = ({ endDate }) => {
    // After selecting only the starting point, reset if you leave
    if (endDate === null) {
      changeRangeDate('', '')
    }
  }

  const _onChange = event => {
    // In the start point selection, always reset the end point when changing the date
    if (focusedInput === 'startDate') {
      changeRangeDate(event.startDate?.format('YYYY-MM-DD'), '')
      return
    }
    changeRangeDate(event.startDate?.format('YYYY-MM-DD'), event.endDate?.format('YYYY-MM-DD'))
  }

  const _onFocusChange = focusedInput => {
    setFocusedInput(focusedInput !== null ? focusedInput : 'startDate')
  }

  return (
    <div css={dateRangeControllerStyle}>
      <DayPickerRangeController
        initialVisibleMonth={() => (beginDate ? moment(beginDate) : moment())}
        startDate={beginDate ? moment(beginDate) : null}
        endDate={endDate ? moment(endDate) : null}
        focusedInput={focusedInput}
        onDatesChange={_onChange}
        onFocusChange={_onFocusChange}
        onClose={_onClose}
        onOutsideClick={() => {}}
        numberOfMonths={1}
        minimumNights={0}
        monthFormat={t('MMM-YY')}
        hideKeyboardShortcutsPanel={true}
        daySize={42}
        isOutsideRange={() => false}
        enableOutsideDays
        navPrev={
          <div className="monthPrev">
            <img
              alt="arrow"
              src={require('@/static/images/icon_caalender_left.svg')}
              onMouseOver={e => (e.currentTarget.src = require('@/static/images/icon_caalender_left.svg'))}
              onMouseOut={e => (e.currentTarget.src = require('@/static/images/icon_caalender_left.svg'))}
            />
          </div>
        }
        navNext={
          <div className="monthNext">
            <img
              alt="arrow"
              src={require('@/static/images/icon_caalender_right.svg')}
              onMouseOver={e => (e.currentTarget.src = require('@/static/images/icon_caalender_right.svg'))}
              onMouseOut={e => (e.currentTarget.src = require('@/static/images/icon_caalender_right.svg'))}
            />
          </div>
        }
        renderCalendarDay={props => (
          <CalendarDay
            {...props}
            renderDayContents={day => (
              <div className="day">
                <span>{day?.format('D')}</span>
              </div>
            )}
          />
        )}
      />
    </div>
  )
}

const dateRangeControllerStyle = css({
  '.DayPicker': {
    boxShadow: 'initial',
  },
  '.CalendarDay__outside': {
    color: '#cccccc',
  },
})
