import { deleteMobileRequestCategories, fetchMobileRequestCategories } from '@/apis/aipass'
import { TBody, THead, Table } from '@/components/molecules/settings/mobile-request/table'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { CategoryType } from '@/models/mobile-request'
import { css } from '@emotion/core'
import { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateCategoryModal } from './create-category-modal'
import { LoadingFull } from '@/components/molecules/loading-full'

type Props = {
  onChange: () => void
  listenCancel: Dayjs | undefined
  listenSave: Dayjs | undefined
  listenOpenModal: Dayjs | undefined
}

export const CategoryTable: React.FC<Props> = ({ onChange, listenCancel, listenSave, listenOpenModal }) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { errorHandler } = useErrorHandler()
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [removeCategoryIds, setRemoveCategoryIds] = useState<number[]>([])
  const [isShowModal, setIsShowModal] = useState(false)
  const [editingDetail, setEditingDetail] = useState<CategoryType>()
  const [nextOrder, setNextOrder] = useState<number>(1)

  const fetchCategories = async () => {
    const categories = (await fetchMobileRequestCategories()) || []
    setCategories(categories)
    setNextOrder(categories.length ? categories[categories.length - 1].order + 1 : 1)
  }

  const showDetail = (category: CategoryType) => {
    setEditingDetail(category)
    setIsShowModal(true)
  }

  const removeList = (id: number) => {
    setRemoveCategoryIds([...removeCategoryIds, id])
    setCategories(categories.filter(c => c.id !== id))
    onChange()
  }

  const onDelete = async () => {
    try {
      setIsLoading(true)
      await deleteMobileRequestCategories(removeCategoryIds)
      setRemoveCategoryIds([])
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onModalSave = () => {
    fetchCategories()
  }

  const onModalClose = () => {
    setEditingDetail(undefined)
    setIsShowModal(false)
  }

  useEffect(() => {
    if (listenCancel) fetchCategories()
  }, [listenCancel])

  useEffect(() => {
    if (listenOpenModal) setIsShowModal(true)
  }, [listenOpenModal])

  useEffect(() => {
    if (listenSave) onDelete()
  }, [listenSave])

  useEffect(() => {
    fetchCategories()
  }, [])

  return (
    <>
      <Table>
        <THead>
          <tr>
            <th style={{ width: 83 }}>{t('Display order')}</th>
            <th style={{ width: 72 }}></th>
            <th>{t('Title')}</th>
            <th style={{ width: 72 }}></th>
          </tr>
        </THead>
        <TBody>
          {categories.map(item => (
            <tr key={item.id} onClick={() => showDetail(item)}>
              <td>{item.order}</td>
              <td>
                <div css={thumbnailImageStyle} style={{ backgroundImage: `url(${item.imagePath})` }}></div>
              </td>
              <td>{item.name[i18n.language]}</td>
              <td>
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={e => {
                    e.stopPropagation()
                    removeList(item.id)
                  }}
                  src={require('@/static/images/mobile_request/delete_orange.svg')}
                  alt=""
                />
              </td>
            </tr>
          ))}
        </TBody>
      </Table>
      {isShowModal && (
        <CreateCategoryModal
          editing={editingDetail}
          nextOrder={nextOrder}
          onSave={onModalSave}
          onClose={onModalClose}
          setLoading={setIsLoading}
        />
      )}
      <LoadingFull isLoading={isLoading} />
    </>
  )
}

const thumbnailImageStyle = css({
  backgroundSize: 38,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  borderRadius: 5,
  width: 38,
  height: 38,
})
