import { RoomType } from '@/models/room-manager/response/guest-room-indicator'
import React from 'react'
import { TFunction } from 'react-i18next'
import { css } from '@emotion/core'

export type PopupProps = {
  position: {
    top: number
    left: number
  }
  room: RoomType
  t: TFunction<'translation', undefined>
}

export interface PopupType {
  height: number
  width: number
  getHeight?: () => number
  Component: React.FC<PopupProps>
}

export const popupStyle = css({
  minHeight: 115,
  width: 400,
  background: '#FFFFFF',
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: 5,
  position: 'absolute',
  zIndex: 10,
})

export const headerStyle = css({
  height: 29,
  display: 'flex',
  // todo override
  // background: '#F2F2F2',
  padding: '8px 16px',
  justifyContent: 'space-between',
  p: {
    // todo override
    // color: '#676767',
    letterSpacing: 0.6,
    fontWeight: 'bold',
    fontSize: 12,
  },
})

export const bodyStyle = css({
  display: 'flex',
  gap: 17,
  padding: 16,
  minHeight: 58,
  '.info': {
    flex: '0 0 183px',
    paddingRight: 16,
    borderRight: '1px solid #F2F2F2',
  },
  '.note': {
    flex: '0 0 183px',
    '> div': {
      marginBottom: 18,
    },
    '.category': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      marginBottom: 10,
      fontSize: 12,
      fontWeight: 'bold',
    },
    '.memo': {
      fontSize: 10,
      lineHeight: 1.6,
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 5,
    },
  },
  'p.not-assigned': {
    color: '#CCCCCC',
    letterSpacing: 0.6,
    fontWeight: 'bold',
    fontSize: 12,
  },
  'p.guest-name': {
    letterSpacing: 0.6,
    fontSize: 12,
    fontWeight: 'bold',
    color: '#272727',
  },
  'p.stay-date': {
    letterSpacing: 0.5,
    fontSize: 10,
    fontWeight: 'bold',
    color: '#676767',
    paddingTop: 8,
  },
  '.room-status': {
    paddingTop: 12,
  },
  '.paid-status': {
    display: 'flex',
    paddingTop: 16,
    p: {
      letterSpacing: 0.5,
      fontSize: 10,
      fontWeight: 'bold',
      color: '#F2A40B',
      paddingLeft: 8,
    },
  },
})

export const footerStyle = css({
  height: 28,
  display: 'flex',
  borderTop: '1px solid #F2F2F2',
  alignItems: 'center',
  'p, a': {
    letterSpacing: 0.5,
    fontSize: 10,
    fontWeight: 'bold',
    color: '#676767',
    width: '50%',
    textAlign: 'center',
    lineHeight: '28px',
    cursor: 'pointer',
    ':first-of-type': {
      borderRight: '1px solid #F2F2F2',
    },
    '&.-disabled': {
      cursor: 'auto',
      color: '#CCCCCC',
    },
  },
})
