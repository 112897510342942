import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { TableBody, TableHeader, TableRow } from '@/components/organisms/settings/sales-manager/table'
import { SalesSubjectType } from '@/models/sales-manager/sales'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'

type Props = {
  salesSettings: SalesSubjectType[]
  onClickRow: (detail: SalesSubjectType) => void
  onClickDeleteRow: (id: string) => void
}

export const SalesListItem: React.FC<Props> = ({ salesSettings, onClickRow, onClickDeleteRow }) => {
  const { t } = useTranslation()

  const computedHasMultiLineRow = (detail: SalesSubjectType): boolean => {
    return detail.planCode.length > 1
  }

  return (
    <>
      <TableHeader>
        <div css={codeColStyle}>{t('Course ID')}</div>
        <div css={nameColStyle}>{t('Subject name')}</div>
        <div css={categoryColStyle}>{t('Department name')}</div>
        <div css={categoryColStyle}>{t('Sale category')}</div>
        <div css={priceColStyle}>{t('Amount of money')}</div>
        <div css={priceColStyle}>{t('Tax rate')}</div>
        <div css={categoryColStyle}>{t('Amount categories')}</div>
        <div css={categoryColStyle}>{t('Plan code')}</div>
        <div css={deleteColStyle}></div>
      </TableHeader>
      <TableBody>
        {salesSettings.map(detail => (
          <TableRow
            css={css({
              '> div': { fontWeight: 500 },
              minHeight: 49,
              ...(computedHasMultiLineRow(detail)
                ? {
                    alignItems: 'start',
                    padding: '18px 0',
                  }
                : {}),
            })}
            key={detail.id}
            onClick={() => onClickRow(detail)}
          >
            <div css={[codeColStyle, { marginLeft: 32 }]}>{detail.subjectCode}</div>
            <div css={nameColStyle}>{detail.subjectName}</div>
            <div css={categoryColStyle}>{detail.departmentName}</div>
            <div css={categoryColStyle}>{detail.category ? t(`sales_category_${detail.category}`) : '-'}</div>
            <div css={priceColStyle}>¥{detail.price?.price?.toLocaleString() || '-'}</div>
            <div css={priceColStyle}>{detail.tax}</div>
            <div css={categoryColStyle}>{detail.price.hasSeparated || '-'}</div>
            <div css={css(categoryColStyle, { gap: 7, display: 'flex', flexFlow: 'column' })}>
              {detail.planCode.length === 0 ? '-' : detail.planCode.map(code => <p key={code}>{code}</p>)}
            </div>
            <div css={deleteColStyle} style={{ marginTop: computedHasMultiLineRow(detail) ? '-10px' : 'inherit' }}>
              <DeleteIcon onClick={() => onClickDeleteRow(detail.id)} />
            </div>
          </TableRow>
        ))}
      </TableBody>
    </>
  )
}

const codeColStyle = css({ marginLeft: 27, width: 99 })
const nameColStyle = css({
  flexGrow: 1,
  overflowWrap: 'anywhere',
  width: 'min-content',
})
const categoryColStyle = css({ width: 124 })
const priceColStyle = css({ width: 73, paddingRight: 32 })
const deleteColStyle = css({ width: 30, margin: '0 24px' })
