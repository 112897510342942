import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import 'dayjs/locale/ja'
import 'react-dates/initialize'
import { modalContainerStyle } from '@/components/pages/accommodation-management/list-layout'
import { Button } from '@/components/atoms/button'

type ContainerProps = {
  connected?: boolean
  onDisconnect: () => void
  onSync: () => void
  onCancel: () => void
}

export const RemoteLockModal: React.FC<ContainerProps> = ({ connected, onDisconnect, onSync, onCancel }) => {
  const { t } = useTranslation()

  return (
    <>
      <div css={modalContainerStyle}></div>
      <div css={modalBodyContainerStyle}>
        <h3 css={modalHeaderStyle}>{t('Key setting')}</h3>
        <div css={modalMainContainerStyle}>
          <div css={modalMainTopContainerStyle}>{t('Work with remote lock')}</div>
          <div css={modalMainConnectRemoteLockContainerStyle}>
            <div css={modalMainTopConnectRemoteLockContainerStyle}>
              <div>
                <img src={require('@/static/images/remotelock_logo.png')} css={remoteLockLogoStyle} />
              </div>
              {connected && (
                <div>
                  <Button width={104} height={32} buttonType={4} onClick={onDisconnect}>
                    {t('Disconnect')}
                  </Button>
                </div>
              )}
            </div>
            {connected && (
              <>
                <div css={modalMainExplainContainerStyle}>{t('To update your device information please press the button below')}</div>
                <Button width={74} height={32} buttonType={3} marginRight={16} marginBottom={24} onClick={onSync}>
                  {t('Update')}
                </Button>
              </>
            )}
          </div>
          <div css={modalMainBottomConnectRemoteLockContainerStyle}>
            <p css={isCheckedConnectTextStyle}>{t(connected ? 'Connected' : 'Disconnected')}</p>
          </div>
        </div>
        <div css={modalButtonSectionStyle}>
          <Button width={110} height={38} buttonType={3} onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </div>
      </div>
    </>
  )
}

// modal
const modalBodyContainerStyle = css({
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: 680,
  zIndex: 102,
  opacity: 1,
  background: '#FFF',
  borderRadius: 5,
  transform: 'translate(-50%, -50%)',
})

const modalHeaderStyle = css({
  margin: '22px 32px',
  fontWeight: 'bold',
  fontSize: 18,
  color: '#272727',
})

const modalMainContainerStyle = css({
  padding: '31px 32px',
  backgroundColor: '#F2F2F2',
})

const modalMainTopContainerStyle = css({
  display: 'flex',
  marginBottom: 12,
})

const modalMainConnectRemoteLockContainerStyle = css({
  width: '100%',
  backgroundColor: '#FFF',
  borderRadius: '5px 5px 0 0',
  borderLeft: '1px solid #CCCCCC',
  borderTop: '1px solid #CCCCCC',
  borderRight: '1px solid #CCCCCC',
  borderBottom: '1px solid #F2F2F2',
  padding: '24px 24px 0px',
})

const modalMainTopConnectRemoteLockContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 16.8,
})

const modalMainExplainContainerStyle = css({
  color: '#676767',
  fontWeight: 'normal',
  fontSize: 12,
  letterSpacing: 1.2,
  marginBottom: 16,
})

const modalMainBottomConnectRemoteLockContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#FFF',
  borderRadius: '0 0 5px 5px',
  borderLeft: '1px solid #CCCCCC',
  borderTop: '1px solid #F2F2F2',
  borderRight: '1px solid #CCCCCC',
  borderBottom: '1px solid #CCCCCC',
  padding: '12px 24px',
  color: '#FF5500',
})

const remoteLockLogoStyle = css({
  width: 200,
})

const modalButtonSectionStyle = css({
  display: 'flex',
  padding: '11px 32px 11px 32px',
  justifyContent: 'flex-end',
  boxShadow: '0px 0px 6px #0000001A',
})

const isCheckedConnectTextStyle = css({
  color: '#676767',
  fontSize: 12,
  fontWeight: 'bold',
})
