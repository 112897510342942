import React, { useState } from 'react'
import { SingleDatePicker, CalendarDay } from 'react-dates'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

type DatePickerProps = {
  date?: string
  onDateChange: React.Dispatch<React.SetStateAction<string>>
  placeHolder?: string
  disabled?: boolean
}

export const DatePicker: React.FC<DatePickerProps> = ({ date, onDateChange, placeHolder, disabled }) => {
  const { t } = useTranslation()
  const [calendarFocused, setCalendarFocused] = useState<boolean>(false)

  return (
    <>
      <SingleDatePicker
        date={date ? moment(date) : null}
        placeholder={placeHolder ? placeHolder : t('Date')}
        onDateChange={date => date && onDateChange(date.format('YYYY-MM-DD'))}
        focused={calendarFocused}
        onFocusChange={v => setCalendarFocused(Boolean(v.focused))}
        numberOfMonths={1}
        isOutsideRange={() => false}
        displayFormat={t('MM-DD-YYYY')}
        monthFormat={t('MMM-YY')}
        disabled={disabled}
        id="date"
        hideKeyboardShortcutsPanel={true}
        customInputIcon={<img alt="calendar" src={require('@/static/images/calendar_icon.svg')} width={14} />}
        navPrev={
          <div className="monthPrev">
            <img
              alt="arrow"
              src={require('@/static/images/icon_caalender_left.svg')}
              onMouseOver={e => (e.currentTarget.src = require('@/static/images/icon_caalender_left.svg'))}
              onMouseOut={e => (e.currentTarget.src = require('@/static/images/icon_caalender_left.svg'))}
            />
          </div>
        }
        navNext={
          <div className="monthNext">
            <img
              alt="arrow"
              src={require('@/static/images/icon_caalender_right.svg')}
              onMouseOver={e => (e.currentTarget.src = require('@/static/images/icon_caalender_right.svg'))}
              onMouseOut={e => (e.currentTarget.src = require('@/static/images/icon_caalender_right.svg'))}
            />
          </div>
        }
        renderCalendarDay={props => (
          <CalendarDay
            {...props}
            renderDayContents={day => (
              <div className="day">
                <span>{day?.format('D')}</span>
              </div>
            )}
          />
        )}
      />
    </>
  )
}
