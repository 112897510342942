import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useHistory, useLocation } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

type Props = {
  checkin: any
  _onTopClick: () => void
  _onScanClick: () => void
}

export const QrComplete: React.FC = () => {
  const history = useHistory()
  const [checkin, setCheckin] = useState<{}>()
  const { state } = useLocation<{
    checkinData: any
  }>()

  const _onTopClick = () => {
    history.push({ pathname: `/mobile-menu` })
  }

  const _onScanClick = () => {
    history.push({ pathname: `/qr-checkin/camera`, search: window.location.search })
  }

  useEffect(() => {
    if (!state) {
      history.push({ pathname: `/qr-checkin/scan`, search: window.location.search })
      return
    }
    if (state.checkinData.checkin) {
      setCheckin(state.checkinData.checkin)
    }
  })

  return <UI checkin={checkin} _onTopClick={_onTopClick} _onScanClick={_onScanClick} />
}

export const UI: React.FC<Props> = ({ checkin, _onTopClick, _onScanClick }) => {
  const { t } = useTranslation()
  const windows = useWindowSize()

  return (
    <div
      style={{
        height: windows.height,
      }}
      css={qrCompleteContainerStyle}
    >
      <div css={qrCompleteWrapperStyle} />
      <div css={qrCompleteSectionStyle}>
        <img css={checkinImageStyle} alt="check" src={require('@/static/images/cleaning_manager/mobile/icon_checkin.svg')} />
        <p css={completeTitleStyle}>{t('Check-in completed')}</p>
        <div css={checkinDataStyle}>
          {checkin && (
            <>
              <p css={checkinDataLabelStyle}>{t('Full name')}</p>
              <p css={checkinDataValueStyle}>{checkin.name}</p>
              <p css={checkinDataLabelStyle}>{t('Furigana')}</p>
              <p css={checkinDataValueStyle}>{checkin.nameKana}</p>
              <p css={checkinDataLabelStyle}>{t('Address')}</p>
              <p css={checkinDataValueStyle}>{checkin.address}</p>
              <p css={checkinDataLabelStyle}>{t('Phone number')}</p>
              <p css={checkinDataValueStyle}>{checkin.telephone}</p>
              <p css={checkinDataLabelStyle}>{t('Number of people')}</p>
              <p css={checkinDataValueStyle}>
                {checkin.totalMember} {t('Person')}
              </p>
            </>
          )}
        </div>
        <div css={qrContainerButtonsStyle}>
          <button type="button" css={goTopButtonStyle} onClick={_onTopClick}>
            {t('To TOP')}
          </button>
          <button type="button" css={scanButtonStyle} onClick={_onScanClick}>
            <img alt="scan" src={require('@/static/images/scan.svg')} />
          </button>
        </div>
      </div>
    </div>
  )
}

const qrCompleteContainerStyle = css({
  background: 'white',
  minWidth: '100%',
  textAlign: 'center',
})

const qrCompleteWrapperStyle = css({
  background: '#185AA5',
  height: 630,
  left: 0,
  position: 'absolute',
  top: -350,
  transform: 'skew(0deg, 12deg)',
  width: '100%',
  zIndex: 1,
})

const qrCompleteSectionStyle = css({
  position: 'relative',
  zIndex: 2,
})

const completeTitleStyle = css({
  color: '#fff',
  fontSize: 18,
  fontWeight: 'bold',
  margin: '24px auto 0 auto',
  letterSpacing: '2.7px',
})

const checkinDataStyle = css({
  background: '#fff',
  borderRadius: 5,
  boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
  margin: '32px auto 0 auto',
  width: 343,
  textAlign: 'left',
  padding: '8px 24px 32px 24px',
})

const checkinDataLabelStyle = css({
  color: '#272727',
  fontSize: 12,
  margin: '24px 0 12px',
  textAlign: 'left',
  letterSpacing: '2.4px',
})

const checkinDataValueStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  textAlign: 'left',
  letterSpacing: '2.8px',
  lineHeight: '19px',
})

const qrContainerButtonsStyle = css({
  margin: '32px auto 0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  button: {
    borderRadius: '50%',
    margin: '0 29px',
    height: 90,
    width: 90,
  },
})

const goTopButtonStyle = css({
  background: 'transparent',
  border: '1px solid #F2A40B',
  color: '#F2A40B',
  fontSize: 14,
  letterSpacing: '2.8px',
})

const scanButtonStyle = css({
  border: 'none',
  background: '#F2A40B',
  color: '#fff',
  img: {
    height: 42.94,
    width: 42.76,
  },
})

const checkinImageStyle = css({
  height: 62,
  margin: '43px auto 0 auto',
  width: 62,
})
