import dayjs from 'dayjs'

export interface SpaceDetail {
  id?: string
  spaceReservationId?: string
  spaceTypeName: string
  date?: dayjs.Dayjs
  spaceIndex: number
  usageTime: {
    usageFrom: string
    usageTo: string
  }
  pax: number
  answer: string
  reservationUserName: string
  reservation: {
    id?: string
    reservationId: string
    approvedStatus: number
    checkinId?: string
    guestName: string
  }
  salesHour: {
    start: string
    end: string
  }
  spaceId: string
  groupId?: string
  style: { left: number; width: number; backgroundColor: string; border?: string; color: string }
}

export interface NewSpace {
  [typeName: string]: SpaceDetail[]
}

export const NOW = dayjs()
export const UNIT_NUMBER = 15
export const TIME_LINE_WIDTH = 147

export const convertEndTime = (endTime) => {
  return endTime === '00:00' ? '24:00' : endTime
}

export const convertDayJsFromString = (time: string) => {
  return dayjs(NOW.format(`YYYY/MM/DD ${time}`))
}

export const getTime = time => {
  const [hour, minute] = time.split(':')
  return { hour, minute }
}

export const getTimeRangeCount = (props: { start: string; end: string }) => {
  const start = dayjs(NOW.format(`YYYY/MM/DD ${props.start}`))
  const end = dayjs(NOW.format(`YYYY/MM/DD ${convertEndTime(props.end)}`))
  return end.diff(start, 'minute') / UNIT_NUMBER
}

export const rangeTime15minuteUnit = (props: { start: string; end: string }) => {
  const count = getTimeRangeCount(props)
  const timeRanges: string[] = []
  const startDayJs = convertDayJsFromString(props.start)
  for (let i = 0; i <= count; i++) {
    const formatString = startDayJs.add(i * UNIT_NUMBER, 'minute').format('HH:mm')
    timeRanges.push(i === count ? convertEndTime(formatString) : formatString) // 販売終了時間で00:00の時は24:00を返す
  }
  return timeRanges
}

export const isOverlapOtherTimes = ({
  newStartTime,
  newEndTime,
  targetSpaces,
  salesHour,
}: {
  newStartTime: dayjs.Dayjs
  newEndTime: dayjs.Dayjs
  targetSpaces: SpaceDetail[]
  salesHour: { start: string; end: string }
}) => {
  const salesEnd = convertDayJsFromString(salesHour.end)
  if (newEndTime.isAfter(salesEnd)) {
    return true
  }

  return targetSpaces.some(space => {
    const startTime = convertDayJsFromString(space.usageTime.usageFrom)
    const endTime = convertDayJsFromString(space.usageTime.usageTo)

    return (
      (newStartTime.isSameOrAfter(startTime) && newStartTime.isBefore(endTime)) ||
      (newEndTime.isAfter(startTime) && newEndTime.isSameOrBefore(endTime)) ||
      (newStartTime.isSameOrBefore(startTime) && newEndTime.isSameOrAfter(endTime))
    )
  })
}
