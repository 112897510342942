import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form'
import { AssetSpaceDetailFormValue } from '@/components/organisms/settings/guest-app/space-detail-modal'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import uuid from 'react-uuid'
import { useDayjsRange } from '@/hooks/use-dayjs-range'

type Props = {
  control: Control<AssetSpaceDetailFormValue, any>
  errors: FieldErrors<AssetSpaceDetailFormValue>
}

export const InputSalesHours: React.FC<Props> = ({ control, errors }) => {
  const { t } = useTranslation()
  const { dayjsRange } = useDayjsRange()

  const watchSalesHourStart = useWatch({ control, name: 'salesHour.start' })
  const watchSalesHourEnd = useWatch({ control, name: 'salesHour.end' })

  const defaultTimeLists = () => {
    const range = dayjsRange({ interval: 15, unit: 'minute' })
    return [
      ...range.map(r => ({
        label: r.format('HH:mm'),
        value: r.format('HH:mm'),
      })),
      { label: '24:00', value: '24:00' },
    ]
  }

  const selectableSalesHourStart = useMemo(() => {
    if (!watchSalesHourEnd) {
      return defaultTimeLists()
    }
    return defaultTimeLists().filter(time => {
      return (
        Number(watchSalesHourEnd.split(':')[0]) * 60 + Number(watchSalesHourEnd.split(':')[1] || 0) >
        Number(time.value.split(':')[0]) * 60 + Number(time.value.split(':')[1])
      )
    })
  }, [watchSalesHourEnd])

  const selectableSalesHourEnd = useMemo(() => {
    if (!watchSalesHourStart) {
      return defaultTimeLists()
    }
    return defaultTimeLists().filter(time => {
      return (
        Number(watchSalesHourStart.split(':')[0]) * 60 + Number(watchSalesHourStart.split(':')[1] || 0) <
        Number(time.value.split(':')[0]) * 60 + Number(time.value.split(':')[1])
      )
    })
  }, [watchSalesHourStart])

  return (
    <>
      <div css={inputTitleTextStyle}>
        {t('Sales hours')}
        <div css={requireLabelTextStyle}>※</div>
      </div>
      <div css={inputAreaStyle}>
        <div style={{ marginRight: 8, width: 92 }}>
          <Controller
            name="salesHour.start"
            control={control}
            rules={{
              required: t('Please select'),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                placeholder="00:00"
                placeHolderColor="#bfbfbf"
                value={value}
                onChange={onChange}
                error={errors.salesHour?.start?.message || errors.salesHour?.end?.message}
                customStyle={css({ paddingBottom: 24 })}
              >
                {selectableSalesHourStart.map(d => (
                  <AntSelect.Option key={uuid()} value={d.label}>
                    {d.value}
                  </AntSelect.Option>
                ))}
              </Select>
            )}
          />
        </div>
        <div style={{ marginRight: 8, lineHeight: '32px' }}>~</div>
        <div style={{ marginRight: 8, width: 92 }}>
          <Controller
            name="salesHour.end"
            control={control}
            rules={{ required: t('Please select') }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Select
                placeholder="00:00"
                placeHolderColor="#bfbfbf"
                value={value}
                onChange={onChange}
                error={errors.salesHour?.start?.message || errors.salesHour?.end?.message ? ' ' : ''}
                customStyle={css({ paddingBottom: 24 })}
              >
                {selectableSalesHourEnd.map(d => (
                  <AntSelect.Option key={uuid()} value={d.label}>
                    {d.value}
                  </AntSelect.Option>
                ))}
              </Select>
            )}
          />
        </div>
      </div>
    </>
  )
}

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputAreaStyle = css({
  display: 'flex',
})
