import { SettingsLayout } from '@/components/layouts/settings-layout'
import { SmartKeyTab } from '@/components/molecules/settings/smart-key-tab'
import { EditFooter } from '@/components/organisms/edit-footer'
import { LinkSettingContainer } from '@/components/organisms/settings/smart-key/link-setting-container'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import React, { useContext, useEffect, useState } from 'react'
import { LinkSpaceToRemoteLock } from '@/components/organisms/settings/smart-key/link-space-to-remote-lock'
import { RemoteLockProvider } from '@/contexts/remote-lock'
import { getAssetList, getLockSettings, getSmartLockAccount, putSpaceAssignRoomKey, updateLockSettings } from '@/apis/aipass'
import { SpaceAsset, isSpaceAsset } from '@/models/guest-app/asset'
import { AccountInfo, LockSetting, LockType, RoomKindType } from '@/models/lock-setting'
import { LoaderContextCreator } from '@/contexts/loader'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useForm } from 'react-hook-form'
import { LinkSpaceToManual } from '@/components/organisms/settings/smart-key/link-space-to-manual'
import { LinkSpaceToSwitchBot } from '@/components/organisms/settings/smart-key/link-space-to-switch-bot'

export type ListType = {
  spaceId: string
  assetName: string
  spaceName: string
  spaceIndex: number
  keyNumber: string | undefined
  remoteDeviceId: string | undefined
}

export const SmartKeySpace: React.FC = () => {
  const { setIsLoading } = useContext(LoaderContextCreator())
  const { errorHandler } = useErrorHandler()

  const [isShowFooter, setIsShowFooter] = useState<boolean>(false)
  const [selectRoomKey, setSelectRoomKey] = useState<LockType>(LockType.Manual)
  const [lockSetting, setLockSetting] = useState<LockSetting>()
  const [accountInfos, setAccountInfos] = useState<AccountInfo[]>()
  const [initFormValue, setInitFormValue] = useState<{ assign: ListType[] }>()

  const { control, reset, getValues } = useForm<{ assign: ListType[] }>()

  const doSave = async () => {
    setIsLoading(true)
    try {
      await updateLockSettings({
        roomKind: RoomKindType.SpaceRoom,
        lockType: selectRoomKey,
      })
      await putSpaceAssignRoomKey(getValues())
      setInitFormValue(getValues())
      setIsShowFooter(false)
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  const initialView = () => {
    initializeState()
    setIsShowFooter(false)
  }

  const initializeState = () => {
    getAssetList().then(assets => {
      const spaceAssets = assets.filter(asset => isSpaceAsset(asset)) as SpaceAsset[]
      const settings = spaceAssets.flatMap(asset => {
        return asset.details.flatMap(space => {
          return [...Array(space.stock)].map((_, n) => {
            const setting = space.roomKeys.find(roomKey => roomKey.spaceIndex === n + 1)
            return {
              spaceId: space.id,
              assetName: asset.title,
              spaceName: space.title,
              spaceIndex: n + 1,
              keyNumber: setting?.keyNumber || '',
              remoteDeviceId: setting?.remoteDeviceId || '',
            }
          })
        })
      })
      setInitFormValue({ assign: settings })
      reset({ assign: settings })
    })
    getLockSettings().then(settings => {
      const currentSetting = settings.find(setting => setting.roomKind === RoomKindType.SpaceRoom)
      setLockSetting(currentSetting)
      currentSetting && setSelectRoomKey(currentSetting.lockType)
    })
    getSmartLockAccount().then(setAccountInfos)
  }

  const clearOldDeviceId = (changedLockType: LockType) => {
    if (!lockSetting) {
      return
    }
    if (changedLockType === lockSetting.lockType) {
      reset(initFormValue)
    } else {
      const settings = getValues().assign.map(room => ({
        ...room,
        remoteDeviceId: '',
      }))
      reset({ assign: settings })
    }
  }

  const footerContent = isShowFooter ? <EditFooter onSave={doSave} onCancel={initialView} /> : undefined

  useEffect(() => {
    initializeState()
  }, [])

  return (
    <>
      <RemoteLockProvider>
        <SettingsLayout loading={false} footerContent={footerContent}>
          <TabContainer tabComponent={<SmartKeyTab currentTab="Space" />} isShowFooter={isShowFooter}>
            <LinkSettingContainer
              selectRoomKey={selectRoomKey}
              onChangeType={(v: LockType) => {
                clearOldDeviceId(v)
                setSelectRoomKey(v)
                setIsShowFooter(true)
              }}
            />

            {selectRoomKey === LockType.Manual && (
              <LinkSpaceToManual
                control={control}
                onChange={() => {
                  setIsShowFooter(true)
                }}
              />
            )}
            {selectRoomKey === LockType.RemoteLock && (
              <LinkSpaceToRemoteLock
                control={control}
                onChange={() => {
                  setIsShowFooter(true)
                }}
              />
            )}
            {selectRoomKey === LockType.SwitchBot && (
              <LinkSpaceToSwitchBot
                accountInfo={accountInfos?.find(account => account.lockType === LockType.SwitchBot)}
                control={control}
                onChange={() => {
                  setIsShowFooter(true)
                }}
              />
            )}
          </TabContainer>
        </SettingsLayout>
      </RemoteLockProvider>
    </>
  )
}
