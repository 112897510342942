import React from 'react'
import moment from 'moment'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

//constants
import { deleteStyle } from '@/constants/layout'

// models
import { SalesType } from '@/models/sales'
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'

type ConfirmedUsageDetailProps = {
  index: number
  usageDetail: SalesType
  confirmedDay: string
  setIsEditMode: (boolean) => void
  changeNotConfirmedDate: (date, index) => void
  accountsReceivableSettings: AccountsReceivableSettingType[]
  advanceCompany?: any
}

export const ConfirmedUsageDetail: React.FC<ConfirmedUsageDetailProps> = ({
  index,
  usageDetail,
  accountsReceivableSettings,
  advanceCompany,
}) => {
  const { t } = useTranslation()

  return (
    <tr key={index} css={listItemReadWrapperStyle}>
      <td css={{ 'padding-right': '0 !important' }}>{usageDetail.salesDate && moment(usageDetail.salesDate).format(t('MM-DD'))}</td>
      <td>{usageDetail.salesSubjectName}</td>
      <td>{usageDetail.salesSubSubjectName ? usageDetail.salesSubSubjectName : '-'}</td>
      <td>
        {`${
          usageDetail.accommodationId?.slice(-5)?.toLocaleUpperCase() || usageDetail.reservationId?.slice(-5)?.toLocaleUpperCase() || '-'
        }/${usageDetail?.roomNumber || '-'}`}
      </td>
      <td>{Number(usageDetail.salesSubjectPrice).toLocaleString()}</td>
      <td>{Number(usageDetail.quantity).toLocaleString()}</td>
      <td>{Number(usageDetail.salesPrice).toLocaleString()}</td>
      <td>
        {usageDetail.companyId
          ? advanceCompany.paymentCompanies.find(item => item?.id === usageDetail.companyId)?.name
          : usageDetail.salesPaymentName}
      </td>
      {!usageDetail.isPaid ? <td css={unpaidStyle}>{t('Unpaid')}</td> : <td>{t('Paid')}</td>}
      <td className="accountsReceivable">{usageDetail.salesAccountsReceivableName || '-'}</td>
      <td></td>
    </tr>
  )
}

const unpaidStyle = css({
  color: '#F2A40B !important',
})

const listItemReadWrapperStyle = css({
  ':hover': {
    boxShadow: 'none',
    cursor: 'default',
  },
  'div.accountsReceivable': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  td: {
    backgroundColor: '#F6F6F6',
    height: 57,
  },
})
