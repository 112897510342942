import React, { useContext, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { ListType } from '@/components/pages/setting/guest-room/key-number'
import { Control, Controller, useFieldArray } from 'react-hook-form'
import { TableHeader, TableBody, TableRow } from '@/components/atoms/settings/table'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import { LoaderContextCreator } from '@/contexts/loader'
import { useErrorHandler } from '@/hooks/use-error-handler'
import {  getSmartLockDevices } from '@/apis/aipass'
import { AccountInfo, Device, LockType } from '@/models/lock-setting'

type Props = {
  accountInfo: AccountInfo | undefined
  control: Control<{ assign: ListType[] }, any>
  onChange: () => void
}

export const SettingSwitchBotGuestRoomKeyList: React.FC<Props> = ({ accountInfo, control, onChange: parentOnChange }) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { isLoading, setIsLoading } = useContext(LoaderContextCreator())
  const [deviceList, setDeviceList] = useState<Device[]>()

  const assignForm = useFieldArray({
    control,
    name: 'assign',
  })

  const fetchDevice = async () => {
    if (!accountInfo?.isConnected) {
      return
    }
    try {
      setIsLoading(true)
      await getSmartLockDevices({ lockType: LockType.SwitchBot }).then(setDeviceList)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isLoading) {
      return
    }
    fetchDevice()
  }, [accountInfo])

  useEffect(() => {})

  return (
    <>
      <TableHeader>
        <div css={roomTypeWidthStyle}>{t('Room type')}</div>
        <div css={floorNumberWidthStyle}>{t('Number of floors')}</div>
        <div css={roomNameWidthStyle}>{t('Room name')}</div>
        <div css={deviceNameWidthStyle}>{t('Device name')}</div>
      </TableHeader>
      <TableBody>
        {assignForm.fields.map((room, i) => (
          <Controller
            key={room.id}
            name={`assign.${i}.remoteDeviceId`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TableRow css={css({ '> div': { fontWeight: 500 } })}>
                <div css={roomTypeWidthStyle}>
                  {assignForm.fields[i].typeNameJa === assignForm.fields[i - 1]?.typeNameJa ? '' : assignForm.fields[i].typeNameJa}
                </div>
                <div css={floorNumberWidthStyle}>{room.floorNameJa}</div>
                <div css={roomNameWidthStyle}>{room.roomNumber}</div>
                <div css={deviceNameWidthStyle}>
                  <Select
                    value={deviceList?.length ? value : ''}
                    onChange={selectVal => {
                      onChange(selectVal)
                      parentOnChange()
                    }}
                    customStyle={css({ paddingBottom: 0, width: 263 })}
                  >
                    <AntSelect.Option value="">-</AntSelect.Option>
                    {deviceList?.map(device => (
                      <AntSelect.Option key={device.id} value={device.id}>
                        {device.name}
                      </AntSelect.Option>
                    ))}
                  </Select>
                </div>
              </TableRow>
            )}
          />
        ))}
      </TableBody>
    </>
  )
}

const roomTypeWidthStyle = css({ width: '20%', paddingLeft: 32 })
const floorNumberWidthStyle = css({ width: '10%' })
const roomNameWidthStyle = css({ width: '15%' })
const deviceNameWidthStyle = css({ width: '55%' })

