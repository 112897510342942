import React, { useEffect, useState } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { useTranslation } from 'react-i18next'
import { MailDeliveryTab } from '@/components/molecules/settings/mail-delivery/mail-delivery-tab'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { css } from '@emotion/core'
import { getMailDeliverySetting, updateMailDeliverySetting } from '@/apis/aipass'
import { EditFooter } from '@/components/organisms/edit-footer'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { errorHandler } from '@/libs/errors'

export const SettingReplyTo: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [showFooter, setShowFooter] = useState(false)
  const [defaultReply, setDefaultReply] = useState('')

  const { t } = useTranslation()

  const { control, handleSubmit, setValue, resetField } = useForm<{ replyEmail: string }>({})

  const _getMailDeliverySetting = async () => {
    const result = await getMailDeliverySetting()
    setValue('replyEmail', result.replyEmail)
    setDefaultReply(result.replyEmail)
  }

  useEffect(() => {
    _getMailDeliverySetting()
  }, [])

  const onSave: SubmitHandler<{ replyEmail: string }> = async submitData => {
    try {
      setLoading(true)
      await updateMailDeliverySetting(submitData)
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
      setShowFooter(false)
    }
  }

  const onCancel = () => {
    resetField('replyEmail', { defaultValue: defaultReply })
    setShowFooter(false)
  }

  const footerContent = showFooter ? <EditFooter onSave={handleSubmit(onSave)} onCancel={onCancel} /> : undefined

  return (
    <SettingsLayout loading={loading} footerContent={footerContent}>
      <TabContainer tabComponent={<MailDeliveryTab currentTab="Reply To" />}>
        <div css={inputBlockStyle}>
          <label>{t('Email address')}</label>
          <Controller
            control={control}
            name="replyEmail"
            render={({ field }) => (
              <input
                value={field.value}
                onChange={e => {
                  field.onChange(e)

                  if (!showFooter) {
                    setShowFooter(true)
                  }
                }}
                placeholder="aipass@aipass.jp"
              />
            )}
          />
          <p className="notice-text">
            {t('The reply from the guest will be sent to the e-mail address specified here.')}
            <br />
            {t('If you do not set a reply address, please specify in the body of the email that This email is for sending only.')}
          </p>
        </div>
      </TabContainer>
    </SettingsLayout>
  )
}

const inputBlockStyle = css({
  padding: 32,
  label: {
    display: 'block',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  input: {
    width: 427,
    height: 32,
    padding: '8px 16px',
    border: '1px solid #CCCCCC',
    borderRadius: 17,
    textAlign: 'left',
    '::placeholder': {
      color: '#CCCCCC',
    },
  },
  '.notice-text': {
    marginTop: 14,
    color: '#676767',
    fontSize: 12,
    lineHeight: '1.75',
  },
})
