import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { sectionHeaderStyle, sectionStyle } from '@/components/pages/dashboard/_index/style'

// constants
import { listContentsStyle, listItemUnreadStyle, listItemReadStyle } from '@/constants/layout'

// apis
import { putAdminReadCheckinNote, putAdminReadReservationNote } from '@/apis/aipass'

// models
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { groupBy } from 'lodash'
import { DashboardNote, ReservationNoteCategory, isCleaningMemo, isFrontMemo, isMealMemo } from '@/models/reservation/reservation-note'
import { CustomerTag } from '@/components/atoms/customer/customer-tag'

type memoProps = {
  dashboardValue: any
}

export const Memo: React.FC<memoProps> = ({ dashboardValue }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const memoList = dashboardValue?.notes

  const groupedMemoList = groupBy(memoList, 'reservationId')

  const _putAdminReadNote = (checkinId, noteId, reservationId) => {
    if (!reservationId) {
      putAdminReadCheckinNote(checkinId, noteId)
    }
    if (reservationId) {
      putAdminReadReservationNote(reservationId, noteId)
    }
  }

  const _changePage = (approvedStatus, checkinId, reservationId, id) => {
    if (approvedStatus === ApprovedStatusType.Checkin) {
      return history.push({ pathname: `/checkin/${checkinId}`, search: `?tabType=checkin` })
    }
    if (approvedStatus === ApprovedStatusType.Stay) {
      return history.push({ pathname: `/stay/${checkinId}`, search: `?tabType=stay` })
    }
    if (approvedStatus === ApprovedStatusType.Checkout) {
      return history.push({ pathname: `/checkout/${checkinId}`, search: `?tabType=checkout` })
    }
    if (approvedStatus === ApprovedStatusType.Customer) {
      return history.push({ pathname: `/customer/${checkinId}`, search: `?tabType=reservation` })
    }

    // When reservation and check-in are linked
    if (reservationId && checkinId) {
      return history.push({
        pathname: `/checkin/${checkinId}`,
        search: location.search,
      })
    }
    // If your reservation and check-in are not yet connected
    if (reservationId) {
      return history.push({
        pathname: `/reservation/${id}`,
        search: `?reservationId=${reservationId}`,
      })
    }

    // When booking and check-in are unlinked
    if (checkinId) {
      return history.push({
        pathname: `/checkin/${checkinId}`,
        search: location.search,
      })
    }
  }

  const createGroupMemo = (groupedMemo: DashboardNote[]) => {
    const fistMemo = groupedMemo[0]

    return {
      id: fistMemo.id,
      approvedStatus: fistMemo.approvedStatus,
      checkinId: fistMemo.checkinId,
      createdAt: fistMemo.createdAt,
      customerTags: fistMemo.customerTags || [],
      createdAtUnixtime: fistMemo.createdAtUnixtime,
      name: fistMemo.name,
      noteId: fistMemo.noteId,
      noteRead: fistMemo.noteRead,
      reservationId: fistMemo.reservationId,
      roomNumber: fistMemo.roomNumber,
      updatedAt: fistMemo.updatedAt,
      updatedAtUnixtime: fistMemo.updatedAtUnixtime,
      memos: [
        { category: ReservationNoteCategory.Front, memo: groupedMemo.find(({ category }) => isFrontMemo(category))?.memo || '-' },
        { category: ReservationNoteCategory.Cleaning, memo: groupedMemo.find(({ category }) => isCleaningMemo(category))?.memo || '-' },
        { category: ReservationNoteCategory.Meal, memo: groupedMemo.find(({ category }) => isMealMemo(category))?.memo || '-' },
      ],
    }
  }

  return (
    <div css={mixedSectionStyle}>
      <div css={sectionHeaderStyle}>{t('Memo')}</div>
      <div css={headerWrapperStyle}>
        <div css={titleRoomNumberStyle}>{t('Room number')}</div>
        <div css={titleNameStyle}>{t('Guest')}</div>
        <div css={titleCustomerTagStyle}>{t('CustomerTag')}</div>
        <div css={titleMemoStyle}>{t('Memo')}</div>
      </div>
      <div css={mixedListContentsStyle}>
        {groupedMemoList &&
          Object.keys(groupedMemoList).map((key: string, index) => {
            const groupedMemo = createGroupMemo(groupedMemoList[key])

            return (
              <>
                {!!groupedMemo.memos.length && (
                  <div
                    key={index}
                    css={!groupedMemo.noteRead ? memoListItemUnreadStyle : memoListItemReadStyle}
                    onClick={() => {
                      _putAdminReadNote(groupedMemo.checkinId, groupedMemo.noteId, groupedMemo?.reservationId)
                      _changePage(groupedMemo.approvedStatus, groupedMemo.checkinId, groupedMemo?.reservationId, groupedMemo?.id)
                    }}
                  >
                    <div css={dataRoomNumberStyle}>
                      <div css={roomNumberStyle}>{!groupedMemo.roomNumber ? '-' : `${groupedMemo.roomNumber}`}</div>
                    </div>
                    <div css={dataNameStyle}>
                      <div css={roomNumberStyle}>{groupedMemo.name ?? '-'}</div>
                    </div>
                    <div css={dataCustomerTagStyle}>
                      {groupedMemo.customerTags.map(tag => (
                        <CustomerTag fontColor={tag.fontColor} bgColor={tag.backgroundColor} name={tag.name} />
                      ))}
                    </div>
                    <div css={memoStyle}>
                      {groupedMemo.memos.map(memo => {
                        return <div>
                            <img src={require(`@/static/images/reservation-note/icon_${memo.category}.svg`)} alt="" />
                            <p>{memo.memo}</p>
                        </div>
          })}
                    </div>
                  </div>
                )}
              </>
            )
          })}
      </div>
    </div>
  )
}

const mixedSectionStyle = css(sectionStyle, {
  marginTop: 16,
  overflow: 'hidden',
})

const headerWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: 28,
  background: '#FAFAFA',
  borderTop: '1px solid #F2F2F2',
  borderBottom: '1px solid #F2F2F2',
  fontSize: 10,
  color: '#676767',
  fontWeight: 'bold',
  padding: '0 40px 0 32px',
})

const titleMemoStyle = css({
  width: '62.5%',
})

const titleRoomNumberStyle = css({
  width: '7.7%',
})

const titleCustomerTagStyle = css({
  width: '18.9%',
})

const titleNameStyle = css({
  width: '11.4%',
})

const mixedListContentsStyle = css(listContentsStyle, {
  height: 580,
  overflowX: 'hidden',
  overflowY: 'auto',
})

const dataRoomNumberStyle = css({
  width: '7.7%',
  display: 'flex',
  alignItems: 'center',
})

const dataNameStyle = css({
  width: '11.4%',
  display: 'flex',
  alignItems: 'center',
})

const dataCustomerTagStyle = css({
  width: '18.9%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: 8,
})

// list detail
const memoStyle = css({
  width: '62.5%',
  div: {
    display: 'flex',
    alignItems: 'center',
    gap: 14,
    '& + div': {
      marginTop: 12,
    }
  },
  p: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: 1.5,
  }
})

const roomNumberStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const memoListItemUnreadStyle = css(listItemUnreadStyle, {
  fontSize: 12,
  alignItems: 'flex-start',
  fontWeight: 'bold',
  padding: '16px 32px',
})

const memoListItemReadStyle = css(listItemReadStyle, {
  fontSize: 12,
  fontWeight: 'bold',
  padding: '16px 32px',
})
