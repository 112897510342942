import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { css } from '@emotion/core'

// apis
import * as api from '@/apis/aipass'

// contexts
import { AccountContext } from '@/contexts/account'

// constants
import { listContainerStyle } from '@/constants/layout'

// models
import { FacilityPageIndex } from '@/models/facility'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { PluginCode, checkActivePlugin } from '@/libs/plugins'
import { BankAccountTypeLabel, InvoiceAccount } from '@/models/admin-account'
import { Hint } from '@/components/atoms/hint'
import { BankNoteTips } from '@/components/organisms/settings/account/bank-note-tips'

export const SettingAccount: React.FC = () => {
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const location = useLocation<any>()
  const history = useHistory()
  const [jpContent, setJpContent] = useState<string>('')
  const { plugins } = useContext<any>(AccountContext)
  const guestAppPluginActive = checkActivePlugin(plugins, PluginCode.GuestApp)

  // Hooks
  const [hotelStatus, setHotelStatus] = useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hotelInvoiceAccount, setHotelInvoiceAccount] = useState<InvoiceAccount | null>()
  const {
    hotelName,
    hotelNameLatin,
    checkinBeginTime,
    checkinEndTime,
    checkoutBeginTime,
    checkoutEndTime,
    telephone,
    frontOpenTime,
    frontCloseTime,
  } = hotelStatus

  // Acquisition of in-house guidance information
  const fetchFacilityAdditionalInfo = async () => {
    await api.fetchFacilityAdditionalInfo(FacilityPageIndex.WifiTop).then(res => {
      if (res !== undefined) {
        setJpContent(res?.facilityAdditionalPageInfo.jpContent)
      }
    })
  }

  const fetchAdminAccount = async () => {
    const res = await api.fetchAdminAccount()
    res && setHotelInvoiceAccount(res.invoiceAccount)
  }

  const fetchFacilityBasicInfo = async () => {
    await api
      .fetchFacilityBasicInfo()
      .then(res => {
        if (res !== undefined) {
          setHotelStatus(res?.basicInfo)
          setIsLoading(false)
        }
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const isValidBankAccount = (
    hotelInvoiceAccount: InvoiceAccount | null | undefined,
  ): hotelInvoiceAccount is NonNullable<InvoiceAccount> => {
    if (!hotelInvoiceAccount) {
      return false
    }
    return !!(
      hotelInvoiceAccount.bankName &&
      hotelInvoiceAccount.bankBranchName &&
      hotelInvoiceAccount.bankAccountType &&
      hotelInvoiceAccount.bankAccountNumber
    )
  }

  useEffect(() => {
    fetchFacilityBasicInfo()
    fetchAdminAccount()

    setIsLoading(true)
    fetchFacilityAdditionalInfo()
  }, [])

  return (
    <SettingsLayout loading={isLoading}>
      <>
        <div css={hotelAccountDetailsContainerStyle}>
          <div css={detailsHeaderContainerStyle}>
            <div css={detailsHeaderTitleStyle}>{t(guestAppPluginActive ? 'Facility information' : 'Facility guidance')}</div>
            <ul
              css={detailsHeaderEditButtonWrapperStyle}
              onClick={() =>
                history.push({
                  pathname: '/setting/account/edit',
                  search: location.search,
                  state: { account: account, hotelStatus: hotelStatus },
                })
              }
            >
              <img src={require('@/static/images/edit.svg')} />
              <li>{t('Edit')}</li>
            </ul>
          </div>
          <div css={detailsMainContainerStyle}>
            <ul css={detailsMainLeftContainerStyle}>
              <li css={hotelListStyle}>
                <p css={mainLeftLabelStyle}>{t('Hotel logo')}</p>
                <div css={hotelLogoImageWrapperStyle}>
                  {hotelStatus && hotelStatus.logoImageId ? (
                    <div css={hotelLogoImageStyle} style={{ backgroundImage: `url(${hotelStatus ? hotelStatus.logoImageId : null})` }} />
                  ) : (
                    <div css={hotelLogoImageNotsetStyle}>
                      <p css={hotelLogoNotsetStyle}>{t('Not set')}</p>
                    </div>
                  )}
                </div>
              </li>
            </ul>
            <ul css={detailMainRightContainerStyle}>
              <li css={hotelListStyle}>
                <p css={mainLeftLabelStyle}>{t('Hotel image')}</p>
                {hotelStatus && hotelStatus.extImageId ? (
                  <div css={hotelPictureImageStyle} style={{ backgroundImage: `url(${hotelStatus ? hotelStatus.extImageId : null})` }} />
                ) : (
                  <div css={hotelImageNotsetStyle}>
                    <p css={hotelNotsetTextStyle}>{t('Not set')}</p>
                  </div>
                )}
              </li>
            </ul>
          </div>
          <div css={hotelCompanyDetailsSubContainerStyle}>
            <ul css={detailsMainLeftContainerStyle}>
              <li css={hotelListStyle}>
                <div css={mainLeftLabelStyle}>{t('Name of facility')}</div>
                <div css={hotelname}>
                  <p css={hotelContentsStyle}>{t('A name of a hotel')}</p>
                  <p css={hotelContentsStyle}>{hotelName ? hotelName : '-'}</p>
                </div>
                <div css={hotelname}>
                  <p css={hotelContentsStyle}>{t('Hotel name (English display)')}</p>
                  <p css={hotelContentsStyle}>{hotelNameLatin ? hotelNameLatin : '-'}</p>
                </div>
              </li>
            </ul>
            <ul css={detailMainRightContainerStyle}>
              <li css={hotelListStyle}>
                <div css={mainLeftLabelStyle}>{t('Check-in/out')}</div>
                <div css={checkinOutStyle}>
                  <p css={hotelContentsStyle}>{t('Check-in time')}</p>
                  <p css={hotelContentsStyle}>
                    {checkinBeginTime} ~ {checkinEndTime}
                  </p>
                </div>
                <div css={checkinOutStyle}>
                  <p css={hotelContentsStyle}>{t('Check out time')}</p>
                  <p css={hotelContentsStyle}>
                    {checkoutBeginTime} ~ {checkoutEndTime}
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div css={hotelCompanyDetailsSubContainerStyle}>
            <ul css={detailsMainLeftContainerStyle}>
              <li css={hotelListStyle}>
                <div css={mainLeftLabelStyle}>{t('Front desk information')}</div>
                <div css={flontInfoStyle}>
                  <p css={hotelContentsStyle}>{t('Front phone number')}</p>
                  <p css={hotelContentsStyle}>{telephone}</p>
                </div>
                <div css={flontInfoStyle}>
                  <p css={hotelContentsStyle}>{t('Front desk hours')}</p>
                  <p css={hotelContentsStyle}>
                    {frontOpenTime} ~ {frontCloseTime}
                  </p>
                </div>
              </li>
            </ul>
            {!guestAppPluginActive && (
              <ul css={detailMainRightContainerStyle}>
                <li css={hotelListStyle}>
                  <div css={mainLeftLabelStyle}>Wi-Fi</div>
                  <p
                    css={hotelContentsStyle}
                    dangerouslySetInnerHTML={{
                      __html: jpContent,
                    }}
                  ></p>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div css={flexColumn}>
          <div css={hotelCompanyDetailsContainerLeft}>
            <div css={hotelCompanyDetailsHeaderContainerStyle}>
              <div css={detailsHeaderTitleStyle}>{t('Receipt')}</div>
              <ul
                css={detailsHeaderEditButtonWrapperStyle}
                onClick={() =>
                  history.push({
                    pathname: '/setting/account/receipt-edit',
                    search: location.search,
                    state: { account: account, hotelStatus: hotelStatus },
                  })
                }
              >
                <img src={require('@/static/images/edit.svg')} />
                <li>{t('Edit')}</li>
              </ul>
            </div>
            <div css={hotelCompanyDetailsMainContainerStyle}>
              <ul>
                <li css={hotelname}>
                  <p css={receiptLeftStyle}>{t('A name of a hotel')}</p>
                  <p css={receiptCompanyRightStyle}>{hotelStatus?.receiptHotelName || '-'}</p>
                </li>
                <li css={hotelname}>
                  <p css={receiptLeftStyle}>{t('Phone number')}</p>
                  <p css={receiptCompanyRightStyle}>{hotelStatus?.receiptTelephone || '-'}</p>
                </li>
                <li css={hotelname}>
                  <p css={receiptLeftStyle}>{t('Address')}</p>
                  <p css={receiptCompanyRightStyle}>{hotelStatus?.receiptAddress || '-'}</p>
                </li>
                <li css={hotelname}>
                  <p css={receiptLeftStyle}>{t('Registration number')}</p>
                  <p css={receiptCompanyRightStyle}>{hotelStatus?.receiptRegistrationNumber || '-'}</p>
                </li>
                <li css={hotelname}>
                  <p css={receiptLeftStyle}>{t('Displayed by plan name and subject set name')}</p>
                  <p css={receiptCompanyRightStyle}>{!hotelStatus?.receiptPlanBreak ? t('Display') : t('Hidden')}</p>
                </li>
              </ul>
            </div>
          </div>
          <div css={hotelCompanyDetailsContainerRight}>
            <div css={hotelCompanyDetailsHeaderContainerStyle}>
              <div css={detailsHeaderTitleStyle}>{t('Invoice')}</div>
              <ul
                css={detailsHeaderEditButtonWrapperStyle}
                onClick={() =>
                  history.push({
                    pathname: '/setting/account/company-info-edit',
                    search: location.search,
                    state: { account: account, hotelStatus: hotelStatus },
                  })
                }
              >
                <img src={require('@/static/images/edit.svg')} />
                <li>{t('Edit')}</li>
              </ul>
            </div>
            <div css={hotelCompanyDetailsMainContainerStyle}>
              <ul>
                <li css={companyInfoStyle}>
                  <div css={mainRightLabelStyle}>{t('Company name')}</div>
                  <p css={receiptCompanyRightStyle}>{hotelInvoiceAccount?.companyName || '-'}</p>
                </li>
                <li css={companyInfoStyle}>
                  <div css={mainRightLabelStyle}>{t('Address')}</div>
                  <div>
                    {hotelInvoiceAccount?.companyPostalCode && (
                      <p css={receiptCompanyRightStyle}>{`〒${hotelInvoiceAccount?.companyPostalCode}`}</p>
                    )}
                    <p css={receiptCompanyRightStyle}>{hotelInvoiceAccount?.companyAddress || '-'}</p>
                  </div>
                </li>
                <li css={companyInfoStyle}>
                  <div css={mainRightLabelStyle}>{t('Phone number')}</div>
                  <p css={receiptCompanyRightStyle}>{hotelInvoiceAccount?.companyTelephone || '-'}</p>
                </li>
                <li css={companyInfoStyle}>
                  <div css={mainRightLabelStyle}>{t("Payee's bank account")}</div>
                  {isValidBankAccount(hotelInvoiceAccount) ? (
                    <div>
                      <p css={receiptCompanyRightStyle}>
                        {hotelInvoiceAccount?.bankName} {hotelInvoiceAccount.bankBranchName}
                      </p>
                      <p css={receiptCompanyRightStyle}>
                        {`${BankAccountTypeLabel[hotelInvoiceAccount.bankAccountType]} ${hotelInvoiceAccount?.bankAccountNumber}`}
                      </p>
                      <p css={receiptCompanyRightStyle}>{hotelInvoiceAccount.bankAccountName}</p>
                    </div>
                  ) : (
                    <p css={receiptCompanyRightStyle}>-</p>
                  )}
                </li>
                <li css={companyInfoStyle}>
                  <div css={mainRightLabelStyle}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {t('Remarks')}
                      <BankNoteTips />
                    </div>
                  </div>
                  <p css={receiptCompanyRightStyle} style={{ lineHeight: '18px' }}>
                    {hotelInvoiceAccount?.note || '-'}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    </SettingsLayout>
  )
}

// topStyle
const hotelAccountDetailsContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 410,
  marginBottom: 16,
})

const detailsHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const detailsHeaderEditButtonWrapperStyle = css({
  display: 'flex',
  border: '1px solid #CCCCCC',
  margin: '11px 0',
  padding: '0 12px',
  cursor: 'pointer',
  borderRadius: 13,
  img: {
    width: 24,
  },
  li: {
    padding: '6px 0',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#F2A40B',
  },
  ':hover': {
    backgroundColor: '#F2F2F2',
  },
})

const detailsHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
})

const detailsMainContainerStyle = css({
  display: 'flex',
  borderBottom: '1px solid #F2F2F2',
})

const detailsMainLeftContainerStyle = css({
  width: '50%',
  borderRight: '1px solid #F2F2F2',
})

const mainLeftTopLabelContentStyle = css({
  color: '#272727',
  paddingBottom: 12,
})

const mainLeftLabelStyle = css(mainLeftTopLabelContentStyle, {
  fontWeight: 'bold',
  fontSize: 16,
  marginBottom: 20,
})
const hotelLogoImageWrapperStyle = css({
  width: '100%',
  margin: 'auto',
})

const hotelLogoImageStyle = css({
  width: 120,
  height: 120,
  margin: 'auto',
  border: '1px solid #CCCCCC',
  borderRadius: '50%',
  backgroundPosition: 'center',
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
})

const hotelLogoImageNotsetStyle = css({
  width: 120,
  height: 120,
  margin: '0 auto',
  border: '1px solid #CCCCCC',
  borderRadius: '50%',
  backgroundPosition: 'center',
  backgroundSize: '100% auto',
  backgroundColor: '#F2F2F2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const hotelLogoNotsetStyle = css({
  padding: '34px 0',
  fontSize: '12px',
  color: '#CCCCCC',
  letterSpacing: '1.2px',
  textAlign: 'center',
})

const hotelPictureImageStyle = css({
  width: '100%',
  height: 159,
  borderRadius: 5,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
})

const hotelImageNotsetStyle = css({
  width: '100%',
  height: 159,
  border: '1px solid #CCCCCC',
  borderRadius: 5,
  backgroundPosition: 'center',
  backgroundSize: '100% auto',
  backgroundColor: '#F2F2F2',
})

const hotelNotsetTextStyle = css({
  padding: '72px 0',
  textAlign: 'center',
  fontSize: '13px',
  letterSpacing: '1.3px',
  color: '#CCCCCC',
})

const detailMainRightContainerStyle = css({
  width: '50%',
})

const mainRightLabelStyle = css(mainLeftTopLabelContentStyle, {
  padding: '10px 0px',
  fontSize: 14,
  minWidth: 100,
})

const hotelContentsStyle = css({
  color: '#272727',
  letterSpacing: 1.4,
  fontSize: 14,
  margin: '10px 20px 5px 0px',
})

// bottomStyle
const hotelCompanyDetailsContainer = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
})

const hotelCompanyDetailsContainerLeft = css(hotelCompanyDetailsContainer, {
  marginRight: 7,
})

const hotelCompanyDetailsContainerRight = css(hotelCompanyDetailsContainer, {
  marginLeft: 7,
  minHeight: 200,
})
const hotelCompanyDetailsHeaderContainerStyle = css(detailsHeaderContainerStyle)

const hotelCompanyDetailsMainContainerStyle = css({
  padding: '24px 32px',
  minHeight: 150,
})
const hotelCompanyDetailsSubContainerStyle = css(detailsMainContainerStyle, {
  minHeight: 120,
})

const flexColumn = css({
  display: 'flex',
})
const hotelname = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const checkinOutStyle = css(hotelname, {})
const flontInfoStyle = css(hotelname, {})
const companyInfoStyle = css(hotelname, {})
const receiptLeftStyle = css(mainLeftTopLabelContentStyle, {
  margin: '10px 0px 5px 0px',
  fontSize: 14,
  minWidth: 210,
})
const receiptCompanyRightStyle = css(hotelContentsStyle, {
  margin: '10px 0px 5px 0px',
  fontSize: 14,
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
})
const hotelListStyle = css({
  margin: 32,
})
