import { useContext, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

// contexts
import { AuthContext } from '@/contexts/auth'

// apis
import { parseToken } from '@/apis/auth0'

export const useHandleDriversLicenseAuthRedirect = () => {
  const { hash } = useLocation()
  const history = useHistory()
  const { setAuth } = useContext<any>(AuthContext)

  useEffect(() => {
    if (hash) {
      _parseToken()
    }
  }, [hash])

  const _parseToken = () => {
    parseToken()
      .then(result => _success(result))
      .catch(error => console.log(error))
      .finally()
  }

  const _success = authData => {
    setAuth(authData)
    // Extract state="checkinId=${checkinId}&imageKey=${imageKey}&page=${page}" in hash
    const state = decodeURIComponent(
      hash
        .split('&')
        .filter(hash => hash.includes('state'))[0]
        .substr(6),
    )
    const checkinId = state
      .split('&')
      .filter(state => state.includes('checkinId'))[0]
      .substr(10)
    const imageKey = state
      .split('&')
      .filter(state => state.includes('imageKey'))[0]
      .substr(9)
    const page = state
      .split('&')
      .filter(state => state.includes('page'))[0]
      .substr(5)

    history.push({
      pathname: `/${page}/${checkinId}`,
      search: `${page !== 'customer' ? `?tabType=${page}` : ''}`,
      state: { imageKey },
    })
  }
}
