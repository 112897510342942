import React, { useEffect, useMemo, useState } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { useTranslation } from 'react-i18next'
import { MailDeliveryTab } from '@/components/molecules/settings/mail-delivery/mail-delivery-tab'
import { deleteMailDeliveryContents, getMailDeliveryContents } from '@/apis/aipass'
import { errorHandler } from '@/libs/errors'
import { css } from '@emotion/core'
import { Button } from '@/components/atoms'
import { EditFooter } from '@/components/organisms/edit-footer'
import { Link, useHistory } from 'react-router-dom'
import { DeliveryContentItem, isReservation, mailDeliveryStatus } from '@/models/mail-delivery/mail-delivery-content'

export const SettingMailDelivery: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [showFooter, setShowFooter] = useState(false)
  const [contents, setContents] = useState<DeliveryContentItem[]>([])
  const [deleteIds, setDeleteIds] = useState<string[]>([])

  const history = useHistory()

  const { t } = useTranslation()

  const _getMailDeliveryContents = async () => {
    try {
      setLoading(true)
      const result = await getMailDeliveryContents()
      setContents(result)
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    _getMailDeliveryContents()
  }, [])

  const statusElement = (status: string) => {
    const color = isReservation(status) ? '#F2A40B' : '#676767'
    return (
      <span className="col-2" style={{ color: color, borderColor: color }}>
        <span className="status">{t(mailDeliveryStatus[status])}</span>
      </span>
    )
  }

  const onDeleteItem = (id: string) => {
    setDeleteIds([...deleteIds, id])
    setShowFooter(true)
  }

  const onDeleteSubmit = async () => {
    try {
      setLoading(true)
      await deleteMailDeliveryContents({ ids: deleteIds })
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
      setShowFooter(false)
    }
  }

  const onCancel = () => {
    setDeleteIds([])
    setShowFooter(false)
  }

  const viewContents = useMemo(() => {
    return contents.filter(content => !deleteIds.includes(content.id))
  }, [contents, deleteIds])

  const footerContent = showFooter ? <EditFooter onSave={onDeleteSubmit} onCancel={onCancel} /> : undefined

  return (
    <SettingsLayout loading={loading} footerContent={footerContent}>
      <TabContainer tabComponent={<MailDeliveryTab currentTab="Distribution List" />}>
        <div css={contentsStyle}>
          <Button
            width={107}
            height={32}
            buttonType={1}
            marginTop={24}
            marginBottom={20}
            marginLeft={32}
            icon={require('@/static/images/plus-white.svg')}
            onClick={() => history.push({ pathname: '/setting/mail-delivery-content/new' })}
          >
            {t('Add new')}
          </Button>
          <p className="list-header">
            <span className="col-1">{t('mailDelivery.title')}</span>
            <span className="col-2">{t('Status')}</span>
            <span className="col-3">{t('Scheduled delivery date')}</span>
          </p>
          <ul className="content-list">
            {viewContents.map(content => (
              <li key={content.id}>
                <Link to={`/setting/mail-delivery-content/${content.id}`}>
                  <span className="col-1">{content.title.ja}</span>
                  {statusElement(content.status)}
                  <span className="col-3">{content.dateText}</span>
                </Link>
                <span className="col-4">
                  <img
                    className="delete-button"
                    src={require('@/static/images/room_delete_gold.svg')}
                    onClick={() => onDeleteItem(content.id)}
                  />
                </span>
              </li>
            ))}
          </ul>
        </div>
      </TabContainer>
    </SettingsLayout>
  )
}

const contentsStyle = css({
  '.list-header': {
    paddingBlock: 8,
    paddingLeft: 32,
    paddingRight: 24,
    background: '#F2F2F2',
    fontSize: 12,
    fontWeight: 'bold',
  },
  '.content-list': {
    li: {
      display: 'flex',
      alignItems: 'center',
      a: {
        width: '100%',
        color: '#272727',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: 14,
        paddingBlock: 16,
        paddingLeft: 32,
        paddingRight: 24,
        borderBottom: '1px solid #F2F2F2',
      },
    },
  },
  span: {
    display: 'inline-block',
    '.status': {
      width: 78,
      paddingBlock: 4,
      textAlign: 'center',
      border: '1px solid',
      fontSize: 10,
      borderRadius: 5,
    },
  },
  '.col-1': {
    width: '67%',
  },
  '.col-2': {
    width: '78px',
    marginRight: 30,
  },
  '.col-3': {
    width: '182px',
  },
  '.col-4': {
    position: 'absolute',
    right: 12,
  },
  '.delete-button': {
    cursor: 'pointer',
  },
})
