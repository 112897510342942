import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@/components/atoms/modal'
import { Button } from '@/components/atoms'
import { Checkbox } from '@/components/atoms/checkbox'
import { ReservationMemos, ReservationNote, ReservationNoteCategory } from '@/models/reservation/reservation-note'
import { css } from '@emotion/core'

export type UseNotes = {
  [key in ReservationNoteCategory]?: {
    memo?: string
    isUse: boolean
  }
}

interface ReservationNotePopupProps {
  onClose: () => void
  pastNotes: ReservationNote[]
  inputNotes: ReservationMemos | null
  onCopyPastNote: (notes: ReservationMemos) => void
}

export const ReservationNoteModal: React.FC<ReservationNotePopupProps> = ({ onClose, inputNotes, pastNotes, onCopyPastNote }) => {
  const [useNotes, setUseNotes] = useState<UseNotes>()

  const { t } = useTranslation()

  const saveNotes = async () => {
    const createInsertMemo = (category: ReservationNoteCategory) => {
      if (useNotes?.[category]?.isUse) {
        const currentNote = inputNotes?.[category]
        const pastNote = useNotes?.[category]?.memo

        if (currentNote) {
          return `${pastNote}\n${currentNote}`
        }

        return pastNote
      }
    }
    const notes: ReservationMemos = {
      [ReservationNoteCategory.Front]: createInsertMemo(ReservationNoteCategory.Front),
      [ReservationNoteCategory.Cleaning]: createInsertMemo(ReservationNoteCategory.Cleaning),
      [ReservationNoteCategory.Meal]: createInsertMemo(ReservationNoteCategory.Meal),
    }

    onCopyPastNote(notes)
  }

  useEffect(() => {
    const notes = pastNotes.reduce((acc, r) => {
      const newUseNote = {
        [r.category]: {
          memo: r.memo,
          isUse: !!r.memo,
        },
      }
      acc = { ...acc, ...newUseNote }
      return acc
    }, useNotes)
    setUseNotes(notes)
  }, [pastNotes])

  return (
    <>
      <Modal style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, margin: 'auto' }}>
        <ModalHeader>{t('Do you want to copy your previous appointment notes?')}</ModalHeader>
        <ModalBody>
          <div css={modalContentStyle}>
            {Object.entries(ReservationNoteCategory).map(([_, category]) => {
              const hasMemo = pastNotes.find(note => note.category === category)?.memo
              return hasMemo ? (
                <div key={category}>
                  <Checkbox
                    value={!!useNotes?.[category]?.isUse}
                    onChange={e => {
                      const currentUseNote = useNotes?.[category]
                      if (currentUseNote) {
                        setUseNotes({ ...useNotes, [category]: { ...currentUseNote, isUse: e } })
                      }
                    }}
                    label={t(`reservationNote.${category}`)}
                  />

                  <p className="memo">{pastNotes.find(note => note.category === category)?.memo || '-'}</p>
                </div>
              ) : (
                <></>
              )
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onClose()}>
            {t('Cancel')}
          </Button>
          <Button
            buttonType={Object.values(useNotes || {}).some(useNote => useNote.isUse) ? 1 : 5}
            height="38px"
            width="110px"
            onClick={() => saveNotes()}
          >
            {t('Copy')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const modalContentStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 27,
  height: '100%',
  background: '#fff',
  padding: 24,
  overflowY: 'scroll',
  '.memo': {
    paddingLeft: 26,
    fontSize: 12,
    marginTop: 6,
    lineHeight: '1.5',
  },
})
