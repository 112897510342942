import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css, CSSObject } from '@emotion/core'
import { useForm, Controller } from 'react-hook-form'
import { capitalize } from 'lodash'

import { AccountContext } from '@/contexts/account'
import { NoticeLoading } from '@/components/molecules/notice-loading'
import { Button } from '@/components/atoms/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { InputField } from '@/components/molecules/input-field'

import { loginEmployeeStaff, logoutEmployeeStaff } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'

type StaffSwitcherProps = {
  customCss?: CSSObject
}

export const StaffSwitcher: React.FC<StaffSwitcherProps> = ({ customCss }) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { employeeStaff, fetchEmployeeStaff } = useContext<any>(AccountContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingWithPassCode, setIsLoadingWithPassCode] = useState<boolean>(false)
  const [currentStaff, setCurrentStaff] = useState<any>()
  const [needPassCodeStaff, setNeedPassCodeStaff] = useState<any>()
  const {
    control,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<{ passCode: string }>()

  const onClickStaff = async (staff?: any) => {
    try {
      if (isLoading || staff?.isCurrent) return

      setIsLoading(true)

      if (staff) {
        if (staff.hasPassCode) return setNeedPassCodeStaff(staff)
        await loginEmployeeStaff(staff?.id)
      } else {
        await logoutEmployeeStaff()
      }

      await fetchEmployeeStaff()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const loginStaffWithPassCode = async ({ passCode }) => {
    try {
      if (isLoadingWithPassCode) return

      setIsLoadingWithPassCode(true)
      await loginEmployeeStaff(needPassCodeStaff?.id, passCode)

      resetForm()
      setNeedPassCodeStaff(undefined)

      await fetchEmployeeStaff()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoadingWithPassCode(false)
    }
  }

  useEffect(() => {
    if (employeeStaff?.length) {
      setCurrentStaff(employeeStaff.find((staff: any) => staff.isCurrent))
    } else {
      setCurrentStaff(undefined)
    }
  }, [employeeStaff])

  if (!employeeStaff?.length) return null

  return (
    <div css={[staffSwitcher, customCss]}>
      <div className="title">
        {currentStaff?.name || t('unset')}
        <img alt="icon_arrow_up" src={require('@/static/images/header/button_arrow_down.svg')} css={{ marginLeft: 8 }} />
      </div>
      <div className="list">
        <div className="list__container">
          {employeeStaff.map((staff: any, index: number) => (
            <div
              key={staff.id}
              onClick={() => onClickStaff(staff)}
              css={[
                { borderBottom: '1px solid #f2f2f2' },
                staff.isCurrent ? { color: '#f2a40b' } : { ':hover': { cursor: 'pointer', background: '#f2f2f2' } },
              ]}
              className="staff-item"
            >
              {staff.name}
            </div>
          ))}
          <div
            onClick={() => currentStaff && onClickStaff()}
            className="staff-item"
            css={[
              { textTransform: 'capitalize' },
              !currentStaff ? { color: '#f2a40b' } : { ':hover': { cursor: 'pointer', background: '#f2f2f2' } },
            ]}
          >
            {t('unset')}
          </div>
        </div>
      </div>
      {needPassCodeStaff && (
        <Modal customCss={{ height: 300, width: 600, minWidth: 600, left: 'calc(50% - 300px)' }}>
          <ModalHeader>{t('Passcode input')}</ModalHeader>
          <ModalBody>
            <NoticeLoading isLoading={isLoadingWithPassCode} />
            <div css={{ padding: 16, border: '1px solid #ddd', borderRadius: 8, backgroundColor: '#fff' }}>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="passCode"
                render={({ field: { onChange, value } }) => (
                  <InputField
                    value={value}
                    handleChangeData={onChange}
                    error={errors.passCode?.message}
                    label={capitalize(t('Passcode input'))}
                  />
                )}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => setNeedPassCodeStaff(undefined)}>
              {t('Cancel')}
            </Button>
            <Button buttonType={1} height="38px" width="110px" onClick={handleSubmit(loginStaffWithPassCode)}>
              {t('Save')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}

const staffSwitcher = css({
  position: 'relative',
  '.title': {
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#676767',
    cursor: 'pointer',
  },
  '.list': {
    position: 'absolute',
    top: 16,
    zIndex: 11,
    left: -80,
    display: 'none',
    width: 180,
    padding: 4,
    '.list__container': {
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: '0px 3px 10px #0000003d',
      borderRadius: 5,
      overflow: 'hidden',
      '.staff-item': {
        padding: 16,
        fontSize: '12px',
        fontWeight: 'bold',
      },
    },
  },
  ':hover': {
    '.list': {
      display: 'block',
    },
  },
})
