export const decodeQr = data => {
  return new Promise((resolve, reject) => {
    try {
      const value = JSON.parse(decodeURIComponent(escape(atob(data))))
      resolve(value)
    } catch (error) {
      reject(error)
    }
  })
}
