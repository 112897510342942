import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { css } from '@emotion/core'

import { Button } from '@/components/atoms/button'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { SettingsEditStaffModal } from '@/components/organisms/settings/staff/settings-edit-staff-modal'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { dashboardPageContainerStyle, tableHeaderThStyle, tableBodyTdStyle, dashboardPageHeaderStyle, HEADER_HEIGHT } from '@/constants/layout'
import { StaffType } from '@/models/staff'
import { EditFooter } from '@/components/organisms/edit-footer'
import * as API from '@/apis/aipass'
import { AccountContext } from '@/contexts/account'

export const SettingStaff: React.FC = () => {
  const windows = useWindowSize()
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { fetchEmployeeStaff } = useContext<any>(AccountContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [staffsList, setStaffList] = useState<StaffType[]>()
  const [editingStaff, setEditingStaff] = useState<StaffType>()
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [deleteRoomIds, setDeleteRoomIds] = useState<string[]>([])

  const loadStaffList = async () => {
    try {
      setLoading(true)

      const res = await API.getStaffList()

      setStaffList(res || [])
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const addNewStaff = () => {
    setShowEditModal(true)
  }

  const editExistStaff = (staff: StaffType) => {
    setEditingStaff(staff)
    setShowEditModal(true)
  }

  const onCloseEdit = () => {
    setEditingStaff(undefined)
    setShowEditModal(false)
  }

  const onSaveEdit = () => {
    setEditingStaff(undefined)
    setShowEditModal(false)
    loadStaffList()
    fetchEmployeeStaff()
  }

  const onClickFooterCancelButton = () => {
    setDeleteRoomIds([])
  }

  const deleteStaff = async (e: React.MouseEvent<HTMLElement>, staff: StaffType) => {
    e.stopPropagation()

    setDeleteRoomIds([...deleteRoomIds, staff.id])
  }

  const onClickFooterSaveButton = async () => {
    try {
      setLoading(true)

      for (const deleteRoomId of deleteRoomIds) {
        await API.deleteStaff(deleteRoomId)
        setDeleteRoomIds(deleteRoomIds.filter(id => id !== deleteRoomId))
      }

      await loadStaffList()
      fetchEmployeeStaff()
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadStaffList()
  }, [])

  const footerContent = deleteRoomIds.length ? (
    <EditFooter onSave={onClickFooterSaveButton} onCancel={onClickFooterCancelButton} />
  ) : undefined

  return (
    <>
      <SettingsLayout loading={loading} footerContent={footerContent}>
        <>
          <div css={dashboardPageContainerStyle}>
            <div css={headerSettingStaffStyle}>
              <div css={headerDetailStyle}>{t('Staff')}</div>
            </div>
            <div css={dashboardPageHeaderStyle}>
              <Button
                buttonType={1}
                width={107}
                height={32}
                fontSize={12}
                marginRight={16}
                icon={require('@/static/images/mobile_request/add.svg')}
                onClick={addNewStaff}
              >
                {t('Add new')}
              </Button>
            </div>
            <div css={{ overflow: 'auto', height: windows.height - HEADER_HEIGHT - 24 * 2 - 123 }}>
              {!!staffsList?.length && (
                <table css={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th css={[tableHeaderThStyle, { paddingLeft: 28 }]}>{t('ID')}</th>
                      <th css={tableHeaderThStyle}>{t('Full name')}</th>
                      <th css={tableHeaderThStyle}>{t('Pass code')}</th>
                      <th css={[tableHeaderThStyle, { paddingRight: 28 }]}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {staffsList?.map(staff => {
                      if (deleteRoomIds.includes(staff.id)) return null
                      return (
                        <tr onClick={() => editExistStaff(staff)} key={staff.id} css={{ cursor: 'pointer' }}>
                          <td css={[tableBodyTdStyle, { paddingLeft: 28, fontWeight: 'bold' }]}>{staff.order}</td>
                          <td css={[tableBodyTdStyle, { fontWeight: 'bold' }]}>{staff.name}</td>
                          <td css={[tableBodyTdStyle, { fontWeight: 'bold' }]}>{staff.passCode}</td>
                          <td css={[tableBodyTdStyle, { paddingRight: 28, width: 32 }]}>
                            <img
                              onClick={e => deleteStaff(e, staff)}
                              src={require('@/static/images/delete_orange.svg')}
                              css={{ width: 32, height: 32, cursor: 'pointer' }}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
              {!staffsList?.length && !loading && (
                <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <h1>{t('No data available')}</h1>
                </div>
              )}
            </div>
          </div>
        </>
      </SettingsLayout>
      {showEditModal && <SettingsEditStaffModal staff={editingStaff} onClose={onCloseEdit} onSave={onSaveEdit} />}
    </>
  )
}

const headerSettingStaffStyle = css({
  height: '50px',
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const headerDetailStyle = css({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: '16px 0',
  color: '#272727',
})
