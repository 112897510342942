import React, { useState } from 'react'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

// apis
import { putAdminReadReview } from '@/apis/aipass'

// components
import { sectionStyle } from '@/components/pages/dashboard/_index/style'
import { ReviewHeader } from './_review-info/review-header'
import { ReviewTab } from './_review-info/review-tab'
import { OverAllNps } from './_review-info/over-all-nps'
import { DailyNps } from './_review-info/daily-nps'
import { Assessment } from './_review-info/assessment'

type ReviewInfoProps = {
  dashboardValue: any
  dailyNps?: any
  reviews?: any
  overallNpsValue?: any
  overallNpsRatio?: any
}

export const ReviewInfo: React.FC<ReviewInfoProps> = ({ dashboardValue }) => {
  const history = useHistory()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const [tab, setTab] = useState<'overall' | 'daily'>('overall')
  const dailyNps = dashboardValue?.dailyNps
  const reviews = dashboardValue?.reviews
  const overallNpsValue = dashboardValue?.overallNpsValue
  const overallNpsRatio = [
    { name: 'Bad / 0~6', value: 0, color: '#CCCCCC', pieColor: '#F2F2F2' },
    { name: 'Good / 7~8', value: 0, color: '#EBD41D' },
    { name: 'Excellent / 9~10', value: 0, color: '#F2A40B' },
  ]

  const overallNpsRatioValue = dashboardValue?.overallNpsRatio

  if (overallNpsRatioValue) {
    overallNpsRatio[0].value = overallNpsRatioValue?.bad
    overallNpsRatio[1].value = overallNpsRatioValue?.good
    overallNpsRatio[2].value = overallNpsRatioValue?.excellent
  }

  const data = [
    { name: 'Bad', value: overallNpsRatio[0]?.value },
    { name: 'Good', value: overallNpsRatio[1]?.value },
    { name: 'Excellent', value: overallNpsRatio[2]?.value },
  ]

  const _putAdminReadReview = async (item) => {
    await putAdminReadReview(item.checkinId)
    return history.push({ pathname: `/checkout/${item.checkinId}`, search: location.search })
  }

  const _onColorChange = item => {
    if (Number(item) <= 6 && item === '') {
      return cellLowStyle
    } else if (Number(item) === 7 || Number(item) === 8) {
      return cellMiddleStyle
    } else if (Number(item) === 9 || Number(item) === 10) {
      return cellHighStyle
    }

    return cellNullStyle
  }

  const _renderPlus = () => {
    if (overallNpsValue <= 0) return null

    // Calculate the angle to display the gradation, white background after the gradation
    const radian = ((90 - (overallNpsValue * 90) / 100) * Math.PI) / 180
    const gradientRate = Math.cos(radian) * 100
    return (
      <div
        style={{
          width: '76px',
          height: '76px',
          background: `linear-gradient(to right,#FF7733, #FF2700 ${gradientRate}%, 0%, #FFF)`,
          position: 'absolute',
          top: '20px',
          left: '109px',
          borderRadius: '0 76px 0 0',
        }}
      ></div>
    )
  }

  const _renderRightGray = () => {
    const deg = overallNpsValue > 0 ? (overallNpsValue * 90) / 100 : 0
    return (
      <div
        style={{
          width: '76px',
          height: '76px',
          background: '#F2F2F2',
          position: 'absolute',
          top: '20px',
          left: '109px',
          transform: `rotate(${deg}deg)`,
          transformOrigin: 'bottom left',
          borderRadius: '0 76px 0 0',
          zIndex: 2,
        }}
      ></div>
    )
  }

  const _renderMinus = () => {
    if (overallNpsValue >= 0) return null

    // Calculate the angle to display the gradation, white background after the gradation
    const radian = ((90 + (overallNpsValue * 90) / 100) * Math.PI) / 180
    const gradientRate = Math.cos(radian) * 100
    return (
      <div
        style={{
          width: '76px',
          height: '76px',
          background: `linear-gradient(to left,#9B9B9B, #606060 ${gradientRate}%, 0%, #FFF)`,
          position: 'absolute',
          top: '20px',
          left: '33px',
          borderRadius: '76px 0 0 0',
        }}
      ></div>
    )
  }

  const _renderLeftGray = () => {
    const deg = overallNpsValue < 0 ? (-overallNpsValue * 90) / 100 : 0
    return (
      <div
        style={{
          width: '76px',
          height: '76px',
          background: '#F2F2F2',
          position: 'absolute',
          top: '20px',
          left: '33px',
          transform: `rotate(-${deg}deg)`,
          transformOrigin: 'bottom right',
          borderRadius: '76px 0 0 0',
          zIndex: 2,
        }}
      ></div>
    )
  }

  const _renderValue = () => {
    const color = overallNpsValue > 0 ? '#FF2700' : '#606060'
    return (
      <div
        style={{
          width: '120px',
          height: '120px',
          background: '#FFF',
          position: 'absolute',
          top: '35px',
          left: '48px',
          borderRadius: '50%',
          zIndex: 2,
        }}
      >
        <p
          style={{
            color,
            textAlign: 'center',
            fontSize: '30px',
            fontWeight: 'bold',
            marginTop: '25px',
            letterSpacing: '0.05em',
          }}
        >
          {overallNpsValue > 0 ? `+${overallNpsValue}` : overallNpsValue}
        </p>
      </div>
    )
  }

  const _renderArrow = () => {
    const deg = (Math.abs(overallNpsValue) * 90) / 100
    const rad = (((Math.abs(overallNpsValue) * 90) / 100) * Math.PI) / 180
    // When the value is 0, the position is top: 2, left: 106, so the position is determined based on that.
    // 1.1 and 1.15 multiplied by left are for fine adjustment.
    const top = 78 - 76 * Math.cos(rad)
    const left = overallNpsValue > 0 ? 106 + 76 * Math.sin(rad) * 1.1 : 106 - 76 * Math.sin(rad) * 1.15
    if (overallNpsValue > 0) {
      return (
        <img
          src={require('@/static/images/nps_up.svg')}
          style={{
            position: 'absolute',
            top: `${top}px`,
            left: `${left}px`,
            transform: `rotate(${deg}deg)`,
            transformOrigin: 'bottom left',
            zIndex: 90,
          }}
        />
      )
    }
    return (
      <img
        src={require('@/static/images/nps_down.svg')}
        style={{
          position: 'absolute',
          top: `${top}px`,
          left: `${left}px`,
          transform: `rotate(-${deg}deg)`,
          transformOrigin: 'bottom right',
          zIndex: 10,
        }}
      />
    )
  }

  return (
    <div css={mixedSectionStyle}>
      <ReviewHeader history={history} location={location} t={t} />
      <ReviewTab tab={tab} setTab={setTab} dailyNps={dailyNps} />
      <div css={graphStyle}>
        <OverAllNps
          tab={tab}
          renderPlus={_renderPlus}
          renderRightGray={_renderRightGray}
          renderMinus={_renderMinus}
          renderLeftGray={_renderLeftGray}
          renderValue={_renderValue}
          renderArrow={_renderArrow}
          overallNpsValue={overallNpsValue}
          data={data}
          overallNpsRatio={overallNpsRatio}
        />
        <DailyNps tab={tab} dailyNps={dailyNps} />
      </div>
      <Assessment
        reviews={reviews}
        putAdminReadReview={_putAdminReadReview}
        onColorChange={_onColorChange}
      />
    </div>
  )
}

//npsStyle
const graphStyle = css({
  width: '100%',
  background: '#fff',
  marginTop: 32,
  marginBottom: 32,
})

// assessmentPointsStyle
const baseCellStyle = css({
  width: 22,
  minWidth: 22,
  marginLeft: 32,
  fontSize: 16,
  textAlign: 'center',
})

const cellNullStyle = css(baseCellStyle, {
  color: '#cccccc',
})

const cellLowStyle = css(baseCellStyle, {
  color: '#676767',
})

const cellMiddleStyle = css(baseCellStyle, {
  color: '#ffa97f',
})

const cellHighStyle = css(baseCellStyle, {
  color: '#ff5500',
})

const mixedSectionStyle = css(sectionStyle, {
  marginTop: 16,
  overflow: 'hidden',
})
