import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { css } from '@emotion/core'
import { translateObject } from '@/libs/text'
import { SalesType } from '@/models/sales'

//libs
export type Props = {
  reservation: any
  index: number
}

export const CheckinPaymentDetail: React.FC<Props> = ({ reservation, index }) => {
  const _formatDate = item => {
    return moment(item).format('MM/DD')
  }
  const { t, i18n } = useTranslation()

  return (
    <div css={containerStyle}>
      <div css={headerStyle}>
        <p css={hotelName}>
          {t('Reservation ID')}
          <span>{reservation.reservationId}</span>
        </p>
        <p css={date}>
          {_formatDate(reservation.checkinDate)} {reservation.nights}
          {t('Night')}
        </p>
      </div>
      <div css={title}>
        <div css={titleDate}>{t('Date')}</div>
        <div css={subject}>{t('Reference')}</div>
        <div css={usage}>{t('Charges')}</div>
        <div css={settlement}>{t('Adjusted amount')}</div>
      </div>
      <div>
        <ul>
          {reservation.sales.map((item, index) => (
            <li key={index} css={saleStyle}>
              <div css={serviceInfoStyle} className="titleDate">
                {_formatDate(item.salesDate)}
              </div>
              <div css={serviceInfoStyle} className="subject">
                {translateObject(item, 'salesSubjectName', i18n.language)}
              </div>
              <div css={serviceInfoStyle} className="usage">
                ¥{item.salesPrice}
              </div>
              <div css={serviceInfoStyle} className="settlement">
                ¥0
              </div>
            </li>
          ))}
        </ul>
        <div css={totalPayment}>
          <div css={salesPayment}>
            <p css={titlePayment}>{t('Amount used')}</p>
            <p css={priceStyle}>¥{reservation.totalPrice}</p>
          </div>
          <div css={salesPayment}>
            <p css={titlePayment}>{t('Credit')}</p>
            <p css={priceStyle}>¥{reservation.totalPayment}</p>
          </div>
          <div css={discount}>
            <p>
              ({t('Discount')}：¥{reservation.totalDiscount})
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const containerStyle = css({
  width: '100%',
  backgroundColor: '#fff',
  textAlign: 'left',
  padding: '30px 0 30px 0',
  borderRadius: '10px',
})

const headerStyle = css({
  textAlign: 'left',
  padding: '0 32px 24px',
})

const hotelName = css({
  color: '#272727',
  textAlign: 'left',
  height: 27,
  display: 'flex',
  alignItems: 'center',
  lineHeight: '19px',
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '8px',
  letterSpacing: '2.7px',
  span: {
    marginLeft: '21px',
    fontWeight: 'bold',
    display: 'inline-block',
    maxWidth: '260px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

const date = css({
  textAlign: 'left',
  color: '#676767',
  fontSize: '16px',
  letterSpacing: '2.4px',
  lineHeight: '19px',
  fontWeight: 'bold',
  height: 24,
})

const title = css({
  display: 'flex',
  backgroundColor: '#f5f5f5',
  height: '28px',
  alignItems: 'center',
  padding: '0 32px',
  fontSize: '12px',
  color: '#676767',
})

const commonStyle = css({
  fontWeight: 'bold',
  textAlign: 'left',
  overflowWrap: 'anywhere',
  lineHeight: '1.8px',
})
const titleDate = css([
  commonStyle,
  {
    width: '14.39%',
  },
])
const subject = css([
  commonStyle,
  {
    width: '20.42%',
  },
])

const usage = css([
  commonStyle,
  {
    width: '36.90%',
  },
])
const settlement = css([
  commonStyle,
  {
    width: '28.29%',
    textAlign: 'right',
  },
])

const saleStyle = css({
  display: 'flex',
  height: '56px',
  color: '#272727',
  fontSize: '16px',
  alignItems: 'center',
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  '.titleDate': {
    width: '14.39%',
  },
  '.subject': {
    width: '20.42%',
  },
  '.usage': {
    width: '36.90%',
  },
  '.settlement': {
    width: '28.29%',
    textAlign: 'right',
  },
})

const totalPayment = css({
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '1.8px',
  fontWeight: 'bold',
  textAlign: 'left',
  margin: ' 24px 32px 0',
})

const salesPayment = css({
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const titlePayment = css({
  color: '#272727',
  fontWeight: 'bold',
  fontSize: '16px',
  textAlign: 'left',
  letterSpacing: '2.4px',
})

const priceStyle = css([
  titlePayment,
  {
    fontSize: 18,
    textAlign: 'right',
  },
])

const discount = css({
  textAlign: 'right',
  color: '#676767',
  fontWeight: 'normal',
  fontSize: '14px',
})

const serviceInfoStyle = css({
  fontSize: '16px',
  letterSpacing: '2.4px',
  lineHeight: '19px',
  fontWeight: 'normal',
  color: '#272727',
})
