import React from 'react'
import { RHFInputField } from '@/components/molecules/RHFInput-field'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NewReservationInput } from '@/models/space-manager'

interface CustomerInfoInputsProps {
  useFormReturn: UseFormReturn<NewReservationInput>
}
export const CustomerInfoInputs: React.FC<CustomerInfoInputsProps> = ({ useFormReturn }) => {
  const { t } = useTranslation()

  const { control } = useFormReturn

  return (
    <div>
      <RHFInputField
        rules={{ required: true }}
        marginBottom={24}
        label={t('Full name')}
        placeholder={t('John Doe')}
        name="newReservation.name"
        control={control}
      />

      <RHFInputField
        rules={{ required: true }}
        marginBottom={24}
        label={t('Furigana')}
        placeholder={'ヤマダ タロウ'}
        name="newReservation.nameKana"
        control={control}
      />

      <RHFInputField
        rules={{ required: true }}
        marginBottom={24}
        label={t('Phone number')}
        placeholder="08012345678"
        name="newReservation.tel"
        control={control}
      />

      <RHFInputField
        rules={{ required: true }}
        marginBottom={24}
        label={t('Email address')}
        placeholder="aipass@aipass.jp"
        name="newReservation.email"
        control={control}
      />

      <RHFInputField
        rules={{ required: true }}
        marginBottom={24}
        label={t('Address')}
        placeholder={t('Address')}
        name="newReservation.address"
        control={control}
      />
    </div>
  )
}
