import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { AssetType } from '@/models/guest-app/asset'
import { ContainerHeader } from '@/components/molecules/settings/container-header'
import { ContainerSidePanelBody, Props as SidePanelProps } from '@/components/molecules/settings/container-side-panel-body'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { updateAsset, updateMobileRequestEmailOrder } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { NotificationInputType, NotificationType } from '@/models/mobile-request'
import { InputField } from '@/components/molecules/input-field'
import { Controller, useForm, useWatch } from 'react-hook-form'

type Props = {
  asset: AssetType & { notification: NotificationType }
}

export const RequestNotificationList: React.FC<Props & SidePanelProps> = ({ asset, menus }) => {
  const { t } = useTranslation()
  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<{ title: string; notification: NotificationInputType }>({
    mode: 'onChange',
    defaultValues: { title: '', notification: { guestAppAssetId: '', email_order: '', email_order_another: '' } },
  })
  const titleWatcher = useWatch({ control, name: 'title' })
  const emailPattern = {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: t('Email invalid'),
  }
  const { doReload, setIsLoading, setIsEditing, listenDoSave, listenDoCancel } = useContext(AssetDetailContext)
  const { errorHandler } = useErrorHandler()
  const saveFormValue = async () => {
    if (!isValid) {
      return
    }
    try {
      setIsLoading(true)
      await updateMobileRequestEmailOrder({ ...getValues().notification, guestAppAssetId: asset.id })
      await updateAsset(asset.id, { title: getValues().title })
      doReload()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (listenDoSave) saveFormValue()
  }, [listenDoSave])

  useEffect(() => {
    if (listenDoCancel) doReload()
  }, [listenDoCancel])

  useEffect(() => {
    if (!asset) {
      return
    }
    setValue('title', asset.title)
    setValue('notification.guestAppAssetId', asset.id)
    setValue('notification.email_order', asset.notification.email1)
    setValue('notification.email_order_another', asset.notification.email2)
  }, [asset])

  return (
    <>
      <ContainerHeader
        goBack
        title={titleWatcher}
        onChangeTitle={(newTitle: string) => {
          setValue('title', newTitle)
          setIsEditing(true)
        }}
      />
      <ContainerSidePanelBody menus={menus}>
        <div css={contentStyle}>
          <div css={inputTitleTextStyle}>{t('Email address')}</div>

          <div css={inputAreaStyle}>
            <div css={inputLabelTextStyle}>1.</div>
            <Controller
              name="notification.email_order"
              control={control}
              rules={{ pattern: emailPattern }}
              render={({ field }) => (
                <InputField
                  marginBottom={0}
                  width="427px"
                  placeholder="aipass@aipass.inc"
                  value={field.value}
                  handleChangeData={e => {
                    field.onChange(e)
                    setIsEditing(true)
                  }}
                  error={errors.notification?.email_order?.message}
                />
              )}
            />
          </div>

          <div css={inputAreaStyle}>
            <div css={inputLabelTextStyle}>2.</div>

            <Controller
              name="notification.email_order_another"
              control={control}
              rules={{ pattern: emailPattern }}
              render={({ field }) => (
                <InputField
                  marginBottom={0}
                  width="427px"
                  placeholder="aipass@aipass.inc"
                  value={field.value}
                  handleChangeData={e => {
                    field.onChange(e)
                    setIsEditing(true)
                  }}
                  error={errors.notification?.email_order_another?.message}
                />
              )}
            />
          </div>
        </div>
      </ContainerSidePanelBody>
    </>
  )
}
const contentStyle = css({
  padding: 32,
})

const inputAreaStyle = css({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: 16,
})

const inputTitleTextStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '1.2px',
  color: '#676767',
  paddingBottom: 16,
})

const inputLabelTextStyle = css({
  paddingRight: 16,
  color: '#272727',
  fontSize: 14,
})
