import React from 'react'
import { SerializedStyles, css } from '@emotion/core'

export type CheckboxProps = {
  value: boolean
  label?: string
  disabled?: boolean
  onChange?: (value: boolean) => void
  style?: SerializedStyles
}

export const Checkbox = ({ value, label, disabled, onChange, style }: CheckboxProps) => {
  const checkBoxImageName = () => {
    if (!value) {
      return 'check-box_off'
    }
    return !disabled ? 'check-box_yellow_on' : 'check-box_gray_on'
  }

  return (
    <div
      css={[fixCheckStyle, { cursor: !disabled ? 'pointer' : 'default' }, style]}
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        !disabled && onChange && onChange(!value)
      }}
    >
      <img src={require(`@/static/images/room/${checkBoxImageName()}.svg`)} css={{ backgroundColor: disabled ? '#f2f2f2' : undefined }} />
      <p css={{ color: disabled ? '#ccc !important' : undefined, fontWeight: 'bold' }}>{label}</p>
    </div>
  )
}

const fixCheckStyle = css({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  img: {
    marginRight: 8,
  },
  p: {
    fontSize: 12,
    letterSpacing: 0.6,
    color: '#676767',
  },
})
