import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { Controller, useForm } from 'react-hook-form'

// apis
import { fetchAdminAccount, updateAdminAccount } from '@/apis/aipass'

// components
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { EditFooter } from '@/components/organisms/edit-footer'
import { InputField } from '@/components/molecules/input-field'
import { TextareaField } from '@/components/molecules/textarea-field'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { BankAccountType, InvoiceAccountUpdateParameter } from '@/models/admin-account'
import { BankNoteTips } from '@/components/organisms/settings/account/bank-note-tips'

export const SettingAccountCompanyInfoEdit: React.FC = () => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm<InvoiceAccountUpdateParameter>({
    mode: 'all',
    defaultValues: {
      companyName: '',
      companyPostalCode: '',
      companyAddress: '',
      companyTelephone: '',
      bankName: '',
      bankBranchName: '',
      bankAccountType: BankAccountType.Saving,
      bankAccountNumber: '',
      bankAccountName: '',
      note: '',
    },
  })

  const onSaveData = async (val: InvoiceAccountUpdateParameter) => {
    try {
      setLoading(true)
      await updateAdminAccount({ invoiceAccount: val })
      history.push({ pathname: '/setting/account' })
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAdminAccount().then(res => {
      res?.invoiceAccount && reset(res.invoiceAccount)
    })
  }, [])

  const footerContent = <EditFooter onCancel={history.goBack} onSave={handleSubmit(onSaveData)} isDisabledSave={!isValid} />
  return (
    <SettingsLayout loading={loading} footerContent={footerContent}>
      <div css={mainContainerStyle}>
        <div className="-main-title">{t('Invoice')}</div>
        <div className="input-container">
          <div className="input-block">
            <div className="-input-label">
              {t('Company name')} <span>{t('*')}</span>
            </div>
            <Controller
              name="companyName"
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <InputField
                  marginBottom={0}
                  placeholder={t('Aipass Co Ltd')}
                  value={value}
                  name={name}
                  handleChangeData={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
          <div className="input-block -small-area">
            <div className="-input-label">
              {t('Post code')} <span>{t('*')}</span>
            </div>
            <Controller
              name="companyPostalCode"
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <InputField
                  marginBottom={0}
                  placeholder={'100-8111'}
                  value={value}
                  name={name}
                  handleChangeData={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
          <div className="input-block">
            <div className="-input-label">
              {t('Address')} <span>{t('*')}</span>
            </div>
            <Controller
              name="companyAddress"
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <InputField
                  marginBottom={0}
                  placeholder={t('Street Address, Apt/Suite (if any), City, State/Province')}
                  value={value}
                  name={name}
                  handleChangeData={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
          <div className="input-block">
            <div className="-input-label">
              {t('Phone number')} <span>{t('*')}</span>
            </div>
            <Controller
              name="companyTelephone"
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <InputField
                  marginBottom={0}
                  placeholder="08012345678"
                  value={value}
                  name={name}
                  handleChangeData={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>

        <div className="input-container">
          <div className="-sub-title">{t("Payee's bank account")}</div>
          <div className="input-block">
            <div className="-input-label">
              {t('Bank name')} <span>{t('*')}</span>
            </div>
            <Controller
              name="bankName"
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <InputField
                  marginBottom={0}
                  placeholder="◯◯銀行"
                  value={value}
                  name={name}
                  handleChangeData={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
          <div className="input-block">
            <div className="-input-label">
              {t('Bank branch name')} <span>{t('*')}</span>
            </div>
            <Controller
              name="bankBranchName"
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <InputField
                  marginBottom={0}
                  placeholder="◯◯支店"
                  value={value}
                  name={name}
                  handleChangeData={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
          <div className="input-block">
            <div className="-input-label">
              {t('Bank account type')} <span>{t('*')}</span>
            </div>
            <Controller
              name="bankAccountType"
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <RadioGroupField
                  value={value}
                  name={name}
                  onChange={onChange}
                  items={[
                    { value: 'saving', label: t('Saving account') },
                    { value: 'checking', label: t('Checking account') },
                  ]}
                  style={{ radioSize: 21, labelMargin: 12 }}
                  groupCss={radioGroupWrapperStyle}
                />
              )}
            />
          </div>
          <div className="input-block">
            <div className="-input-label">
              {t('Bank account number')} <span>{t('*')}</span>
            </div>
            <Controller
              name="bankAccountNumber"
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <InputField
                  marginBottom={0}
                  placeholder="1234567"
                  value={value}
                  name={name}
                  handleChangeData={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
          <div className="input-block">
            <div className="-input-label">
              {t('Bank account name')} <span>{t('*')}</span>
            </div>
            <Controller
              name="bankAccountName"
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <InputField
                  marginBottom={0}
                  placeholder="カ）アイパス"
                  value={value}
                  name={name}
                  handleChangeData={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>

        <div className="input-container">
          <div className="input-block -large-area">
            <div className="-input-label" style={{ display: 'flex', alignItems: 'center' }}>
              {t('Remarks')}
              <BankNoteTips />
            </div>
            <Controller
              name="note"
              control={control}
              render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                <TextareaField
                  marginBottom={0}
                  placeholder="振込手数料はお客様ご負担となります。"
                  value={value}
                  name={name}
                  handleChangeData={onChange}
                  rows={3}
                />
              )}
            />
          </div>
        </div>
      </div>
    </SettingsLayout>
  )
}

const mainContainerStyle = css({
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,

  '.-main-title': {
    letterSpacing: 1.8,
    color: '#272727',
    fontWeight: 'bold',
    fontSize: 18,
    padding: '16px 36px',
  },
  '.-sub-title': {
    letterSpacing: 1.6,
    color: '#272727',
    fontWeight: 'bold',
    fontSize: 16,
  },
  '.input-container': {
    borderTop: '1px solid #F2F2F2',
    padding: 32,
    '.input-block': {
      ':first-of-type': {
        paddingTop: 0,
      },
      width: '50%',
      '&.-small-area': {
        width: '10%',
      },
      '&.-large-area': {
        width: '100%',
      },
      paddingTop: 24,
      '.-input-label': {
        letterSpacing: 1.2,
        color: '#676767',
        fontWeight: 'bold',
        fontSize: 12,
        paddingBottom: 12,
        '> span': {
          position: 'absolute',
          fontSize: 8,
          paddingLeft: 4,
        },
      },
    },
  },
})

const radioGroupWrapperStyle = css({
  '> div:first-of-type': { marginRight: 28 },
})
