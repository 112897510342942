import React from 'react'
import { Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, ComposedChart } from 'recharts'
import { Line } from 'recharts'
import { DashboardType, SalesDetailType } from '@/models/sales-manager/dashboard'

type Props = {
  mode: DashboardType
  details: Array<SalesDetailType>
}

export const Graph: React.FC<Props> = ({ mode, details }) => {
  return (
    <ResponsiveContainer height={225} width="100%" style={{ fontSize: 12, marginTop: 28 }}>
      <ComposedChart data={details}>
        <CartesianGrid strokeDasharray="3 3" />
        {mode === DashboardType.Daily && <XAxis dataKey="salesDate" interval={1} />}
        {mode === DashboardType.Monthly && <XAxis dataKey="salesMonth" interval={0} />}
        <Legend verticalAlign="top" height={32} />
        <Tooltip />
        <YAxis yAxisId="price" orientation="right" label={{ value: '売上(万円)', position: 'top', offset: 16 }} />
        <YAxis yAxisId="OCC" orientation="left" label={{ value: 'OCC(%)', position: 'top', offset: 16 }} />
        <Line yAxisId="OCC" dataKey="OCCRate" stroke="#F47110" strokeWidth={2} name="OCC" unit="%" />
        <Area
          yAxisId="price"
          dataKey="price"
          name="売上"
          unit="万円"
          dot={true}
          stroke="#0017C1"
          strokeWidth={2}
          fill="#4979F5"
          fillOpacity={0.25}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}
