export const AccountsReceivable = {
  NonAccountsReceivable: 0,
  AccountsReceivable: 1,
}

export type AccountsReceivableSettingType = {
  closingDate: number
  channelCode: string
  channelName: string
  code: string
  id: string
  name: string
}
