import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

type ContainerProps = {
  telephone: string
  logoImage?: string
  frontOpenTime: string
  frontCloseTime: string
  marginBottom?: number
  position?: 'fixed' | 'static'
  usedIn: 'top' | 'roomGuidance'
}

export const SelfCheckinHotelInfo: React.FC<ContainerProps> = ({
  logoImage,
  telephone,
  frontOpenTime,
  frontCloseTime,
  marginBottom = 0,
  position = 'fixed',
  usedIn,
}) => {
  const { t } = useTranslation()
  const isUsedTop = usedIn === 'top'
  const styleDependsOnPage = isUsedTop ? topStyle : roomGuidanceStyle
  return (
    <div css={[footerStyle, styleDependsOnPage, { marginBottom, position }]}>
      <div css={footerContent}>
        <div css={isUsedTop ? borderStyleForTopStyle : []}>
          {logoImage ? <img src={logoImage} alt="" /> : <p css={hotelNameStyle}>{t('SelfCheckIn.Contact us')}</p>}
        </div>
        <div css={footerRight}>
          {telephone && <p css={hotelTelephone}>{telephone}</p>}
          {frontOpenTime && frontCloseTime && (
            <p css={ReceptionHours}>
              {t('Reception hours')} : {frontOpenTime}~{frontCloseTime}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

const footerStyle = css({
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  bottom: '0',
  width: '100%',
})
const topStyle = css({ paddingBottom: 0 })
const roomGuidanceStyle = css({
  color: '#676767',
  margin: '20px auto',
})
const hotelNameStyle = css({
  fontSize: 21,
  letterSpacing: 1,
  padding: '3px 26px',
  fontWeight: 'bold',
  '@media(max-width: 1080px)': {
    fontSize: 20,
  },
})
const hotelTelephone = css({
  fontSize: 20,
  letterSpacing: 1,
  height: 29,
  color: '#676767',
  textAlign: 'center',
  fontWeight: 'bold',
  width: 'fit-content',
})
const ReceptionHours = css({
  fontSize: 10,
  letterSpacing: 0,
  height: 15,
  width: 'fit-content',
})
const borderStyleForTopStyle = css({
  display: 'flex',
  alignItems: 'center',
  margin: 'auto 0',
  position: 'relative',
  paddingRight: 13,
  '&::after': {
    content: '""',
    display: 'block',
    width: 1,
    height: 36,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
    background: '#CCCCCC',
  },
})
const footerRight = css({
  padding: '0 21px',
  borderLeft: '1px solid #707070',
})
const footerContent = css({
  display: 'flex',
  margin: '0 auto',
  alignItems: 'center',
})
