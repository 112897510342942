import React, { useMemo } from 'react'
import { SerializedStyles, css } from '@emotion/core'
import { Cascader as AntCascader } from 'antd'
import { CascaderProps } from 'antd/lib/cascader'

type Props = {
  error?: string
  options: any
  customStyle?: SerializedStyles
}

export const Cascader: React.FC<Props & CascaderProps> = ({ error, options, customStyle, ...props }) => {
  const selectBoxStyle = useMemo(
    () =>
      css({
        width: '100%',
        '> input': {
          borderRadius: '16px',
          boxShadow: 'none !important',
          borderColor: error ? 'red !important' : '#CCCCCC !important',
          '&::placeholder': {
            color: '#272727',
          },
          padding: '0 16px',
        },
        '.ant-cascader-picker-label': {
          padding: '0 16px',
        },
        '.ant-cascader-picker-arrow': {
          right: 16,
          width: 10,
        },
      }),
    [error],
  )

  return (
    <div
      css={css(
        {
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          paddingBottom: 14,
        },
        customStyle,
      )}
    >
      <AntCascader
        css={selectBoxStyle}
        options={options}
        expandTrigger="hover"
        allowClear={false}
        suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
        {...props}
      />
      {!!error && <div css={{ fontSize: 10, color: 'rgba(255,0,0,0.5)', position: 'absolute', top: 36 }}>{error}</div>}
    </div>
  )
}
