import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { NoticeLoading } from '@/components/molecules/notice-loading'
import { AdvanceMoneyItemType } from '@/models/sales-manager/advance-money'
import { createAdvanceMoney, fetchSalesPayment, fetchSalesSubject } from '@/apis/aipass'
import { Button } from '@/components/atoms'
import { fetchSalesAccountsReceivable } from '@/apis/aipass'

type Props = {
  setIsOpenModal: () => void
  fetchAdvanceMoneyList: () => void
}

export const AdvanceMoneyCreateModal = ({ setIsOpenModal, fetchAdvanceMoneyList }: Props) => {
  const { t } = useTranslation()
  const advanceMoneyInitalValue: AdvanceMoneyItemType = {
    companyName: '',
    salesSubjectMasterId: '',
    paymentMethodMasterId: '',
    advanceMoney: '',
  }
  const [advanceMoneyItem, setAdvanceMoneyItem] = useState<AdvanceMoneyItemType>(advanceMoneyInitalValue)
  const [buttonDisable, setButtonDisable] = useState<boolean>(true)
  const [salesSubjectOptions, setSalesSubjectOptions] = useState<any[]>([])
  const [salesPaymentOptions, setSalesPaymentOptions] = useState<any[]>([])
  const [accountsReceivableOptions, setAccountsReceivableOptions] = useState<any[]>([])
  const [amountErrorMessage, setAmountErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const numberOnly = data => {
    // const digits = data.value.replace(/[^0-9]/g, '')
    if (!/^[1-9][0-9]*$/.test(data.value)) {
      setAmountErrorMessage(t('Please enter in single-byte number'))
    } else {
      setAmountErrorMessage('')
    }
    setAdvanceMoneyItem(advanceMoneyItem => ({ ...advanceMoneyItem, advanceMoney: data.value }))
  }

  const onChangeFieldValue = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target
    setAdvanceMoneyItem(advanceMoneyItem => ({ ...advanceMoneyItem, [name]: value }))
  }

  const onSaveData = async () => {
    try {
      setIsLoading(true)
      await createAdvanceMoney(advanceMoneyItem)
      fetchAdvanceMoneyList()
      setIsOpenModal()
    } catch (error) {
      alert(t('Communication failed'))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (
      advanceMoneyItem['companyName'].trim() &&
      advanceMoneyItem['salesSubjectMasterId'] &&
      advanceMoneyItem['advanceMoney'] &&
      advanceMoneyItem['paymentMethodMasterId'].trim() &&
      !amountErrorMessage
    ) {
      setButtonDisable(false)
    } else {
      setButtonDisable(true)
    }
  }, [advanceMoneyItem])

  const fetchSelectItemFromMaster = useCallback(async () => {
    setIsLoading(true)
    const salesSubjects = await fetchSalesSubject()

    const salesSubjectsWithoutSameSubjectCode = (salesSubjects || []).salesSubjects.reduce((prev, current) => {
      if (prev[prev.length - 1]?.subjectCode === current?.subjectCode && current?.subSubjectCode !== '-') {
        return prev
      } else {
        return [...prev, current]
      }
    }, [])
    setSalesSubjectOptions(salesSubjectsWithoutSameSubjectCode)

    const salesPayments = await fetchSalesPayment()
    setSalesPaymentOptions(salesPayments?.salesPayment || [])

    const { accountsReceivables } = await fetchSalesAccountsReceivable()
    setAccountsReceivableOptions(accountsReceivables || [])

    setIsLoading(false)
  }, [])

  useEffect(() => {
    fetchSelectItemFromMaster()
  }, [])

  return (
    <Modal>
      <ModalHeader>
        <div>{t('Add advance payment')}</div>
      </ModalHeader>
      <ModalBody>
        <NoticeLoading isLoading={isLoading} />
        <div css={modalMainStyle}>
          <div className="formLabel">
            <p>
              {t('Payee')} <sup>{t('*')}</sup>
            </p>
            <div css={formItem}>
              <input
                type="text"
                placeholder={t('Company name')}
                name="companyName"
                required={true}
                value={advanceMoneyItem?.companyName}
                onChange={onChangeFieldValue}
                maxLength={191}
              />
            </div>
          </div>
          <div className="formLabel">
            <p>
              {t('Earnings')} <sup>{t('*')}</sup>
            </p>
            <div css={formItem}>
              <select css={assignSelectStyle} name="salesSubjectMasterId" onChange={onChangeFieldValue}>
                <option value="">{t('Please select')}</option>
                {salesSubjectOptions.map(opt => (
                  <option key={opt.id} value={opt.id}>
                    {opt.subjectName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="formLabel">
            <p>
              {t('Method of payment')} <sup>{t('*')}</sup>
            </p>
            <div css={formItem}>
              <select css={assignSelectStyle} name="paymentMethodMasterId" onChange={onChangeFieldValue}>
                <option value="">{t('Please select')}</option>
                {salesPaymentOptions.map(opt => (
                  <option key={opt.id} value={opt.id}>
                    {opt.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="formLabel">
            <p>{t('Accounts receivable')}</p>
            <div css={formItem}>
              <select css={assignSelectStyle} name="salesAccountsReceivableId" onChange={onChangeFieldValue}>
                <option value="">{t('Please select')}</option>
                {accountsReceivableOptions.map(opt => (
                  <option key={opt.id} value={opt.id}>
                    {opt.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="formLabel">
            <p>
              {t('Initial amount')} <sup>{t('*')}</sup>
            </p>
            <div css={formItem}>
              <input
                type="tel"
                placeholder="000000"
                pattern="^[0-9]+$"
                name="advanceMoney"
                required={true}
                value={advanceMoneyItem?.advanceMoney}
                onChange={e => numberOnly(e.target)}
                className={amountErrorMessage ? 'borderDanger' : ''}
              />
            </div>
          </div>
          <p css={textDanger}>{amountErrorMessage}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType={3} width={110} height={38} marginTop={11} marginBottom={11} onClick={setIsOpenModal}>
          {t('Cancel')}
        </Button>
        <Button
          buttonType={buttonDisable ? 5 : 1}
          width={110}
          height={38}
          marginLeft={16}
          marginTop={11}
          marginBottom={11}
          onClick={() => onSaveData()}
          isDisabled={buttonDisable}
        >
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalMainStyle = css({
  backgroundColor: '#FFFFFF',
  borderRadius: '5px',
  border: '1px solid #cccccc',
  height: 'fit-content',
  margin: '-2px 0',
  padding: '20px 32px',

  '.formLabel': {
    width: '100%',
    padding: '12px 0',

    p: {
      fontSize: '12px',
      textAlign: 'left',
      letterSpacing: '0.6px',
      color: '#676767',
      fontFamily: '游ゴシック体',
      sup: {
        fontSize: '75%',
      },
    },
  },
})

const formItem = css({
  marginTop: '11px',

  input: {
    width: '100%',
    height: '32px !important',
    border: '1px solid #cccccc',
    borderRadius: '16px !important',
    fontSize: '14px !important',
    lineHeight: '21px !important',
    padding: '9px 16px !important',
    textAlign: 'left',
    backgroundColor: 'white',
  },

  'input.borderDanger': {
    borderColor: 'red',
  },

  'input, select, option': {
    letterSpacing: '1.4px',
  },

  'input::placeholder': {
    color: '#cccccc',
  },
})

const assignSelectStyle = css({
  width: '100%',
  height: '32px',
  border: '1px solid #cccccc',
  color: '#272727',
  borderRadius: '16px',
  fontSize: '14px',
  padding: '7px 16px',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/arrow_down_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '96% center',
  backgroundSize: 8,
  backgroundColor: '#FFFFFF',
  display: 'block',
})

const textDanger = css({
  color: 'red',
  fontSize: '12px',
  fontWeight: 'bold',
  letterSpacing: '0.9px',
})
