import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

type PagingProps = {
  page: number
  setPage?: (v: number) => void
  hasNext: boolean
  hasList: number
  listTotal: number | undefined
  limit: number
  isDisabled?: boolean
}

export const Pagination: React.FC<PagingProps> = ({ page, setPage, hasNext, hasList, listTotal, limit, isDisabled = false }) => {
  const { t } = useTranslation()
  const changePage = (add: number) => {
    if (setPage) {
      setPage(page + add)
    }
  }

  const _currentRow = () => {
    if (!hasNext && Number(page) === 1) {
      if (hasList === 0 || !hasList) {
        return 0
      }
      return `1-${hasList}`
    } else {
      return `${Number(page - 1) * limit + 1}-${hasNext ? Number(page) * limit : hasList + Number(page - 1) * limit}`
    }
  }

  return (
    <>
      <div css={pagingWrapperStyle}>
        <p>
          {_currentRow()} / {listTotal} {t('Rows')}
        </p>
        {page !== 1 && !isDisabled ? (
          <img onClick={() => changePage(-1)} src={require('@/static/images/arrow_left.svg')} css={pagingStyle} />
        ) : (
          <img src={require('@/static/images/arrow_disabled_left.svg')} css={disabledPagingStyle} />
        )}
        {hasNext && !isDisabled ? (
          <img onClick={() => changePage(+1)} src={require('@/static/images/arrow_right.svg')} css={pagingStyle} />
        ) : (
          <img src={require('@/static/images/arrow_disabled_right.svg')} css={disabledPagingStyle} />
        )}
      </div>
    </>
  )
}

const pagingWrapperStyle = css({
  fontWeight: 'bold',
  fontSize: 12,
  lineHeight: '23px',
  color: '#676767',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: 12,
  p: {
    marginRight: 8,
  },
  img: {
    width: 30,
    height: 30,
  },
})

const pagingStyle = css({
  transition: 'all 0.1s',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
})

const disabledPagingStyle = css({
  '&:hover': {
    cursor: 'defalut',
  },
})
