import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/ja'

// apis
import { fetchUserOrders, updateOrderStatus } from '@/apis/aipass'

// models
import { OrderType, OrderStatusType, ProductsType, OrderPaymentMethod } from '@/models/mobile-request/order'
import { useErrorHandler } from '@/hooks/use-error-handler'

type Props = {
  checkinId: string
  currentTab: 'reservationInfo' | 'memo' | 'request'
}

export const Request: React.FC<Props> = ({ checkinId, currentTab }) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [orders, setOrders] = useState<OrderType[]>([])
  const [singleDropDownOpenIndex, setSingleDropDownOpenIndex] = useState<any>(null)

  useEffect(() => {
    if (currentTab === 'request') {
      _fetchUserOrders()
    }
  }, [currentTab])

  const _updateOrderStatus = async (orderId: string, status: number, checkinId: string) => {
    try {
      const res: { orders: OrderType[] } = await updateOrderStatus(orderId, status, checkinId)
      setOrders(res?.orders)
      setSingleDropDownOpenIndex(null)
    } catch (error) {
      errorHandler(error)
    }
  }

  const triggerDropdownByKey = key => {
    const value = singleDropDownOpenIndex === null || singleDropDownOpenIndex !== key ? key : null
    setSingleDropDownOpenIndex(value)
  }

  const _fetchUserOrders = () => {
    fetchUserOrders(checkinId).then((res: { orders: OrderType[] }) => {
      setOrders(res?.orders)
    })
  }

  const _totalPrice = (order): string => {
    let total = 0
    order.products.forEach(v => {
      total += v.price * v.numberOfItems
    })
    return total.toLocaleString()
  }

  return (
    <div css={requestContainerStyle}>
      <div css={requestTitleStyle}>{t('Request list')}</div>
      <div css={requestLabelStyle}>
        <p css={dateStyle}>{t('Receipt date and time')}</p>
        <p css={contentsStyle}>{t('Content')}</p>
        <p css={totalPriceStyle}>{t('Total sales')}</p>
        <p css={paymentMethodStyle}>{t('Method of payment')}</p>
        <p css={roomeNumberStyle}>{t('Room number')}</p>
        <p>{t('Status')}</p>
      </div>
      <ul css={requestWrapperStyle}>
        {orders &&
          orders.map((order: OrderType, key) => {
            return (
              <li key={order.id} css={requestListStyle}>
                <p css={dateStyle}>{moment(order.createdAt).format(t('MM-DD HH:mm'))}</p>
                <div css={contentsStyle}>
                  {order.products &&
                    order.products.map((product: ProductsType) => {
                      return <div key={product.id}>{`${product.nameJa} x ${product.numberOfItems}`}</div>
                    })}
                </div>
                <p css={totalPriceStyle}>¥{_totalPrice(order)}</p>
                <p css={paymentMethodStyle}>{OrderPaymentMethod[order.paymentMethod]}</p>
                <div css={roomeNumberStyle}>{order.roomNumber}</div>
                <div css={statusWrapperStyle} onClick={() => triggerDropdownByKey(key)}>
                  {order.status === OrderStatusType.Uncompleted && (
                    <div css={notCompatibleStyle}>
                      <p>{t('Not compatible')}</p>
                      <img src={require('@/static/images/select.svg')} />
                    </div>
                  )}
                  {order.status === OrderStatusType.Completed && (
                    <div css={doneStyle}>
                      <p>{t('Done')}</p>
                      <img src={require('@/static/images/select_gray.svg')} />
                    </div>
                  )}
                  {singleDropDownOpenIndex === key && (
                    <div css={dropDownStyle}>
                      <ul>
                        <li css={dropDownContentStyle} onClick={() => _updateOrderStatus(order.id, 0, checkinId)}>
                          <span css={dropDownTitleStyle}>{t('Not compatible')}</span>
                        </li>
                        <li css={dropDownContentStyle} onClick={() => _updateOrderStatus(order.id, 1, checkinId)}>
                          <span css={dropDownTitleStyle}>{t('Done')}</span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

const requestContainerStyle = css({
  backgroundColor: '#FFF',
  borderRadius: 10,
  border: '1px solid #CCCCCC',
  overflow: 'hidden',
})

const requestTitleStyle = css({
  fontSize: 16,
  fontWeight: 'bold',
  color: '#272727',
  background: '#fff',
  padding: '19px 32px 15px',
})

const requestLabelStyle = css({
  height: 34,
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  fontWeight: 'bold',
  color: '#676767',
  background: '#F5F5F5',
  padding: '0 32px',
})

const textOverflowStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const dateStyle = css({
  width: '18%',
})

const contentsStyle = css({
  width: '27%',
})

const totalPriceStyle = css(textOverflowStyle, {
  width: '12%',
})

const paymentMethodStyle = css(textOverflowStyle, {
  width: '22%',
})

const roomeNumberStyle = css(textOverflowStyle, {
  width: '10%',
})

const requestWrapperStyle = css({
  minHeight: 185,
  overflow: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
})

const requestListStyle = css({
  display: 'flex',
  padding: '16px 32px',
  borderBottom: '1px solid #F2F2F2',
  fontSize: 14,
  letterSpacing: 0.7,
  lineHeight: '21px',
})

const statusWrapperStyle = css({
  position: 'relative',
})

const commonStyle = css({
  width: 116,
  height: 24,
  borderRadius: 5,
  fontSize: 12,
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '6px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  p: {
    marginRight: 24,
    marginLeft: 30,
    fontWeight: 'bold',
  },
  ':hover': {
    cursor: 'pointer',
  },
})

const notCompatibleStyle = css(commonStyle, {
  border: '1px solid #D39C3A',
  color: '#DD9914',
})

const doneStyle = css(commonStyle, {
  border: '1px solid #676767',
  color: '#676767',
})

const dropDownStyle = css({
  width: 112,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 30,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  fontSize: 14,
  fontWeight: 'normal',
  color: '#272727',
})
