import * as React from 'react'
import { css } from '@emotion/core'

type SigninEmailTextFieldProps = {
  name: string
  label: string
  value: string
  placeholder: string
  validEmail?: boolean
  emailOnChange: (email: string) => void
}

type SigninPasswordTextFieldProps = {
  name: string
  label: string
  value: string
  placeholder: string
  validPassword?: boolean
  passwordOnChange: (password: string) => void
}

export const SigninEmailTextField: React.FC<SigninEmailTextFieldProps> = ({
  name,
  label,
  value,
  placeholder,
  emailOnChange,
  validEmail,
}) => (
  <div css={signinTextFieldSectionStyle}>
    <label css={titleStyle}>{label}</label>
    <br />
    <input
      type="email"
      value={value}
      name={name}
      placeholder={placeholder}
      onChange={e => emailOnChange(e.target.value)}
      css={validEmail ? errorTextFiledStyle : textFiledStyle}
    />
  </div>
)

export const SigninPasswordTextField: React.FC<SigninPasswordTextFieldProps> = ({
  label,
  value,
  name,
  placeholder,
  passwordOnChange,
  validPassword,
}) => (
  <div css={signinTextFieldSectionStyle}>
    <label css={titleStyle}>{label}</label>
    <br />
    <input
      type="password"
      value={value}
      name={name}
      placeholder={placeholder}
      onChange={e => passwordOnChange(e.target.value)}
      css={validPassword ? errorTextFiledStyle : textFiledStyle}
    />
  </div>
)

const signinTextFieldSectionStyle = css({
  margin: '10px auto',
  textAlign: 'left',
  width: 275,
  display: 'block',
})

const titleStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  color: '#272727',
})

const textFiledStyle = css({
  width: '100%',
  height: 30,
  margin: '8px 0',
  padding: '0 16px',
  borderRadius: 22,
  border: '1px solid #CCCCCC',
  fontSize: 12,
  lineHeight: '44px',
  cursor: 'text',
  font: '-internal-light-dark(black, white)',
  '@media(min-width: 640px)': {
    fontSize: 14,
  },
  '&::placeholder': {
    color: '#CCCCCC',
  },
  '&:focus': {
    borderRadius: 22,
    border: '1px solid #F2A40B',
  },
})

const errorTextFiledStyle = css(textFiledStyle, {
  border: '1px solid #ff2700',
})
