import React from 'react'
import { createCustomerTagMaster, updateCustomerTagMaster } from '@/apis/aipass'
import { Button } from '@/components/atoms/button'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@/components/atoms/modal'
import { RHFInputField } from '@/components/molecules/RHFInput-field'
import { Label } from '@/components/molecules/label'
import { NoticeLoading } from '@/components/molecules/notice-loading'
import { ColorLabelType, CustomerTag, CustomerTagMasterType, tagColors } from '@/models/customer-manager/customer-tag'
import { css } from '@emotion/core'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckMark } from '@/static/images/customer-manager/icon_check_tag.svg'

interface TagModalProps {
  onClose: () => void
  editTagInfo?: CustomerTagMasterType
  onCancel: () => void
}

export const TagModal: React.FC<TagModalProps> = ({ onClose, editTagInfo, onCancel }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, isSubmitting, errors },
  } = useForm<CustomerTag>({
    defaultValues: {
      name: editTagInfo?.tag.name,
      bgColor: editTagInfo?.tag.bgColor,
      borderColor: editTagInfo?.tag.borderColor,
      fontColor: editTagInfo?.tag.fontColor,
      colorName: editTagInfo?.tag.colorName,
    },
  })

  const watchColorName = watch('colorName')

  const save: SubmitHandler<CustomerTag> = async submitData => {
    if (editTagInfo) {
      await updateCustomerTagMaster(submitData, editTagInfo.id)
    } else {
      await createCustomerTagMaster(submitData)
    }

    onClose()
  }

  return (
    <Modal customCss={{ height: 544, width: 600, minWidth: 600, left: 'calc(50% - 300px)' }}>
      <ModalHeader>{'顧客タグの作成'}</ModalHeader>
      <ModalBody>
        {isSubmitting && <NoticeLoading isLoading={isSubmitting} />}
        <div css={{ padding: 32, border: '1px solid #ddd', borderRadius: 8, backgroundColor: '#fff' }}>
          <RHFInputField
            required
            error={errors.name?.message}
            rules={{ required: true, maxLength: 10 }}
            name="name"
            control={control}
            label={'タグ名'}
          />

          <Label required label="カラー" />
          <ul style={{ display: 'flex', flexWrap: 'wrap', columnGap: 16, rowGap: 22, paddingRight: 40 }}>
            {Object.keys(tagColors).map(key => {
              const tagColor = tagColors[key as ColorLabelType]
              const selected = watchColorName === key

              return (
                <Controller
                  control={control}
                  name="bgColor"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <li key={field.value} style={{ flex: '0 0 65px', cursor: 'pointer', textAlign: 'center' }}>
                      <div
                        className={selected ? 'selected' : ''}
                        css={[
                          selectColorStyle,
                          { borderColor: selected ? tagColor.fontColor : tagColor.borderColor, background: tagColor.bgColor },
                        ]}
                        onClick={() => {
                          field.onChange(tagColor.bgColor)
                          setValue('colorName', key)
                          setValue('borderColor', tagColor.borderColor)
                          setValue('fontColor', tagColor.fontColor)
                        }}
                      >
                        <CheckMark fill={tagColor.fontColor} />
                      </div>
                      <p style={{ color: tagColor.fontColor, fontSize: 12, fontWeight: 'bold' }}>{t(`customerTag.${key}`)}</p>
                    </li>
                  )}
                />
              )
            })}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(save)}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const selectColorStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 65,
  height: 65,
  marginBottom: 12,
  border: '1px solid',
  borderRadius: '50%',
  svg: {
    display: 'none',
  },
  '&.selected': {
    borderWidth: '2px',
    svg: {
      display: 'block',
    },
  },
})
