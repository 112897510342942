import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// components
import { Button } from '@/components/atoms/button'
import { salesMainContainerStyle } from '@/components/pages/sales-manager/sales/_detail/sales'

type ConfirmModalProps = {
  salesTotalDetail: any
  mode: 'month' | 'daily'
  onClickModalConfirm: (date: string, startDatePeriod: string) => void
  onClickModalCancel: () => void
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({ salesTotalDetail, mode, onClickModalConfirm, onClickModalCancel }) => {
  const { t } = useTranslation()
  const isUnpaid = salesTotalDetail?.totalUnpaid > 0

  const startDatePeriod = salesTotalDetail?.confirmedLastTime
    ? dayjs(salesTotalDetail.confirmedLastTime)
        .add(1, 'second')
        .format('YYYY-MM-DD H:mm:ss')
    : salesTotalDetail?.confirmedLastTime
  return (
    <div css={modalWrapperStyle}>
      <div css={modalContentStyle}>
        <p css={modalTitleStyle}>{t('Are you sure about the following contents')}</p>
        <div css={confirmContentStyle}>
          {mode === 'daily' ? (
            <p css={confirmContentDateStyle}>{dayjs(salesTotalDetail?.salesDate).format(t('MM-DD-YYYY'))}</p>
          ) : (
            <p css={confirmContentDateStyle}>{dayjs(salesTotalDetail?.salesMonth).format(t('YYYY-MM'))}</p>
          )}
          <dl>
            <div css={confirmContentItemStyle}>
              <dt css={confirmContentItemTermStyle}>{t('Earnings')}</dt>
              <dd css={confirmContentItemTextStyle}>¥{salesTotalDetail?.totalSales?.toLocaleString() || '-'}</dd>
            </div>
            <div css={confirmContentItemStyle}>
              <dt css={confirmContentItemTermStyle}>{t('Payment')}</dt>
              <dd css={confirmContentItemTextStyle}>¥{salesTotalDetail?.totalPayment?.toLocaleString() || '-'}</dd>
            </div>
            <div css={confirmContentItemStyle}>
              <dt css={isUnpaid ? confirmContentItemUnpaidTermStyle : confirmContentItemTermStyle}>{t('Amount to be collected')}</dt>
              <dd css={isUnpaid ? confirmContentItemUnpaidTextStyle : confirmContentItemTextStyle}>
                {isUnpaid || salesTotalDetail?.totalUnpaid === 0 ? '' : '-'}¥{Math.abs(salesTotalDetail?.totalUnpaid).toLocaleString()}
              </dd>
            </div>
          </dl>
        </div>
        <div css={footerButtonWrapperStyle}>
          <Button width={185} height={38} marginRight={30} buttonType={3} onClick={onClickModalCancel}>
            {t('Cancel')}
          </Button>
          <Button
            width={185}
            height={38}
            buttonType={1}
            onClick={() => onClickModalConfirm(dayjs(salesTotalDetail?.salesDate).format('YYYY-MM-DD'), startDatePeriod)}
          >
            {t('Confirm')}
          </Button>
        </div>
      </div>
    </div>
  )
}

const modalWrapperStyle = css(salesMainContainerStyle, {
  width: '100vw',
  height: '100vh',
  background: 'rgba(39, 39, 39, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 103,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const modalContentStyle = css({
  width: 688,
  height: 383,
  padding: '45px 0',
  background: '#fff',
  borderRadius: 5,
})

const modalTitleStyle = css({
  marginBottom: 27,
  fontSize: 18,
  letterSpacing: 2.7,
  textAlign: 'center',
  fontWeight: 'bold',
})

const confirmContentStyle = css({
  width: 621,
  padding: 32,
  margin: '0 auto 30px',
  background: '#f5f5f5',
  border: '1px solid #ccc',
})

const confirmContentDateStyle = css({
  marginBottom: 16,
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: 2.7,
})

const confirmContentItemStyle = css({
  marginBottom: 17,
  display: 'flex',
  '&:last-child': {
    marginBottom: 0,
  },
})

const confirmContentItemTextStyle = css({
  fontSize: 16,
  letterSpacing: 2.4,
})

const confirmContentItemTermStyle = css(confirmContentItemTextStyle, {
  width: 100,
})

const unpaidStyle = css({
  color: '#ff5500',
})

const confirmContentItemUnpaidTermStyle = css(confirmContentItemTermStyle, unpaidStyle)

const confirmContentItemUnpaidTextStyle = css(confirmContentItemTextStyle, unpaidStyle)

const footerButtonWrapperStyle = css({
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
})
