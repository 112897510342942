export const FormatType = {
  Classification: 'classification',
  SalesByDepartment: 'salesByDepartment',
  Payment: 'payment',
} as const
export type FormatType = (typeof FormatType)[keyof typeof FormatType]

export const SystemType = {
  MoneyForward: 'moneyForward',
  Freee: 'freee',
} as const
export type SystemType = (typeof SystemType)[keyof typeof SystemType]
