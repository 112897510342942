import { t } from 'i18next'

export const BankAccountType = {
  Saving: 'saving',
  Checking: 'checking',
} as const
export type BankAccountType = (typeof BankAccountType)[keyof typeof BankAccountType]
export const BankAccountTypeLabel: Record<BankAccountType, string> = {
  [BankAccountType.Saving]: t('Saving account'),
  [BankAccountType.Checking]: t('Checking account'),
}

interface Employee {
  email: string
  hotelId: string
  id: string
  language: string
  name: string
  role: string
}
interface Hotel {
  address: string
  checkinBeginTime: string
  checkinEndTime: string
  checkoutBeginTime: string
  checkoutEndTime: string
  email: string
  emailOrder: string
  emailOrderAnother: string
  id: string
  name: string
  nameLatin: string
  postalCode: string
  telephone: string
}
export interface InvoiceAccount {
  companyName: string
  companyPostalCode: string
  companyAddress: string
  companyTelephone: string
  bankName: string
  bankBranchName: string
  bankAccountType: BankAccountType
  bankAccountNumber: string
  bankAccountName: string
  note: string
}

export type AdminAccount = {
  employee: Employee
  hotel: Hotel
  invoiceAccount: InvoiceAccount | null
}

export interface InvoiceAccountUpdateParameter {
  companyName: string
  companyPostalCode: string
  companyAddress: string
  companyTelephone: string
  bankName: string
  bankBranchName: string
  bankAccountType: BankAccountType
  bankAccountNumber: string
  bankAccountName: string
  note: string
}

export type AdminAccountUpdateParameter = {
  invoiceAccount?: InvoiceAccountUpdateParameter
}
