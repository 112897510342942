import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

import { Hint } from '@/components/atoms/hint'

export const BankNoteTips: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Hint customCss={hotelInvoiceAccountRemarksPopupStyle}>
      <div className="popup-content-block">
        <div className="text">{t('It appears in the remarks field of all invoices issued.')}</div>
        <div className="example-block">
          <div>備考</div>
          <div className="preview-note-block">
            <div>【振込先】〇〇銀行 〇〇支店</div>
            <div>普通 0000000 カ) アイパス</div>
            <div>振込手数料はお客様ご負担となります。</div>
          </div>
        </div>
      </div>
    </Hint>
  )
}

const hotelInvoiceAccountRemarksPopupStyle = css({
  paddingLeft: 8,
  height: 18,
  position: 'relative',
  '.help-icon': { width: 18, height: 18 },
  '.help-popup': {
    left: 'calc(18px + 16px)',
    top: -132,
    width: 376,
    position: 'absolute',
    '.popup-content-block': {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      '.text': {
        fontSize: 12,
        letterSpacing: 1.2,
        fontWeight: 'bold',
        color: '#676767',
      },
      '.example-block': {
        padding: '16px 16px 0 16px',
        fontSize: 9,
        fontWeight: 'bold',
        height: '100%',
        '.preview-note-block': {
          marginTop: 6,
          padding: 8,
          border: '1px solid #707070',
          height: '100%',
          lineHeight: '12px',

          '> div:last-of-type': {
            marginTop: 12,
            border: '1px solid #F47110',
            borderRadius: 7,
            padding: 3,
            width: 'fit-content',
          },
        },
      },
    },
  },
})
