import { useCallback, useState, Dispatch, SetStateAction } from 'react'

// apis
import { fetchGuestRoomForReservation } from '@/apis/aipass'

// models
import { GuestRoomAssignResponseType } from '@/models/room-manager/response/guest-room-assign'

type UseEditGuestRoomsType = [
  GuestRoomAssignResponseType['rooms'],
  Dispatch<SetStateAction<GuestRoomAssignResponseType['rooms']>>,
  (reservationId: string, hotelId: string) => Promise<GuestRoomAssignResponseType['rooms']>,
]

// Room list to edit
export function useEditGuestRooms(): UseEditGuestRoomsType {
  const [editGuestRooms, setEditGuestRooms] = useState<GuestRoomAssignResponseType['rooms']>([])

  const handleFetchGuestRooms = useCallback(async (reservationId: string, hotelId: string) => {
    return await fetchGuestRoomForReservation(reservationId, hotelId).then((guestRooms: GuestRoomAssignResponseType['rooms']) => {
      if (!guestRooms) {
        return []
      }
      setEditGuestRooms(guestRooms)
      return guestRooms
    })
  }, [])

  return [editGuestRooms, setEditGuestRooms, handleFetchGuestRooms]
}
