import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

import { Hint } from '@/components/atoms/hint'

export const InvoiceStatusTips: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Hint customCss={hotelInvoiceAccountRemarksPopupStyle}>
      <div className="popup-content-block">
        <div className="text">{t("If a payment is past due, it automatically switches to 'Not collected'")}</div>
      </div>
    </Hint>
  )
}

const hotelInvoiceAccountRemarksPopupStyle = css({
  paddingLeft: 8,
  height: 18,
  position: 'relative',
  '.help-icon': { width: 18, height: 18 },
  '.help-popup': {
    left: 'calc(18px + 16px)',
    top: 0,
    width: 376,
    position: 'absolute',
    '.popup-content-block': {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      '.text': {
        fontSize: 12,
        letterSpacing: 1.2,
        fontWeight: 'bold',
        color: '#676767',
        whiteSpace: 'pre-wrap',
        lineHeight: '20px',
      },
    },
  },
})
