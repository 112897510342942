import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms'
import { useTranslation } from 'react-i18next'
import { MailDeliveryHistory } from '@/models/mail-delivery/mail-delivery-history'
import { css } from '@emotion/core'
import parse from 'html-react-parser'
import { approvedStatusValues } from '@/models/reservation-approved-status'
import dayjs from 'dayjs'
import { blockEndTag, blockStartTag } from '@/components/molecules/settings/editor/custom-blockquote'

interface HistoryModalProps {
  onClose: () => void
  mailDeliveryHistory: MailDeliveryHistory
}

export const HistoryModal: React.FC<HistoryModalProps> = ({ onClose, mailDeliveryHistory }) => {
  const { t } = useTranslation()

  const channelCodeNames = mailDeliveryHistory.channelCodes.map(code => code.name)

  return (
    <Modal customCss={modalStyle}>
      <ModalHeader>{t('Delivery Contents')}</ModalHeader>
      <ModalBody customCss={modalBodyStyle}>
        <div className="modal-inner">
          <div className="block block-1">
            <dl>
              <dt>{t('mailDelivery.title')}</dt>
              <dd className="title">{mailDeliveryHistory.title}</dd>
            </dl>

            <dl>
              <dt>{t('mailDelivery.body')}</dt>
              <dd css={mailContentStyle}>
                {parse(mailDeliveryHistory.content.replaceAll(new RegExp(`${blockStartTag}|${blockEndTag}`, 'g'), ''))}
              </dd>
            </dl>
          </div>

          <div className="block block-2">
            <dl>
              <dt>{t('delivery target')}</dt>
              <dd>
                <p>{`${t('Status')} : ${mailDeliveryHistory.reservationApprovedStatuses
                  .map(status => approvedStatusValues[status])
                  .join('、')}`}</p>
                <p>{`${t('Reservation route')} : ${(mailDeliveryHistory.isNullSearchableChannelCode
                  ? ['-', ...channelCodeNames]
                  : channelCodeNames
                ).join('、')}`}</p>
              </dd>
            </dl>

            <dl>
              <dt>{t('Delivery Date and time')}</dt>
              <dd>{dayjs(mailDeliveryHistory.sentAt).format('YYYY年MM月DD日 HH:mm')}</dd>
            </dl>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType={3} height="38px" width="110px" onClick={onClose}>
          {t('Close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = css({
  left: 'calc(50% - 450px)',
  width: '100%',
  maxWidth: 900,
})

const modalBodyStyle = css({
  '.modal-inner': {
    display: 'flex',
    background: '#fff',
    border: '1px solid #CCCCCC',
    borderRadius: 8,
  },
  '.block': {
    padding: 24,
    maxHeight: 502,
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '&-1': {
      width: '66%',
      borderRight: '1px solid #F2F2F2',
    },
    '&-2': {
      width: '34%',
    },
  },
  dl: {
    fontSize: 12,
  },
  dt: {
    marginBottom: 12,
    fontWeight: 'bold',
  },
  dd: {
    lineHeight: 1.5,
  },
  'dl + dl': {
    marginTop: 24,
  },
  '.title': {
    fontSize: 14,
    fontWeight: 'bold',
  },
})

export const mailContentStyle = css({
  h1: {
    fontSize: 20,
    fontWeight: 'bold',
    margin: '16px 0 8px',
    span: {
      fontWeight: 'bold',
    },
  },
  h2: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: '16px 0 8px',
    span: {
      fontWeight: 'bold',
    },
  },
  'ul ,ol': {
    margin: '0 32px',
  },
  ul: {
    li: {
      listStyleType: 'disc',
    },
  },
  ol: {
    li: {
      listStyleType: 'decimal',
    },
  },
  u: {
    textDecoration: 'underline',
  },
  img: {
    width: '100%',
    maxWidth: 800,
  },
  '.custom-design-link': {
    color: '#A69785',
    textDecoration: 'underline',
  },
  '.custom-design-button': {
    background: '#A59785',
    color: '#FFFFFF',
    borderRadius: 6,
    height: 48,
    display: 'block',
    textAlign: 'center',
    lineHeight: '48px',
    margin: '32px 0',
  },
  '.custom-design-block': {
    margin: '12px 0',
    padding: 16,
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    background: '#F5F5F5',
  },
})
