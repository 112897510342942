import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { AssetType } from '@/models/guest-app/asset'
import { Controller, useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'
import { InputImage } from '@/components/molecules/settings/input-image'
import { postMobileRequestCategories, putMobileRequestCategories } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { TextareaField } from '@/components/molecules/textarea-field'
import { CategoryInputType, CategoryType } from '@/models/mobile-request'

type Props = {
  asset: AssetType
  detail: CategoryType | null
  onCancel: () => void
  onSave: () => void
}

export const RequestCategoryDetailModal: React.FC<Props> = ({ asset, detail, onCancel, onSave: parentSave }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<CategoryInputType>({
    defaultValues: {
      name: detail?.name.ja || '',
      imagePath: detail?.imagePath || '',
      guestAppAssetId: asset.id,
      order: detail?.order || '',
    },
  })

  const { doReload, setIsLoading } = useContext(AssetDetailContext)
  const { errorHandler } = useErrorHandler()
  const [isShowCropModal, setIsShowCropModal] = useState<boolean>(false)

  const onSave = async (value: CategoryInputType) => {
    try {
      setIsLoading(true)
      if (detail) {
        await putMobileRequestCategories(detail.id, value)
      } else {
        await postMobileRequestCategories(value)
      }
      doReload()
      parentSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal customCss={modalStyle} style={{ visibility: isShowCropModal ? 'hidden' : 'visible' }}>
        <ModalHeader>{t('MobileRequest.Create category')}</ModalHeader>
        <ModalBody>
          <div css={modalBodyStyle}>
            <div>
              <div css={inputTitleTextStyle}>
                {t('Title')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <div css={[textareaStyle, { textarea: { height: 76 } }]}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: t('Required field has not been entered') }}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <TextareaField
                      marginBottom={0}
                      placeholder={t('Title name')}
                      value={value}
                      name={name}
                      handleChangeData={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </div>

            <Controller
              name="imagePath"
              control={control}
              rules={{ required: t('Please select {{item}}', { item: t('Image') }) }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <InputImage
                  customStyle={inputAreaStyle}
                  value={value}
                  onChange={onChange}
                  onShowModal={setIsShowCropModal}
                  aspect={16 / 9}
                  error={error?.message}
                />
              )}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(onSave)}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const modalStyle = css({
  top: '5%',
  left: 'calc(50% - 300px)',
  minHeight: 565,
  maxHeight: 565,
  minWidth: 600,
  width: 600,
})

const modalBodyStyle = css({
  padding: 32,
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  minHeight: '100%',
})

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputAreaStyle = css({
  paddingTop: 24,
  display: 'flex',
})

const textareaStyle = css({
  textarea: {
    marginBottom: 0,
    padding: '16px',
    lineHeight: 1.5,
    letterSpacing: '1.4px',
    ':focus': {
      padding: '15px',
    },
  },
})
