import React, { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import { css } from '@emotion/core'
import 'dayjs/locale/ja'
import 'react-dates/initialize'

import {
  mainContainerHeaderStyle,
  headerBarLeftWrapperStyle,
  tableHeaderThStyle,
  tableBodyTdStyle,
  tableBodyTdStyleRead,
  tableBodyTdStyleUnread,
} from '@/constants/layout'

import {
  guestNameUnreadEnStyle,
  guestNameUnreadJaStyle,
  guestNameReadEnStyle,
  guestNameReadJaStyle,
  guestNameStyle,
  guestNameKanaStyle,
  companionStyle,
  reservationRoomNumbersStyle,
  selfCheckinTimeStyle,
  reloadWrapperStyle,
} from '@/components/pages/accommodation-management/list-layout'

// apis
import { putAdminReservationApprovedStatus } from '@/apis/aipass'

// components
import { Button } from '@/components/atoms/button'
import { ReloadButton } from '@/components/atoms/reload-button'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { DetailedReserchPanel } from '@/components/pages/accommodation-management/_stay/detailed-reserch-panel'
import { getSearchedConditions } from '@/components/pages/accommodation-management/search-condition'

// libs
import { dividedRoomNumber, dividedTypeName } from '@/libs/divide-list'
import { useErrorHandler } from '@/hooks/use-error-handler'

// models
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { useSearchedConditions } from '@/hooks/use-search-condition'

type ContainerProps = {
  reservationList: any
  setPage: (v: number) => void
  page: number
  refresh: () => void
  hasList: number
  listTotal: number
  limit: number
  pagingIsDisabled: boolean
  isStatusApproved
  setIsStatusApproved
  onClickReserch: any
  onClickClearReserch: any
  detailedReserch: any
  setDetailedReserch: any
}

export const StayList: React.FC<ContainerProps> = ({
  reservationList,
  page,
  setPage,
  refresh,
  hasList,
  listTotal,
  limit,
  pagingIsDisabled,
  isStatusApproved,
  setIsStatusApproved,
  onClickReserch,
  onClickClearReserch,
  detailedReserch,
  setDetailedReserch,
}) => {
  const windows = useWindowSize()
  const { t, i18n } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [isDropDownReserchPanelOpen, setIsDropDownReserchPanelOpen] = useState<boolean>(false)
  const [checkedStatusApproved] = useState<boolean>(false)
  const [checkedReservationIds, setCheckedReservationIds] = useState<object>({})
  const [checkedAllFlag, setCheckedAllFlag] = useState<boolean>(false)
  const [checkedTotalCount, setCheckedTotalCount] = useState<number>(0)

  const searchedConditions = getSearchedConditions()
  const { SearchedConditionValues } = useSearchedConditions({ searchedConditions })

  // Checkbox when status update (single) is selected
  const handleCheckboxChange = (e, index) => {
    // Empty if already checked
    let newValue = ''
    if (e.target.value !== checkedReservationIds[index]) {
      newValue = e.target.value
      const newIds = { ...checkedReservationIds, [index]: newValue }
      setCheckedReservationIds(newIds)
    }

    if (e.target.value === checkedReservationIds[index]) {
      delete checkedReservationIds[index]
      const newIds = { ...checkedReservationIds, [index]: newValue }
      setCheckedReservationIds(newIds)
    }
    // EX) When checking 0 and 2 (when checking 1 and unchecking) {0: '123', 1: '', 2: '234'}
  }

  // Checkbox when status update (whole) is selected
  const handleAllCheckboxChange = index => {
    if (!checkedAllFlag) {
      const allIds = reservationList.map((v: any) => {
        return v.reservationId
      })
      setCheckedAllFlag(true)
      setCheckedReservationIds(allIds)
    } else {
      setCheckedAllFlag(false)
      setCheckedReservationIds([])
    }
  }

  // Push booking id and approvedStatus on status update
  const onSubmit = async () => {
    try {
      // Format the array, excluding if it is empty
      const reservationIds: string[] = []

      Object.keys(checkedReservationIds).forEach(function (key) {
        if (checkedReservationIds[key] !== '') {
          reservationIds.push(checkedReservationIds[key])
        }
      })

      await putAdminReservationApprovedStatus({ reservationIds: reservationIds, approvedStatus: ApprovedStatusType.Checkout })

      refresh()
    } catch (error) {
      refresh()
      errorHandler(error)
    }
  }

  // Number of status change checks
  useEffect(() => {
    let count = 0
    Object.keys(checkedReservationIds).forEach(key => {
      if (checkedReservationIds[key] !== '') {
        count++
      }
    })
    setCheckedTotalCount(count)
    if (count === 0) {
      setCheckedAllFlag(false)
    }
    if (count > 0 && count === hasList) {
      setCheckedAllFlag(true)
    } else {
      setCheckedAllFlag(false)
    }
  }, [checkedReservationIds])

  return (
    <>
      <div css={mainContainerHeaderStyle}>
        <div css={headerBarLeftWrapperStyle}>
          {!isStatusApproved && (
            <>
              <div>
                <Button
                  buttonType={4}
                  width={i18n.language === 'en' ? 118 : 90}
                  height={32}
                  fontSize={12}
                  onClick={() => setIsDropDownReserchPanelOpen(!isDropDownReserchPanelOpen)}
                >
                  {t('Detailed search')}
                </Button>
                {isDropDownReserchPanelOpen && (
                  <DetailedReserchPanel
                    onClickReserch={onClickReserch}
                    onClickClearReserch={onClickClearReserch}
                    setIsDropDownReserchPanelOpen={() => setIsDropDownReserchPanelOpen(!isDropDownReserchPanelOpen)}
                    detailedReserch={detailedReserch}
                    setDetailedReserch={setDetailedReserch}
                  />
                )}
              </div>
              <div css={reloadWrapperStyle}>
                <ReloadButton onClick={() => refresh()} />
              </div>

              <SearchedConditionValues />
            </>
          )}
          {isStatusApproved && (
            <>
              <div css={selectedNumberStyle}>
                {checkedTotalCount} {t('item selected')}
              </div>
              <Button
                buttonType={3}
                width={164}
                height={32}
                fontSize={12}
                paddingLeft={16}
                paddingRight={16}
                marginLeft={24}
                onClick={() => {
                  onSubmit()
                  setIsStatusApproved(false)
                  setCheckedReservationIds({})
                  setCheckedAllFlag(false)
                }}
              >
                {t('Change to checkout')}
              </Button>
              <Button
                buttonType={4}
                width={99}
                height={32}
                fontSize={12}
                paddingLeft={16}
                paddingRight={16}
                marginLeft={8}
                onClick={() => {
                  setIsStatusApproved(false)
                  setCheckedReservationIds({})
                  setCheckedAllFlag(false)
                }}
              >
                {t('Cancel')}
              </Button>
            </>
          )}
        </div>
        <AccommodationPaging page={page} setPage={setPage} total={listTotal} limit={limit} isDisabled={pagingIsDisabled} />
      </div>
      <div css={{ overflow: 'auto', height: windows.height - 218 }}>
        <table css={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              {isStatusApproved && (
                <th css={[tableHeaderThStyle, { paddingLeft: 32 }]}>
                  <input
                    id="checkboxAll"
                    alt="checkbox"
                    type="image"
                    src={require(`@/static/images/check-box${checkedAllFlag === true ? '' : '_off'}.svg`)}
                    onClick={index => handleAllCheckboxChange(index)}
                  />
                </th>
              )}
              <th css={[tableHeaderThStyle, !isStatusApproved ? { paddingLeft: 32 } : {}]}>{t('Guest')}</th>
              <th css={tableHeaderThStyle}>{t('Reservation ID')}</th>
              <th css={tableHeaderThStyle}>{t('Room type')}</th>
              <th css={tableHeaderThStyle}>{t('Room number')}</th>
              <th css={tableHeaderThStyle}>{t('Date of stay')}</th>
            </tr>
          </thead>
          <tbody>
            {reservationList &&
              reservationList.map((v: any, index) => {
                return (
                  <Link
                    key={index}
                    css={[
                      v.stayRead ? tableBodyTdStyleRead : tableBodyTdStyleUnread,
                      { display: 'table-row', ':hover': { color: '#000' } },
                    ]}
                    to={`/stay/${v.checkinId}${location.search + `&reservationId=${v.reservationId}`}`}
                  >
                    {isStatusApproved && (
                      <td css={[tableBodyTdStyle, { paddingLeft: 32 }]} onClick={e => e.stopPropagation()}>
                        <input
                          id="reservationId"
                          alt="checkboxList"
                          value={v.reservationId}
                          type="image"
                          src={require(
                            `@/static/images/check-box${
                              checkedReservationIds[index] === v.reservationId || checkedStatusApproved ? '' : '_off'
                            }.svg`,
                          )}
                          onClick={e => {
                            e.preventDefault()
                            handleCheckboxChange(e, index)
                          }}
                        />
                      </td>
                    )}
                    <td css={[tableBodyTdStyle, !isStatusApproved ? { paddingLeft: 32 } : {}]}>
                      <div
                        css={() => {
                          if (!v.stayRead) {
                            return i18n.language === 'en' ? guestNameUnreadEnStyle : guestNameUnreadJaStyle
                          } else {
                            return i18n.language === 'en' ? guestNameReadEnStyle : guestNameReadJaStyle
                          }
                        }}
                      >
                        <div css={guestNameKanaStyle}>{v.guestNameKana ? v.guestNameKana : '-'}</div>
                        <div css={guestNameStyle}>{v.guestName ? v.guestName : '-'}</div>
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 200 }]}>
                      <div css={companionStyle}>{v.reservationId ? v.reservationId : '-'}</div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 200 }]}>
                      <div css={reservationRoomNumbersStyle}>{dividedTypeName(v.rooms)}</div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 200 }]}>
                      <div css={reservationRoomNumbersStyle}>{dividedRoomNumber(v.rooms)}</div>
                    </td>
                    <td css={[tableBodyTdStyle]}>
                      <div css={selfCheckinTimeStyle}>
                        {v.checkinDate === null ? (
                          '-'
                        ) : (
                          <>
                            {dayjs(v.checkinDate).format(t('YYYY-MM-DD'))}
                            <span style={{ paddingLeft: 5 }}>
                              {v.nights ?? 0}
                              {t('Night')}
                            </span>
                          </>
                        )}
                      </div>
                    </td>
                  </Link>
                )
              })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const selectedNumberStyle = css({
  fontSize: 14,
  fontWeight: 'bold',
  margin: 'auto',
})
