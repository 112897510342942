import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'

// contexts
import { AccountContext } from '@/contexts/account'

// apis
import { deleteCustomCheckin, fetchCustomCheckin, updateViewStatus } from '@/apis/aipass'

// constants
import { listContainerStyle } from '@/constants/layout'

// components
import { CustomCheckinList } from '@/components/pages/setting/custom-checkin/list'
import { SettingsLayout } from '@/components/layouts/settings-layout'

type ContainerProps = {}

type Props = {
  questions: any
  isLoading: boolean
  goToDetail: (id?: string) => void
  updateViewStatus: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void
  deleteCustomCheckin: (id: string, e: React.ChangeEvent<HTMLInputElement>) => void
}

export const SettingCustomCheckin: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [questions, setQuestions] = useState<any>([])

  const _fetchCustomCheckin = async () => {
    await fetchCustomCheckin()
      .then(res => {
        setQuestions(res?.customCheckin)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  useEffect(() => {
    if (account && account?.hotel) {
      _fetchCustomCheckin()
    }
  }, [account])

  const _deleteCustomCheckin = async (customCheckinId, e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (window.confirm(t('Do you really want to delete this'))) {
      await deleteCustomCheckin(customCheckinId)
        .then(() => {
          _fetchCustomCheckin()
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  const _updateViewStatus = (e: React.ChangeEvent<HTMLInputElement>, customCheckinId: string) => {
    const _questions = [...questions]
    const value = e.target.checked
    const name = e.target.name
    const viewStatus = value ? 1 : 0

    _questions.map(async _question => {
      if (_question.customCheckinId === customCheckinId) {
        _question[name] = viewStatus
        const data = { customCheckinId, viewStatus }
        await updateViewStatus(data)
          .then(() => {
            setQuestions(_questions)
          })
          .catch(error => {
            console.log(error)
          })
      }
    })
  }

  const goToDetail = (id?: string) => {
    if (id !== undefined) {
      history.push({ pathname: '/setting/custom-checkin/edit', search: `?id=${id}` })
    } else {
      history.push({ pathname: '/setting/custom-checkin/edit' })
    }
  }

  return (
    <UI
      questions={questions}
      goToDetail={goToDetail}
      deleteCustomCheckin={_deleteCustomCheckin}
      updateViewStatus={_updateViewStatus}
      isLoading={isLoading}
    />
  )
}

export const UI: React.FC<ContainerProps & Props> = ({ questions, goToDetail, deleteCustomCheckin, updateViewStatus, isLoading }) => {
  const { t } = useTranslation()
  const windows = useWindowSize()
  return (
    <SettingsLayout loading={isLoading}>
      <div
        css={infoMainContainerStyle}
        style={{
          maxHeight: windows.height - 103,
        }}
      >
        <div css={infoHeaderContainerStyle}>
          <div css={infoHeaderTitleStyle}>{t('Check-in items')}</div>
        </div>
        <div
          css={scrollStyle}
          style={{
            maxHeight: windows.height - 237,
          }}
        >
          {questions.map((question, index) => {
            return (
              <CustomCheckinList
                key={index}
                index={index}
                question={question}
                goToDetail={goToDetail}
                deleteCustomCheckin={deleteCustomCheckin}
                updateViewStatus={updateViewStatus}
              />
            )
          })}
        </div>
        <div css={addRoomListWrapperOpenHasRoomManagerPluginStyle} onClick={() => goToDetail()}>
          <img src={require('@/static/images/link_add.svg')} alt={t('Add icon red')} />
          <p>{t('Add item')}</p>
        </div>
      </div>
    </SettingsLayout>
  )
}

const infoMainContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  marginBottom: 16,
  overflow: 'hidden',
})

const infoHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const infoHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
})

const addRoomListWrapperStyle = css({
  cursor: 'pointer',
  display: 'flex',
  height: 36,
  backgroundColor: '#FAFAFA',
  justifyContent: 'center',
  border: '1px dashed #CCCCCC',
  p: {
    lineHeight: '36px',
    color: '#FF5500',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
})

const addRoomListWrapperOpenHasRoomManagerPluginStyle = css(addRoomListWrapperStyle, {
  margin: 24,
  img: {
    width: 20,
  },
  ':hover': {
    background: '#F2F2F2',
  },
})

const scrollStyle = css({
  overflowY: 'scroll',
  height: '70vh',
})
