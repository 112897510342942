import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'

// components
import { Tab as IdentityVerifyUploadTab } from '@/components/pages/self-checkin/identity-verify/tab'
import { SelfCheckinHeader } from '@/components/molecules'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { Loading } from '@/components/molecules/self-checkin/loading'

// models
import { SelfCheckinBasicInfoPluginType } from '@/models/self-checkin/results'

type ContainerProps = {
  title: string
}

export const UploadLicense: React.FC<ContainerProps> = () => {
  const history = useHistory()
  const { search, state } = useLocation<{
    plugin: string
    basicInfo: SelfCheckinBasicInfoPluginType
    hotelId: string
    identificationFrontImage: string
    identificationBackImage: string
    paymentInfo: any
    selectedReservations: any
    singleSelectedResesvation: any
    isGuidanceDisplay: boolean
  }>()

  const { t } = useTranslation()

  console.log(state)

  const uploadImage = (imageType: 'front' | 'back'): void => {
    history.push({
      pathname: '/self-checkin/identity-verify/camera',
      search,
      state: { ...state, imageType, from: 'upload-license' },
    })
  }
  const [identificationFrontImage, setIdentificationFrontImage] = useState<string>()
  const [identificationBackImage, setIdentificationBackImage] = useState<string>()
  const [buttonName] = useState<'submit' | 'next' | 'edit' | 'checkin'>('next')
  const [isLoading] = useState<boolean>(false)

  const isDisabled = (): boolean => {
    if (!identificationFrontImage || !identificationBackImage) {
      return true
    }
    return false
  }

  const getBackPageUrl = async (): Promise<string|void> => {
    if (state.paymentInfo) {
      return '/self-checkin/payment'
    }

    if (state.selectedReservations && !state.isGuidanceDisplay) {
      return '/self-checkin/accommodation-info'
    }

    if (state.selectedReservations) {
      return '/self-checkin/checkin-customize'
    }

    if (state.singleSelectedResesvation) {
      return '/self-checkin/select-reservation'
    }
  }

  const goBack = async () => {
    const backPageUrl = await getBackPageUrl()
    if (backPageUrl) {
      history.push({
        pathname: backPageUrl,
        search,
        state: {
          ...state,
          from: 'upload-license',
        },
      })
    }
  }

  const submit = async () => {
    if (state.selectedReservations) {
      history.push({
        pathname: '/self-checkin/confirm',
        search,
        state: {
          ...state,
          from: 'upload-license',
        },
      })
    } else if (state.singleSelectedResesvation) {
      history.push({
        pathname: '/self-checkin/checkedin-confirm',
        search,
        state: {
          ...state,
          from: 'upload-license',
        },
      })
    }
  }

  useEffect(() => {
    if (state?.identificationFrontImage) {
      setIdentificationFrontImage(state.identificationFrontImage)
    }
    if (state?.identificationBackImage) {
      setIdentificationBackImage(state.identificationBackImage)
    }
  }, [state?.identificationFrontImage, state?.identificationBackImage])


  if (!state?.hotelId) {
    history.replace({ pathname: '/dashboard' })
    return <></>
  }
  
  return (
    <>
      <Loading isLoading={isLoading} />
      <div css={containerStyle}>
        <SelfCheckinHeader goToPreviousPage={goBack} title={t('Identification')} />
        <div css={settingMainContainerStyle}>
          <div css={mainStyle}>
            <div css={settingHeaderContainerStyle}>
              <IdentityVerifyUploadTab currentTab="license" />
            </div>
            <div css={settingListContainerStyle}>
              <div css={descriptionStyle}>{t('Please upload your drivers license')}</div>
              <div css={uploadWrapperStyle}>
                <div css={uploadItemStyle} onClick={() => uploadImage('front')}>
                  <div css={identificationFrontImage ? withBackgroundStyle : undefined}>
                    <img
                      css={identificationImageStyle}
                      src={
                        identificationFrontImage ? identificationFrontImage : require('@/static/images/new_identification_card_front.svg')
                      }
                      alt={t('Check-in with Identification')}
                    />
                  </div>
                  <div className={identificationFrontImage ? 'uploaded' : ''} css={uploadItemTextStyle}>
                    <div>
                      <img
                        src={
                          identificationFrontImage
                            ? require('@/static/images/camera_icon_white.svg')
                            : require('@/static/images/camera_icon_orange.svg')
                        }
                        alt={t('Front of the card')}
                      />
                      <p>{t('Front of the card')}</p>
                    </div>
                  </div>
                </div>
                <div css={uploadItemStyle} onClick={() => uploadImage('back')}>
                  <div css={identificationBackImage ? withBackgroundStyle : undefined}>
                    <img
                      css={identificationImageStyle}
                      src={identificationBackImage ? identificationBackImage : require('@/static/images/new_identification_card_back.svg')}
                      alt={t('Check-in with Identification')}
                    />
                  </div>
                  <div className={identificationBackImage ? 'uploaded' : ''} css={uploadItemTextStyle}>
                    <div>
                      <img
                        src={
                          identificationBackImage
                            ? require('@/static/images/camera_icon_white.svg')
                            : require('@/static/images/camera_icon_orange.svg')
                        }
                        alt={t('Back of the card')}
                      />
                      <p>{t('Back of the card')}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div css={noticeStyle}>{t('Please upload your Identification If you dont have your drivers license')}</div>
            </div>
          </div>
        </div>
        <SelfCheckinFooter isDisabled={isDisabled} goToNextPage={submit} isNext={buttonName} />
      </div>
    </>
  )
}

const descriptionStyle = css({
  padding: '50px 0 40px 0',
  color: '#272727',
  fontSize: 20,
  fontWeight: 'bold',
  lineHeight: '24px',
  letterSpacing: '3.4px',
  textAlign: 'center',
  '@media(max-width: 1080px)': {
    lineHeight: '35px',
    letterSpacing: '3px',
    padding: '44px 0 37px 0',
  },
})

const uploadWrapperStyle = css({
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'space-around',
})

const uploadItemStyle = css({
  cursor: 'pointer',
  position: 'relative',
  color: '#f2a40b',
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: '2.7px',
  lineHeight: '27px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const withBackgroundStyle = css({
  '&::after': {
    content: '""',
    width: '100%',
    height: 256,
    background: 'rgba(0, 0, 0, 0.4)',
    top: 0,
    left: 0,
    position: 'absolute',
    borderRadius: 10,
  },
})

const identificationImageStyle = css({
  width: 369,
  height: 256,
  borderRadius: 8,
})

const uploadItemTextStyle = css({
  height: 69,
  position: 'absolute',
  top: 0,
  bottom: 4,
  left: 0,
  right: 0,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  p: {
    lineHeight: '28px',
  },
  img: {
    width: 28,
    height: 24,
    marginBottom: 3,
  },
  '&.uploaded': {
    color: '#fff',
  },
})

const containerStyle = css({
  width: '100%',
  minHeight: '100vh',
  backgroundColor: '#F2F2F2',
  '*': {
    fontFamily: 'Noto Sans JP',
  },
})

const mainStyle = css({
  padding: '120px 0',
  '@media(max-width: 1080px)': {
    padding: '96px 0',
  },
})

const settingMainContainerStyle = css({
  margin: '0 auto',
  maxWidth: 874,
})

const settingListContainerStyle = css({
  borderRadius: '5px 0 5px 5px',
  background: '#FFF',
  boxShadow: '0px 0px 6px #0000001a',
  minHeight: 491,
  '@media(max-width: 1080px)': {
    minHeight: 458,
  },
})

const settingHeaderContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const noticeStyle = css({
  textAlign: 'center',
  color: '#676767',
  fontSize: 14,
  marginTop: 32,
  '@media(max-width: 1080px)': {
    marginTop: 28,
  },
})
