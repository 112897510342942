export const MIWA_APP_WEBSOCKET_KEY_HOST = 'miwaAppWebsocketHost'
export const MIWA_APP_WEBSOCKET_KEY_PORT = 'miwaAppWebsocketPort'
export const MIWA_APP_WEBSOCKET_PC_ID = 'miwaAppWebsocketPCID'
export const MIWA_APP_WEBSOCKET_HOTEL_ON_SITE_CODE = 'miwaAppWebsocketHotelOnSiteCode'
export const MIWA_APP_WEBSOCKET_CONNECTION_KEY = 'miwaAppWebsocketConnectionKey'

export const MiwaLockStatusSelect = {
  ON: 1,
  OFF: 0,
}

export enum MiwaLockStatus {
  ISSUE = 'issue',
  REISSUE = 'reissue',
  ADDNEWISSUE = 'additional_issue',
}

export enum MiwaLockWebsocketRequestEvent {
  AUTH = 'auth',
  ISSUE = 'issue',
  REISSUE = 'reissue',
  CANCEL = 'cancel',
  ERROR = 'error',
  UNKNOWN = 'unknown',
}

export enum MiwaLockWebsocketResponseEvent {
  AUTH_RESULT = 'auth_result',
  ISSUE_RESULT = 'issue_result',
  REISSUE_RESULT = 'reissue_result',
  ADDITIONAL_ISSUE_RESULT = 'additional_issue_result',
  CANCEL_RESULT = 'cancel_result',
  ERROR_RESULT = 'error',
  UNKNOWN = 'unknown',
}

export enum MiwaLockWebsocketResponseStatusCode {
  AUTH_SUCCESS = 100,
  ISSUE_IN_PROGRESS = 101,
  ISSUE_DONE = 102,
  INVALID_DATA = 400,
  UNAUTHENTICATED = 401,
  CONNECTION_ERROR = 402,
  INVALID_PC_ID = 403,
  WRITE_CARD_ERROR = 405,
  NO_CARD_INSERTED = 406,
  NOT_SUPPORTED_CARD = 407,
  SERVER_ERROR = 500,
  DEVICE_BUSY = 501,
  UNKNOWN = 9999,
}
