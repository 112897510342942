import React from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

// models
import { ChannelCodeType, ChannelType } from '@/models/reservation-channel-code'

type Props = {
  editChannelCodes: ChannelCodeType[]
  onChangeState: (e: React.ChangeEvent<HTMLInputElement>, index) => void
  deleteChannelCode: (id, index) => void
  addChannelCode: () => void
}

export const SettingChannelCodeEditForm: React.FC<Props> = ({ editChannelCodes, onChangeState, deleteChannelCode, addChannelCode }) => {
  const windows = useWindowSize()
  const { t } = useTranslation()

  return (
    <>
      <div css={settingEditHeaderStyle}>
        <div css={channelCodeWidthStyle}>{t('Channel code')}</div>
        <div css={nameWidthStyle}>{t('Name')}</div>
      </div>
      <div
        css={scrollStyle}
        style={{
          height: windows.height - 270,
        }}
      >
        <ul css={settingEditFormStyle}>
          {editChannelCodes &&
            editChannelCodes.map((channelCode: ChannelCodeType, index) => (
              <li key={index}>
                {/* channel code */}
                <div css={channelCodeWidthStyle}>
                  <input
                    name="code"
                    type="text"
                    placeholder="0000"
                    css={inputStyle}
                    value={channelCode.code ?? ''}
                    onChange={e => onChangeState(e, index)}
                  />
                </div>
                {channelCode.type === ChannelType.Default && (
                  <>
                    <div className="name" css={defaultChannelCodeStyle}>
                      {channelCode.name}
                    </div>
                  </>
                )}
                {channelCode.type === ChannelType.Custom && (
                  <>
                    {/* name */}
                    <div css={nameWidthStyle}>
                      <input
                        name="name"
                        type="text"
                        placeholder={t('Channel')}
                        css={inputStyle}
                        value={channelCode.name ?? ''}
                        onChange={e => onChangeState(e, index)}
                      />
                    </div>
                    <div css={deleteButtonStyle} onClick={() => deleteChannelCode(channelCode.id, index)}>
                      <img src={require('@/static/images/delete_yellow.svg')} alt={t('Delete')} />
                    </div>
                  </>
                )}
              </li>
            ))}
        </ul>
        <div css={plusButtonRowStyle} onClick={addChannelCode}>
          <img src={require('@/static/images/plus.svg')} alt={t('Plus')} />
          <p>{t('Add channel')}</p>
        </div>
      </div>
    </>
  )
}

const channelCodeWidthStyle = css({
  width: '15%',
})

const nameWidthStyle = css({
  flex: 1,
})

const settingEditHeaderStyle = css({
  background: '#F5F5F5',
  color: '#676767',
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  padding: '10px 32px',
  '.name': nameWidthStyle,
})

const settingEditFormStyle = css({
  padding: '24px 32px 12px',
  li: {
    display: 'flex',
    padding: '0 0 16px 0',
    div: {
      display: 'flex',
      paddingRight: 16,
      '&.name': nameWidthStyle,
    },
    input: {
      '&::placeholder': {
        color: '#CCCCCC',
      },
    },
    '.price': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      input: {
        width: 66,
        marginRight: 16,
      },
    },
  },
})

const inputStyle = css({
  width: '100%',
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  padding: '0 16px',
})

const deleteButtonStyle = css({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  img: {
    width: 30,
    height: 30,
  },
})

const plusButtonRowStyle = css({
  padding: '0px 32px 0',
  marginRight: 'auto',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  cursor: 'pointer',
  p: {
    color: '#F2A40B',
    fontSize: 12,
    paddingLeft: 10,
  },
  img: {
    width: 20,
    height: 20,
  },
})

const scrollStyle = css({
  'overflow-y': 'scroll',
  height: '68vh',
})

const defaultChannelCodeStyle = css({
  height: 32,
  alignItems: 'center',
  fontSize: 14,
})
