import React, { MouseEvent, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

const insertTags = ['宿泊者名', 'フリガナ', 'メールアドレス', '予約ID', 'チェックイン日', 'チェックアウト日']

export const MailDeliveryTag: React.FC = () => {
  const [copyPosition, setCopyPosition] = useState<{ top: number; left: number; height: number }>()

  const { t } = useTranslation()

  const copyText = async (e: MouseEvent<HTMLSpanElement>, copyText?: string) => {
    const item = e.currentTarget
    const copiedText = item.getBoundingClientRect()
    setCopyPosition({ top: copiedText.top, left: copiedText.left, height: copiedText.height })

    await navigator.clipboard.writeText(copyText || item.innerText)

    setTimeout(() => {
      setCopyPosition(undefined)
    }, 500)
  }

  return (
    <div>
      <div css={tabBlockStyle}>
        <div className="tag-title">
          {t('insertion tag')}
          <div className="hint">
            <span className="question">?</span>
            <img className="description description-tag" src={require('@/static/images/mail-delivery/insert_tag.png')} alt="" />
          </div>
        </div>
        <div className="tag-wrap">
          {insertTags.map(tag => (
            <span key={tag} onClick={e => copyText(e)}>
              {`%${tag}%`}
            </span>
          ))}
        </div>
      </div>

      <div css={tabBlockStyle}>
        <div className="tag-title">
          {t('Insert Room Tag')}
          <div className="hint">
            <span className="question">?</span>
            <img className="description description-room" src={require('@/static/images/mail-delivery/insert_room_tag.png')} alt="" />
          </div>
        </div>
        <div className="quote-block">
          <div
            className="tag"
            onClick={e => {
              copyText(
                e,
                JSON.stringify({
                  defaultText: '部屋タイプ:%部屋タイプ%',
                  role: 'assign-room',
                }),
              )
            }}
          >
            部屋タイプ
          </div>
          <div
            className="tag"
            onClick={e => {
              copyText(
                e,
                JSON.stringify({
                  defaultText: '部屋タイプ:%部屋タイプ%\n部屋番号:%部屋番号%',
                  role: 'assign-room',
                }),
              )
            }}
          >
            部屋タイプ + 部屋番号
          </div>
          <div
            className="tag"
            onClick={e => {
              copyText(
                e,
                JSON.stringify({
                  defaultText: '部屋タイプ:%部屋タイプ%\n部屋番号:%部屋番号%\n鍵番号:%鍵番号%',
                  role: 'assign-room',
                }),
              )
            }}
          >
            部屋タイプ + 部屋番号 + 鍵番号
          </div>
        </div>
      </div>

      {copyPosition && (
        <span css={copyPopupStyle} style={{ top: copyPosition.top + copyPosition.height, left: copyPosition.left }}>
          {t('Copied.')}
        </span>
      )}
    </div>
  )
}

const tabBlockStyle = css({
  border: '1px solid #CCCCCC',
  borderRadius: 10,
  marginBottom: 16,
  '.tag-title': {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    position: 'relative',
    padding: '18px 24px',
    fontSize: 14,
    fontWeight: 'bold',
    borderBottom: '1px solid #CCCCCC',
  },
  '.question': {
    width: 18,
    height: 18,
    background: '#F2A40B',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },
  '.hint': {
    position: 'relative',
    '.description': {
      display: 'none',
      position: 'absolute',
      zIndex: 1000,
      boxShadow: '0px 0px 10px #00000029',
      width: 376,
      right: 0,
      '&-tag': {
        height: 407,
        top: 0,
      },
      '&-room': {
        height: 469,
        bottom: '-200px',
      },
    },
    ':hover .description': {
      display: 'block',
    },
  },
  '.tag-wrap': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
    padding: '18px 24px',
    fontSize: 12,
    color: '#F2A40B',
    cursor: 'pointer',
  },
  '.quote-block': {
    padding: '18px 24px',
  },
  '.tag': {
    width: 'fit-content',
    padding: '6px 12px',
    background: '#f5f5f5',
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 8,
    cursor: 'pointer',
  },
})

const copyPopupStyle = css({
  padding: 8,
  position: 'fixed',
  color: '#676767',
  background: '#fff',
  boxShadow: '0px 0px 10px #00000029',
  fontSize: 12,
  fontWeight: 'bold',
  zIndex: 100,
})
