import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { wholeContainerStyle, mainColumnStyle } from '@/constants/layout'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { SalesManagerTab } from '@/components/pages/sales-manager/tab'
import { Button } from '@/components/atoms'
import { ButtonType } from '@/components/atoms/button'
import { DateRangeField } from '@/components/molecules/date-range-field'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import * as SalesAccountingModel from '@/models/sales-manager/sales-accounting'
import { Controller, useForm, useWatch } from 'react-hook-form'
import dayjs from 'dayjs'
import { exportSalesAccountingReport } from '@/apis/aipass'
import { LoaderContextCreator } from '@/contexts/loader'
import { useErrorHandler } from '@/hooks/use-error-handler'

type FormType = {
  systemType: SalesAccountingModel.SystemType
  formatType: SalesAccountingModel.FormatType
  from: string
  to: string
}

export const SalesManagerAccounting: React.FC = () => {
  const { t } = useTranslation()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const { errorHandler } = useErrorHandler()
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid },
    register,
  } = useForm<FormType>({
    defaultValues: {
      systemType: undefined,
      formatType: SalesAccountingModel.FormatType.Classification,
      from: dayjs().format('YYYY-MM-DD'),
      to: dayjs().format('YYYY-MM-DD'),
    },
  })
  const watchFrom = useWatch({ control, name: 'from' })
  const watchTo = useWatch({ control, name: 'to' })

  const doExport = async (value: FormType) => {
    try {
      setIsLoading(true)
      const { path } = await exportSalesAccountingReport(value)
      if (path) {
        window.open(path, '_blank')
      }
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header title={t('Sales management')} />
        <div css={containerStyle}>
          <div css={mainContainerStyle}>
            <div css={headerContainerStyle}>
              <SalesManagerTab currentTab="accounting" />
            </div>
            <div css={listContainerStyle}>
              <div>
                <div className="form-title">
                  {t('Accounting systems')}
                  <div>※</div>
                </div>
                <div className="form-input">
                  <Controller
                    name="systemType"
                    control={control}
                    rules={{
                      required: t('Please select'),
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Select
                        placeholder={t('Please select')}
                        value={value}
                        onChange={onChange}
                        error={error?.message}
                        customStyle={css({ paddingBottom: 0 })}
                      >
                        <AntSelect.Option value={SalesAccountingModel.SystemType.MoneyForward}>MoneyForward クラウド会計</AntSelect.Option>
                        <AntSelect.Option value={SalesAccountingModel.SystemType.Freee}>freee会計</AntSelect.Option>
                      </Select>
                    )}
                  />
                </div>
              </div>
              <div>
                <div className="form-title">
                  {t('Period')}
                  <div>※</div>
                </div>
                <div className="form-input">
                  <DateRangeField
                    {...register('from', { required: t('Please select') })}
                    {...register('to', { required: t('Please select') })}
                    beginDate={watchFrom}
                    endDate={watchTo}
                    changeRangeDate={(beginDate: string, endDate: string) => {
                      setValue('from', beginDate)
                      setValue('to', endDate)
                      trigger(['from', 'to'])
                    }}
                  />
                  {errors.from?.message ||
                    (errors.to?.message && <div className="input-error">{errors.from?.message || errors.to?.message}</div>)}
                </div>
              </div>
              <div style={{ paddingTop: 8 }}>
                <Button
                  buttonType={isValid ? ButtonType.Primary : ButtonType.PrimaryDisabled}
                  width={84}
                  height={32}
                  fontSize={12}
                  onClick={handleSubmit(doExport)}
                >
                  {t('CSV export')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const containerStyle = css({
  display: 'flex',
  padding: 0,
  minWidth: '1000px',
})

const mainContainerStyle = css({
  width: '100%',
  padding: 24,
})

const listContainerStyle = css({
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: '0 5px 5px 5px',
  background: '#FFF',
  position: 'relative',
  padding: 32,
  // header 55px, tab 45px, padding 24px*2
  height: 'calc(100vh - 45px - 55px - 48px)',
  '.form-title': {
    display: 'flex',
    paddingBottom: 12,
    color: '#676767',
    fontSize: 12,
    fontWeight: 'bold',
    '> div': {
      paddingLeft: 4,
      fontSize: 8,
    },
  },
  '.form-input': {
    width: 315,
    paddingBottom: 24,
    position: 'relative',
  },
  '.input-error': {
    fontSize: 10,
    color: 'rgba(255,0,0,0.5)',
    position: 'absolute',
    top: 36,
  },
})

const headerContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})
