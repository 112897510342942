import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

type Props = {
  onChangeMenu(changedMenu: MenuType): void
  value?: MenuType
}

export const MenuType = {
  Product: 'product',
  Category: 'category',
  Tag: 'tag',
  SalesHours: 'salesHours',
  Notification: 'notification',
} as const
export type MenuType = (typeof MenuType)[keyof typeof MenuType]

export const SideBar: React.FC<Props> = ({ onChangeMenu, value }) => {
  const { t } = useTranslation()
  const [activeMenu, setActiveMenu] = useState<MenuType>(value || MenuType.Product)

  const changeMenu = (changedMenu: MenuType) => {
    setActiveMenu(changedMenu)
    onChangeMenu(changedMenu)
  }

  return (
    <div css={contentStyle}>
      <div css={activeMenu === MenuType.Product ? activeItemStyle : itemStyle} onClick={() => changeMenu(MenuType.Product)}>
        {t('Product')}
      </div>
      <div css={activeMenu === MenuType.Category ? activeItemStyle : itemStyle} onClick={() => changeMenu(MenuType.Category)}>
        {t('Category')}
      </div>
      <div css={activeMenu === MenuType.Tag ? activeItemStyle : itemStyle} onClick={() => changeMenu(MenuType.Tag)}>
        {t('Tag')}
      </div>
      <div css={activeMenu === MenuType.SalesHours ? activeItemStyle : itemStyle} onClick={() => changeMenu(MenuType.SalesHours)}>
        {t('MobileRequest.Sales hours')}
      </div>
      <div css={activeMenu === MenuType.Notification ? activeItemStyle : itemStyle} onClick={() => changeMenu(MenuType.Notification)}>
        {t('Notification')}
      </div>
    </div>
  )
}

const contentStyle = css({
  minWidth: 180,
  borderRight: '1px solid #F2F2F2',
  backgroundColor: '#FFFFFF',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 1,
})

const itemStyle = css({
  cursor: 'pointer',
  height: 44,
  paddingLeft: 24,
  alignItems: 'center',
  display: 'flex',
  fontWeight: 'bold',
  fontSize: '12px',
  letterSpacing: '0.6px',
  color: '#676767',
  borderBottom: '1px solid #F2F2F2',
})
const activeItemStyle = css(itemStyle, {
  backgroundColor: '#F2F2F2',
})
