import React from 'react'
import { SerializedStyles, css } from '@emotion/core'

interface QuestionMarkProps {
  customCss?: SerializedStyles
}

export const QuestionMark: React.FC<QuestionMarkProps> = ({ customCss }) => {
  return <span css={[questionStyle, customCss]}>?</span>
}

const questionStyle = css({
  width: 18,
  height: 18,
  background: '#F2A40B',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12,
  fontWeight: 'bold',
  color: '#fff',
  cursor: 'pointer',
})
