import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

import { ReservationUserEditFormType } from './reservation-user-edit-form'

type ReservationUserEditModalProps = {
  userEditForm: ReservationUserEditFormType
  setUserEditForm: (userEditForm: ReservationUserEditFormType) => void
  errors: any
}

export const ReservationUserEdit: React.FC<ReservationUserEditModalProps> = ({ userEditForm, setUserEditForm, errors }) => {
  const { t } = useTranslation()
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const name = event.target.name
    setUserEditForm({ ...userEditForm, [name]: value })
  }

  return (
    <React.Fragment>
      <div css={modalContentStyle}>
        <div css={formContainerStyle}>
          <div css={leftSectionStyle}>
            <div css={sectionHeaderStyle}>{t('Reservation person')}</div>
            <div css={sectionBodyStyle}>
              <div css={fieldBlockStyle}>
                <div css={fieldTitleStyle}>{t('Full name')}</div>
                <input
                  css={filedInputStyle}
                  placeholder={t('John Doe')}
                  name="userName"
                  value={userEditForm.userName}
                  onChange={handleInputChange}
                ></input>
              </div>
              <div css={fieldBlockStyle}>
                <div css={fieldTitleStyle}>{t('Furigana')}</div>
                <input
                  css={filedInputStyle}
                  placeholder="ヤマダ タロウ"
                  name="userNameKana"
                  value={userEditForm.userNameKana}
                  onChange={handleInputChange}
                ></input>
              </div>
              <div css={fieldBlockStyle}>
                <div css={fieldTitleStyle}>{t('Phone number')}</div>
                <input
                  css={errors.includes('userTel') ? filedInputErrorStyle : filedInputStyle}
                  name="userTel"
                  placeholder="08012345678"
                  value={userEditForm.userTel}
                  onChange={handleInputChange}
                ></input>
                {errors.includes('userTel') ? <div css={fieldErrorStyle}>{t('Please enter in single-byte number')}</div> : ''}
              </div>
              <div css={fieldBlockStyle}>
                <div css={fieldTitleStyle}>{t('Email')}</div>
                <input
                  css={filedInputStyle}
                  name="userMailAddr"
                  placeholder="yamada@aipass.jp"
                  value={userEditForm.userMailAddr}
                  onChange={handleInputChange}
                ></input>
              </div>
              <div css={fieldBlockStyle}>
                <div css={fieldTitleStyle}>{t('Address')}</div>
                <input
                  css={filedInputStyle}
                  name="userAddr"
                  placeholder={t('1-1 Chiyoda Chiyoda-ku')}
                  value={userEditForm.userAddr}
                  onChange={handleInputChange}
                ></input>
              </div>
            </div>
          </div>

          <div css={rightSectionStyle}>
            <div css={sectionHeaderStyle}>{t('Guests')}</div>
            <div css={sectionBodyStyle}>
              <div css={fieldBlockStyle}>
                <div css={fieldTitleStyle}>{t('Full name')}</div>
                <input
                  css={filedInputStyle}
                  placeholder={t('John Doe')}
                  name="guestName"
                  value={userEditForm.guestName}
                  onChange={handleInputChange}
                ></input>
              </div>
              <div css={fieldBlockStyle}>
                <div css={fieldTitleStyle}>{t('Furigana')}</div>
                <input
                  css={filedInputStyle}
                  placeholder="ヤマダ タロウ"
                  name="guestNameKana"
                  value={userEditForm.guestNameKana}
                  onChange={handleInputChange}
                ></input>
              </div>
              <div css={fieldBlockStyle}>
                <div css={fieldTitleStyle}>{t('Phone number')}</div>
                <input
                  css={errors.includes('guestTel') ? filedInputErrorStyle : filedInputStyle}
                  placeholder="08012345678"
                  name="guestTel"
                  value={userEditForm.guestTel}
                  onChange={handleInputChange}
                ></input>
                {errors.includes('guestTel') ? <div css={fieldErrorStyle}>{t('Please enter in single-byte number')}</div> : ''}
              </div>
              <div css={fieldBlockStyle}>
                <div css={fieldTitleStyle}>{t('Email')}</div>
                <input
                  css={filedInputStyle}
                  placeholder="yamada@aipass.jp"
                  name="guestMailAddr"
                  value={userEditForm.guestMailAddr}
                  onChange={handleInputChange}
                ></input>
              </div>
              <div css={fieldBlockStyle}>
                <div css={fieldTitleStyle}>{t('Address')}</div>
                <input
                  css={filedInputStyle}
                  placeholder={t('1-1 Chiyoda Chiyoda-ku')}
                  name="guestAddr"
                  value={userEditForm.guestAddr}
                  onChange={handleInputChange}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const modalContentStyle = css({
  background: '#fff',
  borderRadius: 5,
  marginTop: 12,
})

const formContainerStyle = css({
  width: '100%',
  backgroundColor: '#FFF',
  border: '1px solid #CCCCCC',
  display: 'flex',
  borderRadius: '5px',
  color: '#676767',
  padding: '32px',
})

const leftSectionStyle = css({
  width: '50%',
  paddingRight: 41,
  borderRight: '1px solid #F2F2F2',
})

const rightSectionStyle = css({
  width: '50%',
  paddingLeft: 41,
})

const sectionHeaderStyle = css({
  fontWeight: 'bold',
  fontSize: 16,
  marginBottom: 16,
  color: '#272727',
})

const sectionBodyStyle = css({
  flexGrow: 1,
})

const fieldBlockStyle = css({
  marginBottom: '24px',
  '&:last-child': {
    marginBottom: '0',
  },
})

const fieldTitleStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  marginBottom: '12px',
})

const filedInputStyle = css({
  width: '100%',
  height: 32,
  padding: '8px 16px',
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  textAlign: 'left',
  backgroundSize: 10,
  '::placeholder': {
    color: '#CCCCCC',
  },
})

const filedInputErrorStyle = css(filedInputStyle, {
  border: '1px solid #ff5500',
})

const fieldErrorStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  marginTop: '10px',
  marginBottom: '10px',
  color: '#ff5500',
})
