import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// apis
import { fetchReservationChannelCode, updateReservationChannelCode } from '@/apis/aipass'

import { SettingChannelCodeEditForm } from '@/components/pages/setting/reservation/channel-code/_edit/edit-form'

// models
import { ChannelCodeType, ChannelType } from '@/models/reservation-channel-code'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { EditFooter } from '@/components/organisms/edit-footer'

type ContainerProps = {}

export const SettingReservationChannelCodeEdit: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const initialChannelCode: ChannelCodeType = {
    id: null,
    code: null,
    name: null,
    type: ChannelType.Custom,
  }

  const [editChannelCodes, setEditChannelCodes] = useState<ChannelCodeType[]>([initialChannelCode])
  const [channelCodes, setChannelCodes] = useState<ChannelCodeType[]>([])
  const [deletedChannelCodes, setDeletedChannelCodes] = useState<ChannelCodeType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onChangeState = (e: React.ChangeEvent<HTMLInputElement>, index) => {
    const value = e.target.value
    const name = e.target.name
    const _editChannelCodes = [...editChannelCodes]
    _editChannelCodes[index][name] = value
    setEditChannelCodes(_editChannelCodes)
  }

  // Remove deleted elements from the displayed array
  const deleteChannelCode = (id, index) => {
    const _editChannelCodes = [...editChannelCodes]
    _editChannelCodes.splice(index, 1)
    setEditChannelCodes(_editChannelCodes)

    // If it is an element already saved in the DB, add it to the delete array
    if (id) {
      const deletedChannelCode = {
        id,
        code: null,
        name: null,
        type: ChannelType.Custom,
      }
      const _deleteChannelCodes: ChannelCodeType[] = [...deletedChannelCodes, deletedChannelCode]

      setDeletedChannelCodes(_deleteChannelCodes)
    }
  }

  const addChannelCode = () => {
    setEditChannelCodes([...editChannelCodes, initialChannelCode])
  }

  const _errorMessage = (codeError: boolean, nameError: boolean): string => {
    let errorMessage = ''
    const codeErrorMessage = t('There is an item with no segment code entered')
    const nameErrorMessage = t('There is an item with no name entered')

    if (codeError) {
      errorMessage = codeErrorMessage
    }
    if (nameError) {
      errorMessage = `${errorMessage}${nameErrorMessage}`
    }

    return errorMessage
  }

  /**
   * Return the element to be saved
   *
   * Compare the data in the edited and original arrays and save only the elements whose contents have been edited.
   * If there is even one element that fails validation, display an alert and return null.
   */
  const _validatedChannelCodes = (): ChannelCodeType[] | null => {
    let validatedChannelCodes: ChannelCodeType[] = []
    let codeError = false
    let nameError = false

    // Put newly created/edited elements into storage array
    editChannelCodes.forEach(editChannel => {
      // Go to the next element if nothing is entered
      if (!editChannel.code && !editChannel.name) return

      // If the validation judgment is caught, go to the next element
      if (!editChannel.code) {
        codeError = true
        return
      }
      if (!editChannel.name) {
        nameError = true
        return
      }

      // If there is no id, add it to the save array to create a new one and move to the next element
      if (!editChannel.id) {
        validatedChannelCodes = [...validatedChannelCodes, editChannel]
        return
      }

      /**
       * Determining if content has been edited
       * If the before data cannot be retrieved, the element is anomalous and should not be saved
       */
      const originChannelCode = channelCodes.find(channel => channel.id === editChannel.id)
      if (!originChannelCode) return

      // If the content is edited from the data before change, add it to the save array
      if (originChannelCode.code !== editChannel.code || originChannelCode.name !== editChannel.name) {
        validatedChannelCodes = [...validatedChannelCodes, editChannel]
      }
    })

    // Display an alert if validation fails
    if (codeError || nameError) {
      window.alert(_errorMessage(codeError, nameError))
      return null
    }

    // Combine and return the deleted elements
    return [...validatedChannelCodes, ...deletedChannelCodes]
  }

  const onSaveChannelCodes = () => {
    setIsLoading(true)

    // Extract only newly added/edited/deleted elements
    const validatedChannelCodes = _validatedChannelCodes()

    // If the validation is caught, the save process is not performed
    if (validatedChannelCodes === null) {
      setIsLoading(false)
      return
    }

    updateReservationChannelCode(validatedChannelCodes)
      .then(() => {
        history.push({ pathname: '/setting/reservation/channel-code', search: location.search })
      })
      .catch(() => {
        console.log(t('Communication failed'))
        setIsLoading(false)
      })
  }

  const _fetchChannelCode = async () => {
    setIsLoading(true)
    await fetchReservationChannelCode()
      .then(res => {
        if (res?.channelCodes.length !== 0) {
          setEditChannelCodes(res.channelCodes)
        }
        // Preserve channel code (deep copy) before change
        setChannelCodes(res?.channelCodes.map(channelCode => ({ ...channelCode })))
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  useEffect(() => {
    _fetchChannelCode()
  }, [])

  const footerContent = <EditFooter onCancel={history.goBack} onSave={onSaveChannelCodes} />

  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <div css={settingEditContainerStyle}>
        <div css={settingEditHeaderStyle}>
          <p>{t('Channel')}</p>
        </div>
        {editChannelCodes && (
          <SettingChannelCodeEditForm
            editChannelCodes={editChannelCodes}
            onChangeState={onChangeState}
            deleteChannelCode={deleteChannelCode}
            addChannelCode={addChannelCode}
          />
        )}
      </div>
    </SettingsLayout>
  )
}

const settingEditContainerStyle = css({
  height: '100%',
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
  background: '#FFF',
  padding: '0 0 23px',
})

const settingEditHeaderStyle = css({
  height: 50,
  padding: '16px 32px',
  borderBottom: '1px solid #F2F2F2',
  p: {
    fontSize: 18,
    fontWeight: 'bold',
  },
})
