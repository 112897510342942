import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms/button'
import { headerBarLeftWrapperStyle } from '@/constants/layout'

type Props = {
  onSave: (e) => void
  onCancel: (e) => void
}

export const Footer: React.FC<Props> = ({ onSave, onCancel }) => {
  const { t } = useTranslation()

  return (
    <div css={editButtonWrapperStyle}>
      <div css={headerBarLeftWrapperStyle}>
        <Button
          buttonType={3}
          width={110}
          height={38}
          fontSize={14}
          marginRight={16}
          onClick={onCancel}
        >
          {t('Cancel')}
        </Button>
        <Button
          buttonType={1}
          width={110}
          height={38}
          fontSize={14}
          marginRight={16}
          onClick={onSave}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  )
}

const editButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 32px 10px',
  backgroundColor: '#fff',
  position: 'sticky',
  bottom: 0,
  boxShadow: '0px 0px 6px #0000001A',
})
