import { css } from '@emotion/core'

export const modalContainerStyle = css({
  width: '100%',
  height: 'calc(946px + 120px)',
  background: 'rgba(39, 39, 39, 0.5)',
  position: 'fixed',
  zIndex: 102,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'scroll',
  color: '#272727',
})

export const modalWrapperStyle = css({
  width: 600,
  height: 'calc(946px + 120px)',
})

export const modalStyle = css({
  width: 600,
  height: 635,
  zIndex: 104,
  margin: '120px 0',
})

export const modalTitleStyle = css({
  fontSize: 18,
  letterSpacing: 0.9,
  fontWeight: 'bold',
  paddingLeft: 32,
  display: 'flex',
  alignItems: 'center',
  height: 60,
  background: '#fff',
  borderRadius: '5px 5px 0 0',
})

export const modalSectionStyle = css({
  background: '#F2F2F2',
  padding: '24px 32px',
  overflowY: 'scroll',
})

export const modalItemWrapperStyle = css({
  width: 536,
  height: 'auto',
  border: '1px solid #CCCCCC',
  padding: '32px',
  background: '#fff',
  borderRadius: 5,
})

export const modalFooterStyle = css({
  height: 60,
  background: '#fff',
  borderRadius: '0 0 5px 5px',
  boxShadow: '0px 0px 6px #0000001A',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
})
