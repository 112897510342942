import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { css } from '@emotion/core'
import 'dayjs/locale/ja'

// constants
import {
  wholeContainerStyle,
  mainColumnStyle,
  headerBarRightWrapperStyle,
  headerBarLeftWrapperStyle,
  listContentsStyle,
} from '@/constants/layout'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { SideMenu } from '@/components/organisms/side-menu'
import { Header } from '@/components/organisms/header'
import { SalesManagerTab } from '@/components/pages/sales-manager/tab'
import { Button } from '@/components/atoms'
import { AdvanceMoneyHistoryModal } from '@/components/pages/sales-manager/advance-money/advance-money-history-modal'
import { DetailSearchPanel } from '@/components/organisms/advance-money/detail-search-panel'
import { TableItem } from '@/components/organisms/advance-money/advance-money-item'
import { AdvanceMoneyCreateModal } from './advance-money-create-modal'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { useSearchedConditions } from '@/hooks/use-search-condition'

//models
import { AdvanceMoneySearchType, AdvanceMoneyType, SelectBalanceType, selectBalanceTypeValue } from '@/models/sales-manager/advance-money'
import { deleteAdvanceMoneyCompany, exportAdvanceMoneyCompanyCsv, fetchAdvanceMoney } from '@/apis/aipass'
import dayjs from 'dayjs'

type Props = {
  isDropDownOpen: boolean
  setIsDropDownOpen: (open: boolean) => void
  createCsv: () => void
  windows: any
  isLoading: boolean
  listAdvanceMoney: AdvanceMoneyType[]
  deleteItemMoney: (index: number, id: string) => void
  showFooter: boolean
  onCancel: () => void
  onSaveData: () => void
  openHistoryModal: (id: string) => void
  isOpenHistoryModal: boolean
  advanceMoneyCompanyId: string
  isOpenCreateModal: boolean
  openCreateModal: () => void
  pagination: any
  getPaginateAdvanceMoney: (currentPage: any) => void
  deleteCompanyId: any
  isSearchPanelOpen: boolean
  setIsSearchPanelOpen: (value: boolean) => void
  detailSearchContent: AdvanceMoneySearchType
  setDetailSearchContent: (content: AdvanceMoneySearchType) => void
  onClearSearchPanel: () => void
  onDetailSearch: () => void
}

export const AdvanceMoney: React.FC = () => {
  const windows = useWindowSize()

  const { t } = useTranslation()
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [listAdvanceMoney, setListAdvanceMoney] = useState<AdvanceMoneyType[]>([])
  const [showFooter, setShowFooter] = useState<boolean>(false)
  const [advanceMoneyCompanyId, setAdvanceMoneyId] = useState<string>('')
  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState<boolean>(false)
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false)
  const [deleteCompanyId, setDeleteCompanyId] = useState<any>(null)
  const LIMIT = 30
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useState<boolean>(false)
  const initSearchContent = {
    beginPaymentDate: undefined,
    endPaymentDate: undefined,
    beginWithdrawalDate: undefined,
    endWithdrawalDate: undefined,
    balance: SelectBalanceType.WITH_BALANCE,
    companyName: '',
  }
  const [detailSearchContent, setDetailSearchContent] = useState<AdvanceMoneySearchType>(initSearchContent)

  const [pagination, setPagination] = useState<any>({
    page: 1,
    hasNext: false,
    listTotal: 0,
    hasList: 0,
    limit: LIMIT,
  })

  const createCsv = async () => {
    const res = await exportAdvanceMoneyCompanyCsv(detailSearchContent)

    const url = window.URL.createObjectURL(new Blob([res]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `sales-deposits-list.csv`) //or any other extension
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const deleteItemMoney = (index: number, id: any) => {
    setShowFooter(true)
    setListAdvanceMoney(listAdvanceMoney.filter(item => item?.id !== id))
    setDeleteCompanyId(id)
  }

  const onCancel = () => {
    setShowFooter(false)
    setDeleteCompanyId(null)
    getPaginateAdvanceMoney(pagination.page)
  }

  const onSaveData = async () => {
    try {
      await deleteAdvanceMoneyCompany(deleteCompanyId)
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    }
    setDeleteCompanyId(null)
    getPaginateAdvanceMoney(pagination.page)
    setShowFooter(false)
  }

  const getPaginateAdvanceMoney = async currentPage => {
    try {
      setIsLoading(true)
      const res = await fetchAdvanceMoney(currentPage, LIMIT, detailSearchContent)

      setListAdvanceMoney(res.items)
      setPagination({
        page: res.pagination.page,
        hasNext: res.pagination.page < res.pagination.totalPage,
        listTotal: res.pagination.total,
        limit: LIMIT,
        hasList: res.items.length,
      })
    } catch (e) {
      console.log(t('Communication failed'))
    } finally {
      setIsLoading(false)
    }
  }

  const openHistoryModal = (id: React.SetStateAction<string>) => {
    setIsOpenHistoryModal(!isOpenHistoryModal)
    setAdvanceMoneyId(id)
  }

  const openCreateModal = () => {
    setIsOpenCreateModal(!isOpenCreateModal)
  }

  const onClearSearchPanel = () => {
    setDetailSearchContent(initSearchContent)
  }
  const onDetailSearch = () => {
    setIsSearchPanelOpen(false)
    getPaginateAdvanceMoney(1)
  }

  useEffect(() => {
    getPaginateAdvanceMoney(1)
  }, [])

  return (
    <UI
      openHistoryModal={openHistoryModal}
      advanceMoneyCompanyId={advanceMoneyCompanyId}
      isOpenHistoryModal={isOpenHistoryModal}
      isOpenCreateModal={isOpenCreateModal}
      openCreateModal={openCreateModal}
      isLoading={isLoading}
      isDropDownOpen={isDropDownOpen}
      setIsDropDownOpen={setIsDropDownOpen}
      createCsv={createCsv}
      windows={windows}
      listAdvanceMoney={listAdvanceMoney}
      deleteItemMoney={deleteItemMoney}
      showFooter={showFooter}
      onCancel={onCancel}
      onSaveData={onSaveData}
      pagination={pagination}
      getPaginateAdvanceMoney={getPaginateAdvanceMoney}
      deleteCompanyId={deleteCompanyId}
      isSearchPanelOpen={isSearchPanelOpen}
      setIsSearchPanelOpen={setIsSearchPanelOpen}
      detailSearchContent={detailSearchContent}
      setDetailSearchContent={setDetailSearchContent}
      onClearSearchPanel={onClearSearchPanel}
      onDetailSearch={onDetailSearch}
    />
  )
}

export const UI: React.FC<Props> = ({
  isOpenHistoryModal,
  openHistoryModal,
  isOpenCreateModal,
  openCreateModal,
  advanceMoneyCompanyId,
  isDropDownOpen,
  setIsDropDownOpen,
  createCsv,
  listAdvanceMoney,
  isLoading,
  deleteItemMoney,
  showFooter,
  onCancel,
  onSaveData,
  pagination,
  getPaginateAdvanceMoney,
  deleteCompanyId,
  isSearchPanelOpen,
  setIsSearchPanelOpen,
  detailSearchContent,
  setDetailSearchContent,
  onClearSearchPanel,
  onDetailSearch,
}) => {
  const { t } = useTranslation()

  const searchedConditions = {
    [t('Payment date')]: `${dayjs(detailSearchContent.beginPaymentDate).format(t('YYYY-MM-DD'))} ~
    ${dayjs(detailSearchContent.endPaymentDate).format(t('YYYY-MM-DD'))}`,
    [t('Withdrawal date')]: `${dayjs(detailSearchContent.beginWithdrawalDate).format(t('YYYY-MM-DD'))} ~
    ${dayjs(detailSearchContent.endWithdrawalDate).format(t('YYYY-MM-DD'))}`,
    [t('Balance')]: selectBalanceTypeValue[detailSearchContent.balance],
    [t('Payee')]: detailSearchContent.companyName,
  }
  const { SearchedConditionValues } = useSearchedConditions({ searchedConditions })

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      {isOpenHistoryModal && <AdvanceMoneyHistoryModal setIsOpenModal={openHistoryModal} advanceMoneyCompanyId={advanceMoneyCompanyId} />}
      {isOpenCreateModal && (
        <AdvanceMoneyCreateModal setIsOpenModal={openCreateModal} fetchAdvanceMoneyList={() => getPaginateAdvanceMoney(1)} />
      )}
      <div css={mainColumnStyle}>
        <Header title={t('Sales management')} />
        <div css={containerStyle}>
          <div css={mainContainerStyle}>
            <div css={headerContainerStyle}>
              <SalesManagerTab currentTab="advance-money" />
            </div>
            <div css={showFooter ? listContainerHaveFooterStyle : listContainerStyle}>
              <div css={formWrapperStyle}>
                <div css={headerBarLeftWrapperStyle}>
                  <Button
                    width={107}
                    height={32}
                    buttonType={1}
                    marginRight={8}
                    icon={require('@/static/images/plus-white.svg')}
                    onClick={openCreateModal}
                  >
                    {t('Add new')}
                  </Button>
                  <Button width={107} height={32} buttonType={4} marginRight={8} onClick={() => setIsSearchPanelOpen(!isSearchPanelOpen)}>
                    {t('Detailed search')}
                  </Button>
                  {!isSearchPanelOpen && <SearchedConditionValues />}
                  {isSearchPanelOpen && (
                    <DetailSearchPanel
                      searchContent={detailSearchContent}
                      onChange={setDetailSearchContent}
                      onClear={onClearSearchPanel}
                      onSearch={onDetailSearch}
                    />
                  )}
                </div>
                <div css={rightButtonWrapperStyle}>
                  <Button
                    buttonType={4}
                    width={100}
                    height={32}
                    fontSize={12}
                    marginRight={12}
                    icon={require('@/static/images/csv.svg')}
                    onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                  >
                    CSV <img src={require('@/static/images/select_gray.svg')} css={dropDownIcon} />
                  </Button>
                  {isDropDownOpen && (
                    <div css={dropDownStyle}>
                      <ul>
                        <li
                          css={dropDownContentStyle}
                          onClick={() => {
                            createCsv()
                            setIsDropDownOpen(false)
                          }}
                        >
                          <p css={dropDownTitleStyle}>{t('Download')}</p>
                        </li>
                      </ul>
                    </div>
                  )}
                  <AccommodationPaging
                    page={pagination.page}
                    total={pagination.listTotal}
                    limit={pagination.limit}
                    setPage={page => {
                      getPaginateAdvanceMoney(page)
                    }}
                  />
                </div>
              </div>
              <div css={tableStyle}>
                <div css={listHeaderStyle}>
                  <div css={itemValue}>
                    <div css={headStyles.headIDStyle}>ID</div>
                    <div css={headStyles.headPaymentDateStyle}>{t('Payment date')}</div>
                    <div css={headStyles.headPayeeStyle}>{t('Payee')}</div>
                    <div css={headStyles.headEarningStyle}>{t('Earnings')}</div>
                    <div css={headStyles.headPaymentMethodStyle}>{t('Method of payment')}</div>
                    <div css={headStyles.headAccountsReceivableStyle}>{t('Accounts receivable')}</div>
                    <div css={headStyles.headScheduledCheckinDateStyle}>{t('Scheduled check-in date')}</div>
                    <div css={headStyles.headFinalWithdrawalDateStyle}>{t('Final withdrawal date')}</div>
                    <div css={headStyles.headInitialAmountStyle}>{t('Initial amount')}</div>
                    <div css={headStyles.headBalanceStyle}>{t('Balance')}</div>
                  </div>
                  <div css={headStyles.headActionStyle}></div>
                </div>
                <div css={listContentsWrapperStyle}>
                  <div css={listContentsStyle}>
                    {!!listAdvanceMoney?.length &&
                      listAdvanceMoney.map((item: AdvanceMoneyType, index) => (
                        <TableItem
                          index={index}
                          key={index}
                          item={item}
                          headStyles={headStyles}
                          deleteItemMoney={deleteItemMoney}
                          openHistoryModal={openHistoryModal}
                          deleteCompanyId={deleteCompanyId}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showFooter && (
            <div css={footerContainerStyle}>
              <Button buttonType={3} width={110} height={38} marginLeft={32} marginTop={11} marginBottom={11} onClick={onCancel}>
                {t('Cancel')}
              </Button>
              <Button
                buttonType={1}
                width={110}
                height={38}
                marginLeft={16}
                marginTop={11}
                marginBottom={11}
                fontWeight="normal"
                onClick={() => onSaveData()}
              >
                {t('Save')}
              </Button>
            </div>
          )}
        </div>
        <LoadingFull isLoading={isLoading} />
      </div>
    </div>
  )
}

const rightButtonWrapperStyle = css(headerBarRightWrapperStyle, {
  position: 'relative',
})

const formWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const listContentsWrapperStyle = css({
  overflowY: 'scroll',
  overflowX: 'hidden',
  maxHeight: 'calc(100vh - 300px)',
})

// CSV download
const dropDownStyle = css({
  width: 153,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 38,
  right: 120,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  fontSize: 14,
  color: '#272727',
})

const dropDownIcon = css({
  marginLeft: 8,
})

const containerStyle = css({
  display: 'flex',
  padding: 0,
  minWidth: '1000px',
})

const mainContainerStyle = css({
  width: '100%',
  height: 'calc(100vh - 55px)',
  padding: '24px 24px 26px 24px',
})

const listContainerStyle = css({
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: '0 5px 5px 5px',
  background: '#FFF',
  padding: 32,
  position: 'relative',
  height: 'calc(100vh - 150px)',
})

const listContainerHaveFooterStyle = css(listContainerStyle, {
  height: 'calc(100vh - 234px)',
})

const headerContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const tableStyle = css({
  marginTop: '24px',
  border: '1px solid #D9D9D9',
  borderRadius: '5px',
})

const listHeaderStyle = css({
  display: 'flex',
  height: 32,
  textAlign: 'left',
  color: '#676767',
  fontSize: 12,
  backgroundColor: '#F5F5F5',
  fontWeight: 'bold',
  padding: '0 40px 0 32px',
})

const headTitleStyle = css({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '310px',
  span: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '90%',
  },
})

const itemValue = css({
  flex: 1,
  display: 'flex',
})

const headStyles = {
  headIDStyle: css(headTitleStyle, { width: '4%' }),
  headPaymentDateStyle: css(headTitleStyle, { width: '11%' }),
  headPayeeStyle: css(headTitleStyle, { width: '13%' }),
  headEarningStyle: css(headTitleStyle, { width: '12%' }),
  headPaymentMethodStyle: css(headTitleStyle, { width: '13%' }),
  headAccountsReceivableStyle: css(headTitleStyle, { width: '13%' }),
  headScheduledCheckinDateStyle: css(headTitleStyle, { width: '11%' }),
  headFinalWithdrawalDateStyle: css(headTitleStyle, { width: '11%' }),
  headInitialAmountStyle: css(headTitleStyle, { width: '6%' }),
  headBalanceStyle: css(headTitleStyle, { width: '6%' }),
  headActionStyle: css(headTitleStyle, { width: '20px' }),
}

const footerContainerStyle = css({
  display: 'flex',
  height: 60,
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: '#FFF',
  boxShadow: '0px 1px 6px rba(0,0,0,0.10)',
})
