import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'

// contexts
import { AccountContext } from '@/contexts/account'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle } from '@/constants/layout'

// apis
import { fetchAroundSpots, updateAroundSpots, fetchFacilityBasicInfo } from '@/apis/aipass'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { SettingSideMenu } from '@/components/organisms/setting-side-menu'
import { FacilityAroundSpotList } from '@/components/pages/setting/around-spot/_around-spot/facility-around-spot-list'

// components
import { AroundSpotType } from '@/models/around-spot'

type ContainerProps = {}
type CsvDataType = (string | number | undefined)[][]
type Props = {
  isLoading: boolean
  aroundSpot: AroundSpotType[]
  onFileLoad: (data) => void
  basicInfo: any
  isOpenEditPage: boolean
  setIsOpenEditPage: (boolean) => void
  isSaved: boolean
  setIsSaved: (boolean) => void
  csvData: CsvDataType
}

type Csv = {
  aroundSpots: AroundSpotType[]
}

const csvDataHeader = ['id', 'name', 'category', 'telephone', 'address', 'comment']

const parseOptions = {
  header: true,
  skipEmptyLines: true,
}

export const SettingAroundSpot: React.FC<ContainerProps> = () => {
  const { t, i18n } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const [aroundSpot, setAroundSpot] = useState<AroundSpotType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isCsvUploaded, setIsCsvUpload] = useState<boolean>(false)
  const [basicInfo, setBasicInfo] = useState<any>()
  const [isOpenEditPage, setIsOpenEditPage] = useState<boolean>(false)
  const [csvData, setCsvData] = useState<CsvDataType>([csvDataHeader])
  const [isSaved, setIsSaved] = useState<boolean>(false)

  // Basic information acquisition processing
  const _fetchFacilityBasicInfo = async () => {
    await fetchFacilityBasicInfo()
      .then(res => {
        if (res !== undefined) {
          setBasicInfo(res?.basicInfo)
          if (isSaved) {
            setIsOpenEditPage(!isOpenEditPage)
            setIsLoading(false)
          }
        }
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const _fetchAroundSpots = async () => {
    setIsLoading(true)
    await fetchAroundSpots(account.hotel.id).then(res => {
      setAroundSpot(res)
      setIsLoading(false)
      setIsCsvUpload(false)
    })
  }

  const _onFileLoad = data => {
    setIsLoading(true)
    const uploadData: Csv = {
      aroundSpots:
        data.map(function(value) {
          if (!value.name) return
          if (value.category === '食べる' || value.category === 'Food') {
            value.category = 1
          } else if (value.category === '遊ぶ' || value.category === 'Activities') {
            value.category = 2
          } else if (value.category === '買う' || value.category === 'Shopping') {
            value.category = 3
          } else {
            value.category = 0
          }
          return {
            spotId: value.id,
            facilityName: value.name,
            category: value.category,
            telephone: value.telephone,
            address: value.address,
            comment: value.comment,
          }
        }) || [],
    }

    const hasVaildError = uploadData.aroundSpots.find(
      (spot: AroundSpotType) =>
        [spot?.spotId, spot?.facilityName, spot?.category, spot?.telephone, spot?.address, spot?.comment].includes(undefined) ||
        !spot?.spotId,
    )
    if (hasVaildError) {
      alert(t('Incorrect file check it again please'))
      setIsLoading(false)
      return
    }

    updateAroundSpots(uploadData.aroundSpots)
      .then(res => {
        if (res && res?.errSpotIds) {
          const error =
            i18n.language === 'ja'
              ? res?.errSpotIds.join(',') + ' の施設情報が登録できませんでした'
              : 'Unable to register ' + res?.errSpotIds.join(',')
          alert(error)
        }
        setIsCsvUpload(true)
      })
      .catch((e: string) => {
        console.log(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (account && account?.hotel) {
      _fetchAroundSpots()
      _fetchFacilityBasicInfo()
    }
  }, [account])

  useEffect(() => {
    // Reacquire when CSV loading is complete
    if (isCsvUploaded && account) {
      _fetchAroundSpots()
    }
  }, [isCsvUploaded])

  useEffect(() => {
    // Reacquire after saving on the edit screen
    if (isSaved && account) {
      _fetchFacilityBasicInfo()
      setIsSaved(!isSaved)
    }
  }, [isSaved])

  useEffect(() => {
    const categories = {
      1: t('Food'),
      2: t('Activities'),
      3: t('Shopping'),
    }
    setCsvData([
      csvDataHeader,
      ...aroundSpot.map(item => [item.spotId, item.facilityName, categories[item.category], item.telephone, item.address, item.comment]),
    ])
  }, [aroundSpot])

  return (
    <UI
      isLoading={isLoading}
      aroundSpot={aroundSpot}
      onFileLoad={_onFileLoad}
      basicInfo={basicInfo}
      isOpenEditPage={isOpenEditPage}
      setIsOpenEditPage={setIsOpenEditPage}
      isSaved={isSaved}
      setIsSaved={setIsSaved}
      csvData={csvData}
    />
  )
}

export const UI: React.FC<ContainerProps & Props> = ({
  isLoading,
  aroundSpot,
  onFileLoad,
  basicInfo: _basicInfo,
  isOpenEditPage,
  setIsOpenEditPage: _setIsOpenEditPage,
  isSaved: _isSaved,
  setIsSaved: _setIsSaved,
  csvData,
}) => {
  const { t } = useTranslation()
  const categoryName = id => {
    if (id === 0) {
      return t('Hotel')
    }
    if (id === 1) {
      return t('Food')
    }
    if (id === 2) {
      return t('Activities')
    }
    if (id === 3) {
      return t('Shopping')
    }
  }
  const windows = useWindowSize()
  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header title={t('Setting')} isPublicPageLink />
        <div className="mainContainer" css={settingContainerStyle}>
          <SettingSideMenu />
          {isOpenEditPage === false && (
            <div
              css={settingMainContainerStyle}
              style={{
                minHeight: windows.height - 103,
              }}
            >
              <FacilityAroundSpotList
                aroundSpot={aroundSpot}
                categoryName={categoryName}
                csvData={csvData}
                parseOptions={parseOptions}
                onFileLoad={onFileLoad}
              />
            </div>
          )}
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const settingContainerStyle = css(mainContainerStyle, {
  display: 'flex',
  padding: 0,
})

const settingMainContainerStyle = css({
  width: 'calc(100% - 180px)',
  padding: 24,
})
