import React, { useEffect, useMemo, useRef, useState, ComponentProps } from 'react'
import { SerializedStyles, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { CropModal } from './crop-modal'

type Props = Omit<ComponentProps<'div'>, 'onChange'> & {
  value?: string
  onChange: (value: string) => void
  onShowModal: (isShowModal: boolean) => void
  customStyle?: SerializedStyles
  aspect?: number
  error?: string
}

export const InputFileImage: React.FC<Props> = ({ value, onChange, onShowModal, aspect, error, customStyle, ...props }) => {
  const { t } = useTranslation()
  const [isShowCropModal, setIsShowCropModal] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState<string | ArrayBuffer>()
  const [uploadedImage, setUploadedImage] = useState<string | ArrayBuffer | undefined>(value)
  const inputFileRef = useRef<HTMLInputElement>(null)

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files?.length) {
      return
    }
    if (files[0].type !== 'image/jpeg' && files[0].type !== 'image/png') {
      window.alert(t('The only image formats that can be used are jpg and png'))
      return
    }
    const file = files[0]
    const reader = new FileReader()
    reader.onload = e => {
      const imageUrl = e.target ? e.target.result : null
      if (imageUrl) {
        setSelectedImage(String(imageUrl))
        setIsShowCropModal(true)
      }
    }
    reader.readAsDataURL(file)
  }

  useEffect(() => {
    onShowModal && onShowModal(isShowCropModal)
  }, [isShowCropModal])

  const widthRate = useMemo(() => (aspect && aspect > 1 ? aspect : 1), [aspect])
  const heightRate = useMemo(() => (aspect && aspect <= 1 ? aspect : 1), [aspect])

  const pictureBackgroundSizeStyle = useMemo(() => {
    return css({
      width: 80 * widthRate,
      height: 80 * heightRate,
      '.uploaded-picture, .upload-place-picture': {
        position: 'absolute',
        width: 80 * widthRate,
        height: 80 * heightRate,
      },
    })
  }, [widthRate, heightRate])

  return (
    <>
      <div
        css={[pictureBackgroundStyle, pictureBackgroundSizeStyle, customStyle]}
        onClick={() => {
          if (inputFileRef.current) {
            inputFileRef.current.value = ''
            inputFileRef.current.click()
          }
        }}
        {...props}
      >
        <div className="uploaded-picture" style={{ backgroundImage: `url(${uploadedImage}` }}></div>
        <div
          className="upload-place-picture"
          style={{
            backgroundColor: uploadedImage ? 'rgba(0, 0, 0,0.3)' : '#CCCCCC',
            border: error ? '2px solid red' : 'none',
          }}
        ></div>
      </div>
      <input type="file" ref={inputFileRef} onChange={e => handleChangeFile(e)} style={{ display: 'none' }} />

      {isShowCropModal && (
        <CropModal
          value={String(selectedImage)}
          onUpload={uploadImage => {
            setUploadedImage(uploadImage)
            onChange(uploadImage)
          }}
          onClose={() => setIsShowCropModal(false)}
          aspect={aspect}
        />
      )}
    </>
  )
}

const pictureBackgroundStyle = css({
  position: 'relative',
  '.uploaded-picture, .upload-place-picture': {
    position: 'absolute',
    borderRadius: 5,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    cursor: 'pointer',
  },
  '.uploaded-picture': {
    backgroundSize: 'contain',
  },
  '.upload-place-picture': {
    backgroundImage: `url(${require('@/static/images/camera_icon_white.svg')})`,
    backgroundSize: 17,
  },
})
