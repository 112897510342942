import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

type CustomCheckinProps = {
  index: number
  question: any
  goToDetail: (id?: string) => void
  updateViewStatus: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void
  deleteCustomCheckin: (id: string, e: React.ChangeEvent<HTMLInputElement>) => void
}

export const CustomCheckinList: React.FC<CustomCheckinProps> = ({ question, index, goToDetail, deleteCustomCheckin, updateViewStatus }) => {
  const { t } = useTranslation()
  return (
    <div
      css={customCheckinInfoListContainerStyle}
      onClick={e => {
        if (
          e.target === document.getElementById('deleteButton') ||
          e.target === document.getElementById(`${question.customCheckinId}`) ||
          e.target === document.getElementById(`${String(index)}`)
        ) {
          return
        }
        goToDetail(question.customCheckinId)
      }}
    >
      <div css={customCheckinInfoListNameStyle}>{question.jpTitle}</div>
      <div css={customCheckinInfoListRightContainerStyle}>
        <input
          id={question.customCheckinId}
          name="viewStatus"
          type="checkbox"
          css={customCheckinCheckboxStyle}
          onChange={e => updateViewStatus(e, question.customCheckinId)}
          checked={question?.viewStatus ? true : false}
        />
        <label id={String(index)} htmlFor={question.customCheckinId} css={customCheckinCheckboxLabelStyle} />
        <div css={deleteButtonStyle} onClick={(e: any) => deleteCustomCheckin(question.customCheckinId, e)}>
          <img id="deleteButton" src={require('@/static/images/delete_yellow.svg')} alt={t('Delete')} />
        </div>
      </div>
    </div>
  )
}

const customCheckinInfoListContainerStyle = css({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 64,
  borderBottom: '1px solid #F2F2F2',
  padding: '0 32px',
  ':hover': {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.06)',
  },
})

const customCheckinInfoListNameStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  width: '100%',
  padding: '25px 0px',
  height: '100%',
})

const customCheckinInfoListRightContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
})

export const customCheckinCheckboxStyle = css({
  display: 'none',
  ':checked': {
    '+label': {
      backgroundColor: '#ff5500',
      border: 'none',
      position: 'relative',

      '&::before': {
        content: "'公開'",
        color: '#fff',
        fontSize: 12,
        letterSpacing: 1.2,
        left: 15,
        position: 'absolute',
      },
      '&::after': {
        top: 3,
        left: 47,
        backgroundColor: '#fff',
      },
    },
  },
})

export const customCheckinCheckboxLabelStyle = css({
  width: 73,
  height: 28,
  background: '#F2F2F2',
  position: 'relative',
  display: 'inline-block',
  borderRadius: 14,
  transition: '0.4s',
  boxSizing: 'border-box',
  border: '1px solid #ccc',
  cursor: 'pointer',

  '&::before': {
    content: "'非公開'",
    fontWeight: 'bold',
    color: '#a3a3a3',
    fontSize: 11,
    letterSpacing: 0.55,
    left: 30,
    position: 'absolute',
    top: 7,
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    width: 22,
    height: 22,
    borderRadius: '100%',
    top: 2,
    left: 4,
    zIndex: 2,
    background: '#fff',
    transition: '0.4s',
    boxShadow: '0px 2px 3px #00000029',
  },
})

const deleteButtonStyle = css({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  img: {
    width: 30,
    height: 30,
    display: 'block',
    marginLeft: 25,
    '&:hover': {
      borderRadius: '50%',
      background: '#f2f2f2',
      cursor: 'pointer',
    },
  },
})
