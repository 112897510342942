import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

// components
import { Button } from '@/components/atoms/button'
import { salesMainContainerStyle as reportMainContainerStyle, containerTitleStyle } from '@/components/pages/sales-manager/sales/_detail/sales'
import { saveButtonWrapperStyle } from '@/components/pages/sales-manager/sales/_detail/cash'

// models
import { ReportType } from '@/models/sales-manager/detail'

type ReportProps = {
  reports: ReportType[]
  inputReport: string
  isReportChanged: boolean
  isConfirm: boolean
  mode: 'daily' | 'month'
  onChangeReport: (report: string) => void
  onClickSaveReport: () => void
}

export const Report: React.FC<ReportProps> = ({
  reports,
  inputReport,
  isReportChanged,
  isConfirm,
  onChangeReport,
  onClickSaveReport,
  mode,
}) => {
  const { t } = useTranslation()
  return (
    <div css={reportMainContainerStyle}>
      <div css={containerTitleStyle}>{mode === 'daily' ? t('Daily report') : t('Monthly report')}</div>
      <div css={(reports?.length === 0 || !isConfirm) && sectionStyle} />
      {!isConfirm && (
        <div css={reportWrapperStyle}>
          <TextareaAutosize placeholder={t('Add daily report')} value={inputReport} onChange={v => onChangeReport(v.target.value)} minRows={4} />
          {isReportChanged && (
            <div css={saveButtonWrapperStyle}>
              <Button width={80} height={38} buttonType={1} onClick={onClickSaveReport}>
                {t('Save')}
              </Button>
            </div>
          )}
        </div>
      )}
      {!!reports?.length && reports.map((report, index) => (
          <div key={index} css={reportSectionStyle}>
            <p css={reportTimeStyle}>{dayjs(report?.createdAt).format('YYYY/MM/DD')}</p>
            <div css={reportTextWrapperStyle}>
              <p
                css={reportTextStyle}
                dangerouslySetInnerHTML={{
                  __html: report?.letter?.replace(/[\n\r]/g, '<br />'),
                }}
              />
            </div>
          </div>
        ))}
      {reports?.length === 0 && !!isConfirm && <div css={reportEmptySectionStyle} />}
    </div>
  )
}

const reportWrapperStyle = css({
  padding: '24px 32px',
  textarea: {
    width: '100%',
    height: 130,
    border: '1px solid #cccccc',
    borderRadius: 12,
    marginBottom: 16,
    resize: 'none',
    padding: 16,
    fontSize: 14,
    '::placeholder': {
      color: '#cccccc',
    },
  },
})

const sectionStyle = css({
  borderBottom: '1px solid #f2f2f2',
})

const reportSectionStyle = css({
  backgroundColor: '#FFF',
  borderTop: '1px solid #f2f2f2',
})

const reportEmptySectionStyle = css({
  height: 185,
})

const reportTimeStyle = css({
  padding: '24px 32px 0',
  fontSize: 12,
  color: '#676767',
})

const reportTextWrapperStyle = css({
  margin: '16px 32px 0 32px',
  lineHeight: '22px',
  paddingBottom: 24,
})

const reportTextStyle = css({
  fontSize: 14,
  color: '#272727',
  lineHeight: '22px',
  fontWeight: 400,
  margin: 0,
  wordBreak: 'break-word'
})
