export const FileType = {
  Reservation: 1,
  Ticket: 2,
} as const
export type FileType = typeof FileType[keyof typeof FileType]

export const FileMessageType = {
  1: 'Upload file type reservation',
  2: 'Upload file type ticket',
}

export type CsvUploadHistoryResponseType = {
  id: string
  hotelId: string
  fileType: FileType
  uploadStatus: boolean
  fileName: string
  deviceName: string
  errorMessage: string
  createdAt: Date
  updatedAt: Date
}
