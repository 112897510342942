import { ContentBlock, ContentState, DraftInlineStyle, EditorState, Modifier } from 'draft-js'
import * as Immutable from 'immutable'
import { StyleType } from '../../../organisms/settings/editor'

export const insertBlock = (state: EditorState, insertBlock: ContentBlock[]): EditorState => {
  const content = state.getCurrentContent()
  const blockMap = content.getBlockMap()
  const selection = state.getSelection()
  const pivotBlockKey = state.getSelection().getAnchorKey()
  const block = blockMap.get(pivotBlockKey)

  const blocksBefore = blockMap.toSeq().takeUntil(v => v === block)
  const blocksAfter = blockMap
    .toSeq()
    .skipUntil(v => v === block)
    .rest()
  const newBlockMap = blocksBefore
    .concat([[pivotBlockKey, block], ...insertBlock.map(block => [block.getKey(), block])], blocksAfter)
    .toOrderedMap()

  const newContent = content.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection.merge({
      anchorKey: block.getKey(),
      anchorOffset: 0,
      focusKey: block.getKey(),
      focusOffset: 0,
      isBackward: true,
    }),
  }) as ContentState
  return EditorState.push(state, newContent, 'split-block')
}

export const insertTextInSelectedState = (
  editorState: EditorState,
  text: string,
  entityKey?: string,
  inlineStyle?: DraftInlineStyle,
): {
  contentState: ContentState
  newEditorState: EditorState
} => {
  const contentState = Modifier.insertText(editorState.getCurrentContent(), editorState.getSelection(), text, inlineStyle, entityKey)
  const changedEditorState = EditorState.push(editorState, contentState, 'insert-characters')
  return {
    contentState,
    newEditorState: changeSelectorToCurrent(changedEditorState, text.length),
  }
}

export const changeSelectorToCurrent = (state: EditorState, focusLength: number = 0): EditorState => {
  const selector = {
    anchorOffset: state.getSelection().getEndOffset() - focusLength,
    focusOffset: state.getSelection().getEndOffset(),
    anchorKey: state.getSelection().getFocusKey(),
    focusKey: state.getSelection().getFocusKey(),
  }
  const newSelectionState = state.getSelection().merge(selector)
  return EditorState.forceSelection(state, newSelectionState)
}

export const changeBlockData = (state: EditorState, editBlockKey: string, blockData) => {
  const selector = { anchorKey: editBlockKey, focusKey: editBlockKey }
  const buttonBlockSelector = state.getSelection().merge(selector)
  const changedSelectionState = EditorState.forceSelection(state, buttonBlockSelector)
  const editedButtonBlock = Modifier.mergeBlockData(
    changedSelectionState.getCurrentContent(),
    changedSelectionState.getSelection(),
    Immutable.Map(blockData),
  )
  return EditorState.push(changedSelectionState, editedButtonBlock, 'change-block-data')
}

export const removeBlock = (editorState: EditorState, blockKey: string) => {
  const contentState = editorState.getCurrentContent()
  const removedBlockMap = contentState.getBlockMap().remove(blockKey)
  const newContentState = contentState.set('blockMap', removedBlockMap) as ContentState
  return EditorState.push(editorState, newContentState, 'backspace-character')
}

export const isSelectingWord = (state: EditorState) => {
  return state.getSelection().getStartOffset() !== state.getSelection().getEndOffset()
}

export const isFocusedLastBlock = (state: EditorState) =>
  state.getCurrentContent().getLastBlock().getKey() === state.getSelection().getAnchorKey()

export const isActiveStyleType = (state: EditorState, styleType: StyleType) => {
  const blockStyle = state.getCurrentContent().getBlockForKey(state.getSelection().getStartKey())?.getType()
  if (!blockStyle) {
    return false
  }
  return state.getCurrentInlineStyle().has(styleType) || blockStyle === styleType
}

export const getFocusBlock = (state: EditorState): ContentBlock | undefined => {
  const focusKey = state.getSelection().getFocusKey()
  if (!focusKey) {
    return
  }
  return state.getCurrentContent().getBlockMap().get(focusKey)
}

export const splitBlock = (state: EditorState) => {
  const splittedContent = Modifier.splitBlock(state.getCurrentContent(), state.getSelection())
  return EditorState.push(state, splittedContent, 'split-block')

}