import React from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

// constants
import { HEADER_HEIGHT, MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL, SETTING_MANAGER_LIST_MARGIN } from '@/constants/layout'

// models
import { AccountsReceivable } from '@/models/accounts-receivable'

type Props = {
  paymentSettings: any
}

export const SettingPaymentList: React.FC<Props> = ({ paymentSettings }) => {
  const windows = useWindowSize()
  const { t } = useTranslation()

  return (
    <>
      <div css={paymentListHeaderStyle}>
        <div css={smallWidthStyle}>{t('Display order')}</div>
        <div css={smallWidthStyle}>{t('Receipt ID')}</div>
        <div css={largeWidthStyle}>{t('Method of payment')}</div>
        <div css={smallWidthStyle}>{t('Commission rate')}</div>
        <div css={smallWidthStyle}>{t('Outstanding sales')}</div>
        <div css={smallWidthStyle}>{t('Tax rate')}</div>
        <div css={smallWidthStyle}>{t('Tax classification')}</div>
      </div>
      <ul
        css={paymentListStyle}
        style={{
          maxHeight: windows.height - HEADER_HEIGHT - MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL - SETTING_MANAGER_LIST_MARGIN + 22,
        }}
      >
        {paymentSettings &&
          paymentSettings.map((paymentSetting, index) => (
            <li key={index}>
              <div css={smallWidthStyle}>{paymentSetting.displayOrder}</div>
              <div css={smallWidthStyle}>{paymentSetting.methodCode ? paymentSetting.methodCode : '-'}</div>
              <div css={largeWidthStyle}>{paymentSetting.name}</div>
              <div css={smallWidthStyle}>{paymentSetting.commissionRate !== null ? `${paymentSetting.commissionRate}%` : '-'}</div>
              <div css={smallWidthStyle}>
                {paymentSetting.isAccountsReceivable === AccountsReceivable.AccountsReceivable ? t('Outstanding sales') : '-'}
              </div>
              <div css={smallWidthStyle}>{paymentSetting.tax ? paymentSetting.tax : '-'}%</div>
              <div css={smallWidthStyle}>{paymentSetting.taxClass ? paymentSetting.taxClass : '-'}</div>
            </li>
          ))}
      </ul>
    </>
  )
}

const largeWidthStyle = css({
  width: 500,
})

const smallWidthStyle = css({
  width: 120,
})

const paymentListHeaderStyle = css({
  background: '#F5F5F5',
  color: '#676767',
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  padding: '10px 30px',
})

const paymentListStyle = css({
  overflowX: 'hidden',
  height: '70vh',
  maxHeight: 357,
  li: {
    display: 'flex',
    padding: '16px 22px 16px 30px',
    color: '#272727',
    fontSize: 14,
    borderBottom: '1px #F2F2F2 solid',
  },
})
