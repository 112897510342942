import React from 'react'
import { css } from '@emotion/core'

type Props = {
  label: string
  required?: boolean
  error?: any
}

export const Label: React.FC<Props> = ({ label, required, error }) => {
  return (
    <p css={[labelStyle, error ? { color: 'red' } : {}]}>
      {label} {required && '*'}
    </p>
  )
}
const labelStyle = css({
  color: '#676767',
  fontSize: 12,
  letterSpacing: 0.6,
  marginBottom: 11,
  fontWeight: 'bold',
})
