import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { TableHeader, TableBody, TableRow } from '@/components/atoms/settings/table'
import { Control, Controller, useFieldArray } from 'react-hook-form'
import { ListType } from '@/components/pages/setting/guest-room/key-number'

type Props = {
  control: Control<{ assign: ListType[] }, any>
  onChange: () => void
}

export const SettingManualGuestRoomKeyList: React.FC<Props> = ({ control, onChange: parentOnChange }) => {
  const { t } = useTranslation()

  const assignForm = useFieldArray({
    control,
    name: 'assign',
  })

  return (
    <>
      <TableHeader>
        <div css={roomTypeWidthStyle}>{t('Room type')}</div>
        <div css={floorNumberWidthStyle}>{t('Number of floors')}</div>
        <div css={roomNameWidthStyle}>{t('Room name')}</div>
        <div css={roomNumberWidthStyle}>{t('Key number')}</div>
      </TableHeader>
      <TableBody>
        {assignForm.fields.map((room, i) => (
          <Controller
            key={room.id}
            name={`assign.${i}.keyNumber`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <TableRow css={css({ '> div': { fontWeight: 500 } })}>
                <div css={roomTypeWidthStyle}>
                  {assignForm.fields[i].typeNameJa === assignForm.fields[i - 1]?.typeNameJa ? '' : assignForm.fields[i].typeNameJa}
                </div>
                <div css={floorNumberWidthStyle}>{room.floorNameJa}</div>
                <div css={roomNameWidthStyle}>{room.roomNumber}</div>
                <div css={roomNumberWidthStyle}>
                  <input
                    name={name}
                    type="text"
                    placeholder="0000"
                    css={inputStyle}
                    value={value}
                    onChange={e => {
                      onChange(e)
                      parentOnChange()
                    }}
                  />
                </div>
              </TableRow>
            )}
          />
        ))}
      </TableBody>
    </>
  )
}

export const roomTypeWidthStyle = css({
  width: '28%',
  paddingLeft: 32,
})
const floorNumberWidthStyle = css({ width: '10%' })
const roomNameWidthStyle = css({ width: '22%' })
const roomNumberWidthStyle = css({ width: '40%' })
const inputStyle = css({
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  padding: '0 16px',
  '&::placeholder': {
    color: '#CCCCCC',
  },
})
