import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { AssetType, NewsDetailType } from '@/models/guest-app/asset'
import { Controller, useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'
import { InputField } from '@/components/molecules/input-field'
import { Editor } from '@/components/organisms/settings/editor'
import { createAssetDetail, updateAssetDetail } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { v4 as uuid } from 'uuid'

type Props = {
  asset: AssetType
  detail: NewsDetailType | null
  onCancel: () => void
  onSave: () => void
}

export type AssetNewsDetailFormValue = {
  title: string
  description: string
  rawDescription: string
}

export const NewsDetailModal: React.FC<Props> = ({ asset, detail, onCancel, onSave: parentSave }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<AssetNewsDetailFormValue>({
    defaultValues: {
      title: detail?.title || '',
      description: detail?.description || '',
      rawDescription: detail?.rawDescription || '',
    },
  })
  const { doReload, setIsLoading } = useContext(AssetDetailContext)
  const [isShowCropModal, setIsShowCropModal] = useState<boolean>(false)
  const { errorHandler } = useErrorHandler()
  const editorId = useMemo(() => uuid(), [])

  const onSave = async (value: AssetNewsDetailFormValue) => {
    try {
      setIsLoading(true)
      if (detail) {
        await updateAssetDetail(asset.id, detail.id, asset.formatType, value)
      } else {
        await createAssetDetail(asset.id, asset.formatType, value)
      }
      doReload()
      parentSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal customCss={modalStyle} style={{ visibility: isShowCropModal ? 'hidden' : 'visible' }}>
        <ModalHeader>{t('GuestAppSetting.CreateNews')}</ModalHeader>
        <ModalBody>
          <div css={modalBodyStyle}>
            <div className="left-panel">
              <div css={inputTitleTextStyle}>
                {t('Title')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="title"
                render={({ field: { onChange, value } }) => (
                  <InputField value={value} handleChangeData={onChange} error={errors.title?.message} placeholder={t('Title name')} />
                )}
              />
            </div>
            <div className="right-panel">
              <div css={inputTitleTextStyle}>{t('Explanatory text')}</div>
              <Controller
                control={control}
                name="rawDescription"
                render={({ field: { value } }) => (
                  <Editor
                    id={editorId}
                    rawContent={value}
                    onChange={(raw: string, html: string) => {
                      setValue('rawDescription', raw)
                      setValue('description', html)
                    }}
                    // sub inputTitleText height
                    style={{ height: 'calc(100% - 24px)' }}
                  />
                )}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(onSave)}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const modalStyle = {
  height: 622,
  width: 1200,
  minWidth: 1200,
  left: 'calc((100% - 1200px) / 2)',
}

const modalBodyStyle = {
  padding: 32,
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  height: '100%',
  display: 'flex',
  '.left-panel': {
    width: '50%',
    borderRight: '1px solid #F2F2F2',
    paddingRight: 35.5,
  },
  '.right-panel': {
    width: '50%',
    paddingLeft: 30.5,
    display: 'flex',
    flexFlow: 'column',
  },
}

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
})
