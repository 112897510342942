import { MemoWithCategory } from "@/models/reservation/reservation-note"

export type RoomType = {
  guestRoomId: string
  roomNumber: string
  floorNameJa: string
  floorNameEn: string
  cleaningStatus: CleaningStatusType
  roomUsageStatus: RoomUsageStatusType
  adminComment: string
  staffReport: string
  cleanedAt: string | null
  checkList: Array<{ id: number; isChecked: boolean }>
  nextReservation: ReservationType | null
  photos: Array<{ path: string }>
}

export type ReservationType = {
  nights: number
  stayDays: number
  roomPax: {
    male: number
    female: number
    childA: number
    childB: number
    childC: number
    childD: number
    childE: number
    childF: number
    childOther: number
    total: number
  }
  roomCheckinAt: string
  roomCheckoutAt: string
  notes: MemoWithCategory[]
}

export const CleaningStatusType = {
  Unused: 0,
  NotCleaning: 1,
  Cleaned: 2,
  Inspected: 3,
  DoNotDisturb: 4,
  NotRequired: 5,
} as const
export type CleaningStatusType = (typeof CleaningStatusType)[keyof typeof CleaningStatusType]

export const RoomUsageStatusType = {
  Unused: 1,
  Stay: 2,
  Away: 3,
  CheckOutScheduled: 4,
  CheckedOut: 5,
} as const
export type RoomUsageStatusType = (typeof RoomUsageStatusType)[keyof typeof RoomUsageStatusType]

export type CheckListType = {
  id: number
  name: string
  displayOrder: number
}
export type CheckListFormType = {
  name: string
  displayOrder: number
}
