import React, { useState, useEffect, useContext } from 'react'
import { SettingManualGuestRoomKeyList } from '@/components/pages/setting/guest-room/key-number/_list/manual-guest-room-key-list'
import { SettingRemoteLockGuestRoomKeyList } from '@/components/pages/setting/guest-room/key-number/_list/remotelock-guest-room-key-list'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { AccountInfo, LockSetting, LockType, RoomKindType } from '@/models/lock-setting'
import { LinkSettingContainer } from '@/components/organisms/settings/smart-key/link-setting-container'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { RemoteLockProvider } from '@/contexts/remote-lock'
import { LoaderContextCreator } from '@/contexts/loader'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useForm } from 'react-hook-form'
import { EditFooter } from '@/components/organisms/edit-footer'
import { SmartKeyTab } from '@/components/molecules/settings/smart-key-tab'
import { SettingMiwaLock } from '@/components/organisms/customer/miwa-lock/setting-miwa-lock'
import {
  fetchGuestRoomKey,
  getLockSettings,
  getSmartLockAccount,
  postClientCoordination,
  updateGuestRoomKey,
  updateLockSettings,
} from '@/apis/aipass'
import { SettingSwitchBotGuestRoomKeyList } from './_list/switch-bot-guest-room-key-list'

export type EditGuestRoomDeviceType = { guestRoomId: string; deviceId: string | null }[]

export type ListType = {
  id: string
  typeNameJa: string
  floorNameJa: string
  roomNumber: string
  keyNumber: number | undefined
  remoteDeviceId: string | undefined
}

export const SettingGuestRoomKey: React.FC = () => {
  const { setIsLoading } = useContext(LoaderContextCreator())
  const { errorHandler } = useErrorHandler()
  const { code } = require('query-string').parse(window.location.search)

  const [isShowFooter, setIsShowFooter] = useState<boolean>(false)
  const [selectRoomKey, setSelectRoomKey] = useState<LockType>(LockType.Manual)
  const [lockSetting, setLockSetting] = useState<LockSetting>()
  const [accountInfos, setAccountInfos] = useState<AccountInfo[]>()
  const [initFormValue, setInitFormValue] = useState<{ assign: ListType[] }>()

  const { control, reset, getValues } = useForm<{ assign: ListType[] }>()

  const doSave = async () => {
    setIsLoading(true)
    try {
      await updateLockSettings({
        roomKind: RoomKindType.GuestRoom,
        lockType: selectRoomKey,
      })
      await updateGuestRoomKey(getValues().assign)
      setInitFormValue(getValues())
      setIsShowFooter(false)
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  const initialView = () => {
    initializeState()
    setIsShowFooter(false)
  }

  const initializeState = () => {
    fetchGuestRoomKey().then(res => {
      const settings = res.map(room => ({
        id: room.id,
        typeNameJa: room.typeNameJa,
        floorNameJa: room.floorNameJa,
        roomNumber: room.roomNumber,
        keyNumber: room.keyNumber || '',
        remoteDeviceId: room.remoteDeviceId || '',
      }))
      setInitFormValue({ assign: settings })
      reset({ assign: settings })
    })
    getLockSettings().then(settings => {
      const currentSetting = settings.find(setting => setting.roomKind === RoomKindType.GuestRoom)
      setLockSetting(currentSetting)
      currentSetting && setSelectRoomKey(currentSetting.lockType)
    })
    getSmartLockAccount().then(setAccountInfos)
  }

  const coordinationRemoteLock = async (code: string) => {
    try {
      setIsLoading(true)
      await postClientCoordination(code)
      const url = new URL(window.location.href)
      url.searchParams.delete('code')
      window.history.replaceState('', '', url.href)
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  const clearOldDeviceId = (changedLockType: LockType) => {
    if (!lockSetting) {
      return
    }
    if (changedLockType === lockSetting.lockType) {
      reset(initFormValue)
    } else {
      const settings = getValues().assign.map(room => ({
        ...room,
        remoteDeviceId: '',
      }))
      reset({ assign: settings })
    }
  }

  const footerContent = isShowFooter ? <EditFooter onSave={doSave} onCancel={initialView} /> : undefined

  useEffect(() => {
    initializeState()
  }, [])

  useEffect(() => {
    if (code) coordinationRemoteLock(code)
  }, [code])

  return (
    <RemoteLockProvider>
      <SettingsLayout loading={false} footerContent={footerContent}>
        <TabContainer tabComponent={<SmartKeyTab currentTab="Guest room" />} isShowFooter={isShowFooter}>
          <LinkSettingContainer
            selectRoomKey={selectRoomKey}
            onChangeType={(v: LockType) => {
              setSelectRoomKey(v)
              clearOldDeviceId(v)
              setIsShowFooter(true)
            }}
          />
          {selectRoomKey === LockType.Manual && <SettingManualGuestRoomKeyList control={control} onChange={() => setIsShowFooter(true)} />}
          {selectRoomKey === LockType.RemoteLock && (
            <SettingRemoteLockGuestRoomKeyList control={control} onChange={() => setIsShowFooter(true)} />
          )}
          {selectRoomKey === LockType.SwitchBot && (
            <SettingSwitchBotGuestRoomKeyList
              accountInfo={accountInfos?.find(account => account.lockType === LockType.SwitchBot)}
              control={control}
              onChange={() => setIsShowFooter(true)}
            />
          )}
          {selectRoomKey === LockType.MiwaLock && <SettingMiwaLock />}
        </TabContainer>
      </SettingsLayout>
    </RemoteLockProvider>
  )
}
