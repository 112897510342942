import React, { useContext, useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import 'react-dates/initialize'

// contexts
import { AccountContext } from '@/contexts/account'

// constants
import {
  mainContainerHeaderStyle,
  headerBarLeftWrapperStyle,
  tableHeaderThStyle,
  tableBodyTdStyleRead,
  tableBodyTdStyleUnread,
  tableBodyTdStyle,
} from '@/constants/layout'

// apis
import {
  fetchReservationStatus,
  fetchReservationTlApi,
  fetchReservationTemairazuApi,
  fetchReservationNeppanApi,
  updateSelfCheckin,
  fetchAdminReservationForCheckin,
  insertAdminReservationCheckin,
  updateSalesAdvancePayment,
} from '@/apis/aipass'

// components
import { Button } from '@/components/atoms/button'
import { ReloadButton } from '@/components/atoms/reload-button'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { reloadWrapperStyle, guestNameUnreadEnStyle, guestNameUnreadJaStyle } from '@/components/pages/accommodation-management/list-layout'
import { DetailedReserchPanel } from '@/components/pages/accommodation-management/_reservation/detailed-reserch-panel'
import { getSearchedConditions } from '../search-condition'
import { useSearchedConditions } from '@/hooks/use-search-condition'

// libs
import { dividedRoomNumber, dividedTypeName } from '@/libs/divide-list'

// models
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { ReservationCreateModal } from '@/components/organisms/accommodation-management/reservation-create-modal'
import { ReservationCreateWithRoomModal } from '@/components/organisms/accommodation-management/reservation-create-with-room-modal'
import { setHasRoomManagerPlugin } from '@/libs/plugins'

type ContainerProps = {
  reservationList: any
  page: number
  setPage: (v: number) => void
  refresh: () => void
  fetchAdminCheckinList: () => void
  setIsLoading: (isLoading: boolean) => void
  hasList: number
  listTotal: number
  limit: number
  pagingIsDisabled: boolean
  isStatusApproved
  setIsStatusApproved
  onClickReserch: any
  onClickClearReserch: any
  detailedReserch: any
  setDetailedReserch: any
  reservationDetailedReserch: any
  setReservationDetailedReserch: any
}

export const ReservationList: React.FC<ContainerProps> = ({
  reservationList,
  page,
  setPage,
  refresh,
  fetchAdminCheckinList,
  setIsLoading,
  hasList,
  listTotal,
  limit,
  pagingIsDisabled,
  isStatusApproved,
  setIsStatusApproved,
  onClickReserch,
  onClickClearReserch,
  detailedReserch,
  setDetailedReserch,
  reservationDetailedReserch,
  setReservationDetailedReserch,
}) => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const { plugins } = useContext<any>(AccountContext)
  const windows = useWindowSize()
  const hasRoomManagerPlugin = setHasRoomManagerPlugin(plugins)
  const [isReservationCreateModalOpen, setIsReservationCreateModalOpen] = useState<boolean>(false)
  const [isReservationStatus, setIsReservationStatus] = useState<string>('')
  const [isDropDownReserchPanelOpen, setIsDropDownReserchPanelOpen] = useState<boolean>(false)
  const [checkedStatusApproved] = useState<boolean>(false)
  const [checkedReservationIds, setCheckedReservationIds] = useState<object>({})
  const [checkedAllFlag, setCheckedAllFlag] = useState<boolean>(false)
  const [checkedTotalCount, setCheckedTotalCount] = useState<number>(0)

  const searchedConditions = getSearchedConditions()
  const { SearchedConditionValues } = useSearchedConditions({ searchedConditions })

  // TL1way/Temairazu/Neppan's reservation information acquisition API execution
  const _reservationStatus = async () => {
    await fetchReservationStatus().then(res => {
      setIsReservationStatus(res?.reservationStatus)
    })
  }

  const _reservationApi = async () => {
    if (isReservationStatus === 'TL') {
      await fetchReservationTlApi()
        .then(() => {})
        .then(() => {
          fetchAdminCheckinList()
        })
        .then(() => {
          setIsLoading(false)
        })
    } else if (isReservationStatus === 'TM') {
      await fetchReservationTemairazuApi()
        .then(() => {})
        .then(() => {
          fetchAdminCheckinList()
        })
        .then(() => {
          setIsLoading(false)
        })
    } else if (isReservationStatus === 'NP') {
      await fetchReservationNeppanApi()
        .then(() => {})
        .then(() => {
          fetchAdminCheckinList()
        })
        .then(() => {
          setIsLoading(false)
        })
    } else {
      refresh()
    }
  }

  const reservationListReload = () => {
    setIsLoading(true)
    _reservationApi()
  }

  // Checkbox when status update (single) is selected
  const handleCheckboxChange = (e, index) => {
    // Empty if already checked
    let newValue = ''
    if (e.target.value !== checkedReservationIds[index]) {
      newValue = e.target.value
      const newIds = { ...checkedReservationIds, [index]: newValue }
      setCheckedReservationIds(newIds)
    }

    if (e.target.value === checkedReservationIds[index]) {
      delete checkedReservationIds[index]
      const newIds = { ...checkedReservationIds, [index]: newValue }
      setCheckedReservationIds(newIds)
    }
    // EX) When checking 0 and 2 (when checking 1 and unchecking) {0: '123', 1: '', 2: '234'}
  }

  // Checkbox when status update (whole) is selected
  const handleAllCheckboxChange = index => {
    if (!checkedAllFlag) {
      const allIds = reservationList.map((v: any) => {
        return v.reservationId
      })
      setCheckedAllFlag(true)
      setCheckedReservationIds(allIds)
    } else {
      setCheckedAllFlag(false)
      setCheckedReservationIds([])
    }
  }

  // Push booking id and approvedStatus on status update
  const onSubmit = async () => {
    try {
      setIsLoading(true)
      // Format the array, excluding if it is empty
      const formattedReservationIds: string[] = []

      Object.keys(checkedReservationIds).forEach(function (key) {
        if (checkedReservationIds[key] !== '') {
          formattedReservationIds.push(checkedReservationIds[key])
        }
      })

      const res = await fetchAdminReservationForCheckin('', '', '', page, 0, formattedReservationIds)

      // Initialize when acquiring for the first time
      for (const reservation of res?.reservations || []) {
        await reservationCheckinLink(reservation)
      }

      // Give Advance Receipt Flag
      await updateSalesAdvancePayment(formattedReservationIds)
    } catch (error) {
      console.warn(error)
    } finally {
      // refresh()
      window.location.reload()
    }
  }

  // Batch creation of reservation list
  const reservationCheckinLink = async reservation => {
    const selectedReservation = reservation

    // If the booking has confirmed details, ask if you want to create a check-in
    // if (
    //   (await _hasConfirmedSales(selectedReservation.reservationId)) &&
    //   !window.confirm(t('Includes items with confirmed dates Please pay attention to sales management'))
    // ) {
    //   setLoading(false)
    //   return
    // }

    const userCheckinTime = selectedReservation.checkinDate ? dayjs(selectedReservation.checkinDate).unix() : null
    const checkoutTime = selectedReservation.checkoutDate ? dayjs(selectedReservation.checkoutDate).unix() : null

    const checkinVariables = {
      hotelId: selectedReservation.hotelId,
      approvedStatus: ApprovedStatusType.Checkin,
      userCheckinTime,
      checkoutTime,
      guest: {
        // Show booker info if guest name is empty
        name: _convertNullToEmpty(selectedReservation.guestName) || _convertNullToEmpty(selectedReservation.name),
        nameKana: _convertNullToEmpty(selectedReservation.guestNameKana) || _convertNullToEmpty(selectedReservation.nameKana),
        birthDate: null,
        nationality: null,
        gender: null,
        postalCode: null,
        address: selectedReservation.guestName ? selectedReservation.guestAddr : selectedReservation.addr,
        telephone: selectedReservation.guestName ? selectedReservation.guestTel || '-' : selectedReservation.tel || '-',
        email: selectedReservation.guestName
          ? _convertEmptyToNull(selectedReservation.guestMailAddr)
          : _convertEmptyToNull(selectedReservation.mailAddr),
        occupation: null,
        otherOccupation: null,
        totalMember: selectedReservation.paxTotal,
        picture: null,
        credential: null,
        company: null,
      },
      accompany: [],
      previousPlaceToStay: '自宅',
      nextPlaceToStay: '自宅',
      reservationId: selectedReservation.reservationId,
    }

    /*
      Create a unique ID for check-in information
      ※ Overview
      When processing with a payment plug-in, data registration of usage details is required, but a check-in ID is required at that time.
      Create on the front, not on the backend
      TODO: I want to change this with a design change
    */
    const uuid = require('node-uuid')
    const checkinUuid = uuid.v4()

    // Create check-in information
    await updateSelfCheckin(checkinUuid, checkinVariables).then(async res => {
      // Link check-in and booking
      await insertAdminReservationCheckin(res.checkinId, selectedReservation.reservationId)
    })
  }

  const _convertEmptyToNull = value => {
    return value ? value : null
  }

  const _convertNullToEmpty = value => {
    return value ? value : ''
  }

  useEffect(() => {
    if (account) {
      _reservationStatus()
    }
  }, [account])

  // Number of status change checks
  useEffect(() => {
    let count = 0
    Object.keys(checkedReservationIds).forEach(key => {
      if (checkedReservationIds[key] !== '') {
        count++
      }
    })
    setCheckedTotalCount(count)
    if (count === 0) {
      setCheckedAllFlag(false)
    }
    if (count > 0 && count === hasList) {
      setCheckedAllFlag(true)
    } else {
      setCheckedAllFlag(false)
    }
  }, [checkedReservationIds])

  return (
    <>
      <div css={mainContainerHeaderStyle}>
        <div css={headerBarLeftWrapperStyle}>
          {!isStatusApproved && (
            <>
              <div>
                <Button
                  buttonType={4}
                  width={i18n.language === 'en' ? 118 : 90}
                  height={32}
                  fontSize={12}
                  onClick={() => setIsDropDownReserchPanelOpen(!isDropDownReserchPanelOpen)}
                >
                  {t('Detailed search')}
                </Button>
                {isDropDownReserchPanelOpen && (
                  <DetailedReserchPanel
                    onClickReserch={onClickReserch}
                    onClickClearReserch={onClickClearReserch}
                    setIsDropDownReserchPanelOpen={() => setIsDropDownReserchPanelOpen(!isDropDownReserchPanelOpen)}
                    detailedReserch={detailedReserch}
                    setDetailedReserch={setDetailedReserch}
                    reservationDetailedReserch={reservationDetailedReserch}
                    setReservationDetailedReserch={setReservationDetailedReserch}
                  />
                )}
              </div>
              <div css={reloadWrapperStyle}>
                <ReloadButton onClick={() => reservationListReload()} />
              </div>

              <SearchedConditionValues />
            </>
          )}
          {isStatusApproved && (
            <>
              <div css={selectedNumberStyle}>
                {checkedTotalCount}
                {t('item selected')}
              </div>
              <Button
                buttonType={3}
                width={150}
                height={32}
                fontSize={12}
                paddingLeft={16}
                paddingRight={16}
                marginLeft={24}
                onClick={() => {
                  onSubmit()
                  setIsStatusApproved(false)
                  setCheckedReservationIds({})
                  setCheckedAllFlag(false)
                }}
              >
                {t('Change to checkin')}
              </Button>
              <Button
                buttonType={4}
                width={99}
                height={32}
                fontSize={12}
                paddingLeft={16}
                paddingRight={16}
                marginLeft={8}
                onClick={() => {
                  setIsStatusApproved(false)
                  setCheckedReservationIds({})
                  setCheckedAllFlag(false)
                }}
              >
                {t('Cancel')}
              </Button>
            </>
          )}
        </div>
        <div>
          <div css={ReservationHeaderButtonStyle}>
            <div css={dropDownButtonStyle}>
              <Button
                buttonType={1}
                width={i18n.language === 'en' ? 176 : 124}
                height={32}
                fontSize={12}
                paddingLeft={16}
                paddingRight={16}
                icon={require('@/static/images/new_reservation.svg')}
                onClick={() => setIsReservationCreateModalOpen(true)}
              >
                {t('New reservation')}
                <img src={require('@/static/images/select_white.svg')} css={dropDownIcon} />
              </Button>
            </div>
            <AccommodationPaging page={page} setPage={setPage} total={listTotal} limit={limit} isDisabled={pagingIsDisabled} />
            {isReservationCreateModalOpen &&
              (hasRoomManagerPlugin ? (
                <ReservationCreateWithRoomModal
                  setIsModalOpen={setIsReservationCreateModalOpen}
                  onCreated={({ reservationUuid, reservationId }) => {
                    history.push({
                      pathname: `/reservation/${reservationUuid}`,
                      search: `?tabType=reservation&reservationId=${reservationId}`,
                    })
                  }}
                />
              ) : (
                <ReservationCreateModal setIsModalOpen={setIsReservationCreateModalOpen} />
              ))}
          </div>
        </div>
      </div>
      <div css={{ overflow: 'auto', height: windows.height - 218 }}>
        <table css={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              {isStatusApproved && (
                <th css={[tableHeaderThStyle, { paddingLeft: 32 }]}>
                  <input
                    id="checkboxAll"
                    alt="checkbox"
                    type="image"
                    src={require(`@/static/images/check-box${checkedAllFlag === true ? '' : '_off'}.svg`)}
                    onClick={index => handleAllCheckboxChange(index)}
                  />
                </th>
              )}

              <th css={[tableHeaderThStyle, !isStatusApproved ? { paddingLeft: 32 } : {}]}>{t('Reservation person')}</th>
              <th css={[tableHeaderThStyle, !isStatusApproved ? { paddingLeft: 16 } : {}]}>{t('Guest')}</th>
              <th css={tableHeaderThStyle}>{t('Reservation ID')}</th>
              <th css={tableHeaderThStyle}>{t('Room type')}</th>
              <th css={tableHeaderThStyle}>{t('Room number')}</th>
              <th css={tableHeaderThStyle}>{t('Status')}</th>
              <th css={tableHeaderThStyle}>{t('Date of stay')}</th>
              <th css={tableHeaderThStyle}>{t('Arrival times')}</th>
            </tr>
          </thead>
          <tbody>
            {reservationList &&
              reservationList.map((v: any, index) => {
                return (
                  <Link
                    key={index}
                    css={[
                      v.reservationRead ? tableBodyTdStyleRead : tableBodyTdStyleUnread,
                      { display: 'table-row', ':hover': { color: '#000' } },
                    ]}
                    to={{
                      pathname: `/reservation/${v.id}`,
                      search: `${window.location.search + `&reservationId=${v.reservationId}`}`,
                      state: { fromAccommodationManagement: true },
                    }}
                  >
                    {isStatusApproved && (
                      <td css={[tableBodyTdStyle, { paddingLeft: 32 }]} onClick={e => e.stopPropagation()}>
                        <input
                          id="checkinId"
                          alt="checkboxList"
                          value={v.reservationId}
                          type="image"
                          src={require(`@/static/images/check-box${
                            checkedReservationIds[index] === v.reservationId || checkedStatusApproved ? '' : '_off'
                          }.svg`)}
                          onClick={e => {
                            e.preventDefault()
                            handleCheckboxChange(e, index)
                          }}
                        />
                      </td>
                    )}
                    <td css={[tableBodyTdStyle, !isStatusApproved ? { paddingLeft: 32, maxWidth: 170 } : {}]}>
                      <div css={i18n.language === 'en' ? guestNameUnreadEnStyle : guestNameUnreadJaStyle}>
                        <div css={SubscriberNameKanaStyle}>{v.nameKana ? v.nameKana : '-'}</div>
                        <div css={SubscriberNameStyle}>{v.name ? v.name : '-'}</div>
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle, !isStatusApproved ? { paddingLeft: 16, maxWidth: 170 } : {}]}>
                      <div css={i18n.language === 'en' ? guestNameUnreadEnStyle : guestNameUnreadJaStyle}>
                        <div css={SubscriberNameKanaStyle}>{v.guestNameKana ? v.guestNameKana : '-'}</div>
                        <div css={SubscriberNameStyle}>{v.guestName ? v.guestName : '-'}</div>
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 170 }]}>
                      <div css={!v.reservationRead ? unreadStyle : readStyle}>
                        <div css={reservationIdStyle}>{v.reservationId ? v.reservationId : '-'}</div>
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 170 }]}>
                      <div css={[roomTypeStyle, !v.reservationRead ? unreadDataRoomNumberStyle : readDataRoomNumberStyle]}>
                        {dividedTypeName(v.rooms)}
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 150 }]}>
                      <div css={roomNumberStyle}>
                        {v.rooms.length !== 0 ? (
                          <div css={!v.reservationRead ? unreadDataRoomNumberStyle : readDataRoomNumberStyle}>
                            {dividedRoomNumber(v.rooms)}
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle]}>
                      <div css={statusTagStyle}>
                        {v.reservationStatus === 1 && <div css={statusTagOrangeStyle}>{t('New')}</div>}
                        {v.reservationStatus !== 1 && (
                          <div css={statusTagGrayStatus}>
                            {v.reservationStatus === 2 && t('Update')}
                            {v.reservationStatus === 3 && t('Cancel')}
                            {v.reservationStatus === 4 && t('No show')}
                          </div>
                        )}
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle]}>
                      <div css={!v.reservationRead ? unreadStyle : readStyle}>
                        {v.checkinDate === null ? (
                          '-'
                        ) : (
                          <>
                            {dayjs(v.checkinDate).format(t('YYYY-MM-DD'))}
                            <span style={{ paddingLeft: 5 }}>
                              {v.nights ?? 0}
                              {t('Night')}
                            </span>
                          </>
                        )}
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle]}>
                      <div css={!v.reservationRead ? unreadStyle : readStyle}>{v.checkinTime === null ? '-' : v.checkinTime}</div>
                    </td>
                  </Link>
                )
              })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const ReservationHeaderButtonStyle = css({
  display: 'flex',
  position: 'relative',
})

const dropDownButtonStyle = css({
  position: 'relative',
})

const dataTextStyle = css({
  paddingRight: 8,
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const SubscriberNameKanaStyle = css(dataTextStyle, {
  fontSize: 12,
  letterSpacing: 0.48,
  lineHeight: 1.5,
  color: '#676767',
})

const SubscriberNameStyle = css(dataTextStyle, {
  fontSize: 14,
  lineHeight: 1.5,
})

const reservationIdStyle = css(dataTextStyle, {
  fontSize: 14,
  lineHeight: 2.5,
  color: '#272727',
})

const roomTypeStyle = css(dataTextStyle, {
  fontSize: 14,
  lineHeight: 2.5,
  color: '#272727',
})

const roomNumberStyle = css(dataTextStyle, {
  fontSize: 14,
  lineHeight: 2.5,
  color: '#272727',
  minWidth: 100,
})

const statusTagStyle = css({
  textAlign: 'center',
  fontSize: 12,
})

const statusTagGrayStatus = css({
  height: 22,
  width: 88,
  border: '1px solid #676767',
  borderRadius: 5,
  color: '#676767',
  padding: '4px 10px',
})

const statusTagOrangeStyle = css({
  height: 22,
  minWidth: 78,
  width: 88,
  border: '1px solid #F2A40B',
  borderRadius: 5,
  color: '#F2A40B',
  padding: '4px 10px',
})

const unreadStyle = css({
  fontWeight: 'bold',
})

const readStyle = css({
  fontWeight: 'normal',
})

const unreadDataRoomNumberStyle = css(unreadStyle, dataTextStyle)

const readDataRoomNumberStyle = css(readStyle, dataTextStyle)

const dropDownIcon = css({
  marginLeft: 8,
})

const selectedNumberStyle = css({
  fontSize: 14,
  fontWeight: 'bold',
  margin: 'auto',
})
