import React from 'react'
import { Floor } from './floor'
import { css } from '@emotion/core'

type MainListCleaningProps = {
  collapsedTracking: object
  setCollapsedTracking: (arg0: any) => void
  rooms: object
  setIsOpenRoomDetailModal: (arg0: boolean) => void
  setSelectedRoomId: (arg0: string) => void
}

export const MainListCleaning = ({
  collapsedTracking,
  setCollapsedTracking,
  rooms,
  setIsOpenRoomDetailModal,
  setSelectedRoomId,
}: MainListCleaningProps) => {
  return (
    <div css={listFloorWrapper}>
      {Object.keys(rooms).map((floorNumber, index) => (
        <Floor
          key={index}
          floorNumber={floorNumber}
          roomList={rooms[floorNumber]}
          collapsedTracking={collapsedTracking}
          setCollapsedTracking={setCollapsedTracking}
          setIsOpenRoomDetailModal={setIsOpenRoomDetailModal}
          setSelectedRoomId={setSelectedRoomId}
        />
      ))}
    </div>
  )
}

const listFloorWrapper = css({
  fontSize: '12px',
  overflowY: 'auto',
  overflowX: 'auto',
})
