import React, { useContext } from 'react'

import { AccountContext } from '@/contexts/account'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { setEmployeeCurrentHotel } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'

export const HotelSwitcher: React.FC = () => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { employeeHotels } = useContext<any>(AccountContext)

  const onClickHotel = async (hotel: any) => {
    try {
      await setEmployeeCurrentHotel(hotel.id)

      window.location.replace('/')
    } catch (error) {
      errorHandler(error)
    }
  }

  if (employeeHotels?.length <= 1) return null

  return (
    <div css={hotelsSwitcher}>
      <div className="title">
        <img src={require('@/static/images/header/hotel.svg')} css={{ paddingRight: 12 }} />
        {t('switch hotel')}
        <img alt="icon_arrow_up" src={require('@/static/images/arrow_right_gray.svg')} css={{ marginLeft: 'auto' }} />
      </div>
      <div className="list">
        <div className="list__container">
          {employeeHotels.map((hotel: any, index: number) => (
            <div
              key={hotel.id}
              onClick={() => onClickHotel(hotel)}
              css={[index ? { borderTop: '1px solid #f2f2f2' } : {}]}
              className="hotel-item"
            >
              {hotel.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const hotelsSwitcher = css({
  position: 'relative',
  '.title': {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px 8px 16px',
    fontSize: '12px',
    fontWeight: 'bold',
    cursor: 'pointer',
    color: '#676767',
    '&:hover': {
      background: '#f2f2f2',
    },
  },
  '.list': {
    position: 'absolute',
    left: '-180px',
    top: 0,
    display: 'none',
    width: 180,
    padding: 4,
    '.list__container': {
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: '0px 3px 10px #0000003d',
      borderRadius: 5,
      overflow: 'hidden',
      '.hotel-item': {
        padding: 16,
        fontSize: '12px',
        fontWeight: 'bold',
        '&:hover': {
          background: '#f2f2f2',
          cursor: 'pointer',
        },
      },
    },
  },
  ':hover': {
    '.list': {
      display: 'block',
    },
  },
})
