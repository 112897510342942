import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// contexts
import { AccountContext } from '@/contexts/account'
import { MiwaAppWebsocketContext } from '@/contexts/miwa-app-websocket'

// type
// import { userRoomAssignType, clientInfoType } from '@/models/remote-lock/response'

// models
import { GuestRoomAssignResponseType, PaxFullObject } from '@/models/guest-room/guest-room-assign'
import { AdminReservationResponseType } from '@/models/reservation/admin-reservation'
import { TMiwaAppWebsocketContext, TMiwaLockStatus, TMiwaState } from '@/models/miwa-lock'

// hooks
import { useHotelGuestRooms } from '@/hooks/use-hotel-guest-rooms'
import { useRoomsSelectable } from '@/hooks/use-rooms-selectable'
import { useEditGuestRooms } from '@/hooks/use-edit-guest-rooms-for-free'

// libis
import { setHasRoomManagerPlugin, setHasSmartKeyPlugin, setHasMiwaLockPlugin } from '@/libs/plugins'
import { validUpdateGuestRoom } from '@/components/organisms/customer/_guest-room/valid-update-guest-room'
import { useErrorHandler } from '@/hooks/use-error-handler'

// components
import { NotEditFormItem } from '@/components/organisms/customer/_guest-room/not-edit-form-item'
import { RoomNumberSelect } from '@/components/organisms/customer/_guest-room/room-number-select'
import { RoomTypeSelect } from '@/components/organisms/customer/_guest-room/room-type-select'
import { AddRoomButton } from '@/components/organisms/customer/_guest-room/add-room-button'
import { EditRoomButton } from '@/components/organisms/customer/_guest-room/edit-room-button'
import { DatePicker } from '@/components/organisms/customer/_guest-room/date-picker'
import { PaxModal } from '@/components/organisms/customer/pax-modal'
import { Button } from '@/components/atoms/button'

// api
import { updateGuestRoomAssign, updateAllGuestRoomAssign, updateMiwaCardIssuesLogs, guestRoomFix, guestRoomAutoAssign } from '@/apis/aipass'

// constant
import {
  MiwaLockStatus,
  MiwaLockWebsocketRequestEvent,
  MiwaLockWebsocketResponseEvent,
  MiwaLockWebsocketResponseStatusCode,
} from '@/constants/miwa-lock'
import { KeyCardReleaseModal } from './miwa-lock/key-card-release-modal'
import { KeyCardReleaseResultModal } from './miwa-lock/key-card-release-result-modal'
import { LoadingFull } from '@/components/molecules/loading-full'
import { ReadyState } from 'react-use-websocket'

// extends dayjs
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { simpleTableStyle } from '@/constants/layout'
import { RegistrationGuestNameButton } from '../accommodation-management/registraton-guest-name-button'
import { Hint } from '@/components/atoms/hint'
dayjs.extend(isSameOrBefore)

type GuestRoomProps = {
  reservation: AdminReservationResponseType['reservations']
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isEditGuestRoom: boolean
  setIsEditGuestRoom: (v: boolean) => void
  onSavedData?: () => void
}

export const GuestRoom: React.FC<GuestRoomProps> = ({ reservation, setIsLoading, isEditGuestRoom, setIsEditGuestRoom, onSavedData }) => {
  const { plugins, account } = useContext<any>(AccountContext)
  const { t, i18n } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const history = useHistory()
  const location = useLocation<any>()

  // Room list to edit
  const [editGuestRooms, setEditGuestRooms, handleFetchGuestRooms] = useEditGuestRooms()
  // Hotel room list
  const [hotelGuestRooms, handleFetchGuestRoomForHotel] = useHotelGuestRooms()
  // List of selectable rooms
  const [roomsSelectable, handleFetchSelectableGuestRoom] = useRoomsSelectable()
  // Show/hide save button
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [showAutomaticAssignmentModal, setShowAutomaticAssignmentModal] = useState<boolean>(false)
  // For unassigned reserved rooms
  const [reservationRooms, setReservationRooms] = useState<{ typeNameJa: string; assignCheckinDate: string; assignCheckoutDate: string }[]>(
    [],
  )
  // Assignment Room Removed
  const [deletedEditGuestRooms, setDeletedEditGuestRooms] = useState<GuestRoomAssignResponseType['rooms']>([])

  /* Plugin judgment */
  // Whether it is a room management plugin
  const hasRoomManagerPlugin = setHasRoomManagerPlugin(plugins)
  // Check has plugin Smart Key
  const hasSmartKeyPlugin = setHasSmartKeyPlugin(plugins)
  // Check has plugin Miwa Lock
  const hasMiwaLockPlugin = setHasMiwaLockPlugin(plugins)

  const inititalMiwaState = {
    numberOfCards: 0,
    numberOfCardsReleaseSuccess: 0,
    guestRoomId: '',
    guestRoomAssignId: '',
    checkinTime: '',
    checkinDate: '',
    lastCheckinDatetime: '',
    checkoutTime: '',
    checkoutDate: '',
    typeNameJa: '',
    roomNumber: '',
    issueType: '',
    miwaActionType: '',
    miwaActionTime: '',
    hotelOnSiteCode: undefined,
    issueCount: 1,
  } as TMiwaState

  const {
    lastJsonMessage,
    sendJsonMessage,
    miwaAppWebsocketPCID,
    miwaAppWebsocketHotelOnSiteCode,
    miwaAppWebsocketErrorMessage,
    setMiwaAppWebsocketErrorMessage,
    fetchConnectionInfo,
    isLoadingFetchConnectionInfo,
    readyState,
  } = useContext<TMiwaAppWebsocketContext>(MiwaAppWebsocketContext)

  const [miwaState, setMiwaState] = useState<TMiwaState>(inititalMiwaState)
  const [isOpenKeyCardReleaseModal, setIsOpenKeyCardReleaseModal] = useState(false)
  const [isOpenKeyCardReleaseResultModal, setIsOpenKeyCardReleaseResultModal] = useState(false)
  const [isReloadWhenWriteCardMiwaSuccess, setIsReloadWhenWriteCardMiwaSuccess] = useState(false)
  const [isClickButtonRelease, setIsClickButtonRelease] = useState(false)
  const [isCurrentDateSameOrBeforeCheckoutDate, setIsCurrentDateSameOrBeforeCheckoutDate] = useState(false)
  const canBeAutoAssignment = editGuestRooms?.length && editGuestRooms.some(i => i.roomNumber === '指定なし') && reservation?.reservationId
  const canBeFixed =
    editGuestRooms?.length && editGuestRooms.every(i => !!i.roomNumber && i.roomNumber !== '指定なし') && reservation?.reservationId
  const [isFixed, setIsFixed] = useState<boolean>(reservation?.isFixed || false)
  /**
   * Remote lock related variables
   */
  // RemoteLock plugin
  // const hasRemoteLockPlugin = setHasRemoteLockPlugin(plugins)
  // Current room assignment status
  // const [userRoomAssign, setUserRoomAssign] = useState<userRoomAssignType[]>()
  // RemoteLock client information
  // const [remoteLockClientInfo, setRemoteLockClientInfo] = useState<clientInfoType>()
  // Remote lock setting state management of setting information
  // const [isRemoteLockSelected, setIsRemoteLockSelected] = useState<boolean>()

  /**
   * Room assignment cancellation process
   * Revert to current assignment
   */
  const onClickCancel = async () => {
    setIsLoading(true)

    setIsEdit(false)
    await handleFetchGuestRooms({ reservationId: reservation?.reservationId, hotelId: account?.hotel?.id })

    setIsLoading(false)
  }
  const _handleFetchGuestRooms = async () => {
    handleFetchGuestRoomForHotel()
    handleFetchSelectableGuestRoom()

    await handleFetchGuestRooms({ reservationId: reservation?.reservationId, hotelId: account?.hotel?.id }).then(e => {})
  }

  useEffect(() => {
    if (!account?.hotel?.id) return
    if (reservation?.reservationId) {
      _handleFetchGuestRooms()
      checkCurrentDateSameOrBeforeCheckoutDate()
    }

    setIsFixed(reservation?.isFixed || false)

    /**
     * Setting an unassigned reserved room
     */

    // There is a plug-in for room management, and it is limited when there is a reservation room
    if (hasRoomManagerPlugin && reservation && !!reservation?.reservationId && reservation?.reservationRoom?.length) {
      // Limited to cases where there is a room management plug-in and active reserved rooms
      const notAssignedReservationRoom = reservation.reservationRoom.filter(room => !room.guestRoomAssignId)
      // Set a reserved room if not
      if (notAssignedReservationRoom.length) {
        addReservationRoomType(reservation.reservationRoom)
      } else {
        // If there is no target reserved room, delete all reserved rooms
        setReservationRooms([])
      }
    }
  }, [reservation, account?.hotel?.id])

  // =================================================================

  /**
   * =================================================================
   * Room display/edit processing
   * =================================================================
   */

  /**
   * Processing to be added when a reserved room is moved to the room management
   */
  const addReservationRoomType = (rooms: AdminReservationResponseType['reservations']['reservationRoom']) => {
    // put together reserved rooms
    const _reservationRooms: {
      typeNameJa: string
      assignCheckinDate: string
      assignCheckoutDate: string
    }[] = []

    // If there are no rooms currently assigned
    for (let i = 0; i < rooms.length; i++) {
      for (let v = 0; v < rooms[i].currentRoomNum; v++) {
        _reservationRooms.push({
          typeNameJa: rooms[i].typeNameJa,
          assignCheckinDate: reservation.checkinDate,
          assignCheckoutDate: reservation.checkoutDate,
        })
      }
    }

    setReservationRooms(_reservationRooms)
  }

  /**
   * Get a list of currently selectable room types
   */
  const guestRoomTypeList = (roomInfo: GuestRoomAssignResponseType['rooms'][0]) => {
    const typeName = i18n.language === 'ja' ? 'typeNameJa' : 'typeNameEn'
    const list = [
      {
        id: 1,
        name: '選択してください',
        value: '',
      },
    ]
    // Delete the selected room from the list once to avoid duplication of rooms.
    const rooms = roomsSelectable.filter(room => Number(roomInfo.roomTypeId) !== Number(room.roomTypeId))
    rooms.forEach(data => {
      list.push({
        id: Number(data.roomTypeId) + 1,
        name: data[typeName],
        value: data[typeName],
      })
    })
    // Add the selected room type to the hotel-wide list of rooms if the selected room type is not in the selectable array
    if (
      roomInfo &&
      roomInfo.roomTypeId &&
      hotelGuestRooms &&
      hotelGuestRooms.findIndex(room => room.roomTypeId === roomInfo.roomTypeId) !== -1
    ) {
      list.push({
        id: Number(roomInfo.roomTypeId) + 1,
        name: roomInfo[typeName] ?? '',
        value: roomInfo[typeName] ?? '',
      })
    }
    // Sort ascending by roomTypeId (id = 1 is 'Please select')
    list.sort((a, b) => {
      return a.id - b.id
    })
    return list
  }

  /**
   * Get a list of selectable room numbers from the selected room type
   */
  const guestRoomNumberList = (roomInfo: GuestRoomAssignResponseType['rooms'][0]) => {
    const list = [
      {
        name: '選択してください',
        value: '',
        guestRoomId: '',
      },
    ]

    // If room type is selected
    if (roomInfo && roomInfo.typeNameJa) {
      roomsSelectable.forEach(data => {
        if (data.rooms && data.rooms.length && data.typeNameJa === roomInfo.typeNameJa) {
          data.rooms.forEach(room => {
            list.push({
              name: room.roomNumber,
              value: room.guestRoomId,
              guestRoomId: room.guestRoomId,
            })
          })
        }
      })
      if (roomInfo.roomNumber && roomInfo.guestRoomId) {
        let isDuplicate = false
        list.filter(v => {
          if (v.value === roomInfo.guestRoomId) isDuplicate = true
        })
        // Add if not duplicate
        if (!isDuplicate) {
          list.push({
            name: roomInfo.roomNumber,
            value: roomInfo.guestRoomId,
            guestRoomId: roomInfo.guestRoomId,
          })
        }
      }
      // Sort Ascending
      list.sort((a, b) => Number(a.name) - Number(b.name))
    } else {
      // Show all available room numbers if no room type is selected
      const allNumber: { guestRoomId: string; number: string }[] = []
      roomsSelectable.forEach(data => {
        if (data.rooms && data.rooms.length) {
          data.rooms.forEach(room => {
            allNumber.push({
              guestRoomId: room['guestRoomId'],
              number: room['roomNumber'],
            })
          })
        }
      })

      allNumber?.forEach(guestRoom => {
        list.push({
          name: guestRoom['number'],
          value: guestRoom['number'],
          guestRoomId: guestRoom['guestRoomId'],
        })
      })
    }
    return list
  }

  /**
   * Processing when changing the room type of each room
   */
  const onEditRoomType = (event: React.ChangeEvent<HTMLSelectElement>, selectIndex: number) => {
    // If no room type is selected, stop processing
    if (!event.target.value || event.target.value === '選択してください') {
      alert(t('Please select a room type'))
      event.preventDefault()
      return
    }

    // Create a new array to update the state
    const _editGuestRooms = [...editGuestRooms]

    hotelGuestRooms.forEach(room => {
      if (room.typeNameJa === event.target.value) {
        const { roomTypeId, typeNameJa, typeNameEn } = room
        _editGuestRooms[selectIndex] = {
          ..._editGuestRooms[selectIndex],
          roomTypeId,
          typeNameJa,
          typeNameEn,
          roomNumber: null,
          guestRoomId: null,
        }
      }
    })

    setEditGuestRooms(_editGuestRooms)
    setIsEdit(true)
  }

  /**
   * Processing when changing the room number of each room
   */
  const onEditRoomNumber = (event: React.ChangeEvent<HTMLSelectElement>, selectGuestRoomId: string, selectIndex: number) => {
    // If no room number is selected, stop processing
    if (!selectGuestRoomId) {
      alert(t('Please select a room number'))
      event.preventDefault()
      return
    }
    // Create a new array to update the state
    const _editGuestRooms = [...editGuestRooms]
    _editGuestRooms[selectIndex].guestRoomId = selectGuestRoomId
    _editGuestRooms[selectIndex].roomNumber = getRoomNumberByGuestRoomId(selectGuestRoomId)

    setEditGuestRooms(_editGuestRooms)
    setIsEdit(true)
  }

  /**
   * Get the room number based on guestRoomId
   */
  const getRoomNumberByGuestRoomId = (guestRoomId: string) => {
    let roomNumber = ''
    hotelGuestRooms.forEach(hotelGuestRoom => {
      if (hotelGuestRoom.roomNumber) {
        Object.keys(hotelGuestRoom.roomNumber).forEach(key => {
          hotelGuestRoom.roomNumber[key].forEach(room => {
            if (room.guestRoomId === guestRoomId) {
              roomNumber = room.roomNumber
            }
          })
        })
      }
    })
    return roomNumber
  }

  const onEditRoomAssignGuestName = (event: React.ChangeEvent<HTMLInputElement>, selectIndex: number) => {
    // Create a new array to update the state
    const _editGuestRooms = [...editGuestRooms]
    _editGuestRooms[selectIndex].assignGuestName = event.target.value
    _editGuestRooms[selectIndex].reservationGuestName = event.target.value
    setEditGuestRooms(_editGuestRooms)
    setIsEdit(true)
  }

  const onEditRoomAssignPax = (event: React.ChangeEvent<HTMLInputElement>, selectIndex: number) => {
    // Create a new array to update the state
    const _editGuestRooms = [...editGuestRooms]
    _editGuestRooms[selectIndex].pax = parseInt(event.target.value) || 0
    setEditGuestRooms(_editGuestRooms)
    setIsEdit(true)
  }

  const onEditPaxFull = async (paxObject: PaxFullObject, selectIndex: number) => {
    try {
      setIsLoading(true)

      const _editGuestRooms = [...editGuestRooms]

      _editGuestRooms[selectIndex] = { ..._editGuestRooms[selectIndex], ...paxObject }

      await _updateGuestRoomAssign(_editGuestRooms)

      setEditGuestRooms(_editGuestRooms)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const _updateGuestRoomAssign = async editGuestRooms => {
    if (!hasRoomManagerPlugin) return await updateAllGuestRoomAssign(reservation, editGuestRooms)

    return await updateGuestRoomAssign(editGuestRooms)
  }

  // =================================================================

  /**
   * =================================================================
   * Each button processing
   * =================================================================
   */

  /**
   * Add room button
   */
  const onFieldPush = () => {
    if (!reservation) return

    let _editGuestRooms: typeof editGuestRooms = []
    if (editGuestRooms.length) {
      _editGuestRooms = [...editGuestRooms]
    }

    _editGuestRooms.push({
      reservationId: reservation?.reservationId,
      assignGuestName: reservation?.guestName,
      reservationUserName: reservation?.userName,
      reservationGuestName: reservation?.guestName,
      /**
       * Check-in, check-out defaults to booking check-in, check-out
       * If there is no check-out date, set the day after the check-in date
       */
      assignCheckinDate: reservation.checkinDate,
      assignCheckoutDate: reservation.checkoutDate
        ? reservation.checkoutDate
        : dayjs(reservation.checkinDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
      guestRoomAssignId: null,
      roomTypeId: null,
      typeNameJa: null,
      typeNameEn: null,
      guestRoomId: null,
      roomNumber: null,
      keyNumber: null,
      issueType: '',
    })

    setIsEdit(true)
    setEditGuestRooms(_editGuestRooms)
  }

  /**
   * Delete button for each room
   */
  const onDeleteRoom = (selectIndex: number) => {
    const _editGuestRooms = [...editGuestRooms]
    if (_editGuestRooms[selectIndex].guestRoomAssignId) {
      setDeletedEditGuestRooms([
        ...deletedEditGuestRooms,
        {
          guestRoomAssignId: _editGuestRooms[selectIndex].guestRoomAssignId,
          reservationId: null,
          assignGuestName: null,
          reservationUserName: null,
          reservationGuestName: null,
          assignCheckinDate: null,
          assignCheckoutDate: null,
          roomTypeId: null,
          guestRoomId: null,
          typeNameJa: null,
          typeNameEn: null,
          roomNumber: null,
          keyNumber: null,
        },
      ])
    }
    _editGuestRooms.splice(selectIndex, 1)

    setEditGuestRooms(_editGuestRooms)
  }

  /**
   * save button
   */
  const updateGuestRoom = async () => {
    try {
      setIsLoading(true)

      // validation
      if (!validUpdateGuestRoom(editGuestRooms)) {
        setIsLoading(false)
        return
      }

      let _editGuestRooms = [...editGuestRooms]

      // Add to editGuestRooms if there was a deleted assigned room
      if (deletedEditGuestRooms.length) {
        _editGuestRooms = [...editGuestRooms, ...deletedEditGuestRooms]
      }

      await _updateGuestRoomAssign(_editGuestRooms).then(() => {
        setIsEdit(false)
        setIsEditGuestRoom(!isEditGuestRoom)
        if (onSavedData) onSavedData()
      })
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * =================================================================
   * helper
   * =================================================================
   */

  /**
   * Returns the manually set key number or access guest PIN number depending on the key number acquisition processing status.
   */
  const getGuestRoomKeyNumber = (room: GuestRoomAssignResponseType['rooms'][0] | null): string => {
    if (!room) return '-'
    /**
     * TODO: Support after remote lock update support
     */
    // Returns "-" until acquisition is completed
    // if (hasRemoteLockPlugin && !remoteLockClientInfo) {
    //   return '-'
    // }

    // if (hasRemoteLockPlugin && remoteLockClientInfo) {
    //   // The setting linkage method is not remote lock
    //   // not linked
    //   if (!remoteLockClientInfo.connected) {
    //     return '-'
    //   }
    //     return room.keyNumber

    //   // Presence or absence of access guest information
    //   if (userRoomAssign) {
    //     const targetIndex = userRoomAssign.findIndex(roomAssign => roomAssign.guest_room_assign_id === room.guestRoomAssignId)
    //     //Whether the assignment data is found and the device is registered in the target room
    //     if (targetIndex !== -1 && userRoomAssign.length !== 0 && userRoomAssign[targetIndex].remote_device_id) {
    //       // Returns PIN number if registered
    //       return userRoomAssign[targetIndex].access_guest_pin_number ? userRoomAssign[targetIndex].access_guest_pin_number! : '-'
    //     } else {
    //       // Returns "-" if not registered
    //       return '-'
    //     }
    //   } else {
    //     // Returns "-" until acquisition is completed
    //     return '-'
    //   }
    // }

    /**
     * remote lock plug-in off
     */
    return room.keyNumber ?? '-'
  }

  const validateBeforeOpenKeyCardReleaseModal = () => {
    const errorMessage = t('Please input PCID and try again.')

    if (!miwaAppWebsocketPCID) {
      window.alert(errorMessage)
      return false
    }

    return true
  }

  // /**
  //  * @type {(room: GuestRoomAssignResponseType['rooms'][0]) => void}
  //  * @description Whenever click button issue/reissue, the miwaState will be update
  //  */
  const onOpenKeyCardReleaseModal = async (room: any) => {
    if (!validateBeforeOpenKeyCardReleaseModal()) return

    setIsClickButtonRelease(true)
    setMiwaState({
      ...miwaState,
      guestRoomId: room.guestRoomId || '',
      guestRoomAssignId: room.guestRoomAssignId || '',
      checkinTime: room.checkinTime ? room.checkinTime : '',
      checkinDate: room.assignCheckinDate ? room.assignCheckinDate.split(' ')[0] : '',
      lastCheckinDatetime: room.lastCheckinDatetime || '',
      checkoutTime: room.checkoutTime ? handleCheckoutTimeMiwa(room)[1] : '',
      checkoutDate: room.assignCheckoutDate ? handleCheckoutTimeMiwa(room)[0] : '',
      typeNameJa: room.typeNameJa || '',
      roomNumber: room.roomNumber || '',
      issueType: room.issueType || '',
      issueCount: room.issueCount || 1,
    })

    if (readyState === ReadyState.OPEN) {
      setIsOpenKeyCardReleaseModal(true)
    } else {
      await fetchConnectionInfo()
    }
  }

  const handleCheckoutTimeMiwa = (room: any) => {
    const checkoutTime = room.checkoutTime
    const checkoutDate = room.assignCheckoutDate.split(' ')[0]
    const checkoutDateTimeExtendOneHour = dayjs(`${checkoutDate} ${checkoutTime}`).format('YYYY-MM-DD HH:mm')
    const checkoutDateExtendOneHour = checkoutDateTimeExtendOneHour.split(' ')[0]
    const checkoutTimeExtendOneHour = checkoutDateTimeExtendOneHour.split(' ')[1]

    return [checkoutDateExtendOneHour, checkoutTimeExtendOneHour]
  }

  const handleOpenReleaseKeyCardModal = (statusCode: number) => {
    if (isClickButtonRelease && statusCode === MiwaLockWebsocketResponseStatusCode.AUTH_SUCCESS) {
      setIsOpenKeyCardReleaseModal(true)
    }
  }

  const onClickKeyCardReleaseModalCancel = () => {
    // Reset data and close modal
    setMiwaState(inititalMiwaState)
    setIsClickButtonRelease(false)
    setIsOpenKeyCardReleaseModal(false)
  }

  const onClickKeyCardReleaseModalSave = (type: TMiwaLockStatus) => {
    const checkoutTime = dayjs(`${miwaState.checkoutDate} ${miwaState.checkoutTime}`).format('YYYY-MM-DD HH:mm')

    const currentTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm')
    const issueCount = type === MiwaLockStatus.REISSUE ? miwaState.issueCount + 1 : miwaState.issueCount

    setMiwaState({
      ...miwaState,
      issueCount,
      miwaActionType: type,
      miwaActionTime: currentTime,
    })

    const checkinTime = handleCheckinTimeByType(type, currentTime)

    // Send room info to websocket server
    const sendMessage = {
      reservation_id: reservation?.id?.toUpperCase(),
      issue_at: currentTime,
      checkin_date: dayjs(checkinTime).format('YYYY-MM-DD HH:mm'),
      checkout_date: checkoutTime,
      room_number: Number(miwaState.roomNumber),
      number_of_cards: miwaState.numberOfCards,
    }
    if (miwaAppWebsocketHotelOnSiteCode) {
      Object.assign(sendMessage, {
        hotel_on_site_code: miwaAppWebsocketHotelOnSiteCode,
        issue_count: issueCount,
      })
    }

    console.log({ sendMessage })
    sendJsonMessage({
      type: type,
      body: sendMessage,
    })

    setIsOpenKeyCardReleaseModal(false)
    setIsClickButtonRelease(false)
    setIsOpenKeyCardReleaseResultModal(true)
  }

  const handleCheckinTimeByType = (type: string, currentTime: string) => {
    let time = ''
    switch (type) {
      case MiwaLockStatus.ISSUE:
        time = `${miwaState.checkinDate} ${miwaState.checkinTime}`
        break
      case MiwaLockStatus.REISSUE:
        time = dayjs(miwaState.lastCheckinDatetime).add(1, 'm').format('YYYY-MM-DD HH:mm')
        break
      case MiwaLockStatus.ADDNEWISSUE:
        time = miwaState.lastCheckinDatetime
        break
      default:
        break
    }

    return time
  }

  const handleCloseKeyCardReleaseResultModal = async () => {
    // Close modal
    setIsOpenKeyCardReleaseResultModal(false)
    miwaAppWebsocketErrorMessage && setMiwaAppWebsocketErrorMessage('')

    // Emit event to server when close modal while the process is not done yet
    if (miwaState.numberOfCardsReleaseSuccess < miwaState.numberOfCards) {
      sendJsonMessage({
        type: MiwaLockWebsocketRequestEvent.CANCEL,
      })
    }

    const actionLastCheckinTime = handleCheckinTimeByType(miwaState.miwaActionType, miwaState.miwaActionTime)

    const payloads = {
      guestRoomId: miwaState.guestRoomId,
      guestRoomAssignId: miwaState.guestRoomAssignId,
      checkinTime: miwaState.checkinTime,
      checkinDate: dayjs(miwaState.checkinDate).unix(),
      lastCheckinDatetime: dayjs(actionLastCheckinTime).format('YYYY-MM-DD HH:mm:ss'),
      checkoutTime: miwaState.checkoutTime,
      checkoutDate: dayjs(miwaState.checkoutDate).unix(),
      issueType: miwaState.miwaActionType,
      issueCount: miwaState.issueCount,
      requestedCards: miwaState.numberOfCards,
      issuedCards: miwaState.numberOfCardsReleaseSuccess,
      payload: miwaState,
    }

    if (miwaState.numberOfCardsReleaseSuccess > 0) {
      await saveMiwaCardIssuesLogs(payloads)
    }

    // Reset data and close modal
    setMiwaState(inititalMiwaState)
  }

  const saveMiwaCardIssuesLogs = async payloads => {
    try {
      setIsReloadWhenWriteCardMiwaSuccess(true)
      await updateMiwaCardIssuesLogs(payloads)
      await _handleFetchGuestRooms()
    } catch (error) {
      console.log('Fail To Update MiwaCardIssuesLogs: ', error)
    } finally {
      setIsReloadWhenWriteCardMiwaSuccess(false)
    }
  }

  const checkCurrentDateSameOrBeforeCheckoutDate = () => {
    const currentDate = dayjs().format('YYYY-MM-DD')
    const reservationCheckoutDate = dayjs(reservation.checkoutDate).format('YYYY-MM-DD')
    const isSameOrBeforeDate = dayjs(currentDate).isSameOrBefore(reservationCheckoutDate)
    setIsCurrentDateSameOrBeforeCheckoutDate(isSameOrBeforeDate)
  }

  const miwaButtonType = (issueType: string) => {
    if (isEdit || !isCurrentDateSameOrBeforeCheckoutDate) {
      return 5 // Disable button when editing
    }

    if (issueType === MiwaLockStatus.ISSUE) {
      return 7
    }

    if (issueType === MiwaLockStatus.REISSUE) {
      return 4
    }

    return 3
  }

  const handleReadWriteCard = async (statusCode: number) => {
    if (statusCode === MiwaLockWebsocketResponseStatusCode.ISSUE_IN_PROGRESS) {
      setMiwaState(prev => ({
        ...prev,
        numberOfCardsReleaseSuccess: prev.numberOfCardsReleaseSuccess + 1,
      }))
    }

    if (statusCode !== MiwaLockWebsocketResponseStatusCode.ISSUE_DONE) {
      miwaAppWebsocketErrorMessage && setMiwaAppWebsocketErrorMessage('')
    }
  }

  const fixedRooms = async () => {
    try {
      setIsLoading(true)

      await guestRoomFix({ reservationId: reservation.reservationId, isFixed: !isFixed })

      setIsFixed(!isFixed)

      await _handleFetchGuestRooms()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const makeAutomaticAssignment = async () => {
    try {
      setShowAutomaticAssignmentModal(false)
      setIsLoading(true)

      const res = await guestRoomAutoAssign({ reservationIds: [reservation.reservationId], pageType: 'reservation' })

      if (res?.errors) errorHandler({ response: { data: res } })

      await _handleFetchGuestRooms()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const { type, body } = lastJsonMessage

      switch (type) {
        case MiwaLockWebsocketResponseEvent.AUTH_RESULT:
          handleOpenReleaseKeyCardModal(body.status_code)
          break
        case MiwaLockWebsocketResponseEvent.ISSUE_RESULT:
          handleReadWriteCard(body.status_code)
          break
        case MiwaLockWebsocketResponseEvent.REISSUE_RESULT:
          handleReadWriteCard(body.status_code)
          break
        case MiwaLockWebsocketResponseEvent.ADDITIONAL_ISSUE_RESULT:
          handleReadWriteCard(body.status_code)
          break
        default:
          break
      }
    }
  }, [lastJsonMessage])

  return (
    <div css={guestRoomWrapperStyle}>
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '6.5px 32px' }}>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <div css={[guestRoomTitleStyle, { marginRight: 32 }]}>{t('Guest room')}</div>
          {!!canBeAutoAssignment && (
            <Button
              width={120}
              height={24}
              buttonType={2}
              fontSize={12}
              borderRadius={4}
              onClick={() => setShowAutomaticAssignmentModal(true)}
            >
              {t('Automatic assignment')}
            </Button>
          )}
          {showAutomaticAssignmentModal && (
            <div
              css={{
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0,0,0,0.5)',
                zIndex: 3,
              }}
            >
              <div css={{ backgroundColor: '#fff', padding: 32, borderRadius: 8 }}>
                <div css={{ fontWeight: 'bold', marginBottom: 32, fontSize: 18 }}>{t('Are you sure you want to assign?')}</div>
                <div css={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                  <Button width={116} height={36} buttonType={3} onClick={() => setShowAutomaticAssignmentModal(false)}>
                    {t('Cancel')}
                  </Button>
                  <Button width={116} height={36} buttonType={2} onClick={makeAutomaticAssignment}>
                    {t('Assignment')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        {!!editGuestRooms?.length && (
          <div css={{ display: 'flex', gap: 24 }}>
            {reservation?.reservationId && (
              <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <Hint customCss={hintImageStyle}>
                  <div className="inner-block">
                    <p>「部屋番号」「部屋代表者」の列名を含むCSVファイルをアップロードしてください。</p>
                    <img src={require('@/static/images/room/説明画像.png')} alt="" />
                  </div>
                </Hint>
                <RegistrationGuestNameButton
                  reservationRooms={editGuestRooms}
                  onReload={() => {
                    handleFetchGuestRooms({ reservationId: reservation.reservationId, hotelId: account?.hotel?.id })
                  }}
                />
              </div>
            )}
            <div css={[fixCheckStyle, { cursor: canBeFixed ? 'pointer' : 'default' }]} onClick={() => canBeFixed && fixedRooms()}>
              <img
                src={require(`@/static/images/room/check-box${isFixed ? '_yellow_on' : '_off'}.svg`)}
                css={{ backgroundColor: !canBeFixed ? '#f2f2f2' : undefined }}
              />
              <p css={{ color: !canBeFixed ? '#ccc !important' : undefined, fontWeight: 'bold' }}>{t('Fixed room')}</p>
            </div>
          </div>
        )}
      </div>
      <table css={simpleTableStyle}>
        <thead>
          <tr>
            <th css={{ width: 100 }}>{t('Room ID')}</th>
            <th css={{ width: !hasRoomManagerPlugin ? 200 : 170 }}>{t('Date')}</th>
            <th>{t('Room representative')}</th>
            <th css={{ width: 100 }}>{t('Capacity')}</th>
            <th>{t('Room type')}</th>
            <th>{t('Room number')}</th>
            <th>{t('Smart key')}</th>
          </tr>
        </thead>
        <tbody>
          {hasRoomManagerPlugin ? (
            <>
              {reservation && !editGuestRooms.length && reservationRooms.length
                ? reservationRooms.map((room, index) => {
                    return (
                      <NotEditFormItem
                        reservation={reservation}
                        room={room}
                        roomIndex={index}
                        key={index}
                        miwaButtonType={miwaButtonType}
                        hasSmartKeyPlugin={hasSmartKeyPlugin}
                        hasMiwaLockPlugin={hasMiwaLockPlugin}
                        onOpenKeyCardReleaseModal={onOpenKeyCardReleaseModal}
                        getGuestRoomKeyNumber={getGuestRoomKeyNumber}
                        onEditRoomAssignGuestName={onEditRoomAssignGuestName}
                        onEditRoomAssignPax={onEditRoomAssignPax}
                        onEditPaxFull={onEditPaxFull}
                        isCurrentDateSameOrBeforeCheckoutDate={isCurrentDateSameOrBeforeCheckoutDate}
                        hideEdit={true}
                      />
                    )
                  })
                : ''}
              {reservation && editGuestRooms.length
                ? editGuestRooms.map((room, index) => {
                    return (
                      <NotEditFormItem
                        reservation={reservation}
                        room={room}
                        roomIndex={index}
                        key={index}
                        miwaButtonType={miwaButtonType}
                        hasSmartKeyPlugin={hasSmartKeyPlugin}
                        hasMiwaLockPlugin={hasMiwaLockPlugin}
                        onOpenKeyCardReleaseModal={onOpenKeyCardReleaseModal}
                        getGuestRoomKeyNumber={getGuestRoomKeyNumber}
                        onEditRoomAssignGuestName={onEditRoomAssignGuestName}
                        onEditRoomAssignPax={onEditRoomAssignPax}
                        onEditPaxFull={onEditPaxFull}
                        isCurrentDateSameOrBeforeCheckoutDate={isCurrentDateSameOrBeforeCheckoutDate}
                      />
                    )
                  })
                : ''}
              {(isEdit || !!(reservation && !editGuestRooms.length && !reservationRooms.length)) && (
                <tr>
                  <td colSpan={7} css={{ padding: '0 !important' }}>
                    {isEdit && <EditRoomButton updateGuestRoom={updateGuestRoom} onClickCancel={onClickCancel} />}
                    {reservation && !editGuestRooms.length && !reservationRooms.length && (
                      <div
                        css={addRoomListWrapperOpenStyle}
                        onClick={() =>
                          history.push({
                            pathname: '/room-manager/daily',
                            search: `reservationId=${reservation.reservationId}`,
                            state: {
                              ...location.state,
                            },
                          })
                        }
                      >
                        <img src={require('@/static/images/plus.svg')} css={addIconStyle} alt={t('Add icon yellow')} />
                        <p>{t('Add room')}</p>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </>
          ) : (
            <>
              {!!reservation &&
                !!editGuestRooms.length &&
                editGuestRooms.map((room, index) => {
                  return (
                    <tr key={index}>
                      <td>{room?.guestRoomAssignId?.slice(-4).toUpperCase()}</td>
                      <td>
                        <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <DatePicker room={room} dateType="checkin" setIsEdit={setIsEdit} /> ~
                          <DatePicker room={room} dateType="checkout" setIsEdit={setIsEdit} />
                        </div>
                      </td>
                      <td>
                        <input
                          css={inputGuestNameStyle}
                          value={room.assignGuestName ? room.assignGuestName : room.reservationGuestName ? room.reservationGuestName : ''}
                          onChange={event => onEditRoomAssignGuestName(event, index)}
                          onClick={() => setIsEdit(true)}
                        />
                      </td>
                      <td>
                        <div css={paxContainer}>
                          <input
                            css={[inputCapacityStyle, { pointerEvents: 'none' }]}
                            value={room.pax ? Number(room.pax).toString() : '0'}
                            onChange={event => onEditRoomAssignPax(event, index)}
                            onClick={() => setIsEdit(true)}
                            type="number"
                            min={0}
                          />
                          <PaxModal room={room} roomIndex={index} onEditPaxFull={onEditPaxFull} />
                        </div>
                      </td>
                      <td>
                        <RoomTypeSelect
                          room={room}
                          roomIndex={index}
                          guestRoomTypeList={guestRoomTypeList}
                          onEditRoomType={onEditRoomType}
                          isEdit={isEdit}
                          cssStyles={{ width: '100%', marginRight: 0, maxWidth: 'initial' }}
                        />
                      </td>
                      <td>
                        <RoomNumberSelect
                          room={room}
                          roomIndex={index}
                          guestRoomNumberList={guestRoomNumberList}
                          onEditRoomNumber={onEditRoomNumber}
                          isEdit={isEdit}
                          cssStyles={{ width: '100%', marginRight: 0, maxWidth: 'initial' }}
                        />
                      </td>
                      <td>
                        <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          {hasSmartKeyPlugin ? (
                            hasMiwaLockPlugin && room?.issueType ? (
                              !isEdit ? (
                                <Button
                                  width={72}
                                  height={32}
                                  fontSize={12}
                                  buttonType={miwaButtonType(room?.issueType)}
                                  isDisabled={isEdit || !isCurrentDateSameOrBeforeCheckoutDate}
                                  onClick={() => onOpenKeyCardReleaseModal(room)}
                                >
                                  {room?.issueType === MiwaLockStatus.ISSUE ? t('Issue') : t('Reissue')}
                                </Button>
                              ) : (
                                '-'
                              )
                            ) : (
                              getGuestRoomKeyNumber(room)
                            )
                          ) : (
                            '-'
                          )}
                          {isEdit && (
                            <div onClick={() => onDeleteRoom(index)} css={{ cursor: 'pointer' }}>
                              <img src={require('@/static/images/delete_yellow.svg')} width="30" height="30"></img>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              <tr>
                <td colSpan={8} css={{ padding: '0 !important' }}>
                  <AddRoomButton
                    onFieldPush={onFieldPush}
                    updateGuestRoom={updateGuestRoom}
                    onClickCancel={onClickCancel}
                    isEdit={isEdit}
                  />
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>

      {isOpenKeyCardReleaseModal && (
        <KeyCardReleaseModal
          miwaState={miwaState}
          setMiwaState={setMiwaState}
          onClickModalCancel={onClickKeyCardReleaseModalCancel}
          onClickModalSave={onClickKeyCardReleaseModalSave}
        />
      )}

      {isOpenKeyCardReleaseResultModal && (
        <KeyCardReleaseResultModal
          miwaState={miwaState}
          setMiwaState={setMiwaState}
          numberOfCards={miwaState.numberOfCards}
          numberOfCardsReleaseSuccess={miwaState.numberOfCardsReleaseSuccess}
          onClickModalCancel={handleCloseKeyCardReleaseResultModal}
        />
      )}

      <LoadingFull isLoading={isReloadWhenWriteCardMiwaSuccess || isLoadingFetchConnectionInfo} />
    </div>
  )
}

// guest room
const guestRoomWrapperStyle = css({
  border: '1px solid #CCCCCC',
  background: '#FFF',
  borderRadius: 10,
})

const guestRoomTitleStyle = css({
  fontSize: 14,
  fontWeight: 'bold',
})

const inputGuestNameStyle = css({
  color: '#272727',
  padding: '0 10px',
  height: 32,
  borderRadius: 17,
  marginRight: 16,
  appearance: 'none',
  border: '1px solid #CCC',
  width: '100%',
})

const inputCapacityStyle = css(inputGuestNameStyle, {
  width: '100%',
})

const addRoomListWrapperOpenStyle = css({
  cursor: 'pointer',
  display: 'flex',
  height: 36,
  backgroundColor: '#FAFAFA',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px dashed #CCCCCC',
  margin: '24px 32px',
  p: {
    lineHeight: '36px',
    color: '#F2A40B',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
  marginTop: 24,
})

const addIconStyle = css({
  width: 13,
  height: 13,
})

const paxContainer = css({
  '.pax-modal': {
    display: 'none',
    position: 'absolute',
  },
  ':hover .pax-modal': {
    display: 'block',
  },
})

const fixCheckStyle = css({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  img: {
    marginRight: 8,
  },
  p: {
    fontSize: 12,
    letterSpacing: 0.6,
    color: '#676767',
  },
})

const hintImageStyle = css({
  '.help-icon': { width: 18, height: 18 },
  '.help-popup': { right: 26, top: -13 },
  '.inner-block': {
    width: 386,
    padding: 16,
    textAlign: 'center',
    p: {
      marginBottom: 16,
      color: '#676767',
      fontWeight: 'bold',
      textAlign: 'left',
      lineHeight: 1.5,
      letterSpacing: 1.2,
      fontSize: 12,
    },
    img: { width: 181 },
  },
})
