import React, { useState, useEffect, useContext, useMemo, MouseEvent } from 'react'
import { css } from '@emotion/core'
import { get as transformHeaderGet } from 'lodash'
import { useTranslation } from 'react-i18next'
import { salesSubjects } from '@/libs/sales-subjects'
import { Button } from '@/components/atoms/button'
import { SettingSalesManagerTab } from '@/components/organisms/settings/sales-manager/tab'
import { CSVReader } from '@/components/organisms/csv-reader'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { SubjectDetailModal } from '@/components/organisms/settings/sales-manager/subject-detail-modal'
import { LoaderContextCreator } from '@/contexts/loader'
import { SalesSubjectType } from '@/models/sales-manager/sales'
import { EditFooter } from '@/components/organisms/edit-footer'
import { SalesListItem } from '@/components/organisms/settings/sales-manager/sales/sales-list-item'
import { deleteSalesSubject, fetchSalesSubjectReduceSubSubject, importSalesSubject } from '@/apis/aipass'

type ContainerProps = {}

export const SettingSalesManagerSales: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const [salesSettings, setSalesSettings] = useState<SalesSubjectType[]>([])
  const [showDetailProp, setShowDetailProp] = useState<SalesSubjectType | undefined | null>(undefined)
  const [deleteIds, setDeleteIds] = useState<string[]>([])

  const initializeComponent = async (opt?: { noIndicator: boolean }) => {
    !opt?.noIndicator && setIsLoading(true)
    const salesSubjects = await fetchSalesSubjectReduceSubSubject()
    setSalesSettings([])
    setSalesSettings(salesSubjects || [])
    setDeleteIds([])
    !opt?.noIndicator && setIsLoading(false)
  }

  const addDeleteTarget = (id: string) => {
    setDeleteIds([...deleteIds, id])
  }

  const doDelete = async () => {
    try {
      setIsLoading(true)
      if (deleteIds.length) {
        const hasRelatedMasterIds = salesSettings.filter(detail => detail.hasSubSubject).map(detail => detail.id)
        const isRelatedDeletion = deleteIds.some(deleteId => hasRelatedMasterIds.includes(deleteId))
        if (isRelatedDeletion) {
          if (!window.confirm(t('The related sub subjects will be deleted at the same time. \nDo you really want to delete them?'))) {
            return
          }
        }
        await deleteSalesSubject(deleteIds)
        await initializeComponent()
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const doDeleteCancel = () => {
    setDeleteIds([])
  }

  const isShowFooter = useMemo(() => deleteIds.length > 0, [deleteIds])
  const deleteFilteredSalesSubject = useMemo(
    () => salesSettings.filter(detail => !deleteIds.includes(detail.id)),
    [salesSettings, deleteIds],
  )

  useEffect(() => {
    initializeComponent()
  }, [])

  const keyLength = Object.keys(salesSubjects).length - 1
  const parseOptions = {
    header: true,
    skipEmptyLines: true,
    transformHeader: function (h, i) {
      const isPlanCode = h.includes('プランコード')
      const header = !isPlanCode ? transformHeaderGet(salesSubjects, h) : `planCode${i - keyLength}`
      return header
    },
  }
  let isFormat: boolean = true
  const onFileLoad = data => {
    setIsLoading(true)
    const salesSubjects = {
      salesSubjects: data.map(value => {
        // get the property
        const planCode: string[] = []
        for (const [key, v] of Object.entries(value)) {
          // Check if it is a csv but not another format (reservation etc.)
          if (key === 'undefined') {
            isFormat = false
          }
          // Put those with planCode in the key name in the array called planCode
          const regex: RegExp = /planCode/
          const matchedKey = key.match(regex)
          if (matchedKey) {
            // Delete plan code with ''
            if (v !== '') {
              planCode.push(`${v}`)
            }
          }
        }

        return {
          departmentCode: value.departmentCode,
          departmentName: value.departmentName,
          subjectCode: value.subjectCode,
          name: value.subjectName,
          subSubjectCode: value.subSubjectCode,
          subSubjectName: value.subSubjectName,
          hasSeparated: value.hasSeparated,
          category: value.category,
          price: value.price,
          malePrice: value.malePrice,
          tax: value.tax,
          taxClass: value.taxClass,
          plans: planCode,
        }
      }),
    }

    // Check for blank required fields
    let isNotBlank = true
    let isNumber = true
    let isPregMatch = true
    let isCategoriesMatch = true
    let matchedKeyRequired: string[] | null
    let matchedKeyNumber: string[] | null
    let matchedKeyPreg: string[] | null
    let matchedCategories: string[] | null
    const requiredArray: any[] = []
    const numberArray: any[] = []
    const pregArray: any[] = []
    const categoriesArray: any[] = []
    if (isFormat) {
      data.map(value => {
        for (const [key, v] of Object.entries(value)) {
          // Put the combination with the corresponding key name into the array
          const requiredRegex: RegExp =
            /departmentCode|departmentName|subjectCode|subjectName|subSubjectCode|subSubjectName|hasSeparated|category/
          const numberRegex: RegExp = /price|tax/
          const pregRegex: RegExp = /departmentCode|subjectCode/
          const categoryRegex: RegExp = /category/
          // If the key name is the corresponding one, put it in the new matched array
          matchedKeyRequired = key.match(requiredRegex)
          matchedKeyNumber = key.match(numberRegex)
          matchedKeyPreg = key.match(pregRegex)
          matchedCategories = key.match(categoryRegex)
          if (matchedKeyRequired) {
            if (matchedKeyPreg) {
              pregArray.push(String(v))
            }
            requiredArray.push(`${v}`)
            if (matchedKeyNumber) {
              numberArray.push(Number(v))
            }
            if (matchedCategories) {
              categoriesArray.push(v)
            }
          } else if (matchedKeyNumber) {
            numberArray.push(Number(v))
          }
        }

        const blankCheck = currentValue => {
          return currentValue !== ''
        }
        const numberCheck = currentValue => {
          if (!isNaN(currentValue)) {
            return typeof currentValue == 'number'
          } else {
            return false
          }
        }
        const pregCheck = currentValue => {
          if (/^[0-9a-zA-Z]+$/.test(currentValue)) {
            return true
          } else {
            return false
          }
        }
        const categoriesCheck = currentValue => {
          return currentValue === '客室売上' || currentValue === 'その他売上' || currentValue === '仮受'
        }
        // If there is even one blank value, it becomes false
        isNotBlank = requiredArray.every(blankCheck)
        // If there is even one non-Number, it becomes false.
        isNumber = numberArray.every(numberCheck)
        // If even one piece is not a half-width alphanumeric character, it becomes false.
        isPregMatch = pregArray.every(pregCheck)

        isCategoriesMatch = categoriesArray.every(categoriesCheck)
      })

      if (isNotBlank && isNumber && isPregMatch && isCategoriesMatch) {
        importSalesSubject(salesSubjects.salesSubjects)
          .then(data => {
            if (data.errorMessage) {
              alert(data.errorMessage)
            }
          })
          .catch(e => {
            errorHandler(e)
          })
          .finally(() => {
            setIsLoading(false)
            initializeComponent()
          })
      } else if (!isNotBlank && isNumber && isPregMatch) {
        window.alert(t('A required item has not been entered check it again please'))
        window.location.reload()
      } else if (isNotBlank && !isNumber && isPregMatch) {
        window.alert(t('Incorrect format Please enter in single-byte number'))
        window.location.reload()
      } else if (!isNotBlank && !isNumber) {
        window.alert(
          t('Required items have not been entered check it again please The format is incorrect Please enter in single-byte number'),
        )
        window.location.reload()
      } else if (!isPregMatch) {
        window.alert(t('The format is incorrect Enter the department code and subject code in single-byte alphanumeric characters'))
        window.location.reload()
      } else if (!isCategoriesMatch) {
        window.alert(t('Some category format is incorrect'))
        window.location.reload()
      }
    } else {
      window.alert(t('Incorrect file check it again please'))
      window.location.reload()
    }
  }

  const footerContent = isShowFooter ? <EditFooter onSave={doDelete} onCancel={doDeleteCancel} /> : undefined

  return (
    <SettingsLayout loading={false} footerContent={footerContent}>
      <TabContainer tabComponent={<SettingSalesManagerTab currentTab="Subject" />}>
        <>
          <div css={containerHeaderStyle}>
            <Button
              buttonType={1}
              width={107}
              height={32}
              fontSize={12}
              onClick={() => setShowDetailProp(null)}
              icon={require('@/static/images/plus-white.svg')}
            >
              {t('Add new')}
            </Button>

            <Button
              buttonType={4}
              width={100}
              height={32}
              fontSize={12}
              icon={require('@/static/images/csv.svg')}
              onClick={(e: MouseEvent) => {
                document.getElementById('subject-master-csv-upload-input')?.click()
              }}
            >
              {t('CSV')}
            </Button>
            <CSVReader
              cssClass="subject-master-csv-uploader"
              inputId="subject-master-csv-upload-input"
              parserOptions={parseOptions}
              onFileLoaded={data => onFileLoad(data)}
            />
          </div>
          <SalesListItem salesSettings={deleteFilteredSalesSubject} onClickRow={setShowDetailProp} onClickDeleteRow={addDeleteTarget} />
          {showDetailProp !== undefined && (
            <SubjectDetailModal
              detail={showDetailProp}
              onSave={() => {
                initializeComponent()
                setShowDetailProp(undefined)
              }}
              onCancel={() => setShowDetailProp(undefined)}
            />
          )}
        </>
      </TabContainer>
    </SettingsLayout>
  )
}

const containerHeaderStyle = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 32px 19px 32px',
  borderBottom: '1px solid #F2F2F2',
  '.subject-master-csv-uploader': { display: 'none' },
})
