import React, { useMemo, useState } from 'react'
import { Button } from '@/components/atoms'
import { MailDeliveryContentInput, MailDeliveryStatus, isDraft, isReservation } from '@/models/mail-delivery/mail-delivery-content'
import { css } from '@emotion/core'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ButtonType } from '@/components/atoms/button'
import { isNaN } from 'lodash'

interface FormFooterProps {
  onCancel: () => void
  onSave: () => void
  onSendTest: (email: string) => void
  isDisabledSave?: boolean
  useFormReturn: UseFormReturn<MailDeliveryContentInput>
}

export const FormFooter: React.FC<FormFooterProps> = ({ onCancel, onSave, onSendTest, useFormReturn }) => {
  const { t } = useTranslation()
  const [testSendTo, setTestSendTo] = useState<string>('')

  const { setValue, watch } = useFormReturn

  const status = watch('status')
  const requireStringFields = watch(['title', 'content', 'rawContent', 'deliveryDate.deliveryTime'])
  const requireNumberFields = watch(['deliveryDate.numberOfDay'])
  const requireArrayFields = watch(['channelCodes', 'reservationStatuses'])

  const isDisabledSave = useMemo(() => {
    return (
      requireStringFields.some(field => !field) ||
      requireNumberFields.some(field => isNaN(field) || field < 0) ||
      requireArrayFields.some(field => field.length === 0)
    )
  }, [requireStringFields, requireNumberFields, requireArrayFields])

  return (
    <>
      <div css={buttonBlockStyle}>
        <Button
          buttonType={3}
          width={110}
          height={38}
          fontSize={14}
          marginLeft={32}
          marginTop={11}
          marginBottom={11}
          onClick={() => {
            onCancel()
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          buttonType={isDisabledSave ? 6 : 1}
          isDisabled={isDisabledSave}
          width={110}
          height={38}
          fontSize={14}
          marginLeft={16}
          marginRight={24}
          marginTop={11}
          marginBottom={11}
          onClick={() => {
            setValue('status', MailDeliveryStatus.Reservation)
            onSave()
          }}
        >
          {isReservation(status) ? t('Save') : t('Delivery Reservation')}
        </Button>

        {(!status || isDraft(status)) && (
          <p
            className="draft-text"
            onClick={() => {
              setValue('status', MailDeliveryStatus.Draft)
              onSave()
            }}
          >
            {t('Save Draft')}
          </p>
        )}
        {isReservation(status) && (
          <p
            className="draft-text"
            onClick={() => {
              setValue('status', MailDeliveryStatus.Halt)
              onSave()
            }}
          >
            {t('suspension of delivery')}
          </p>
        )}
      </div>

      <div css={[buttonBlockStyle, { marginLeft: 'auto' }]}>
        <input
          style={{ width: 240, height: 32 }}
          placeholder={t('Enter your email address')}
          onChange={e => setTestSendTo(e.target.value)}
        />
        <Button
          isDisabled={testSendTo.length === 0}
          buttonType={testSendTo.length === 0 ? ButtonType.PrimaryDisabled : ButtonType.Secondary}
          width={140}
          height={32}
          fontSize={12}
          marginLeft={8}
          marginTop={11}
          marginBottom={11}
          onClick={() => {
            onSendTest(testSendTo)
          }}
        >
          {t('Send test mail')}
        </Button>
      </div>
    </>
  )
}

const buttonBlockStyle = css({
  display: 'flex',
  alignItems: 'center',
  paddingRight: 32,
  input: {
    height: 32,
    padding: '8px 16px',
    border: '1px solid #CCCCCC',
    borderRadius: 17,
    textAlign: 'left',
    '::placeholder': {
      color: '#CCCCCC',
    },
  },
  '.draft-text': {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#F2A40B',
    cursor: 'pointer',
  },
})
