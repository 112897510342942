import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Button } from '@/components/atoms'

type Props = {
  onClickTop: () => void
}
export const FrontSupport: React.FC<Props> = ({ onClickTop }) => {
  const { t } = useTranslation()

  return (
    <>
      <div css={innerStyle}>
        <h1 css={descriptionStyle}>{t('Please complete the procedure at the front desk')}</h1>
        <img
          src={require('@/static/images/self-checkin/complete-image.svg')}
          alt={t('Self-checkin complete')}
          css={checkinCompleteImgStyle}
        />
        <span css={noticeStyle}>{t('Please pick up your key and settle your account at the front desk')}</span>
        <Button
          buttonType={3}
          width={144}
          height={48}
          fontSize={18}
          marginLeft="auto"
          fontWeight="normal"
          marginRight="auto"
          onClick={onClickTop}
        >
          {t('Top')}
        </Button>
      </div>
      <div css={languageSelectStyle}></div>
    </>
  )
}

const innerStyle = css({
  width: 454,
  margin: '130px auto',
})

const descriptionStyle = css({
  marginBottom: 38,
  color: '#D68E0F',
  fontSize: 24,
  height: 36,
  fontWeight: 'bold',
  textAlign: 'center',
  letterSpacing: '1.2px',
  lineHeight: '34px',
})

const checkinCompleteImgStyle = css({
  width: '400px',
  margin: '0 auto 53px',
  display: 'block',
})

const languageSelectStyle = css({
  paddingBottom: 32,
})
const noticeStyle = css({
  fontSize: 16,
  color: '#272727',
  lineHeight: '21px',
  letterSpacing: '1.6px',
  height: 24,
  display: 'block',
  textAlign: 'center',
  marginBottom: '47px',
})
