import { guestInfoType } from '@/models/checkin'
import { CustomerType } from '@/models/customer-manager/customer'
import { OccupationType } from '@/models/self-checkin/accommodation-info'

export const occupations = [
  {
    label: 'SelectOptions.Occupation.Employee',
    value: 'SelectOptions.Occupation.Employee',
  },
  {
    label: 'SelectOptions.Occupation.SelfEmployed',
    value: 'SelectOptions.Occupation.SelfEmployed',
  },
  {
    label: 'SelectOptions.Occupation.Student',
    value: 'SelectOptions.Occupation.Student',
  },
  {
    label: 'SelectOptions.Occupation.NoOccupation',
    value: 'SelectOptions.Occupation.NoOccupation',
  },
]

const others = {
  label: 'SelectOptions.Occupation.Other',
  value: 'SelectOptions.Occupation.Other',
}

export const occupationsWithOthers = [...occupations, others]

export const customerOccupation = (occupationInfo: guestInfoType | CustomerType | undefined) => {
  if (occupationInfo?.occupation === OccupationType.Employee || occupationInfo?.occupation === OccupationType.SelfEmployee) {
    return `${occupationInfo.occupation} / ${occupationInfo?.company || '-'}`
  } else if (occupationInfo?.occupation === OccupationType.Other) {
    return occupationInfo.otherOccupation
  } else if (!occupationInfo?.occupation) {
    return '-'
  } else {
    return occupationInfo?.occupation
  }
}
