import React, { useEffect, useRef, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// apis
import { fetchCustomerSearch } from '@/apis/aipass'

// components
import { Button } from '@/components/atoms/button'
import { modalContainerStyle } from '@/components/pages/accommodation-management/list-layout'

// libs
import { InputField } from '@/components/molecules/input-field'

// models
import { CustomerSearchType } from '@/models/customer-manager/customer'

type SearchCustomerLinkModalProps = {
  setIsOpenSearchCustomerLinkModal: (v: boolean) => void
  onSaveCustomerLink: () => void
  hotelCuicinUserId: string
  setHotelCuicinUserId: (v: string) => void
}

export const SearchCustomerLinkModal: React.FC<SearchCustomerLinkModalProps> = ({
  hotelCuicinUserId,
  setHotelCuicinUserId,
  setIsOpenSearchCustomerLinkModal,
  onSaveCustomerLink,
}) => {
  const { t } = useTranslation()

  const [isCheckboxActive, setIsCheckboxActive] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [nameKana, setNameKana] = useState<string>('')
  const [telephone, setTelephone] = useState<string>('')
  const [customers, setCustomers] = useState<CustomerSearchType[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [totalNumber, setTotalNumber] = useState<number>(1)
  const listDiv = useRef<HTMLDivElement>(null)

  const DISPLAY_LIMIT_IN_PAGE = 30
  const displayFrom = DISPLAY_LIMIT_IN_PAGE * (currentPage - 1) + 1
  const displayTo = Math.min(DISPLAY_LIMIT_IN_PAGE * currentPage, totalNumber)

  const _fetchCustomerSearch = async (page: number) => {
    await fetchCustomerSearch(name, nameKana, telephone, '', page, DISPLAY_LIMIT_IN_PAGE)
      .then(res => {
        resetListScroll()
        setCustomers(res?.hotelCuicinUsers)
        setTotalNumber(res?.total)
        setCurrentPage(page)
        setLastPage(Math.ceil(res?.total / DISPLAY_LIMIT_IN_PAGE))
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'name') setName(value)
    if (name === 'nameKana') setNameKana(value)
    if (name === 'telephone') setTelephone(value)
  }

  const onClickPrevious = () => {
    if (currentPage === 1) return
    _fetchCustomerSearch(currentPage - 1)
  }

  const onClickNext = () => {
    if (currentPage === lastPage) return
    _fetchCustomerSearch(currentPage + 1)
  }

  const resetListScroll = () => {
    if (listDiv?.current?.scrollTop) {
      listDiv.current.scrollTop = 0
    }
  }

  const onSubmit = () => {
    setHotelCuicinUserId('')
    setIsCheckboxActive(false)
    _fetchCustomerSearch(1)
  }

  return (
    <>
      <div css={modalContainerStyle}></div>
      <div css={modalBodyContainerStyle}>
        <h3 css={modalHeaderStyle}>{t('Customer search')}</h3>
        <div css={modalMainContainerStyle}>
          <div css={modalSearchContainerStyle}>
            <InputField
              label={t('Full name')}
              width="95%"
              placeholder={t('John Doe')}
              name="name"
              value={name}
              handleChangeData={onChange}
            />
            <InputField
              label={t('Furigana')}
              width="95%"
              placeholder={'ヤマダ タロウ'}
              name="nameKana"
              value={nameKana}
              handleChangeData={onChange}
            />
            <InputField
              label={t('Phone number')}
              width="95%"
              placeholder="08012345678"
              name="telephone"
              value={telephone}
              handleChangeData={onChange}
            />
            <div css={searchButtonStyle}>
              <Button buttonType={1} width={58} height={32} fontSize={12} onClick={onSubmit}>
                {t('Search')}
              </Button>
            </div>
          </div>
          <ul css={labelContainerStyle}>
            <li css={userLabelStyle}>{t('Full name')}</li>
            <li css={telephoneLabelStyle}>{t('Phone number')}</li>
          </ul>
          <div css={modalMainCheckInListContainerStyle} ref={listDiv}>
            {customers &&
              customers?.map(v => (
                <div
                  key={v.id}
                  css={hotelCuicinUserId === v?.id ? activeStyle : nonActiveStyle}
                  onClick={() => {
                    setHotelCuicinUserId(v.id)
                    setIsCheckboxActive(true)
                  }}
                >
                  <img
                    alt="checkbox"
                    src={require(`@/static/images/radio${hotelCuicinUserId === v.id ? '_on' : '_off'}.svg`)}
                    css={checkBoxStyle}
                  />
                  <div css={userWrapperStyle}>
                    <img css={imageStyle} src={v?.picture ? v?.picture : require('@/static/images/user.svg')} />
                    <div css={nameWrapperStyle}>
                      <ruby>{v?.nameKana === null || v?.nameKana === '' ? '-' : v.nameKana}</ruby>
                      <div css={nameStyle}>{v?.name === null || v?.name === '' ? '-' : v.name}</div>
                    </div>
                    <div css={telephoneStyle}>{v?.telephone === null || v?.telephone === '' ? '-' : v.telephone}</div>
                  </div>
                </div>
              ))}
          </div>
          <div css={detailListPaginationContainerStyle}>
            <p css={paginationLineCountStyle}>
              {displayFrom}-{displayTo} / {totalNumber} {t('Rows')}
            </p>
            <div css={paginationArrowStyle} onClick={() => {}}>
              {displayFrom === 1 ? (
                <img alt="arrow_disabled_left" src={require('@/static/images/arrow_disabled_left.svg')} onClick={onClickPrevious} />
              ) : (
                <img alt="arrow_left" src={require('@/static/images/arrow_left.svg')} onClick={onClickPrevious} />
              )}
            </div>
            <div css={paginationArrowStyle} onClick={() => {}}>
              {displayTo === totalNumber ? (
                <img alt="arrow_disabled_right" src={require('@/static/images/arrow_disabled_right.svg')} onClick={onClickNext} />
              ) : (
                <img alt="arrow_right" src={require('@/static/images/arrow_right.svg')} onClick={onClickNext} />
              )}
            </div>
          </div>
        </div>
        <div css={modalButtonSectionStyle}>
          <Button width={110} height={38} buttonType={3} marginRight={16} onClick={() => setIsOpenSearchCustomerLinkModal(false)}>
            {t('Cancel')}
          </Button>
          <Button width={110} height={40} buttonType={isCheckboxActive ? 1 : 5} onClick={onSaveCustomerLink}>
            {t('Confirm')}
          </Button>
        </div>
      </div>
    </>
  )
}

// Modal
const modalBodyContainerStyle = css({
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: 680,
  zIndex: 102,
  opacity: 1,
  background: '#FFF',
  borderRadius: 5,
  transform: 'translate(-50%, -50%)',
})

const modalHeaderStyle = css({
  margin: '22px 32px',
  fontWeight: 'bold',
  fontSize: 18,
  color: '#272727',
})

const modalMainContainerStyle = css({
  padding: '26px 32px',
  backgroundColor: '#F2F2F2',
})

const userLabelStyle = css({
  marginLeft: 102,
  width: '50%',
})

const telephoneLabelStyle = css({
  width: '50%',
})

const modalMainCheckInListContainerStyle = css({
  width: '100%',
  height: 256,
  backgroundColor: '#FFF',
  border: '1px solid #CCCCCC',
  borderTop: 'none',
  borderRadius: '0 0 5px 5px',
  overflow: 'auto',
})

const labelContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#FFF',
  padding: '10px 36px',
  color: '#676767',
  fontWeight: 'bold',
  fontSize: 12,
  borderRadius: '5px 5px 0 0',
  borderLeft: '1px solid #CCCCCC',
  borderTop: '1px solid #CCCCCC',
  borderRight: '1px solid #CCCCCC',
  borderBottom: '1px solid #F2F2F2',
})

const nonActiveStyle = css({
  cursor: 'pointer',
  minHeight: 64,
  display: 'flex',
  alignItems: 'center',
  padding: '12px 27px 12px 32px',
  borderBottom: '1px solid #f2f2f2',
  ':hover': {
    boxShadow: '0px 3px 6px #0000000f',
  },
})

const activeStyle = css(nonActiveStyle, {
  backgroundColor: '#fef3f0',
})

const userWrapperStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  display: 'flex',
  marginLeft: 25,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  alignItems: 'center',
})

const modalButtonSectionStyle = css({
  display: 'flex',
  padding: '11px 32px 11px 32px',
  justifyContent: 'flex-end',
  boxShadow: '0px 0px 6px #0000001A',
})

const nameStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginLeft: 25,
  width: 220,
})

const telephoneStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: 220,
})

const checkBoxStyle = css({
  width: 18,
})

const detailListPaginationContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: 30,
  width: '100%',
  justifyContent: 'flex-end',
  marginTop: 15,
})

const paginationLineCountStyle = css({
  fontSize: 12,
  color: '#676767',
  marginRight: 16,
  padding: '8px 0',
  fontWeight: 'bold',
})

const paginationArrowStyle = css({
  borderRadius: '50%',
  height: 30,
  ':hover': {
    backgroundColor: '#F2F2F2',
    cursor: 'pointer',
  },
})

const modalSearchContainerStyle = css({
  display: 'flex',
})

const searchButtonStyle = css({
  display: 'flex',
  alignItems: 'flex-end',
  marginBottom: 16,
})

const imageStyle = css({
  margin: '0 auto',
  display: 'block',
  width: 40,
  borderRadius: '50%',
})

const nameWrapperStyle = css({
  color: '#272727',
  display: 'flex',
  flexDirection: 'column',
  ruby: {
    color: '#676767',
    fontSize: 12,
    marginLeft: 25,
    paddingBottom: 6,
  },
})
