import { ApprovedStatusType } from '@/models/reservation-approved-status'

export type OrderType = {
  id: string
  hotelId: string
  checkinId: string
  approvedStatus: ApprovedStatusType
  guestName: string
  guestPicture: string
  status: number
  roomNumber: string
  paymentMethod: number | string
  requestRead: boolean
  createdAt: Date
  updatedAt: Date
  products: ProductsType[]
}

export const OrderStatusType = {
  Uncompleted: 0,
  Completed: 1,
}

export type ProductsType = {
  id: string
  nameJa: string
  price: string
  numberOfItems: string
}

export type DashboardOrderType = Omit<OrderType, 'guestPicture'>

export const OrderPaymentMethod = ['チェックアウト払い', 'アイパス決済', '-']
