import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Controller, useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'
import { InputField } from '@/components/molecules/input-field'
import { createSalesSubSubject, fetchSalesSubjectReduceSubSubject, updateSalesSubSubject } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { SalesSubSubjectMasterType, SalesSubjectType } from '@/models/sales-manager/sales'
import { LoaderContextCreator } from '@/contexts/loader'
import { Select, Option } from '@/components/atoms/select'

type Props = {
  detail: SalesSubSubjectMasterType | null
  onCancel: () => void
  onSave: () => void
}

export type FormValue = {
  salesSubjectMasterId: string
  subSubjectCode: string
  name: string
  price: number
}

export const SubSubjectDetailModal: React.FC<Props> = ({ detail, onCancel, onSave: parentSave }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValue>({
    defaultValues: {
      salesSubjectMasterId: detail?.salesSubjectMasterId || undefined,
      subSubjectCode: detail?.subSubjectCode || '',
      name: detail?.name || '',
      price: detail ? detail.price || 0 : undefined,
    },
  })
  const { errorHandler } = useErrorHandler()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const [salesSubjects, setSalesSubjects] = useState<SalesSubjectType[]>([])
  const [isInitializing, setIsInitializing] = useState<boolean>(true)

  const onSave = async (value: FormValue) => {
    try {
      setIsLoading(true)
      if (detail) {
        await updateSalesSubSubject(detail.id, value)
      } else {
        await createSalesSubSubject(value)
      }
      parentSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchSalesSubjectReduceSubSubject().then(res => {
      setSalesSubjects(res || [])
      setIsInitializing(false)
    })
  }, [])

  return (
    <>
      <Modal customCss={modalStyle}>
        <ModalHeader>{t(detail ? 'Edit auxiliary course' : 'Add a auxiliary course')}</ModalHeader>
        <ModalBody>
          <div css={modalBodyStyle}>
            <div css={inputTextStyle}>
              {t('Auxiliary course ID')}
              <div css={requireLabelTextStyle}>※</div>
            </div>
            <Controller
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              name="subSubjectCode"
              render={({ field: { onChange, value } }) => (
                <InputField
                  value={value}
                  handleChangeData={onChange}
                  error={errors.subSubjectCode?.message}
                  placeholder="1010"
                  marginBottom={24}
                />
              )}
            />

            <div css={inputTextStyle}>
              {t('Auxiliary course name')}
              <div css={requireLabelTextStyle}>※</div>
            </div>
            <Controller
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              name="name"
              render={({ field: { onChange, value } }) => (
                <InputField
                  value={value}
                  handleChangeData={onChange}
                  error={errors.name?.message}
                  placeholder={t('Auxiliary course name')}
                  marginBottom={24}
                />
              )}
            />

            <div css={inputTextStyle}>
              {t('Subject name')}
              <div css={requireLabelTextStyle}>※</div>
            </div>
            <Controller
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              name="salesSubjectMasterId"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select
                  placeholder={t('Please select')}
                  value={!isInitializing ? value : undefined}
                  onChange={onChange}
                  error={error?.message}
                  showSearch
                  filterOption={true}
                  optionFilterProp="children"
                  customStyle={css({ paddingBottom: 24 })}
                >
                  {salesSubjects.map(v => (
                    <Option key={v.id} value={v.id}>
                      {v.subjectCode}/{v.subjectName}
                    </Option>
                  ))}
                  {detail && !salesSubjects.find(master => master.id === detail?.salesSubjectMasterId) && (
                    <Option value={detail?.salesSubjectMaster.id}>
                      {detail?.salesSubjectMaster.subjectCode}/{detail?.salesSubjectMaster.name}
                    </Option>
                  )}
                </Select>
              )}
            />

            <hr css={hrStyle}></hr>

            <div css={inputTextStyle}>
              {t('Amount of money')}
              <div css={requireLabelTextStyle}>※</div>
            </div>
            <Controller
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              name="price"
              render={({ field: { onChange, value } }) => (
                <InputField
                  type="number"
                  value={value}
                  handleChangeData={onChange}
                  error={errors.price?.message}
                  placeholder="1000"
                  marginBottom={24}
                />
              )}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(onSave)}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const modalStyle = {
  minHeight: 534,
  maxHeight: 534,
  width: 600,
  minWidth: 600,
  left: 'calc((100% - 600px) / 2)',
}

const modalBodyStyle = css({
  padding: 32,
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
})

const inputTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})
const hrStyle = css({
  borderColor: '#F2F2F2',
  marginTop: 0,
  marginBottom: 24,
})
