import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import { Button } from '@/components/atoms/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { NoticeLoading } from '@/components/molecules/notice-loading'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { StaffType } from '@/models/staff'
import { InputField } from '@/components/molecules/input-field'
import * as API from '@/apis/aipass'
import { capitalize } from 'lodash'

type SettingsEditStaffModalProps = {
  staff?: StaffType
  onSave: () => any
  onClose: () => any
}

export const SettingsEditStaffModal = ({ staff, onSave, onClose }: SettingsEditStaffModalProps) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [loading, setLoading] = useState<boolean>(false)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<StaffType>({
    defaultValues: staff ? staff : {},
  })

  const save = async formData => {
    try {
      setLoading(true)

      if (staff?.id) {
        await API.updateStaff(staff.id, { ...staff, ...formData })
      } else {
        await API.createStaff(formData)
      }

      onSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal customCss={{ height: 458, width: 600, minWidth: 600, left: 'calc(50% - 300px)' }}>
      <ModalHeader>{t(staff?.id ? 'Update Staff' : 'Add Staff')}</ModalHeader>
      <ModalBody>
        {loading && <NoticeLoading isLoading={loading} />}
        <div css={{ padding: 16, border: '1px solid #ddd', borderRadius: 8, backgroundColor: '#fff' }}>
          <Controller
            control={control}
            name="order"
            rules={{
              required: t('Required field has not been entered'),
              pattern: { value: /^[0-9]{0,3}$/, message: t('Please enter half-width numbers up to {{count}}', { count: 3 }) },
            }}
            render={({ field: { onChange, value } }) => (
              <InputField
                value={value}
                handleChangeData={onChange}
                error={errors.order?.message}
                label={t('ID')}
                placeholder="00"
                required
              />
            )}
          />
          <Controller
            control={control}
            name="name"
            rules={{ required: t('Required field has not been entered') }}
            render={({ field: { onChange, value } }) => (
              <InputField
                value={value}
                handleChangeData={onChange}
                error={errors.name?.message}
                label={t('Full name')}
                placeholder={t('John Doe')}
                required
              />
            )}
          />
          <Controller
            control={control}
            name="passCode"
            rules={{
              pattern: { value: /^[0-9]{4}?$/, message: t('Please enter {{count}} half-width numbers', { count: 4 }) },
            }}
            render={({ field: { onChange, value } }) => (
              <InputField
                value={value}
                handleChangeData={onChange}
                error={errors.passCode?.message}
                label={capitalize(t('Pass code'))}
                placeholder="0000"
              />
            )}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={1} height="38px" width="110px" onClick={handleSubmit(save)}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
