import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'

// contexts
import { AccountContext } from '@/contexts/account'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle, listContainerStyle } from '@/constants/layout'

// apis
import { fetchFacilityStatusInfo, updateFacilityStatusInfo } from '@/apis/aipass'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { SettingSideMenu } from '@/components/organisms/setting-side-menu'
import { FacilityInfoList } from '@/components/pages/setting/facility/list/_list/facility-info-list'

// models
import { facilityStatusInfoType, FacilityPageIndex } from '@/models/facility'

type ContainerProps = {
  location: any
  history: any
  isLoading: boolean
  basicStatus: facilityStatusInfoType
  facilityStatusInfo: facilityStatusInfoType[]
  updateFacilityStatus: (pageIndex: string, checked: boolean) => void
}

export const SettingFacilityList: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const location = useLocation()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [checkinOutStatus, setCheckinOutStatus] = useState<facilityStatusInfoType>({
    pageIndex: FacilityPageIndex.CheckinOut,
    viewStatus: 0,
  })
  // FIXME: It doesn't have to be State because there is an unused part
  // eslint-disable-next-line no-unused-vars
  const [_basicStatus, setBasicStatus] = useState<facilityStatusInfoType>({ pageIndex: FacilityPageIndex.Basic, viewStatus: 0 })
  const [accessStatus, setAccessStatus] = useState<facilityStatusInfoType>({ pageIndex: FacilityPageIndex.Access, viewStatus: 0 })
  const [wifiStatus, setwifiStatus] = useState<facilityStatusInfoType>({ pageIndex: FacilityPageIndex.Wifi, viewStatus: 0 })
  const [guideStatus, setGuideStatus] = useState<facilityStatusInfoType>({ pageIndex: FacilityPageIndex.Guide, viewStatus: 0 })
  const [floorStatus, setFloorStatus] = useState<facilityStatusInfoType>({ pageIndex: FacilityPageIndex.Floor, viewStatus: 0 })
  const [termsStatus, setTermsStatus] = useState<facilityStatusInfoType>({ pageIndex: FacilityPageIndex.Terms, viewStatus: 0 })
  const [mealStatus, setMealStatus] = useState<facilityStatusInfoType>({ pageIndex: FacilityPageIndex.Meal, viewStatus: 0 })
  const [bathStatus, setBathStatus] = useState<facilityStatusInfoType>({ pageIndex: FacilityPageIndex.Bath, viewStatus: 0 })
  const [equipmentStatus, setEquipmentStatus] = useState<facilityStatusInfoType>({
    pageIndex: FacilityPageIndex.Equipment,
    viewStatus: 0,
  })

  useEffect(() => {
    if (account && account?.hotel) {
      _fetchFacilityStatusInfo()
    }
  }, [account])

  const _fetchFacilityStatusInfo = async () => {
    await fetchFacilityStatusInfo().then(res => {
      res?.facilityStatus.map(statusInfo => {
        if (statusInfo.pageIndex === FacilityPageIndex.CheckinOut) {
          setCheckinOutStatus(statusInfo)
        }
        if (statusInfo.pageIndex === FacilityPageIndex.Basic) {
          setBasicStatus(statusInfo)
        }
        if (statusInfo.pageIndex === FacilityPageIndex.Wifi) {
          setwifiStatus(statusInfo)
        }
        if (statusInfo.pageIndex === FacilityPageIndex.Meal) {
          setMealStatus(statusInfo)
        }
        if (statusInfo.pageIndex === FacilityPageIndex.Bath) {
          setBathStatus(statusInfo)
        }
        if (statusInfo.pageIndex === FacilityPageIndex.Equipment) {
          setEquipmentStatus(statusInfo)
        }
        if (statusInfo.pageIndex === FacilityPageIndex.Floor) {
          setFloorStatus(statusInfo)
        }
        if (statusInfo.pageIndex === FacilityPageIndex.Access) {
          setAccessStatus(statusInfo)
        }
        if (statusInfo.pageIndex === FacilityPageIndex.Guide) {
          setGuideStatus(statusInfo)
        }
        if (statusInfo.pageIndex === FacilityPageIndex.Terms) {
          setTermsStatus(statusInfo)
        }
      })
      setIsLoading(false)
    })
  }

  const _updateFacilityStatus = (pageIndex, checked) => {
    setIsLoading(true)
    const viewStatus = checked ? 1 : 0
    updateFacilityStatusInfo(pageIndex, viewStatus).then(() => {
      setIsLoading(false)
    })
    if (pageIndex === FacilityPageIndex.CheckinOut) {
      setCheckinOutStatus({
        pageIndex,
        viewStatus,
      })
    }
    if (pageIndex === FacilityPageIndex.Basic) {
      setBasicStatus({
        pageIndex,
        viewStatus,
      })
    }
    if (pageIndex === FacilityPageIndex.Wifi) {
      setwifiStatus({
        pageIndex,
        viewStatus,
      })
    }
    if (pageIndex === FacilityPageIndex.Meal) {
      setMealStatus({
        pageIndex,
        viewStatus,
      })
    }
    if (pageIndex === FacilityPageIndex.Bath) {
      setBathStatus({
        pageIndex,
        viewStatus,
      })
    }
    if (pageIndex === FacilityPageIndex.Equipment) {
      setEquipmentStatus({
        pageIndex,
        viewStatus,
      })
    }
    if (pageIndex === FacilityPageIndex.Floor) {
      setFloorStatus({
        pageIndex,
        viewStatus,
      })
    }
    if (pageIndex === FacilityPageIndex.Access) {
      setAccessStatus({
        pageIndex,
        viewStatus,
      })
    }
    if (pageIndex === FacilityPageIndex.Guide) {
      setGuideStatus({
        pageIndex,
        viewStatus,
      })
    }
    if (pageIndex === FacilityPageIndex.Terms) {
      setTermsStatus({
        pageIndex,
        viewStatus,
      })
    }
  }
  const windows = useWindowSize()

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header title={t('Setting')} isPublicPageLink />
        <div className="mainContainer" css={mainContainer}>
          <SettingSideMenu />
          <div css={facilityInfoContainerStyle}>
            <div css={facilityInfoMainContainerStyle}>
              <div css={facilityInfoHeaderContainerStyle}>
                <div
                  css={facilityInfoHeaderTitleStyle}
                  style={{
                    maxHeight: windows.height - 103,
                  }}
                >
                  {t('Facility guidance')}
                </div>
              </div>
              <div
                css={scrollStyle}
                style={{
                  maxHeight: windows.height - 153,
                }}
              >
                <FacilityInfoList
                  statusInfo={checkinOutStatus}
                  onClick={() => history.push({ pathname: '/setting/facility/checkin-out/edit', search: location.search })}
                  onChange={v => _updateFacilityStatus(checkinOutStatus?.pageIndex, v.target.checked)}
                />
                {/* <FacilityInfoList
                  statusInfo={basicStatus}
                  onClick={() => history.push({ pathname: '/setting/facility/basic/edit', search: location.search })}
                  onChange={v => _updateFacilityStatus(basicStatus?.pageIndex, v.target.checked)}
                /> */}
                <FacilityInfoList
                  statusInfo={wifiStatus}
                  onClick={() => history.push({ pathname: '/setting/facility/wifi/edit', search: location.search })}
                  onChange={v => _updateFacilityStatus(wifiStatus?.pageIndex, v.target.checked)}
                />
                <FacilityInfoList
                  statusInfo={mealStatus}
                  onClick={() => history.push({ pathname: '/setting/facility/meal/edit', search: location.search })}
                  onChange={v => _updateFacilityStatus(mealStatus?.pageIndex, v.target.checked)}
                />
                <FacilityInfoList
                  statusInfo={bathStatus}
                  onClick={() => history.push({ pathname: '/setting/facility/bath/edit', search: location.search })}
                  onChange={v => _updateFacilityStatus(bathStatus?.pageIndex, v.target.checked)}
                />
                <FacilityInfoList
                  statusInfo={equipmentStatus}
                  onClick={() => history.push({ pathname: '/setting/facility/equipment/edit', search: location.search })}
                  onChange={v => _updateFacilityStatus(equipmentStatus?.pageIndex, v.target.checked)}
                />
                <FacilityInfoList
                  statusInfo={floorStatus}
                  onClick={() => history.push({ pathname: '/setting/facility/floor/edit', search: location.search })}
                  onChange={v => _updateFacilityStatus(floorStatus?.pageIndex, v.target.checked)}
                />
                <FacilityInfoList
                  statusInfo={accessStatus}
                  onClick={() => history.push({ pathname: '/setting/facility/access/edit', search: location.search })}
                  onChange={v => _updateFacilityStatus(accessStatus?.pageIndex, v.target.checked)}
                />
                <FacilityInfoList
                  statusInfo={guideStatus}
                  onClick={() => history.push({ pathname: '/setting/facility/guide/edit', search: location.search })}
                  onChange={v => _updateFacilityStatus(guideStatus?.pageIndex, v.target.checked)}
                />
                <FacilityInfoList
                  statusInfo={termsStatus}
                  onClick={() => history.push({ pathname: '/setting/facility/terms/edit', search: location.search })}
                  onChange={v => _updateFacilityStatus(termsStatus?.pageIndex, v.target.checked)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const mainContainer = css(mainContainerStyle, {
  display: 'flex',
  padding: 0,
})

const facilityInfoContainerStyle = css({
  width: 'calc(100% - 180px)',
  height: 'calc(100vh - 55px)',
  padding: 24,
})
const facilityInfoMainContainerStyle = css(listContainerStyle, {
  width: '100%',
  height: '100%',
  background: '#FFF',
  marginBottom: 16,
})

const facilityInfoHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const facilityInfoHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
})

const scrollStyle = css({
  overflowY: 'scroll',
})
