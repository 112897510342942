import { TBody, THead, Table } from '@/components/molecules/settings/mobile-request/table'
import { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'
import { CreateProductModal } from './create-product-modal'
import { ProductType } from '@/models/mobile-request'
import { useTranslation } from 'react-i18next'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { deleteMobileRequestProducts, fetchMobileRequestProducts } from '@/apis/aipass'
import { css } from '@emotion/core'
import { LoadingFull } from '@/components/molecules/loading-full'

type Props = {
  onChange: () => void
  listenCancel: Dayjs | undefined
  listenSave: Dayjs | undefined
  listenOpenModal: Dayjs | undefined
}

export const ProductTable: React.FC<Props> = ({ onChange, listenCancel, listenSave, listenOpenModal }) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { errorHandler } = useErrorHandler()
  const [removeProductIds, setRemoveProductIds] = useState<string[]>([])
  const [isShowModal, setIsShowModal] = useState(false)
  const [editingDetail, setEditingDetail] = useState<ProductType>()
  const [products, setProducts] = useState<ProductType[]>([])
  const [nextOrder, setNextOrder] = useState<number>(1)

  const fetchProduct = async () => {
    const products = (await fetchMobileRequestProducts()) || [];
    setProducts(products)
    setNextOrder(products.length ? products[products.length -1].order + 1 : 1)
  }

  const showDetail = (product: ProductType) => {
    setEditingDetail(product)
    setIsShowModal(true)
  }

  const removeList = (id: string) => {
    setRemoveProductIds([...removeProductIds, id])
    setProducts(products.filter(p => p.id !== id))
    onChange()
  }

  const onDelete = async () => {
    try {
      setIsLoading(true)
      await deleteMobileRequestProducts(removeProductIds)
      setRemoveProductIds([])
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onModalSave = () => {
    fetchProduct()
  }

  const onModalClose = () => {
    setEditingDetail(undefined)
    setIsShowModal(false)
  }

  useEffect(() => {
    if (listenCancel) fetchProduct()
  }, [listenCancel])

  useEffect(() => {
    if (listenOpenModal) setIsShowModal(true)
  }, [listenOpenModal])

  useEffect(() => {
    if (listenSave) onDelete()
  }, [listenSave])

  useEffect(() => {
    fetchProduct()
  }, [])

  return (
    <>
      <Table>
        <THead>
          <tr>
            <th style={{ width: 83 }}>{t('Display order')}</th>
            <th style={{ width: 72 }}></th>
            <th style={{ minWidth: 200 }}>{t('Title')}</th>
            <th style={{ minWidth: 112 }}>{t('Category')}</th>
            <th style={{ minWidth: 195 }}>{t('Tag')}</th>
            <th style={{ minWidth: 136 }}>{t('MobileRequest.Sales hours')}</th>
            <th style={{ width: 85 }}>{t('Amount')}</th>
            <th style={{ width: 51 }}>{t('Stock')}</th>
            <th style={{ width: 72 }}></th>
          </tr>
        </THead>
        <TBody>
          {products.map(item => (
            <tr key={item.id} onClick={() => showDetail(item)}>
              <td>{item.order}</td>
              <td>
                <div css={thumbnailImageStyle} style={{ backgroundImage: `url(${item.imagePath})` }}></div>
              </td>
              <td>{item.name[i18n.language]}</td>
              <td>{item.category?.name[i18n.language]}</td>
              <td style={{ whiteSpace: 'normal' }}>
                {item.tags.map(tag => (
                  <span css={tagStyle} key={tag.id}>
                    <span css={tagTextStyle}>{tag.name[i18n.language]}</span>
                  </span>
                ))}
              </td>
              <td>{item.salesHour?.name}</td>
              <td>¥{item.price.toLocaleString()}</td>
              <td>{item.stock.toLocaleString()}</td>
              <td>
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={e => {
                    e.stopPropagation()
                    removeList(item.id)
                  }}
                  src={require('@/static/images/mobile_request/delete_orange.svg')}
                  alt=""
                />
              </td>
            </tr>
          ))}
        </TBody>
      </Table>
      {isShowModal && <CreateProductModal editing={editingDetail} nextOrder={nextOrder} onSave={onModalSave} onClose={onModalClose} />}
      <LoadingFull isLoading={isLoading} />
    </>
  )
}

const thumbnailImageStyle = css({
  backgroundSize: 38,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  borderRadius: 5,
  width: 38,
  height: 38,
})

const tagStyle = css({
  fontSize: 12,
  color: '#676767',
  letterSpacing: '1.2px',
  fontWeight: 'bold',
  borderRadius: 12,
  backgroundColor: '#F2F2F2',
  margin: '3px 8px 3px 0',
  padding: 6,
  maxWidth: 84,
  display: 'inline-block',
})

const tagTextStyle = css({
  overflow: 'hidden',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  display: '-webkit-box',
})
