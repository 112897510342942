import React, { useContext, useEffect, useMemo, useState } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { EditFooter } from '@/components/organisms/edit-footer'
import { GuestAppTab } from '@/components/molecules/settings/guest-app-tab'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { ToggleButton } from '@/components/molecules/settings/toggle-button'
import { deleteAsset, getAssetList, updateAsset, updateAssetDisplayOrder } from '@/apis/aipass'
import { AssetType, formatTypeLabel } from '@/models/guest-app/asset'
import { useHistory } from 'react-router-dom'
import { ContainerHeader } from '@/components/molecules/settings/container-header'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useDraggableDom } from '@/components/molecules/settings/use-draggable-dom'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'
import { AddAssetModal } from '@/components/organisms/settings/guest-app/add-asset-modal'
import { PluginCode, checkActivePlugin } from '@/libs/plugins'
import { AccountContext } from '@/contexts/account'
import { GuestAppTableHeader, GuestAppTableBody, GuestAppTableRow } from '@/components/atoms/settings/guest-app-table'

export const SettingGuestAppAsset: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [assets, setAssets] = useState<AssetType[]>([])
  const [isShowFooter, setIsShowFooter] = useState<boolean>(false)
  const [deleteIds, setDeleteIds] = useState<string[]>([])
  const [showAddAssetModal, setShowAddAssetModal] = useState<boolean>(false)
  const { errorHandler } = useErrorHandler()
  const history = useHistory()
  const changeDisplayOrder = (newList: AssetType[]) => {
    setAssets(newList)
    setIsShowFooter(true)
  }
  const { dragStart, dragOver, dragDrop } = useDraggableDom({ list: assets, onChange: changeDisplayOrder })
  const { plugins } = useContext<any>(AccountContext)

  const hasAssetCustomPlugin = useMemo(() => checkActivePlugin(plugins, PluginCode.GuestAppAssetCustom), [plugins])

  const addDeleteTarget = (id: string) => {
    setAssets(assets.filter(asset => asset.id !== id))
    setDeleteIds([...deleteIds, id])
    setIsShowFooter(true)
  }

  const doSave = async () => {
    try {
      setLoading(true)
      await updateAssetDisplayOrder(assets.map(asset => asset.id))
      if (deleteIds.length) {
        await deleteAsset(deleteIds)
        setDeleteIds([])
      }
      initialView()
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const doToggleVisibleStatus = async (id: string) => {
    setLoading(true)
    await updateAsset(id, { changeVisible: true })
    setAssets(await getAssetList())
    setLoading(false)
  }

  const initialView = async () => {
    setIsShowFooter(false)
    setDeleteIds([])
    setAssets(await getAssetList())
  }

  useEffect(() => {
    initialView()
  }, [])

  const footerContent = isShowFooter ? <EditFooter onSave={doSave} onCancel={initialView} /> : undefined
  return (
    <>
      <SettingsLayout loading={loading} footerContent={footerContent}>
        <TabContainer tabComponent={<GuestAppTab currentTab="Asset" />} isShowFooter={isShowFooter}>
          <>
            <ContainerHeader onClickAddButton={hasAssetCustomPlugin ? () => setShowAddAssetModal(true) : undefined} />
            <GuestAppTableHeader>
              <div css={titleColStyle}>{t('Title')}</div>
              <div css={formatColStyle}>{t('Format')}</div>
              <div css={toggleColStyle}></div>
              <div css={deleteColStyle}></div>
            </GuestAppTableHeader>
            <GuestAppTableBody>
              {assets.map(asset => (
                <GuestAppTableRow
                  className={hasAssetCustomPlugin && asset.isFixed ? '' : 'is-custom'}
                  id={asset.id}
                  key={asset.id}
                  onClick={() => {
                    if (!asset.isFixed) {
                      history.push({ pathname: `/setting/guest-app/asset/${asset.id}` })
                    }
                  }}
                  draggable={hasAssetCustomPlugin && !asset.isFixed}
                  onDragStart={dragStart}
                  onDragOver={dragOver}
                  onDrop={dragDrop}
                >
                  <div css={titleColStyle}>{asset.title}</div>
                  <div css={formatColStyle}>{asset.isFixed ? '-' : t(formatTypeLabel[asset.formatType])}</div>
                  <div css={toggleColStyle}>
                    <ToggleButton
                      value={asset.isVisible}
                      onChange={async () => {
                        await doToggleVisibleStatus(asset.id)
                      }}
                    />
                  </div>
                  <div css={deleteColStyle}>
                    {hasAssetCustomPlugin && !asset.isFixed && <DeleteIcon onClick={() => addDeleteTarget(asset.id)} />}
                  </div>
                </GuestAppTableRow>
              ))}
            </GuestAppTableBody>
          </>
        </TabContainer>
      </SettingsLayout>

      {showAddAssetModal && <AddAssetModal onClose={() => setShowAddAssetModal(false)} doReload={initialView} setIsLoading={setLoading} />}
    </>
  )
}

const titleColStyle = css({
  paddingLeft: 62,
  flexGrow: 1,
  overflowWrap: 'anywhere',
  width: 'min-content',
})
const formatColStyle = css({
  width: 130,
})
const toggleColStyle = css({
  width: 73,
  margin: '0 16px',
})
const deleteColStyle = css({
  width: 30,
  margin: '0 16px',
})
