import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

export const SigninError: React.FC<{}> = () => {
  const { t } = useTranslation()
  return (
    <div>
      <div css={errorTextStyle}>{t('E-mail or Password is incorrect')}</div>
    </div>
  )
}

const errorTextStyle = css({
  background: 'inherit',
  color: '#ff2700',
  fontSize: 12,
  fontWeight: 'bold',
})
