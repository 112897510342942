import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

import { env } from '@/libs/env'

export const FooterAgreement: React.FC<{}> = () => {
  const { i18n } = useTranslation()
  const lang = i18n.language
  const idAipassUrl = env('ID_AIPASS_URL')

  return (
    <div css={footerAgreementStyle}>
      {lang === 'ja' && (
        <p>
          <a href={`${idAipassUrl}/about-terms-of-use-hotel`} target="_blank" rel="noreferrer noreferrer">
            利用規約
          </a>
          及び
          <a href={`${idAipassUrl}/about-privacy-policy`} target="_blank" rel="noreferrer noreferrer">
            個人情報保護方針
          </a>
          に同意の上、ご利用ください
        </p>
      )}
      {lang === 'en' && (
        <p>
          I agree with the&nbsp;
          <a href={`${idAipassUrl}/about-terms-of-use-hotel`} target="_blank" rel="noreferrer noreferrer">
            Terms of use&nbsp;
          </a>
          and&nbsp;
          <a href={`${idAipassUrl}/about-privacy-policy`} target="_blank" rel="noreferrer noreferrer">
            Privacy policy
          </a>
        </p>
      )}
      {lang === 'zh' && (
        <p>
          请在同意
          <a href={`${idAipassUrl}/about-terms-of-use-hotel`} target="_blank" rel="noreferrer noreferrer">
            使用条款
          </a>
          和隐
          <a href={`${idAipassUrl}/about-privacy-policy`} target="_blank" rel="noreferrer noreferrer">
            隐私政策
          </a>
          后使用
        </p>
      )}
      {lang === 'ko' && (
        <p>
          이용 약관&nbsp;
          <a href={`${idAipassUrl}/about-terms-of-use-hotel`} target="_blank" rel="noreferrer noreferrer">
            이용 약관&nbsp;
          </a>
          및&nbsp;
          <a href={`${idAipassUrl}/about-privacy-policy`} target="_blank" rel="noreferrer noreferrer">
            개인 정보 보호 정책&nbsp;
          </a>
          에 동의 한 후, 이용하십시오
        </p>
      )}
    </div>
  )
}

const footerAgreementStyle = css({
  textAlign: 'center',
  color: '#272727',
  fontSize: 16,
  lineHeight: '21px',
  letterSpacing: '1.8px',
  marginTop: '32px',
  marginBottom: 80,
  a: {
    color: '#f2a40b',
  },
})
