import React, { useContext, useState, useEffect, useRef } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Editor as DrtaftEditor, EditorState, RichUtils, ContentState, convertFromHTML, CompositeDecorator, DraftStyleMap } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { useWindowSize } from 'react-use'

// contexts
import { AccountContext } from '@/contexts/account'

type EditorProps = {
  jpContent: string
  editorState: any
  setEditorState: (editorState: any) => void
  usedIn: 'access' | 'wifi' | 'custom-checkin' | 'others'
}

const styleMap: DraftStyleMap = {
  BOLD: {
    fontWeight: 'bold',
  },
  UNDERLINE: {
    textDecoration: 'underline',
  },
  link: {
    color: '#FF5500',
    textDecoration: 'underline',
  },
}

export const editorOptions = {
  entityStyleFn: entity => {
    const entityType = entity.get('type').toLowerCase()
    if (entityType === 'link') {
      const data = entity.getData()
      return {
        element: 'a',
        attributes: {
          href: data.url,
          target: '_blank',
        },
        style: {},
      }
    }
  },
}

export const Editor: React.FC<EditorProps> = ({ jpContent, editorState, setEditorState, usedIn }) => {
  const { t, i18n } = useTranslation()
  const [isLinkInputOpen, setIsLinkInputOpen] = useState<boolean>(false)
  const [urlValue, setUrlValue] = useState<string>('')
  const editorRef = useRef<any>()

  const Link: any = (props: any) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData()
    return (
      <a href={url} style={styleMap.link}>
        {props.children}
      </a>
    )
  }

  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity()
      return entityKey !== null && contentState?.getEntity(entityKey)?.getType() === 'LINK'
    }, callback)
  }

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ])

  const _convertFromHTML = html => {
    const blocksFromHTML = convertFromHTML(html)
    const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
    return content
  }

  useEffect(() => {
    if (jpContent) {
      setEditorState(EditorState.createWithContent(_convertFromHTML(jpContent), decorator))
    }
  }, [jpContent])

  const onChangeEditor = (newEditorState: any) => {
    setEditorState(newEditorState)
  }

  const isActiveInlineStyle = style => {
    const blockStyle = editorState.getCurrentInlineStyle().has(style)
    return blockStyle ? '_active' : ''
  }

  const isActiveBlockType = block => {
    const blockStyle = RichUtils.getCurrentBlockType(editorState)
    return blockStyle === block ? '_active' : ''
  }

  const onEditorHeaderTow = () => {
    onChangeEditor(RichUtils.toggleBlockType(editorState, 'header-two'))
  }

  const onEditorBold = () => {
    onChangeEditor(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
  }

  const onEditorUnderline = () => {
    onChangeEditor(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
  }

  const onEditorList = () => {
    onChangeEditor(RichUtils.toggleBlockType(editorState, 'unordered-list-item'))
  }

  const onEditorNumberList = () => {
    onChangeEditor(RichUtils.toggleBlockType(editorState, 'ordered-list-item'))
  }

  const onEditorLink = () => {
    const url = urlValue
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })
    onChangeEditor(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey))
    setUrlValue('')
    setIsLinkInputOpen(false)
  }

  const windows = useWindowSize()
  return (
    <>
      <div css={editorToolContainerStyle}>
        <img
          src={require(`@/static/images/textediter_title${i18n.language === 'en' ? '_en' : ''}${isActiveBlockType('header-two')}.svg`)}
          css={textediterToolTitleStyle}
          onClick={() => onEditorHeaderTow()}
        />
        <img
          src={require(`@/static/images/textediter_bold${isActiveInlineStyle('BOLD')}.svg`)}
          css={textediterToolItemStyle}
          onClick={() => onEditorBold()}
        />
        <img
          src={require(`@/static/images/textediter_underline${isActiveInlineStyle('UNDERLINE')}.svg`)}
          css={textediterToolItemStyle}
          onClick={() => onEditorUnderline()}
        />
        <img
          src={require(`@/static/images/textediter_list${isActiveBlockType('unordered-list-item')}.svg`)}
          css={textediterToolItemStyle}
          onClick={() => onEditorList()}
        />
        <img
          src={require(`@/static/images/textediter_numberlist${isActiveBlockType('ordered-list-item')}.svg`)}
          css={textediterToolItemStyle}
          onClick={() => onEditorNumberList()}
        />
        <img
          src={require(`@/static/images/textediter_link.svg`)}
          css={textediterToolItemStyle}
          onClick={() => setIsLinkInputOpen(!isLinkInputOpen)}
        />
        {isLinkInputOpen && (
          <div css={textediterLinkInputContainerStyle}>
            <input type="text" css={textediterLinkInputStyle} value={urlValue} onChange={e => setUrlValue(e.target.value)} />
            <button css={textediterLinkInputAddButtonStyle} onClick={() => onEditorLink()}>
              {t('Add link')}
            </button>
          </div>
        )}
      </div>
      <div
        css={editorStyle}
        style={
          usedIn === 'access'
            ? {
                height: windows.height - 409,
              }
            : usedIn === 'others'
            ? {
                height: windows.height - 327,
              }
            : {}
        }
      >
        <DrtaftEditor
          ref={editorRef}
          editorState={editorState}
          customStyleMap={styleMap}
          onChange={newEditorState => onChangeEditor(newEditorState)}
        />
      </div>
    </>
  )
}

const editorToolContainerStyle = css({
  width: '100%',
  backgroundColor: '#f2f2f2',
  border: '1px solid #cccccc',
  borderBottom: 'none',
  borderRadius: '12px 12px 0 0',
  padding: '10px 14px',
  img: {
    cursor: 'pointer',
  },
})

const editorStyle = css({
  border: '1px solid #cccccc',
  borderRadius: '0 0 12px 12px',
  borderTop: 'none',
  padding: 20,
  fontSize: 14,
  lineHeight: 1.5,
  height: '77%',
  width: '100%',
  overflow: 'scroll',
  position: 'relative',
  overflowX: 'auto',
  overflowY: 'scroll',
  span: {
    fontWeight: 'unset',
  },
  h2: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: '16px 0 8px',
    span: {
      fontWeight: 'bold',
    },
  },
  'ul ,ol': {
    margin: '0 32px',
  },
})

const textediterToolTitleStyle = css({
  width: 57,
  height: 24,
  marginRight: 6,
  ':hover': {
    backgroundColor: '#FFF',
    borderRadius: 12,
  },
})

const textediterToolItemStyle = css({
  width: 24,
  height: 24,
  marginRight: 6,
  position: 'relative',
  ':hover': {
    backgroundColor: '#FFF',
    borderRadius: '50%',
  },
})

const textediterLinkInputContainerStyle = css({
  width: '75%',
  padding: '5px 10px',
  backgroundColor: '#f2f2f2',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: 90,
  left: 18,
  zIndex: 2,
  '&::before': {
    content: "''",
    position: 'absolute',
    top: -25,
    left: '41.2%',
    marginLeft: 4.1,
    border: '10px solid transparent',
    borderBottom: '19px solid #f2f2f2',
  },
})

const textediterLinkInputStyle = css({
  width: 'calc(100% - 80px)',
  borderRadius: 5,
  marginRight: 10,
  border: '1px solid #ccc',
})

const textediterLinkInputAddButtonStyle = css({
  margin: 0,
  border: 'none',
  borderRadius: 10,
  backgroundColor: '#fff',
  cursor: 'pointer',
})
