import React from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

// models
import { MarketSegmentType, GroupType } from '@/models/reservation-market-segment'

type Props = {
  marketSegments: MarketSegmentType[]
}

export const SettingMarketSegmentList: React.FC<Props> = ({ marketSegments }) => {
  const windows = useWindowSize()
  const { t } = useTranslation()

  const _groupTypeText = (groupType: MarketSegmentType['groupType']) => {
    if (groupType === GroupType.Individual) return t('Individual')
    if (groupType === GroupType.Group) return t('Group')

    return ''
  }

  return (
    <>
      <div css={segmentListHeaderStyle}>
        <div css={segmentCodeWidthStyle}>{t('Segment code')}</div>
        <div css={nameWidthStyle}>{t('Name')}</div>
        <div css={groupWidthStyle}>{t('Group')}</div>
      </div>
      <div
        css={scrollStyle}
        style={{
          height: windows.height - 270,
        }}
      >
        <ul
          css={paymentListStyle}
          style={{
            height: windows.height - 270,
          }}
        >
          {marketSegments &&
            marketSegments.map((marketSegment: MarketSegmentType, index) => (
              <li key={index}>
                <div css={segmentCodeWidthStyle}>{marketSegment.code ?? '-'}</div>
                <div css={nameWidthStyle}>{marketSegment.name}</div>
                <div css={groupWidthStyle}>{_groupTypeText(marketSegment.groupType)}</div>
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}

const segmentListHeaderStyle = css({
  background: '#F5F5F5',
  color: '#676767',
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  padding: '10px 40px 10px 28px',
})

const paymentListStyle = css({
  li: {
    display: 'flex',
    padding: '16px 32px',
    color: '#272727',
    fontSize: 14,
    borderBottom: '1px #F2F2F2 solid',
  },
})

const segmentCodeWidthStyle = css({
  width: '15%',
  paddingLeft: 2,
})

const nameWidthStyle = css({
  flex: 1,
  paddingLeft: 2,
})

const groupWidthStyle = css({
  width: '15%',
  paddingLeft: 2,
})

const scrollStyle = css({
  overflowY: 'scroll',
})
