import React, { DragEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Control, Controller, FieldErrors, useFieldArray } from 'react-hook-form'
import { InputFileImage } from '@/components/molecules/settings/input-file-image'
import { AssetSpaceDetailFormValue } from '@/components/organisms/settings/guest-app/space-detail-modal'
import { useDraggableDom } from '../../use-draggable-dom'

type Props = {
  control: Control<AssetSpaceDetailFormValue, any>
  errors: FieldErrors<AssetSpaceDetailFormValue>
  setIsShowCropModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const InputSpaceImage: React.FC<Props> = ({ control, errors, setIsShowCropModal }) => {
  const { t } = useTranslation()
  const imageInput = useFieldArray({ control, name: 'images' })
  const [dragoverItemId, setDragoverItemId] = useState<string>()
  const [draggingItemId, setDraggingItemId] = useState<string>()
  const [isDragToAfter, setIsDragToAfter] = useState<boolean>()
  const changeDisplayOrder = (newList: { path: string; id: string }[]) => {
    imageInput.replace(newList)
  }
  const { dragStart, dragOver, dragDrop, isDropToAfter } = useDraggableDom({
    list: imageInput.fields,
    onChange: changeDisplayOrder,
    direction: 'horizontal',
  })

  const appendDragGhostImageDom = (imagePath: string, e: DragEvent<HTMLDivElement>) => {
    const img = new Image(e.currentTarget.clientWidth, e.currentTarget.clientHeight)
    img.src = imagePath || require('@/static/images/dummy-120x80.png')
    img.id = 'drag-ghost-image'
    img.setAttribute('style', 'position: absolute; top: -1000px; border-radius: 5px')
    document.body.appendChild(img)
    e.dataTransfer.setDragImage(
      img,
      e.clientX - e.currentTarget.getBoundingClientRect().x,
      e.clientY - e.currentTarget.getBoundingClientRect().y,
    )
  }
  const removeDragGhostImageDom = () => {
    const ghostImage = document.getElementById('drag-ghost-image')
    ghostImage?.parentNode?.removeChild(ghostImage)
  }

  return (
    <div css={inputImageStyle}>
      <div css={inputTitleTextStyle}>
        {t('Image')}
        <div css={requireLabelTextStyle}>※</div>
      </div>
      <div className="image-tile">
        {imageInput.fields.map((path, index) => (
          <Controller
            key={path.id}
            name={`images.${index}.path`}
            control={control}
            rules={{ required: t('Please select {{item}}', { item: t('Image') }) }}
            render={({ field: { value }, fieldState: { error } }) => (
              <div style={{ position: 'relative' }}>
                <InputFileImage
                  value={value}
                  onChange={path => imageInput.update(index, { path })}
                  onShowModal={setIsShowCropModal}
                  aspect={16 / 9}
                  error={error?.message}
                  draggable="true"
                  onDragStart={e => {
                    appendDragGhostImageDom(value, e)
                    setDraggingItemId(path.id)
                    dragStart(e)
                  }}
                  onDragEnter={e => setDragoverItemId(path.id)}
                  onDragLeave={e => setDragoverItemId('')}
                  onDragOver={e => {
                    setIsDragToAfter(isDropToAfter(e))
                    dragOver(e)
                  }}
                  onDrop={dragDrop}
                  onDragEnd={() => {
                    setDraggingItemId('')
                    setDragoverItemId('')
                    removeDragGhostImageDom()
                  }}
                  id={path.id}
                  customStyle={css({
                    '.upload-place-picture': {
                      backgroundColor: path.id === draggingItemId ? 'rgba(205,205,205, 0.7) !important' : 'auto',
                    },
                  })}
                />
                {dragoverItemId === path.id && <div className={isDragToAfter ? 'draggable-line -right' : 'draggable-line -left'}></div>}
                <img
                  className="input-remove-icon"
                  src={require('@/static/images/deleat_icon.svg')}
                  onClick={e => {
                    e.stopPropagation()
                    imageInput.remove(index)
                  }}
                ></img>
              </div>
            )}
          />
        ))}
        <div className="add-image-button" onClick={() => imageInput.append({ path: '' })}>
          <div className="add-upload-place"></div>
        </div>
      </div>
      <div className="annotation-text">{t('RecommendedImageSize', { width: 1280, height: 720 })}</div>
      {(!!errors.images?.length || !imageInput.fields.length) && (
        <div className="error-text">{t('Please select {{item}}', { item: t('Image') })}</div>
      )}
    </div>
  )
}

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputImageStyle = css({
  position: 'relative',
  '.image-tile': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
    '.input-remove-icon': {
      position: 'absolute',
      width: 19,
      cursor: 'pointer',
      top: '-5px',
      right: '-5px',
    },
    '.draggable-line': {
      '&.-left': {
        borderLeft: 'solid 2px #f2a40b',
        left: -9,
      },
      '&.-right': {
        borderRight: 'solid 2px #f2a40b',
        right: -9,
      },
      top: -6,
      position: 'absolute',
      height: 'calc(100% + 12px)',
    },
    '.add-image-button': {
      width: 142.2,
      height: 80,
      background: '#F5F5F5 0% 0% no-repeat padding-box',
      border: '1px dashed #CCCCCC',
      borderRadius: 5,
      position: 'relative',
      marginBottom: 8,
      '.add-upload-place': {
        width: 142.2,
        height: 80,
        position: 'absolute',
        borderRadius: 5,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        cursor: 'pointer',
        backgroundImage: `url(${require('@/static/images/plus_yellow.svg')})`,
      },
    },
  },
  '.annotation-text': {
    fontSize: 10,
    color: '#676767',
    marginBottom: 24,
  },
  '.error-text': {
    fontSize: 10,
    color: 'rgba(255, 0, 0, 0.5)',
    position: 'absolute',
    bottom: -12,
  },
})
