import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// apis
import { fetchReservationMarketSegment, updateReservationMarketSegment } from '@/apis/aipass'

import { SettingMarketSegmentEditForm } from '@/components/pages/setting/reservation/market-segment/_edit/edit-form'

// models
import { MarketSegmentType } from '@/models/reservation-market-segment'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { EditFooter } from '@/components/organisms/edit-footer'

type ContainerProps = {}

export const SettingReservationMarketSegmentEdit: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const initialMarketSegment: MarketSegmentType = {
    id: null,
    code: null,
    name: null,
    groupType: null,
  }

  const [editMarketSegments, setEditMarketSegments] = useState<MarketSegmentType[]>([initialMarketSegment])
  const [marketSegments, setMarketSegments] = useState<MarketSegmentType[]>([])
  const [deletedMarketSegments, setDeletedMarketSegments] = useState<MarketSegmentType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onChangeState = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index) => {
    const value = e.target.value
    const name = e.target.name
    const _editMarketSegments = [...editMarketSegments]
    _editMarketSegments[index][name] = value
    setEditMarketSegments(_editMarketSegments)
  }

  // Remove deleted elements from the displayed array
  const deleteMarketSegment = (id, index) => {
    const _editMarketSegments = [...editMarketSegments]
    _editMarketSegments.splice(index, 1)
    setEditMarketSegments(_editMarketSegments)

    // If it is an element already saved in the DB, add it to the delete array
    if (id) {
      const deletedMarketSegment = {
        id,
        code: null,
        name: null,
        groupType: null,
      }
      const _deleteMarketSegments: MarketSegmentType[] = [...deletedMarketSegments, deletedMarketSegment]

      setDeletedMarketSegments(_deleteMarketSegments)
    }
  }

  const addMarketSegment = () => {
    setEditMarketSegments([...editMarketSegments, initialMarketSegment])
  }

  const _errorMessage = (codeError: boolean, nameError: boolean, groupTypeError: boolean): string => {
    let errorMessage = ''
    const codeErrorMessage = t('There is an item with no segment code entered')
    const nameErrorMessage = t('There is an item with no name entered')
    const groupErrorMessage = t('There is an item with no group selected')

    if (codeError) {
      errorMessage = codeErrorMessage
    }
    if (nameError) {
      errorMessage = `${errorMessage}${nameErrorMessage}`
    }
    if (groupTypeError) {
      errorMessage = `${errorMessage}${groupErrorMessage}`
    }

    return errorMessage
  }

  /**
   * Return the element to be saved
   *
   * Compare the data in the edited and original arrays and save only the elements whose contents have been edited.
   * If there is even one element that fails validation, display an alert and return null.
   */
  const _validatedMarketSegments = (): MarketSegmentType[] | null => {
    let validatedMarketSegments: MarketSegmentType[] = []
    let codeError = false
    let nameError = false
    let groupTypeError = false

    // Put newly created/edited elements into storage array
    editMarketSegments.forEach(editSegment => {
      // Go to the next element if nothing is entered
      if (!editSegment.code && !editSegment.name && (editSegment.groupType === null || editSegment.groupType === '-')) return

      // If the validation judgment is caught, go to the next element
      if (!editSegment.code) {
        codeError = true
        return
      }
      if (!editSegment.name) {
        nameError = true
        return
      }
      if (editSegment.groupType === null || editSegment.groupType === '-') {
        groupTypeError = true
        return
      }

      // If there is no id, add it to the save array to create a new one and move to the next element
      if (!editSegment.id) {
        validatedMarketSegments = [...validatedMarketSegments, editSegment]
        return
      }

      /**
       * Determining if content has been edited
       * If the before data cannot be retrieved, the element is anomalous and should not be saved
       */
      const originMarketSegment = marketSegments.find(segment => segment.id === editSegment.id)
      if (!originMarketSegment) return

      // If the content is edited from the data before change, add it to the save array
      if (
        originMarketSegment.code !== editSegment.code ||
        originMarketSegment.name !== editSegment.name ||
        originMarketSegment.groupType !== Number(editSegment.groupType)
      ) {
        validatedMarketSegments = [...validatedMarketSegments, editSegment]
      }
    })

    // Display an alert if validation fails
    if (codeError || nameError || groupTypeError) {
      window.alert(_errorMessage(codeError, nameError, groupTypeError))
      return null
    }

    // Combine and return the deleted elements
    return [...validatedMarketSegments, ...deletedMarketSegments]
  }

  /**
   * preservation processing
   * Compare the edited and original array data and apply update processing only to the elements whose contents have been edited
   */
  const onSaveMarketSegments = () => {
    setIsLoading(true)

    // Extract only newly added/edited/deleted elements
    const validatedMarketSegments = _validatedMarketSegments()

    // If the validation is caught, the save process is not performed
    if (validatedMarketSegments === null) {
      setIsLoading(false)
      return
    }

    updateReservationMarketSegment(validatedMarketSegments)
      .then(() => {
        history.push({ pathname: '/setting/reservation/market-segment', search: location.search })
      })
      .catch(() => {
        console.log(t('Communication failed'))
        setIsLoading(false)
      })
  }

  const _fetchMarketSegment = async () => {
    setIsLoading(true)
    await fetchReservationMarketSegment()
      .then(res => {
        if (res?.marketSegments.length !== 0) {
          setEditMarketSegments(res.marketSegments)
        }
        // Retain market segment (deep copy) before change
        setMarketSegments(res?.marketSegments.map(marketSegment => ({ ...marketSegment })))
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  useEffect(() => {
    _fetchMarketSegment()
  }, [])

  const footerContent = <EditFooter onCancel={history.goBack} onSave={onSaveMarketSegments} />
  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <div css={settingEditContainerStyle}>
        <div css={settingEditHeaderStyle}>
          <p>{t('Segment')}</p>
        </div>
        {editMarketSegments && (
          <SettingMarketSegmentEditForm
            editMarketSegments={editMarketSegments}
            onChangeState={onChangeState}
            deleteMarketSegment={deleteMarketSegment}
            addMarketSegment={addMarketSegment}
          />
        )}
      </div>
    </SettingsLayout>
  )
}

const settingEditContainerStyle = css({
  height: '100%',
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
  background: '#FFF',
  padding: '0 0 23px',
})

const settingEditHeaderStyle = css({
  height: 50,
  padding: '16px 32px',
  borderBottom: '1px solid #F2F2F2',
  p: {
    fontSize: 18,
    fontWeight: 'bold',
  },
})
