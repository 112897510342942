import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button, ButtonType } from '@/components/atoms/button'
import { InputField } from '@/components/molecules/input-field'
import { createSalesSubject, fetchSalesDepartment, updateSalesSubject } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import {
  SalesCategory,
  SalesDepartmentMasterType,
  SalesPriceOutsidePlanSeparate,
  SalesPriceSeparate,
  SalesSubjectType,
} from '@/models/sales-manager/sales'
import { LoaderContextCreator } from '@/contexts/loader'
import { Select, Option } from '@/components/atoms/select'
import { Cascader } from '@/components/atoms/cascader'

type Props = {
  detail: SalesSubjectType | null
  onCancel: () => void
  onSave: () => void
}

export type FormValue = {
  subjectCode: string
  subjectName: string
  departmentId: string
  category: SalesCategory
  price: number
  tax: number
  hasSeparated: SalesPriceSeparate | SalesPriceOutsidePlanSeparate
  planCode: { code: string }[]
}

export const SubjectDetailModal: React.FC<Props> = ({ detail, onCancel, onSave: parentSave }) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const [isInitializing, setIsInitializing] = useState<boolean>(true)
  const [salesDepartments, setSalesDepartments] = useState<SalesDepartmentMasterType[]>([])

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormValue>({
    mode: 'all',
    defaultValues: {
      subjectCode: detail?.subjectCode || '',
      subjectName: detail?.subjectName || '',
      departmentId: detail?.departmentId || undefined,
      category: detail?.category || undefined,
      price: detail ? detail.price?.price || 0 : undefined,
      tax: detail ? detail?.tax || 0 : undefined,
      hasSeparated: detail?.price?.hasSeparated || undefined,
      planCode: detail?.planCode.map(code => ({ code })) || [{ code: '' }],
    },
  })
  const planCodeInput = useFieldArray({
    control,
    name: 'planCode',
    rules: { required: false },
  })

  const onSave = async (value: FormValue) => {
    try {
      setIsLoading(true)
      if (detail) {
        await updateSalesSubject(detail.id, value)
      } else {
        await createSalesSubject(value)
      }
      parentSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const initializeComponent = async (opt?: { noIndicator: boolean }) => {
    const response = await fetchSalesDepartment()
    setSalesDepartments(response ?? [])
    setIsInitializing(false)
  }

  useEffect(() => {
    initializeComponent()
  }, [])

  const valueToCascader = (value: SalesPriceSeparate | SalesPriceOutsidePlanSeparate | undefined) => {
    if (!value) {
      return undefined
    }
    const optionGroupCode = Object.values(SalesPriceSeparate).includes(value as any) ? '1' : '2'
    return [optionGroupCode, value]
  }

  const computedAmountCategoryOptions = useMemo(() => {
    return [
      {
        value: '1',
        label: t('Included in Plan'),
        children: Object.values(SalesPriceSeparate).map(separate => ({
          value: separate,
          label: `${separate} : ${t(`priceCategory.${separate}`)}`,
        })),
      },
      {
        value: '2',
        label: t('Not included in the plan'),
        children: Object.values(SalesPriceOutsidePlanSeparate).map(separate => ({
          value: separate,
          label: `${separate} : ${t(`priceCategory.${separate}`)}`,
        })),
      },
    ]
  }, [])

  return (
    <>
      <Modal customCss={modalStyle}>
        <ModalHeader>{t(detail ? 'Edit subject' : 'Add a subject')}</ModalHeader>
        <ModalBody>
          <div css={modalBodyStyle}>
            <>
              <div css={inputTextStyle}>
                {t('Course ID')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="subjectCode"
                render={({ field: { onChange, value } }) => (
                  <InputField
                    value={value}
                    handleChangeData={onChange}
                    error={errors.subjectCode?.message}
                    placeholder="1010"
                    marginBottom={24}
                  />
                )}
              />
            </>
            <>
              <div css={inputTextStyle}>
                {t('Subject name')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="subjectName"
                render={({ field: { onChange, value } }) => (
                  <InputField
                    value={value}
                    handleChangeData={onChange}
                    error={errors.subjectName?.message}
                    placeholder={t('Subject name')}
                    marginBottom={24}
                  />
                )}
              />
            </>
            <>
              <div css={inputTextStyle}>
                {t('Department name')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="departmentId"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    placeholder={t('Please select')}
                    value={!isInitializing ? value : undefined}
                    onChange={onChange}
                    error={error?.message}
                    showSearch
                    filterOption={true}
                    optionFilterProp="children"
                    customStyle={css({ paddingBottom: 24 })}
                  >
                    {salesDepartments.map(v => (
                      <Option key={v.id} value={v.id}>
                        {v.departmentCode}/{v.departmentName}
                      </Option>
                    ))}
                    {detail && !salesDepartments.find(master => master.id === detail?.departmentId) && (
                      <Option value={detail?.departmentId}>
                        {detail?.departmentCode}/{detail?.departmentName}
                      </Option>
                    )}
                  </Select>
                )}
              />
            </>
            <>
              <div css={inputTextStyle}>
                {t('Sale category')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="category"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    placeholder={t('Please select')}
                    value={!isInitializing ? value : undefined}
                    onChange={onChange}
                    error={error?.message}
                    customStyle={css({ paddingBottom: 24 })}
                  >
                    <Option value={SalesCategory.Customer}>{t('sales_category_customer')}</Option>
                    <Option value={SalesCategory.Temporary}>{t('sales_category_temporary')}</Option>
                    <Option value={SalesCategory.Other}>{t('sales_category_other')}</Option>
                  </Select>
                )}
              />
            </>
            <hr css={hrStyle}></hr>
            <>
              <div css={inputTextStyle}>
                {t('Amount of money')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="price"
                render={({ field: { onChange, value } }) => (
                  <InputField
                    type="number"
                    value={value}
                    handleChangeData={onChange}
                    error={errors.price?.message}
                    placeholder="1000"
                    marginBottom={24}
                  />
                )}
              />
            </>
            <>
              <div css={inputTextStyle}>
                {t('Tax rate')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="tax"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    placeholder={t('Please select')}
                    value={!isInitializing ? value : undefined}
                    onChange={onChange}
                    error={error?.message}
                    customStyle={css({ paddingBottom: 24 })}
                  >
                    <Option value={10}>10</Option>
                    <Option value={8}>8</Option>
                    <Option value={0}>0</Option>
                  </Select>
                )}
              />
            </>
            <hr css={hrStyle}></hr>
            <>
              <div css={inputTextStyle}>
                {t('Amount categories')}
                <div css={requireLabelTextStyle}>※</div>
                <a
                  href="https://aipass.zendesk.com/hc/ja/articles/16513575575705-売上マスタの料金分類について#h.mkhetma54vr6"
                  target="_blank"
                  rel="noreferrer noreferrer"
                >
                  <span css={questionIconStyle}>?</span>
                </a>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="hasSeparated"
                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                  <Cascader
                    options={computedAmountCategoryOptions}
                    error={error?.message}
                    placeholder={t('Please select')}
                    value={valueToCascader(value)}
                    displayRender={label => label[label.length - 1]}
                    onChange={value => {
                      const childrenOptIndex = 1
                      const categoryCode = value[childrenOptIndex]
                      onChange(categoryCode)
                    }}
                    customStyle={css({ paddingBottom: 24 })}
                  />
                )}
              />
            </>
            <>
              <div css={inputTextStyle}>{t('Plan code')}</div>
              {planCodeInput.fields.map((plan, index) => (
                <div key={plan.id} style={{ display: 'flex', gap: 8 }}>
                  <Controller
                    name={`planCode.${index}.code`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputField value={value} handleChangeData={onChange} placeholder={t('Plan code')} />
                    )}
                  />
                  <div>
                    <img
                      style={{ cursor: 'pointer' }}
                      src={require('@/static/images/mobile_request/delete_orange.svg')}
                      alt=""
                      onClick={() => planCodeInput.remove(index)}
                    />
                  </div>
                </div>
              ))}

              <div css={addItemStyle} onClick={() => planCodeInput.append({ code: '' })}>
                <img src={require('@/static/images/plus_yellow.svg')} alt={t('Add icon yellow')} />
                <p>{t('Add a plan code')}</p>
              </div>
            </>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button
            buttonType={isValid ? ButtonType.Primary : ButtonType.PrimaryDisabled}
            height="38px"
            width="110px"
            onClick={handleSubmit(onSave)}
          >
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const questionIconStyle = css({
  cursor: 'pointer',
  marginTop: -3,
  marginLeft: 8,
  width: 16,
  height: 16,
  background: '#F2A40B',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 12,
  fontWeight: 'bold',
  color: '#fff',
})

const modalStyle = {
  minHeight: 534,
  maxHeight: 534,
  width: 600,
  minWidth: 600,
  left: 'calc((100% - 600px) / 2)',
}

const modalBodyStyle = css({
  padding: 32,
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
})

const inputTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const addItemStyle = css({
  marginTop: 16,
  cursor: 'pointer',
  display: 'flex',
  height: 36,
  backgroundColor: '#FAFAFA',
  justifyContent: 'center',
  border: '1px dashed #CCCCCC',
  flex: 1,
  p: {
    lineHeight: '36px',
    color: '#F2A40B',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 10.8,
  },
  img: {
    width: 20,
  },
  ':hover': {
    background: '#F2F2F2',
  },
})

const hrStyle = css({
  borderColor: '#F2F2F2',
  marginTop: 0,
  marginBottom: 24,
})
