import React, { MouseEvent, useContext, useEffect, useRef, useState } from 'react'
import { css } from '@emotion/core'
import { RoomListType, RoomStatusType, RoomType } from '@/models/room-manager/response/guest-room-indicator'
import { IndicatorRoom } from '@/components/molecules/room-manager/indicator-room'
import { HEADER_HEIGHT, SIDE_MENU_WIDTH } from '@/constants/layout'
import { EmptyRoomPopup } from '../room-popup/empty-room-popup'
import { StayPopup } from '../room-popup/stay-popup'
import { CheckInPopup } from '../room-popup/check-in-popup'
import { CheckOutPopup } from '../room-popup/check-out-popup'
import { CleaningPopup } from '../room-popup/cleaning-popup'
import { PopupType } from '../room-popup/popup-type'
import { RoomManagerIndicatorContext, RoomStatusFilter, RoomTypeFilter } from '@/components/pages/room-manager/indicator'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'

type Props = {
  roomStatusFilter: RoomStatusFilter | undefined
  roomTypeFilter: RoomTypeFilter | undefined
  roomEachFloor: RoomListType[]
}

export const DefaultRoomBoxSize = {
  width: 83,
  height: 60,
}

export const AllRoomsOnFloor: React.FC<Props> = ({ roomStatusFilter, roomTypeFilter, roomEachFloor }) => {
  const [selectedRoom, setSelectedRoom] = useState<RoomType>()
  const [popupPosition, setPopupPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 })
  const [popup, setPopup] = useState<PopupType>()
  const roomAreaRef = useRef<HTMLDivElement>(null)
  const { reloadEvent } = useContext(RoomManagerIndicatorContext)
  const { t, i18n } = useTranslation()
  const [boxZoomRate, setBoxZoomRate] = useState<number>(1)
  const [selectRoomRect, setSelectRoomRect] = useState<DOMRect>()
  const numberOfBoxesPerRow = 16

  useEffect(() => {
    initializeComponent()
  }, [reloadEvent])

  useEffect(() => {
    const roomAreaRect = roomAreaRef?.current?.getBoundingClientRect()
    if (!roomAreaRect) {
      return
    }
    const boxSizeRate = roomAreaRect.width / numberOfBoxesPerRow / DefaultRoomBoxSize.width
    setBoxZoomRate(boxSizeRate)
  }, [roomAreaRef])

  const initializeComponent = () => {
    setPopup(undefined)
    setSelectedRoom(undefined)
  }

  const adjustTopNotExceedArea = (top: number, bottom: number): number => {
    const roomAreaRect = roomAreaRef.current?.getBoundingClientRect()
    const areaTop = roomAreaRect?.top || 0
    const areaBottom = (roomAreaRect?.bottom || window.outerHeight) + window.scrollY

    let exceedSize = 0
    if (areaTop > top) {
      exceedSize = top - areaTop
    } else if (areaBottom < bottom) {
      exceedSize = bottom - areaBottom
    }
    return top - exceedSize
  }

  const onSelectRoom = (room: RoomType | undefined, e?: MouseEvent<HTMLDivElement>) => {
    setSelectedRoom(room)

    if (!e || !room) {
      setPopup(undefined)
      return
    }

    const popup = computedPopupComponent(room)
    setPopup(popup)
    calcPopupPosition(popup, e)
  }

  const calcPopupPosition = (popup: PopupType, e: MouseEvent): void => {
    const paddingSpace = 8

    const selectRoomRect = e.currentTarget.getBoundingClientRect()
    const zoomedSelectRoomRect = {
      top: selectRoomRect.top * boxZoomRate,
      height: selectRoomRect.height * boxZoomRate,
      right: selectRoomRect.right * boxZoomRate,
      left: selectRoomRect.left * boxZoomRate,
    }

    const computedPopupTop = zoomedSelectRoomRect.top - (popup.height - zoomedSelectRoomRect.height) / 2 + window.scrollY

    const isLeftHalfOfWindow = window.outerWidth / 2 > e.clientX
    const popupPosition = {
      top: adjustTopNotExceedArea(computedPopupTop, computedPopupTop + popup.height) - HEADER_HEIGHT,
      left: 0,
    }
    if (isLeftHalfOfWindow) {
      popupPosition.left = zoomedSelectRoomRect.right - SIDE_MENU_WIDTH + paddingSpace
    } else {
      popupPosition.left = zoomedSelectRoomRect.left - SIDE_MENU_WIDTH - popup.width - paddingSpace - popup.width
    }

    setSelectRoomRect(selectRoomRect)
    setPopupPosition({
      top: popupPosition.top,
      left: popupPosition.left,
    })
  }

  const computedPopupComponent = (room: RoomType): PopupType => {
    switch (room.roomStatus) {
      case RoomStatusType.empty:
        return new EmptyRoomPopup()
      case RoomStatusType.checkIn:
        return new CheckInPopup(room)
      case RoomStatusType.stay:
        return new StayPopup(room)
      case RoomStatusType.checkOut:
        return new CheckOutPopup(room)
      case RoomStatusType.cleaning:
        return new CleaningPopup(room)
    }
  }

  const computedDisable = (room: RoomType): boolean => {
    const isNotSelectedRoomType = !!(roomTypeFilter?.length && !roomTypeFilter.includes(room.roomTypeId))
    const isNotSelectedRoomStatus = !!(roomStatusFilter?.length && !roomStatusFilter.includes(room.roomStatus))
    return isNotSelectedRoomType || isNotSelectedRoomStatus
  }

  const isFiltering = (): boolean => {
    return !!(roomTypeFilter?.length || roomStatusFilter?.length)
  }

  const filledBlankSpace = (roomEachFloor: RoomListType[]) => {
    const roomAreaRect = roomAreaRef.current?.getBoundingClientRect()
    if (!roomAreaRect) {
      return roomEachFloor
    }
    const maxFloorCount = Math.floor(roomAreaRect.height / (DefaultRoomBoxSize.height * boxZoomRate))
    const maxRowRoomCount = numberOfBoxesPerRow - 1 // -1 = floor box num

    let roomEachFloorCount = roomEachFloor.length
    const filledFloor = roomEachFloor.map(floor => {
      // Number of turns added to total number of floors
      roomEachFloorCount += Math.ceil(floor.rooms.length / maxRowRoomCount) - 1
      const blankNum = floor.rooms.length % maxRowRoomCount
      if (blankNum === 0) {
        return floor
      }
      return {
        ...floor,
        rooms: [...floor.rooms, ...[...Array(maxRowRoomCount - blankNum)].map(() => undefined)],
      }
    })

    const filledFloorCount = maxFloorCount - roomEachFloorCount
    const blankFloor =
      filledFloorCount > 0
        ? [...Array(filledFloorCount)].map(
            (): RoomListType => ({
              floorId: uuid(),
              floorName: '',
              floorNameEn: '',
              rooms: [...Array(maxRowRoomCount)].map(() => undefined),
            }),
          )
        : []
    return [...filledFloor, ...blankFloor]
  }

  useEffect(() => {
    if (popup?.getHeight && selectRoomRect) {
      const popupHeight = popup.getHeight()

      const computedPopupTop = selectRoomRect.top - (popupHeight - selectRoomRect.height) / 2 + window.scrollY

      const topPosition = adjustTopNotExceedArea(computedPopupTop, computedPopupTop + popupHeight) - HEADER_HEIGHT

      setPopupPosition({ top: topPosition, left: popupPosition.left })
    }
  }, [popup, selectRoomRect])

  return (
    <div ref={roomAreaRef} style={{ height: '100%' }}>
      {filledBlankSpace(roomEachFloor).map(roomList => (
        <div key={roomList.floorId} css={css({ display: 'flex' })}>
          <div
            css={[
              baseBoxStyle,
              floorStyle,
              {
                zoom: boxZoomRate,
              },
            ]}
          >
            <p>{i18n.language === 'ja' ? roomList.floorName : roomList.floorNameEn}</p>
          </div>
          <div css={roomRowStyle}>
            {roomList.rooms.map(room =>
              room ? (
                <IndicatorRoom
                  key={room.roomId}
                  room={room}
                  isSelected={selectedRoom?.roomId === room.roomId}
                  isDisable={computedDisable(room)}
                  onClick={e => onSelectRoom(room, e)}
                  boxZoomRate={boxZoomRate}
                />
              ) : (
                <div
                  key={uuid()}
                  css={[
                    baseBoxStyle,
                    {
                      zoom: boxZoomRate,
                    },
                  ]}
                >
                  <div css={roomStyle}></div>
                  {isFiltering() && <div css={disableStyle} />}
                </div>
              ),
            )}
          </div>
        </div>
      ))}
      {popup && selectedRoom && (
        <>
          <popup.Component position={popupPosition} room={selectedRoom} t={t} />
          <div css={popupBackgroundStyle} onClick={() => onSelectRoom(undefined)}></div>
        </>
      )}
    </div>
  )
}

const baseBoxStyle = css({
  margin: 1,
  position: 'relative',
  minWidth: DefaultRoomBoxSize.width - 2,
  width: DefaultRoomBoxSize.width - 2,
  minHeight: DefaultRoomBoxSize.height - 2,
  height: DefaultRoomBoxSize.height - 2,
})

const roomRowStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
})

const floorStyle = css({
  backgroundColor: '#F5F5F5',
  padding: '0 14px',
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexFlow: 'column',
  p: {
    color: '#272727',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.6,
    lineHeight: 1.5,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    overflow: 'hidden',
  },
})

const popupBackgroundStyle = css({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
})
const roomStyle = css({
  height: '100%',
  width: '100%',
  backgroundColor: '#F5F5F5',
  position: 'absolute',
})

const disableStyle = css({
  width: 'calc(100% + 2px)',
  height: 'calc(100% + 2px)',
  background: '#000000',
  opacity: 0.3,
  position: 'absolute',
})
