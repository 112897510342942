import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/button'

type AddRoomButtonProps = {
  onFieldPush: () => void
  updateGuestRoom: () => Promise<void>
  onClickCancel: () => void
  isEdit: boolean
}

export const AddRoomButton: React.FC<AddRoomButtonProps> = ({
  onFieldPush,
  updateGuestRoom,
  onClickCancel,
  isEdit,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div css={addRoomListWrapperOpenStyle} onClick={onFieldPush}>
        <img src={require('@/static/images/plus.svg')} css={addIconStyle} alt={t('Add icon yellow')} />
        <p>{t('Add room')}</p>
      </div>
      {isEdit && (
        <div css={saveButtonHasRoomManagerPluginStyle}>
          <Button
            buttonType={3}
            width={100}
            height={32}
            fontSize={12}
            marginRight={16}
            fontWeight={'bold'}
            onClick={() => onClickCancel()}>
            {t('Cancel')}
          </Button>
          <Button width={58} height={32} buttonType={1} fontSize={12} onClick={() => updateGuestRoom()}>
            {t('Save')}
          </Button>
        </div>
      )}
    </>
  )
}

const addRoomListWrapperStyle = css({
  cursor: 'pointer',
  display: 'flex',
  height: 36,
  backgroundColor: '#FAFAFA',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px dashed #CCCCCC',
  margin: '24px 32px',
  p: {
    lineHeight: '36px',
    color: '#F2A40B',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
})

const addRoomListWrapperOpenStyle = css(addRoomListWrapperStyle, {
  marginTop: 24,
})

const addIconStyle = css({
  width: 13,
  height: 13,
})

const saveButtonHasRoomManagerPluginStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px 24px',
  borderTop: '1px solid #f2f2f2',
})
