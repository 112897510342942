import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// apis
import { fetchSalesDiscount, fetchSalesAccountsReceivable, updateSalesDiscount } from '@/apis/aipass'

// components
import { SettingDiscountEditForm } from '@/components/organisms/settings/sales-manager/discount/edit-form'

// models
import { DiscountSettingType, DiscountType } from '@/models/sales'
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { EditFooter } from '@/components/organisms/edit-footer'

type ContainerProps = {}

export const SettingSalesManagerDiscountEdit: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const initialDiscountSettings: DiscountSettingType[] = [
    {
      discountSubjectCode: '',
      name: '',
      price: '',
      type: DiscountType.Custom,
      isAccountsReceivable: 0,
      accountsReceivableCode: '',
      accountsReceivableName: '',
      tax: '',
      taxClass: '',
    },
  ]
  const initialAccountsReceivableSettings: AccountsReceivableSettingType[] = [
    {
      closingDate: 0,
      channelCode: '',
      channelName: '',
      code: '',
      id: '',
      name: '',
    },
  ]
  const [accountsReceivableSettings, setAccountsReceivableSettings] =
    useState<AccountsReceivableSettingType[]>(initialAccountsReceivableSettings)
  const [discountSettings, setDiscountSettings] = useState<DiscountSettingType[]>(initialDiscountSettings)
  const [discountList, setDiscountList] = useState<any>()
  const [deteledDiscountSettings, setDeteledDiscountSettings] = useState<{ id: string }[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()
  const location = useLocation()

  const onChangeState = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index) => {
    const value = e.target.value
    const name = e.target.name
    const newDiscountSettings = [...discountSettings]
    newDiscountSettings[index][name] = value
    setDiscountSettings(newDiscountSettings)
  }

  const deleteDiscountSetting = (id, index) => {
    // Remove deleted elements from the displayed array
    const newDiscountSettings = [...discountSettings]
    newDiscountSettings.splice(index, 1)
    if (id) {
      const newDeteledDiscountSettings: { id: string }[] = [...deteledDiscountSettings, { id: id }]
      setDeteledDiscountSettings(newDeteledDiscountSettings)
    }
    setDiscountSettings(newDiscountSettings)
  }

  const addDiscountSetting = () => {
    const newDiscountSetting: DiscountSettingType = {
      discountSubjectCode: '',
      name: '',
      price: '',
      type: DiscountType.Custom,
      isAccountsReceivable: 0,
      accountsReceivableCode: '',
      accountsReceivableName: '',
      tax: '',
      taxClass: '',
    }
    setDiscountSettings([...discountSettings, newDiscountSetting])
  }

  const onSaveDiscountSettings = () => {
    setIsLoading(true)
    // Get accountsReceivableCode from accountsReceivableSettings that matches accountsReceivableName in discountSettings
    const newDiscountSettings = discountSettings.map(discountSetting => {
      accountsReceivableSettings.forEach(accountsReceivableSetting => {
        if (accountsReceivableSetting.name === discountSetting.accountsReceivableName) {
          discountSetting.accountsReceivableCode = accountsReceivableSetting.code
        }
      })
      const newDiscountSetting = { ...discountSetting }
      return newDiscountSetting
    })
    let fixedDiscountSettings = JSON.parse(JSON.stringify(newDiscountSettings))
    // Compare with data before change and delete from fixed array if the contents are the same
    discountList.forEach(discount => {
      const fixedDiscountIndex = fixedDiscountSettings.findIndex(item => item?.id === discount.id)
      if (fixedDiscountIndex === -1) return
      const fixedDiscount = fixedDiscountSettings[fixedDiscountIndex]
      if (
        discount.discountSubjectCode === fixedDiscount.discountSubjectCode &&
        discount.name === fixedDiscount.name &&
        discount.price === fixedDiscount.price &&
        discount.isAccountsReceivable === fixedDiscount.isAccountsReceivable &&
        discount.accountsReceivableCode === fixedDiscount.accountsReceivableCode &&
        discount.accountsReceivableName === fixedDiscount.accountsReceivableName &&
        discount.tax === fixedDiscount.tax &&
        discount.taxClass === fixedDiscount.taxClass
      ) {
        fixedDiscountSettings.splice(fixedDiscountIndex, 1)
      }
    })
    fixedDiscountSettings = fixedDiscountSettings.map(fixeDiscountSetting => {
      fixeDiscountSetting.price = Number(fixeDiscountSetting.price)
      fixeDiscountSetting.tax = Number(fixeDiscountSetting.tax)
      fixeDiscountSetting.isAccountsReceivable = Number(fixeDiscountSetting.isAccountsReceivable)
      if (fixeDiscountSetting.accountsReceivableName === '') {
        fixeDiscountSetting.accountsReceivableCode = ''
      }
      return fixeDiscountSetting
    })
    // Join id of deleted data
    fixedDiscountSettings = [...fixedDiscountSettings, ...deteledDiscountSettings]
    updateSalesDiscount(fixedDiscountSettings)
      .then(() => {
        history.push({ pathname: '/setting/sales-manager/discount', search: location.search })
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const _fetchSalesDiscount = async () => {
    setIsLoading(true)
    await fetchSalesDiscount()
      .then(res => {
        setDiscountSettings(res?.salesDiscount)
        // Discount setting list before change
        setDiscountList(JSON.parse(JSON.stringify(res?.salesDiscount)))
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  const _fetchSalesAccountsReceivable = async () => {
    setIsLoading(true)
    await fetchSalesAccountsReceivable()
      .then(res => {
        setAccountsReceivableSettings(res?.accountsReceivables)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  useEffect(() => {
    _fetchSalesDiscount()
    _fetchSalesAccountsReceivable()
  }, [])

  const footerContent = <EditFooter onSave={onSaveDiscountSettings} onCancel={history.goBack} />

  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <>
        <div css={settingDiscountEditContainerStyle}>
          <div css={settingDiscountEditHeaderStyle}>
            <p>{t('Discount')}</p>
          </div>
          {discountSettings && (
            <SettingDiscountEditForm
              discountSettings={discountSettings}
              onChangeState={onChangeState}
              deleteDiscountSetting={deleteDiscountSetting}
              addDiscountSetting={addDiscountSetting}
              accountsReceivableSettings={accountsReceivableSettings}
            />
          )}
        </div>
      </>
    </SettingsLayout>
  )
}

const settingDiscountEditContainerStyle = css({
  height: '100%',
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
  background: '#FFF',
  padding: '0 0 23px',
})

const settingDiscountEditHeaderStyle = css({
  height: 50,
  padding: '16px 32px',
  borderBottom: '1px solid #F2F2F2',
  p: {
    fontSize: 18,
    fontWeight: 'bold',
  },
})
