import React, { useState } from 'react'
import dayjs from 'dayjs'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// models
import { ReservationType } from '@/models/reservation'
import { dividedRoomNumber } from '@/libs/divide-list'
import { ApprovedStatusType } from '@/models/reservation-approved-status'

import i18n from '@/i18n'

type Props = {
  reservations: ReservationType[]
  changeApprovedStatus: (reservationIds, approvedStatus) => void
  bulkChangeApprovedStatus: (approvedStatus) => void
  onClickDeleteReservationCheckin: (checkinId, reservationId) => void
  checkinReception?: () => void
}

export const approvedStatus = [
  {
    value: ApprovedStatusType.Checkin,
    label: i18n.t('Checkin'),
  },
  {
    value: ApprovedStatusType.Stay,
    label: i18n.t('During your stay'),
  },
  {
    value: ApprovedStatusType.Checkout,
    label: i18n.t('Checkout'),
  },
]

export const ReservationList: React.FC<Props> = ({
  reservations,
  changeApprovedStatus,
  bulkChangeApprovedStatus,
  onClickDeleteReservationCheckin,
  checkinReception,
}) => {
  const { t } = useTranslation()
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)
  const [isSingleDropDownOpen, setIsSingleDropDownOpen] = useState<boolean>(false)
  const [isSingleDropDownOpenIndex, setIsSingleDropDownOpenIndex] = useState<number>(0)

  return (
    <>
      <div css={reservationListContainer}>
        <div css={reservationListSubject}>
          <div css={reservationListTitleStyle}>{t('Reservation list')}</div>
          <div css={reservationButtonStyle} onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
            {t('Bulk change')}
            <img css={arrowStyle} src={require('@/static/images/arrow_yellow.svg')} alt="" />
            {isDropDownOpen && (
              <div css={dropDownStyle}>
                <ul css={dropDownBodyStyle}>
                  <li css={dropDownContentStyle} onClick={() => bulkChangeApprovedStatus(ApprovedStatusType.Checkin)}>
                    <p css={dropDownTitleStyle}>{t('Checkin')}</p>
                  </li>
                  <li css={dropDownContentStyle} onClick={() => bulkChangeApprovedStatus(ApprovedStatusType.Stay)}>
                    <p css={dropDownTitleStyle}>{t('During your stay')}</p>
                  </li>
                  <li css={dropDownContentStyle} onClick={() => bulkChangeApprovedStatus(ApprovedStatusType.Checkout)}>
                    <p css={dropDownTitleStyle}>{t('Checkout')}</p>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div css={listTitle}>
          <div css={reservationIdStyle}>{t('Accommodation ID')}</div>
          <div css={reservationDateStyle}>{t('Date of stay')}</div>
          <div css={reservationNightStyle}>{t('Number of nights')}</div>
          <div css={reservationUserStyle}>{t('Guest')}</div>
          <div css={roomNumberStyle}>{t('Room number')}</div>
          <div css={{ width: '10%' }}>{t('Amount to be collected')}</div>
          <div css={reservationStatusStyle}>{t('Status')}</div>
        </div>
        <ul>
          {!reservations.length && (
            <li css={listStyle}>
              <div css={reservationIdStyle}>-</div>
              <div css={reservationDateStyle}>-</div>
              <div css={reservationNightStyle}>0{t('Night')}</div>
              <div css={reservationUserStyle}>-</div>
              <div css={roomNumberStyle}>-</div>
              <div css={{ width: '10%' }}>¥0</div>
              <div css={approvedStatusCheckinReceptionStyle} onClick={checkinReception}>
                {t('Check-in reception')}
              </div>
            </li>
          )}
          {reservations &&
            reservations.map((reservation, key) => (
              <li key={reservation.accommodationId} css={listStyle}>
                <div css={reservationIdStyle}>{reservation.accommodationId}</div>
                <div css={reservationDateStyle}>
                  {`${reservation.checkinDate ? dayjs(reservation.checkinDate).format(t('MM-DD')) : '-'}
                  ~
                  ${reservation.checkoutDate ? dayjs(reservation.checkoutDate).format(t('MM-DD')) : '-'}`}
                </div>
                <div css={reservationNightStyle}>
                  {reservation?.checkinDate && reservation?.checkoutDate
                    ? dayjs(reservation?.checkoutDate).diff(dayjs(reservation?.checkinDate), 'day')
                    : 0}
                  {t('Night')}
                </div>
                <div css={reservationUserStyle}>{reservation.guestName}</div>
                <div css={roomNumberStyle}>{dividedRoomNumber(reservation.assignedRoom)}</div>
                <div css={{ width: '10%', color: reservation?.unpaid ? '#F2A40B' : '' }}>
                  ¥{Number(reservation?.unpaid || 0).toLocaleString()}
                </div>
                <div
                  css={
                    (reservation.approvedStatus === ApprovedStatusType.Checkin && approvedStatusCheckinStyle) ||
                    (reservation.approvedStatus === ApprovedStatusType.Stay && approvedStatusStayStyle) ||
                    (reservation.approvedStatus === ApprovedStatusType.Checkout && approvedStatusCheckoutStyle)
                  }
                  onClick={() => {
                    setIsSingleDropDownOpen(!isSingleDropDownOpen)
                    setIsSingleDropDownOpenIndex(key)
                  }}
                >
                  {reservation.approvedStatus === ApprovedStatusType.Checkin && <>{t('Checkin')}</>}
                  {reservation.approvedStatus === ApprovedStatusType.Stay && <>{t('During your stay')}</>}
                  {reservation.approvedStatus === ApprovedStatusType.Checkout && <>{t('Checkout')}</>}
                  <img css={arrowSelectboxStyle} src={require('@/static/images/select_white.svg')} alt="" />
                  {isSingleDropDownOpen && isSingleDropDownOpenIndex === key && (
                    <div css={dropDownStyle}>
                      <ul css={dropDownBodyStyle}>
                        {reservation.approvedStatus !== ApprovedStatusType.Checkin && (
                          <li
                            css={dropDownContentStyle}
                            onClick={() => changeApprovedStatus(reservation.reservationId, ApprovedStatusType.Checkin)}
                          >
                            <p css={dropDownTitleStyle}>{t('Checkin')}</p>
                          </li>
                        )}
                        {reservation.approvedStatus !== ApprovedStatusType.Stay && (
                          <li
                            css={dropDownContentStyle}
                            onClick={() => changeApprovedStatus(reservation.reservationId, ApprovedStatusType.Stay)}
                          >
                            <p css={dropDownTitleStyle}>{t('During your stay')}</p>
                          </li>
                        )}
                        {reservation.approvedStatus !== ApprovedStatusType.Checkout && (
                          <li
                            css={dropDownContentStyle}
                            onClick={() => changeApprovedStatus(reservation.reservationId, ApprovedStatusType.Checkout)}
                          >
                            <p css={dropDownTitleStyle}>{t('Checkout')}</p>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <img
                  src={require('@/static/images/deleat.svg')}
                  css={deleteStyle}
                  width="24"
                  height="24"
                  onClick={() => onClickDeleteReservationCheckin(reservation.checkinId, reservation.reservationId)}
                ></img>
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}

const reservationListContainer = css({
  backgroundColor: '#fff',
  border: '1px solid #CCCCCC',
  borderRadius: '10px',
})

const listTitle = css({
  display: 'flex',
  backgroundColor: '#f5f5f5',
  color: '#676767',
  fontWeight: 'bold',
  width: '100%',
  padding: '10px 40px',
  fontSize: 12,
})

const listStyle = css(listTitle, {
  backgroundColor: '#fff',
  color: '#272727',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '24px',
  borderRadius: '0px 0px 10px 10px',
})

const reservationListSubject = css({
  fontSize: 14,
  fontWeight: 'bold',
  padding: '13px 34px 8px 33px',
  display: 'flex',
  justifyContent: 'space-between',
})

const reservationListTitleStyle = css({
  margin: 'auto 0px',
})
const reservationIdStyle = css({
  width: '10%',
})

const reservationDateStyle = css({
  width: '17%',
})

const reservationNightStyle = css({
  width: '8%',
})

const reservationUserStyle = css({
  width: '20%',
})

const roomNumberStyle = css({
  width: '20%',
})

const reservationStatusStyle = css({
  width: '12%',
})

const reservationStatusSelectboxStyle = css({
  width: 116,
  fontSize: 12,
  fontWeight: 'bold',
  textAlign: 'center',
  letterSpacing: 0,
  borderRadius: 5,
  color: '#fff',
  border: '1px solid #fff',
  position: 'relative',
  paddingRight: 6,
  cursor: 'pointer',
})

const deleteStyle = css({
  width: '5%',
})

const arrowSelectboxStyle = css({
  margin: '0 3px',
  position: 'absolute',
  padding: '9px 6px 0px 0px',
  right: 0,
})

const arrowStyle = css({
  position: 'absolute',
  right: 0,
  padding: '4px 8px',
})

const reservationButtonStyle = css({
  width: 116,
  border: '1px solid #E7A73C',
  borderRadius: 5,
  color: '#E7A73C',
  fontSize: 12,
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '6px 0',
  position: 'relative',
  paddingRight: 10,
})

const approvedStatusCheckinReceptionStyle = css(reservationStatusSelectboxStyle, {
  backgroundColor: '#F2A40B',
})
const approvedStatusCheckinStyle = css(reservationStatusSelectboxStyle, {
  backgroundColor: '#F47110',
})
const approvedStatusStayStyle = css(reservationStatusSelectboxStyle, {
  backgroundColor: '#3E85CC',
})
const approvedStatusCheckoutStyle = css(reservationStatusSelectboxStyle, {
  backgroundColor: '#7DC931',
})

const dropDownStyle = css({
  width: 140,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 30,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownBodyStyle = css({})

const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  fontSize: 14,
  fontWeight: 'normal',
  color: '#272727',
})
