import React, { useMemo } from 'react'
import { SerializedStyles, css } from '@emotion/core'
import { Select as AntSelect } from 'antd'
import { OptionProps, SelectProps } from 'antd/lib/select'

type Props = {
  error?: string
  customStyle?: SerializedStyles
  placeHolderColor?: string
}

export const Select: React.FC<Props & SelectProps> = ({ error, customStyle, placeHolderColor, children, ...props }) => {
  const selectBoxStyle = useMemo(
    () =>
      css({
        width: '100%',
        '> div': {
          borderRadius: '16px',
          boxShadow: 'none !important',
          borderColor: error ? 'red !important' : '#CCCCCC !important',
        },
        '.ant-select-selection__rendered': {
          paddingLeft: '5px !important',
        },
        '.ant-select-selection__placeholder': {
          color: placeHolderColor || '#272727',
          paddingLeft: 5,
        },
        '.ant-select-arrow': {
          right: 16,
        },
      }),
    [error],
  )

  return (
    <div
      css={css(
        {
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          paddingBottom: 16,
        },
        customStyle,
      )}
    >
      <AntSelect css={selectBoxStyle} suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />} {...props}>
        {children}
      </AntSelect>
      {!!error && <div css={{ fontSize: 10, color: 'rgba(255,0,0,0.5)', position: 'absolute', top: 36 }}>{error}</div>}
    </div>
  )
}

export const Option: React.FC<OptionProps> = ({ children, ...props }) => {
  return <AntSelect.Option {...props}>{children}</AntSelect.Option>
}
