import React from 'react'
import { css } from '@emotion/core'

type MenuItemProps = {
  onClick: () => void
  title: string
  imgSrc: string
  imgAlt: string
}

const MenuItem = ({ onClick, title, imgSrc, imgAlt }: MenuItemProps) => {
  return (
    <div css={mobileMenuItemStyle} onClick={onClick}>
      <img css={mobileMenuIconStyle} src={imgSrc} alt={imgAlt} />
      <span css={mobileMenuTitleStyle}>{title}</span>
    </div>
  )
}

const mobileMenuItemStyle = css({ height: '50px', marginTop: '8px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center' })
const mobileMenuIconStyle = css({ height: '20px', width: '20px', margin: '0 24px 0 32px' })
const mobileMenuTitleStyle = css({
  fontFamily: 'Noto Sans JP',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '19px',
  letterSpacing: '2.1px',
})

export default MenuItem
