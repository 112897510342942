import { useFileUpload } from '@/hooks/use-file-upload'
import React, { useRef } from 'react'

type Pros = {
  onUpload: (path: string) => void
  multiple?: boolean
}

export const AddImageButton: React.FC<Pros> = ({ onUpload, multiple }) => {
  const inputFileRef = useRef<HTMLInputElement>(null)
  const { inputFileHandler } = useFileUpload()

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }
    if (!multiple) {
      inputFileHandler(e, ['image/jpeg', 'image/png'], f => {
        onUpload(f.uploadedPath)
      })
      return
    }

    const fileList = e.target.files
    await Promise.all(
      Array.from(fileList).map(file => {
        const oneFileEvent: any = { target: { files: [file] } }
        inputFileHandler(oneFileEvent, ['image/jpeg', 'image/png'], f => {
          onUpload(f.uploadedPath)
          Promise.resolve()
        })
      }),
    )
  }

  return (
    <div
      className="add-image-button"
      onClick={() => {
        if (inputFileRef.current) {
          inputFileRef.current.value = ''
          inputFileRef.current.click()
        }
      }}
    >
      <div className="add-upload-place"></div>
      <input type="file" multiple={!!multiple} ref={inputFileRef} onChange={e => handleChangeFile(e)} style={{ display: 'none' }} />
    </div>
  )
}
