import React, { useState } from 'react'
import { css } from '@emotion/core'
import { Button } from '@/components/atoms/button'
import { useTranslation } from 'react-i18next'

export const useAssignConfirmDialog = () => {
  const { t } = useTranslation()
  const [isShowDialog, setIsShowDialog] = useState(false)
  const [onClickButton, setOnClickButton] = useState<(isConfirmed: boolean) => void>()

  const openConfirmDialog = (): Promise<boolean> => {
    setIsShowDialog(true)
    return new Promise(resolve => setOnClickButton(() => resolve))
  }

  const onClose = (isConfirmed: boolean) => {
    setIsShowDialog(false)
    if (onClickButton) {
      onClickButton(isConfirmed)
    }
  }

  const AssignConfirmDialog: React.FC = () =>
    isShowDialog ? (
      <>
        <div css={modalBackgroundStyle}> </div>
        <div css={modalStyle}>
          <div className="text-area">
            <p>{t('Remaining inventory is not enough')}</p>
            <p>{t('Would you like to make additional assignments')}</p>
          </div>
          <div className="button-area">
            <Button width={110} height={38} buttonType={3} fontSize={12} onClick={() => onClose(false)}>
              {t('Cancel')}
            </Button>
            <Button width={110} height={38} buttonType={1} fontSize={12} onClick={() => onClose(true)}>
              {t('Assignment')}
            </Button>
          </div>
        </div>
      </>
    ) : (
      <></>
    )
  return {
    openConfirmDialog,
    AssignConfirmDialog,
  }
}

const modalStyle = css({
  height: 187,
  width: 400,
  position: 'absolute',
  background: '#FFFFFF',
  top: 'calc(-50vh + 100% - 187px / 2)',
  left: 'calc(-50vw + 100% - 400px / 2)',
  padding: '0 78px',
  borderRadius: 5,
  '.text-area': {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    letterSpacing: 0.9,
    paddingTop: 40,
    lineHeight: '27px',
  },
  '.button-area': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 24,
  },
})

const modalBackgroundStyle = css({
  background: '#272727',
  width: '100vw',
  height: '100vh',
  position: 'absolute',
  top: 'calc(-100vh + 100%)',
  left: 'calc(-100vw + 100%)',
  opacity: 0.5,
})
