import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonType } from '@/components/atoms/button'
import { CSVReader } from '../csv-reader'
import * as api from '@/apis/aipass'
import { GuestRoomAssignResponseType } from '@/models/guest-room/guest-room-assign'
import { LoaderContextCreator } from '@/contexts/loader'
import { useErrorHandler } from '@/hooks/use-error-handler'

type Props = {
  reservationRooms: GuestRoomAssignResponseType['rooms']
  onReload: () => void
}

export const RegistrationGuestNameButton: React.FC<Props> = ({ reservationRooms, onReload }) => {
  const parseOptions = {
    header: true,
    skipEmptyLines: true,
  }
  const roomNumberColumnName = '部屋番号'
  const roomGuestColumnName = '部屋代表者'
  const { setIsLoading } = useContext(LoaderContextCreator())
  const { errorHandler } = useErrorHandler()
  const { t } = useTranslation()

  const findByRoomNumber = (roomNumber: string) => {
    return reservationRooms.find(room => room.roomNumber === roomNumber)
  }

  const importGuestRowData = async rows => {
    const ignoreRows: string[] = []
    const changedGuestNameRooms = rows
      .map(row => ({
        roomNumber: row[roomNumberColumnName],
        guestName: row[roomGuestColumnName],
      }))
      .filter(({ roomNumber, guestName }, rowNum) => {
        if (!roomNumber || !guestName) {
          return false
        }
        if (!findByRoomNumber(roomNumber)) {
          ignoreRows.push(`${rowNum + 1}行目:「${roomNumber}」はアサインされていません`)
          return false
        }
        return true
      })
      .map(({ roomNumber, guestName }) => {
        const room = findByRoomNumber(roomNumber)
        room!.assignGuestName = guestName
        return room
      })
    try {
      setIsLoading(true)
      await api.updateGuestRoomAssign(changedGuestNameRooms as GuestRoomAssignResponseType['rooms'])
      if (ignoreRows.length) {
        alert(['取り込めなかった行があります', ...ignoreRows].join('\n'))
      }
      onReload()
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button buttonType={ButtonType.NonStyle} width={109} height={32} fontSize={12} onClick={() => {}}>
        <CSVReader
          inputStyle={{ display: 'none', cursor: 'pointer' }}
          label={t('Batch registration of room representatives')}
          parserOptions={parseOptions}
          cssInputClass={'dropDownTitleStyle'}
          onFileLoaded={async data => {
            if (!data[0][roomNumberColumnName] || !data[0][roomGuestColumnName]) {
              alert(`列「${roomNumberColumnName}」「${roomGuestColumnName}」を含むCSVファイルをアップロードしてください`)
              return
            }
            importGuestRowData(data)
          }}
        />
      </Button>
    </>
  )
}
