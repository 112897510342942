import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { css } from '@emotion/core'
import { isEqual } from 'lodash'

import { Button } from '@/components/atoms/button'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { DashboardPageLayout } from '@/components/layouts/dashboard-page-layout'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { dashboardPageContainerStyle, tableHeaderThStyle } from '@/constants/layout'
import { OperationLogType, OperationLogFiltersType } from '@/models/operation-log'
import { OperationLogRow } from '@/components/organisms/operation-log/row'
import { OperationLogSearch } from '@/components/organisms/operation-log/search'
import { LoadingFull } from '@/components/molecules/loading-full'
import * as API from '@/apis/aipass'

export const OperationLog: React.FC = () => {
  const limit = 50
  const windows = useWindowSize()
  const { t, i18n } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [operationLogList, setOperationLogList] = useState<(OperationLogType & { showDetail?: boolean })[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const [hasNext, setHasNext] = useState<boolean>(false)
  const [isOpenDetailedSearch, setIsOpenDetailedSearch] = useState<boolean>(false)
  const [searchFilters, setSearchFilters] = useState<OperationLogFiltersType>({})

  const loadOperationLogList = async () => {
    try {
      setLoading(true)

      const res = await API.getOperationLogList({ filters: searchFilters, page, limit })

      setOperationLogList([]) // To reset the table position to the top
      setOperationLogList(res.data || [])
      setTotal(res.meta?.total || 0)
      setHasNext(res.meta?.current_page < res.meta?.last_page)
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const onChangeSearchFilters = data => {
    if (!isEqual(data, searchFilters)) {
      setPage(1)
      setSearchFilters(data)
    }

    setIsOpenDetailedSearch(false)
  }

  useEffect(() => {
    loadOperationLogList()
  }, [page, limit, searchFilters])

  return (
    <>
      <DashboardPageLayout title={t('Operation Log')}>
        <>
          <div css={[dashboardPageContainerStyle, { position: 'relative' }]}>
            <LoadingFull isLoading={loading} />
            <div css={headerSettingOperationLogStyle}>
              <div css={{ display: 'flex', alignItems: 'center' }}>
                <div css={headerDetailStyle}>{t('Operation history')}</div>

                <Button
                  buttonType={4}
                  width={i18n.language === 'en' ? 118 : 90}
                  height={32}
                  fontSize={12}
                  marginLeft={24}
                  marginRight={24}
                  onClick={() => setIsOpenDetailedSearch(!isOpenDetailedSearch)}
                >
                  {t('Detailed search')}
                </Button>
              </div>
              <AccommodationPaging page={Number(page)} setPage={setPage} total={total} />
            </div>
            {
              <div css={{ display: isOpenDetailedSearch ? 'block' : 'none' }}>
                <OperationLogSearch onChange={onChangeSearchFilters} />
              </div>
            }
            <div css={{ overflow: 'auto', height: windows.height - 154 }}>
              {!!operationLogList?.length && (
                <table css={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th css={[tableHeaderThStyle, { paddingLeft: 28 }]}></th>
                      <th css={[tableHeaderThStyle]}>{t('Date and time of operation')}</th>
                      <th css={tableHeaderThStyle}>{t('Operation')}</th>
                      <th css={tableHeaderThStyle}>{t('Features')}</th>
                      <th css={tableHeaderThStyle}>{t('Table')}</th>
                      <th css={tableHeaderThStyle}>{t('Account')}</th>
                      <th css={[tableHeaderThStyle, { paddingRight: 28 }]}>{t('Staff')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {operationLogList?.map(operation => (
                      <OperationLogRow key={operation.id} operation={operation} />
                    ))}
                  </tbody>
                </table>
              )}
              {!operationLogList?.length && !loading && (
                <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <h1>{t('No data available')}</h1>
                </div>
              )}
            </div>
          </div>
        </>
      </DashboardPageLayout>
    </>
  )
}

const headerSettingOperationLogStyle = css({
  height: '50px',
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const headerDetailStyle = css({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: '16px 0',
  color: '#272727',
})
