import React, { MouseEventHandler, useState } from 'react'
import { css, SerializedStyles } from '@emotion/core'

type DropDownItemProps = {
  title: string
  onClick: MouseEventHandler<HTMLLIElement>
  icon?: string
}

type DropDownProps = {
  title: string
  items: DropDownItemProps[]
  buttonStyle?: SerializedStyles
}

export const DropDown = (props: DropDownProps) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)

  return (
    <div css={css(buttonStyle, props.buttonStyle)} onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
      {props.title}
      <img css={arrowStyle} src={require('@/static/images/arrow_gray.svg')} alt="" />
      {isDropDownOpen && (
        <div css={dropDownStyle}>
          <ul css={dropDownBodyStyle}>
            {props.items.map(item => (
              <li key={item.title} css={dropDownContentStyle} onClick={item.onClick}>
                <p css={dropDownTitleStyle}>{item.title}</p>
                {item.icon && <img css={arrowStyle} src={item.icon} alt="" />}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

const buttonStyle = css({
  width: 116,
  border: '1px solid #ccc',
  borderRadius: 5,
  color: '#676767',
  fontSize: 12,
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '6px 0',
  position: 'relative',
  paddingRight: 10,
  cursor: 'pointer',
  textTransform: 'capitalize',
})

const arrowStyle = css({
  position: 'absolute',
  right: 0,
  padding: '4px 8px',
})

const dropDownStyle = css({
  width: 140,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 30,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownBodyStyle = css({})

const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  fontSize: 14,
  fontWeight: 'normal',
  color: '#272727',
})
