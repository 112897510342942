import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'
import useBrowserContextCommunication from 'react-window-communication-hook'

import { qrSignout } from '@/apis/auth0'
import logo from '@/static/images/icon/logo.svg'

type Props = {
  _onScanClick: () => void
  _onLogoutClick: () => void
}

export const QrScan: React.FC = () => {
  const history = useHistory()
  const [communicationState, postMessage] = useBrowserContextCommunication('')

  const _onScanClick = () => {
    history.push({ pathname: `/qr-checkin/camera`, search: window.location.search })
  }

  const _onLogoutClick = async () => {
    postMessage('logout')
    qrSignout()
  }

  useEffect(() => {
    // Log out the management screen of all tabs when logging out (to avoid duplicate logins)
    if (communicationState.lastMessage === 'logout') {
      qrSignout()
    }
  }, [postMessage])

  return <UI _onScanClick={_onScanClick} _onLogoutClick={_onLogoutClick} />
}

export const UI: React.FC<Props> = ({ _onScanClick, _onLogoutClick }) => {
  const { t } = useTranslation()
  const windows = useWindowSize()

  return (
    <div
      style={{
        height: windows.height,
      }}
      css={qrCheckinWrapperStyle}
    >
      <img css={logoStyle} alt="logo" src={logo} />
      <p css={scanTitleStyle}>{t('Scan QR code')}</p>
      <button css={scanButtonStyle} onClick={_onScanClick} type="button">
        <img css={scanImageStyle} alt="scan" src={require('@/static/images/scan.svg')} />
      </button>
      <p css={scanTextStyle}>
        {t('The guests QR code')}
        <br />
        {t('Please read')}
      </p>
      <p css={logoutStyle} onClick={_onLogoutClick}>
        {t('Logout')}
      </p>
    </div>
  )
}

const qrCheckinWrapperStyle = css({
  background: 'white',
  minWidth: '100vw',
  padding: '24.05px 0',
  textAlign: 'center',
})

const scanButtonStyle = css({
  background: 'linear-gradient(90deg, #FF7733, #FF2700)',
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  height: 143.68,
  margin: '80.32px auto 60px auto',
  width: 143.68,
})

const logoStyle = css({
  height: 30,
  width: 75,
})

const scanTitleStyle = css({
  color: '#272727',
  fontSize: 18,
  fontWeight: 'bold',
  marginTop: 86.05,
  letterSpacing: '3.6px',
  lineHeight: '32px',
})

const scanImageStyle = css({
  height: 63.1,
  width: 62.84,
})

const scanTextStyle = css({
  color: '#272727',
  fontSize: 14,
  whiteSpace: 'pre-line',
  height: 38,
  lineHeight: '21px',
  letterSpacing: '1.4px',
})

const logoutStyle = css({
  color: '#FF5500',
  fontSize: 14,
  letterSpacing: '1.4px',
  height: 14,
  lineHeight: '14px',
  width: 'max-content',
  margin: '32px auto 0',
  ':hover': {
    cursor: 'pointer',
  },
})
