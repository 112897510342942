import { t } from 'i18next'

export const ReservationStatusType = {
  NewReport: 1,
  ModificationReport: 2,
  CancellationReport: 3,
  NoshowReport: 4,
} as const
export type ReservationStatusType = (typeof ReservationStatusType)[keyof typeof ReservationStatusType]

export const reservationStatusValues: Record<ReservationStatusType, string> = {
  [ReservationStatusType.NewReport]: t('New'),
  [ReservationStatusType.ModificationReport]: t('Update'),
  [ReservationStatusType.CancellationReport]: t('Cancel'),
  [ReservationStatusType.NoshowReport]: t('No show'),
}
