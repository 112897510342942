import React, { useMemo, useRef, useState, ComponentProps } from 'react'
import { SerializedStyles, css } from '@emotion/core'
import { useFileUpload } from '@/hooks/use-file-upload'

type Props = Omit<ComponentProps<'div'>, 'onChange'> & {
  value?: string
  onChange: (value: string) => void
  onShowModal: (isShowModal: boolean) => void
  readonly?: boolean
  customStyle?: SerializedStyles
  aspect?: number
  error?: string
}

export const NoneCropInputFileImage: React.FC<Props> = ({
  value,
  onChange,
  onShowModal,
  aspect,
  error,
  readonly,
  customStyle,
  ...props
}) => {
  const [uploadedImage, setUploadedImage] = useState<string | undefined>(value)
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const { inputFileHandler } = useFileUpload()

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    inputFileHandler(e, ['image/jpeg', 'image/png'], f => {
      onChange(f.uploadedPath)
      setUploadedImage(f.uploadedPath)
    })
  }

  const widthRate = useMemo(() => (aspect && aspect > 1 ? aspect : 1), [aspect])
  const heightRate = useMemo(() => (aspect && aspect <= 1 ? aspect : 1), [aspect])

  const pictureBackgroundSizeStyle = useMemo(() => {
    return css({
      width: 80 * widthRate,
      height: 80 * heightRate,
      '.uploaded-picture, .upload-place-picture': {
        position: 'absolute',
        width: 80 * widthRate,
        height: 80 * heightRate,
      },
    })
  }, [widthRate, heightRate])

  return (
    <>
      <div
        css={[pictureBackgroundStyle, pictureBackgroundSizeStyle, customStyle]}
        onClick={() => {
          if (readonly) {
            setIsShowPreview(true)
            return
          }
          if (inputFileRef.current) {
            inputFileRef.current.value = ''
            inputFileRef.current.click()
          }
        }}
        {...props}
      >
        <div className="uploaded-picture" style={{ backgroundImage: `url(${uploadedImage}` }}></div>
        {readonly ? (
          <div className="uploaded-picture" style={{ border: '1px solid #CCCCCC' }}></div>
        ) : (
          <div
            className="upload-place-picture"
            style={{
              backgroundColor: uploadedImage ? 'rgba(0, 0, 0,0.3)' : '#CCCCCC',
              border: error ? '2px solid red' : 'none',
            }}
          ></div>
        )}
      </div>
      <input type="file" ref={inputFileRef} onChange={e => handleChangeFile(e)} style={{ display: 'none' }} />
      {isShowPreview && (
        <div css={previewPhotoStyle}>
          <div className="background-overlay" onClick={() => setIsShowPreview(false)}>
            <img className="preview-photo" src={uploadedImage} />
          </div>
        </div>
      )}
    </>
  )
}

const pictureBackgroundStyle = css({
  position: 'relative',
  '.uploaded-picture, .upload-place-picture': {
    position: 'absolute',
    borderRadius: 5,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    cursor: 'pointer',
  },
  '.uploaded-picture': {
    backgroundSize: 'cover',
  },
  '.upload-place-picture': {
    backgroundImage: `url(${require('@/static/images/camera_icon_white.svg')})`,
    backgroundSize: 17,
  },
})

const previewPhotoStyle = css({
  '.background-overlay': {
    position: 'fixed',
    zIndex: 10000,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0,0,0,0.5)',
    display: 'flex',
  },
  '.preview-photo': {
    margin: '32px auto',
  },
})
