import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useForm, Controller, useFieldArray } from 'react-hook-form'

// components
import { InputField } from '@/components/molecules/input-field'
import { Button } from '@/components/atoms/button'
import { GuestRoomTypeType, NewGuestRoomTypeType } from '@/models/guest-room-type'

// layout
import {
  modalContainerStyle,
  modalWrapperStyle,
  modalStyle,
  modalTitleStyle,
  modalItemWrapperStyle,
  modalFooterStyle,
} from '@/components/pages/setting/guest-room/room/_room/modal/modal-layout'
import { insertAdminGuestRoomType, putAdminGuestRoomType } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'

type Props = {
  title: string
  editRoom?: GuestRoomTypeType
  onClickCancel: () => void
  onClickSave: () => void
  nextDisplayOrder?: number
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const RoomTypeModal: React.FC<Props> = ({ title, onClickCancel, onClickSave, editRoom, nextDisplayOrder, setIsLoading }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<NewGuestRoomTypeType>({
    defaultValues: {
      id: editRoom?.id,
      roomTypeId: editRoom?.roomTypeId,
      roomTypeName: editRoom?.typeNameJa,
      roomTypeShortName: editRoom?.typeShortName,
      otaCodes: editRoom?.otaCodes || [{ code: '' }, { code: '' }, { code: '' }, { code: '' }, { code: '' }],
      quantity: editRoom?.quantity,
      displayOrder: editRoom?.displayOrder || nextDisplayOrder || 1,
    },
  })
  const otaCodeInputs = useFieldArray({
    control,
    name: 'otaCodes',
  })

  const { errorHandler } = useErrorHandler()

  const save = async (formValues: NewGuestRoomTypeType) => {
    try {
      setIsLoading(true)
      if (editRoom && formValues.id) {
        await putAdminGuestRoomType(formValues.id, formValues)
      } else {
        await insertAdminGuestRoomType(formValues)
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }

    onClickSave()
  }

  return (
    <div css={modalContainerStyle} style={{ height: '100%', alignItems: 'normal' }}>
      <div css={modalWrapperStyle} style={{ height: 'auto' }}>
        <div css={[modalStyle, { margin: '15px auto' }]}>
          <div css={modalTitleStyle}>{title}</div>
          <div css={[modalSectionStyle, { maxHeight: '80%' }]}>
            <div css={modalItemWrapperStyle}>
              <Controller
                name="displayOrder"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    value={value}
                    name={name}
                    handleChangeData={onChange}
                    label={t('Display order')}
                    placeholder="00000"
                    type="number"
                  />
                )}
              />

              <Controller
                name="roomTypeId"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    value={value}
                    name={name}
                    handleChangeData={onChange}
                    label={t('Room type ID')}
                    placeholder={t('Room type ID (within 4 digits)')}
                    type="number"
                    disabled={!!editRoom}
                    required
                  />
                )}
              />

              <Controller
                name="roomTypeName"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    value={value}
                    name={name}
                    handleChangeData={onChange}
                    label={t('Room type name')}
                    placeholder={t('Room type name')}
                    required
                  />
                )}
              />

              <Controller
                name="roomTypeShortName"
                control={control}
                rules={{ maxLength: 3 }}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    value={value}
                    name={name}
                    handleChangeData={onChange}
                    label={t('Room type short name')}
                    placeholder={`${t('Room type short name')}(${t('maxLength', { max: 3 })})`}
                  />
                )}
              />

              <Controller
                name="quantity"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    value={value}
                    name={name}
                    handleChangeData={onChange}
                    label={t('Number of rooms')}
                    placeholder={t('Number of rooms')}
                    required
                    type="number"
                  />
                )}
              />

              {otaCodeInputs.fields.map((otaCode, index) => (
                <>
                  <input type="hidden" {...register(`otaCodes.${index}.id`)} />
                  <Controller
                    key={otaCode.id}
                    name={`otaCodes.${index}.code`}
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <div css={otaCodeStyle}>
                        <InputField
                          value={value}
                          name={name}
                          handleChangeData={onChange}
                          label={t('Room type code')}
                          placeholder={t('OTA code')}
                          width={'425px'}
                        />
                        <img
                          css={firstCodeDeleteStyle}
                          src={require('@/static/images/room_delete_gold.svg')}
                          onClick={() => otaCodeInputs.remove(index)}
                        />
                      </div>
                    )}
                  />
                </>
              ))}
              <div css={addRoomTypeWrapperStyle} onClick={() => otaCodeInputs.append({ code: '' })}>
                <p>{t('Add room type code')}</p>
              </div>
            </div>
          </div>
          <div css={modalFooterStyle}>
            <Button
              buttonType={3}
              width={110}
              height={38}
              fontSize={14}
              marginRight={16}
              onClick={() => {
                onClickCancel()
              }}
            >
              {t('Cancel')}
            </Button>
            <Button
              buttonType={!isValid ? 5 : 1}
              width={110}
              height={38}
              fontSize={14}
              marginRight={32}
              onClick={handleSubmit(save)}
              isDisabled={!isValid}
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const modalSectionStyle = css({
  background: '#F2F2F2',
  padding: '24px 28px',
  height: 569,
  overflowY: 'scroll',
})

const addRoomTypeWrapperStyle = css({
  cursor: 'pointer',
  display: 'flex',
  height: 36,
  backgroundColor: '#FAFAFA',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px dashed #CCCCCC',
  p: {
    lineHeight: '36px',
    color: '#FF5500',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
})

const otaCodeStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
})

const firstCodeDeleteStyle = css({
  width: 30,
  height: 30,
  marginLeft: 16,
  marginTop: 23,
})
