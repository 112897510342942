import React, { useState, useEffect, useContext } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import Lottie from 'react-lottie'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

// contexts
import { AccountContext } from '@/contexts/account'

// apis
import { updateDashboardNote, fetchDashboardNote } from '@/apis/aipass'

// components
import { sectionHeaderStyle, sectionStyle } from '@/components/pages/dashboard/_index/style'
import { Button } from '@/components/atoms'

type MessageBoardProps = {}

export const MessageBoard: React.FC<MessageBoardProps> = () => {
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const [memo, setMemo] = useState<{ id: string; memo: string; updatedAt: string }>({ id: '', memo: '', updatedAt: '' })
  const [inputNote, setInputNote] = useState<string>('')
  const [canEdit, setCanEdit] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const _updateDashboardNote = async () => {
    setIsLoading(true)
    await updateDashboardNote(memo?.id, inputNote)
      .then(() => {
        _fetchDashboardNote()
      })
      .catch(error => {
        console.log('error', error)
      })
      .finally(() => {
        setCanEdit(false)
        setIsLoading(false)
      })
  }

  const _fetchDashboardNote = async () => {
    setIsLoading(true)
    await fetchDashboardNote()
      .then(res => {
        res && setMemo(res[0])
        res && setInputNote(res[0]?.memo)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onCancel = () => {
    setCanEdit(false)
    _fetchDashboardNote()
  }

  useEffect(() => {
    if (account && account?.hotel) {
      _fetchDashboardNote()
    }
  }, [account])

  return (
    <div css={[mixedSectionStyle, { position: 'relative' }]}>
      {isLoading && (
        <div
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(255, 255, 255, 0.8)',
          }}
        >
          <Lottie
            height="80px"
            width="80px"
            isLoading={isLoading}
            options={{ animationData: require('@/static/images/json/loading.json'), autoplay: true, loop: true }}
          />
        </div>
      )}
      <div css={messageBoardHeaderStyle}>
        <div css={TitleStyle}>{t('Whole share')}</div>
        <div css={dayStyle}>
          {t('Update date')}: {memo?.updatedAt ? dayjs(memo?.updatedAt).format(t('MM-DD HH:mm')) : ''}
        </div>
      </div>
      <div css={memoBlockStyle}>
        <TextareaAutosize
          onClick={() => {
            setCanEdit(true)
          }}
          value={inputNote}
          onChange={v => setInputNote(v.target.value)}
        />
      </div>
      {canEdit && (
        <div css={addButtonWrapperStyle}>
          <Button buttonType={3} width={100} height={32} fontSize={12} marginRight={16} onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button buttonType={1} width={100} height={32} fontSize={12} onClick={_updateDashboardNote}>
            {t('Save')}
          </Button>
        </div>
      )}
    </div>
  )
}

const mixedSectionStyle = css(sectionStyle, {
  minHeight: 440,
})

const messageBoardHeaderStyle = css(sectionHeaderStyle, {
  borderBottom: '1px solid #f2f2f2',
  justifyContent: 'space-between',
  marginLeft: 0,
  padding: '0 32px',
})

const addButtonWrapperStyle = css({
  margin: '0 auto 24px auto',
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: 24,
})

const TitleStyle = css({
  color: '#272727',
})

const dayStyle = css({
  fontSize: 12,
  color: '#676767',
})

const memoBlockStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  width: '100%',
  textarea: {
    width: '100%',
    minHeight: 295,
    maxHeight: 295,
    border: '1px solid #CCCCCC',
    borderRadius: 12,
    display: 'block',
    margin: '23px 23px 16px 23px',
    padding: '12px 10px',
    resize: 'none',
    fontSize: 14,
    lineHeight: '21px',
    '&::placeholder': {
      color: '#CCCCCC',
    },
  },
})
