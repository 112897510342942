import React from 'react'
import { css } from '@emotion/core'

// components
import { ChangeOrder } from '@/components/pages/room-manager/_daily/change-order'

// models
import { GuestRoomAssignStatusResponseType } from '@/models/room-manager/response/guest-room-assign-status'
import { useTranslation } from 'react-i18next'

type LeftGuestRoomProps = {
  guestRooms: GuestRoomAssignStatusResponseType['guestRoomAssignStatusList'] | undefined
  order: 'room' | 'floor'
  setOrder: React.Dispatch<React.SetStateAction<'room' | 'floor'>>
  hiddenRoomNameList: string[]
  changeHiddenRoomNameList: (type: 'add' | 'delete', labelName?: string | undefined) => void
  fetchGuestRoomAssignStatus: (
    selectOrder?: 'room' | 'floor',
    guestRoom?: GuestRoomAssignStatusResponseType['guestRoomAssignStatusList'],
  ) => Promise<any>
  onClickNewReservation: () => void
}

export const LeftGuestRoom: React.FC<LeftGuestRoomProps> = ({
  guestRooms,
  order,
  setOrder,
  hiddenRoomNameList,
  changeHiddenRoomNameList,
  fetchGuestRoomAssignStatus,
  onClickNewReservation,
}) => {
  const { t } = useTranslation()

  // Show/hide sidebar when clicked
  const onClickLabelName = (labelName: string) => {
    // Add to hiddenRoomNameList if labelName is not in hiddenRoomNameList
    if (!hiddenRoomNameList.includes(labelName)) {
      changeHiddenRoomNameList('add', labelName)
    } else {
      // Remove from hiddenRoomNameList if any
      changeHiddenRoomNameList('delete', labelName)
    }
  }

  const sortRooms = (guestRooms: { [roomNumber: string]: { displayOrder: number; roomNumber: string } }) => {
    const rooms = Object.values(guestRooms)
    rooms.sort((a, b) => a.displayOrder - b.displayOrder)
    return rooms.map(r => r.roomNumber)
  }

  return (
    <div id="leftBlock" css={roomListRoomWrapperStyle}>
      <div css={roomListSectionStyle}>
        <div css={roomListBlockStyle}>
          <ChangeOrder
            order={order}
            setOrder={setOrder}
            hiddenRoomNameList={hiddenRoomNameList}
            changeHiddenRoomNameList={changeHiddenRoomNameList}
            fetchGuestRoomAssignStatus={fetchGuestRoomAssignStatus}
            onClickNewReservation={onClickNewReservation}
          />
        </div>
        <div css={remainingRoomTotalStyle}>{t('Total remaining rooms')}</div>
        {guestRooms &&
          Object.keys(guestRooms).map(floor => {
            if (!floor) return null
            return (
              <div key={floor} style={{ cursor: 'pointer' }}>
                <div css={roomListFloorWrapperStyle} onClick={() => onClickLabelName(floor)}>
                  <p css={roomListFloorStyle}>{floor}</p>
                </div>
                {sortRooms(guestRooms[floor]).map(room => {
                  if (room === '指定なし') return null
                  return (
                    !hiddenRoomNameList.includes(floor) && (
                      <div key={room} css={order === 'floor' ? roomWrapperStyle : roomWrapperRoomTypeStyle}>
                        {order === 'floor' ? (
                          <ul>
                            <li css={roomNumberStyle}>{guestRooms[floor][room].roomNumber}</li>
                            <li css={roomTypeStyle}>{guestRooms[floor][room].roomType}</li>
                          </ul>
                        ) : (
                          <ul>
                            <li css={roomNumberStyle}>{guestRooms[floor][room].roomNumber}</li>
                          </ul>
                        )}
                      </div>
                    )
                  )
                })}
              </div>
            )
          })}
      </div>
    </div>
  )
}

const roomListSectionStyle = css({})

const roomListRoomWrapperStyle = css({
  minWidth: 'calc(22% - 4px)',
  position: 'sticky',
  left: 0,
  zIndex: 10,
})

const roomListFloorWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: 47,
  background: '#fafafa',
  padding: '8px 17px 8px 24px',
  borderBottom: '1px solid #E3E3E3',
})
const roomListFloorStyle = css({
  fontSize: 12,
  letterSpacing: 0.6,
  fontWeight: 'bold',
  color: '#676767',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  lineHeight: '15px',
})

const roomListBlockStyle = css({
  borderRight: '1px solid #F2F2F2',
  borderBottom: '1px solid #E3E3E3',
  height: 90,
  background: '#FFFFFF',
  position: 'sticky',
  top: 0,
})

const roomWrapperStyle = css({
  height: 42,
  padding: '8px 0 8px 22px',
  background: '#fff',
  borderRight: '1px solid #F2F2F2',
  borderBottom: '1px solid #F2F2F2',
  ':last-of-type': {
    borderBottom: '1px solid #E3E3E3',
  },
})

const roomWrapperRoomTypeStyle = css(roomWrapperStyle, {
  lineHeight: '25px',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 17px 8px 22px',
})

const roomNumberStyle = css({
  fontSize: 12,
  letterSpacing: 0.6,
  marginBottom: 4,
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const roomTypeStyle = css({
  fontSize: 10,
  letterSpacing: 0.5,
  color: '#A3A3A3',
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const remainingRoomTotalStyle = css({
  backgroundColor: '#FAFAFA',
  height: 29,
  color: '#A3A3A3',
  fontWeight: 'bold',
  fontSize: 12,
  letterSpacing: '0.6px',
  padding: '7.5px 0 7.5px 24px',
  borderBottom: '1px solid #E3E3E3',
  borderRight: '1px solid #F2F2F2',
  position: 'sticky',
  top: 90,
  zIndex: 1,
})
