import React from 'react'
import Lottie from 'react-lottie'

type LoadingProps = {
  isLoading: boolean
}

export const LoadingFull: React.FC<LoadingProps> = ({ isLoading }) => {
  const animationData = require('@/static/images/json/loading.json')

  return (
    <React.Fragment>
      {isLoading && (
        <div
          css={[
            {
              position: 'fixed',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(255, 255, 255, 0.8)',
              zIndex: 10000,
            },
          ]}
        >
          <Lottie height="80px" width="80px" options={{ animationData: animationData, autoplay: true, loop: true }} />
        </div>
      )}
    </React.Fragment>
  )
}
