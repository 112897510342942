import { AxiosError } from 'axios'

import I18n from '../i18n'

export type ApiError<T = any> = AxiosError<
  {
    message?: string
    errorMessage?: string
    errors?: { [propName: string]: string[] }
  } & T
>

export const isApiError = (arg: any): arg is ApiError => {
  return (
    arg?.response?.data?.message !== undefined ||
    arg?.response?.data?.errorMessage !== undefined ||
    arg?.response?.data?.errors !== undefined
  )
}

export const errorHandler = (error: AxiosError | any, options?: { mustShow: boolean }) => {
  if (!isApiError(error)) return alert(error)
  if (error?.response?.data?.mustShow && !options?.mustShow) return

  const errorResponseData = error?.response?.data
  if (!errorResponseData) return alert(error)

  const mainErrorText = I18n.t(errorResponseData?.message || errorResponseData?.errorMessage || '')

  const errorsArray: ({ message: string; params?: string } | string)[][] = Object.values(errorResponseData?.errors || [])

  let errorText = ''

  for (const [indexTop, topErrors] of errorsArray.entries() as any) {
    if (indexTop === 0 && errorText) errorText += '\n'
    if (Array.isArray(topErrors)) {
      for (const [index, error] of topErrors.entries() as any) {
        if (typeof error === 'object') {
          errorText += (indexTop !== 0 || index !== 0 ? '\n' : '') + I18n.t(error.message, error.params)
        } else if (typeof error === 'string') {
          errorText += (indexTop !== 0 || index !== 0 ? '\n' : '') + I18n.t(error)
        }
      }
    }
  }

  if (errorText === '\n') errorText = ''
  if (!errorText && !mainErrorText) errorText = String(error)

  alert(errorText || mainErrorText)
}
