import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// components
import { Header as CommonHeader } from '@/components/organisms/header'

export const Header: React.FC = () => {
  const { t } = useTranslation()

  return (
    <CommonHeader
      title={t('Room management')}
      afterTitle={
        <div css={statusHint}>
          <button
            css={{
              border: 'none',
              backgroundColor: '#F2A40B',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 18,
              height: 18,
              borderRadius: 9,
              fontSize: 12,
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
          >
            ?
          </button>
          <div css={statusTypeContainerStyle} className="status-list">
            <div css={{ fontSize: 12, color: '#272727', opacity: 0.67, marginBottom: 12 }}>{t('Room status')}</div>
            <div css={statusTypeWrapperStyle}>
              <div css={statusTypeStyle}>
                <div css={circleStyle} style={{ background: '#F2A429' }}></div>
                <p css={{ color: '#F2A429' }}>{t('Reserve')}</p>
              </div>
              <div css={statusTypeStyle}>
                <div css={circleStyle} style={{ background: '#F47110' }}></div>
                <p css={{ color: '#F47110' }}>{t('Checkin')}</p>
              </div>
              <div css={statusTypeStyle}>
                <div css={circleStyle} style={{ background: '#3E85CC' }}></div>
                <p css={{ color: '#3E85CC' }}>{t('During your stay')}</p>
              </div>
              <div css={statusTypeStyle}>
                <div css={circleStyle} style={{ background: '#7DC931' }}></div>
                <p css={{ color: '#7DC931' }}>{t('Checkout')}</p>
              </div>
            </div>
          </div>
        </div>
      }
    />
  )
}


const statusHint = css({
  position: 'relative',
  '.status-list': {
    display: 'none',
  },
  ':hover .status-list': {
    display: 'block',
  },
})

const statusTypeContainerStyle = css({
  padding: 16,
  backgroundColor: '#fff',
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
  position: 'absolute',
  top: 0,
  left: 0,
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
})

const statusTypeWrapperStyle = css({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 16,
})

const statusTypeStyle = css({
  display: 'flex',
  p: {
    fontSize: 12,
    letterSpacing: 0.6,
  },
})

const circleStyle = css({
  width: 14,
  height: 14,
  borderRadius: '50%',
  marginRight: 12,
})
