import React, { useState } from 'react'
import { SingleDatePicker, CalendarDay } from 'react-dates'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { css } from '@emotion/core'

type DatePickerProps = {
  date?: string
  onDateChange: (dateString: string) => void
  onFocusDetailDate?: (e: React.ChangeEvent<HTMLDivElement>) => void
  placeHolder?: string
  disabled?: boolean
  isFullYear?: boolean
  position?: { top: number; left: number }
}

export const DatePicker: React.FC<DatePickerProps> = ({ date, onDateChange, placeHolder, disabled, isFullYear, position }) => {
  const [calendarFocused, setCalendarFocused] = useState<boolean>(false)

  const { t } = useTranslation()

  const dateStyle = css({
    '.SingleDatePicker_picker.SingleDatePicker_picker__directionLeft': {
      position: 'fixed',
      top: `${position?.top}px!important`,
      left: `${position?.left}px!important`,
    },
  })

  return (
    <div css={position ? dateStyle : undefined}>
      <SingleDatePicker
        date={date ? moment(date) : null}
        placeholder={placeHolder ? placeHolder : t('Date')}
        onDateChange={date => date && onDateChange(date.format('YYYY-MM-DD'))}
        focused={calendarFocused}
        onFocusChange={v => setCalendarFocused(Boolean(v.focused))}
        numberOfMonths={1}
        isOutsideRange={() => false}
        displayFormat={isFullYear ? t('MM-DD-YYYY') : t('MM-DD')}
        monthFormat={t('MMM-YY')}
        disabled={disabled}
        id="date"
        hideKeyboardShortcutsPanel={true}
        customInputIcon={<img alt="calendar" src={require('@/static/images/calendar_icon.svg')} width={14} />}
        navPrev={
          <div className="monthPrev">
            <img
              alt="arrow"
              src={require('@/static/images/icon_caalender_left.svg')}
              onMouseOver={e => (e.currentTarget.src = require('@/static/images/icon_caalender_left.svg'))}
              onMouseOut={e => (e.currentTarget.src = require('@/static/images/icon_caalender_left.svg'))}
            />
          </div>
        }
        navNext={
          <div className="monthNext">
            <img
              alt="arrow"
              src={require('@/static/images/icon_caalender_right.svg')}
              onMouseOver={e => (e.currentTarget.src = require('@/static/images/icon_caalender_right.svg'))}
              onMouseOut={e => (e.currentTarget.src = require('@/static/images/icon_caalender_right.svg'))}
            />
          </div>
        }
        renderCalendarDay={props => (
          <CalendarDay
            {...props}
            renderDayContents={day => (
              <div className="day">
                <span>{day?.format('D')}</span>
              </div>
            )}
          />
        )}
      />
    </div>
  )
}
