import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// models
import { AdminReservationResponseType } from '@/models/reservation/admin-reservation'

// apis
import { fetchReservationReview } from '@/apis/aipass'

type ReviewProps = {
  reservation: AdminReservationResponseType['reservations']
}

export const Review: React.FC<ReviewProps> = ({ reservation }) => {
  const { t } = useTranslation()
  const [review, SetReview] = useState<any>()
  const _review = review?.reviewFromCheckout.length === 0 ? null : review

  // 最新のレビューを表示
  const latestIndex = _review?.reviewFromCheckout.length - 1

  const _scoreStyle = () => {
    if (_review?.reviewFromCheckout[latestIndex].point <= 6 || _review?.reviewFromCheckout[latestIndex].point !== null) {
      return lowScoreStyle
    }
    if (_review?.reviewFromCheckout[latestIndex].point <= 8) {
      return middleScoreStyle
    }
    if (_review?.reviewFromCheckout[latestIndex].point <= 10) {
      return HighScoreStyle
    }
  }

  const _fetchReservationReview = async () => {
    const reservationId = reservation.reservationId
    if (reservationId === 'redirect') return
    await fetchReservationReview(reservationId).then(res => {
      SetReview(res)
    })
  }

  useEffect(() => {
    _fetchReservationReview()
  }, [reservation])

  return (
    <div css={sectionStyle}>
      <div css={sectionHeaderStyle}>
        {t('Evaluation')}
        {(() => {
          if (_review?.reviewFromCheckout[latestIndex].point <= 4 && _review?.reviewFromCheckout[latestIndex].point !== null) {
            return <img src={require('@/static/images/evaluation.svg')} css={attentionLogoStyle} />
          }
        })()}
      </div>
      <div css={reviewWrapperStyle}>
        <div css={scoreBlockStyle}>
          <div css={_scoreStyle()}>
            <div css={moleculeStyle}>
              {_review?.reviewFromCheckout[latestIndex].point ? _review?.reviewFromCheckout[latestIndex].point : '-'}
            </div>
            <div css={denominatorStyle}>/10</div>
          </div>
        </div>
        <div css={reviewBlockStyle}>
          <div css={reviewStyle}>
            <div css={reviewRowStyle}>
              <div css={scoreTitleStyle}>{t('Improvements')}</div>
              <div css={improvementIconStyle}>
                {_review?.reviewFromCheckout[latestIndex].improvement
                  ? _review?.reviewFromCheckout[latestIndex].improvement.split(',').map(item => (
                      <div key={item} css={iconStyle}>
                        {item}
                      </div>
                    ))
                  : '-'}
              </div>
            </div>
            <div css={reviewCommentRowStyle}>
              <div css={scoreCommentTitleStyle}>{t('Comment')}</div>
              <div css={commentStyle}>
                {_review?.reviewFromCheckout[latestIndex].comment !== null && _review?.reviewFromCheckout[latestIndex].comment !== ''
                  ? _review?.reviewFromCheckout[latestIndex].comment.split('\n').map((str, index) => (
                      <React.Fragment key={index}>
                        {str}
                        <br />
                      </React.Fragment>
                    ))
                  : '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const sectionStyle = css({
  width: '100%',
  minWidth: 185,
  backgroundColor: '#FFF',
  border: '1px solid #CCCCCC',
  borderRadius: 10,
})

const sectionHeaderStyle = css({
  display: 'flex',
  paddingRight: '24px',
  height: 49,
  alignItems: 'center',
  fontSize: 16,
  fontWeight: 'bold',
  paddingLeft: 32,
  borderBottom: '1px solid #F2F2F2',
  color: '#272727',
})

const attentionLogoStyle = css({
  marginLeft: 16,
  width: 16,
  height: 16,
})

const reviewWrapperStyle = css({
  display: 'flex',
  padding: 24,
})

const scoreBlockStyle = css({
  display: 'flex',
  width: 91,
})

const scoreBaseStyle = css({
  display: 'flex',
  justifyContent: 'center',
  width: 91,
  borderRight: '1px solid #F2F2F2',
})

const HighScoreStyle = css(scoreBaseStyle, {
  color: '#676767',
})

const middleScoreStyle = css(scoreBaseStyle, {
  color: '#FFA97F',
})

const lowScoreStyle = css(scoreBaseStyle, {
  color: '#F4DF35',
})

const moleculeStyle = css({
  fontSize: 28,
  fontWeight: 'bold',
})

const denominatorStyle = css({
  fontSize: 14,
  paddingTop: 11,
  paddingLeft: 3,
  fontWeight: 'bold',
})

const reviewBlockStyle = css({
  display: 'flex',
  justifyContent: 'center',
})

const reviewStyle = css({
  display: 'flex',
  flexFlow: 'column',
})

const reviewRowStyle = css({
  display: 'flex',
})

const reviewCommentRowStyle = css(reviewRowStyle, {
  marginTop: 24,
})

const scoreTitleStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  paddingLeft: 24,
  width: 89,
  color: '#ccc',
})

const scoreCommentTitleStyle = css({
  display: 'flex',
  fontSize: 12,
  paddingLeft: 24,
  width: 89,
  color: '#ccc',
  lineHeight: 1.5,
})

const improvementIconStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  fontWeight: 'bold',
  flex: 1,
  '& > *': {
    marginRight: 8,
  },
})

const iconStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 51,
  height: 25,
  borderRadius: 13,
  background: '#F2F2F2',
  color: '#272727',
})

const commentStyle = css({
  fontSize: 14,
  flex: 1,
  color: '#272727',
  flexWrap: 'wrap',
  height: 'auto',
  lineHeight: 1.5,
})
