import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Button } from '@/components/atoms'
import { Select, Option } from '@/components/atoms/select'
import { useTranslation } from 'react-i18next'
import { Editor } from '@/components/organisms/settings/editor'
import { MailDeliveryTag } from '@/components/molecules/settings/mail-delivery/mail-delivery-tag'
import { ApprovedStatusType, approvedStatusValues } from '@/models/reservation-approved-status'
import { timesBy15Minute } from '@/libs/times'
import { fetchReservationChannelCode, saveMailDeliveryContent } from '@/apis/aipass'
import { MailTemplateModal, Template } from './mail-template-modal'
import {
  MailDeliveryContent,
  MailDeliveryContentInput,
  MailDeliveryStatus,
  dateExecution,
  isDraft,
  lineNotificationStatus,
  targetStatus,
} from '@/models/mail-delivery/mail-delivery-content'
import { SaveDraftConfirmDialog } from './save-draft-confirm-dialog'
import { v4 as uuid } from 'uuid'
import { ChannelCodeType } from '@/models/reservation-channel-code'
import { isEmpty } from 'lodash'
import { Checkbox } from '@/components/atoms/checkbox'
import { Hint } from '@/components/atoms/hint'

interface DetailContentFormProps {
  useFormReturn: UseFormReturn<MailDeliveryContentInput>
  detail?: MailDeliveryContent
  channelCodes?: ChannelCodeType[]
}

export const formatFormData = (submitData: MailDeliveryContentInput) => {
  return {
    ...submitData,
    isSendToAfterDelivery: submitData.isSendToAfterDelivery || false,
    isNullSearchableChannelCode: submitData.channelCodes.includes('-'),
    channelCodes: submitData.channelCodes.filter(code => code !== '-') ?? [],
    reservationStatuses: submitData.reservationStatuses ? submitData.reservationStatuses.map(Number) : [],
  }
}

export const DetailContentForm: React.FC<DetailContentFormProps> = ({ detail, useFormReturn, channelCodes }) => {
  const [reservationChannelCodes, setReservationChannelCodes] = useState<ChannelCodeType[]>(channelCodes || [])
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [contentData, setContentData] = useState<Template>({
    id: detail?.id || uuid(),
    name: '',
    title: detail?.title.ja || '',
    content: detail?.content.ja || '',
    rawContent: detail?.rawContent || '',
  })

  const { t } = useTranslation()

  const _fetchReservationChannelCode = () => {
    fetchReservationChannelCode().then(res => {
      if (res) setReservationChannelCodes(res.channelCodes)
    })
  }

  const { control, register, setValue, getValues } = useFormReturn
  const status = getValues('status')

  const onLoadTemplate = (template: Template) => {
    setContentData({ ...template, id: uuid(), content: template.content })
    setValue('title', template.title)

    setShowTemplateModal(false)
  }

  useEffect(() => {
    if (isEmpty(reservationChannelCodes)) {
      _fetchReservationChannelCode()
    }
  }, [])

  const onDraftSave = async () => {
    const values = getValues()
    const formattedData = {
      ...values,
      status: MailDeliveryStatus.Draft,
      channelCodes: values.channelCodes ?? [],
      reservationStatuses: values.reservationStatuses ? values.reservationStatuses.map(Number) : [],
    }
    await saveMailDeliveryContent(formattedData)
  }

  return (
    <>
      <div css={inputBlockStyle}>
        <label>{t('mailDelivery.title')}</label>
        <input {...register('title')} style={{ width: 715 }} placeholder={t('Enter subject')} />
        <div className="editor-content input-space">
          <label>{t('mailDelivery.body')}</label>
          <div css={editorBlockStyle}>
            <Editor
              id={contentData?.id}
              placeholder={t('Enter text')}
              htmlContent={contentData?.content}
              rawContent={contentData?.rawContent}
              onChange={(raw: string, html: string) => {
                setValue('content', html, { shouldDirty: true })
                setValue('rawContent', raw, { shouldDirty: true })
              }}
              style={{ height: 567, flex: '0 0 715px' }}
              isShowVideoIcon={false}
              headerElement={
                <Button buttonType={8} fontSize={11} borderRadius={16} width={143} height={27} onClick={() => setShowTemplateModal(true)}>
                  {t('Select a template')}
                </Button>
              }
            />
            <MailDeliveryTag />
          </div>
        </div>
        <div className="input-divide">
          <p className="head-label date">
            {t('Scheduled delivery date')}
            <span css={requireLabelTextStyle}>※</span>
          </p>
          <div className="delivery-date">
            <select {...register('deliveryDate.targetStatus')} style={{ width: 174 }}>
              {targetStatus.map(({ value, label }) => (
                <option key={value} value={value}>
                  {t(label)}
                </option>
              ))}
            </select>

            <input
              {...register('deliveryDate.numberOfDay', { setValueAs: v => parseInt(v) })}
              type="number"
              style={{ width: 58 }}
              min={0}
              placeholder="1"
            />

            <select {...register('deliveryDate.dateExecution')} style={{ width: 84 }}>
              {dateExecution.map(({ value, label }) => (
                <option key={value} value={value}>
                  {t(label)}
                </option>
              ))}
            </select>

            <select
              {...register('deliveryDate.deliveryTime')}
              style={{ width: 129 }}
              onFocus={() => {
                const deliveryTime = getValues('deliveryDate.deliveryTime')
                if (!deliveryTime) {
                  setValue('deliveryDate.deliveryTime', '12:00')
                }
              }}
            >
              <option value={''}>{t('Select a time')}</option>
              {timesBy15Minute.map(time => (
                <option key={time.value} value={time.value}>
                  {time.name}
                </option>
              ))}
            </select>
          </div>
          <div className="send-to-after-delivery">
            <Controller
              control={control}
              name="isSendToAfterDelivery"
              render={({ field: { onChange, value } }) => {
                return (
                  <Checkbox
                    value={value}
                    onChange={onChange}
                    label={t(
                      'Delivery will be made to reservations that are eligible for delivery after the scheduled delivery date and time.',
                    )}
                  />
                )
              }}
            />
            <Hint
              customCss={css({
                paddingLeft: 8,
                height: 18,
                position: 'static',
                '.help-icon': { width: 18, height: 18 },
                '.help-popup': {
                  left: 'calc(18px + 16px)',
                  top: -18,
                  position: 'relative',
                },
              })}
            >
              <div className="-popup">
                <div>{t('SendToAfterDeliveryPopupLine1')}</div>
                <div style={{ paddingTop: 11.5 }}>({t('e.g.')})</div>
                <div>{t('SendToAfterDeliveryPopupLine2')}</div>
                <div>{t('SendToAfterDeliveryPopupLine3')}</div>
              </div>
            </Hint>
          </div>

          <div className="delivery-target input-divide">
            <p className="head-label target">{t('delivery target')}</p>
            <div style={{ width: 415 }}>
              <label>{t('Accommodation management status')}</label>
              <Controller
                control={control}
                name="reservationStatuses"
                render={({ field }) => {
                  return (
                    <Select
                      mode="multiple"
                      className="delivery-select"
                      removeIcon={<img src={require('@/static/images/mail-delivery/form_delete.svg')} />}
                      placeholder={t('Please select')}
                      onChange={field.onChange}
                      value={field.value}
                    >
                      {Object.keys(approvedStatusValues)
                        .filter(key => key !== ApprovedStatusType.Customer.toString())
                        .map(key => (
                          <Option key={key} value={Number(key)}>
                            {approvedStatusValues[key]}
                          </Option>
                        ))}
                    </Select>
                  )
                }}
              />
            </div>

            <div className="input-space" style={{ width: 715 }}>
              <label>{t('Channel code name')}</label>
              <Controller
                control={control}
                name="channelCodes"
                render={({ field }) => (
                  <Select
                    mode="multiple"
                    className="delivery-select"
                    removeIcon={<img src={require('@/static/images/mail-delivery/form_delete.svg')} />}
                    placeholder={t('Please select')}
                    showSearch={false}
                    onChange={field.onChange}
                    value={field.value}
                  >
                    {[{ id: '-', name: '-' }, ...reservationChannelCodes].map(reservationChannelCode => (
                      <Option key={reservationChannelCode.id} value={reservationChannelCode.id!}>
                        {reservationChannelCode.name}
                      </Option>
                    ))}
                  </Select>
                )}
              />
            </div>

            <div className="input-space">
              <label>{t('mailDelivery.LINE')}</label>
              <select {...register('lineNotificationStatus')} style={{ width: 97 }}>
                {lineNotificationStatus.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      {showTemplateModal && <MailTemplateModal onSave={onLoadTemplate} onClose={() => setShowTemplateModal(false)} />}
      <SaveDraftConfirmDialog when={!status || isDraft(status)} onOk={onDraftSave} />
    </>
  )
}

const inputBlockStyle = css({
  padding: '25px 32px',
  label: {
    display: 'block',
    marginBottom: 12,
    fontSize: 12,
    fontWeight: 'bold',
  },
  input: {
    height: 32,
    padding: '8px 16px',
    border: '1px solid #CCCCCC',
    borderRadius: 17,
    textAlign: 'left',
    '::placeholder': {
      color: '#CCCCCC',
    },
  },
  select: {
    width: '100%',
    height: 32,
    fontSize: 14,
    borderRadius: 19,
    padding: '0 24px 0 16px',
    appearance: 'none',
    backgroundImage: `url(${require('@/static/images/select.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '90% center',
    backgroundSize: 10,
    borderColor: '#CCCCCC',
  },
  '.delivery-select': {
    li: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    span: {
      right: 10,
    },
  },
  '.editor-content': {
    paddingBottom: 32,
  },
  '.head-label': {
    fontSize: 14,
    fontWeight: 'bold',
    '&.date': {
      display: 'flex',
      gap: 8,
      marginBottom: 12,
    },
    '&.target': {
      marginBottom: 21,
    },
  },
  '.delivery-date': {
    display: 'flex',
    gap: 16,
  },
  '.send-to-after-delivery': {
    marginTop: 16,
    marginBottom: 32,
    display: 'flex',
    '.-popup': {
      lineHeight: '21px',
      margin: '-4.5px 0',
      fontSize: 12,
      fontWeight: 'bold',
      width: 386,
      padding: 16,
      '> div': {
        letterSpacing: 1.2,
      },
    },
  },
  '.input-divide': {
    paddingInline: 32,
    paddingTop: 30,
    marginInline: -32,
    borderTop: '1px solid #F2F2F2',
  },
  '.input-space': {
    marginTop: 24,
  },
})
const editorBlockStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  gap: 16,
})

const requireLabelTextStyle = css({
  fontSize: 10,
  fontWeight: 'bold',
  color: '#676767',
})
