import dayjs from 'dayjs'

export const makeDates = (selectDate, total, start) => {
  // 14 days ago as startDate
  const startDate: any = dayjs(selectDate).add(start, 'd')

  // Array the dates two weeks before and after startDate
  let dates: any[] = []
  for (let i = 0; i < total; i++) {
    dates.push({
      // Date, day of the week (for display)
      date: startDate.add(i, 'd').date(),
      week: startDate.add(i, 'd').day(),
      // Date (for comparison)
      days: startDate.add(i, 'd').format('YYYY-MM-DD'),
      // for inventory display
      daysForStock: startDate.add(i, 'd').format('YYYYMMDD'),
    })
  }
  const years: any[] = []
  for (let i = 0; i < total; i++) {
    years.push({
      year: startDate.add(i, 'd').year(),
      month: startDate.add(i, 'd').month() + 1,
    })
  }

  dates = _yearFormat(years, dates)
  dates = _weekFormat(dates)

  return dates
}

const _yearFormat = (years, dates) => {
  // Remove duplicate dates
  for (let i = 1; i < years.length; i++) {
    if (years[i].month == years[i - 1].month) {
      dates[i].year = ''
      dates[i].month = ''
    } else {
      dates[i].year = years[i].year
      dates[i].month = years[i].month
    }
  }

  // Insert unconditionally at the beginning
  dates[0].year = years[0].year
  dates[0].month = years[0].month

  return dates
}

// Convert day of week
const _weekFormat = dates => {
  dates.map(l => {
    switch (l.week) {
      case 0:
        return (l.week = '日')
      case 1:
        return (l.week = '月')
      case 2:
        return (l.week = '火')
      case 3:
        return (l.week = '水')
      case 4:
        return (l.week = '木')
      case 5:
        return (l.week = '金')
      case 6:
        return (l.week = '土')
    }
  })
  return dates
}
