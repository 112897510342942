import React, { ComponentProps } from 'react'
import { css } from '@emotion/core'
import { draggableIconStyle } from '@/components/molecules/settings/use-draggable-dom'

export const GuestAppTableHeader: React.FC = ({ children }) => {
  return <div css={tableHeaderStyle}>{children}</div>
}
export const GuestAppTableBody: React.FC = ({ children }) => {
  return <div css={tableBodyStyle}>{children}</div>
}
export const GuestAppTableRow: React.FC<ComponentProps<'div'>> = ({ children, ...props }) => {
  return (
    <div css={[tableRowStyle, props.draggable && draggableIconStyle]} {...props}>
      {children}
    </div>
  )
}

const tableHeaderStyle = css({
  height: 32,
  background: '#F2F2F2',
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  '> div': {
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: '1.2px',
    color: '#676767',
  },
})

const tableBodyStyle = css({
  overflow: 'auto',
  height: '100%',
})

const tableRowStyle = css([
  {
    display: 'flex',
    minHeight: 62,
    alignItems: 'center',
    '> div': {
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: '0.56px',
      color: '#272727',
    },
    borderBottom: '1px solid #F2F2F2',
    cursor: 'pointer',
    position: 'relative',
  },
])
