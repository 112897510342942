import React from 'react'
import { SettingTab } from '../setting-tab'

interface MailDeliveryTabProps {
  currentTab: 'Distribution List' | 'Reply To'
}

export const MailDeliveryTab: React.FC<MailDeliveryTabProps> = ({  currentTab }) => {
  return (
    <SettingTab
      currentTab={currentTab}
      tabs={[
        {
          name: 'Distribution List',
          path: '/setting/mail-delivery',
        },
        {
          name: 'Reply To',
          path: '/setting/reply-to',
        },
      ]}
    />
  )
}
