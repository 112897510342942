import React, { useContext, useMemo } from 'react'
import { SettingTab } from '@/components/molecules/settings/setting-tab'
import { listContainerStyle } from '@/constants/layout'
import { css } from '@emotion/core'
import { AccountContext } from '@/contexts/account'
import { PluginCode, checkActivePlugin } from '@/libs/plugins'

export const SmartKeyTab: React.FC<{ currentTab: 'Guest room' | 'Space' }> = ({ currentTab, children }) => {
  const { plugins } = useContext<any>(AccountContext)
  const spaceManagementPluginActive = checkActivePlugin(plugins, PluginCode.SpaceManagement)
  const smartKeyPluginActive = checkActivePlugin(plugins, PluginCode.SmartKey)

  const tabs = useMemo(() => {
    const _tabs = [
      {
        name: 'Guest room',
        path: '/setting/smart-key/room',
      },
    ]
    if (spaceManagementPluginActive && smartKeyPluginActive) {
      _tabs.push({
        name: 'Space',
        path: '/setting/smart-key/space',
      })
    }
    return _tabs
  }, [spaceManagementPluginActive, smartKeyPluginActive])

  return (
    <SettingTab currentTab={currentTab} tabs={tabs} customTabStyle={css({ width: 160 })}>
      <div css={topImageContainerStyle}>{children}</div>
    </SettingTab>
  )
}

const topImageContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 'auto',
  marginBottom: -10,
})
