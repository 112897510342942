import { ReservationType } from '@/models/reservation'

export const getRoomCascadeOptionsByReservations = ({
  reservations,
  reservation,
}: {
  reservations?: ReservationType[]
  reservation?: ReservationType
}) => {
  const reservationsList = reservations?.length ? reservations : reservation ? [reservation] : []
  return [
    ...reservationsList.reduce((sum: any[], item: any) => {
      if (!item?.reservationId) return sum

      const children = (item.assignedRoom || []).reduce((sum: any[], room) => {
        if (room.guestRoomAssignId) {
          sum.push({
            value: room.guestRoomAssignId,
            label: room.roomNumber,
            disabled: false,
          })
        }

        return sum
      }, [])

      sum.push({
        value: item?.reservationId,
        label: item?.accommodationId ? item?.accommodationId.slice(-5).toUpperCase() : item?.reservationId.slice(-5).toUpperCase(),
        children: children.length ? children : undefined,
        disabled: false,
      })

      return sum
    }, []),
  ]
}

export const findRoomCascadeOptionByGuestRoomAssignId = ({
  roomsOptions,
  guestRoomAssignId,
  reservationId,
}: {
  roomsOptions: any[]
  guestRoomAssignId?: string
  reservationId?: string
}): string[] => {
  if (guestRoomAssignId) {
    for (const roomOption of roomsOptions) {
      if (roomOption.value === guestRoomAssignId) return [roomOption.value]
      else if (roomOption['children']?.length) {
        const child = roomOption['children'].find(child => child.value === guestRoomAssignId)
        if (child) return [roomOption.value, child.value]
      }
    }

    if (roomsOptions?.[0]?.children?.[0]?.value) {
      return [roomsOptions[0].value, roomsOptions[0].children[0].value]
    }
  }

  if (reservationId) return [reservationId]

  return roomsOptions[0]
}

export const findDefaultRoomCascadeOption = ({
  reservationId,
  reservations,
  roomsOptions,
  usageDetail,
}: {
  reservationId?: string
  reservations: any[]
  roomsOptions: any[]
  usageDetail?: any
}): string[] => {
  if (reservationId) {
    const currentReservation = reservations.find(item => item?.reservationId === reservationId)

    if (currentReservation?.reservationId) {
      return usageDetail?.guestRoomAssignId
        ? [currentReservation?.reservationId, usageDetail.guestRoomAssignId]
        : [currentReservation?.reservationId]
    }
  }

  return roomsOptions?.[0]?.value ? [roomsOptions[0].value] : []
}

export const roomCascadeOptionsLabelRender = (label: string[]) => (label.length === 1 ? `${label[0]}/-` : label.join('/'))
