import React, { CSSProperties } from 'react'
import { css } from '@emotion/core'

type ContainerProps = {
  listItems: {
    label: string
    width: number | string
    style?: CSSProperties
    child?: JSX.Element
  }[]
}

type Props = {}

export const SettingGuestRoomLabel: React.FC<ContainerProps & Props> = ({ listItems }) => {
  return (
    <div css={settingGuestRoomLabelStyle}>
      {listItems.map(v => {
        return (
          <div key={v.label} css={labelStyle} style={{ width: v.width, ...v.style }}>
            {v.label}
            {v.child}
          </div>
        )
      })}
    </div>
  )
}

const settingGuestRoomLabelStyle = css({
  background: '#f2f2f2',
  height: 32,
  paddingLeft: 27,
  display: 'flex',
  alignItems: 'center',
})

const labelStyle = css({
  color: '#676767',
  fontSize: 12,
  letterSpacing: 1.2,
  fontWeight: 'bold',
})
