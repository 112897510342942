import React from 'react'
import { SerializedStyles, css } from '@emotion/core'

type Props = {
  src?: string
  customCss?: SerializedStyles
}

export const Hint: React.FC<Props> = ({ src, customCss, children }) => {
  return (
    <div css={[hintStyle, customCss]}>
      <img className="help-icon" src={require('@/static/images/help.svg')}></img>
      {src && <img className="help-image" src={src} alt="" />}
      {children && <div className="help-popup">{children}</div>}
    </div>
  )
}

const hintStyle = css({
  position: 'relative',
  '.help-icon': {
    cursor: 'help',
  },
  '.help-image, .help-popup': {
    display: 'none',
    position: 'absolute',
    zIndex: 100000,
  },
  ':hover .help-image, :hover .help-popup': {
    display: 'block',
  },
  '.help-popup': {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 10px #00000029',
    borderRadius: 5,
  },
})
