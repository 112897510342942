import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// contexts
import { AccountContext } from '@/contexts/account'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle } from '@/constants/layout'

// apis
import { fetchFacilityBasicInfo } from '@/apis/aipass'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { SettingSideMenu } from '@/components/organisms/setting-side-menu'
import { TopImage } from '@/components/pages/setting/top-page/top-image/top-image'
import { TopImageEdit } from '@/components/pages/setting/top-page/top-image/top-image-edit'
import { IconSetting } from '@/components/pages/setting/top-page/icon-setting/icon-setting'

type ContainerProps = {
  updateIconStatus: (icon: string, checked: boolean) => void
  changeState: (e, id) => void
  index: number
  iconStatus: any
}

type Props = {
  isLoading: boolean
  basicInfo: any
  isOpenEditPage: boolean
  setIsOpenEditPage: (boolean) => void
  isSaved: boolean
  setIsSaved: (boolean) => void
  updateIconStatus: any
  changeState: (e, id) => void
  index: number
}

export const SettingTopPage: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [basicInfo, setBasicInfo] = useState<any>()
  const [isOpenEditPage, setIsOpenEditPage] = useState<boolean>(false)
  const [isSaved, setIsSaved] = useState<boolean>(false)
  // FIXME: It doesn't have to be State because there is an unused part
  // eslint-disable-next-line no-unused-vars
  const [updateIconStatus, _setupdateIconStatus] = useState<any>(false)
  // eslint-disable-next-line no-unused-vars
  const [changeState, _setChangeState] = useState<any>(false)
  // eslint-disable-next-line no-unused-vars
  const [index, _setIndex] = useState<any>(false)
  // eslint-disable-next-line no-unused-vars
  const [iconStatus, _setIconStatus] = useState<any>(false)

  // Basic information acquisition processing
  const _fetchFacilityBasicInfo = async () => {
    await fetchFacilityBasicInfo()
      .then(res => {
        if (res !== undefined) {
          setBasicInfo(res?.basicInfo)
          if (isSaved) {
            setIsOpenEditPage(!isOpenEditPage)
            setIsLoading(false)
          }
        }
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  useEffect(() => {
    if (account && account?.hotel) {
      _fetchFacilityBasicInfo()
    }
  }, [account])

  useEffect(() => {
    // Reacquire after saving on the edit screen
    if (isSaved && account) {
      _fetchFacilityBasicInfo()
      setIsSaved(!isSaved)
    }
  }, [isSaved])

  return (
    <UI
      isLoading={isLoading}
      basicInfo={basicInfo}
      isOpenEditPage={isOpenEditPage}
      setIsOpenEditPage={setIsOpenEditPage}
      isSaved={isSaved}
      setIsSaved={setIsSaved}
      updateIconStatus={updateIconStatus}
      changeState={changeState}
      index={index}
      iconStatus={iconStatus}
    />
  )
}

export const UI: React.FC<ContainerProps & Props> = ({
  isLoading,
  basicInfo,
  isOpenEditPage,
  setIsOpenEditPage,
  isSaved,
  setIsSaved,
  updateIconStatus,
  changeState,
  iconStatus,
}) => {
  const { t } = useTranslation()
  // FIXME: It doesn't have to be State because there is an unused part
  // eslint-disable-next-line no-unused-vars
  const [index, _setIndex] = useState<number>(1)
  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header title={t('Setting')} isPublicPageLink />
        <div className="mainContainer" css={settingContainerStyle}>
          <SettingSideMenu />
          <div css={containerBoxStyle}>
            {isOpenEditPage === false && (
              <>
                <div css={settingMainContainerStyle}>
                  <IconSetting updateIconStatus={updateIconStatus} changeState={changeState} index={index} iconStatus={iconStatus} />
                </div>
                <div css={settingMainContainerStyle}>
                  <TopImage basicInfo={basicInfo} isOpenEditPage={isOpenEditPage} setIsOpenEditPage={setIsOpenEditPage} />
                </div>
              </>
            )}
            {isOpenEditPage && (
              <div css={settingMainContainerEditPageStyle}>
                <TopImageEdit
                  basicInfo={basicInfo}
                  isOpenEditPage={isOpenEditPage}
                  setIsOpenEditPage={setIsOpenEditPage}
                  isSaved={isSaved}
                  setIsSaved={setIsSaved}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const settingContainerStyle = css(mainContainerStyle, {
  display: 'flex',
  padding: 0,
})

const settingMainContainerStyle = css({
  width: 'calc(100% - 180px)',
  padding: '24px 24px 0px 24px',
  minWidth: 1016,
})

const settingMainContainerEditPageStyle = css({
  width: 'calc(100% - 180px)',
  padding: 0,
  minWidth: 1016,
})

const containerBoxStyle = css({})
