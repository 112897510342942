import React, { useContext, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms'
import { ButtonType } from '@/components/atoms/button'
import { CleaningManagerContext } from './room'
import { fetchListRoomAsPdf } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { BulkChangeStatusModal } from '../bulk-change-status-modal'

type HeaderListCleaningProps = {
  collapsedTracking: object
  setCollapsedTracking: (arg0: any) => void
}

export const HeaderListCleaning = ({ collapsedTracking, setCollapsedTracking }: HeaderListCleaningProps) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { selectedDate, setIsLoading } = useContext(CleaningManagerContext)
  const [isOpenBulkChangeStatusModal, setIsOpenBulkChangeStatusModal] = useState(false)

  const isOpenedAllFloor = useMemo(() => {
    if (Object.keys(collapsedTracking).length) {
      const openedFloors = Object.values(collapsedTracking).filter(item => item === false)

      return openedFloors.length === Object.keys(collapsedTracking).length
    } else {
      return false
    }
  }, [collapsedTracking])

  const handleOpenAllFloor = () => {
    setCollapsedTracking(
      Object.keys(collapsedTracking).reduce(function (result, item) {
        result[item] = isOpenedAllFloor
        return result
      }, {}),
    )
  }

  const downloadReport = async () => {
    try {
      setIsLoading && setIsLoading(true)
      const url = await fetchListRoomAsPdf(selectedDate.format('YYYY-MM-DD'))
      window.open(url, '_blank')
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading && setIsLoading(false)
    }
  }

  return (
    <>
      <div css={headerWrapperStyle}>
        <div css={headerCleaningListStyle}>
          <img
            src={require(`@/static/images/cleaning_manager/icon_openall_arrow${isOpenedAllFloor ? '_up' : '_down'}.svg`)}
            css={dropAllButtonStyle}
          />
          <span onClick={() => handleOpenAllFloor()} css={titleDropStyle}>
            {isOpenedAllFloor ? t('cleaningManager.Hide All Rooms') : t('cleaningManager.Show All Rooms')}
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Button
            buttonType={ButtonType.Secondary}
            width={82}
            height={32}
            fontSize={12}
            onClick={() => setIsOpenBulkChangeStatusModal(true)}
          >
            {t('Bulk change')}
          </Button>
          <Button
            buttonType={ButtonType.NonStyle}
            width={130}
            height={32}
            fontSize={12}
            icon={require('@/static/images/csv.svg')}
            onClick={() => downloadReport()}
          >
            {t('Download')}
          </Button>
        </div>
      </div>
      {isOpenBulkChangeStatusModal && <BulkChangeStatusModal onClose={() => setIsOpenBulkChangeStatusModal(false)} />}
    </>
  )
}

const headerWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 24px',
  position: 'sticky',
  zIndex: 99,
  top: 0,
  backgroundColor: '#fff',
  borderRadius: '5px 5px 0 0',
})

const dropAllButtonStyle = css({
  marginRight: '8px',
})

const titleDropStyle = css({
  fontSize: 12,
  lineHeight: '18px',
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  textDecoration: 'underline',
  color: '#676767',
  '&:hover': {
    cursor: 'pointer',
  },
})

const headerCleaningListStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '14px 0',
})
