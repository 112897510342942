import React, { useState, useContext } from 'react'
import { css } from '@emotion/core'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Button } from '@/components/atoms/button'
import { sectionHeaderStyle, sectionStyle } from '@/components/pages/dashboard/_index/style'

// libs
import { setHasAccountingInterlockingPlugin } from '@/libs/plugins'
import { downloadRevClCsv } from '@/libs/csv-download'

// contexts
import { AccountContext } from '@/contexts/account'

import { fetchOldComExportRevCsv } from '@/apis/aipass'

type DetailHeaderProps = {
  salesTotalDetail: any
  mode: 'month' | 'daily'
  downloadSaleDetails: () => void
  downloadPaymentList: () => void
}

export const DetailHeader: React.FC<DetailHeaderProps> = ({ salesTotalDetail, mode, downloadSaleDetails, downloadPaymentList }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { search } = useLocation()
  const { plugins } = useContext<any>(AccountContext)
  const hasAccountingInterlockingPlugin = setHasAccountingInterlockingPlugin(plugins)

  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)

  const isUnpaid = salesTotalDetail?.totalUnpaid > 0
  const unPaid = salesTotalDetail?.totalUnpaid

  const goToSalesDetailList = () => {
    let beginDate = ''
    let endDate = ''
    let salesDailyId = ''
    if (mode === 'daily') {
      beginDate = salesTotalDetail?.salesDate
      endDate = salesTotalDetail?.salesDate
      salesDailyId = salesTotalDetail?.salesManagerDailyId
    }
    if (mode === 'month') {
      beginDate = dayjs(salesTotalDetail?.salesMonth)
        .startOf('month')
        .format('YYYY-MM-DD')
      endDate = dayjs(salesTotalDetail?.salesMonth)
        .endOf('month')
        .format('YYYY-MM-DD')
    }
    history.push({
      pathname: '/sales-manager/detail-list',
      search: `?beginDate=${beginDate}&endDate=${endDate}${salesDailyId ? `&salesDailyId=${salesDailyId}` : ''}`,
      state: { from: '/sales-manager/detail', search },
    })
  }

  const _exportRevAndCl = async () => {
    try {
      const resultREV = await fetchOldComExportRevCsv(salesTotalDetail?.salesDate, 'REV')
      downloadRevClCsv(resultREV, `${dayjs(salesTotalDetail?.salesDate).format('YYYYMMDD')}_REV.csv`)

      const resultCL = await fetchOldComExportRevCsv(salesTotalDetail?.salesDate, 'CL')
      downloadRevClCsv(resultCL, `${dayjs(salesTotalDetail?.salesDate).format('YYYYMMDD')}_CL.csv`)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div css={detailSectionStyle}>
      <div css={detailHeaderStyle}>
        <div css={detailHeaderDateWrapperStyle}>
          <p css={detailHeaderDateStyle}>{mode === 'daily' ? t('Daily closing state') : t('Monthly closing state')}</p>
          {!!salesTotalDetail?.isConfirm && <p css={isConfirmStyle}>{t('Confirmed')}</p>}
        </div>
        <div css={rightContentHeaderWrapperStyle}>
          <div css={rightButtonWrapperStyle}>
            <Button
              buttonType={4}
              width={156}
              height={32}
              fontSize={12}
              marginRight={16}
              icon={require('@/static/images/csv.svg')}
              onClick={() => setIsDropDownOpen(!isDropDownOpen)}
            >
              {t('Download')} <img src={require('@/static/images/select_gray.svg')} css={dropDownIcon} />
            </Button>
            {isDropDownOpen && (
              <div css={dropDownStyle}>
                <ul>
                  <li
                    css={dropDownContentStyle}
                    onClick={() => {
                      downloadSaleDetails()
                      setIsDropDownOpen(!isDropDownOpen)
                    }}
                  >
                    <p css={dropDownTitleStyle}>{t('SaleDetail')}</p>
                  </li>
                  <li
                    css={dropDownContentStyle}
                    onClick={() => {
                      downloadPaymentList()
                      setIsDropDownOpen(false)
                    }}
                  >
                    <p css={dropDownTitleStyle}>{t('Payment list')}</p>
                  </li>
                  {hasAccountingInterlockingPlugin && (
                    <li
                      css={!salesTotalDetail?.isConfirm ? disabledButton : dropDownContentStyle}
                      onClick={() => {
                        _exportRevAndCl()
                        setIsDropDownOpen(false)
                      }}
                    >
                      <p css={!salesTotalDetail?.isConfirm ? disabledTitle : dropDownTitleStyle}>{t('REV CL')}</p>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
          <Button width={74} height={32} buttonType={8} fontSize={12} onClick={goToSalesDetailList}>
            {t('Detail')}
          </Button>
        </div>
      </div>
      <ul css={contentsWrapperStyle}>
        <li css={contentsStyle}>
          <div css={contentsTitleStyle}>{t('Actual sales')}</div>
          <div css={contentsMoneyStyle}>¥{salesTotalDetail?.totalSales ? Number(salesTotalDetail?.totalSales).toLocaleString() : 0}</div>
        </li>
        <li css={contentsStyle}>
          <div css={isUnpaid ? contentsReceivableTitleStyle : contentsTitleStyle}>{t('Amount to be collected')}</div>
          <div css={isUnpaid ? contentsReceivableMoneyStyle : contentsMoneyStyle}>¥{unPaid ? unPaid.toLocaleString() : 0}</div>
        </li>
        <li css={contentsStyle}>
          <div css={contentsTitleStyle}>{t('Balance carried forward')}</div>
          <div css={contentsMoneyStyle}>
            ¥{salesTotalDetail?.totalCarriedForward ? Number(salesTotalDetail?.totalCarriedForward).toLocaleString() : 0}
          </div>
        </li>
      </ul>
    </div>
  )
}

const detailSectionStyle = css(sectionStyle, {
  width: '100%',
  background: '#FFFFFF',
  marginBottom: 16,
})

const detailHeaderStyle = css(sectionHeaderStyle, {
  height: 50,
  fontSize: 18,
  justifyContent: 'space-between',
  margin: '0 32px',
  padding: 0,
})

const detailHeaderDateWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
})

const detailHeaderDateStyle = css({
  fontSize: 18,
})

const isConfirmStyle = css({
  width: 75,
  height: 25,
  marginLeft: 24,
  border: '1px solid #676767',
  borderRadius: 5,
  color: '#676767',
  fontSize: 12,
  lineHeight: '25px',
  textAlign: 'center',
  letterSpacing: 1.8,
})

const contentsWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  borderTop: '1px solid #F2F2F2',
})

const contentsStyle = css({
  height: 100,
  width: '33.3%',
  padding: '0 32px',
  borderRight: '1px solid #F2F2F2',
  ':last-child': {
    borderRight: 'none',
  },
})

const contentsTitleStyle = css({
  fontSize: 16,
  letterSpacing: 2.4,
  fontWeight: 'bold',
  margin: '24px 0 0 0',
})
const contentsMoneyStyle = css({
  fontSize: 18,
  letterSpacing: 2.7,
  fontWeight: 'bold',
  margin: '16px 0 0 auto',
  width: 'max-content',
})
const contentsReceivableTitleStyle = css(contentsTitleStyle, {
  color: '#F2A40B',
})
const contentsReceivableMoneyStyle = css(contentsMoneyStyle, {
  color: '#F2A40B',
})

const rightContentHeaderWrapperStyle = css({
  display: 'flex',
})

const rightButtonWrapperStyle = css({
  display: 'flex',
  position: 'relative',
})

const dropDownIcon = css({
  marginLeft: 8,
})

const dropDownStyle = css({
  width: 156,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 40,
  right: 16,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 19px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const disabledButton = css(dropDownContentStyle, {
  pointerEvents: 'none',
})

const dropDownTitleStyle = css({
  fontSize: 16,
  color: '#272727',
  letterSpacing: '1.6px',
})

const disabledTitle = css(dropDownTitleStyle, {
  color: '#cccccc',
})
