/**
 * ConvertToSeparatedName
 *
 * Convert the full name obtained from the backend into split names according to nationality.
 *
 * example:
 *  ja
 *  fullName:山田 太郎,lastName:山田,firstName:太郎
 *
 *  en
 * fullName:First Middle Last,firstName:First Middle,lastName:Last
 */
type ConvertToSeparatedNameProps = {
    name: string
    isJapanese?: boolean
}

export const convertToSeparatedName = ({name, isJapanese = true}: ConvertToSeparatedNameProps)
    : string[] => {
    const parseFullName = name?.replace('　', ' ').split(' ')
    let firstName
    let lastName

    if (isJapanese) {
        lastName = parseFullName?.slice(0, parseFullName.length - 1).join(' ') || ''
        firstName = parseFullName?.slice(-1).join(' ') || ''
    } else {
        lastName = parseFullName?.slice(-1).join(' ') || ''
        firstName = parseFullName?.slice(0, parseFullName.length - 1).join(' ') || ''
    }
    return [firstName, lastName]
}

