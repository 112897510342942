import { CategoryType, NotificationType, SalesHourType, TagType } from '../mobile-request'
import { ButtonRoleType } from './design'

export const FormatType = {
  Checkin: 'checkin',
  Checkout: 'checkout',
  Free: 'free',
  Pulldown: 'pulldown',
  Map: 'map',
  Button1: 'button1',
  Button2: 'button2',
  News: 'news',
  Request: 'request',
  Coupon: 'coupon',
  Image: 'image',
  Space: 'space',
} as const
export type FormatType = (typeof FormatType)[keyof typeof FormatType]

export const formatTypeLabel: Record<FormatType, string> = {
  [FormatType.Checkin]: 'Checkin',
  [FormatType.Checkout]: 'Checkout',
  [FormatType.Free]: 'FreeText',
  [FormatType.Pulldown]: 'Pulldown',
  [FormatType.Button1]: 'Button1',
  [FormatType.Button2]: 'Button2',
  [FormatType.Map]: 'MAP',
  [FormatType.Request]: 'GuestAppSetting.Request',
  [FormatType.Coupon]: 'Coupon',
  [FormatType.News]: 'News',
  [FormatType.Image]: 'ImageButton',
  [FormatType.Space]: 'Space',
}

export type AssetType = {
  id: string
  title: string
  isVisible: boolean
  isFixed: boolean
  formatType: FormatType
}

export type FreeDetailType = {
  id: string
  title: string
  description: string
  rawDescription: string
  imagePath: string
  updatedAt: string
}

export type NewsDetailType = {
  id: string
  title: string
  description: string
  rawDescription: string
  updatedAt: string
}

export type PulldownDetailType = {
  id: string
  description: string
  rawDescription: string
}

export type MapDetailType = {
  id: string
  title: string
  category: MapCategoryType
  subCategory: string
  imagePath: string
  telephone: string
  address: string
  siteUrl: string
  route: string
  comment: string
  businessHours: MapBusinessHourType[]
  isFavorite: boolean
  lat: string
  lng: string
  placeId: string
}

export type MapCategoryType = {
  id: string
  title: string
  imagePath: string
}

export type MapBusinessHourType = {
  weekday: string
  startTime: string
  endTime: string | undefined
}

export type ButtonDetailType = {
  id: string
  name: string
  link: string
  file: string
  fileName: string
  type: 'url' | 'image'
  designType: ButtonRoleType
}

export type ImageDetailType = {
  id: string
  title: string
  description: string
  rawDescription: string
  imagePath: string
  destinationUrl: string
  destinationType: 'url' | 'newPage'
  isTitleShowHome: boolean
}

export type RequestDetailType = {
  id: string
  name: string
  description: string
  imagePath: string
  isFavorite: boolean
  category: CategoryType | undefined
  tags: Array<TagType>
  salesHourId: number | undefined
  salesHourName: string | undefined
  salesDepartmentMasterId: string | undefined
  salesDepartmentMasterName: string | undefined
  salesDepartmentMasterCode: string | undefined
  salesSubjectMasterId: string | undefined
  salesSubjectMasterName: string | undefined
  salesSubjectMasterCode: string | undefined
  price: number
  stock: number
}

export const CouponScopeUsageType = {
  All: 'all',
  Login: 'login',
  CheckIn: 'checkIn',
} as const
export type CouponScopeUsageType = (typeof CouponScopeUsageType)[keyof typeof CouponScopeUsageType]

export type CouponDetailType = {
  id: string
  title: string
  facilityName: string
  imagePath: string
  description: string
  expireFrom: string
  expireTo: string
  url: string
  scopeUsage: CouponScopeUsageType
  maxNumberOfUse: number
}

export const SpacePriceType = {
  Charge: 'charge',
} as const
export type SpacePriceType = (typeof SpacePriceType)[keyof typeof SpacePriceType]
export type SpaceChargePriceType = {
  usageTime: {
    start: string | undefined
    end: string | undefined
  }
  unitPrice: number | undefined
  maxPrice: number | undefined
}
export type SpaceDetailType = {
  id: string
  title: string
  description: string
  question: string
  images: string[]
  salesHour: {
    start: string
    end: string
  }
  spacePrice:
    | {
        type: SpacePriceType
        chargePrice: SpaceChargePriceType
      }
    | undefined
  salesSubject:
    | {
        salesDepartmentId: string
        salesDepartmentCode: string
        salesDepartmentName: string
        salesSubjectMasterId: string
        salesSubjectMasterCode: string
        salesSubjectMasterName: string
        salesSubSubjectCode: string
        salesSubSubjectName: string
      }
    | undefined
  roomKeys: {
    spaceIndex: number
    keyNumber: string | undefined
    remoteDeviceId: string | undefined
  }[]
  stock: number
  stockUnit: string
  isFavorite: boolean
}
export type SpaceCreateAndUpdateRequestType = {
  title: string
  images: string[]
  description: string
  question: string
  salesHour: {
    start: string
    end: string
  }
  spacePriceType: SpacePriceType | undefined
  chargePrice:
    | {
        usageTime: {
          start: string | undefined
          end: string | undefined
        }
        price: number | undefined
        maxPrice: number | undefined
      }
    | undefined
  stock: number
  stockUnit: string
  salesSubjectMasterId: string | undefined
}

export const isFreeAsset = (asset: AssetType | undefined): asset is AssetType & { details: FreeDetailType[] } =>
  asset?.formatType === FormatType.Free
export const isPulldownAsset = (asset: AssetType | undefined): asset is AssetType & { details: PulldownDetailType[] } =>
  asset?.formatType === FormatType.Pulldown
export const isMapAsset = (asset: AssetType | undefined): asset is AssetType & { details: MapDetailType[] } =>
  asset?.formatType === FormatType.Map
export const isNewsAsset = (asset: AssetType | undefined): asset is AssetType & { details: NewsDetailType[] } =>
  asset?.formatType === FormatType.News
export const isButton1Asset = (asset: AssetType | undefined): asset is AssetType & { details: ButtonDetailType[] } =>
  asset?.formatType === FormatType.Button1
export const isButton2Asset = (asset: AssetType | undefined): asset is AssetType & { details: ButtonDetailType[] } =>
  asset?.formatType === FormatType.Button2
export const isImageAsset = (asset: AssetType | undefined): asset is AssetType & { details: ImageDetailType[] } =>
  asset?.formatType === FormatType.Image
export const isRequestAsset = (
  asset: AssetType | undefined,
): asset is AssetType & {
  details: RequestDetailType[]
  categories: CategoryType[]
  tags: TagType[]
  salesHours: SalesHourType[]
  notification: NotificationType
} => asset?.formatType === FormatType.Request
export const isCouponAsset = (asset: AssetType | undefined): asset is AssetType & { details: CouponDetailType[] } =>
  asset?.formatType === FormatType.Coupon
export const isSpaceAsset = (asset: AssetType | undefined): asset is AssetType & { details: SpaceDetailType[] } =>
  asset?.formatType === FormatType.Space

export type SpaceAsset = AssetType & { details: SpaceDetailType[] }
