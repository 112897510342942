export const countryList = (language, setDefaultPlaceholder = true) => {
  const list: { name: string; value: string }[] = []
  if (setDefaultPlaceholder) {
    list.push({
      name: language === 'ja' ? '選択してください' : 'Please select',
      value: '',
    })
  }
  Object.keys(nationality).forEach(key => {
    list.push({
      name: nationality[key][language === 'ja' ? 'ja' : 'en'],
      value: key,
    })
  })

  if (language === 'ja') return list

  return list.sort((a, b) => a.name.localeCompare(b.name))
}

export const nationality = {
  ISL: {
    ja: 'アイスランド',
    en: 'Iceland',
  },
  IRL: {
    ja: 'アイルランド',
    en: 'Ireland',
  },
  AZE: {
    ja: 'アゼルバイジャン',
    en: 'Azerbaijan',
  },
  AFG: {
    ja: 'アフガニスタン',
    en: 'Afghanistan',
  },
  USA: {
    ja: 'アメリカ合衆国',
    en: 'United States',
  },
  VIR: {
    ja: 'アメリカ領ヴァージン諸島',
    en: 'Virgin Islands',
  },
  ASM: {
    ja: 'アメリカ領サモア',
    en: 'American Samoa',
  },
  ARE: {
    ja: 'アラブ首長国連邦',
    en: 'United Arab Emirates',
  },
  DZA: {
    ja: 'アルジェリア',
    en: 'Algeria',
  },
  ARG: {
    ja: 'アルゼンチン',
    en: 'Argentina',
  },
  ABW: {
    ja: 'アルバ',
    en: 'Aruba',
  },
  ALB: {
    ja: 'アルバニア',
    en: 'Albania',
  },
  ARM: {
    ja: 'アルメニア',
    en: 'Armenia',
  },
  AIA: {
    ja: 'アンギラ',
    en: 'Anguilla',
  },
  AGO: {
    ja: 'アンゴラ',
    en: 'Angola',
  },
  ATG: {
    ja: 'アンティグア・バーブーダ',
    en: 'Antigua and Barbuda',
  },
  AND: {
    ja: 'アンドラ',
    en: 'Andorra',
  },
  YEM: {
    ja: 'イエメン',
    en: 'Yemen',
  },
  GBR: {
    ja: 'イギリス',
    en: 'United Kingdom',
  },
  IOT: {
    ja: 'イギリス領インド洋地域',
    en: 'British Indian Ocean Territory',
  },
  BVI: {
    ja: 'イギリス領ヴァージン諸島',
    en: 'Virgin Islands',
  },
  ISR: {
    ja: 'イスラエル',
    en: 'Israel',
  },
  ITA: {
    ja: 'イタリア',
    en: 'Italy',
  },
  IRQ: {
    ja: 'イラク',
    en: 'Iraq',
  },
  IRN: {
    ja: 'イラン|イラン・イスラム共和国',
    en: 'Iran',
  },
  IND: {
    ja: 'インド',
    en: 'India',
  },
  IDN: {
    ja: 'インドネシア',
    en: 'Indonesia',
  },
  WLF: {
    ja: 'ウォリス・フツナ',
    en: 'Wallis and Futuna',
  },
  UGA: {
    ja: 'ウガンダ',
    en: 'Uganda',
  },
  UKR: {
    ja: 'ウクライナ',
    en: 'Ukraine',
  },
  UZB: {
    ja: 'ウズベキスタン',
    en: 'Uzbekistan',
  },
  URY: {
    ja: 'ウルグアイ',
    en: 'Uruguay',
  },
  ECU: {
    ja: 'エクアドル',
    en: 'Ecuador',
  },
  EGY: {
    ja: 'エジプト',
    en: 'Egypt',
  },
  EST: {
    ja: 'エストニア',
    en: 'Estonia',
  },
  ETH: {
    ja: 'エチオピア',
    en: 'Ethiopia',
  },
  ERI: {
    ja: 'エリトリア',
    en: 'Eritrea',
  },
  SLV: {
    ja: 'エルサルバドル',
    en: 'El Salvador',
  },
  AUS: {
    ja: 'オーストラリア',
    en: 'Australia',
  },
  AUT: {
    ja: 'オーストリア',
    en: 'Austria',
  },
  ALA: {
    ja: 'オーランド諸島',
    en: 'Åland Islands',
  },
  OMN: {
    ja: 'オマーン',
    en: 'Oman',
  },
  NLD: {
    ja: 'オランダ',
    en: 'Netherlands',
  },
  GHA: {
    ja: 'ガーナ',
    en: 'Ghana',
  },
  CPV: {
    ja: 'カーボベルデ',
    en: 'Cape Verde',
  },
  GGY: {
    ja: 'ガーンジー',
    en: 'Guernsey',
  },
  GUY: {
    ja: 'ガイアナ',
    en: 'Guyana',
  },
  KAZ: {
    ja: 'カザフスタン',
    en: 'Kazakhstan',
  },
  QAT: {
    ja: 'カタール',
    en: 'Qatar',
  },
  UMI: {
    ja: '合衆国領有小離島',
    en: 'United States Minor Outlying Islands',
  },
  CAN: {
    ja: 'カナダ',
    en: 'Canada',
  },
  GAB: {
    ja: 'ガボン',
    en: 'Gabon',
  },
  CMR: {
    ja: 'カメルーン',
    en: 'Cameroon',
  },
  GMB: {
    ja: 'ガンビア',
    en: 'Gambia',
  },
  KHM: {
    ja: 'カンボジア',
    en: 'Cambodia',
  },
  MNP: {
    ja: '北マリアナ諸島',
    en: 'Northern Mariana Islands',
  },
  GIN: {
    ja: 'ギニア',
    en: 'Guinea',
  },
  GNB: {
    ja: 'ギニアビサウ',
    en: 'Guinea-Bissau',
  },
  CYP: {
    ja: 'キプロス',
    en: 'Cyprus',
  },
  CUB: {
    ja: 'キューバ',
    en: 'Cuba',
  },
  CUW: {
    ja: 'キュラソー島|キュラソー',
    en: 'Curaçao',
  },
  GRC: {
    ja: 'ギリシャ',
    en: 'Greece',
  },
  KIR: {
    ja: 'キリバス',
    en: 'Kiribati',
  },
  KGZ: {
    ja: 'キルギス',
    en: 'Kyrgyzstan',
  },
  GTM: {
    ja: 'グアテマラ',
    en: 'Guatemala',
  },
  GLP: {
    ja: 'グアドループ',
    en: 'Guadeloupe',
  },
  GUM: {
    ja: 'グアム',
    en: 'Guam',
  },
  KWT: {
    ja: 'クウェート',
    en: 'Kuwait',
  },
  COK: {
    ja: 'クック諸島',
    en: 'Cook Islands',
  },
  GRL: {
    ja: 'グリーンランド',
    en: 'Greenland',
  },
  CXR: {
    ja: 'クリスマス島 (オーストラリア)|クリスマス島',
    en: 'Christmas Island',
  },
  GEO: {
    ja: 'グルジア',
    en: 'Georgia',
  },
  GRD: {
    ja: 'グレナダ',
    en: 'Grenada',
  },
  HRV: {
    ja: 'クロアチア',
    en: 'Croatia',
  },
  CYM: {
    ja: 'ケイマン諸島',
    en: 'Cayman Islands',
  },
  KEN: {
    ja: 'ケニア',
    en: 'Kenya',
  },
  CIV: {
    ja: 'コートジボワール',
    en: "Côte d'Ivoire",
  },
  CCK: {
    ja: 'ココス諸島|ココス（キーリング）諸島',
    en: 'Cocos (Keeling) Islands',
  },
  CRI: {
    ja: 'コスタリカ',
    en: 'Costa Rica',
  },
  COM: {
    ja: 'コモロ',
    en: 'Comoros',
  },
  COL: {
    ja: 'コロンビア',
    en: 'Colombia',
  },
  COG: {
    ja: 'コンゴ共和国',
    en: 'Congo',
  },
  COD: {
    ja: 'コンゴ民主共和国',
    en: 'Congo',
  },
  SAU: {
    ja: 'サウジアラビア',
    en: 'Saudi Arabia',
  },
  SGS: {
    ja: 'サウスジョージア・サウスサンドウィッチ諸島',
    en: 'South Georgia and the South Sandwich Islands',
  },
  WSM: {
    ja: 'サモア',
    en: 'Samoa',
  },
  STP: {
    ja: 'サントメ・プリンシペ',
    en: 'Sao Tome and Principe',
  },
  BLM: {
    ja: 'サン・バルテルミー島|サン・バルテルミー',
    en: 'Saint Barthélemy',
  },
  ZMB: {
    ja: 'ザンビア',
    en: 'Zambia',
  },
  SPM: {
    ja: 'サンピエール島・ミクロン島',
    en: 'Saint Pierre and Miquelon',
  },
  SMR: {
    ja: 'サンマリノ',
    en: 'San Marino',
  },
  MAF: {
    ja: 'サン・マルタン (西インド諸島)|サン・マルタン（フランス領）',
    en: 'Saint Martin (French part)',
  },
  SLE: {
    ja: 'シエラレオネ',
    en: 'Sierra Leone',
  },
  DJI: {
    ja: 'ジブチ',
    en: 'Djibouti',
  },
  GIB: {
    ja: 'ジブラルタル',
    en: 'Gibraltar',
  },
  JEY: {
    ja: 'ジャージー',
    en: 'Jersey',
  },
  JAM: {
    ja: 'ジャマイカ',
    en: 'Jamaica',
  },
  SYR: {
    ja: 'シリア|シリア・アラブ共和国',
    en: 'Syrian Arab Republic',
  },
  SGP: {
    ja: 'シンガポール',
    en: 'Singapore',
  },
  SXM: {
    ja: 'シント・マールテン|シント・マールテン（オランダ領）',
    en: 'Sint Maarten (Dutch part)',
  },
  ZWE: {
    ja: 'ジンバブエ',
    en: 'Zimbabwe',
  },
  CHE: {
    ja: 'スイス',
    en: 'Switzerland',
  },
  SWE: {
    ja: 'スウェーデン',
    en: 'Sweden',
  },
  SDN: {
    ja: 'スーダン',
    en: 'Sudan',
  },
  SJM: {
    ja: 'スヴァールバル諸島およびヤンマイエン島',
    en: 'Svalbard and Jan Mayen',
  },
  ESP: {
    ja: 'スペイン',
    en: 'Spain',
  },
  SUR: {
    ja: 'スリナム',
    en: 'Suriname',
  },
  LKA: {
    ja: 'スリランカ',
    en: 'Sri Lanka',
  },
  SVK: {
    ja: 'スロバキア',
    en: 'Slovakia',
  },
  SVN: {
    ja: 'スロベニア',
    en: 'Slovenia',
  },
  SWZ: {
    ja: 'スワジランド',
    en: 'Swaziland',
  },
  SYC: {
    ja: 'セーシェル',
    en: 'Seychelles',
  },
  GNQ: {
    ja: '赤道ギニア',
    en: 'Equatorial Guinea',
  },
  SEN: {
    ja: 'セネガル',
    en: 'Senegal',
  },
  SRB: {
    ja: 'セルビア',
    en: 'Serbia',
  },
  KNA: {
    ja: 'セントクリストファー・ネイビス',
    en: 'Saint Kitts and Nevis',
  },
  VCT: {
    ja: 'セントビンセント・グレナディーン|セントビンセントおよびグレナディーン諸島',
    en: 'Saint Vincent and the Grenadines',
  },
  SHN: {
    ja: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
    en: 'Saint Helena',
  },
  LCA: {
    ja: 'セントルシア',
    en: 'Saint Lucia',
  },
  SOM: {
    ja: 'ソマリア',
    en: 'Somalia',
  },
  SLB: {
    ja: 'ソロモン諸島',
    en: 'Solomon Islands',
  },
  TCA: {
    ja: 'タークス・カイコス諸島',
    en: 'Turks and Caicos Islands',
  },
  THA: {
    ja: 'タイ王国|タイ',
    en: 'Thailand',
  },
  KOR: {
    ja: '大韓民国',
    en: 'Korea',
  },
  TWN: {
    ja: '台湾',
    en: 'Taiwan',
  },
  TJK: {
    ja: 'タジキスタン',
    en: 'Tajikistan',
  },
  TZA: {
    ja: 'タンザニア',
    en: 'Tanzania',
  },
  CZE: {
    ja: 'チェコ',
    en: 'Czech Republic',
  },
  TCD: {
    ja: 'チャド',
    en: 'Chad',
  },
  CAF: {
    ja: '中央アフリカ共和国',
    en: 'Central African Republic',
  },
  CHN: {
    ja: '中華人民共和国|中国',
    en: 'China',
  },
  TUN: {
    ja: 'チュニジア',
    en: 'Tunisia',
  },
  PRK: {
    ja: '朝鮮民主主義人民共和国',
    en: 'Korea',
  },
  CHL: {
    ja: 'チリ',
    en: 'Chile',
  },
  TUV: {
    ja: 'ツバル',
    en: 'Tuvalu',
  },
  DNK: {
    ja: 'デンマーク',
    en: 'Denmark',
  },
  DEU: {
    ja: 'ドイツ',
    en: 'Germany',
  },
  TGO: {
    ja: 'トーゴ',
    en: 'Togo',
  },
  TKL: {
    ja: 'トケラウ',
    en: 'Tokelau',
  },
  DOM: {
    ja: 'ドミニカ共和国',
    en: 'Dominican Republic',
  },
  DMA: {
    ja: 'ドミニカ国',
    en: 'Dominica',
  },
  TTO: {
    ja: 'トリニダード・トバゴ',
    en: 'Trinidad and Tobago',
  },
  TKM: {
    ja: 'トルクメニスタン',
    en: 'Turkmenistan',
  },
  TUR: {
    ja: 'トルコ',
    en: 'Turkey',
  },
  TON: {
    ja: 'トンガ',
    en: 'Tonga',
  },
  NGA: {
    ja: 'ナイジェリア',
    en: 'Nigeria',
  },
  NRU: {
    ja: 'ナウル',
    en: 'Nauru',
  },
  NAM: {
    ja: 'ナミビア',
    en: 'Namibia',
  },
  ATA: {
    ja: '南極',
    en: 'Antarctica',
  },
  NIU: {
    ja: 'ニウエ',
    en: 'Niue',
  },
  NIC: {
    ja: 'ニカラグア',
    en: 'Nicaragua',
  },
  NER: {
    ja: 'ニジェール',
    en: 'Niger',
  },
  JPN: {
    ja: '日本',
    en: 'Japan',
  },
  ESH: {
    ja: '西サハラ',
    en: 'Western Sahara',
  },
  NCL: {
    ja: 'ニューカレドニア',
    en: 'New Caledonia',
  },
  NZL: {
    ja: 'ニュージーランド',
    en: 'New Zealand',
  },
  NPL: {
    ja: 'ネパール',
    en: 'Nepal',
  },
  NFK: {
    ja: 'ノーフォーク島',
    en: 'Norfolk Island',
  },
  NOR: {
    ja: 'ノルウェー',
    en: 'Norway',
  },
  HMD: {
    ja: 'ハード島とマクドナルド諸島',
    en: 'Heard Island and McDonald Islands',
  },
  BHR: {
    ja: 'バーレーン',
    en: 'Bahrain',
  },
  HTI: {
    ja: 'ハイチ',
    en: 'Haiti',
  },
  PAK: {
    ja: 'パキスタン',
    en: 'Pakistan',
  },
  VAT: {
    ja: 'バチカン|バチカン市国',
    en: 'Holy See (Vatican City State)',
  },
  PAN: {
    ja: 'パナマ',
    en: 'Panama',
  },
  VUT: {
    ja: 'バヌアツ',
    en: 'Vanuatu',
  },
  BHS: {
    ja: 'バハマ',
    en: 'Bahamas',
  },
  PNG: {
    ja: 'パプアニューギニア',
    en: 'Papua New Guinea',
  },
  BMU: {
    ja: 'バミューダ諸島|バミューダ',
    en: 'Bermuda',
  },
  PLW: {
    ja: 'パラオ',
    en: 'Palau',
  },
  PRY: {
    ja: 'パラグアイ',
    en: 'Paraguay',
  },
  BRB: {
    ja: 'バルバドス',
    en: 'Barbados',
  },
  PSE: {
    ja: 'パレスチナ',
    en: 'Palestinian Territory',
  },
  HUN: {
    ja: 'ハンガリー',
    en: 'Hungary',
  },
  BGD: {
    ja: 'バングラデシュ',
    en: 'Bangladesh',
  },
  TLS: {
    ja: '東ティモール',
    en: 'Timor-Leste',
  },
  PCN: {
    ja: 'ピトケアン諸島|ピトケアン',
    en: 'Pitcairn',
  },
  FJI: {
    ja: 'フィジー',
    en: 'Fiji',
  },
  PHL: {
    ja: 'フィリピン',
    en: 'Philippines',
  },
  FIN: {
    ja: 'フィンランド',
    en: 'Finland',
  },
  BTN: {
    ja: 'ブータン',
    en: 'Bhutan',
  },
  BVT: {
    ja: 'ブーベ島',
    en: 'Bouvet Island',
  },
  PRI: {
    ja: 'プエルトリコ',
    en: 'Puerto Rico',
  },
  FRO: {
    ja: 'フェロー諸島',
    en: 'Faroe Islands',
  },
  FLK: {
    ja: 'フォークランド諸島|フォークランド（マルビナス）諸島',
    en: 'Falkland Islands (Malvinas)',
  },
  BRA: {
    ja: 'ブラジル',
    en: 'Brazil',
  },
  FRA: {
    ja: 'フランス',
    en: 'France',
  },
  GUF: {
    ja: 'フランス領ギアナ',
    en: 'French Guiana',
  },
  PYF: {
    ja: 'フランス領ポリネシア',
    en: 'French Polynesia',
  },
  ATF: {
    ja: 'フランス領南方・南極地域',
    en: 'French Southern Territories',
  },
  BGR: {
    ja: 'ブルガリア',
    en: 'Bulgaria',
  },
  BFA: {
    ja: 'ブルキナファソ',
    en: 'Burkina Faso',
  },
  BRN: {
    ja: 'ブルネイ|ブルネイ・ダルサラーム',
    en: 'Brunei Darussalam',
  },
  BDI: {
    ja: 'ブルンジ',
    en: 'Burundi',
  },
  VNM: {
    ja: 'ベトナム',
    en: 'Viet Nam',
  },
  BEN: {
    ja: 'ベナン',
    en: 'Benin',
  },
  VEN: {
    ja: 'ベネズエラ|ベネズエラ・ボリバル共和国',
    en: 'Venezuela',
  },
  BLR: {
    ja: 'ベラルーシ',
    en: 'Belarus',
  },
  BLZ: {
    ja: 'ベリーズ',
    en: 'Belize',
  },
  PER: {
    ja: 'ペルー',
    en: 'Peru',
  },
  BEL: {
    ja: 'ベルギー',
    en: 'Belgium',
  },
  POL: {
    ja: 'ポーランド',
    en: 'Poland',
  },
  BIH: {
    ja: 'ボスニア・ヘルツェゴビナ',
    en: 'Bosnia and Herzegovina',
  },
  BWA: {
    ja: 'ボツワナ',
    en: 'Botswana',
  },
  BES: {
    ja: 'BES諸島|ボネール、シント・ユースタティウスおよびサバ',
    en: 'Bonaire',
  },
  BOL: {
    ja: 'ボリビア|ボリビア多民族国',
    en: 'Bolivia',
  },
  PRT: {
    ja: 'ポルトガル',
    en: 'Portugal',
  },
  HKG: {
    ja: '香港',
    en: 'Hong Kong',
  },
  HND: {
    ja: 'ホンジュラス',
    en: 'Honduras',
  },
  MHL: {
    ja: 'マーシャル諸島',
    en: 'Marshall Islands',
  },
  MAC: {
    ja: 'マカオ',
    en: 'Macao',
  },
  MKD: {
    ja: 'マケドニア共和国|マケドニア旧ユーゴスラビア共和国',
    en: 'Macedonia',
  },
  MDG: {
    ja: 'マダガスカル',
    en: 'Madagascar',
  },
  MYT: {
    ja: 'マヨット',
    en: 'Mayotte',
  },
  MWI: {
    ja: 'マラウイ',
    en: 'Malawi',
  },
  MLI: {
    ja: 'マリ共和国|マリ',
    en: 'Mali',
  },
  MLT: {
    ja: 'マルタ',
    en: 'Malta',
  },
  MTQ: {
    ja: 'マルティニーク',
    en: 'Martinique',
  },
  MYS: {
    ja: 'マレーシア',
    en: 'Malaysia',
  },
  IMN: {
    ja: 'マン島',
    en: 'Isle of Man',
  },
  FSM: {
    ja: 'ミクロネシア連邦',
    en: 'Micronesia',
  },
  ZAF: {
    ja: '南アフリカ共和国|南アフリカ',
    en: 'South Africa',
  },
  SSD: {
    ja: '南スーダン',
    en: 'South Sudan',
  },
  MMR: {
    ja: 'ミャンマー',
    en: 'Myanmar',
  },
  MEX: {
    ja: 'メキシコ',
    en: 'Mexico',
  },
  MUS: {
    ja: 'モーリシャス',
    en: 'Mauritius',
  },
  MRT: {
    ja: 'モーリタニア',
    en: 'Mauritania',
  },
  MOZ: {
    ja: 'モザンビーク',
    en: 'Mozambique',
  },
  MCO: {
    ja: 'モナコ',
    en: 'Monaco',
  },
  MDV: {
    ja: 'モルディブ',
    en: 'Maldives',
  },
  MDA: {
    ja: 'モルドバ|モルドバ共和国',
    en: 'Moldova',
  },
  MAR: {
    ja: 'モロッコ',
    en: 'Morocco',
  },
  MNG: {
    ja: 'モンゴル国|モンゴル',
    en: 'Mongolia',
  },
  MNE: {
    ja: 'モンテネグロ',
    en: 'Montenegro',
  },
  MSR: {
    ja: 'モントセラト',
    en: 'Montserrat',
  },
  JOR: {
    ja: 'ヨルダン',
    en: 'Jordan',
  },
  LAO: {
    ja: 'ラオス|ラオス人民民主共和国',
    en: "Lao People's Democratic Republic",
  },
  LVA: {
    ja: 'ラトビア',
    en: 'Latvia',
  },
  LTU: {
    ja: 'リトアニア',
    en: 'Lithuania',
  },
  LBY: {
    ja: 'リビア',
    en: 'Libya',
  },
  LIE: {
    ja: 'リヒテンシュタイン',
    en: 'Liechtenstein',
  },
  LBR: {
    ja: 'リベリア',
    en: 'Liberia',
  },
  ROU: {
    ja: 'ルーマニア',
    en: 'Romania',
  },
  LUX: {
    ja: 'ルクセンブルク',
    en: 'Luxembourg',
  },
  RWA: {
    ja: 'ルワンダ',
    en: 'Rwanda',
  },
  LSO: {
    ja: 'レソト',
    en: 'Lesotho',
  },
  LBN: {
    ja: 'レバノン',
    en: 'Lebanon',
  },
  REU: {
    ja: 'レユニオン',
    en: 'Réunion',
  },
  RUS: {
    ja: 'ロシア|ロシア連邦',
    en: 'Russian Federation',
  },
}
