import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { css } from '@emotion/core'

type LoadingProps = {
  isLoading: boolean
}

export const Loading: React.FC<LoadingProps> = ({ isLoading }) => {
  const animationData = require('@/static/images/json/loading.json')
  const [height, setHeight] = useState<any>(0)

  useEffect(() => {
    setHeight(document.getElementById('root')?.clientHeight)
  }, [document.getElementById('root')?.clientHeight])

  return (
    <React.Fragment>
      {isLoading && (
        <div css={loadinOverlayStyle} style={{ height: height * 1.4 }}>
          <div css={loadingIconStyle}>
            <Lottie height="80px" width="80px" options={{ animationData: animationData, autoplay: true, loop: true }} />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

const loadinOverlayStyle = css({
  position: 'fixed',
  zIndex: 100,
  width: '100%',
  height: 798,
  background: 'rgba(255, 255, 255, 0.8)',
  opacity: 0.7,
  alignItems: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '-webkit-transform': 'translate(-50%, -50%)',
  '-ms-transform': 'translate(-50%, -50%)',
})

const loadingIconStyle = css({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '-webkit-transform': 'translate(-50%, -50%)',
  '-ms-transform': 'translate(-50%, -50%)',
})
