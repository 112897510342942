import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { AssetType, CouponDetailType, CouponScopeUsageType } from '@/models/guest-app/asset'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'
import { InputField } from '@/components/molecules/input-field'
import { InputImage } from '@/components/molecules/settings/input-image'
import { createAssetDetail, updateAssetDetail } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { TextareaField } from '@/components/molecules/textarea-field'
import { DateRangeField } from '@/components/molecules/date-range-field'
import { Select, Option } from '@/components/atoms/select'

type Props = {
  asset: AssetType
  detail: CouponDetailType | null
  onCancel: () => void
  onSave: () => void
}

export type AssetCouponDetailFormValue = {
  title: string
  facilityName: string
  imagePath: string
  description: string
  expireFrom: string
  expireTo: string
  url: string
  scopeUsage: CouponScopeUsageType
  maxNumberOfUse: number | null
}

export const CouponDetailModal: React.FC<Props> = ({ asset, detail, onCancel, onSave: parentSave }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    setValue,
  } = useForm<AssetCouponDetailFormValue>({
    defaultValues: {
      title: detail?.title || '',
      facilityName: detail?.facilityName || '',
      imagePath: detail?.imagePath || '',
      description: detail?.description || '',
      expireFrom: detail?.expireFrom || '',
      expireTo: detail?.expireTo || '',
      url: detail?.url || '',
      scopeUsage: detail?.scopeUsage || CouponScopeUsageType.All,
      maxNumberOfUse: detail?.maxNumberOfUse || null,
    },
  })
  const scopeUsageWatcher = useWatch({ control, name: 'scopeUsage' })
  const expireFromWatcher = useWatch({ control, name: 'expireFrom' })
  const expireToWatcher = useWatch({ control, name: 'expireTo' })
  const isExpireEmpty = useMemo(
    () => isSubmitted && (!expireFromWatcher || !expireToWatcher),
    [isSubmitted, expireFromWatcher, expireToWatcher],
  )

  const { doReload, setIsLoading } = useContext(AssetDetailContext)
  const [isShowCropModal, setIsShowCropModal] = useState<boolean>(false)
  const { errorHandler } = useErrorHandler()

  const onSave = async (value: AssetCouponDetailFormValue) => {
    try {
      setIsLoading(true)
      if (detail) {
        await updateAssetDetail(asset.id, detail.id, asset.formatType, value)
      } else {
        await createAssetDetail(asset.id, asset.formatType, value)
      }
      doReload()
      parentSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal customCss={modalStyle} style={{ visibility: isShowCropModal ? 'hidden' : 'visible' }}>
        <ModalHeader>{t('GuestAppSetting.CreateCoupon')}</ModalHeader>
        <ModalBody customCss={{ overflow: 'unset' }}>
          <div css={modalBodyStyle}>
            <div className="left-panel">
              <div css={inputTitleTextStyle}>
                {t('Title')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="title"
                render={({ field: { onChange, value } }) => (
                  <InputField
                    marginBottom={24}
                    placeholder={t('Title name')}
                    value={value}
                    handleChangeData={onChange}
                    error={errors.title?.message}
                  />
                )}
              />

              <div css={inputTitleTextStyle}>
                {t('Name of facility')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="facilityName"
                render={({ field: { onChange, value } }) => (
                  <InputField
                    marginBottom={24}
                    placeholder={t('Name of facility')}
                    value={value}
                    handleChangeData={onChange}
                    error={errors.facilityName?.message}
                  />
                )}
              />
              <Controller
                name="imagePath"
                control={control}
                rules={{ required: t('Please select {{item}}', { item: t('Image') }) }}
                render={({ field: { onChange, value } }) => (
                  <InputImage
                    customStyle={inputAreaStyle}
                    value={value}
                    onChange={onChange}
                    onShowModal={setIsShowCropModal}
                    aspect={16 / 9}
                    error={errors.imagePath?.message}
                  />
                )}
              />
            </div>
            <div className="right-panel">
              <div css={inputTitleTextStyle}>{t('Explanatory text')}</div>
              <Controller
                control={control}
                name="description"
                render={({ field: { onChange, value, name } }) => (
                  <TextareaField
                    css={textareaStyle}
                    marginBottom={16}
                    placeholder={t('Explanatory text')}
                    name={name}
                    value={value}
                    handleChangeData={onChange}
                    rows={6}
                  />
                )}
              />

              <div style={{ display: 'flex', gap: 20 }}>
                <div>
                  <div css={inputTitleTextStyle}>
                    {t('GuestAppSetting.Terms of Use')}
                    <div css={requireLabelTextStyle}>※</div>
                  </div>
                  <Controller
                    control={control}
                    rules={{ required: t('Required field has not been entered') }}
                    name="scopeUsage"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select style={{ width: 244 }} onChange={onChange} value={value}>
                          <Option value={CouponScopeUsageType.All}>{t(`GuestAppSetting.${CouponScopeUsageType.All}`)}</Option>
                          <Option value={CouponScopeUsageType.Login}>{t(`GuestAppSetting.${CouponScopeUsageType.Login}`)}</Option>
                          <Option value={CouponScopeUsageType.CheckIn}>{t(`GuestAppSetting.${CouponScopeUsageType.CheckIn}`)}</Option>
                        </Select>
                      )
                    }}
                  />
                </div>

                <div>
                  <div css={inputTitleTextStyle}>
                    {t('GuestAppSetting.Maximum number of times used')}
                  </div>
                  <Controller
                    control={control}
                    name="maxNumberOfUse"
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        disabled={scopeUsageWatcher === CouponScopeUsageType.All}
                        width="244px"
                        type="number"
                        min={1}
                        placeholder="5"
                        value={value ?? ''}
                        handleChangeData={e => onChange(e.target.value ? parseInt(e.target.value) : null)}
                        error={errors.facilityName?.message}
                      />
                    )}
                  />
                </div>
              </div>

              <div css={inputTitleTextStyle}>
                {t('Date of expiry')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <div
                css={css({
                  '.DateRangePickerInput': { borderColor: isExpireEmpty ? 'red' : '#cccccc' },
                  position: 'relative',
                  marginBottom: 16,
                })}
              >
                <DateRangeField
                  beginDate={expireFromWatcher}
                  endDate={expireToWatcher}
                  openDirection="up"
                  changeRangeDate={(beginDate, endDate) => {
                    setValue('expireFrom', beginDate)
                    setValue('expireTo', endDate)
                  }}
                />
                {isExpireEmpty && <div css={errorMessageStyle}>{t('Required field has not been entered')}</div>}
              </div>

              <div css={inputTitleTextStyle}>URL</div>
              <Controller
                control={control}
                name="url"
                render={({ field: { onChange, value } }) => (
                  <InputField value={value} marginBottom={0} handleChangeData={onChange} placeholder="https://" />
                )}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button buttonType={isValid && !isExpireEmpty ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(onSave)}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const modalStyle = {
  height: 622,
  width: 1200,
  minWidth: 1200,
  left: 'calc((100% - 1200px) / 2)',
}

const modalBodyStyle = {
  padding: '24px 32px',
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  height: '100%',
  display: 'flex',
  '.left-panel': {
    width: '50%',
    borderRight: '1px solid #F2F2F2',
    paddingRight: 35.5,
  },
  '.right-panel': {
    width: '50%',
    paddingLeft: 30.5,
    display: 'flex',
    flexFlow: 'column',
  },
  textarea: {
    height: '100%',
  },
}

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputAreaStyle = css({
  paddingTop: 24,
  display: 'flex',
})

const errorMessageStyle = css({
  fontSize: 10,
  color: 'rgba(255,0,0,0.5)',
  marginTop: 4,
  position: 'absolute',
})

const textareaStyle = css({
  height: 157,
  '& textarea': { lineHeight: 1.5 },
})
