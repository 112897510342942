import React from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// components
import { sectionHeaderStyle } from '@/components/pages/dashboard/_index/style'

// constants
import { listHeaderGrayStyle, listContentsWrapperStyle } from '@/constants/layout'

type Props = {
  downloadCsvHistory: any
}

export const CsvDownloadHistoryList: React.FC<Props> = ({ downloadCsvHistory }) => {
  const { t } = useTranslation()
  const windows = useWindowSize()

  return (
    <div
      css={mainContainerStyle}
      style={{
        minHeight: windows.height - 105,
      }}
    >
      <div css={headerContainerStyle}>
        <div css={headerStyle}>{t('Csv download history')}</div>
      </div>
      <ul css={listHeaderStyle}>
        <li css={headCreateDateStyle}>{t('Creation date')}</li>
        <li css={headEffectiveDateStyle}>{t('Date of expiry')}</li>
        <li css={headCsvLinkStyle}>{t('Link')}</li>
      </ul>
      <div
        css={listContentsWrapperStyle}
        style={{
          maxHeight: windows.height - 180,
        }}
      >
        <div css={customListContentsStyle}>
          {downloadCsvHistory &&
            downloadCsvHistory.map((v: any) => {
              return (
                <div key={v.id} css={customListItemUnreadStyle}>
                  <div css={dataCreateDateStyle}>{dayjs.unix(v.createdAt).format(t('MM-DD HH:mm'))}</div>
                  <div css={dataEffectiveDateStyle}>{dayjs.unix(v.createdAt).add(7, 'd').format(t('MM-DD HH:mm'))}</div>
                  <div css={dataCsvLinkStyle}>
                    {v.url !== '' ? (
                      <a href={v.url} css={downloadColorStyle}>
                        {t('Download')}
                      </a>
                    ) : (
                      t('Making') + (v.totalRow ? `...(${Math.ceil(v.currentRow / v.totalRow * 100)}%)` : '')
                    )}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

const mainContainerStyle = css({
  width: '100%',
  borderRadius: 5,
  boxShadow: '0px 0px 6px #0000001a',
  background: '#FFF',
  overflow: 'hidden',
})

const headerStyle = css(sectionHeaderStyle, {
  height: 50,
  marginLeft: 28,
})

const headerContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#FFF',
})

const listHeaderStyle = css(listHeaderGrayStyle, {
  padding: '0 32px',
})

const headTitleStyle = css({
  display: 'flex',
  alignItems: 'center',
})

const headCreateDateStyle = css(headTitleStyle, {
  width: '12%',
})

const headEffectiveDateStyle = css(headTitleStyle, {
  width: '12%',
})

const headCsvLinkStyle = css(headTitleStyle, {
  width: '21.2%',
})

const dataCreateDateStyle = css(headCreateDateStyle, {})

const dataEffectiveDateStyle = css(headEffectiveDateStyle, {})

const dataCsvLinkStyle = css(headCsvLinkStyle, {
  fontWeight: 'bold',
  fontSize: 12,
})

const customListContentsStyle = css({
  fontSize: 14,
})

const customListItemUnreadStyle = css({
  padding: '16px 32px',
  display: 'flex',
  borderBottom: '1px solid #f2f2f2',
  width: '100%',
  alignItems: 'center',
})

const downloadColorStyle = css({
  color: '#F2A40B',
})
