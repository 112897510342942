import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { AssetType, ImageDetailType } from '@/models/guest-app/asset'
import { Controller, useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'
import { InputField } from '@/components/molecules/input-field'
import { Editor } from '@/components/organisms/settings/editor'
import { InputImage } from '@/components/molecules/settings/input-image'
import { createAssetDetail, updateAssetDetail } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { v4 as uuid } from 'uuid'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { ToggleButton } from '@/components/molecules/settings/toggle-button'
import { isNil } from 'lodash'
import { Hint } from '@/components/atoms/hint'

type Props = {
  asset: AssetType
  detail: ImageDetailType | null
  onCancel: () => void
  onSave: () => void
}

export type AssetImageDetailFormValue = {
  title: string
  imagePath: string
  description: string
  rawDescription: string
  destinationUrl: string
  destinationType: 'url' | 'newPage'
  isTitleShowHome: boolean
}

export const ImageDetailModal: React.FC<Props> = ({ asset, detail, onCancel, onSave: parentSave }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<AssetImageDetailFormValue>({
    defaultValues: {
      title: detail?.title || '',
      imagePath: detail?.imagePath || '',
      description: detail?.description || '',
      rawDescription: detail?.rawDescription || '',
      destinationUrl: detail?.destinationUrl || '',
      destinationType: detail?.destinationType || 'newPage',
      isTitleShowHome: detail?.isTitleShowHome || false,
    },
  })

  const watchDestinationType = watch('destinationType')

  const { doReload, setIsLoading } = useContext(AssetDetailContext)
  const [isShowCropModal, setIsShowCropModal] = useState<boolean>(false)
  const { errorHandler } = useErrorHandler()
  const editorId = useMemo(() => uuid(), [])

  const onSave = async (value: AssetImageDetailFormValue) => {
    try {
      setIsLoading(true)
      if (detail) {
        await updateAssetDetail(asset.id, detail.id, asset.formatType, value)
      } else {
        await createAssetDetail(asset.id, asset.formatType, value)
      }
      doReload()
      parentSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal customCss={modalStyle} style={{ visibility: isShowCropModal ? 'hidden' : 'visible' }}>
        <ModalHeader>{t('GuestAppSetting.CreateImage')}</ModalHeader>
        <ModalBody>
          <div css={modalBodyStyle}>
            <div className="left-panel">
              <div css={inputTitleTextStyle}>
                {t('Title')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="title"
                render={({ field: { onChange, value } }) => (
                  <InputField value={value} handleChangeData={onChange} error={errors.title?.message} />
                )}
              />

              <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                <p css={inputTitleTextStyle} style={{ paddingBottom: 0, gap: 8 }}>
                  {t('Display below the image')}
                  <Hint>
                    <div css={hintImageStyle}>
                      <p>{t('The title can be displayed under the image button on the facility dedicated page.')}</p>
                      <img src={require('@/static/images/help_title_with.png')} alt="" />
                    </div>
                  </Hint>
                </p>
                <Controller
                  name="isTitleShowHome"
                  control={control}
                  render={({ field: { onChange, value } }) => <ToggleButton onChange={onChange} value={value} />}
                />
              </div>

              <Controller
                name="imagePath"
                control={control}
                rules={{ required: t('Please select {{item}}', { item: t('Image') }) }}
                render={({ field: { onChange, value } }) => (
                  <InputImage
                    customStyle={inputAreaStyle}
                    value={value}
                    onChange={onChange}
                    onShowModal={setIsShowCropModal}
                    aspect={16 / 9}
                    error={errors.imagePath?.message}
                  />
                )}
              />
            </div>
            <div className="right-panel">
              <div css={inputTitleTextStyle}>{t('Destination')}</div>
              <Controller
                control={control}
                name="destinationType"
                render={({ field: { onChange, value } }) => (
                  <RadioGroupField
                    groupCss={{ width: '100%', height: 'auto', gap: 24, flexShrink: 0 }}
                    items={[
                      { value: 'url', label: 'URL' },
                      { value: 'newPage', label: '新規ページ' },
                    ]}
                    style={{ radioSize: 21 }}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              {watchDestinationType === 'url' && (
                <Controller
                  control={control}
                  name="destinationUrl"
                  render={({ field: { onChange, value } }) => (
                    <InputField type="text" css={{ marginTop: 16 }} placeholder="URL" value={value} onChange={onChange} />
                  )}
                />
              )}

              {watchDestinationType === 'newPage' && (
                <>
                  <div css={inputTitleTextStyle} style={{ marginTop: 24 }}>
                    {t('Explanatory text')}
                  </div>
                  <Controller
                    control={control}
                    name="rawDescription"
                    render={({ field: { value } }) => (
                      <Editor
                        id={editorId}
                        rawContent={value}
                        onChange={(raw: string, html: string) => {
                          setValue('rawDescription', raw)
                          setValue('description', html)
                        }}
                        // sub inputTitleText height
                        style={{ height: 'calc(82% - 24px)' }}
                      />
                    )}
                  />
                </>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(onSave)}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const modalStyle = {
  height: 622,
  width: 1200,
  minWidth: 1200,
  left: 'calc((100% - 1200px) / 2)',
}

const modalBodyStyle = {
  padding: 32,
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  height: '100%',
  display: 'flex',
  '.left-panel': {
    width: '50%',
    borderRight: '1px solid #F2F2F2',
    paddingRight: 35.5,
  },
  '.right-panel': {
    width: '50%',
    paddingLeft: 30.5,
    display: 'flex',
    flexFlow: 'column',
  },
}

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputAreaStyle = css({
  paddingTop: 24,
  display: 'flex',
})

const hintImageStyle = css({
  '.help-icon': { width: 18, height: 18 },
  '.help-popup': {
    right: 26,
    top: -13,
  },
  '.inner-block': {
    width: 386,
    padding: 16,
    textAlign: 'center',
    p: {
      marginBottom: 16,
      color: '#676767',
      fontWeight: 'bold',
      textAlign: 'left',
      lineHeight: 1.5,
      letterSpacing: 1.2,
      fontSize: 12,
    },
    img: {
      width: 181,
    },
  },
})
