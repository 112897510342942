import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

// models
import { CustomerReservationType } from '@/models/customer-manager/customer'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { tableBodyTdStyleUnread, tableBodyTdStyle } from '@/constants/layout'
import { css } from '@emotion/core'
import { CategoryLabel } from '@/components/atoms/reservation-note/category-label'
import { Button } from '@/components/atoms'
import { Link } from 'react-router-dom'
import { ReservationNoteCategory } from '@/models/reservation/reservation-note'

type CustomerListProps = {
  reservation: CustomerReservationType
  index: number
}

export const CustomerList: React.FC<CustomerListProps> = ({ reservation, index }) => {
  const [showMemo, setShowMemo] = useState(false)
  const [memoPosition, setMemoPosition] = useState({ left: 0, top: 0 })

  const detailMemoRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()
  const accommodationDetailUrl = useMemo(() => {
    if (reservation.approvedStatus === ApprovedStatusType.Checkin) {
      return `/checkin/${reservation.checkinId}`
    } else if (reservation.approvedStatus === ApprovedStatusType.Stay) {
      return `/stay/${reservation.checkinId}`
    } else if (reservation.approvedStatus === ApprovedStatusType.Checkout) {
      return `/checkout/${reservation.checkinId}`
    }
    return `/reservation/${reservation.id}&reservationId=${reservation.reservationId}`
  }, [reservation.approvedStatus])

  if (!reservation) return null

  const onClickMemo = (e: React.MouseEvent<HTMLButtonElement>) => {
    const rect = e.currentTarget.getBoundingClientRect()

    const detailMemoHeight = detailMemoRef.current?.getBoundingClientRect().height ?? 0
    const windowHeight = window.innerHeight - detailMemoHeight
    let topPosition = rect.y + 35

    if (topPosition > windowHeight) {
      topPosition = -(detailMemoHeight / 2)
    } else {
      topPosition = 0
    }

    setMemoPosition({ left: rect.x, top: topPosition })
    setShowMemo(!showMemo)
  }

  return (
    <tr key={index} css={[tableBodyTdStyleUnread, { display: 'table-row', '&:hover': { cursor: 'auto' } }]}>
      <td css={[currentTableBodyTdStyle, { paddingLeft: 32 }]}>
        {reservation?.checkinDate ? dayjs(reservation?.checkinDate).format(t('YYYY-MM-DD')) : '-'}{' '}
        {reservation.checkinDate && reservation.checkoutDate ? dayjs(reservation.checkoutDate).diff(reservation.checkinDate, 'day') : '-'}
        {t('Night')}
      </td>
      <td css={currentTableBodyTdStyle}>{reservation?.accommodationId ? reservation?.accommodationId : '-'}</td>
      <td css={currentTableBodyTdStyle}>{`${reservation?.paxTotal} ${t('Name')}`}</td>
      <td css={currentTableBodyTdStyle}>{reservation?.plan ? reservation?.plan : '-'}</td>
      <td css={currentTableBodyTdStyle}>
        {reservation?.typeNameJa ? reservation?.typeNameJa : '-'}/{reservation?.roomNumber ? reservation?.roomNumber : '-'}
        {reservation?.relatedReservations?.map(r => {
          return `${r?.typeNameJa !== reservation?.typeNameJa ? (r?.typeNameJa ? `, ${r?.typeNameJa}` : ', -') : ''}/${
            r?.roomNumber ? r?.roomNumber : '-'
          }`
        })}
      </td>
      <td css={currentTableBodyTdStyle}>{reservation?.point ? reservation?.point : '-'}</td>
      <td css={currentTableBodyTdStyle}>
        {reservation?.salesTotalPrice ? `${reservation?.salesTotalPrice.toLocaleString()} ${t('Circle')}` : '-'}
      </td>
      <td css={[currentTableBodyTdStyle, memoStyle]}>
        <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
          {Object.entries(ReservationNoteCategory).map(([_, value]) => (
            <div key={value} style={{ display: 'flex', alignItems: 'center' }}>
              <img src={require(`@/static/images/reservation-note/icon_${value}.svg`)} alt="" />
              <p className="list-memo">{reservation.notes.find(note => note.category === value)?.memo ?? '-'}</p>
            </div>
          ))}
        </div>
        <div style={{ position: 'relative' }}>
          <Link css={detailLinkStyle} to={accommodationDetailUrl}>
            {t('Details')}
          </Link>
          <Button
            isDisabled={reservation.notes.length === 0}
            buttonType={reservation.notes.length === 0 ? 5 : 3}
            marginTop={12}
            fontSize={11}
            borderRadius={16}
            width={57}
            height={32}
            onClick={e => onClickMemo(e)}
          >
            {t('Memo')}
          </Button>
          <div ref={detailMemoRef} css={[memoDetailStyle, { top: memoPosition.top }]} style={{ zIndex: showMemo ? 10 : -1 }}>
            {Object.entries(ReservationNoteCategory).map(([_, value]) => {
              return (
                <div key={value} className="detail-inner">
                  <p style={{ display: 'flex', alignItems: 'center', gap: 8, fontWeight: 'bold' }}>
                    <CategoryLabel category={value} />
                  </p>
                  <p style={{ marginTop: 14, lineHeight: '1.5' }}>{reservation.notes.find(note => note.category === value)?.memo ?? '-'}</p>
                </div>
              )
            })}
          </div>
        </div>
      </td>
    </tr>
  )
}

const currentTableBodyTdStyle = css(tableBodyTdStyle, {
  verticalAlign: 'top',
  paddingBottom: 24,
  paddingTop: 24,
  fontWeight: 400,
  color: 'rgb(39, 39, 39)',
})

const detailLinkStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 57,
  height: 32,
  background: '#F2A40B',
  borderRadius: 22,
  color: '#fff',
  '&:hover': {
    color: '#fff',
    background: '#D68E0F',
  },
})

const memoStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: 24,
  paddingRight: 32,
  '.list-memo': {
    width: 245,
    lineHeight: 1.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginLeft: 12,
    '+ p': {
      marginTop: 16,
    },
  },
})

const memoDetailStyle = css({
  display: 'flex',
  minWidth: '800px',
  padding: '26px 24px',
  position: 'absolute',
  zIndex: 10,
  right: 70,
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 0px 10px #00000029',
  '.detail-inner': {
    flex: '0 0 33%',
    paddingRight: 27,
    ' + .detail-inner': {
      borderLeft: '1px solid #ccc',
      paddingLeft: 27,
    },
  },
})
