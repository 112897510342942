import React, { useMemo, useState } from 'react'
import { css } from '@emotion/core'
import { FormatType } from '@/models/guest-app/asset'

const formatDescription: Record<Exclude<FormatType, 'checkin' | 'checkout'>, string> = {
  [FormatType.Free]: '施設の情報を掲載できます。画像、テキスト、ボタンを活用して自由にページを作成できます。',
  [FormatType.Pulldown]: '簡易的な情報を掲載できます。ボタンを押すとテキストをプルダウンで表示できます。',
  [FormatType.Button1]: '1つのボタンを表示できます。遷移先は任意のURLか画像を指定できます。',
  [FormatType.Button2]: '横並びの2つのボタンを表示できます。遷移先は任意のURLか画像を指定できます。',
  [FormatType.Map]: '周辺のおすすめスポットを掲載できます。\nGoogleAPIで簡単に登録できます。',
  [FormatType.Request]: 'ルームオーダーやアメニティなどの商品を販売できます。',
  [FormatType.Coupon]: '付帯施設や周辺施設のクーポンを掲載できます。',
  [FormatType.News]: '重要な情報を掲載できます。',
  [FormatType.Image]: '画像ボタンを表示できます。遷移先は任意のURLか新規ページを指定できます。',
  [FormatType.Space]: '貸切風呂、ワークスペース、送迎バスなどの予約を受付できます。',
}

type Props = {
  type: FormatType
  label: string
  value: FormatType
  onChange: (value: FormatType) => void
  disabled?: boolean
}

export const AssetFormatRadio: React.FC<Props> = ({ type, label, value, onChange, disabled }) => {
  const [popupPosition, setPopupPosition] = useState<number>()

  const className = useMemo(() => {
    const c: string[] = []
    value === type && c.push('active')
    disabled && c.push('disabled')
    return c.join(' ')
  }, [value, disabled])

  const getPopupPosition = (e: React.MouseEvent<HTMLDivElement>) => {
    setPopupPosition(e.currentTarget.getBoundingClientRect().right + 8)
  }

  return (
    <div css={radioStyle} className={className} onClick={e => !disabled && onChange(type)} onMouseEnter={e => getPopupPosition(e)}>
      <img width={24} height={24} src={require(`@/static/images/asset-format/icon_${type}_a.svg`)} className="inactive-icon" />
      <img width={24} height={24} src={require(`@/static/images/asset-format/icon_${type}_b.svg`)} className="active-icon" />
      <div>{label}</div>

      <div className="description-popup" style={{ left: popupPosition }}>
        <img src={require(`@/static/images/asset-format/hover_image_${type}.png`)} />
        <div>
          <img width={24} height={24} src={require(`@/static/images/asset-format/icon_${type}_a.svg`)} />
          <dl>
            <dt>{label}</dt>
            <dd>{formatDescription[type]}</dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

const radioStyle = css({
  cursor: 'pointer',
  display: 'flex',
  flexFlow: 'column',
  height: 71,
  minHeight: 71,
  width: 88,
  minWidth: 88,
  border: '1px solid #CCCCCC',
  borderRadius: 10,
  alignItems: 'center',
  justifyContent: 'center',
  '.inactive-icon': { display: 'block' },
  '.active-icon': { display: 'none' },
  '&.disabled': { backgroundColor: '#F2F2F2', cursor: 'not-allowed' },
  '&.active': {
    '.inactive-icon': { display: 'none' },
    '.active-icon': { display: 'block' },
    '> div': { color: '#F2A40B' },
    borderColor: '#F2A40B',
  },
  img: { marginTop: 17 },
  div: {
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: 1,
    padding: '10px 0',
  },
  '.description-popup': {
    width: 376,
    padding: 24,
    boxShadow: '0px 0px 10px #00000029',
    background: '#fff',
    borderRadius: 5,
    display: 'none',
    position: 'fixed',
    top: 'calc(32px + 225px)',
    zIndex: 20,
    img: {
      marginTop: 0,
    },
    '> img': {
      width: 328,
    },
    div: {
      display: 'flex',
      gap: 16,
      marginTop: 24,
      padding: 0,
    },
    dl: {
      dt: {
        fontWeight: 'bold',
        fontSize: 14,
        marginBottom: 8,
      },
      dd: {
        fontSize: 12,
        color: '#676767',
        fontWeight: 'normal',
        lineHeight: 1.5,
      },
    },
  },
  '&:hover': {
    '.description-popup': {
      display: 'block',
    },
  },
})
