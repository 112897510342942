export const salesSubjects = {
  部門コード: 'departmentCode',
  部門名: 'departmentName',
  科目コード: 'subjectCode',
  科目名: 'subjectName',
  補助科目コード: 'subSubjectCode',
  補助科目名: 'subSubjectName',
  料金分類: 'hasSeparated',
  金額: 'price',
  売上区分: 'category',
  大人男性金額: 'malePrice',
  大人女性金額: 'femalePrice',
  子どもA金額: 'childAPrice',
  子どもB金額: 'childBPrice',
  子どもC金額: 'childCPrice',
  子どもD金額: 'childDPrice',
  子どもE金額: 'childEPrice',
  子どもF金額: 'childFPrice',
  子どもその他金額: 'childOtherPrice',
  税率: 'tax',
  税区分: 'taxClass',
}

export const isSalesSubjectChange = (origin, newValue) => {
  const checkChangeState =
    castToString(newValue.salesDate) === castToString(origin?.salesDate) &&
    castToString(newValue.salesSubjectId) === castToString(origin?.salesSubjectId) &&
    castToString(newValue.salesSubSubjectId) === castToString(origin?.salesSubSubjectId) &&
    castToString(newValue.reservationId) === castToString(origin?.reservationId) &&
    newValue.salesSubjectPrice.toString() === origin?.salesSubjectPrice.toString() &&
    newValue.quantity.toString() === origin?.quantity.toString() &&
    castToString(newValue.salesPaymentId) === castToString(origin?.salesPaymentId) &&
    newValue.isPaid == Boolean(origin?.isPaid) &&
    castToString(newValue.companyId) === castToString(origin?.companyId) &&
    castToString(newValue.salesAccountsReceivableMasterId) === castToString(origin?.salesAccountsReceivableMasterId) &&
    castToString(newValue.guestRoomAssignId) === castToString(origin?.guestRoomAssignId)
  return !checkChangeState
}

export const isDiscountChange = (origin, newValue) => {
  const checkChangeState =
    castToString(newValue.salesDate) === castToString(origin?.salesDate) &&
    castToString(newValue.salesDiscountId) === castToString(origin?.salesDiscountId) &&
    castToString(newValue.reservationId) === castToString(origin?.reservationId) &&
    castToString(newValue.salesPaymentId) === castToString(origin?.salesPaymentId) &&
    castToString(newValue.companyId) === castToString(origin?.companyId) &&
    newValue.salesSubjectPrice.toString() === origin?.salesSubjectPrice.toString() &&
    castToString(newValue.salesAccountsReceivableMasterId) === castToString(origin?.salesAccountsReceivableMasterId) &&
    castToString(newValue.guestRoomAssignId) === castToString(origin?.guestRoomAssignId)
  return !checkChangeState
}

const castToString = value => value || ''
