import React from 'react'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle } from '@/constants/layout'

// components
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { LoadingFull } from '@/components/molecules/loading-full'

type Props = {
  children: JSX.Element
  title: string
  loading?: boolean
}

export const DashboardPageLayout = ({ children, title, loading }: Props) => {
  return (
    <>
      <div css={wholeContainerStyle}>
        <SideMenu />
        <div className="mainColumn" css={mainColumnStyle}>
          <Header title={title} />
          {loading ? <LoadingFull isLoading={loading} /> : <div css={mainContainerStyle}>{children}</div>}
        </div>
      </div>
    </>
  )
}
