import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms'
import { Controller, useForm } from 'react-hook-form'
import { InputField } from '@/components/molecules/input-field'

export type EditorLinkPopupForm = {
  name: string
  url: string
}

type Props = {
  onSave: (form: EditorLinkPopupForm) => void
  onCancel: () => void
  isSelectingWord: boolean
}

export const EditorLinkPopup: React.FC<Props> = ({ onSave, onCancel, isSelectingWord }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<EditorLinkPopupForm>({
    defaultValues: {
      name: '',
      url: '',
    },
  })

  return (
    <div css={popupStyle}>
      {!isSelectingWord && (
        <div className="input-area">
          <span className="input-label">{t('LinkName')}</span>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, name } }) => (
              <InputField marginBottom={0} placeholder={t('LinkName')} value={value} name={name} handleChangeData={onChange} />
            )}
          />
        </div>
      )}
      <div className="input-area">
        <span className="input-label">{t('LinkTo')}</span>
        <Controller
          name="url"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, name } }) => (
            <InputField marginBottom={0} placeholder="URL" value={value} name={name} handleChangeData={onChange} />
          )}
        />
      </div>
      <div className="button-area">
        <Button buttonType={3} width={97} height={28} fontSize={12} marginRight={12} onClick={() => onCancel()}>
          {t('Cancel')}
        </Button>
        <Button buttonType={isValid ? 1 : 5} width={97} height={28} fontSize={12} onClick={handleSubmit(onSave)} isDisabled={!isValid}>
          {t('LinkCreation')}
        </Button>
      </div>
    </div>
  )
}

const popupStyle = css({
  width: 475,
  minWidth: 475,
  // height: 236,
  borderRadius: 5,
  background: '#ffffff',
  filter: 'drop-shadow(0px 3px 10px #00000029)',
  padding: 24,
  position: 'absolute',
  left: 'calc(-475px / 2 + 8px)',
  top: 38,
  '&::before': {
    content: "''",
    position: 'absolute',
    top: -25,
    left: 'calc(50% - 10px)',
    marginLeft: 4.1,
    border: '10px solid transparent',
    borderBottom: '19px solid #ffffff',
  },
  '.input-area': {
    display: 'flex',
    flexFlow: 'column',
    paddingBottom: 24,
    textAlign: 'left',
  },
  '.input-label': {
    paddingBottom: 12,
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: 0.6,
    color: '#676767',
  },
  '.button-area': {
    display: 'flex',
    justifyContent: 'end',
  },
})
