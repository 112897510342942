import { t } from 'i18next'

export const ApprovedStatus = {
  Reservation: '0',
  Checkin: '1',
  Stay: '2',
  Checkout: '3',
  Customer: '4',
}

export type ReservationApprovedStatusType = '0' | '1' | '2' | '3' | '4'

export const ApprovedStatusType = {
  Reservation: 0,
  Checkin: 1,
  Stay: 2,
  Checkout: 3,
  Customer: 4,
} as const
export type ApprovedStatusType = (typeof ApprovedStatusType)[keyof typeof ApprovedStatusType]

export const approvedStatusValues: Record<ApprovedStatusType, string> = {
  [ApprovedStatusType.Reservation]: t('Reservation'),
  [ApprovedStatusType.Checkin]: t('CheckIn'),
  [ApprovedStatusType.Stay]: t('Stay'),
  [ApprovedStatusType.Checkout]: t('Checkout'),
  [ApprovedStatusType.Customer]: t('Customer'),
}

export type CheckinType = {
  id: string
  approvedStatus: typeof ApprovedStatus
}
