import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import useBrowserContextCommunication from 'react-window-communication-hook'
import axios from 'axios'
// apis
import { signin, qrSignin, parseToken } from '@/apis/auth0'
import { fetchAdminAccount, fetchPlugins } from '@/apis/aipass'
// components
import { AuthContext } from '@/contexts/auth'
import { Button } from '@/components/atoms/button'
import { SigninHeader } from '@/components/pages/signin/signin-header'
import { SigninContainer } from '@/components/pages/signin/signin-container'
import { SigninError } from '@/components/pages/signin/signin-error'
import { Copyright } from '@/components/molecules/copyright'

//libs
import { env } from '@/libs/env'

export const Signin: React.FC<{}> = () => {
  return <UI />
}

export const UI: React.FC<{}> = () => {
  const { auth, setAuth } = useContext<any>(AuthContext)
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false)
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false)
  const [isFormError, setIsFormError] = useState<boolean>(false)
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [failureAuth, setFailureAuth] = useState<boolean>(false)
  const [communicationState, postMessage] = useBrowserContextCommunication('')
  const { t } = useTranslation()
  const idAipassUrl = env('ID_AIPASS_URL')
  // const hotelAipassUrl = env('HOTEL_AIPASS_URL')
  const operatingCompanyUrl = env('OPERATING_COMPANY_URL')

  const _inputEmailValidationCheck = () => {
    if (email === '' || !email.match(/[\w\-.]+@[\w\-.]+\.[a-zA-Z]+/)) {
      setIsValidEmail(true)
      return
    }
    setIsValidEmail(false)
  }

  const _inputPasswordValidationCheck = () => {
    if (password === '') {
      setIsValidPassword(true)
      return
    }
    setIsValidPassword(false)
  }

  useEffect(() => {
    // Reload the management screen of all tabs when logging in (to avoid duplicate logins)
    if (communicationState.lastMessage === 'login') {
      window.location.reload()
    }
  }, [postMessage])

  const _signIn = () => {
    setIsBusy(true)
    if (window.location.pathname === '/qr-checkin') {
      qrSignin(email, password)
        .catch(_failure)
        .finally(() => {
          setIsBusy(false)
        })
    } else {
      signin(email, password)
        .catch(_failure)
        .finally(() => {
          setIsBusy(false)
        })
    }
  }

  const _parseToken = () => {
    parseToken()
      .then(result => _success(result))
      .catch(error => _failure(error))
      .finally()
  }

  const _success = async authData => {
    try {
      setAuth(authData)
      postMessage('login')
      const { plugins } = await fetchPlugins()

      if (window.location.pathname.indexOf('qr-checkin') !== -1) {
        history.replace({
          pathname: '/qr-checkin/scan',
        })
      } else {
        history.replace({
          pathname: '/dashboard',
        })
      }
    } catch (error) {
        console.error(error)
        alert(t('Login failed because IP address is not allowed'))
    }
  }

  const _failure = error => {
    setIsFormError(true)
    _inputEmailValidationCheck()
    _inputPasswordValidationCheck()
    console.log('認証失敗', error)
  }

  useEffect(() => {
    // Determine if logged in
    if (auth) {
      // Determine if authentication key is valid
      fetchAdminAccount().then(res => {
        if (res && res.hotel) {
          if (window.location.pathname.indexOf('qr-checkin') !== -1) {
            history.replace({
              pathname: '/qr-checkin/scan',
            })
          } else {
            history.replace({
              pathname: '/dashboard',
            })
          }
        } else {
          setFailureAuth(true)
        }
      })
    } else {
      setFailureAuth(true)
    }
  }, [auth])

  useEffect(() => {
    const hash = window.location.hash

    if (auth || !hash) return

    _parseToken()
  }, [window, auth])

  return (
    <>
      {failureAuth && (
        <div css={signinContainerStyle}>
          <div css={signinFormContainerStyle}>
            <SigninHeader />
            <SigninContainer
              email={email}
              password={password}
              emailOnChange={setEmail}
              passwordOnChange={setPassword}
              validEmail={isValidEmail}
              validPassword={isValidPassword}
            />
            {isFormError ? <SigninError /> : null}
            {isBusy ? (
              <Button
                buttonType={4}
                width={275}
                height={38}
                marginTop={22}
                marginLeft={'auto'}
                marginRight={'auto'}
                onClick={() => _signIn()}
              >
                {t('Login')}
              </Button>
            ) : (
              <Button
                buttonType={1}
                width={275}
                height={38}
                marginTop={22}
                marginLeft={'auto'}
                marginRight={'auto'}
                onClick={() => _signIn()}
              >
                {t('Login')}
              </Button>
            )}
          </div>
          {/* {window.location.pathname === '/qr-checkin' ? (
            <a
              href={`${hotelAipassUrl}`}
              css={changePageStyle}
            >
              {t('Click here for the management screen'}
            </a>
          ) : (
            <a
              href={`${hotelAipassUrl}/qr-checkin`}
              css={changePageStyle}
            >
              {t('Click here for QR check-in')}
            </a>
          )} */}
          <div css={{ marginBottom: 32 }}>
            <div css={signinLinkContainerStyle}>
              <a href={operatingCompanyUrl} css={signinLinkStyle} target="_blank" rel="noreferrer noreferrer">
                {t('Operating company')}
              </a>
              <a href={`${idAipassUrl}/about-terms-of-use-hotel`} css={signinLinkStyle} target="_blank" rel="noreferrer noreferrer">
                {t('Terms of service')}
              </a>
              <a href={`${idAipassUrl}/about-privacy-policy`} css={lastLinkStyle} target="_blank" rel="noreferrer noreferrer">
                {t('Privacy policy')}
              </a>
            </div>
            <Copyright style={{ position: 'relative', marginTop: 0, bottom: 0 }} />
          </div>
        </div>
      )}
    </>
  )
}

const signinContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  backgroundImage: `url(${require('@/static/images/icon/background-pc.png')})`,
  backgroundSize: '100% 100%',
  minHeight: '100vh',
})

const signinFormContainerStyle = css({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: 32,
})

const signinLinkContainerStyle = css({
  marginTop: 32,
  marginBottom: 32,
})

const signinLinkStyle = css({
  fontSize: 12,
  letterSpacing: 2.4,
  color: '#FF5500',
  marginRight: 24,
  '&:hover': {
    opacity: 0.8,
  },
})

const lastLinkStyle = css(signinLinkStyle, {
  marginRight: 0,
})

// const changePageStyle = css({
//   fontSize: 12,
//   letterSpacing: 2.4,
//   color: '#FF5500',
//   lineHeight: '18px',
//   marginTop: 32,
//   '@media(max-width: 520px)': {
//     marginTop: 24,
//   },
// })
