import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// contexts
import { AccountContext } from '@/contexts/account'

// libs
import * as pluginGetter from '@/libs/plugins'
import { SettingSideMenuItem } from '../molecules/setting-side-menu-item'

type SideMenuProps = {
  pathName?: string
}

export const SettingSideMenuWithGuestApp: React.FC<SideMenuProps> = () => {
  const { t } = useTranslation()
  const { plugins } = useContext<any>(AccountContext)
  // Plugin judgment
  const hasSalesManagerPlugin = pluginGetter.setHasSalesManagerPlugin(plugins)
  const hasPaymentPlugin = pluginGetter.setHasPaymentPlugin(plugins)
  const hasPrinterPlugin = pluginGetter.setHasPrinterPlugin(plugins)
  const hasCleaningPluginActive = pluginGetter.setHasCleaningManagerPlugin(plugins)
  const hasMailDeliveryActive = pluginGetter.hasMailDeliveryPlugin(plugins)
  const hasAnyReservationPlugin = pluginGetter.hasAnyReservationPlugin(plugins)
  const hasCustomCheckinPlugin = pluginGetter.setHasCustomCheckinPlugin(plugins)
  const hasSelfCheckinPlugin = pluginGetter.setHasSelfCheckinPlugin(plugins)
  const hasSmartKeyPlugin = pluginGetter.setHasSmartKeyPlugin(plugins)
  const defaultCheckinPage = (): string => {
    if (hasSelfCheckinPlugin) {
      return '/setting/checkin/self-checkin'
    } else if (hasCustomCheckinPlugin) {
      return '/setting/checkin/custom-checkin'
    }
    return ''
  }

  return (
    <React.Fragment>
      <div css={sideMenuContainerStyle}>
        <SettingSideMenuItem to="/setting/account" title={t('Account')} />
        <SettingSideMenuItem to="/setting/staff" title={t('Staff')} />
        <SettingSideMenuItem to="/setting/guest-app/asset" title={t('GuestApp')} activePattern="/setting/guest-app/" />
        {defaultCheckinPage() && <SettingSideMenuItem to={defaultCheckinPage()} title={t('CheckIn')} activePattern="/setting/checkin/" />}
        {hasAnyReservationPlugin && (
          <SettingSideMenuItem
            to="/setting/reservation/market-segment"
            title={t('Reservation linkage')}
            activePattern="/setting/reservation"
          />
        )}
        <SettingSideMenuItem to="/setting/customer-manager" title={t('Customer manger')} activePattern="/setting/customer-manager" />
        <SettingSideMenuItem to="/setting/guest-room-number" title={t('Guest room')} activePattern="/setting/guest-room" />
        {hasSmartKeyPlugin && (
          <SettingSideMenuItem to="/setting/smart-key/room" title={t('Smart Key')} activePattern="/setting/smart-key" />
        )}
        {hasSalesManagerPlugin && (
          <SettingSideMenuItem to="/setting/sales-manager/sales" title={t('Sales management')} activePattern="/setting/sales-manager" />
        )}
        {hasPaymentPlugin && <SettingSideMenuItem to="/setting/payment" title={t('Settlement')} />}
        {hasPrinterPlugin && <SettingSideMenuItem to="/setting/printer/self-checkin" title={t('Printer')} />}
        {hasCleaningPluginActive && <SettingSideMenuItem to="/setting/cleaning-manager" title={t('Cleaning-management')} />}
        {hasMailDeliveryActive && <SettingSideMenuItem to="/setting/mail-delivery" title={t('Mail delivery')} />}
      </div>
    </React.Fragment>
  )
}

const sideMenuContainerStyle = css({
  minWidth: 180,
  zIndex: 1,
  background: 'white',
  position: 'sticky',
  top: 55,
  boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
  height: 'calc(100vh - 55px)',
})
