import React, { useMemo } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { DashboardType, SalesSummaryType } from '@/models/sales-manager/dashboard'

type Props = {
  mode: DashboardType
  summary: SalesSummaryType | undefined
  diff: SalesSummaryType | undefined
  per: SalesSummaryType | undefined
}
const Block: React.FC<{
  label: string
  prevLabel: string
  unit: string
  summary: number | undefined
  diff: number | undefined
  per: number | undefined
}> = ({ label, prevLabel, unit, summary, diff, per }) => {
  const diffStyle = useMemo(() => {
    if (diff === undefined || diff === 0) {
      return css({})
    }
    return css({ color: diff > 0 ? '#185AA5' : '#E6483B' })
  }, [diff])

  const iconPath = useMemo(() => {
    if (diff === undefined || diff === 0) {
      return require('@/static/images/icon_keep.svg')
    }
    return diff > 0 ? require('@/static/images/icon_up.svg') : require('@/static/images/icon_down.svg')
  }, [diff])

  return (
    <div>
      <div className="title">{label}</div>
      <div className="summary">{unit === '¥' ? `¥${summary?.toLocaleString() ?? '-'}` : `${summary ?? '-'}${unit}`}</div>
      <div className="diff-block" css={diffStyle}>
        <img src={iconPath} />
        <div>
          <div>
            {prevLabel}:{per ?? '-'}%
          </div>
          <div>{unit === '¥' ? `(¥${diff?.toLocaleString() ?? '-'})` : `(${diff ?? '-'}${unit})`}</div>
        </div>
      </div>
    </div>
  )
}

export const SummaryBlock: React.FC<Props> = ({ mode, summary, diff, per }) => {
  const { t } = useTranslation()

  const prevLabel = useMemo(() => (mode === DashboardType.Daily ? t('MoM') : t('YoY')), [mode])

  return (
    <div css={summaryBlockStyle}>
      <Block label={t('Sales')} prevLabel={prevLabel} unit="¥" summary={summary?.price} per={per?.price} diff={diff?.price} />
      <Block
        label={t('Number of rooms sold')}
        prevLabel={prevLabel}
        unit={t('room counter')}
        summary={summary?.soldRoomsCount}
        per={per?.soldRoomsCount}
        diff={diff?.soldRoomsCount}
      />
      <Block
        label={t('Number of guests')}
        prevLabel={prevLabel}
        unit={t('Persons')}
        summary={summary?.guestCount}
        per={per?.guestCount}
        diff={diff?.guestCount}
      />
      <Block label="OCC" prevLabel={prevLabel} unit="%" summary={summary?.OCCRate} per={per?.OCCRate} diff={diff?.OCCRate} />
      <Block label="ADR" prevLabel={prevLabel} unit="¥" summary={summary?.ADR} per={per?.ADR} diff={diff?.ADR} />
      <Block label="RevPAR" prevLabel={prevLabel} unit="¥" summary={summary?.revPAR} per={per?.revPAR} diff={diff?.revPAR} />
    </div>
  )
}

const summaryBlockStyle = css({
  display: 'flex',
  paddingTop: 16,
  '> div': {
    width: 'calc(100% / 6)',
    height: 120,
    border: '1px solid #CCCCCC',
    padding: 16,
    ':not(:first-of-type)': { borderLeft: 'none' },
    ':first-of-type': { borderRadius: '5px 0 0 5px' },
    ':last-of-type': { borderRadius: '0 5px 5px 0' },
  },
  '.title': {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 2.1,
    color: '#272727',
  },
  '.summary': {
    paddingTop: 24,
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 2.4,
    color: '#272727',
    textAlign: 'right',
  },
  '.diff-block': {
    display: 'flex',
    paddingTop: 9,
    justifyContent: 'flex-end',
    img: {
      paddingRight: 12,
    },
    '> div': {
      fontSize: 10,
      fontWeight: 'bold',
      letterSpacing: 1.5,
      textAlign: 'right',
      '> div': {
        ':last-of-type': { paddingTop: 5 },
      },
    },
  },
})
