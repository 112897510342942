import React, { createContext, useState, useContext, useMemo, Context } from 'react'
import { cancelClientCoordination, getClientInfo, remoteLockSyncDevices } from '@/apis/aipass'
import { AccountContext } from '@/contexts/account'
import { setHasRemoteLockPlugin, setHasSmartKeyPlugin } from '@/libs/plugins'
import { clientInfoType } from '@/models/remote-lock/response'

export type RemoteLockClient = {
  initialize: () => Promise<void>
  disconnect: () => Promise<void>
  syncDevice: () => Promise<void>
  openRemoteLockOAuthModal: () => void
  isEnabled: boolean
  isConnected: boolean
}

const remoteLockContext = createContext<RemoteLockClient | undefined>(undefined)

export const RemoteLockContextCreator = (): Context<RemoteLockClient> => {
  if (!remoteLockContext) {
    throw new Error('undefined context')
  }
  return remoteLockContext as Context<RemoteLockClient>
}

export const RemoteLockProvider = ({ children }) => {
  const { plugins } = useContext<any>(AccountContext)
  const hasSmartKeyPlugin = setHasSmartKeyPlugin(plugins)!
  const hasRemoteLockPlugin = setHasRemoteLockPlugin(plugins)!

  const [remoteLockClientInfo, setRemoteLockClientInfo] = useState<clientInfoType>()

  const initialize = async () => {
    try {
      if (!hasRemoteLockPlugin) {
        return
      }
      const res = await getClientInfo()
      if (!res) return console.warn('getClientInfo returned empty response')
      setRemoteLockClientInfo(res.clientInfo)
    } catch (error) {
      console.warn({ error })
    }
  }

  const disconnect = async () => {
    try {
      await cancelClientCoordination()
      await initialize()
    } catch (error) {
      console.warn(error)
    }
  }

  const syncDevice = async () => {
    await remoteLockSyncDevices()
  }

  const openRemoteLockOAuthModal = () => {
    if (!remoteLockClientInfo) {
      return
    }
    const a = document.createElement('a')
    a.href = `https://connect.remotelock.jp/oauth/authorize?client_id=${remoteLockClientInfo.clientId}&redirect_uri=${remoteLockClientInfo.redirectUri}&response_type=code`
    document.body.appendChild(a)
    a.click()
  }

  const isEnabled = useMemo((): boolean => {
    return !!(hasSmartKeyPlugin && hasRemoteLockPlugin && remoteLockClientInfo)
  }, [hasSmartKeyPlugin, hasRemoteLockPlugin, remoteLockClientInfo])

  const isConnected = useMemo((): boolean => {
    return !!(isEnabled && remoteLockClientInfo?.connected)
  }, [isEnabled, remoteLockClientInfo])

  return (
    <remoteLockContext.Provider
      value={{
        initialize,
        disconnect,
        syncDevice,
        openRemoteLockOAuthModal,
        isEnabled,
        isConnected,
      }}
    >
      {children}
    </remoteLockContext.Provider>
  )
}
