import * as React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

//libs
import { CreditCardForm } from './credit-card-form'
import { CREDIT_CARD } from '@/models/self-checkin/payment-method'
import { UseFormReturn } from 'react-hook-form'
import { CardInfo } from '.'
export type Props = {
  selectedPaymentMethod: string
  paymentMethodOptions: {
    label: string
    value: string
  }[]
  paymentSetting?: any
  onChangePaymentMethod: (data) => void
  cardForm: UseFormReturn<CardInfo, any>
}

export const CheckinPaymentMethod: React.FC<Props> = ({
  selectedPaymentMethod,
  paymentMethodOptions,
  onChangePaymentMethod,
  paymentSetting,
  cardForm,
}) => {
  const { t } = useTranslation()

  return (
    <div css={paymentWrapper}>
      <div css={paymentSelectWrapper}>
        <span css={labelStyle}>{t('Payment options')}</span>
        <select name="selectPaymentMethod" css={selectStyle} value={selectedPaymentMethod} onChange={onChangePaymentMethod}>
          {paymentMethodOptions &&
            paymentMethodOptions.map(
              paymentMethod =>
                paymentSetting.includes(paymentMethod.value) && (
                  <option value={paymentMethod.value} key={paymentMethod.value}>
                    {t(`SelfCheckinPayment.${paymentMethod.label}`)}
                  </option>
                ),
            )}
        </select>
      </div>
      {selectedPaymentMethod === CREDIT_CARD && <CreditCardForm cardForm={cardForm} />}
    </div>
  )
}

const paymentSelectWrapper = css({
  paddingBottom: '16px',
  padding: '0 52px 16px 52px',
})
const paymentWrapper = css({
  width: '100%',
  backgroundColor: '#fff',
  textAlign: 'left',
  padding: '30px 0',
  borderRadius: '10px',
  marginTop: '16px',
})

const labelStyle = css({
  fontWeight: 'bold',
  fontSize: 18,
  color: '#272727',
  paddingBottom: 14,
  display: 'block',
})
const selectStyle = css({
  width: '100%',
  height: '61px',
  fontSize: 21,
  backgroundColor: '#fff',
  borderRadius: 40,
  padding: '0 35px 0 35px',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '95% center',
  backgroundSize: 10,
  display: 'block',
  marginRight: 32,
  borderColor: '#CCCCCC',
})
