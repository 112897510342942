import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { css } from '@emotion/core'
import { RoomStatusFilter } from '@/components/pages/room-manager/indicator'
import { RoomStatusType, TotalType } from '@/models/room-manager/response/guest-room-indicator'
import { PluginCode, checkActivePlugin } from '@/libs/plugins'
import { AccountContext } from '@/contexts/account'
import { useTranslation } from 'react-i18next'

type Props = {
  setRoomStatusFilter?: Dispatch<SetStateAction<RoomStatusFilter | undefined>>
  total: TotalType | undefined
}

export const RomStatusBox: React.FC<Props> = ({ setRoomStatusFilter, total }) => {
  const [selectedStatus, setSelectedStatus] = useState<RoomStatusFilter>([])
  const { plugins } = useContext<any>(AccountContext)
  const { t } = useTranslation()

  const toggleStatus = (selectStatus: RoomStatusType) => {
    const toggledStatus = selectedStatus.includes(selectStatus)
      ? selectedStatus.filter(s => s !== selectStatus)
      : [...selectedStatus, selectStatus]

    setSelectedStatus(toggledStatus)
    if (setRoomStatusFilter) {
      setRoomStatusFilter(toggledStatus)
    }
  }

  return (
    <div css={boxAreaStyle}>
      <div
        css={boxStyle}
        className={`-empty ${selectedStatus.includes(RoomStatusType.empty) && '-selected'}`}
        onClick={() => toggleStatus(RoomStatusType.empty)}
      >
        <div>{t('cleaningManager.Vacant')}</div>
        <div>{total?.emptyRoom || 0}</div>
      </div>
      <div
        css={boxStyle}
        className={`-check-in ${selectedStatus.includes(RoomStatusType.checkIn) && '-selected'}`}
        onClick={() => toggleStatus(RoomStatusType.checkIn)}
      >
        <div>{t('Indicators.CIWaiting')}</div>
        <div>{total?.checkin || 0}</div>
      </div>
      <div
        css={boxStyle}
        className={`-stay ${selectedStatus.includes(RoomStatusType.stay) && '-selected'}`}
        onClick={() => toggleStatus(RoomStatusType.stay)}
      >
        <div>{t('Stay')}</div>
        <div>{total?.stay || 0}</div>
      </div>
      <div
        css={boxStyle}
        className={`-check-out ${selectedStatus.includes(RoomStatusType.checkOut) && '-selected'}`}
        onClick={() => toggleStatus(RoomStatusType.checkOut)}
      >
        <div>{t('cleaningManager.Checkout Soon')}</div>
        <div>{total?.checkoutScheduled || 0}</div>
      </div>
      {checkActivePlugin(plugins, PluginCode.CleaningManager) && (
        <div
          css={boxStyle}
          className={`-cleaning ${selectedStatus.includes(RoomStatusType.cleaning) && '-selected'}`}
          onClick={() => toggleStatus(RoomStatusType.cleaning)}
        >
          <div>{t('Indicators.NotCleaned')}</div>
          <div>{total?.roomNotClean || 0}</div>
        </div>
      )}
    </div>
  )
}

const statusColor = css({
  '&.-selected': {
    '*': { color: '#FFFFFF !important' },
  },
  '&.-empty': {
    div: { color: '#676767' },
    '&.-selected': { backgroundColor: '#676767' },
  },
  '&.-check-in': {
    div: { color: '#F47110' },
    '&.-selected': { backgroundColor: '#F47110' },
  },
  '&.-stay': {
    div: { color: '#3E85CC' },
    '&.-selected': { backgroundColor: '#3E85CC' },
  },
  '&.-check-out': {
    div: { color: '#7DC931' },
    '&.-selected': { backgroundColor: '#7DC931' },
  },
  '&.-cleaning': {
    div: { color: '#F2A40B' },
    '&.-selected': { backgroundColor: '#F2A40B' },
  },
})

const boxAreaStyle = css({
  marginTop: 18,
  marginLeft: 32,
  display: 'flex',
  borderRadius: 5,
  border: '1px solid #CCCCCC',
  backgRound: '#FFFFFF',
  width: 'fit-content',
  height: 'fit-content',
})

const boxStyle = css(statusColor, {
  width: 84,
  height: 50,
  textAlign: 'center',
  borderRight: '1px solid #CCCCCC',
  padding: '10px 0',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  ':last-of-type': {
    borderRight: 'none',
  },
  cursor: 'pointer',
  div: {
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: '0.6px',
    color: '#676767',
  },
})
