import React, { useContext, useEffect } from 'react'
import { AssetType, ButtonDetailType } from '@/models/guest-app/asset'
import { ContainerHeader } from '@/components/molecules/settings/container-header'
import { ContainerSidePanelBody } from '@/components/molecules/settings/container-side-panel-body'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { updateAsset, updateAssetDetail } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useTranslation } from 'react-i18next'
import { ButtonForm } from './button-form'
import { css } from '@emotion/core'
import { useForm, useWatch } from 'react-hook-form'
import { ButtonRoleType } from '@/models/guest-app/design'

type Props = {
  asset: AssetType & { details: ButtonDetailType[] }
}

export type AssetButtonDetailFormValue = {
  title: string
  button1: AssetButtonFormValue
  button2: AssetButtonFormValue | undefined
}

export type AssetButtonFormValue = {
  name: string
  link: string
  fileName: string
  filePath: string
  type: 'url' | 'image'
  designType: ButtonRoleType
}

export const ButtonAssetDetail: React.FC<Props> = ({ asset }) => {
  const { t } = useTranslation()
  const {
    control,
    watch,
    setValue,
    reset,

    formState: { isDirty },
  } = useForm<AssetButtonDetailFormValue>({
    defaultValues: {
      title: asset.title,
      button1: asset.details[0],
      button2: asset.details[1] || undefined,
    },
  })
  const { doReload, listenDoSave, listenDoCancel, setIsLoading, setIsEditing } = useContext(AssetDetailContext)
  const { errorHandler } = useErrorHandler()
  const formValue = watch()
  const titleWatcher = useWatch({ control, name: 'title' })

  const saveFormValue = async () => {
    try {
      setIsLoading(true)
      await updateAsset(asset.id, { title: formValue.title })
      await updateAssetDetail(asset.id, asset.details[0].id, asset.formatType, formValue.button1)
      if (formValue.button2) {
        await updateAssetDetail(asset.id, asset.details[1].id, asset.formatType, formValue.button2)
      }
      reset(formValue)
      setIsEditing(false)
      doReload()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isDirty) {
      setIsEditing(true)
    }
  }, [formValue])

  useEffect(() => {
    if (listenDoSave) saveFormValue()
  }, [listenDoSave])

  useEffect(() => {
    if (listenDoCancel) {
      doReload()
      reset()
    }
  }, [listenDoCancel])

  return (
    <>
      <ContainerHeader goBack title={titleWatcher} onChangeTitle={(newTitle: string) => setValue('title', newTitle)} />
      <ContainerSidePanelBody menus={[{ title: t('Content'), path: '', isActive: true }]}>
        <div css={containerStyle}>
          <div>
            <ButtonForm 
            buttonLabelName={`${t('ButtonName')}${asset.details[1] ? `(${t('left')})` : ''}`}
            namePrefix="button1" control={control} setValue={setValue} />
          </div>
          {asset.details[1] && (
            <div>
              <ButtonForm 
              buttonLabelName={`${t('ButtonName')}(${t('right')})`}
              namePrefix="button2" control={control} setValue={setValue} />
            </div>
          )}
        </div>
      </ContainerSidePanelBody>
    </>
  )
}
const containerStyle = css({
  padding: 32,
  height: '100%',
  display: 'flex',
  '>div:nth-of-type(1)': {
    width: '50%',
    paddingRight: 32,
  },
  '>div:nth-of-type(2)': {
    width: '50%',
    borderLeft: '1px solid #F2F2F2',
    paddingLeft: 32,
  },
})
