import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'
import { AssetType, CouponDetailType } from '@/models/guest-app/asset'
import { ContainerHeader } from '@/components/molecules/settings/container-header'
import { ContainerSidePanelBody } from '@/components/molecules/settings/container-side-panel-body'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { deleteAssetDetail, updateAsset, updateAssetDetailDisplayOrder } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import dayjs from 'dayjs'
import { useDraggableDom } from '@/components/molecules/settings/use-draggable-dom'
import { CouponDetailModal } from './coupon-detail-modal'
import { GuestAppTableHeader, GuestAppTableBody, GuestAppTableRow } from '@/components/atoms/settings/guest-app-table'

type Props = {
  asset: AssetType & { details: CouponDetailType[] }
}

export const CouponAssetList: React.FC<Props> = ({ asset }) => {
  const { t } = useTranslation()
  const [formValue, setFormValue] = useState<{ title: string; details: CouponDetailType[] }>({
    title: asset.title,
    details: asset.details,
  })
  const [deleteIds, setDeleteIds] = useState<string[]>([])
  const [showDetailProp, setShowDetailProp] = useState<CouponDetailType | undefined | null>(undefined)
  const { doReload, setIsLoading, setIsEditing, listenDoSave, listenDoCancel } = useContext(AssetDetailContext)
  const { errorHandler } = useErrorHandler()
  const addDeleteTarget = (id: string) => {
    setFormValue({ ...asset, details: formValue.details.filter(detail => detail.id !== id) })
    setDeleteIds([...deleteIds, id])
    setIsEditing(true)
  }

  const changeDisplayOrder = (newList: CouponDetailType[]) => {
    setFormValue({ ...asset, details: newList })
    setIsEditing(true)
  }
  const { dragStart, dragOver, dragDrop } = useDraggableDom({ list: formValue.details, onChange: changeDisplayOrder })

  const saveFormValue = async () => {
    try {
      setIsLoading(true)
      if (deleteIds.length) {
        await deleteAssetDetail(asset.id, deleteIds)
        setDeleteIds([])
      }
      if (formValue.details.length) {
        await updateAssetDetailDisplayOrder(
          asset.id,
          formValue.details.map(detail => detail.id),
        )
      }
      await updateAsset(asset.id, { title: formValue.title })
      doReload()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (listenDoSave) saveFormValue()
  }, [listenDoSave])

  useEffect(() => {
    if (listenDoCancel) {
      setDeleteIds([])
      doReload()
    }
  }, [listenDoCancel])

  useEffect(() => {
    if (!asset) {
      return
    }
    setFormValue({
      title: asset.title,
      details: asset.details,
    })
  }, [asset])

  return (
    <>
      <ContainerHeader
        goBack
        title={formValue.title}
        onChangeTitle={(newTitle: string) => {
          setFormValue({ ...formValue, title: newTitle })
          setIsEditing(true)
        }}
        onClickAddButton={() => setShowDetailProp(null)}
      />
      <ContainerSidePanelBody menus={[{ title: t('List'), path: '', isActive: true }]}>
        <>
          <GuestAppTableHeader>
            <div css={thumbnailColStyle}></div>
            <div css={titleColStyle}>{t('Title')}</div>
            <div css={facilityColStyle}>{t('Name of facility')}</div>
            <div css={expireStyle}>{t('Date of expiry')}</div>
            <div css={deleteColStyle}></div>
          </GuestAppTableHeader>
          <GuestAppTableBody>
            {formValue.details.map(detail => (
              <GuestAppTableRow
                id={detail.id}
                key={detail.id}
                onClick={() => setShowDetailProp(detail)}
                draggable="true"
                onDragStart={dragStart}
                onDragOver={dragOver}
                onDrop={dragDrop}
              >
                <div css={thumbnailColStyle}>
                  <img src={detail.imagePath}></img>
                </div>
                <div css={titleColStyle}>{detail.title}</div>
                <div css={facilityColStyle}>{detail.facilityName}</div>
                <div css={expireStyle}>
                  {`${dayjs(detail.expireFrom).format(t('YYYY-MM-DD'))} ~ ${dayjs(detail.expireTo).format(t('YYYY-MM-DD'))}`}
                </div>
                <div css={deleteColStyle}>
                  <DeleteIcon onClick={() => addDeleteTarget(detail.id)} />
                </div>
              </GuestAppTableRow>
            ))}
          </GuestAppTableBody>
        </>
      </ContainerSidePanelBody>
      {showDetailProp !== undefined && (
        <CouponDetailModal
          asset={asset}
          detail={showDetailProp}
          onCancel={() => setShowDetailProp(undefined)}
          onSave={() => setShowDetailProp(undefined)}
        />
      )}
    </>
  )
}

const thumbnailColStyle = css({
  marginLeft: 62,
  marginRight: 24,
  width: 'calc(38px * 16 / 9)',
  '> img': {
    height: 38,
    borderRadius: 5,
  },
})
const titleColStyle = css({
  flexGrow: 1,
  overflowWrap: 'anywhere',
  width: 'min-content',
})
const facilityColStyle = css({
  width: 195,
})
const expireStyle = css({
  width: 270,
})
const deleteColStyle = css({
  width: 30,
  margin: '0 16px',
})
