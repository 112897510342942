import * as React from 'react'
import { css } from '@emotion/core'

export const Copyright = ({ style }: { style?: React.CSSProperties }) => {
  const today = new Date()
  return (
    <div css={copyrightStyle} style={style}>
      ©{today.getFullYear()} aipass Inc.
    </div>
  )
}

export const copyrightStyle = css({
  textAlign: 'center',
  marginTop: 'auto',
  color: '#676767',
  fontSize: 10,
  position: 'absolute',
  bottom: 40,
  '@media(max-width: 520px)': {
    bottom: 24,
  },
})
