import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { RoomManagerIndicatorContext, RoomTypeFilter } from '@/components/pages/room-manager/indicator'
import { fetchAdminReservationStock } from '@/apis/aipass'
import dayjs from 'dayjs'
import { SiteControllerStockAdjustmentRoomType } from '@/models/room-manager/response/site-controller-stock'
import { useTranslation } from 'react-i18next'

type Props = {
  setRoomTypeFilter?: Dispatch<SetStateAction<RoomTypeFilter | undefined>>
}

export const RemainingRoomBox: React.FC<Props> = ({ setRoomTypeFilter }) => {
  const [totalRemainingCount, setTotalRemainingCount] = useState<number>(0)
  const [roomTypes, setRoomTypes] = useState<SiteControllerStockAdjustmentRoomType[]>([])
  const [selectedType, setSelectedType] = useState<Array<string>>([])
  const { reloadEvent } = useContext(RoomManagerIndicatorContext)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    initializeComponent()
  }, [reloadEvent])

  const initializeComponent = () => {
    fetchAdminReservationStock(dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')).then(res => {
      const flatStocks = Object.entries(res.stocks).flatMap(([floorName, stockEachDate]) =>
        Object.entries(stockEachDate).flatMap(([date, stock]) => stock),
      )
      const excludeTemporaryRoomStocks = flatStocks.filter(stock => stock?.typeName && stock.typeName[0] !== '_')
      setRoomTypes(excludeTemporaryRoomStocks)
      setTotalRemainingCount(res.totalRemainingCountEachDate[dayjs().format('YYYYMMDD')] || 0)
    })
  }

  const toggleSelectType = (id: string) => {
    const toggledType = selectedType.includes(id) ? selectedType.filter(typeId => typeId !== id) : [...selectedType, id]
    setSelectedType(toggledType)
    if (setRoomTypeFilter) {
      setRoomTypeFilter(toggledType)
    }
  }

  return (
    <div css={contentAreaStyle}>
      <div>{t('Indicators.RemainingRooms')}</div>
      <div css={boxAreaStyle}>
        <div css={boxStyle}>
          <div>{t('All')}</div>
          <div>{totalRemainingCount}</div>
        </div>
        {roomTypes.map(type => (
          <div
            css={boxStyle}
            className={`-selectable ${selectedType.includes(type.typeId) && '-selected'}`}
            key={type.typeId}
            onClick={() => toggleSelectType(type.typeId)}
          >
            <div>{type.typeShortName || (i18n.language === 'ja' ? type.typeName : type.typeNameEn)}</div>
            <div>{type.remainingCount}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

const contentAreaStyle = css({
  marginLeft: 32,
  marginTop: 18,
  display: 'flex',
  alignItems: 'center',
  div: {
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: 0.6,
    color: '#676767',
  },
})

const boxAreaStyle = css({
  marginLeft: 17,
  display: 'flex',
  borderRadius: 5,
  border: '1px solid #CCCCCC',
  backgRound: '#FFFFFF',
  width: 'fit-content',
  height: 'fit-content',
  overflow: 'auto',
  maxWidth: 'calc(54px * 14)',
})

const boxStyle = css({
  minWidth: 54,
  width: 54,
  height: 50,
  textAlign: 'center',
  borderRight: '1px solid #CCCCCC',
  padding: '10px 0',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  ':last-of-type': {
    borderRight: 'none',
  },
  div: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '&.-selected': {
    '*': {
      color: '#FFFFFF !important',
    },
    background: '#676767 !important',
  },
  '&.-selectable': {
    cursor: 'pointer',
  },
})
