import React, { useMemo } from 'react'
import { AssetType, RequestDetailType } from '@/models/guest-app/asset'
import { useLocation } from 'react-router-dom'
import { RequestAssetList } from './request-asset-list'
import { RequestCategoryList } from './request-category-list'
import { RequestNotificationList } from './request-notification-list'
import { RequestSalesHourList } from './request-sales-hour-list'
import { RequestTagList } from './request-tag-list'
import { useTranslation } from 'react-i18next'
import { CategoryType, NotificationType, SalesHourType, TagType } from '@/models/mobile-request'

type Props = {
  asset: AssetType & {
    details: RequestDetailType[]
    categories: CategoryType[]
    tags: TagType[]
    salesHours: SalesHourType[]
    notification: NotificationType

  }
}

export const RequestAssetTemplate: React.FC<Props> = ({ asset }) => {
  const { t } = useTranslation()
  const location = useLocation()

  const listPath = `/setting/guest-app/asset/${asset.id}`
  const categoryPath = `/setting/guest-app/asset/${asset.id}/request-category`
  const tagPath = `/setting/guest-app/asset/${asset.id}/request-tag`
  const salesHourPath = `/setting/guest-app/asset/${asset.id}/request-sales-hour`
  const notificationPath = `/setting/guest-app/asset/${asset.id}/request-notification`

  const menus = useMemo(
    () => [
      { title: t('List'), path: listPath, isActive: listPath === location.pathname },
      {
        title: t('Category'),
        path: categoryPath,
        isActive: categoryPath === location.pathname,
      },
      {
        title: t('Tag'),
        path: tagPath,
        isActive: tagPath === location.pathname,
      },
      {
        title: t('MobileRequest.Sales hours'),
        path: salesHourPath,
        isActive: salesHourPath === location.pathname,
      },
      {
        title: t('Notification'),
        path: notificationPath,
        isActive: notificationPath === location.pathname,
      },
    ],
    [location.pathname],
  )

  if (location.pathname === categoryPath) {
    return <RequestCategoryList asset={asset} menus={menus} />
  }
  if (location.pathname === tagPath) {
    return <RequestTagList asset={asset} menus={menus} />
  }
  if (location.pathname === salesHourPath) {
    return <RequestSalesHourList asset={asset} menus={menus} />
  }
  if (location.pathname === notificationPath) {
    return <RequestNotificationList asset={asset} menus={menus} />
  }
  return <RequestAssetList asset={asset} menus={menus} />
}
