import React, { useContext, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Select, Option } from '@/components/atoms/select'
import { Link } from 'react-router-dom'
import 'dayjs/locale/ja'

// contexts
import { AccountContext } from '@/contexts/account'

// libs
import { setHasRepeatPlugin } from '@/libs/plugins'

// components
import { Button } from '@/components/atoms/button'

// models
import { CustomerType } from '@/models/customer-manager/customer'
import { CustomerTagInput } from '@/components/molecules/customer/customer-tag-input'

type CustomerLinkProps = {
  history: any
  isEdit: boolean
  customer: CustomerType
  currentTags: string[]
  deleteCustomerLink: () => void
  onCancel: () => void
}

export const CustomerLink: React.FC<CustomerLinkProps> = ({ history, isEdit, customer, currentTags, deleteCustomerLink, onCancel }) => {
  const { t } = useTranslation()
  const { plugins } = useContext<any>(AccountContext)

  // Whether it is a repeat management plugin
  const hasRepeatPlugin = setHasRepeatPlugin(plugins)

  return (
    <>
      <div
        css={{
          height: 48,
          borderBottom: '1px solid #f5f5f5',
          padding: '0 32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontWeight: 'bold',
          fontSize: 16,
        }}
      >
        <div css={{ color: '#272727' }}>{t('Customer')}</div>
        <div>
          {isEdit ? (
            <div css={editButtonWrapperStyle}>
              <Button width={100} height={32} buttonType={3} fontSize={12} marginRight={16} onClick={onCancel}>
                {t('Cancel')}
              </Button>
              <Button width={58} height={32} buttonType={1} fontSize={12}>
                {t('Save')}
              </Button>
            </div>
          ) : (
            <Button buttonType={4} fontSize={11} width={32} height={32} onClick={deleteCustomerLink}>
              <img alt="close" src={require('@/static/images/cancel.svg')} />
            </Button>
          )}
        </div>
      </div>
      <div css={SectionStyle}>
        <Link
          to={() => ({
            pathname: `/customer/${customer.id}`,
            search: new URLSearchParams({ backPage: history.location.pathname + history.location.search }).toString(),
          })}
          css={{
            backgroundColor: '#F5F5F5',
            borderRadius: 10,
            display: 'flex',
            marginBottom: 8,
            transition: 'all 0.3s ease',
            padding: '4px 12px',
            color: '#272727',
            ':hover': { color: '#272727', backgroundColor: '#e5e5e5' },
          }}
        >
          <div css={{ padding: '12px', marginTop: 14, flex: '0 1 auto', maxWidth: '55%', wordWrap: 'break-word' }}>
            <div css={{ fontSize: 10 }}>{customer?.name_kana || customer?.nameKana || '-'}</div>
            <div css={nameStyle}>{customer?.name ? customer?.name : '-'}</div>
            {hasRepeatPlugin ? (
              <div css={inputWrapperStyle}>
                <p style={{ fontSize: 12, fontWeight: 'bold', color: '#676767' }}>
                {`${t('Number of nights stayed')} : ${customer?.stayCount}`}
                </p>
              </div>
            ) : (
              <>-</>
            )}
          </div>
          <div css={{ padding: '12px', flex: '1', minWidth: 0, wordWrap: 'break-word' }}>
            <div css={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
              <img src={require('@/static/images/phone.svg')} css={{ marginRight: 8 }} />
              <p css={{ fontSize: 12, wordWrap: 'break-word', width: 'calc(100% - 20px)' }}>
                {customer.telephone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1$2$3')}
              </p>
            </div>
            <div css={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
              <img src={require('@/static/images/mail.svg')} css={{ marginRight: 8 }} />
              <p css={{ fontSize: 12, wordWrap: 'break-word', width: 'calc(100% - 20px)' }}>{customer.email}</p>
            </div>
            <div css={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
              <img src={require('@/static/images/access.svg')} css={{ marginRight: 8 }} />
              <p css={{ fontSize: 12, wordWrap: 'break-word', width: 'calc(100% - 20px)' }}>{customer.address}</p>
            </div>
          </div>
        </Link>
        <div css={itemWrapperStyle}>
          <CustomerTagInput customerTags={currentTags} customerId={customer.id} />
        </div>
      </div>
    </>
  )
}

const textStyle = css({
  color: '#272727',
})

const SectionStyle = css({
  backgroundColor: '#FFF',
  padding: '16px 24px 22px 24px',
  position: 'relative',
})

const itemWrapperStyle = css(textStyle, {
  img: {
    marginRight: 16,
    width: 18,
  },
  p: {
    fontSize: 14,
    height: 20,
    lineHeight: '25px',
  },
  span: {
    color: '#F2A40B',
  },
})

const nameStyle = css({
  margin: '6px 0 12px',
  fontWeight: 'bold',
  fontSize: 16,
  display: 'block',
})

const inputWrapperStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
})

const editButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
})
