import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// libs
import { setHasMobileRequestPlugin } from '@/libs/plugins'

// contexts
import { AccountContext } from '@/contexts/account'

type Props = {
  currentTab: 'reservationInfo' | 'request'
  setCurrentTab: (v: 'reservationInfo' | 'request') => void
}

export const RoomInfoTab: React.FC<Props> = ({ currentTab, setCurrentTab }) => {
  const { t } = useTranslation()
  const { plugins } = useContext<any>(AccountContext)
  // Plugin judgment
  const hasMobileRequestPlugin = setHasMobileRequestPlugin(plugins)

  return (
    <div css={tabContainerStyle}>
      <div css={currentTab === 'reservationInfo' ? tabActiveStyle : tabStyle} onClick={() => setCurrentTab('reservationInfo')}>
        {t('Accommodation information')}
      </div>
      {hasMobileRequestPlugin && (
        <div css={currentTab === 'request' ? tabActiveStyle : tabStyle} onClick={() => setCurrentTab('request')}>
          {t('Request')}
        </div>
      )}
    </div>
  )
}

const centerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 0px 6px #0000001A',
})

const tabContainerStyle = css({
  height: 45,
  display: 'flex',
})

const tabStyle = css(centerStyle, {
  width: 134,
  height: 45,
  borderRadius: '5px 5px 0 0',
  background: '#F5F5F5',
  boxShadow: '0px 0px 6px #0000001A',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#676767',
  '&:hover': {
    cursor: 'pointer',
  },
})

const tabActiveStyle = css(centerStyle, {
  width: 134,
  height: 41,
  borderRadius: '5px 5px 0 0',
  background: '#fff',
  boxShadow: '0px 0px 6px #0000001A',
  position: 'relative',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#F2A40B',
  '&::after': {
    position: 'absolute',
    bottom: -4,
    left: 0,
    height: 6,
    width: '100%',
    content: '""',
    background: '#fff',
    zIndex: 3,
  },
})
