import React, { createContext, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { wholeContainerStyle, mainColumnStyle, HEADER_HEIGHT, SIDE_MENU_WIDTH } from '@/constants/layout'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { LoadingFull } from '@/components/molecules/loading-full'
import { RomStatusBox } from '@/components/organisms/room-manager/indicator/room-status-box'
import { RemainingRoomBox } from '@/components/organisms/room-manager/indicator/remaining-room-box'
import { AllRoomsOnFloor } from '@/components/organisms/room-manager/indicator/all-rooms-on-floor'
import { RoomListType, RoomStatusType, TotalType } from '@/models/room-manager/response/guest-room-indicator'
import dayjs from 'dayjs'
import { fetchGuestRoomIndicator } from '@/apis/aipass'
import { useTranslation } from 'react-i18next'
import { useAutoReload } from '@/hooks/use-auto-reload'

export type RoomStatusFilter = Array<RoomStatusType>
export type RoomTypeFilter = Array<string>

export const RoomManagerIndicatorContext = createContext<{
  doReload?: () => void
  reloadEvent?: string
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
}>({})

export const RoomManagerIndicator: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [reloadEvent, setReloadEvent] = useState<string>()

  const [roomStatusFilter, setRoomStatusFilter] = useState<RoomStatusFilter>()
  const [roomTypeFilter, setRoomTypeFilter] = useState<RoomTypeFilter>()

  const [roomEachFloor, setRoomEachFloor] = useState<RoomListType[]>([])
  const [total, setTotal] = useState<TotalType>()
  const { t } = useTranslation()
  const { setReloadCallback } = useAutoReload()

  useEffect(() => {
    initializeComponent()
    setReloadCallback(doReload, 60000)
  }, [])

  const doReload = () => {
    initializeComponent()
    setReloadEvent(dayjs().toISOString())
  }

  const initializeComponent = () => {
    fetchGuestRoomIndicator(dayjs()).then(res => {
      setRoomEachFloor(res?.roomsEachFloor || [])
      setTotal(res?.total)
    })
  }

  return (
    <RoomManagerIndicatorContext.Provider
      value={{
        setIsLoading,
        doReload,
        reloadEvent,
      }}
    >
      <div css={wholeContainerStyle}>
        <SideMenu />
        <div className="mainColumn" css={mainColumnStyle}>
          <Header title={t('Room management')} />
          <div css={roomContainerStyle}>
            <div style={{ padding: 24, height: '100%' }}>
              <div css={mainWrapperStyle}>
                <div className="box-area">
                  <RomStatusBox setRoomStatusFilter={setRoomStatusFilter} total={total} />
                  <RemainingRoomBox setRoomTypeFilter={setRoomTypeFilter} />
                </div>

                <div className="room-area">
                  <AllRoomsOnFloor roomStatusFilter={roomStatusFilter} roomTypeFilter={roomTypeFilter} roomEachFloor={roomEachFloor} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <LoadingFull isLoading={isLoading} />
      </div>
    </RoomManagerIndicatorContext.Provider>
  )
}

const roomContainerStyle = css({
  position: 'relative',
  height: `calc(100% - ${HEADER_HEIGHT}px)`,
})

const mainWrapperStyle = css({
  minWidth: 1177,
  // 48px = padding space
  width: `calc(100% + 100vw - ${SIDE_MENU_WIDTH}px - 48px - 100%)`,
  background: '#ffffff',
  height: '100%',
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
  overflow: 'auto',
  display: 'flex',
  flexFlow: 'column',
  '.box-area': {
    display: 'flex',
  },
  '.room-area': {
    marginTop: 18,
    marginBottom: 2,
    flexGrow: 1,
  },
})
