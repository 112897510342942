import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/ja'
import 'react-dates/initialize'

// components
import { Button } from '@/components/atoms/button'
import { DatePicker } from '@/components/organisms/reservation/_detail-edit-info/date-picker'
import dayjs from 'dayjs'

export type DetailedReserchType = {
  fileName: string
  searchBeginDate?: number
  searchEndDate?: number
}

type ContainerProps = {
  detailedReserch: DetailedReserchType
  onClickReserch: () => void
  setDetailedReserch: (v: DetailedReserchType) => any
  setIsDropDownReserchPanelOpen: (e?) => void
}

export const DetailedReserchPanel: React.FC<ContainerProps> = ({
  onClickReserch,
  setIsDropDownReserchPanelOpen,
  detailedReserch,
  setDetailedReserch,
}) => {
  const { t } = useTranslation()
  const getTimeList = ({ count = 24, add = 0 } = { count: 24, add: 0 }) => [...Array(count)].map((v, index) => `${index + add}:00`)
  const [beginTimeList] = useState(getTimeList())
  const [endTimeList, setEndTimeList] = useState(getTimeList({ add: 1 }))
  const [date, setDate] = useState<string>('')
  const [beginTime, setBeginTime] = useState<string>('00:00')
  const [endTime, setEndTime] = useState<string>('24:00')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setDetailedReserch({ ...detailedReserch, [name]: value })
  }

  const clearDetailedResearchPanelButton = () => {
    setDate('')
    setBeginTime('00:00')
    setEndTime('24:00')
    setDetailedReserch({ fileName: '', searchBeginDate: undefined, searchEndDate: undefined })
  }

  const searchDetailedReserchPanelButton = () => {
    onClickReserch()
    setIsDropDownReserchPanelOpen()
  }

  useEffect(() => {
    if (!beginTime) return

    const endTimeList = getTimeList({ add: 1 })
    const beginTimeIndex = endTimeList.indexOf(beginTime)
    const endTimeIndex = endTimeList.indexOf(endTime)

    if (beginTimeIndex !== -1) {
      setEndTimeList(endTimeList.slice(beginTimeIndex + 1))
    } else {
      setEndTimeList(endTimeList)
    }

    if (endTimeIndex <= beginTimeIndex) {
      setEndTime('')
    }
  }, [beginTime])

  useEffect(() => {
    if (!date) return

    let searchBeginDate = detailedReserch.searchBeginDate
    let searchEndDate = detailedReserch.searchEndDate

    if (beginTime) {
      const [beginHours, beginMinutes] = beginTime.split(':')

      searchBeginDate = dayjs(date)
        .set('hour', +beginHours)
        .set('minute', +beginMinutes)
        .unix()
    } else {
      searchBeginDate = date ? dayjs(date).unix() : undefined
    }

    if (endTime) {
      const [endHours, endMinutes] = endTime.split(':')

      searchEndDate = dayjs(date)
        .set('hour', +endHours)
        .set('minute', +endMinutes)
        .unix()
    } else {
      searchEndDate = date
        ? dayjs(date)
            .add(1, 'day')
            .unix()
        : undefined
    }

    setDetailedReserch({
      ...detailedReserch,
      searchBeginDate,
      searchEndDate,
    })
  }, [beginTime, endTime, date])

  useEffect(() => {
    if (detailedReserch.searchBeginDate) {
      setBeginTime(dayjs.unix(detailedReserch.searchBeginDate).format('H:mm'))
      setDate(
        dayjs
          .unix(detailedReserch.searchBeginDate)
          .startOf('day')
          .toString(),
      )
    }
    if (detailedReserch.searchEndDate) {
      const endTime = dayjs.unix(detailedReserch.searchEndDate).format('H:mm')

      if (endTime === '0:00') setEndTime('24:00')
      else setEndTime(endTime)

      if (!detailedReserch.searchBeginDate) {
        setDate(
          dayjs
            .unix(detailedReserch.searchEndDate)
            .startOf('day')
            .toString(),
        )
      }
    }
  }, [])

  return (
    <div css={dropDownReserchPanelStyle}>
      <div css={middleReserchPanelStyle}>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('issue date')}</div>
          <div css={checkinTimeStyle}>
            <DatePicker date={date} onDateChange={setDate} />
          </div>
        </div>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('issue time')}</div>
          <div css={checkinTimeStyle}>
            <select css={basicDetailContentsSelectBoxStyle} disabled={!date} value={beginTime} onChange={e => setBeginTime(e.target.value)}>
              {/* <option value={''}>-</option> */}
              {beginTimeList.map(item => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <span css={wavyLineStyle}>~</span>
            <select css={basicDetailContentsSelectBoxStyle} disabled={!date} value={endTime} onChange={e => setEndTime(e.target.value)}>
              {/* <option value={''}>-</option> */}
              {endTimeList.map(item => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('file name')}</div>
          <input
            css={filedInputStyle}
            name="fileName"
            placeholder={t('file name')}
            value={detailedReserch.fileName}
            onChange={handleInputChange}></input>
        </div>
      </div>
      <div css={bottomReserchPanelStyle}>
        <div css={assignButtonContainerStyle}>
          <Button width={92} height={32} buttonType={3} fontSize={12} onClick={clearDetailedResearchPanelButton}>
            {t('Clear')}
          </Button>
          <Button width={93} height={32} buttonType={1} fontSize={12} marginLeft={16} onClick={searchDetailedReserchPanelButton}>
            {t('Search')}
          </Button>
        </div>
      </div>
    </div>
  )
}

const dropDownReserchPanelStyle = css({
  width: 'calc(100% - 228px)',
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 130,
  left: 204,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const middleReserchPanelStyle = css({
  display: 'flex',
  marginTop: '28px',
  borderBottom: '1px solid rgb(242, 242, 242)',
  paddingBottom: '28px',
})

const bottomReserchPanelStyle = css({
  display: 'flex',
  margin: '16px 31px',
})

const fieldBlockStyle = css({
  marginLeft: '32px',
})

const fieldTitleStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  marginBottom: '12px',
  textTransform: 'capitalize',
})

const filedInputStyle = css({
  width: '265px',
  height: 32,
  padding: '8px 16px',
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  textAlign: 'left',
  backgroundSize: 10,
  '::placeholder': {
    color: '#CCCCCC',
  },
})

const assignButtonContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const checkinTimeStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  color: '#676767',
  fontWeight: 'bold',
  marginBottom: 23,
})

const wavyLineStyle = css({
  padding: '0 7px',
})

const basicDetailContentsSelectBoxStyle = css({
  width: 115,
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  padding: '0 30px 0 16px',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '85px center',
  backgroundSize: 10,
  display: 'block',
  cursor: 'pointer',
})
