import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { salesMainContainerStyle, containerTitleStyle } from '@/components/pages/sales-manager/sales/_detail/sales'
import { CashAmountType } from '@/components/pages/sales-manager/sales/detail'

// components
import { Button } from '@/components/atoms/button'

type CashProps = {
  cash: { [key: string]: number }
  register: { [key in string]: string }
  salesTotalDetail: any
  isCashAmountChanged: boolean
  onChangeCashAmount: (e: React.ChangeEvent<HTMLInputElement>, type: CashAmountType) => void
  onClickSaveCashAmount: () => void
}

export const Cash: React.FC<CashProps> = ({
  cash,
  register,
  salesTotalDetail,
  isCashAmountChanged,
  onChangeCashAmount,
  onClickSaveCashAmount,
}) => {
  const { t } = useTranslation()
  const [isVisibleAdvanceCash, setIsVisibleAdvanceCash] = useState<boolean>(false)

  const cashAmountOptions: { value: string; label: string }[] = []
  for (let i = 0; i < 101; i++) {
    cashAmountOptions.push({ value: `${i}`, label: `${i}` })
  }

  const isConfirm: boolean = salesTotalDetail?.isConfirm

  return (
    <div css={cashMainContainerStyle}>
      <div css={containerTitleStyle}>{t('Cash register')}</div>
      <div css={cashHeaderStyle}>
        <div css={cashHeaderContentStyle}>
          <p css={totalDetailStyle}>{t('Cash at cash register')}</p>
          <p css={totalStyle}>¥{register.totalCash ? Number(register.totalCash)?.toLocaleString() : 0}</p>
          <p css={totalDetailStyle}>
            {t('Cash register total')}: ¥{register?.totalCashRegister ? Number(register.totalCashRegister)?.toLocaleString() : 0}
          </p>
          <p css={totalDetailStyle}>{t('Starting amount')}: ¥{register?.startCashRegister ? Number(register.startCashRegister)?.toLocaleString() : 0}</p>
        </div>
        <div css={cashHeaderContentStyle}>
          <p css={totalDetailStyle}>{t('Cash')}</p>
          <p css={totalStyle}>¥{register.salesTotalPaymentCash ? Number(register.salesTotalPaymentCash)?.toLocaleString() : 0}</p>
          <p css={totalCashCashDetailStyle} onClick={() => setIsVisibleAdvanceCash(!isVisibleAdvanceCash)}>
            {t('Cash deposit')}: ¥{register?.salesTotalCash ? Number(register.salesTotalCash)?.toLocaleString() : 0}
            <img src={require(`@/static/images/${isVisibleAdvanceCash ? 'select_gray_up' : 'select_gray'}.svg`)} css={dropDownIconStyle} />
          </p>
          {isVisibleAdvanceCash && (
            <p css={totalAdvanceCashDetailStyle}>
              {t('Internal advance payment')}: ¥{register?.salesTotalAdvanceCash ? Number(register.salesTotalAdvanceCash)?.toLocaleString() : 0}
            </p>
          )}
          <p css={totalDetailStyle}>
            {t('Discount')}: ¥{register?.salesTotalDiscountCash ? Number(register.salesTotalDiscountCash)?.toLocaleString() : 0}
          </p>
        </div>
      </div>
      <div css={moneyWrapperStyle}>
        <div css={moneyInnerStyle}>
          <div css={moneySectionStyle}>
            <div css={moneyDetailStyle}>
              <div css={moneyTypeStyle}>10,000 {t('Yen bill')} ×</div>
              {!isConfirm ? (
                <input
                  name="amount10000"
                  type="text"
                  css={selectBoxStyle}
                  value={cash.amount10000}
                  onChange={e => onChangeCashAmount(e, 'amount10000')}
                  placeholder="0"
                  className="amount10000Style"
                />
              ) : (
                <p>{cash.amount10000}</p>
              )}
              {t('Sheet')}
            </div>
            <div css={moneyDetailStyle}>
              <div css={moneyTypeStyle}>2,000 {t('Yen bill')} ×</div>
              {!isConfirm ? (
                <input
                  name="amount2000"
                  type="text"
                  css={selectBoxStyle}
                  value={cash.amount2000}
                  onChange={e => onChangeCashAmount(e, 'amount2000')}
                  placeholder="0"
                  className="amount2000Style"
                />
              ) : (
                <p>{cash.amount2000}</p>
              )}
              {t('Sheet')}
            </div>
            <div css={moneyDetailStyle}>
              <div css={moneyTypeStyle}>500 {t('Yen coin')} ×</div>
              {!isConfirm ? (
                <input
                  name="amount500"
                  type="text"
                  css={selectBoxStyle}
                  value={cash.amount500}
                  onChange={e => onChangeCashAmount(e, 'amount500')}
                  placeholder="0"
                  className="amount500Style"
                />
              ) : (
                <p>{cash.amount500}</p>
              )}
              {t('Sheet')}
            </div>
            <div css={moneyDetailStyle}>
              <div css={moneyTypeStyle}>50 {t('Yen coin')} ×</div>
              {!isConfirm ? (
                <input
                  name="amount50"
                  type="text"
                  css={selectBoxStyle}
                  value={cash.amount50}
                  onChange={e => onChangeCashAmount(e, 'amount50')}
                  placeholder="0"
                  className="amount50Style"
                />
              ) : (
                <p>{cash.amount50}</p>
              )}
              {t('Sheet')}
            </div>
            <div css={moneyDetailStyle}>
              <div css={moneyTypeStyle}>5 {t('Yen coin')} ×</div>
              {!isConfirm ? (
                <input
                  name="amount5"
                  type="text"
                  css={selectBoxStyle}
                  value={cash.amount5}
                  onChange={e => onChangeCashAmount(e, 'amount5')}
                  placeholder="0"
                  className="amount5Style"
                />
              ) : (
                <p>{cash.amount5}</p>
              )}
              {t('Sheet')}
            </div>
          </div>
          <div css={moneySectionStyle}>
            <div css={moneyDetailStyle}>
              <div css={moneyTypeStyle}>5,000 {t('Yen bill')} ×</div>
              {!isConfirm ? (
                <input
                  name="amount5000"
                  type="text"
                  css={selectBoxStyle}
                  value={cash.amount5000}
                  onChange={e => onChangeCashAmount(e, 'amount5000')}
                  placeholder="0"
                  className="amount5000Style"
                />
              ) : (
                <p>{cash.amount5000}</p>
              )}
              {t('Sheet')}
            </div>
            <div css={moneyDetailStyle}>
              <div css={moneyTypeStyle}>1,000 {t('Yen bill')} ×</div>
              {!isConfirm ? (
                <input
                  name="amount1000"
                  type="text"
                  css={selectBoxStyle}
                  value={cash.amount1000}
                  onChange={e => onChangeCashAmount(e, 'amount1000')}
                  placeholder="0"
                  className="amount1000Style"
                />
              ) : (
                <p>{cash.amount1000}</p>
              )}
              {t('Sheet')}
            </div>
            <div css={moneyDetailStyle}>
              <div css={moneyTypeStyle}>100 {t('Yen coin')} ×</div>
              {!isConfirm ? (
                <input
                  name="amount100"
                  type="text"
                  css={selectBoxStyle}
                  value={cash.amount100}
                  onChange={e => onChangeCashAmount(e, 'amount100')}
                  placeholder="0"
                  className="amount100Style"
                />
              ) : (
                <p>{cash.amount100}</p>
              )}
              {t('Sheet')}
            </div>
            <div css={moneyDetailStyle}>
              <div css={moneyTypeStyle}>10 {t('Yen coin')} ×</div>
              {!isConfirm ? (
                <input
                  name="amount10"
                  type="text"
                  css={selectBoxStyle}
                  value={cash.amount10}
                  onChange={e => onChangeCashAmount(e, 'amount10')}
                  placeholder="0"
                  className="amount10Style"
                />
              ) : (
                <p>{cash.amount10}</p>
              )}
              {t('Sheet')}
            </div>
            <div css={moneyDetailStyle}>
              <div css={moneyTypeStyle}>1 {t('Yen coin')} ×</div>
              {!isConfirm ? (
                <input
                  name="amount1"
                  type="text"
                  css={selectBoxStyle}
                  value={cash.amount1}
                  onChange={e => onChangeCashAmount(e, 'amount1')}
                  placeholder="0"
                  className="amount1Style"
                />
              ) : (
                <p>{cash.amount1}</p>
              )}
              {t('Sheet')}
            </div>
          </div>
        </div>
        {isCashAmountChanged && (
          <div css={saveButtonWrapperStyle}>
            <Button width={120} height={38} buttonType={1} onClick={onClickSaveCashAmount}>
              {t('Save')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const cashMainContainerStyle = css(salesMainContainerStyle, {
  marginRight: 0,
})

const cashHeaderStyle = css({
  display: 'flex',
  height: 170,
  borderTop: '1px solid #F2F2F2',
  background: '#f5f5f5',
})

const cashHeaderContentStyle = css({
  flex: 1,
  padding: '11px 32px',
  ':first-of-type': {
    borderRight: '1px solid #CCCCCC',
  },
})

const totalStyle = css({
  fontSize: 18,
  letterSpacing: 2.7,
  fontWeight: 'bold',
  marginTop: 13,
  marginBottom: 17,
  padding: '0 10px',
})

const totalDetailStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  marginTop: 13,
  color: '#676767',
})

const totalCashCashDetailStyle = css(totalDetailStyle, {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
})

const dropDownIconStyle = css({
  marginLeft: 9,
  width: 9,
  height: 9,
})

const totalAdvanceCashDetailStyle = css(totalDetailStyle, {
  fontSize: 10,
  marginTop: 9,
  marginBottom: 13,
})

const moneyWrapperStyle = css({
  padding: '24px 32px',
})

const moneyInnerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const moneySectionStyle = css({
  flex: 1,
})

const moneyDetailStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 16,
  fontSize: 14,
  color: '#676767',
  fontWeight: 'bold',
})

const moneyTypeStyle = css({
  width: 105,
})
const selectBoxStyle = css({
  width: '37%',
  maxWidth: 98,
  minWidth: 40,
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  appearance: 'none',
  display: 'block',
  paddingLeft: 22,
  marginRight: 5,
})

export const saveButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
})
