export const genders = [
  {
    label: '女性',
    value: 'F',
  },
  {
    label: '男性',
    value: 'M',
  },
]

export const gendersWithTranslate = [
  {
    label: 'Female',
    value: 'F',
  },
  {
    label: 'Male',
    value: 'M',
  },
]
