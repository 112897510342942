import React, { useState } from 'react'
import { DateRangePicker, CalendarDay } from 'react-dates'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import 'react-dates/initialize'

type DateRangeFieldProps = {
  beginDate: string
  endDate: string
  openDirection?: 'up' | 'down'
  changeRangeDate: (beginDate, endDate) => void
}

export const DateRangeField: React.FC<DateRangeFieldProps> = ({ beginDate, endDate, changeRangeDate, openDirection = 'down' }) => {
  const { t } = useTranslation()
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null)

  const _onClose = ({ endDate }) => {
    // After selecting only the starting point, reset if you leave
    if (endDate === null) {
      changeRangeDate('', '')
    }
  }

  const _onChange = event => {
    // In the start point selection, always reset the end point when changing the date
    if (focusedInput === 'startDate') {
      changeRangeDate(event.startDate?.format('YYYY-MM-DD'), '')
      return
    }
    changeRangeDate(event.startDate ? event.startDate?.format('YYYY-MM-DD') : '', event.endDate ? event.endDate?.format('YYYY-MM-DD') : '')
  }

  const _onFocusChange = focusedInput => {
    setFocusedInput(focusedInput)
  }

  return (
    <div css={{ zIndex: 101, display: 'inline-block' }}>
      <DateRangePicker
        displayFormat={t('MM-DD-YYYY')}
        startDate={beginDate ? moment(beginDate) : null}
        startDateId="startDate"
        startDatePlaceholderText={t('Date')}
        endDate={endDate ? moment(endDate) : null}
        endDateId="endDate"
        endDatePlaceholderText={t('Date')}
        focusedInput={focusedInput}
        onDatesChange={_onChange}
        onFocusChange={_onFocusChange}
        onClose={_onClose}
        openDirection={openDirection}
        readOnly={true}
        customArrowIcon={
          <span
            style={{
              color: beginDate !== null ? '#272727' : '#cccccc',
            }}
          >
            ~
          </span>
        }
        numberOfMonths={1}
        minimumNights={0}
        monthFormat={t('MMM-YY')}
        hideKeyboardShortcutsPanel={true}
        firstDayOfWeek={0}
        daySize={42}
        isOutsideRange={() => false}
        customInputIcon={<img alt="calendar" src={require('@/static/images/calendar_icon.svg')} width={14} />}
        navPrev={
          <div className="monthPrev">
            <img
              alt="arrow"
              src={require('@/static/images/icon_caalender_left.svg')}
              onMouseOver={e => (e.currentTarget.src = require('@/static/images/icon_caalender_left.svg'))}
              onMouseOut={e => (e.currentTarget.src = require('@/static/images/icon_caalender_left.svg'))}
            />
          </div>
        }
        navNext={
          <div className="monthNext">
            <img
              alt="arrow"
              src={require('@/static/images/icon_caalender_right.svg')}
              onMouseOver={e => (e.currentTarget.src = require('@/static/images/icon_caalender_right.svg'))}
              onMouseOut={e => (e.currentTarget.src = require('@/static/images/icon_caalender_right.svg'))}
            />
          </div>
        }
        renderCalendarDay={props => (
          <CalendarDay
            {...props}
            renderDayContents={day => (
              <div className="day">
                <span>{day?.format('D')}</span>
              </div>
            )}
          />
        )}
      />
    </div>
  )
}
