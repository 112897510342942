import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs, { Dayjs } from 'dayjs'
import { Cascader } from 'antd'
import moment, { Moment } from 'moment'
import { InputField } from './input-field'

type CascaderOptionsType = { value: any; label: string; children: { value: any; label: string }[] }

type Props = {
  value: Dayjs
  onChange: (value: Dayjs) => void
  min: string | undefined
  max: string | undefined
  width?: string
}

export const MonthCascaderField: React.FC<Props> = ({ value, onChange, min, max, width = '100%' }) => {
  const { t, i18n } = useTranslation()
  const [isMonthCascaderOpen, setIsMonthCascaderOpen] = useState<boolean>(false)
  const [monthOptions, setMonthOptions] = useState<CascaderOptionsType[]>([])

  const buildMonthOptions = (min: Moment, max: Moment) => {
    const minYear = min.get('year')
    const minMonth = min.get('month')
    const maxYear = max.get('year')
    const maxMonth = max.get('month')

    const options: CascaderOptionsType[] = []

    for (let year = minYear; year <= maxYear; year++) {
      let months: { value: any; label: string }[] = []

      if (year === minYear) {
        months = [...Array(12 - minMonth)].map((item, index) => ({
          value: String(index + minMonth),
          label: dayjs()
            .month(index + minMonth)
            .locale(i18n.language)
            .format('MMM'),
        }))
      } else if (year === maxYear) {
        months = [...Array(maxMonth + 1)].map((item, index) => ({
          value: String(index),
          label: dayjs().month(index).locale(i18n.language).format('MMM'),
        }))
      } else {
        months = [...Array(12)].map((item, index) => ({
          value: String(index),
          label: dayjs().month(index).locale(i18n.language).format('MMM'),
        }))
      }

      options.push({
        value: String(year),
        label: String(year + t('+year')),
        children: months,
      })
    }

    setMonthOptions(options)
  }

  useEffect(() => {
    if (min && max) {
      buildMonthOptions(moment(min), moment(max))
    }
  }, [min, max, i18n.language])

  return (
    <div style={{ position: 'relative', color: '#676767' }} className={isMonthCascaderOpen ? 'date-change-container--cascader-active' : ''}>
      <InputField fieldWidth={width} width={width} value={value.format(t('YYYY-MM'))} marginBottom={0} />
      <Cascader
        defaultValue={value ? [String(value.year()), String(value.month())] : []}
        options={monthOptions}
        onChange={([year, month]) => onChange(dayjs(`${year}-${Number(month) + 1}-01`))}
        allowClear={false}
        css={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, opacity: 0 }}
        popupClassName="popup-cascader-customize-date"
        onPopupVisibleChange={value => setIsMonthCascaderOpen(value)}
      />
    </div>
  )
}
