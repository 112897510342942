import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'

// components
import { SelfCheckinHotelInfo } from '@/components/organisms/self-checkin/hotel-info'
import { Loading } from '@/components/molecules/self-checkin/loading'

//apis
import {
  fetchFacilityBasicInfoInSelfCheckin,
  fetchPaymentSettingByHotelId,
  fetchSelfCheckinSetting,
  fetchGuide,
  CurrentVersionKey,
  LatestVersionKey,
} from '@/apis/aipass'

// models
import { SelfCheckinBasicInfoPluginType } from '@/models/self-checkin/results'
import { setHasCustomCheckinPlugin, hasAnyReservationPlugin } from '@/libs/plugins'

export const SelectLanguage: React.FC<{}> = () => {
  const { t, i18n } = useTranslation()
  const { search } = useLocation()
  const history = useHistory()
  const [results, setResults] = useState<SelfCheckinBasicInfoPluginType>()
  const [selfCheckinSetting, setSelfCheckinSetting] = useState({
    requiredJapaneseIdentify: true,
    requiredForeignIdentify: true,
    requiredAccompanyNameInput: false,
    requiredFuriganaInput: false,
    requiredBirthDateInput: false,
    requiredGenderInput: false,
    requiredTelephoneInput: false,
    requiredEmailInput: false,
    requiredAddressInput: false,
    isFrontDescGuidance: false,
  })
  const [paymentSetting, setPaymentSetting] = useState([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const params = new URLSearchParams(search)
  const hotelId = params.get('hotelId')
  const lang = i18n.language
  type LanguageType = 'ja' | 'en' | 'zh' | 'ko'

  const changeLanguage = async (language: LanguageType): Promise<void> => {
    i18n.changeLanguage(language)
    localStorage.setItem('lang', language)

    let isGuidanceDisplay = false
    const guides = await fetchGuide(hotelId).then(res => {
      return res?.customCheckin || []
    })
    if (setHasCustomCheckinPlugin(results?.plugin) && !!guides.length) {
      isGuidanceDisplay = true
    }

    if (hasAnyReservationPlugin(results?.plugin)) {
      history.push({
        pathname: `/self-checkin/search-reservation`,
        state: {
          basicInfo: results?.basicInfo,
          hotelId,
          plugin: results?.plugin,
          ...selfCheckinSetting,
          requiredIdentify: false,
          paymentSetting,
          isGuidanceDisplay,
        },
      })
    } else {
      history.push({
        pathname: `/self-checkin/accommodation-info`,
        state: {
          basicInfo: results?.basicInfo,
          hotelId,
          plugin: results?.plugin,
          ...selfCheckinSetting,
          requiredIdentify: false,
          paymentSetting,
          noneSelectReservation: true,
          isGuidanceDisplay,
        },
      })
    }
  }
  // Basic information acquisition processing
  const _fetchFacilityBasicInfoInSelfCheckin = async () => {
    setIsLoading(true)
    await fetchFacilityBasicInfoInSelfCheckin(hotelId)
      .then(res => {
        if (res !== undefined) {
          setResults(res?.results)
        }
        setIsLoading(false)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const hotelNameLatin = results?.basicInfo[0]?.hotelNameLatin
  const telephone = results?.basicInfo[0]?.telephone
  const frontOpenTime = results?.basicInfo[0]?.frontOpenTime
  const frontCloseTime = results?.basicInfo[0]?.frontCloseTime
  const extImage = results?.basicInfo[0]?.extImageId
  const logoImage = results?.basicInfo[0]?.logoImageId

  const browserBackBlockFunc = () => {
    history.go(1)
  }

  const backgroundStyle = () => {
    if (extImage) {
      return css({
        background: `url(${extImage}) center/cover no-repeat`,
        position: 'relative',
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.36)',
          zIndex: 1,
        },
      })
    } else {
      return css({
        background: '#185AA5 0% 0% no-repeat padding-box',
      })
    }
  }

  useEffect(() => {
    if (!hotelId) {
      return
    }
    _fetchFacilityBasicInfoInSelfCheckin()
    fetchSelfCheckinSetting(hotelId).then(res => {
      if (!res) {
        return
      }
      Object.keys(res).forEach(k => {
        if (Number.isInteger(res[k])) {
          res[k] = Boolean(res[k])
        }
      })
      setSelfCheckinSetting({
        ...res,
        requiredJapaneseIdentify: res.requiredIdentify,
      })
    })

    fetchPaymentSettingByHotelId(hotelId).then(res => {
      const selfCheckinPaymentSetting = res?.settings?.payment_time?.find(item => item.value === 'SELF_CHECKIN')
      if (selfCheckinPaymentSetting?.is_enabled) {
        const temp: any = []
        for (let index = 0; index < selfCheckinPaymentSetting.payment_method.length; index++) {
          const paymentMethod = selfCheckinPaymentSetting.payment_method[index]
          if (paymentMethod.is_enabled) {
            temp.push(paymentMethod.value)
          }
        }
        setPaymentSetting(temp)
      }
    })
  }, [hotelId])

  useEffect(() => {
    if (sessionStorage.getItem(CurrentVersionKey) !== sessionStorage.getItem(LatestVersionKey)) {
      sessionStorage.removeItem(CurrentVersionKey)
      sessionStorage.removeItem(LatestVersionKey)
      return window.location.reload()
    }
    window.history.pushState(null, '', window.location.href)
    window.addEventListener('popstate', browserBackBlockFunc)
    return () => {
      window.removeEventListener('popstate', browserBackBlockFunc)
    }
  }, [])

  useEffect(() => {
    const language = localStorage.getItem('lang')
    if (language) i18n.changeLanguage(language)
  }, [])

  if (!hotelId) {
    history.push({
      pathname: '/dashboard',
    })
    return <></>
  }

  return (
    <>
      <Loading isLoading={isLoading} />
      <div css={[containerStyle, { fontFamily: 'Noto Sans JP' }]}>
        <div css={[backgroundStyle(), { minHeight: '100vh' }]}>
          <div css={pleaseSelectStyle}>
            <p>{t('Please select language')}</p>
          </div>
          <div css={innerStyle}>
            <div css={checkinMethodsStyle}>
              <div css={checkinMethodItemStyle} className={lang === 'ja' ? 'selected' : ''} onClick={() => changeLanguage('ja')}>
                <p>日本語</p>
              </div>
              <div css={checkinMethodItemStyle} className={lang === 'en' ? 'selected' : ''} onClick={() => changeLanguage('en')}>
                <p>English</p>
              </div>
            </div>
            <div css={checkinMethodsStyle}>
              <div css={checkinMethodItemStyle} className={lang === 'zh' ? 'selected' : ''} onClick={() => changeLanguage('zh')}>
                <p>中文</p>
              </div>
              <div css={checkinMethodItemStyle} className={lang === 'ko' ? 'selected' : ''} onClick={() => changeLanguage('ko')}>
                <p>한국어</p>
              </div>
            </div>
          </div>
          <div css={poweredByContainer}>
            {results?.basicInfo && (
              <SelfCheckinHotelInfo
                logoImage={logoImage}
                telephone={telephone}
                frontOpenTime={frontOpenTime}
                frontCloseTime={frontCloseTime}
                usedIn="top"
                position="static"
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const containerStyle = css({
  width: '100%',
  minHeight: '100vh',
})

const innerStyle = css({
  width: 840,
  zIndex: 2,
  position: 'relative',
  margin: '0 auto',
})

const checkinMethodsStyle = css({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: 20,
})

const checkinMethodItemStyle = css({
  width: '31.742vw',
  height: 'calc( 100vh * 0.192 )',
  borderRadius: 10,
  border: '2px solid #fff',
  boxShadow: '0px 3px 10px #00000014',
  textAlign: 'center',
  padding: '56.5px 0',
  cursor: 'pointer',
  margin: '0 10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  img: {
    width: 244,
  },
  p: {
    color: '#fff',
    fontSize: 24,
    letterSpacing: '2.4px',
    lineHeight: '42px',
    fontWeight: 'bold',
  },
})

const poweredByContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  width: '100%',
  height: 96,
  textAlign: 'center',
  position: 'fixed',
  bottom: 0,
  zIndex: 2,
  img: {
    width: 64,
    height: 64,
  },
})
const pleaseSelectStyle = css({
  color: '#FFF',
  position: 'relative',
  zIndex: 2,
  textAlign: 'center',
  fontSize: 21,
  fontWeight: 'bold',
  paddingTop: 'calc(100vh * 0.14)',
  paddingBottom: 'calc( 100vh * 0.05 )',
  p: {
    fontSize: 18,
    lineHeight: '30px',
    letterSpacing: '2.7px',
  },
})
