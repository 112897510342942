export type Guide = {
  customCheckinId: string
  jpTitle: string
  enTitle: string
  zhTitle: string
  koTitle: string
  jpContent: string
  enContent: string
  zhContent: string
  koContent: string
  inputType: 'none' | 'textArea' | 'selectBox'
  jpInputValue: any
  isRequired: boolean
  selectedAnswer?: string
}

export const checkValidateGuidesRequired = (guides: Guide[]): Guide[] => {
  return guides
    .filter(guide => guide.inputType !== 'none')
    .filter(guide => {
      return Boolean(guide.isRequired) && !guide.selectedAnswer
    })
}

export const addValidateGuidesRequired = (customCheckinId: string, guides: Guide[]): Guide[] => {
  return guides.filter(guides => {
    return Boolean(guides.isRequired) && guides.customCheckinId === customCheckinId
  })
}

export const changeValidateGuidesRequired = (customCheckinId: string, validGuides: Guide[]): Guide[] => {
  return validGuides.filter(validGuide => {
    return Boolean(validGuide.isRequired) && validGuide.customCheckinId !== customCheckinId
  })
}
