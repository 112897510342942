import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// constants
import { listContainerStyle } from '@/constants/layout'

type ContainerProps = {}

type Props = {
  basicInfo: any
  isOpenEditPage: boolean
  setIsOpenEditPage: (boolean) => void
}

export const TopImage: React.FC<ContainerProps & Props> = ({ basicInfo, isOpenEditPage, setIsOpenEditPage }) => {
  const { t } = useTranslation()

  return (
    <div css={topImageContainerStyle}>
      <div css={topImageHeaderContainerStyle}>
        <div css={detailsHeaderTitleStyle}>{t('Image')}</div>
        {isOpenEditPage === false && (
          <ul
            css={detailsHeaderEditButtonWrapperStyle}
            onClick={() => {
              setIsOpenEditPage(!isOpenEditPage)
            }}
          >
            <img src={require('@/static/images/edit.svg')} />
            <li>{t('Edit')}</li>
          </ul>
        )}
      </div>
      {isOpenEditPage === false && (
        <>
          <div css={mainContainerStyle}>
            <div css={facilityRecommendStyle}>
              <p css={titleStyle}>{t('Facility recommendation')}</p>
              {basicInfo && (
                <div
                  css={facilityRecommendImageStyle}
                  style={
                    basicInfo && {
                      backgroundImage:
                        basicInfo.facilityRecommendImageId !== null
                          ? `url(${basicInfo.facilityRecommendImageId})`
                          : `url(${require('@/static/images/around-spot/facility_recommend_image.jpg')})`,
                    }
                  }
                />
              )}
            </div>
            <div css={foodStyle}>
              <p css={titleStyle}>{t('Eat')}</p>
              {basicInfo && (
                <div
                  css={foodImageStyle}
                  style={
                    basicInfo && {
                      backgroundImage:
                        basicInfo.foodImageId !== null
                          ? `url(${basicInfo.foodImageId})`
                          : `url(${require('@/static/images/around-spot/food_image.jpg')})`,
                    }
                  }
                />
              )}
            </div>
            <div css={playStyle}>
              <p css={titleStyle}>{t('Play')}</p>
              {basicInfo && (
                <div
                  css={playImageStyle}
                  style={
                    basicInfo && {
                      backgroundImage:
                        basicInfo.playImageId !== null
                          ? `url(${basicInfo.playImageId})`
                          : `url(${require('@/static/images/around-spot/play_image.jpg')})`,
                    }
                  }
                />
              )}
            </div>
            <div css={buyStyle}>
              <p css={titleStyle}>{t('Buy')}</p>
              {basicInfo && (
                <div
                  css={buyImageStyle}
                  style={
                    basicInfo && {
                      backgroundImage:
                        basicInfo.buyImageId !== null
                          ? `url(${basicInfo.buyImageId})`
                          : `url(${require('@/static/images/around-spot/buy_image.jpg')})`,
                    }
                  }
                />
              )}
            </div>
          </div>
          <div css={linkStyle}>
            <p css={linkStringStyle}>{t('Link destination')}</p>
            {basicInfo && basicInfo.facilityRecommendLinkUrl && (
              <a href={basicInfo.facilityRecommendLinkUrl} target="facilityRecommendLinkUrl" css={dropDownAtagStyle}>
                <li css={dropDownHeaderStyle}>{basicInfo.facilityRecommendLinkUrl}</li>
              </a>
            )}
            {basicInfo && (basicInfo.facilityRecommendLinkUrl === '' || basicInfo.facilityRecommendLinkUrl === null) && <div>-</div>}
          </div>
        </>
      )}
    </div>
  )
}

const topImageContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 360,
  marginBottom: 16,
})

const topImageHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const detailsHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
})

const detailsHeaderEditButtonWrapperStyle = css({
  display: 'flex',
  border: '1px solid #CCCCCC',
  margin: '11px 0',
  padding: '0 12px',
  cursor: 'pointer',
  borderRadius: 13,
  img: {
    width: 20,
  },
  li: {
    padding: '6px 0',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#F2A40B',
  },
  ':hover': {
    backgroundColor: '#F2F2F2',
  },
})

const mainContainerStyle = css({
  display: 'flex',
  paddingTop: 32,
  paddingLeft: 32,
  paddingRight: 32,
  paddingBottom: 0,
  justifyContent: 'space-between',
  color: '#272727',
  width: '100%',
})

const facilityRecommendStyle = css({
  width: '40%',
  height: 212,
  marginRight: 19,
})

const foodStyle = css({
  width: 180,
  marginRight: 19,
})

const playStyle = css({
  width: 180,
  marginRight: 19,
})

const buyStyle = css({
  width: 180,
})

const titleStyle = css({
  fontSize: 16,
  marginBottom: 16,
  fontWeight: 'bold',
})

const facilityRecommendImageStyle = css({
  height: 180,
  borderRadius: 5,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
})

const foodImageStyle = css(facilityRecommendImageStyle, {
  width: 180,
})

const playImageStyle = css(facilityRecommendImageStyle, {
  width: 180,
})

const buyImageStyle = css(facilityRecommendImageStyle, {
  width: 180,
})

const linkStyle = css({
  size: 14,
  padding: '24px 32px 0px 32px',
  color: '#272727',
  width: '100%',
  display: 'flex',
  fontSize: 14,
})

const linkStringStyle = css({
  paddingRight: 24.5,
  paddingBottom: 32,
  fontWeight: 'bold',
  minWidth: 85,
})

const dropDownAtagStyle = css({
  '&:link': {
    color: '#272727',
  },
  '&:visited': {
    color: '#272727',
  },
})

const dropDownHeaderStyle = css({
  fontSize: 14,
  color: '#272727',
  '&:hover': {
    color: '#ff5500',
  },
})
