import React, { useState } from 'react'
import { css } from '@emotion/core'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'

// constants
import { headerBarLeftWrapperStyle } from '@/constants/layout'

// components
import { Button } from '@/components/atoms/button'
import { CSVReader } from '@/components/organisms/csv-reader'

const parseOptions = {
  header: true,
  skipEmptyLines: true,
}

type ContainerProps = {
  csvData?: string[][]
  onFileLoad?: (data: any) => void
  onClickAddButton: () => void
  enabledCsvButton?: boolean
}

export const SettingGuestRoomEditButtons: React.FC<ContainerProps> = ({
  csvData,
  onFileLoad,
  onClickAddButton,
  enabledCsvButton = true,
}) => {
  const { t } = useTranslation()
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)

  return (
    <div css={editButtonWrapperStyle}>
      <div css={headerBarLeftWrapperStyle}>
        <Button
          buttonType={1}
          width={107}
          height={32}
          marginRight={16}
          fontSize={12}
          icon={require('@/static/images/mobile_request/add.svg')}
          onClick={() => onClickAddButton()}
        >
          {t('Add new')}
        </Button>
      </div>
      {enabledCsvButton && onFileLoad && (
        <div>
          <Button
            buttonType={4}
            width={100}
            height={32}
            fontSize={12}
            icon={require('@/static/images/csv.svg')}
            onClick={() => setIsDropDownOpen(!isDropDownOpen)}
          >
            {t('CSV')} <img src={require('@/static/images/select_gray.svg')} css={dropDownIcon} />
          </Button>
          {isDropDownOpen && (
            <div css={dropDownStyle}>
              <ul css={dropDownBodyStyle}>
                <li css={dropDownContentStyle}>
                  <CSVReader
                    inputStyle={{ display: 'none', cursor: 'pointer' }}
                    label={t('Upload')}
                    parserOptions={parseOptions}
                    cssInputClass={'dropDownTitleStyle'}
                    onFileLoaded={data => {
                      onFileLoad(data)
                      setIsDropDownOpen(false)
                    }}
                  />
                </li>
                {csvData && (
                  <li
                    css={dropDownContentStyle}
                    onClick={() => {
                      setIsDropDownOpen(false)
                    }}
                  >
                    <CSVLink filename={'guest-room.csv'} data={csvData}>
                      <p css={dropDownTitleStyle}>{t('Download')}</p>
                    </CSVLink>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const editButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 32px 19px',
  backgroundColor: '#fff',
})

const dropDownIcon = css({
  marginLeft: 8,
})

const dropDownStyle = css({
  width: 180,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 185,
  right: 50,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownBodyStyle = css({})

const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  fontSize: 12,
  color: 'rgba(0, 0, 0, 0.65)',
})
