import { css } from '@emotion/core'

export const sectionStyle = css({
  backgroundColor: '#FFF',
  boxShadow: '0px 0px 6px #0000001A',
  borderBottom: '1px solid #F2F2F2',
  borderRadius: 5,
})

export const sectionHeaderStyle = css({
  display: 'flex',
  alignItems: 'center',
  paddingRight: '24px',
  height: 49,
  fontSize: 16,
  fontWeight: 'bold',
  marginLeft: 32,
  color: '#272727',
})
