/**
 * Set sessionStorage
 */
export const setItemStorage = (
  name: string,
  content: string | object | any[],
  type: 'sessionStorage' | 'localStorage' = 'sessionStorage',
) => {
  if (!name) return
  return window[type].setItem(name, JSON.stringify(content))
}
/**
 * Get sessionStorage
 */
export const getItemStorage = (name: string, type: 'sessionStorage' | 'localStorage' = 'sessionStorage') => {
  if (!name) return
  return JSON.parse(window[type].getItem(name)!) || ''
}
/**
 * Clear sessionStorage
 */
export const removeItemStorage = (name: string, type: 'sessionStorage' | 'localStorage' = 'sessionStorage') => {
  if (!name) return
  return window[type].removeItem(name)
}
