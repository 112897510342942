export const DashboardType = {
  Daily: 'daily',
  Monthly: 'monthly',
} as const
export type DashboardType = (typeof DashboardType)[keyof typeof DashboardType]

export type SalesSummaryType = {
  price: number
  discount: number
  soldRoomsCount: number
  guestCount: number
  OCCRate: number
  ADR: number
  revPAR: number
}
export type SalesDetailType = {
  salesDate: string
  salesManagerId: string | undefined
  isConfirmed: boolean
  price: number
  discount: number
  soldRoomsCount: number
  guestCount: number
  OCCRate: number
  ADR: number
  revPAR: number
}
export type DashboardSalesType = {
  summary: SalesSummaryType | undefined
  diff: SalesSummaryType | undefined
  per: SalesSummaryType | undefined
  details: Array<SalesDetailType>
  openSalesManagerId: string
}
