import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import { DailyProps } from '@/components/pages/room-manager/_daily/daily-props'

type DailyListHeadProps = Pick<DailyProps, 'today' | 'dates'>

export const DailyListHead: React.FC<DailyListHeadProps> = ({ today, dates }) => {
  const { t } = useTranslation()
  return (
    <div css={daysListWrapper} id="daysListHeader">
      <div css={dayListMonthListWrapper}>
        {dates &&
          dates.map(date => {
            return (
              <div key={date.days} css={dayListMonthStyle}>
                {date.year !== '' && `${date.year} ${t('Year')} ${date.month} ${t('Month')}`}
              </div>
            )
          })}
      </div>
      <div css={datesWrapperStyle}>
        {dates &&
          dates.map(date => {
            return (
              <ul key={date.days} css={date.days == today ? dayListTodayStyle : dayListDaysStyle}>
                <li css={date.days == today ? dayListTodayWeekStyle : dayListWeekStyle}>{date.week}</li>
                <li css={date.days == today ? dayListTodayDayStyle : dayListDateStyle}>{date.date}</li>
              </ul>
            )
          })}
      </div>
    </div>
  )
}

const centerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const daysListWrapper = css({
  position: 'sticky',
  top: 0,
  zIndex: 3,
})

const dayListMonthListWrapper = css({
  display: 'flex',
})

const dayListMonthStyle = css({
  padding: '10px 0 11px 16px',
  whiteSpace: 'nowrap',
  height: 32,
  borderBottom: '1px solid #F2F2F2',
  fontSize: 11,
  letterSpacing: 0.55,
  color: '#676767',
  fontWeight: 'bold',
  background: '#FFFFFF',
  position: 'sticky',
  top: 0,
  minHeight: 32,
  zIndex: 2,
  width: 111,
})

const datesWrapperStyle = css({
  display: 'flex',
})

const dayListDaysStyle = css({
  height: 58,
  minWidth: 111,
  background: '#fff',
  textAlign: 'center',
  borderRight: '1px solid #F2F2F2',
  borderBottom: '1px solid #E3E3E3',
  paddingTop: 12,
  position: 'sticky',
  top: 32,
  minHeight: 58,
  zIndex: 2,
})

const dayListTodayStyle = css(dayListDaysStyle, {
  background: '#FFFDE3',
})

const dayListWeekStyle = css({
  fontSize: 10,
  letterSpacing: 0.5,
  margin: '0 auto 9px',
  fontWeight: 'bold',
  color: '#A3A3A3',
})

const dayListTodayWeekStyle = css(dayListWeekStyle, {
  color: '#F2A40B',
  margin: '0 auto 4px',
})

const dayListDateStyle = css({
  fontSize: 16,
  letterSpacing: 0.8,
  '&:lastChild': {
    borderRight: 'none',
  },
})

const dayListTodayDayStyle = css(dayListDateStyle, centerStyle, {
  fontSize: 14,
  width: 28,
  height: 28,
  borderRadius: '50%',
  color: '#F2A40B',
  margin: '0 auto',
})
