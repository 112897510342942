import { useEffect } from 'react'

export const useSameTabsReload = ({ key }) => {
  const getKeyValue = () => Number(localStorage.getItem(key) || 0)

  const beforeLeavePage = () => {
    if (getKeyValue() <= 1) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, String(getKeyValue() - 1))
    }
  }

  const onChangeStore = e => {
    if (!key) return

    if (e.key == key && e.newValue == 'reload') {
      window.removeEventListener('beforeunload', beforeLeavePage)
      window.location.reload()
    }
  }

  const reloadSameTabs = () => {
    if (!key) return

    localStorage.setItem(key, 'reload')
    localStorage.setItem(key, String(1))
  }

  useEffect(() => {
    if (!key) return

    localStorage.setItem(key, String((getKeyValue() || 0) + 1))

    window.addEventListener('storage', onChangeStore)
    window.addEventListener('beforeunload', beforeLeavePage)

    return () => {
      window.removeEventListener('storage', onChangeStore)
      window.addEventListener('beforeunload', beforeLeavePage)
    }
  }, [key])

  return {
    reloadSameTabs,
  }
}
