import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { SerializedStyles, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'

type Props<T = string> = {
  tabs: ReadonlyArray<{
    path: string
    name: T
  }>
  currentTab: T
  customTabStyle?: SerializedStyles
}

export const SettingTab = <T,>({ currentTab, tabs, customTabStyle }: React.PropsWithChildren<Props<T>>) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  return (
    <div css={settingTabContainerStyle}>
      {tabs.map(tab => (
        <div
          key={`tab-${uuid()}`}
          css={[currentTab === tab.name ? settingTabActiveStyle : settingTabStyle, customTabStyle]}
          onClick={() => history.push({ pathname: tab.path, search: location.search })}
        >
          {t(tab.name as string)}
        </div>
      ))}
    </div>
  )
}
const centerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const settingTabContainerStyle = css({
  height: 45,
  display: 'flex',
})

const settingTabStyle = css(centerStyle, {
  width: 134,
  height: 45,
  borderRadius: '5px 5px 0 0',
  background: '#F5F5F5',
  boxShadow: '0px 0px 6px #0000001A',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#676767',
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.8,
  },
})

const settingTabActiveStyle = css(centerStyle, {
  width: 134,
  height: 41,
  borderRadius: '5px 5px 0 0',
  background: '#fff',
  boxShadow: '0px 0px 6px #0000001A',
  position: 'relative',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#F2A40B',
  '&::after': {
    position: 'absolute',
    bottom: -4,
    left: 0,
    height: 6,
    width: '100%',
    content: '""',
    background: '#fff',
    zIndex: 3,
  },
})
