import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'

// apis
import { fetchSalesCashregister, updateSalesCashregister } from '@/apis/aipass'

// components
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { EditFooter } from '@/components/organisms/edit-footer'

type ContainerProps = {}

export const SettingSalesManagerRegisterEdit: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()

  const [cashRegisterSetting, setCashRegisterSetting] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()
  const location = useLocation()

  const onChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCashRegisterSetting(e.target.value)
  }

  const onSaveSalesSetting = () => {
    setIsLoading(true)
    updateSalesCashregister(Number(cashRegisterSetting))
      .then(() => {
        history.push({ pathname: '/setting/sales-manager/register', search: location.search })
      })
      .catch(() => {
        setIsLoading(false)
        console.log(t('Communication failed'))
      })
  }

  const _fetchSalesCashregister = async () => {
    setIsLoading(true)
    await fetchSalesCashregister()
      .then(res => {
        setCashRegisterSetting(res?.price)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  useEffect(() => {
    _fetchSalesCashregister()
  }, [])

  const footerContent = <EditFooter onSave={onSaveSalesSetting} onCancel={history.goBack} />
  const windows = useWindowSize()
  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <>
        <div
          css={settingSalesEditContainerStyle}
          style={{
            minHeight: windows.height - 162,
          }}
        >
          <div css={settingSalesEditHeaderStyle}>
            <p>{t('Cash register')}</p>
          </div>
          <div css={cashRegisterSettingContainerStyle}>
            <p>{t('Starting amount')}</p>
            <div>
              <input
                name="price"
                type="text"
                placeholder="0000"
                css={inputStyle}
                value={cashRegisterSetting}
                onChange={e => onChangeState(e)}
              />
            </div>
          </div>
        </div>
      </>
    </SettingsLayout>
  )
}

const settingSalesEditContainerStyle = css({
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
  background: '#FFF',
  padding: '0 0 23px',
})

const settingSalesEditHeaderStyle = css({
  height: 50,
  padding: '16px 32px',
  borderBottom: '1px solid #F2F2F2',
  p: {
    fontSize: 18,
    fontWeight: 'bold',
  },
})

const inputStyle = css({
  width: '100%',
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  padding: '0 16px',
  '&::placeholder': {
    color: '#CCCCCC',
  },
})

const cashRegisterSettingContainerStyle = css({
  padding: '24px 34px',
  p: {
    color: '#272727',
    fontWeight: 'bold',
    fontSize: 16,
    paddingBottom: 16,
  },
  div: {
    width: 104,
    input: inputStyle,
  },
})
