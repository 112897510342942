import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { LoaderContextCreator } from '@/contexts/loader'
import { dashboardPageContainerStyle, tableHeaderThStyle } from '@/constants/layout'
import { Button, ButtonType } from '@/components/atoms/button'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { DashboardPageLayout } from '@/components/layouts/dashboard-page-layout'
import { useWindowSize } from 'react-use'
import { ReservationSearchModal } from '@/components/organisms/invoice-manager/reservation-search-modal'
import { InvoiceDetailModal } from '@/components/organisms/invoice-manager/invoice-detail-modal'
import { InvoiceSearchType, InvoiceStatusType, InvoiceType, ReservationType } from '@/models/sales-invoice'
import { getInvoice } from '@/apis/aipass'
import { InvoiceListRow } from '@/components/organisms/invoice-manager/row'
import { InvoiceSearch } from '@/components/organisms/invoice-manager/search'
import { InvoiceStatusTips } from '@/components/organisms/invoice-manager/invoice-status-tips'

export const InvoiceManagerList: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { isLoading, setIsLoading } = useContext(LoaderContextCreator())
  const [isOpenSearchPanel, setIsOpenSearchPanel] = useState<boolean>(false)
  const [isOpenReservationSearchModal, setIsOpenReservationSearchModal] = useState<boolean>(false)
  const [isOpenInvoiceDetailModal, setIsOpenInvoiceDetailModal] = useState<boolean>(false)
  const [initialReservations, setInitialReservations] = useState<ReservationType[]>([])
  const [invoiceSearchResult, setInvoiceSearchResult] = useState<{
    total: number
    hasPrev: boolean
    hasNext: boolean
    result: InvoiceType[]
  }>()
  const [invoiceSearchParams, setInvoiceSearchParams] = useState<InvoiceSearchType>({
    filterStatus: [InvoiceStatusType.InProgress, InvoiceStatusType.Expired],
  })
  const windows = useWindowSize()

  const doSearch = async () => {
    setIsLoading(true)
    setInvoiceSearchResult(invoiceSearchResult ? { ...invoiceSearchResult, result: [] } : undefined)
    const res = await getInvoice(invoiceSearchParams)
    setIsOpenSearchPanel(false)
    setIsLoading(false)
    if (!res) {
      return
    }
    setInvoiceSearchResult(res)
  }

  const onCloseInvoiceDetailModel = () => {
    setIsOpenInvoiceDetailModal(false)
    doSearch()
  }

  const onChangePage = (page: number) => {
    setInvoiceSearchParams({
      ...invoiceSearchParams,
      page,
    })
  }

  useEffect(() => {
    doSearch()
  }, [invoiceSearchParams])

  return (
    <DashboardPageLayout title={t('Invoice management')}>
      <>
        <div css={[dashboardPageContainerStyle, { position: 'relative' }]}>
          <div css={headerSettingOperationLogStyle}>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <div css={headerDetailStyle}>{t('Invoice list')}</div>

              <Button
                buttonType={ButtonType.NonStyle}
                width={i18n.language === 'en' ? 118 : 90}
                height={32}
                fontSize={12}
                marginLeft={24}
                marginRight={24}
                onClick={() => setIsOpenSearchPanel(!isOpenSearchPanel)}
              >
                {t('Detailed search')}
              </Button>
            </div>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <Button
                buttonType={ButtonType.Primary}
                width={i18n.language === 'en' ? 127 : 107}
                height={32}
                fontSize={12}
                marginLeft={24}
                marginRight={24}
                onClick={() => setIsOpenReservationSearchModal(true)}
              >
                {t('New invoice')}
              </Button>
              <AccommodationPaging page={invoiceSearchParams?.page || 1} setPage={onChangePage} total={invoiceSearchResult?.total || 0} />
            </div>
          </div>
          <div css={{ display: isOpenSearchPanel ? 'block' : 'none' }}>
            <InvoiceSearch onSearch={setInvoiceSearchParams} />
          </div>
          <div css={{ overflow: 'auto', height: windows.height - 154 }}>
            {invoiceSearchResult?.total ? (
              <table css={tableStyle}>
                <thead>
                  <tr>
                    <th css={tableHeaderThStyle} className="col-creation-date">
                      {t('Creation date')}
                    </th>
                    <th css={tableHeaderThStyle} className="col-invoice-status">
                      <div>
                        {t('Invoice status')}
                        <InvoiceStatusTips />
                      </div>
                    </th>
                    <th css={tableHeaderThStyle} className="col-invoice-name">
                      {t('Invoice name')}
                    </th>
                    <th css={tableHeaderThStyle} className="col-amount-bill">
                      {t('Amount bill')}
                    </th>
                    <th css={tableHeaderThStyle} className="col-payment-at">
                      {t('Payment at')}
                    </th>
                    <th css={tableHeaderThStyle} className="col-collected-at">
                      {t('Collected at')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceSearchResult.result.map(item => (
                    <InvoiceListRow key={item.id} invoice={item} onUpdate={doSearch} />
                  ))}
                </tbody>
              </table>
            ) : (
              !isLoading && (
                <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <h1>{t('No data available')}</h1>
                </div>
              )
            )}
          </div>
        </div>
        {isOpenReservationSearchModal && (
          <ReservationSearchModal
            onClose={() => setIsOpenReservationSearchModal(false)}
            onNext={(reservations: ReservationType[]) => {
              setInitialReservations(reservations)
              setIsOpenInvoiceDetailModal(true)
            }}
          />
        )}
        {isOpenInvoiceDetailModal && <InvoiceDetailModal onClose={onCloseInvoiceDetailModel} initialReservations={initialReservations} />}
      </>
    </DashboardPageLayout>
  )
}

const headerSettingOperationLogStyle = css({
  height: '50px',
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const headerDetailStyle = css({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: '16px 0',
  color: '#272727',
})

const tableStyle = css({
  width: '100%',
  borderCollapse: 'collapse',
  'th, td': {
    ':first-of-type': { paddingLeft: 32 },
  },
  '.col-creation-date': { width: 176 },
  '.col-invoice-status': {
    width: 176,
    zIndex: 10,
    '> div': { display: 'flex', alignItems: 'center' },
  },
  '.col-amount-bill': { width: 176 },
  '.col-payment-at': { width: 176 },
  '.col-collected-at': { width: 176 },
})
