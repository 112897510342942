import React, { useContext, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// components
import { Button } from '@/components/atoms/button'

//api
import { fetchAdvanceCompany, fetchSalesAccountsReceivable, fetchSalesPayment } from '@/apis/aipass'

import { Cascader, Select } from 'antd'
import { PosCalculateModal } from '../../accommodation-management/pos-calculate-modal'
import { PluginCode, checkActivePlugin } from '@/libs/plugins'
import { AccountContext } from '@/contexts/account'
import { TransactionType } from '@/models/smaregi/transaction'

type Props = {
  transaction?: TransactionType
  reservationId?: string
  checkinId?: string
  onAdjustSales: (selected: { salesPaymentId: string; accountsReceivableId: string; companyId: string }) => void
  onClose: (isUpdated: boolean) => void
}

type CascaderOptionType = {
  value: string
  label: string
  disabled?: boolean
  code?: string
  children?: Array<CascaderOptionType>
}

export const AdjustmentPaymentForm: React.FC<Props> = ({
  transaction,
  reservationId,
  checkinId,
  onAdjustSales,
  onClose,
}) => {
  const { t, i18n } = useTranslation()
  const [isVisiblePaymentForm, setIsVisiblePaymentForm] = useState<boolean>(false)

  const [companyId, setCompanyId] = useState<string>('')
  const [advanceCompanyOptions, setAdvanceCompanyOptions] = useState<{
    paymentCompanies: CascaderOptionType[]
    paymentMethodId: string
  }>()

  const defaultPaymentOption = { value: '', label: t('Please select') }
  const [salesPaymentId, setSalesPaymentId] = useState<string>('')
  const [salesPaymentOptions, setSalesPaymentOptions] = useState<CascaderOptionType[]>([defaultPaymentOption])

  const defaultReceivableOption = { value: '', label: '-' }
  const [accountsReceivableId, setAccountsReceivableId] = useState<string>('')
  const [accountsReceivableOptions, setAccountsReceivableOptions] = useState<CascaderOptionType[]>([defaultReceivableOption])

  const [isShowPosCalcButton, setIsShowPosCalcButton] = useState<boolean>(false)
  const [showPosCalculateModal, setShowPosCalculateModal] = useState<boolean>(false)
  const { plugins } = useContext<any>(AccountContext)
  const isActivePluginSmaregi = checkActivePlugin(plugins, PluginCode.Smaregi)

  useEffect(() => {
    setIsShowPosCalcButton(isActivePluginSmaregi && !isVisiblePaymentForm)
  }, [isActivePluginSmaregi, isVisiblePaymentForm])

  const onChangePaymentForm = () => {
    setIsVisiblePaymentForm(!isVisiblePaymentForm)
    setSalesPaymentId('')
    setCompanyId('')
    setAccountsReceivableId('')
  }

  const displayRender = label => label[label.length - 1]

  const _adjustSales = () => {
    onAdjustSales({ salesPaymentId, accountsReceivableId, companyId })
    setIsVisiblePaymentForm(false)
  }

  const _fetchAdvanceCompany = () => {
    fetchAdvanceCompany().then(res => {
      setAdvanceCompanyOptions({
        paymentCompanies: (res.paymentCompanies || []).map(item => ({
          value: item.id,
          label: item.name,
          disabled: !item.currentBalance,
        })),
        paymentMethodId: res.paymentMethod?.id,
      })
    })
  }

  const _fetchSalesAccountsReceivable = () => {
    fetchSalesAccountsReceivable().then(res => {
      if (!res?.accountsReceivables) {
        return
      }
      setAccountsReceivableOptions([
        defaultReceivableOption,
        ...res.accountsReceivables.map(item => ({
          value: item.id,
          label: item.name,
          code: item.code,
        })),
      ])
    })
  }

  const _fetchSalesPayment = () => {
    fetchSalesPayment().then(res => {
      if (!res?.salesPayment) {
        return
      }
      setSalesPaymentOptions([
        defaultPaymentOption,
        ...res?.salesPayment.map(item => ({
          value: item.id,
          label: item.name,
          children: item.id === advanceCompanyOptions?.paymentMethodId ? advanceCompanyOptions?.paymentCompanies : undefined,
          code: item.methodCode,
        })),
      ])
    })
  }

  const changedSalesPayment = (value: string[]) => {
    setSalesPaymentId(value[0])
    autoCompleteWithPaymentMethod(value[0])
    setCompanyId(value.length === 2 ? value[1] : '')
  }

  const autoCompleteWithPaymentMethod = (paymentMethodMasterId: string) => {
    const selectedPaymentMethod = salesPaymentOptions.find(salesPayment => salesPayment.value === paymentMethodMasterId)
    const alignmentAccountReceivable = accountsReceivableOptions?.find(receivable => receivable.code === selectedPaymentMethod?.code)
    setAccountsReceivableId(alignmentAccountReceivable ? alignmentAccountReceivable.value : '')
  }

  useEffect(() => {
    if (!advanceCompanyOptions) {
      return
    }
    _fetchSalesPayment()
    _fetchSalesAccountsReceivable()
  }, [advanceCompanyOptions])

  useEffect(() => {
    _fetchAdvanceCompany()
  }, [])

  return (
    <>
      {!isVisiblePaymentForm && (
        <div css={buttonWrapperStyle}>
          <Button width={192} height={32} buttonType={3} onClick={onChangePaymentForm}>
            {t('Calculate')}
          </Button>
        </div>
      )}
      {isVisiblePaymentForm && (
        <div css={salesPaymentContainerStyle}>
          <div css={textStyle}>
            {t('Method of payment')}
            <sup>{i18n.language === 'en' ? '*' : '※'}</sup>
          </div>
          <div css={salesPaymentSelectWrapper}>
            <Cascader
              options={salesPaymentOptions}
              expandTrigger="hover"
              className={'edit-mode-cascader cascader-customize'}
              displayRender={displayRender}
              value={[salesPaymentId, companyId]}
              popupClassName="popup-cascader-customize"
              onChange={changedSalesPayment}
              allowClear={false}
              suffixIcon={<span></span>}
            />
          </div>

          <div css={textStyle}>{t('Accounts receivable')}</div>
          <div css={salesPaymentSelectWrapper}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={true}
              suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
              onChange={value => setAccountsReceivableId(value)}
              value={accountsReceivableId}
            >
              {accountsReceivableOptions.map((accountsReceivable, index) => (
                <Select.Option key={index} value={accountsReceivable.value}>
                  {accountsReceivable.label}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div css={buttonsWrapperStyle}>
            <Button buttonType={3} width={100} height={32} fontSize={12} marginRight={16} onClick={onChangePaymentForm}>
              {t('Cancel')}
            </Button>
            <Button
              width={80}
              height={32}
              buttonType={salesPaymentId ? 1 : 5}
              fontSize={12}
              onClick={_adjustSales}
              isDisabled={!salesPaymentId}
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      )}

      {isShowPosCalcButton && (
        <div css={buttonWrapperStyle}>
          <Button
            width={192}
            height={32}
            buttonType={3}
            onClick={() => setShowPosCalculateModal(true)}
            customCss={css({ borderColor: '#CCCCCC' })}
          >
            {t('POS Calculate')}
          </Button>
        </div>
      )}
      {showPosCalculateModal && (
        <PosCalculateModal
          onClose={(isUpdated: boolean) => {
            setShowPosCalculateModal(false)
            onClose(isUpdated)
          }}
          reservationId={reservationId}
          transaction={transaction}
          checkinId={checkinId}
        />
      )}
    </>
  )
}
const buttonWrapperStyle = css({
  margin: '0 auto',
  width: 192,
  marginTop: 16,
})
const salesPaymentContainerStyle = css({
  border: '1px solid #CCCCCC',
  borderRadius: 5,
  background: '#F2F2F2',
  padding: 24,
  marginTop: 16,
})
const textStyle = css({
  fontWeight: 'bold',
  color: '#676767',
  fontSize: 12,
})
const buttonsWrapperStyle = css({
  display: 'flex',
  justifyContent: 'center',
})
const salesPaymentSelectWrapper = css({
  margin: '12px 0 16px 0',
  '.ant-cascader-picker-label': {
    fontSize: 14,
    fontWeight: 'normal',
    letterSpacing: '1.4px',
    color: '#676767',
  },
  '.ant-cascader-picker-label:hover + .ant-cascader-input': {
    borderColor: '#CCCCCC',
    borderRightWidth: 'unset',
  },
  '.ant-cascader-picker:focus .ant-cascader-input': {
    borderColor: '#CCCCCC',
    borderRightWidth: 'unset',
    boxShadow: 'unset',
  },
  '.ant-input:focus': {
    boxShadow: 'unset',
  },
  '.cascader-customize': {
    width: '100%',
    marginRight: '0.5%',
    marginLeft: 0,
    height: 32,
    borderRadius: 16,
    input: {
      borderRadius: 16,
      border: '1px solid #CCCCCC',
    },
  },
  '.edit-mode-cascader': {
    backgroundImage: `url(${require('@/static/images/select.svg')})`,
    backgroundPosition: '95% center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 9,
  },
})
