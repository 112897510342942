import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/ja'
import 'react-dates/initialize'

// components
import { Button } from '@/components/atoms/button'
import { DatePicker } from '@/components/organisms/reservation/_detail-edit-info/date-picker'

type ContainerProps = {
  onClickReserch: () => void
  onClickClearReserch: () => void
  setIsDropDownReserchPanelOpen: (e?) => void
  detailedReserch: any
  setDetailedReserch: any
}

export const DetailedReserchPanel: React.FC<ContainerProps> = ({
  onClickReserch,
  onClickClearReserch,
  setIsDropDownReserchPanelOpen,
  detailedReserch,
  setDetailedReserch,
}) => {
  const { t } = useTranslation()

  // input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setDetailedReserch({ ...detailedReserch, [name]: value })
  }

  // date change
  const setSearchBeginDate = date => {
    setDetailedReserch({ ...detailedReserch, searchBeginDate: date })
  }
  const setSearchEndDate = date => {
    setDetailedReserch({ ...detailedReserch, searchEndDate: date })
  }

  // clear button
  const clearDetailedResearchPanelButton = () => {
    onClickClearReserch()
  }

  // search button
  const searchDetailedReserchPanelButton = () => {
    onClickReserch()
    setIsDropDownReserchPanelOpen()
  }

  return (
    <div css={dropDownReserchPanelStyle}>
      <div css={topReserchPanelStyle}>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Full name')}</div>
          <input
            css={filedInputStyle}
            placeholder={t('John Doe')}
            name="name"
            value={detailedReserch.name}
            onChange={handleInputChange}
          ></input>
        </div>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Furigana')}</div>
          <input
            css={filedInputStyle}
            placeholder="ヤマダ　タロウ"
            name="nameKana"
            value={detailedReserch.nameKana}
            onChange={handleInputChange}
          ></input>
        </div>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Reservation ID')}</div>
          <input
            css={filedInputStyle}
            placeholder="12345678"
            name="searchReservationId"
            value={detailedReserch.searchReservationId}
            onChange={handleInputChange}
          ></input>
        </div>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Room number')}</div>
          <input
            css={filedInputStyle}
            placeholder="0101"
            name="roomNumber"
            value={detailedReserch.roomNumber}
            onChange={handleInputChange}
          ></input>
        </div>
      </div>
      <div css={middleReserchPanelStyle}>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Check-out date')}</div>
          <div css={checkinTimeStyle}>
            <DatePicker
              date={detailedReserch.searchBeginDate !== '' ? detailedReserch.searchBeginDate : ''}
              onDateChange={setSearchBeginDate}
            />
            <span css={wavyLineStyle}>~</span>
            <DatePicker date={detailedReserch.searchEndDate !== '' ? detailedReserch.searchEndDate : ''} onDateChange={setSearchEndDate} />
          </div>
        </div>
      </div>
      <div css={bottomReserchPanelStyle}>
        <div css={assignButtonContainerStyle}>
          <Button width={92} height={32} buttonType={3} fontSize={12} onClick={clearDetailedResearchPanelButton}>
            {t('Clear')}
          </Button>
          <Button width={93} height={32} buttonType={1} fontSize={12} marginLeft={16} onClick={searchDetailedReserchPanelButton}>
            {t('Search')}
          </Button>
        </div>
      </div>
    </div>
  )
}

const dropDownReserchPanelStyle = css({
  width: '84%',
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 197,
  left: 204,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const topReserchPanelStyle = css({
  display: 'flex',
  marginTop: '32px',
  borderBottom: '1px solid rgb(242, 242, 242)',
  paddingBottom: '28px',
})

const middleReserchPanelStyle = css({
  display: 'flex',
  marginTop: '28px',
  borderBottom: '1px solid rgb(242, 242, 242)',
  paddingBottom: '28px',
})

const bottomReserchPanelStyle = css({
  display: 'flex',
  margin: '16px 31px',
})

const fieldBlockStyle = css({
  marginLeft: '32px',
})

const fieldTitleStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  marginBottom: '12px',
})

const filedInputStyle = css({
  width: '265px',
  height: 32,
  padding: '8px 16px',
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  textAlign: 'left',
  backgroundSize: 10,
  '::placeholder': {
    color: '#CCCCCC',
  },
})

const assignButtonContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const checkinTimeStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  color: '#676767',
  fontWeight: 'bold',
  marginBottom: 23,
})

const wavyLineStyle = css({
  padding: '0 7px',
})
