import React from 'react'
import { AssetType, MapDetailType } from '@/models/guest-app/asset'
import { useLocation } from 'react-router-dom'
import { MapAssetList } from './map-asset-list'
import { MapCategoryList } from './map-category-list'

type Props = {
  asset: AssetType & { details: MapDetailType[] }
}

export const MapAssetTemplate: React.FC<Props> = ({ asset }) => {
  const location = useLocation()
  const listPath = `/setting/guest-app/asset/${asset.id}`
  return location.pathname === listPath ? <MapAssetList asset={asset} /> : <MapCategoryList asset={asset} />
}
