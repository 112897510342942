import { css } from '@emotion/core'

export const tabStyle = css({
  display: 'flex',
  justifyContent: 'left',
  backgroundColor: '#F2F2F2',
  fontSize: 14,
  fontWeight: 'bold',
  padding: 6,
  paddingBottom: 0,
  marginLeft: -6,
  overflow: 'hidden',
  position: 'relative',
  zIndex: 1,
})

export const nonActiveTabStyle = css({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 160,
  height: 45,
  color: '#676767',
  backgroundColor: '#f5f5f5',
  borderRadius: '5px 5px 0 0',
  boxShadow: '0px 0px 6px #0000001A',
})

export const activeTabStyle = css(nonActiveTabStyle, {
  color: '#ff5500',
  backgroundColor: '#FFFFFF',
  borderRadius: '5px 5px 0 0',
  boxShadow: '0px 0px 6px #0000001A',
})

export const tabCountTextStyle = css({
  minWidth: 102,
  marginRight: 8,
  textAlign: 'center',
})

export const tabCountStyle = css({
  color: '#FFFFFF',
  fontSize: 10,
  fontWeight: 'bold',
  borderRadius: 10,
  padding: 6,
  paddingTop: 3,
  paddingBottom: 3,
  backgroundColor: '#676767',
})

export const activeTabCountStyle = css(tabCountStyle, {
  backgroundColor: '#FF5500',
})

export const ReservationHeaderButtonStyle = css({
  display: 'flex',
})

export const arrowImageStyle = css({
  display: 'flex',
  marginLeft: '16px',
})

// list head
export const searchInputStyle = css({
  display: 'flex',
  height: 32,
  borderRadius: 17,
  backgroundColor: '#FFFFFF',
  border: '1px solid #CCCCCC',
  paddingLeft: 42,
  paddingRight: 16,
  '::placeholder': {
    color: '#CCCCCC',
    fontSize: 12,
  },
})

export const searchWrapperStyle = css({
  position: 'relative',
  margin: 'auto 8px',
})

export const searchImageStyle = css({
  width: 14,
  position: 'absolute',
  top: 9,
  left: 16,
})

export const headCheckboxStyle = css({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 32,
})

export const headPictureStyle = css({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 32,
})

export const headSortButtonStyle = css({
  paddingLeft: 8,
  cursor: 'pointer',
})

export const headGuestNameForCheckinStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '20.5%',
  minWidth: 284,
  cursor: 'pointer',
})

export const headGuestNameStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '20.5%',
  minWidth: 300,
  paddingLeft: 32,
  cursor: 'pointer',
})

export const headTitleListStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '16%',
  minWidth: 200,
  cursor: 'pointer',
})

export const headIdTitleStyle = css(headTitleListStyle, {
  width: '11%',
  minWidth: 72.72,
  cursor: 'pointer',
})

export const headRoomTypeStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '18.5%',
  minWidth: 200,
  cursor: 'pointer',
})

export const headRoomNumberStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '13.5%',
  minWidth: 160,
  cursor: 'pointer',
})

export const headTitleFlexListStyle = css(headTitleListStyle, {
  display: 'flex',
  width: '16.5%',
  cursor: 'pointer',
})

export const headCheckoutStyle = css({
  display: 'flex',
  minWidth: 180,
  alignItems: 'center',
})

export const headCheckinTimeStyle = css(headTitleListStyle, {
  width: '19%',
  minWidth: 200,
})

export const headOtherMenuStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: 53,
  minWidth: 53,
})

export const headEvaluationStyle = css({
  display: 'flex',
  width: '19%',
  minWidth: 230,
  alignItems: 'center',
})

export const headCheckoutPlanStyle = css({
  display: 'flex',
  width: 180,
  minWidth: 180,
  alignItems: 'center',
})

export const headSubscriberNameStyle = css({
  display: 'flex',
  width: '17%',
  minWidth: 250,
  paddingLeft: 32,
  alignItems: 'center',
})

// list data
export const dataGuestStyle = css({
  display: 'flex',
  width: '20.5%',
  minWidth: 268,
})

export const dataStatusApprovedCheckboxStyle = css({
  paddingLeft: 32,
})

export const dataGuestPictureStyle = css(headPictureStyle, {})

export const dataGuestNameJaStyle = css(headGuestNameStyle, {
  minWidth: 228,
})

export const dataReservationPerson = css({})

export const dataGuestIdStyle = css({
  width: '11%',
  minWidth: 72.72,
})

export const dataCompanionStyle = css({
  width: '18%',
  minWidth: 200,
})

export const companionStyle = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingRight: 16,
  lineHeight: '1.3em',
})

export const dataRoomNumberStyle = css({
  width: '22%',
  minWidth: 200,
})

export const dataReservationRoomNumberStyle = css({
  width: '19%',
  minWidth: 200,
  display: 'flex',
})

export const dataRoomTypeStyle = css(dataReservationRoomNumberStyle, {
  width: '18.5%',
  minWidth: 224.22,
})

export const dataGuestRoomNumberStyle = css({
  width: '14%',
  minWidth: 163.61,
  display: 'flex',
})

export const dataMemoStyle = css({
  width: '16%',
  minWidth: 200,
})

export const SubscriberNameStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  lineHeight: 1.5,
})

export const statusTagStyle = css({
  paddingLeft: 32,
  fontSize: 12,
})

export const statusTagGrayStatus = css({
  width: 80,
  textAlign: 'center',
  border: '1px solid #676767',
  borderRadius: 5,
  color: '#676767',
  padding: '4px 10px',
})

export const statusTagOrangeStyle = css({
  textAlign: 'center',
  border: '1px solid #ff5500',
  borderRadius: 5,
  color: '#ff5500',
  padding: '4px 10px',
})

export const dataRouteStyle = css({
  display: 'flex',
  width: 100,
  minWidth: 100,
  paddingLeft: 89,
  alignItems: 'center',
})

export const dataEvaluationStyle = css(headEvaluationStyle, {
  width: '16%',
  minWidth: 200,
})

export const dataCheckoutPlanStyle = css(headCheckoutPlanStyle, {})

export const dataCheckoutStyle = css(headCheckoutStyle, {})

export const guestIdStyle = css({
  width: '6%',
  minWidth: 70,
})

export const dataSelfCheckinTimeStyle = css({
  width: '14%',
  minWidth: 160,
})

export const dataGuestCheckoutTimeStyle = css(dataSelfCheckinTimeStyle, {})

export const selfCheckinTimeStyle = css({})

export const memoStyle = css({
  width: 162,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  lineHeight: 1.5,
})

export const attentionLogoStyle = css({
  marginLeft: 16,
  width: 16,
  height: 16,
})

export const dataOtherMenuStyle = css({
  color: '#676767',
  minHeight: 30,
  minWidth: 30,
  height: 30,
  width: 30,
  cursor: 'pointer',
  '&:hover': {
    borderRadius: '50%',
    background: '#f2f2f2',
  },
})

export const deleteButtonStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 102,
  height: 34,
  background: '#ffffff',
  boxShadow: '0px 0px 6px #00000029',
  borderRadius: 5,
  position: 'relative',
  right: 70,
  marginTop: 6,
  zIndex: 100,
  fontSize: 16,
  '&:hover': {
    background: ' #f5f5f5',
  },
})

export const deleteButtonTextStyle = css({
  paddingLeft: 8,
})

// list
export const guestPictureStyle = css({
  width: 40,
  borderRadius: '50%',
})

export const guestNameUnreadJaStyle = css({
  textAlign: 'left',
  padding: '13px 0',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  lineHeight: 1.5,
  width: 180,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const guestNameUnreadStyle = css(guestNameUnreadJaStyle, {
  width: 300,
  minWidth: 300,
  padding: '13px 0 13px 158px',
})

export const ReservationPersonStyle = css({
  display: 'flex',
  width: 225,
  minWidth: 225,
  textAlign: 'left',
  padding: '13px 0',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  lineHeight: 1.5,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const reservationRoomNumbersStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 170,
  whiteSpace: 'nowrap',
})

export const reservationRoomNumberStyle = css({
  display: 'inline-block',
  marginRight: 12,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 170,
  whiteSpace: 'nowrap',
})

export const guestNameStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  lineHeight: 1.5,
})

export const guestNameKanaStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  lineHeight: 1.5,
  fontSize: 12,
  letterSpacing: 0.48,
  color: '#676767',
})

export const guestNameHonorificsStyle = css({
  lineHeight: 1.5,
})

export const guestNameUnreadEnStyle = css(guestNameUnreadJaStyle, {
  justifyContent: 'left',
})

export const guestNameReadJaStyle = css(guestNameUnreadJaStyle, {
  fontWeight: 'normal',
  lineHeight: 1.5,
})

export const guestNameReadEnStyle = css(guestNameReadJaStyle, {
  justifyContent: 'left',
})

// deletemodal
export const modalContainerStyle = css({
  position: 'fixed',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.5)',
  top: 0,
  left: 0,
  zIndex: 102,
})

export const modalBodyContainerStyle = css({
  zIndex: 1111,
  background: 'white',
  borderRadius: 5,
  boxShadow: '0 0 6px rgba(0, 0, 0, 0.1)',
  width: 394,
  minHeight: 188,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

export const deleteModalContentPStyle = css({
  textAlign: 'center',
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: 1.8,
  color: '#272727',
  margin: '40px 0 16px 0',
})

export const deleteModalContentValueStyle = css({
  textAlign: 'center',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: 0.64,
  color: '#676767',
  margin: '0 auto',
  zIndex: 1111,
})

export const deleteModalContentCloseButtonStyle = css({
  border: '1px solid #F2A40B',
  borderRadius: 23,
  padding: '11px 0px',
  textAlign: 'center',
  fontSize: 14,
  letterSpacing: 2.1,
  color: '#F2A40B',
  margin: '24px 16px 0 79px',
  width: 110,
  cursor: 'pointer',
  display: 'inline-block',
  '&:hover': {
    background: '#F2A40B',
    color: 'white',
  },
})

export const deleteModalContentDeleteButtonStyle = css({
  background: '#F2A40B',
  border: 'none',
  borderRadius: 23,
  padding: '9px 0px',
  textAlign: 'center',
  fontSize: 14,
  letterSpacing: 2.1,
  color: '#ffffff',
  width: 110,
  cursor: 'pointer',
  '&:hover': {
    background: '#F2A40B',
    border: 'none',
    color: 'white',
  },
})

// score
export const scoreBaseStyle = css({
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 'bold',
})

export const HighScoreStyle = css(scoreBaseStyle, {
  color: '#FF5500',
})

export const middleScoreStyle = css(scoreBaseStyle, {
  color: '#FFA97F',
})

export const lowScoreStyle = css(scoreBaseStyle, {
  color: '#676767',
})

export const dataGuestNameStyle = css(headGuestNameStyle, {
  fontWeight: 'normal',
  display: 'flex',
  justifyContent: 'space-between',
})

export const stayReadOnlyDateRangeField = css({
  color: '#272727',
  fontSize: 12,
  borderRadius: 16,
  height: 32,
  border: '1px solid #dbdbdb',
  width: 296,
  backgroundImage: `url(${require('@/static/images/calendar_icon.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '14px center',
  backgroundSize: 14,
  paddingLeft: 50,
  backgroundColor: '#F2F2F2',
})

export const reloadWrapperStyle = css({
  marginLeft: 8,
})
