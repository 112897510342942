import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { AssetType } from '@/models/guest-app/asset'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'
import { postMobileRequestSalesHours, putMobileRequestSalesHours } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { TextareaField } from '@/components/molecules/textarea-field'
import { SalesHourInputType, SalesHourType } from '@/models/mobile-request'
import dayjs from 'dayjs'
import { Select } from 'antd'

type Props = {
  asset: AssetType
  detail: SalesHourType | null
  onCancel: () => void
  onSave: () => void
}

export const RequestSalesHourDetailModal: React.FC<Props> = ({ asset, detail, onCancel, onSave: parentSave }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<SalesHourInputType>({
    defaultValues: {
      name: detail?.name || '',
      hours: detail?.hours || [],
      guestAppAssetId: asset.id,
      order: detail?.order || '',
    },
  })
  const hourInput = useFieldArray({
    rules: { required: true },
    control,
    name: 'hours',
  })

  const { doReload, setIsLoading } = useContext(AssetDetailContext)
  const { errorHandler } = useErrorHandler()

  const onSave = async (value: SalesHourInputType) => {
    try {
      setIsLoading(true)
      if (detail) {
        await putMobileRequestSalesHours(detail.id, value)
      } else {
        await postMobileRequestSalesHours(value)
      }
      doReload()
      parentSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const computedSaleTimeOptions = (): string[] => {
    const options: string[] = []
    for (let i = 0; i < 48; i++) {
      options.push(
        dayjs()
          .startOf('d')
          .add(30 * i, 'm')
          .format('HH:mm'),
      )
    }
    return options
  }

  return (
    <>
      <Modal customCss={modalStyle}>
        <ModalHeader>{t('MobileRequest.Create sales hour')}</ModalHeader>
        <ModalBody>
          <div css={modalBodyStyle}>
            <div>
              <div css={inputTitleTextStyle}>
                {t('Title')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <div css={[textareaStyle, { textarea: { height: 76 } }]}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: t('Required field has not been entered') }}
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <TextareaField
                      marginBottom={0}
                      placeholder={t('Title name')}
                      value={value}
                      name={name}
                      handleChangeData={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </div>

            <div css={inputAreaStyle}>
              <div css={inputTitleTextStyle}>
                {t('MobileRequest.Sales hours')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              {hourInput.fields.map((hour, index) => (
                <div css={saleTimeAreaStyle} key={hour.id}>
                  <Controller
                    name={`hours.${index}.from`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        css={selectBoxStyle}
                        suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                        value={value}
                        onChange={onChange}
                      >
                        {computedSaleTimeOptions().map(time => (
                          <Select.Option key={`from.${index}.${time}`} value={time}>
                            {time}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  />
                  <p style={{ fontSize: 12, color: '#676767', padding: '0 8px' }}>〜</p>
                  <Controller
                    name={`hours.${index}.to`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        css={selectBoxStyle}
                        suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
                        value={value}
                        onChange={onChange}
                      >
                        {computedSaleTimeOptions().map(time => (
                          <Select.Option key={`to.${index}.${time}`} value={time}>
                            {time}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  />

                  <div>
                    <img
                      style={{ cursor: 'pointer' }}
                      src={require('@/static/images/mobile_request/delete_orange.svg')}
                      alt=""
                      onClick={() => hourInput.remove(index)}
                    />
                  </div>
                </div>
              ))}
              <div
                css={addItemStyle}
                onClick={() =>
                  hourInput.append({
                    from: '00:00',
                    to: '00:00',
                  })
                }
              >
                <img src={require('@/static/images/plus_yellow.svg')} alt={t('Add icon yellow')} />
                <p>{t('MobileRequest.Add sales hours')}</p>
              </div>

              {errors?.hours !== undefined && (
                <div css={errorMessageStyle}>{t('Please select {{item}}', { item: t('MobileRequest.Sales hours') })}</div>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(onSave)}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const modalStyle = css({
  top: '5%',
  left: 'calc(50% - 300px)',
  minHeight: 565,
  maxHeight: 565,
  minWidth: 600,
  width: 600,
})

const modalBodyStyle = css({
  padding: 32,
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  minHeight: '100%',
})

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputAreaStyle = css({
  paddingTop: 24,
})

const textareaStyle = css({
  textarea: {
    marginBottom: 0,
    padding: '16px',
    lineHeight: 1.5,
    letterSpacing: '1.4px',
    ':focus': {
      padding: '15px',
    },
  },
})

const selectBoxStyle = css({
  width: 92,
  '> div': {
    borderRadius: '16px',
    border: '1px solid #CCCCCC',
    boxShadow: 'none !important',
    borderColor: '#CCCCCC !important',
  },
})
const addItemStyle = css({
  marginTop: 8,
  width: '50%',
  cursor: 'pointer',
  display: 'flex',
  height: 36,
  backgroundColor: '#FAFAFA',
  justifyContent: 'center',
  border: '1px dashed #CCCCCC',
  flex: 1,
  p: {
    lineHeight: '36px',
    color: '#F2A40B',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 10.8,
  },
  img: {
    width: 20,
  },
  ':hover': {
    background: '#F2F2F2',
  },
})
const saleTimeAreaStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '50%',
  paddingBottom: '8px',
})

const errorMessageStyle = css({
  fontSize: 10,
  color: 'rgba(255,0,0,0.5)',
  marginTop: 4,
  position: 'absolute',
})
