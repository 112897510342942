import React from 'react'
import { SerializedStyles, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

//models
import { AdvanceMoneyType } from '@/models/sales-manager/advance-money'
import dayjs from 'dayjs'
import { numberWithCommas } from '@/libs/formater'

type Props = {
  index: number
  item: AdvanceMoneyType
  headStyles: {
    headIDStyle: SerializedStyles
    headPaymentDateStyle: SerializedStyles
    headPayeeStyle: SerializedStyles
    headEarningStyle: SerializedStyles
    headPaymentMethodStyle: SerializedStyles
    headAccountsReceivableStyle: SerializedStyles
    headScheduledCheckinDateStyle: SerializedStyles
    headFinalWithdrawalDateStyle: SerializedStyles
    headInitialAmountStyle: SerializedStyles
    headBalanceStyle: SerializedStyles
    headActionStyle: SerializedStyles
  }
  deleteItemMoney: (index: number, id: string) => void
  openHistoryModal: (arg0: string) => void
  deleteCompanyId: any
}

export const TableItem: React.FC<Props> = ({ index, item, headStyles, deleteItemMoney, openHistoryModal, deleteCompanyId }) => {
  const { t } = useTranslation()
  return (
    <div key={index} css={!item.has_submitted ? listUnreadStyle : listItemReadStyle}>
      <div css={itemValue} onClick={() => openHistoryModal(item?.id)}>
        <div css={headStyles.headIDStyle}>{item?.display_id}</div>
        <div css={headStyles.headPaymentDateStyle}>{dayjs.unix(item?.created_at_unixtime).format(t('MM-DD-YYYY'))}</div>
        <div css={headStyles.headPayeeStyle}>
          <span>{item?.company_name}</span>
        </div>
        <div css={headStyles.headEarningStyle}>
          <span>{item?.subject_name}</span>
        </div>
        <div css={headStyles.headPaymentMethodStyle}>
          <span>{item?.payment_name}</span>
        </div>
        <div css={headStyles.headAccountsReceivableStyle}>
          <span>{item?.accounts_receivable_name || '-'}</span>
        </div>
        <div css={headStyles.headScheduledCheckinDateStyle}>
          {item?.first_checkin_date ? dayjs(item?.first_checkin_date).format(t('MM-DD-YYYY')) : '-'}
        </div>
        <div css={headStyles.headFinalWithdrawalDateStyle}>
          {item?.final_withdrawal_date_unixtime ? dayjs.unix(item?.final_withdrawal_date_unixtime).format(t('MM-DD-YYYY')) : '-'}
        </div>
        <div css={headStyles.headInitialAmountStyle}>¥{numberWithCommas(item?.advance_money)}</div>
        <div css={headStyles.headBalanceStyle}>¥{numberWithCommas(item?.current_balance)}</div>
      </div>
      <div css={headStyles.headActionStyle}>
        {!item.has_submitted && !deleteCompanyId && (
          <img src={require('@/static/images/delete_orange.svg')} onClick={() => deleteItemMoney(index, item?.id)} />
        )}
      </div>
    </div>
  )
}

const listUnreadStyle = css({
  padding: '0 32px',
  cursor: 'pointer',
  display: 'flex',
  height: 44,
  width: '100%',
  borderBottom: '1px solid #f2f2f2',
  backgroundColor: '#FFF',
  '&:hover': {
    boxShadow: '0px 3px 6px #0000000f',
    position: 'relative',
  },
})

const listItemReadStyle = css(listUnreadStyle, {
  background: '#F6F6F6',
})

const itemValue = css({
  flex: 1,
  display: 'flex',
})
