import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// components
import { Button } from '@/components/atoms/button'

// models
// import { ReservationType } from '@/models/reservation'
import dayjs from 'dayjs'
import i18n from '@/i18n'
import { dividedRoomNumber } from '@/libs/divide-list'
import { ApprovedStatusType } from '@/models/reservation-approved-status'

type Props = {
  reservation: any
  reservationCheckinLink: (v: {}) => void
}

export const approvedStatus = [
  {
    value: ApprovedStatusType.Checkin,
    label: i18n.t('Checkin'),
  },
  {
    value: ApprovedStatusType.Stay,
    label: i18n.t('During your stay'),
  },
  {
    value: ApprovedStatusType.Checkout,
    label: i18n.t('Checkout'),
  },
]

export const ReservationList: React.FC<Props> = ({ reservation, reservationCheckinLink }) => {
  const { t } = useTranslation()
  return (
    <>
      <div css={reservationListContainer}>
        <div css={reservationListSubject}>
          <div css={{ color: '#272727' }}>{t('Reservation list')}</div>
          {/* <Button buttonType={10} width={116} height={24} marginLeft={0} onClick={() => {}} borderRadius={5} fontWeight={'normal'}>
            Bulk change
            <img css={arrowStyle} src={require('@/static/images/arrow_yellow.svg')} alt="" />
          </Button> */}
        </div>
        <div css={listTitle}>
          <div css={reservationIdStyle}>{t('Accommodation ID')}</div>
          <div css={reservationDateStyle}>{t('Date of stay')}</div>
          <div css={reservationNightStyle}>{t('Number of nights')}</div>
          <div css={reservationUserStyle}>{t('Guest')}</div>
          <div css={roomNumberStyle}>{t('Room number')}</div>
          <div css={{ width: '10%' }}>{t('Amount to be collected')}</div>
          <div css={reservationStatusStyle}>{t('Status')}</div>
        </div>
        <ul>
          <li key={reservation && reservation.accommodationId ? reservation.accommodationId : ''} css={listStyle}>
            <div css={reservationIdStyle}>{reservation && reservation.accommodationId ? reservation.accommodationId : ''}</div>
            <div css={reservationDateStyle}>
              {reservation &&
                `${dayjs(reservation.checkinDate).format(t('MM-DD')) ? dayjs(reservation.checkinDate).format(t('MM-DD')) : ''}
              ~
              ${dayjs(reservation.checkoutDate).format(t('MM-DD')) ? dayjs(reservation.checkoutDate).format(t('MM-DD')) : ''}`}
            </div>
            <div css={reservationNightStyle}>
              {reservation && reservation?.checkinDate && reservation?.checkoutDate
                ? dayjs(reservation?.checkoutDate).diff(dayjs(reservation?.checkinDate), 'day')
                : 0}
              {t('Night')}
            </div>
            <div css={reservationUserStyle}>{reservation && reservation.guestName ? reservation.guestName : ''}</div>
            <div css={roomNumberStyle}>{dividedRoomNumber(reservation && reservation.assignedRoom ? reservation.assignedRoom : '')}</div>
            <div css={{ width: '10%', color: reservation?.unpaid ? '#F2A40B' : '' }}>
              ¥{Number(reservation?.unpaid || 0).toLocaleString()}
            </div>
            <div css={reservationStatusStyle}>
              <Button
                buttonType={2}
                width={139}
                height={24}
                fontSize={12}
                marginLeft={0}
                onClick={() => reservationCheckinLink(reservation)}
                borderRadius={5}
                fontWeight={'normal'}
              >
                {t('Check-in create')}
              </Button>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}

const reservationListContainer = css({
  backgroundColor: '#fff',
  border: '1px solid #CCCCCC',
  borderRadius: '10px',
  overflow: 'hidden',
})

const listTitle = css({
  display: 'flex',
  backgroundColor: '#f5f5f5',
  color: '#676767',
  fontWeight: 'bold',
  width: '100%',
  padding: '10px 40px',
  fontSize: 12,
})

const listStyle = css(listTitle, {
  backgroundColor: '#fff',
  color: '#272727',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '24px',
})

const reservationListSubject = css({
  fontSize: 14,
  fontWeight: 'bold',
  padding: '13px 34px 8px 33px',
  display: 'flex',
  justifyContent: 'space-between',
})

const reservationIdStyle = css({
  width: '10%',
})

const reservationDateStyle = css({
  width: '17%',
})

const reservationNightStyle = css({
  width: '8%',
})

const reservationUserStyle = css({
  width: '20%',
})

const roomNumberStyle = css({
  width: '23%',
})

const reservationStatusStyle = css({
  width: '12%',
})
