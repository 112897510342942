import { css } from '@emotion/core'
import React, { ComponentPropsWithRef, forwardRef } from 'react'

interface RadioButtonSquareProps {
  text: string
  value?: string
  name?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  id: string
}

type RadioButtonSquareType = ComponentPropsWithRef<'input'> & RadioButtonSquareProps

export const RadioButtonSquare: React.FC<RadioButtonSquareType> = forwardRef(({ text, onChange, checked, value, name, id }, ref) => {
  return (
    <div css={radioButtonStyle}>
      <input type="radio" onChange={onChange} id={id} name={name} value={value} ref={ref} checked={checked} />
      <label htmlFor={id}>{text}</label>
    </div>
  )
})
RadioButtonSquare.displayName = 'RadioButtonSquare'

const radioButtonStyle = css({
  input: {
    appearance: 'none',
    display: 'none',
    ':checked': {
      '+label': {
        color: '#F2A40B',
        borderWidth: 2,
      },
    },
  },
  label: {
    color: '#676767',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    paddingBlock: 15,
    border: '1px solid',
    borderRadius: 5,
    fontSize: 14,
    cursor: 'pointer',
    fontWeight: 'bold',
  },
})
