import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { wholeContainerStyle, mainColumnStyle } from '@/constants/layout'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { SalesManagerTab } from '@/components/pages/sales-manager/tab'
import { DashboardType } from '@/models/sales-manager/dashboard'
import dayjs from 'dayjs'
import { SalesDashboard } from '@/components/organisms/sales-manager/sales-dashboard'

type ViewParameter = {
  mode?: DashboardType
  targetDay?: string
}

export const SalesManagerDashboard: React.FC = () => {
  const { t } = useTranslation()
  const viewParameter: ViewParameter = require('query-string').parse(window.location.search)

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header title={t('Sales management')} />
        <div css={containerStyle}>
          <div css={mainContainerStyle}>
            <div css={headerContainerStyle}>
              <SalesManagerTab currentTab="sales" />
            </div>
            <div css={listContainerStyle}>
              <SalesDashboard
                initialViewMonth={dayjs(viewParameter?.targetDay)}
                initialViewMode={viewParameter.mode || DashboardType.Daily}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const containerStyle = css({
  display: 'flex',
  padding: 0,
  minWidth: '1000px',
})

const mainContainerStyle = css({
  width: '100%',
  padding: 24,
})

const listContainerStyle = css({
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: '0 5px 5px 5px',
  background: '#FFF',
  position: 'relative',
})

const headerContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})
