import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useWatch, Control, UseFormSetValue } from 'react-hook-form'
import 'react-dates/initialize'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import { FormType } from '@/components/organisms/accommodation-management/reservation-create-with-room-modal'

type ContainerProps = {
  index: number
  guestRoomInfos: Array<{ guestRoomId: string; typeName: string; roomNum: number }> | undefined
  control: Control<FormType>
  setValue: UseFormSetValue<FormType>
}

export const RoomTypeAndNumArrayItem: React.FC<ContainerProps> = ({ index, control, setValue, guestRoomInfos }) => {
  const { t } = useTranslation()
  const selectedGuestRoomId = useWatch({
    control,
    name: `rooms.${index}.guestRoomId`,
  })

  const pickRoomTypeNameByType = (): string => {
    return guestRoomInfos?.find(info => info.guestRoomId === selectedGuestRoomId)?.typeName || ''
  }

  const pickRoomNumByType = useMemo((): number => {
    return guestRoomInfos?.find(info => info.guestRoomId === selectedGuestRoomId)?.roomNum || 1
  }, [selectedGuestRoomId])

  useEffect(() => {
    setValue(`rooms.${index}.num`, selectedGuestRoomId ? '1' : undefined)
    setValue(`rooms.${index}.typeName`, pickRoomTypeNameByType())
  }, [selectedGuestRoomId])

  return (
    <div key={index} className="room-field-row">
      <div>
        <Controller
          name={`rooms.${index}.guestRoomId`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select value={value} onChange={onChange}>
              <AntSelect.Option value="">{t('Please select')}</AntSelect.Option>
              {guestRoomInfos?.map(info => (
                <AntSelect.Option key={`${index}.${info.guestRoomId}`} value={info.guestRoomId}>
                  {info.typeName}
                </AntSelect.Option>
              ))}
            </Select>
          )}
        />
      </div>
      <div>
        <Controller
          name={`rooms.${index}.num`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select value={value} onChange={onChange} placeholder={'-'}>
              {[...Array(pickRoomNumByType)].map((v, i) => (
                <AntSelect.Option key={`num.${index}.${i}`} value={`${i + 1}`}>
                  {`${i + 1}室`}
                </AntSelect.Option>
              ))}
            </Select>
          )}
        />
      </div>
    </div>
  )
}
