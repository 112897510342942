import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { AccountContext } from '@/contexts/account'
import { NoticeModal } from '@/components/organisms/notice-modal'
import { checkNoticeCenter, fetchAdminNotice } from '@/apis/aipass'
import { HotelNoticeType } from '@/models/hotel-notice'

export const Notice: React.FC = () => {
  const { account } = React.useContext<any>(AccountContext)
  const [isNoticeModalOpen, setIsNoticeModalOpen] = useState<boolean>(false)
  const [badgeCount, setBadgeCount] = useState<number>(0)
  const [notices, setNotices] = useState<HotelNoticeType[]>([])

  useEffect(() => {
    if (account) {
      _fetchAdminNotice()
    }
  }, [account])

  const _fetchAdminNotice = () => {
    fetchAdminNotice().then(res => {
      setBadgeCount(res?.unreadCount || 0)
      setNotices(res?.notices || [])
    })
  }

  const onClickNoticeIcon = () => {
    setIsNoticeModalOpen(!isNoticeModalOpen)
    setBadgeCount(0)
    checkNoticeCenter()
  }
  return (
    <>
      <div css={noticeWrapperStyle}>
        {badgeCount === 0 && (
          <div onClick={onClickNoticeIcon} css={noticeButtonStyle}>
            <img src={require('@/static/images/header/news.svg')} />
          </div>
        )}
        {badgeCount !== 0 && (
          <div>
            <div onClick={onClickNoticeIcon} css={noticeButtonStyle}>
              <img src={require('@/static/images/header/news_attention.svg')} />
            </div>
            <div css={badgeNumberStyle}>{badgeCount < 99 ? badgeCount : 99}</div>
          </div>
        )}
      </div>
      {isNoticeModalOpen && <NoticeModal notices={notices} onClose={() => setIsNoticeModalOpen(false)} />}
    </>
  )
}

const noticeWrapperStyle = css({
  padding: '0',
  position: 'relative',
  cursor: 'pointer',
  paddingTop: '2px',
})

const noticeButtonStyle = css({
  '&:hover': {
    borderRadius: '50%',
    background: '#f2f2f2',
  },
})

const badgeNumberStyle = css({
  width: 16,
  height: 16,
  background: '#F2A40B',
  border: '1px solid #fff',
  borderRadius: '50%',
  color: '#fff',
  fontSize: '10px',
  fontWeight: 'bold',
  lineHeight: '14px',
  textAlign: 'center',
  letterSpacing: '0.5px',
  position: 'absolute',
  top: 0,
  right: '-3px',
})
