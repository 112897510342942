import { ApprovedStatus } from '@/models/reservation-approved-status'
import { TransactionSideType } from './smaregi/transaction'

export type PaymentSettingType = {
  id: string
  name: string
  commissionRate: number
  isDefaultCash: boolean
  isFixed: boolean
  methodCode?: string
  children?: PaymentSettingType[]
}

export type SubjectSettingType = {
  id: string
  hotelId: string
  departmentCode: string
  departmentName: string
  subjectCode: string
  subjectName: string
  price: {
    hasSeparated: string
    price: number
    malePrice: number
    femalePrice: number
    childAPrice: number
    childBPrice: number
    childCPrice: number
    childDPrice: number
    childOtherPrice: number
  }
  tax: number
  planCode: string[]
  subSubjectId: string
  subSubjectCode: string
  subSubjectName: string
  subSubjectPrice: number
  subIds?: string[]
}

// デフォルト項目の設定
export const DiscountType = {
  Custom: 0, // 指定なし
  Default: 1, // ポイント
} as const
type DiscountType = (typeof DiscountType)[keyof typeof DiscountType]

export type DiscountSettingType = {
  id?: string
  discountSubjectCode: string
  name: string
  price: string
  type: DiscountType
  isAccountsReceivable: number
  accountsReceivableCode: string
  accountsReceivableName: string
  createdAt?: Date
  tax: string
  taxClass: string
}

export type SalesType = {
  id?: string
  salesDate: string
  reservationName: string
  salesDepartmentName: string
  salesSubjectId: string
  salesSubjectName: string
  salesSubjectPrice: number | ''
  quantity: number
  salesSubjectTax: number
  salesPaymentId: string
  salesPaymentName: string
  salesPaymentPrice: number
  isPaid: boolean
  salesPaymentCommissionRate: number
  checkinName: string
  isConfirm: boolean
  salesPrice: number
  unpaid: number
  paymentSalesManagerDailyId: string
  accommodationId: string
  salesSubSubjectId: string
  salesSubSubjectCode: string
  salesSubSubjectName: string
  salesSubSubjectPrice: number
  reservationId: string
  salesAccountsReceivableMasterId: string
  salesAccountsReceivableName: string
  companyId?: string
  guestRoomAssignId?: string
  roomNumber?: string
  hasSentPos?: boolean
  transactionSide?: TransactionSideType
}

export type EditSalesType = {
  id?: string
  companyId?: string
  salesDate: string
  salesSubjectId: string
  salesSubSubjectId: string
  salesSubjectPrice: number | ''
  quantity: number
  salesPaymentId: string
  salesPaymentPrice: number
  isPaid: boolean
  checkinId?: string
  reservationId?: string
  isEdit?: boolean
  paymentSalesManagerDailyId?: string | null
  accommodationId: string
  salesSubjectCode: string
  salesSubSubjectCode: string
  salesAccountsReceivableMasterId: string
  salesAccountsReceivableName: string
  guestRoomAssignId?: string
  salesSubjectSetId?: string
}

export type SalesDiscountType = {
  approvedStatus: typeof ApprovedStatus
  checkinId?: string
  reservationId?: string
  accommodationId?: string
  id: string
  isConfirm: boolean
  salesDate: string
  salesDiscountId: string
  salesDiscountName: string
  salesPaymentId: string
  salesPaymentName: string
  salesSubjectPrice: number
  paymentSalesManagerDailyId: string
  salesAccountsReceivableMasterId: string
  salesAccountsReceivableName: string
  companyId?: string
  guestRoomAssignId?: string
  hasSentPos?: boolean
  transactionSide?: TransactionSideType
  roomNumber?: string
}

export type EditDiscountType = {
  id?: string
  salesDate: string
  salesDiscountId: string
  salesSubjectPrice: number | ''
  salesPaymentId: string
  checkinId?: string
  reservationId?: string
  isDiscount: boolean
  isEdit?: boolean
  paymentSalesManagerDailyId?: string | null
  salesAccountsReceivableMasterId?: string
  companyId?: string
  guestRoomAssignId?: string
}

export type TotalListType = {
  name: string
  totalPayment: string
}

export type UsageDetailTyoe = {
  confirmDay: string
  sales: SalesType[]
  totalList: TotalListType[]
}

// Sales revision
export type SalesReviseType = {
  id?: string
  salesDate: string
  salesSubjectId: string
  salesSubjectName: string
  salesSubjectPrice: string
  salesPaymentId: string
  salesPaymentName: string
  note: string
  isEdit?: boolean
  isConfirm: boolean
  salesManagerDailyId: string
}

// Sales revision (edit)
export type EditSalesReviseType = {
  id?: string
  salesDate: string
  salesSubjectId: string
  salesSubjectPrice: string
  salesPaymentId: string
  note: string | ''
  salesManagerDailyId: string
}

export type SalesSubjectSetSettingType = {
  salesSubjectSetId: string
  hotelId: string
  salesSubjectSetNameId: number
  salesSubjectSetName: string
  salesSubjectSetMap: Array<{
    salesSubjectSetMapId: string
    salesSubjectMasterId: string
    salesSubjectMasterName: string
    isDisplay: boolean
  }>
}
export type CalculatedSalesSubjectSetType = {
  checkinId: string | undefined
  hotelId: string
  guestRoomAssignId: string
  reservationId: string
  accommodationId: string
  guestRoomId: string
  pax: number
  salesSubjectId: string
  asSeparated: string
  salesSubjectCode: string
  salesSubjectName: string
  salesSubjectPrice: number
  salesSubjectTax: number
  departmentCode: string
  departmentName: string
  quantity: number
  salesDate: string
  salesPaymentPrice: number
  isDisplay: boolean
}
