import { css } from '@emotion/core'
// constants
import { mainContainerStyle } from '@/constants/layout'

export const settingContainerStyle = css(mainContainerStyle, {
  display: 'flex',
  padding: 0,
})

export const settingMainContainerStyle = css({
  width: 'calc(100% - 180px)',
})

export const settingHeaderContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

export const listWrapperStyle = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  minHeight: 49,
  padding: '15px 0 15px 27px',
  color: '#272727',
  borderBottom: '1px solid #F2F2F2',
  ':hover': {
    cursor: 'pointer',
  },
})

export const listStyle = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  overflowWrap: 'break-word',
  fontSize: 14,
  letterSpacing: 1.4,
})

export const hideListStyle = css({
  display: 'none',
})

export const deleteStyle = css({
  margin: '0 24px 0 auto',
})

export const settingMainWrapperStyle = css({
  padding: 24,
})
