import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// components
import { sectionStyle } from '@/components/pages/dashboard/_index/style'

// models
import { BusinessFormsType } from '@/models/sales-manager/business-forms'

type SalesTableProps = {
  businessForms: BusinessFormsType | undefined
}

export const SalesTable: React.FC<SalesTableProps> = ({ businessForms }) => {
  const { t } = useTranslation()
  return (
    <div css={mainContainerStyle}>
      <ul css={mixedLeftSectionStyle}>
        <li css={listItemStyle}>
          <div css={listItemTitleStyle}>{t('Earnings')}</div>
          <div css={listItemContentStyle}>
            {businessForms?.totalSales !== undefined ? `¥${Number(businessForms?.totalSales).toLocaleString()}` : '-'}
          </div>
        </li>
        <li css={listItemStyle}>
          <div css={listItemTitleStyle}>{t('Payment')}</div>
          <div css={listItemContentStyle}>
            {businessForms?.totalPayment !== undefined ? `¥${Number(businessForms?.totalPayment).toLocaleString()}` : '-'}
          </div>
        </li>
        <li css={listItemStyle}>
          <div css={listItemTitleStyle}>{t('Amount to be collected')}</div>
          <div css={listItemContentStyle}>
            {businessForms?.totalUnpaid !== undefined ? `¥${Number(businessForms?.totalUnpaid).toLocaleString()}` : '-'}
          </div>
        </li>
      </ul>
      <ul css={mixedRightSectionStyle}>
        <li css={listItemStyle}>
          <div css={listItemTitleStyle}>{t('Number of guests')}</div>
          <div css={listItemContentStyle}>{businessForms?.numberOfGuests !== undefined ? `${businessForms?.numberOfGuests} ${t('Person')}` : '-'}</div>
        </li>
        <li css={listItemStyle}>
          <div css={listItemTitleStyle}>{t('Number of rooms sold')}</div>
          <div css={listItemContentStyle}>
            {businessForms?.numberOfRoomsForSale !== undefined ? `${businessForms?.numberOfRoomsForSale} ${t('room counter')}` : '-'}
          </div>
        </li>
        <li css={listItemStyle}>
          <div css={listItemTitleStyle}>OCC</div>
          <div css={listItemContentStyle}>{businessForms?.occupancyRatio !== undefined ? `${businessForms?.occupancyRatio}%` : '-'}</div>
        </li>
        <li css={listItemStyle}>
          <div css={listItemTitleStyle}>ADR</div>
          <div css={listItemContentStyle}>
            {businessForms?.averageDailyRate !== undefined ? `¥${Number(businessForms?.averageDailyRate).toLocaleString()}` : '-'}
          </div>
        </li>
        <li css={listItemStyle}>
          <div css={listItemTitleStyle}>RevPAR</div>
          <div css={listItemContentStyle}>
            {businessForms?.revenuePerAvailableRoom !== undefined
              ? `¥${Number(businessForms?.revenuePerAvailableRoom).toLocaleString()}`
              : '-'}
          </div>
        </li>
      </ul>
    </div>
  )
}

const mainContainerStyle = css({
  display: 'flex',
})

const mixedLeftSectionStyle = css(sectionStyle, {
  margin: '24px 8px 0 0',
  boxShadow: 'none',
  border: '1px solid #D9D9D9',
  width: '-webkit-fill-available',
  height: 'fit-content',
})

const mixedRightSectionStyle = css(mixedLeftSectionStyle, {
  margin: '24px 0 0 8px',
})

const listItemStyle = css({
  height: 70,
  display: 'flex',
  alignItems: 'center',
  padding: '0 32px',
  borderRadius: '0 0 5px 5px',
  borderTop: '1px solid #F2F2F2',
  fontWeight: 'bold',
  fontsize: 16,
})

const listItemTitleStyle = css({
  display: 'flex',
  flex: 1,
  fontSize: 16,
  color: '#272727',
  letterSpacing: 2.4,
})

const listItemContentStyle = css(listItemTitleStyle, {
  justifyContent: 'flex-end',
})
