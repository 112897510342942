import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms'
import { ButtonType } from '@/components/atoms/button'
import { MonthCascaderField } from '@/components/molecules/month-cascader-field'
import { getReservationsBoundaryDates } from '@/apis/aipass'
import { Dayjs } from 'dayjs'
import { DashboardType } from '@/models/sales-manager/dashboard'
import { useHistory } from 'react-router-dom'

type Props = {
  openSalesManagerId: string | undefined
  month: Dayjs
  mode: DashboardType
  onChangeMonth: (month: Dayjs) => void
  onChangeMode: (mode: DashboardType) => void
}

export const ToolBar: React.FC<Props> = ({ openSalesManagerId, month, mode, onChangeMonth, onChangeMode }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [selectedMonth, setSelectedMonth] = useState<Dayjs>(month)
  const [reservationsBoundaryDates, setReservationsBoundaryDates] = useState<{ min: string; max: string }>()

  const moveMonth = (value: number) => {
    setSelectedMonth(selectedMonth.clone().add(value, 'month'))
  }

  useEffect(() => {
    if (!selectedMonth) {
      return
    }
    onChangeMonth(selectedMonth)
  }, [selectedMonth])

  useEffect(() => {
    getReservationsBoundaryDates().then(res => setReservationsBoundaryDates(res))
  }, [])

  return (
    <div css={toolBarStyle}>
      <div className="left-block">
        <div className="mode-toggle-block">
          <Button
            buttonType={mode === DashboardType.Daily ? ButtonType.Primary : ButtonType.Secondary}
            width={51}
            height={27}
            onClick={() => onChangeMode(DashboardType.Daily)}
          >
            {t('Daily')}
          </Button>
          <div className="vertical-divide"></div>
          <Button
            buttonType={mode === DashboardType.Monthly ? ButtonType.Primary : ButtonType.Secondary}
            width={51}
            height={27}
            onClick={() => onChangeMode(DashboardType.Monthly)}
          >
            {t('Monthly')}
          </Button>
        </div>
        <div className="date-block">
          <div className="prev" onClick={() => moveMonth(-1)}>
            <img src={require('@/static/images/arrow_right_gray_nospace.svg')} />
            <span>{t('Previous month')}</span>
          </div>
          <MonthCascaderField
            value={month}
            onChange={setSelectedMonth}
            min={reservationsBoundaryDates?.min}
            max={reservationsBoundaryDates?.max}
            width="112px"
          />
          <div className="next" onClick={() => moveMonth(1)}>
            <span>{t('Next month')}</span>
            <img src={require('@/static/images/arrow_right_gray_nospace.svg')} />
          </div>
        </div>
        {mode === DashboardType.Monthly && (
          <div className="remarks">{t('Aggregate for each of the six months before and after this month, including this month')}</div>
        )}
      </div>
      <div className="right-block">
        <Button
          buttonType={ButtonType.Simple}
          width={74}
          height={32}
          onClick={() =>
            history.push({
              pathname: '/sales-manager/detail-list',
              search: new URLSearchParams({
                beginDate: month.clone().startOf('month').format('YYYY-MM-DD'),
                endDate: month.clone().endOf('month').format('YYYY-MM-DD'),
              }).toString(),
            })
          }
        >
          {t('Detail')}
        </Button>
        {mode === DashboardType.Daily && openSalesManagerId && (
          <Button
            buttonType={ButtonType.Primary}
            width={118}
            height={32}
            onClick={() =>
              history.push({
                pathname: '/sales-manager/detail',
                search: new URLSearchParams({
                  id: openSalesManagerId,
                  mode: 'daily',
                }).toString(),
              })
            }
          >
            {t('Daily closing')}
          </Button>
        )}
      </div>
    </div>
  )
}
const toolBarStyle = css({
  display: 'flex',
  justifyContent: 'space-between',

  '*': { fontSize: '12px !important', fontWeight: 'bold' },
  '.left-block': {
    display: 'flex',
    gap: 32,
    alignItems: 'center',
    '.mode-toggle-block': {
      display: 'flex',
      gap: 16,
      alignSelf: 'center',
      button: {
        borderRadius: 5,
      },
    },
    '.date-block': {
      display: 'flex',
      alignItems: 'center',
      color: '#A3A3A3',
      '.prev, .next': {
        display: 'flex',
        alignItems: 'center',
        span: { padding: '0 8px' },
        img: { height: 14 },
        cursor: 'pointer',
      },
      '.prev': {
        span: { paddingRight: 16 },
        img: { transform: 'rotate(180deg)' },
      },
      '.next': {
        span: { paddingLeft: 16 },
      },
    },
  },
  '.right-block': {
    display: 'flex',
    gap: 16,
  },
  '.vertical-divide': {
    border: '0.5px solid #cccccc',
  },
  '.remarks': { color: '#676767', marginLeft: -8 },
})
