interface DeliverDate {
  numberOfDay: number
  targetStatus: TargetStatus
  dateExecution: DateExecution
  deliveryTime: string
}

interface TranslateObject {
  ja: string
  en: string
  ko: string
  zh: string
}

export interface MailDeliveryContentInput {
  title: string
  content: string
  rawContent: string
  status: MailDeliveryStatus,
  lineNotificationStatus: LineNotificationStatus
  isNullSearchableChannelCode: boolean
  isSendToAfterDelivery: boolean
  deliveryDate: DeliverDate
  reservationStatuses: number[]
  channelCodes: string[]
}

export interface MailDeliveryContent {
  id: string
  title: TranslateObject
  content: TranslateObject
  rawContent: string
  status: MailDeliveryStatus
  lineNotificationStatus: LineNotificationStatus
  isNullSearchableChannelCode: boolean
  isSendToAfterDelivery: boolean
  deliveryDate: DeliverDate
  reservationStatuses: number[]
  channelCodes: { reservationChannelCodeId: string; name:string }[]
}

export enum MailDeliveryStatus {
  Draft = 'draft',
  Reservation = 'reservation',
  Halt = 'halt',
}
export const mailDeliveryStatus = {
  [MailDeliveryStatus.Draft]: '下書き',
  [MailDeliveryStatus.Reservation]: '配信予定',
  [MailDeliveryStatus.Halt]: '配信停止',
}
export const isReservation = (status: string) => {
  return status === MailDeliveryStatus.Reservation
}
export const isDraft = (status: string) => {
  return status === MailDeliveryStatus.Draft
}

export enum TargetStatus {
  CheckIn = 'checkIn',
  Checkout = 'checkout',
}
export const targetStatus = [
  { value: TargetStatus.CheckIn, label: 'Check-in date' },
  { value: TargetStatus.Checkout, label: 'Check-out date' },
]

export enum DateExecution {
  Later = 'later',
  Ago = 'ago',
}
export const dateExecution = [
  { value: DateExecution.Later, label: DateExecution.Later },
  { value: DateExecution.Ago, label: DateExecution.Ago },
]

export enum LineNotificationStatus {
  All = 'all',
  Notified = 'notified',
  Unnoticed = 'unnoticed',
}
export const lineNotificationStatus = [
  { label: 'すべて', value: LineNotificationStatus.All },
  { label: '通知済み', value: LineNotificationStatus.Notified },
  { label: '未通知', value: LineNotificationStatus.Unnoticed },
]

export interface DeliveryContentItem {
  id: string
  title: {
    ja: string
    en: string
    ko: string
    zh: string
  }
  status: string
  dateText: string
}