import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

type PosCalculateSuccessModalProps = {
  onClose: () => void
}

export const PosCalculateSuccessModal: React.FC<PosCalculateSuccessModalProps> = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <div css={modalWrapperStyle}>
      <div css={modalContentStyle}>
        <div css={modalCloseStyle} onClick={onClose}>
          <img src={require('@/static/images/delete_orange.svg')} width={30} onClick={() => {}} />
        </div>
        <div>
          <p css={modalTitleStyle}>{t('The data has been transmitted')}</p>
          <p css={modalSummaryStyle}>{t('Please complete the payment at the POS terminal.')}</p>
        </div>
        <div>
          <img src={require('@/static/images/pos_image.svg')} width={248} />
        </div>
      </div>
    </div>
  )
}

const modalWrapperStyle = css({
  width: '100vw',
  height: '100vh',
  background: 'rgba(39, 39, 39, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'scroll',
})

const modalContentStyle = css({
  width: 600,
  height: 350,
  background: '#fff',
  borderRadius: 5,
  display: 'flex',
  flexFlow: 'column',
  textAlign: 'center',
})

const modalCloseStyle = css({
  alignSelf: 'end',
  padding: '24px 24px 0 0',
  cursor: 'pointer',
})

const modalTitleStyle = css({
  color: '#272727',
  fontSize: 23,
  fontWeight: 'bold',
  lineHeight: '21px',
  letterSpacing: 1.15,
})

const modalSummaryStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  lineHeight: '21px',
  letterSpacing: 0.7,
  paddingTop: 12,
  paddingBottom: 24,
})
