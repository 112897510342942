import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { GuestRoomTab } from '@/components/pages/setting/guest-room/tab'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { useErrorHandler } from '@/hooks/use-error-handler'
import * as api from '@/apis/aipass'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import { ToggleButton } from '@/components/molecules/settings/toggle-button'
import { Hint } from '@/components/atoms/hint'

enum AssignOrders {
  ascending = 'asc',
  descending = 'desc',
}
enum VacancyPeriods {
  longFirst = 'long',
  shortFirst = 'short',
}
enum FloorSettings {
  same = 'same',
  differentPossible = 'different_possible',
}
enum TakeInSettings {
  on = 'on',
  off = 'off',
}
enum Priorities {
  assignOrder = 'assignOrder',
  vacancyPeriod = 'vacancyPeriod',
}

export type GuestRoomAutoAssignSettingType = {
  assignOrder: AssignOrders
  vacancyPeriod: VacancyPeriods
  floorSetting: FloorSettings
  takeInSetting: TakeInSettings
  priorities: Array<Priorities>
}

export const SettingGuestRoomAutoAssign: React.FC = () => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [assignOrder, setAssignOrder] = useState<AssignOrders>(AssignOrders.ascending)
  const assignOrderItems = [
    { value: AssignOrders.ascending, label: t('Ascending') },
    { value: AssignOrders.descending, label: t('Descending') },
  ]
  const [vacancyPeriod, setVacancyPeriod] = useState<VacancyPeriods>(VacancyPeriods.longFirst)
  const vacancyPeriodItems = [
    { value: VacancyPeriods.longFirst, label: t('Long first') },
    { value: VacancyPeriods.shortFirst, label: t('Short first') },
  ]
  const [floorSetting, setFloorSetting] = useState<FloorSettings>(FloorSettings.same)
  const floorSettingItems = [
    { value: FloorSettings.same, label: t('Same floor') },
    { value: FloorSettings.differentPossible, label: t('Different floors are also possible') },
  ]
  const [takeInSetting, setTakeInSetting] = useState(false)
  const [priorities, setPriorities] = useState<Array<Priorities>>([Priorities.assignOrder, Priorities.vacancyPeriod])

  const fetchAutoAssignmentSettings = async () => {
    try {
      setIsLoading(true)
      const response = await api.fetchAutoAssignmentSettings()
      setSettingsByResponse(response)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
      setIsLoaded(true)
    }
  }

  const updateAutoAssignmentSettings = async () => {
    try {
      setIsLoading(true)
      await api.updateAutoAssignmentSettings({
        assignOrder,
        vacancyPeriod,
        floorSetting,
        takeInSetting: takeInSetting ? TakeInSettings.on : TakeInSettings.off,
        priorities,
      })
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const setSettingsByResponse = (response: GuestRoomAutoAssignSettingType) => {
    if (response.assignOrder) setAssignOrder(response.assignOrder)
    if (response.vacancyPeriod) setVacancyPeriod(response.vacancyPeriod)
    if (response.floorSetting) setFloorSetting(response.floorSetting)
    if (response.takeInSetting) setTakeInSetting(response.takeInSetting === TakeInSettings.on)
    if (response.priorities) setPriorities(response.priorities)
  }

  useEffect(() => {
    if (isLoaded) updateAutoAssignmentSettings()
  }, [assignOrder, vacancyPeriod, floorSetting, takeInSetting, priorities])

  useEffect(() => {
    fetchAutoAssignmentSettings()
  }, [])

  return (
    <SettingsLayout loading={isLoading}>
      <TabContainer tabComponent={<GuestRoomTab currentTab="auto-assign" />} isShowFooter={false}>
        <div css={mainContainerStyle}>
          <div>
            <div className="block-title">{t('Priority')}</div>
            <div className="setting-row">
              <div className="order-label-line">
                <Select
                  value={priorities.findIndex(p => p === Priorities.assignOrder) + 1}
                  onChange={v => {
                    setPriorities(v === 1 ? [Priorities.assignOrder, Priorities.vacancyPeriod] : [Priorities.vacancyPeriod, Priorities.assignOrder])
                  }}
                  customStyle={css({ paddingBottom: 0, width: 62 })}
                >
                  <AntSelect.Option value={1}>1</AntSelect.Option>
                  <AntSelect.Option value={2}>2</AntSelect.Option>
                </Select>
                <div className="label-text">{t('Assign sequence')}</div>
              </div>
              <div>
                <RadioGroupField
                  value={assignOrder}
                  items={assignOrderItems}
                  onChange={e => setAssignOrder(e.target.value)}
                  groupCss={{ width: 300 }}
                  itemWrapperCss={{ width: '50%' }}
                  itemCss={{ fontWeight: 'normal' }}
                  style={{ radioSize: 18 }}
                />
              </div>
            </div>
            <div className="setting-row">
              <div className="order-label-line">
                <Select
                  value={priorities.findIndex(p => p === Priorities.vacancyPeriod) + 1}
                  onChange={v => {
                    setPriorities(v === 1 ? [Priorities.vacancyPeriod, Priorities.assignOrder] : [Priorities.assignOrder, Priorities.vacancyPeriod])
                  }}
                  customStyle={css({ paddingBottom: 0, width: 62 })}
                >
                  <AntSelect.Option value={1}>1</AntSelect.Option>
                  <AntSelect.Option value={2}>2</AntSelect.Option>
                </Select>
                <div className="label-text">{t('Vacancy period')}</div>
                <Hint
                  customCss={css({
                    left: -8,
                    '.help-popup': { left: 16, top: 0 },
                  })}
                >
                  <div css={vacancyPeriodHintStyle}>
                    <div css={{ marginBottom: 24 }}>
                      <div className="title">{t('Longest vacancy period')}</div>
                      <div>{t('Preferentially assigned rooms that have not been occupied immediately before')}</div>
                    </div>
                    <img src={require('@/static/images/room/vacancyPeriodHint-2.jpg')} />
                    <div css={{ marginBottom: 24, marginTop: 32 }}>
                      <div className="title">{t('Shortest vacancy period')}</div>
                      <div>{t('Preferential assignment from the most recently used room')}</div>
                    </div>
                    <img src={require('@/static/images/room/vacancyPeriodHint-1.jpg')} />
                  </div>
                </Hint>
              </div>
              <div>
                <RadioGroupField
                  value={vacancyPeriod}
                  items={vacancyPeriodItems}
                  onChange={e => setVacancyPeriod(e.target.value)}
                  groupCss={{ width: 300 }}
                  itemWrapperCss={{ width: '50%' }}
                  itemCss={{ fontWeight: 'normal' }}
                  style={{ radioSize: 18 }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="block-title">{t('Other conditions')}</div>
            <div className="setting-row">
              <div className="order-label-line">
                <div className="label-text">{t('Reservation of 2 or more rooms')}</div>
              </div>
              <div>
                <RadioGroupField
                  value={floorSetting}
                  items={floorSettingItems}
                  onChange={e => setFloorSetting(e.target.value)}
                  groupCss={{ width: 300 }}
                  itemWrapperCss={{ width: '50%' }}
                  itemCss={{ fontWeight: 'normal' }}
                  style={{ radioSize: 18 }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="block-title">{t('Auto reflect timing')}</div>
            <div className="setting-row">
              <div className="order-label-line">
                <div className="label-text">{t('At time of reservation')}</div>
              </div>
              <div>
                <ToggleButton
                  value={takeInSetting}
                  onChange={() => {
                    setTakeInSetting(!takeInSetting)
                  }}
                  label={{ on: 'ON', off: 'OFF' }}
                />
              </div>
            </div>
          </div>
        </div>
      </TabContainer>
    </SettingsLayout>
  )
}

const mainContainerStyle = css({
  paddingTop: 75,

  '.block-title': {
    background: '#F2F2F2 0% 0% no-repeat padding-box',
    height: 32,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 1.2,
    color: '#676767',
    alignContent: 'center',
    padding: '0 32px',
  },
  '.setting-row': {
    padding: '0 32px',
    display: 'flex',
    justifyContent: 'space-between',
    height: 64,
    alignItems: 'center',
    borderBottom: '1px solid #F2F2F2',
    '.order-label-line': {
      display: 'flex',
      alignItems: 'center',
      gap: 24,
      '.label-text': {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 1.4,
        color: '#272727',
      },
    },
  },
})

const vacancyPeriodHintStyle = css({
  width: 600,
  padding: 32,
  img: { width: '100%' },
  '.title': {
    color: '#F2A40B',
    fontWeight: 'bold',
    marginBottom: 16,
  },
})
