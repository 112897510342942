import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

type LanguageType = 'ja' | 'en' | 'zh' | 'ko'

export const LanguageSelect: React.FC<{ drawerCss?: any }> = ({ drawerCss }) => {
  const { i18n } = useTranslation()
  const [isLanguageModalVisible, setIsLanguageModalVisible] = useState<boolean>(false)

  const changeLanguage = (language: LanguageType): void => {
    i18n.changeLanguage(language)
    localStorage.setItem('lang', language)
    setIsLanguageModalVisible(false)
  }

  const closeModal = e => {
    if (e.target === e.currentTarget) {
      //Close the menu only when you click outside the menu
      setIsLanguageModalVisible(false)
    }
  }

  useEffect(() => {
    const language = localStorage.getItem('lang')

    if (language) i18n.changeLanguage(language)
  }, [])

  return (
    <>
      <div className="languageButton" css={languageButtonStyle} onClick={() => setIsLanguageModalVisible(true)}>
        <img src={require(`@/static/images/self-checkin-payment/language.svg`)} css={languageImageStyle} alt="language" />
        <p>Language</p>
        <img src={require(`@/static/images/self-checkin-payment/arrow.svg`)} css={languageArrowImageStyle} alt="down-arrow" />
      </div>
      {isLanguageModalVisible && (
        <div
          css={languageSelectWrapperStyle}
          className="languageSelectWrapper"
          onClick={e => {
            closeModal(e)
          }}
        >
          <div css={[languagesSelectContentStyle, drawerCss]}>
            <ul>
              <li className={i18n.language === 'ja' ? 'selected' : ''} onClick={() => changeLanguage('ja')}>
                日本語
              </li>
              <li className={i18n.language === 'en' ? 'selected' : ''} onClick={() => changeLanguage('en')}>
                English
              </li>
              <li className={i18n.language === 'zh' ? 'selected' : ''} onClick={() => changeLanguage('zh')}>
                中文
              </li>
              <li className={i18n.language === 'ko' ? 'selected' : ''} onClick={() => changeLanguage('ko')}>
                한국어
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

const languageSelectWrapperStyle = css({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  top: 0,
  left: 0,
  zIndex: 100000,
})

const languagesSelectContentStyle = css({
  position: 'fixed',
  zIndex: 2,
  top: 65,
  right: 15,
  width: '14vw',
  height: 'auto',
  boxShadow: '0px 0px 6px #0000001A',
  ul: {
    maxWidth: 300,
    width: '100%',
    margin: '0 auto',
    li: {
      color: '#cccccc',
      textAlign: 'center',
      width: '100%',
      height: 24,
      fontSize: 12,
      letterSpacing: 0.6,
      borderBottom: '1px solid #cccccc4a',
      backgroundColor: '#fff',
      padding: '26px 0 36px 0',
      cursor: 'pointer',
      '&:last-child': {
        borderRight: 0,
      },
      '&.selected': {
        color: '#F2A40B',
        cursor: 'initial',
      },
    },
  },
})

const languageImageStyle = css({
  height: '18.87px',
  width: '18.87px',
  marginRight: 12,
})

const languageArrowImageStyle = css({
  height: 19,
  marginLeft: 14,
})

const languageButtonStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  color: '#F2A40B',
  fontSize: 18,
  letterSpacing: 0.7,
  lineHeight: '17px',
  cursor: 'pointer',
  margin: 'auto',
  '@media(max-width: 1080px)': {
    fontSize: 16,
  },
  '*': {
    fontFamily: 'Lato',
  },
  p: {
    fontSize: '16px',
    color: '#f2a40b',
  },
})
