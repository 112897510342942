import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Guide } from '@/utils/guide'
import { RequireText } from '@/components/atoms/require-text'

export type CustomCheckinProps = {
  index: number
  guide: Guide
  options: any
  selectedInputValue: any
  onChangeState: (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>, customCheckinId: string) => void
  getTitle: (guide: any) => string | JSX.Element | JSX.Element[] | undefined
  getContent: (guide: any) => string | JSX.Element | JSX.Element[] | undefined
  isEdit?: boolean
}

export const CustomCheckin: React.FC<CustomCheckinProps> = ({
  index,
  guide,
  options,
  selectedInputValue,
  onChangeState,
  getTitle,
  getContent,
  isEdit,
}) => {
  const { t } = useTranslation()
  return (
    <div css={isEdit ? editFormWrapperStyle : formWrapperStyle}>
      <div css={isEdit ? editItemWrapperStyle : itemWrapperStyle}>
        {isEdit ? <h2 css={titleStyle}>{getTitle(guide)}</h2> : <h1 css={titleStyle}>{getTitle(guide)}</h1>}
        {guide.inputType !== 'none' && Boolean(guide.isRequired) ? <RequireText /> : ''}
        <div>{getContent(guide)}</div>
        {guide.inputType === 'textArea' && (
          <textarea
            id={guide.customCheckinId}
            css={textAreaStyle}
            rows={3}
            name="value"
            placeholder={t('Please write your requests')}
            defaultValue={guide.selectedAnswer}
            value={selectedInputValue.value}
            onChange={e => onChangeState(e, guide.customCheckinId)}
          />
        )}
        {guide.inputType === 'textArea' ? (
          <div id={guide.customCheckinId + '-error'} css={errorMessage}>
            {t('Required field has not been entered')}
          </div>
        ) : null}
        {guide.inputType === 'selectBox' && (
          <select
            id={guide.customCheckinId}
            css={selectBoxStyle}
            name="value"
            value={selectedInputValue.value}
            onChange={e => onChangeState(e, guide.customCheckinId)}
          >
            <option value="">{guide.selectedAnswer && guide.selectedAnswer !== '-' ? guide.selectedAnswer : t('Please select')}</option>
            {options[index] &&
              options[index].map(
                (option, i) =>
                  guide.selectedAnswer !== option && (
                    <option key={`selectedAnswer${i}`} defaultValue={selectedInputValue.value} value={option}>
                      {option}
                    </option>
                  ),
              )}
          </select>
        )}
        {guide.inputType === 'selectBox' ? (
          <div id={guide.customCheckinId + '-error'} css={errorMessage}>
            {t('Required field has not been entered')}
          </div>
        ) : null}
      </div>
    </div>
  )
}

const itemWrapperStyle = css({
  '*': {
    fontFamily: 'Noto Sans JP',
  },
  backgroundColor: '#FFFFFF',
  lineHeight: '1.5',
  h1: {
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 16,
    lineHeight: '25px',
    color: '#272727',
    letterSpacing: '1.8px',
  },
  h2: {
    textAlign: 'left',
    fontSize: 18,
    marginBottom: 16,
    lineHeight: '27px',
    color: '#272727',
  },
  p: {
    textAlign: 'left',
    fontSize: 16,
    color: '#272727',
    letterSpacing: '1.6px',
    lineHeight: '21px',
    fontWeight: 500,
    fontFamily: '游ゴシック体',
    strong: {
      fontWeight: 'bold',
    },
  },
  br: {
    display: 'block',
    content: '""',
    margin: '5px 0',
  },
  ul: {
    marginLeft: '40px',
  },
  ol: {
    marginLeft: '40px',
  },
  li: {
    textAlign: 'left',
    fontSize: '16px',
    '@media(max-width: 1080px)': {
      fontSize: 18,
    },
  },
  textarea: {
    marginTop: 24,
    '-webkitAppearance': 'none',
  },
  strong: {
    fontWeight: 'bold',
  },
  a: {
    fontSize: '16px',
    letterSpacing: '1.6px',
    lineHeight: '26px',
    color: '#f2a40b',
    fontFamily: '游ゴシック体',
  },
})

const editItemWrapperStyle = css(itemWrapperStyle, {
  borderBottom: '1px solid #F2F2F2',
  padding: 50,
})

const formWrapperStyle = css({
  maxWidth: '874px',
  backgroundColor: '#fff',
  margin: '0 auto',
  padding: 50,
  borderBottom: '1px solid #F2F2F2',
  borderRadius: 5,
})

const editFormWrapperStyle = css({
  maxWidth: '874px',
  backgroundColor: '#fff',
  margin: '0 auto',
})

const titleStyle = css({
  display: 'inline-block',
})

const errorMessage = css({
  visibility: 'hidden',
  fontSize: '14px',
  fontWeight: 'bold',
  marginTop: '16px',
  textAlign: 'left',
  color: '#FF2700',
})

const textAreaStyle = css({
  width: '100%',
  textAlign: 'left',
  borderRadius: 10,
  border: '1px solid #CCCCCC',
  padding: 16,
  fontSize: 21,
})

const selectBoxStyle = css({
  '-webkit-appearance': 'none',
  width: '100%',
  height: 60,
  color: '#272727',
  fontSize: 21,
  border: '1px solid #CCCCCC',
  borderRadius: 50,
  paddingLeft: 20,
  marginTop: 24,
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '96.5% center',
  backgroundColor: '#FFFFFF',
  backgroundSize: 16,
  display: 'block',
})
