import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/atoms/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { TextareaField } from '@/components/molecules/textarea-field'
import { updateAnnounceForCleaningManager } from '@/apis/aipass'
import { css } from '@emotion/core'
import { Dayjs } from 'dayjs'

type EditNoticeModalProps = {
  selectedDate: Dayjs
  adminComment: string
  onSave: () => void
  onClose: () => void
}

export const EditNoticeModal = ({ selectedDate, adminComment, onSave, onClose }: EditNoticeModalProps) => {
  const { t } = useTranslation()
  const [formValues, setFormValues] = useState<{ dateOf: Dayjs; announce: string }>({
    dateOf: selectedDate,
    announce: adminComment,
  })
  const [changedFromValue, setChangedFromValue] = useState(false)

  const save = () => {
    setChangedFromValue(false)
    updateAnnounceForCleaningManager(selectedDate, formValues.announce).then(() => {
      onSave()
    })
  }

  const changeFormValue = (key: keyof typeof formValues, newValue: string) => {
    setChangedFromValue(true)
    setFormValues({
      ...formValues,
      [key]: newValue,
    })
  }

  return (
    <Modal customCss={modalStyle}>
      <ModalHeader customCss={modalHeaderStyle}>{t('cleaningManager.Notice')}</ModalHeader>
      <ModalBody customCss={modalBodyStyle}>
        <div>
          <div css={textareaStyle}>
            <TextareaField
              value={formValues.announce}
              name="notice"
              placeholder=""
              marginBottom={0}
              handleChangeData={e => changeFormValue('announce', e.target.value)}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter customCss={modalFooterStyle}>
        <Button buttonType={3} height="36px" width="166px" marginRight={16} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={changedFromValue ? 1 : 5} height="36px" width="166px" onClick={save} isDisabled={!changedFromValue}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = css({
  maxHeight: '404px',
})

const modalHeaderStyle = css({
  textAlign: 'center',
  backgroundColor: '#F2F2F2',
  boxShadow: 'none',
  color: '#676767',
})

const modalBodyStyle = css({
  padding: 0,
  height: '274px',
})

const modalFooterStyle = css({
  height: '68px',
  display: 'flex',
  justifyContent: 'space-between',
})

const textareaStyle = css({
  padding: '0 32px',
  textarea: {
    marginBottom: 0,
    height: '245px',
    padding: '24px',
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '1.6px',
    ':focus': {
      padding: '23px',
    },
  },
})
