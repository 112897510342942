export type ChannelCodeType = {
  id: string | null
  code: string | null
  name: string | null
  type: ChannelType
}

// Setting default items
export const ChannelType = {
  Custom: 0, // unspecified
  Default: 1, // Default
} as const
type ChannelType = typeof ChannelType[keyof typeof ChannelType]
