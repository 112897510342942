import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { omit, uniqBy } from 'lodash'

// apis
import { deleteSalesSubjectSet, fetchSalesSubject, fetchSalesSubjectSet, updateSalesSubjectSetList } from '@/apis/aipass'

// constants
import { simpleTableStyle } from '@/constants/layout'

// components
import { SettingSalesManagerTab } from '@/components/organisms/settings/sales-manager/tab'

import { useErrorHandler } from '@/hooks/use-error-handler'
import { EditFooter } from '@/components/organisms/edit-footer'
import { SalesSubjectType, SalesSubjectSetType } from '@/models/sales-manager/sales'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { SettingSalesSubjectEditButtons } from '@/components/organisms/settings/sales-manager/subject-set/edit-buttons'
import { SubjectSetModal } from '@/components/organisms/settings/sales-manager/subject-set/subject-set-modal'

type ContainerProps = {}

export const SettingSalesManagerSubjectSet: React.FC<ContainerProps> = () => {
  const { errorHandler } = useErrorHandler()
  const { t } = useTranslation()
  const csvDataHeader = ['科目セットID', '科目セット名']
  const [salesSubjectSet, setSalesSubjectSet] = useState<SalesSubjectSetType[]>([])
  const [salesSubjects, setSalesSubjects] = useState<SalesSubjectType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showSubjectSetModal, setShowSubjectSetModal] = useState<boolean>(false)
  const [editSubjectSet, setEditSubjectSet] = useState<SalesSubjectSetType>()
  const [deleteSalesSubjectSetIds, setDeleteSalesSubjectSetIds] = useState<string[]>([])
  const [csvData, setCsvData] = useState([csvDataHeader])

  const _fetchSalesSubjectSet = async () => {
    setIsLoading(true)
    const res = await fetchSalesSubjectSet()
    setSalesSubjectSet(res?.salesSubjectSet || [])
    setIsLoading(false)
  }

  const _fetchSalesSubject = async () => {
    try {
      setIsLoading(true)

      const res = await fetchSalesSubject()

      setSalesSubjects(uniqBy<SalesSubjectType>(res?.salesSubjects || [], 'subjectName'))
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const _deleteSalesSubject = async () => {
    try {
      setIsLoading(true)

      await deleteSalesSubjectSet({ deleteSalesSubjectSetIds })

      setDeleteSalesSubjectSetIds([])

      await _fetchSalesSubjectSet()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onFileLoad = async data => {
    try {
      setIsLoading(true)

      if (!data?.[0]['科目セットID']) {
        return alert(t('Subject set ID (order) is empty'))
      }
      if (!data?.[0]['科目セット名']) {
        return alert(t('Subject set name is empty'))
      }

      const salesSubjectSetList = data.map(item => ({
        salesSubjectSetNameId: item['科目セットID'] || '',
        salesSubjectSetName: item['科目セット名'] || '',
        salesSubjectList: Object.values(omit(item, '科目セットID', '科目セット名')).filter(i => !!i),
      }))

      const res = await updateSalesSubjectSetList({ salesSubjectSetList })
      await _fetchSalesSubjectSet()

      if (res?.errors) errorHandler({ response: { data: res } })
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onClickAdd = () => {
    setShowSubjectSetModal(true)
  }

  const onClickEdit = item => {
    setEditSubjectSet(item)
    setShowSubjectSetModal(true)
  }

  const cancelEdit = () => {
    setEditSubjectSet(undefined)
    setShowSubjectSetModal(false)
  }

  const onClickDelete = item => {
    setDeleteSalesSubjectSetIds([...deleteSalesSubjectSetIds, item.salesSubjectSetId])
  }

  const onSuccessChanged = () => {
    cancelEdit()
    _fetchSalesSubjectSet()
  }

  useEffect(() => {
    _fetchSalesSubjectSet()
    _fetchSalesSubject()
  }, [])

  useEffect(() => {
    const maxSubjectCount =
      salesSubjectSet?.reduce((max, i) => (max > i.salesSubjectSetMap.length ? max : i.salesSubjectSetMap.length), 0) || 0

    setCsvData([
      [...csvDataHeader, ...[...Array(maxSubjectCount)].map((i, index) => `科目(${index + 1})`)],
      ...salesSubjectSet.map(item => [
        String(item.salesSubjectSetNameId),
        item.salesSubjectSetName,
        ...item.salesSubjectSetMap.map(i => i.salesSubjectMasterName),
      ]),
    ])
  }, [salesSubjectSet])

  const footerContent = deleteSalesSubjectSetIds?.length ? (
    <EditFooter onSave={_deleteSalesSubject} onCancel={() => setDeleteSalesSubjectSetIds([])} />
  ) : undefined

  return (
    <>
      {showSubjectSetModal && (
        <SubjectSetModal
          salesSubjects={salesSubjects}
          editSubjectSet={editSubjectSet}
          onClickCancel={cancelEdit}
          onSuccess={onSuccessChanged}
        />
      )}
      <SettingsLayout loading={isLoading} footerContent={footerContent}>
        <TabContainer
          tabComponent={<SettingSalesManagerTab currentTab="Subject set" />}
          isShowFooter={!!deleteSalesSubjectSetIds?.length}
        >
          <SettingSalesSubjectEditButtons csvData={csvData} onFileLoad={onFileLoad} onClickAddButton={onClickAdd} />

          <table css={salesSubjectSetTableStyle}>
            <thead>
              <tr>
                <th css={{ width: '15%' }}>{t('Subject set ID (order)')}</th>
                <th css={{ width: '30%' }}>{t('Subject set name')}</th>
                <th css={{ width: '55%' }}>{t('Subject')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {salesSubjectSet.map(item => {
                if (deleteSalesSubjectSetIds.includes(item.salesSubjectSetId)) return null

                return (
                  <tr key={item.salesSubjectSetId} css={{ cursor: 'pointer' }} onClick={() => onClickEdit(item)}>
                    <td css={{ paddingTop: 16, paddingBottom: 16 }}>{item.salesSubjectSetNameId}</td>
                    <td css={{ paddingTop: 16, paddingBottom: 16 }}>{item.salesSubjectSetName}</td>
                    <td css={{ paddingTop: 16, paddingBottom: 16 }}>
                      {item.salesSubjectSetMap.map(i => i.salesSubjectMasterName).join(', ')}
                    </td>
                    <td css={{ paddingTop: 16, paddingBottom: 16 }}>
                      <img
                        css={deleteButton}
                        src={require('@/static/images/room_delete_gold.svg')}
                        onClick={e => {
                          e.stopPropagation()
                          onClickDelete(item)
                        }}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </TabContainer>
      </SettingsLayout>
    </>
  )
}

const salesSubjectSetTableStyle = css(simpleTableStyle, {
  'tr th': {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  overflow: 'auto',
  display: 'block',
})

const deleteButton = css({
  opacity: 0.6,
  transition: 'opacity 300ms ease-out',
  ':hover': {
    opacity: 1,
  },
})
