import uuid from 'react-uuid'
import S3 from 'aws-sdk/clients/s3'
import b64toBlob from 'b64-to-blob'
import { env } from '@/libs/env'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { AccountContext } from '@/contexts/account'

const bucketName = env('AWS_BUCKET_NAME')
const bucket = new S3({
  accessKeyId: env('AWS_ACCESS_KEY_ID'),
  secretAccessKey: env('AWS_SECRET_ACCESS_KEY'),
  region: env('AWS_REGION'),
})

export const useFileUpload = () => {
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)

  const inputFileHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    allowMineType: string[],
    onFileUploaded: (result: { uploadedPath: string, file: File }) => void,
  ) => {
    const files = event.target.files
    if (!files?.length) {
      return
    }
    const file = files[0]
    if (!allowMineType.includes(file?.type)) {
      window.alert(t('UnsupportedFileFormats'))
      return
    }
    const allowMaxFileByte = 10 * 1024 * 1024
    if (file.size > allowMaxFileByte) {
      window.alert(t('FileSizeTooLarge'))
      return
    }

    const reader = new FileReader()
    reader.onload = async e => {
      const fileData = e.target ? e.target.result : null
      if (fileData) {
        const uploadedPath = await upload({
          hotelId: account.employee.hotelId,
          file,
          fileData: fileData.toString(),
        })

        onFileUploaded({ uploadedPath, file })
      }
    }
    reader.readAsDataURL(file)
  }

  const upload = async ({
    hotelId,
    file,
    fileData,
  }: {
    hotelId: string
    file: { name: string; type: string }
    fileData: string
  }): Promise<string> => {
    const encodeTrimmedFileData = fileData.replace(`data:${file.type};base64,`, '')
    const param: S3.Types.PutObjectRequest = {
      Bucket: bucketName,
      Key: `${hotelId}/${uuid()}/${file.name}`,
      Body: b64toBlob(encodeTrimmedFileData, file.type),
      ACL: 'public-read',
      ContentType: file.type,
    }
    return new Promise(resolve => {
      bucket.upload(param, (err: Error, data: S3.ManagedUpload.SendData) => {
        resolve(data.Location)
      })
    })
  }

  return { inputFileHandler, upload }
}
