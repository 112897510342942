import * as React from 'react'
import Lottie from 'react-lottie'
import { css } from '@emotion/core'

type LoadingProps = {
  isLoading: boolean
}

export const NoticeLoading: React.FC<LoadingProps> = ({ isLoading }) => {
  const animationData = require('@/static/images/json/loading.json')
  return (
    <React.Fragment>
      {isLoading && (
        <div css={loadinOverlayStyle}>
          <div css={loadingIconStyle}>
            <Lottie height="80px" width="80px" options={{ animationData: animationData, autoplay: true, loop: true }} />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

const loadinOverlayStyle = css({
  position: 'absolute',
  zIndex: 100,
  top: 50,
  left: 4,
  width: 'calc(100% - 10px)',
  height: 'calc(100% - 55px)',
  background: 'rgba(255, 255, 255, 0.8)',
  opacity: 0.7,
  alignItems: 'center',
})

const loadingIconStyle = css({
  position: 'absolute',
  top: '35%',
  left: 'calc(50% - 40px)',
})
