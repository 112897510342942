import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonRoleType } from '@/models/guest-app/design'
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form'
import { InputField } from '@/components/molecules/input-field'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { css } from '@emotion/core'
import { useFileUpload } from '@/hooks/use-file-upload'
import { AssetButtonDetailFormValue } from './button-asset-detail'

type Props = {
  namePrefix: 'button1' | 'button2'
  buttonLabelName: string
  control: Control<AssetButtonDetailFormValue, any>
  setValue: UseFormSetValue<AssetButtonDetailFormValue>
}

export const ButtonForm: React.FC<Props> = ({ control, setValue, namePrefix, buttonLabelName }) => {
  const { t } = useTranslation()
  const typeWatcher = useWatch({ control, name: `${namePrefix}.type` })
  const fileNameWatcher = useWatch({ control, name: `${namePrefix}.fileName` })

  const inputFileRef = useRef<HTMLInputElement>(null)
  const { inputFileHandler } = useFileUpload()

  return (
    <div css={formStyle}>
      <div className="input-area">
        <div className="input-label">{buttonLabelName}</div>
        <Controller
          name={`${namePrefix}.name`}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, name } }) => (
            <InputField marginBottom={0} placeholder={t('ButtonName')} value={value} name={name} handleChangeData={onChange} />
          )}
        />
      </div>
      <div className="input-area">
        <div className="input-label">{t('ButtonLinkTo')}</div>
        <Controller
          name={`${namePrefix}.type`}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, name } }) => (
            <RadioGroupField
              value={value}
              name={name}
              onChange={onChange}
              items={[
                { value: 'url', label: 'URL' },
                { value: 'image', label: t('Image') },
              ]}
              style={{ radioSize: 18, labelMargin: 12 }}
              groupCss={radioGroupWrapperStyle}
            />
          )}
        />
        {typeWatcher === 'url' && (
          <Controller
            name={`${namePrefix}.link`}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, name } }) => (
              <InputField marginBottom={0} placeholder="URL" value={value} name={name} handleChangeData={onChange} />
            )}
          />
        )}
        {typeWatcher === 'image' && (
          <>
            {!fileNameWatcher && (
              <div
                css={uploadAreaStyle}
                onClick={() => {
                  if (inputFileRef.current) {
                    inputFileRef.current.value = ''
                    inputFileRef.current.click()
                  }
                }}
              >
                <img src={require('@/static/images/plus.svg')} width={13} />
                <div>{t('UploadImage')}</div>
                <input
                  type="file"
                  ref={inputFileRef}
                  onChange={e =>
                    inputFileHandler(e, ['application/pdf', 'image/jpeg', 'image/png'], f => {
                      setValue(`${namePrefix}.filePath`, f.uploadedPath)
                      setValue(`${namePrefix}.fileName`, f.file.name)
                    })
                  }
                  style={{ display: 'none' }}
                />
              </div>
            )}
            {fileNameWatcher && (
              <div css={uploadAreaStyle} className="uploaded">
                <div>{fileNameWatcher}</div>
                <img
                  src={require('@/static/images/delete_orange.svg')}
                  width={20}
                  onClick={() => {
                    setValue(`${namePrefix}.filePath`, '')
                    setValue(`${namePrefix}.fileName`, '')
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="input-area">
        <div className="input-label">{t('Design')}</div>
        <Controller
          name={`${namePrefix}.designType`}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, name } }) => (
            <RadioGroupField
              value={value}
              name={name}
              onChange={onChange}
              items={[
                { value: ButtonRoleType.Primary, label: `${t('Button')}1` },
                { value: ButtonRoleType.Secondary, label: `${t('Button')}2` },
              ]}
              style={{ radioSize: 18, labelMargin: 12 }}
              groupCss={css([radioGroupWrapperStyle, radioLabelStyleForDesignType])}
            />
          )}
        />
      </div>
    </div>
  )
}

const radioLabelStyleForDesignType = css({
  height: 32,
  label: {
    width: 98,
    height: 30,
    borderRadius: 6,
    lineHeight: '30px',
    textAlign: 'center',
  },
  'div:nth-of-type(1)': {
    label: {
      background: '#A59785',
      color: '#FFFFFF',
    },
  },
  'div:nth-of-type(2)': {
    label: {
      background: '#FFFFFF',
      border: '1px solid #A59785',
      color: '#A59785',
    },
  },
})

const formStyle = css({
  '.input-area': {
    marginTop: 24,
    ':first-of-type': {
      marginTop: 0,
    },
  },
  '.input-label': {
    marginBottom: 12,
    color: '#676767',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.6,
  },
})

const radioGroupWrapperStyle = css({
  alignSelf: 'start',
  marginBottom: 12,
  '> div:first-of-type': {
    marginRight: 24,
  },
})

const uploadAreaStyle = css({
  display: 'flex',
  justifyContent: 'center',
  border: '1px dashed #CCCCCC',
  background: '#FAFAFA',
  fontSize: 12,
  fontWeight: 'bold',
  color: '#F2A40B',
  height: 32,
  lineHeight: '30px',
  cursor: 'pointer',
  borderRadius: 5,
  '> img': {
    marginRight: 10,
  },
  '&.uploaded': {
    justifyContent: 'space-between',
    borderStyle: 'solid',
    padding: '0px 16px',
    '> img': {
      cursor: 'pointer',
    },
  },
})
