import React, { useContext, useEffect, useMemo, useState } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { EditFooter } from '@/components/organisms/edit-footer'
import * as api from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { GuestAppTab } from '@/components/molecules/settings/guest-app-tab'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { DefaultThemeType, DesignType, themeTypeLabel } from '@/models/guest-app/design'
import { ColorPickerInput } from '@/components/molecules/settings/color-picker-input'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { AccountContext } from '@/contexts/account'
import { checkActivePlugin, PluginCode } from '@/libs/plugins'

import { ReactComponent as IconMenuHamburger } from '@/static/images/custom-design/icon_menu_hamburger.svg'
import { ReactComponent as IconPulldown } from '@/static/images/custom-design/icon_pulldown.svg'
import { DesignCm } from '@/components/organisms/settings/guest-app/design-cm'

export const SettingGuestAppDesign: React.FC = () => {
  type FormType = {
    brandColor: string
    type: DefaultThemeType
  }
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [loading, setLoading] = useState<boolean>(false)
  const [showFooter, setShowFooter] = useState(false)
  const [designTheme, setDesignTheme] = useState<DesignType | undefined>()
  const [apiCallInterval, setApiCallInterval] = useState<NodeJS.Timeout>()
  const { control, setValue, handleSubmit, getValues } = useForm<FormType>({
    defaultValues: {
      brandColor: '#ffffff',
      type: DefaultThemeType.White,
    },
  })
  const brandColorWatcher = useWatch({ control, name: 'brandColor' })
  const typeWatcher = useWatch({ control, name: 'type' })
  const { plugins } = useContext<any>(AccountContext)

  const fetchDesignTheme = async () => {
    const storeDesign = await api.getDesignTheme()
    if (!storeDesign) {
      return
    }
    setDesignTheme(storeDesign)
    setValue('brandColor', storeDesign.theme.brandColor)
    setValue('type', storeDesign.theme.type)
  }
  const previewDesignTheme = async () => {
    if (!hasDesignCustomPlugin) {
      return
    }
    const storeDesign = await api.previewDesignTheme(getValues())
    if (!storeDesign) {
      return
    }
    setDesignTheme(storeDesign)
  }

  const doEditTheme = async (formValues: FormType) => {
    try {
      setLoading(true)
      await api.updateDesignTheme(formValues)
      initialView()
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const hasDesignCustomPlugin = useMemo(() => checkActivePlugin(plugins, PluginCode.GuestAppDesign), [plugins])

  useEffect(() => {
    if (apiCallInterval) {
      clearTimeout(apiCallInterval)
    }
    setApiCallInterval(setTimeout(() => previewDesignTheme(), 500))
  }, [brandColorWatcher, typeWatcher])

  const initialView = () => {
    setShowFooter(false)
    fetchDesignTheme()
  }

  useEffect(initialView, [])

  const customStyle = () =>
    css({
      '*': {
        fontFamily: designTheme?.text.fontFamily,
        color: designTheme?.text.textColor,
      },
      '&.custom-background': {
        backgroundColor: designTheme?.background.backgroundColor,
        margin: 24,
        boxShadow: '0px 0px 6px #0000001A',
        borderRadius: 5,
      },
      '.custom-card': {
        backgroundColor: designTheme?.background.cardColor,
      },
      '.custom-button': {
        backgroundColor: designTheme?.button[0].backgroundColor,
        borderRadius: designTheme?.button[0].borderRadius,
        color: designTheme?.button[0].textColor,
      },
      '.custom-icon': {
        '& :not([fill="none"])': {
          fill: designTheme?.icon.color,
        },
      },
      '.custom-head-line': {
        color: designTheme?.text.headlineColor,
      },
      '.custom-link': {
        color: designTheme?.text.linkColor,
      },
      '.custom-border':{
        borderColor: designTheme?.background.backgroundColor
      }
    })

  const footerContent = showFooter ? <EditFooter onSave={handleSubmit(doEditTheme)} onCancel={initialView} /> : undefined

  return (
    <>
      <SettingsLayout loading={loading} footerContent={footerContent}>
        <>
          {hasDesignCustomPlugin && (
            <div css={multiContainerStyle}>
              <div className="left-container">
                <TabContainer tabComponent={<GuestAppTab currentTab="Design" />} isShowFooter={showFooter}>
                  <div css={formPanelStyle}>
                    <div className="brand-color-area">
                      <div className="input-label">{t('BrandColor')}</div>
                      <Controller
                        control={control}
                        name="brandColor"
                        render={({ field: { onChange, value } }) => (
                          <ColorPickerInput
                            value={value}
                            onChange={e => {
                              setShowFooter(true)
                              onChange(e)
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="theme-area">
                      <div className="input-label">{t('Theme')}</div>
                      <Controller
                        control={control}
                        name="type"
                        render={({ field: { onChange, value } }) => (
                          <RadioGroupField
                            value={value}
                            onChange={e => {
                              setShowFooter(true)
                              onChange(e)
                            }}
                            style={{ radioSize: 21, labelMargin: 12 }}
                            itemWrapperCss={{ width: 154, marginRight: 28 }}
                            items={[
                              { value: DefaultThemeType.White, label: themeTypeLabel[DefaultThemeType.White] },
                              { value: DefaultThemeType.Black, label: themeTypeLabel[DefaultThemeType.Black] },
                            ]}
                          />
                        )}
                      />
                      <div>
                        <img className="view-img" src={require('@/static/images/custom-design/image_white.png')} />
                        <img className="view-img" src={require('@/static/images/custom-design/image_black.png')} />
                      </div>
                      <span>
                        ※他のテーマをご希望の方は
                        <a href="https://aipass.zendesk.com/hc/ja/requests/new" target="_blank" rel="noreferrer">
                          サポート
                        </a>
                        までお問い合わせください
                      </span>
                    </div>
                  </div>
                </TabContainer>
              </div>
              <div className="right-container">
                <div css={previewStyle}>
                  <div className="preview-header">
                    プレビュー <p>※アセットや画像は一例です</p>
                  </div>
                  <div css={customStyle()} className="custom-background">
                    <div className="section-header custom-card">
                      <h1 className="custom-head-line">aipass HOTEL</h1>
                      <IconMenuHamburger className="custom-icon" />
                    </div>
                    <div className="section-check-in-out">
                      <img src={require('@/static/images/custom-design/hotel_demo_picture.png')}></img>
                      <div>
                        <div className="custom-button">チェックイン</div>
                        <div className="custom-button">チェックアウト</div>
                      </div>
                    </div>
                    <div className="section-free custom-card">
                      <div className="title">
                        <div className="custom-head-line">重要なお知らせ</div>
                        <span className="custom-link">すべて見る</span>
                      </div>
                      <div>
                        <div className="item custom-border">
                          <span>2023.08.02</span>
                          <div>エレベーターの点検工事のご案内</div>
                        </div>
                        <div className="item custom-border">
                          <span>2023.07.28</span>
                          <div>手配荷物配送サービスのご案内</div>
                        </div>
                        <div className="item custom-border">
                          <span>2023.07.26</span>
                          <div>駐車場の事前予約に関するご案内</div>
                        </div>
                      </div>
                    </div>
                    <div className="section-pulldown custom-card">
                      <div className="title">
                        <div className="custom-head-line">無料Wi-Fi</div>
                        <IconPulldown className="custom-icon" />
                      </div>
                    </div>
                    <div className="section-banner">
                      <img src={require('@/static/images/custom-design/preview_banner.png')}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!hasDesignCustomPlugin && <DesignCm />}
        </>
      </SettingsLayout>
    </>
  )
}
const multiContainerStyle = css({
  display: 'flex',
  '.left-container': {
    width: '70%',
  },
  '.right-container': {
    width: '30%',
    marginLeft: 16,
  },
})

const formPanelStyle = css({
  padding: '50px 32px',
  display: 'flex',
  '.brand-color-area': {
    paddingRight: 32,
    width: 240,
  },
  '.theme-area': {
    '.view-img': {
      width: 154,
      marginRight: 28,
      paddingTop: 20,
      paddingBottom: 24,
    },
    '> span': {
      fontSize: 12,
      letterSpacing: 1.2,
      color: '#676767',
      a: {
        color: '#F2A40B',
        '&:hover': {
          color: '#676767',
        },
      },
    },
  },
  '.input-label': {
    letterSpacing: 0.6,
    fontSize: 12,
    fontWeight: 'bold',
    color: '#676767',
    paddingBottom: 12,
  },
})

const previewStyle = css({
  marginTop: 45,
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
  overflow: 'hidden',
  '.preview-header': {
    background: '#ffffff',
    padding: 16,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1.4,
    color: '#676767',
    display:'flex',
    '> p': {
      paddingLeft: 16,
      fontSize: 10,
      fontWeight: 400,
      letterSpacing: 0.5,
      color: '#676767',
      height: 14,
      lineHeight: '14px',
    },
  },
  '.section-header': {
    display: 'flex',
    borderRadius: 5,
    justifyContent: 'space-between',
    padding: '0 16px',
    alignItems: 'center',
    div: {
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: 0.9,
    },
  },
  '.section-check-in-out': {
    marginBottom: -24,
    img: {
      width: '100%',
      maxHeight: 200,
      objectFit: 'cover',
    },
    '> div': {
      display: 'flex',
      position: 'relative',
      top: -24,
      padding: '0 16px',
    },
    '.custom-button': {
      width: '50%',
      textAlign: 'center',
      height: 48,
      lineHeight: '48px',
      fontSize: 16,
      '&:first-of-type': {
        marginRight: 8,
      },
    },
  },
  '.section-free': {
    marginTop: 16,
    padding: 16,
    '.title': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '> span': {
        fontSize: 12,
      },
    },
    '.item': {
      display: 'flex',
      alignItems: 'center',
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      padding: '16px 0',
      '&:first-of-type': {
        paddingTop: 0,
        border: 'none',
      },
      '> span': {
        fontSize: 10,
        paddingRight: 16,
      },
      '> div': {
        fontSize: 14,
      },
    },
  },
  '.section-pulldown': {
    marginTop: 8,
    padding: '0 16px',
    '.title': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  '.section-banner': {
    marginTop: 16,
    width: '100%',
    padding: '0 15px 24px 15px',
    '> img': {
      width: '100%',
    },
  },
  '.custom-head-line': {
    fontSize: 18,
    letterSpacing: 0.9,
    padding: '16px 0',
  },
})
