import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

import { Label } from '@/components/molecules/label'
import { ImageCropModal } from '../molecules/image-crop-modal'

export type LoadedFileType = string | ArrayBuffer | null

type FileFieldProps = {
  value?: LoadedFileType
  label?: string
  withCrop?: boolean
  onChange: React.Dispatch<LoadedFileType>
}

export const FileField = ({ value, label, withCrop, onChange }: FileFieldProps) => {
  const { t } = useTranslation()
  const [imageForCrop, setImageForCrop] = useState<LoadedFileType>()

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const base64 = await getBase64(e.target.files?.[0])

    if (!withCrop) return onChange(base64)

    setImageForCrop(base64)
  }

  const onImageCropped = (value: LoadedFileType) => {
    onChange(value)
    setImageForCrop(undefined)
  }

  const getBase64 = (file): Promise<LoadedFileType> => {
    return new Promise((resolve, reject) => {
      if (!file) resolve(null)

      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  return (
    <>
      <div>
        <Label label={label || t('Image')} />
        <label htmlFor="productPicture" css={productPictureStyle}></label>
        {!value && (
          <input
            type="file"
            id="productPicture"
            css={productPictureInputNoneStyle}
            onChange={e => handleChangeFile(e)}
            onClick={(e: any) => {
              e.target.value = ''
            }}
          />
        )}
        {value && (
          <>
            <input
              type="file"
              id="productPicture"
              css={productPictureInputNoneStyle}
              style={{ backgroundImage: `url(${value || ''})` }}
              onChange={e => handleChangeFile(e)}
              onClick={(e: any) => {
                e.target.value = ''
              }}
            />
            <div css={productImageStyle} style={{ backgroundImage: `url(${value || ''})` }}></div>
          </>
        )}
      </div>
      {imageForCrop && <ImageCropModal value={imageForCrop} onChange={onImageCropped} onClose={() => setImageForCrop(undefined)} />}
    </>
  )
}

const productPictureStyle = css({
  marginBottom: 16,
  '&::after': {
    display: 'inline-block',
    width: 310,
    height: 180,
    content: '""',
    verticalAlign: 'middle',
    backgroundImage: `url(${require('@/static/images/camera_icon_white.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(0,0,0,0.27)',
    backgroundSize: 30,
    borderRadius: 5,
    marginBottom: 16,
  },
})

const productPictureInputNoneStyle = css({
  position: 'absolute',
  display: 'none',
})

const productImageStyle = css({
  width: 310,
  height: 180,
  margin: '-195px 0 16px 0',
  borderRadius: 5,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
})
