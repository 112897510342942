import React, { useEffect, useState } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { dashboardPageContainerStyle } from '@/constants/layout'
import { DetailContentForm, formatFormData } from '@/components/organisms/settings/mail-delivery/detail-content-form'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormFooter } from '@/components/organisms/settings/mail-delivery/form-footer'
import { DateExecution, MailDeliveryContent, MailDeliveryContentInput } from '@/models/mail-delivery/mail-delivery-content'
import { sendTestMailDeliveryContent, updateMailDeliveryContent } from '@/apis/aipass'
import { errorHandler } from '@/libs/errors'
import { useHistory, useParams } from 'react-router-dom'
import { SentTestMailDialog } from './sent-test-mail-dialog'

interface FormEditContainerProps {
  detail: MailDeliveryContent
  fetchLoading: boolean
}

export const FormEditContainer: React.FC<FormEditContainerProps> = ({ detail, fetchLoading }) => {
  const [loading, setLoading] = useState(false)
  const [sentEmail, setSentEmail] = useState('')

  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const channelCodes = detail.channelCodes.map(channelCode => channelCode.reservationChannelCodeId)

  const useFormReturn = useForm<MailDeliveryContentInput>({
    defaultValues: {
      title: detail.title.ja,
      status: detail.status,
      deliveryDate: {
        numberOfDay: detail.deliveryDate.numberOfDay,
        dateExecution: detail.deliveryDate.dateExecution || DateExecution.Ago,
        deliveryTime: detail.deliveryDate.deliveryTime,
        targetStatus: detail.deliveryDate.targetStatus,
      },
      isSendToAfterDelivery: detail.isSendToAfterDelivery,
      lineNotificationStatus: detail.lineNotificationStatus,
      reservationStatuses: detail.reservationStatuses,
      channelCodes: detail.isNullSearchableChannelCode ? ['-', ...channelCodes] : channelCodes,
    },
  })
  const { handleSubmit, setValue } = useFormReturn

  const onSubmit: SubmitHandler<MailDeliveryContentInput> = async submitData => {
    const formattedData = formatFormData(submitData)

    try {
      setLoading(true)
      await updateMailDeliveryContent(id, formattedData)

      return history.push({ pathname: `/setting/mail-delivery` })
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const onSendTest = async (email: string) => {
    try {
      setLoading(true)
      await sendTestMailDeliveryContent(email, useFormReturn.getValues())
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
      setSentEmail(email)
    }
  }

  useEffect(() => {
    setValue('content', detail.content.ja)
    setValue('rawContent', detail.rawContent)
  }, [])

  return (
    <>
      <SettingsLayout
        loading={fetchLoading || loading}
        footerContent={
          <FormFooter
            onSave={handleSubmit(onSubmit)}
            onCancel={() => history.push({ pathname: `/setting/mail-delivery` })}
            onSendTest={onSendTest}
            useFormReturn={useFormReturn}
          />
        }
      >
        <div css={dashboardPageContainerStyle}>
          <div css={headerSettingStaffStyle}>
            <div css={headerDetailStyle}>{t('Delivery Details')}</div>
          </div>

          <DetailContentForm useFormReturn={useFormReturn} detail={detail} />
        </div>
      </SettingsLayout>
      <SentTestMailDialog email={sentEmail} onClose={() => setSentEmail('')} />
    </>
  )
}

const headerSettingStaffStyle = css({
  height: '50px',
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const headerDetailStyle = css({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: '16px 0',
  color: '#272727',
})
