export const FacilityPageIndex = {
  Basic: '1',
  Access: '2',
  Wifi: '3',
  Guide: '4',
  Floor: '5',
  Terms: '6',
  Meal: '7',
  Bath: '8',
  Equipment: '9',
  CheckinOut: '10',
  WifiTop: '11',
}

export type facilityStatusInfoType = {
  pageIndex: string
  viewStatus: number
}
