import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

type facilityInfoListProps = {
  statusInfo: any
  title: string
  name: string
  onChange: (data: any) => void
  directPaymentTitle?: string
}

export const FacilityPaymentList: React.FC<facilityInfoListProps> = ({ title, name, statusInfo, onChange, directPaymentTitle }) => {
  const { t } = useTranslation()
  const [isEnabled, setIsEnabled] = useState<boolean>(false)
  const [isSettingCreditCardPayment, setIsSettingCreditCardPayment] = useState<boolean>(false)
  const [isSettingDirectPayment, setIsSettingDirectPayment] = useState<boolean>(false)

  useEffect(() => {
    setIsEnabled(!!statusInfo.is_enabled)
    setIsSettingCreditCardPayment(!!statusInfo.payment_method.find(item => item.value == 'CREDIT_CARD' && item.is_enabled))
    setIsSettingDirectPayment(!!statusInfo.payment_method.find(item => item.value == 'DIRECT' && item.is_enabled))
  }, [statusInfo])

  const handleOnOffSetting = status => {
    onChange({ ...statusInfo, is_enabled: status ? 1 : 0 })
  }

  const handleSettingPaymentMethod = (key, status) => {
    const newPaymentMethod = statusInfo.payment_method.map(item => {
      if (item.value == key) {
        return { ...item, is_enabled: status ? 1 : 0 }
      }

      return item
    })
    onChange({ ...statusInfo, payment_method: newPaymentMethod })
  }

  return (
    <div>
      <div css={facilityRecommendStyle}>
        <div css={facilityContainerStyle}>
          <div css={facilityNameStyle}>{title}</div>
          <div css={facilityRightContainerStyle}>
            <input
              name={statusInfo.value}
              type="checkbox"
              id={statusInfo.value}
              css={facilityCheckboxStyle}
              checked={isEnabled}
              onChange={() => {
                const newStatus = !isEnabled
                setIsEnabled(newStatus)
                handleOnOffSetting(newStatus)
              }}
            />
            <label htmlFor={statusInfo.value} css={facilityCheckboxLabelStyle} />
          </div>
        </div>
      </div>
      {isEnabled ? (
        <div css={paymentSettingStyle}>
          <div css={paymentSettingMainStyle}>
            <div css={titleStyle}>{t('Payment settings')}</div>
            <div css={optionWrapperStyle}>
              <input
                id={`${name}_payment_direct`}
                type="image"
                alt="checkbox"
                css={inputCheckboxStyle}
                onClick={() => {
                  const newStatus = !isSettingDirectPayment
                  setIsSettingDirectPayment(newStatus)
                  handleSettingPaymentMethod('DIRECT', newStatus)
                }}
                src={require(`@/static/images/check-box${isSettingDirectPayment ? '' : '_off'}.svg`)}
              />
              <label htmlFor={`${name}_payment_direct`}>
                <span css={labelStyle}>{directPaymentTitle ? directPaymentTitle : t('Front payment')}</span>
              </label>
            </div>
            <div css={optionWrapperStyle}>
              <input
                id={`${name}_payment_credit_card`}
                type="image"
                alt="checkbox"
                css={inputCheckboxStyle}
                onClick={() => {
                  const newStatus = !isSettingCreditCardPayment
                  setIsSettingCreditCardPayment(!isSettingCreditCardPayment)
                  handleSettingPaymentMethod('CREDIT_CARD', newStatus)
                }}
                src={require(`@/static/images/check-box${isSettingCreditCardPayment ? '' : '_off'}.svg`)}
              />

              <label htmlFor={`${name}_payment_credit_card`}>
                <span css={labelStyle}>{t('Credit card payment (IPAS payment)')}</span>
              </label>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

const facilityRecommendStyle = css({
  width: '100%',
  height: 64,
})

const facilityContainerStyle = css({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 64,
  borderBottom: '1px solid #F2F2F2',
  padding: '0 32px',
})

const facilityNameStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  width: '100%',
  padding: '25px 0px',
  height: '100%',
})

const facilityRightContainerStyle = css({
  display: 'flex',
  img: {
    display: 'block',
    marginLeft: 27,
    padding: 4,
    ':hover': {
      borderRadius: '50%',
      background: '#F2F2F2',
      cursor: 'pointer',
    },
  },
})

export const facilityCheckboxStyle = css({
  display: 'none',
  ':checked': {
    '+label': {
      backgroundColor: '#f2a40b',
      border: 'none',
      position: 'relative',

      '&::before': {
        content: "'ON'",
        color: '#fff',
        fontSize: 12,
        letterSpacing: 1.2,
        left: 15,
        position: 'absolute',
      },
      '&::after': {
        top: 3,
        left: 47,
        backgroundColor: '#fff',
      },
    },
  },
})

export const facilityCheckboxLabelStyle = css({
  width: 73,
  height: 28,
  background: '#F2F2F2',
  position: 'relative',
  display: 'inline-block',
  borderRadius: 14,
  transition: '0.4s',
  boxSizing: 'border-box',
  border: '1px solid #ccc',
  cursor: 'pointer',

  '&::before': {
    content: "'OFF'",
    fontWeight: 'bold',
    color: '#a3a3a3',
    fontSize: 11,
    letterSpacing: 0.55,
    left: 30,
    position: 'absolute',
    top: 7,
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    width: 22,
    height: 22,
    borderRadius: '100%',
    top: 2,
    left: 4,
    zIndex: 2,
    background: '#fff',
    transition: '0.4s',
    boxShadow: '0px 2px 3px #00000029',
  },
})

const paymentSettingStyle = css({
  width: '100%',
  height: 135,
  background: '#F5F5F5 0% 0% no-repeat padding-box',
  padding: '24px 61px',
})

const paymentSettingMainStyle = css({})
const inputCheckboxStyle = {
  width: 20,
  height: 20,
}
const titleStyle = css({
  textAlign: 'left',
  letterSpacing: '1.4px',
  color: '#676767',
  fontWeight: 'bold',
  paddingBottom: 16,
  fontSize: 14,
})

const optionWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingBottom: 16,
  img: {
    width: 20,
    height: 20,
  },
})

const labelStyle = css({
  fontSize: 14,
  textAlign: 'left',
  color: '#272727',
  letterSpacing: '1.4px',
  marginLeft: '19px',
})
