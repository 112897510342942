import { TBody, THead, Table } from '@/components/molecules/settings/mobile-request/table'
import { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'
import { CreateSaleHourModal } from './create-sales-hour-modal'
import { SalesHourType } from '@/models/mobile-request'
import { useTranslation } from 'react-i18next'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { deleteMobileRequestSalesHours, fetchMobileRequestSalesHours } from '@/apis/aipass'
import { LoadingFull } from '@/components/molecules/loading-full'

type Props = {
  onChange: () => void
  listenCancel: Dayjs | undefined
  listenSave: Dayjs | undefined
  listenOpenModal: Dayjs | undefined
}
export const SalesHoursTable: React.FC<Props> = ({ onChange, listenCancel, listenSave, listenOpenModal }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { errorHandler } = useErrorHandler()
  const [salesHours, setSalesHours] = useState<SalesHourType[]>([])
  const [removeSalesHourIds, setRemoveSalesHourIds] = useState<number[]>([])
  const [isShowModal, setIsShowModal] = useState(false)
  const [editingDetail, setEditingDetail] = useState<SalesHourType>()
  const [nextOrder, setNextOrder] = useState<number>(1)

  const fetchCategories = async () => {
    const salesHours = (await fetchMobileRequestSalesHours()) || []
    setSalesHours(salesHours)
    setNextOrder(salesHours.length ? salesHours[salesHours.length - 1].order + 1 : 1)
  }

  const showDetail = (salesHour: SalesHourType) => {
    setEditingDetail(salesHour)
    setIsShowModal(true)
  }

  const removeList = (id: number) => {
    setRemoveSalesHourIds([...removeSalesHourIds, id])
    setSalesHours(salesHours.filter(s => s.id !== id))
    onChange()
  }

  const onDelete = async () => {
    try {
      setIsLoading(true)
      await deleteMobileRequestSalesHours(removeSalesHourIds)
      setRemoveSalesHourIds([])
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onModalSave = () => {
    fetchCategories()
  }
  const onModalClose = () => {
    setEditingDetail(undefined)
    setIsShowModal(false)
  }

  useEffect(() => {
    if (listenCancel) fetchCategories()
  }, [listenCancel])

  useEffect(() => {
    if (listenOpenModal) setIsShowModal(true)
  }, [listenOpenModal])

  useEffect(() => {
    if (listenSave) onDelete()
  }, [listenSave])

  useEffect(() => {
    fetchCategories()
  }, [])

  return (
    <>
      <Table>
        <THead>
          <tr>
            <th style={{ width: 83 }}>{t('Display order')}</th>
            <th style={{ width: 273 }}>{t('Title')}</th>
            <th>{t('MobileRequest.Sales hours')}</th>
            <th style={{ width: 72 }}></th>
          </tr>
        </THead>
        <TBody>
          {salesHours.map(item => (
            <tr key={item.id} onClick={() => showDetail(item)}>
              <td>{item.order}</td>
              <td>{item.name}</td>
              <td>{item.hours.map(hour => `${hour.from}-${hour.to}`).join(',')}</td>
              <td>
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={e => {
                    e.stopPropagation()
                    removeList(item.id)
                  }}
                  src={require('@/static/images/mobile_request/delete_orange.svg')}
                  alt=""
                />
              </td>
            </tr>
          ))}
        </TBody>
      </Table>
      {isShowModal && (
        <CreateSaleHourModal
          editing={editingDetail}
          nextOrder={nextOrder}
          onSave={onModalSave}
          onClose={onModalClose}
          setLoading={setIsLoading}
        />
      )}
      <LoadingFull isLoading={isLoading} />
    </>
  )
}
