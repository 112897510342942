import React from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

// models
import { DiscountSettingType } from '@/models/sales'
import { AccountsReceivable } from '@/models/accounts-receivable'

type Props = {
  discountSettings: DiscountSettingType[]
}

export const SettingDiscountList: React.FC<Props> = ({ discountSettings }) => {
  const windows = useWindowSize()
  const { t } = useTranslation()
  return (
    <>
      <div css={paymentListHeaderStyle}>
        <div className="subjectCode" css={subjectCodeWidthStyle}>
          {t('Discount item ID')}
        </div>
        <div className="name" css={nameWidthStyle}>
          {t('Discount item')}
        </div>
        <div className="price" css={priceWidthStyle}>
          {t('Amount of money')}
        </div>
        <div className="isAccountsReceivable" css={isAccountsReceivableWidthStyle}>
          {t('Outstanding sales')}
        </div>
        <div className="accountsReceivableName" css={accountsReceivableNameWidthStyle}>
          {t('Accounts receivable')}
        </div>
        <div className="taxWidthStyle" css={taxWidthStyle}>
          {t('Tax rate')}
        </div>
        <div className="taxClassWidthStyle" css={taxClassWidthStyle}>
          {t('Tax classification')}
        </div>
      </div>
      <div
        css={scrollStyle}
        style={{
          height: windows.height - 270,
        }}
      >
        <ul
          css={paymentListStyle}
          style={{
            height: windows.height - 270,
          }}
        >
          {discountSettings &&
            discountSettings.map((discountSetting, index) => (
              <li key={index}>
                <div className="subjectCode" css={subjectCodeWidthStyle}>
                  {discountSetting.discountSubjectCode ? discountSetting.discountSubjectCode : '-'}
                </div>
                <div className="name" css={nameWidthStyle}>
                  {discountSetting.name}
                </div>
                <div className="price" css={priceWidthStyle}>
                  {discountSetting.name === 'ポイント' ? '-' : `¥${Number(discountSetting.price).toLocaleString()}`}
                </div>
                <div className="isAccountsReceivable" css={isAccountsReceivableWidthStyle}>
                  {discountSetting.isAccountsReceivable === AccountsReceivable.AccountsReceivable ? t('Outstanding sales') : '-'}
                </div>
                <div className="accountsReceivableName" css={accountsReceivableNameWidthStyle}>
                  {discountSetting.accountsReceivableName ? discountSetting.accountsReceivableName : '-'}
                </div>
                <div className="taxWidthStyle" css={taxWidthStyle}>
                  {discountSetting.tax ? discountSetting.tax : '-'}%
                </div>
                <div className="taxClassWidthStyle" css={taxClassWidthStyle}>
                  {discountSetting.taxClass ? discountSetting.taxClass : '-'}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}

export const subjectCodeWidthStyle = css({
  width: '15%',
})

const nameWidthStyle = css({
  width: '35%',
})

const priceWidthStyle = css({
  width: '10%',
})

const isAccountsReceivableWidthStyle = css({
  width: '10%',
})

const accountsReceivableNameWidthStyle = css({
  width: '10%',
})

const taxWidthStyle = css({
  width: '10%',
})

const taxClassWidthStyle = css({
  width: '10%',
})

const paymentListHeaderStyle = css({
  background: '#F5F5F5',
  color: '#676767',
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  padding: '10px 32px',
  '.subjectCode': subjectCodeWidthStyle,
  '.name': nameWidthStyle,
  '.price': priceWidthStyle,
  '.isAccountsReceivable': isAccountsReceivableWidthStyle,
  '.accountsReceivableName': accountsReceivableNameWidthStyle,
  '.taxWidthStyle': taxWidthStyle,
  '.taxClassWidthStyle': taxClassWidthStyle,
})

const paymentListStyle = css({
  li: {
    display: 'flex',
    padding: '16px 32px',
    color: '#272727',
    fontSize: 14,
    borderBottom: '1px #F2F2F2 solid',
    '.subjectCode': subjectCodeWidthStyle,
    '.name': nameWidthStyle,
    '.price': priceWidthStyle,
    '.isAccountsReceivable': isAccountsReceivableWidthStyle,
    '.accountsReceivableName': accountsReceivableNameWidthStyle,
  },
})

const scrollStyle = css({
  overflowY: 'scroll',
})
