import React, { useState } from 'react'
import { css } from '@emotion/core'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'

// components
import { Button } from '@/components/atoms/button'
import { CSVReader } from '@/components/organisms/csv-reader'

// constants
import { mainContainerHeaderBarStyle, headerBarLeftWrapperStyle } from '@/constants/layout'

type ContainerProps = {
  csvData: any
  parseOptions: any
  onFileLoad: (data) => void
}

type Props = {}

export const CsvButton: React.FC<ContainerProps & Props> = ({ csvData, parseOptions, onFileLoad }) => {
  const { t } = useTranslation()
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)
  return (
    <div css={customMainContainerHeaderBarStyle}>
      <div css={headerBarLeftWrapperStyle}>
        <Button
          buttonType={4}
          width={100}
          height={32}
          fontSize={12}
          marginRight={12}
          icon={require('@/static/images/csv.svg')}
          onClick={() => {
            setIsDropDownOpen(!isDropDownOpen)
          }}
        >
          {t('CSV')}
          <img src={require('@/static/images/arrow_gray.svg')} css={csvButtonRightImageStyle}></img>
        </Button>
        {isDropDownOpen && (
          <div css={dropDownStyle}>
            <ul>
              <li css={dropDownContentStyle}>
                <p css={dropDownTitleStyle}>
                  <CSVReader
                    inputStyle={{ display: 'none', cursor: 'pointer' }}
                    label={t('CSV upload')}
                    parserOptions={parseOptions}
                    onFileLoaded={data => {
                      onFileLoad(data)
                    }}
                  />
                </p>
              </li>
              <li css={dropDownContentStyle}>
                <CSVLink filename={'around-map.csv'} data={csvData}>
                  <p css={dropDownTitleStyle}>{t('CSV download')}</p>
                </CSVLink>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

const customMainContainerHeaderBarStyle = css(mainContainerHeaderBarStyle, {
  marginTop: 0,
  marginBottom: 0,
  padding: '16px 16px',
  height: 64,
  width: '100%',
  background: '#FFF',
  justifyContent: 'right',
})

const csvButtonRightImageStyle = css({
  marginLeft: '7px',
})

const dropDownStyle = css({
  width: 160,
  zIndex: 100,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 185,
  right: 50,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownContentStyle = css({
  display: 'flex',
  height: 32,
  listStyle: 'none',
  alignItems: 'center',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  paddingLeft: 16,
  fontSize: 14,
  fontWeight: 'normal',
  color: '#272727',
})
