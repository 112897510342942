import React, { useContext, Dispatch, SetStateAction } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// components
import { Modal, ModalHeader, ModalBody } from '@/components/atoms/modal'

// models
import { TMiwaAppWebsocketContext, TMiwaState } from '@/models/miwa-lock'
import { MiwaAppWebsocketContext } from '@/contexts/miwa-app-websocket'

type TKeyCardReleaseResultModalProps = {
  miwaState: TMiwaState
  setMiwaState: Dispatch<SetStateAction<TMiwaState>>
  numberOfCards: number
  numberOfCardsReleaseSuccess: number
  onClickModalCancel: () => void
}

export const KeyCardReleaseResultModal: React.FC<TKeyCardReleaseResultModalProps> = ({
  numberOfCards,
  numberOfCardsReleaseSuccess,
  onClickModalCancel,
}) => {
  const { t } = useTranslation()
  const { miwaAppWebsocketErrorMessage } = useContext<TMiwaAppWebsocketContext>(MiwaAppWebsocketContext)

  return (
    <Modal customCss={{ width: 680, height: 'unset', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <ModalHeader customCss={{ position: 'relative', boxShadow: 'none' }}>
        <div css={keyCardReleaseResultHeaderModal}>
          <div css={keyCardReleaseResultTitleModal}>{t('Keep card on NFC')}</div>
          <img src={require('@/static/images/miwa-lock/miwa_deleat_icon.svg')} css={iconCloseStyle} onClick={onClickModalCancel} />
        </div>
      </ModalHeader>
      <ModalBody customCss={{ backgroundColor: '#fff', paddingTop: '7px', paddingBottom: miwaAppWebsocketErrorMessage ? '32px' : '43px' }}>
        <div css={keyCardReleaseResultImageWrapper}>
          <img
            src={require('@/static/images/miwa-lock/miwa_image.svg')}
            css={keyCardReleaseResultImage}
            alt={t('Keep card on NFC')}
          />
        </div>
        <div css={keyCardReleaseResultNumberModal}>{`${numberOfCardsReleaseSuccess}/${numberOfCards}`}</div>
        {miwaAppWebsocketErrorMessage && <p css={errorMessage} dangerouslySetInnerHTML={{ __html: miwaAppWebsocketErrorMessage }} />}
      </ModalBody>
    </Modal>
  )
}

const keyCardReleaseResultHeaderModal = css({
  width: '100%',
})

const iconCloseStyle = css({
  position: 'absolute',
  top: '24px',
  right: '25px',
  cursor: 'pointer',
})

const keyCardReleaseResultTitleModal = css({
  color: '#272727',
  fontSize: '23px',
  fontWeight: 'bold',
  letterSpacing: '1.15px',
  lineHeight: '21px',
  textAlign: 'center',
  marginTop: '29px',
})

const keyCardReleaseResultImageWrapper = css({
  marginBottom: '29px',
})

const keyCardReleaseResultImage = css({
  display: 'block',
  margin: '0 auto',
})

const keyCardReleaseResultNumberModal = css({
  color: '#272727',
  fontSize: 32,
  fontWeight: 'bold',
  letterSpacing: '1.6px',
  lineHeight: '21px',
  textAlign: 'center',
})

const errorMessage = css({
  textAlign: 'center',
  color: '#EB0000',
  fontSize: '16px',
  fontWeight: 'bold',
  lineHeight: '21px',
  letterSpacing: '0.8px',
  marginTop: '24px',
})
