import { useState, Dispatch, SetStateAction } from 'react'

// apis
import { fetchGuestRoomForFee } from '@/apis/aipass'

// models
import { GuestRoomAssignResponseType } from '@/models/guest-room/guest-room-assign'

type UseEditGuestRoomsType = [
  GuestRoomAssignResponseType['rooms'],
  Dispatch<SetStateAction<GuestRoomAssignResponseType['rooms']>>,
  (params: { reservationId: string; checkinId?: string; hotelId?: string }) => Promise<GuestRoomAssignResponseType['rooms']>
]

// Room list to edit
export function useEditGuestRooms(): UseEditGuestRoomsType {
  const [editGuestRooms, setEditGuestRooms] = useState<GuestRoomAssignResponseType['rooms']>([])

  const handleFetchGuestRooms = async ({
    reservationId,
    checkinId,
    hotelId,
  }: {
    reservationId: string
    checkinId?: string
    hotelId?: string
  }) => {
    return await fetchGuestRoomForFee({ reservationId, checkinId, hotelId }).then(
      (guestRooms: GuestRoomAssignResponseType['rooms']) => {
        if (!guestRooms) {
          return []
        }

        setEditGuestRooms(guestRooms)
        return guestRooms
      }
    )
  }

  return [editGuestRooms, setEditGuestRooms, handleFetchGuestRooms]
}
