export type MarketSegmentType = {
  id: string | null
  code: string | null
  name: string | null
  groupType: 0 | 1 | '-' | null
}

export const GroupType = {
  Individual: 0,
  Group: 1,
}
