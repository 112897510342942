export const AccountRoleType = {
  STAFF: '1',
  CLEANER: '2',
  MANAGER: '3',
} as const
export type AccountRoleType = typeof AccountRoleType[keyof typeof AccountRoleType]

type AccountType = { employee: { role: AccountRoleType } }

export const hasCleanerRole = (account: AccountType | undefined): boolean => {
  return hasRole([AccountRoleType.CLEANER], account?.employee.role)
}

export const hasStaffRole = (account: AccountType | undefined): boolean => {
  return hasRole([AccountRoleType.STAFF, AccountRoleType.MANAGER], account?.employee.role)
}

export const hasManagerRole = (account: AccountType | undefined): boolean => {
  return hasRole([AccountRoleType.MANAGER], account?.employee.role)
}

const hasRole = (roleGroup: AccountRoleType[], accountRole: AccountRoleType | undefined): boolean => {
  if (!accountRole) {
    return false
  }
  return roleGroup.includes(accountRole)
}
