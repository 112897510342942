import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import { HistoryAdvanceMoneyType } from '@/models/sales-manager/advance-money'

import { numberWithCommas } from '@/libs/formater'

type AdvanceMoneyHistoryListItemProps = {
  historyData: HistoryAdvanceMoneyType[]
}

export const AdvanceMoneyHistoryListItem = ({ historyData }: AdvanceMoneyHistoryListItemProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const goToAccommodationDetail = advanceMoneyHistory => {
    if (advanceMoneyHistory.checkin_id) {
      history.push({
        pathname: `/checkin/${advanceMoneyHistory.checkin_id}`,
        search: `?reservationId=${advanceMoneyHistory.reservation_id}`,
      })
    } else if (advanceMoneyHistory.reservation_index_id) {
      history.push({
        pathname: `/reservation/${advanceMoneyHistory.reservation_index_id}`,
        search: `?reservationId=${advanceMoneyHistory.reservation_id}`,
      })
    }
  }

  return (
    <div>
      <ul css={historyWrapper}>
        {historyData.map((item, index) => (
          <li key={index} css={historyItem}>
            <div css={historyInfo} className="checkinDate">
              {dayjs(item.checkin_date).format(t('MM-DD'))}
            </div>
            <div css={historyInfo} className="paymentDatetime">
              {item.payment_date_unixtime === null ? '-' : dayjs.unix(item.payment_date_unixtime).format(t('MM-DD-YYYY'))}
            </div>
            <div css={historyInfo} className="reservationPerson" onClick={() => goToAccommodationDetail(item)}>
              <span>{item.reservation_guest_name}</span>
            </div>
            <div css={historyInfo} className="initialAmount">
              <span>¥{numberWithCommas(item.payment_amount)}</span>
            </div>
            <div css={historyInfo} className="balance">
              <span>¥{numberWithCommas(item.balance)}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

const historyInfo = css({
  letterSpacing: '0.48px',
  lineHeight: '19px',
  fontWeight: 'normal',
  marginRight: 26,
  color: '#272727',
})

const historyItem = css({
  display: 'flex',
  height: '35px',
  color: '#272727',
  fontSize: '12px',
  alignItems: 'center',
  padding: '12px 22px',
  borderBottom: '1px solid #F2F2F2',
  '.checkinDate': {
    width: '16.1%',
  },
  '.paymentDatetime': {
    width: '20.87%',
  },
  '.reservationPerson': {
    width: '23.19%',
    color: '#F2A40B',
    span: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      whiteSpace: 'pre',
    },
    cursor: 'pointer',
  },
  '.initialAmount': {
    width: '11.42%',
    span: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
  },
  '.balance': {
    width: '10.5%',
    span: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
  },
})

const historyWrapper = css({
  overflow: 'auto',
  height: '281px',
})
