import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

type SalesProps = {
  salesTotalDetail: any
  mode: 'daily' | 'month'
}

export const Sales: React.FC<SalesProps> = ({ salesTotalDetail, mode }) => {
  const { t } = useTranslation()

  return (
    <div css={salesMainContainerStyle}>
      <div css={containerTitleStyle}>
        {t('Actual sales')}
        <p>
          {mode === 'daily'
            ? `${t('Business day')}：${dayjs(salesTotalDetail?.salesDate).format(t('YYYY-M-D'))}`
            : `${t('Business month')}：${dayjs(salesTotalDetail?.salesMonth).format(t('YYYY-M'))}`}
        </p>
      </div>
      <div css={containerItemStyle}>
        <div css={itemLeftStyle}>{t('Subject')}</div>
        <div css={itemCenterStyle}>{t('Quantity')}</div>
        <div css={itemMoneyStyle}>{t('Amount of money')}</div>
      </div>
      <ul>
        {salesTotalDetail?.salesDetail &&
          salesTotalDetail?.salesDetail.map((detail, index) => (
            <li css={contentsWrapperStyle} key={index}>
              <p css={itemLeftContentStyle}>{detail.name}</p>
              <p css={itemCenterContentStyle}>{detail.quantity}</p>
              <p css={itemMoneyContentStyle}>¥{detail.price ? Number(detail.price).toLocaleString() : 0}</p>
            </li>
          ))}
        <li css={separateStyle} />
      </ul>
      <div css={totalWrapperStyle}>
        <p css={totalContentsTitleStyle}>{t('Total')}</p>
        <p css={totalContentsMoneyStyle}>¥{salesTotalDetail?.totalSales ? Number(salesTotalDetail?.totalSales).toLocaleString() : 0}</p>
      </div>
      <div css={taxWrapperStyle}>
        <p css={taxTextStyle}>{t('Consumption tax')}</p>
        <p css={taxTextStyle}>¥{salesTotalDetail?.totalTax ? Number(salesTotalDetail?.totalTax).toLocaleString() : 0}</p>
      </div>
    </div>
  )
}

export const salesMainContainerStyle = css({
  width: '50%',
  background: '#FFFFFF',
  boxShadow: '0px 0px 6px #0000001A',
  border: '1px solid #F2F2F2',
  borderRadius: 5,
  marginRight: 16,
  marginBottom: 16,
})

export const containerTitleStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: 50,
  fontSize: 18,
  fontWeight: 'bold',
  padding: '0 32px',
  justifyContent: 'space-between',
  p: {
    color: '#F2A40B',
    fontSize: 14,
    letterSpacing: 0.7,
  },
})

export const containerItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#F5F5F5',
  height: 32,
  paddingLeft: 32,
  paddingRight: 32,
  width: '100%',
  margin: '0 0 15px 0px',
})

export const itemStyle = css({
  fontSize: 12,
  letterSpacing: 1.2,
  color: '#676767',
  fontWeight: 'bold',
})

export const itemMoneyStyle = css(itemStyle, {
  textAlign: 'right',
  minWidth: '20%',
})

export const itemContentStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'normal',
})

const itemLeftStyle = css(itemStyle, {
  flex: 3,
})

const itemCenterStyle = css(itemStyle, {
  flex: 1,
})

const ellipsisStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

// inside
const itemLeftContentStyle = css(itemLeftStyle, itemContentStyle, ellipsisStyle)

const itemCenterContentStyle = css(itemCenterStyle, itemContentStyle, ellipsisStyle)

const itemMoneyContentStyle = css(itemMoneyStyle, itemContentStyle, ellipsisStyle)

export const contentsWrapperStyle = css(containerItemStyle, {
  backgroundColor: '#fff',
  marginBottom: 0,
  fontSize: 14,
})

export const separateStyle = css({
  borderBottom: '1px solid #F2F2F2',
  margin: '15px 32px 0',
})

export const contentsMoneyStyle = css({
  paddingLeft: 5,
  textAlign: 'right',
  width: '20%',
})

export const totalWrapperStyle = css({
  display: 'flex',
  fontWeight: 'bold',
  margin: '23px 32px 16px auto',
  justifyContent: 'space-between',
  width: '50%',
  maxWidth: 225,
})

export const totalContentsTitleStyle = css({
  fontSize: 16,
  letterSpacing: 2.4,
})

export const totalContentsMoneyStyle = css({
  fontSize: 18,
  letterSpacing: 2.7,
})

const taxWrapperStyle = css(totalWrapperStyle, {
  fontWeight: 'normal',
  color: '#676767',
  margin: '0 32px 24px auto',
})

const taxTextStyle = css({
  fontSize: 14,
  letterSpacing: 2.1,
})
