import React, { useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'

// components
import { SelfCheckinHeader } from '@/components/molecules/self-checkin/header'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { CheckinPaymentDetail } from '@/components/pages/self-checkin/payment/checkin-payment-detail'

// apis
import { fetchCheckinReservationsAndSales } from '@/apis/aipass'
import { CASH, paymentMethodOptions as defaultPaymentMethodOptions } from '@/models/self-checkin/payment-method'
import { CheckinPaymentMethod } from '../payment/payment-method-select'
import { useForm } from 'react-hook-form'

export type CardInfo = {
  cardNumber: string
  expiredMonth: string
  expiredYear: string
  holderName: string
  cvc: string
}

export const SelfCheckinQrCameraPayment: React.FC = () => {
  const { search, state } = useLocation<{
    hotelId: string
    paymentInfo: any
    from: any
    edit?: any
    qrReservationIds: any
    requiredIdentify: any
    paymentSetting: any
  }>()
  const history = useHistory()
  const [searchReservation, setSearchReservation] = useState<any>([])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(state?.paymentSetting?.length ? state.paymentSetting[0] : CASH)
  const [paymentMethodOptions] = useState(defaultPaymentMethodOptions)
  const { t, i18n } = useTranslation()
  const [totalTax, setTotalTax] = useState<Number>(0)
  const cardForm = useForm<CardInfo>({
    mode: 'onBlur',
    defaultValues: {
      cardNumber: '',
      expiredMonth: '',
      expiredYear: '',
      holderName: '',
      cvc: '',
    },
  })
  const [isLoading, setIsLoading] = useState<any>(true)

  useEffect(() => {
    if (state.qrReservationIds) {
      fetchCheckinReservationsAndSales(state.hotelId, state.qrReservationIds).then(res => {
        if (!res) return console.warn('fetchCheckinReservationsAndSales returned empty response')
        setSearchReservation(res)
        _totalTax(res?.reservations || [])
        setIsLoading(false)
      })
    }
  }, [state.qrReservationIds, state.hotelId])

  useEffect(() => {
    if (state.paymentInfo) {
      setSelectedPaymentMethod(state.paymentInfo.paymentMethod)
      cardForm.reset(state.paymentInfo.cardInfo)
    }
  }, [state.paymentInfo?.paymentMethod, state.paymentInfo?.cardInfo])

  const validateCardInfo = useMemo(() => {
    if (selectedPaymentMethod === CASH) {
      return true
    }
    return cardForm.formState.isValid
  }, [selectedPaymentMethod, cardForm.formState.isValid])

  const getNextPagePath = (): string => {
    if (state?.requiredIdentify) {
      return i18n.language !== 'ja' ? `/self-checkin/qr-checkin-camera/upload-passport` : '/self-checkin/qr-checkin-camera/upload-license'
    }
    return '/self-checkin/qr-checkin-camera/checkedin-confirm'
  }

  const goBack = () => {
    history.push({
      pathname: '/self-checkin/qr-checkin-camera',
      search,
      state: {
        ...state,
        paymentInfo: {
          paymentMethod: selectedPaymentMethod,
          cardInfo: cardForm.getValues(),
        },
      },
    })
  }

  const goToNextPage = () => {
    if (!validateCardInfo) {
      return
    }

    history.push({
      pathname: getNextPagePath(),
      search,
      state: {
        ...state,
        paymentInfo: {
          paymentMethod: selectedPaymentMethod,
          cardInfo: cardForm.getValues(),
          totalAmount: searchReservation?.totalAmount,
        },
      },
    })
  }

  const _totalTax = reservations => {
    const taxList: any = []
    reservations.forEach(reservation => {
      const tax = reservation.sales.reduce(
        (p, s) =>
          Math.floor(
            p + ((Number(s.salesPrice) - Number(s.salesPaymentPrice)) * Number(s.salesSubjectTax)) / (100 + Number(s.salesSubjectTax)),
          ),
        0,
      )
      taxList.push(tax)
    })
    setTotalTax(
      taxList.reduce(function (sum, element) {
        return sum + element
      }, 0),
    )
  }

  return (
    <>
      <SelfCheckinHeader goToPreviousPage={goBack} title={t('Payment details')} />
      <div css={containerStyle}>
        <div css={wrapper}>
          {searchReservation?.reservations?.map(
            (reservation, index) =>
              !!reservation.sales.length && (
                <div css={wrapperStyle} key={index}>
                  <CheckinPaymentDetail reservation={reservation} index={index} />
                </div>
              ),
          )}
        </div>
        <div css={wrapper}>
          <div css={commonContainerStyle}>
            <div css={amountStyle}>
              <p css={amountItem}>{t('Amount billed')}</p>
              <p css={amountValue}>¥{searchReservation?.totalAmount}</p>
            </div>
            <p css={taxStyle}>
              ({t('Tax')}：¥{totalTax})
            </p>
          </div>
        </div>

        {!!state.paymentSetting.length && (
          <div css={wrapper}>
            <div css={paymentStyle}>
              <CheckinPaymentMethod
                paymentMethodOptions={paymentMethodOptions}
                onChangePaymentMethod={e => {
                  cardForm.reset({
                    cardNumber: '',
                    expiredMonth: '',
                    expiredYear: '',
                    holderName: '',
                    cvc: '',
                  })
                  setSelectedPaymentMethod(e.target.value)
                }}
                paymentSetting={state.paymentSetting}
                selectedPaymentMethod={selectedPaymentMethod}
                cardForm={cardForm}
              />
            </div>
          </div>
        )}
      </div>
      <SelfCheckinFooter isDisabled={() => isLoading || !validateCardInfo} goToNextPage={goToNextPage} isNext={'next'} />
    </>
  )
}

const containerStyle = css({
  backgroundColor: '#f2f2f2',
  paddingTop: '86px',
  paddingBottom: '106px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  justifyContent: 'flex-start',
  '*': {
    fontFamily: 'Noto Sans JP',
    color: '#272727',
  },
})

const wrapperStyle = css({
  background: 'transparent',
  borderRadius: '10px',
  margin: '27px auto 0',
  maxWidth: 872,
})

const paymentStyle = css({
  maxWidth: 872,
  margin: '0 auto',
})

const commonContainerStyle = css({
  backgroundColor: '#fff',
  textAlign: 'left',
  padding: '30px 32px 30px 32px',
  borderRadius: '10px',
  margin: '16px auto 0',
  maxWidth: 872,
})

const amountStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const amountItem = css({
  fontSize: '16px',
  color: '#d68e0f',
  fontWeight: 'bold',
  letterSpacing: '2.7px',
})
const amountValue = css(amountItem, {
  fontSize: '18px',
})

const taxStyle = css({
  textAlign: 'right',
  width: '100%',
  color: '#676767',
  fontSize: '14px',
  letterSpacing: '2.1px',
  marginTop: '2px',
  lineHeight: '19px',
})
const wrapper = css({
  padding: '0 75px 0 74px',
})
