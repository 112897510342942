import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { InputField } from '@/components/molecules/input-field'
import { Button, ButtonType } from '@/components/atoms/button'
import {
  modalContainerStyle,
  modalWrapperStyle,
  modalStyle,
  modalTitleStyle,
  modalSectionStyle,
  modalItemWrapperStyle,
  modalFooterStyle,
} from '@/components/pages/setting/guest-room/room/_room/modal/modal-layout'
import { GuestRoomFloorType } from '@/models/guest-room-floor'
import { useForm, Controller } from 'react-hook-form'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { LoaderContextCreator } from '@/contexts/loader'
import { insertAdminGuestRoomFloor, putAdminGuestRoomFloor } from '@/apis/aipass'

type Props = {
  detail?: GuestRoomFloorType
  nextDisplayOrder: string
  onSave: () => void
  onClose: () => void
}

type FormType = {
  displayOrder: string | undefined
  roomFloorName: string
}

export const FloorModal: React.FC<Props> = ({ detail, nextDisplayOrder, onSave, onClose }) => {
  const { t } = useTranslation()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const { errorHandler } = useErrorHandler()

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormType>({
    defaultValues: {
      displayOrder: detail?.displayOrder || nextDisplayOrder,
      roomFloorName: detail?.floorNameJa || '',
    },
  })

  const doSave = async (value: FormType) => {
    try {
      setIsLoading(true)
      if (detail) {
        await putAdminGuestRoomFloor(detail.roomFloorId, value)
      } else {
        await insertAdminGuestRoomFloor(value)
      }
      onSave()
      onClose()
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div css={modalContainerStyle}>
      <div css={modalWrapperStyle}>
        <div css={modalStyle}>
          <div css={modalTitleStyle}>{detail ? t('Edit floor') : t('Add floor')}</div>
          <div css={modalSectionStyle}>
            <div css={modalItemWrapperStyle} style={{ width: 'auto' }}>
              <Controller
                control={control}
                name="displayOrder"
                rules={{ required: t('Required field has not been entered') }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <InputField
                    handleChangeData={onChange}
                    name={name}
                    value={value}
                    label={t('Display order')}
                    placeholder="00000"
                    error={error?.message}
                    required
                    marginBottom={24}
                  />
                )}
              />

              <Controller
                control={control}
                name="roomFloorName"
                rules={{ required: t('Required field has not been entered') }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <InputField
                    handleChangeData={onChange}
                    name={name}
                    value={value}
                    label={t('Floor')}
                    placeholder={t('Floor')}
                    error={error?.message}
                    required
                    marginBottom={24}
                  />
                )}
              />
            </div>
          </div>
          <div css={modalFooterStyle}>
            <Button
              buttonType={ButtonType.Secondary}
              width={110}
              height={38}
              fontSize={14}
              marginRight={16}
              onClick={() => {
                onClose()
              }}
            >
              {t('Cancel')}
            </Button>
            <Button
              buttonType={isValid ? ButtonType.Primary : ButtonType.PrimaryDisabled}
              width={110}
              height={38}
              fontSize={14}
              marginRight={32}
              onClick={handleSubmit(doSave)}
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
