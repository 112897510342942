import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

type ReviewTabProps = {
  tab: any
  setTab: any
  dailyNps: any
}

export const ReviewTab: React.FC<ReviewTabProps> = ({ tab, setTab, dailyNps }) => {
  const { t, i18n } = useTranslation()
  return (
    <div className="switchStatus_body">
      <div css={switchStatusStyle}>
        <div css={tabStyle}>
          <div css={tab === 'overall' ? ActiveNpsTab : npsTab} onClick={() => setTab('overall')}>
            {t('NPS')}
          </div>
          <div css={tab === 'daily' ? ActiveDailyNpsTab : dailyNpsTab} onClick={() => setTab('daily')}>
            {t('Daily NPS')}
          </div>
        </div>
        <div css={periodStyle}>
          {i18n.language === 'ja' && dailyNps && dailyNps[0]?.date && dailyNps[0]?.date} {i18n.language === 'ja' && '〜'}{' '}
          {i18n.language === 'ja' && dailyNps && dailyNps[6]?.date && dailyNps[6]?.date}
        </div>
      </div>
    </div>
  )
}

// switchStatus_body
const switchStatusStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  background: '#FAFAFA',
  justifyContent: 'space-between',
})

const tabStyle = css({
  display: 'flex',
  justifyContent: 'left',
})

const BaseNpsTab = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 36,
  fontWeight: 'bold',
  color: '#676767',
})

const npsTab = css(BaseNpsTab, {
  cursor: 'pointer',
  width: 59,
})

const ActiveNpsTab = css(BaseNpsTab, {
  background: '#FFF',
  borderTop: '2px solid #EF9A2A',
  color: '#F2A40B',
  width: 59,
  borderBottom: '1px solid #F2F2F2',
})

const dailyNpsTab = css(BaseNpsTab, {
  cursor: 'pointer',
  width: 84,
  height: 36,
})

const ActiveDailyNpsTab = css(BaseNpsTab, {
  background: '#FFF',
  borderTop: '2px solid #EF9A2A',
  width: 84,
  color: '#F2A40B',
  borderBottom: '1px solid #F2F2F2',
})

const periodStyle = css({
  fontSize: 12,
  fontWeight: 'normal',
  letterSpacing: 0.6,
  color: '#272727',
  paddingRight: 31,
})
