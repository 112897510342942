import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { TextareaField } from '@/components/molecules/textarea-field'
import { css } from '@emotion/core'
import { InputField } from '@/components/molecules/input-field'
import { InputImage } from '@/components/molecules/settings/input-image'
import { postMobileRequestTags, putMobileRequestTags } from '@/apis/aipass'
import { TagInputType, TagType } from '@/models/mobile-request'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useForm, Controller } from 'react-hook-form'

type Props = {
  editing?: TagType
  nextOrder: number
  onSave: () => void
  onClose: () => void
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateTagModal = ({ editing, nextOrder, onSave, onClose, setLoading }: Props) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<TagInputType>({
    defaultValues: {
      name: editing?.name.ja || '',
      imagePath: editing?.imagePath || '',
      order: editing?.order || nextOrder,
    },
  })
  const [isShowCropModal, setIsShowCropModal] = useState<boolean>(false)

  const save = async (formValues: TagInputType) => {
    if (isSubmitting) return
    try {
      if (editing) {
        await putMobileRequestTags(editing.id, formValues)
      } else {
        await postMobileRequestTags(formValues)
      }
      onSave()
      onClose()
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal customCss={modalStyle} style={{ visibility: isShowCropModal ? 'hidden' : 'visible' }}>
      <ModalHeader customCss={modalHeaderStyle}>{t('MobileRequest.Create tag')}</ModalHeader>
      <ModalBody customCss={modalBodyStyle}>
        <div css={bodyContentStyle}>
          <div>
            <div css={inputTitleTextStyle}>
              {t('Title')}
              <div css={requireLabelTextStyle}>※</div>
            </div>
            <div css={[textareaStyle, { textarea: { height: 67 } }]}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value, name } }) => (
                  <TextareaField marginBottom={0} placeholder={t('Title name')} value={value} name={name} handleChangeData={onChange} />
                )}
              />
            </div>
          </div>

          <Controller
            name="imagePath"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <InputImage value={value} customStyle={inputAreaStyle} onChange={onChange} onShowModal={setIsShowCropModal} />
            )}
          />

          <div css={inputAreaStyle}>
            <div style={{ paddingRight: 8, width: '50%' }}>
              <div css={inputTitleTextStyle}>{t('Display order')}</div>
              <Controller
                name="order"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <InputField marginBottom={0} type="number" placeholder="00000" value={value} name={name} handleChangeData={onChange} />
                )}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter customCss={modalFooterStyle}>
        <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(save)} isDisabled={!isValid}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = css({
  top: '5%',
  left: 'calc(50% - 300px)',
  minHeight: 565,
  maxHeight: 565,
  minWidth: 600,
  width: 600,
})

const modalHeaderStyle = css({
  height: '60px',
  boxShadow: 'none',
  color: '#272727',
})

const modalBodyStyle = css({
  padding: 32,
  height: 530,
})

const modalFooterStyle = css({
  height: '60px',
  display: 'flex',
})

const bodyContentStyle = css({
  padding: '32px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #CCCCCC',
  borderRadius: '5px',
  width: '100%',
  height: '100%',
})

const textareaStyle = css({
  textarea: {
    marginBottom: 0,
    padding: '16px',
    lineHeight: '16px',
    letterSpacing: '1.4px',
    ':focus': {
      padding: '15px',
    },
  },
})

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputAreaStyle = css({
  paddingTop: 24,
  display: 'flex',
})
