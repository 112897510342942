import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/ja'
import 'react-dates/initialize'

// components
import { Button } from '@/components/atoms/button'
import { DatePicker } from '@/components/organisms/reservation/_detail-edit-info/date-picker'

// models
import { ReservationStatusType } from '@/models/reservation-reservation-status'
import { EntireSearchContentType } from '@/models/entire-search'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { EntireSearchDateType } from '@/models/entire-search'
import { RadioGroupField } from '@/components/molecules/radio-group-field'

type ContainerProps = {
  search: () => void
  clearEntireSearchPanel: () => void
  entireSearchContent: EntireSearchContentType
  setEntireSearchContent: Dispatch<SetStateAction<EntireSearchContentType>>
  entireSearchReservationStatuses: ReservationStatusType[]
  setEntireSearchReservationStatuses: Dispatch<SetStateAction<ReservationStatusType[]>>
}

export const EntireSearchPanel: React.FC<ContainerProps> = ({
  search,
  clearEntireSearchPanel,
  entireSearchContent,
  setEntireSearchContent,
  entireSearchReservationStatuses,
  setEntireSearchReservationStatuses,
}) => {
  const { t } = useTranslation()
  const reservationStatuses = ReservationStatusType
  const approvedStatuses = ApprovedStatusType
  const entireSearchDateType = EntireSearchDateType

  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setEntireSearchContent({ ...entireSearchContent, [name]: value })
  }

  // Date type
  const setDateType = value => {
    setEntireSearchContent({ ...entireSearchContent, globalSearchDateType: value })
  }
  // Date change
  const setBeginDate = date => {
    setEntireSearchContent({ ...entireSearchContent, beginDate: `${date} 00:00:00` })
  }

  const setEndDate = date => {
    setEntireSearchContent({ ...entireSearchContent, endDate: `${date} 23:59:59` })
  }

  // Reservation status change
  const reservationStatusesChange = value => {
    if (entireSearchReservationStatuses.includes(value)) {
      // When unchecking a checkbox
      entireSearchReservationStatuses = entireSearchReservationStatuses.filter(reservationStatus => {
        return reservationStatus !== value
      })

      setEntireSearchReservationStatuses(entireSearchReservationStatuses)
    } else {
      // when checking the box
      setEntireSearchReservationStatuses([...entireSearchReservationStatuses, value])
    }
  }

  // Accommodation management status change
  const approvedStatusesChange = value => {
    let adjustApprovedStatuses = entireSearchContent?.approvedStatuses || []

    if (adjustApprovedStatuses.includes(value)) {
      // when unchecking a checkbox
      adjustApprovedStatuses = adjustApprovedStatuses.filter(reservationStatus => {
        return reservationStatus !== value
      })

      setEntireSearchContent({
        ...entireSearchContent,
        approvedStatuses: adjustApprovedStatuses,
      })
    } else {
      // when checking the box
      adjustApprovedStatuses.push(value)

      setEntireSearchContent({
        ...entireSearchContent,
        approvedStatuses: adjustApprovedStatuses,
      })
    }
  }

  return (
    <div css={dropDownSearchPanelStyle}>
      <div css={topSearchPanelStyle}>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Full name')}</div>
          <input
            css={filedInputStyle}
            placeholder={t('John Doe')}
            name="name"
            value={entireSearchContent?.name}
            onChange={handleContentChange}
          />
        </div>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Furigana')}</div>
          <input
            css={filedInputStyle}
            placeholder="ヤマダ タロウ"
            name="nameKana"
            value={entireSearchContent?.nameKana}
            onChange={handleContentChange}
          />
        </div>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Phone number')}</div>
          <input
            css={filedInputStyle}
            placeholder="09012345678"
            name="telephone"
            value={entireSearchContent?.telephone}
            onChange={handleContentChange}
          />
        </div>
        <div css={fieldBlockStyle}>
          <div css={fieldTitleStyle}>{t('Reservation ID')}</div>
          <input
            css={filedInputStyle}
            placeholder="12345678"
            name="searchReservationId"
            value={entireSearchContent?.searchReservationId}
            onChange={handleContentChange}
          />
        </div>
      </div>
      <div css={middleSearchPanelStyle}>
        <div>
          <div css={fieldBlockStyle}>
            <div css={radioButtonsWrapperStyle}>
              <RadioGroupField
                value={String(entireSearchContent.globalSearchDateType)}
                items={[
                  {
                    id: 'stay-date',
                    value: String(EntireSearchDateType.Stay),
                    label: t('Date of stay'),
                  },
                  {
                    id: 'checkin-date',
                    value: String(EntireSearchDateType.Checkin),
                    label: t('Check-in date'),
                  },
                  {
                    id: 'checkout-date',
                    value: String(EntireSearchDateType.Checkout),
                    label: t('Check-out date'),
                  },
                  {
                    id: 'reservation-date',
                    value: String(EntireSearchDateType.Reservation),
                    label: t('Reservation date'),
                  },
                ]}
                onChange={e => setDateType(e.target.value)}
                name="globalSearchDateType"
                style={{ radioSize: 18 }}
                itemWrapperCss={radioButtonWrapperStyle}
                itemCss={radioLabelStyle}
              />
            </div>
            <div css={checkinTimeStyle}>
              <DatePicker date={entireSearchContent?.beginDate} onDateChange={setBeginDate} placeHolder={t('Full date')} />
              <span css={wavyLineStyle}>~</span>
              <DatePicker date={entireSearchContent?.endDate} onDateChange={setEndDate} placeHolder={t('Full date')} />
            </div>
          </div>
        </div>
        <div>
          <div css={fieldBlockStyle}>
            <div css={fieldTitleStyle}>{t('Room assignment status')}</div>
            <select
              name="selectGuestRoomAssign"
              css={assignSelectStyle}
              value={entireSearchContent?.selectGuestRoomAssign}
              onChange={handleContentChange}
            >
              <option value="all">{t('All')}</option>
              <option value="assigned">{t('Assigned')}</option>
              <option value="notAssigned">{t('Not assigned')}</option>
            </select>
          </div>
          <div css={fieldBlockStyle}>
            <div css={fieldTitleStyle}>{t('Room number')}</div>
            <input
              css={filedInputStyle}
              placeholder="0101"
              name="roomNumber"
              value={entireSearchContent?.roomNumber}
              onChange={handleContentChange}
            />
          </div>
          <div css={fieldBlockStyle}>
            <div css={fieldTitleStyle}>{t('Memo')}</div>
            <input
              css={filedInputStyle}
              placeholder={t('Excluding breakfast')}
              name="note"
              value={entireSearchContent?.note}
              onChange={handleContentChange}
            />
          </div>
        </div>
      </div>
      <div css={CheckboxContainerStyle}>
        <div css={fieldLeftButtonBlockStyle}>
          <div css={fieldTitleStyle}>{t('Booking status')}</div>
          <div css={reservationStatusSelectStyle}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <input
                      id="new-report"
                      alt="checkbox"
                      type="image"
                      name="newReport"
                      onClick={() => reservationStatusesChange(reservationStatuses.NewReport)}
                      src={require(`@/static/images/check-box${
                        entireSearchReservationStatuses.includes(reservationStatuses.NewReport) ? '' : '_off'
                      }.svg`)}
                    />
                    <label htmlFor="new-report">{t('New')}</label>
                  </td>
                  <td>
                    <input
                      id="modification-report"
                      alt="checkbox"
                      type="image"
                      name="modificationReport"
                      onClick={() => reservationStatusesChange(reservationStatuses.ModificationReport)}
                      src={require(`@/static/images/check-box${
                        entireSearchReservationStatuses.includes(reservationStatuses.ModificationReport) ? '' : '_off'
                      }.svg`)}
                    />
                    <label htmlFor="modification-report">{t('Update')}</label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      id="cancellation-report"
                      alt="checkbox"
                      type="image"
                      name="cancellationReport"
                      onClick={() => reservationStatusesChange(reservationStatuses.CancellationReport)}
                      src={require(`@/static/images/check-box${
                        entireSearchReservationStatuses.includes(reservationStatuses.CancellationReport) ? '' : '_off'
                      }.svg`)}
                    />
                    <label htmlFor="cancellation-report">{t('Cancel')}</label>
                  </td>
                  <td>
                    <input
                      id="no-show-report"
                      alt="checkbox"
                      type="image"
                      name="noShowReport"
                      onClick={() => reservationStatusesChange(reservationStatuses.NoshowReport)}
                      src={require(`@/static/images/check-box${
                        entireSearchReservationStatuses.includes(reservationStatuses.NoshowReport) ? '' : '_off'
                      }.svg`)}
                    />
                    <label htmlFor="no-show-report">{t('No show')}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div css={fieldRightButtonBlockStyle}>
          <div css={fieldTitleStyle}>{t('Accommodation management status')}</div>
          <div css={reservationStatusSelectStyle}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <input
                      id="reservation-status"
                      alt="checkbox"
                      type="image"
                      name="reservationStatus"
                      onClick={() => approvedStatusesChange(approvedStatuses.Reservation)}
                      src={require(`@/static/images/check-box${
                        entireSearchContent?.approvedStatuses.includes(approvedStatuses.Reservation) ? '' : '_off'
                      }.svg`)}
                    />
                    <label htmlFor="reservation-status">{t('Reserve')}</label>
                  </td>
                  <td>
                    <input
                      id="checkin-status"
                      alt="checkbox"
                      type="image"
                      name="checkinStatus"
                      onClick={() => approvedStatusesChange(approvedStatuses.Checkin)}
                      src={require(`@/static/images/check-box${
                        entireSearchContent?.approvedStatuses.includes(approvedStatuses.Checkin) ? '' : '_off'
                      }.svg`)}
                    />
                    <label htmlFor="checkin-status">{t('Checkin')}</label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      id="stay-status"
                      alt="checkbox"
                      type="image"
                      name="stayStatus"
                      onClick={() => approvedStatusesChange(approvedStatuses.Stay)}
                      src={require(`@/static/images/check-box${
                        entireSearchContent?.approvedStatuses.includes(approvedStatuses.Stay) ? '' : '_off'
                      }.svg`)}
                    />
                    <label htmlFor="stay-status">{t('During your stay')}</label>
                  </td>
                  <td>
                    <input
                      id="checkout-status"
                      alt="checkbox"
                      type="image"
                      name="checkoutStatus"
                      onClick={() => approvedStatusesChange(approvedStatuses.Checkout)}
                      src={require(`@/static/images/check-box${
                        entireSearchContent?.approvedStatuses.includes(approvedStatuses.Checkout) ? '' : '_off'
                      }.svg`)}
                    />
                    <label htmlFor="checkout-status">{t('Checkout')}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div css={bottomSearchPanelStyle}>
        <div css={assignButtonContainerStyle}>
          <Button width={92} height={32} buttonType={3} fontSize={12} onClick={clearEntireSearchPanel}>
            {t('Clear')}
          </Button>
          <Button width={93} height={32} buttonType={1} fontSize={12} marginLeft={16} onClick={search}>
            {t('Search')}
          </Button>
        </div>
      </div>
    </div>
  )
}

const dropDownSearchPanelStyle = css({
  width: '100%',
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 50,
  left: 0,
  border: '1px solid #f2f2f2',
  boxShadow: '0px 3px 4px #0000001a',
  borderRadius: 2,
})

const topSearchPanelStyle = css({
  display: 'flex',
  marginTop: '32px',
  borderBottom: '1px solid rgb(242, 242, 242)',
  paddingBottom: '28px',
})

const middleSearchPanelStyle = css({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  marginTop: '28px',
  borderBottom: '1px solid rgb(242, 242, 242)',
  paddingBottom: '15px',
  flexFlow: 'column',
  '> div': {
    display: 'flex',
    paddingTop: 24,
    ':first-of-type': {
      paddingTop: 0,
    },
  },
})

const bottomSearchPanelStyle = css({
  display: 'flex',
  margin: '16px 31px',
})

const fieldBlockStyle = css({
  ':first-of-type': {
    marginLeft: 32,
  },
  marginRight: 24,
})

const fieldTitleStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  color: '#676767',
  marginBottom: '12px',
})

const filedInputStyle = css({
  width: '210px',
  height: 32,
  padding: '8px 16px',
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  textAlign: 'left',
  backgroundSize: 10,
  '::placeholder': {
    color: '#CCCCCC',
    fontSize: 14,
  },
})

const assignButtonContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const reservationStatusSelectStyle = css({
  borderCollapse: 'separate',
  borderSpacing: 10,
  fontSize: 12,
  input: {
    width: 20,
    height: 20,
  },
  label: {
    display: 'inline-table',
    margin: '18px 26px 0 12px',
    cursor: 'pointer',
  },
})

const assignSelectStyle = css({
  width: 210,
  height: 32,
  color: '#272727',
  fontSize: 14,
  border: '1px solid #CCCCCC',
  fontWeight: 'normal',
  borderRadius: 19,
  paddingLeft: 16,
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/arrow_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '95% center',
  backgroundSize: 10,
  display: 'block',
})

const checkinTimeStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  color: '#676767',
  fontWeight: 'bold',
  '.SingleDatePickerInput__withBorder': {
    width: 210,
  },
})

const wavyLineStyle = css({
  padding: '0 7px',
})

const radioButtonsWrapperStyle = css({
  display: 'flex',
  marginBottom: 19,
})
const radioButtonWrapperStyle = css({
  display: 'flex',

  '& + &': {
    marginLeft: 24,
  },
})

const radioLabelStyle = css({
  label: {
    fontSize: 12,
    color: '#676767',
    fontWeight: 'bold',
  },
})

const CheckboxContainerStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'normal',
  color: '#676767',
  backgroundColor: '#FFF',
  padding: '24px 0 26px 0px',
  borderBottom: '1px solid #f2f2f2',
})

const fieldLeftButtonBlockStyle = css({
  marginLeft: 32,
})

const fieldRightButtonBlockStyle = css({
  marginLeft: '20px',
})
