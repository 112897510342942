import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { omit } from 'lodash'

import { Button } from '@/components/atoms/button'
import { RoomPropType } from '@/components/organisms/customer/_guest-room/not-edit-form-item'
import { PaxFullObject } from '@/models/guest-room/guest-room-assign'

type PaxModalProps = {
  room: RoomPropType
  roomIndex: number
  onEditPaxFull: (value: PaxFullObject, index: number) => void
  disabled?: boolean
}

export const PaxModal: React.FC<PaxModalProps> = ({ room, roomIndex, onEditPaxFull, disabled }) => {
  const { t } = useTranslation()
  const [localValues, setLocalValues] = useState<PaxFullObject>({})
  const hasChange =
    room.paxChildA != localValues.paxChildA ||
    room.paxChildB != localValues.paxChildB ||
    room.paxChildC != localValues.paxChildC ||
    room.paxChildD != localValues.paxChildD ||
    room.paxChildE != localValues.paxChildE ||
    room.paxChildF != localValues.paxChildF ||
    room.paxChildOther != localValues.paxChildOther ||
    room.paxFemale != localValues.paxFemale ||
    room.paxMale != localValues.paxMale

  const onChangePax = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const value = parseInt(event.target.value) || 0

    setLocalValues({
      ...localValues,
      [type]: value,
    })
  }

  const onClickConfirm = () => {
    onEditPaxFull(
      {
        ...localValues,
        pax: Object.keys(omit(localValues, ['pax'])).reduce((sum, key) => Number(sum) + Number(localValues[key]), 0),
      },
      roomIndex,
    )
  }

  useEffect(() => {
    setLocalValues({
      paxChildA: room.paxChildA ? Number(room.paxChildA).toString() : '0',
      paxChildB: room.paxChildB ? Number(room.paxChildB).toString() : '0',
      paxChildC: room.paxChildC ? Number(room.paxChildC).toString() : '0',
      paxChildD: room.paxChildD ? Number(room.paxChildD).toString() : '0',
      paxChildE: room.paxChildE ? Number(room.paxChildE).toString() : '0',
      paxChildF: room.paxChildF ? Number(room.paxChildF).toString() : '0',
      paxChildOther: room.paxChildOther ? Number(room.paxChildOther).toString() : '0',
      paxFemale: room.paxFemale ? Number(room.paxFemale).toString() : '0',
      paxMale: room.paxMale ? Number(room.paxMale).toString() : '0',
      pax: room.pax ? Number(room.pax).toString() : '0',
    })
  }, [room])

  return (
    <div className="pax-modal" css={paxModal}>
      <div css={fieldsContainer}>
        <div css={fieldContainer}>
          <div className="title">{t('Male')}</div>
          <input
            value={localValues.paxMale ? Number(localValues.paxMale).toString() : '0'}
            onChange={event => onChangePax(event, 'paxMale')}
            disabled={disabled}
            type="number"
            min={0}
          />
        </div>
        <div css={fieldContainer}>
          <div className="title">{t('Female')}</div>
          <input
            value={localValues.paxFemale ? Number(localValues.paxFemale).toString() : '0'}
            onChange={event => onChangePax(event, 'paxFemale')}
            disabled={disabled}
            type="number"
            min={0}
          />
        </div>
      </div>
      <div>
        <div
          css={{ margin: '16px 12px', borderTop: '1px solid #F2F2F2', paddingTop: 16, fontSize: 12, color: '#676767', fontWeight: 'bold' }}
        >
          {t('Child')}
        </div>
        <div css={fieldsContainer}>
          <div css={fieldContainer}>
            <div className="title">A</div>
            <input
              value={localValues.paxChildA ? Number(localValues.paxChildA).toString() : '0'}
              onChange={event => onChangePax(event, 'paxChildA')}
              disabled={disabled}
              type="number"
              min={0}
            />
          </div>
          <div css={fieldContainer}>
            <div className="title">B</div>
            <input
              value={localValues.paxChildB ? Number(localValues.paxChildB).toString() : '0'}
              onChange={event => onChangePax(event, 'paxChildB')}
              disabled={disabled}
              type="number"
              min={0}
            />
          </div>
          <div css={fieldContainer}>
            <div className="title">C</div>
            <input
              value={localValues.paxChildC ? Number(localValues.paxChildC).toString() : '0'}
              onChange={event => onChangePax(event, 'paxChildC')}
              disabled={disabled}
              type="number"
              min={0}
            />
          </div>
          <div css={fieldContainer}>
            <div className="title">D</div>
            <input
              value={localValues.paxChildD ? Number(localValues.paxChildD).toString() : '0'}
              onChange={event => onChangePax(event, 'paxChildD')}
              disabled={disabled}
              type="number"
              min={0}
            />
          </div>
          <div css={fieldContainer}>
            <div className="title">E</div>
            <input
              value={localValues.paxChildE ? Number(localValues.paxChildE).toString() : '0'}
              onChange={event => onChangePax(event, 'paxChildE')}
              disabled={disabled}
              type="number"
              min={0}
            />
          </div>
          <div css={fieldContainer}>
            <div className="title">F</div>
            <input
              value={localValues.paxChildF ? Number(localValues.paxChildF).toString() : '0'}
              onChange={event => onChangePax(event, 'paxChildF')}
              disabled={disabled}
              type="number"
              min={0}
            />
          </div>
          <div css={fieldContainer}>
            <div className="title">{t('Others')}</div>
            <input
              value={localValues.paxChildOther ? Number(localValues.paxChildOther).toString() : '0'}
              onChange={event => onChangePax(event, 'paxChildOther')}
              disabled={disabled}
              type="number"
              min={0}
            />
          </div>
        </div>
      </div>
      <div
        css={{
          margin: '0 -12px 6px',
          borderTop: '1px solid #F2F2F2',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '16px 24px 0',
        }}
      >
        <Button width={110} height={38} buttonType={hasChange ? 1 : 6} isDisabled={!hasChange} onClick={onClickConfirm}>
          {t('Save')}
        </Button>
      </div>
    </div>
  )
}

const paxModal = css({
  maxWidth: 512,
  width: '100%',
  padding: '16px 12px 10px',
  backgroundColor: '#FFF',
  boxShadow: '0px 0px 10px #00000029',
  borderRadius: 6,
  zIndex: 10,
})

const fieldsContainer = css({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: 'repeat(auto-fill, minmax(96px, 1fr))',
  gap: '12px 24px',
  margin: '0 16px 16px',
})

const fieldContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '.title': {
    fontSize: 12,
    color: '#676767',
    fontWeight: 'bold',
  },
  input: {
    width: 42,
    height: 32,
    color: '#272727',
    padding: '0 10px',
    borderRadius: 17,
    appearance: 'none',
    border: '1px solid #CCC',
  },
})
