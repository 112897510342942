import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

type StatsProps = {
  roomStats: {
    totalRoomDoNotDisturb: number
    totalRoomNotClean: number
    totalRoomNotCleanWithStay: number
    totalRoomNotCleanWithOut: number
    totalRoomCleaned: number
    totalRoomInspected: number
  }
}

export const Stats = ({ roomStats }: StatsProps) => {
  const { t } = useTranslation()

  return (
    <div css={statWrapperStyle}>
      <div css={statusTypeWrapperStyle}>
        <div css={statusDirtyStyle}>
          <p>{t('cleaningManager.Dirty')}</p>
          <span>{roomStats.totalRoomNotClean}</span>
        </div>
        <div css={totalStayCleaningStyle}>
          <p>{t('cleaningManager.Stay Cleaning')}</p>
          <span>{roomStats.totalRoomNotCleanWithStay}</span>
        </div>
        <div css={totalOutCleaningStyle}>
          <p>{t('cleaningManager.Out Cleaning')}</p>
          <span>{roomStats.totalRoomNotCleanWithOut}</span>
        </div>
      </div>
      <div css={statusTypeWrapperStyle}>
        <div css={statusCleanedStyle}>
          <p>{t('cleaningManager.Clean')}</p>
          <span>{roomStats.totalRoomCleaned}</span>
        </div>
        <div css={totalInspectedStyle}>
          <p>{t('cleaningManager.Inspected')}</p>
          <span>{roomStats.totalRoomInspected}</span>
        </div>
        <div css={totalRoomDoNotDisturbStyle}>
          <p>{t('cleaningManager.Do not Disturb')}</p>
          <span>{roomStats.totalRoomDoNotDisturb}</span>
        </div>
      </div>
    </div>
  )
}

const statWrapperStyle = css({
  display: 'flex',
  height: '100%',
  backgroundColor: '#FAFAFA',
  borderRadius: '0 0 5px 5px',
})

const statusTypeWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  padding: '12px 24px 14px 24px',
  ':first-of-type': {
    borderRight: '1px solid #f2f2f2',
  },
})
const statusStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  // height: '100%',
  height: 26,
  p: {
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 'bold',
    letterSpacing: '0.7px',
  },
  span: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 'bold',
    letterSpacing: '2.4px',
  },
})

const statusDirtyStyle = css(statusStyle, {
  color: '#67676bor7',
})
const statusCleanedStyle = css(statusStyle, {
  color: '#3E85CC',
})
const totalInspectedStyle = css(statusStyle, {
  color: '#7cc931',
})
const totalStayCleaningStyle = css(statusStyle, {
  color: '#F47110',
  textIndent: '1em',
  'p, span': {
    fontSize: 14,
    letterSpacing: '2.1',
  },
})
const totalOutCleaningStyle = css(statusStyle, {
  color: '#F2A40B',
  textIndent: '1em',
  'p, span': {
    fontSize: 14,
    letterSpacing: '2.1',
  },
})
const totalRoomDoNotDisturbStyle = css(statusStyle, {
  color: '#676767',
})
