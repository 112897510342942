import { fetchDeviceSetting } from '@/apis/aipass'
import { deviceSettingsType, DeviceType, SendType } from '@/models/device/device-setting'
import { useErrorHandler } from './use-error-handler'

export const useEposPrinter = (connectIdKey: string) => {
  const { errorHandler } = useErrorHandler()

  const connect = async (ip: string, port: number) => {
    return await new Promise<any>((resolve, reject) => {
      const ePosDev = new (window as any).epson.ePOSDevice()
      ePosDev.connect(ip, port, connectStatus => {
        if (connectStatus !== 'OK' && connectStatus !== 'SSL_CONNECT_OK') {
          reject(`プリンターに接続できません. status:${connectStatus}`)
          return
        }
        resolve(ePosDev)
      })
    })
  }

  const disconnect = (connection, printer) => {
    if (!connection?.isConnected) {
      return
    }
    if (!printer) {
      connection.disconnect()
      return
    }
    connection.deleteDevice(printer, () => connection.disconnect())
  }

  const createPrinter = async connection => {
    return new Promise<any>((resolve, reject) => {
      connection!.createDevice(
        'local_printer',
        connection.DEVICE_TYPE_PRINTER,
        { crypto: false, buffer: false },
        (deviceObject, errorCode) => {
          if (!deviceObject || errorCode !== 'OK') {
            reject(`プリンターに接続できません. code:${errorCode}`)
            return
          }
          deviceObject.timeout = 10000
          deviceObject.onreceive = res => {
            console.log({ res })
            disconnect(connection, deviceObject)
          }
          deviceObject.oncoveropen = () => {
            console.log('coveropen')
            disconnect(connection, deviceObject)
          }
          resolve(deviceObject)
        },
      )
    })
  }

  const getPrinterToConnectTo = async (): Promise<deviceSettingsType | undefined> => {
    const allDevice = await fetchDeviceSetting()
    if (!allDevice) {
      return
    }
    return allDevice.find(device => device.id === localStorage.getItem(connectIdKey))
  }

  const printImage = async (canvas: HTMLCanvasElement): Promise<void> => {
    const printerSetting = await getPrinterToConnectTo()
    if (!printerSetting) {
      return
    }
    try {
      const connection = await connect(printerSetting.ipAddress, printerSetting.port)
      const printer = await createPrinter(connection)
      printer.addImage(canvas.getContext('2d'), 0, 0, canvas.width, canvas.height)
      printer.addCut(printer.CUT_FEED)
      printer.send()
    } catch (error) {
      console.error(error)
      errorHandler(error)
    }
  }

  const storePrinterConnectId = (printerId: string): void => {
    localStorage.setItem(connectIdKey, printerId)
  }

  const existsPrinterByType = async (deviceType: DeviceType, sendType: SendType): Promise<boolean> => {
    if (!localStorage.getItem(connectIdKey)) {
      return false
    }
    const printerSetting = await getPrinterToConnectTo()
    return printerSetting?.deviceType === deviceType && printerSetting?.sendType === sendType
  }

  return { printImage, storePrinterConnectId, existsPrinterByType }
}
