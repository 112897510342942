import dayjs, { Dayjs, OpUnitType } from 'dayjs'

export const useDayjsRange = () => {
  const dayjsRange = (args: { start?: Dayjs; end?: Dayjs; interval: number; unit: OpUnitType }): Dayjs[] => {
    let currentDate = dayjs(args.start || dayjs().startOf('day'))
    const endOfRange = args.end || currentDate.add(1, 'day')
    const ranges: Dayjs[] = []
    // while (currentDate.isBefore(endOfRange) || currentDate.isSame(endOfRange)) {
    while (currentDate.isBefore(endOfRange)) {
      ranges.push(currentDate.clone())
      currentDate = currentDate.add(args.interval, args.unit)
    }
    return ranges
  }

  return { dayjsRange }
}
