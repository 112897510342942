import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import dayjs from 'dayjs'

// constants
import { simpleTableStyle } from '@/constants/layout'

// components
import { sectionHeaderStyle } from '@/components/pages/dashboard/_index/style'

import { TransactionType } from '@/models/smaregi/transaction'

type UsageDetailsProps = {
  transaction: TransactionType | undefined
}

export const UsageDetails: React.FC<UsageDetailsProps> = ({ transaction }) => {
  const { t } = useTranslation()

  return (
    <div css={containerStyle}>
      <div css={headerContainerStyle}>
        <div css={headerStyle}>{t('Sales')}</div>
      </div>
      <table css={tableStyle}>
        <thead>
          <tr>
            <th css={{ width: '12%' }}>{t('Date')}</th>
            <th css={{ width: '16%' }}>{t('Subject')}</th>
            <th css={{ width: '16%' }}>{t('Supplementary subjects')}</th>
            <th css={{ width: '8%' }}>{t('Unit price')}</th>
            <th css={{ width: '8%' }}>{t('Quantity')}</th>
            <th css={{ width: '8%' }}>{t('Earnings')}</th>
            <th css={{ width: '12%' }}>{t('Method of payment')}</th>
            <th css={{ width: '8%' }}>{t('Payment')}</th>
            <th css={{ width: '12%' }}>{t('Accounts receivable')}</th>
          </tr>
        </thead>
        <tbody>
          {transaction?.sales
            ?.filter(sale => !sale.isDiscount)
            .map((usageDetail, index) => (
              <tr key={index}>
                <td>{dayjs(usageDetail.salesAt).format(t('MM-DD'))}</td>
                <td>{usageDetail.saleSubjectName}</td>
                <td>{usageDetail.saleSubSubjectName || '-'}</td>
                <td>{Number(usageDetail.unitPrice).toLocaleString()}</td>
                <td>{Number(usageDetail.quantity).toLocaleString()}</td>
                <td>{Number(usageDetail.salesPrice).toLocaleString()}</td>
                <td>{usageDetail.advanceCompanyName || usageDetail.paymentMethodMasterName || '-'}</td>
                {!usageDetail.paid ? <td css={unpaidTextStyle}>{t('Unpaid')}</td> : <td>{t('Paid')}</td>}
                <td className="accountsReceivable">{usageDetail.accountReceivableMasterName || '-'}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

const containerStyle = css({
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  border: '1px solid #cccccc',
  width: '100%',
  overflow: 'hidden',
})

const headerStyle = css(sectionHeaderStyle, {
  height: 43.5,
  marginLeft: 28,
  fontSize: 14,
  fontWeight: 'bold',
  letterSpacing: '2.1px',
})

const tableStyle = css(simpleTableStyle, {
  'tbody td': {
    padding: '21px 8px',
  },
})

const headerContainerStyle = css({
  marginRight: 32,
  display: 'flex',
  justifyContent: 'space-between',
})

const unpaidTextStyle = css({
  color: '#F2A40B !important',
})
