import React, { useContext, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { InputField } from '@/components/molecules/input-field'
import { Label } from '@/components/molecules/label'
import { Button, ButtonType } from '@/components/atoms/button'
import { GuestRoomNumberType } from '@/models/guest-room-number'
import {
  modalContainerStyle,
  modalWrapperStyle,
  modalStyle,
  modalTitleStyle,
  modalSectionStyle,
  modalItemWrapperStyle,
  modalFooterStyle,
} from '@/components/pages/setting/guest-room/room/_room/modal/modal-layout'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { LoaderContextCreator } from '@/contexts/loader'
import { Controller, useForm } from 'react-hook-form'
import { getAdminGuestRoomFloor, getAdminGuestRoomTypeSelect, insertAdminGuestRoomNumber, putAdminGuestRoomNumber } from '@/apis/aipass'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import { GuestRoomFloorType } from '@/models/guest-room-floor'
import { GuestRoomTypeSelectType } from '@/models/guest-room-type'

type Props = {
  detail?: GuestRoomNumberType
  nextDisplayOrder: string
  nextAssignOrder: string
  onSave: () => void
  onClose: () => void
}

type FormType = {
  displayOrder: string
  assignOrder: string
  roomNumber: string
  roomTypeId: string
  roomFloorId: string
  capacity: string | undefined
}

export const RoomNumberModal: React.FC<Props> = ({ detail, nextDisplayOrder, nextAssignOrder, onSave, onClose }) => {
  const { t, i18n } = useTranslation()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const { errorHandler } = useErrorHandler()
  const [guestRoomTypes, setGuestRoomTypes] = useState<GuestRoomTypeSelectType[]>([])
  const [guestRoomFloors, setGuestRoomFloors] = useState<GuestRoomFloorType[]>([])

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormType>({
    defaultValues: {
      displayOrder: detail?.displayOrder || nextDisplayOrder,
      assignOrder: detail?.assignOrder || nextAssignOrder,
      roomNumber: detail?.roomNumber || '',
      roomTypeId: detail?.roomTypeId || '',
      roomFloorId: detail?.roomFloorId || '',
      capacity: detail?.capacity || undefined,
    },
  })

  const doSave = async (value: FormType) => {
    try {
      setIsLoading(true)
      if (detail) {
        await putAdminGuestRoomNumber(detail.id, value)
      } else {
        await insertAdminGuestRoomNumber(value)
      }
      onSave()
      onClose()
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getAdminGuestRoomTypeSelect().then(setGuestRoomTypes)
    getAdminGuestRoomFloor().then(setGuestRoomFloors)
  }, [])

  return (
    <div css={modalContainerStyle} style={{ height: '100%', alignItems: 'normal' }}>
      <div css={modalWrapperStyle} style={{ height: 'auto' }}>
        <div css={[modalStyle, { margin: '15px auto' }]}>
          <div css={modalTitleStyle}>{detail ? t('Edit room') : t('Add a room')}</div>
          <div css={[modalSectionStyle, { maxHeight: '80%' }]}>
            <div css={[modalItemWrapperStyle, { width: 'auto', paddingBottom: 8 }]}>
              <Controller
                control={control}
                name="displayOrder"
                rules={{ required: t('Required field has not been entered') }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <InputField
                    handleChangeData={onChange}
                    name={name}
                    value={value}
                    label={t('Display order')}
                    placeholder="00000"
                    error={error?.message}
                    required
                    marginBottom={24}
                  />
                )}
              />
              <Controller
                control={control}
                name="assignOrder"
                rules={{ required: t('Required field has not been entered') }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <InputField
                    handleChangeData={onChange}
                    name={name}
                    value={value}
                    label={t('Assign sequence')}
                    placeholder="00000"
                    error={error?.message}
                    required
                    marginBottom={24}
                  />
                )}
              />
              <Controller
                control={control}
                name="roomNumber"
                rules={{ required: t('Required field has not been entered') }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <InputField
                    handleChangeData={onChange}
                    name={name}
                    value={value}
                    label={t('Room number')}
                    placeholder={t('Room number')}
                    error={error?.message}
                    required
                    marginBottom={24}
                  />
                )}
              />
              <Controller
                control={control}
                name="roomTypeId"
                rules={{ required: t('Required field has not been entered') }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <>
                    <Label label={`${t('Room type')} *`} />
                    <Select
                      value={guestRoomTypes.length ? value : ''}
                      onChange={onChange}
                      error={error?.message}
                      customStyle={css({ paddingBottom: 24 })}
                    >
                      <AntSelect.Option value="" disabled>
                        {t('Please select a room type')}
                      </AntSelect.Option>
                      {guestRoomTypes.map(v => (
                        <AntSelect.Option key={v.id} value={v.roomTypeId}>
                          {i18n.language === 'ja' ? v.typeNameJa : v.typeNameEn}
                        </AntSelect.Option>
                      ))}
                    </Select>
                  </>
                )}
              />
              <Controller
                control={control}
                name="roomFloorId"
                rules={{ required: t('Required field has not been entered') }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <>
                    <Label label={`${t('Floor')} *`} />
                    <Select
                      value={guestRoomFloors.length ? value : ''}
                      onChange={onChange}
                      error={error?.message}
                      customStyle={css({ paddingBottom: 24 })}
                    >
                      <AntSelect.Option value="" disabled>
                        {t('Please select a floor')}
                      </AntSelect.Option>
                      {guestRoomFloors.map(v => (
                        <AntSelect.Option key={v.roomFloorId} value={v.roomFloorId}>
                          {i18n.language === 'ja' ? v.floorNameJa : v.floorNameEn}
                        </AntSelect.Option>
                      ))}
                    </Select>
                  </>
                )}
              />
              <Controller
                control={control}
                name="capacity"
                rules={{ required: t('Required field has not been entered') }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <InputField
                    handleChangeData={onChange}
                    type="number"
                    name={name}
                    value={value}
                    label={t('Capacity')}
                    placeholder="00"
                    error={error?.message}
                    required
                    marginBottom={24}
                  />
                )}
              />
            </div>
          </div>
          <div css={modalFooterStyle}>
            <Button buttonType={ButtonType.Secondary} width={110} height={38} fontSize={14} marginRight={16} onClick={() => onClose()}>
              {t('Cancel')}
            </Button>
            <Button
              buttonType={isValid ? ButtonType.Primary : ButtonType.PrimaryDisabled}
              width={110}
              height={38}
              fontSize={14}
              marginRight={32}
              onClick={handleSubmit(doSave)}
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
