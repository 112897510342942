import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { useWindowSize } from 'react-use'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle, listContainerStyle } from '@/constants/layout'

// apis
import { fetchFacilityAdditionalInfo, updateFacilityAdditionalInfo } from '@/apis/aipass'

// libs
import { FacilityTranslateText } from '@/libs/translate-text'

// components
import { Button } from '@/components/atoms/button'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { SettingSideMenu } from '@/components/organisms/setting-side-menu'
import { Editor, editorOptions } from '@/components/organisms/editor'
import { LoadingFull } from '@/components/molecules/loading-full'

// models
import { FacilityPageIndex } from '@/models/facility'

type ContainerProps = {}

type Props = {
  history: any
  location: any
  isLoading: boolean
  jpContent: string
  onSubmit: any
  editorState: any
  setEditorState: (editorState: any) => void
}

export const SettingFacilityBathEdit: React.FC<ContainerProps> = () => {
  const history = useHistory()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [jpContent, setJpContent] = useState<string>('')
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [additionalInfo, setAdditionalInfo] = useState<any>()

  useEffect(() => {
    _fetchFacilityAdditionalInfo()
  }, [])

  // Acquisition of in-house guidance information
  const _fetchFacilityAdditionalInfo = async () => {
    await fetchFacilityAdditionalInfo(FacilityPageIndex.Bath).then(res => {
      if (res !== undefined) {
        setAdditionalInfo(res?.facilityAdditionalPageInfo)
        setJpContent(res?.facilityAdditionalPageInfo.jpContent)
      }
    })
  }

  const onSubmit = async () => {
    setIsLoading(true)
    const jpContent = stateToHTML(editorState.getCurrentContent(), editorOptions).replace(/\r?\n/g, '</br>')
    const pageId = additionalInfo?.pageId ? additionalInfo?.pageId : null
    const pageIndex = FacilityPageIndex.Bath
    await FacilityTranslateText({ pageId, pageIndex, jpContent }).then(data => {
      updateFacilityAdditionalInfo(data)
        .then(() => {
          setIsLoading(false)
          history.push({ pathname: '/setting/facility/list', search: location.search })
        })
        .catch(error => {
          console.log(error)
        })
    })
  }

  return (
    <UI
      history={history}
      location={location}
      jpContent={jpContent}
      onSubmit={onSubmit}
      editorState={editorState}
      setEditorState={setEditorState}
      isLoading={isLoading}
    />
  )
}

export const UI: React.FC<ContainerProps & Props> = ({
  history,
  location,
  jpContent,
  onSubmit,
  editorState,
  setEditorState,
  isLoading,
}) => {
  const windows = useWindowSize()
  const { t } = useTranslation()

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header title={t('Setting')} isPublicPageLink />
        <div className="mainContainer" css={settingContainerStyle}>
          <SettingSideMenu />
          <div css={bathEditContainerStyle}>
            <div css={bathEditMainContainerStyle}>
              <div
                css={bathEditTopContainerStyle}
                style={{
                  maxHeight: windows.height - 162,
                }}
              >
                <div css={bathEditHeaderContainerStyle}>
                  <div css={bathEditHeaderTitleStyle}>{t('Bathhouse')}</div>
                </div>
                <div
                  css={editorContainerStyle}
                  style={{
                    maxHeight: windows.height - 280,
                  }}
                >
                  <Editor jpContent={jpContent} editorState={editorState} setEditorState={setEditorState} usedIn={'others'} />
                </div>
              </div>
            </div>
            <div css={settingFooterContainerStyle}>
              <Button
                buttonType={3}
                width={110}
                height={38}
                marginLeft={32}
                marginTop={11}
                marginBottom={11}
                onClick={() => history.push({ pathname: '/setting/facility/list', search: location.search })}
              >
                {t('Cancel')}
              </Button>
              <Button buttonType={1} width={110} height={38} marginLeft={16} marginTop={11} marginBottom={11} onClick={() => onSubmit()}>
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const settingContainerStyle = css(mainContainerStyle, {
  display: 'flex',
  padding: 0,
})

const editorContainerStyle = css({
  margin: 32,
  position: 'relative',
  height: '580px',
})

const bathEditContainerStyle = css({
  width: 'calc(100% - 180px)',
})

const bathEditMainContainerStyle = css({
  padding: 24,
  height: '88vh',
})

const bathEditTopContainerStyle = css(listContainerStyle, {
  width: '100%',
  height: '100%',
  background: '#FFF',
  marginBottom: 16,
})

const bathEditHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const bathEditHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
})

// footer
const settingFooterContainerStyle = css({
  display: 'flex',
  height: 59,
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: '#FFF',
  boxShadow: '0px 1px 6px rgba(0,0,0,0.10)',
})
