import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'

// contexts
import { AccountContext } from '@/contexts/account'

// constants
import {
  listContainerStyle,
  listContentsWrapperStyle,
  listContentsStyle,
  HEADER_HEIGHT,
  MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL,
  CONTAINER_WITH_HEADER_BUTTON_LIST_MARGIN,
  TAB_HEIGHT,
  GRAY_LABEL_HEIGHT,
  LIST_FOOTER_HEIGHT,
} from '@/constants/layout'

// layout
import {
  settingHeaderContainerStyle,
  listWrapperStyle,
  listStyle,
  hideListStyle,
  deleteStyle,
} from '@/components/pages/setting/guest-room/room/layout'

// apis
import { getAdminGuestRoomType, insertAdminGuestRoomTypeCsv, deleteAdminGuestRoomType } from '@/apis/aipass'

// components
import { GuestRoomTab } from '@/components/pages/setting/guest-room/tab'
import { RoomTypeModal } from '@/components/pages/setting/guest-room/room/_room/modal/room-type-modal'
import { SettingGuestRoomLabel } from '@/components/pages/setting/guest-room/room/_room/label'
import { SettingGuestRoomEditButtons } from '@/components/pages/setting/guest-room/room/_room/edit-buttons'
import { EditFooter } from '@/components/organisms/edit-footer'
import { GuestRoomTypeType, GuestRoomTypeCsvType } from '@/models/guest-room-type'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { SettingsLayout } from '@/components/layouts/settings-layout'

type Csv = {
  guestRoomTypesCsv: GuestRoomTypeCsvType[]
}

export const SettingGuestRoomType: React.FC = () => {
  const windows = useWindowSize()
  const { errorHandler } = useErrorHandler()
  const { t, i18n } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isUpload, setIsUpload] = useState<boolean>(false)
  const [showFooter, setShowFooter] = useState<boolean>(false)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [deleteRoomIds, setDeleteRoomIds] = useState<string[]>([])
  const [editRoom, setEditRoom] = useState<GuestRoomTypeType>()
  const [guestRoomTypes, setGuestRoomTypes] = useState<GuestRoomTypeType[]>([])

  useEffect(() => {
    if (account && account?.hotel) {
      _fetchGuestRoomType()
    }
  }, [account])

  useEffect(() => {
    if (isUpload && account) {
      _fetchGuestRoomType()
    }
  }, [isUpload])

  const _fetchGuestRoomType = async () => {
    setIsLoading(true)
    await getAdminGuestRoomType().then(res => {
      setGuestRoomTypes(res)
      setIsLoading(false)
      setIsUpload(false)
    })
  }

  const updateGuestRoomType = async (value: GuestRoomTypeType) => {
    setEditRoom(value)
    setShowEditModal(true)
  }

  const csvData = [
    [
      '客室タイプID',
      '客室タイプ',
      '客室タイプ名(略称)',
      '部屋数',
      '客室タイプコード(1)',
      '客室タイプコード(2)',
      '客室タイプコード(3)',
      '客室タイプコード(4)',
      '客室タイプコード(5)',
      '客室タイプコード(6)',
      '客室タイプコード(7)',
      '客室タイプコード(8)',
      '客室タイプコード(9)',
      '客室タイプコード(10)',
    ],
  ]

  const onFileLoad = async data => {
    try {
      setIsLoading(true)

      const characterCountError: string[] = []
      const characterError: string[] = []
      const typeNameError: string[] = []

      const uploadData: Csv = {
        guestRoomTypesCsv: data.map(function (value) {
          const roomTypeId = value.客室タイプID
          const roomTypeName = t(value.客室タイプ)
          const roomQuantity = t(value.部屋数)
          const roomTypeShortName = value['客室タイプ名(略称)']

          if (roomTypeId) {
            if (roomTypeId.match(/^[0-9]+$/)) {
              if (roomTypeId.length > 4) {
                characterCountError.push(roomTypeId)
                return
              } else if (typeNameError.includes(roomTypeId)) {
                return
              } else {
                return {
                  roomTypeId,
                  roomTypeName,
                  roomTypeShortName,
                  quantity: roomQuantity,
                  otaCodes: [
                    value['客室タイプコード(1)'],
                    value['客室タイプコード(2)'],
                    value['客室タイプコード(3)'],
                    value['客室タイプコード(4)'],
                    value['客室タイプコード(5)'],
                    value['客室タイプコード(6)'],
                    value['客室タイプコード(7)'],
                    value['客室タイプコード(8)'],
                    value['客室タイプコード(9)'],
                    value['客室タイプコード(10)'],
                  ],
                }
              }
            } else {
              characterError.push(roomTypeId)
              return
            }
          }
        }),
      }

      if (characterCountError.length) {
        alert(t('Room type ID {{id}} must be 4 characters or less', { id: characterCountError.join(',') }))
      } else if (characterError.length) {
        alert(t('Room type ID {{id}} is not a single-byte number', { id: characterError.join(',') }))
      }

      const res = await insertAdminGuestRoomTypeCsv(uploadData.guestRoomTypesCsv)

      if (res && res?.errRoomIds) {
        const errRoomIds = res.errRoomIds.filter(value => value)
        if (errRoomIds.length) {
          alert(t('Room ID {{id}} could not be captured', { id: errRoomIds.join(',') }))
        }
      }
      setIsUpload(true)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onCLickDelete = (id: string) => {
    setShowFooter(true)
    setDeleteRoomIds(deleteRoomIds => [...deleteRoomIds, id])
  }

  const onClickFooterSaveButton = async () => {
    try {
      setShowFooter(false)
      if (!deleteRoomIds) {
        return
      }
      if (window.confirm(t('Are you sure you want to delete it'))) {
        await deleteAdminGuestRoomType(deleteRoomIds)
        setDeleteRoomIds([])
      } else {
        setDeleteRoomIds([])
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  const onClickFooterCancelButton = () => {
    setShowFooter(false)
    setDeleteRoomIds([])
  }

  const onClickModalCancelButton = () => {
    setShowCreateModal(false)
    setShowEditModal(false)
  }
  const onClickModalSaveButton = async () => {
    setShowCreateModal(false)
    setShowEditModal(false)
    await _fetchGuestRoomType()
  }

  const listItems = [
    {
      label: t('Display order'),
      width: 62,
    },
    {
      label: t('Room type ID'),
      width: 112,
    },
    {
      label: t('Room type name'),
      width: 227,
    },
    {
      label: t('Room type short name'),
      width: 148,
    },
    {
      label: t('Number of rooms'),
      width: 79,
    },
    {
      label: t('Room type code'),
      width: 470,
    },
  ]

  const onClickAddButton = () => {
    setShowCreateModal(true)
  }

  const computedNextDisplayOrder = () => {
    return guestRoomTypes?.length ? guestRoomTypes[guestRoomTypes.length - 1].displayOrder + 1 : 1
  }

  const footerContent = showFooter ? <EditFooter onSave={onClickFooterSaveButton} onCancel={onClickFooterCancelButton} /> : undefined

  return (
    <>
      {showCreateModal && (
        <RoomTypeModal
          title={t('Add room type')}
          onClickCancel={onClickModalCancelButton}
          onClickSave={onClickModalSaveButton}
          nextDisplayOrder={computedNextDisplayOrder()}
          setIsLoading={setIsLoading}
        />
      )}
      {showEditModal && (
        <RoomTypeModal
          title={t('Edit Room Type')}
          onClickCancel={onClickModalCancelButton}
          onClickSave={onClickModalSaveButton}
          editRoom={editRoom}
          setIsLoading={setIsLoading}
        />
      )}
      <SettingsLayout loading={isLoading} footerContent={footerContent}>
        <>
          <div css={settingHeaderContainerStyle}>
            <GuestRoomTab currentTab="roomType" />
          </div>
          <div css={[listContainerStyle]}>
            <SettingGuestRoomEditButtons
              csvData={csvData}
              onFileLoad={onFileLoad}
              onClickAddButton={onClickAddButton}
              enabledCsvButton={!guestRoomTypes?.length}
            />
            <SettingGuestRoomLabel listItems={listItems} />
            <div
              css={[listContentsWrapperStyle]}
              style={
                showFooter
                  ? {
                      maxHeight:
                        windows.height -
                        HEADER_HEIGHT -
                        MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL -
                        CONTAINER_WITH_HEADER_BUTTON_LIST_MARGIN -
                        TAB_HEIGHT -
                        GRAY_LABEL_HEIGHT -
                        LIST_FOOTER_HEIGHT,
                    }
                  : {
                      maxHeight:
                        windows.height -
                        HEADER_HEIGHT -
                        MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL -
                        CONTAINER_WITH_HEADER_BUTTON_LIST_MARGIN -
                        TAB_HEIGHT -
                        GRAY_LABEL_HEIGHT,
                    }
              }
            >
              <div css={listContentsStyle}>
                {guestRoomTypes &&
                  guestRoomTypes.map((value: GuestRoomTypeType, index) => {
                    if (deleteRoomIds.includes(value.id)) {
                      return <div key={index} css={hideListStyle} />
                    } else {
                      return (
                        <div key={index} css={listWrapperStyle}>
                          <div css={listStyle} onClick={() => updateGuestRoomType(value)}>
                            <div style={{ width: listItems[0].width }}>{value.displayOrder}</div>
                            <div style={{ width: listItems[1].width }}>{value.roomTypeId}</div>
                            <div style={{ width: listItems[2].width }}>{i18n.language === 'ja' ? value.typeNameJa : value.typeNameEn}</div>
                            <div style={{ width: listItems[3].width }}>{value.typeShortName}</div>
                            <div style={{ width: listItems[4].width }}>{value.quantity || value.quantity == 0 ? value.quantity : '-'}</div>
                            <div style={{ width: listItems[5].width }}>
                              {value.otaCodes ? value.otaCodes.map(({ code }) => code).join(', ') : ''}
                            </div>
                          </div>
                          <img
                            css={deleteStyle}
                            src={require('@/static/images/room_delete_gold.svg')}
                            onClick={() => onCLickDelete(value.id)}
                          />
                        </div>
                      )
                    }
                  })}
              </div>
            </div>
          </div>
        </>
      </SettingsLayout>
    </>
  )
}
