import React, { useEffect, useState } from 'react'
import { Cascader } from 'antd'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import dayjs from 'dayjs'
import moment, { Moment } from 'moment'
import 'dayjs/locale/ja'
import { mainContainerHeaderBarStyle, headerBarLeftWrapperStyle, dashboardPageContainerStyle } from '@/constants/layout'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { fetchReport, getReservationsBoundaryDates } from '@/apis/aipass'
import { DashboardPageLayout } from '@/components/layouts/dashboard-page-layout'
import { ReportTypes, ReportPeriods } from '@/models/report'
import { NoticeLoading } from '@/components/molecules/notice-loading'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { DateField } from '@/components/molecules/date-field'
import { useForceUpdate } from '@/hooks/use-force-update'
import { ReportDownloadLink } from '@/components/organisms/reports-manager/report-download-link'
import { SalesSubjectSelectBox } from '@/components/organisms/reports-manager/sales-subject-selecte-box'
import { ToggleButton } from '@/components/molecules/settings/toggle-button'
import { ReservationNoteCategory } from '@/models/reservation/reservation-note'

type CascaderOptionsType = { value: any; label: string; children: { value: any; label: string }[] }

export const ReportsManager: React.FC = () => {
  const windows = useWindowSize()
  const { t, i18n } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [loading, setLoading] = useState<boolean>(false)
  const [date, setDate] = useState<Moment | null>(moment())
  const [monthOptions, setMonthOptions] = useState<CascaderOptionsType[]>([])
  const [reservationsBoundaryDates, setReservationsBoundaryDates] = useState<{ min: string; max: string }>()
  const [reportPeriod, setReportPeriod] = useState<ReportPeriods>(ReportPeriods.day)
  const [isMonthCascaderOpen, setIsMonthCascaderOpen] = useState<boolean>(false)
  const reportPeriodItems = [
    { value: ReportPeriods.day, label: t('By day') },
    { value: ReportPeriods.month, label: t('By month') },
  ]
  const forceUpdate = useForceUpdate()
  const [reportSalesSubjects, setReportSalesSubjects] = useState<string[]>([])
  const [reportMemoCategory, setReportMemoCategory] = useState<ReservationNoteCategory>(ReservationNoteCategory.Front)
  const [isSalesMonthIncludeTax, setIsSalesMonthIncludeTax] = useState(true)
  const [isSalesMonthChannelIncludeTax, setIsSalesMonthChannelIncludeTax] = useState(true)

  const storeSearchSubjectHistory = (type: ReportTypes, subjectCodes: string[]) => {
    if (type !== ReportTypes.dailyOperations && type !== ReportTypes.salesBySubjectsMonthly) {
      return
    }
    const storageKey = `${type}.history`
    const storedHistories = JSON.parse(localStorage.getItem(storageKey) || '[]')
    const newHistory = subjectCodes.join(',')
    const replaceHistories = storedHistories.filter(sh => sh !== newHistory)
    replaceHistories.unshift(newHistory)
    localStorage.setItem(storageKey, JSON.stringify(replaceHistories.slice(0, 5)))
  }

  const getStoredSearchSubjectHistory = (type: ReportTypes): Array<string[]> => {
    const storageKey = `${type}.history`
    return JSON.parse(localStorage.getItem(storageKey) || '[]').map((sh: string) => sh.split(','))
  }

  const downloadReport = async ({
    type,
    format,
    subjectCodes = [],
    isIncludeTax = false,
    noteCategory,
  }: {
    type: ReportTypes
    format: 'pdf' | 'csv'
    subjectCodes?: string[]
    isIncludeTax?: boolean
    noteCategory?: ReservationNoteCategory
  }) => {
    try {
      setLoading(true)
      const isRequiredSubject = type === ReportTypes.salesBySubjectsMonthly || type === ReportTypes.dailyOperations
      if (isRequiredSubject && !subjectCodes.length) {
        return errorHandler(t('Please select subjects'))
      }
      const params = { type, format, subjectCodes, isIncludeTax, noteCategory }
      storeSearchSubjectHistory(type, subjectCodes)

      if (reportPeriod === ReportPeriods.day) params['date'] = moment(date).format('YYYY-MM-DD')
      if (reportPeriod === ReportPeriods.month) params['month'] = moment(date).format('YYYY-MM')

      const res = await fetchReport(params)

      if (typeof res?.url === 'string') window.open(res.url, '_blank')
      else errorHandler(res)
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchReservationsBoundaryDates = async () => {
    try {
      setLoading(true)
      const reservationsBoundaryDates = await getReservationsBoundaryDates()
      setReservationsBoundaryDates(reservationsBoundaryDates)
      buildMonthOptions()
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const buildMonthOptions = () => {
    if (typeof reservationsBoundaryDates?.min !== 'string' || typeof reservationsBoundaryDates?.max !== 'string') return

    const min = moment(reservationsBoundaryDates.min)
    const max = moment(reservationsBoundaryDates.max)
    const minYear = min.get('year')
    const minMonth = min.get('month')
    const maxYear = max.get('year')
    const maxMonth = max.get('month')

    const options: CascaderOptionsType[] = []

    for (let year = minYear; year <= maxYear; year++) {
      let months: { value: any; label: string }[] = []

      if (year === minYear) {
        months = [...Array(12 - minMonth)].map((item, index) => ({
          value: String(index + minMonth),
          label: dayjs()
            .month(index + minMonth)
            .locale(i18n.language)
            .format('MMM'),
        }))
      } else if (year === maxYear) {
        months = [...Array(maxMonth + 1)].map((item, index) => ({
          value: String(index),
          label: dayjs().month(index).locale(i18n.language).format('MMM'),
        }))
      } else {
        months = [...Array(12)].map((item, index) => ({
          value: String(index),
          label: dayjs().month(index).locale(i18n.language).format('MMM'),
        }))
      }

      options.push({
        value: String(year),
        label: String(year + t('+year')),
        children: months,
      })
    }

    setMonthOptions(options)
  }

  const onChangeMonth = value => {
    const year = value[0]
    const month = value[1]

    setDate(moment().year(year).month(month))
  }

  useEffect(() => {
    forceUpdate()
  }, [i18n.language])

  useEffect(() => {
    buildMonthOptions()
  }, [reservationsBoundaryDates, i18n.language])

  useEffect(() => {
    fetchReservationsBoundaryDates()
  }, [])

  return (
    <DashboardPageLayout title={t('Reports')}>
      <>
        <NoticeLoading isLoading={loading} />
        <div css={mainContainerHeaderBarStyle}>
          <div css={headerBarLeftWrapperStyle}>
            <RadioGroupField
              value={reportPeriod}
              items={reportPeriodItems}
              onChange={e => setReportPeriod(e.target.value)}
              itemCss={css({ marginRight: 32 })}
            />
            <div css={dateChangeContainer} className={isMonthCascaderOpen ? 'date-change-container--cascader-active' : ''}>
              <DateField
                value={date}
                onChange={value => setDate(value)}
                min={reservationsBoundaryDates?.min}
                max={reservationsBoundaryDates?.max}
                displayFormat={reportPeriod === ReportPeriods.month ? t('YYYY-MM') : undefined}
              />
              {reportPeriod === ReportPeriods.month && (
                <Cascader
                  defaultValue={date ? [String(date?.get('year')), String(date?.get('month'))] : []}
                  options={monthOptions}
                  onChange={onChangeMonth}
                  allowClear={false}
                  css={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, opacity: 0 }}
                  popupClassName="popup-cascader-customize-date"
                  onPopupVisibleChange={value => setIsMonthCascaderOpen(value)}
                />
              )}
            </div>
          </div>
        </div>
        <div css={dashboardPageContainerStyle}>
          <div css={{ overflow: 'auto', height: windows.height - 236, marginBottom: 16 }}>
            <table css={{ width: '100%', borderCollapse: 'collapse' }}>
              {reportPeriod === ReportPeriods.month ? (
                <tbody>
                  <ReportDownloadLink
                    title={t('reports.SalesMonth')}
                    type={ReportTypes.salesMonthly}
                    onDownload={params => downloadReport({ ...params, isIncludeTax: isSalesMonthIncludeTax })}
                  >
                    <span style={{ borderLeft: 'solid 1px #CCCCCC', paddingLeft: 24 }}>
                      <ToggleButton
                        value={isSalesMonthIncludeTax}
                        onChange={() => {
                          setIsSalesMonthIncludeTax(!isSalesMonthIncludeTax)
                        }}
                        label={{ on: t('tax inc'), off: t('w/o tax') }}
                      />
                    </span>
                  </ReportDownloadLink>
                  <ReportDownloadLink
                    title={t('reports.SalesByReservationChannel')}
                    type={ReportTypes.salesMonthlyByChannel}
                    onDownload={params => downloadReport({ ...params, isIncludeTax: isSalesMonthChannelIncludeTax })}
                  >
                    <span style={{ borderLeft: 'solid 1px #CCCCCC', paddingLeft: 24 }}>
                      <ToggleButton
                        value={isSalesMonthChannelIncludeTax}
                        onChange={() => {
                          setIsSalesMonthChannelIncludeTax(!isSalesMonthChannelIncludeTax)
                        }}
                        label={{ on: t('tax inc'), off: t('w/o tax') }}
                      />
                    </span>
                  </ReportDownloadLink>
                  <ReportDownloadLink
                    title={t('Table of actual monthly operation')}
                    type={ReportTypes.actualOperationsMonthly}
                    onDownload={downloadReport}
                  />
                  <ReportDownloadLink title={t('reports.Deposits')} type={ReportTypes.depositsMonthly} onDownload={downloadReport} />
                  <ReportDownloadLink
                    title={t('Subject summary')}
                    type={ReportTypes.salesBySubjectsMonthly}
                    onDownload={params => downloadReport({ ...params, subjectCodes: reportSalesSubjects })}
                  >
                    <SalesSubjectSelectBox
                      value={reportSalesSubjects}
                      onChange={setReportSalesSubjects}
                      history={getStoredSearchSubjectHistory(ReportTypes.salesBySubjectsMonthly)}
                    />
                  </ReportDownloadLink>
                </tbody>
              ) : (
                <tbody>
                  <ReportDownloadLink
                    title={t('reports.DailyDepartments')}
                    type={ReportTypes.dailyDepartments}
                    onDownload={downloadReport}
                  />
                  <ReportDownloadLink
                    title={`${t('reports.SalesDay')} / ${t('Room sales')}`}
                    type={ReportTypes.salesRoomDaily}
                    onDownload={downloadReport}
                  />
                  <ReportDownloadLink
                    title={`${t('reports.SalesDay')} / ${t('Other sales / provisional receipt')}`}
                    type={ReportTypes.salesOtherDaily}
                    onDownload={downloadReport}
                  />
                  <ReportDownloadLink
                    title={t('reports.Deposit by Accounts Receivable')}
                    type={ReportTypes.depositsDaily}
                    onDownload={downloadReport}
                  />
                  <ReportDownloadLink title={t('Refunds')} type={ReportTypes.refundsDaily} onDownload={downloadReport} />
                  <ReportDownloadLink
                    title={t('Operation report')}
                    type={ReportTypes.dailyOperations}
                    onDownload={params =>
                      downloadReport({ ...params, subjectCodes: reportSalesSubjects, noteCategory: reportMemoCategory })
                    }
                  >
                    <div style={{ width: '100%', borderLeft: 'solid 1px #CCCCCC', paddingLeft: 24, marginBlock: 9 }}>
                      <div css={operationInputContainer}>
                        <span css={operationInputLabel}>{t('Display items')}</span>
                        <SalesSubjectSelectBox
                          value={reportSalesSubjects}
                          onChange={setReportSalesSubjects}
                          history={getStoredSearchSubjectHistory(ReportTypes.dailyOperations)}
                        />
                      </div>
                      <div css={[operationInputContainer, { marginTop: 16, marginBottom: 10 }]}>
                        <span css={operationInputLabel}>{t('Output note')}</span>
                        <RadioGroupField
                          value={reportMemoCategory}
                          groupCss={{ display: 'flex', gap: 24, paddingLeft: 24 }}
                          style={{ radioSize: 16, labelMargin: 12 }}
                          itemCss={{ label: { color: '#676767 !important', fontSize: 12 } }}
                          items={[
                            { value: ReservationNoteCategory.Front, label: t(`reservationNote.${ReservationNoteCategory.Front}`) },
                            { value: ReservationNoteCategory.Cleaning, label: t(`reservationNote.${ReservationNoteCategory.Cleaning}`) },
                            { value: ReservationNoteCategory.Meal, label: t(`reservationNote.${ReservationNoteCategory.Meal}`) },
                          ]}
                          onChange={e => setReportMemoCategory(e.target.value)}
                        />
                      </div>
                    </div>
                  </ReportDownloadLink>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </>
    </DashboardPageLayout>
  )
}

const dateChangeContainer = css({ position: 'relative' })

const operationInputContainer = css({
  display: 'flex',
  alignItems: 'center',
})

const operationInputLabel = css({
  fontSize: 12,
  fontWeight: 'bold',
})
