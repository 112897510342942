import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { css } from '@emotion/core'
import { isEqual } from 'lodash'
import dayjs from 'dayjs'
import moment from 'moment'
import uuid from 'react-uuid'

// constants
import { wholeContainerStyle, mainColumnStyle, HEADER_HEIGHT } from '@/constants/layout'

// contexts
import { AccountContext } from '@/contexts/account'

// hooks
import { useEditGuestRooms } from '@/hooks/use-edit-guest-rooms'
import { useRoomsSelectableHasRoomManagerPlugin } from '@/hooks/use-rooms-selectable'

// components
import { Header } from '@/components/organisms/room-manager/header'
import { Button } from '@/components/atoms/button'
import { SideMenu } from '@/components/organisms/side-menu'
import { AssignPanel } from '@/components/pages/room-manager/_room-manager/assign-panel'
import { makeDates } from '@/components/pages/room-manager/_daily/make-dates'
import { DailyListHead } from '@/components/pages/room-manager/_daily/daily-list-head'
import { DailyListBody } from '@/components/pages/room-manager/_daily/daily-list-body'
import { LeftGuestRoom } from '@/components/pages/room-manager/_daily/left-guest-room'
import { LoadingFull } from '@/components/molecules/loading-full'
import { RoomSummaryBar } from '@/components/pages/room-manager/_room-manager/room-summary-bar'

// apis
import * as API from '@/apis/aipass'

// libs
import { setHasTlTwoWayPlugin, setHasRemoteLockPlugin } from '@/libs/plugins'
import { DateType } from '@/components/pages/room-manager/_daily/daily-props'
import { clickShowDetail } from '@/components/pages/room-manager/_daily/_body/click-show-detail'

// models
import { GuestRoomAssignStatusResponseType, StatusType } from '@/models/room-manager/response/guest-room-assign-status'
import { ApprovedStatus } from '@/models/reservation-approved-status'
import { AdminReservationResponseType } from '@/models/reservation/admin-reservation'
import { GuestRoomSelectableResponseType } from '@/models/room-manager/response/guest-room-selectable'
import { GuestRoomAssignResponseType } from '@/models/room-manager/response/guest-room-assign'
import { SiteControllerStockType, TotalRemainingCountEachDateType } from '@/models/room-manager/response/site-controller-stock'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useHotelGuestRooms } from '@/hooks/use-hotel-guest-rooms'
import { RemainingRoomTotalBody } from '@/components/organisms/room-manager/remaining-room-total-body'
import { ReservationCreateWithRoomModal } from '@/components/organisms/accommodation-management/reservation-create-with-room-modal'
import { useHistory } from 'react-router-dom'

export const RoomManagerDaily: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const windows = useWindowSize()
  const { errorHandler } = useErrorHandler()
  const { plugins, account } = useContext<any>(AccountContext)
  const params = require('query-string').parse(window.location.search)
  const reservationId: string | null = params.reservationId
  const guestRoomAssignId: string | null = params.guestRoomAssignId

  /**
   * Data for calendar tag display
   *
   * [Overview]
   * guestRooms:
   * Original data without date change
   * roomDatesChangedGuestRooms:
   * Update the date of each room in guestRooms when the check-in date and check-out date of each room are changed,
   * It is necessary to change the display of the calendar, but when the calendar is displayed, the original data of guestRooms is reacquired.
   * Since the data does not change, manage the date-changed guestRooms with roomDatesChangedGuestRooms
   */
  const [guestRooms, setGuestRooms] = useState<GuestRoomAssignStatusResponseType['guestRoomAssignStatusList']>()
  const [roomDatesChangedGuestRooms, setRoomDatesChangedGuestRooms] =
    useState<GuestRoomAssignStatusResponseType['guestRoomAssignStatusList']>()
  // Reservation information
  const [reservation, setReservation] = useState<AdminReservationResponseType['reservations']>()
  // Room list to edit
  const [editGuestRooms, setEditGuestRooms, handleFetchGuestRooms] = useEditGuestRooms()
  // List of selectable rooms
  const [roomsSelectable, handleFetchSelectableGuestRoom] = useRoomsSelectableHasRoomManagerPlugin()
  const [hotelGuestRooms, handleFetchGuestRoomForHotel] = useHotelGuestRooms()

  // Date management at the top of the calendar
  const [dates, setDates] = useState<DateType[]>([])
  // Date management at the top of the calendar
  const [roomManagerTopSelectDate, setRoomManagerTopSelectDate] = useState<moment.Moment>(moment())
  const today = dayjs().format('YYYY-MM-DD')
  const total = 10
  const start = -2
  const end = 7
  // Date management on the right side of the calendar
  const [beginDate, setBeginDate] = useState<string>(`${moment(roomManagerTopSelectDate).add(start, 'd').format('YYYY-MM-DD')}`)
  // Date management on the left side of the calendar
  const [endDate, setEndDate] = useState<string>(`${moment(roomManagerTopSelectDate).add(end, 'd').format('YYYY-MM-DD')}`)
  // IN/OUT check-in/check-out date
  const [reservationCheckinDate, setReservationCheckinDate] = useState<moment.Moment>(moment().hour(12).minute(0).second(0))
  const [reservationCheckoutDate, setReservationCheckoutDate] = useState<moment.Moment>(
    moment().add(1, 'days').hour(12).minute(0).second(0),
  )
  // For date management before changing the calendar (retaining reservationCheckinDate, reservationCheckoutDate before change)
  const [prevReservationCheckinDate, setPrevReservationCheckinDate] = useState<moment.Moment>(moment())
  const [prevReservationCheckoutDate, setPrevReservationCheckoutDate] = useState<moment.Moment>(moment().add(1, 'days'))
  // For creating a room in preparation
  const [preparation, setPreparation] = useState<boolean>(false)
  // for loading
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingReservationDetail, setIsLoadingReservationDetail] = useState<boolean>(false)
  // For handling when clicking the calendar room assignment
  const [isClickStatusBar, setIsClickStatusBar] = useState<boolean>(false)
  // For switch edit mode of assignPanel
  const [assignPanelEditMode, setAssignPanelEditMode] = useState<boolean>(false)
  // Flag for not scrolling when bar is clicked
  const [isBar, setIsBar] = useState<boolean>(false)

  // room name hidden list
  const [hiddenRoomNameList, setHiddenRoomNameList] = useState<string[]>([])
  // Site controller inventory management
  const [siteControllerStock, setSiteControllerStock] = useState<SiteControllerStockType | []>([])
  const [totalRemainingCountEachDate, setTotalRemainingCountEachDate] = useState<TotalRemainingCountEachDateType | {}>([])
  // Removed in-progress room
  const [deletedPreparationRooms, setDeletedPreparationRooms] = useState<GuestRoomAssignResponseType['rooms']>([])
  // For calendar room order, floor order change management
  const [order, setOrder] = useState<'room' | 'floor'>('room')
  // For changing the url when clicking the calendar room assignment
  const [changeUrl, setChangeUrl] = useState<string>('')
  // Currently selected room assignment Id
  const [roomAssignActiveId, setRoomAssignActiveId] = useState<string>('')

  // Tl2way plugin (reservation acquisition, inventory acquisition, inventory adjustment)
  const hasTlTwoWayPlugin = setHasTlTwoWayPlugin(plugins)
  // RemoteLock plugin (key management)
  const hasRemoteLockPlugin = setHasRemoteLockPlugin(plugins)
  const [showAssignPanel, setShowAssignPanel] = useState<boolean>(false)
  const [automaticAssignmentReservationsIds, setAutomaticAssignmentReservationsIds] = useState<string[] | undefined>()

  const [isReservationCreateModalOpen, setIsReservationCreateModalOpen] = useState<boolean>(false)

  /**
   * Reservation information acquisition handling
   */
  useEffect(() => {
    if (!account?.hotel?.id) return
    if (reservationId) {
      // ID set for tag display switching
      setRoomAssignActiveId(reservationId)
      // Get reservation information if you don't have it yet
      if (!reservation) {
        fetchReservationDetail(reservationId)
      }
      setIsClickStatusBar(false)
    } else {
      // Get Available Rooms
      fetchSelectableGuestRoom(moment(), moment(moment().add(1, 'days')))
    }
  }, [reservationId, account?.hotel?.id])

  /**
   * Preparing bar handling
   */
  useEffect(() => {
    if (guestRoomAssignId) {
      onClickPreparationRoom(guestRoomAssignId)
    }
  }, [guestRoomAssignId])

  /**
   * Get Site Controller Inventory
   */
  useEffect(() => {
    if (plugins) {
      fetchSiteControllerStock(moment(beginDate), moment(endDate))
    }
  }, [plugins])

  /**
   * upper calendar handling
   */
  useEffect(() => {
    const _beginDate = moment(roomManagerTopSelectDate).add(start, 'd').format('YYYY-MM-DD')
    const _endDate = moment(roomManagerTopSelectDate).add(end, 'd').format('YYYY-MM-DD')

    setDates(makeDates(roomManagerTopSelectDate, total, start))
    setBeginDate(_beginDate)
    setEndDate(_endDate)

    if (!isBar) {
      calendarScrollReset()
    }

    // Redraw the calendar if you change the date at the top of the calendar
    // eslint-disable-next-line no-extra-semi
    ;(async () => {
      setIsLoading(true)
      await API.fetchGuestRoomAssignStatus(order, _beginDate, _endDate)
        .then((res: GuestRoomAssignStatusResponseType) => {
          if (res?.guestRoomAssignStatusList) setGuestRooms(res.guestRoomAssignStatusList)
        })
        .finally(() => {
          finishLoading()
        })
      if (plugins) {
        await fetchSiteControllerStock(moment(_beginDate), moment(_endDate))
      }
    })()
  }, [roomManagerTopSelectDate])

  // For handling IN and OUT of the assign panel
  useEffect(() => {
    // Change the currently assigned room number to "Select room number" when changing the calendar date
    // Reset the room number and change the assigned date if the accommodation date is changed other than when the bar is clicked
    if (!isClickStatusBar) {
      for (const room of editGuestRooms) {
        room.roomNumber = '部屋番号を選択'
        room.guestRoomId = ''

        if (
          moment(room.assignCheckinDate).format('YYYY-MM-DD') === moment(prevReservationCheckinDate).format('YYYY-MM-DD') ||
          !moment(room.assignCheckinDate).isBetween(reservationCheckinDate, reservationCheckoutDate) ||
          !room.assignCheckinDate
        ) {
          room.assignCheckinDate = moment(reservationCheckinDate).format('YYYY-MM-DD 12:00:00')
        }

        if (
          moment(room.assignCheckoutDate).format('YYYY-MM-DD') === moment(prevReservationCheckoutDate).format('YYYY-MM-DD') ||
          !moment(room.assignCheckoutDate).isBetween(reservationCheckinDate, reservationCheckoutDate) ||
          !room.assignCheckoutDate
        ) {
          room.assignCheckoutDate = moment(reservationCheckoutDate).format('YYYY-MM-DD 12:00:00')
        }
      }
    }

    setPrevReservationCheckinDate(reservationCheckinDate)
    setPrevReservationCheckoutDate(reservationCheckoutDate)
    setIsClickStatusBar(false)

    // Initialization process for each statuses
    initializeGuestRoomsStatuses()
  }, [reservationCheckinDate, reservationCheckoutDate])

  /**
   * ================================================================
   * Data acquisition related
   * ================================================================
   */

  /**
   * Get calendar assignment data
   */
  const fetchGuestRoomAssignStatus = async (
    selectOrder?: 'room' | 'floor',
    guestRoom?: GuestRoomAssignStatusResponseType['guestRoomAssignStatusList'],
  ) => {
    const formatBeginDate = `${beginDate}T00:00:00Z`
    const formatEndDate = `${endDate}T23:59:59Z`
    const paramsOrder = selectOrder ? selectOrder : order
    setIsLoading(true)
    await API.fetchGuestRoomAssignStatus(paramsOrder, formatBeginDate, formatEndDate)
      .then((res: GuestRoomAssignStatusResponseType) => {
        // Display tag if room number is included in assignPanel
        if (editGuestRooms.length !== 0 && editGuestRooms[0].guestRoomId) {
          if (guestRoom) {
            mergeEditGuestRooms(guestRoom)
          } else if (roomDatesChangedGuestRooms) {
            mergeEditGuestRooms(roomDatesChangedGuestRooms)
          } else if (res?.guestRoomAssignStatusList) {
            mergeEditGuestRooms(res.guestRoomAssignStatusList)
          }
        } else {
          if (res?.guestRoomAssignStatusList) setGuestRooms(res.guestRoomAssignStatusList)
        }
      })
      .finally(() => {
        finishLoading()
      })
  }

  /**
   * Reservation information acquisition
   */
  const fetchReservationDetail = async (reservationId: string) => {
    try {
      setIsLoading(true)
      setIsLoadingReservationDetail(true)

      const res = await API.fetchReservationDetail({ reservationId })

      if (!res) return console.warn('fetchReservation return empty response')

      const reservation: AdminReservationResponseType['reservations'] = res.reservations

      setReservation(reservation)
      setReservationCheckinDate(moment(reservation.checkinDate))
      setRoomManagerTopSelectDate(moment(reservation.checkinDate))
      setPrevReservationCheckinDate(moment(reservation.checkinDate))
      setPrevReservationCheckoutDate(moment(reservation.checkoutDate))
      // If there is no check-out date, set the day after the check-in date
      const checkoutDate = reservation.checkoutDate ? moment(reservation.checkoutDate) : moment(reservation.checkinDate).add(1, 'days')
      setReservationCheckoutDate(checkoutDate)
      const editRooms = await handleFetchGuestRooms(reservationId, account?.hotel?.id)

      if (editRooms.length) {
        await handleManyGuestRoomsFetch(editRooms)
      } else {
        // Set initial value for adding rooms if editGuestRooms is empty (no rooms assigned)
        const initialEditRoom = {
          roomTypeId: null,
          typeNameJa: null,
          typeNameEn: null,
          guestRoomId: null,
          roomNumber: null,
          assignCheckinDate: moment(reservation.checkinDate).format('YYYY-MM-DD HH:mm:ss'),
          assignCheckoutDate: moment(checkoutDate).format('YYYY-MM-DD HH:mm:ss'),
          editingId: `${uuid()}`,
        }
        setEditGuestRooms([initialEditRoom])
        // Acquired during the stay period of the reservation
        await fetchSelectableGuestRoom(moment(reservation.checkinDate), moment(reservation.checkoutDate), [initialEditRoom])
      }
    } finally {
      setIsLoading(false)
      setIsLoadingReservationDetail(false)
    }
  }

  const finishLoading = () => {
    if (!isLoadingReservationDetail) setIsLoading(false)
  }

  const handleManyGuestRoomsFetch = async editRooms => {
    let lastFetchSelectableGuestRoomParams: any = undefined
    let lastEditGuestRooms = editRooms

    for (const [index, room] of (editRooms || []).entries()) {
      const targetRoomTypeName = leastRoomTypeIdTypeNameEditRooms(editRooms)

      if (targetRoomTypeName) calendarScrollRoomTypePosition(targetRoomTypeName)

      const beginDate = moment(room.assignCheckinDate)
      const endDate = moment(room.assignCheckoutDate)

      const _beginDate = beginDate.format('YYYY-MM-DD')
      // Set the next day after the check-in date if the check-out date is not set
      const _endDate = endDate.isValid() ? endDate?.format('YYYY-MM-DD') : beginDate.add(1, 'days').format('YYYY-MM-DD')

      // Only the selected room assignment can be assigned again in the same period

      const currentFetchSelectableGuestRoomParams = [_beginDate, _endDate, reservationId ? reservationId : undefined]

      if (isEqual(lastFetchSelectableGuestRoomParams, currentFetchSelectableGuestRoomParams)) continue

      const guestRooms = await handleFetchSelectableGuestRoom(_beginDate, _endDate, reservationId ? reservationId : undefined)
      const currentEditGuestRooms = updateEditGuestRoomsSelectableRooms(guestRooms, editRooms, index, true)

      if (!isEqual(lastEditGuestRooms, currentEditGuestRooms)) setEditGuestRooms(lastEditGuestRooms)

      lastFetchSelectableGuestRoomParams = currentFetchSelectableGuestRoomParams
      lastEditGuestRooms = currentEditGuestRooms
    }
  }

  /**
   * Get Available Rooms
   */
  const fetchSelectableGuestRoom = async (
    beginDate: moment.Moment,
    endDate: moment.Moment,
    editRooms?: GuestRoomAssignResponseType['rooms'],
    roomIndex?: number,
  ) => {
    try {
      const _beginDate = beginDate.format('YYYY-MM-DD')
      // Set the next day after the check-in date if the check-out date is not set
      const _endDate = endDate.isValid() ? endDate?.format('YYYY-MM-DD') : beginDate.add(1, 'days').format('YYYY-MM-DD')
      setIsLoading(true)
      // Only the selected room assignment can be assigned again in the same period

      const guestRooms = await handleFetchSelectableGuestRoom(_beginDate, _endDate, reservationId ? reservationId : undefined)
      updateEditGuestRoomsSelectableRooms(guestRooms, editRooms, roomIndex)
    } finally {
      finishLoading()
    }
  }

  /**
   * Recreate Assignments panel data
   */
  const reCreateAssignPanelDetail = async (reservationId: string) => {
    await fetchReservationDetail(reservationId)
    setRoomDatesChangedGuestRooms(undefined)
    setAssignPanelEditMode(false)
  }

  /**
   * Site Controller Inventory Acquisition
   */
  const fetchSiteControllerStock = async (beginDate: moment.Moment, endDate: moment.Moment) => {
    // const yesterday = moment().add(-1, 'day')
    // beginDateが昨日より前なら昨日を入れる
    const _beginDate = moment(beginDate)
    const _endDate = moment(endDate)

    if (order === 'room') {
      await fetchStock(moment(_beginDate).format('YYYY-MM-DD'), moment(_endDate).format('YYYY-MM-DD'))
    }
  }

  const fetchStock = async (beginDate: string, endDate: string) => {
    try {
      const res = await API.fetchAdminReservationStock(beginDate, endDate)

      //   const newStockResultList = formatStockResultList(res.stockResultList)
      setSiteControllerStock(res.stocks)
      setTotalRemainingCountEachDate(res.totalRemainingCountEachDate)
    } catch (error) {
      errorHandler(error)
    }
  }

  /**
   * ================================================================
   * Handling related
   * ================================================================
   */

  /**
   * What to do when clicking the ready checkbox
   */
  const changePreparation = (preparation: boolean) => {
    /**
     * When the check box under preparation is activated Extract the difference of each room and set it to the delete room under preparation array
     * When the check box under preparation is deactivated, the one with the current editGuestRooms assignment to the preparing room deletion array
     *
     * The check box in preparation is only available when creating a new assignment or editing in preparation
     * The guestRoomAssignId in editGuestRooms will be the guestRoomAssignId in preparation
     */
    if (preparation) {
      const _editGuestRooms: typeof editGuestRooms = []
      const combinedRooms = [
        ...editGuestRooms.filter(room => room.guestRoomAssignId),
        ...deletedPreparationRooms.filter(room => room.guestRoomAssignId),
      ]
      combinedRooms.filter(function (val, i, self) {
        return i === self.indexOf(val)
      })

      // set for delete
      combinedRooms.forEach(room => {
        _editGuestRooms.push({
          guestRoomId: null,
          roomTypeId: null,
          typeNameEn: null,
          roomNumber: null,
          typeNameJa: null,
          assignCheckinDate: null,
          assignCheckoutDate: null,
          guestRoomAssignId: room.guestRoomAssignId,
          editingId: room.editingId,
        })
        // room.guestRoomAssignId here is the guestRoomAssignId in preparation, so delete it.
        room.guestRoomAssignId = null
      })

      setDeletedPreparationRooms(_editGuestRooms)
    } else {
      if (editGuestRooms.length) {
        const _editGuestRooms: typeof editGuestRooms = []
        editGuestRooms.forEach(room => {
          if (room.guestRoomAssignId) {
            _editGuestRooms.push({
              guestRoomId: null,
              roomTypeId: null,
              typeNameEn: null,
              roomNumber: null,
              typeNameJa: null,
              assignCheckinDate: null,
              assignCheckoutDate: null,
              guestRoomAssignId: room.guestRoomAssignId,
              editingId: room.editingId,
            })
            // room.guestRoomAssignId here is the guestRoomAssignId in preparation, so delete it.
            room.guestRoomAssignId = null
          }
        })
        setDeletedPreparationRooms(_editGuestRooms)
      }
    }

    /**
     * Update the assignStatus of the displayed tag to be assigned and change the color
     */
    let _guestRoom: GuestRoomAssignStatusResponseType['guestRoomAssignStatusList']
    if (roomDatesChangedGuestRooms) {
      _guestRoom = { ...roomDatesChangedGuestRooms }
    } else {
      _guestRoom = { ...guestRooms }
    }
    Object.keys(_guestRoom).forEach(floor => {
      Object.keys(_guestRoom[floor]).forEach(guestRoomNumber => {
        _guestRoom[floor][guestRoomNumber].statuses.forEach(status => {
          if (status.assignStatus) {
            status.assignStatus = preparation ? 'preparation' : ApprovedStatus.Reservation
          }
        })
      })
    })
    setPreparation(preparation)
    setAssignPanelEditMode(true)
    setGuestRooms(_guestRoom)
    if (roomDatesChangedGuestRooms) {
      setRoomDatesChangedGuestRooms(_guestRoom)
    }
  }

  /**
   * For switching the display of the sidebar
   */
  const changeHiddenRoomNameList = (type: 'add' | 'delete', labelName?: string) => {
    if (!guestRooms) {
      return
    }

    // Add new
    if (type === 'add') {
      if (labelName) {
        // If there are room types add them to the array
        setHiddenRoomNameList(hiddenRoomNameList.concat(labelName))
      } else {
        // If there is no room type, add all guestRooms floor names and room type names to the array
        const _roomNameList: string[] = []
        Object.keys(guestRooms).forEach(floorName => {
          Object.keys(guestRooms[floorName]).forEach(guestRoomNumber => {
            _roomNameList.push(guestRooms[floorName][guestRoomNumber].roomType)
          })
        })

        setHiddenRoomNameList(hiddenRoomNameList.concat(_roomNameList))
      }
    }
    if (type === 'delete') {
      if (labelName) {
        // If there is a room type, remove it from the array
        setHiddenRoomNameList(hiddenRoomNameList.filter(name => name !== labelName))
      } else {
        // Initialize if there is no room type
        setHiddenRoomNameList([])
      }
    }
  }

  /**
   * What happens when you click on the ready bar
   */
  const onClickPreparationRoom = async (guestRoomAssignId: string) => {
    if (!guestRooms) {
      return
    }

    // Delete current reservation information
    setReservation(undefined)
    const preparationRoom: GuestRoomAssignResponseType['rooms'][0] = {
      roomTypeId: null,
      typeNameJa: null,
      typeNameEn: null,
      roomNumber: null,
      guestRoomId: null,
    }

    Object.keys(guestRooms).forEach(floor => {
      // Retrieve guestRooms room number using room type (or floor number)
      Object.keys(guestRooms[floor]).forEach(guestRoomNumber => {
        // Determining the room number tag currently assigned (to return to the initial state of data acquisition)
        guestRooms[floor][guestRoomNumber].statuses.some(status => {
          if (status.guestRoomAssignId === guestRoomAssignId) {
            preparationRoom.typeNameJa = guestRooms[floor][guestRoomNumber].roomType
            preparationRoom.typeNameEn = guestRooms[floor][guestRoomNumber].roomType
            preparationRoom.roomNumber = guestRoomNumber
            preparationRoom.guestRoomAssignId = status.guestRoomAssignId
            preparationRoom.assignCheckinDate = status.assignCheckinDate
            preparationRoom.assignCheckoutDate = status.assignCheckoutDate
            if (status.guestRoomId) {
              preparationRoom.guestRoomId = status.guestRoomId
            }
            return true
          }
        })
      })
    })
    // Bar activation
    setPreparation(true)
    setRoomAssignActiveId(guestRoomAssignId)
    setReservationCheckinDate(moment(preparationRoom.assignCheckinDate))
    setReservationCheckoutDate(moment(preparationRoom.assignCheckoutDate))
    // Add target rooms in preparation to editGuestRooms (remove other rooms)
    setEditGuestRooms([preparationRoom])
    fetchSelectableGuestRoom(moment(preparationRoom.assignCheckinDate), moment(preparationRoom.assignCheckoutDate), [preparationRoom])
  }

  /**
   * ================================================================
   * helper
   * ================================================================
   */

  /**
   * scroll back to the left edge
   */
  const calendarScrollReset = () => {
    const container = document.getElementById('roomsContainer')
    container!.scrollTo({
      left: 0,
    })
  }

  /**
   * Scroll the calendar vertically to the desired room type
   */
  const calendarScrollRoomTypePosition = (roomType: string) => {
    // the element of the whole calendar
    const dailyContainer = document.getElementById('roomsContainer') as HTMLDivElement | null
    // calendar top element
    const daysListHeader = document.getElementById('daysListHeader') as HTMLDivElement | null
    const targetDailyList = document.getElementById(`dailyList_${roomType}`) as HTMLDivElement | null
    if (dailyContainer && daysListHeader && targetDailyList) {
      targetDailyList.scrollIntoView()
      dailyContainer.scrollTo(0, dailyContainer.scrollTop - daysListHeader.offsetHeight)
    }
  }

  /**
   * Returns the room type with the lowest roomTypeId of the assigned rooms
   */
  const leastRoomTypeIdTypeNameEditRooms = (rooms: typeof editGuestRooms) => {
    if (rooms.length) {
      let roomTypeIds = rooms.map(room => Number(room.roomTypeId!))
      // Remove duplicates
      roomTypeIds = roomTypeIds.filter((value, index, self) => self.indexOf(value) === index)
      // Get minimum room type ID
      const leastRoomTypeId = Math.min(...roomTypeIds)
      // Get room type with minimum value from rooms
      const leastRoomTypeRoom = rooms.find(room => Number(room.roomTypeId!) === leastRoomTypeId)

      return leastRoomTypeRoom!.typeNameJa
    } else {
      return null
    }
  }

  /**
   * Initialize (delete) the place where the current tag of guestRooms is displayed
   */
  const initializeGuestRoomsStatuses = async (fllInitialize?: boolean) => {
    // Refetch data and fully initialize guestRooms statuses
    if (fllInitialize) {
      const formatBeginDate = `${beginDate}T00:00:00Z`
      const formatEndDate = `${endDate}T23:59:59Z`
      await API.fetchGuestRoomAssignStatus(order, formatBeginDate, formatEndDate)
        .then((res: GuestRoomAssignStatusResponseType) => {
          if (res?.guestRoomAssignStatusList) setGuestRooms(res.guestRoomAssignStatusList)
          setRoomDatesChangedGuestRooms(undefined)
        })
        .finally(() => {
          finishLoading()
        })
      return
    }

    let _guestRooms: GuestRoomAssignStatusResponseType['guestRoomAssignStatusList']
    if (roomDatesChangedGuestRooms) {
      _guestRooms = { ...roomDatesChangedGuestRooms }
    } else {
      _guestRooms = { ...guestRooms }
    }
    Object.keys(_guestRooms).forEach(floor => {
      // Retrieve guestRooms room number using room type (or floor number)
      Object.keys(_guestRooms[floor]).forEach(guestRoomNumber => {
        // Determining the room number tag currently assigned (to return to the initial state of data acquisition)
        const assignInitialStatuses = _guestRooms[floor][guestRoomNumber].statuses.filter(
          status =>
            Object.prototype.hasOwnProperty.call(status, 'guestRoomAssignId') ||
            Object.prototype.hasOwnProperty.call(status, 'guestRoomId'),
        )
        if (assignInitialStatuses.length !== 0) {
          _guestRooms[floor][guestRoomNumber].statuses = assignInitialStatuses
        } else {
          // Initialize statuses if not the currently assigned room number tag
          _guestRooms[floor][guestRoomNumber].statuses = []
        }
      })
    })
    setGuestRooms(_guestRooms)
    setPrevReservationCheckinDate(reservationCheckinDate)
    setPrevReservationCheckoutDate(reservationCheckoutDate)
    setReservationCheckinDate(reservationCheckinDate)
    setReservationCheckoutDate(reservationCheckoutDate)
  }

  /**
   * eset/update selectableRooms in ditGuestRooms
   */
  const updateEditGuestRoomsSelectableRooms = (
    selectableRooms: GuestRoomSelectableResponseType['guestRooms'],
    editRooms?: GuestRoomAssignResponseType['rooms'],
    roomIndex?: number,
    returnValue?: boolean,
  ) => {
    /**
     * If there is roomIndex, specify the target Index of editGuestRooms and update guestRooms
     * If there is no roomIndex, it is the first time or the accommodation date of the reservation is changed, so editRooms Initialize all guestRooms
     */
    let _editGuestRooms: GuestRoomAssignResponseType['rooms']
    if (editRooms) {
      _editGuestRooms = [...editRooms]
    } else {
      _editGuestRooms = [...editGuestRooms]
    }

    // Filter by assignCheckinDate to avoid adding a room when a reserved room is deleted
    _editGuestRooms = _editGuestRooms.filter(room => !!room.assignCheckinDate)
    if (roomIndex) {
      if (_editGuestRooms?.[roomIndex]) _editGuestRooms[roomIndex].selectableRooms = selectableRooms
    } else {
      _editGuestRooms.forEach(room => {
        room.selectableRooms = selectableRooms
      })
    }

    if (returnValue) return _editGuestRooms

    setEditGuestRooms(_editGuestRooms)
  }

  const filterOldEditingRooms = (
    showRooms: GuestRoomAssignStatusResponseType['guestRoomAssignStatusList'],
    editingRooms: GuestRoomAssignResponseType['rooms'],
  ) => {
    const editingIds = editGuestRooms.map(editRoom => editRoom.editingId).filter(editingId => !!editingId)
    Object.keys(showRooms).forEach(floor => {
      Object.keys(showRooms[floor]).forEach(roomNumber => {
        showRooms[floor][roomNumber].statuses = showRooms[floor][roomNumber].statuses.filter(
          room => !(room.editingId && editingIds.includes(room.editingId)),
        )
      })
    })
  }

  /**
   * Edit guestRoom to show selected tags
   * If roomDatesChangedGuestRooms exists (if room dates have changed) guestRoomData is passed roomDatesChangedGuestRooms
   */
  const mergeEditGuestRooms = (guestRoomData: GuestRoomAssignStatusResponseType['guestRoomAssignStatusList']) => {
    /**
     * TODO: Fixed here so that tags under preparation can also be displayed
     * Reservation, check-in, and check-out seem to work as they are
     */
    const newGuestRooms = { ...guestRoomData }

    filterOldEditingRooms(newGuestRooms, editGuestRooms)

    // Get the room type (or floor) from guestRooms
    Object.keys(newGuestRooms).forEach(floor => {
      /**
       * If the check-in/check-out of each room is changed, the data management of guestRooms is changed to roomDatesChangedGuestRooms
       * Therefore, even if the data is reacquired, the tag will not be updated, so it is necessary to delete the tag of the room number that is not in editGuestRooms.
       */
      if (roomDatesChangedGuestRooms) {
        const roomsNumbers = editGuestRooms.map(room => room.roomNumber)
        // Extract room numbers from newGuestRooms that do not match the room numbers in the currently selected editGuestRooms
        const changeRoomNumberRooms: string[] = Object.keys(newGuestRooms[floor]).filter((guestRoomNumber: string) => {
          return !roomsNumbers.includes(guestRoomNumber)
        })
        if (changeRoomNumberRooms.length !== 0) {
          // Remove tags with mismatched room numbers, but leave tags with currently assigned room numbers
          changeRoomNumberRooms.forEach(changeRoomNumber => {
            // Determining the room number tag currently assigned (to return to the initial state of data acquisition)
            const assignInitialStatuses = newGuestRooms[floor][changeRoomNumber].statuses.filter(
              status =>
                Object.prototype.hasOwnProperty.call(status, 'guestRoomAssignId') ||
                Object.prototype.hasOwnProperty.call(status, 'guestRoomId'),
            )
            if (assignInitialStatuses.length !== 0) {
              roomDatesChangedGuestRooms[floor][changeRoomNumber].statuses = assignInitialStatuses
            } else {
              // Initialize statuses if not the currently assigned room number tag
              newGuestRooms[floor][changeRoomNumber].statuses = []
            }
          })
        }
      }

      Object.keys(newGuestRooms[floor]).forEach(guestRoomNumber => {
        if (guestRoomNumber === '指定なし') return
        // Is the room number selected in assignPanel?
        const selectedRoomNumberRooms: any[] = editGuestRooms.filter(
          editGuestRoom => editGuestRoom.roomNumber === newGuestRooms[floor][guestRoomNumber].roomNumber,
        )
        /**
         * Display tags by inserting assignStatus at the beginning of the array (inserted at the beginning so that they are at the bottom when overlapping)
         */
        // Room assignment from customer management
        if (selectedRoomNumberRooms.length && reservation && String(reservation.approvedStatus)) {
          selectedRoomNumberRooms.forEach(selectedRoomNumberRoom => {
            // Create an array for judgment so as not to add if there is already assignStatus
            const existsAlreadyStatuses = newGuestRooms[floor][guestRoomNumber].statuses.filter(status => {
              return status.assignStatus === String(reservation.approvedStatus) || status.assignStatus === 'preparation'
            })
            if (!existsAlreadyStatuses.length) {
              const statusesAssignStatus = reservation.approvedStatus ? String(reservation.approvedStatus) : null
              newGuestRooms[floor][guestRoomNumber].statuses.unshift({
                assignStatus: preparation ? 'preparation' : statusesAssignStatus,
                assignCheckinDate: moment(selectedRoomNumberRoom.assignCheckinDate).format('YYYY-MM-DD HH:mm:ss'),
                assignCheckinDays: moment(selectedRoomNumberRoom.assignCheckinDate).format('YYYY-MM-DD'),
                changeAssignCheckinDate: moment(selectedRoomNumberRoom.assignCheckinDate).format('YYYY-MM-DD HH:mm:ss'),
                changeAssignCheckinDays: moment(selectedRoomNumberRoom.assignCheckinDate).format('YYYY-MM-DD'),
                assignCheckoutDate: moment(selectedRoomNumberRoom.assignCheckoutDate).format('YYYY-MM-DD HH:mm:ss'),
                assignCheckoutDays: moment(selectedRoomNumberRoom.assignCheckoutDate).format('YYYY-MM-DD'),
                changeAssignCheckoutDate: moment(selectedRoomNumberRoom.assignCheckoutDate).format('YYYY-MM-DD HH:mm:ss'),
                changeAssignCheckoutDays: moment(selectedRoomNumberRoom.assignCheckoutDate).format('YYYY-MM-DD'),
                editingId: selectedRoomNumberRoom.editingId,
              })
            }
          })
        }
        // New reservation room assignment or preparation assignment
        if (selectedRoomNumberRooms.length && !reservation) {
          selectedRoomNumberRooms.forEach(selectedRoomNumberRoom => {
            // Create an array for judgment so as not to add if there is already assignStatus
            const existsAlreadyStatuses = newGuestRooms[floor][guestRoomNumber].statuses.filter(status => {
              return (
                (status.assignStatus === 'preparation' || status.assignStatus === ApprovedStatus.Reservation) &&
                status.assignCheckinDate === selectedRoomNumberRoom.assignCheckinDate &&
                status.assignCheckoutDate === selectedRoomNumberRoom.assignCheckoutDate
              )
            })
            if (!existsAlreadyStatuses.length) {
              newGuestRooms[floor][guestRoomNumber].statuses.unshift({
                assignStatus: preparation ? 'preparation' : ApprovedStatus.Reservation,
                assignCheckinDate: moment(selectedRoomNumberRoom.assignCheckinDate).format('YYYY-MM-DD HH:mm:ss'),
                assignCheckinDays: moment(selectedRoomNumberRoom.assignCheckinDate).format('YYYY-MM-DD'),
                changeAssignCheckinDate: moment(selectedRoomNumberRoom.assignCheckinDate).format('YYYY-MM-DD HH:mm:ss'),
                changeAssignCheckinDays: moment(selectedRoomNumberRoom.assignCheckinDate).format('YYYY-MM-DD'),
                assignCheckoutDate: moment(selectedRoomNumberRoom.assignCheckoutDate).format('YYYY-MM-DD HH:mm:ss'),
                assignCheckoutDays: moment(selectedRoomNumberRoom.assignCheckoutDate).format('YYYY-MM-DD'),
                changeAssignCheckoutDate: moment(selectedRoomNumberRoom.assignCheckoutDate).format('YYYY-MM-DD HH:mm:ss'),
                changeAssignCheckoutDays: moment(selectedRoomNumberRoom.assignCheckoutDate).format('YYYY-MM-DD'),
                editingId: selectedRoomNumberRoom.editingId,
              })
            }
          })
        }

        // Add room type to each tag
        newGuestRooms[floor][guestRoomNumber].statuses.forEach(status => {
          if (status.guestRoomAssignId) {
            status.roomType = newGuestRooms[floor][guestRoomNumber].roomType
          }
        })
      })
    })

    if (!isEqual(newGuestRooms, guestRooms)) setGuestRooms(newGuestRooms)
    if (roomDatesChangedGuestRooms) {
      setRoomDatesChangedGuestRooms(newGuestRooms)
    }
  }

  /**
   * Assignment cancellation processing
   */
  const clearRoomAssign = () => {
    window.history.pushState('', '', '/room-manager/daily')
    // Initialize State for each room assignment
    setRoomAssignActiveId('')
    setReservationCheckinDate(moment())
    setReservationCheckoutDate(moment().add(1, 'days'))
    setEditGuestRooms([])
    setReservation(undefined)
    setShowAssignPanel(false)
    setRoomManagerTopSelectDate(moment())

    setChangeUrl(window.location.href)
  }

  const onClickUnattachedStatus = (statusData: StatusType) => {
    const params = require('query-string').parse(window.location.search)
    if (statusData.reservationId === params.reservationId || statusData.guestRoomAssignId === params.guestRoomAssignId) {
      return clearRoomAssign()
    }

    clickShowDetail(statusData)
    setIsClickStatusBar(true)
    setChangeUrl(window.location.href)
    setIsBar(true)
  }

  const clickToFreeDate = ({ date, group, roomNumber }: { date: any; group: string; roomNumber: string }) => {
    if (!date.days || !guestRooms || !guestRooms[group][roomNumber]) return

    let guestRoomId
    let roomTypeId

    for (const roomType of hotelGuestRooms) {
      for (const floor in roomType.roomNumber) {
        guestRoomId = roomType.roomNumber[floor].find(room => room.roomNumber === roomNumber)?.guestRoomId

        if (guestRoomId) break
      }

      if (guestRoomId) {
        roomTypeId = roomType.roomTypeId
        break
      }
    }

    if (!guestRoomId) return alert('Unknown guest room')

    const checkinDate = moment(date.days).hour(12).minute(0).second(0)
    const checkoutDate = moment(date.days).add(1, 'days').hour(12).minute(0).second(0)
    const _editGuestRooms = [...editGuestRooms]

    let selectRoomType: string
    if (order === 'floor') {
      selectRoomType =
        hotelGuestRooms.find(room => (room.roomNumber[group] || []).map(info => info.roomNumber).includes(roomNumber))?.typeNameJa || ''
    } else {
      selectRoomType = guestRooms[group][roomNumber].roomType
    }

    _editGuestRooms.push({
      roomTypeId,
      typeNameJa: selectRoomType,
      typeNameEn: selectRoomType,
      guestRoomId,
      roomNumber,
      assignCheckinDate: moment(checkinDate).format('YYYY-MM-DD HH:mm:ss'),
      assignCheckoutDate: moment(checkoutDate).format('YYYY-MM-DD HH:mm:ss'),
      editingId: `${uuid()}`,
    })

    const addedIndex = _editGuestRooms.length - 1
    fetchSelectableGuestRoom(checkinDate, checkoutDate, _editGuestRooms, addedIndex)

    let minCheckinDate, maxCheckoutDate

    for (const editGuestRoom of _editGuestRooms) {
      if (!minCheckinDate || moment(minCheckinDate).isAfter(editGuestRoom.assignCheckinDate)) {
        minCheckinDate = moment(editGuestRoom.assignCheckinDate)
      }
      if (!maxCheckoutDate || moment(maxCheckoutDate).isBefore(editGuestRoom.assignCheckoutDate)) {
        maxCheckoutDate = moment(editGuestRoom.assignCheckoutDate)
      }
    }

    setReservationCheckinDate(minCheckinDate)
    setReservationCheckoutDate(maxCheckoutDate)

    setEditGuestRooms(_editGuestRooms)
    setAssignPanelEditMode(true)
    setShowAssignPanel(true)
    setIsClickStatusBar(true)
  }

  const makeAutomaticAssignment = async () => {
    try {
      setIsLoading(true)

      const res = await API.guestRoomAutoAssign({ reservationIds: automaticAssignmentReservationsIds, pageType: 'room_management' })

      if (res?.errors) errorHandler({ response: { data: res } })

      setAutomaticAssignmentReservationsIds(undefined)
      fetchGuestRoomAssignStatus(order)
      if (reservation) fetchReservationDetail(reservation.reservationId)
    } catch (error) {
      errorHandler(error)
    } finally {
      finishLoading()
    }
  }

  useEffect(() => {
    fetchGuestRoomAssignStatus(order, guestRooms)
  }, [editGuestRooms])

  useEffect(() => {
    if (reservation || guestRoomAssignId) setShowAssignPanel(true)
    else setShowAssignPanel(false)
  }, [reservation, guestRoomAssignId])

  useEffect(() => {
    if (!reservation && !guestRoomAssignId && !editGuestRooms.length) setShowAssignPanel(false)
  }, [editGuestRooms])

  useEffect(() => {
    handleFetchGuestRoomForHotel()
  }, [])

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header />
        <div css={roomContainerStyle}>
          <div css={mainWrapperStyle}>
            <div id="roomsContainer" css={roomListContainerStyle} style={{ maxHeight: windows.height - HEADER_HEIGHT - 48 }}>
              <LeftGuestRoom
                guestRooms={guestRooms}
                order={order}
                setOrder={setOrder}
                hiddenRoomNameList={hiddenRoomNameList}
                changeHiddenRoomNameList={changeHiddenRoomNameList}
                fetchGuestRoomAssignStatus={fetchGuestRoomAssignStatus}
                onClickNewReservation={() => setIsReservationCreateModalOpen(true)}
              />
              <div css={dayListWrapperStyle}>
                <ul id="dayList" css={dayListSectionStyle}>
                  <DailyListHead today={today} dates={dates} />
                  <RemainingRoomTotalBody dates={dates} totalRemainingCountEachDate={totalRemainingCountEachDate}></RemainingRoomTotalBody>
                  <DailyListBody
                    guestRooms={guestRooms}
                    today={today}
                    dates={dates}
                    editGuestRooms={editGuestRooms}
                    setChangeUrl={setChangeUrl}
                    roomAssignActiveId={roomAssignActiveId}
                    beginDate={beginDate}
                    endDate={endDate}
                    setIsBar={setIsBar}
                    setIsLoading={setIsLoading}
                    hiddenRoomNameList={hiddenRoomNameList}
                    setIsClickStatusBar={setIsClickStatusBar}
                    siteControllerStock={siteControllerStock}
                    clearRoomAssign={clearRoomAssign}
                    clickToFreeDate={clickToFreeDate}
                  />
                </ul>
              </div>
            </div>
          </div>
          <RoomSummaryBar
            reservation={reservation}
            roomManagerTopSelectDate={roomManagerTopSelectDate}
            setRoomManagerTopSelectDate={setRoomManagerTopSelectDate}
            guestRooms={guestRooms}
            editGuestRooms={editGuestRooms}
            order={order}
            hiddenRoomNameList={hiddenRoomNameList}
            showAssignPanel={showAssignPanel}
            onClickUnattachedStatus={onClickUnattachedStatus}
            assignPanelEditMode={assignPanelEditMode}
            setAutomaticAssignmentReservationsIds={setAutomaticAssignmentReservationsIds}
            assignPanel={() => (
              <AssignPanel
                reservation={reservation}
                reCreateAssignPanelDetail={reCreateAssignPanelDetail}
                reservationCheckinDate={reservationCheckinDate}
                setReservationCheckinDate={setReservationCheckinDate}
                reservationCheckoutDate={reservationCheckoutDate}
                setReservationCheckoutDate={setReservationCheckoutDate}
                editGuestRooms={editGuestRooms}
                setEditGuestRooms={setEditGuestRooms}
                guestRooms={guestRooms}
                setRoomDatesChangedGuestRooms={setRoomDatesChangedGuestRooms}
                setIsLoading={setIsLoading}
                changeUrl={changeUrl}
                setRoomAssignActiveId={setRoomAssignActiveId}
                hasTlTwoWayPlugin={hasTlTwoWayPlugin}
                hasRemoteLockPlugin={hasRemoteLockPlugin}
                setRoomManagerTopSelectDate={setRoomManagerTopSelectDate}
                setIsBar={setIsBar}
                preparation={preparation}
                setPreparation={setPreparation}
                order={order}
                changePreparation={changePreparation}
                assignPanelEditMode={assignPanelEditMode}
                setAssignPanelEditMode={setAssignPanelEditMode}
                fetchSelectableGuestRoom={fetchSelectableGuestRoom}
                fetchGuestRoomAssignStatus={fetchGuestRoomAssignStatus}
                initializeGuestRoomsStatuses={initializeGuestRoomsStatuses}
                deletedPreparationRooms={deletedPreparationRooms}
                setDeletedPreparationRooms={setDeletedPreparationRooms}
                calendarScrollRoomTypePosition={calendarScrollRoomTypePosition}
                clearRoomAssign={clearRoomAssign}
              />
            )}
          />
        </div>
        {automaticAssignmentReservationsIds?.length && (
          <div
            css={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.5)',
              zIndex: 10,
            }}
          >
            <div css={{ backgroundColor: '#fff', padding: 32, borderRadius: 8 }}>
              <div css={{ fontWeight: 'bold', marginBottom: 32, fontSize: 18 }}>{t('Are you sure you want to assign?')}</div>
              <div css={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <Button width={116} height={36} buttonType={3} onClick={() => setAutomaticAssignmentReservationsIds(undefined)}>
                  {t('Cancel')}
                </Button>
                <Button width={116} height={36} buttonType={2} onClick={makeAutomaticAssignment}>
                  {t('Assignment')}
                </Button>
              </div>
            </div>
          </div>
        )}
        {isReservationCreateModalOpen && (
          <ReservationCreateWithRoomModal
            setIsModalOpen={setIsReservationCreateModalOpen}
            onCreated={({ reservationId }) => {
              setReservation(undefined)
              history.replace({
                pathname: '/room-manager/daily',
                search: `?reservationId=${reservationId}`,
              })
            }}
          />
        )}
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const roomContainerStyle = css({
  //   display: 'flex',
  //   justifyContent: 'space-between',
  position: 'relative',
  height: 'calc(100vh - 78px)',
})

const mainWrapperStyle = css({
  //   width: '76%',
  //   width: 'calc(100% - 224px)',
  width: 'calc(100% - 230px)',
  padding: '24px 0 0 24px',
  height: '100%',
})

const roomListContainerStyle = css({
  boxShadow: '0px 0px 6px #0000001A',
  position: 'relative',
  borderRadius: '0 5px 5px 5px',
  width: '100%',
  display: 'flex',
  overflowX: 'scroll',
  height: '100%',
  backgroundColor: '#fff',
})

const dayListWrapperStyle = css({
  minWidth: '78%',
  display: 'flex',
})

const dayListSectionStyle = css({
  height: 'max-content',
})
