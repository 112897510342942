import React from 'react'
import { css } from '@emotion/core'
import moment from 'moment'

// model
import { GuestRoomAssignResponseType } from '@/models/room-manager/response/guest-room-assign'

type RoomNumberSelectProps = {
  room: GuestRoomAssignResponseType['rooms'][0]
  roomIndex: number
  onEditRoomNumber: (e: React.ChangeEvent<HTMLSelectElement>, selectRoomType: string, selectIndex: number) => void
  guestRoomNumberList: (roomInfo: GuestRoomAssignResponseType['rooms'][0]) => { name: string; value: string }[]
  assignPanelEditMode: boolean
  disabled?: boolean
  editGuestRooms?: GuestRoomAssignResponseType['rooms']
}

export const RoomNumberSelect: React.FC<RoomNumberSelectProps> = ({
  room,
  roomIndex,
  onEditRoomNumber,
  guestRoomNumberList,
  assignPanelEditMode,
  disabled,
  editGuestRooms,
}) => {
  const assignSelectStyle = css({
    width: '100%',
    height: 32,
    color: '#272727',
    border: '1px solid #CCCCCC',
    borderRadius: 19,
    paddingLeft: 16,
    appearance: 'none',
    backgroundImage: assignPanelEditMode ? `url(${require('@/static/images/arrow_down_yellow.svg')})` : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '95% center',
    backgroundSize: 10,
    display: 'block',
  })

  const isDuplicateOtherEditRoom = (selectOptionGuestRoomId: string): boolean => {
    return (editGuestRooms || []).some(other => {
      if (other.guestRoomId !== selectOptionGuestRoomId) {
        return false
      }
      const otherCheckin = other.assignCheckinDate ? moment(other.assignCheckinDate).startOf('day') : undefined
      const otherCheckout = other.assignCheckoutDate ? moment(other.assignCheckoutDate).startOf('day') : undefined
      const currentCheckin = room.assignCheckinDate ? moment(room.assignCheckinDate).startOf('day') : undefined
      const currentCheckout = room.assignCheckoutDate ? moment(room.assignCheckoutDate).startOf('day') : undefined
      const unknownPeriod = !otherCheckin || !otherCheckout || !currentCheckin || !currentCheckout
      if (unknownPeriod) {
        return true
      }
      return currentCheckin.isBefore(otherCheckout) && currentCheckout.isAfter(otherCheckin)
    })
  }

  return (
    <select
      name={`roomNumber[${roomIndex}]`}
      css={[assignSelectStyle, disabled ? { backgroundColor: '#F5F5F5' } : {}]}
      value={room && room.guestRoomId !== null ? room.guestRoomId : ''}
      onChange={e => onEditRoomNumber(e, e.target.value, roomIndex)}
      disabled={disabled}
    >
      {guestRoomNumberList(room).map(roomNumberItem => {
        if (
          roomNumberItem['guestRoomId'] &&
          roomNumberItem['name'] !== '指定なし' &&
          room.guestRoomId !== roomNumberItem['guestRoomId'] &&
          isDuplicateOtherEditRoom(roomNumberItem['guestRoomId'])
        ) {
          return null
        }
        return (
          <option key={roomNumberItem['guestRoomId']} value={roomNumberItem['value']}>
            {roomNumberItem['name']}
          </option>
        )
      })}
    </select>
  )
}
