export const FEATURES_LIST = [
  {
    value: 'sales',
    label: 'Sales management',
  },
  {
    value: 'setting',
    label: 'Setting',
  },
  {
    value: 'room',
    label: 'Room',
  },
  {
    value: 'cleaning',
    label: 'Cleaning',
  },
  {
    value: 'customer',
    label: 'Customer',
  },
  {
    value: 'line',
    label: 'LINE',
  },
  {
    value: 'smart_key',
    label: 'Smart Key',
  },
  {
    value: 'mobile_request',
    label: 'Mobile request',
  },
  {
    value: 'accommodation',
    label: 'Accommodation',
  },
  {
    value: 'report',
    label: 'Report',
  },
]

export const TABLES_LIST = [
  'Sale',
  'SalesSubject',
  'SalesDailyReport',
  'SalesDailyCash',
  'SalesManagerDaily',
  'SalesManagerMonth',
  'SalesPayment',
  'AdvanceMoneyHistory',
  'Company',
  'SmaregiTransactionHistory',
  'Checkin',
  'CheckinGuest',
  'Reservation',
  'ReservationGuest',
  'ReservationPayment',
  'ReservationUser',
  'ReservationNote',
  'UploadReceiptLog',
  'Hotel',
  'CustomCheckin',
  'CustomCheckinInputItem',
  'GuestRoomOtaCode',
  'PaymentMethodMaster',
  'GuestRoomType',
  'MobileRequestOrder',
  'MiwaCardIssuesLog',
  'LineResponseHistory',
  'HotelCuicinUser',
  'GuestRoom',
  'GuestRoomAssign',
  'GuestRoomAssignMap',
  'GuestRoomAssignPax',
  'GuestRoomPreparation',
  'GuestRoomCleanHistory',
  'GuestRoomCleanReport',
  'ReservationUpload',
  'ReservationDownload',
  'SalesDetailsDownload',
  'ReportDailyDownload',
  'ReportMonthlyDownload',
  'ReportDepositsDownload',
]
