import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

// models
import { ReservationStatusType } from '@/models/reservation-reservation-status'

// components
import { ReservationUserGuest } from '@/components/organisms/customer/_reservation-user-guest/reservation-usr-guest'
import { Button } from '@/components/atoms/button'
import { DatePicker } from '@/components/organisms/reservation/_detail-edit-info/date-picker'
import { ShortText } from '@/components/atoms/short-text'
import { LoadingFull } from '@/components/molecules/loading-full'

import { useErrorHandler } from '@/hooks/use-error-handler'
import { times } from '@/libs/times'

// apis

import {
  putAdminReservation,
  fetchReservationMarketSegment,
  updateAdminReservationMarketSegment,
  fetchReservationChannelCode,
  updateAdminReservationChannelCode,
  deleteSalesAdvancePayment,
} from '@/apis/aipass'

// constants
import { AccountContext } from '@/contexts/account'

// libs
import { setHasTlTwoWayPlugin } from '@/libs/plugins'
import { env } from '@/libs/env'
import { useAssignConfirmDialog } from './use-assign-confirm-dialog'

type DetailInfoProps = {
  reservation: any
  onChangeInfo?: () => void
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isEdit: boolean
  setIsEdit: (v: boolean) => void
  setIsEditReservationStatus: (v: boolean) => void
  setMarketSegmentChange: (v: boolean) => void
  onChangeMarketSegment?: () => void
}

export const DetailInfo: React.FC<DetailInfoProps> = ({
  reservation,
  setIsLoading,
  onChangeInfo,
  isEdit,
  setIsEdit,
  setIsEditReservationStatus,
  setMarketSegmentChange,
  onChangeMarketSegment,
}) => {
  const aipassZendeskUrl = env('AIPASS_ZENDESK_URL')
  const { t } = useTranslation()
  const { plugins } = useContext<any>(AccountContext)
  const { errorHandler, isOutOfStockError } = useErrorHandler()
  const [reservationDetailOpen, setReservationDetailOpen] = useState<boolean>(false)
  const [marketSegment, setMarketSegment] = useState<any>([])
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)

  const [isEditLoading, setIsEditLoading] = useState<boolean>(false)
  const [showEditFooter, setShowEditFooter] = useState<boolean>(false)
  const [channelCodeId, setChannelCodeId] = useState(reservation?.channelCodeId)
  const [reservationChannelCode, setReservationChannelCode] = useState<any>([])
  const [checkinDate, setCheckinDate] = useState(reservation?.checkinDate)
  const [checkoutDate, setCheckoutDate] = useState(reservation?.checkoutDate)
  const [checkinTime, setCheckinTime] = useState(reservation?.checkinTime)
  const [checkoutTime, setCheckoutTime] = useState(reservation?.checkoutTime)
  const { AssignConfirmDialog, openConfirmDialog } = useAssignConfirmDialog()

  const isChangedCheckinDate = () => dayjs(checkinDate).format('YYYY-MM-DD') !== dayjs(reservation?.checkinDate).format('YYYY-MM-DD')
  const isChangedCheckoutDate = () => dayjs(checkoutDate).format('YYYY-MM-DD') !== dayjs(reservation?.checkoutDate).format('YYYY-MM-DD')

  // Whether it is a Tl2way plugin
  const hasTlTwoWayPlugin = setHasTlTwoWayPlugin(plugins)

  // Reservation status change
  const updateReservationStatus = async reservationStatus => {
    try {
      // Issue an alert only if you have subscribed to the TL2way plug-in and are selling online
      if (
        (reservationStatus === ReservationStatusType.CancellationReport || reservationStatus === ReservationStatusType.NoshowReport) &&
        reservation.isNetSalesReservation &&
        hasTlTwoWayPlugin
      ) {
        if (
          window.confirm(
            t('Its an online reservation If you change or delete the reservation details you may need to adjust the inventory separately'),
          )
        ) {
          // noop
        } else {
          return
        }
      }
      setIsLoading(true)
      await _updateReservation({ reservationStatus })
      setIsEditReservationStatus(true)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
      setIsEditReservationStatus(false)
    }
  }

  const _displayMeal = meal => {
    // Corresponds to accommodation and meal conditions that are already in the production DB
    if (meal === '朝食付き' || meal === '朝食付' || meal === '朝食が客室料金に含まれています。') {
      return '朝食'
    }
    if (meal === '夕食付き' || meal === '夕食付') {
      return '夕食'
    }
    if (meal === '朝夕食付き' || meal === '朝夕食付' || meal === '夕朝食付') {
      return '朝食/夕食'
    }
    if (meal === '2食付' || meal === '1泊2食') {
      return '2食付き'
    }
    if (meal === '朝昼夕食付き') {
      return '朝食/昼食/夕食'
    }
    // Arrange the applicable lodging and meal conditions in an array without specifying
    const unspecifiedList = [
      undefined,
      '',
      null,
      '指定なし',
      '食事別',
      '食事なし',
      '素泊まり',
      'この客室にはお食事のオプションはありません。',
      '素泊り',
      '朝食が客室料金に含まれています。',
      '他',
      '露天風呂の間2名様　B期間',
      'その他',
      'ツアー代',
      '泊食条件',
      'ツイン２名様　S期間',
      '露天風呂の間2名様　A期間',
    ]
    if (unspecifiedList.includes(meal)) {
      return '-'
    }

    // From now on, the accommodation and meal conditions will be saved in the DB as one of the following: breakfast / lunch / dinner / 1 meal included / 2 meals included / -
    return meal
  }

  const _displayChild = childs => {
    if (
      !childs?.paxChildA &&
      !childs?.paxChildB &&
      !childs?.paxChildC &&
      !childs?.paxChildD &&
      !childs?.paxChildE &&
      !childs?.paxChildF &&
      !childs?.paxChildOther
    ) {
      return ''
    }
    return (
      <>
        <div css={displayChildStyle}>
          {t('Child')} : A {childs?.paxChildA}
          {t('Name')} B {childs?.paxChildB}
          {t('Name')} C {childs?.paxChildC}
          {t('Name')} D {childs?.paxChildD}
          {t('Name')} E {childs?.paxChildE}
          {t('Name')} F {childs?.paxChildF}
          {t('Name')} {t('Others')} {childs?.paxChildOther}
          {t('Name')}
          <a target="_blank" rel="noopener noreferrer" href={`${aipassZendeskUrl}/hc/ja/articles/16229000356249-子どもの表記区分について`}>
            <img alt="help" src={require('@/static/images/help.svg')} css={helpImageStyle} />
          </a>
        </div>
      </>
    )
  }

  const _fetchReservationMarketSegment = () => {
    fetchReservationMarketSegment().then(res => {
      if (res?.marketSegments) setMarketSegment(res.marketSegments)
    })
  }

  const updateMarketSegment = marketSegmentId => {
    setIsLoading(true)
    updateAdminReservationMarketSegment(reservation.reservationId, marketSegmentId).then(res => {
      setMarketSegmentChange(true)
      setIsLoading(false)
      if (onChangeMarketSegment) onChangeMarketSegment()
    })
    setMarketSegmentChange(false)
  }

  // reserved room
  const _reservationGuestRoom = () => {
    if (!reservation?.reservationRoom?.length) return <li>- / -</li>
    return reservation.reservationRoom.map((v, index) => {
      const roomName = v.typeNameJa ?? v.typeNameEn
      return <li key={index}>{`${roomName ? roomName : '-'} / ${v.roomNum}${t('room counter')}`}</li>
    })
  }

  const _onReservationFieldPush = () => {
    setReservationDetailOpen(true)
  }

  const _onReservationFieldClose = () => {
    setReservationDetailOpen(false)
  }

  const getReservationUpdateDate = (reservation: any): string => {
    if (!reservation || (!reservation.statusUpdatedAt && !reservation.reservationDate)) {
      return '-'
    }
    return dayjs(reservation.statusUpdatedAt || reservation.reservationDate).format(t('YYYY-MM-DD HH:mm'))
  }

  const _fetchReservationChannelCode = () => {
    fetchReservationChannelCode().then(res => {
      if (res) setReservationChannelCode(res.channelCodes)
    })
  }

  const updateChannelCode = channelCodeId => {
    setChannelCodeId(channelCodeId)
  }

  const _validate = (): boolean => {
    // Accommodation date before change
    const checkinDateWithoutHour = checkinDate?.includes(' ') ? checkinDate.split(' ')[0] : checkinDate // Default BE return YYYY-MM-DD HH:MM:SS
    const checkoutDateWithoutHour = checkoutDate?.includes(' ') ? checkoutDate.split(' ')[0] : checkoutDate // Default BE return YYYY-MM-DD HH:MM:SS

    if (dayjs(checkoutDate).format('YYYY-MM-DD') < dayjs(checkinDate).format('YYYY-MM-DD')) {
      window.alert(t('Please set the check-out date after the check-in date'))
      return false
    }

    if (
      checkinTime &&
      checkoutTime &&
      dayjs(`${checkoutDateWithoutHour} ${checkoutTime}`).isSameOrBefore(`${checkinDateWithoutHour} ${checkinTime}`)
    ) {
      window.alert(t('Checkout time before checkin time'))
      return false
    }

    return true
  }

  const _updateReservation = async (
    updateValues: {
      checkinDate?: string
      checkoutDate?: string
      checkinTime?: string
      checkoutTime?: string
      reservationStatus?: ReservationStatusType
    },
    option?: { validationOnly?: boolean; excludeStockCheck?: boolean },
  ) => {
    const data = {
      reservationId: reservation.reservationId,
      reservation: {
        checkinDate: dayjs(updateValues.checkinDate || reservation.checkinDate).format('YYYY-MM-DD'),
        checkoutDate: dayjs(updateValues.checkoutDate || reservation.checkoutDate).format('YYYY-MM-DD'),
        checkinTime: updateValues.checkinTime || reservation.checkinTime,
        checkoutTime: updateValues.checkoutTime || reservation.checkoutTime,
        meal: reservation.meal,
        method: reservation.method,
        nights: reservation.nights,
        notes: reservation.notes,
        payment: reservation.payment,
        plan: reservation.plan,
        accommodationFee: reservation.accommodationFee,
        pointDiscount: reservation.pointDiscount,
        male: reservation.paxMale,
        female: reservation.paxFemale,
        childA: reservation.paxChildA,
        childB: reservation.paxChildB,
        childC: reservation.paxChildC,
        childD: reservation.paxChildD,
        childE: reservation.paxChildE,
        childF: reservation.paxChildF,
        childOther: reservation.paxChildOther,
        optionStatus: updateValues.reservationStatus || reservation.reservationStatus,
      },
    }
    await putAdminReservation({ ...data, ...option })
  }

  const onSaveData = async () => {
    try {
      if (!_validate()) return

      try {
        await _updateReservation({ checkinDate, checkoutDate, checkinTime, checkoutTime }, { validationOnly: true })
      } catch (e) {
        if (!isOutOfStockError(e as Error)) {
          throw e
        }
        if (!(await openConfirmDialog())) {
          return
        }
      }

      setIsEditLoading(true)

      await updateAdminReservationChannelCode(reservation.reservationId, channelCodeId)

      if (isChangedCheckinDate()) {
        // Remove down payment flag
        await deleteSalesAdvancePayment(reservation.reservationId).catch(() => {
          console.log(t('Communication failed'))
        })
      }

      await _updateReservation({ checkinDate, checkoutDate, checkinTime, checkoutTime }, { excludeStockCheck: true })
      if (onChangeInfo) onChangeInfo()

      setShowEditFooter(false)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsEditLoading(false)
    }
  }

  const resetReservationData = () => {
    setCheckinDate(reservation?.checkinDate)
    setCheckoutDate(reservation?.checkoutDate)
    setCheckinTime(reservation?.checkinTime)
    setCheckoutTime(reservation?.checkoutTime)
    setChannelCodeId(reservation?.channelCodeId)
  }

  useEffect(() => {
    _fetchReservationChannelCode()
    _fetchReservationMarketSegment()
  }, [])

  useEffect(() => {
    resetReservationData()
  }, [reservation])

  useEffect(() => {
    if (
      isChangedCheckinDate() ||
      isChangedCheckoutDate() ||
      checkinTime !== reservation?.checkinTime ||
      checkoutTime !== reservation?.checkoutTime ||
      channelCodeId !== reservation?.channelCodeId
    ) {
      setShowEditFooter(true)
    } else {
      setShowEditFooter(false)
    }
  }, [checkinDate, checkoutDate, checkinTime, checkoutTime, channelCodeId])

  return (
    <div css={[containerStyle, { position: 'relative' }]}>
      <LoadingFull isLoading={isEditLoading} css={{ zIndex: 1 }} />
      <div css={reservationDetailTopContentStyle}>
        {/* Booking status */}
        {reservation && (
          <div css={reservationStatusStyle} onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
            {reservation.reservationStatus === ReservationStatusType.NewReport && <>{t('New')}</>}
            {reservation.reservationStatus === ReservationStatusType.ModificationReport && <>{t('Update')}</>}
            {reservation.reservationStatus === ReservationStatusType.CancellationReport && <>{t('Cancel')}</>}
            {reservation.reservationStatus === ReservationStatusType.NoshowReport && <>{t('No show')}</>}
            <img css={arrowStyle} src={require('@/static/images/arrow_yellow.svg')} alt="" />
            {isDropDownOpen && (
              <div css={dropDownStyle}>
                <ul css={dropDownBodyStyle}>
                  {reservation.reservationStatus !== ReservationStatusType.NewReport && (
                    <li
                      css={dropDownContentStyle}
                      onClick={() => {
                        updateReservationStatus(ReservationStatusType.NewReport)
                      }}
                    >
                      <p css={dropDownTitleStyle}>{t('New')}</p>
                    </li>
                  )}
                  {reservation.reservationStatus !== ReservationStatusType.ModificationReport && (
                    <li
                      css={dropDownContentStyle}
                      onClick={() => {
                        updateReservationStatus(ReservationStatusType.ModificationReport)
                      }}
                    >
                      <p css={dropDownTitleStyle}>{t('Update')}</p>
                    </li>
                  )}
                  {reservation.reservationStatus !== ReservationStatusType.CancellationReport && (
                    <li
                      css={dropDownContentStyle}
                      onClick={() => {
                        updateReservationStatus(ReservationStatusType.CancellationReport)
                      }}
                    >
                      <p css={dropDownTitleStyle}>{t('Cancel')}</p>
                    </li>
                  )}
                  {reservation.reservationStatus !== ReservationStatusType.NoshowReport && (
                    <li
                      css={dropDownContentStyle}
                      onClick={() => {
                        updateReservationStatus(ReservationStatusType.NoshowReport)
                      }}
                    >
                      <p css={dropDownTitleStyle}>{t('No show')}</p>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        )}
        <div css={{ display: 'flex', alignItems: 'center' }}>
          {reservation && (
            <select
              name="selectMarketSegment"
              css={selectStyle}
              onChange={e => updateMarketSegment(e.target.value)}
              value={reservation.marketSegmentId ?? ''}
            >
              <option value="">{t('Market segment')}</option>
              {marketSegment.map(itemSegment => (
                <option key={itemSegment.id} value={itemSegment.id}>
                  {itemSegment.name}
                </option>
              ))}
            </select>
          )}
          <div css={[planEditButtonStyle, { marginLeft: 20 }]} onClick={() => setIsEdit(!isEdit)}>
            <img alt="edit" src={require('@/static/images/edit.svg')} />
          </div>
        </div>
      </div>
      {/* Full booking details */}
      <div css={wrapperStyle}>
        <div>
          {/* Date of stay */}
          <div>
            <div css={checkInDateStyle}>
              <img alt="icon_plan_name" src={require('@/static/images/date.svg')} />
              <div>
                <div
                  css={{
                    display: 'grid',
                    alignItems: 'center',
                    gridTemplateColumns: '1fr auto 1fr',
                    gridTemplateRows: 'repeat(2, 1fr)',
                    gridGap: '16px 0',
                  }}
                >
                  <DatePicker date={checkinDate} onDateChange={setCheckinDate} />
                  <span css={wavyLineStyle}>~</span>
                  <DatePicker date={checkoutDate} onDateChange={setCheckoutDate} />
                  <select
                    name="checkin_time"
                    css={checkinTimeSelectBoxStyle}
                    value={checkinTime ?? ''}
                    onChange={e => setCheckinTime(e.target.value)}
                  >
                    {times.map((item, index) => (
                      <option key={index} value={item['value']}>
                        {item['name'] === '指定なし' ? '-' : item['name']}
                      </option>
                    ))}
                  </select>
                  <span />
                  <select
                    name="checkout_time"
                    css={checkinTimeSelectBoxStyle}
                    value={checkoutTime ?? ''}
                    onChange={e => setCheckoutTime(e.target.value)}
                  >
                    {times.map((item, index) => (
                      <option key={index} value={item['value']}>
                        {item['name'] === '指定なし' ? '-' : item['name']}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/* Number of guests */}
          <div css={numberOfCustomerStyle}>
            <img alt="people" src={require('@/static/images/people.svg')} />
            {reservation && reservation.paxTotal !== 0 ? reservation.paxTotal + t('Name') : '-'}
          </div>
          <div css={numberOfCustomerDetailStyle}>
            {reservation && typeof reservation.paxMale === 'number' && typeof reservation.paxFemale === 'number' ? (
              <>
                {t('Adult')} : {t('Male')}
                <span>{reservation.paxMale !== 0 ? reservation.paxMale + t('Name') : '-'}</span>
                {t('Female')}
                <span>{reservation.paxFemale !== 0 ? reservation.paxFemale + t('Name') : '-'}</span>
              </>
            ) : (
              <li>-</li>
            )}
            {_displayChild(reservation)}
          </div>

          {/* memo */}
          <div css={memoStyle}>
            <img alt="memo" src={require('@/static/images/memo.svg')} />
            <ShortText>{reservation && reservation.notes ? reservation.notes : '-'}</ShortText>
          </div>
        </div>
        <div className="divider" />
        <div>
          {/* channel code name */}
          <div css={[reservationRoomContainerStyle, { marginTop: 0 }]}>
            <img alt="icon_plan_name" src={require('@/static/images/chanel.svg')} />
            <div>
              <select name="channelCode" css={selectStyle} onChange={e => updateChannelCode(e.target.value)} value={channelCodeId ?? ''}>
                <option value="">-</option>
                {reservationChannelCode.map(itemChannelCode => (
                  <option key={itemChannelCode.id} value={itemChannelCode.id}>
                    {itemChannelCode.name}
                  </option>
                ))}
                {reservation?.channelCodeId && !reservationChannelCode.some(({ id }) => reservation?.channelCodeId === id) && (
                  <option value={reservation?.channelCodeId} selected>
                    {reservation?.channelCodeName}
                  </option>
                )}
              </select>
            </div>
          </div>
          {/* plan name */}
          <div css={reservationRoomContainerStyle}>
            <div css={reservationRoomIconStyle}>
              <img src={require('@/static/images/plan.svg')} />
            </div>
            <ul css={reservationRoomListStyle}>{reservation?.plan || '-'}</ul>
          </div>
          {/* reserved room */}
          <div css={reservationRoomContainerStyle}>
            <div css={reservationRoomIconStyle}>
              <img src={require('@/static/images/icon_room.svg')} />
            </div>
            <ul css={reservationRoomListStyle}>{_reservationGuestRoom()}</ul>
          </div>

          {/* meal */}
          <div css={mealPlanStyle}>
            <img alt="eat" src={require('@/static/images/eat.svg')} />
            {_displayMeal(reservation && reservation.meal)}
          </div>

          {/* payment */}
          <div css={paymentPlanStyle}>
            <img alt="pay" src={require('@/static/images/pay.svg')} />
            {reservation && reservation.totalAmount ? `¥${Number(reservation.totalAmount).toLocaleString()}` : '-'} / {''}
            {reservation && reservation.payment ? reservation.payment : t('Local payment')}
          </div>
          <div css={paymentPlanDetailStyle}>
            <span>
              {t('Accommodation fee')} :{' '}
              {reservation && reservation.accommodationFee ? `¥${Number(reservation.accommodationFee).toLocaleString()}` : '-'}
            </span>
            <span>
              {t('Point discount')} :
              {reservation && reservation.pointDiscount ? ` - ¥${Number(reservation.pointDiscount).toLocaleString()}` : ' -'}
            </span>
          </div>
        </div>
        {/* Date of stay */}
      </div>
      {/* view the details */}
      {!reservationDetailOpen && (
        <div css={reservationDetailCloseStyle} onClick={() => _onReservationFieldPush()}>
          <p>{t('View the details')}</p>
          <img alt="icon_arrow_down" src={require('@/static/images/arrow_down.svg')} />
        </div>
      )}
      {reservationDetailOpen && reservation && (
        <div css={reservationInfoDetailStyle}>
          <div css={detailListWrapper}>
            <ReservationUserGuest
              userOrGuest={'user'}
              kana={reservation.userNameKana ? reservation.userNameKana : '-'}
              name={reservation.userName ? reservation.userName : '-'}
              tel={reservation.userTel ? reservation.userTel : '-'}
              mail={reservation.userMailAddr ? reservation.userMailAddr : '-'}
              address={reservation.userAddr ? reservation.userAddr : '-'}
            />
            <div className="divider" />
            <ReservationUserGuest
              userOrGuest={'guest'}
              kana={reservation.guestNameKana ? reservation.guestNameKana : '-'}
              name={reservation.guestName ? reservation.guestName : '-'}
              tel={reservation.guestTel ? reservation.guestTel : '-'}
              mail={reservation.guestMailAddr ? reservation.guestMailAddr : '-'}
              address={reservation.guestAddr ? reservation.guestAddr : '-'}
            />
          </div>
          <div css={reservationDetailCloseStyle} onClick={() => _onReservationFieldClose()}>
            <p>{t('Close up')}</p>
            <img css={reversal} alt="icon_arrow_up" src={require('@/static/images/arrow_down.svg')} />
          </div>
        </div>
      )}
      {showEditFooter ? (
        <div css={[reservationDetailBottomContentStyle, { justifyContent: 'end', backgroundColor: '#fff', padding: '12px 32px' }]}>
          <Button buttonType={3} width={100} height={30} fontSize={12} marginRight={16} onClick={resetReservationData}>
            {t('Cancel')}
          </Button>
          <Button buttonType={1} width={100} height={30} fontSize={12} onClick={onSaveData}>
            {t('Save')}
          </Button>
        </div>
      ) : (
        <div css={reservationDetailBottomContentStyle}>
          <div css={idDataStyle}>
            <p>
              {t('Accommodation ID')} : {reservation && reservation.accommodationId && reservation.accommodationId}
            </p>
            <p>
              {t('Reservation ID')} : {reservation && reservation.reservationId && reservation.reservationId}
            </p>
          </div>
          <div css={reservationUpdateDateStyle}>
            <p>
              {t('Reservation update:')} {getReservationUpdateDate(reservation)}
            </p>
          </div>
        </div>
      )}
      <AssignConfirmDialog />
    </div>
  )
}

const containerStyle = css({
  fontSize: 16,
  color: '#676767',
  width: '100%',
  backgroundColor: '#FFFFFF',
})

const selectStyle = css({
  display: 'block',
  height: 32,
  borderRadius: 16,
  fontSize: 14,
  textAlign: 'left',
  border: '1px solid #CCCCCC',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  paddingLeft: 20,
  paddingRight: 34,
  backgroundPosition: '92% center',
  cursor: 'pointer',
  width: '205px',
})

const reservationDetailTopContentStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: 32,
  paddingRight: 32,
  borderBottom: '1px solid #CCCCCC',
})

const reservationDetailBottomContentStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: 32,
  paddingRight: 30,
  height: 54,
  color: '#676767',
  backgroundColor: '#F5F5F5',
})

const reservationStatusStyle = css({
  width: 116,
  height: 24,
  border: '1px solid #E7A73C',
  borderRadius: 5,
  color: '#E7A73C',
  fontSize: 12,
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '6px 0',
  marginTop: 11,
  marginBottom: 10,
  cursor: 'pointer',
  position: 'relative',
})

const arrowStyle = css({
  position: 'absolute',
  right: 8,
  top: 9,
})

const reservationUpdateDateStyle = css({
  color: '#676767',
  fontSize: 12,
  marginTop: 17,
  marginBottom: 17,
})

const dropDownStyle = css({
  width: 108,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 30,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownBodyStyle = css({})

const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  fontSize: 14,
  fontWeight: 'normal',
  color: '#676767',
})

// 予約詳細
const wrapperStyle = css({
  backgroundColor: '#FFFFFF',
  display: 'flex',
  alignItems: 'stretch',
  minHeight: 347,
  '> div:not(.divider)': {
    flex: 1,
    padding: 24,
  },
  '> .divider': {
    width: 1,
    backgroundColor: '#ccc',
    margin: '24px 0',
  },
})

const planEditButtonStyle = css({
  width: 32,
  height: 32,
  borderRadius: '50%',
  border: '1px solid #CCCCCC',
  cursor: 'pointer',
  backgroundColor: '#FFF',
})

// 宿泊日
const checkInDateStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  '> img': {
    marginRight: 18,
  },
  '.SingleDatePickerInput': {
    display: 'flex',
    width: 'auto',
    '.DateInput': {
      width: '100%',
    },
    '.SingleDatePicker_picker': {
      top: '12px !important',
    },
    '.SingleDatePickerInput_calendarIcon': {
      display: 'none',
    },
  },
})

// 宿泊人数
const numberOfCustomerStyle = css(checkInDateStyle, {
  marginTop: 25,
})

const numberOfCustomerDetailStyle = css({
  margin: '8px 0 0 37px',
  fontSize: 14,
  color: '#676767',
  letterSpacing: 0.7,
  alignItems: 'center',
  span: {
    margin: '0 18px 0 0',
  },
})

const helpImageStyle = css({
  paddingLeft: 11,
})

const displayChildStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginTop: 11,
})

// 予約部屋
const reservationRoomContainerStyle = css(checkInDateStyle, {
  display: 'flex',
  marginTop: 24,
})

const reservationRoomIconStyle = css({
  width: 18,
  height: 18,
  marginRight: 16,
})

const reservationRoomListStyle = css({
  width: '100%',
  li: {
    color: '#676767',
    lineHeight: '21px',
    fontSize: 16,
    fontWeight: 'bold',
  },
  'li:last-child': {
    paddinBottom: 0,
  },
})

// 食事
const mealPlanStyle = css(checkInDateStyle, {
  margin: '24px 0 0 0',
})

// 支払い
const paymentPlanStyle = css(checkInDateStyle, {
  margin: '28px 0 0 0',
})

const paymentPlanDetailStyle = css(numberOfCustomerDetailStyle, {
  margin: '8px 0 0 38px',
})

// メモ
const memoStyle = css({
  margin: '24px 25px 0 0',
  fontSize: 14,
  fontWeight: 'normal',
  color: '#676767',
  letterSpacing: 0.7,
  display: '-webkit-inline-box',
  lineHeight: '1.4',
  overflowWrap: 'anywhere',
  img: {
    margin: '0 11px 0 0',
  },
})

const idDataStyle = css({
  display: 'flex',
  fontSize: 12,
  justifyContent: 'space-between',
  alignItems: 'center',
  p: {
    margin: '0 24px 0 0',
  },
})

// 詳細を見る
const reservationDetailCloseStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#F2A40B',
  fontSize: 12,
  padding: '8px 0',
  cursor: 'pointer',
  fontWeight: 'bold',
  marginLeft: 0,
  borderTop: '1px solid #ccc',
  borderBottom: '1px solid #ccc',
})

const reservationInfoDetailStyle = css({
  background: '#fff',
  color: '#676767',
  borderRadius: '0 0 5px 5px',
  borderTop: '1px solid #CCCCCC',
})

const detailListWrapper = css({
  background: '#fff',
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',
  '> .divider': {
    width: 1,
    backgroundColor: '#ccc',
    margin: '32px 0',
  },
})

const reversal = css({
  transform: 'scale(1, -1)',
})

const checkinTimeSelectBoxStyle = css({
  width: 107,
  backgroundPosition: 72,
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  height: 32,
  padding: '8px 20px',
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  textAlign: 'left',
})

const wavyLineStyle = css({
  padding: '0 7px',
})
