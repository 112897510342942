import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'

import { LoadingFull } from '@/components/molecules/loading-full'
import { LanguageSelect } from '@/components/molecules'
import { Button } from '@/components/atoms/button'
import { DatePicker } from '@/components/organisms/customer/_detail/date-picker'
import { countryList } from '@/libs/nationality'
import { occupationsWithOthers } from '@/libs/occupations'
import { adminCheckinApprovedTabletRegister, fetchAdminCheckin } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'

export const TabletRegisterPage = () => {
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const checkinId = params.get('checkinId')
  const { t, i18n } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<{
    name?: string
    nameKana?: string
    birthDate?: string
    gender?: string
    address?: string
    nationality?: string
    countryName?: string
    email?: string
    telephone?: string
    occupation?: string
    otherOccupation?: string
    company?: string
    previousPlaceToStay?: string
    nextPlaceToStay?: string
    accompany: { id?: string; name?: string }[]
  }>()
  const formData = watch()
  const { fields: accompanyFields, append: addAccompany } = useFieldArray({ control, name: 'accompany' })
  const [fullLoading, setFullLoading] = useState<boolean>(false)
  const [actionLoading, setActionLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const [checkin, setCheckin] = useState<any>()
  const genders = [
    { value: 'F', label: t('Female') },
    { value: 'M', label: t('Male') },
  ]
  const nationality = countryList(i18n.language, false)

  const onSubmit = async formData => {
    try {
      setActionLoading(true)

      await adminCheckinApprovedTabletRegister({
        checkinId,
        checkin: {
          previousPlaceToStay: formData.previousPlaceToStay,
          nextPlaceToStay: formData.nextPlaceToStay,
          accompany: formData.accompany.filter(a => a.name),
          deleteAccompany: formData.accompany.filter(a => !a.name && a.id),
          guest: {
            name: formData.name,
            nameKana: formData.nameKana,
            birthDate: formData.birthDate,
            nationality: formData.nationality === 'NJP' ? formData.countryName : formData.nationality,
            gender: formData.gender,
            address: formData.address,
            email: formData.email,
            telephone: formData.telephone,
            occupation: formData.occupation,
            otherOccupation: formData.otherOccupation,
            company: formData.company,
          },
        },
      })
      await getCheckinData({ withoutLoader: true })

      setSuccess(true)
    } catch (error) {
      errorHandler(error)
    } finally {
      setActionLoading(false)
    }
  }

  const getCheckinData = async ({ withoutLoader = false } = {}) => {
    try {
      if (!withoutLoader) setFullLoading(true)

      const checkin = await fetchAdminCheckin(checkinId)

      setCheckin(checkin)
    } catch (error) {
      errorHandler(error)
    } finally {
      setFullLoading(false)
    }
  }

  const setFieldsValuesByCheckin = () => {
    const guest = checkin?.checkin?.guest || {}

    setValue('name', guest.name)
    setValue('nameKana', guest.nameKana)
    setValue('birthDate', guest.birthDate)
    setValue('gender', guest.gender)
    setValue('address', guest.address)
    if (!guest.nationality || guest.nationality === 'JPN') {
      setValue('nationality', guest.nationality)
    } else {
      setValue('nationality', 'NJP')
      setValue('countryName', guest.nationality)
    }
    setValue('email', guest.email)
    setValue('telephone', guest.telephone)
    setValue('occupation', guest.occupation)
    setValue('otherOccupation', guest.otherOccupation)
    setValue('company', guest.company)

    setValue('previousPlaceToStay', checkin?.checkin?.previousPlaceToStay)
    setValue('nextPlaceToStay', checkin?.checkin?.nextPlaceToStay)

    setValue('accompany', checkin?.checkin?.accompanyList?.length ? checkin?.checkin?.accompanyList : [{ name: undefined }])
  }

  const getFenderByValue = v => genders.find(g => g.value === v)?.label || ''
  const getNationalityByValue = v => nationality.find(n => n.value === v)?.name || ''

  useEffect(() => {
    const language = localStorage.getItem('lang')

    if (language) i18n.changeLanguage(language)
  }, [])

  useEffect(() => {
    if (!checkinId) history.replace('/')

    getCheckinData()
  }, [checkinId])

  useEffect(() => {
    setFieldsValuesByCheckin()
  }, [checkin])

  useEffect(() => {
    if (success) setShowSuccessModal(true)
  }, [success])

  return (
    <div css={{ maxWidth: 960, margin: 'auto', padding: '56px 32px', position: 'relative', minHeight: '100vh' }}>
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 24 }}>
        <h1 css={{ fontSize: 26, fontWeight: 'bold' }}>{t('Guest Registration Card')}</h1>
        <div>
          <LanguageSelect drawerCss={{ right: 'calc(50% - min(50vw, 480px) + 12px)', top: 98, minWidth: 200 }} />
        </div>
      </div>
      {fullLoading ? (
        <LoadingFull isLoading={true} />
      ) : (
        <div css={{ position: 'relative' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div css={tableContainer}>
              <>
                <div css={[labeldiv, { minHeight: 40 }]}>{i18n.language === 'ja' && t('Furigana')}</div>
                <div css={{ minHeight: 37 }}>
                  {i18n.language === 'ja' && (
                    <input {...register('nameKana')} css={textInput} placeholder="シメイ" className={success ? 'success' : ''} />
                  )}
                </div>
                <div
                  css={[
                    noOnlyOneItem,
                    {
                      gridRow: 'span 2 / span 2',
                      gridTemplateColumns: '3fr 2fr',
                    },
                  ]}
                >
                  <div>
                    {success ? (
                      <div>{formData.birthDate}</div>
                    ) : (
                      // <input
                      //   {...register('birthDate')}
                      //   css={textInput}
                      //   placeholder="Date of birth"
                      //   type="date"
                      //   className={success ? 'success' : ''}
                      // />
                      <Controller
                        control={control}
                        name={'birthDate'}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker value={value} onChange={onChange} placeholder={t('Date of birth')} />
                        )}
                      />
                    )}
                  </div>
                  <div>
                    {success ? (
                      <div>{getFenderByValue(formData.gender)}</div>
                    ) : (
                      <select
                        {...register('gender')}
                        css={selectBoxStyle}
                        className={`${success ? 'success' : ''} ${formData.gender ? 'selected' : ''}`}
                      >
                        <option value={''}>{t('Sex')}</option>
                        {genders.map((gender, index) => (
                          <option value={gender.value} key={index}>
                            {gender.label}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </>
              <>
                <div css={[labeldiv, { minHeight: 47 }]}>{t('Your name')}</div>
                <div>
                  <input
                    {...register('name')}
                    css={textInput}
                    placeholder={t('Last name') + ' ' + t('First name')}
                    className={success ? 'success' : ''}
                  />
                </div>
              </>
              <>
                <div css={labeldiv}>{t('Address')}</div>
                <div css={{ gridColumn: 'span 2' }}>
                  <input
                    {...register('address')}
                    css={textInput}
                    placeholder={t('Street Address, Apt/Suite (if any), City, State/Province')}
                    className={success ? 'success' : ''}
                  />
                </div>
              </>
              <>
                <div css={labeldiv}>{t('Nationality')}</div>
                <div>
                  {success ? (
                    <div>{formData.nationality === 'NJP' ? t('Non-Japanese') : getNationalityByValue(formData.nationality)}</div>
                  ) : (
                    <select
                      {...register('nationality')}
                      css={selectBoxStyle}
                      className={`${success ? 'success' : ''} ${formData.nationality ? 'selected' : ''}`}
                    >
                      <option value={''}>{t('Nationality')}</option>
                      <option value={'JPN'}>{t('Japanese')}</option>
                      <option value={'NJP'}>{t('Non-Japanese')}</option>
                    </select>
                  )}
                </div>
                <div>
                  {formData.nationality === 'NJP' && (
                    <>
                      {success ? (
                        <div>{getNationalityByValue(formData.countryName)}</div>
                      ) : (
                        <select
                          {...register('countryName')}
                          css={selectBoxStyle}
                          className={`${success ? 'success' : ''} ${formData.countryName ? 'selected' : ''}`}
                        >
                          <option value={''}>{t('Country name')}</option>
                          {nationality.map((country, index) => {
                            return (
                              <option value={country.value} key={index}>
                                {country.name}
                              </option>
                            )
                          })}
                        </select>
                      )}
                    </>
                  )}
                </div>
              </>
              <>
                <div css={labeldiv}>{t('Contact')}</div>
                <div css={errors.telephone ? { border: '1px solid red', margin: -1 } : {}}>
                  <input
                    {...register('telephone', {
                      pattern: {
                        value: /^[0-9]+$/,
                        message: t('Please enter the phone number in half-width numbers'),
                      },
                    })}
                    css={[textInput, errors.telephone ? { color: 'red' } : {}]}
                    placeholder={t('Phone number')}
                    className={success ? 'success' : ''}
                  />
                  {!!errors.telephone && (
                    <div css={{ fontSize: 10, color: 'rgba(255,0,0,0.5)', marginTop: 4 }}>{errors.telephone?.message}</div>
                  )}
                </div>
                <div css={errors.email ? { border: '1px solid red', margin: -1 } : {}}>
                  <input
                    {...register('email', {
                      pattern: {
                        value: /^.+\@.+\..+$/,
                        message: t('Please enter a valid email address'),
                      },
                    })}
                    css={textInput}
                    placeholder={t('Email address')}
                    className={success ? 'success' : ''}
                  />
                  {!!errors.email && <div css={{ fontSize: 10, color: 'rgba(255,0,0,0.5)', marginTop: 4 }}>{errors.email?.message}</div>}
                </div>
              </>
              <>
                <div css={labeldiv}>{t('Occupation')}</div>
                <div>
                  {success ? (
                    <div>{formData.occupation}</div>
                  ) : (
                    <select
                      {...register('occupation')}
                      css={selectBoxStyle}
                      placeholder={t('Occupation')}
                      className={`${success ? 'success' : ''} ${formData.occupation ? 'selected' : ''}`}
                    >
                      <option value={''}>{t('Occupation')}</option>
                      {occupationsWithOthers.map((occupation, index) => {
                        return (
                          <option value={`${t(occupation.value)}`} key={index}>
                            {t(occupation.label)}
                          </option>
                        )
                      })}
                    </select>
                  )}
                </div>
                <div>
                  {(formData.occupation === t('SelectOptions.Occupation.Employee') ||
                    formData.occupation === 'Employee' ||
                    formData.occupation === '会社員') && (
                    <>
                      {success ? (
                        <div>{formData.company}</div>
                      ) : (
                        <input
                          {...register('company')}
                          css={textInput}
                          placeholder={t('Company name')}
                          className={success ? 'success' : ''}
                        />
                      )}
                    </>
                  )}
                  {(formData.occupation === t('SelectOptions.Occupation.Other') ||
                    formData.occupation === 'その他' ||
                    formData.occupation === 'Other') && (
                    <>
                      {success ? (
                        <div>{formData.otherOccupation}</div>
                      ) : (
                        <input
                          {...register('otherOccupation')}
                          css={textInput}
                          placeholder={t('SelectOptions.Occupation.Other')}
                          className={success ? 'success' : ''}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
              <>
                <div css={labeldiv}>{t('Place to Stay')}</div>
                <div>
                  <input
                    {...register('previousPlaceToStay')}
                    css={textInput}
                    placeholder={t('Previous Place to Stay')}
                    className={success ? 'success' : ''}
                  />
                </div>
                <div>
                  <input
                    {...register('nextPlaceToStay')}
                    css={textInput}
                    placeholder={t('Next Place to Stay')}
                    className={success ? 'success' : ''}
                  />
                </div>
              </>
              <>
                <div css={labeldiv}>{t('Companion')}</div>
                <div
                  css={[
                    noOnlyOneItem,
                    {
                      position: 'relative',
                      gridColumn: 'span 2',
                      gridTemplateColumns: '1fr',
                    },
                  ]}
                >
                  {accompanyFields.map((field, index) => (
                    <div key={field.id} css={{ height: 60 }}>
                      <input
                        {...register(`accompany.${index}.name`)}
                        css={textInput}
                        placeholder={t('Full name')}
                        className={success ? 'success' : ''}
                      />
                    </div>
                  ))}
                  {!success && (
                    <div
                      onClick={() => addAccompany({ name: undefined })}
                      css={{ position: 'absolute', right: 4, bottom: 14, color: '#f2a40b', cursor: 'pointer' }}
                    >
                      {t('Addition')}
                    </div>
                  )}
                </div>
              </>
            </div>
            <div css={{ display: 'flex', justifyContent: 'center', marginTop: 32, position: 'relative' }}>
              {actionLoading ? (
                <div css={{ marginTop: 32 }}>
                  <LoadingFull isLoading={true} />
                </div>
              ) : success ? (
                <Button onClick={() => window.close()} buttonType={1} width={238} height={48} fontSize={18} fontWeight={'bold'}>
                  {t('Next')}
                </Button>
              ) : (
                <Button buttonType={1} width={238} height={48} fontSize={18} fontWeight={'bold'} type="submit">
                  {t('Input completed')}
                </Button>
              )}
            </div>
          </form>
          {showSuccessModal && (
            <div
              css={{
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgba(64, 64, 64, 0.58)',
              }}
            >
              <div
                css={{
                  backgroundColor: '#fff',
                  borderRadius: 31,
                  position: 'relative',
                  padding: 40,
                  width: '100%',
                  margin: 16,
                  maxWidth: 600,
                }}
              >
                <img
                  src={require('@/static/images/delete_yellow.svg')}
                  css={{ position: 'absolute', right: 16, top: 16, width: 40, display: 'block', cursor: 'pointer' }}
                  onClick={() => setShowSuccessModal(false)}
                />
                <h1 css={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center', color: '#F2A40B', marginTop: 16 }}>
                  {t('Acceptance completed')}
                </h1>
                <img
                  src={require('@/static/images/tablet-register/acceptance-completed.png')}
                  css={{ margin: '32px auto', maxWidth: '100%', display: 'block', width: 360 }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const tableContainer = css({
  border: '2px solid #676767',
  borderRadius: 12,
  height: '100%',
  marginTop: 16,
  overflow: 'hidden',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr 1fr',
  gap: '1px',
  backgroundColor: '#676767',
  ' > div': {
    backgroundColor: '#fff',
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  '.mbsc-input-box': {
    width: '100%',
    input: {
      '&.mbsc-control': {
        color: '#272727',
        fontWeight: 500,
        height: 40,
        border: '1px solid #676767',
        borderRadius: 8,
        backgroundPosition: '95% center',
        backgroundImage: `url(${require('@/static/images/self-checkin-payment/arrow.svg')})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'initial',
        fontSize: 16,
        '::placeholder': {
          color: '#ccc',
        },
        ':focus': {
          border: '1px solid #676767',
        },
      },
    },
    // '.DateInput': {
    //   width: '100%',
    //   '.DateInput_input': {
    //     textAlign: 'left',
    //   },
    // },
  },
  '.SingleDatePicker_picker__directionLeft': {
    top: 'initial !important',
  },
  '.SingleDatePickerInput_calendarIcon': {
    display: 'none',
  },
})

const labeldiv = css({
  fontWeight: 'bold',
  minHeight: 60,
})

const textInput = css({
  border: 'none',
  backgroundColor: 'none',
  width: '100%',
  color: '#272727',
  '::placeholder': {
    color: '#ccc',
  },
  '&.success': {
    pointerEvents: 'none',
    '::placeholder': {
      color: '#fff',
    },
  },
})

const selectBoxStyle = css({
  width: '100%',
  height: 40,
  border: '1px solid #676767',
  borderRadius: 8,
  appearance: 'none',
  display: 'block',
  paddingLeft: 14,
  paddingRight: 14,
  backgroundColor: '#fff',
  backgroundPosition: '95% center',
  backgroundImage: `url(${require('@/static/images/self-checkin-payment/arrow.svg')})`,
  backgroundRepeat: 'no-repeat',
  color: '#ccc',
  '&.selected': {
    color: '#272727',
  },
})

const noOnlyOneItem = css({
  display: 'grid !important',
  gap: 1,
  padding: '0 !important',
  backgroundColor: '#676767 !important',
  alignItems: 'initial !important',
  '> div': { backgroundColor: '#fff', display: 'flex', alignItems: 'center', padding: '8px' },
})
