import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import moment from 'moment'

import { Button } from '@/components/atoms/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { NoticeLoading } from '@/components/molecules/notice-loading'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { CouponInputType, CouponType } from '@/models/coupon'
import { InputField } from '@/components/molecules/input-field'
import { FileField } from '@/components/atoms/fileInput'
import { TextareaField } from '@/components/molecules/textarea-field'
import { DateRangeField } from '@/components/molecules/date-range-field'
import { Label } from '@/components/molecules/label'
import { createSettingsCoupon, updateSettingsCoupon } from '@/apis/aipass'

type SettingsEditCouponModalProps = {
  coupon?: CouponType
  onSave: () => any
  onClose: () => any
}

export const SettingsEditCouponModal = ({ coupon, onSave, onClose }: SettingsEditCouponModalProps) => {
  const windows = useWindowSize()
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [loading, setLoading] = useState<boolean>(false)
  const [fromValid, setFromValid] = useState<boolean>(false)
  const [formData, setFormData] = useState<CouponInputType>({
    couponCode: coupon?.couponCode || '',
    title: coupon?.title || '',
    titleLatin: coupon?.titleLatin || '',
    venueName: coupon?.venueName || '',
    venueNameLatin: coupon?.venueNameLatin || '',
    image: coupon?.image || '',
    description: coupon?.description || '',
    descriptionEn: coupon?.descriptionEn || '',
    descriptionKo: coupon?.descriptionKo || '',
    descriptionZh: coupon?.descriptionZh || '',
    validFrom: coupon?.validFrom || moment().format('YYYY-MM-DD'),
    validTo:
      coupon?.validTo ||
      moment()
        .add(1, 'M')
        .format('YYYY-MM-DD'),
    url: coupon?.url || '',
  })

  const setFormDataField = (fieldName: string, value: any) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const changeValidDates = (validFrom: string, validTo: string) => {
    setFormData({ ...formData, validFrom, validTo })
  }

  const save = async () => {
    try {
      setLoading(true)

      if (coupon?.id) {
        await updateSettingsCoupon({ ...coupon, ...formData })
      } else {
        await createSettingsCoupon(formData)
      }

      onSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const validCouponCode = () => {
    return /^[A-Za-z0-9]+$/.test(formData.couponCode)
  }

  const validCouponUrl = () => {
    return /^(?:https?:\/\/(?:www\.)?|https:(?:\/\/)?)?\w+(?:[-.]\w+)+(?:\/[^\/\s]+)*$/.test(formData.url)
  }

  useEffect(() => {
    setFromValid(
      !!(validCouponCode() && formData.title && formData.venueName && formData.validFrom && formData.validTo && validCouponUrl()),
    )
  }, [formData])

  return (
    <Modal customCss={{ height: windows.height - 178, width: 600, minWidth: 600, left: 'calc(50% - 300px)' }}>
      <ModalHeader>{t('Add coupon')}</ModalHeader>
      <ModalBody>
        {loading && <NoticeLoading isLoading={loading} />}
        <div css={{ padding: 16, border: '1px solid #ddd', borderRadius: 8, backgroundColor: '#fff' }}>
          <InputField
            handleChangeData={e => setFormDataField('couponCode', e.target.value)}
            value={formData.couponCode}
            name="couponCode"
            label={t('Coupon ID') + ' *'}
            placeholder={t('Coupon ID')}
            maxLength={50}
          />
          {formData.couponCode && !validCouponCode() && (
            <div css={{ fontSize: 12, marginBottom: 8, marginTop: -10, color: 'red', fontWeight: 'bold' }}>
              {t('Only latin characters and number please')}
            </div>
          )}
          <InputField
            handleChangeData={e => setFormDataField('title', e.target.value)}
            value={formData.title}
            name="title"
            label={t('Coupon name') + ' *'}
            placeholder={t('Coupon name')}
          />
          <InputField
            handleChangeData={e => setFormDataField('titleLatin', e.target.value)}
            value={formData.titleLatin}
            name="titleLatin"
            label={t('Coupon name') + ' (English)'}
            placeholder={t('Coupon name')}
          />
          <InputField
            handleChangeData={e => setFormDataField('venueName', e.target.value)}
            value={formData.venueName}
            name="venueName"
            label={t('Name of facility') + ' *'}
            placeholder={t('Name of facility')}
          />
          <InputField
            handleChangeData={e => setFormDataField('venueNameLatin', e.target.value)}
            value={formData.venueNameLatin}
            name="venueNameLatin"
            label={t('Name of facility') + ' (English)'}
            placeholder={t('Name of facility')}
          />
          <FileField value={formData.image} onChange={value => setFormDataField('image', value)} label={t('Image')} withCrop={true} />
          <TextareaField
            handleChangeData={e => setFormDataField('description', e.target.value)}
            value={formData.description}
            name="description"
            label={`${t('Explanatory text')} JA`}
            placeholder={t('Explanatory text')}
          />
          <TextareaField
            handleChangeData={e => setFormDataField('descriptionEn', e.target.value)}
            value={formData.descriptionEn}
            name="descriptionEn"
            label={`${t('Explanatory text')} EN`}
            placeholder={t('Explanatory text')}
          />
          <TextareaField
            handleChangeData={e => setFormDataField('descriptionKo', e.target.value)}
            value={formData.descriptionKo}
            name="descriptionKo"
            label={`${t('Explanatory text')} KO`}
            placeholder={t('Explanatory text')}
          />
          <TextareaField
            handleChangeData={e => setFormDataField('descriptionZh', e.target.value)}
            value={formData.descriptionZh}
            name="descriptionZh"
            label={`${t('Explanatory text')} ZH`}
            placeholder={t('Explanatory text')}
          />
          <div css={{ marginBottom: 16 }}>
            <Label label={t('Expiration date') + ' *'} />
            <DateRangeField
              beginDate={formData.validFrom}
              endDate={formData.validTo}
              changeRangeDate={changeValidDates}
              openDirection="up"
            />
          </div>
          <InputField
            handleChangeData={e => setFormDataField('url', e.target.value)}
            value={formData.url}
            name="url"
            label="URL *"
            placeholder="URL"
          />
          {formData.url && !validCouponUrl() && (
            <div css={{ fontSize: 12, marginBottom: 8, marginTop: -10, color: 'red', fontWeight: 'bold' }}>{t('The URL invalid')}</div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={!fromValid ? 5 : 1} isDisabled={!fromValid} height="38px" width="110px" onClick={save}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
