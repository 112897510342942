import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { Link } from 'react-router-dom'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

// contexts
import { AccountContext } from '@/contexts/account'

import {
  mainContainerHeaderStyle,
  headerBarLeftWrapperStyle,
  listHeaderGrayStyle,
  wholeContainerStyle,
  mainColumnStyle,
  listContainerGrayStyle,
  mainContainerStyle,
  tableHeaderThStyle,
  tableBodyTdStyleRead,
  tableBodyTdStyleUnread,
  tableBodyTdStyle,
} from '@/constants/layout'

// apis
import * as api from '@/apis/aipass'

// components
import { ReloadButton } from '@/components/atoms/reload-button'
import { LoadingFull } from '@/components/molecules/loading-full'
import { DateRangeField } from '@/components/molecules/date-range-field'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { Button } from '@/components/atoms/button'
import { InputField } from '@/components/molecules/input-field'
import { ReservationHeaderButtonStyle, companionStyle } from '@/components/pages/accommodation-management/list-layout'
import { useSearchedConditions } from '@/hooks/use-search-condition'

// models
import { CustomerType } from '@/models/customer-manager/customer'

// libs
import { setHasRepeatPlugin } from '@/libs/plugins'
import { CustomerTag } from '@/components/atoms/customer/customer-tag'

export const CustomerList: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { plugins } = useContext<any>(AccountContext)
  const location = useLocation()
  const windows = useWindowSize()
  const history = useHistory()
  const searchParams: {
    beginDate?: string
    endDate?: string
    name?: string
    nameKana?: string
    page?: number
  } = require('query-string').parse(location.search)
  const [customers, setCustomers] = useState<CustomerType[]>([])
  const hasRepeatPlugin = setHasRepeatPlugin(plugins)
  const [name, setName] = useState<string>(searchParams.name || '')
  const [nameKana, setNameKana] = useState<string>(searchParams.nameKana || '')
  const [beginDate, setBeginDate] = useState<string>(
    searchParams.beginDate ? searchParams.beginDate : `${dayjs().set('day', -30).format('YYYY-MM-DD')}`,
  )
  const [endDate, setEndDate] = useState<string>(searchParams.endDate ? searchParams.endDate : `${dayjs().format('YYYY-MM-DD')}`)
  const [page, setPage] = useState<number>(searchParams.page || 1)
  const [listTotal, setListTotal] = useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const limit = 30

  const changeRangeDate = (beginDate: string, endDate: string) => {
    setBeginDate(beginDate)
    setEndDate(endDate)
  }

  const onClear = () => {
    setName('')
    setNameKana('')
  }

  const doSearchCustomers = async () => {
    setIsLoading(true)
    const formattedBeginDate = beginDate !== '' ? `${beginDate} 00:00:00` : ''
    const formattedEndDate = endDate !== '' ? `${endDate} 23:59:59` : ''
    setIsOpen(false)
    await api
      .fetchCustomers(name, nameKana, formattedBeginDate, formattedEndDate, Number(page), limit)
      .then(res => {
        setCustomers([]) // To reset the table position to the top
        setCustomers(res?.hotelCuicinUsers)
        setListTotal(res?.total)
        history.replace({
          pathname: '',
          search: new URLSearchParams({
            beginDate,
            endDate,
            name,
            nameKana,
            page: `${page}`,
          }).toString(),
        })
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if ((beginDate !== '' && endDate !== '') || beginDate === '') {
      doSearchCustomers()
    }
  }, [beginDate, endDate, page])

  const searchedConditions = {
    [t('Date of stay')]: `${dayjs(beginDate).format(t('YYYY-MM-DD'))} ~
    ${dayjs(endDate).format(t('YYYY-MM-DD'))}`,
    [t('Name')]: name,
    [t('Furigana')]: nameKana,
  }

  const { SearchedConditionValues } = useSearchedConditions({ searchedConditions })

  return (
    <>
      <div css={listContainerGrayStyle}>
        <div css={wholeContainerStyle}>
          <SideMenu />
          <div css={mainColumnStyle}>
            <Header title={t('Customer management')} />
            <div css={mainContainerStyle}>
              <div css={mainContainerHeaderStyle}>
                <div css={headerBarLeftWrapperStyle}>
                  <DateRangeField beginDate={beginDate} endDate={endDate} changeRangeDate={changeRangeDate} />
                  <Button
                    buttonType={4}
                    width={i18n.language === 'en' ? 118 : 90}
                    height={32}
                    fontSize={12}
                    marginLeft={8}
                    marginRight={8}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {t('Detailed search')}
                  </Button>
                  <ReloadButton onClick={doSearchCustomers} />
                  {!isOpen && <SearchedConditionValues />}
                </div>
                <div css={headerBarLeftWrapperStyle}>
                  <AccommodationPaging page={Number(page)} setPage={setPage} total={listTotal || 0} limit={limit} />
                  <div css={ReservationHeaderButtonStyle}></div>
                </div>
              </div>
              {isOpen && (
                <div css={modalWrapperStyle}>
                  <div css={modalInputWrapperStyle}>
                    <InputField
                      handleChangeData={e => setName(e.target.value)}
                      value={name}
                      name="name"
                      label={t('Full name')}
                      placeholder={t('John Doe')}
                      fieldWidth="40%"
                      width="95%"
                    />
                    <InputField
                      handleChangeData={e => setNameKana(e.target.value)}
                      value={nameKana}
                      name="nameKana"
                      label={t('Furigana')}
                      placeholder="ヤマダ タロウ"
                      fieldWidth="40%"
                      width="95%"
                    />
                  </div>
                  <div css={searchButtonStyle}>
                    <Button buttonType={3} width={92} height={32} fontSize={12} marginRight={16} onClick={onClear}>
                      {t('Clear')}
                    </Button>
                    <Button buttonType={1} width={92} height={32} fontSize={12} onClick={doSearchCustomers}>
                      {t('Search')}
                    </Button>
                  </div>
                </div>
              )}
              <div css={{ overflow: 'auto', height: windows.height - 168 }}>
                <table css={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th css={[tableHeaderThStyle, { paddingLeft: 32, width: 45 }]}></th>
                      <th css={[tableHeaderThStyle, { width: 200 }]}>{t('Full name')}</th>
                      <th css={[tableHeaderThStyle, { width: 200 }]}>{t('Phone number')}</th>
                      <th css={[tableHeaderThStyle, { width: 200 }]}>{t('CustomerTag')}</th>
                      <th css={[tableHeaderThStyle, { width: 200 }]}>{t('Email')}</th>
                      <th css={[tableHeaderThStyle, { width: 80 }]}>{t('Number of nights stayed')}</th>
                      <th css={[tableHeaderThStyle, { width: 200 }]}>{t('Last night of stay')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers &&
                      customers.map((v: CustomerType, index) => {
                        if (!v) return null

                        return (
                          <Link
                            key={index}
                            css={[
                              v.customerRead ? tableBodyTdStyleRead : tableBodyTdStyleUnread,
                              { display: 'table-row', ':hover': { color: '#000' } },
                              { color: '#000', ':hover': { color: '#000' } },
                            ]}
                            to={`/customer/${v.id}?beginDate=${beginDate}&endDate=${endDate}&name=${name}&nameKana=${nameKana}`}
                          >
                            <td css={[tableBodyTdStyle, { paddingLeft: 32 }]}>
                              <img src={v.picture ? v.picture : require('@/static/images/user.svg')} css={pictureStyle} />
                            </td>
                            <td css={tableBodyTdStyle}>
                              <div css={nameWrapperStyle}>
                                <ruby>{v?.nameKana === null || v?.nameKana === '' ? '-' : v.nameKana}</ruby>
                                <div css={dataNameStyle}>{v?.name === null || v?.name === '' ? '-' : v.name}</div>
                              </div>
                            </td>
                            <td css={tableBodyTdStyle}>{v.telephone ? v.telephone : '-'}</td>
                            <td css={tableBodyTdStyle}>
                              <div css={tagsStyle}>
                                {v.tagInfo?.tags.map(tagInfo => (
                                  <CustomerTag
                                    name={tagInfo.tag.name}
                                    bgColor={tagInfo.tag.bgColor}
                                    fontColor={tagInfo.tag.fontColor}
                                  />
                                ))}
                              </div>
                            </td>
                            <td css={tableBodyTdStyle}>
                              <div css={companionStyle}>{v.email ? v.email : '-'}</div>
                            </td>
                            <td css={tableBodyTdStyle}>
                              {hasRepeatPlugin
                                ? (v.stayCount > 1 && <span css={stayCountStyle}>{v.stayCount}</span>) ||
                                  (v.stayCount <= 1 && <span>{v.stayCount ? v.stayCount : '-'}</span>)
                                : '-'}
                            </td>
                            <td css={tableBodyTdStyle}>
                              {v.checkinDate ? dayjs(v.checkinDate).format(t('MM-DD-YYYY')) : '-'}{' '}
                              {v.checkinDate && v.checkoutDate ? dayjs(v.checkoutDate).diff(v.checkinDate, 'day') : '-'}
                              {t('Night')}
                            </td>
                          </Link>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <LoadingFull isLoading={isLoading} />
        </div>
      </div>
    </>
  )
}

const pictureStyle = css({
  width: 40,
  height: 40,
  borderRadius: '50%',
})

const nameWrapperStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  ruby: {
    color: '#676767',
    fontSize: 12,
    marginBottom: 6,
  },
  marginRight: 23,
})

const dataNameStyle = css({
  width: 'auto',
})

const dataTagStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const modalWrapperStyle = css(listHeaderGrayStyle, {
  width: 'calc(85.17% - 24px)',
  height: 'auto',
  background: '#fff',
  position: 'absolute',
  border: '1px solid #F2F2F2',
  boxShadow: '0px 3px 4px #0000001A',
  zIndex: 100,
  display: 'flex',
  flexDirection: 'column',
})

const modalInputWrapperStyle = css({
  display: 'flex',
  flexDirection: 'row',
  padding: '32px 32px 8px 32px',
  border: '1px solid #F2F2F2',
})

const searchButtonStyle = css({
  display: 'flex',
  padding: '16px 32px',
})

const stayCountStyle = css({
  color: '#F2A40B',
})

const tagsStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 8,
  height: 56,
  overflowY: 'hidden',
})
