import React, { useEffect, useState } from 'react'
import { getMailDeliveryHistory } from '@/apis/aipass'
import { QuestionMark } from '@/components/atoms/question-mark'
import { ReloadButton } from '@/components/atoms/reload-button'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { DateRangeField } from '@/components/molecules/date-range-field'
import { HistoryModal } from '@/components/organisms/mail-delivery/history-modal'
import {
  dashboardPageContainerStyle,
  mainColumnStyle,
  mainContainerStyle,
  tableBodyTdStyle,
  tableHeaderThStyle,
  wholeContainerStyle,
} from '@/constants/layout'
import { errorHandler } from '@/libs/errors'
import { MailDeliveryHistory, MailDeliveryHistoryResult } from '@/models/mail-delivery/mail-delivery-history'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { LoadingFull } from '@/components/molecules/loading-full'
import { useWindowSize } from 'react-use'

const PER_PAGE_NUMBER = 30

export const MailDeliveryHistoryList: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [beginDate, setBeginDate] = useState<string>(`${dayjs().subtract(1, 'week').format('YYYY-MM-DD')}`)
  const [endDate, setEndDate] = useState<string>(`${dayjs().format('YYYY-MM-DD')}`)
  const [page, setPage] = useState<number>(1)
  const [mailDeliveryHistoryResult, setMailDeliveryHistoryResult] = useState<MailDeliveryHistoryResult>()
  const [selectedMailDeliveryHistory, setSelectedMailDeliveryHistory] = useState<MailDeliveryHistory>()

  const { t } = useTranslation()

  const windows = useWindowSize()

  const _getMailDeliveryHistory = async () => {
    try {
      setLoading(true)
      const result = await getMailDeliveryHistory({ page, sentAtFrom: beginDate, sentAtTo: endDate })
      setMailDeliveryHistoryResult(result)
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const refresh = () => {
    _getMailDeliveryHistory()
  }

  const changeRangeDate = (beginDate, endDate) => {
    setBeginDate(beginDate)
    setEndDate(endDate)
  }

  const viewStatus = (isSendCompleted: boolean) => {
    const color = isSendCompleted ? '#676767' : '#F2A40B'

    return (
      <span className="status" style={{ borderColor: color, color }}>
        {t(isSendCompleted ? 'delivery completed' : 'now being delivered')}
      </span>
    )
  }

  useEffect(() => {
    _getMailDeliveryHistory()
  }, [endDate, beginDate, page])

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      {mailDeliveryHistoryResult ? (
        <div css={mainColumnStyle}>
          <Header title={t('Mail delivery')} />
          <div css={mainContainerStyle}>
            <div css={dateRangeStyle}>
              <DateRangeField beginDate={beginDate} endDate={endDate} changeRangeDate={changeRangeDate} />
              <ReloadButton onClick={refresh} />
            </div>
            <div css={[dashboardPageContainerStyle, contentStyle]}>
              <div className="title-block">
                <p className="title">{t('delivery history')}</p>
                <AccommodationPaging page={page} total={mailDeliveryHistoryResult.total} limit={PER_PAGE_NUMBER} setPage={setPage} />
              </div>
              <div className="list-block" style={{ height: windows.height - 218 }}>
                <table css={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th css={tableHeaderThStyle}>{t('mailDelivery.title')}</th>
                      <th css={tableHeaderThStyle}>{t('Status')}</th>
                      <th css={tableHeaderThStyle}>{t('Regular delivery date and time')}</th>
                      <th css={tableHeaderThStyle}>{t('Number of deliverables')}</th>
                      <th css={tableHeaderThStyle}>{t('Number of Successes')}</th>
                      <th css={tableHeaderThStyle}>
                        <div className="hint">
                          {t('Number of openings')}
                          <QuestionMark customCss={css({ marginLeft: 10 })} />
                          <span className="description">{t('Shows the number of opens within 3 days of delivery')}</span>
                        </div>
                      </th>
                      <th css={tableHeaderThStyle}>{t('Delivery Date and time')}</th>
                    </tr>
                  </thead>

                  {mailDeliveryHistoryResult && (
                    <tbody>
                      {mailDeliveryHistoryResult?.histories.map(mailDeliveryHistory => (
                        <tr key={mailDeliveryHistory.id} onClick={() => setSelectedMailDeliveryHistory(mailDeliveryHistory)}>
                          <td css={tdStyle}>{mailDeliveryHistory.title}</td>
                          <td css={tdStyle}>{viewStatus(mailDeliveryHistory.isSendCompleted)}</td>
                          <td css={tdStyle}>{mailDeliveryHistory.scheduledDeliveryAt}</td>
                          <td css={tdStyle}>{mailDeliveryHistory.totalSend}</td>
                          <td css={tdStyle}>{mailDeliveryHistory.isSendCompleted ? mailDeliveryHistory.totalSuccess : '-'}</td>
                          <td css={tdStyle}>{mailDeliveryHistory.isSendCompleted ? mailDeliveryHistory.totalOpen : '-'}</td>
                          <td css={tdStyle}>{dayjs(mailDeliveryHistory.sentAt).format('YYYY年MM月DD日 HH:mm')}</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
            {selectedMailDeliveryHistory && (
              <HistoryModal onClose={() => setSelectedMailDeliveryHistory(undefined)} mailDeliveryHistory={selectedMailDeliveryHistory} />
            )}
          </div>
          <LoadingFull isLoading={loading} />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const dateRangeStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  marginBottom: 16,
})

const contentStyle = css({
  '.title-block': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '17px 28px',
    '.title': {
      fontSize: 16,
      fontWeight: 'bold',
    },
  },
  '.list-block': {
    overflowY: 'auto',
    '&::-webkit-scrollbar-track': {
      background: '#F5F5F5',
    },
  },
  table: {
    width: '100%',
  },
  th: {
    '&:first-of-type': {
      width: '34%',
      paddingLeft: 32,
    },
  },
  td: {
    cursor: 'pointer',
    '&:first-of-type': {
      paddingLeft: 32,
    },
  },
  '.hint': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '.description': {
      display: 'none',
      width: 250,
      padding: 8,
      textAlign: 'center',
      color: '#676767',
      background: '#fff',
      boxShadow: '0px 0px 10px #00000029',
      fontSize: 12,
      fontWeight: 'bold',
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 100,
    },
    ':hover .description': {
      display: 'block',
    },
  },
  '.status': {
    display: 'inline-block',
    width: 77,
    paddingBlock: 4,
    position: 'relative',
    textAlign: 'center',
    border: '1px solid',
    fontSize: 10,
    borderRadius: 5,
  },
})

const tdStyle = css(tableBodyTdStyle, {
  paddingBlock: 22,
  fontWeight: 'bold',
})
