import React from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

// models
import { DiscountSettingType, DiscountType } from '@/models/sales'
import { AccountsReceivable, AccountsReceivableSettingType } from '@/models/accounts-receivable'

type Props = {
  discountSettings: DiscountSettingType[]
  onChangeState: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index) => void
  deleteDiscountSetting: (id, index) => void
  addDiscountSetting: () => void
  accountsReceivableSettings: AccountsReceivableSettingType[]
}
export const SettingDiscountEditForm: React.FC<Props> = ({
  discountSettings,
  onChangeState,
  deleteDiscountSetting,
  addDiscountSetting,
  accountsReceivableSettings,
}) => {
  const windows = useWindowSize()
  const { t } = useTranslation()

  return (
    <>
      <div css={discountSettingEditHeaderStyle}>
        <div className="subjectCode" css={subjectCodeWidthStyle}>
          {t('Discount item ID')}
        </div>
        <div className="name" css={nameWidthStyle}>
          {t('Discount item')}
        </div>
        <div className="price" css={priceWidthStyle}>
          {t('Amount of money')}
        </div>
        <div className="isAccountsReceivable" css={isAccountsReceivableWidthStyle}>
          {t('Outstanding sales')}
        </div>
        <div className="accountsReceivableName" css={accountsReceivableNameWidthStyle}>
          {t('Accounts receivable')}
        </div>
        <div className="taxWidthStyle" css={taxWidthStyle}>
          {t('Tax rate')}
        </div>
        <div className="taxClassWidthStyle" css={taxClassWidthStyle}>
          {t('Tax classification')}
        </div>
        <div
          style={{
            width: '3%',
          }}
        ></div>
      </div>
      <div
        css={scrollStyle}
        style={{
          height: windows.height - 270,
        }}
      >
        <ul css={discountSettingEditFormStyle}>
          {discountSettings &&
            discountSettings.map((discountSetting: DiscountSettingType, index) => (
              <li key={index}>
                <div className="subjectCode">
                  <input
                    name="discountSubjectCode"
                    type="text"
                    placeholder="0000"
                    css={inputStyle}
                    value={discountSetting.discountSubjectCode}
                    onChange={e => onChangeState(e, index)}
                  />
                </div>
                {discountSetting.type === DiscountType.Default && (
                  <>
                    <div className="name" css={defaultPointStyle}>
                      {discountSetting.name}
                    </div>
                    <div className="price" css={priceWidthStyle}>
                      {discountSetting.name === 'ポイント' ? '-' : Number(discountSetting.price).toLocaleString()}
                    </div>
                    <div className="isAccountsReceivable" css={isAccountsReceivableWidthStyle}>
                      <select
                        name="isAccountsReceivable"
                        css={inputSelectStyle}
                        value={discountSetting.isAccountsReceivable}
                        onChange={e => onChangeState(e, index)}
                      >
                        <option>-</option>
                        <option value={AccountsReceivable.AccountsReceivable}>{t('Outstanding sales')}</option>
                      </select>
                    </div>
                    <div className="accountsReceivableName" css={accountsReceivableNameWidthStyle}>
                      <select
                        name="accountsReceivableName"
                        css={inputSelectStyle}
                        value={discountSetting.accountsReceivableName ? discountSetting.accountsReceivableName : ''}
                        onChange={e => onChangeState(e, index)}
                      >
                        <option value="">-</option>
                        {accountsReceivableSettings &&
                          accountsReceivableSettings.map((accountsReceivableSetting, index) => (
                            <option key={index} value={accountsReceivableSetting.name}>
                              {accountsReceivableSetting.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="taxWidthStyle" css={taxWidthStyle}>
                      <input
                        name="tax"
                        type="text"
                        css={taxInputStyle}
                        placeholder="0"
                        value={discountSetting.tax}
                        onChange={e => onChangeState(e, index)}
                      />
                      %
                    </div>
                    <div className="taxClassWidthStyle" css={taxClassWidthStyle}>
                      <input
                        name="taxClass"
                        type="text"
                        css={inputStyle}
                        placeholder="000"
                        value={discountSetting.taxClass}
                        onChange={e => onChangeState(e, index)}
                      />
                    </div>
                    <div css={deleteButtonAreaStyle}></div>
                  </>
                )}
                {discountSetting.type === DiscountType.Custom && (
                  <>
                    <div className="name">
                      <input
                        name="name"
                        type="text"
                        placeholder={t('Coupon')}
                        css={inputStyle}
                        value={discountSetting.name}
                        onChange={e => onChangeState(e, index)}
                      />
                    </div>
                    <div className="price" css={priceWidthStyle}>
                      <input
                        name="price"
                        type="text"
                        placeholder="0000"
                        css={inputStyle}
                        value={discountSetting.price}
                        onChange={e => onChangeState(e, index)}
                      />
                    </div>
                    <div className="isAccountsReceivable" css={isAccountsReceivableWidthStyle}>
                      <select
                        name="isAccountsReceivable"
                        css={inputSelectStyle}
                        value={discountSetting.isAccountsReceivable}
                        onChange={e => onChangeState(e, index)}
                      >
                        <option>-</option>
                        <option value={AccountsReceivable.AccountsReceivable}>{t('Outstanding sales')}</option>
                      </select>
                    </div>
                    <div className="accountsReceivableName" css={accountsReceivableNameWidthStyle}>
                      <select
                        name="accountsReceivableName"
                        css={inputSelectStyle}
                        value={discountSetting.accountsReceivableName ? discountSetting.accountsReceivableName : ''}
                        onChange={e => onChangeState(e, index)}
                      >
                        <option value="">-</option>
                        {accountsReceivableSettings &&
                          accountsReceivableSettings.map((accountsReceivableSetting, index) => (
                            <option key={index} value={accountsReceivableSetting.name}>
                              {accountsReceivableSetting.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="taxWidthStyle" css={taxWidthStyle}>
                      <input
                        name="tax"
                        type="text"
                        css={taxInputStyle}
                        placeholder="0"
                        value={discountSetting.tax}
                        onChange={e => onChangeState(e, index)}
                      />
                      %
                    </div>
                    <div className="taxClassWidthStyle" css={taxClassWidthStyle}>
                      <input
                        name="taxClass"
                        type="text"
                        css={inputStyle}
                        placeholder="000"
                        value={discountSetting.taxClass}
                        onChange={e => onChangeState(e, index)}
                      />
                    </div>
                    <div css={deleteButtonStyle} onClick={() => deleteDiscountSetting(discountSetting.id, index)}>
                      <img src={require('@/static/images/delete_yellow.svg')} alt={t('Delete')} />
                    </div>
                  </>
                )}
              </li>
            ))}
        </ul>
        <div css={plusButtonRowStyle} onClick={addDiscountSetting}>
          <div>
            <img src={require('@/static/images/plus.svg')} alt={t('Add Discount Item')} />
            <p>{t('Add Discount Item')}</p>
          </div>
        </div>
      </div>
    </>
  )
}
export const subjectCodeWidthStyle = css({
  width: '12%',
  fontSize: 14,
})
const nameWidthStyle = css({
  width: '28%',
  fontSize: 14,
})
const priceWidthStyle = css({
  width: '10%',
  fontSize: 14,
})

const isAccountsReceivableWidthStyle = css({
  width: '10%',
  fontSize: 14,
})

const accountsReceivableNameWidthStyle = css({
  width: '15%',
  fontSize: 14,
})

const taxWidthStyle = css({
  width: '12%',
  fontSize: 14,
})

const taxClassWidthStyle = css({
  width: '10%',
})

const discountSettingEditHeaderStyle = css({
  background: '#F5F5F5',
  color: '#676767',
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  padding: '10px 32px',
  '.subjectCode': subjectCodeWidthStyle,
  '.name': nameWidthStyle,
  '.price': priceWidthStyle,
  '.isAccountsReceivable': isAccountsReceivableWidthStyle,
  '.accountsReceivableName': accountsReceivableNameWidthStyle,
  '.taxWidthStyle': taxWidthStyle,
  '.taxClassWidthStyle': taxClassWidthStyle,
})

const discountSettingEditFormStyle = css({
  padding: '24px 32px 12px',
  li: {
    display: 'flex',
    padding: '0 0 16px 0',
    div: {
      display: 'flex',
      paddingRight: 16,
      alignItems: 'center',
      '&.subjectCode': subjectCodeWidthStyle,
      '&.name': nameWidthStyle,
      '&.price': priceWidthStyle,
      '&.isAccountsReceivable': isAccountsReceivableWidthStyle,
      '&.accountsReceivableName': accountsReceivableNameWidthStyle,
    },
    input: {
      '&::placeholder': {
        color: '#CCCCCC',
      },
    },
    '.price': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      input: {
        width: 66,
        marginRight: 16,
      },
    },
  },
})
const inputStyle = css({
  width: '100%',
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  padding: '0 16px',
})

const taxInputStyle = css(inputStyle, {
  marginRight: 16,
})

const inputSelectStyle = css({
  height: 32,
  width: '100%',
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '86% center',
  backgroundSize: 9,
  display: 'block',
  padding: '0 0 0 16px',
})

const defaultPointStyle = css({
  height: 32,
  alignItems: 'center',
  fontSize: 14,
})
const deleteButtonStyle = css({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  width: '3%',
  img: {
    width: 30,
    height: 30,
  },
})
const deleteButtonAreaStyle = css({
  alignItems: 'center',
  width: '3%',
})
const plusButtonRowStyle = css({
  padding: '0px 32px 0',
  div: {
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    cursor: 'pointer',
    p: {
      color: '#F2A40B',
      fontSize: 12,
      paddingLeft: 10,
    },
    img: {
      width: 20,
      height: 20,
    },
  },
})
const scrollStyle = css({
  'overflow-y': 'auto',
  height: '68vh',
})
