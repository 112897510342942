import { TPlugin } from '@/models/plugin'

export const PluginCode = {
  Coupon: 'CU',
  Report: 'RPT',
  Smaregi: 'SMR',
  CleaningManager: 'CM',
  GuestApp: 'GUE',
  GuestAppAssetCustom: 'GAC',
  GuestAppDesign: 'GAD',
  ReservationTLLincoln: 'TL',
  ReservationNeppan: 'NP',
  ReservationNeppanTwoWay: 'NPT',
  ReservationTemairazu: 'TM',
  ReservationChillnn: 'CN',
  ReservationRakutsu: 'RK',
  ReservationRakutsuForToyo: 'RK_TOYO',
  MailDelivery: 'MD',
  BackupReceiptPdf: 'BR',
  SmartKey: 'SK',
  SpaceManagement: 'SPM',
} as const
export type PluginCode = (typeof PluginCode)[keyof typeof PluginCode]

export const setHasRoomManagerPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'RM')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }
}

export const setHasSalesManagerPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'SM')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }
}

export const setHasCustomCheckinPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'CC')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }
}

export const setHasTlTwoWayPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'TT')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }
}

export const setHasMobileRequestPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'MR')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }
}

export const setHasTicketPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'TKT')
    return result.length !== 0
  }
}

export const setHasPrinterPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'PRT')
    return result.length !== 0
  }
}

export const setHasPaymentPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'PA')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }
}

export const setHasRemoteLockPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'RL')
    if (result.length !== 0) {
      return true
    }

    if (result.length === 0) {
      return false
    }
  }
}

export const setHasSelfCheckinPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'SC')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }
}

export const setHasRepeatPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'RP')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }
}

export const setHasCleaningManagerPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'CM')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }
}

export const setHasAccountingInterlockingPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'AI')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }
}

export const setHasReservationAnalysisPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'RA')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }
}

export const setHasSmartKeyPlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'SK')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }

  return false
}

export const setHasMiwaLockPlugin: (plugins: TPlugin[]) => boolean = (plugins: TPlugin[]) => {
  if (plugins) {
    const result = plugins.filter((element: TPlugin) => element.code === 'MWL')
    return result.length !== 0
  }

  return false
}

export const setHasNotPaymentSalePlugin = plugins => {
  if (plugins) {
    const result = plugins.filter(element => element.code === 'NPS')
    if (result.length !== 0) {
      return true
    }
    if (result.length === 0) {
      return false
    }
  }
}

export const checkActivePlugin = (plugins, code: PluginCode) => {
  if (!plugins?.length || !code) return false

  return !!plugins.find(plugin => plugin.code === code)?.isEnabled
}

export const hasAnyReservationPlugin = (plugins): boolean => {
  return (
    checkActivePlugin(plugins, PluginCode.ReservationTLLincoln) ||
    checkActivePlugin(plugins, PluginCode.ReservationNeppan) ||
    checkActivePlugin(plugins, PluginCode.ReservationTemairazu) ||
    checkActivePlugin(plugins, PluginCode.ReservationChillnn) ||
    checkActivePlugin(plugins, PluginCode.ReservationRakutsu) ||
    checkActivePlugin(plugins, PluginCode.ReservationRakutsuForToyo)
  )
}

export const hasMailDeliveryPlugin = plugins => {
  return checkActivePlugin(plugins, PluginCode.MailDelivery)
}

export const hasBackupReceiptPdfPlugin = plugins => {
  return checkActivePlugin(plugins, PluginCode.BackupReceiptPdf)
}
