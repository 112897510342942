import { env } from '@/libs/env'
import { v2 } from '@google-cloud/translate'
import { Loader } from '@googlemaps/js-api-loader'

/**
 * Google翻訳API
 *
 * @param text
 */
export const translateText = text => {
  return new Promise((resolve, reject) => {
    if (Array.isArray(text) && !text.length) {
      return resolve([[], [], []])
    }

    const options = {
      model: 'base',
      source: 'ja',
      key: env('GOOGLE_MAPS_KEY'),
    }

    const translateClient = new v2.Translate(options)

    // テキストを英語・中国語・韓国語に翻訳し、配列に格納して返す
    Promise.all([
      translateClient.translate(text, 'en'),
      translateClient.translate(text, 'zh'),
      translateClient.translate(text, 'ko'),
      translateClient.translate(text, 'zh-TW'),
    ])
      .then(responses => {
        const [enResponse, zhResponse, koResponse, zhTwResponse] = responses
        return resolve([enResponse[0], zhResponse[0], koResponse[0], zhTwResponse[0]])
      })
      .catch(() => {
        return reject('データの送受信に失敗しました\n時間をおいて再度お試し下さい')
      })
  })
}

export const translateBy = async (text: string, to: string): Promise<string> => {
  const translateClient = new v2.Translate({ key: env('GOOGLE_MAPS_KEY') })
  const resp = await translateClient.translate(text, to)
  return resp ? resp[0] : ''
}

const loader = new Loader({
  apiKey: env('GOOGLE_MAPS_KEY'),
  version: 'beta',
  libraries: ['places'],
})

export type Place = google.maps.places.Place & { address: string }

export const loadAutocomplete = async (hotelAddress: string, inputDomId: string, onChange: (placeId: string) => void) => {
  const { Autocomplete } = await loader.importLibrary('places')

  const input = document.getElementById(inputDomId) as HTMLInputElement
  const options: google.maps.places.AutocompleteOptions = {
    // bounds: hotelAddress,
    componentRestrictions: { country: 'jp' },
    fields: ['place_id'],
    strictBounds: false,
    types: ['establishment'],
  }
  const autocomplete = new Autocomplete(input, options)
  autocomplete.addListener('place_changed', () => {
    onChange(autocomplete.getPlace().place_id!)
  })
}

export const searchRoute = async (from: string, to: string): Promise<google.maps.DirectionsRoute | undefined> => {
  const { DirectionsService } = await loader.importLibrary('routes')
  const { routes } = await new DirectionsService().route({
    origin: from,
    destination: to,
    travelMode: google.maps.TravelMode.WALKING,
  })
  return routes && routes[0]
}

export const fetchPlaceDetail = async (placeId: string): Promise<google.maps.places.PlaceResult | null> => {
  const { PlacesService } = await loader.importLibrary('places')
  const placeService = new PlacesService(document.createElement('div'))
  return new Promise(resolve => {
    placeService.getDetails(
      {
        language: 'ja',
        placeId,
        // @see https://developers.google.com/maps/documentation/javascript/places?hl=ja#place_details_fields
        fields: ['address_components', 'formatted_phone_number', 'geometry.location', 'name', 'opening_hours', 'website', 'type'],
      },
      resolve,
    )
  })
}

export const searchMapPlace = async (keyword: string): Promise<Place[]> => {
  const { Place } = await loader.importLibrary('places')
  const request = {
    textQuery: keyword,
    fields: ['displayName', 'formattedAddress'],
    maxResultCount: 5,
    region: 'jp',
  }
  const { places } = await Place.searchByText(request)
  return places.map(place => {
    Object.assign(place, { address: place.formattedAddress?.split(' ').slice(1).join(' ') })
    return place as Place
  })
}
