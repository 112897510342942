import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import dayjs from 'dayjs'

// constants
import {
  wholeContainerStyle,
  mainColumnStyle,
  HEADER_HEIGHT,
  MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL,
  mainContainerHeaderBarStyle,
  headerBarLeftWrapperStyle,
  mainContainerStyle,
  listContainerStyle,
  listContentsWrapperStyle,
  CONTAINER_WITH_HEADER_BUTTON_LIST_MARGIN,
  listContentsStyle,
  TAB_HEIGHT,
} from '@/constants/layout'

// components
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { LoadingFull } from '@/components/molecules/loading-full'
import { DateRangeField } from '@/components/molecules/date-range-field'
import { ReloadButton } from '@/components/atoms/reload-button'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'

// apis
import { fetchOrders, updateOrderStatus } from '@/apis/aipass'

// models
import { OrderType, OrderStatusType, ProductsType, OrderPaymentMethod } from '@/models/mobile-request/order'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { Checkbox } from '@/components/atoms/checkbox'
import { useQuery } from '@/hooks/use-query'
import { Button } from '@/components/atoms'
import { ButtonType } from '@/components/atoms/button'
import { useErrorHandler } from '@/hooks/use-error-handler'

export const RequestManager: React.FC = () => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const windows = useWindowSize()
  const history = useHistory()
  const [orders, setOrders] = useState<OrderType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [beginDate, setBeginDate] = useState<string>(`${dayjs().add(-1, 'day').format('YYYY-MM-DD')}`)
  const [endDate, setEndDate] = useState<string>(`${dayjs().format('YYYY-MM-DD')}`)
  const [page, setPage] = useState<number>(1)
  const [listTotal, setListTotal] = useState(0)
  const [checkedOrderIds, setCheckedOrderIds] = useState<string[]>([])
  const [checkStatusPerPage, setCheckStatusPerPage] = useState<number[]>([])
  const limit = 30
  const query = useQuery()

  useEffect(() => {
    beginDate && endDate && page && _fetchOrders()
  }, [beginDate, endDate, page])

  const refresh = async () => {
    setCheckedOrderIds([])
    setCheckStatusPerPage([])
    await _fetchOrders()
  }

  const _fetchOrders = async () => {
    setIsLoading(true)
    const formatedBeginDate = `${beginDate} 00:00:00`
    const formatedEndDate = `${endDate} 23:59:59`
    await fetchOrders(formatedBeginDate, formatedEndDate, page, limit)
      .then((res: { orders: OrderType[]; totalListNumber: number }) => {
        setOrders([]) // To reset the table position to the top
        setOrders(res?.orders)
        setListTotal(res?.totalListNumber)
      })
      .finally(() => setIsLoading(false))
  }

  const changeRangeDate = (changedBeginDate: string, changedEndDate: string) => {
    setBeginDate(changedBeginDate)
    setEndDate(changedEndDate)
    history.push({
      pathname: '/request-manager',
      search: `?beginDate=${changedBeginDate}&endDate=${changedEndDate}&page=${page}`,
    })
  }

  const changePage = changedPage => {
    history.push({
      pathname: '/request-manager',
      search: `?beginDate=${beginDate}&endDate=${endDate}&page=${changedPage}`,
    })
  }

  const orderContents = (order: OrderType): string => {
    return order.products.map((product: ProductsType) => `${product.nameJa} x ${product.numberOfItems}`).join('\n')
  }

  const _approvedStatus = (status: number) => {
    switch (status) {
      case ApprovedStatusType.Checkin:
        return 'checkin'
      case ApprovedStatusType.Stay:
        return 'stay'
      case ApprovedStatusType.Checkout:
        return 'checkout'
    }
  }

  const bulkUpdateSupportStatus = async (isSupported: boolean) => {
    try {
      setIsLoading(true)
      await updateOrderStatus(checkedOrderIds, isSupported)
      await refresh()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const toggleAllOrderCheckbox = (isChecked: boolean) => {
    const currentPageOrderIds = orders.map(order => order.id)
    const filteredCurrentPage = checkedOrderIds.filter(i => currentPageOrderIds.indexOf(i) === -1)
    if (isChecked) {
      setCheckedOrderIds([...filteredCurrentPage, ...currentPageOrderIds])
      setCheckStatusPerPage([...checkStatusPerPage, page])
    } else {
      setCheckedOrderIds(filteredCurrentPage)
      setCheckStatusPerPage(checkStatusPerPage.filter(p => p !== page))
    }
  }

  const toggleOrderCheckbox = (isChecked: boolean, targetId: string) => {
    if (isChecked) {
      setCheckedOrderIds([...checkedOrderIds, targetId])
    } else {
      const filteredCurrentPage = checkedOrderIds.filter(id => id !== targetId)
      setCheckedOrderIds(filteredCurrentPage)
      setCheckStatusPerPage(checkStatusPerPage.filter(p => p !== page))
    }
  }

  useEffect(() => {
    if (query.has('beginDate')) {
      setBeginDate(query.get('beginDate') as string)
    }
    if (query.has('endDate')) {
      setEndDate(query.get('endDate') as string)
    }
    if (query.has('page')) {
      setPage(Number(query.get('page')))
    }
  }, [])

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header title={t('Request management')} />
        <div css={mainContainerStyle}>
          <div css={mainContainerHeaderBarStyle}>
            <div css={headerBarLeftWrapperStyle}>
              <DateRangeField beginDate={beginDate} endDate={endDate} changeRangeDate={changeRangeDate} />
              <ReloadButton marginLeft={12} onClick={() => refresh()} />
            </div>
          </div>
          <div css={listContainerStyle}>
            <div css={orderListHeaderStyle}>
              {checkedOrderIds.length === 0 && <div css={orderListTitleStyle}>{t('Request list')}</div>}
              {checkedOrderIds.length !== 0 && (
                <div className="selected-button-area">
                  <div css={orderListTitleStyle}>{`${checkedOrderIds.length}${t('item selected')}`}</div>
                  <Button
                    buttonType={ButtonType.Secondary}
                    width={107}
                    height={32}
                    fontSize={12}
                    onClick={() => bulkUpdateSupportStatus(true)}
                  >
                    {t('Changed to supported')}
                  </Button>
                  <Button
                    buttonType={ButtonType.NonStyle}
                    width={107}
                    height={32}
                    fontSize={12}
                    onClick={() => bulkUpdateSupportStatus(false)}
                  >
                    {t('Changed to unsupported')}
                  </Button>
                </div>
              )}

              <AccommodationPaging page={Number(page)} setPage={setPage} total={listTotal} limit={limit} onChange={changePage} />
            </div>
            <ul css={orderListLabelStyle}>
              <li css={checkboxStyle}>
                <Checkbox value={checkStatusPerPage.includes(page)} onChange={toggleAllOrderCheckbox} />
              </li>
              <li css={guestStyle}>{t('Full name')}</li>
              <li css={statusStyle}>{t('Status')}</li>
              <li css={roomNumberStyle}>{t('Room number')}</li>
              <li css={contentsStyle}>{t('Content')}</li>
              <li css={totalSalesStyle}>{t('Total sales')}</li>
              <li css={paymentMethodStyle}>{t('Method of payment')}</li>
              <li css={orderedAtStyle}>{t('Receipt date and time')}</li>
            </ul>
            <div
              id="listContents"
              css={listContentsWrapperStyle}
              style={{
                maxHeight:
                  windows.height -
                  HEADER_HEIGHT -
                  MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL -
                  CONTAINER_WITH_HEADER_BUTTON_LIST_MARGIN -
                  TAB_HEIGHT,
              }}
            >
              <ul css={[listContentsStyle, orderListContentsStyle]}>
                {orders &&
                  orders.map((order: OrderType, index) => {
                    const rowComponent = (
                      <>
                        <div css={[checkboxStyle, indentToMatchImageStyle]}>
                          <Checkbox
                            value={checkedOrderIds.includes(order.id)}
                            onChange={checked => toggleOrderCheckbox(checked, order.id)}
                          />
                        </div>
                        <div css={guestStyle}>
                          <img
                            src={order.guestPicture ? order.guestPicture : require('@/static/images/user.svg')}
                            css={guestPictureStyle}
                          />
                          <p css={textOverflowStyle}>{order.guestName ? order.guestName : '-'}</p>
                          <span>{t('Mr / Ms')}</span>
                        </div>
                        <div css={[statusStyle, indentToMatchImageStyle]}>
                          {order.status === OrderStatusType.Uncompleted ? (
                            <p css={activeStatusStyle}>{t('Not compatible')}</p>
                          ) : (
                            <p>{t('Done')}</p>
                          )}
                        </div>
                        <div css={[roomNumberStyle, indentToMatchImageStyle]}>{order.roomNumber}</div>
                        <div css={[contentsStyle, indentToMatchImageStyle]}>{orderContents(order)}</div>
                        <div css={[totalSalesStyle, indentToMatchImageStyle]}>
                          ¥{order.products.reduce((sum, product) => sum + Number(product.price), 0).toLocaleString()}
                        </div>
                        <div css={[paymentMethodStyle, indentToMatchImageStyle]}>{OrderPaymentMethod[order.paymentMethod]}</div>
                        <div css={indentToMatchImageStyle}>{dayjs(order.createdAt).format(t('MM-DD(ddd) HH:mm'))}</div>
                      </>
                    )
                    return order.checkinId ? (
                      <Link
                        key={order.id}
                        css={[!order.requestRead ? orderUnReadStyle : orderReadStyle, { color: '#000', ':hover': { color: '#000' } }]}
                        to={`${_approvedStatus(order.approvedStatus)}/${order.checkinId}?detailTabType=request`}
                      >
                        {rowComponent}
                      </Link>
                    ) : (
                      <div key={order.id} css={orderReadonlyStyle}>
                        {rowComponent}
                      </div>
                    )
                  })}
              </ul>
            </div>
          </div>
        </div>
        <LoadingFull isLoading={isLoading} />
      </div>
    </div>
  )
}

const textOverflowStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const orderListHeaderStyle = css({
  display: 'flex',
  alignItems: 'center',
  background: '#fff',
  height: 50,
  padding: '0 32px',
  justifyContent: 'space-between',
  borderRadius: '5px 5px 0 0',
  '.selected-button-area': {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '> div': {
      paddingRight: 16,
    },
  },
})

const orderListTitleStyle = css({
  fontSize: 16,
  color: '#272727',
  fontWeight: 'bold',
})

const orderListLabelStyle = css({
  textAlign: 'left',
  color: '#676767',
  fontSize: 12,
  display: 'flex',
  backgroundColor: '#F5F5F5',
  borderBottom: '1px solid #f2f2f2',
  padding: '10px 8px 10px 32px',
  fontWeight: 'bold',
  alignItems: 'center',
})

const checkboxStyle = css({ paddingRight: 24 })
const guestStyle = css({
  width: '19%',
  display: 'flex',
  alignItems: 'center',
  paddingRight: 24,
  height: 'fit-content',
  span: {
    margin: '0 0 0 auto',
  },
})
const statusStyle = css({ width: '8%' })
const roomNumberStyle = css(textOverflowStyle, {
  width: '8%',
  paddingRight: 32,
})
const contentsStyle = css(textOverflowStyle, {
  width: '25%',
  paddingRight: 32,
  whiteSpace: 'pre-wrap',
  lineHeight: '19px !important',
  marginTop: 'calc((14px - 19px) / 2)',
})
const totalSalesStyle = css({ width: '10%' })
const paymentMethodStyle = css({ width: '12%' })
const orderedAtStyle = css({ width: '12%' })

const orderListContentsStyle = css({
  borderRadius: '0 0 5px 5px',
  'li:last-child': {
    borderRadius: '0 0 5px 5px',
  },
})

const orderReadStyle = css({
  display: 'flex',
  alignItems: 'flexStart',
  paddingLeft: 32,
  paddingTop: 16,
  paddingBottom: 16,
  minHeight: 72,
  background: '#f6f6f6',
  fontSize: 14,
  color: '#272727',
  fontWeight: 'normal',
  ':hover': {
    cursor: 'pointer',
    boxShadow: '0px 3px 6px #0000000f',
    position: 'relative',
  },
  '*': {
    lineHeight: '14px',
  },
})

const orderUnReadStyle = css(orderReadStyle, {
  background: '#ffffff',
  fontWeight: 'bold',
})

const orderReadonlyStyle = css(orderUnReadStyle, {
  ':hover': { cursor: 'auto' },
})

const activeStatusStyle = css({
  color: '#F2A40B',
})

const guestPictureStyle = css({
  marginRight: 24,
  width: 40,
  height: 40,
  borderRadius: '50%',
})

const indentToMatchImageStyle = css({
  paddingTop: 'calc((40px - 14px) / 2)',
})
