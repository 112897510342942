import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { mainColumnStyle, mainContainerStyle, wholeContainerStyle } from '@/constants/layout'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Assignment } from '@/components/organisms/space-manager/assignment'
import { SpaceAsset, isSpaceAsset } from '@/models/guest-app/asset'
import { getAssetList } from '@/apis/aipass'
import { LoadingFull } from '@/components/molecules/loading-full'
import { isEmpty } from 'lodash'

export const SpaceManager: React.FC = () => {
  const [spaceAssets, setSpaceAssets] = useState<SpaceAsset[]>([])
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const fetchSpaceAsset = async () => {
    setLoading(true)
    const result = await getAssetList()
    setSpaceAssets(result.filter(asset => isSpaceAsset(asset)) as SpaceAsset[])
    setLoading(false)
  }

  useEffect(() => {
    fetchSpaceAsset()
  }, [])

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div css={mainColumnStyle}>
        <Header title={t('Space-Management')} />
        <div css={mainContainerStyle}>{!isEmpty(spaceAssets) && <Assignment spaceAssets={spaceAssets} />}</div>
      </div>
      <LoadingFull isLoading={loading} />
    </div>
  )
}
