import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SerializedStyles, css } from '@emotion/core'
import { RoomType, CleaningStatusType, RoomUsageStatusType, CheckListType } from '@/constants/cleaning-manager'
import i18n, { LanguageType } from '@/i18n'
import { Checkbox } from '@/components/atoms/checkbox'
import { Select } from 'antd'
import { putCleaningRoomCheckList, updateGuestRoomStatusForCleaningManager } from '@/apis/aipass'
import dayjs, { Dayjs } from 'dayjs'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { hasCleaningMemo } from '@/models/reservation/reservation-note'

type ContainerProps = {
  room: RoomType
  setIsOpenRoomDetailModal: (arg0: boolean) => void
  setSelectedRoomId: (arg0: string) => void
}

export const CleaningManagerContext = createContext<{
  checkList: CheckListType[]
  selectedDate: Dayjs
  reloadRoomList: () => void
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
}>({ checkList: [], selectedDate: dayjs(), reloadRoomList: () => {} })

export const Room: React.FC<ContainerProps> = ({ room, setIsOpenRoomDetailModal, setSelectedRoomId }) => {
  const { t } = useTranslation()
  const [cleaningStatusStyle, setCleaningStatusStyle] = useState<SerializedStyles>()
  const [roomUsageStatusText, setRoomUsageStatusText] = useState<string>('')
  const [hasInstruction, setHasInstruction] = useState<boolean>(false)
  const [hasComment, setHasComment] = useState<boolean>(false)
  const [hasPhoto, setHasPhoto] = useState<boolean>(false)
  const [roomCheckList, setRoomCheckList] = useState<Array<CheckListType & { isChecked: boolean }>>([])
  const [isDisabledCheckList, setIsDisabledCheckList] = useState<boolean>(true)
  const { errorHandler } = useErrorHandler()

  const { checkList, selectedDate, reloadRoomList, setIsLoading } = useContext(CleaningManagerContext)

  const computedRoomUsageStatus = (roomUsageStatus: RoomUsageStatusType) => {
    setRoomUsageStatusText(t('cleaningManager.Vacant'))
    switch (roomUsageStatus) {
      case RoomUsageStatusType.Stay:
        setRoomUsageStatusText(t('cleaningManager.Occupied'))
        break
      case RoomUsageStatusType.Away:
        setRoomUsageStatusText(t('cleaningManager.Out'))
        break
      case RoomUsageStatusType.CheckOutScheduled:
        setRoomUsageStatusText(t('cleaningManager.Checkout Soon'))
        break
      case RoomUsageStatusType.CheckedOut:
        setRoomUsageStatusText(t('cleaningManager.Done Checkout'))
        break
    }
  }

  const computedRoomStyle = (cleaningStatus: CleaningStatusType, roomUsageStatus: RoomUsageStatusType) => {
    const isMultipleNights = ([RoomUsageStatusType.Stay, RoomUsageStatusType.Away] as Array<number>).includes(roomUsageStatus as number)

    const isCleaningRequired = ([RoomUsageStatusType.Unused, RoomUsageStatusType.Away, RoomUsageStatusType.CheckedOut] as Array<
      number
    >).includes(roomUsageStatus as number)

    switch (cleaningStatus) {
      case CleaningStatusType.NotCleaning:
        if (isMultipleNights) {
          setCleaningStatusStyle(css([stayCleaningStatusColor, !isCleaningRequired && dirtyRoomColor]))
        } else {
          setCleaningStatusStyle(css([outCleaningStatusColor, !isCleaningRequired && dirtyRoomColor]))
        }
        break
      case CleaningStatusType.Cleaned:
        setCleaningStatusStyle(cleanStatusColor)
        break
      case CleaningStatusType.Inspected:
        setCleaningStatusStyle(inspectedStatusColor)
        break
      case CleaningStatusType.DoNotDisturb:
        setCleaningStatusStyle(ddStatusColor)
        break
      default:
        setCleaningStatusStyle(dirtyStatusColor)
        break
    }
  }

  const openDetailRoomModal = id => {
    setIsOpenRoomDetailModal(true)
    setSelectedRoomId(id)
  }

  useEffect(() => {
    setRoomCheckList(
      checkList.map(list => ({
        ...list,
        isChecked: !!room.checkList.find(roomCheckItem => roomCheckItem.id === list.id)?.isChecked,
      })),
    )
  }, [room, checkList])

  useEffect(() => {
    computedRoomUsageStatus(room.roomUsageStatus)
    computedRoomStyle(room.cleaningStatus, room.roomUsageStatus)
    setHasInstruction(room.adminComment.length > 0)
    setHasComment(room.staffReport.length > 0)
    setHasPhoto(room.photos.length > 0)
    setIsDisabledCheckList(room.cleaningStatus !== CleaningStatusType.NotCleaning)
  }, [room])

  const changeRoomStatus = async (newStatus: CleaningStatusType) => {
    try {
      setIsLoading!(true)
      if (newStatus === CleaningStatusType.NotCleaning) {
        clearAllCheckList()
      }
      await updateGuestRoomStatusForCleaningManager({
        guestRoomId: room.guestRoomId,
        dateOf: selectedDate,
        staffReport: undefined,
        cleaningStatus: newStatus,
        roomUsageStatus: undefined,
      })

      reloadRoomList()
    } catch (error) {
      errorHandler(error)
    }
  }

  const clearAllCheckList = async () => {
    for (let i = 0; i < roomCheckList.length; i += 1) {
      await putCleaningRoomCheckList({
        guestRoomId: room.guestRoomId,
        dateOf: selectedDate.format('YYYY-MM-DD'),
        checkListId: roomCheckList[i].id,
        isChecked: false,
      })
    }
    setRoomCheckList(
      roomCheckList.map(list => ({
        ...list,
        isChecked: false,
      })),
    )
  }

  const changeCheckList = (checkListId: number, isChecked: boolean) => {
    setRoomCheckList(
      roomCheckList.map(list => {
        if (list.id === checkListId) {
          list.isChecked = isChecked
        }
        return list
      }),
    )
    putCleaningRoomCheckList({
      guestRoomId: room.guestRoomId,
      dateOf: selectedDate.format('YYYY-MM-DD'),
      checkListId,
      isChecked,
    })
  }

  return (
    <div css={[borderRoomStyled, cleaningStatusStyle]}>
      <div css={roomHeaderStyle} />
      <div css={roomInfoStyle}>
        <span className="room-number-text">{room.roomNumber}</span>
        <Select
          value={room.cleaningStatus}
          className="room-cleaning-status-select"
          suffixIcon={
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6">
              <path id="ic_play_arrow_24px" d="M0,0V8L6,4Z" transform="translate(8) rotate(90)" fill="#f2a40b" />
            </svg>
          }
          placeholder={t('Please select')}
          onChange={value => changeRoomStatus(value)}
        >
          <option value={CleaningStatusType.NotCleaning} style={cleaningStatusSelectOptionStyle}>
            {t('cleaningManager.Dirty')}
          </option>
          <option value={CleaningStatusType.Cleaned} style={cleaningStatusSelectOptionStyle}>
            {t('cleaningManager.Clean')}
          </option>
          <option value={CleaningStatusType.Inspected} style={cleaningStatusSelectOptionStyle}>
            {t('cleaningManager.Inspected')}
          </option>
          <option value={CleaningStatusType.DoNotDisturb} style={cleaningStatusSelectOptionStyle}>
            {t('cleaningManager.Do not Disturb')}
          </option>
          <option value={CleaningStatusType.NotRequired} style={cleaningStatusSelectOptionStyle}>
            {t('cleaningManager.No Cleaning')}
          </option>
          <option value={CleaningStatusType.Unused} style={cleaningStatusSelectOptionStyle}>
            -&emsp;
          </option>
        </Select>
        <span className="inline-text">
          <p className="room-usage-status-text" style={{ letterSpacing: i18n.language === LanguageType.ja ? '0px' : '0.5px' }}>
            {roomUsageStatusText}
          </p>
          <p className="next-check-in-text">
            IN : {room.nextReservation?.roomCheckinAt ? dayjs(room.nextReservation.roomCheckinAt).format('HH:mm') : '--:--'}
          </p>
        </span>
      </div>
      {roomCheckList.length > 0 && (
        <div css={roomCheckListStyle}>
          {roomCheckList.map(i => (
            <Checkbox
              key={`${room.guestRoomId}_${i.id}`}
              value={i.isChecked}
              onChange={value => changeCheckList(i.id, value)}
              label={i.name}
              disabled={isDisabledCheckList}
              style={css({ width: 'fit-content' })}
            />
          ))}
        </div>
      )}
      <div css={roomFooterStyle} onClick={() => openDetailRoomModal(room.guestRoomId)}>
        <p>{t('Details')}</p>
        <div>
          {hasPhoto && <img className="icon" src={require('@/static/images/camera_icon_gray.svg')} alt="" />}
          {(hasInstruction || hasCleaningMemo(room.nextReservation?.notes)) && (
            <img className="icon" src={require('@/static/images/cleaning_manager/icon_instruction.svg')} alt="" />
          )}
          {hasComment && <img className="icon" src={require('@/static/images/cleaning_manager/icon_comment.svg')} alt="Icon Report" />}
        </div>
      </div>
    </div>
  )
}

const borderRoomStyled = css({
  width: 'calc(100% / 5)',
  padding: '8px 4px',
  color: '#676767',
})

const roomHeaderStyle = css({
  height: 7,
  backgroundColor: '#CCCCCC',
  borderRadius: '5px 5px 0 0',
  opacity: 1,
})

const roomInfoStyle = css({
  border: '1px solid #CCCCCC',
  borderTop: 'none',
  padding: '12px 15px 16px 15px',
  display: 'grid',
  '.inline-text': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '.room-number-text': {
    fontSize: 14,
    lineHeight: '14px',
    fontWeight: 'bold',
    letterSpacing: '1.4px',
  },
  '.room-cleaning-status-select': {
    width: '100%',
    letterSpacing: '1.8px',
    margin: '2px 0',
    height: 27,
    '.ant-select-selection': {
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      boxShadow: 'none',
      height: 27,
    },
    '.ant-select-selection__rendered': {
      paddingLeft: '0px !important',
      marginLeft: '0px !important',
      lineHeight: '27px',
    },
    '.ant-select-arrow': {
      paddingLeft: 6,
      height: 0,
      position: 'initial',
      marginTop: '-6px !important',
    },
  },
  '.room-usage-status-text': {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  '.next-check-in-text': {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 'bold',
    color: '#A3A3A3',
    letterSpacing: 0,
  },
  '.ant-select-selection-selected-value': {
    fontSize: 18,
    lineHeight: '27px',
    fontWeight: 'bold',
    letterSpacing: 1.8,
    maxWidth: '100%',
  },
})

const roomCheckListStyle = css({
  border: '1px solid #CCCCCC',
  borderTop: 'none',
  padding: '12px 15px 16px 15px',
  '> div': {
    paddingBottom: '12px',
    ':last-child': {
      paddingBottom: 0,
    },
    '> p': {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 'bold',
    },
  },
})

const roomFooterStyle = css({
  border: '1px solid #CCCCCC',
  borderTop: 'none',
  padding: '8px 15px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '0 0 5px 5px',
  cursor: 'pointer',
  '> div': {
    display: 'flex',
    '.icon': {
      width: '16px',
      height: '16px',
      marginLeft: '4.5px',
    },
  },
  '> p': {
    fontSize: 12,
    lineHeight: '18px',
  },
})

const cleaningStatusSelectOptionStyle = {
  fontSize: '14px',
  lineHeight: '18px',
  ontWeight: 'bold',
}

const dirtyRoomColor = css({
  '> div, .ant-select-selection': { backgroundColor: '#FFFFFF' },
})
const outCleaningStatusColor = css({
  '.ant-select-selection-selected-value': { color: '#F2A40B !important' },
  '.ant-select-arrow path': { fill: '#F2A40B' },
  '> div:first-of-type': { backgroundColor: '#F2A40B' },
  '> div, .ant-select-selection': { backgroundColor: '#FFFCDD' },
})
const stayCleaningStatusColor = css({
  '.ant-select-selection-selected-value': { color: '#F47110 !important' },
  '.ant-select-arrow path': { fill: '#F47110' },
  '> div:first-of-type': { backgroundColor: '#F47110' },
  '> div, .ant-select-selection': { backgroundColor: '#FEF3F0' },
})
const dirtyStatusColor = css({
  '.ant-select-selection-selected-value': { color: '#676767 !important' },
  '.ant-select-arrow path': { fill: '#676767' },
  '> div:first-of-type': { backgroundColor: '#CCCCCC' },
})
const ddStatusColor = css({
  '.ant-select-selection-selected-value': { color: '#676767 !important' },
  '.ant-select-arrow path': { fill: '#676767' },
  '> div:first-of-type': { backgroundColor: '#676767' },
  '> div, .ant-select-selection': { backgroundColor: '#F2F2F2' },
})
const cleanStatusColor = css({
  '.ant-select-selection-selected-value': { color: '#3E85CC !important' },
  '.ant-select-arrow path': { fill: '#3E85CC' },
  '> div:first-of-type': { backgroundColor: '#3E85CC' },
  '> div, .ant-select-selection': { backgroundColor: '#EFF9FE' },
})
const inspectedStatusColor = css({
  '.ant-select-selection-selected-value': { color: '#7CC931 !important' },
  '.ant-select-arrow path': { fill: '#7CC931' },
  '> div:first-of-type': { backgroundColor: '#7CC931' },
  '> div, .ant-select-selection': { backgroundColor: '#F5FDF2' },
})
