import React from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

// models
import { MarketSegmentType } from '@/models/reservation-market-segment'

type Props = {
  editMarketSegments: MarketSegmentType[]
  onChangeState: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index) => void
  deleteMarketSegment: (id, index) => void
  addMarketSegment: () => void
}

export const SettingMarketSegmentEditForm: React.FC<Props> = ({
  editMarketSegments,
  onChangeState,
  deleteMarketSegment,
  addMarketSegment,
}) => {
  const windows = useWindowSize()
  const { t } = useTranslation()

  return (
    <>
      <div css={settingEditHeaderStyle}>
        <div css={segmentCodeWidthStyle}>{t('Segment code')}</div>
        <div css={nameWidthStyle}>{t('Name')}</div>
        <div css={groupWidthStyle}>{t('Group')}</div>
      </div>
      <div
        css={scrollStyle}
        style={{
          height: windows.height - 270,
        }}
      >
        <ul css={settingEditFormStyle}>
          {editMarketSegments &&
            editMarketSegments.map((marketSegment: MarketSegmentType, index) => (
              <li key={`${marketSegment.id}${index}`}>
                {/* segment code */}
                <div css={segmentCodeWidthStyle}>
                  <input
                    name="code"
                    type="text"
                    placeholder="0000"
                    css={inputStyle}
                    value={marketSegment.code ?? ''}
                    onChange={e => onChangeState(e, index)}
                  />
                </div>
                {/* name */}
                <div css={nameWidthStyle}>
                  <input
                    name="name"
                    type="text"
                    placeholder={t('Segment')}
                    css={inputStyle}
                    value={marketSegment.name ?? ''}
                    onChange={e => onChangeState(e, index)}
                  />
                </div>
                {/* group */}
                <div css={groupWidthStyle}>
                  <select
                    name="groupType"
                    css={selectBoxStyle}
                    defaultValue={marketSegment.groupType ?? '-'}
                    onChange={e => onChangeState(e, index)}
                  >
                    {!marketSegment.id && <option value="-">-</option>}
                    <option value="0">{t('Individual')}</option>
                    <option value="1">{t('Group')}</option>
                  </select>
                </div>
                <div css={deleteButtonStyle} onClick={() => deleteMarketSegment(marketSegment.id, index)}>
                  <img src={require('@/static/images/delete_yellow.svg')} alt={t('Delete')} />
                </div>
              </li>
            ))}
        </ul>
        <div css={plusButtonRowStyle} onClick={addMarketSegment}>
          <img src={require('@/static/images/plus.svg')} alt={t('Plus')} />
          <p>{t('Add segment')}</p>
        </div>
      </div>
    </>
  )
}

const segmentCodeWidthStyle = css({
  width: '15%',
})

const nameWidthStyle = css({
  flex: 1,
})

const groupWidthStyle = css({
  width: '15%',
})

const settingEditHeaderStyle = css({
  background: '#F5F5F5',
  color: '#676767',
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  padding: '10px 90px 10px 32px',
})

const settingEditFormStyle = css({
  padding: '24px 32px 12px',
  li: {
    display: 'flex',
    padding: '0 0 16px 0',
    div: {
      display: 'flex',
      paddingRight: 16,
    },
    input: {
      '&::placeholder': {
        color: '#CCCCCC',
      },
    },
    '.price': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      input: {
        width: 66,
        marginRight: 16,
      },
    },
  },
})

const inputStyle = css({
  width: '100%',
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  padding: '0 16px',
})

const selectBoxStyle = css({
  height: 32,
  width: '100%',
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  display: 'block',
  paddingLeft: 14,
  paddingRight: 5,
  marginRight: 10,
  backgroundPosition: '90% center',
})

const deleteButtonStyle = css({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  img: {
    width: 30,
    height: 30,
  },
})

const plusButtonRowStyle = css({
  padding: '0px 32px 0',
  marginRight: 'auto',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  cursor: 'pointer',
  p: {
    color: '#F2A40B',
    fontSize: 12,
    paddingLeft: 10,
  },
  img: {
    width: 20,
    height: 20,
  },
})

const scrollStyle = css({
  'overflow-y': 'scroll',
  height: '68vh',
})
