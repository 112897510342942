import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { useWindowSize } from 'react-use'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle, listContainerStyle } from '@/constants/layout'

// apis
import { fetchFacilityAccessInfo, updateFacilityAccessInfo } from '@/apis/aipass'

// libs
import { FacilityTranslateTextForAccess } from '@/libs/translate-text'

// components
import { Button } from '@/components/atoms/button'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { SettingSideMenu } from '@/components/organisms/setting-side-menu'
import { Editor, editorOptions } from '@/components/organisms/editor'
import { LoadingFull } from '@/components/molecules/loading-full'

type ContainerProps = {}

type Props = {
  history: any
  location: any
  isLoading: boolean
  jpContent: string
  onSubmit: any
  editorState: any
  setEditorState: (editorState: any) => void
  address: string
  setAddress: any
}

export const SettingFacilityAccessEdit: React.FC<ContainerProps> = () => {
  const history = useHistory()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [jpContent, setJpContent] = useState<string>('')
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [accessInfo, setAccessInfo] = useState<any>()
  const [address, setAddress] = useState<string>('')

  useEffect(() => {
    _fetchFacilityAccessInfo()
  }, [])

  // Acquisition of facility access information
  const _fetchFacilityAccessInfo = async () => {
    await fetchFacilityAccessInfo().then(res => {
      if (res !== undefined) {
        setAddress(res?.facilityAccessInfo.address)
        setJpContent(res?.facilityAccessInfo.jpContent)
        setAccessInfo(res?.facilityAccessInfo)
      }
    })
  }

  const onSubmit = async () => {
    setIsLoading(true)
    const jpContent = stateToHTML(editorState.getCurrentContent(), editorOptions).replace(/\r?\n/g, '</br>')
    const pageId = accessInfo?.pageId ? accessInfo?.pageId : null
    await FacilityTranslateTextForAccess({ pageId, jpContent, address }).then(data => {
      updateFacilityAccessInfo(data)
        .then(() => {
          setIsLoading(false)
          history.push({ pathname: '/setting/facility/list', search: location.search })
        })
        .catch(error => {
          console.log(error)
        })
    })
  }

  return (
    <UI
      history={history}
      location={location}
      jpContent={jpContent}
      onSubmit={onSubmit}
      editorState={editorState}
      setEditorState={setEditorState}
      address={address}
      setAddress={setAddress}
      isLoading={isLoading}
    />
  )
}

export const UI: React.FC<ContainerProps & Props> = ({
  history,
  location,
  jpContent,
  onSubmit,
  editorState,
  setEditorState,
  address,
  setAddress,
  isLoading,
}) => {
  const windows = useWindowSize()
  const { t } = useTranslation()
  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header title={t('Setting')} isPublicPageLink />
        <div className="mainContainer" css={settingContainerStyle}>
          <SettingSideMenu />
          <div css={accessEditContainerStyle}>
            <div css={accessEditMainContainerStyle}>
              <div
                css={accessEditTopContainerStyle}
                style={{
                  maxHeight: windows.height - 162,
                }}
              >
                <div css={accessEditHeaderContainerStyle}>
                  <div css={accessEditHeaderTitleStyle}>{t('Access')}</div>
                </div>
                <div css={accessEditFormContainerStyle}>
                  <label htmlFor="address" css={accessEditFormLabelStyle}>
                    {t('Address')}
                  </label>
                  <input
                    type="text"
                    id="address"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    css={accessEditFormInputStyle}
                  />
                </div>
                <div
                  css={editorContainerStyle}
                  style={{
                    maxHeight: windows.height - 280,
                  }}
                >
                  <Editor jpContent={jpContent} editorState={editorState} setEditorState={setEditorState} usedIn={'access'} />
                </div>
              </div>
            </div>
            <div css={settingFooterContainerStyle}>
              <Button
                buttonType={3}
                width={110}
                height={38}
                marginLeft={32}
                marginTop={11}
                marginBottom={11}
                onClick={() => history.push({ pathname: '/setting/facility/list', search: location.search })}
              >
                {t('Cancel')}
              </Button>
              <Button buttonType={1} width={110} height={38} marginLeft={16} marginTop={11} marginBottom={11} onClick={() => onSubmit()}>
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const settingContainerStyle = css(mainContainerStyle, {
  display: 'flex',
  padding: 0,
})

const accessEditFormContainerStyle = css({
  margin: '24px 32px 0 32px',
})

const accessEditFormLabelStyle = css({
  display: 'block',
  color: '#676767',
  fontSize: 12,
  fontWeight: 'bold',
  marginBottom: 12,
})

const accessEditFormInputStyle = css({
  width: 372,
  height: 32,
  border: '1px solid #ccc',
  borderRadius: 22,
  padding: '0 16px',
})

const editorContainerStyle = css({
  margin: 32,
  position: 'relative',
  height: '480px',
})

const accessEditContainerStyle = css({
  width: 'calc(100% - 180px)',
})

const accessEditMainContainerStyle = css({
  padding: 24,
  height: '88vh',
})

const accessEditTopContainerStyle = css(listContainerStyle, {
  width: '100%',
  height: '100%',
  background: '#FFF',
  marginBottom: 16,
})

const accessEditHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const accessEditHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
})

// footer
const settingFooterContainerStyle = css({
  display: 'flex',
  height: 59,
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: '#FFF',
  boxShadow: '0px 1px 6px rgba(0,0,0,0.10)',
})
