import React from 'react'
import { css } from '@emotion/core'
import * as IPopup from './popup-type'
import { RoomType } from '@/models/room-manager/response/guest-room-indicator'
import dayjs from 'dayjs'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { ReservationApprovedStatusSelect } from '@/components/molecules/room-manager/reservation-approved-status-select'
import { Link } from 'react-router-dom'
import { ReservationNoteCategory } from '@/models/reservation/reservation-note'
import { CategoryLabel } from '@/components/atoms/reservation-note/category-label'
import { CustomerTag } from '@/components/atoms/customer/customer-tag'

export class CleaningPopup implements IPopup.PopupType {
  height = 323
  width = 200
  private unpaidTextHeight = 28
  private popupRef: React.RefObject<HTMLDivElement> = React.createRef()

  constructor(room: RoomType) {
    const outOrOutScheduleReservation = room.prevReservation || room.currentReservation
    this.height -= outOrOutScheduleReservation?.hasUnpaidSales ? 0 : this.unpaidTextHeight
  }

  getHeight(): number {
    if (this.popupRef.current) {
      return this.popupRef.current.clientHeight
    }
    return this.height
  }

  Component: React.FC<IPopup.PopupProps> = ({ position, room, t }) => {
    const outOrOutScheduleReservation = (room: RoomType) => room.prevReservation || room.currentReservation

    return (
      <div ref={this.popupRef} css={[IPopup.popupStyle, css({ ...position })]}>
        <div css={headerStyle}>
          <p>{room.roomNumber}</p>
          <p>{t('Indicators.NotCleaned')}</p>
        </div>

        <div css={bodyStyle}>
          <div className="current-reservation">
            {outOrOutScheduleReservation(room) ? (
              <>
                <p className="guest-name">{outOrOutScheduleReservation(room)?.guestName}</p>
                <p className="stay-date">
                  {dayjs(outOrOutScheduleReservation(room)?.checkInDate).format('M/D HH:mm')}&ensp;~&ensp;
                  {dayjs(outOrOutScheduleReservation(room)?.checkOutDate).format('M/D HH:mm')}
                </p>
                {!!outOrOutScheduleReservation(room)?.customerTags.length && (
                  <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap', marginTop: 12, marginBottom: 16 }}>
                    {outOrOutScheduleReservation(room)?.customerTags.map(tag => (
                      <CustomerTag key={tag.name} fontColor={tag.fontColor} bgColor={tag.bgColor} name={tag.name} />
                    ))}
                  </div>
                )}
                <ReservationApprovedStatusSelect
                  value={outOrOutScheduleReservation(room)?.approvedStatus || ApprovedStatusType.Stay}
                  reservationId={outOrOutScheduleReservation(room)?.reservationId || ''}
                  disabled={outOrOutScheduleReservation(room)?.approvedStatus === ApprovedStatusType.Checkout}
                />
                {room.currentReservation?.hasUnpaidSales && (
                  <div className="paid-status">
                    <img src={require('@/static/images/room/settle_orange.svg')} width={11} />
                    <p>{t('Indicators.NotPaid')}</p>
                  </div>
                )}
              </>
            ) : (
              <>
                <p className="empty">{t('Not assigned')}</p>
              </>
            )}
          </div>
          <div className="next-reservation">
            <p className="title">{t('Indicators.NextReservation')}</p>
            {room.nextReservation ? (
              <div className="reservation-content">
                <div className="info">
                  <p className="guest-name">{room.nextReservation?.guestName}</p>
                  <p className="stay-date">
                    {dayjs(room.nextReservation?.checkInDate).format('M/D HH:mm')}&ensp;~&ensp;
                    {dayjs(room.nextReservation?.checkOutDate).format('M/D HH:mm')}
                  </p>
                  {!!room.nextReservation?.customerTags?.length && (
                    <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap', marginTop: 12, marginBottom: 16 }}>
                      {room.nextReservation?.customerTags.map(tag => (
                        <CustomerTag key={tag.name} fontColor={tag.fontColor} bgColor={tag.bgColor} name={tag.name} />
                      ))}
                    </div>
                  )}

                  <ReservationApprovedStatusSelect
                    value={room.nextReservation?.approvedStatus || ApprovedStatusType.Reservation}
                    reservationId={room.nextReservation?.reservationId || ''}
                    checkinId={room.nextReservation?.checkInId}
                  />
                </div>

                <div className="note">
                  {Object.entries(ReservationNoteCategory).map(([_, value]) => (
                    <div key={value}>
                      <p className="category">
                        <CategoryLabel category={value} />
                      </p>
                      <p className="memo">{room?.nextReservation?.notes.find(note => note.category === value)?.memo ?? '-'}</p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <p className="empty">{t('Not assigned')}</p>
            )}
          </div>
        </div>

        <div css={IPopup.footerStyle}>
          {room.nextReservation ? (
            <>
              <Link
                to={{
                  pathname: `/reservation/${room.nextReservation.reservationId}`,
                  search: `?reservationId=${room.nextReservation.reservationId}`,
                }}
              >
                {t('Indicators.ReservationDetails')}
              </Link>
              <Link
                to={{
                  pathname: '/room-manager/daily',
                  search: `?reservationId=${room.nextReservation.reservationId}`,
                }}
              >
                {t('Indicators.RoomChange')}
              </Link>
            </>
          ) : (
            <>
              <p className="-disabled">{t('Indicators.ReservationDetails')}</p>
              <Link to={{ pathname: '/room-manager/daily' }}>{t('Indicators.WalkIn')}</Link>
            </>
          )}
        </div>
      </div>
    )
  }
}

const headerStyle = css(IPopup.headerStyle, {
  background: '#FFFCDD',
  p: {
    color: '#F2A40B',
  },
})

const bodyStyle = css({
  '.current-reservation': {
    padding: 16,
  },
  '.next-reservation': {
    padding: 16,
    borderTop: '1px solid #F2F2F2',
    minHeight: 132.5,
    '.reservation-content': {
      display: 'flex',
      '.info': {
        flex: '0 0 184px',
        paddingRight: 16,
        borderRight: '1px solid #F2F2F2',
      },
      '.note': {
        display: 'flex',
        flexDirection: 'column',
        gap: 18,
        flex: '0 0 166px',
        paddingLeft: 16,
        '.category': {
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          marginBottom: 10,
          fontSize: 12,
          fontWeight: 'bold',
        },
        '.memo': {
          fontSize: 10,
          lineHeight: 1.6,
          overflow: 'hidden',
          display: '-webkit-box',
          whiteSpace: 'pre-wrap',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 5,
        },
      },
    },
  },
  'p.title': {
    paddingBottom: 13.5,
    textAlign: 'center',
  },
  'p.title, p.guest-name': {
    letterSpacing: 0.6,
    fontSize: 12,
    fontWeight: 'bold',
    color: '#272727',
  },
  'p.stay-date': {
    letterSpacing: 0.5,
    fontSize: 10,
    fontWeight: 'bold',
    color: '#676767',
    paddingTop: 8,
  },
  'p.empty': {
    color: '#CCCCCC',
    letterSpacing: 0.6,
    fontWeight: 'bold',
    fontSize: 12,
  },
  '.room-status': {
    paddingTop: 12,
  },
  '.paid-status': {
    display: 'flex',
    paddingTop: 16,
    p: {
      letterSpacing: 0.5,
      fontSize: 10,
      fontWeight: 'bold',
      color: '#F2A40B',
      paddingLeft: 8,
    },
  },
})
