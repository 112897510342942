import React from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

// models
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'

type Props = {
  accountsReceivables: AccountsReceivableSettingType[]
}

export const SettingReceivableList: React.FC<Props> = ({ accountsReceivables }) => {
  const windows = useWindowSize()
  const { t } = useTranslation()

  return (
    <>
      <div css={paymentListHeaderStyle}>
        <div css={codeWidthStyle}>{t('Receivable ID')}</div>
        <div css={nameWidthStyle}>{t('Accounts receivable name')}</div>
        <div css={channelCodeWidthStyle}>{t('Channel name')}</div>
        <div css={closingDateWidthStyle}>{t('Closing date')}</div>
      </div>
      <div
        css={scrollStyle}
        style={{
          height: windows.height - 270,
        }}
      >
        <ul
          css={paymentListStyle}
          style={{
            height: windows.height - 270,
          }}
        >
          {accountsReceivables &&
            accountsReceivables.map((accountsReceivable, index) => (
              <li key={accountsReceivable.code || index}>
                <div css={codeWidthStyle}>{accountsReceivable.code || '-'}</div>
                <div css={nameWidthStyle}>{accountsReceivable.name}</div>
                <div css={channelCodeWidthStyle}>{accountsReceivable.channelName || '-'}</div>
                <div css={closingDateWidthStyle}>
                  {accountsReceivable.closingDate ? t('{{count}}th', { count: +accountsReceivable.closingDate }) : t('The last day')}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}

export const codeWidthStyle = css({
  width: '20%',
})

const nameWidthStyle = css({
  width: '35%',
})

const channelCodeWidthStyle = css({
  width: '35%',
})

const closingDateWidthStyle = css({
  width: '10%',
})

const paymentListHeaderStyle = css({
  background: '#F5F5F5',
  color: '#676767',
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  padding: '10px 32px',
})

const paymentListStyle = css({
  li: {
    display: 'flex',
    padding: '16px 32px',
    color: '#272727',
    fontSize: 14,
    borderBottom: '1px #F2F2F2 solid',
  },
})

const scrollStyle = css({
  overflowY: 'scroll',
})
