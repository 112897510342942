import React from 'react'
import { css } from '@emotion/core'
import { TableHeader, TableBody, TableRow } from '@/components/atoms/settings/table'
import { useTranslation } from 'react-i18next'
import { Control, Controller, useFieldArray } from 'react-hook-form'
import { ListType } from '@/components/pages/setting/smart-key/space'

type Props = {
  control: Control<{ assign: ListType[] }, any>
  onChange: () => void
}

export const LinkSpaceToManual: React.FC<Props> = ({ control, onChange: parentOnChange }) => {
  const { t } = useTranslation()

  const assignForm = useFieldArray({
    control,
    name: 'assign',
  })

  return (
    <>
      <TableHeader>
        <div css={assetNameColStyle}>{t('Asset')}</div>
        <div css={spaceNameColStyle}>{t('Space name')}</div>
        <div css={keyNumberColStyle}>{t('Key number')}</div>
      </TableHeader>
      <TableBody>
        {assignForm.fields.map((space, i) => (
          <Controller
            key={`${space.spaceId}-${space.spaceIndex}`}
            name={`assign.${i}.keyNumber`}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <TableRow css={css({ '> div': { fontWeight: 500 } })}>
                <div css={assetNameColStyle}>
                  {assignForm.fields[i].assetName === assignForm.fields[i - 1]?.assetName ? '' : assignForm.fields[i].assetName}
                </div>
                <div css={spaceNameColStyle}>{`${space.spaceName} - ${space.spaceIndex}`}</div>
                <div css={keyNumberColStyle}>
                  <input
                    name={name}
                    type="text"
                    placeholder="0000"
                    css={inputStyle}
                    value={value}
                    onChange={e => {
                      onChange(e)
                      parentOnChange()
                    }}
                  />
                </div>
              </TableRow>
            )}
          />
        ))}
      </TableBody>
    </>
  )
}

const assetNameColStyle = css({
  padding: '0 32px',
  width: 200,
  overflowWrap: 'anywhere',
})
const spaceNameColStyle = css({
  flexGrow: 1,
  overflowWrap: 'anywhere',
  width: 'min-content',
  paddingRight: 32,
})
const keyNumberColStyle = css({
  width: 300,
})

const inputStyle = css({
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  padding: '0 16px',
  '&::placeholder': {
    color: '#CCCCCC',
  },
})
