import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Global, css } from '@emotion/core'
import 'antd/dist/antd.css'

// contexts
import { AuthProvider } from '@/contexts/auth'
import { AccountProvider } from '@/contexts/account'
import { MiwaAppWebsocketProvider } from '@/contexts/miwa-app-websocket'

// components
import { Router } from '@/components/apps/router'
import { ErrorBoundary } from '@/components/apps/error-boundary'
import { LoaderProvider } from './contexts/loader'

export const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <AccountProvider>
          <MiwaAppWebsocketProvider>
            <LoaderProvider>
              <Router />
            </LoaderProvider>
          </MiwaAppWebsocketProvider>
        </AccountProvider>
      </AuthProvider>
    </ErrorBoundary>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <link rel="stylesheet" href={require('@/static/styles/reset.css')} />
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css?family=Lato');
        @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP');
        * {
          box-sizing: border-box;
          font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Lato', sans-serif;
          letter-spacing: 0.9px;
          outline: 0;
          text-decoration: none;
        }
        @media only screen and (max-width: 599px) {
          * {
            font-family: 'Noto Sans JP', 'Lato', sans-serif;
          }
        }
        *::-webkit-scrollbar {
          height: 8px;
          width: 8px;
        }
        *::-webkit-scrollbar-track {
          background: transparent;
        }
        *::-webkit-scrollbar-thumb {
          background: #ccc;
          border-radius: 8px;
        }
        a {
          text-decoration: none;
        }
        li {
          list-style: none;
        }
        input,
        select {
          letter-spacing: 0.9px;
        }
      `}
    />
    <link rel="stylesheet" href={require('@/static/styles/datepicker.css')} />
    <link rel="stylesheet" href={require('@/static/styles/datepicker-custom.css')} />
    <link rel="stylesheet" href={require('@/static/styles/cascader-antd-custom.css')} />
    <link rel="stylesheet" href={require('@/static/styles/select-antd-custom.css')} />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
