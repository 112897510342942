import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { AssetType, MapBusinessHourType, MapCategoryType, MapDetailType } from '@/models/guest-app/asset'
import { Controller, useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'
import { InputField } from '@/components/molecules/input-field'
import { InputImage } from '@/components/molecules/settings/input-image'
import { createAssetDetail, getAssetMapCategoryList, updateAssetDetail } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { TextareaField } from '@/components/molecules/textarea-field'
import { Select, Option } from '@/components/atoms/select'
import { fetchPlaceDetail, loadAutocomplete, searchRoute, translateBy } from '@/apis/google'
import { AccountContext } from '@/contexts/account'

type Props = {
  asset: AssetType
  detail: MapDetailType | null
  onCancel: () => void
  onSave: () => void
}

export type AssetMapDetailFormValue = {
  title: string
  categoryId: string
  subCategory: string | undefined
  imagePath: string | undefined
  telephone: string | undefined
  address: string
  siteUrl: string | undefined
  route: string | undefined
  comment: string | undefined
  isFavorite: boolean
  businessHours: MapBusinessHourType[]
  lat: string
  lng: string
  placeId: string
}

export const MapDetailModal: React.FC<Props> = ({ asset, detail, onCancel, onSave: parentSave }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<AssetMapDetailFormValue>({
    defaultValues: {
      title: detail?.title || '',
      categoryId: detail?.category.id || undefined,
      subCategory: detail?.subCategory || '',
      imagePath: detail?.imagePath || '',
      telephone: detail?.telephone || '',
      address: detail?.address || '',
      siteUrl: detail?.siteUrl || '',
      route: detail?.route || '',
      comment: detail?.comment || '',
      isFavorite: detail?.isFavorite || false,
      businessHours: detail?.businessHours || [],
      lat: detail?.lat || '',
      lng: detail?.lng || '',
      placeId: detail?.placeId || '',
    },
  })
  const { doReload, setIsLoading } = useContext(AssetDetailContext)
  const [isShowCropModal, setIsShowCropModal] = useState<boolean>(false)
  const { errorHandler } = useErrorHandler()
  const [isInitializing, setIsInitializing] = useState<boolean>(true)
  const [categories, setCategories] = useState<MapCategoryType[]>([])
  const { account } = useContext<any>(AccountContext)

  const onSave = async (value: AssetMapDetailFormValue) => {
    try {
      setIsLoading(true)
      if (detail) {
        await updateAssetDetail(asset.id, detail.id, asset.formatType, value)
      } else {
        await createAssetDetail(asset.id, asset.formatType, value)
      }
      doReload()
      parentSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const loadMasterData = async () => {
    const res = await getAssetMapCategoryList(asset.id)
    setCategories(res)
    setIsInitializing(false)
  }

  const selectPlace = async (placeId: string) => {
    const placeWithDetail = await fetchPlaceDetail(placeId)
    if (!placeWithDetail) {
      return
    }

    // trim zip and country
    const address =
      placeWithDetail.address_components
        // 末尾の国、郵便番号をトリム
        ?.slice(0, placeWithDetail.address_components.length - 2)
        .reverse()
        .map(addr => addr.long_name)
        .join('') || ''
    const route = await searchRoute(account.hotel.address, address)

    // 同じ地点を選択したときのために更新を促す
    setValue('title', '')
    setValue('title', placeWithDetail.name || '')
    setValue('address', address)
    setValue('subCategory', placeWithDetail.types ? await translateBy(placeWithDetail.types[0], 'ja') : '')
    setValue('siteUrl', placeWithDetail.website || '')
    setValue('telephone', placeWithDetail.formatted_phone_number?.replaceAll('-', '') || '')
    setValue(
      'businessHours',
      placeWithDetail.opening_hours?.periods?.map((period): MapBusinessHourType => {
        const zeroPadding = (hourOrMinute: number) => `0${hourOrMinute}`.slice(-2)
        return {
          weekday: `${period.open.day}`,
          startTime: `${zeroPadding(period.open.hours)}:${zeroPadding(period.open.minutes)}`,
          endTime: period.close ? `${zeroPadding(period.close.hours)}:${zeroPadding(period.close.minutes)}` : '00:00',
        }
      }) || [],
    )
    setValue('lat', `${placeWithDetail.geometry?.location?.lat() || ''}`)
    setValue('lng', `${placeWithDetail.geometry?.location?.lng() || ''}`)
    setValue('placeId', placeId)
    setValue('route', `徒歩${route?.legs[0].duration?.text}`)
  }

  useEffect(() => {
    loadMasterData()
    loadAutocomplete(account.hotel.address, 'autocomplete', selectPlace)
  }, [])

  return (
    <>
      <Modal customCss={modalStyle} style={{ visibility: isShowCropModal ? 'hidden' : 'visible' }}>
        <ModalHeader>{t('GuestAppSetting.CreateMap')}</ModalHeader>
        <ModalBody>
          <div css={modalBodyStyle}>
            <div className="left-panel">
              <div className="search-box">
                <div css={inputTitleTextStyle}>
                  {t('Title')}
                  <div css={requireLabelTextStyle}>※</div>
                </div>
                <Controller
                  control={control}
                  rules={{ required: t('Required field has not been entered') }}
                  name="title"
                  render={({ field: { onChange, value } }) => (
                    <InputField
                      id="autocomplete"
                      placeholder={t('GuestAppSetting.EnterFacilityName')}
                      value={value}
                      onChange={onChange}
                      error={errors.title?.message}
                    />
                  )}
                />
              </div>

              <div className="input-col">
                <div>
                  <div css={inputTitleTextStyle}>
                    {t('Category')}
                    <div css={requireLabelTextStyle}>※</div>
                  </div>
                  <Controller
                    control={control}
                    rules={{ required: t('Please select {{item}}', { item: t('Category') }) }}
                    name="categoryId"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        placeholder={t('Please select')}
                        value={!isInitializing ? value : undefined}
                        onChange={onChange}
                        error={errors.categoryId?.message}
                      >
                        {categories.map(category => (
                          <Option key={category.id} value={category.id}>
                            {category.title}
                          </Option>
                        ))}
                      </Select>
                    )}
                  />
                </div>
                <div>
                  <div css={inputTitleTextStyle}>{t('SubCategory')}</div>
                  <Controller
                    control={control}
                    name="subCategory"
                    render={({ field: { onChange, value } }) => (
                      <InputField value={value} handleChangeData={onChange} placeholder={t('SubCategoryName')} marginBottom={0} />
                    )}
                  />
                </div>
              </div>

              <Controller
                name="imagePath"
                control={control}
                rules={{ required: t('Please select {{item}}', { item: t('Image') }) }}
                render={({ field: { onChange, value } }) => (
                  <InputImage
                    value={value}
                    onChange={onChange}
                    onShowModal={setIsShowCropModal}
                    aspect={16 / 9}
                    error={errors.imagePath?.message}
                  />
                )}
              />
            </div>
            <div className="right-panel">
              <div className="input-col">
                <div>
                  <div css={inputTitleTextStyle}>{t('Phone number')}</div>
                  <Controller
                    control={control}
                    name="telephone"
                    render={({ field: { onChange, value } }) => (
                      <InputField value={value} handleChangeData={onChange} placeholder="08012345678" marginBottom={0} />
                    )}
                  />
                </div>
                <div>
                  <div css={inputTitleTextStyle}>
                    {t('Address')}
                    <div css={requireLabelTextStyle}>※</div>
                  </div>
                  <Controller
                    control={control}
                    rules={{ required: t('Required field has not been entered') }}
                    name="address"
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        value={value}
                        handleChangeData={onChange}
                        placeholder={t('Address')}
                        error={errors.address?.message}
                        marginBottom={0}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="input-col">
                <div>
                  <div css={inputTitleTextStyle}>{t('SiteUrl')}</div>
                  <Controller
                    control={control}
                    name="siteUrl"
                    render={({ field: { onChange, value } }) => (
                      <InputField value={value} handleChangeData={onChange} placeholder="URL" marginBottom={0} />
                    )}
                  />
                </div>
                <div>
                  <div css={inputTitleTextStyle}>{t('GuestAppSetting.MapRoute')}</div>
                  <Controller
                    control={control}
                    name="route"
                    render={({ field: { onChange, value } }) => (
                      <InputField
                        value={value}
                        handleChangeData={onChange}
                        placeholder={t('GuestAppSetting.MapRoutePlaceholder')}
                        marginBottom={0}
                      />
                    )}
                  />
                </div>
              </div>

              <div css={inputTitleTextStyle}>{t('GuestAppSetting.MapComment')}</div>
              <Controller
                control={control}
                name="comment"
                render={({ field: { onChange, value, name } }) => (
                  <TextareaField
                    value={value}
                    handleChangeData={onChange}
                    error={errors.comment?.message}
                    placeholder={t('GuestAppSetting.MapCommentPlaceholder')}
                    name={name}
                    css={css({ flexGrow: 1 })}
                  />
                )}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(onSave)}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const modalStyle = {
  height: 622,
  width: 1200,
  minWidth: 1200,
  left: 'calc((100% - 1200px) / 2)',
}

const modalBodyStyle = css({
  padding: 32,
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  height: '100%',
  display: 'flex',
  '.input-col': {
    display: 'flex',
    gap: 22,
    '> div': { width: '100%', marginBottom: 24 },
  },
  '.search-box': {
    marginBottom: 24,
    position: 'relative',
    '.suffix-icon': {
      position: 'absolute',
      right: 16,
      // height + icon height(1/2)
      top: 'calc(24px + 8px)',
    },
  },
  '.left-panel': {
    width: '50%',
    borderRight: '1px solid #F2F2F2',
    paddingRight: 35.5,
  },
  '.right-panel': {
    width: '50%',
    paddingLeft: 30.5,
    display: 'flex',
    flexFlow: 'column',
  },
  textarea: {
    height: '100%',
    lineHeight: 1.5,
  },
})

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})
