import { SerializedStyles } from '@emotion/core'
import React from 'react'

interface RequireTextProps {
  customCss?: SerializedStyles
}

export const RequireText: React.FC<RequireTextProps> = ({ customCss }) => {
  return <span css={[customCss, { color: '#f2a40b' }]}>*</span>
}
