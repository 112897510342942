import React, { useEffect, useState, useContext } from 'react'
import { useWindowSize } from 'react-use'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import QrReader from 'react-qr-reader'

import { ErrorModal } from '@/components/molecules/qr-checkin/error-modal'
import { ApprovedStatus } from '@/models/reservation-approved-status'
import { fetchAdminAccount, updateAdminCheckin, updateCheckinReception } from '@/apis/aipass'
import { decodeQr } from '@/libs/qr-decode'
import { ApprovedStatusType } from '@/models/reservation-approved-status'

// contexts
import { AccountContext } from '@/contexts/account'

// libis
import { hasAnyReservationPlugin } from '@/libs/plugins'

type Props = {
  _onClose: () => void
  _onScan: (data: any) => void
  isError: boolean
  _onError: () => void
}

export const QrCamera: React.FC = () => {
  const history = useHistory()
  const [isError, setIsError] = useState<boolean>(false)
  const [hasCheckinData, setHasCheckinData] = useState<boolean>(false)
  const [isOnScan, setIsOnScan] = useState<boolean>(false)
  const [isUpdateCheckinReception, setIsUpdateCheckinReception] = useState<boolean>(false)
  const _onClose = () => {
    history.push({ pathname: `/mobile-menu` })
  }
  const { plugins } = useContext<any>(AccountContext)

  useEffect(() => {
    // Determine if authentication key is valid
    fetchAdminAccount()
      .then(res => {
        if (!res || !res.hotel) {
          history.replace({
            pathname: '/qr-checkin',
            search: window.location.search,
          })
        } else return
      })
      .catch(() => {
        history.replace({
          pathname: '/qr-checkin',
          search: window.location.search,
        })
      })
  }, [])

  const _onScan = data => {
    if (data && !hasCheckinData && !isOnScan) {
      setIsOnScan(true)
      decodeQr(data)
        .then(async (res: any) => {
          if (!hasAnyReservationPlugin(plugins) && !isUpdateCheckinReception) {
            // Free plugin create reservation.
            await updateCheckinReception([res?.id], ApprovedStatusType.Stay, 'accommodationDetail').then(res => {
              if (res?.message === 'success') {
                setIsUpdateCheckinReception(true)
              }
            })
          }
          updateAdminCheckin({ checkinId: res?.id, approvedStatus: ApprovedStatus.Stay }).then(checkinData => {
            setHasCheckinData(true)
            if (checkinData) {
              history.push({
                pathname: `/qr-checkin/complete`,
                search: window.location.search,
                state: { checkinData },
              })
              return
            }
            if (!checkinData) {
              setIsOnScan(false)
              setIsError(true)
              return
            }
          })
        })
        .catch(() => {
          setIsOnScan(false)
          setIsError(true)
          return
        })
    }
    if (!data) {
      setIsOnScan(false)
      setIsError(false)
    }
  }

  const _onError = () => {
    setIsError(true)
  }

  return <UI _onClose={_onClose} _onScan={_onScan} isError={isError} _onError={_onError} />
}

export const UI: React.FC<Props> = ({ _onClose, _onScan, isError, _onError }) => {
  const { t } = useTranslation()
  const windows = useWindowSize()

  return (
    <>
      {isError && <ErrorModal />}
      <div
        style={{
          height: windows.height,
        }}
        css={qrCameraWrapperStyle}
      >
        <QrReader showViewFinder={false} onScan={_onScan} onError={_onError} constraints={{ facingMode: 'user' }} />
        <button css={cameraCloseStyle} type="button" onClick={_onClose}>
          <img alt="close" src={require('@/static/images/close.svg')} />
        </button>
        <p css={qrCameraTitleStyle}>
          {t('The QR code')}
          <br />
          {t('Fit the screen')}
        </p>
        <div css={qrCameraMaskStyle}>
          <div css={maskTopStyle} />
          <div css={cameraWrapperStyle}>
            <div css={maskLeftStyle} />
            <div css={qrCameraStyle} />
            <div css={maskRightStyle} />
          </div>
          <div css={maskBottomStyle} />
        </div>
      </div>
    </>
  )
}

const qrCameraWrapperStyle = css({
  background: '#F2F2F2',
  minWidth: '100%',
  textAlign: 'center',
  section: {
    height: '100%',
  },
})

const cameraCloseStyle = css({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  left: 23.61,
  position: 'absolute',
  top: 24.94,
  zIndex: 2,
  img: {
    height: 17.45,
    width: 17.45,
  },
})

const qrCameraMaskStyle = css({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
})

const cameraWrapperStyle = css({
  display: 'flex',
})

const maskTopStyle = css({
  background: 'rgba(0, 0, 0, 0.6)',
  height: 170,
})

const maskLeftStyle = css({
  background: 'rgba(0, 0, 0, 0.6)',
  height: 323,
  width: 'calc((100% - 323px)/2)',
})

const qrCameraStyle = css({
  width: 323,
  height: 323,
  background: 'transparent',
  border: '2px solid #FFFFFF',
})

const maskRightStyle = css({
  background: 'rgba(0, 0, 0, 0.6)',
  height: 323,
  width: 'calc((100% - 323px)/2)',
})

const maskBottomStyle = css({
  background: 'rgba(0, 0, 0, 0.6)',
  height: 'calc(100% - 323px - 170px)',
})

const qrCameraTitleStyle = css({
  fontSize: 18,
  letterSpacing: '3.6px',
  fontWeight: 'bold',
  lineHeight: '28px',
  color: 'white',
  position: 'absolute',
  width: '100%',
  zIndex: 2,
  top: 102,
})
