import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { ReadyState } from 'react-use-websocket'
import { useTranslation } from 'react-i18next'

// components
import { Button } from '@/components/atoms'

// contexts
import { MiwaAppWebsocketContext } from '@/contexts/miwa-app-websocket'

// models
import { TMiwaAppWebsocketContext } from '@/models/miwa-lock'

// constants
import { HEADER_HEIGHT, MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL, SETTING_MANAGER_LIST_MARGIN } from '@/constants/layout'
import { LoaderContextCreator } from '@/contexts/loader'

export const SettingMiwaLock: React.FC= () => {
  const windows = useWindowSize()
  const { t } = useTranslation()
  const { setIsLoading } = useContext(LoaderContextCreator())

  const {
    isLoadingFetchConnectionInfo,
    fetchConnectionInfo,
    miwaAppWebsocketPCID,
    setMiwaAppWebsocketPCID,
    miwaAppWebsocketErrorMessage,
    readyState,
  } = useContext<TMiwaAppWebsocketContext>(MiwaAppWebsocketContext)

  const _validate = (): boolean => {
    const errorMessage = t("Please input PCID")

    if (!miwaAppWebsocketPCID) {
      window.alert(errorMessage)
      return false
    }

    return true
  }

  const onClickConnect = async () => {
    if (!_validate()) return

    setIsLoading(true)

    await fetchConnectionInfo()

    setIsLoading(false)
  }

  return (
    <div
      css={guestRoomHeaderStyle}
      style={{
        height: windows.height - HEADER_HEIGHT - MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL - SETTING_MANAGER_LIST_MARGIN + 32 + 13,
      }}>
      {readyState === ReadyState.OPEN && !miwaAppWebsocketErrorMessage ? (
        <>
          <p css={paragraphStyle}>{t('Connect websocket success')}</p>
        </>
      ) : (
        <>
          <p css={paragraphStyle}>{t('Please keep window app open')}</p>
          <div>
            <label htmlFor="pcId" css={innputLabelStyle}>
              {t('PCID')}
            </label>
            <div css={inputWrapperStyle}>
              <input
                id="pcId"
                type="text"
                name="pcId"
                css={innputStyle}
                value={miwaAppWebsocketPCID}
                onChange={e => setMiwaAppWebsocketPCID(e.target.value)}
              />
              <Button
                width={63}
                height={32}
                buttonType={isLoadingFetchConnectionInfo ? 5 : 1}
                isDisabled={isLoadingFetchConnectionInfo}
                onClick={onClickConnect}>
                {t('Connect')}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const guestRoomHeaderStyle = css({
  padding: '32px 27px',
})

const paragraphStyle = css({
  color: '#676767',
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: '27px',
  letterSpacing: '0.7px',
  marginBottom: '32px',
})

const innputLabelStyle = css({
  color: '#676767',
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: '27px',
  letterSpacing: '0.7px',
  marginBottom: '12px',
  display: 'block',
})

const innputStyle = css({
  color: '#272727',
  fontsize: '14px',
  letterSpacing: '0.7px',
  lineHeight: '20px',
  '&::placeholder': {
    color: '#ccc',
  },
  padding: '9px 16px',
  width: '410px',
  height: '32px',
  border: '1px solid #CCCCCC',
  borderRadius: '16px',
  marginRight: '16px',
})

const inputWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
})
