import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { AccountContext } from '@/contexts/account'
import {
  listContainerStyle,
  listContentsWrapperStyle,
  listContentsStyle,
  HEADER_HEIGHT,
  MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL,
  CONTAINER_WITH_HEADER_BUTTON_LIST_MARGIN,
  TAB_HEIGHT,
  GRAY_LABEL_HEIGHT,
  LIST_FOOTER_HEIGHT,
} from '@/constants/layout'
import {
  settingHeaderContainerStyle,
  listWrapperStyle,
  listStyle,
  hideListStyle,
  deleteStyle,
} from '@/components/pages/setting/guest-room/room/layout'
import { getAdminGuestRoomFloor, deleteAdminGuestRoomFloor } from '@/apis/aipass'

// components
import { GuestRoomTab } from '@/components/pages/setting/guest-room/tab'
import { GuestRoomFloorType } from '@/models/guest-room-floor'
import { SettingGuestRoomLabel } from '@/components/pages/setting/guest-room/room/_room/label'
import { SettingGuestRoomEditButtons } from '@/components/pages/setting/guest-room/room/_room/edit-buttons'
import { EditFooter } from '@/components/organisms/edit-footer'
import { FloorModal } from '@/components/pages/setting/guest-room/room/_room/modal/floor-modal'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { errorHandler } from '@/libs/errors'

export const SettingGuestRoomFloor: React.FC = () => {
  const windows = useWindowSize()
  const { t, i18n } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showFooter, setShowFooter] = useState<boolean>(false)
  const [deleteRoomIds, setDeleteRoomIds] = useState<string[]>([])
  const [guestRoomFloors, setGuestRoomFloors] = useState<GuestRoomFloorType[]>([])
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [nextDisplayOrder, setNextDisplayOrder] = useState<string>()
  const [showDetailFloor, setShowDetailFloor] = useState<GuestRoomFloorType | undefined>()

  const fetchGuestRoomFloor = async () => {
    setIsLoading(true)
    const res = await getAdminGuestRoomFloor()
    setGuestRoomFloors(res)
    setIsLoading(false)
    setNextDisplayOrder(`${Number(res.slice(-1)[0]?.displayOrder || 0) + 1}`)
  }

  const addDeleteItem = (id: string) => {
    setShowFooter(true)
    setDeleteRoomIds(deleteRoomIds => [...deleteRoomIds, id])
  }

  const onClickFooterSaveButton = async () => {
    try {
      setIsLoading(true)
      setShowFooter(false)
      if (deleteRoomIds && window.confirm(t('Are you sure you want to delete it'))) {
        await deleteAdminGuestRoomFloor({ ids: deleteRoomIds })
      }
      setShowFooter(false)
      setDeleteRoomIds([])
    } catch (e) {
      errorHandler(e)
    } finally {
      fetchGuestRoomFloor()
      setIsLoading(false)
    }
  }

  const onClickFooterCancelButton = () => {
    setShowFooter(false)
    setDeleteRoomIds([])
  }

  const listItems = [
    {
      label: t('Display order'),
      width: 149,
    },
    {
      label: t('Floor'),
      width: 'auto',
    },
  ]
  const footerContent = showFooter ? <EditFooter onSave={onClickFooterSaveButton} onCancel={onClickFooterCancelButton} /> : undefined

  useEffect(() => {
    if (account && account?.hotel) {
      fetchGuestRoomFloor()
    }
  }, [account])

  return (
    <>
      {showCreateModal && (
        <FloorModal
          detail={showDetailFloor}
          nextDisplayOrder={nextDisplayOrder || '1'}
          onSave={() => fetchGuestRoomFloor()}
          onClose={() => {
            setShowCreateModal(false)
            setShowDetailFloor(undefined)
          }}
        />
      )}

      <SettingsLayout loading={isLoading} footerContent={footerContent}>
        <>
          <div css={settingHeaderContainerStyle}>
            <GuestRoomTab currentTab="floor" />
          </div>
          <div css={[listContainerStyle]}>
            <SettingGuestRoomEditButtons
              enabledCsvButton={false}
              onClickAddButton={() => {
                setShowCreateModal(true)
              }}
            />
            <SettingGuestRoomLabel listItems={listItems} />
            <div
              css={[listContentsWrapperStyle]}
              style={
                showFooter
                  ? {
                      maxHeight:
                        windows.height -
                        HEADER_HEIGHT -
                        MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL -
                        CONTAINER_WITH_HEADER_BUTTON_LIST_MARGIN -
                        TAB_HEIGHT -
                        GRAY_LABEL_HEIGHT -
                        LIST_FOOTER_HEIGHT,
                    }
                  : {
                      maxHeight:
                        windows.height -
                        HEADER_HEIGHT -
                        MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL -
                        CONTAINER_WITH_HEADER_BUTTON_LIST_MARGIN -
                        TAB_HEIGHT -
                        GRAY_LABEL_HEIGHT,
                    }
              }
            >
              <div css={listContentsStyle}>
                {guestRoomFloors &&
                  guestRoomFloors.map((value: any, index) => {
                    if (deleteRoomIds.includes(value.roomFloorId)) {
                      return <div key={index} css={hideListStyle} />
                    } else {
                      return (
                        <div key={index} css={listWrapperStyle}>
                          <div
                            css={listStyle}
                            onClick={() => {
                              setShowDetailFloor(value)
                              setShowCreateModal(true)
                            }}
                          >
                            <div style={{ width: listItems[0].width }}>{value.displayOrder}</div>
                            <div style={{ width: listItems[1].width }}>
                              {i18n.language === 'ja' ? value.floorNameJa : value.floorNameEn}
                            </div>
                          </div>
                          <img
                            css={deleteStyle}
                            src={require('@/static/images/room_delete_gold.svg')}
                            onClick={() => addDeleteItem(value.roomFloorId)}
                          />
                        </div>
                      )
                    }
                  })}
              </div>
            </div>
          </div>
        </>
      </SettingsLayout>
    </>
  )
}
