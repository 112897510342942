import * as React from 'react'
import logo from '@/static/images/icon/logo.svg'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

export const SigninHeader: React.FC<{}> = () => {
  const { t } = useTranslation()
  return (
    <div css={signinHeaderSectionStyle}>
      <div css={headerStyle}>{window.location.pathname === '/qr-checkin' ? t('QR check-in') : t('aiPass management screen')}</div>
      <img alt="logo" src={logo} css={headerLogoStyle} />
    </div>
  )
}

const signinHeaderSectionStyle = css({
  textAlign: 'center',
  alignItems: 'center',
})

const headerStyle = css({
  zindex: '100',
  color: '#272727',
  fontSize: 16,
  letterSpacing: 1.6,
})

const headerLogoStyle = css({
  height: 80,
  width: 240,
  minHeight: 56,
  minWidth: 180,
  marginTop: 24,
  '@media(min-width: 640px)': {
    height: 110,
    width: 270,
    minHeight: 56,
  },
})
