import React from 'react'
import { Button } from '@/components/atoms'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

interface DuplicateAssignmentConfirmDialogProps {
  isShowDialog: boolean
  onCancel: () => void
  onAssign: () => void
}
export const DuplicateAssignmentConfirmDialog: React.FC<DuplicateAssignmentConfirmDialogProps> = ({ isShowDialog, onCancel, onAssign }) => {
  const { t } = useTranslation()

  return isShowDialog ? (
    <>
      <div css={modalBackgroundStyle} />
      <div css={modalStyle}>
        <p>{t('I have a day use assignment. Would you like to make additional assignments?')}</p>
        <div className="button-area">
          <Button width={110} height={38} buttonType={3} fontSize={12} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button width={110} height={38} buttonType={1} fontSize={12} onClick={() => onAssign()}>
            {t('Assignment')}
          </Button>
        </div>
      </div>
    </>
  ) : (
    <></>
  )
}

const modalStyle = css({
  height: 196,
  width: 400,
  position: 'fixed',
  zIndex: 1000,
  background: '#FFFFFF',
  top: 'calc(-50vh + 100% - 196px / 2)',
  left: 'calc(-50vw + 100% - 400px / 2)',
  padding: '40px',
  borderRadius: 5,
  p: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    letterSpacing: 0.9,
    whiteSpace: 'pre-wrap',
    lineHeight: '27px',
  },
  '.button-area': {
    display: 'flex',
    justifyContent: 'center',
    gap: '24px',
    paddingTop: 24,
  },
})

const modalBackgroundStyle = css({
  background: '#272727',
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  zIndex: 999,
  top: 'calc(-100vh + 100%)',
  left: 'calc(-100vw + 100%)',
  opacity: 0.5,
})
