import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// components
import { SigninEmailTextField, SigninPasswordTextField } from '@/components/pages/signin/signin-text-field'

export type SigninContainerProps = {
  email: string
  password: string
  emailOnChange: (email: string) => void
  passwordOnChange: (password: string) => void
  validEmail: boolean
  validPassword: boolean
}

export const SigninContainer: React.FC<SigninContainerProps> = ({
  email,
  password,
  emailOnChange,
  passwordOnChange,
  validEmail,
  validPassword,
}) => {
  const { t } = useTranslation()
  return (
    <div css={SigninContainerSectionStyle}>
      <SigninEmailTextField
        name="email"
        label={t('Email address')}
        value={email}
        placeholder={t('Single-byte alphanumeric characters')}
        emailOnChange={v => emailOnChange(v)}
        validEmail={validEmail}
      />
      <SigninPasswordTextField
        name="password"
        label={t('Password')}
        value={password}
        placeholder={t('{{count}} or more half-width alphanumeric characters', { count: 8 })}
        passwordOnChange={v => passwordOnChange(v)}
        validPassword={validPassword}
      />
    </div>
  )
}

const SigninContainerSectionStyle = css({
  margin: '26px auto 0 auto',
})
