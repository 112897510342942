import React from 'react'
import { css } from '@emotion/core'
import * as IPopup from './popup-type'
import { RoomType } from '@/models/room-manager/response/guest-room-indicator'
import dayjs from 'dayjs'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { ReservationApprovedStatusSelect } from '@/components/molecules/room-manager/reservation-approved-status-select'
import { Link } from 'react-router-dom'
import { ReservationNoteCategory } from '@/models/reservation/reservation-note'
import { CategoryLabel } from '@/components/atoms/reservation-note/category-label'
import { CustomerTag } from '@/components/atoms/customer/customer-tag'

export class CheckOutPopup implements IPopup.PopupType {
  height = 185
  width = 200
  private unpaidTextHeight = 28
  private popupRef: React.RefObject<HTMLDivElement> = React.createRef()

  constructor(room: RoomType) {
    this.height -= room.currentReservation?.hasUnpaidSales ? 0 : this.unpaidTextHeight
  }

  getHeight(): number {
    if (this.popupRef.current) {
      return this.popupRef.current.clientHeight
    }
    return this.height
  }

  Component: React.FC<IPopup.PopupProps> = ({ position, room, t }) => {
    return (
      <div ref={this.popupRef} css={[IPopup.popupStyle, css({ ...position })]}>
        <div css={headerStyle}>
          <p>{room.roomNumber}</p>
          <p>{t('cleaningManager.Checkout Soon')}</p>
        </div>

        <div css={IPopup.bodyStyle}>
          <div className="note">
            <p className="guest-name">{room.currentReservation?.guestName}</p>
            <p className="stay-date">
              {dayjs(room.currentReservation?.checkInDate).format('M/D HH:mm')}&ensp;~&ensp;
              {dayjs(room.currentReservation?.checkOutDate).format('M/D HH:mm')}
            </p>
            {!!room.currentReservation?.customerTags?.length && (
              <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap', marginTop: 12, marginBottom: 16 }}>
                {room.currentReservation?.customerTags.map(tag => (
                  <CustomerTag key={tag.name} fontColor={tag.fontColor} bgColor={tag.bgColor} name={tag.name} />
                ))}
              </div>
            )}

            <ReservationApprovedStatusSelect
              value={room.currentReservation?.approvedStatus || ApprovedStatusType.Stay}
              reservationId={room.currentReservation?.reservationId || ''}
              checkinId={room.currentReservation?.checkInId}
              disabled={!room.currentReservation?.checkInId}
            />

            {room.currentReservation?.hasUnpaidSales && (
              <div className="paid-status">
                <img src={require('@/static/images/room/settle_orange.svg')} width={11} />
                <p>{t('Indicators.NotPaid')}</p>
              </div>
            )}
          </div>
          <div className="note">
            {Object.entries(ReservationNoteCategory).map(([_, value]) => (
              <div key={value}>
                <p className="category">
                  <CategoryLabel category={value} />
                </p>
                <p className="memo">{room?.currentReservation?.notes.find(note => note.category === value)?.memo ?? '-'}</p>
              </div>
            ))}
          </div>
        </div>

        <div css={IPopup.footerStyle}>
          {room.currentReservation?.reservationId ? (
            <>
              <Link
                to={{
                  pathname: `/stay/${room.currentReservation.checkInId}`,
                  search: `?reservationId=${room.currentReservation.reservationId}`,
                }}
              >
                {t('Indicators.ReservationDetails')}
              </Link>
              <Link
                to={{
                  pathname: '/room-manager/daily',
                  search: `?reservationId=${room.currentReservation.reservationId}`,
                }}
              >
                {t('Indicators.RoomChange')}
              </Link>
            </>
          ) : (
            <>
              <p className="-disabled"> {t('Indicators.ReservationDetails')}</p>
              <p className="-disabled">{t('Indicators.RoomChange')}</p>
            </>
          )}
        </div>
      </div>
    )
  }
}

const headerStyle = css(IPopup.headerStyle, {
  background: '#F6FDF2',
  p: {
    color: '#7DC931',
  },
})
