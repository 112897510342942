import React from 'react'
import { css } from '@emotion/core'

type Props = {
  size?: number
  padding?: number
  marginLeft?: number | 'auto'
  marginRight?: number | 'auto'
  onClick: () => void
}

export const ReloadButton: React.FC<Props> = ({ size = 32, padding = 9, marginLeft = 0, marginRight = 0, onClick }) => {
  return (
    <div onClick={onClick} style={{ marginLeft, marginRight, width: size, height: size, padding: padding - 1 }} css={reloadButton}>
      <img src={require('@/static/images/reload.svg')} style={{ width: size - padding * 2, height: size - padding * 2 }} />
    </div>
  )
}

const reloadButton = css({
  border: '1px solid #ccc',
  borderRadius: 23,
  backgroundColor: '#fff',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
})
