import React, { useCallback, useEffect, useState } from 'react'
import { Button } from '@/components/atoms'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

interface SaveDraftConfirmDialogProps {
  onClose: () => void
  email: string
}
export const SentTestMailDialog: React.FC<SaveDraftConfirmDialogProps> = ({ onClose, email }) => {
  const { t } = useTranslation()

  return !!email ? (
    <>
      <div css={modalBackgroundStyle} />
      <div css={modalStyle}>
        <div className="close-button">
          <img src={require('@/static/images/room_delete_gold.svg')} onClick={() => onClose()} />
        </div>
        <p style={{ fontSize: 21 }}>{t('Test email sent.')}</p>
        <p style={{ fontSize: 18, marginTop: 12 }}>{email}</p>
      </div>
    </>
  ) : (
    <></>
  )
}

const modalStyle = css({
  height: 160,
  width: 480,
  position: 'fixed',
  zIndex: 1000,
  background: '#FFFFFF',
  top: 'calc(-50vh + 100% - 160px / 2)',
  left: 'calc(-50vw + 100% - 480px / 2)',
  padding: '24px',
  borderRadius: 5,
  p: {
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: 0.9,
    color: '#272727',
    whiteSpace: 'pre-wrap',
    lineHeight: '27px',
  },
  '.modal-inner': {
    width: '100%',
    position: 'relative',
  },
  '.close-button': {
    textAlign: 'right',
    cursor: 'pointer'
  },
})

const modalBackgroundStyle = css({
  background: '#272727',
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  zIndex: 999,
  top: 'calc(-100vh + 100%)',
  left: 'calc(-100vw + 100%)',
  opacity: 0.5,
})
