import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import dayjs from 'dayjs'
import { DateField } from '@/components/molecules/date-field'

type CalendarProps = {
  selectedDate: any
  setSelectedDate: (arg0: any) => void
}

export const Calendar = ({ selectedDate, setSelectedDate }: CalendarProps) => {
  const { t } = useTranslation()
  return (
    <div css={calendarWrapperStyle}>
      <div css={calendarStyle}>
        <img
          css={backIconStyle}
          src={require('@/static/images/cleaning_manager/icon_calender_arrow_left.svg')}
          alt="logo"
          onClick={() => {
            setSelectedDate(selectedDate.add(-1, 'd'))
          }}
        />
        <DateField
          customCss={overWriteDateFieldStyle}
          value={moment(selectedDate.format('YYYY-MM-DD'))}
          onChange={date => date && setSelectedDate(dayjs(date.format('YYYY-MM-DD')))}
          displayFormat={t('cleaningManager.YYYY-MM-DD')}
        />
        <img
          css={nextIconStyle}
          src={require('@/static/images/cleaning_manager/icon_calender_arrow_right.svg')}
          alt="logo"
          onClick={() => {
            setSelectedDate(selectedDate.add(1, 'd'))
          }}
        />
      </div>
    </div>
  )
}

const calendarWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  padding: '0 32px',
})

const calendarStyle = css({
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: 'auto',
})

const overWriteDateFieldStyle = css({
  'div.SingleDatePicker': {
    '.SingleDatePickerInput': {
      width: '130px',
      border: 'none',
      'div.SingleDatePicker_picker': {
        zIndex: 100,
      },
      '.SingleDatePickerInput_calendarIcon': { display: 'none' },
      'div.DateInput': {
        'input.DateInput_input': {
          padding: '5px 0',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.65)',
          letterSpacing: '0.8px',
        },
      },
    },
  },
})

const mobileCalendarIconStyle = css({
  height: '30px',
  width: '30px',
  cursor: 'pointer',
  borderRadius: '50%',
  '&:hover': {
    backgroundColor: '#F2F2F2',
  },
})

const backIconStyle = css(mobileCalendarIconStyle, { marginRight: 36 })

const nextIconStyle = css(mobileCalendarIconStyle, { marginLeft: 36 })
