import React, { ComponentProps } from 'react'

// components
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import { InputField } from './input-field'

type Props = {
  label?: string
  required?: boolean
  placeholder?: string
  fieldWidth?: string
  width?: string
  marginBottom?: number
  size?: number
  error?: string
  handleChangeData?: (e: any) => void
  maxLength?: number
  type?: string
  disabled?: boolean
}
type InputField = ComponentProps<'input'> & Props
type RHFInputField<T extends FieldValues> = UseControllerProps<T> & InputField

export const RHFInputField = <T extends FieldValues>({
  label,
  required = false,
  placeholder,
  fieldWidth = '100%',
  width = '100%',
  marginBottom = 16,
  name,
  size,
  rules,
  error,
  handleChangeData,
  maxLength,
  type,
  disabled = false,
  control,
}: RHFInputField<T>) => {
  const { field } = useController<T>({ name, control, rules })

  return (
    <InputField
      {...field}
      label={label}
      required={required}
      fieldWidth={fieldWidth}
      width={width}
      marginBottom={marginBottom}
      size={size}
      error={error}
      handleChangeData={e => {
        field.onChange(e)
        handleChangeData && handleChangeData
      }}
      maxLength={maxLength}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}
