import { ReservationGuestType } from '@/models/reservation/reservation-guest'
import { ReservationUserType } from '@/models/reservation/reservation-user'

export type ReservationUserEditFormType = {
  userName: string
  userNameKana: string
  userTel: string
  userMailAddr: string
  userAddr: string
  guestName: string
  guestNameKana: string
  guestTel: string
  guestMailAddr: string
  guestAddr: string
}

export class ReservationUserEditForm {
  constructor(private props: ReservationUserEditFormType) {}

  getReservationUser(): ReservationUserType {
    return {
      name: this.props.userName?.replace('　',' '),
      nameKana: this.props.userNameKana?.replace('　',' '),
      tel: this.props.userTel || '0',
      addr: this.props.userAddr,
      mailAddr: this.props.userMailAddr,
    }
  }

  getReservationGuest(): ReservationGuestType {
    return {
      name: this.props.guestName?.replace('　',' '),
      nameKana: this.props.guestNameKana?.replace('　',' '),
      tel: this.props.guestTel || '0',
      addr: this.props.guestAddr,
      mailAddr: this.props.guestMailAddr,
    }
  }

  validate(): (keyof ReservationUserEditFormType)[] {
    const errors: (keyof ReservationUserEditFormType)[] = []
    if (this.props.userTel && !this.validateTel(this.props.userTel)) {
      errors.push('userTel')
    }
    if (this.props.guestTel && !this.validateTel(this.props.guestTel)) {
      errors.push('guestTel')
    }
    return errors
  }

  private validateTel(tel: string): boolean {
    return !/[^0-9]/g.test(tel)
  }
}
