import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Button } from '@/components/atoms/button'
import { useHistory } from 'react-router-dom'
import { BackIcon } from '@/components/molecules/settings/icon/back-icon'
import { EditIcon } from '@/components/molecules/settings/icon/edit-icon'
import { InputField } from '@/components/molecules/input-field'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'

type Props = {
  goBack?: boolean
  title?: string
  onChangeTitle?: (value: string) => void
  onClickAddButton?: () => void
}

export const ContainerHeader: React.FC<Props> = ({ goBack, title, onChangeTitle, onClickAddButton }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false)
  const { listenDoSave, listenDoCancel, setIsEditing } = useContext(AssetDetailContext)

  useEffect(() => {
    if (listenDoSave) setIsEditingTitle(false)
  }, [listenDoSave])

  useEffect(() => {
    if (listenDoCancel) setIsEditingTitle(false)
  }, [listenDoCancel])

  return (
    <div css={containerHeaderStyle}>
      <div className="left-space">
        {goBack && <BackIcon onClick={history.goBack} />}
        {title !== undefined && (
          <div className="title-area">
            {!isEditingTitle && (
              <>
                <div className="title">{title}</div>
                {onChangeTitle && (
                  <EditIcon
                    onClick={() => {
                      setIsEditingTitle(true)
                      setIsEditing(true)
                    }}
                  />
                )}
              </>
            )}
            {isEditingTitle && (
              <InputField value={title} handleChangeData={e => onChangeTitle && onChangeTitle(e.target.value)} marginBottom={0} />
            )}
          </div>
        )}
      </div>

      {onClickAddButton && (
        <Button buttonType={1} width={84} height={32} fontSize={12} onClick={() => onClickAddButton()}>
          {t('Add new')}
        </Button>
      )}
    </div>
  )
}
const containerHeaderStyle = css({
  height: 75,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 32px 0 16px',
  borderBottom: '1px solid #F2F2F2',
  '.left-space': {
    display: 'flex',
    '.title-area': {
      display: 'flex',
      alignItems: 'center',
      minWidth: 573,
      '.title': {
        padding: '0px 8px',
        color: '#272727',
        letterSpacing: 0.6,
        fontWeight: 'bold',
        fontSize: 16,
      },
    },
  },
})
