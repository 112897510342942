import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import { css } from '@emotion/core'

type Props = {
  value: string
  onChange: (newColor: string) => void
}

export const ColorPickerInput: React.FC<Props> = ({ value, onChange }) => {
  const [isShowPicker, setIsShowPicker] = useState<boolean>(false)

  return (
    <div css={defaultStyle}>
      <input value={value} onFocus={() => setIsShowPicker(true)} onChange={e => onChange(e.target.value)} />
      <div className="color-preview" style={{ backgroundColor: value }}></div>
      {isShowPicker && (
        <>
          <div className="modal-background" onClick={() => setIsShowPicker(false)}></div>
          <SketchPicker
            disableAlpha={true}
            color={value}
            onChange={event => {
              const colorString = event.source === 'rgb' ? `rgb(${event.rgb.r},${event.rgb.g},${event.rgb.g},${event.rgb.a})` : event.hex
              onChange(colorString)
            }}
          />
        </>
      )}
    </div>
  )
}

const defaultStyle = css({
  position: 'absolute',
  '> input': {
    height: 32,
    width: 210,
    border: '1px solid #CCCCCC',
    borderRadius: 16,
    textIndent: 44,
    letterSpacing: 1.4,
    color: '#272727',
    fontSize: 14,
  },
  '.sketch-picker input': {
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    color: '#272727',
  },
  '.modal-background': {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  '.color-preview': {
    position: 'absolute',
    left: 8,
    top: 6,
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: 'solid 1px #ccc',
  },
})
