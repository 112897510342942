import React, { useState } from 'react'
import { css } from '@emotion/core'
import { Button } from '@/components/atoms/button'
import { useTranslation } from 'react-i18next'

type ConfirmState = { isConfirmed: boolean; isForceUpdate: boolean }

export const useAdjustmentConfirmDialog = () => {
  const { t } = useTranslation()
  const [isShowDialog, setIsShowDialog] = useState(false)
  const [onClickButton, setOnClickButton] = useState<(state: ConfirmState) => void>()
  const [errorMessage, setErrorMessage] = useState<string>('')

  const openAdjustmentConfirmDialog = (message: string): Promise<ConfirmState> => {
    setIsShowDialog(true)
    setErrorMessage(message)
    return new Promise(resolve => setOnClickButton(() => resolve))
  }

  const onClose = (state: ConfirmState) => {
    setIsShowDialog(false)
    if (onClickButton) {
      onClickButton(state)
    }
  }

  const AdjustmentConfirmDialog: React.FC = () =>
    isShowDialog ? (
      <>
        <div css={modalBackgroundStyle}> </div>
        <div css={modalStyle}>
          <div className="text-area">
            <p>{t('There are unsettled accounts that need to be confirmed.')}</p>
            {errorMessage.split('\n').map((m, i) => (
              <p key={i}>{`・${t(m)}`}</p>
            ))}
            <p style={{ paddingTop: 8 }}>{t("Select 'Do not update' if you want to update only the subjects that do not apply.")}</p>
          </div>
          <div className="button-area">
            <Button
              width={110}
              height={38}
              buttonType={3}
              fontSize={12}
              onClick={() =>
                onClose({
                  isConfirmed: false,
                  isForceUpdate: false,
                })
              }
            >
              {t('Cancel')}
            </Button>
            <div style={{ display: 'flex', gap: 16 }}>
              <Button
                width={110}
                height={38}
                buttonType={3}
                fontSize={12}
                onClick={() =>
                  onClose({
                    isConfirmed: true,
                    isForceUpdate: false,
                  })
                }
              >
                {t('No update')}
              </Button>
              <Button
                width={110}
                height={38}
                buttonType={1}
                fontSize={12}
                onClick={() =>
                  onClose({
                    isConfirmed: true,
                    isForceUpdate: true,
                  })
                }
              >
                {t('Update')}
              </Button>
            </div>
          </div>
        </div>
      </>
    ) : (
      <></>
    )
  return {
    openAdjustmentConfirmDialog,
    AdjustmentConfirmDialog,
  }
}

const modalStyle = css({
  width: 800,
  position: 'fixed',
  zIndex: 1000,
  background: '#FFFFFF',
  top: 'calc(-50vh + 100% - 200px / 2)',
  left: 'calc(-50vw + 100% - 800px / 2)',
  padding: '0 78px',
  borderRadius: 5,
  '.text-area': {
    fontWeight: 'bold',
    fontSize: 18,
    letterSpacing: 0.9,
    paddingTop: 40,
    lineHeight: '27px',
  },
  '.button-area': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 0',
  },
})

const modalBackgroundStyle = css({
  background: '#272727',
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  zIndex: 999,
  top: 'calc(-100vh + 100%)',
  left: 'calc(-100vw + 100%)',
  opacity: 0.5,
})
