import React, { createContext, useState, Dispatch, SetStateAction, useEffect, useContext } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { useTranslation } from 'react-i18next'

// constants
import {
  MIWA_APP_WEBSOCKET_KEY_PORT,
  MIWA_APP_WEBSOCKET_KEY_HOST,
  MIWA_APP_WEBSOCKET_CONNECTION_KEY,
  MIWA_APP_WEBSOCKET_PC_ID,
  MIWA_APP_WEBSOCKET_HOTEL_ON_SITE_CODE,
} from '@/constants/miwa-lock'

// contexts
import { AccountContext } from '@/contexts/account'

// libis
import { setHasMiwaLockPlugin } from '@/libs/plugins'

// utils
import { getItemStorage, setItemStorage, removeItemStorage } from '@/utils/storage'

// models
import { TMiwaAppWebsocketContext } from '@/models/miwa-lock'

// api
import { getMiwaAppWebsocketPort } from '@/apis/aipass'

// constants
import { MiwaLockWebsocketResponseEvent, MiwaLockWebsocketResponseStatusCode } from '@/constants/miwa-lock'

export const MIWA_APP_WEBSOCKET_PORT = 58058
export const MIWA_APP_WEBSOCKET_HOST = 'ws://127.0.0.1'

export const MiwaAppWebsocketContext = createContext<TMiwaAppWebsocketContext>({
  isShouldConnectWebsocket: false,
  setIsShouldConnectWebsocket: (() => undefined) as Dispatch<SetStateAction<boolean>>,
  isLoadingFetchConnectionInfo: false,
  fetchConnectionInfo: (async () => undefined) as () => Promise<void>,
  miwaAppWebsocketHost: MIWA_APP_WEBSOCKET_HOST,
  setMiwaAppWebsocketHost: (() => undefined) as Dispatch<SetStateAction<string>>,
  miwaAppWebsocketPCID: '',
  setMiwaAppWebsocketPCID: (() => undefined) as Dispatch<SetStateAction<string>>,
  miwaAppWebsocketPort: MIWA_APP_WEBSOCKET_PORT,
  setMiwaAppWebsocketPort: (() => undefined) as Dispatch<SetStateAction<number>>,
  miwaAppWebsocketConnectionKey: '',
  setSiwaAppWebsocketConnectionKey: (() => undefined) as Dispatch<SetStateAction<string>>,
  miwaAppWebsocketErrorMessage: '',
  setMiwaAppWebsocketErrorMessage: (() => undefined) as Dispatch<SetStateAction<string>>,
  miwaAppWebsocketHotelOnSiteCode: undefined,
  sendJsonMessage: (() => undefined) as (jsonMessage: any, keep?: boolean) => void,
  lastJsonMessage: (() => undefined) as any,
  readyState: -1,
})

export const MiwaAppWebsocketProvider = ({ children }) => {
  const { plugins } = useContext<any>(AccountContext)
  const { t } = useTranslation()

  // Check has plugin Miwa Lock
  const hasMiwaLockPlugin = setHasMiwaLockPlugin(plugins)

  const [isShouldConnectWebsocket, setIsShouldConnectWebsocket] = useState(false)
  const [isLoadingFetchConnectionInfo, setIsLoadingFetchConnectionInfo] = useState(false)
  const [miwaAppWebsocketErrorMessage, setMiwaAppWebsocketErrorMessage] = useState('')
  const [miwaAppWebsocketHost, setMiwaAppWebsocketHost] = useState(
    getItemStorage(MIWA_APP_WEBSOCKET_KEY_HOST, 'localStorage') || MIWA_APP_WEBSOCKET_HOST,
  )
  const [miwaAppWebsocketHotelOnSiteCode, setMiwaAppWebsocketHotelOnSiteCode] = useState(
    getItemStorage(MIWA_APP_WEBSOCKET_HOTEL_ON_SITE_CODE, 'localStorage') || '',
  )
  const [miwaAppWebsocketPCID, setMiwaAppWebsocketPCID] = useState(getItemStorage(MIWA_APP_WEBSOCKET_PC_ID, 'localStorage') || '')
  const [miwaAppWebsocketPort, setMiwaAppWebsocketPort] = useState(
    Number(getItemStorage(MIWA_APP_WEBSOCKET_KEY_PORT)) || MIWA_APP_WEBSOCKET_PORT,
  )
  const [miwaAppWebsocketConnectionKey, setSiwaAppWebsocketConnectionKey] = useState(
    getItemStorage(MIWA_APP_WEBSOCKET_CONNECTION_KEY) || '',
  )

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    `${miwaAppWebsocketHost}:${miwaAppWebsocketPort}`,
    {
      share: true,
      shouldReconnect: (event: WebSocketEventMap['close']) => {
        return false
      },
      queryParams: {
        pc_id: miwaAppWebsocketPCID,
        connection_id: miwaAppWebsocketConnectionKey,
      },
      onClose: (event: WebSocketEventMap['close']) => {
        const errorMessage = miwaAppWebsocketErrorMessage ? miwaAppWebsocketErrorMessage : t('Cannot connect to network. Please try again.')
        window.alert(errorMessage)
        miwaAppWebsocketErrorMessage && setMiwaAppWebsocketErrorMessage('')
        isShouldConnectWebsocket && setIsShouldConnectWebsocket(false)
      },
      onMessage: (event: WebSocketEventMap['message']) => {
        const { data } = event
        const { type, body } = JSON.parse(data)
        const statusCode = body.status_code

        if (type === MiwaLockWebsocketResponseEvent.ERROR_RESULT) {
          switch (statusCode) {
            case MiwaLockWebsocketResponseStatusCode.UNAUTHENTICATED:
              setMiwaAppWebsocketErrorMessage(t('Verification failed. Please check and try again.'))
              break
            case MiwaLockWebsocketResponseStatusCode.INVALID_DATA:
              setMiwaAppWebsocketErrorMessage(t('Unable to issue card. Please try again.'))
              break
            case MiwaLockWebsocketResponseStatusCode.CONNECTION_ERROR:
              setMiwaAppWebsocketErrorMessage(t('Cannot connect to network. Please try again.'))
              break
            case MiwaLockWebsocketResponseStatusCode.INVALID_PC_ID:
              setMiwaAppWebsocketErrorMessage(t('PCID mismatch. Please check and try again.'))
              break
            case MiwaLockWebsocketResponseStatusCode.WRITE_CARD_ERROR:
              setMiwaAppWebsocketErrorMessage(t('Unable to read card. Check your reading window app terminal.'))
              break
            case MiwaLockWebsocketResponseStatusCode.NO_CARD_INSERTED:
              setMiwaAppWebsocketErrorMessage(t('Unable to read card. Please hold your card over.'))
              break
            case MiwaLockWebsocketResponseStatusCode.NOT_SUPPORTED_CARD:
              setMiwaAppWebsocketErrorMessage(t('Unable to read card. Please check your card.'))
              break
            case MiwaLockWebsocketResponseStatusCode.SERVER_ERROR:
              setMiwaAppWebsocketErrorMessage(t('Server error'))
              break
            case MiwaLockWebsocketResponseStatusCode.DEVICE_BUSY:
              setMiwaAppWebsocketErrorMessage(
                t('The NFC is processing another card issue. Please try again after the process is complete.'),
              )
              break
            default:
              setMiwaAppWebsocketErrorMessage(t('Unable to read card. Please try again.'))
              break
          }
        }
      },
    },
    isShouldConnectWebsocket,
  )

  const fetchConnectionInfo = async () => {
    try {
      setIsLoadingFetchConnectionInfo(true)
      const { appConnectionInfo, hotelOnSiteCode } = await getMiwaAppWebsocketPort(miwaAppWebsocketPCID)
      const { port = MIWA_APP_WEBSOCKET_PORT, connectionId = 'unknown' } = appConnectionInfo

      // set port to state
      setMiwaAppWebsocketPort(port)
      setItemStorage(MIWA_APP_WEBSOCKET_KEY_PORT, port)
      // set connection key to state
      setSiwaAppWebsocketConnectionKey(connectionId)
      setItemStorage(MIWA_APP_WEBSOCKET_CONNECTION_KEY, connectionId)
      // set PC ID to localstorage
      setItemStorage(MIWA_APP_WEBSOCKET_PC_ID, miwaAppWebsocketPCID, 'localStorage')
      // change status connect websocket
      !isShouldConnectWebsocket && setIsShouldConnectWebsocket(true)

      setMiwaAppWebsocketHotelOnSiteCode(hotelOnSiteCode)
      setItemStorage(MIWA_APP_WEBSOCKET_HOTEL_ON_SITE_CODE, hotelOnSiteCode || '', 'localStorage')
    } catch (error) {
      handleErrorMessageFromServer(error)
    } finally {
      setIsLoadingFetchConnectionInfo(false)
    }
  }

  const handleErrorMessageFromServer = error => {
    if (error.response) {
      const errorCode = error.response?.data?.errorCode
      errorCode === 400
        ? window.alert(t('PCID does not exist. Please check and try again.'))
        : window.alert(t('Cannot connect to network. Please try again.'))
    } else {
      window.alert(t('Cannot connect to network. Please try again.'))
    }
  }

  useEffect(() => {
    if (readyState === ReadyState.CLOSED) {
      setIsShouldConnectWebsocket(false)
      removeItemStorage(MIWA_APP_WEBSOCKET_KEY_PORT, 'sessionStorage')
      removeItemStorage(MIWA_APP_WEBSOCKET_CONNECTION_KEY, 'sessionStorage')
    }
  }, [readyState])

  useEffect(() => {
    if (hasMiwaLockPlugin && miwaAppWebsocketPCID && miwaAppWebsocketPort && miwaAppWebsocketConnectionKey) {
      setIsShouldConnectWebsocket(true)
    }
  }, [plugins])

  return (
    <MiwaAppWebsocketContext.Provider
      value={{
        isShouldConnectWebsocket,
        setIsShouldConnectWebsocket,
        isLoadingFetchConnectionInfo,
        fetchConnectionInfo,
        miwaAppWebsocketHost,
        setMiwaAppWebsocketHost,
        miwaAppWebsocketPCID,
        setMiwaAppWebsocketPCID,
        miwaAppWebsocketPort,
        setMiwaAppWebsocketPort,
        miwaAppWebsocketConnectionKey,
        setSiwaAppWebsocketConnectionKey,
        miwaAppWebsocketErrorMessage,
        setMiwaAppWebsocketErrorMessage,
        miwaAppWebsocketHotelOnSiteCode,
        sendJsonMessage,
        lastJsonMessage,
        readyState,
      }}
    >
      {children}
    </MiwaAppWebsocketContext.Provider>
  )
}
