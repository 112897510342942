import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

import { PaymentIndex, PaymentInfoType, PaymentMethodType } from '@/models/payment-setting'

// constants
import { listContainerStyle } from '@/constants/layout'

// API
import { fetchPaymentSetting, postPaymentSetting, updatePaymentSetting } from '@/apis/aipass'
// components
import { FacilityPaymentList } from './facility-list'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { EditFooter } from '@/components/organisms/edit-footer'

type ContainerProps = {}

export const SettingPayment: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [paymentSettings, setPaymentSettings] = useState<PaymentInfoType[]>([])
  const [paymentID, setPaymentID] = useState({ id: '', value: '' })
  const [originPaymentID, setOriginPaymentID] = useState({ id: '', value: '' })

  const handleInputChange = e => {
    const paymentIdValue = e.target.value
    setPaymentID({ ...paymentID, value: paymentIdValue })
  }

  const loadPaymentSetting = () => {
    fetchPaymentSetting().then(res => {
      if (res.settings.payment_id.length !== 0) {
        setPaymentID({ ...res.settings.payment_id })
        setOriginPaymentID({ ...res.settings.payment_id })
      }
      setPaymentSettings(res.settings.payment_time ?? [])
    })
  }

  const doUpdate = async (id: string, data: { payment_time: PaymentInfoType[] } | { payment_id: string }) => {
    setIsLoading(true)
    if (id) {
      await updatePaymentSetting(data)
    } else {
      await postPaymentSetting(data)
    }
    setIsLoading(false)
    loadPaymentSetting()
  }

  const cancelEdit = () => {
    setPaymentID(originPaymentID)
    setIsEditMode(false)
  }

  const savePaymentId = () => {
    const paymentIdValue = paymentID.value.trim()
    const checkNumericHalfSize = paymentIdValue.match(/^[0-9]+$/)
    const checkLength = paymentIdValue.length !== 3

    if (checkLength) {
      alert(t('Please enter 3 digits'))
      return
    } else if (!checkNumericHalfSize) {
      alert(t('Please enter in single-byte number'))
      return
    }
    doUpdate(paymentID.id, { payment_id: paymentIdValue })
  }

  const savePaymentSetting = (type: PaymentIndex, data: PaymentInfoType) => {
    doUpdate(data.id, { payment_time: [data] })
  }

  const pickByPaymentIndex = (type: PaymentIndex): PaymentInfoType => {
    return (
      paymentSettings.find(setting => setting.value === type) || {
        id: '',
        value: type,
        is_enabled: 0,
        payment_method: [
          { value: PaymentMethodType.Front, is_enabled: 0 },
          { value: PaymentMethodType.Credit, is_enabled: 0 },
        ],
      }
    )
  }

  const footerContent = useMemo(
    () => (isEditMode ? <EditFooter onSave={savePaymentId} onCancel={cancelEdit} /> : undefined),
    [isEditMode, paymentID],
  )

  useEffect(() => {
    loadPaymentSetting()
  }, [])

  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <div css={settingPaymentContainerStyle} style={isEditMode ? { maxHeight: '806px' } : { maxHeight: 'none' }}>
        <div css={headerSettingPaymentStyle}>
          <div css={headerDetailStyle}>{t('Settlement')}</div>
        </div>
        <>
          <div css={facilityPaymentIDContainerStyle}>
            <div css={facilityPaymentIDlStyle}>{t('Transaction ID + Facility ID')}</div>
            <input
              name="paymendID"
              type="text"
              css={inputStyle}
              placeholder="000"
              onClick={() => setIsEditMode(true)}
              value={paymentID.value}
              onChange={e => handleInputChange(e)}
            />
          </div>
          <FacilityPaymentList
            statusInfo={pickByPaymentIndex(PaymentIndex.Checkin)}
            title={t('Checkin')}
            name="check-in"
            onChange={data => savePaymentSetting(PaymentIndex.Checkin, data)}
          />
          <FacilityPaymentList
            statusInfo={pickByPaymentIndex(PaymentIndex.Checkout)}
            title={t('Checkout')}
            name="check-out"
            onChange={data => savePaymentSetting(PaymentIndex.Checkout, data)}
          />
          <FacilityPaymentList
            statusInfo={pickByPaymentIndex(PaymentIndex.MobileRequest)}
            title={t('Mobile request')}
            name="mobile-request"
            directPaymentTitle={t('Check out payment')}
            onChange={data => savePaymentSetting(PaymentIndex.MobileRequest, data)}
          />
          <FacilityPaymentList
            statusInfo={pickByPaymentIndex(PaymentIndex.SelfCheckin)}
            title={t('Self check-in')}
            name="self-check-in"
            onChange={data => savePaymentSetting(PaymentIndex.SelfCheckin, data)}
          />
          <FacilityPaymentList
            statusInfo={pickByPaymentIndex(PaymentIndex.ReserveSpace)}
            title={t('Reserve space')}
            name="reserve-space"
            onChange={data => savePaymentSetting(PaymentIndex.ReserveSpace, data)}
          />
        </>
      </div>
    </SettingsLayout>
  )
}

const settingPaymentContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 410,
  maxHeight: 807,
  boxShadow: '0px 0px 6px #0000001a',
  borderRadius: 5,
  height: '100%',
  marginBottom: 16,
  overflowY: 'auto',
})
const headerSettingPaymentStyle = css({
  height: '50px',
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const headerDetailStyle = css({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: '16px 0',
  color: '#272727',
})

const facilityContainerStyle = css({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 64,
  borderBottom: '1px solid #F2F2F2',
  padding: '0 32px',
})

const facilityPaymentIDContainerStyle = css([
  facilityContainerStyle,
  {
    justifyContent: 'flex-start',
  },
])

const facilityNameStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  width: '100%',
  padding: '25px 0px',
  height: '100%',
})

export const facilityCheckboxStyle = css({
  display: 'none',
  ':checked': {
    '+label': {
      backgroundColor: '#f2a40b',
      border: 'none',
      position: 'relative',

      '&::before': {
        content: "'ON'",
        color: '#fff',
        fontSize: 12,
        letterSpacing: 1.2,
        left: 15,
        position: 'absolute',
      },
      '&::after': {
        top: 3,
        left: 47,
        backgroundColor: '#fff',
      },
    },
  },
})

export const facilityCheckboxLabelStyle = css({
  width: 73,
  height: 28,
  background: '#F2F2F2',
  position: 'relative',
  display: 'inline-block',
  borderRadius: 14,
  transition: '0.4s',
  boxSizing: 'border-box',
  border: '1px solid #ccc',
  cursor: 'pointer',

  '&::before': {
    content: "'OFF'",
    fontWeight: 'bold',
    color: '#a3a3a3',
    fontSize: 11,
    letterSpacing: 0.55,
    left: 30,
    position: 'absolute',
    top: 7,
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    width: 22,
    height: 22,
    borderRadius: '100%',
    top: 2,
    left: 4,
    zIndex: 2,
    background: '#fff',
    transition: '0.4s',
    boxShadow: '0px 2px 3px #00000029',
  },
})

const facilityPaymentIDlStyle = css([
  facilityNameStyle,
  {
    fontSize: 12,
    color: '#676767',
    width: 'auto',
    paddingRight: 24,
  },
])

const inputStyle = css({
  width: '72px',
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  padding: '0 16px',
  textAlign: 'left',
  '&::-webkit-outer-spin-button': {
    appearance: 'none',
  },
})
