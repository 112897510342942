import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { tableBodyTdStyle } from '@/constants/layout'
import { InvoiceStatusType, InvoiceStatusValues, InvoiceType } from '@/models/sales-invoice'
import dayjs from 'dayjs'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import { changeInvoiceStatus } from '@/apis/aipass'
import { LoaderContextCreator } from '@/contexts/loader'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useAdjustmentConfirmDialog } from './use-adjustment-confirm-dialog'
import { AxiosError } from 'axios'

type Props = {
  invoice: InvoiceType
  onUpdate: () => void
}

export const InvoiceListRow: React.FC<Props> = ({ invoice, onUpdate }) => {
  const { t } = useTranslation()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const { errorHandler } = useErrorHandler()
  const { AdjustmentConfirmDialog, openAdjustmentConfirmDialog } = useAdjustmentConfirmDialog()

  const changeStatus = async (val: InvoiceStatusType) => {
    if (invoice.status === InvoiceStatusType.Completed) {
      if (!window.confirm('支払い方法が請求書の精算済み科目を未精算に戻しますか？\n※確定済み日付の明細は変更されません')) {
        return
      }
    }
    try {
      setIsLoading(true)
      await changeInvoiceStatus(invoice.id, { status: val })
      onUpdate()
    } catch (e) {
      const error = e as AxiosError
      if (error?.response?.status !== 428) {
        errorHandler(error)
        return
      }
      setIsLoading(false)
      await confirmAndChangeStatus(val, error.response.data.errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const confirmAndChangeStatus = async (val: InvoiceStatusType, errorMessage: string) => {
    const { isConfirmed, isForceUpdate } = await openAdjustmentConfirmDialog(errorMessage)
    if (!isConfirmed) {
      return
    }
    try {
      setIsLoading(true)
      await changeInvoiceStatus(invoice.id, {
        status: val,
        isConfirmed,
        isForceUpdate,
      })
      onUpdate()
    } catch (e) {
      errorHandler(e)
    }
  }

  const statusBoxColorStyle = useMemo(() => {
    if (invoice.status === InvoiceStatusType.InProgress) {
      return css({
        '.ant-select-selection': { border: '1px solid #E7A73C !important' },
        '.ant-select-selection-selected-value': { color: '#F2A40B !important' },
        '.ant-select-arrow': { color: '#F2A40B' },
      })
    }
    if (invoice.status === InvoiceStatusType.Expired) {
      return css({
        '.ant-select-selection': { border: '1px solid #F47110 !important' },
        '.ant-select-selection-selected-value': { color: '#F47110 !important' },
        '.ant-select-arrow': { color: '#F47110' },
      })
    }
    if (invoice.status === InvoiceStatusType.Cancel) {
      return css({
        '.ant-select-selection': { border: '1px solid #676767 !important', background: 'none !important' },
        '.ant-select-selection-selected-value': { color: '#676767 !important' },
        '.ant-select-arrow': { display: 'none' },
        '.ant-select-selection__rendered': { lineHeight: '24px', marginLeft: 24 },
      })
    }
    return css({
      '.ant-select-selection': {
        border: '1px solid #676767 !important',
      },
      '.ant-select-selection-selected-value': { color: '#676767 !important' },
      '.ant-select-arrow': { color: '#676767' },
    })
  }, [invoice.status])

  return (
    <>
      <tr key={invoice.id} css={rowStyle}>
        <td css={[tableBodyTdStyle]}>{dayjs(invoice.createdAt).format(t('MM-DD HH:mm'))}</td>
        <td css={[tableBodyTdStyle]}>
          <Select
            value={invoice.status}
            customStyle={css([statusSelectStyle, statusBoxColorStyle])}
            onChange={v => changeStatus(v as unknown as InvoiceStatusType)}
            disabled={invoice.status === InvoiceStatusType.Cancel}
          >
            <AntSelect.Option value={InvoiceStatusType.InProgress}>{InvoiceStatusValues[InvoiceStatusType.InProgress]}</AntSelect.Option>
            <AntSelect.Option value={InvoiceStatusType.Expired}>{InvoiceStatusValues[InvoiceStatusType.Expired]}</AntSelect.Option>
            <AntSelect.Option value={InvoiceStatusType.Completed}>{InvoiceStatusValues[InvoiceStatusType.Completed]}</AntSelect.Option>
            <AntSelect.Option value={InvoiceStatusType.Cancel}>{InvoiceStatusValues[InvoiceStatusType.Cancel]}</AntSelect.Option>
          </Select>
        </td>
        <td css={[tableBodyTdStyle]}>
          <a href={invoice.filePath} target="_blank" rel="noreferrer">
            {invoice.fileName}
          </a>
        </td>
        <td css={[tableBodyTdStyle]}>¥{invoice.totalAmount.toLocaleString()}</td>
        <td css={[tableBodyTdStyle]}>{dayjs(invoice.paymentDate).format(t('MM-DD'))}</td>
        <td css={[tableBodyTdStyle]}>{invoice.completedAt ? dayjs(invoice.completedAt).format(t('MM-DD HH:mm')) : '-'}</td>
      </tr>
      <AdjustmentConfirmDialog />
    </>
  )
}

const rowStyle = css({
  fontWeight: 'bold',
  color: '#272727',
  height: 64,
  a: {
    color: '#F2A40B',
  },
})

const statusSelectStyle = css({
  paddingBottom: 0,
  width: 100,
  '.ant-select-selection': {
    fontSize: 12,
    border: '1px solid #E7A73C !important',
    borderRadius: 5,
    height: 24,
  },
  '.ant-select-selection-selected-value': { color: '#F2A40B !important' },
  '.ant-select-selection__rendered': { lineHeight: '24px', marginLeft: 19 },
  '.ant-select-arrow': {
    img: { display: 'none' },
    width: 0,
    height: 0,
    color: '#F2A40B',
    borderStyle: 'solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    borderTop: '6px solid ',
    borderBottom: 0,
  },
})
