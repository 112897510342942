import React from 'react'
import { css } from '@emotion/core'

// components
import { LanguageSelect } from '@/components/molecules'

type Props = {
  title: string
  goToPreviousPage?: () => void
}

export const SelfCheckinHeader: React.FC<Props> = ({ title, goToPreviousPage }) => {
  return (
    <div css={headerStyle}>
      <div>
        {goToPreviousPage && (
          <img src={require('@/static/images/arrow_left_yellow.svg')} css={goToPreviousStyle} onClick={() => goToPreviousPage()} />
        )}
      </div>
      <div>{title}</div>
      <div>
        <LanguageSelect />
      </div>
    </div>
  )
}

const headerStyle = css({
  height: 80,
  fontSize: 22,
  color: '#272727',
  fontWeight: 'bold',
  fontFamily: 'Noto Sans JP',
  lineHeight: '27px',
  letterSpacing: '1.8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 3px #00000014',
  backgroundColor: '#fff',
  position: 'fixed',
  width: '100%',
  zIndex: 5,
  paddingLeft: '40px',
  paddingRight: '40px',
  '@media(max-width: 1080px)': {
    height: 70,
    fontSize: 22,
    paddingLeft: '20px',
    paddingRight: '20px',
  },
})

const goToPreviousStyle = css({
  width: '52px',
  height: '52px',
  zIndex: 6,
  cursor: 'pointer',
})
