import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Label } from '@/components/molecules/label'
import { Button } from '@/components/atoms'
import { DateField } from '@/components/molecules/date-field'
import { Checkbox } from '@/components/atoms/checkbox'
import { InvoiceSearchType, InvoiceStatusType } from '@/models/sales-invoice'
import { Moment } from 'moment'

type Props = {
  onSearch: (value: InvoiceSearchType) => void
}

type FiltersInputType = {
  status: {
    inProgress: boolean
    notCollected: boolean
    collected: boolean
    cancelled: boolean
  }
  beginDate?: Moment
  endDate?: Moment
}

export const InvoiceSearch: React.FC<Props> = ({ onSearch }) => {
  const { t } = useTranslation()
  const { control, handleSubmit, reset } = useForm<FiltersInputType>({
    defaultValues: {
      status: {
        inProgress: true,
        notCollected: true,
      },
    },
  })

  const doSearch = (data: FiltersInputType) => {
    onSearch({
      filterStatus: [
        data.status.inProgress && InvoiceStatusType.InProgress,
        data.status.cancelled && InvoiceStatusType.Cancel,
        data.status.collected && InvoiceStatusType.Completed,
        data.status.notCollected && InvoiceStatusType.Expired,
      ].filter((v): v is InvoiceStatusType => !!v),
      paymentDateFrom: data.beginDate ? data.beginDate.format('YYYY-MM-DD') : undefined,
      paymentDateTo: data.endDate ? data.endDate.format('YYYY-MM-DD') : undefined,
    })
  }

  return (
    <div css={dropDownResearchPanelStyle}>
      <div className="form-block">
        <div className="input-block">
          <Label label={t('Invoice status')} />
          <div className="invoice-status-parts">
            <Controller
              control={control}
              name="status.inProgress"
              render={({ field: { onChange, value } }) => (
                <div css={{ margin: '0 8px' }}>
                  <Checkbox value={value} onChange={onChange} label={t('In progress')} />
                </div>
              )}
            />
            <Controller
              control={control}
              name="status.notCollected"
              render={({ field: { onChange, value } }) => (
                <div css={{ margin: '0 8px' }}>
                  <Checkbox value={value} onChange={onChange} label={t('Not collected')} />
                </div>
              )}
            />
            <Controller
              control={control}
              name="status.collected"
              render={({ field: { onChange, value } }) => (
                <div css={{ margin: '0 8px' }}>
                  <Checkbox value={value} onChange={onChange} label={t('Collected')} />
                </div>
              )}
            />
            <Controller
              control={control}
              name="status.cancelled"
              render={({ field: { onChange, value } }) => (
                <div css={{ margin: '0 8px' }}>
                  <Checkbox value={value} onChange={onChange} label={t('Cancelled')} />
                </div>
              )}
            />
          </div>
        </div>

        <div>
          <Label label={t('Payment at')} />
          <div>
            <div css={{ display: 'flex', alignItems: 'center', margin: '0 -8px' }}>
              <Controller
                control={control}
                name="beginDate"
                render={({ field: { onChange, value } }) => (
                  <div css={{ margin: '0 8px' }}>
                    <DateField value={value} onChange={onChange} placeholder={t('Full date')} />
                  </div>
                )}
              />
              <div css={{ margin: '0 8px' }}>~</div>
              <Controller
                control={control}
                name="endDate"
                render={({ field: { onChange, value } }) => (
                  <div css={{ margin: '0 8px' }}>
                    <DateField value={value} onChange={onChange} placeholder={t('Full date')} />
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div css={bottomResearchPanelStyle}>
        <div css={assignButtonContainerStyle}>
          <Button
            width={92}
            height={32}
            buttonType={3}
            fontSize={12}
            onClick={() => {
              reset()
            }}
          >
            {t('Clear')}
          </Button>
          <Button width={93} height={32} buttonType={1} fontSize={12} marginLeft={16} onClick={handleSubmit(doSearch)}>
            {t('Search')}
          </Button>
        </div>
      </div>
    </div>
  )
}

const dropDownResearchPanelStyle = css({
  width: '100%',
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 50,
  left: 0,
  boxShadow: '0px 3px 10px #0000003d',
  '.form-block': {
    padding: 32,
    gap: 32,
    display: 'flex',
    '.input-block': {
      '> p': { marginBottom: 18 },
    },
    '.invoice-status-parts': {
      display: 'flex',
      gap: 16,
    },
  },
})

const bottomResearchPanelStyle = css({
  borderTop: '1px solid rgb(242, 242, 242)',
  display: 'flex',
  padding: '16px 32px',
})

const assignButtonContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})
