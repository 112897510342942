import React, { useContext } from 'react'
import { Select } from 'antd'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import {
  fetchReservationDetail,
  fetchSales,
  insertAdminReservationCheckin,
  putAdminReservationApprovedStatus,
  updateSalesAdvancePayment,
  updateSelfCheckin,
} from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { RoomManagerIndicatorContext } from '@/components/pages/room-manager/indicator'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'
import dayjs from 'dayjs'
import { css } from '@emotion/core'

type Props = {
  value: ApprovedStatusType
  reservationId: string
  checkinId?: string
  disabled?: boolean
}

export const ReservationApprovedStatusSelect: React.FC<Props> = ({ value, reservationId, checkinId, disabled }) => {
  const { errorHandler } = useErrorHandler()
  const { doReload, setIsLoading } = useContext(RoomManagerIndicatorContext)
  const { t } = useTranslation()

  const createCheckin = async (): Promise<void> => {
    const { reservations: reservation } = await fetchReservationDetail({ reservationId })
    const checkinVariables = {
      hotelId: reservation.hotelId,
      approvedStatus: ApprovedStatusType.Checkin,
      userCheckinTime: reservation.checkinDate ? dayjs(reservation.checkinDate).unix() : null,
      checkoutTime: reservation.checkoutDate ? dayjs(reservation.checkoutDate).unix() : null,
      guest: {
        name: reservation.guestName || reservation.name || '',
        nameKana: reservation.guestNameKana || reservation.nameKana || '',
        birthDate: null,
        nationality: null,
        gender: null,
        postalCode: null,
        address: reservation.guestName ? reservation.guestAddr : reservation.userAddr,
        telephone: reservation.guestName ? reservation.guestTel || '0' : reservation.tel || '0',
        email: (reservation.guestName ? reservation.guestMailAddr : reservation.mailAddr) || '',
        occupation: null,
        otherOccupation: null,
        totalMember: reservation.paxTotal,
        picture: null,
        credential: null,
        company: null,
      },
      accompany: [],
      previousPlaceToStay: '自宅',
      nextPlaceToStay: '自宅',
      reservationId,
    }
    const createdCheckin = await updateSelfCheckin(uuid(), checkinVariables, reservationId)
    await insertAdminReservationCheckin(createdCheckin.checkinId, reservationId)
    await updateSalesAdvancePayment([reservationId])
  }

  const changeApprovedStatus = async (changedValue: ApprovedStatusType): Promise<void> => {
    const isPayable = ([ApprovedStatusType.Stay, ApprovedStatusType.Checkout] as ApprovedStatusType[]).includes(value)
    if (isPayable && changedValue === ApprovedStatusType.Checkin) {
      const salesResponse = await fetchSales(checkinId)
      const hasSalesPaid = salesResponse.sales.some(item => item.isPaid && item.isConfirm && item.reservationId === reservationId)
      if (hasSalesPaid) {
        alert('計上済の科目があるので、ステータスを変更できません。')
        return
      }
    }
    await putAdminReservationApprovedStatus({ reservationIds: [reservationId], approvedStatus: changedValue })
  }

  return (
    <Select
      css={css({ 'span.ant-select-arrow': { right: 17 } })}
      className="room-status"
      suffixIcon={!disabled ? <img src={require('@/static/images/arrow_down_yellow.svg')} /> : <></>}
      defaultValue={value}
      onChange={async changedValue => {
        try {
          setIsLoading && setIsLoading(true)
          if (value === ApprovedStatusType.Reservation) {
            await createCheckin()
          }
          await changeApprovedStatus(changedValue)
          doReload && doReload()
        } catch (error) {
          errorHandler(error)
        } finally {
          setIsLoading && setIsLoading(false)
        }
      }}
      disabled={disabled}
    >
      {value === ApprovedStatusType.Reservation && <Select.Option value={ApprovedStatusType.Reservation}>{t('Reservation')}</Select.Option>}
      <Select.Option value={ApprovedStatusType.Checkin}>{t('CheckIn')}</Select.Option>
      <Select.Option value={ApprovedStatusType.Stay}>{t('Stay')}</Select.Option>
      <Select.Option value={ApprovedStatusType.Checkout}>{t('Checkout')}</Select.Option>
    </Select>
  )
}
