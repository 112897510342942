import React, { CSSProperties } from 'react'
import { css } from '@emotion/core'

type Props = {
  onClick: () => void
  style?: CSSProperties 
}
export const DeleteIcon: React.FC<Props> = ({ onClick, style }) => {
  return (
    <div css={buttonStyle} style={style}>
      <img
        src={require('@/static/images/icon_delete2.svg')}
        className="icon"
        onClick={e => {
          e.stopPropagation()
          onClick()
        }}
      />
    </div>
  )
}

const buttonStyle = css({
  display: 'flex',
  '.icon': {
    width: 30,
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '50%',
      background: '#f2f2f2',
      cursor: 'pointer',
    },
  },
})
