import { useCallback, useState } from 'react'

// apis
import { fetchGuestRoomSelectable, fetchGuestRoomSelectableHasRoomManagerPlugin } from '@/apis/aipass'

// models
import { GuestRoomSelectableResponseType } from '@/models/room-manager/response/guest-room-selectable'

type UseRoomsSelectableType = [
  GuestRoomSelectableResponseType['guestRooms'],
  () => Promise<GuestRoomSelectableResponseType['guestRooms']>,
]

type UseRoomsSelectableHasRoomManagerType = [
  GuestRoomSelectableResponseType['guestRooms'],
  (
    assignCheckinDate: string,
    assignCheckoutDate: string,
    reservationId?: string,
  ) => Promise<GuestRoomSelectableResponseType['guestRooms']>,
]

// No room management plug-in
export function useRoomsSelectable(): UseRoomsSelectableType {
  const [roomsSelectable, setRoomsSelectable] = useState<GuestRoomSelectableResponseType['guestRooms']>([])

  const handleFetchSelectableGuestRoom = useCallback(async () => {
    return await fetchGuestRoomSelectable().then((guestRooms: GuestRoomSelectableResponseType['guestRooms']) => {
      if (!guestRooms) {
        return []
      }
      setRoomsSelectable(guestRooms)
      return guestRooms
    })
  }, [])

  return [roomsSelectable, handleFetchSelectableGuestRoom]
}

// 客室管理プラグイン有
export function useRoomsSelectableHasRoomManagerPlugin(): UseRoomsSelectableHasRoomManagerType {
  const [roomsSelectable, setRoomsSelectable] = useState<GuestRoomSelectableResponseType['guestRooms']>([])

  const handleFetchSelectableGuestRoom = useCallback(
    async (assignCheckinDate: string, assignCheckoutDate: string, reservationId?: string) => {
      return await fetchGuestRoomSelectableHasRoomManagerPlugin(assignCheckinDate, assignCheckoutDate, reservationId).then(
        (guestRooms: GuestRoomSelectableResponseType['guestRooms']) => {
          if (!guestRooms) {
            return []
          }
          setRoomsSelectable(guestRooms)
          return guestRooms
        },
      )
    },
    [],
  )

  return [roomsSelectable, handleFetchSelectableGuestRoom]
}
