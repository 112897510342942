import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// apis
import { fetchFacilityBasicInfo, updateFacilityBasicInfo } from '@/apis/aipass'

// data
import { times } from '@/libs/times'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle, listContainerStyle } from '@/constants/layout'

// components
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { SettingSideMenu } from '@/components/organisms/setting-side-menu'
import { Button } from '@/components/atoms/button'
import { LoadingFull } from '@/components/molecules/loading-full'

type ContainerProps = {}

type Props = {
  location: any
  history: any
  telephone: string
  setFrontTelephone: any
  checkinBeginTime: string
  setCheckinBeginTime: any
  checkinEndTime: string
  setCheckInEndTime: any
  checkoutBeginTime: string
  setCheckoutBeginTime: any
  checkoutEndTime: string
  setCheckoutEndTime: any
  frontOpenTime: string
  setFrontOpenTime: any
  frontCloseTime: any
  setFrontCloseTime: any
  isLoading: boolean
  onSubmit: () => void
}

export const SettingFacilityBasicEdit: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  // Hooks
  const [hotelStatus, setHotelStatus] = useState<any>({})
  const [telephone, setFrontTelephone] = useState<string>('')
  const [checkinBeginTime, setCheckinBeginTime] = useState<string>('')
  const [checkinEndTime, setCheckInEndTime] = useState<string>('')
  const [checkoutBeginTime, setCheckoutBeginTime] = useState<string>('')
  const [checkoutEndTime, setCheckoutEndTime] = useState<string>('')
  const [frontOpenTime, setFrontOpenTime] = useState<string>('')
  const [frontCloseTime, setFrontCloseTime] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    _fetchFacilityBasicInfo()
  }, [])

  // Basic information acquisition processing
  const _fetchFacilityBasicInfo = async () => {
    await fetchFacilityBasicInfo()
      .then(res => {
        if (res !== undefined) {
          setHotelStatus(res?.basicInfo)
          setFrontTelephone(res?.basicInfo.telephone)
          setCheckinBeginTime(res?.basicInfo.checkinBeginTime)
          setCheckInEndTime(res?.basicInfo.checkinEndTime)
          setCheckoutBeginTime(res?.basicInfo.checkoutBeginTime)
          setCheckoutEndTime(res?.basicInfo.checkoutEndTime)
          setFrontOpenTime(res?.basicInfo.frontOpenTime)
          setFrontCloseTime(res?.basicInfo.frontCloseTime)
          setIsLoading(false)
        }
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  // Basic information update process
  const onSubmit = () => {
    _updateFacilityBasicInfo()
    setIsLoading(true)
  }

  const _updateFacilityBasicInfo = async () => {
    const { hotelName, hotelNameLatin, logoImageId, extImageId, pageId } = hotelStatus
    const data = {
      hotelName,
      hotelNameLatin,
      telephone,
      checkinBeginTime,
      checkinEndTime,
      checkoutBeginTime,
      checkoutEndTime,
      frontOpenTime,
      frontCloseTime,
      logoImageId,
      extImageId,
      pageId,
    }
    await updateFacilityBasicInfo(data)
      .then(() => {
        setIsLoading(false)
        history.push({ pathname: '/setting/facility/list', search: location.search })
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  return (
    <UI
      location={location}
      history={history}
      telephone={telephone}
      setFrontTelephone={setFrontTelephone}
      checkinBeginTime={checkinBeginTime}
      setCheckinBeginTime={setCheckinBeginTime}
      checkinEndTime={checkinEndTime}
      setCheckInEndTime={setCheckInEndTime}
      checkoutBeginTime={checkoutBeginTime}
      setCheckoutBeginTime={setCheckoutBeginTime}
      checkoutEndTime={checkoutEndTime}
      setCheckoutEndTime={setCheckoutEndTime}
      frontOpenTime={frontOpenTime}
      setFrontOpenTime={setFrontOpenTime}
      frontCloseTime={frontCloseTime}
      setFrontCloseTime={setFrontCloseTime}
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  )
}

export const UI: React.FC<ContainerProps & Props> = ({
  location,
  history,
  telephone,
  setFrontTelephone,
  checkinBeginTime,
  setCheckinBeginTime,
  checkinEndTime,
  setCheckInEndTime,
  checkoutBeginTime,
  setCheckoutBeginTime,
  checkoutEndTime,
  setCheckoutEndTime,
  frontOpenTime,
  setFrontOpenTime,
  frontCloseTime,
  setFrontCloseTime,
  isLoading,
  onSubmit,
}) => {
  const { t } = useTranslation()

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header title={t('Setting')} isPublicPageLink />
        <div className="mainContainer" css={settingContainerStyle}>
          <SettingSideMenu />
          <div css={basicDetailContainerStyle}>
            <div css={basicDetailMainContainerStyle}>
              <div css={basicDetailTopContainerStyle}>
                <div css={basicDetailHeaderContainerStyle}>
                  <div css={basicDetailHeaderTitleStyle}>{t('Basic information')}</div>
                </div>
                <div css={basicDetailFormContainerStyle}>
                  <div css={basicDetailContentsStyle}>
                    <label css={basicDetailContentsLabelStyle}>{t('Front phone number')}</label>
                    <input
                      type="text"
                      css={basicDetailContentsFrontNumberInputStyle}
                      placeholder="08012345678"
                      value={telephone}
                      onChange={e => setFrontTelephone(e.target.value)}
                    />
                  </div>
                  <div css={basicDetailContentsStyle}>
                    <label css={basicDetailContentsLabelStyle}>{t('Check-in time')}</label>
                    <div css={basicDetailContentsSelectContainerStyle}>
                      <select
                        css={basicDetailContentsSelectBoxStyle}
                        value={checkinBeginTime}
                        onChange={e => setCheckinBeginTime(e.target.value)}
                      >
                        {times.map((item, index) => (
                          <option key={index} value={item['value']}>
                            {item['name']}
                          </option>
                        ))}
                      </select>
                      <div css={basicDetailContentsSepStyle}>〜</div>
                      <select
                        css={basicDetailContentsSelectBoxStyle}
                        value={checkinEndTime}
                        onChange={e => setCheckInEndTime(e.target.value)}
                      >
                        {times.map((item, index) => (
                          <option key={index} value={item['value']}>
                            {item['name']}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div css={basicDetailContentsStyle}>
                    <label css={basicDetailContentsLabelStyle}>{t('Check out time')}</label>
                    <div css={basicDetailContentsSelectContainerStyle}>
                      <select
                        css={basicDetailContentsSelectBoxStyle}
                        value={checkoutBeginTime}
                        onChange={e => setCheckoutBeginTime(e.target.value)}
                      >
                        {times.map((item, index) => (
                          <option key={index} value={item['value']}>
                            {item['name']}
                          </option>
                        ))}
                      </select>
                      <div css={basicDetailContentsSepStyle}>〜</div>
                      <select
                        css={basicDetailContentsSelectBoxStyle}
                        value={checkoutEndTime}
                        onChange={e => setCheckoutEndTime(e.target.value)}
                      >
                        {times.map((item, index) => (
                          <option key={index} value={item['value']}>
                            {item['name']}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div css={basicDetailContentsStyle}>
                    <label css={basicDetailContentsLabelStyle}>{t('Reception opening hours')}</label>
                    <div css={basicDetailContentsSelectContainerStyle}>
                      <select
                        css={basicDetailContentsSelectBoxStyle}
                        value={frontOpenTime}
                        onChange={e => setFrontOpenTime(e.target.value)}
                      >
                        {times.map((item, index) => (
                          <option key={index} value={item['value']}>
                            {item['name']}
                          </option>
                        ))}
                      </select>
                      <div css={basicDetailContentsSepStyle}>〜</div>
                      <select
                        css={basicDetailContentsSelectBoxStyle}
                        value={frontCloseTime}
                        onChange={e => setFrontCloseTime(e.target.value)}
                      >
                        {times.map((item, index) => (
                          <option key={index} value={item['value']}>
                            {item['name']}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div css={settingFooterContainerStyle}>
              <Button
                buttonType={3}
                width={110}
                height={38}
                marginLeft={32}
                marginTop={11}
                marginBottom={11}
                onClick={() => history.push({ pathname: '/setting/facility/list', search: location.search })}
              >
                {t('Cancel')}
              </Button>
              <Button buttonType={1} width={110} height={38} marginLeft={16} marginTop={11} marginBottom={11} onClick={() => onSubmit()}>
                {t('Save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const settingContainerStyle = css(mainContainerStyle, {
  display: 'flex',
  padding: 0,
})

const basicDetailContainerStyle = css({
  width: 'calc(100% - 180px)',
})

const basicDetailMainContainerStyle = css({
  padding: 24,
  height: '88vh',
})

const basicDetailTopContainerStyle = css(listContainerStyle, {
  width: '100%',
  height: '100%',
  background: '#FFF',
  marginBottom: 16,
  minHeight: 610,
})

const basicDetailHeaderContainerStyle = css({
  height: 50,
  paddingLeft: 32,
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const basicDetailHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
})

const basicDetailFormContainerStyle = css({
  margin: '24px 32px 0px',
  height: 300,
})

const basicDetailContentsStyle = css({
  marginBottom: 24,
})

const basicDetailContentsLabelStyle = css({
  display: 'block',
  fontSize: 12,
  fontWeight: 'bold',
  color: '#676767',
  marginBottom: 12,
})

const basicDetailContentsFrontNumberInputStyle = css({
  width: 372,
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  padding: '0 16px',
  '::placeholder': {
    color: '#CCCCCC',
    fontSize: 12,
    letterSpacing: 0.7,
  },
})

const basicDetailContentsSelectContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
})

const basicDetailContentsSelectBoxStyle = css({
  width: 115,
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  padding: '0 30px 0 16px',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '85px center',
  backgroundSize: 10,
  display: 'block',
  cursor: 'pointer',
})

const basicDetailContentsSepStyle = css({
  margin: '0 8px',
})

// footer
const settingFooterContainerStyle = css({
  display: 'flex',
  height: 59,
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: '#FFF',
  boxShadow: '0px 1px 6px rgba(0,0,0,0.10)',
})
