import React, { useEffect, useState } from 'react'
import { MailDeliveryContent } from '@/models/mail-delivery/mail-delivery-content'
import { getMailDeliveryContentDetail } from '@/apis/aipass'
import { errorHandler } from '@/libs/errors'
import { useParams } from 'react-router-dom'
import { FormEditContainer } from '@/components/organisms/settings/mail-delivery/form-edit-container'

export const MailDeliveryContentEdit: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState<MailDeliveryContent>()

  const { id } = useParams<{ id: string }>()

  const _getMailDeliveryContentDetail = async () => {
    try {
      setLoading(true)
      const result = await getMailDeliveryContentDetail(id)
      setDetail(result)
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    _getMailDeliveryContentDetail()
  }, [])

  return detail ? <FormEditContainer detail={detail} fetchLoading={loading} /> : <></>
}
