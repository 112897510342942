import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
// slider can select range
import Slider from '@material-ui/core/Slider'
// cropper can crop images
import Cropper from 'react-easy-crop'
// getCroppedImg is a method that returns the image URL as png
import getCroppedImg from '@/components/pages/setting/account/cropImage.js'
import uuid from 'react-uuid'
// S3 is storage for the internet
import S3 from 'aws-sdk/clients/s3'
// b64toBlob converts base64 string to Blob format File
// Blob objects store raw binary data
// The only properties of the Blob object are size and type, and the data inside cannot be accessed directly.
import b64toBlob from 'b64-to-blob'

// apis
import { updateFacilityBasicInfo } from '@/apis/aipass'

// libs
import { env } from '@/libs/env'

// constants
import { listContainerStyle } from '@/constants/layout'

// components
import { Button } from '@/components/atoms/button'
import { LoadingFull } from '@/components/molecules/loading-full'

const accessKeyId = env('AWS_ACCESS_KEY_ID')
const secretAccessKey = env('AWS_SECRET_ACCESS_KEY')
const bucketName = env('AWS_BUCKET_NAME')
const region = env('AWS_REGION')

// Required information for uploading to S3
const bucket = new S3({
  accessKeyId,
  secretAccessKey,
  region,
})

type ContainerProps = {}

type Props = {
  basicInfo: any
  isOpenEditPage: boolean
  setIsOpenEditPage: (boolean) => void
  isSaved: boolean
  setIsSaved: (boolean) => void
}
export const TopImageEdit: React.FC<ContainerProps & Props> = ({ basicInfo, isOpenEditPage, setIsOpenEditPage, isSaved, setIsSaved }) => {
  const { t } = useTranslation()

  //Hooks
  // FIXME: It doesn't have to be State because there is an unused part
  // eslint-disable-next-line no-unused-vars
  const [pageId, _setpageId] = useState(basicInfo?.pageId)
  const [facilityRecommendImageId, setFacilityRecommendImageId] = useState(basicInfo?.facilityRecommendImageId)
  const [foodImageId, setFoodImageId] = useState(basicInfo?.foodImageId)
  const [playImageId, setPlayImageId] = useState(basicInfo?.playImageId)
  const [buyImageId, setBuyImageId] = useState(basicInfo?.buyImageId)
  const [facilityRecommendLinkUrl, setfacilityRecommendLinkUrl] = useState(basicInfo?.facilityRecommendLinkUrl)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false)

  // cropModal
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [cropModalOpen, setCropModalOpen] = useState(false)
  const [cropModalType, setCropModalType] = useState('')
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  // File upload process
  const handleChangeFile = (e: any, type: string) => {
    const files = e.target.files
    if (files.length > 0) {
      const file = files[0] // get first file
      // Create FileReader
      const reader = new FileReader()
      // Event when load completes
      reader.onload = e => {
        // e.target.resultでDataURIを取得
        const imageUrl = e.target ? e.target.result : null
        if (type === 'facilityRecommend') {
          setFacilityRecommendImageId(imageUrl)
        } else if (type === 'food') {
          setFoodImageId(imageUrl)
        } else if (type === 'play') {
          setPlayImageId(imageUrl)
        } else if (type === 'buy') {
          setBuyImageId(imageUrl)
        }
        setCropModalOpen(true)
        setCropModalType(type)
      }
      // reads the specified Blob or File object
      reader.readAsDataURL(file)
    } else {
      console.log(t('Upload failed'))
    }
  }

  // image processing
  const showCroppedImage = async (cropModalType: string) => {
    let fixedCropModalTypeId = ''
    if (cropModalType === 'facilityRecommend') {
      fixedCropModalTypeId = facilityRecommendImageId
    } else if (cropModalType === 'food') {
      fixedCropModalTypeId = foodImageId
    } else if (cropModalType === 'play') {
      fixedCropModalTypeId = playImageId
    } else if (cropModalType === 'buy') {
      fixedCropModalTypeId = buyImageId
    }
    let croppedImage: any = await getCroppedImg(fixedCropModalTypeId, croppedAreaPixels)
    croppedImage = croppedImage.replace('data:image/jpeg;base64,', '')

    const param: S3.Types.PutObjectRequest = {
      Bucket: bucketName,
      Key: `${uuid()}.jpeg`, // The key becomes the S3 folder name
      Body: b64toBlob(croppedImage, 'image/jpeg'),
      ACL: 'public-read',
      ContentType: 'image/jpeg',
    }
    bucket.upload(param, (err: Error, data: S3.ManagedUpload.SendData) => {
      const imageUrl = data.Location
      if (cropModalType === 'facilityRecommend') {
        setFacilityRecommendImageId(imageUrl)
      } else if (cropModalType === 'food') {
        setFoodImageId(imageUrl)
      } else if (cropModalType === 'play') {
        setPlayImageId(imageUrl)
      } else if (cropModalType === 'buy') {
        setBuyImageId(imageUrl)
      }
      setCropModalOpen(false)
    })
  }

  // Modal cancellation processing
  const onCloseCropModal = type => {
    if (type === 'facilityRecommend') {
      setFacilityRecommendImageId(basicInfo.facilityRecommendImageId)
    } else if (type === 'food') {
      setFoodImageId(basicInfo.foodImageId)
    } else if (type === 'play') {
      setPlayImageId(basicInfo.playImageId)
    } else if (type === 'buy') {
      setBuyImageId(basicInfo.buyImageId)
    }
    setCropModalOpen(false)
  }

  // TOP image update processing
  const onSaveData = () => {
    _updateFacilityBasicInfo()
  }

  const _updateFacilityBasicInfo = async () => {
    setIsLoading(true)
    // Pass "updateTopImageAndLinkStatus" when updating TOP image and link in AdminFacility.php
    const updateTopImageAndLinkStatus = 'updateTopImageAndLinkStatus'
    const data = {
      pageId,
      facilityRecommendImageId,
      foodImageId,
      playImageId,
      buyImageId,
      facilityRecommendLinkUrl,
      updateTopImageAndLinkStatus,
    }
    await updateFacilityBasicInfo(data)
      .then(() => {
        setIsSaved(!isSaved)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const onChangeLink = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = e.target
    setfacilityRecommendLinkUrl(value)
  }

  const _cropperImage = (cropModalType: string) => {
    if (cropModalType === 'facilityRecommend') {
      return facilityRecommendImageId
    } else if (cropModalType === 'food') {
      return foodImageId
    } else if (cropModalType === 'play') {
      return playImageId
    } else if (cropModalType === 'buy') {
      return buyImageId
    }
  }

  return (
    <>
      <div css={topImageContainerStyle}>
        <div css={topImageEditHeaderContainerStyle}>
          <div css={detailsHeaderTitleStyle}>{t('Image')}</div>
          <div
            css={imageSizeDescriptionStyle}
            onMouseOver={() => setIsPopUpOpen(!isPopUpOpen)}
            onMouseLeave={() => setIsPopUpOpen(!isPopUpOpen)}
          >
            <img src={require('@/static/images/help.svg')} css={imageSizeDescriptionIconStyle} />
          </div>
          {isPopUpOpen && (
            <div css={imageSizeDescriptionContainerStyle}>
              <p css={imageSizeDescriptionItemStyle}>{t('*The image formats that can be used are jpg and png only')}</p>
              <p css={imageSizeDescriptionItemStyle}>{t('* Image size of Recommended facility')}</p>
              <p css={imageSizeDescriptionItemStyle}>&nbsp;&nbsp;{t('Maximum size {{w}}px x {{h}}px', { w: 2640, h: 640 })}</p>
              <p css={imageSizeDescriptionItemStyle}>&nbsp;&nbsp;{t('Recommended size {{w}}px x {{h}}px', { w: 660, h: 320 })}</p>
              <p css={imageSizeDescriptionItemStyle}>{t('* Image size of eat, play and buy')}</p>
              <p css={imageSizeDescriptionItemStyle}>&nbsp;&nbsp;{t('Maximum size {{w}}px x {{h}}px', { w: 400, h: 400 })}</p>
              <p>&nbsp;&nbsp;{t('Recommended size {{w}}px x {{h}}px', { w: 200, h: 200 })}</p>
            </div>
          )}
        </div>
        {isOpenEditPage === false && (
          <div css={topImageHeaderContainerStyle}>
            <div css={detailsHeaderTitleStyle}>{t('Image')}</div>
            <ul
              css={detailsHeaderEditButtonWrapperStyle}
              onClick={() => {
                setIsOpenEditPage(!isOpenEditPage)
              }}
            >
              <img src={require('@/static/images/edit.svg')} />
              <li>{t('Edit')}</li>
            </ul>
          </div>
        )}
        <div css={mainContainerStyle}>
          <div css={facilityRecommendStyle}>
            <p css={titleStyle}>{t('Facility recommendation')}</p>
            <div css={facilityRecommendWrapperStyle}>
              <label htmlFor="facilityRecommendPicture">
                <img src={require('@/static/images/camera.svg')} />
              </label>
              <label css={facilityRecommendInputStyle} htmlFor="facilityRecommendPicture" />
              <input
                type="file"
                id="facilityRecommendPicture"
                css={facilityRecommendInputNoneStyle}
                onChange={e => handleChangeFile(e, 'facilityRecommend')}
                onClick={(e: any) => {
                  e.target.value = ''
                }}
              />
              {basicInfo && (
                <div
                  css={facilityRecommendImageStyle}
                  style={{
                    backgroundImage:
                      basicInfo && facilityRecommendImageId !== null
                        ? `url(${facilityRecommendImageId})`
                        : `url(${require('@/static/images/around-spot/facility_recommend_image.jpg')})`,
                  }}
                />
              )}
            </div>
          </div>
          <div css={foodStyle}>
            <p css={titleStyle}>{t('Eat')}</p>
            <div css={foodWrapperStyle}>
              <label htmlFor="foodPicture">
                <img src={require('@/static/images/camera.svg')} />
              </label>
              <label css={foodInputStyle} htmlFor="foodPicture" />
              <input
                type="file"
                id="foodPicture"
                css={foodInputNoneStyle}
                onChange={e => handleChangeFile(e, 'food')}
                onClick={(e: any) => {
                  e.target.value = ''
                }}
              />
              {basicInfo && (
                <div
                  css={foodImageStyle}
                  style={{
                    backgroundImage:
                      basicInfo && foodImageId !== null
                        ? `url(${foodImageId})`
                        : `url(${require('@/static/images/around-spot/food_image.jpg')})`,
                  }}
                />
              )}
            </div>
          </div>
          <div css={playStyle}>
            <p css={titleStyle}>{t('Play')}</p>
            <div css={playWrapperStyle}>
              <label htmlFor="playPicture">
                <img src={require('@/static/images/camera.svg')} />
              </label>
              <label css={playInputStyle} htmlFor="playPicture" />
              <input
                type="file"
                id="playPicture"
                css={playInputNoneStyle}
                onChange={e => handleChangeFile(e, 'play')}
                onClick={(e: any) => {
                  e.target.value = ''
                }}
              />
              {basicInfo && (
                <div
                  css={playImageStyle}
                  style={{
                    backgroundImage:
                      basicInfo && playImageId !== null
                        ? `url(${playImageId})`
                        : `url(${require('@/static/images/around-spot/play_image.jpg')})`,
                  }}
                />
              )}
            </div>
          </div>
          <div css={buyStyle}>
            <p css={titleStyle}>{t('Buy')}</p>
            <div css={buyWrapperStyle}>
              <label htmlFor="buyPicture">
                <img src={require('@/static/images/camera.svg')} />
              </label>
              <label css={buyInputStyle} htmlFor="buyPicture" />
              <input
                type="file"
                id="buyPicture"
                css={buyInputNoneStyle}
                onChange={e => handleChangeFile(e, 'buy')}
                onClick={(e: any) => {
                  e.target.value = ''
                }}
              />
              {basicInfo && (
                <div
                  css={buyImageStyle}
                  style={{
                    backgroundImage:
                      basicInfo && buyImageId !== null
                        ? `url(${buyImageId})`
                        : `url(${require('@/static/images/around-spot/buy_image.jpg')})`,
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div css={linkStyle}>
          <p css={linkStringStyle}>{t('Link destination')}</p>
          <input
            type="text"
            css={linkInputStyle}
            value={facilityRecommendLinkUrl}
            name="link"
            placeholder=""
            onChange={e => onChangeLink(e)}
          />
        </div>
      </div>
      {cropModalOpen && (
        <div css={cropModalContainerStyle}>
          <div css={cropModalBackgroundStyle} />
          <div css={cropModalMainContainerStyle}>
            <div
              className="crop-container"
              css={cropModalType === 'facilityRecommend' ? cropperFacilityRecommendInsideStyle : cropperAnotherInsideStyle}
            >
              {cropModalType === 'facilityRecommend' && <div css={cropModalHeaderStyle}>{t('Set recommended images for facilities')}</div>}
              {cropModalType === 'food' && <div css={cropModalHeaderStyle}>{t('Set image to eat')}</div>}
              {cropModalType === 'play' && <div css={cropModalHeaderStyle}>{t('Set play image')}</div>}
              {cropModalType === 'buy' && <div css={cropModalHeaderStyle}>{t('Set image to buy')}</div>}
              <div css={cropWrapperStyle}>
                <div css={cropTopSpaceStyle} />
                <div css={cropperStyle}>
                  <Cropper
                    image={_cropperImage(cropModalType)}
                    crop={crop}
                    zoom={zoom}
                    aspect={cropModalType === 'facilityRecommend' ? 2 / 1 : 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
                <div css={cropBottomSpaceStyle} />
              </div>
              <div css={cropModalFooterStyle}>
                <div css={cropModalSliderContainerStyle}>
                  <img src={require('@/static/images/picture.svg')} css={sliderIconSmStyle} />
                  <Slider value={zoom} min={1} max={3} step={0.1} aria-labelledby="Zoom" onChange={(e, zoom: any) => setZoom(zoom)} />
                  <img src={require('@/static/images/picture.svg')} css={sliderIconLgStyle} />
                </div>
                <div css={cropModalButtonContainerStyle}>
                  <Button buttonType={3} width={110} height={38} marginLeft={16} onClick={() => onCloseCropModal(cropModalType)}>
                    {t('Cancel')}
                  </Button>
                  <Button buttonType={1} width={110} height={38} marginLeft={16} onClick={() => showCroppedImage(cropModalType)}>
                    {t('Apply')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div css={settingFooterContainerStyle}>
        <Button width={110} height={38} marginRight={16} buttonType={3} onClick={() => setIsOpenEditPage(!isOpenEditPage)}>
          {t('Cancel')}
        </Button>
        <Button width={110} height={38} buttonType={1} onClick={() => onSaveData()}>
          {t('Save')}
        </Button>
      </div>
      <LoadingFull isLoading={isLoading} />
    </>
  )
}

// mainStyle
const topImageContainerStyle = css(listContainerStyle, {
  background: '#FFF',
  minHeight: 'calc(100% - 105px)',
  marginBottom: 16,
  margin: 24,
})

const topImageHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const topImageEditHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  alignItems: 'center',
})

const detailsHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
})

const detailsHeaderEditButtonWrapperStyle = css({
  display: 'flex',
  border: '1px solid #CCCCCC',
  margin: '11px 0',
  padding: '0 12px',
  cursor: 'pointer',
  borderRadius: 13,
  img: {
    width: 20,
  },
  li: {
    padding: '6px 0',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#FF5500',
  },
  ':hover': {
    backgroundColor: '#F2F2F2',
  },
})

const mainContainerStyle = css({
  display: 'flex',
  paddingTop: 32,
  paddingLeft: 32,
  paddingRight: 32,
  paddingBottom: 0,
  justifyContent: 'space-between',
  color: '#272727',
  width: '100%',
})

// anotherStyle
const facilityRecommendStyle = css({
  width: '40%',
  height: 212,
  marginRight: 19,
})

const foodStyle = css({
  width: 180,
  marginRight: 19,
})

const playStyle = css({
  width: 180,
  marginRight: 19,
})

const buyStyle = css({
  width: 180,
})

const titleStyle = css({
  fontSize: 16,
  marginBottom: 16,
  fontWeight: 'bold',
})

const linkStyle = css({
  size: 14,
  padding: '24px 32px',
  color: '#272727',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
})

const linkStringStyle = css({
  paddingRight: 24.5,
  fontWeight: 'bold',
  fontSize: 12,
})

// imageStyle
const facilityRecommendImageStyle = css({
  height: 180,
  borderRadius: 5,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
})

const foodImageStyle = css(facilityRecommendImageStyle, {
  width: 180,
})

const playImageStyle = css(facilityRecommendImageStyle, {
  width: 180,
})

const buyImageStyle = css(facilityRecommendImageStyle, {
  width: 180,
})

// wrapperStyle
const facilityRecommendWrapperStyle = css({
  position: 'relative',
  height: 180,
  width: '100%',
  img: {
    position: 'absolute',
    top: '44%',
    left: '47%',
    zIndex: 1,
  },
})

const foodWrapperStyle = css({
  position: 'relative',
  height: 180,
  width: 180,
  img: {
    position: 'absolute',
    top: '44%',
    left: '47%',
    zIndex: 1,
  },
})

const playWrapperStyle = css(foodWrapperStyle, {})

const buyWrapperStyle = css(foodWrapperStyle, {
  marginRight: 0,
})

// inputStyle
const facilityRecommendInputStyle = css({
  cursor: 'pointer',
  width: '100%',
  height: 180,
  position: 'absolute',
  background: '#000000',
  opacity: 0.3,
  borderRadius: 5,
  backgroundImage: 'url(@/static/images/camera.svg)',
})

const foodInputStyle = css(facilityRecommendInputStyle, {
  width: 180,
})

const playInputStyle = css(facilityRecommendInputStyle, {
  width: 180,
})

const buyInputStyle = css(facilityRecommendInputStyle, {
  width: 180,
})

const linkInputStyle = css({
  padding: 0,
  paddingLeft: 16,
  color: '#272727',
  fontSize: 14,
  lineHeight: '30px',
  border: '1px solid #ccc',
  borderRadius: 16,
  width: 464,
  '&::placeholder': {
    color: '#ccc',
  },
})

// inputNoneStyle
const facilityRecommendInputNoneStyle = css({
  position: 'absolute',
  display: 'none',
})

const foodInputNoneStyle = css({
  position: 'absolute',
  display: 'none',
})

const playInputNoneStyle = css(foodInputNoneStyle, {})

const buyInputNoneStyle = css(foodInputNoneStyle, {})

// cropModal
const cropModalBackgroundStyle = css({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  background: 'black',
  opacity: 0.5,
  zIndex: 200,
})

const cropModalContainerStyle = css({
  zIndex: 102,
})

const cropModalMainContainerStyle = css({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: `translate(-50%, -50%)`,
  height: 450,
  width: 600,
  background: 'white',
  borderRadius: '5px',
  zIndex: 201,
})

const cropperFacilityRecommendInsideStyle = css({
  height: '100%',
  position: 'relative',
  '.reactEasyCrop': {
    '&_CropArea': {
      boxShadow: '0 0 0 9999em rgba(0,0,0,0.1)',
      border: '2px solid #F2A40B',
      ':before': {
        border: 'none',
      },
      ':after': {
        border: 'none',
      },
    },
  },
})

const cropperAnotherInsideStyle = css(cropperFacilityRecommendInsideStyle, {
  '.reactEasyCrop': {
    '&_CropArea': {
      width: '100%',
      height: '100%',
    },
  },
})

const cropModalHeaderStyle = css({
  zIndex: 1,
  background: '#FFF',
  width: '100%',
  height: 50,
  padding: '16px 0px 16px 32px',
  fontSize: '18px',
  color: '#272727',
  fontWeight: 'bold',
  borderRadius: '5px 5px 0 0',
  boxShadow: '0px 1px 6px rgba(0,0,0,0.10)',
})

const cropModalFooterStyle = css({
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  padding: '14px 32px',
  width: '100%',
  justifyContent: 'space-between',
  background: '#FFF',
  borderRadius: '0 0 5px 5px',
})

const cropModalSliderContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: 210,
  '.MuiSlider': {
    '&-rail': {
      height: 1,
      color: '#CCC',
    },
    '&-root': {
      color: 'rgb(242, 164, 11)',
    },
    '&-thumb': {
      color: '#FFF',
      border: '2px solid rgb(242, 164, 11)',
      borderRadius: '50%',
      ':hover': {
        boxShadow: '0px 0px 0px 8px rgb(242, 164, 11, 0.2)',
      },
    },
  },
})

const cropWrapperStyle = css({
  width: 600,
  height: 340,
  position: 'relative',
  padding: '40px 0',
})

const cropTopSpaceStyle = css({
  width: '100%',
  height: 40,
  backgroundColor: '#e5e5e5',
  top: '0px',
  position: 'absolute',
})

const cropBottomSpaceStyle = css({
  width: '100%',
  height: 40,
  backgroundColor: '#e5e5e5',
  bottom: '0px',
  position: 'absolute',
})

const cropperStyle = css({
  position: 'absolute',
  top: '40px',
  left: 0,
  right: 0,
  bottom: '40px',
  height: 260,
})

const sliderIconSmStyle = css({
  width: 20,
  paddingRight: 8,
})

const sliderIconLgStyle = css({
  paddingLeft: 8,
})

const cropModalButtonContainerStyle = css({
  display: 'flex',
})

// footer
const settingFooterContainerStyle = css({
  display: 'flex',
  height: 59,
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: '#FFF',
  boxShadow: '0px 1px 6px rgba(0,0,0,0.10)',
  padding: '11px 32px',
})

const imageSizeDescriptionStyle = css({
  marginLeft: 11,
  display: 'flex',
  alignItems: 'center',
})

const imageSizeDescriptionContainerStyle = css({
  cursor: 'pointer',
  position: 'absolute',
  width: 348,
  height: 178,
  padding: 16,
  background: '#FFF',
  borderRadius: 5,
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px',
  fontSize: 14,
  color: '#272727',
  top: 92,
  left: 544,
  zIndex: 1,
  fontsize: 12,
  letterSpacing: 0.6,
})

const imageSizeDescriptionItemStyle = css({
  paddingBottom: 9,
})

const imageSizeDescriptionIconStyle = css({
  height: 17,
  width: 17,
})
