import React from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { sectionHeaderStyle, sectionStyle } from '@/components/pages/dashboard/_index/style'
import dayjs from 'dayjs'

// constants
import { listContentsStyle, listItemUnreadStyle, listItemReadStyle } from '@/constants/layout'

//models
import { DashboardOrderType, ProductsType } from '@/models/mobile-request/order'
import { ApprovedStatusType } from '@/models/reservation-approved-status'

type Props = {
  orders: DashboardOrderType[] | undefined
}

export const Request: React.FC<Props> = ({ orders }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const _orderContents = (products: any): string => {
    let text = ''
    products.map((product: ProductsType) => {
      text += `${product.nameJa} x ${product.numberOfItems}、`
    })
    return text.slice(0, -1)
  }

  const _createdDate = (createdAt: Date): string => {
    // Compare createdAt in Japan time with today's date
    const today = dayjs().format('MMDD')
    const createdDate = dayjs(createdAt).format('MMDD')

    // Returns time if today, date if not today
    if (today === createdDate) {
      return dayjs(createdAt).format('HH:mm')
    } else {
      return dayjs(createdAt).format(t('MM-DD'))
    }
  }

  const goToDetail = (checkinId: string | undefined, approvedStatus: ApprovedStatusType | undefined): void => {
    if (!checkinId || !approvedStatus) {
      return
    }
    history.push({
      pathname: `${_approvedStatus(approvedStatus)}/${checkinId}`,
      search: `?detailTabType=request`,
    })
  }

  const _approvedStatus = status => {
    switch (status) {
      case ApprovedStatusType.Checkin:
        return 'checkin'
      case ApprovedStatusType.Stay:
        return 'stay'
      case ApprovedStatusType.Checkout:
        return 'checkout'
    }
  }

  const goToList = (): void => {
    history.push({
      pathname: '/request-manager',
      search: `?detailTabType=request`,
    })
  }

  return (
    <div css={mixedSectionStyle}>
      <div css={[sectionHeaderStyle, requestTitleStyle]}>
        <div>{t('Request')}</div>
        <div css={listLinkStyle} onClick={() => goToList()}>
          {t('See the list')}
        </div>
      </div>
      <div css={headerWrapperStyle}>
        <div css={titleStyle}>{t('Content')}</div>
        <div css={titleRoomNumberStyle}>{t('Room number')}</div>
        <div css={titleNameStyle}>{t('Full name')}</div>
        <div css={titleUpdateDayStyle}>{t('Date')}</div>
      </div>
      <div css={mixedListContentsStyle}>
        {orders &&
          orders.map((order: DashboardOrderType, index) => {
            return (
              <div
                key={index}
                css={[order.requestRead ? memoListItemReadStyle : memoListItemUnreadStyle, !order.checkinId && css({cursor: 'auto'})]}
                onClick={() => {
                  goToDetail(order.checkinId, order.approvedStatus)
                }}
              >
                <div css={dataMemoStyle}>
                  <div css={memoStyle}>{_orderContents(order.products)}</div>
                </div>
                <div css={dataRoomNumberStyle}>
                  <div css={roomNumberStyle}>{order.roomNumber ? order.roomNumber : '-'}</div>
                </div>
                <div css={dataNameStyle}>
                  <div css={roomNumberStyle}>{order.guestName ? order.guestName : '-'}</div>
                </div>
                <div css={dataCheckoutPlanStyle}>{_createdDate(order.createdAt)}</div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

const requestTitleStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: 36,
})

const listLinkStyle = css({
  fontSize: 12,
  color: '#DD9914',
  ':hover': {
    cursor: 'pointer',
    opacity: 0.8,
  },
})

const mixedSectionStyle = css(sectionStyle, {
  marginTop: 16,
  overflow: 'hidden',
})

const headerWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: 28,
  background: '#FAFAFA',
  borderTop: '1px solid #F2F2F2',
  borderBottom: '1px solid #F2F2F2',
  fontSize: 10,
  color: '#676767',
  fontWeight: 'bold',
  padding: '0 40px 0 32px',
})

const titleStyle = css({
  width: 'calc(64.9%)',
})

const titleRoomNumberStyle = css({
  width: 'calc(12.4%)',
})

const titleNameStyle = css({
  width: 'calc(14.4%)',
})

const titleUpdateDayStyle = css({
  width: 'calc(8.3%)',
})

const mixedListContentsStyle = css(listContentsStyle, {
  height: 176,
  overflowX: 'hidden',
  overflowY: 'auto',
})

// list data
const dataMemoStyle = css(titleStyle, {
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  paddingRight: 16,
})

const dataRoomNumberStyle = css(titleRoomNumberStyle, {
  display: 'flex',
  alignItems: 'center',
})

const dataNameStyle = css(titleNameStyle, {
  display: 'flex',
  alignItems: 'center',
})

const dataCheckoutPlanStyle = css(titleUpdateDayStyle, {
  display: 'flex',
  alignItems: 'center',
})

// list detail
const memoStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  lineHeight: 1.5,
})

const roomNumberStyle = css({
  padding: '13px 0',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const memoListItemUnreadStyle = css(listItemUnreadStyle, {
  fontSize: 12,
  height: 44,
  padding: '0 32px',
})

const memoListItemReadStyle = css(listItemReadStyle, {
  fontSize: 12,
  height: 44,
  padding: '0 32px',
})
