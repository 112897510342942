import React from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

// models
import { ChannelCodeType } from '@/models/reservation-channel-code'

type Props = {
  channelCodes: ChannelCodeType[]
}

export const SettingChannelCodeList: React.FC<Props> = ({ channelCodes }) => {
  const windows = useWindowSize()
  const { t } = useTranslation()

  return (
    <>
      <div css={paymentListHeaderStyle}>
        <div css={channelCodeWidthStyle}>{t('Channel code')}</div>
        <div css={nameWidthStyle}>{t('Name')}</div>
      </div>
      <div
        css={scrollStyle}
        style={{
          height: windows.height - 270,
        }}
      >
        <ul
          css={paymentListStyle}
          style={{
            height: windows.height - 270,
          }}
        >
          {channelCodes &&
            channelCodes.map((channelCode: ChannelCodeType, index) => (
              <li key={index}>
                <div css={channelCodeWidthStyle}>{channelCode.code ?? '-'}</div>
                <div css={nameWidthStyle}>{channelCode.name}</div>
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}

const paymentListHeaderStyle = css({
  background: '#F5F5F5',
  color: '#676767',
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  padding: '10px 43px 10px 28px',
})

const paymentListStyle = css({
  li: {
    display: 'flex',
    padding: '16px 32px',
    color: '#272727',
    fontSize: 14,
    borderBottom: '1px #F2F2F2 solid',
  },
})

export const channelCodeWidthStyle = css({
  width: '15%',
  paddingLeft: 2,
})

const nameWidthStyle = css({
  flex: 1,
  paddingLeft: 2,
})

const scrollStyle = css({
  overflowY: 'scroll',
})
