import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useHistory, useLocation } from 'react-router-dom'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { ReceiptHistoryList } from '@/components/pages/sales-manager/receipt-history/receipt-history-list'
import { DetailedReserchType } from '@/components/pages/sales-manager/receipt-history/detailed-reserch-panel'

// constants
import { wholeContainerStyle, mainColumnStyle } from '@/constants/layout'
import { SideMenu } from '@/components/organisms/side-menu'
import { Header } from '@/components/organisms/header'
import { AccountContext } from '@/contexts/account'

// apis
import { fetchReceiptHistory } from '@/apis/aipass'
import { capitalize } from '@/libs/text'

type Props = {
  receiptHistory: any
  isLoading: boolean
  detailedReserch: any
  goBack: () => void
  onClickReserch: () => void
  setDetailedReserch: (e: any) => void
}

const detailedReserchList = {
  fileName: '',
  searchBeginDate: undefined,
  searchEndDate: undefined,
}

export const ReceiptHistory: React.FC<Props> = () => {
  const history = useHistory()
  const { search } = useLocation()
  const { account } = useContext<any>(AccountContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [receiptHistory, setReceiptHistory] = useState<any>([])
  const [detailedReserch, setDetailedReserch] = useState<DetailedReserchType>(detailedReserchList)
  const params = new URLSearchParams(search)
  const customerId = params.get('customerId')
  const checkinId = params.get('checkinId')
  const reservationId = params.get('reservationId')

  const _fetchReceiptHistory = async () => {
    setIsLoading(true)

    await fetchReceiptHistory({ customerId, reservationId, checkinId, ...detailedReserch }).then(res => {
      setReceiptHistory(res)
      setIsLoading(false)
    })
  }

  const goBack = () => history.goBack()

  useEffect(() => {
    if (account && account?.hotel) _fetchReceiptHistory()
  }, [account])

  return (
    <UI
      receiptHistory={receiptHistory}
      isLoading={isLoading}
      goBack={goBack}
      detailedReserch={detailedReserch}
      setDetailedReserch={setDetailedReserch}
      onClickReserch={_fetchReceiptHistory}
    />
  )
}

export const UI: React.FC<Props> = ({ receiptHistory, isLoading, detailedReserch, goBack, setDetailedReserch, onClickReserch }) => {
  const windows = useWindowSize()
  const { t } = useTranslation()

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div css={mainColumnStyle}>
        <Header title={capitalize(t('issue history'))} isBack goBack={goBack} />
        <div
          css={settingMainContainerStyle}
          style={{
            minHeight: windows.height - 180,
          }}>
          <ReceiptHistoryList
            receiptHistory={receiptHistory}
            detailedReserch={detailedReserch}
            setDetailedReserch={setDetailedReserch}
            onClickReserch={onClickReserch}
          />
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const settingMainContainerStyle = css({
  padding: 24,
})
