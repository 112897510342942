import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { AccountContext } from '@/contexts/account'
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle } from '@/constants/layout'
import { LoadingFull } from '@/components/molecules/loading-full'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { SettingSideMenu } from '@/components/organisms/setting-side-menu'
import { SettingSideMenuWithGuestApp } from '../organisms/setting-side-menu-with-guest-app'
import { PluginCode, checkActivePlugin } from '@/libs/plugins'

type ContainerProps = {
  loading: boolean
  children: JSX.Element
  footerContent?: JSX.Element
}

const footerHeight = 59

export const SettingsLayout: React.FC<ContainerProps> = ({ loading, children, footerContent }) => {
  const { t } = useTranslation()
  const { plugins } = useContext<any>(AccountContext)
  const guestAppPluginActive = checkActivePlugin(plugins, PluginCode.GuestApp)

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header title={t('Setting')} isPublicPageLink />
        <div className="mainContainer" css={settingContainerStyle}>
          {guestAppPluginActive ? <SettingSideMenuWithGuestApp /> : <SettingSideMenu />}
          <div css={settingPageContainerStyle}>
            <div css={settingMainContainerStyle}>{children}</div>
            {footerContent && <div css={settingFooterContainerStyle}>{footerContent}</div>}
          </div>
        </div>
      </div>
      <LoadingFull isLoading={loading} />
    </div>
  )
}

const settingContainerStyle = css(mainContainerStyle, {
  display: 'flex',
  padding: 0,
  minWidth: '1000px',
})

const settingPageContainerStyle = css({
  width: 'calc(100% - 180px)',
  display: 'flex',
  flexDirection: 'column',
})

const settingMainContainerStyle = css({
  padding: 24,
  display: 'flex',
  flexDirection: 'column',
  height:'100%',
})

const settingFooterContainerStyle = css({
  display: 'flex',
  height: footerHeight,
  position: 'sticky',
  zIndex: 100,
  bottom: 0,
  width: '100%',
  backgroundColor: '#FFF',
  boxShadow: '0px 1px 6px #0000001A',
})
