import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { Room } from './room'
import { Collapse } from 'react-collapse'
import { useTranslation } from 'react-i18next'
import '@/components/organisms/cleaning-manager/list-cleaning-room/custom-collapse.css'
import { RoomType, CleaningStatusType } from '@/constants/cleaning-manager'

type ContainerProps = {
  floorNumber: string
  roomList: Array<RoomType>
  collapsedTracking: object
  setCollapsedTracking: (arg0: any) => void
  setIsOpenRoomDetailModal: (arg0: boolean) => void
  setSelectedRoomId: (arg0: string) => void
}

export const Floor: React.FC<ContainerProps> = ({
  floorNumber,
  roomList,
  collapsedTracking,
  setCollapsedTracking,
  setIsOpenRoomDetailModal,
  setSelectedRoomId,
}) => {
  const { t, i18n } = useTranslation()
  const [isOpened, setIsOpened] = useState<boolean>(collapsedTracking[floorNumber] === undefined ? false : !collapsedTracking[floorNumber])

  const toggleOpenCollapse = () => {
    setIsOpened(!isOpened)
    setCollapsedTracking({ ...collapsedTracking, [`${floorNumber}`]: isOpened })
  }

  useEffect(() => {
    setIsOpened(collapsedTracking[floorNumber] === undefined ? false : !collapsedTracking[floorNumber])
  }, [collapsedTracking])

  const doNotDisturbStatus = roomList.filter(room => room.cleaningStatus === CleaningStatusType.DoNotDisturb).length
  const dirtyStatus = roomList.filter(room => room.cleaningStatus === CleaningStatusType.NotCleaning).length
  const cleanedStatus = roomList.filter(room => room.cleaningStatus === CleaningStatusType.Cleaned).length
  const inspectedStatus = roomList.filter(room => room.cleaningStatus === CleaningStatusType.Inspected).length

  return (
    <div css={isOpened ? floorWrapperStyle : closeFloorWrapperStyle}>
      <div css={floorStyle} onClick={() => toggleOpenCollapse()}>
        <span css={floorTitleStyle}>{i18n.language === 'ja' ? roomList[0].floorNameJa : roomList[0].floorNameEn}</span>
        <div css={statusStyle}>
          <div css={cleanedStatusStyle}>
            <span css={titleStatusStyle}>{t('cleaningManager.Dirty')}</span>
            <span>{dirtyStatus}</span>
          </div>
          <div css={cleanedStatusStyle}>
            <span css={titleStatusStyle}>{t('cleaningManager.Clean')}</span>
            <span>{cleanedStatus}</span>
          </div>
          <div css={cleanedStatusStyle}>
            <span css={titleStatusStyle}>{t('cleaningManager.Inspected')}</span>
            <span>{inspectedStatus}</span>
          </div>
          <div css={cleanedStatusStyle}>
            <span css={titleStatusStyle}>{t('cleaningManager.Do not Disturb')}</span>
            <span>{doNotDisturbStatus}</span>
          </div>
        </div>
      </div>
      <Collapse isOpened={isOpened} theme={{ content: 'ReactCollapse--content_custom' }}>
        <div css={roomWrapperStyle}>
          {roomList.map((room, index) => (
            <Room setIsOpenRoomDetailModal={setIsOpenRoomDetailModal} key={index} room={room} setSelectedRoomId={setSelectedRoomId} />
          ))}
        </div>
      </Collapse>
    </div>
  )
}

const floorWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
})

const closeFloorWrapperStyle = css(floorWrapperStyle, {
  ':last-child': {
    '> div': {
      borderRadius: '0 0 5px 5px',
    },
  },
})

const floorStyle = css({
  width: '100%',
  height: '49px',
  border: '1px solid #F2F2F2',
  background: '#FAFAFA 0% 0% no-repeat padding-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 24px',
  '&:hover': {
    cursor: 'pointer',
  },
})

const floorTitleStyle = css({
  fontSize: 12,
  lineHeight: '18px',
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  textAlign: 'center',
  color: '#676767',
})

const statusStyle = css({
  display: 'flex',
})

const titleStatusStyle = css({
  paddingRight: '32px',
  letterSpacing: '0.6px',
})

const cleanedStatusStyle = css({
  span: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 'bold',
    letterSpacing: '0.6px',
  },
  paddingLeft: '16px',
  ':after': {
    height: '12px',
    borderRight: '1px solid #ccc',
    paddingRight: '16px',
    content: '""',
  },
  color: '#676767',
  ':last-child::after': {
    paddingRight: '0px',
    borderRight: 'none',
  },
})

const roomWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  alignItems: 'center',
  overflow: 'hidden',
  padding: '8px',
})
