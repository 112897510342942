import { css } from '@emotion/core'

/* Basic style of bar */
export const barStyle = css({
  width: '95%',
  minWidth: '13.3%',
  height: 28,
  display: 'flex',
  borderRadius: 5,
  fontSize: 12,
  fontWeight: 'bold',
  alignItems: 'center',
  paddingBlock: 5,
  paddingLeft: 5,
  border: '1px solid #CCCCCC',
  position: 'absolute',
  left: '53%',
  marginTop: 6,
  zIndex: 1,
  '&:hover': {
    cursor: 'pointer',
  },
})

// Selected bar
export const barActiveStyle = css(barStyle, {
  border: 'none',
  color: '#FFFFFF',
})

/* start position */
export const startBarStyle = css(barStyle, {
  width: '100%',
  borderRight: 'none',
  borderRadius: '5px 0 0 5px',
  zIndex: 2,
  ':hover': {
    cursor: 'pointer',
  },
})

export const startBarActiveStyle = css(barActiveStyle, startBarStyle, { border: 'none' })

/* intermediate */
export const betweenBarStyle = css({
  width: '102%',
  minWidth: '13.3%',
  height: 28,
  borderTop: '1px solid #CCCCCC',
  borderBottom: '1px solid #CCCCCC',
  position: 'absolute',
  left: '50%',
  marginTop: 6,
  zIndex: 1,
  ':hover': {
    cursor: 'pointer',
  },
})

export const betweenBarActiveStyle = css(barActiveStyle, betweenBarStyle, { border: 'none', borderRadius: '0px' })

/* For leftmost and rightmost */
export const beginBarStyle = css(betweenBarStyle, {
  left: 0,
  display: 'flex',
  alignItems: 'center',
  zIndex: 2,
  fontSize: 12,
  fontWeight: 'bold',
  paddingLeft: 64.5,
})

export const beginBarActiveStyle = css(betweenBarStyle, beginBarStyle, { border: 'none' })

/* end position */
export const endBarStyle = css(barStyle, {
  left: '-50%',
  borderLeft: 'none',
  borderRadius: '0 5px 5px 0',
  justifyContent: 'flex-end',
  ':hover': {
    cursor: 'pointer',
  },
})

export const endBarActiveStyle = css(barActiveStyle, endBarStyle, { border: 'none' })

/* day use */
export const dayUseBarStyle = css(barStyle, {
  width: 24,
  height: 24,
  borderRadius: 5,
  left: 43,
  marginTop: 8,
  zIndex: 2,
  ':hover': {
    cursor: 'pointer',
  },
})

export const dayUseActiveBarStyle = css(barActiveStyle, dayUseBarStyle, {
  border: 'none',
  ':hover': {
    cursor: 'default',
  },
})

/* Now changing */
export const barChangeColorStyle = css({
  background: '#CCCCCC',
  color: '#FFFFFF',
  border: 'none',
  // boxShadow: '0px 3px 6px 0px #00000029',
})
export const barChangeStyle = css(barStyle, barChangeColorStyle)
export const startBarChangeStyle = css(startBarStyle, barChangeColorStyle)
export const betweenBarChangeStyle = css(betweenBarStyle, barChangeColorStyle)
export const beginBarChangeStyle = css(beginBarStyle, barChangeColorStyle)
export const endBarChangeStyle = css(endBarStyle, barChangeColorStyle)
export const dayUseBarChangeStyle = css(dayUseBarStyle, barChangeColorStyle)

export const nameStyle = css({
  margin: '0 5px 0 0',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  '&:hover': {
    opacity: 0.8,
    cursor: 'pointer',
  },
})
