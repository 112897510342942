import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import { Button } from '@/components/atoms/button'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerFooterBarStyle } from '@/constants/layout'

// contexts
import { AccountContext } from '@/contexts/account'

// components
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { DetailHeader } from '@/components/pages/sales-manager/sales/_detail/header'
import { Sales } from '@/components/pages/sales-manager/sales/_detail/sales'
import { Payment } from '@/components/pages/sales-manager/sales/_detail/payment'
import { Report } from '@/components/pages/sales-manager/sales/_detail/report'
import { Cash } from '@/components/pages/sales-manager/sales/_detail/cash'
import { ConfirmModal } from '@/components/pages/sales-manager/sales/_detail/confirm-modal'
import { LoadingFull } from '@/components/molecules/loading-full'
import { Transaction } from '@/components/organisms/sales-manager/transaction'

// apis
import {
  fetchSalesTotalDetail,
  fetchSalesDetailCash,
  updateSalesDetailCash,
  confirmSalesDetail,
  fetchSalesReport,
  updateSalesReport,
  fetchSalesRevise,
  fetchComExportRevCsv,
  fetchComExportCLCsv,
  exportBookingData,
  exportAccommodationData,
  fetchSalesDetailCsv,
  fetchSalePaymentListCsv,
} from '@/apis/aipass'

// libs
import {
  PluginCode,
  checkActivePlugin,
  setHasAccountingInterlockingPlugin,
  setHasNotPaymentSalePlugin,
  setHasReservationAnalysisPlugin,
} from '@/libs/plugins'

// models
import { ReportType } from '@/models/sales-manager/detail'
import { SalesReviseType } from '@/models/sales'
import { csvDownload } from '@/libs/csv-download'
import { useErrorHandler } from '@/hooks/use-error-handler'

export type CashAmountType =
  | 'amount10000'
  | 'amount5000'
  | 'amount2000'
  | 'amount1000'
  | 'amount500'
  | 'amount100'
  | 'amount50'
  | 'amount10'
  | 'amount5'
  | 'amount1'

export const SalesManagerDetail: React.FC = () => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { id, mode, transactionId } = require('query-string').parse(window.location.search)
  const { plugins } = useContext<any>(AccountContext)

  let salesManagerDailyId = ''
  let salesManagerMonthId = ''
  if (mode === 'daily') {
    salesManagerDailyId = id
  }
  if (mode === 'month') {
    salesManagerMonthId = id
  }

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [salesTotalDetail, setSalesTotalDetail] = useState<any>({})
  const [cash, setCash] = useState<{ [key in CashAmountType & ('salesManagerDailyId' | 'salesManagerMonthId')]: string }>({})
  const [reports, setReports] = useState<ReportType[]>([])
  const [inputReport, setInputReport] = useState<string>('')
  const [register, setRegister] = useState<{ [key in string]: string }>({})
  const [isCashAmountChanged, setIsCashAmountChanged] = useState<boolean>(false)
  const [isReportChanged, setIsReportChanged] = useState<boolean>(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false)
  const [, setChangeUrl] = useState<string>(window.location.href)
  const history = useHistory()

  // Sales revision
  const [revises, setRevises] = useState<SalesReviseType[]>([])
  // Original sales data (for comparison when updating)
  const hasAccountingInterlockingPlugin = setHasAccountingInterlockingPlugin(plugins)
  const hasReservationAnalysisPlugin = setHasReservationAnalysisPlugin(plugins)
  const hasNotPaymentSalePlugin = setHasNotPaymentSalePlugin(plugins)
  const isActivePluginSmaregi = checkActivePlugin(plugins, PluginCode.Smaregi)

  const goBack = () => {
    history.push({ pathname: '/sales-manager', search: window.location.search })
  }

  const onChangeCashAmount = (e: React.ChangeEvent<HTMLInputElement>, type: CashAmountType) => {
    setCash({ ...cash, [type]: e.target.value })
    setIsCashAmountChanged(true)
  }

  const checkCompletedInput = cash => {
    const isHalfSizeNumberAmount1 = Boolean(!String(cash['amount1']).match(/^[0-9]+$/))
    const isHalfSizeNumberAmount5 = Boolean(!String(cash['amount5']).match(/^[0-9]+$/))
    const isHalfSizeNumberAmount10 = Boolean(!String(cash['amount10']).match(/^[0-9]+$/))
    const isHalfSizeNumberAmount50 = Boolean(!String(cash['amount50']).match(/^[0-9]+$/))
    const isHalfSizeNumberAmount100 = Boolean(!String(cash['amount100']).match(/^[0-9]+$/))
    const isHalfSizeNumberAmount500 = Boolean(!String(cash['amount500']).match(/^[0-9]+$/))
    const isHalfSizeNumberAmount1000 = Boolean(!String(cash['amount1000']).match(/^[0-9]+$/))
    const isHalfSizeNumberAmount2000 = Boolean(!String(cash['amount2000']).match(/^[0-9]+$/))
    const isHalfSizeNumberAmount5000 = Boolean(!String(cash['amount5000']).match(/^[0-9]+$/))
    const isHalfSizeNumberAmount10000 = Boolean(!String(cash['amount10000']).match(/^[0-9]+$/))

    if (
      isHalfSizeNumberAmount1 ||
      isHalfSizeNumberAmount5 ||
      isHalfSizeNumberAmount10 ||
      isHalfSizeNumberAmount50 ||
      isHalfSizeNumberAmount100 ||
      isHalfSizeNumberAmount500 ||
      isHalfSizeNumberAmount1000 ||
      isHalfSizeNumberAmount2000 ||
      isHalfSizeNumberAmount5000 ||
      isHalfSizeNumberAmount10000
    ) {
      alert(t('Please enter in single-byte number'))
      return true
    } else {
      return false
    }
  }

  const onClickSaveCashAmount = async () => {
    const isCompletedInput = checkCompletedInput(cash)
    if (!isCompletedInput) {
      setIsLoading(true)
      await updateSalesDetailCash(mode, salesManagerDailyId, salesManagerMonthId, cash)
        .then(() => {
          setIsCashAmountChanged(false)
          _fetchSalesDetailCash()
        })
        .catch(() => {
          console.log(t('Communication failed'))
        })
    }
  }

  const onChangeReport = (report: string) => {
    setInputReport(report)
    setIsReportChanged(true)
  }

  const onClickSaveReport = async () => {
    if (inputReport === '') {
      setIsReportChanged(false)
      return
    }
    setIsLoading(true)
    await updateSalesReport(mode, salesManagerDailyId, salesManagerMonthId, inputReport)
      .then(() => {
        _fetchSalesReport()
        setIsReportChanged(false)
        setInputReport('')
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onClickConfirm = async () => {
    if (hasNotPaymentSalePlugin) {
      if (salesTotalDetail.totalUnpaid) {
        alert(t('There are some courses that have not been settled so please mark them as settled before confirming'))
        return
      }
    }
    setConfirmModalVisible(true)
  }

  // The input item date is salesTotalDetail?.salesDate
  // that is the date (sales_manager_daily.sales_date) associated with the displayed ID.
  const onClickModalConfirm = async (date, startDatePeriod) => {
    try {
      setIsLoading(true)
      setConfirmModalVisible(false)

      if (mode === 'month') {
        return alert('This mode is not applicable.')
      }
      const res = await confirmSalesDetail(mode, salesManagerDailyId, salesManagerMonthId)
      const url = new URL(window.location.href)
      url.searchParams.set('id', res?.salesManagerId)
      // Update URL
      window.history.replaceState('', '', url.search)
      setChangeUrl(window.location.href)

      if (hasAccountingInterlockingPlugin) {
        const formatDay = dayjs(date).format('YYYYMMDD')
        const endDatePeriod = dayjs().format('YYYY-MM-DD H:mm:ss')

        fetchComExportRevCsv(date, startDatePeriod, endDatePeriod).then(res => {
          const url = window.URL.createObjectURL(new Blob([res]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${formatDay}_REV.csv`) //or any other extension
          document.body.appendChild(link)
          link.click()
        })

        fetchComExportCLCsv(date, startDatePeriod, endDatePeriod).then(res => {
          const url = window.URL.createObjectURL(new Blob([res]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${formatDay}_CL.csv`) //or any other extension
          document.body.appendChild(link)
          link.click()
        })
      }
      if (hasReservationAnalysisPlugin) {
        const exportDate = dayjs(date).format('YYYYMMDD')
        try {
          await exportBookingData(exportDate)
        } catch (err) {
          alert(t('Failed to send file Please contact the site administrator'))
        }
        try {
          await exportAccommodationData(exportDate)
        } catch (err) {
          alert(t('Failed to send file Please contact the site administrator'))
        }
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onClickModalCancel = () => {
    setConfirmModalVisible(false)
  }

  const _fetchSalesTotalDetail = async () => {
    setIsLoading(true)
    fetchSalesTotalDetail(mode, salesManagerDailyId, salesManagerMonthId).then(res => {
      setSalesTotalDetail(res?.detail)
    })
  }

  const _fetchSalesReport = async () => {
    fetchSalesReport(mode, salesManagerDailyId, salesManagerMonthId).then(res => {
      setReports(res?.reports)
    })
  }

  const _fetchSalesRevise = async () => {
    fetchSalesRevise(salesManagerDailyId).then(res => {
      setRevises(res?.revises)
    })
    _fetchSalesTotalDetail()
  }

  const _fetchSalesDetailCash = async () => {
    fetchSalesDetailCash(mode, salesManagerDailyId, salesManagerMonthId).then(res => {
      if (res?.cash) {
        if (mode === 'daily') {
          const {
            totalCashRegister,
            startCashRegister,
            totalCash,
            salesTotalPaymentCash,
            salesTotalCash,
            salesTotalDiscountCash,
            salesTotalAdvanceCash,
            ...cash
          } = res?.cash
          setRegister({
            totalCashRegister,
            startCashRegister,
            totalCash,
            salesTotalPaymentCash,
            salesTotalCash,
            salesTotalDiscountCash,
            salesTotalAdvanceCash,
          })
          setCash(cash)
        }
        if (mode === 'month') {
          const {
            totalCashRegister,
            startCashRegister,
            totalCash,
            salesTotalPaymentCash,
            salesTotalCash,
            salesTotalDiscountCash,
            salesTotalAdvanceCash,
            ...cash
          } = res?.cash
          setRegister({
            totalCashRegister,
            startCashRegister,
            totalCash,
            salesTotalPaymentCash,
            salesTotalCash,
            salesTotalDiscountCash,
            salesTotalAdvanceCash,
          })
          setCash(cash)
        }
      }
      setIsLoading(false)
    })
  }

  const downloadSaleDetails = async () => {
    if (!salesTotalDetail.salesDate && !salesTotalDetail.salesMonth) {
      return
    }
    try {
      setIsLoading(true)
      const salesBeginDate =
        salesTotalDetail?.salesDate ??
        dayjs(salesTotalDetail?.salesMonth)
          .startOf('month')
          .format('YYYY-MM-DD')
      const salesEndDate =
        salesTotalDetail?.salesDate ??
        dayjs(salesTotalDetail?.salesMonth)
          .endOf('month')
          .format('YYYY-MM-DD')
      const res = await fetchSalesDetailCsv(salesBeginDate, salesEndDate, salesTotalDetail?.salesManagerDailyId)
      const data = res?.salesDetailListCsv
      const fileName = 'sales-detail-list.csv'
      csvDownload(data, fileName)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const downloadPaymentList = async () => {
    if (!salesTotalDetail) {
      return
    }
    try {
      setIsLoading(true)
      const res = await fetchSalePaymentListCsv(
        salesTotalDetail.confirmedLastTime ? dayjs(salesTotalDetail.confirmedLastTime).add(1, 'second').format() : undefined,
        salesTotalDetail.confirmedThisTime || undefined,
        salesTotalDetail.salesManagerMonthId || undefined,
      )
      const data = res?.paymentListCsv
      const fileName = 'payment-list.csv'
      csvDownload(data, fileName)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const isDetailToConfirmation = useMemo(() => {
    if (!salesTotalDetail || salesTotalDetail.isConfirm) {
      return false
    }
    return salesTotalDetail?.confirmedThisTime === null && salesTotalDetail?.confirmedLastTime !== null
  }, [salesTotalDetail])

  useEffect(() => {
    _fetchSalesTotalDetail()
    _fetchSalesDetailCash()
    _fetchSalesReport()
    _fetchSalesRevise()
  }, [id])

  // Update sales details when you update sales adjustments
  useEffect(() => {
    _fetchSalesReport()
  }, [revises])

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header isBack title={t('Sales management')} goBack={goBack} />
        <div css={mainWrapperStyle}>
          <DetailHeader
            salesTotalDetail={salesTotalDetail}
            mode={mode}
            downloadSaleDetails={downloadSaleDetails}
            downloadPaymentList={downloadPaymentList}
          />
          <div css={contentsWrapperStyle}>
            <Sales salesTotalDetail={salesTotalDetail} mode={mode} />
            <Payment salesTotalDetail={salesTotalDetail} />
          </div>
          {mode === 'daily' && (
            <>
              <div css={contentsWrapperStyle}>
                <Report
                  reports={reports}
                  inputReport={inputReport}
                  isConfirm={salesTotalDetail?.isConfirm}
                  onChangeReport={onChangeReport}
                  isReportChanged={isReportChanged}
                  onClickSaveReport={onClickSaveReport}
                  mode={mode}
                />
                <Cash
                  cash={cash}
                  register={register}
                  salesTotalDetail={salesTotalDetail}
                  isCashAmountChanged={isCashAmountChanged}
                  onChangeCashAmount={onChangeCashAmount}
                  onClickSaveCashAmount={onClickSaveCashAmount}
                />
              </div>
              {isActivePluginSmaregi && <Transaction salesDate={salesTotalDetail?.salesDate} transactionId={transactionId} />}
            </>
          )}
        </div>
        {mode === 'daily' && (
          <>
            <div css={mainContainerFooterBarStyle}>
              {isDetailToConfirmation && (
                <Button width={110} height={38} buttonType={1} onClick={onClickConfirm}>
                  {t('Confirm')}
                </Button>
              )}
            </div>
            {confirmModalVisible && (
              <ConfirmModal
                salesTotalDetail={salesTotalDetail}
                mode={mode}
                onClickModalConfirm={onClickModalConfirm}
                onClickModalCancel={onClickModalCancel}
              />
            )}
          </>
        )}
        <LoadingFull isLoading={isLoading} />
      </div>
    </div>
  )
}

const mainWrapperStyle = css({
  width: '100%',
  padding: '24px 24px 0',
  marginBottom: 70,
  overflow: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
})

const contentsWrapperStyle = css({
  width: '100%',
  display: 'flex',
})
