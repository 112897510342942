import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@/components/atoms/modal'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms'
import { css } from '@emotion/core'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { fetchReservation } from '@/apis/aipass'
import { ButtonType } from '@/components/atoms/button'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { RHFInputField } from '@/components/molecules/RHFInput-field'
import { EntireSearchContentType, EntireSearchDateType } from '@/models/entire-search'
import { Checkbox } from '@/components/atoms/checkbox'
import { ReservationType } from '@/models/sales-invoice'

type SearchReservationParams = {
  name?: string
  nameKana?: string
  telephone?: string
}

interface Props {
  onClose: () => void
  onNext: (reservations: ReservationType[]) => void
}
export const ReservationSearchModal: React.FC<Props> = ({ onClose, onNext }) => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [listTotal, setListTotal] = useState(0)
  const [searchResultReservations, setSearchResultReservations] = useState<ReservationType[]>([])
  const [selectedReservations, setSelectedReservations] = useState<ReservationType[]>([])
  const [searchCondition, setSearchCondition] = useState<
    EntireSearchContentType & {
      page: number
      limit: number
    }
  >({
    page,
    limit: 50,
    approvedStatuses: [],
    reservationStatuses: [],
    note: '',
    searchReservationId: '',
    roomNumber: '',
    selectGuestRoomAssign: null,
    beginDate: undefined,
    endDate: undefined,
    name: '',
    nameKana: '',
    globalSearchDateType: EntireSearchDateType.Stay,
    telephone: '',
  })
  const { control, handleSubmit } = useForm<SearchReservationParams>({})

  const doNext = () => {
    if (!selectedReservations.length) {
      return
    }
    onNext(selectedReservations)
    onClose()
  }

  const doChangePage = (page: number) => {
    setSearchCondition({ ...searchCondition, page })
  }

  const doSearchReservation = (formValue: SearchReservationParams) => {
    setPage(1)
    setSelectedReservations([])
    setSearchCondition({
      ...searchCondition,
      page: 1,
      name: formValue.name || '',
      nameKana: formValue.nameKana || '',
      telephone: formValue.telephone || '',
    })
  }

  const doToggleReservationCheck = (clickedReservation: ReservationType) => {
    if (isCheckedReservation(clickedReservation)) {
      setSelectedReservations([...selectedReservations.filter(r => r.reservationId !== clickedReservation.reservationId)])
    } else {
      setSelectedReservations([...selectedReservations, clickedReservation])
    }
  }

  const isCheckedReservation = (reservation: ReservationType): boolean =>
    selectedReservations.findIndex(r => r.reservationId === reservation.reservationId) !== -1

  useEffect(() => {
    if (!searchCondition?.name && !searchCondition?.nameKana && !searchCondition?.telephone) {
      return
    }
    setSearchResultReservations([])
    fetchReservation(searchCondition).then(result => {
      setListTotal(result.total)
      setSearchResultReservations(
        result.reservations.map(r => ({
          ...r,
          reservationUserName: r.name,
          reservationUserNameKana: r.nameKana,
        })),
      )
    })
  }, [searchCondition])

  return (
    <Modal customCss={modalStyle}>
      <ModalHeader>{t('Search your reservation')}</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(doSearchReservation)}>
          <div className="search-block">
            <RHFInputField control={control} marginBottom={0} label={t('Full name')} placeholder={t('John Doe')} name="name" />
            <RHFInputField control={control} marginBottom={0} label={t('Furigana')} placeholder={'ヤマダ タロウ'} name="nameKana" />
            <RHFInputField control={control} marginBottom={0} label={t('Phone number')} placeholder="08012345678" name="telephone" />
            <div>
              <Button type="submit" buttonType={1} width={58} height={32} fontSize={12}>
                {t('Search')}
              </Button>
            </div>
          </div>
        </form>

        <div className="container-label">
          <p style={{ width: 18 }}></p>
          <p className="col-1">{t('Reservation person')}</p>
          <p className="col-2">{t('Confirmed guest')}</p>
          <p className="col-3">{t('Date of stay')}</p>
        </div>
        <div className="reservation-container">
          <ul className="reservation-list">
            {searchResultReservations &&
              searchResultReservations.map(reservation => {
                return (
                  <li
                    key={reservation.reservationId}
                    onClick={() => doToggleReservationCheck(reservation)}
                    className={isCheckedReservation(reservation) ? 'selected' : ''}
                  >
                    <Checkbox
                      onChange={() => doToggleReservationCheck(reservation)}
                      value={isCheckedReservation(reservation)}
                      style={css({ img: { marginRight: 0 } })}
                    />
                    <div className="col-1">
                      <p>
                        <span className="ruby">{reservation.reservationUserNameKana || '-'}</span>
                        <span>{reservation.reservationUserName || '-'}</span>
                      </p>
                    </div>
                    <div className="col-2">
                      <span>{reservation.guestNameKana || '-'}</span>
                      <span>{reservation.guestName || '-'}</span>
                    </div>
                    <div className="col-3">{dayjs(reservation.checkinDate).format(t('YYYY-MM-DD'))}</div>
                  </li>
                )
              })}
          </ul>
        </div>

        <div className="reservation-container-footer">
          <div className="selected-counter">
            {t('Selected')}：
            <span>
              {selectedReservations.length}
              {t('item num')}
            </span>
          </div>
          <AccommodationPaging page={page} setPage={setPage} total={listTotal} onChange={doChangePage} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType={ButtonType.Secondary} height="38px" width="110px" marginRight={16} onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <Button
          buttonType={selectedReservations.length ? ButtonType.Primary : ButtonType.PrimaryDisabled}
          height="38px"
          width="110px"
          onClick={() => doNext()}
        >
          {t('Next')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = css({
  top: '10%',
  height: 'auto',
  minHeight: 0,
  maxHeight: 'calc(100vh - 10%)',

  '.container-label': {
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    background: '#fff',
    display: 'flex',
    padding: '10px 27px 10px 17px',
    fontSize: 12,
    color: '#676767',
    fontWeight: 'bold',
    border: '1px solid #ccc',
    borderBottom: '1px solid #F2F2F2',
    gap: 20,
    overflowY: 'scroll',
    '.col-1': { width: '40%' },
    '.col-2': { width: '40%' },
    '.col-3': { maxWidth: 130, minWidth: 130 },
  },
  '.reservation-container': {
    height: 256,
    border: '1px solid #ccc',
    borderTop: 'none',
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
    background: '#fff',
    overflowY: 'scroll',
    '.col-1': { width: '40%' },
    '.col-2': { width: '40%' },
    '.col-3': { maxWidth: 130, minWidth: 130 },
    '.reservation-list': {
      fontSize: 14,
      li: {
        display: 'flex',
        gap: 20,
        cursor: 'pointer',
        minHeight: 64,
        position: 'relative',
        alignItems: 'center',
        padding: '12px 27px 12px 17px',
        borderBottom: '1px solid #f2f2f2',
        ':hover': {
          boxShadow: '0px 3px 6px #0000000f',
        },
        '&.selected': {
          background: 'rgb(255, 252, 221)',
        },
        '.check-box': {
          width: 18,
          position: 'absolute',
          top: 0,
          bottom: 0,
          margin: 'auto',
          left: 12,
        },
      },
      span: {
        display: 'block',
      },
      '.ruby': {
        fontSize: 12,
      },
    },
  },
  '.reservation-container-footer': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 18,
    '.selected-counter': {
      fontWeight: 'bold',
      fontSize: 16,
      letterSpacing: 1.6,
      color: '#676767',
      span: {
        fontSize: 20,
        letterSpacing: 2,
        color: '#F2A40B',
      },
    },
  },
  '.search-block': {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 8,
    marginBottom: 16,
  },
  '.label-block': {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    padding: '10px 48px',
    color: '#676767',
    fontWeight: 'bold',
    fontSize: 12,
  },
})
