import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonType } from '@/components/atoms/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { bulkUpdateGuestRoomStatusForCleaningManager } from '@/apis/aipass'
import { css } from '@emotion/core'
import { CleaningStatusType } from '@/constants/cleaning-manager'
import { CleaningManagerContext } from './list-cleaning-room/room'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { Controller, useForm } from 'react-hook-form'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'

type Props = {
  onClose: () => void
}
type FormType = {
  from: CleaningStatusType
  to: CleaningStatusType
}
export const BulkChangeStatusModal = ({ onClose }: Props) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { selectedDate, setIsLoading, reloadRoomList } = useContext(CleaningManagerContext)
  const { control, handleSubmit } = useForm<FormType>({
    defaultValues: {
      from: CleaningStatusType.NotCleaning,
      to: CleaningStatusType.Cleaned,
    },
  })

  const save = async (value: FormType) => {
    setIsLoading && setIsLoading(true)
    try {
      await bulkUpdateGuestRoomStatusForCleaningManager({
        ...value,
        dateOf: selectedDate,
      })
      reloadRoomList()
      onClose()
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading && setIsLoading(false)
    }
  }

  return (
    <Modal customCss={modalStyle}>
      <ModalHeader>{t('Bulk change')}</ModalHeader>
      <ModalBody>
        <div css={modalBodyStyle}>
          <div className="input-block">
            <div css={inputTitleTextStyle}>{t('Before change')}</div>
            <Controller
              name="from"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select value={value} onChange={onChange} customStyle={css({ paddingBottom: 0 })}>
                  <AntSelect.Option value={CleaningStatusType.NotCleaning}>{t('cleaningManager.Dirty')}</AntSelect.Option>
                  <AntSelect.Option value={CleaningStatusType.Cleaned}>{t('cleaningManager.Clean')}</AntSelect.Option>
                  <AntSelect.Option value={CleaningStatusType.Inspected}>{t('cleaningManager.Inspected')}</AntSelect.Option>
                  <AntSelect.Option value={CleaningStatusType.DoNotDisturb}>{t('cleaningManager.Do not Disturb')}</AntSelect.Option>
                  <AntSelect.Option value={CleaningStatusType.NotRequired}>{t('cleaningManager.No Cleaning')}</AntSelect.Option>
                  <AntSelect.Option value={CleaningStatusType.Unused}>-</AntSelect.Option>
                </Select>
              )}
            />
          </div>
          <div className="arrow-block">
            <img src={require('@/static/images/arrow_right_gray_nospace.svg')} />
          </div>
          <div className="input-block">
            <div css={inputTitleTextStyle}>{t('After change')}</div>
            <Controller
              name="to"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select value={value} onChange={onChange} customStyle={css({ paddingBottom: 0 })}>
                  <AntSelect.Option value={CleaningStatusType.NotCleaning}>{t('cleaningManager.Dirty')}</AntSelect.Option>
                  <AntSelect.Option value={CleaningStatusType.Cleaned}>{t('cleaningManager.Clean')}</AntSelect.Option>
                  <AntSelect.Option value={CleaningStatusType.Inspected}>{t('cleaningManager.Inspected')}</AntSelect.Option>
                  <AntSelect.Option value={CleaningStatusType.DoNotDisturb}>{t('cleaningManager.Do not Disturb')}</AntSelect.Option>
                  <AntSelect.Option value={CleaningStatusType.NotRequired}>{t('cleaningManager.No Cleaning')}</AntSelect.Option>
                  <AntSelect.Option value={CleaningStatusType.Unused}>-</AntSelect.Option>
                </Select>
              )}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter customCss={modalFooterStyle}>
        <Button buttonType={ButtonType.Secondary} height={36} width={110} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={ButtonType.Primary} height={36} width={110} onClick={handleSubmit(save)}>
          {t('Change')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = css({
  width: 600,
  height: 302,
  top: 'calc(50% - (302px / 2))',
})

const modalBodyStyle = css({
  padding: 32,
  paddingBottom: 42,
  background: '#fff',
  borderRadius: 5,
  display: 'flex',
  alignItems: 'flex-end',
  gap: 26,
  '.input-block': {
    flexGrow: 1,
    width: '50%',
  },
  '.arrow-block': {
    padding: '6px 0px',
    img: { height: 18 },
  },
})

const modalFooterStyle = css({
  height: 60,
  display: 'flex',
  justifyContent: 'space-between',
})

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})
