import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import moment from 'moment'

import { Button } from '@/components/atoms/button'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { SettingsEditCouponModal } from '@/components/organisms/settings/coupons/settings-edit-coupon-modal'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { deleteSettingsCoupon, fetchSettingsCouponsList } from '@/apis/aipass'
import { dashboardPageContainerStyle, tableHeaderThStyle, tableBodyTdStyle, dashboardPageHeaderStyle } from '@/constants/layout'
import { CouponType } from '@/models/coupon'

export const SettingCoupons: React.FC = () => {
  const windows = useWindowSize()
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [loading, setLoading] = useState<boolean>(true)
  const [couponsList, setCouponsList] = useState<CouponType[]>()
  const [editingCoupon, setEditingCoupon] = useState<CouponType>()
  const [showEditModal, setShowEditModal] = useState<boolean>(false)

  const loadCouponsList = async () => {
    try {
      setLoading(true)

      const res = await fetchSettingsCouponsList()

      setCouponsList(res.data || [])
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  const addNewCoupon = () => {
    setShowEditModal(true)
  }

  const editExistCoupon = (coupon: CouponType) => {
    setEditingCoupon(coupon)
    setShowEditModal(true)
  }

  const onCloseEdit = () => {
    setEditingCoupon(undefined)
    setShowEditModal(false)
  }

  const onSaveEdit = () => {
    setEditingCoupon(undefined)
    setShowEditModal(false)
    loadCouponsList()
  }

  const deleteCoupon = async (e: React.MouseEvent<HTMLElement>, coupon: CouponType) => {
    try {
      e.stopPropagation()

      setLoading(true)

      await deleteSettingsCoupon(coupon.id)
      await loadCouponsList()
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadCouponsList()
  }, [])

  return (
    <>
      <SettingsLayout loading={loading}>
        <div css={dashboardPageContainerStyle}>
          <div css={dashboardPageHeaderStyle}>
            <Button
              buttonType={1}
              width={107}
              height={32}
              fontSize={12}
              marginRight={16}
              icon={require('@/static/images/mobile_request/add.svg')}
              onClick={addNewCoupon}
            >
              {t('Add new')}
            </Button>
          </div>
          <div css={{ overflow: 'auto', height: windows.height - 178 }}>
            {!!couponsList?.length && (
              <table css={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th css={[tableHeaderThStyle, { paddingLeft: 28 }]}>{t('ID')}</th>
                    <th css={tableHeaderThStyle}>{t('Coupon')}</th>
                    <th css={tableHeaderThStyle}>{t('Name of facility')}</th>
                    <th css={tableHeaderThStyle}>{t('Expiration date')}</th>
                    <th css={tableHeaderThStyle}>{t('URL')}</th>
                    <th css={[tableHeaderThStyle, { paddingRight: 28 }]}></th>
                  </tr>
                </thead>
                <tbody>
                  {couponsList?.map(coupon => (
                    <tr onClick={() => editExistCoupon(coupon)} key={coupon.id}>
                      <td css={[tableBodyTdStyle, { paddingLeft: 28 }]}>
                        <div css={{ display: 'flex', alignItems: 'center' }}>
                          <div css={{ width: 100, marginRight: 16, fontWeight: 'bold' }}>{coupon.couponCode}</div>
                          <div css={{ width: 100, height: 100, borderRadius: 4, overflow: 'hidden' }}>
                            <img src={coupon.image} css={{ objectFit: 'cover', width: '100%', height: '100%', display: 'block' }} />
                          </div>
                        </div>
                      </td>
                      <td css={[tableBodyTdStyle, { maxWidth: 400 }]}>
                        <div>
                          <div
                            css={{ fontWeight: 'bold', fontSize: 18, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                          >
                            {coupon.title}
                          </div>
                          <div css={{ fontSize: 12, marginTop: 8 }}>{coupon.description}</div>
                        </div>
                      </td>
                      <td css={[tableBodyTdStyle, { whiteSpace: 'nowrap', maxWidth: 180, textOverflow: 'ellipsis', overflow: 'hidden' }]}>
                        {coupon.venueName}
                      </td>
                      <td css={[tableBodyTdStyle, { whiteSpace: 'nowrap', maxWidth: 220, textOverflow: 'ellipsis', overflow: 'hidden' }]}>
                        {`${moment(coupon.validFrom).format('YYYY-MM-DD')} ~ ${moment(coupon.validTo).format('YYYY-MM-DD')}`}
                      </td>
                      <td css={[tableBodyTdStyle, { maxWidth: 120 }]}>
                        <a
                          href={coupon.url}
                          target="_blank"
                          css={{
                            color: 'rgba(0, 0, 0, 0.65)',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: 'block',
                            maxWidth: 120,
                            lineHeight: '1.2em',
                          }}
                        >
                          {(coupon.url || '-')
                            .replace('http://', '')
                            .replace('https://', '')
                            .replace('HTTP://', '')
                            .replace('HTTPS://', '')}
                        </a>
                      </td>
                      <td css={[tableBodyTdStyle, { paddingRight: 28 }]}>
                        <img
                          onClick={e => deleteCoupon(e, coupon)}
                          src={require('@/static/images/delete_orange.svg')}
                          css={{ width: 32, height: 32, cursor: 'pointer' }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {!couponsList?.length && !loading && (
              <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <h1>{t('No data available')}</h1>
              </div>
            )}
          </div>
        </div>
      </SettingsLayout>
      {showEditModal && <SettingsEditCouponModal coupon={editingCoupon} onClose={onCloseEdit} onSave={onSaveEdit} />}
    </>
  )
}
