import { t } from 'i18next'

export type AdvanceMoneyType = {
  id: string
  display_id: string
  created_at_unixtime: number
  updated_at_unixtime: number
  company_name: string
  subject_name: string
  payment_name: string
  lastDate: string
  advance_money: string
  current_balance: string
  has_submitted: boolean
  first_checkin_date: string | null
  final_withdrawal_date_unixtime: number | null
  accounts_receivable_name: string
}

export type HistoryAdvanceMoneyType = {
  checkin_date: string
  payment_date_unixtime: number
  reservation_guest_name: string
  payment_amount: string
  balance: string
}

export type AdvanceMoneyItemType = {
  companyName: string
  salesSubjectMasterId: string
  paymentMethodMasterId: string
  advanceMoney: string
}

export const SelectBalanceType = {
  ALL: 'all',
  WITH_BALANCE: 'withBalance',
  NO_BALANCE: 'noBalance',
} as const
export type SelectBalanceType = (typeof SelectBalanceType)[keyof typeof SelectBalanceType]

export const selectBalanceTypeValue: Record<SelectBalanceType, string> = {
  [SelectBalanceType.ALL]: t('All'),
  [SelectBalanceType.WITH_BALANCE]: t('With balance'),
  [SelectBalanceType.NO_BALANCE]: t(''),
}

export type AdvanceMoneySearchType = {
  beginPaymentDate: string | undefined
  endPaymentDate: string | undefined
  beginWithdrawalDate: string | undefined
  endWithdrawalDate: string | undefined
  balance: SelectBalanceType
  companyName: string
}
