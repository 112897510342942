import React from 'react'
import { css } from '@emotion/core'
import { TotalRemainingCountEachDateType } from '@/models/room-manager/response/site-controller-stock'

type RemainingRoomTotalBodyProps = {
  dates: {
    days: string
    daysForStock: string
  }[]
  totalRemainingCountEachDate: TotalRemainingCountEachDateType
}

export const RemainingRoomTotalBody: React.FC<RemainingRoomTotalBodyProps> = ({ dates, totalRemainingCountEachDate }) => {
  const computedTotalRemainingCount = (date: string): string | number => {
    return totalRemainingCountEachDate[date] || '-'
  }

  return (
    <div css={remainingRoomTotalStyle}>
      {dates && dates.map(date => <div key={`remaining-room-num-${date.days}`}>{computedTotalRemainingCount(date.daysForStock)}</div>)}
    </div>
  )
}

const remainingRoomTotalStyle = css({
  display: 'flex',
  backgroundColor: '#FAFAFA',
  height: 29,
  div: {
    padding: '7.5px 0',
    width: 111,
    textAlign: 'center',
    color: '#A3A3A3',
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: '0.6px',
    borderBottom: '1px solid #E3E3E3',
    borderRight: '1px solid #F2F2F2',
  },
  position: 'sticky',
  top: 90,
  zIndex: 3,
})
