import { useEffect } from 'react'

export const useAutoReload = () => {
  let reloadInterval: NodeJS.Timeout
  let intervalCallback: () => void
  let intervalMs: number

  const setReloadCallback = (cb: () => void, ms: number) => {
    intervalCallback = cb
    intervalMs = ms
    setReloadInterval()
  }

  const setReloadInterval = () => {
    if (!intervalCallback || !intervalMs) {
      return
    }
    if (reloadInterval) {
      clearInterval(reloadInterval)
    }
    reloadInterval = setInterval(intervalCallback, intervalMs)
  }

  useEffect(() => {
    window.addEventListener('mousemove', setReloadInterval)
    window.addEventListener('click', setReloadInterval)
    window.addEventListener('keydown', setReloadInterval)
    return () => {
      window.removeEventListener('mousemove', setReloadInterval)
      window.removeEventListener('click', setReloadInterval)
      window.removeEventListener('keydown', setReloadInterval)
      reloadInterval && clearInterval(reloadInterval)
    }
  }, [])
  return { setReloadCallback }
}
