import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { TreeSelect } from 'antd'
import { SalesSubjectType } from '@/models/sales-manager/sales'
import { useTranslation } from 'react-i18next'
import { fetchSalesSubjectReduceSubSubject } from '@/apis/aipass'
import { uniq } from 'lodash'

type Props = {
  value: string[]
  onChange: (subjectCodes: string[]) => void
  history: Array<string[]>
}

export const SalesSubjectSelectBox: React.FC<Props> = ({ value, history, onChange }) => {
  const { t } = useTranslation()
  const [salesSubjects, setSalesSubjects] = useState<SalesSubjectType[]>([])
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<string[]>([])

  const convertHistoryToTree = (subjectCodes: string[]): { title: string; value: string } | undefined => {
    const titleAndValues = salesSubjects
      .filter(subject => subjectCodes.includes(subject.subjectCode))
      .reduce(
        (prev: { title: string[]; value: string[] }, current) => {
          prev.title.push(current.subjectName)
          prev.value.push(current.subjectCode)
          return prev
        },
        { title: [], value: [] },
      )
    return {
      title: titleAndValues?.title.join(', '),
      value: titleAndValues?.value.join(','),
    }
  }

  useEffect(() => {
    fetchSalesSubjectReduceSubSubject().then(res => setSalesSubjects(res))
  }, [])

  return (
    <span css={reportsStyleSalesBySubjectsItemList}>
      <TreeSelect
        className="subjects-month-select"
        style={{ width: '90%' }}
        dropdownStyle={{ maxHeight: '300px', width: 'min-content' }}
        placeholder={t('Add display items')}
        multiple
        showSearch
        filterTreeNode={(input, treeNode) => treeNode.props?.title?.includes(input)}
        value={salesSubjects.length ? value : undefined}
        treeExpandedKeys={treeExpandedKeys}
        onTreeExpand={keys => setTreeExpandedKeys(keys)}
        onSelect={(val, node) => {
          const groupNodeKeys = ['history', 'subject']
          if (!groupNodeKeys.includes(node.props.value)) {
            return
          }
          const isExpanded = treeExpandedKeys.includes(node.props.value)
          setTreeExpandedKeys(isExpanded ? treeExpandedKeys.filter(k => k !== node.props.value) : [...treeExpandedKeys, node.props.value])
        }}
        onChange={codes => {
          const enableValues = codes.filter(code => code !== 'history' && code !== 'subject').flatMap(code => code.split(','))
          onChange(uniq(enableValues))
        }}
        treeData={[
          {
            title: '出力履歴',
            key: 'history',
            value: 'history',
            children: history.map((codes, i) => ({
              ...convertHistoryToTree(codes),
              key: `history-${i}`,
            })),
          },
          {
            title: '科目',
            key: 'subject',
            value: 'subject',
            children: salesSubjects.map(v => ({
              title: v.subjectName,
              key: v.subjectCode,
              value: v.subjectCode,
            })),
          },
        ]}
      />
      <button type="button" name="resetButton" css={resetButtonStyle} onClick={() => onChange([])}>
        {t('Reset')}
      </button>
    </span>
  )
}
const reportsStyleSalesBySubjectsItemList = css({
  display: 'flex',
  flexGrow: 1,
  minHeight: 32,
  paddingLeft: 24,
  '.ant-select-selection': {
    borderRadius: '16px 0 0 16px',
  },
  '.ant-select-selection--multiple': {
    '.ant-select-selection__rendered': {
      '.ant-select-selection__placeholder': {
        fontWeight: 'normal',
        color: '#CCCCCC !important',
      },
    },
  },
})
const resetButtonStyle = css({
  width: 85,
  verticalAlign: 'top',
  borderRadius: '0 16px 16px 0',
  border: '1px solid #cccccc',
  borderLeft: 'none',
  backgroundColor: '#ffffff',
  fontWeight: 'bold',
  fontSize: 12,
  cursor: 'pointer',
})
