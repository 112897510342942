import React, { useContext, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { TableHeader, TableBody, TableRow } from '@/components/atoms/settings/table'
import { useTranslation } from 'react-i18next'
import { getSmartLockDevices } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import { LoaderContextCreator } from '@/contexts/loader'
import { Control, Controller, useFieldArray } from 'react-hook-form'
import { ListType } from '@/components/pages/setting/smart-key/space'
import { AccountInfo, Device, LockType } from '@/models/lock-setting'

type Props = {
  accountInfo: AccountInfo | undefined
  control: Control<{ assign: ListType[] }, any>
  onChange: () => void
}

export const LinkSpaceToSwitchBot: React.FC<Props> = ({ accountInfo, control, onChange: parentOnChange }) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { isLoading, setIsLoading } = useContext(LoaderContextCreator())
  const [deviceList, setDeviceList] = useState<Device[]>()

  const assignForm = useFieldArray({
    control,
    name: 'assign',
  })

  const fetchDevice = async () => {
    if (!accountInfo?.isConnected) {
      return
    }
    try {
      setIsLoading(true)
      await getSmartLockDevices({ lockType: LockType.SwitchBot }).then(setDeviceList)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isLoading) {
      return
    }
    fetchDevice()
  }, [accountInfo])

  return (
    <>
      <TableHeader>
        <div css={assetNameColStyle}>{t('Asset')}</div>
        <div css={spaceNameColStyle}>{t('Space name')}</div>
        <div css={keyNumberColStyle}>{t('Device name')}</div>
      </TableHeader>
      <TableBody>
        {assignForm.fields.map((space, i) => (
          <Controller
            key={`${space.spaceId}-${space.spaceIndex}`}
            name={`assign.${i}.remoteDeviceId`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TableRow css={css({ '> div': { fontWeight: 500 } })}>
                <div css={assetNameColStyle}>
                  {assignForm.fields[i].assetName === assignForm.fields[i - 1]?.assetName ? '' : assignForm.fields[i].assetName}
                </div>
                <div css={spaceNameColStyle}>{`${space.spaceName} - ${space.spaceIndex}`}</div>
                <div css={keyNumberColStyle}>
                  <Select
                    value={deviceList?.length ? value : ''}
                    onChange={selectVal => {
                      onChange(selectVal)
                      parentOnChange()
                    }}
                    customStyle={css({ paddingBottom: 0 })}
                  >
                    <AntSelect.Option value="">-</AntSelect.Option>
                    {deviceList?.map(device => (
                      <AntSelect.Option key={device.id} value={device.id}>
                        {device.name}
                      </AntSelect.Option>
                    ))}
                  </Select>
                </div>
              </TableRow>
            )}
          />
        ))}
      </TableBody>
    </>
  )
}

const assetNameColStyle = css({
  padding: '0 32px',
  width: 200,
  overflowWrap: 'anywhere',
})
const spaceNameColStyle = css({
  flexGrow: 1,
  overflowWrap: 'anywhere',
  width: 'min-content',
  paddingRight: 32,
})
const keyNumberColStyle = css({
  width: 300,
  paddingRight: 32,
})