import React from 'react'
import { useTranslation } from 'react-i18next'

// components
import { Button } from '@/components/atoms/button'

type Props = {
  onCancel: () => void
  onSave: () => void
  isDisabledSave?: boolean
}

// I copied the footer of the mobile request product setting and made it for the room setting => TODO: Use this for the mobile request product setting as well
export const EditFooter: React.FC<Props> = ({ onCancel, onSave, isDisabledSave }) => {
  const { t } = useTranslation()
  return (
    <>
      <Button
        buttonType={3}
        width={110}
        height={38}
        fontSize={14}
        marginLeft={32}
        marginTop={11}
        marginBottom={11}
        onClick={() => {
          onCancel()
        }}
      >
        {t('Cancel')}
      </Button>
      <Button
        buttonType={isDisabledSave ? 6 : 1}
        width={110}
        height={38}
        fontSize={14}
        marginLeft={16}
        marginTop={11}
        marginBottom={11}
        onClick={() => {
          onSave()
        }}
      >
        {t('Save')}
      </Button>
    </>
  )
}
