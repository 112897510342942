import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/ja'
import dayjs from 'dayjs'

// contexts
import { AccountContext } from '@/contexts/account'

// apis
import {
  fetchAdminCheckin,
  insertAdminReservationCheckin,
  fetchReservationConfirmedSales,
  fetchReservationLink,
  putAdminReservation,
  updateCheckinReception,
  updateSalesAdvancePayment,
  fetchReservationMarketSegment,
  updateAdminReservationMarketSegment,
  fetchReservationChannelCode,
  updateAdminReservationChannelCode,
  deleteSalesAdvancePayment,
} from '@/apis/aipass'

// models
import { ReservationStatusType } from '@/models/reservation-reservation-status'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { useErrorHandler } from '@/hooks/use-error-handler'

// components
import { Button } from '@/components/atoms/button'
import { DateRangeField } from '@/components/molecules/date-range-field'
import { modalContainerStyle } from '@/components/pages/accommodation-management/list-layout'
import { ReservationUserGuest } from '@/components/organisms/customer/_reservation-user-guest/reservation-usr-guest'
import { DatePicker } from '@/components/organisms/reservation/_detail-edit-info/date-picker'
import { LoadingFull } from '@/components/molecules/loading-full'

// libs
import { setHasTlTwoWayPlugin } from '@/libs/plugins'
import { ShortText } from '@/components/atoms/short-text'
import { times } from '@/libs/times'
import { useAssignConfirmDialog } from '../../reservation/use-assign-confirm-dialog'

type PlanInfoProps = {
  reservation: any
  onChangeReservation?: () => void
  isEdit: boolean
  setIsEdit: (v: boolean) => void
  setIsEditReservationStatus: (v: boolean) => void
  setMarketSegmentChange: (v: boolean) => void
}

export const PlanInfoDetail: React.FC<PlanInfoProps> = ({
  reservation,
  onChangeReservation,
  isEdit,
  setIsEdit,
  setIsEditReservationStatus,
  setMarketSegmentChange,
}) => {
  const { errorHandler, isOutOfStockError } = useErrorHandler()
  const params = useParams<any>()
  const { t, i18n } = useTranslation()
  const { account, plugins } = useContext<any>(AccountContext)
  const { id } = params

  // Reservation information list linked to check-in
  const [checkinReservations, setCheckinReservations] = useState<any>([])
  const [reservationDetailOpen, setReservationDetailOpen] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [reservationId, setReservationId] = useState<any>('')
  const [reservationCheckinDate, setReservationCheckinDate] = useState<any>('')
  const [reservationCheckoutDate, setReservationCheckoutDate] = useState<any>('')
  const [isCheckboxActive, setIsCheckboxActive] = useState<boolean>(false)

  // Reservation information list displayed in the pop-up linking reservation from check-in
  const [reservationList, setReservationList] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)
  const [beginDate, setBeginDate] = useState<string>(dayjs(reservation?.checkinDate).format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState<string>(dayjs(reservation?.checkinDate).add(1, 'day').format('YYYY-MM-DD'))
  const [isInitial, setIsInitial] = useState<boolean>(true)
  // Maximum number of items retrieved with one GETAPI (30 items)
  const DISPLAY_LIMIT_IN_PAGE = 30
  // Number of lists obtained by GETAPI
  const [totalNumber, setTotalNumber] = useState<number>(0)
  // The last number of items in the list obtained by GETAPI
  const [lastPage, setLastPage] = useState<number>(1)
  // Current page (e.g. page 1, page 2)
  const [currentPage, setCurrentPage] = useState<number>(1)
  // "1" part of 1-30 (30*(1-1)+1 = 1st page)
  const displayFrom = DISPLAY_LIMIT_IN_PAGE * (currentPage - 1) + 1
  // "1" part of 1-30 (30*(1-1)+1 = 1st page)
  const displayTo = Math.min(DISPLAY_LIMIT_IN_PAGE * currentPage, totalNumber)

  // Whether it is a Tl2way plugin
  const hasTlTwoWayPlugin = setHasTlTwoWayPlugin(plugins)

  const [marketSegment, setMarketSegment] = useState<any>([])

  const [isSendingCreateReservation, setIsSendingCreateReservation] = useState<boolean>(false)
  const [isEditLoading, setIsEditLoading] = useState<boolean>(false)
  const [showEditFooter, setShowEditFooter] = useState<boolean>(false)
  const [channelCodeId, setChannelCodeId] = useState(reservation?.channelCodeId)
  const [reservationChannelCode, setReservationChannelCode] = useState<any>([])
  const [checkinDate, setCheckinDate] = useState(reservation?.checkinDate)
  const [checkoutDate, setCheckoutDate] = useState(reservation?.checkoutDate)
  const [checkinTime, setCheckinTime] = useState(reservation?.checkinTime)
  const [checkoutTime, setCheckoutTime] = useState(reservation?.checkoutTime)
  const { AssignConfirmDialog, openConfirmDialog } = useAssignConfirmDialog()

  const isChangedCheckinDate = () => dayjs(checkinDate).format('YYYY-MM-DD') !== dayjs(reservation?.checkinDate).format('YYYY-MM-DD')
  const isChangedCheckoutDate = () => dayjs(checkoutDate).format('YYYY-MM-DD') !== dayjs(reservation?.checkoutDate).format('YYYY-MM-DD')

  const _fetchAdminCheckin = async () => {
    await fetchAdminCheckin(id).then(res => {
      setCheckinReservations(res?.reservations)
      setIsLoading(false)
    })
  }

  // Reservation status change
  const updateReservationStatus = async reservationStatus => {
    try {
      /**
       * [For Tl2way] Inventory adjustment
       *
       * If the reservation status is canceled or no show, perform inventory adjustment to remove the assigned room
       */
      let needPopup = true

      if (
        reservation.approvedStatus == ApprovedStatusType.Stay ||
        reservation.approvedStatus == ApprovedStatusType.Checkout ||
        (reservation.approvedStatus == ApprovedStatusType.Checkin && reservationStatus == ReservationStatusType.ModificationReport)
      ) {
        needPopup = false
      }
      let isRunUpdate = false
      if (!needPopup) {
        isRunUpdate = true
      } else if (window.confirm(t('Are you sure you want to unlink the reservation'))) {
        isRunUpdate = true
      }

      if (!isRunUpdate) {
        return
      }
      // Issue an alert only if you have subscribed to the TL2way plug-in and are selling online
      if (
        (reservationStatus === ReservationStatusType.CancellationReport || reservationStatus === ReservationStatusType.NoshowReport) &&
        reservation.isNetSalesReservation &&
        hasTlTwoWayPlugin
      ) {
        if (
          window.confirm(
            t('Its an online reservation If you change or delete the reservation details you may need to adjust the inventory separately'),
          )
        ) {
          // noop
        } else {
          return
        }
      }

      setIsLoading(true)
      await _updateReservation({ reservationStatus })
      setIsEditReservationStatus(true)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
      setIsEditReservationStatus(false)
    }
  }

  const _onReservationFieldClose = () => {
    setReservationDetailOpen(false)
  }

  const _onReservationFieldPush = () => {
    setReservationDetailOpen(true)
  }

  const _displayChild = childs => {
    if (
      !childs?.paxChildA &&
      !childs?.paxChildB &&
      !childs?.paxChildC &&
      !childs?.paxChildD &&
      !childs?.paxChildE &&
      !childs?.paxChildF &&
      !childs?.paxChildOther
    ) {
      return ''
    }
    return (
      <p>
        <br />
        子供 : A {childs?.paxChildA}名 B {childs?.paxChildB}名 C {childs?.paxChildC}名 D {childs?.paxChildD}名 E {childs?.paxChildE}名 F{' '}
        {childs?.paxChildF}名 その他 {childs?.paxChildOther}名
      </p>
    )
  }

  const _displayMeal = meal => {
    // Corresponds to accommodation and meal conditions that are already in the production DB
    if (meal === '朝食付き' || meal === '朝食付' || meal === '朝食が客室料金に含まれています。') {
      return '朝食'
    }
    if (meal === '夕食付き' || meal === '夕食付') {
      return '夕食'
    }
    if (meal === '朝夕食付き' || meal === '朝夕食付' || meal === '夕朝食付') {
      return '朝食/夕食'
    }
    if (meal === '2食付' || meal === '1泊2食') {
      return '2食付き'
    }
    if (meal === '朝昼夕食付き') {
      return '朝食/昼食/夕食'
    }
    // Arrange the applicable lodging and meal conditions in an array without specifying
    const unspecifiedList = [
      undefined,
      '',
      null,
      '指定なし',
      '食事別',
      '食事なし',
      '素泊まり',
      'この客室にはお食事のオプションはありません。',
      '素泊り',
      '朝食が客室料金に含まれています。',
      '他',
      '露天風呂の間2名様　B期間',
      'その他',
      'ツアー代',
      '泊食条件',
      'ツイン２名様　S期間',
      '露天風呂の間2名様　A期間',
    ]
    if (unspecifiedList.includes(meal)) {
      return '-'
    }

    // From now on, accommodation and food conditions will be saved in the DB by either of the following
    // Breakfast / Lunch / Dinner / 1 board / 2 meals / -
    return meal
  }

  // Acquisition of all reservation information for displaying all items
  const _fetchAllReservationInfo = async () => {
    const formatedBeginDate = `${beginDate} 00:00:00`
    const formatedEndDate = `${endDate} 23:59:59`
    if (isInitial) {
      await fetchReservationLink(formatedBeginDate, formatedEndDate, reservation?.telephone, Number(isInitial)).then(res => {
        if (res) {
          setTotalNumber(res?.length)
          setLastPage(Math.ceil(res?.length / DISPLAY_LIMIT_IN_PAGE))
        }
      })
    } else {
      await fetchReservationLink(formatedBeginDate, formatedEndDate, reservation?.telephone, Number(isInitial)).then(res => {
        if (res) {
          setTotalNumber(res?.length)
          setLastPage(Math.ceil(res?.length / DISPLAY_LIMIT_IN_PAGE))
        }
      })
    }
  }
  // Reservation information acquisition by paging
  const _fetchReservationInfoByPaging = async () => {
    const formatedBeginDate = `${beginDate} 00:00:00`
    const formatedEndDate = `${endDate} 23:59:59`
    if (isInitial) {
      await fetchReservationLink(
        formatedBeginDate,
        formatedEndDate,
        reservation?.telephone,
        Number(isInitial),
        currentPage,
        DISPLAY_LIMIT_IN_PAGE,
      ).then(res => {
        if (res) {
          // Reservation information is set in the reservation information list displayed in the pop-up linking reservation from check-in
          setReservationList(res)
          // Automatically select isSameTelphone (the first data with the same phone number)
          if (res?.[0]?.isSameTelphone) {
            setReservationId(res[0]?.reservationId)
            setIsCheckboxActive(true)
            setReservationCheckinDate(res[0]?.reservationCheckinDate)
            setReservationCheckoutDate(res[0]?.reservationCheckoutDate)
          }
        }
        setIsLoading(false)
      })
    } else {
      await fetchReservationLink(
        formatedBeginDate,
        formatedEndDate,
        reservation?.telephone,
        Number(isInitial),
        currentPage,
        DISPLAY_LIMIT_IN_PAGE,
      ).then(res => {
        if (res) {
          setReservationList(res)
        }
        setIsLoading(false)
      })
    }
  }

  // go back
  const onClickPrevious = () => {
    if (currentPage === 1) return
    setCurrentPage(currentPage - 1)
  }

  // move on
  const onClickNext = () => {
    if (currentPage === lastPage) return
    setCurrentPage(currentPage + 1)
  }

  // Reservation and linking from check-in
  const _setReservationCheckin = async () => {
    if (!reservationId) return
    setIsLoading(true)
    // If the reservation has confirmed details, confirm whether to link them
    if (
      (await _hasConfirmedSales(reservationId)) &&
      !window.confirm(t('Includes items with confirmed dates Please pay attention to sales management'))
    ) {
      setIsLoading(false)
      return
    }

    // Match the time to overwrite and update the check-in time and check-out time of the corresponding check-in information in the reservation information
    await insertAdminReservationCheckin(id, reservationId, reservationCheckinDate, reservationCheckoutDate)
      .then(() => {
        setIsLoading(false)
        setIsModalOpen(false)
        window.location.reload()
      })
      .catch(error => {
        console.log(error)
      })

    const reservationIds = reservationId.split(',')
    // Give Advance Receipt Flag
    await updateSalesAdvancePayment(reservationIds)
  }

  // Check if there are confirmed reservation details
  const _hasConfirmedSales = async reservationId => {
    let hasConfirmedSales = false
    await fetchReservationConfirmedSales(reservationId)
      .then(res => {
        // Returns true if there are confirmed details
        if (res.sales.length > 0) hasConfirmedSales = true
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })

    return hasConfirmedSales
  }

  const _reservationGuestRoom = reservationRoom => {
    if (reservationRoom?.length === 0) {
      return <li>- / -</li>
    }
    return reservationRoom?.map((v, index) => {
      const roomName = i18n.language === 'ja' ? v.typeNameJa : v.typeNameEn
      return <li key={index}>{`${roomName ? roomName : '-'} / ${v.roomNum}${t('room counter')}`}</li>
    })
  }

  const _changeRangeDate = (beginDate, endDate) => {
    setIsInitial(false)
    setReservationId('')
    setBeginDate(beginDate)
    setEndDate(endDate)
  }

  // Create new reservation
  const _createReservation = async () => {
    if (isSendingCreateReservation) {
      return
    } else {
      setIsSendingCreateReservation(true)
    }

    setIsLoading(true)
    setBeginDate(dayjs().format('YYYY-MM-DD'))
    setEndDate('')
    await updateCheckinReception([params.id], ApprovedStatusType.Checkin, 'accommodationDetail')
      .then(() => {
        setIsLoading(false)
        window.location.reload()
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setIsSendingCreateReservation(false)
      })
  }

  useEffect(() => {
    if (account && account?.hotel) {
      setIsLoading(true)
      _fetchAdminCheckin()
    }
  }, [account])

  useEffect(() => {
    if (account && account?.hotel) {
      if (beginDate && endDate) {
        _fetchAllReservationInfo()
        _fetchReservationInfoByPaging()
      }
    }
  }, [account, currentPage, beginDate, endDate])

  const _fetchReservationMarketSegment = () => {
    fetchReservationMarketSegment().then(res => {
      if (res?.marketSegments) setMarketSegment(res.marketSegments)
    })
  }

  const _fetchReservationChannelCode = () => {
    fetchReservationChannelCode().then(res => {
      if (res) setReservationChannelCode(res.channelCodes)
    })
  }

  const updateChannelCode = channelCodeId => {
    setChannelCodeId(channelCodeId)
  }

  const _validate = (): boolean => {
    // Accommodation date before change
    const checkinDateWithoutHour = checkinDate?.includes(' ') ? checkinDate.split(' ')[0] : checkinDate // Default BE return YYYY-MM-DD HH:MM:SS
    const checkoutDateWithoutHour = checkoutDate?.includes(' ') ? checkoutDate.split(' ')[0] : checkoutDate // Default BE return YYYY-MM-DD HH:MM:SS

    if (dayjs(checkoutDate).format('YYYY-MM-DD') < dayjs(checkinDate).format('YYYY-MM-DD')) {
      window.alert(t('Please set the check-out date after the check-in date'))
      return false
    }

    if (
      checkinTime &&
      checkoutTime &&
      dayjs(`${checkoutDateWithoutHour} ${checkoutTime}`).isSameOrBefore(`${checkinDateWithoutHour} ${checkinTime}`)
    ) {
      window.alert(t('Checkout time before checkin time'))
      return false
    }

    return true
  }

  const _updateReservation = async (
    updateValues: {
      checkinDate?: string
      checkoutDate?: string
      checkinTime?: string
      checkoutTime?: string
      reservationStatus?: ReservationStatusType
    },
    option?: { validationOnly?: boolean; excludeStockCheck?: boolean },
  ) => {
    const data = {
      reservationId: reservation.reservationId,
      reservation: {
        checkinDate: dayjs(updateValues.checkinDate || reservation.checkinDate).format('YYYY-MM-DD'),
        checkoutDate: dayjs(updateValues.checkoutDate || reservation.checkoutDate).format('YYYY-MM-DD'),
        checkinTime: updateValues.checkinTime || reservation.checkinTime,
        checkoutTime: updateValues.checkoutTime || reservation.checkoutTime,
        meal: reservation.meal,
        method: reservation.method,
        nights: reservation.nights,
        notes: reservation.notes,
        payment: reservation.payment,
        plan: reservation.plan,
        accommodationFee: reservation.accommodationFee,
        pointDiscount: reservation.pointDiscount,
        male: reservation.paxMale,
        female: reservation.paxFemale,
        childA: reservation.paxChildA,
        childB: reservation.paxChildB,
        childC: reservation.paxChildC,
        childD: reservation.paxChildD,
        childE: reservation.paxChildE,
        childF: reservation.paxChildF,
        childOther: reservation.paxChildOther,
        optionStatus: updateValues.reservationStatus || reservation.reservationStatus,
      },
    }
    await putAdminReservation({ ...data, ...option })
  }

  const onSaveData = async () => {
    try {
      if (!_validate()) return

      try {
        await _updateReservation({ checkinDate, checkoutDate, checkinTime, checkoutTime }, { validationOnly: true })
      } catch (e) {
        if (!isOutOfStockError(e as Error)) {
          throw e
        }
        if (!(await openConfirmDialog())) {
          return
        }
      }

      setIsEditLoading(true)

      await updateAdminReservationChannelCode(reservation.reservationId, channelCodeId)

      if (isChangedCheckinDate()) {
        // Remove down payment flag
        await deleteSalesAdvancePayment(reservation.reservationId).catch(() => {
          console.log(t('Communication failed'))
        })
      }

      await _updateReservation({ checkinDate, checkoutDate, checkinTime, checkoutTime }, { excludeStockCheck: true })
      if (onChangeReservation) onChangeReservation()

      setShowEditFooter(false)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsEditLoading(false)
    }
  }

  const resetReservationData = () => {
    setCheckinDate(reservation?.checkinDate)
    setCheckoutDate(reservation?.checkoutDate)
    setCheckinTime(reservation?.checkinTime)
    setCheckoutTime(reservation?.checkoutTime)
    setChannelCodeId(reservation?.channelCodeId)
  }

  const updateMarketSegment = marketSegmentId => {
    setIsLoading(true)
    updateAdminReservationMarketSegment(reservation.reservationId, marketSegmentId).then(res => {
      setMarketSegmentChange(true)
      setIsLoading(false)
    })
    setMarketSegmentChange(false)
  }

  const getReservationUpdateDate = (reservation: any): string => {
    if (!reservation || (!reservation.statusUpdatedAt && !reservation.reservationDate)) {
      return '-'
    }
    return dayjs(reservation.statusUpdatedAt || reservation.reservationDate).format(t('YYYY-MM-DD HH:mm'))
  }

  useEffect(() => {
    _fetchReservationChannelCode()
    _fetchReservationMarketSegment()
  }, [])

  useEffect(() => {
    resetReservationData()
  }, [reservation])

  useEffect(() => {
    if (
      isChangedCheckinDate() ||
      isChangedCheckoutDate() ||
      checkinTime !== reservation?.checkinTime ||
      checkoutTime !== reservation?.checkoutTime ||
      channelCodeId !== reservation?.channelCodeId
    ) {
      setShowEditFooter(true)
    } else {
      setShowEditFooter(false)
    }
  }, [checkinDate, checkoutDate, checkinTime, checkoutTime, channelCodeId])

  useEffect(() => {
    // If the check-in details screen and there is no reservation linked to the check-in, the clicked part of "reservation and link" is initially displayed.
    if (
      account &&
      account?.hotel &&
      checkinReservations &&
      reservation &&
      checkinReservations.length === 0 &&
      reservation.approvedStatus === ApprovedStatusType.Checkin
    ) {
      const addedReservations = [...checkinReservations]
      // Display the click part of "reservation and link" with reservationCheckinLink=true
      addedReservations.push({ reservationCheckinLink: true })
      setCheckinReservations(addedReservations)
    }
  }, [checkinReservations])

  return (
    <div css={{ position: 'relative' }}>
      <LoadingFull isLoading={isEditLoading} />
      {/* When a reservation is linked to check-in and the click screen for "reservation and link" is not displayed (linked reservation information display) */}
      {!!reservation && !reservation.reservationCheckinLink && (
        <div css={reservationInfoStyle}>
          <div css={topContentStyle}>
            {reservation && (
              <div css={reservationStatusStyle} onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
                {reservation.reservationStatus === ReservationStatusType.NewReport && <>{t('New')}</>}
                {reservation.reservationStatus === ReservationStatusType.ModificationReport && <>{t('Update')}</>}
                {reservation.reservationStatus === ReservationStatusType.CancellationReport && <>{t('Cancel')}</>}
                {reservation.reservationStatus === ReservationStatusType.NoshowReport && <>{t('No show')}</>}
                <img css={arrowStyle} src={require('@/static/images/arrow_yellow.svg')} alt="" />
                {isDropDownOpen && (
                  <div css={dropDownStyle}>
                    <ul css={dropDownBodyStyle}>
                      {reservation.reservationStatus !== ReservationStatusType.NewReport && (
                        <li
                          css={dropDownContentStyle}
                          onClick={() => {
                            updateReservationStatus(ReservationStatusType.NewReport)
                          }}
                        >
                          <p css={dropDownTitleStyle}>{t('New')}</p>
                        </li>
                      )}
                      {reservation.reservationStatus !== ReservationStatusType.ModificationReport && (
                        <li
                          css={dropDownContentStyle}
                          onClick={() => {
                            updateReservationStatus(ReservationStatusType.ModificationReport)
                          }}
                        >
                          <p css={dropDownTitleStyle}>{t('Update')}</p>
                        </li>
                      )}
                      {reservation.reservationStatus !== ReservationStatusType.CancellationReport && (
                        <li
                          css={dropDownContentStyle}
                          onClick={() => {
                            updateReservationStatus(ReservationStatusType.CancellationReport)
                          }}
                        >
                          <p css={dropDownTitleStyle}>{t('Cancel')}</p>
                        </li>
                      )}
                      {reservation.reservationStatus !== ReservationStatusType.NoshowReport && (
                        <li
                          css={dropDownContentStyle}
                          onClick={() => {
                            updateReservationStatus(ReservationStatusType.NoshowReport)
                          }}
                        >
                          <p css={dropDownTitleStyle}>{t('No show')}</p>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            )}
            <div css={{ display: 'flex', alignItems: 'center' }}>
              {reservation && marketSegment.length ? (
                <select
                  name="selectMarketSegment"
                  css={selectStyle}
                  onChange={e => updateMarketSegment(e.target.value)}
                  value={reservation.marketSegmentId ?? ''}
                >
                  <option value="">{t('Market segment')}</option>
                  {marketSegment.map(itemSegment => (
                    <option key={itemSegment.id} value={itemSegment.id}>
                      {itemSegment.name}
                    </option>
                  ))}
                </select>
              ) : null}
              <div css={editableIcon} onClick={() => setIsEdit(!isEdit)}></div>
            </div>
          </div>
          {/* Full booking details */}
          <div css={listWrapperStyle}>
            <div>
              {/* Date of stay */}
              <div>
                <div css={checkInDateStyle}>
                  <img alt="icon_plan_name" src={require('@/static/images/date.svg')} />
                  <div>
                    <div
                      css={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: '1fr auto 1fr',
                        gridTemplateRows: 'repeat(2, 1fr)',
                        gridGap: '16px 0',
                      }}
                    >
                      <DatePicker date={checkinDate} onDateChange={setCheckinDate} />
                      <span css={wavyLineStyle}>~</span>
                      <DatePicker date={checkoutDate} onDateChange={setCheckoutDate} />
                      <select
                        name="checkin_time"
                        css={checkinTimeSelectBoxStyle}
                        value={checkinTime ?? ''}
                        onChange={e => setCheckinTime(e.target.value)}
                      >
                        {times.map((item, index) => (
                          <option key={index} value={item['value']}>
                            {item['name'] === '指定なし' ? '-' : item['name']}
                          </option>
                        ))}
                      </select>
                      <span />
                      <select
                        name="checkout_time"
                        css={checkinTimeSelectBoxStyle}
                        value={checkoutTime ?? ''}
                        onChange={e => setCheckoutTime(e.target.value)}
                      >
                        {times.map((item, index) => (
                          <option key={index} value={item['value']}>
                            {item['name'] === '指定なし' ? '-' : item['name']}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* Number of guests */}
              <div css={numberOfCustomerStyle}>
                <img alt="icon_plan_name" src={require('@/static/images/numberofpeople.svg')} />
                <ul>
                  {reservation?.paxTotal ? reservation?.paxTotal : '-'}
                  {t('Name')}
                </ul>
              </div>
              <p css={listSubTextStyle}>
                {t('Adult:')}
                {`${t('Male')} ${reservation?.paxMale ? reservation?.paxMale : '-'}${t('Name')}`}
                &emsp;
                {`${t('Female')} ${reservation?.paxFemale ? reservation?.paxFemale : '-'}${t('Name')}`}
              </p>
              <p css={listSubTextStyle}>{_displayChild(reservation)}</p>

              {/* memo */}
              <div css={listMemoStyle}>
                <img alt="memo" src={require('@/static/images/memo.svg')} />
                <ShortText>{reservation?.notes ? reservation?.notes : '-'}</ShortText>
              </div>
            </div>
            <div className="divider" />
            <div>
              {/* chanel code name */}
              <div css={[reservationRoomContainerStyle, { marginTop: 0 }]}>
                <img alt="icon_plan_name" src={require('@/static/images/chanel.svg')} />
                <div>
                  <select
                    name="channelCode"
                    css={selectStyle}
                    onChange={e => updateChannelCode(e.target.value)}
                    value={channelCodeId ?? ''}
                  >
                    <option value="">-</option>
                    {reservationChannelCode.map(itemChannelCode => (
                      <option key={itemChannelCode.id} value={itemChannelCode.id}>
                        {itemChannelCode.name}
                      </option>
                    ))}
                    {reservation?.channelCodeId && !reservationChannelCode.some(({ id }) => reservation?.channelCodeId === id) && (
                      <option value={reservation?.channelCodeId} selected>
                        {reservation?.channelCodeName}
                      </option>
                    )}
                  </select>
                </div>
              </div>
              {/* plan name */}
              <div css={reservationRoomContainerStyle}>
                <img alt="icon_plan_name" src={require('@/static/images/plan.svg')} />
                <ul>{reservation?.plan || '-'}</ul>
              </div>
              {/* reserved room */}
              <div css={reservationRoomContainerStyle}>
                <img alt="icon_plan_name" src={require('@/static/images/icon_plan_name.svg')} />
                <ul>{_reservationGuestRoom(reservation?.reservationRoom)}</ul>
              </div>

              {/* meal */}
              <div css={listMealStyle}>
                <img alt="eat" src={require('@/static/images/eat.svg')} />
                <p css={listTextStyle}>{_displayMeal(reservation?.meal)}</p>
              </div>

              {/* payment */}
              <div css={listPayStyle}>
                <div css={listPayTopBlockStyle}>
                  <img alt="pay" src={require('@/static/images/pay.svg')} />
                  <p css={listTextStyle}>
                    {reservation?.totalAmount ? `¥${Number(reservation?.totalAmount).toLocaleString()}` : '-'} /{' '}
                    {reservation?.payment ? reservation?.payment : '現地決済'}
                  </p>
                </div>
                <div css={listPaySubBlockStyle}>
                  <p css={listSubTextStyle}>
                    {t('Accommodation fee')}
                    {reservation?.accommodationFee ? `¥${Number(reservation?.accommodationFee).toLocaleString()}` : '¥0'}
                    &emsp;{t('Point discount')}
                    {reservation?.pointDiscount
                      ? reservation?.pointDiscount === 0
                        ? '¥0'
                        : `-¥${Number(reservation?.pointDiscount).toLocaleString()}`
                      : '¥0'}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* view the details */}
          {!reservationDetailOpen && (
            <div css={reservationDetailCloseStyle} onClick={() => _onReservationFieldPush()}>
              <p>{t('View the details')}</p>
              <img alt="icon_arrow_down" src={require('@/static/images/arrow_down.svg')} />
            </div>
          )}
        </div>
      )}
      {/* When displaying the check-in details screen and the "reservation and link" click screen (Even if there is no reservation linked to the check-in, reservationCheckinLink: true is added to the reservation information list (checkinReservations) when the reservation information is acquired. be done) */}
      {!reservation?.reservationId && (
        <>
          <div css={reservationCheckinWrapperStyle}>
            <div css={reservationCheckinLinkWrapperStyle}>
              <div css={reservationCheckinLinkStyle} onClick={() => setIsModalOpen(true)}>
                <img src={require('@/static/images/search.svg')} alt={t('Add icon red')} css={addIconStyle} />
                <p>{t('Reservation and pegging')}</p>
              </div>
            </div>
            <div css={reservationCheckinLinkRightWrapperStyle}>
              <div
                css={reservationCheckinLinkStyle}
                onClick={() => {
                  _createReservation()
                }}
              >
                <img src={require('@/static/images/create.svg')} alt={t('Add icon red')} css={addIconStyle} />
                <p>{t('Create a reservation')}</p>
              </div>
            </div>
          </div>
        </>
      )}
      {isModalOpen && (
        <React.Fragment>
          <div css={modalContainerStyle}></div>
          <div css={modalBodyContainerStyle}>
            {isLoading && <div css={loadingOverlayStyle} />}
            <h3 css={modalHeaderStyle}>{t('Select relevant booking')}</h3>
            <div css={modalMainContainerStyle}>
              <div css={dateRangeFieldContainerStyle}>
                <DateRangeField beginDate={beginDate} endDate={endDate} changeRangeDate={_changeRangeDate} />
              </div>
              <div css={modalMainTopContainerStyle}>
                <ul css={modalMainTopContentsListStyle}>
                  <li css={modalTopContentsLabelStyle}>{t('Guests')} :</li>
                  <li css={modalTopContentsStyle}>{reservation && reservation.name ? reservation.name : '-'}</li>
                </ul>
                <ul css={modalMainTopContentsListRightStyle}>
                  <li css={modalTopContentsLabelStyle}>{t('Date of stay')} :</li>
                  <li css={modalTopContentsStyle}>{dayjs(reservation && reservation.userCheckinTime).format(t('MM-DD(ddd)'))}</li>
                </ul>
              </div>
              <ul css={reservationLabelContainerStyle}>
                <li css={reservationUserLabelStyle}>{t('Reservation name')}</li>
                <li css={reservationGuestLabelStyle}>{t('Guests')}</li>
                <li css={reservationCheckinLabelStyle}>{t('Date of stay')}</li>
              </ul>
              <div css={modalMainCheckInListContainerStyle}>
                {reservationList &&
                  reservationList?.length !== 0 &&
                  reservationList?.map(v => (
                    <li
                      key={v.reservationId}
                      css={reservationId === v?.reservationId ? reservationContentsActiveStyle : reservationContentsStyle}
                      onClick={() => {
                        setReservationId(v?.reservationId)
                        setIsCheckboxActive(true)
                        setReservationCheckinDate(v?.reservationCheckinDate)
                        setReservationCheckoutDate(v?.reservationCheckoutDate)
                      }}
                    >
                      <img
                        alt="checkbox"
                        src={require(`@/static/images/radio${reservationId === v.reservationId ? '_on' : '_off'}.svg`)}
                        css={reservationCheckBoxStyle}
                      />
                      <ul css={reservationUserWrapperStyle}>
                        <li css={reservationGuestNameKanaStyle}>
                          {v?.reservationUserNameKana === null || v?.reservationUserNameKana === '' ? '-' : v.reservationUserNameKana}
                        </li>
                        <li css={reservationGuestNameStyle}>
                          {v?.reservationUserName === null || v?.reservationUserName === '' ? '-' : v.reservationUserName}
                        </li>
                      </ul>
                      <ul css={reservationGuestWrapperStyle}>
                        <li css={reservationGuestNameKanaStyle}>
                          {v?.reservationGuestNameKana === null || v?.reservationGuestNameKana === '' ? '-' : v.reservationGuestNameKana}
                        </li>
                        <li css={reservationGuestNameStyle}>
                          {v?.reservationGuestName === null || v?.reservationGuestName === '' ? '-' : v.reservationGuestName}
                        </li>
                      </ul>
                      <div css={reservationScheduleDayStyle}>
                        {v?.reservationCheckinDate ? dayjs(v?.reservationCheckinDate).format('MM月DD日(ddd)') : '-'}
                      </div>
                    </li>
                  ))}
              </div>
              <div css={detailListPaginationContainerStyle}>
                <p css={paginationLineCountStyle}>
                  {displayFrom}-{displayTo} / {totalNumber} {t('Rows')}
                </p>
                <div css={paginationArrowStyle} onClick={onClickPrevious}>
                  {displayFrom === 1 ? (
                    <img alt="arrow_disabled_left" src={require('@/static/images/arrow_disabled_left.svg')} onClick={onClickPrevious} />
                  ) : (
                    <img alt="arrow_left" src={require('@/static/images/arrow_left.svg')} onClick={onClickPrevious} />
                  )}
                </div>
                <div css={paginationArrowStyle} onClick={onClickNext}>
                  {displayTo === totalNumber ? (
                    <img alt="arrow_disabled_right" src={require('@/static/images/arrow_disabled_right.svg')} onClick={onClickNext} />
                  ) : (
                    <img alt="arrow_right" src={require('@/static/images/arrow_right.svg')} onClick={onClickNext} />
                  )}
                </div>
              </div>
            </div>
            <div css={modalButtonSectionStyle}>
              <Button width={110} height={38} buttonType={3} marginRight={16} onClick={() => setIsModalOpen(false)}>
                {t('Cancel')}
              </Button>
              <Button
                width={110}
                height={40}
                buttonType={isCheckboxActive ? 1 : 5}
                onClick={() => {
                  isCheckboxActive && _setReservationCheckin()
                }}
              >
                {t('Completion')}
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}
      {/* If you press "View reservation details" and do not display the "Reservation and link" click screen (connected reservation information display) */}
      {reservationDetailOpen && !reservation?.reservationCheckinLink && (
        <div css={reservationInfoDetailStyle}>
          <div css={detailListWrapper}>
            <ReservationUserGuest
              userOrGuest={'user'}
              kana={
                reservation?.reservationUserNameKana === null || reservation?.reservationUserNameKana === ''
                  ? '-'
                  : reservation?.reservationUserNameKana
              }
              name={
                reservation?.reservationUserName === null || reservation?.reservationUserName === ''
                  ? '-'
                  : reservation?.reservationUserName
              }
              tel={
                reservation?.reservationUserTel === null || reservation?.reservationUserTel === '' ? '-' : reservation?.reservationUserTel
              }
              mail={
                reservation?.reservationUserMailAddr === null || reservation?.reservationUserMailAddr === ''
                  ? '-'
                  : reservation?.reservationUserMailAddr
              }
              address={
                reservation?.reservationUserAddr === null || reservation?.reservationUserAddr === ''
                  ? '-'
                  : reservation?.reservationUserAddr
              }
            />
            <div className="divider" />
            <ReservationUserGuest
              userOrGuest={'guest'}
              kana={reservation && reservation.guestNameKana ? reservation.guestNameKana : '-'}
              name={reservation && reservation.guestName ? reservation.guestName : '-'}
              tel={reservation && reservation.guestTel ? reservation.guestTel : '-'}
              mail={reservation && reservation.guestMailAddr ? reservation.guestMailAddr : '-'}
              address={reservation && reservation.guestAddr ? reservation.guestAddr : '-'}
            />
          </div>
          <div css={reservationDetailCloseStyle} onClick={() => _onReservationFieldClose()}>
            <p>{t('Close up')}</p>
            <img css={reversal} alt="icon_arrow_up" src={require('@/static/images/arrow_down.svg')} />
          </div>
        </div>
      )}
      {showEditFooter ? (
        <div css={[bottomContentStyle, { justifyContent: 'end', backgroundColor: '#fff', padding: '12px 32px' }]}>
          <Button buttonType={3} width={100} height={30} fontSize={12} marginRight={16} onClick={resetReservationData}>
            {t('Cancel')}
          </Button>
          <Button buttonType={1} width={100} height={30} fontSize={12} onClick={onSaveData}>
            {t('Save')}
          </Button>
        </div>
      ) : (
        reservation &&
        reservation?.reservationId && (
          <div css={bottomContentStyle}>
            <div css={detailIdStyle}>
              <p>
                {reservation?.accommodationId === undefined
                  ? `${t('Accommodation ID')} : -`
                  : `${t('Accommodation ID')} : ${reservation?.accommodationId}`}
              </p>
              <p>
                {reservation?.reservationId === undefined
                  ? `${t('Reservation ID')} : -`
                  : `${t('Reservation ID')} : ${reservation?.reservationId}`}
              </p>
            </div>
            <div css={reservationUpdateDateStyle}>
              {t('Reservation update:')} {getReservationUpdateDate(reservation)}
            </div>
          </div>
        )
      )}
      <AssignConfirmDialog />
    </div>
  )
}

const reservationInfoStyle = css({
  borderRadius: '10px 10px 0 0',
  border: '1px solid #CCCCCC',
  borderBottom: 'none',
  overflow: 'hidden',
})

const topContentStyle = css({
  paddingLeft: 32,
  paddingRight: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #CCCCCC',
})

const bottomContentStyle = css({
  paddingLeft: 32,
  paddingRight: 30,
  display: 'flex',
  backgroundColor: '#F5F5F5',
  alignItems: 'center',
  border: '1px solid #CCCCCC',
  borderRadius: '0 0 10px 10px',
  borderTop: '0',
  height: 54,
  justifyContent: 'space-between',
})

const arrowStyle = css({
  position: 'absolute',
  right: 8,
  top: 9,
})

const dropDownStyle = css({
  width: 108,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 30,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownBodyStyle = css({})

const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const selectStyle = css({
  display: 'block',
  height: 32,
  borderRadius: 16,
  textAlign: 'left',
  fontSize: 14,
  border: '1px solid #CCCCCC',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  paddingLeft: 20,
  paddingRight: 34,
  cursor: 'pointer',
  backgroundPosition: '92% center',
  width: '205px',
})

const dropDownTitleStyle = css({
  fontSize: 14,
  fontWeight: 'normal',
  color: '#676767',
})

const reservationStatusStyle = css({
  width: 116,
  height: 24,
  border: '1px solid #E7A73C',
  borderRadius: 5,
  color: '#E7A73C',
  fontSize: 12,
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '6px 0',
  marginTop: 11,
  marginBottom: 10,
  position: 'relative',
})

const reservationUpdateDateStyle = css({
  color: '#676767',
  fontSize: 12,
  marginTop: 17,
  marginBottom: 17,
})

// 予約詳細
const listWrapperStyle = css({
  backgroundColor: '#FFFFFF',
  width: '100%',
  minHeight: 347,
  borderBottom: 'solid 1px #CCCCCC',
  display: 'flex',
  alignItems: 'stretch',
  '> div:not(.divider)': {
    flex: 1,
    padding: 24,
  },
  '> .divider': {
    width: 1,
    backgroundColor: '#ccc',
    margin: '24px 0',
  },
})

// 宿泊日
const checkInDateStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  '> img': {
    marginRight: 18,
  },
  '.SingleDatePickerInput': {
    display: 'flex',
    width: 'auto',
    '.DateInput': {
      width: '100%',
    },
    '.SingleDatePicker_picker': {
      top: '12px !important',
    },
    '.SingleDatePickerInput_calendarIcon': {
      display: 'none',
    },
  },
})

// 宿泊人数
const numberOfCustomerStyle = css(checkInDateStyle, {
  marginTop: 25,
})

// 予約部屋
const reservationRoomContainerStyle = css(checkInDateStyle, {
  display: 'flex',
  marginTop: 24,
})

const listTextStyle = css({})

// 食事
const listMealStyle = css(checkInDateStyle, {
  margin: '24px 0 0 0',
})

// 支払い
const listPayTopBlockStyle = css(checkInDateStyle, {
  margin: '28px 0 0 0',
})

const listPayStyle = css({
  color: '#676767',
  fontWeight: 'bold',
  alignItems: 'center',
})

const listPaySubBlockStyle = css({
  display: 'flex',
  flexDirection: 'column',
})

const listSubTextStyle = css({
  fontSize: 12,
  color: '#676767',
  fontWeight: 'normal',
  paddingTop: 10,
  paddingLeft: 35,
})

// メモ
const listMemoStyle = css({
  margin: '24px 25px 0 0',
  fontSize: 14,
  fontWeight: 'normal',
  color: '#676767',
  letterSpacing: 0.7,
  display: '-webkit-inline-box',
  lineHeight: '1.4',
  overflowWrap: 'anywhere',
  img: {
    margin: '0 11px 0 0',
  },
})

const reservationInfoDetailStyle = css({
  background: '#fff',
  color: '#676767',
  borderLeft: '1px solid #CCCCCC',
  borderRight: '1px solid #CCCCCC',
})

const detailListWrapper = css({
  background: '#fff',
  width: '100%',
  borderBottom: '1px solid #CCCCCC',
  display: 'flex',
  alignItems: 'stretch',
  '> .divider': {
    width: 1,
    backgroundColor: '#ccc',
    margin: '32px 0',
  },
})

const detailIdStyle = css({
  fontSize: 12,
  color: '#676767',
  fontWeight: 'normal',
  display: 'flex',
  overflow: 'hidden',
  p: {
    margin: '0 24px 0 0',
  },
})

const reservationDetailCloseStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#DD9914',
  fontSize: 12,
  padding: '8px 0',
  cursor: 'pointer',
  fontWeight: 'bold',
  borderBottom: '1px solid #ccc',
  marginLeft: 0,
})

const reservationCheckinWrapperStyle = css({
  backgroundColor: '#FFF',
  display: 'flex',
  width: '100%',
  margin: '21px 0 32px 0',
})

const reservationCheckinLinkWrapperStyle = css({
  width: '50%',
  backgroundColor: '#FFF',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  borderRadius: '0 0 5px 5px',
})

const reservationCheckinLinkRightWrapperStyle = css(reservationCheckinLinkWrapperStyle, {
  marginLeft: 29,
})

const reservationCheckinLinkStyle = css({
  width: '100%',
  height: 129,
  cursor: 'pointer',
  backgroundColor: '#FAFAFA',
  border: '1px solid #CCCCCC',
  borderRadius: 10,
  padding: '39px 0 38px',
  textAlign: 'center',
  p: {
    textAlign: 'center',
    color: '#676767',
    fontSize: 14,
    fontWeight: 'bold',
  },
  alignItems: 'center',
  ':hover': {
    background: '#F2F2F2',
  },
})

const addIconStyle = css({
  width: 24,
  height: 24,
  marginBottom: 14,
})

// Modal
const modalBodyContainerStyle = css({
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: 680,
  zIndex: 102,
  opacity: 1,
  background: '#FFF',
  borderRadius: 5,
  transform: 'translate(-50%, -50%)',
})

// 処理中オーバーレイ
const loadingOverlayStyle = css({
  position: 'absolute',
  zIndex: 105,
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.8)',
  opacity: 0.7,
  alignItems: 'center',
  borderRadius: 5,
})

const modalHeaderStyle = css({
  margin: '22px 32px',
  fontWeight: 'bold',
  fontSize: 18,
  color: '#676767',
})

const modalMainContainerStyle = css({
  padding: '26px 32px',
  backgroundColor: '#F2F2F2',
})

const dateRangeFieldContainerStyle = css({
  marginBottom: 24,
})

const modalMainTopContainerStyle = css({
  display: 'flex',
  marginBottom: 24,
})

const modalMainTopContentsListStyle = css(modalMainTopContainerStyle, {
  marginBottom: 0,
})

const modalMainTopContentsListRightStyle = css(modalMainTopContentsListStyle, {
  marginLeft: 'auto',
})

const modalTopContentsLabelStyle = css({
  fontSize: 14,
  color: '#A3A3A3',
  marginRight: 12,
  fontWeight: 'bold',
})

const reservationUserLabelStyle = css({
  marginLeft: 38,
  width: 140,
})

const reservationGuestLabelStyle = css({
  marginLeft: 20,
  width: 140,
})

const reservationCheckinLabelStyle = css({
  marginLeft: 'auto',
  minWidth: 92,
})

const modalTopContentsStyle = css({
  maxWidth: '111px',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#676767',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const modalMainCheckInListContainerStyle = css({
  width: '100%',
  height: 256,
  backgroundColor: '#FFF',
  border: '1px solid #CCCCCC',
  borderTop: 'none',
  borderRadius: '0 0 5px 5px',
  overflow: 'auto',
})

const reservationLabelContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#FFF',
  padding: '10px 36px',
  color: '#676767',
  fontWeight: 'bold',
  fontSize: 12,
  borderRadius: '5px 5px 0 0',
  borderLeft: '1px solid #CCCCCC',
  borderTop: '1px solid #CCCCCC',
  borderRight: '1px solid #CCCCCC',
  borderBottom: '1px solid #ccc',
})

const reservationContentsStyle = css({
  cursor: 'pointer',
  minHeight: 64,
  display: 'flex',
  alignItems: 'center',
  padding: '12px 27px 12px 32px',
  borderBottom: '1px solid #ccc',
  ':hover': {
    boxShadow: '0px 3px 6px #0000000f',
  },
})

const reservationContentsActiveStyle = css(reservationContentsStyle, {
  backgroundColor: '#fef3f0',
})

const reservationUserWrapperStyle = css({
  color: '#676767',
  fontSize: 14,
  width: 140,
  justifyContent: 'space-between',
  fontWeight: 'bold',
  display: 'flex',
  marginLeft: 25,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  flexDirection: 'column',
})

const reservationGuestWrapperStyle = css({
  color: '#676767',
  fontSize: 14,
  width: 140,
  justifyContent: 'space-between',
  fontWeight: 'bold',
  display: 'flex',
  marginLeft: 20,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  flexDirection: 'column',
})

const reservationScheduleDayStyle = css({
  color: '#676767',
  fontSize: 14,
  marginLeft: 'auto',
  minWidth: 92,
  textAlign: 'center',
})

const modalButtonSectionStyle = css({
  display: 'flex',
  padding: '11px 32px 11px 32px',
  justifyContent: 'flex-end',
  boxShadow: '0px 0px 6px #0000001A',
})

const reservationGuestNameKanaStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: 12,
  paddingBottom: 6,
})

const reservationGuestNameStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: 14,
})

const reservationCheckBoxStyle = css({
  width: 18,
})

const detailListPaginationContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: 30,
  width: '100%',
  justifyContent: 'flex-end',
  marginTop: 15,
})

const paginationLineCountStyle = css({
  fontSize: 12,
  color: '#676767',
  marginRight: 16,
  padding: '8px 0',
  fontWeight: 'bold',
})

const paginationArrowStyle = css({
  borderRadius: '50%',
  height: 30,
  ':hover': {
    backgroundColor: '#F2F2F2',
    cursor: 'pointer',
  },
})

const editableIcon = css({
  display: 'block',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundColor: '#FFF',
  borderRadius: '50px',
  width: '30px',
  height: '30px',
  float: 'right',
  backgroundImage: `url(${require('@/static/images/edit.svg')})`,
  border: 'solid 1px #cccccc',
  ':hover': {
    cursor: 'pointer',
  },
  marginLeft: 20,
})

const reversal = css({
  transform: 'scale(1, -1)',
})

const wavyLineStyle = css({
  padding: '0 7px',
})

const checkinTimeSelectBoxStyle = css({
  width: 107,
  backgroundPosition: 72,
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  height: 32,
  padding: '8px 20px',
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  textAlign: 'left',
})
