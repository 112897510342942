export const SalesCategory = {
  Customer: 'customer',
  Temporary: 'temporary',
  Other: 'other',
} as const
export type SalesCategory = (typeof SalesCategory)[keyof typeof SalesCategory]

export const SalesPriceSeparate = {
  Simple: '0',
  AdultsAndChildren: '3',
  Adults: '4',
  Children: '5',
  AdultsMale: '6',
  AdultsFemale: '7',
  ChildA: '8',
  ChildB: '9',
  ChildC: '10',
  ChildD: '11',
  ChildE: '24',
  ChildF: '25',
  ChildOther: '12',
} as const
export type SalesPriceSeparate = (typeof SalesPriceSeparate)[keyof typeof SalesPriceSeparate]
export const SalesPriceOutsidePlanSeparate = {
  Simple: '99',
  AdultsAndChildren: '13',
  Adults: '14',
  Children: '15',
  AdultsMale: '16',
  AdultsFemale: '17',
  ChildA: '18',
  ChildB: '19',
  ChildC: '20',
  ChildD: '21',
  ChildE: '26',
  ChildF: '27',
  ChildOther: '22',
} as const
export type SalesPriceOutsidePlanSeparate = (typeof SalesPriceOutsidePlanSeparate)[keyof typeof SalesPriceOutsidePlanSeparate]

export type SalesSubjectType = {
  id: string
  hotelId: string
  departmentId: string
  departmentCode: string
  departmentName: string
  subjectCode: string
  subjectName: string
  subSubjectId: string
  subSubjectCode: string
  subSubjectName: string
  subSubjectPrice: number
  hasSubSubject?: boolean
  price: {
    hasSeparated: SalesPriceSeparate | SalesPriceOutsidePlanSeparate | undefined
    price: number
    malePrice: number
    femalePrice: number
    childAPrice: number
    childBPrice: number
    childCPrice: number
    childDPrice: number
    childEPrice: number
    childFPrice: number
    childOtherPrice: number
  }
  tax: number
  taxClass: number
  category: SalesCategory | undefined
  planCode: string[]
}

export type SalesSubjectSetMapType = {
  salesSubjectSetMapId: string
  salesSubjectMasterId: string
  salesSubjectMasterName: string
}

export type SalesSubjectSetType = {
  salesSubjectSetId: string
  hotelId: string
  salesSubjectSetNameId: number
  salesSubjectSetName: string
  salesSubjectSetMap: SalesSubjectSetMapType[]
}

export type SalesDepartmentMasterType = {
  id: string
  departmentCode: string
  departmentName: string
  relatedSubjectMasterCount: number
}
export type SalesSubjectMasterType = {
  id: string
  salesDepartementMasterId: string
  subjectCode: string
  name: string
  hasSeparated: SalesPriceSeparate | SalesPriceOutsidePlanSeparate | undefined
  price: number
  malePrice: number
  femalePrice: number
  childAPrice: number
  childBPrice: number
  childCPrice: number
  childDPrice: number
  childEPrice: number
  childFPrice: number
  childOtherPrice: number
  tax: number
  taxClass: string
  category: SalesCategory | undefined
}
export type SalesSubSubjectMasterType = {
  id: string
  salesSubjectMasterId: string
  subSubjectCode: string
  name: string
  price: number
  salesSubjectMaster: SalesSubjectMasterType
}
