import React, { useEffect, useRef, useState } from 'react'
import { css } from '@emotion/core'

// components
import { Label } from '@/components/molecules/label'

type Props = {
  label?: string
  required?: boolean
  placeholder: string
  fieldWidth?: string
  width?: string
  marginBottom?: number
  name: string
  size?: number
  rows?: number
  handleChangeData: (e: any) => void
  value?: string | number
  autoSize?: boolean
  error?: string
  icon?: JSX.Element
}

export const TextareaField: React.FC<Props> = ({
  label,
  required = false,
  placeholder,
  fieldWidth = '100%',
  width = '100%',
  marginBottom = 16,
  name,
  rows,
  handleChangeData,
  value,
  autoSize,
  error,
  icon,
  ...props
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [textareaHeight, setTextareaHeight] = useState<string>()

  useEffect(() => {
    if (autoSize && textareaRef.current) {
      const borderPx = 2
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight + borderPx}px`
      setTextareaHeight(textareaRef.current.style.height)
    }
  }, [])

  return (
    <div style={{ marginBottom, width: fieldWidth, height: textareaHeight, position: 'relative' }} {...props}>
      {label && <Label label={label} required={required} />}
      <textarea
        name={name}
        value={value}
        rows={rows}
        style={{ width }}
        css={[textareaStyle, error ? { borderColor: 'red' } : {}]}
        placeholder={placeholder}
        onChange={handleChangeData}
        ref={textareaRef}
        className={icon ? 'has-icon' : ''}
      />
      {!!error && <div css={{ fontSize: 10, color: 'rgba(255,0,0,0.5) !important', marginTop: 4, position: 'absolute' }}>{error}</div>}
      {icon && <div css={iconStyle}>{icon}</div>}
    </div>
  )
}

const textareaStyle = css({
  border: '1px solid #CCCCCC',
  borderRadius: 7,
  padding: 16,
  fontSize: 14,
  marginBottom: 16,
  width: '100%',
  height: 88,
  resize: 'none',
  '::placeholder': {
    color: '#CCCCCC',
  },
  ':focus': {
    border: '2px solid #f2a40b',
    padding: 15,
  },
  '&.has-icon': {
    paddingRight: 32,
    ':focus': { paddingRight: 31 },
  },
})
const iconStyle = css({
  top: 16,
  right: 16,
  position: 'absolute',
})
