import React from 'react'
import { css } from '@emotion/core'
import { useHistory } from 'react-router-dom'

export type Props = {
  menus: {
    title: string
    path: string
    isActive: boolean
  }[]
}

export const ContainerSidePanelBody: React.FC<Props> = ({ menus, children }) => {
  const history = useHistory()

  return (
    <div css={containerBodyStyle}>
      <div className="left-space">
        {menus.map(menu => (
          <div
            key={menu.path}
            className={menu.isActive ? 'is-active' : ''}
            onClick={() => {
              if (!menu.isActive) {
                history.replace(menu.path)
              }
            }}
          >
            {menu.title}
          </div>
        ))}
      </div>
      <div className="right-space">{children}</div>
    </div>
  )
}
const containerBodyStyle = css({
  display: 'flex',
  overflow: 'hidden',
  // sub container header
  height: 'calc(100% - 75px)',
  '.left-space': {
    width: 180,
    cursor: 'pointer',
    borderRight: '1px solid #F2F2F2',
    '.is-active': {
      background: '#F2F2F2',
      cursor: 'auto',
    },
    '> div': {
      height: 44,
      borderBottom: '1px solid #F2F2F2',
      padding: '16px 24px',
      letterSpacing: 0.6,
      fontWeight: 'bold',
      fontSize: 12,
      color: '#676767',
    },
  },
  '.right-space': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
})
