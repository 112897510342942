import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { css } from '@emotion/core'
import { Button } from '@/components/atoms'
import { ButtonType } from '@/components/atoms/button'
import { CheckinReceipt } from './checkin-receipt'
import { useLocation } from 'react-router-dom'
import { SelfCheckinCompleteState } from '@/components/pages/self-checkin/complete'

type Props = {
  onClickTop: () => void
}
export const FrontSupportWithPrinter: React.FC<Props> = ({ onClickTop }) => {
  const { t } = useTranslation()
  const { state } = useLocation<SelfCheckinCompleteState>()

  return (
    <div css={componentBackGroundStyle}>
      <div css={componentStyle}>
        <div className="head-line-block">
          <img src={require('@/static/images/icon_checkin_orange.svg')}></img>
          <span>{t('Check-in completed')}</span>
        </div>
        <div className="announce-block">
          <div className="checkin-message-block">
            <div>
              {t('SelfCheckIn.Printed accommodation guide.')}
              <br />
              <Trans i18nKey="SelfCheckIn.Please take this at hand and hand it to the front desk.">
                Please take this at hand and hand it<span style={{ color: '#F2A40B' }}>to the front desk.</span>
              </Trans>
            </div>
            <Button buttonType={ButtonType.Primary} width={194} height={48} onClick={onClickTop}>
              {t('SelfCheckIn.Confirmed')}
            </Button>
          </div>
          <div className="printer-demo-block">
            <img src={require('@/static/images/self-checkin/checkin-printer-a.png')} />
            <img src={require('@/static/images/self-checkin/checkin-printer-b.png')} />
          </div>
        </div>
      </div>
      <CheckinReceipt checkinId={state.checkinId} hotelId={state.hotelId} payment={state.payment} />
    </div>
  )
}
const componentBackGroundStyle = css({
  backgroundColor: '#F2F2F2',
  padding: '0 60px',
  height: '100vh',
  alignContent: 'center',
})
const componentStyle = css({
  backgroundColor: '#FFFFFF',
  borderRadius: 10,
  whiteSpace: 'break-spaces',

  '.head-line-block': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 32,
    padding: '32px 0',
    borderBottom: '1px solid #CCCCCC',
    '> img': { width: 48 },
    '> span': {
      fontSize: 21,
      lineHeight: '21px',
      fontWeight: 'bold',
      letterSpacing: 1.05,
      color: '#F2A40B',
    },
  },
  '.announce-block': {
    display: 'flex',
    padding: 60,
    gap: 60,
    '.checkin-message-block': {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '>div': {
        font: 'normal normal bold 18px / 32px Noto Sans JP',
        letterSpacing: 2.7,
        color: '#272727',
        textAlign: 'center',
      },
      '> button': { marginTop: 32 },
    },
    '.printer-demo-block': {
      position: 'relative',
      width: '50%',
      minHeight: 374,
      display: 'flex',
      justifyContent: 'center',
      '> img': {
        position: 'absolute',
        width: 374,
        height: 374,
        opacity: 0,
        animation: 'image-switch-animation 10s infinite',
        '&:nth-of-type(1)': { animationDelay: '0s' },
        '&:nth-of-type(2)': { animationDelay: '5s' },
      },
    },
  },

  '@keyframes image-switch-animation': {
    '0%': { opacity: 0 },
    '5%': { opacity: 1 },
    '60%': { opacity: 1 },
    '65%': { opacity: 0 },
  },
})
