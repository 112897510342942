// models
import { StatusType } from '@/models/room-manager/response/guest-room-assign-status'

export const clickShowDetail = (status: StatusType) => {
  const reservationId = status.reservationId
  const guestRoomAssignId = status.guestRoomAssignId

  const url = new URL(window.location.href)

  // for normal bars
  if (reservationId) {
    url.searchParams.set('reservationId', reservationId)
    url.searchParams.delete('guestRoomAssignId')
  } else {
    url.searchParams.delete('reservationId')
  }

  // for bars in preparation
  if (status.status === 'preparation' && guestRoomAssignId) {
    url.searchParams.set('guestRoomAssignId', guestRoomAssignId)
  } else {
    url.searchParams.delete('guestRoomAssignId')
  }
  // Update URL
  window.history.replaceState('', '', url.search)
}
