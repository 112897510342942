import React, { useState, useEffect, useContext, useMemo } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { deleteSalesSubSubject, fetchSalesSubSubject } from '@/apis/aipass'
import { Button } from '@/components/atoms/button'
import { SettingSalesManagerTab } from '@/components/organisms/settings/sales-manager/tab'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { LoaderContextCreator } from '@/contexts/loader'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'
import { TableBody, TableHeader, TableRow } from '@/components/organisms/settings/sales-manager/table'
import { EditFooter } from '@/components/organisms/edit-footer'
import { SalesSubSubjectMasterType } from '@/models/sales-manager/sales'
import { SubSubjectDetailModal } from '@/components/organisms/settings/sales-manager/sub-subject-detail-modal'
import { useErrorHandler } from '@/hooks/use-error-handler'

type ContainerProps = {}

export const SettingSalesManagerSubSubject: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const [salesSubSubjects, setSalesSubSubjects] = useState<SalesSubSubjectMasterType[]>([])
  const [showDetailProp, setShowDetailProp] = useState<SalesSubSubjectMasterType | undefined | null>(undefined)
  const [deleteIds, setDeleteIds] = useState<string[]>([])
  const { errorHandler } = useErrorHandler()

  const initializeComponent = async (opt?: { noIndicator: boolean }) => {
    !opt?.noIndicator && setIsLoading(true)
    setDeleteIds([])
    const response = await fetchSalesSubSubject()
    setSalesSubSubjects(response ?? [])
    !opt?.noIndicator && setIsLoading(false)
  }

  const addDeleteTarget = (id: string) => {
    setSalesSubSubjects(salesSubSubjects.filter(detail => detail.id !== id))
    setDeleteIds([...deleteIds, id])
  }
  const doDelete = async () => {
    try {
      setIsLoading(true)
      if (deleteIds.length) {
        await deleteSalesSubSubject(deleteIds)
      }
      initializeComponent({ noIndicator: true })
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }
  const doDeleteCancel = () => {
    initializeComponent({ noIndicator: true })
  }

  const isShowFooter = useMemo(() => deleteIds.length > 0, [deleteIds])

  useEffect(() => {
    initializeComponent()
  }, [])

  const footerContent = isShowFooter ? <EditFooter onSave={doDelete} onCancel={doDeleteCancel} /> : undefined

  return (
    <SettingsLayout loading={false} footerContent={footerContent}>
      <TabContainer tabComponent={<SettingSalesManagerTab currentTab="Supplementary subjects" />} isShowFooter={isShowFooter}>
        <>
          <div css={containerHeaderStyle}>
            <Button
              buttonType={1}
              width={107}
              height={32}
              fontSize={12}
              onClick={() => setShowDetailProp(null)}
              icon={require('@/static/images/plus-white.svg')}
            >
              {t('Add new')}
            </Button>
          </div>
          <TableHeader>
            <div css={codeColStyle}>{t('Auxiliary course ID')}</div>
            <div css={nameColStyle}>{t('Auxiliary course name')}</div>
            <div css={subjectNameColStyle}>{t('Subject name')}</div>
            <div css={priceColStyle}>{t('Amount of money')}</div>
            <div css={deleteColStyle}></div>
          </TableHeader>
          <TableBody>
            {salesSubSubjects.map(detail => (
              <TableRow
                css={css({
                  '> div': { fontWeight: 500 },
                  minHeight: 49,
                })}
                key={detail.id}
                onClick={() => setShowDetailProp(detail)}
              >
                <div css={[codeColStyle, { marginLeft: 32 }]}>{detail.subSubjectCode}</div>
                <div css={nameColStyle}>{detail.name}</div>
                <div css={subjectNameColStyle}>{detail.salesSubjectMaster.name}</div>
                <div css={priceColStyle}>¥{detail.price?.toLocaleString()}</div>
                <div css={deleteColStyle}>
                  <DeleteIcon onClick={() => addDeleteTarget(detail.id)} />
                </div>
              </TableRow>
            ))}
          </TableBody>
          {showDetailProp !== undefined && (
            <SubSubjectDetailModal
              detail={showDetailProp}
              onSave={() => {
                initializeComponent()
                setShowDetailProp(undefined)
              }}
              onCancel={() => setShowDetailProp(undefined)}
            />
          )}
        </>
      </TabContainer>
    </SettingsLayout>
  )
}

const codeColStyle = css({
  marginLeft: 27,
  width: 200,
})
const nameColStyle = css({
  flexGrow: 1,
  overflowWrap: 'anywhere',
  width: 'min-content',
})
const subjectNameColStyle = css({ width: 343 })
const priceColStyle = css({ width: 73, paddingRight: 32 })
const deleteColStyle = css({
  width: 30,
  margin: '0 24px',
})

const containerHeaderStyle = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 0px 19px 32px',
  borderBottom: '1px solid #F2F2F2',
})
