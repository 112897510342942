import { isCleaningMemo, isFrontMemo, isMealMemo } from '@/models/reservation/reservation-note'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface CategoryLabelProps {
  category: string
}

export const CategoryLabel: React.FC<CategoryLabelProps> = ({ category }) => {
  const { t } = useTranslation()

  if (isFrontMemo(category)) {
    return (
      <>
        <img src={require('@/static/images/reservation-note/icon_front.svg')} alt="" />
        {t('reservationNote.front')}
      </>
    )
  }
  if (isCleaningMemo(category)) {
    return (
      <>
        <img src={require('@/static/images/reservation-note/icon_cleaning.svg')} alt="" />
        {t('reservationNote.cleaning')}
      </>
    )
  }
  if (isMealMemo(category)) {
    return (
      <>
        <img src={require('@/static/images/reservation-note/icon_meal.svg')} alt="" />
        {t('reservationNote.meal')}
      </>
    )
  }

  return <></>
}
