import React from 'react'
import { css } from '@emotion/core'
import { XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area, ReferenceLine } from 'recharts'
import { useTranslation } from 'react-i18next'

type DailyNpsProps = {
  tab: any
  dailyNps: any
}

export const DailyNps: React.FC<DailyNpsProps> = ({ tab, dailyNps }) => {
  const { i18n } = useTranslation()
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && i18n.language === 'ja') {
      return (
        <div css={customTooltipStyle}>
          <div css={customTooltipvaluesStyle}>
            <p css={customTooltipLabelStyle}>{`${payload[0]?.payload?.date}`}</p>
            <p css={customTooltipValueStyle}>
              NPS
              <span>{`${payload[0]?.value > 0 ? '+' : ''}${payload[0]?.value}`}</span>
            </p>
          </div>
        </div>
      )
    }
    if (active && i18n.language === 'en') {
      return (
        <div css={customTooltipStyle}>
          <div css={customTooltipvaluesStyle}>
            <p css={customTooltipLabelStyle}>{`${payload[0]?.payload?.date_en}`}</p>
            <p css={customTooltipValueStyle}>
              NPS
              <span>{`${payload[0]?.value > 0 ? '+' : ''}${payload[0]?.value}`}</span>
            </p>
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <>
      {tab === 'daily' && (
        <div css={dailyStyle}>
          <AreaChart
            width={300}
            height={200}
            data={dailyNps}
            margin={{
              top: 10,
              right: 20,
              left: -20,
              bottom: 30,
            }}
          >
            <CartesianGrid stroke="#ccc" horizontal={false} />
            <XAxis
              dataKey={i18n.language === 'ja' ? 'xlabel' : 'xlabel_en'}
              axisLine={{ stroke: '#ccc' }}
              tickLine={false}
              tick={{ stroke: '#ccc', fontSize: '10px', fontFamily: 'medium' }}
              tickMargin={10}
              interval={0}
            />
            <YAxis
              axisLine={{ stroke: '#ccc' }}
              tickLine={false}
              tick={{ stroke: '#ccc', fontSize: '10px' }}
              ticks={[100, 0, -100]}
              tickMargin={10}
            />
            <Tooltip content={<CustomTooltip />} />
            <ReferenceLine y={0} stroke="#ccc" />
            <Area
              type="monotone"
              dataKey="nps"
              stroke="#F2A40B"
              strokeWidth={2}
              fill="url(#splitColor)"
              fillOpacity={1}
              dot={{ stroke: '#F2A40B', fill: '#F2A40B', strokeWidth: 1, r: 2 }}
              activeDot={{ stroke: '#fff', fill: '#F2A40B', strokeWidth: 2, r: 5 }}
            />
            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0" stopColor="#FFDDCC" stopOpacity={1} />
                <stop offset="1" stopColor="#FFDDCC" stopOpacity={0} />
              </linearGradient>
            </defs>
          </AreaChart>
        </div>
      )}
    </>
  )
}

// dailyStyle
const dailyStyle = css({
  height: 165,
  '.recharts-wrapper': {
    height: 165,
    margin: '0 auto',
    '.recharts-surface': {},
  },
})

// customToolTipStyle
const customTooltipStyle = css({
  background: '#fff',
  borderRadius: 3,
  border: 'none',
  boxShadow: `0px 3px 6px rgba(0, 0, 0, 0.16)`,
  p: {
    padding: 0,
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 1.6,
    letterSpacing: 0.6,
  },
  '&+p': {
    marginTop: 8,
  },
})

const customTooltipvaluesStyle = css({
  display: 'inline-block',
  margin: 12,
})

const customTooltipLabelStyle = css({
  color: '#676767',
})

const customTooltipValueStyle = css({
  color: '#CCCCCC',
  span: {
    display: 'inline-block',
    marginLeft: 8,
    color: '#ff5500',
  },
})
