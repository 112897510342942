import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

//api
import { adjustSales, fetchAdvanceCompany } from '@/apis/aipass'

// models
import { AdjustmentPaymentForm } from '@/components/organisms/sales-manager/_sales/adjustment-payment-form'
import { NOT_ENOUGH_BALANCE_CODE } from '@/errors/company-advance-money-error'
type AdjustmentProps = {
  totalSalesPrice: number
  totalTax: number
  totalSalesPaymentPrice: number
  totalSalesDiscountPrice: number
  totalUnpaid: number
  totalSalesPayments: { companyId?: any; name: string; totalDiscount: number }[]
  currentTabReservationId?: string
  checkinId?: string
  setIsLoading: Dispatch<SetStateAction<boolean>>
  onAdjustSales: () => void
  onSendPos: () => void
}

export const Adjustment: React.FC<AdjustmentProps> = ({
  totalSalesPrice,
  totalTax,
  totalSalesPaymentPrice,
  totalSalesDiscountPrice,
  totalUnpaid,
  totalSalesPayments,
  currentTabReservationId,
  checkinId,
  setIsLoading,
  onAdjustSales,
  onSendPos,
}) => {
  const { t } = useTranslation()
  const [advanceCompany, setAdvanceCompany] = useState<any>({ paymentCompanies: [], paymentMethod: [] })

  useEffect(() => {
    fetchAdvanceCompany().then(res => {
      setAdvanceCompany(res)
    })
  }, [])

  const _onAdjustSales = async ({ salesPaymentId, accountsReceivableId, companyId }) => {
    setIsLoading(true)
    await adjustSales(salesPaymentId, checkinId, currentTabReservationId, companyId, accountsReceivableId)
      .then(() => {
        onAdjustSales()
      })
      .catch(error => {
        if (error?.response?.data?.errorCode === NOT_ENOUGH_BALANCE_CODE) {
          alert(t('Insufficient balance Please check again'))
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div css={salesRightItemStyle}>
      <table css={salesItemStyle}>
        <tbody>
          <tr>
            <td css={headStyle}>{t('Total sales')}</td>
            <td css={rightHeadStyle}>¥{Number(totalSalesPrice).toLocaleString()}</td>
          </tr>
          <tr>
            <td css={bodyStyle}>{t('Consumption tax')}</td>
            <td css={rightBodyStyle}>¥{Number(totalTax).toLocaleString()}</td>
          </tr>
          <tr>
            <td css={bodyStyle}>{t('Receipt total')}</td>
            <td css={rightBodyStyle}>¥{Number(totalSalesPaymentPrice).toLocaleString()}</td>
          </tr>
          <>
            {totalSalesPayments &&
              totalSalesPayments?.map((totalSalesPayment, index) => (
                <tr key={index}>
                  <td css={discountStyle}>
                    {totalSalesPayment.companyId
                      ? advanceCompany?.paymentCompanies?.find(v => v.id === totalSalesPayment.companyId)?.name
                      : totalSalesPayment.name}
                  </td>
                  <td css={rightBodyStyle}>¥{Number(totalSalesPayment.totalDiscount).toLocaleString()}</td>
                </tr>
              ))}
          </>
          <tr>
            <td css={[discountStyle, bottomStyle]}>{t('Discount')}</td>
            <td css={[rightBodyStyle, bottomStyle]}>¥{Number(totalSalesDiscountPrice).toLocaleString()}</td>
          </tr>
          <tr>
            <td css={totalUnpaid > 0 ? isUnpaidFootStyle : footStyle}>{t('Amount to be collected')}</td>
            <td css={totalUnpaid > 0 ? isUnpaidRightFootStyle : rightFootStyle}>
              {totalUnpaid < 0 ? '-' : ''}¥{Math.abs(totalUnpaid).toLocaleString()}
            </td>
          </tr>
        </tbody>
      </table>

      <AdjustmentPaymentForm
        checkinId={checkinId}
        reservationId={currentTabReservationId}
        onAdjustSales={_onAdjustSales}
        onClose={(isUpdated: boolean) => {
          isUpdated && onSendPos()
        }}
      />
    </div>
  )
}

const salesItemStyle = css({
  fontSize: 14,
  width: '100%',
})

const salesRightItemStyle = css(salesItemStyle, {
  width: '30%',
  minWidth: 256,
  padding: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 5,
  flexDirection: 'column',
  height: 'fit-content',
  marginLeft: 16,
})

const headStyle = css({
  width: '70%',
  color: '#272727',
  fontWeight: 'bold',
  paddingBottom: 16,
})

const bodyStyle = css({
  width: '70%',
  color: '#676767',
  margin: '16px 0',
  paddingBottom: 16,
})

const rightHeadStyle = css(headStyle, {
  textAlign: 'right',
})

const rightBodyStyle = css(bodyStyle, {
  textAlign: 'right',
})

const discountStyle = css(bodyStyle, {
  paddingLeft: 28,
})

const bottomStyle = css({
  paddingBottom: 24,
})

const footStyle = css({
  borderTop: '1px solid #F2F2F2',
  paddingTop: 24,
  fontWeight: 'bold',
})

const isUnpaidFootStyle = css(footStyle, {
  paddingTop: 24,
  fontWeight: 'bold',
})

const isUnpaidRightFootStyle = css(isUnpaidFootStyle, {
  textAlign: 'right',
  color: '#F2A40B',
})

const rightFootStyle = css(footStyle, {
  textAlign: 'right',
})
