export const capitalize = (str: string) => {
  if (typeof str !== 'string') return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

type LanguageType = 'ja' | 'en' | 'zh' | 'ko'

export const translateObject = <T>(object: T, key: Extract<keyof T, string>, language: LanguageType | string): string => {
  const langForUCC = language.substring(0, 1).toUpperCase() + language.substring(1)
  const keyByLang = `${key}${langForUCC}`
  return object[keyByLang] || object[key] || ''
}
