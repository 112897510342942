import React from 'react'
import moment from 'moment'

import { ApprovedStatus } from '@/models/reservation-approved-status'

// models
import { StatusType } from '@/models/room-manager/response/guest-room-assign-status'
import { DateType } from '@/components/pages/room-manager/_daily/daily-props'

import {
  barStyle,
  startBarStyle,
  betweenBarStyle,
  beginBarStyle,
  endBarStyle,
  dayUseBarStyle,
} from '@/components/pages/room-manager/_daily/_body/bar-layout'

type DailyListBodyProps = {
  statusData: StatusType
  statusIndex: number
  date: DateType
  beginDate: string
  endDate: string
}

export const AssignTag: React.FC<DailyListBodyProps> = ({ statusData, statusIndex, date, beginDate, endDate }) => {
  // const assignCheckinDays = moment(statusData.assignCheckinDate).format('YYYY-MM-DD')
  const assignCheckoutBeforeDays =
    statusData.changeAssignCheckoutDate &&
    moment(statusData.changeAssignCheckoutDate)
      .add(-1, 'days')
      .format('YYYY-MM-DD')
  // const assignCheckoutDays = moment(statusData.assignCheckoutDate).format('YYYY-MM-DD')
  const _assignCheckinDays = moment(statusData.changeAssignCheckinDate)
  const _assignCheckoutDays = moment(statusData.changeAssignCheckoutDate)
  const _days = moment(date.days).format('YYYY-MM-DD')
  const _beginDate = moment(beginDate)
  const _endDate = moment(endDate)

  const _statusColor = () => {
    let bgColor = ''

    switch (statusData.assignStatus) {
      case ApprovedStatus.Reservation:
        bgColor = '#F2A429'
        break
      case ApprovedStatus.Checkin:
        bgColor = '#F47110'
        break
      case ApprovedStatus.Stay:
        bgColor = '#3E85CC'
        break
      case ApprovedStatus.Checkout:
        bgColor = '#7DC931'
        break
      case 'preparation':
        bgColor = '#676767'
        break
      default:
        bgColor = '#F2A429'
    }

    return { background: bgColor, border: 'none' }
  }

  return (
    <React.Fragment key={`AssignTagCompoment${statusIndex}`}>
      {statusData.changeAssignCheckinDays &&
      statusData.changeAssignCheckoutDays &&
      statusData.changeAssignCheckinDays !== statusData.changeAssignCheckoutDays ? (
        statusData.changeAssignCheckinDays === assignCheckoutBeforeDays ? (
          /****************
           * 1 night 2 days
           ***************/
          // If the check-in date is before the start date, display the endTag on the start date
          _assignCheckinDays.isBefore(_beginDate) && _beginDate.isSame(_days, 'day') ? (
            <div css={endBarStyle} style={_statusColor()}></div>
          ) : (
            // Other than that, display the tag on the check-in date
            _assignCheckinDays.isSame(_days, 'day') && <div css={barStyle} style={_statusColor()}></div>
          )
        ) : (
          [
            /****************
             * 2 nights or more
             ***************/
            // If the check-in date is before the start date, at the position of the start date
            _assignCheckinDays.isBefore(_beginDate) &&
              _beginDate.isSame(_days, 'day') &&
              // Show endTag if checkout date
              (_beginDate.isSame(_assignCheckoutDays, 'day') ? (
                <div css={endBarStyle} style={_statusColor()} key={`AssignTagCompomentEndBarStyle${statusIndex}`}></div>
              ) : (
                // Display beginTag if not checkout date
                <div css={beginBarStyle} style={_statusColor()} key={`AssignTagCompomentBeginBarStyle${statusIndex}`}></div>
              )),
            // Start with check-in date
            statusData.changeAssignCheckinDays === date.days && (
              <div css={startBarStyle} style={_statusColor()} key={`AssignTagCompomentStartBarStyle${statusIndex}`}></div>
            ),
            // connect
            statusData.changeAssignCheckinDays < date.days &&
              moment(statusData.changeAssignCheckoutDate)
                .add(-1, 'days')
                .format('YYYY-MM-DD') > date.days && (
                <div css={betweenBarStyle} style={_statusColor()} key={`AssignTagCompomentBetweenBarStyle${statusIndex}`}></div>
              ),
            // Make the checkout date the end position
            statusData.changeAssignCheckoutDays == date.days && (
              <div css={endBarStyle} style={_statusColor()} key={`AssignTagCompomentEndBarStyle${statusIndex}`}></div>
            ),
            // If the check-out date is later than the end date and not the check-in date, display the beginTag on the end date
            _assignCheckoutDays.isAfter(_endDate, 'day') && _endDate.isSame(_days, 'day') && !_assignCheckinDays.isSame(_days, 'day') && (
              <div css={beginBarStyle} style={_statusColor()} key={`AssignTagCompomentBeginBarStyle2${statusIndex}`}></div>
            ),
          ]
        )
      ) : (
        /****************
         * day use
         ***************/
        statusData.changeAssignCheckinDays === date.days && (
          <div css={dayUseBarStyle} style={_statusColor()} key={`AssignTagCompomentdayUseBarStyle${statusIndex}`}></div>
        )
      )}
    </React.Fragment>
  )
}
