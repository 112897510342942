import { SalesType } from '@/models/sales'

export const reduceTaxAmount = (sales: SalesType[]): number => {
  const salesByTaxRate: { [key: number]: number } = {}
  sales
    .filter(sale => sale.salesPrice && sale.salesSubjectTax)
    .forEach(sale => (salesByTaxRate[sale.salesSubjectTax] = sale.salesPrice + (salesByTaxRate[sale.salesSubjectTax] || 0)))
  return Object.entries(salesByTaxRate)
    .map(([taxRate, price]) => {
      const taxPrice = (price / (100 + Number(taxRate))) * Number(taxRate)
      return taxPrice > 0 ? Math.floor(taxPrice) : Math.ceil(taxPrice)
    })
    .reduce((prev, current) => prev + current, 0)
}
