import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Controller, useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'
import { InputField } from '@/components/molecules/input-field'
import { createSalesDepartment, updateSalesDepartment } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { SalesDepartmentMasterType } from '@/models/sales-manager/sales'
import { LoaderContextCreator } from '@/contexts/loader'

type Props = {
  detail: SalesDepartmentMasterType | null
  onCancel: () => void
  onSave: () => void
}

export type FormValue = {
  departmentCode: string
  departmentName: string
}

export const DepartmentDetailModal: React.FC<Props> = ({ detail, onCancel, onSave: parentSave }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValue>({
    defaultValues: {
      departmentCode: detail?.departmentCode || '',
      departmentName: detail?.departmentName || '',
    },
  })
  const { errorHandler } = useErrorHandler()
  const { setIsLoading } = useContext(LoaderContextCreator())

  const onSave = async (value: FormValue) => {
    try {
      setIsLoading(true)
      if (detail) {
        await updateSalesDepartment(detail.id, value)
      } else {
        await createSalesDepartment(value)
      }
      parentSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal customCss={modalStyle}>
        <ModalHeader>{t(detail ? 'Edit department' : 'Add a department')}</ModalHeader>
        <ModalBody>
          <div css={modalBodyStyle}>
            <div css={inputTextStyle}>
              {t('Department ID')}
              <div css={requireLabelTextStyle}>※</div>
            </div>
            <Controller
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              name="departmentCode"
              render={({ field: { onChange, value } }) => (
                <InputField
                  value={value}
                  handleChangeData={onChange}
                  error={errors.departmentName?.message}
                  placeholder="1010"
                  marginBottom={24}
                  />
              )}
            />

            <div css={inputTextStyle}>
              {t('Department name')}
              <div css={requireLabelTextStyle}>※</div>
            </div>
            <Controller
              control={control}
              rules={{ required: t('Required field has not been entered') }}
              name="departmentName"
              render={({ field: { onChange, value } }) => (
                <InputField
                  value={value}
                  handleChangeData={onChange}
                  error={errors.departmentCode?.message}
                  placeholder={t('Department name')}
                  marginBottom={24}
                  />
              )}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(onSave)}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const modalStyle = {
  minHeight: 534,
  maxHeight: 534,
  width: 600,
  minWidth: 600,
  left: 'calc((100% - 600px) / 2)',
}

const modalBodyStyle = css({
  padding: 32,
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const inputTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})
