import React from 'react'
import { useTranslation } from 'react-i18next'
import '@/static/styles/mobiscroll.react.min.css'
import '@/static/styles/mobiscroll-custom.css'

// libs
import mobiscroll from '@/libs/mobiscroll'

type DatePickerProps = {
  value?: string
  placeholder?: string
  error?: boolean
  size?: 'big'
  onChange: (value: string) => void
}

export const DatePicker: React.FC<DatePickerProps> = ({ value = '', placeholder, error = null, size = '', onChange = () => {} }) => {
  const { t } = useTranslation()

  mobiscroll.settings = {
    dateFormat: 'yy/mm/dd',
    dateWheels: 'yy mm dd',
    theme: 'mobiscroll',
    themeVariant: 'light',
    width: '505',
  }

  return (
    <>
      <mobiscroll.Date
        value={value}
        defaultValue={value || '1985/01/01'}
        touchUi={false}
        onChange={event => onChange(event.valueText)}
        display="bubble">
        <mobiscroll.Input
          className={error ? `error ${size}` : `${size}`}
          placeholder={placeholder || t('Please select')}
          inputStyle="box"
        />
      </mobiscroll.Date>
    </>
  )
}
