import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { DatePicker } from '@/components/pages/room-manager/_room-manager/date-picker'
import { css } from '@emotion/core'

import { Button } from '@/components/atoms/button'

// models
import { GuestRoomAssignStatusResponseType, StatusType } from '@/models/room-manager/response/guest-room-assign-status'
import { GuestRoomAssignResponseType } from '@/models/room-manager/response/guest-room-assign'
import { AdminReservationResponseType } from '@/models/reservation/admin-reservation'
import moment from 'moment'

type RoomSummaryBarProps = {
  reservation: AdminReservationResponseType['reservations'] | undefined
  guestRooms: GuestRoomAssignStatusResponseType['guestRoomAssignStatusList'] | undefined
  showAssignPanel: boolean
  assignPanelEditMode: boolean
  roomManagerTopSelectDate: moment.Moment
  order: 'room' | 'floor'
  hiddenRoomNameList: string[]
  setRoomManagerTopSelectDate: React.Dispatch<React.SetStateAction<moment.Moment>>
  assignPanel: () => React.ReactChild
  onClickUnattachedStatus: (status: StatusType) => void
  editGuestRooms: GuestRoomAssignResponseType['rooms']
  setAutomaticAssignmentReservationsIds: (reservationsIds: string[]) => void
}

export const RoomSummaryBar: React.FC<RoomSummaryBarProps> = ({
  reservation,
  guestRooms,
  showAssignPanel,
  assignPanelEditMode,
  roomManagerTopSelectDate,
  order,
  hiddenRoomNameList,
  setRoomManagerTopSelectDate,
  assignPanel,
  onClickUnattachedStatus,
  editGuestRooms,
  setAutomaticAssignmentReservationsIds,
}) => {
  const { t } = useTranslation()
  const [unattachedEditStatuses, setUnattachedEditStatuses] = useState({})
  const [unattachedGuestRoomsByType, setUnattachedGuestRoomsByType] = useState<any[]>([])
  const canBeAutoAssignment =
    unattachedGuestRoomsByType?.length && Object.values(unattachedGuestRoomsByType).some(roomType => roomType?.statuses?.length)

  const goToPrevDate = () => {
    setRoomManagerTopSelectDate(moment(roomManagerTopSelectDate).subtract(1, 'days'))
  }
  const goToNextDate = () => {
    setRoomManagerTopSelectDate(moment(roomManagerTopSelectDate).add(1, 'days'))
  }

  const getUnattachedStatuses = guestRoom => {
    const isFullByEdit = status => {
      if (editGuestRooms?.[0]?.reservationId === status.reservationId) {
        return editGuestRooms.every(r => r.roomNumber && r.roomNumber !== '指定なし')
      }
    }
    return (guestRoom['指定なし']?.statuses || []).filter(
      s =>
        s.guestRoomAssignId &&
        (moment(roomManagerTopSelectDate).isBetween(s.assignCheckinDays, s.assignCheckoutDays, 'date', '[)') ||
          moment(roomManagerTopSelectDate).isSame(s.assignCheckinDays, 'date')) &&
        !isFullByEdit(s),
    )
  }

  const onClickAutoAssignment = () => {
    setAutomaticAssignmentReservationsIds(
      unattachedGuestRoomsByType.reduce((sum, roomType) => [...sum, ...roomType.statuses.map(status => status.reservationId)], []),
    )
  }

  useEffect(() => {
    if (!guestRooms) return setUnattachedGuestRoomsByType([])

    setUnattachedGuestRoomsByType(
      Object.keys(guestRooms).map(roomType => ({
        type: roomType,
        statuses: getUnattachedStatuses(guestRooms[roomType]),
      })),
    )
  }, [guestRooms])

  //   useEffect(() => {
  //     const unattachedEditGuestRooms = editGuestRooms.filter(r => !r.roomNumber || r.roomNumber === '指定なし')
  //     const unattachedEditStatuses = unattachedEditGuestRooms.reduce((sum, r: any) => {
  //       if (!r.typeNameJa) return sum
  //       if (!sum[r.typeNameJa]) {
  //         sum[r.typeNameJa] = []
  //       }
  //       sum[r.typeNameJa].push({
  //         guestRoomAssignId: r.guestRoomAssignId,
  //         checkinDate: r.assignCheckinDate,
  //         checkoutDate: r.assignCheckoutDate,
  //         assignCheckinDays: r.assignCheckinDate,
  //         assignCheckoutDays: r.assignCheckoutDate,
  //         reservationId: r.reservationId,
  //         reservationGuestName: r.reservationGuestName,
  //       })

  //       return sum
  //     }, {})

  //     setUnattachedEditStatuses(unattachedEditStatuses)
  //   }, [editGuestRooms])

  useEffect(() => {
    const onScrollRooms = e => document.getElementById('unattachedRoomsContainer')?.scrollTo(0, e.target?.scrollTop)
    const onScrollUnattachedRooms = e => document.getElementById('roomsContainer')?.scrollTo(0, e.target?.scrollTop)

    document.getElementById('roomsContainer')?.addEventListener('scroll', onScrollRooms)
    document.getElementById('unattachedRoomsContainer')?.addEventListener('scroll', onScrollUnattachedRooms)

    return () => {
      document.getElementById('roomsContainer')?.removeEventListener('scroll', onScrollRooms)
      document.getElementById('unattachedRoomsContainer')?.removeEventListener('scroll', onScrollUnattachedRooms)
    }
  }, [])

  return (
    <div css={{ position: 'absolute', right: 0, top: 0, bottom: 0, paddingRight: 24, paddingTop: 24, zIndex: 13 }}>
      <div css={{ display: 'flex', height: '100%', boxShadow: '0px 0px 6px #00000029', backgroundColor: '#fff' }}>
        <div css={{ height: '100%', width: 194, display: 'flex', flexDirection: 'column' }}>
          <div css={datePickerContainer}>
            <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={require(`@/static/images/arrow_left_gray.svg`)} css={{ width: 36, cursor: 'pointer' }} onClick={goToPrevDate} />
              <DatePicker
                date={roomManagerTopSelectDate}
                setDate={setRoomManagerTopSelectDate}
                type={'select'}
                setRoomManagerTopSelectDate={setRoomManagerTopSelectDate}
                readOnly={true}
              />
              <img src={require(`@/static/images/arrow_right_gray.svg`)} css={{ width: 36, cursor: 'pointer' }} onClick={goToNextDate} />
            </div>
            {!!canBeAutoAssignment && (
              <div>
                <Button
                  width={120}
                  height={24}
                  buttonType={showAssignPanel ? 5 : 2}
                  fontSize={12}
                  onClick={onClickAutoAssignment}
                  isDisabled={showAssignPanel}
                >
                  {t('Automatic assignment')}
                </Button>
              </div>
            )}
          </div>
          <div css={{ paddingBottom: 8, overflowX: 'hidden', overflowY: 'auto', flex: 1 }} id="unattachedRoomsContainer">
            {guestRooms &&
              unattachedGuestRoomsByType.map(item => {
                const roomType = item.type
                const unattachedStatuses = item.statuses

                // if (assignPanelEditMode && unattachedEditStatuses[roomType]) {
                //     unattachedStatuses.push(...unattachedEditStatuses[roomType].map(s => ({ ...s, isEditingNow: true })))
                // }

                return (
                  <div key={roomType}>
                    {order !== 'floor' && (
                      <div
                        css={{
                          height: 48,
                          display: 'flex',
                          padding: '0 16px',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          background: '#FAFAFA',
                          marginTop: '-1px',
                          borderTop: '1px solid #E3E3E3',
                          borderBottom: '1px solid #E3E3E3',
                        }}
                      >
                        <div
                          css={{
                            fontSize: 12,
                            lineHeight: '15px',
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {roomType}
                        </div>
                        {!!unattachedStatuses.length && (
                          <div
                            css={{
                              minWidth: 18,
                              height: 18,
                              padding: '0 3px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: 10,
                              fontWeight: 'bold',
                              backgroundColor: '#f2a40b',
                              borderRadius: 9,
                              color: '#fff',
                            }}
                          >
                            {unattachedStatuses.length}
                          </div>
                        )}
                      </div>
                    )}
                    {!hiddenRoomNameList.includes(roomType) && (
                      <div
                        css={{
                          height: order !== 'floor' ? Math.max(Object.keys(guestRooms[roomType] || {}).length - 1, 0) * 42 : '100%',
                          overflowY: 'auto',
                        }}
                      >
                        {unattachedStatuses.map((s: any) => {
                          const isSelected = reservation?.reservationId === s.reservationId || s.isEditingNow
                          return (
                            <div
                              key={s.guestRoomAssignId}
                              css={{
                                height: 42,
                                padding: '0 16px',
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: '1px solid #F2F2F2',
                                fontSize: 12,
                              }}
                            >
                              <div
                                css={{
                                  borderRadius: 14,
                                  padding: '0 16px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  height: 28,
                                  cursor: !isSelected ? 'pointer' : undefined,
                                  border: !isSelected ? '1px solid #ccc' : undefined,
                                  color: isSelected ? '#fff' : undefined,
                                  backgroundColor: isSelected ? '#676767' : undefined,
                                }}
                                onClick={() => onClickUnattachedStatus(s)}
                              >
                                <div css={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                  {s.reservationGuestName || '-'}
                                </div>
                                <div css={{ whiteSpace: 'nowrap' }}>
                                  {s.checkinDate && s.checkoutDate ? dayjs(s.checkoutDate).diff(s.checkinDate, 'day') : '-'}
                                  {t('Night')}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
        {showAssignPanel && <div css={{ width: 270, height: '100%', borderLeft: '1px solid #F2F2F2' }}>{assignPanel()}</div>}
      </div>
    </div>
  )
}

const datePickerContainer = css({
  padding: '28px 24px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  // Add RemainingRoomTotal height(29px)
  height: 'calc(90px + 29px)',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: '1px solid #E3E3E3',
  '.DateInput_input': {
    fontWeight: 'bold',
  },
  '.SingleDatePickerInput': {
    border: 'none',
    textAlign: 'center',
    width: 130,
    '.SingleDatePickerInput_calendarIcon': { display: 'none' },
  },
  '.SingleDatePicker_picker.SingleDatePicker_picker__directionLeft': {
    top: '32px !important',
    left: '-150px !important',
  },
})
