import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'
import { AssetType, FormatType, RequestDetailType } from '@/models/guest-app/asset'
import { ContainerHeader } from '@/components/molecules/settings/container-header'
import { ContainerSidePanelBody, Props as SidePanelProps } from '@/components/molecules/settings/container-side-panel-body'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { deleteAssetDetail, updateAsset, updateAssetDetail, updateAssetDetailDisplayOrder } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { RequestProductDetailModal } from './request-product-detail-modal'
import { useDraggableDom } from '@/components/molecules/settings/use-draggable-dom'
import { CategoryType, SalesHourType, TagType } from '@/models/mobile-request'
import { GuestAppTableHeader, GuestAppTableBody, GuestAppTableRow } from '@/components/atoms/settings/guest-app-table'

type Props = {
  asset: AssetType & {
    details: RequestDetailType[]
    categories: CategoryType[]
    tags: TagType[]
    salesHours: SalesHourType[]
  }
}

export const RequestAssetList: React.FC<Props & SidePanelProps> = ({ asset, menus }) => {
  const { t } = useTranslation()
  const [formValue, setFormValue] = useState<{ title: string; details: RequestDetailType[] }>({
    title: asset.title,
    details: asset.details,
  })
  const [deleteIds, setDeleteIds] = useState<string[]>([])
  const [showDetailProp, setShowDetailProp] = useState<RequestDetailType | undefined | null>(undefined)
  const { doReload, setIsLoading, setIsEditing, listenDoSave, listenDoCancel } = useContext(AssetDetailContext)
  const { errorHandler } = useErrorHandler()
  const addDeleteTarget = (id: string) => {
    setFormValue({ ...asset, details: formValue.details.filter(detail => detail.id !== id) })
    setDeleteIds([...deleteIds, id])
    setIsEditing(true)
  }
  const changeDisplayOrder = (newList: RequestDetailType[]) => {
    setFormValue({ ...asset, details: newList })
    setIsEditing(true)
  }
  const { dragStart, dragOver, dragDrop } = useDraggableDom({ list: formValue.details, onChange: changeDisplayOrder })

  const saveFormValue = async () => {
    try {
      setIsLoading(true)
      if (deleteIds.length) {
        await deleteAssetDetail(asset.id, deleteIds)
        setDeleteIds([])
      }
      if (formValue.details.length) {
        await updateAssetDetailDisplayOrder(
          asset.id,
          formValue.details.map(detail => detail.id),
        )
      }
      await updateAsset(asset.id, { title: formValue.title })
      doReload()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const doToggleFavorite = async (assetId: string, detail: RequestDetailType) => {
    try {
      setIsLoading(true)
      await updateAssetDetail(assetId, detail.id, FormatType.Request, { isFavorite: !detail.isFavorite })
      doReload()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (listenDoSave) saveFormValue()
  }, [listenDoSave])

  useEffect(() => {
    if (listenDoCancel) {
      setDeleteIds([])
      doReload()
    }
  }, [listenDoCancel])

  useEffect(() => {
    if (!asset) {
      return
    }
    setFormValue({
      title: asset.title,
      details: asset.details,
    })
  }, [asset])

  return (
    <>
      <ContainerHeader
        goBack
        title={formValue.title}
        onChangeTitle={(newTitle: string) => {
          setFormValue({ ...formValue, title: newTitle })
          setIsEditing(true)
        }}
        onClickAddButton={() => setShowDetailProp(null)}
      />
      <ContainerSidePanelBody menus={menus}>
        <>
          <GuestAppTableHeader>
            <div css={favoriteColStyle}></div>
            <div css={thumbnailColStyle}></div>
            <div css={titleColStyle}>{t('Title')}</div>
            <div css={categoryColStyle}>{t('Category')}</div>
            <div css={tagColStyle}>{t('Tag')}</div>
            <div css={salesHourColStyle}>{t('MobileRequest.Sales hours')}</div>
            <div css={amountColStyle}>{t('Amount')}</div>
            <div css={stockColStyle}>{t('Stock')}</div>
            <div css={deleteColStyle}></div>
          </GuestAppTableHeader>
          <GuestAppTableBody>
            {formValue.details.map(detail => (
              <GuestAppTableRow
                id={detail.id}
                key={detail.id}
                onClick={() => setShowDetailProp(detail)}
                draggable="true"
                onDragStart={dragStart}
                onDragOver={dragOver}
                onDrop={dragDrop}
              >
                <div
                  css={favoriteColStyle}
                  onClick={e => {
                    e.stopPropagation()
                    doToggleFavorite(asset.id, detail)
                  }}
                >
                  {detail.isFavorite ? (
                    <img src={require('@/static/images/icon_star_f.svg')}></img>
                  ) : (
                    <img src={require('@/static/images/icon_star_n.svg')}></img>
                  )}
                </div>

                <div css={thumbnailColStyle}>
                  <img src={detail.imagePath}></img>
                </div>
                <div css={titleColStyle}>{detail.name}</div>
                <div css={categoryColStyle}>{detail.category?.name.ja}</div>
                <div css={tagColStyle}>
                  {detail.tags.map(tag => (
                    <span key={tag.id}>
                      <span>{tag.name.ja}</span>
                    </span>
                  ))}
                </div>
                <div css={salesHourColStyle}>{detail.salesHourName}</div>
                <div css={amountColStyle}>¥{detail.price.toLocaleString()}</div>
                <div css={stockColStyle}>{detail.stock}</div>
                <div css={deleteColStyle}>
                  <DeleteIcon onClick={() => addDeleteTarget(detail.id)} />
                </div>
              </GuestAppTableRow>
            ))}
          </GuestAppTableBody>
        </>
      </ContainerSidePanelBody>
      {showDetailProp !== undefined && (
        <RequestProductDetailModal
          asset={asset}
          detail={showDetailProp}
          onCancel={() => setShowDetailProp(undefined)}
          onSave={() => setShowDetailProp(undefined)}
        />
      )}
    </>
  )
}

const favoriteColStyle = css({
  marginLeft: 62,
  width: 18,
})
const thumbnailColStyle = css({
  marginLeft: 16,
  marginRight: 24,
  width: 'calc(38px * 16 / 9)',
  '> img': {
    height: 38,
    borderRadius: 5,
  },
})
const titleColStyle = css({
  flexGrow: 1,
  width: 'min-content',
})
const categoryColStyle = css({ width: 100 })
const tagColStyle = css({
  width: 180,
  '> span': {
    fontSize: 12,
    color: 'rgb(103, 103, 103)',
    letterSpacing: 1.2,
    fontWeight: 'bold',
    borderRadius: 12,
    backgroundColor: 'rgb(242, 242, 242)',
    margin: '3px 8px 3px 0px',
    padding: 6,
    maxWidth: 84,
    display: 'inline-block',
    '>span': {
      overflow: 'hidden',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
    },
  },
})
const salesHourColStyle = css({ width: 100 })
const amountColStyle = css({ width: 80 })
const stockColStyle = css({ width: 40 })
const deleteColStyle = css({
  width: 30,
  margin: '0 16px',
})
