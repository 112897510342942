import React, { useState, useContext, useEffect } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { CheckinTabContainer } from '@/components/organisms/settings/checkin-tab-container'
import { listContainerStyle } from '@/constants/layout'
import { putSelfCheckinSetting, fetchSelfCheckinSetting } from '@/apis/aipass'
import { AccountContext } from '@/contexts/account'
import { QuestionMark } from '@/components/atoms/question-mark'

export const SelfCheckin: React.FC = () => {
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [customInput, setCustomInput] = useState({
    requiredIdentify: true,
    requiredForeignIdentify: true,
    requiredAccompanyNameInput: false,
    requiredFuriganaInput: true,
    requiredBirthDateInput: true,
    requiredGenderInput: true,
    requiredTelephoneInput: true,
    requiredEmailInput: false,
    requiredAddressInput: true,
  })

  const changeCustomInput = async (props: { inputName: string; changeValue: boolean }) => {
    setIsLoading(true)
    setCustomInput({ ...customInput, [props.inputName]: props.changeValue })
    await putSelfCheckinSetting({ [props.inputName]: props.changeValue })
    setIsLoading(false)
  }

  const _fetchSelfCheckinSetting = async () => {
    if (account) {
      setIsLoading(true)
      const res = await fetchSelfCheckinSetting(account.hotel.id)
      if (res) {
        setCustomInput(res)
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    _fetchSelfCheckinSetting()
  }, [account])

  return (
    <>
      <SettingsLayout loading={isLoading}>
        <>
          <CheckinTabContainer currentTab="Self check-in" />
          <div css={topImageContainerStyle}>
            <div css={infoHeaderContainerStyle}>
              <div css={infoHeaderTitleStyle}>{t('Identity verification')}</div>
            </div>
            <div css={mainContainerStyle}>
              <div css={facilityRecommendStyle}>
                <div css={facilityInfoListContainerStyle}>
                  <div css={facilityInfoListNameStyle}>{t('Japanese nationality')}</div>
                  <div css={facilityInfoListRightContainerStyle}>
                    <input
                      name="required_identify"
                      type="checkbox"
                      id="required_identify"
                      css={facilityCheckboxStyle}
                      checked={!!customInput.requiredIdentify}
                      onChange={() => changeCustomInput({ inputName: 'requiredIdentify', changeValue: !customInput.requiredIdentify })}
                    />
                    <label htmlFor={'required_identify'} css={facilityCheckboxLabelStyle} />
                  </div>
                </div>
                <div css={facilityInfoListContainerStyle}>
                  <div css={facilityInfoListNameStyle}>{t('Foreign nationality')}</div>
                  <div css={facilityInfoListRightContainerStyle}>
                    <input
                      name="required_foreign_identify"
                      type="checkbox"
                      id="required_foreign_identify"
                      css={facilityCheckboxStyle}
                      checked={!!customInput.requiredForeignIdentify}
                      onChange={() =>
                        changeCustomInput({ inputName: 'requiredForeignIdentify', changeValue: !customInput.requiredForeignIdentify })
                      }
                    />
                    <label htmlFor={'required_foreign_identify'} css={facilityCheckboxLabelStyle} />
                  </div>
                </div>
              </div>
            </div>
            <div css={infoHeaderContainerStyle}>
              <div css={infoHeaderTitleStyle}>{t('Accompany name')}</div>
            </div>
            <div css={mainContainerStyle}>
              <div css={facilityRecommendStyle}>
                <div css={facilityInfoListContainerStyle}>
                  <div css={facilityInfoListNameStyle}>{t('Accompany name input')}</div>
                  <div css={facilityInfoListRightContainerStyle}>
                    <input
                      name="required_accompany_name_input"
                      type="checkbox"
                      id="required_accompany_name_input"
                      css={facilityIsRequiredCheckboxStyle}
                      checked={!!customInput.requiredAccompanyNameInput}
                      onChange={() =>
                        changeCustomInput({ inputName: 'requiredAccompanyNameInput', changeValue: !customInput.requiredAccompanyNameInput })
                      }
                    />
                    <label htmlFor={'required_accompany_name_input'} css={facilityIsRequiredCheckboxLabelStyle} />
                  </div>
                </div>
              </div>
            </div>

            <div css={infoHeaderContainerStyle}>
              <div css={[infoHeaderTitleStyle, { display: 'flex', alignItems: 'center', gap: 8 }]}>
                {t('Input items')}
                <div css={hintStyle}>
                  <QuestionMark />
                  <p
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 16,
                      backgroundColor: '#fff',
                      boxShadow: '0px 0px 6px #0000001A',
                      borderRadius: 5,
                      position: 'absolute',
                      top: -6,
                      bottom: 0,
                      left: 23,
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                    }}
                  >
                    {t('Name, address, and contact information are required by the Ryokan Business Law')}
                  </p>
                </div>
              </div>
            </div>
            <div css={mainContainerStyle}>
              <div css={facilityRecommendStyle}>
                <div css={facilityInfoListContainerStyle}>
                  <div css={facilityInfoListNameStyle}>{t('Furigana (for Japanese nationals)')}</div>
                  <div css={facilityInfoListRightContainerStyle}>
                    <input
                      name="required_furigana_input"
                      type="checkbox"
                      id="required_furigana_input"
                      css={facilityIsRequiredCheckboxStyle}
                      checked={!!customInput.requiredFuriganaInput}
                      onChange={() => changeCustomInput({ inputName: 'requiredFuriganaInput', changeValue: !customInput.requiredFuriganaInput })}
                    />
                    <label htmlFor={'required_furigana_input'} css={facilityIsRequiredCheckboxLabelStyle} />
                  </div>
                </div>

                <div css={facilityInfoListContainerStyle}>
                  <div css={facilityInfoListNameStyle}>{t('Date of birth')}</div>
                  <div css={facilityInfoListRightContainerStyle}>
                    <input
                      name="required_birth_date_input"
                      type="checkbox"
                      id="required_birth_date_input"
                      css={facilityIsRequiredCheckboxStyle}
                      checked={!!customInput.requiredBirthDateInput}
                      onChange={() =>
                        changeCustomInput({ inputName: 'requiredBirthDateInput', changeValue: !customInput.requiredBirthDateInput })
                      }
                    />
                    <label htmlFor={'required_birth_date_input'} css={facilityIsRequiredCheckboxLabelStyle} />
                  </div>
                </div>

                <div css={facilityInfoListContainerStyle}>
                  <div css={facilityInfoListNameStyle}>{t('Sex')}</div>
                  <div css={facilityInfoListRightContainerStyle}>
                    <input
                      name="required_gender_input"
                      type="checkbox"
                      id="required_gender_input"
                      css={facilityIsRequiredCheckboxStyle}
                      checked={!!customInput.requiredGenderInput}
                      onChange={() => changeCustomInput({ inputName: 'requiredGenderInput', changeValue: !customInput.requiredGenderInput })}
                    />
                    <label htmlFor={'required_gender_input'} css={facilityIsRequiredCheckboxLabelStyle} />
                  </div>
                </div>

                <div css={facilityInfoListContainerStyle}>
                  <div css={facilityInfoListNameStyle}>{t('Phone number')}</div>
                  <div css={facilityInfoListRightContainerStyle}>
                    <input
                      name="required_telephone_input"
                      type="checkbox"
                      id="required_telephone_input"
                      css={facilityIsRequiredCheckboxStyle}
                      checked={!!customInput.requiredTelephoneInput}
                      onChange={() =>
                        changeCustomInput({ inputName: 'requiredTelephoneInput', changeValue: !customInput.requiredTelephoneInput })
                      }
                    />
                    <label htmlFor={'required_telephone_input'} css={facilityIsRequiredCheckboxLabelStyle} />
                  </div>
                </div>

                <div css={facilityInfoListContainerStyle}>
                  <div css={facilityInfoListNameStyle}>{t('Email address')}</div>
                  <div css={facilityInfoListRightContainerStyle}>
                    <input
                      name="required_email_input"
                      type="checkbox"
                      id="required_email_input"
                      css={facilityIsRequiredCheckboxStyle}
                      checked={!!customInput.requiredEmailInput}
                      onChange={() => changeCustomInput({ inputName: 'requiredEmailInput', changeValue: !customInput.requiredEmailInput })}
                    />
                    <label htmlFor={'required_email_input'} css={facilityIsRequiredCheckboxLabelStyle} />
                  </div>
                </div>

                <div css={facilityInfoListContainerStyle}>
                  <div css={facilityInfoListNameStyle}>{t('Address')}</div>
                  <div css={facilityInfoListRightContainerStyle}>
                    <input
                      name="required_address_input"
                      type="checkbox"
                      id="required_address_input"
                      css={facilityIsRequiredCheckboxStyle}
                      checked={!!customInput.requiredAddressInput}
                      onChange={() => changeCustomInput({ inputName: 'requiredAddressInput', changeValue: !customInput.requiredAddressInput })}
                    />
                    <label htmlFor={'required_address_input'} css={facilityIsRequiredCheckboxLabelStyle} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </SettingsLayout>
    </>
  )
}

const topImageContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 'auto',
  marginBottom: -10,
})

const mainContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  color: '#272727',
  width: '100%',
  flexWrap: 'wrap',
})

const facilityRecommendStyle = css({
  width: '100%',
})

const facilityInfoListContainerStyle = css({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 64,
  borderBottom: '1px solid #F2F2F2',
  padding: '0 32px',
})

const facilityInfoListNameStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  width: '100%',
  padding: '25px 0px',
  height: '100%',
  letterSpacing: '1.4px',
})

const facilityInfoListRightContainerStyle = css({
  display: 'flex',
  img: {
    display: 'block',
    marginLeft: 27,
    padding: 4,
    ':hover': {
      borderRadius: '50%',
      background: '#F2F2F2',
      cursor: 'pointer',
    },
  },
})

export const facilityIsRequiredCheckboxStyle = css({
  display: 'none',
  ':checked': {
    '+label': {
      backgroundColor: '#F2A40B',
      border: 'none',
      position: 'relative',

      '&::before': {
        content: "'必須'",
        color: '#fff',
        fontSize: 12,
        letterSpacing: 1.2,
        left: 15,
        position: 'absolute',
      },
      '&::after': {
        top: 3,
        left: 47,
        backgroundColor: '#fff',
      },
    },
  },
})

export const facilityIsRequiredCheckboxLabelStyle = css({
  width: 73,
  height: 28,
  background: '#F2F2F2',
  position: 'relative',
  display: 'inline-block',
  borderRadius: 14,
  transition: '0.4s',
  boxSizing: 'border-box',
  border: '1px solid #ccc',
  cursor: 'pointer',

  '&::before': {
    content: "'任意'",
    fontWeight: 'bold',
    color: '#a3a3a3',
    fontSize: 11,
    letterSpacing: 0.55,
    left: 30,
    position: 'absolute',
    top: 7,
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    width: 22,
    height: 22,
    borderRadius: '100%',
    top: 2,
    left: 4,
    zIndex: 2,
    background: '#fff',
    transition: '0.4s',
    boxShadow: '0px 2px 3px #00000029',
  },
})

export const facilityCheckboxStyle = css({
  display: 'none',
  ':checked': {
    '+label': {
      backgroundColor: '#F2A40B',
      border: 'none',
      position: 'relative',

      '&::before': {
        content: "'表示'",
        color: '#fff',
        fontSize: 12,
        letterSpacing: 1.2,
        left: 15,
        position: 'absolute',
      },
      '&::after': {
        top: 3,
        left: 47,
        backgroundColor: '#fff',
      },
    },
  },
})

export const facilityCheckboxLabelStyle = css({
  width: 73,
  height: 28,
  background: '#F2F2F2',
  position: 'relative',
  display: 'inline-block',
  borderRadius: 14,
  transition: '0.4s',
  boxSizing: 'border-box',
  border: '1px solid #ccc',
  cursor: 'pointer',

  '&::before': {
    content: "'非表示'",
    fontWeight: 'bold',
    color: '#a3a3a3',
    fontSize: 11,
    letterSpacing: 0.55,
    left: 30,
    position: 'absolute',
    top: 7,
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    width: 22,
    height: 22,
    borderRadius: '100%',
    top: 2,
    left: 4,
    zIndex: 2,
    background: '#fff',
    transition: '0.4s',
    boxShadow: '0px 2px 3px #00000029',
  },
})

const infoHeaderContainerStyle = css({
  background: '#F5F5F5',
  height: 25,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const infoHeaderTitleStyle = css({
  fontSize: 12,
  padding: '6px 0',
})

const hintStyle = css({
  position: 'relative',
  p: {
    display: 'none',
  },
  ':hover p': {
    display: 'flex',
  },
})
