import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// contexts
import { AccountContext } from '@/contexts/account'

// constants
import { listContainerStyle } from '@/constants/layout'

// apis
import { updateIconStatus, fetchIconDisplay } from '@/apis/aipass'

//component
import { IconSettingList } from '@/components/pages/setting/top-page/icon-setting/_list/icon-setting-list'
import { LoadingFull } from '@/components/molecules/loading-full'

// libs
import { setHasMobileRequestPlugin } from '@/libs/plugins'

type ContainerProps = {
  updateIconStatus: (icon: number, checked: boolean) => void
  changeState: (e: any, id: any) => void
  index: number
  iconStatus: []
}

export const IconSetting: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const { account, plugins } = useContext<any>(AccountContext)
  const [iconStatus, setIconStatus] = useState<any>()
  // FIXME: It doesn't have to be State because it has unused parts
  // eslint-disable-next-line no-unused-vars
  const [index, _setIndex] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const hasMobileRequestPlugin = setHasMobileRequestPlugin(plugins)

  const _updateIconStatus = iconDisplay => {
    updateIconStatus(iconDisplay).then(() => {
      _fetchIconDisplay()
    })
  }

  const changeState = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    setIsLoading(true)
    const value = e.target.value
    const name = e.target.name
    const iconArray = Object.values(iconStatus)
    let checkinValue
    let checkoutValue
    let hotelGuideValue
    let aroundSpotValue
    let mobileRequestValue
    let frontTelephoneValue
    let wifiValue
    if (name === 'checkin') {
      if (value === '1') {
        checkinValue = 0
      } else if (value === '0') {
        checkinValue = 1
      }
    }
    if (name === 'checkout') {
      if (value === '1') {
        checkoutValue = 0
      } else if (value === '0') {
        checkoutValue = 1
      }
    }
    if (name === 'hotel_guide') {
      if (value === '1') {
        hotelGuideValue = 0
      } else if (value === '0') {
        hotelGuideValue = 1
      }
    }
    if (name === 'around_spot') {
      if (value === '1') {
        aroundSpotValue = 0
      } else if (value === '0') {
        aroundSpotValue = 1
      }
    }
    if (name === 'mobile_request') {
      if (value === '1') {
        mobileRequestValue = 0
      } else if (value === '0') {
        mobileRequestValue = 1
      }
    }
    if (name === 'front_telephone') {
      if (value === '1') {
        frontTelephoneValue = 0
      } else if (value === '0') {
        frontTelephoneValue = 1
      }
    }
    if (name === 'wifi') {
      if (value === '1') {
        wifiValue = 0
      } else if (value === '0') {
        wifiValue = 1
      }
    }
    const iconDisplay = {
      checkin: checkinValue !== undefined ? checkinValue : Number(iconArray[1]),
      checkout: checkoutValue !== undefined ? checkoutValue : Number(iconArray[2]),
      hotelGuide: hotelGuideValue !== undefined ? hotelGuideValue : Number(iconArray[3]),
      aroundSpot: aroundSpotValue !== undefined ? aroundSpotValue : Number(iconArray[4]),
      frontTelephone: frontTelephoneValue !== undefined ? frontTelephoneValue : Number(iconArray[5]),
      wifi: wifiValue !== undefined ? wifiValue : Number(iconArray[6]),
      mobileRequest: mobileRequestValue !== undefined ? mobileRequestValue : Number(iconArray[7]),
    }
    if (
      String(checkinValue) ||
      String(checkoutValue) ||
      String(hotelGuideValue) ||
      String(aroundSpotValue) ||
      String(mobileRequestValue) ||
      String(frontTelephoneValue) ||
      String(wifiValue)
    ) {
      _updateIconStatus(iconDisplay)
    }
  }

  const _fetchIconDisplay = () => {
    if (account) {
      fetchIconDisplay(account.hotel.id).then(res => {
        setIconStatus(res.icon_display[0])
        setIsLoading(false)
      })
    }
  }

  useEffect(() => {
    _fetchIconDisplay()
  }, [account])

  return (
    <div css={topImageContainerStyle}>
      <div css={topImageHeaderContainerStyle}>
        <div css={detailsHeaderTitleStyle}>{t('Icon')}</div>
      </div>
      <>
        <div css={mainContainerStyle}>
          {iconStatus && (
            <>
              <div css={facilityRecommendStyle}>
                <IconSettingList
                  name="checkin"
                  value={1}
                  statusInfo="1"
                  onChange={v => _updateIconStatus(iconStatus)}
                  changeState={changeState}
                  index={index}
                  iconStatus={iconStatus?.checkin}
                  iconStatusName={t('Checkin')}
                />
              </div>
              <div css={facilityRecommendStyle}>
                <IconSettingList
                  name="checkout"
                  value={1}
                  statusInfo="1"
                  onChange={v => _updateIconStatus(iconStatus)}
                  changeState={changeState}
                  index={index}
                  iconStatus={iconStatus?.checkout}
                  iconStatusName={t('Checkout')}
                />
              </div>

              <div css={facilityRecommendStyle}>
                <IconSettingList
                  name="hotel_guide"
                  value={1}
                  statusInfo="1"
                  onChange={v => _updateIconStatus(iconStatus)}
                  changeState={changeState}
                  index={index}
                  iconStatus={iconStatus?.hotelGuide}
                  iconStatusName={t('Facility guidance')}
                />
              </div>

              <div css={facilityRecommendStyle}>
                <IconSettingList
                  name="around_spot"
                  value={1}
                  statusInfo="1"
                  onChange={v => _updateIconStatus(iconStatus)}
                  changeState={changeState}
                  index={index}
                  iconStatus={iconStatus?.aroundSpot}
                  iconStatusName={t('Tourist information')}
                />
              </div>

              {hasMobileRequestPlugin && (
                <div css={facilityRecommendStyle}>
                  <IconSettingList
                    name="mobile_request"
                    value={1}
                    statusInfo="1"
                    onChange={v => _updateIconStatus(iconStatus)}
                    changeState={changeState}
                    index={index}
                    iconStatus={iconStatus?.mobileRequest}
                    iconStatusName={t('Request')}
                  />
                </div>
              )}

              <div css={facilityRecommendStyle}>
                <IconSettingList
                  name="front_telephone"
                  value={1}
                  statusInfo="1"
                  onChange={v => _updateIconStatus(iconStatus)}
                  changeState={changeState}
                  index={index}
                  iconStatus={iconStatus?.frontTelephone}
                  iconStatusName={t('Front phone')}
                />
              </div>

              <div css={facilityRecommendStyle}>
                <IconSettingList
                  name="wifi"
                  value={1}
                  statusInfo="1"
                  onChange={v => _updateIconStatus(iconStatus)}
                  changeState={changeState}
                  index={index}
                  iconStatus={iconStatus?.wifi}
                  iconStatusName="Wi-Fi"
                />
              </div>
            </>
          )}
        </div>
        <LoadingFull isLoading={isLoading} />
      </>
    </div>
  )
}

const topImageContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 'auto',
  marginBottom: -10,
})

const topImageHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const detailsHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
})

const mainContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  color: '#272727',
  width: '100%',
  flexWrap: 'wrap',
  'div:nth-child(2n)': {
    borderLeft: '1px solid #F2F2F2',
  },
})

const facilityRecommendStyle = css({
  width: '50%',
  height: 64,
})
