import { css } from '@emotion/core'
import React, { CSSProperties, ReactNode } from 'react'
import Lottie from 'react-lottie'

type ModalProps = {
  isLoading?: boolean
  customCss?: object
  style?: CSSProperties
}

type TModalHeaderProps = {
  children: ReactNode
  customCss?: object
}

type TModalBodyProps = TModalHeaderProps

type TModalFooterProps = TModalHeaderProps

export const Modal: React.FC<ModalProps> = ({ children, isLoading, customCss = {}, style = {} }) => {
  const animationData = require('@/static/images/json/loading.json')
  return (
    <React.Fragment>
      <div css={modalContainerStyle}>
        <div css={css(modalBodyContainerStyle, { ...customCss })} style={style}>
          {children}
          {isLoading && (
            <div css={loadinOverlayStyle}>
              <div css={loadingIconStyle}>
                <Lottie height="80px" width="80px" options={{ animationData: animationData, autoplay: true, loop: true }} />
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export const ModalHeader: React.FC<TModalBodyProps> = ({ children, customCss = {} }) => {
  return <h3 css={css(modalHeaderStyle, { ...customCss })}>{children}</h3>
}

type ModalBodyProps = {
  customCss?: object
}

export const ModalBody: React.FC<ModalBodyProps> = ({ children, customCss = {} }) => {
  return <div css={css(modalBodyStyle, { ...customCss })}>{children}</div>
}

export const ModalFooter: React.FC<TModalFooterProps> = ({ children, customCss = {} }) => {
  return <div css={css(modalFooterStyle, { ...customCss })}>{children}</div>
}

const modalContainerStyle = css({
  position: 'fixed',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  top: 0,
  left: 0,
  zIndex: 103,
  overflowY: 'auto',
})

const modalBodyContainerStyle = css({
  margin: '32px auto',
  position: 'sticky',
  maxWidth: '90%',
  width: 680,
  zIndex: 103,
  opacity: 1,
  background: '#FFF',
  borderRadius: 5,
  height: '550px;',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const modalHeaderStyle = css({
  padding: '22px 32px',
  fontWeight: 'bold',
  fontSize: 18,
  color: '#272727',
  zIndex: 1,
  boxShadow: '0px 0px 6px #0000001A',
})

const modalBodyStyle = css({
  padding: '26px 32px',
  backgroundColor: '#F2F2F2',
  overflowY: 'auto',
  height: `100%`,
})

const modalFooterStyle = css({
  display: 'flex',
  padding: '11px 32px 11px 32px',
  justifyContent: 'flex-end',
  boxShadow: '0px 0px 6px #0000001A',
  alignItems: 'center',
})

const loadinOverlayStyle = css({
  position: 'absolute',
  zIndex: 103,
  top: 0,
  height: '100%',
  width: '100%',
  background: 'rgba(255, 255, 255, 0.8)',
  opacity: 0.7,
  alignItems: 'center',
})

const loadingIconStyle = css({
  position: 'absolute',
  top: '40%',
  left: '50%',
})
