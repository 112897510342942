import React, { useState, useContext, useEffect } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { listContainerStyle } from '@/constants/layout'
import { putSelfCheckinSetting, fetchSelfCheckinSetting } from '@/apis/aipass'
import { AccountContext } from '@/contexts/account'

export const SettingSelfCheckin: React.FC = () => {
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [requiredIdentify, setRequiredIdentify] = useState(true)
  const [requiredForeignIdentify, setRequiredForeignIdentify] = useState(true)
  const [requiredAccompanyNameInput, setRequiredAccompanyNameInput] = useState(false)

  const changeState = async () => {
    setIsLoading(true)
    setRequiredIdentify(!requiredIdentify)
    await putSelfCheckinSetting({ requiredIdentify: !requiredIdentify })
    setIsLoading(false)
  }

  const changeStateForeignNationality = async () => {
    setIsLoading(true)
    setRequiredForeignIdentify(!requiredForeignIdentify)
    await putSelfCheckinSetting({ requiredForeignIdentify: !requiredForeignIdentify })
    setIsLoading(false)
  }

  const changeStateAccompanyNameInputRequired = async () => {
    setIsLoading(true)
    setRequiredAccompanyNameInput(!requiredAccompanyNameInput)
    await putSelfCheckinSetting({ requiredAccompanyNameInput: !requiredAccompanyNameInput })
    setIsLoading(false)
  }

  const _fetchSelfCheckinSetting = async () => {
    if (account) {
      setIsLoading(true)
      const res = await fetchSelfCheckinSetting(account.hotel.id)
      if (res) {
        setRequiredIdentify(res.requiredIdentify)
        setRequiredForeignIdentify(res.requiredForeignIdentify)
        setRequiredAccompanyNameInput(res.requiredAccompanyNameInput)
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    _fetchSelfCheckinSetting()
  }, [account])

  return (
    <SettingsLayout loading={isLoading}>
      <div css={topImageContainerStyle}>
        <div css={topImageHeaderContainerStyle}>
          <div css={detailsHeaderTitleStyle}>{t('Self check-in')}</div>
        </div>
        <div css={infoHeaderContainerStyle}>
          <div css={infoHeaderTitleStyle}>{t('Identity verification')}</div>
        </div>
        <div css={mainContainerStyle}>
          <>
            <div css={facilityRecommendStyle}>
              <div css={facilityInfoListContainerStyle}>
                <div css={facilityInfoListNameStyle}>{t('Japanese nationality')}</div>
                <div css={facilityInfoListRightContainerStyle}>
                  <input
                    name="required_identify"
                    type="checkbox"
                    id="required_identify"
                    css={facilityCheckboxStyle}
                    checked={!!requiredIdentify}
                    onChange={changeState}
                  />
                  <label htmlFor={'required_identify'} css={facilityCheckboxLabelStyle} />
                </div>
              </div>
              <div css={facilityInfoListContainerStyle}>
                <div css={facilityInfoListNameStyle}>{t('Foreign nationality')}</div>
                <div css={facilityInfoListRightContainerStyle}>
                  <input
                    name="required_foreign_identify"
                    type="checkbox"
                    id="required_foreign_identify"
                    css={facilityCheckboxStyle}
                    checked={!!requiredForeignIdentify}
                    onChange={changeStateForeignNationality}
                  />
                  <label htmlFor={'required_foreign_identify'} css={facilityCheckboxLabelStyle} />
                </div>
              </div>
            </div>
          </>
        </div>
        <div css={infoHeaderContainerStyle}>
          <div css={infoHeaderTitleStyle}>{t('Accompany name')}</div>
        </div>
        <div css={mainContainerStyle}>
          <div css={facilityRecommendStyle}>
            <div css={facilityInfoListContainerStyle}>
              <div css={facilityInfoListNameStyle}>{t('Accompany name input')}</div>
              <div css={facilityInfoListRightContainerStyle}>
                <input
                  name="required_accompany_name_input"
                  type="checkbox"
                  id="required_accompany_name_input"
                  css={facilityIsRequiredCheckboxStyle}
                  checked={!!requiredAccompanyNameInput}
                  onChange={changeStateAccompanyNameInputRequired}
                />
                <label htmlFor={'required_accompany_name_input'} css={facilityIsRequiredCheckboxLabelStyle} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingsLayout>
  )
}

const topImageContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 'auto',
  marginBottom: -10,
})

const topImageHeaderContainerStyle = css({
  height: 50,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const detailsHeaderTitleStyle = css({
  fontSize: 18,
  fontWeight: 'bold',
  padding: '16px 0',
  letterSpacing: '1.8px',
  color: '#272727',
})

const mainContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  color: '#272727',
  width: '100%',
  flexWrap: 'wrap',
})

const facilityRecommendStyle = css({
  width: '100%',
})

const facilityInfoListContainerStyle = css({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 64,
  borderBottom: '1px solid #F2F2F2',
  padding: '0 32px',
})

const facilityInfoListNameStyle = css({
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  width: '100%',
  padding: '25px 0px',
  height: '100%',
  letterSpacing: '1.4px',
})

const facilityInfoListRightContainerStyle = css({
  display: 'flex',
  img: {
    display: 'block',
    marginLeft: 27,
    padding: 4,
    ':hover': {
      borderRadius: '50%',
      background: '#F2F2F2',
      cursor: 'pointer',
    },
  },
})

export const facilityIsRequiredCheckboxStyle = css({
  display: 'none',
  ':checked': {
    '+label': {
      backgroundColor: '#F2A40B',
      border: 'none',
      position: 'relative',

      '&::before': {
        content: "'必須'",
        color: '#fff',
        fontSize: 12,
        letterSpacing: 1.2,
        left: 15,
        position: 'absolute',
      },
      '&::after': {
        top: 3,
        left: 47,
        backgroundColor: '#fff',
      },
    },
  },
})

export const facilityIsRequiredCheckboxLabelStyle = css({
  width: 73,
  height: 28,
  background: '#F2F2F2',
  position: 'relative',
  display: 'inline-block',
  borderRadius: 14,
  transition: '0.4s',
  boxSizing: 'border-box',
  border: '1px solid #ccc',
  cursor: 'pointer',

  '&::before': {
    content: "'任意'",
    fontWeight: 'bold',
    color: '#a3a3a3',
    fontSize: 11,
    letterSpacing: 0.55,
    left: 30,
    position: 'absolute',
    top: 7,
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    width: 22,
    height: 22,
    borderRadius: '100%',
    top: 2,
    left: 4,
    zIndex: 2,
    background: '#fff',
    transition: '0.4s',
    boxShadow: '0px 2px 3px #00000029',
  },
})

export const facilityCheckboxStyle = css({
  display: 'none',
  ':checked': {
    '+label': {
      backgroundColor: '#F2A40B',
      border: 'none',
      position: 'relative',

      '&::before': {
        content: "'表示'",
        color: '#fff',
        fontSize: 12,
        letterSpacing: 1.2,
        left: 15,
        position: 'absolute',
      },
      '&::after': {
        top: 3,
        left: 47,
        backgroundColor: '#fff',
      },
    },
  },
})

export const facilityCheckboxLabelStyle = css({
  width: 73,
  height: 28,
  background: '#F2F2F2',
  position: 'relative',
  display: 'inline-block',
  borderRadius: 14,
  transition: '0.4s',
  boxSizing: 'border-box',
  border: '1px solid #ccc',
  cursor: 'pointer',

  '&::before': {
    content: "'非表示'",
    fontWeight: 'bold',
    color: '#a3a3a3',
    fontSize: 11,
    letterSpacing: 0.55,
    left: 30,
    position: 'absolute',
    top: 7,
  },
  '&::after': {
    content: "''",
    position: 'absolute',
    width: 22,
    height: 22,
    borderRadius: '100%',
    top: 2,
    left: 4,
    zIndex: 2,
    background: '#fff',
    transition: '0.4s',
    boxShadow: '0px 2px 3px #00000029',
  },
})

const infoHeaderContainerStyle = css({
  background: '#F5F5F5',
  height: 25,
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const infoHeaderTitleStyle = css({
  fontSize: 12,
  padding: '6px 0',
})
