export const RoomKindType = {
  GuestRoom: 'guestRoom',
  SpaceRoom: 'spaceRoom',
} as const
export type RoomKindType = (typeof RoomKindType)[keyof typeof RoomKindType]

export const LockType = {
  Manual: 'manual',
  RemoteLock: 'remoteLock',
  MiwaLock: 'miwaLock',
  SwitchBot: 'switchBot',
} as const
export type LockType = (typeof LockType)[keyof typeof LockType]

export interface LockSetting {
  roomKind: RoomKindType
  lockType: LockType
}

export interface AccountInfo {
  lockType: LockType
  isEnabled: boolean
  isConnected: boolean
}

export interface Device {
  id: string
  name: string
  serialNumber: string | undefined
}
