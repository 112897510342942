import { LoadingFull } from '@/components/molecules/loading-full'
import React, { Context, Dispatch, SetStateAction, createContext, useState } from 'react'

type ContextType = {
  isLoading:boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}
const loaderContext = createContext<ContextType | undefined>(undefined)

export const LoaderContextCreator = (): Context<ContextType> => {
  if (!loaderContext) {
    throw new Error('undefined context')
  }
  return loaderContext as Context<ContextType>
}

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  return (
    <loaderContext.Provider value={{ setIsLoading, isLoading }}>
      {children}
      <LoadingFull isLoading={isLoading} />
    </loaderContext.Provider>
  )
}
