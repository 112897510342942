import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// models
import { GuestRoomAssignStatusResponseType } from '@/models/room-manager/response/guest-room-assign-status'
import { Button } from '@/components/atoms'
import { ButtonType } from '@/components/atoms/button'

type ChangeOrderProps = {
  order: 'room' | 'floor'
  setOrder: React.Dispatch<React.SetStateAction<'room' | 'floor'>>
  hiddenRoomNameList: string[]
  changeHiddenRoomNameList: (type: 'add' | 'delete', roomTypeName?: string | undefined) => void
  fetchGuestRoomAssignStatus: (
    selectOrder?: 'room' | 'floor',
    guestRoom?: GuestRoomAssignStatusResponseType['guestRoomAssignStatusList'],
  ) => Promise<any>
  onClickNewReservation: () => void
}

export const ChangeOrder: React.FC<ChangeOrderProps> = ({
  order,
  setOrder,
  hiddenRoomNameList,
  changeHiddenRoomNameList,
  fetchGuestRoomAssignStatus,
  onClickNewReservation,
}) => {
  const { t } = useTranslation()

  const onClickChangeOrder = () => {
    if (order === 'room') {
      setOrder('floor')
      // Reconfigure guestRoom
      fetchGuestRoomAssignStatus('floor')
    } else {
      setOrder('room')
      // Reconfigure guestRoom
      fetchGuestRoomAssignStatus('room')
    }
    // Initialize hiddenRoomNameList
    changeHiddenRoomNameList('delete')
  }

  return (
    <div css={containerStyle}>
      <div>
        <Button buttonType={ButtonType.Secondary} width={120} height={24} fontSize={12} onClick={() => onClickNewReservation()}>
          {t('New reservation')}
        </Button>
      </div>
      <div>
        <div
          css={showRoomNameWrapperStyle}
          onClick={() => (!hiddenRoomNameList.length ? changeHiddenRoomNameList('add') : changeHiddenRoomNameList('delete'))}
        >
          <div css={showRoomNameStyle}>{!hiddenRoomNameList.length ? t('Room name hidden') : t('Room name display')}</div>
        </div>

        <div css={showRoomNameWrapperStyle} onClick={() => onClickChangeOrder()}>
          <div css={showRoomNameStyle}>{order === 'room' ? t('Room order') : t('Floor order')}</div>
        </div>
      </div>
    </div>
  )
}
const containerStyle = css({
  height: '100%',
  display: 'flex',
  gap: 18,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '> div': {
    ':last-of-type': {
      display: 'flex',
      gap: 12,
    },
  },
})

const showRoomNameWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
})

const showRoomNameStyle = css({
  fontSize: 12,
  color: '#676767',
  fontWeight: 'bold',
  textDecoration: 'underline',
})
