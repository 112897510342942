import React, { useEffect, useMemo, useState } from 'react'
import { SerializedStyles, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { InputFileImage } from './input-file-image'

type Props = {
  value?: string
  customStyle?: SerializedStyles
  onChange: (value: string) => void
  onShowModal: (isShowModal: boolean) => void
  aspect?: number
  error?: string
}

export const InputImage: React.FC<Props> = ({ value, customStyle, onChange, onShowModal, aspect, error }) => {
  const { t } = useTranslation()
  const [isShowCropModal, setIsShowCropModal] = useState<boolean>(false)

  useEffect(() => {
    onShowModal && onShowModal(isShowCropModal)
  }, [isShowCropModal])

  const widthRate = useMemo(() => (aspect && aspect > 1 ? aspect : 1), [aspect])
  const heightRate = useMemo(() => (aspect && aspect <= 1 ? aspect : 1), [aspect])

  return (
    <>
      <div
        css={customStyle}
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          paddingBottom: 14,
        }}
      >
        <div css={inputTitleTextStyle}>
          {t('Image')}
          <div css={requireLabelTextStyle}>※</div>
        </div>

        <InputFileImage value={value} onChange={onChange} onShowModal={setIsShowCropModal} aspect={16 / 9} error={error} />

        <div css={annotationTextStyle}>{t('RecommendedImageSize', { width: 1280 / heightRate, height: 1280 / widthRate })}</div>
        {!!error && <div css={{ fontSize: 10, color: 'rgba(255,0,0,0.5)', position: 'absolute', bottom: 0 }}>{error}</div>}
      </div>
    </>
  )
}

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const annotationTextStyle = css({
  paddingTop: 12,
  fontSize: 10,
  color: '#676767',
})
