import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { AssetType, SpaceDetailType, SpacePriceType } from '@/models/guest-app/asset'
import { Controller, useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'
import { InputField } from '@/components/molecules/input-field'
import { createAssetDetail, updateAssetDetail } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { TextareaField } from '@/components/molecules/textarea-field'
import { InputSpaceImage } from '@/components/molecules/settings/asset/space/input-space-image'
import { InputSalesHours } from '@/components/molecules/settings/asset/space/input-sales-hours'
import { InputSalesSubject } from '@/components/molecules/settings/asset/space/input-sales-subject'
import { InputUsageHours } from '@/components/molecules/settings/asset/space/input-usage-hours'
import { InputChargePrice } from '@/components/molecules/settings/asset/space/input-charge-price'
import { Hint } from '@/components/atoms/hint'

type Props = {
  asset: AssetType
  detail: SpaceDetailType | null
  onCancel: () => void
  onSave: () => void
}

export type AssetSpaceDetailFormValue = {
  title: string
  images: { path: string }[]
  description: string
  question: string
  salesHour: {
    start: string
    end: string
  }
  spacePriceType: SpacePriceType | undefined
  chargePrice:
    | {
        usageTime: {
          startHour: string
          startMinute: string
          endHour: string
          endMinute: string
        }
        unitPrice: number
        maxPrice: number | undefined
      }
    | undefined
  stock: number
  stockUnit: string
  salesDepartmentId: string | undefined
  salesSubjectMasterId: string | undefined
}

export const SpaceDetailModal: React.FC<Props> = ({ asset, detail, onCancel, onSave: parentSave }) => {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<AssetSpaceDetailFormValue>({
    defaultValues: {
      title: detail?.title || '',
      images: detail?.images.map(path => ({ path })) || [{ path: '' }],
      description: detail?.description || '',
      question: detail?.question || '',
      salesHour: detail?.salesHour,
      spacePriceType: detail?.spacePrice?.type,
      chargePrice: detail?.spacePrice?.chargePrice
        ? {
            usageTime: {
              startHour: detail.spacePrice.chargePrice.usageTime.start?.split(':')[0],
              startMinute: detail.spacePrice.chargePrice.usageTime.start?.split(':')[1],
              endHour: detail.spacePrice.chargePrice.usageTime.end?.split(':')[0],
              endMinute: detail.spacePrice.chargePrice.usageTime.end?.split(':')[1],
            },
            unitPrice: detail.spacePrice.chargePrice.unitPrice,
            maxPrice: detail.spacePrice.chargePrice.maxPrice,
          }
        : undefined,
      stock: detail?.stock,
      stockUnit: detail?.stockUnit || '',
      salesDepartmentId: detail?.salesSubject?.salesDepartmentId || '',
      salesSubjectMasterId: detail?.salesSubject?.salesSubjectMasterId,
    },
  })

  const { doReload, setIsLoading } = useContext(AssetDetailContext)
  const [isShowCropModal, setIsShowCropModal] = useState<boolean>(false)
  const { errorHandler } = useErrorHandler()

  const onSave = async (value: AssetSpaceDetailFormValue) => {
    try {
      setIsLoading(true)
      const requestValue = {
        ...value,
        images: value.images.map(i => i.path),
        spacePriceType: SpacePriceType.Charge,
        chargePrice: {
          usageTime: {
            start:
              value.chargePrice?.usageTime.startHour &&
              value.chargePrice?.usageTime.startMinute &&
              `${value.chargePrice?.usageTime.startHour}:${value.chargePrice?.usageTime.startMinute}`,
            end:
              value.chargePrice?.usageTime.endHour &&
              value.chargePrice?.usageTime.endMinute &&
              `${value.chargePrice?.usageTime.endHour}:${value.chargePrice?.usageTime.endMinute}`,
          },
          price: value.chargePrice?.unitPrice,
          maxPrice: value.chargePrice?.maxPrice,
        },
      }
      if (detail) {
        await updateAssetDetail(asset.id, detail.id, asset.formatType, requestValue)
      } else {
        await createAssetDetail(asset.id, asset.formatType, requestValue)
      }
      doReload()
      parentSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal customCss={modalStyle} style={{ visibility: isShowCropModal ? 'hidden' : 'visible' }}>
        <ModalHeader>{t('GuestAppSetting.CreateSpace')}</ModalHeader>
        <ModalBody>
          <div css={modalBodyStyle}>
            <div className="left-panel">
              <>
                <div css={inputTitleTextStyle}>
                  {t('Title')}
                  <div css={requireLabelTextStyle}>※</div>
                </div>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: t('Required field has not been entered') }}
                  render={({ field: { onChange, value, name } }) => (
                    <TextareaField
                      marginBottom={24}
                      placeholder={t('Title name')}
                      value={value}
                      name={name}
                      handleChangeData={onChange}
                      error={errors.title?.message}
                      rows={2}
                    />
                  )}
                />
              </>

              <InputSpaceImage control={control} errors={errors} setIsShowCropModal={setIsShowCropModal} />

              <>
                <div css={inputTitleTextStyle}>{t('Content')}</div>
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <TextareaField
                      marginBottom={24}
                      placeholder={t('Content')}
                      value={value}
                      name={name}
                      handleChangeData={onChange}
                      rows={6}
                    />
                  )}
                />
              </>
              <>
                <div css={inputTitleTextStyle}>
                  {t('Questions and notes')}
                  <Hint
                    src={require('@/static/images/space/help_info.svg')}
                    customCss={css({
                      paddingLeft: 8,
                      position: 'static',
                      '.help-image': { bottom: 0 },
                    })}
                  />
                </div>

                <Controller
                  name="question"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <TextareaField
                      marginBottom={24}
                      placeholder={t('Questions and notes')}
                      value={value}
                      name={name}
                      handleChangeData={onChange}
                      rows={3}
                    />
                  )}
                />
              </>
            </div>
            <div className="right-panel">
              <InputSalesHours control={control} errors={errors} />

              <div css={usageBlockStyle}>
                <InputUsageHours control={control} errors={errors} />
                <hr css={css(hrStyle, { borderColor: '#CCCCCC' })}></hr>
                <InputChargePrice control={control} errors={errors} />
              </div>
              <hr css={css(hrStyle, { marginBottom: 32 })}></hr>
              <div css={inputAreaStyle}>
                <div style={{ marginRight: 16, width: 92 }}>
                  <div css={inputTitleTextStyle}>
                    {t('Stock')}
                    <div css={requireLabelTextStyle}>※</div>
                  </div>
                  <Controller
                    name="stock"
                    control={control}
                    rules={{ required: t('Required field has not been entered') }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <InputField
                        marginBottom={24}
                        placeholder="0"
                        type="number"
                        value={value}
                        name={name}
                        handleChangeData={onChange}
                        error={error?.message}
                      />
                    )}
                  />
                </div>
                <div style={{ width: 92 }}>
                  <div css={inputTitleTextStyle}>
                    {t('Quantity unit')}
                    <div css={requireLabelTextStyle}>※</div>
                  </div>
                  <Controller
                    name="stockUnit"
                    control={control}
                    rules={{ required: t('Required field has not been entered') }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                      <InputField
                        marginBottom={24}
                        placeholder={t('Person')}
                        value={value}
                        name={name}
                        handleChangeData={onChange}
                        error={error?.message}
                      />
                    )}
                  />
                </div>
              </div>

              <hr css={css(hrStyle, { marginBottom: 32 })}></hr>
              <InputSalesSubject control={control} detail={detail} setValue={setValue} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(onSave)}>
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const modalStyle = {
  height: 622,
  width: 1200,
  minWidth: 1200,
  left: 'calc((100% - 1200px) / 2)',
  top: '5%',
}

const modalBodyStyle = {
  padding: '24px 32px',
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  display: 'flex',
  '.left-panel': {
    width: '50%',
    borderRight: '1px solid #F2F2F2',
    paddingRight: 35.5,
  },
  '.right-panel': {
    width: '50%',
    paddingLeft: 30.5,
    display: 'flex',
    flexFlow: 'column',
  },
  textarea: {
    height: '100%',
    lineHeight: 1.5,
    marginBottom: 0,
  },
}

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
  alignItems: 'center',
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const hrStyle = css({
  borderColor: '#F2F2F2',
  marginTop: 0,
  marginBottom: 24,
})

const inputAreaStyle = css({
  display: 'flex',
})

const usageBlockStyle = css({
  background: '#F5F5F5 0% 0% no-repeat padding-box',
  border: '1px solid #CCCCCC',
  borderRadius: 5,
  padding: 24,
  marginBottom: 32,
})
