import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { css } from '@emotion/core'
import { getMailDeliveryTemplates } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import parse from 'html-react-parser'

export interface Template {
  id: string
  title: string
  name: string
  content: string
  rawContent: string
}

interface MailTemplateModalProps {
  onSave: (template: Template) => void
  onClose: () => void
}

export const MailTemplateModal: React.FC<MailTemplateModalProps> = ({ onSave, onClose }) => {
  const [templates, setTemplates] = useState<Template[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<Template>()
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()

  const _getMailDeliveryTemplates = async () => {
    try {
      setLoading(true)
      const result = await getMailDeliveryTemplates()
      setTemplates(result)
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    _getMailDeliveryTemplates()
  }, [])

  const insertTemplate = () => {
    selectedTemplate && onSave(selectedTemplate)
  }

  return (
    <Modal customCss={modalStyle} isLoading={loading}>
      <ModalHeader customCss={modalHeaderStyle}>{t('Mail Templates')}</ModalHeader>
      <ModalBody customCss={modalBodyStyle}>
        <ul css={templateList} className="block block-name">
          {templates.map(template => {
            const isSelected = template.id === selectedTemplate?.id
            return (
              <li key={template.id} onClick={() => setSelectedTemplate(template)} className={isSelected ? 'selected' : ''}>
                <span>{template.name}</span>
                {isSelected && <img src={require('@/static/images/mail-delivery/arrow-right.svg')} />}
              </li>
            )
          })}
        </ul>

        <div className="divide" />

        <div css={contentStyle} className="block block-content">
          {selectedTemplate && (
            <>
              <p className="title">{`件名：${selectedTemplate.title}`}</p>
              <div className='content'>{parse(selectedTemplate.content)}</div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter customCss={modalFooterStyle}>
        <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={!selectedTemplate ? 5 : 1} height="38px" width="110px" onClick={insertTemplate} isDisabled={!selectedTemplate}>
          {t('Select')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = css({
  top: '5%',
  left: 'calc(50% - 600px)',
  maxWidth: 1200,
  width: '100%',
  height: 640,
})

const modalHeaderStyle = css({
  height: '60px',
  boxShadow: 'none',
  color: '#272727',
})

const modalBodyStyle = css({
  display: 'flex',
  '.divide': {
    width: 1,
    marginInline: 24,
    height: 525,
    background: '#ccc',
  },
  '.block': {
    width: '100%',
    background: '#fff',
    border: '1px solid #CCCCCC',
    borderRadius: 10,
    height: 525,
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '&.block-name': {
      maxWidth: 381,
    },
    '&.block-content': {
      maxWidth: 707,
    },
  },
})

const modalFooterStyle = css({
  height: '60px',
  display: 'flex',
})

const templateList = css({
  li: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
    fontSize: 12,
    fontWeight: 'bold',
    borderBottom: '1px solid #CCCCCC',
    cursor: 'pointer',
    '&.selected': {
      color: '#F2A40B',
    },
  },
})

const contentStyle = css({
  background: '#fff',
  '> *': {
    padding: '16px 24px',
  },
  '.title': {
    fontSize: 14,
    fontWeight: 'bold',
    borderBottom: '1px solid #CCCCCC',
  },
  '.content': {
    fontSize: 12,
    lineHeight: 1.5,
  }
})
