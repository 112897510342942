import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// apis
import { fetchSalesCashregister } from '@/apis/aipass'

// components
import { SettingSalesManagerTab } from '@/components/organisms/settings/sales-manager/tab'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'

type ContainerProps = {}

export const SettingSalesManagerRegister: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const [cashRegisterSetting, setCashRegisterSetting] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()
  const location = useLocation()

  const _fetchSalesCashregister = async () => {
    setIsLoading(true)
    await fetchSalesCashregister()
      .then(res => {
        setCashRegisterSetting(res?.price)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  useEffect(() => {
    _fetchSalesCashregister()
  }, [])

  return (
    <SettingsLayout loading={isLoading}>
      <TabContainer tabComponent={<SettingSalesManagerTab currentTab="Cash register" />}>
        <div css={settingListContainerStyle}>
          <div css={cashRegisterSettingContainerStyle}>
            <p>{t('Starting amount')}</p>
            {cashRegisterSetting && <p>¥{cashRegisterSetting.toLocaleString()}</p>}
          </div>
          <div
            css={editButtonStyle}
            onClick={() => history.push({ pathname: '/setting/sales-manager/register/edit', search: location.search })}
          >
            <img src={require('@/static/images/edit.svg')} alt={t('Edit')} />
            <p>{t('Edit')}</p>
          </div>
        </div>
      </TabContainer>
    </SettingsLayout>
  )
}

const settingListContainerStyle = css({
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: '0 5px 5px 5px',
  background: '#FFF',
  padding: '24px 32px 32px',
  position: 'relative',
  height: 'calc(100% - 45px)',
  display: 'flex',
  justifyContent: 'space-between',
})

const editButtonStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid #CCCCCC',
  padding: '6px 12px',
  borderRadius: 22,
  cursor: 'pointer',
  width: 72,
  height: 32,
  p: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#F2A40B',
  },
  img: {
    width: 20,
  },
  ':hover': {
    backgroundColor: '#F2F2F2',
  },
})

const cashRegisterSettingContainerStyle = css({
  p: {
    color: '#272727',
    '&:first-of-type': {
      fontWeight: 'bold',
      fontSize: 16,
      paddingBottom: 16,
    },
    '&:last-child': {
      fontSize: 14,
    },
  },
})
