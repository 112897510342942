import { useCallback, useState } from 'react'

// apis
import { fetchGuestRoomForHotel } from '@/apis/aipass'

// models
import { AdminGuestRoomResponseType } from '@/models/admin-guest-room'

type UseHotelGuestRoomsType = [AdminGuestRoomResponseType['guestRoomInfo'], () => Promise<void>]

// Hotel room list
export function useHotelGuestRooms(): UseHotelGuestRoomsType {
  const [hotelGuestRooms, setHotelGuestRooms] = useState<AdminGuestRoomResponseType['guestRoomInfo']>([])

  const handleFetchGuestRoomForHotel = useCallback(async () => {
    await fetchGuestRoomForHotel().then((guestRooms: AdminGuestRoomResponseType['guestRoomInfo']) => {
      if (guestRooms) {
        setHotelGuestRooms(guestRooms)
      }
    })
  }, [])

  return [hotelGuestRooms, handleFetchGuestRoomForHotel]
}
