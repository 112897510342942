import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

// apis
import { fetchReservationChannelCode } from '@/apis/aipass'

import { SettingReservationTab } from '@/components/pages/setting/reservation/tab'
import { SettingChannelCodeList } from '@/components/pages/setting/reservation/channel-code/_index/list'

// models
import { ChannelCodeType, ChannelType } from '@/models/reservation-channel-code'
import { SettingsLayout } from '@/components/layouts/settings-layout'

type ContainerProps = {}

export const SettingReservationChannelCode: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const windows = useWindowSize()

  const initialChannelCode: ChannelCodeType = {
    id: null,
    code: null,
    name: null,
    type: ChannelType.Custom,
  }
  const [channelCodes, setChannelCodes] = useState<ChannelCodeType[]>([initialChannelCode])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const _fetchChannelCode = async () => {
    setIsLoading(true)
    await fetchReservationChannelCode()
      .then(res => {
        setChannelCodes(res?.channelCodes)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  useEffect(() => {
    _fetchChannelCode()
  }, [])

  return (
    <SettingsLayout loading={isLoading}>
      <>
        <div css={settingHeaderContainerStyle}>
          <SettingReservationTab currentTab="channelCode" />
        </div>
        <div
          css={settingListContainerStyle}
          style={{
            maxHeight: windows.height - 148,
          }}
        >
          <div css={editButtonWrapperStyle}>
            <div
              css={editButtonStyle}
              onClick={() => history.push({ pathname: '/setting/reservation/channel-code/edit', search: location.search })}
            >
              <img src={require('@/static/images/edit.svg')} alt={t('Edit')} />
              <p>{t('Edit')}</p>
            </div>
          </div>
          <SettingChannelCodeList channelCodes={channelCodes} />
        </div>
      </>
    </SettingsLayout>
  )
}

const settingListContainerStyle = css({
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: '0 5px 5px 5px',
  background: '#FFF',
  position: 'relative',
})

const settingHeaderContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const editButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '24px 32px 34px',
})

const editButtonStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid #CCCCCC',
  padding: '6px 12px',
  borderRadius: 22,
  cursor: 'pointer',
  width: 76,
  height: 32,
  p: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#F2A40B',
  },
  img: {
    width: 20,
  },
  ':hover': {
    backgroundColor: '#F2F2F2',
  },
})
