import React from 'react'
import { css } from '@emotion/core'
import { sectionHeaderStyle } from '@/components/pages/dashboard/_index/style'

type ReviewHeaderProps = {
  history: any
  location: any
  t: any
}

export const ReviewHeader: React.FC<ReviewHeaderProps> = ({ history, t }) => {
  return (
    <div css={headerStyle}>
      {t('Evaluation')}
      <div css={linkStyle} onClick={() => history.push({ pathname: '/accommodation-management', search: `?tabType=checkout` })}>
        {t('View List')}
      </div>
    </div>
  )
}

const headerStyle = css(sectionHeaderStyle, {
  justifyContent: 'space-between',
  paddingRight: 32,
  cursor: 'pointer',
})

const linkStyle = css({
  color: '#F2A40B',
  fontSize: 12,
  fontWeight: 'bold',
  '&:link': {
    color: '#FF5500',
  },
  '&:visited': {
    color: '#FF5500',
  },
})
