import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import dayjs from 'dayjs'

import enJson from './locales/en.json'
import jaJson from './locales/ja.json'
import zhJson from './locales/zh.json'
import koJson from './locales/ko.json'
import moment from 'moment/moment'

export const LanguageType = {
  ja: 'ja',
  en: 'en',
  zh: 'zh',
  ko: 'ko',
} as const
export type LanguageType = typeof LanguageType[keyof typeof LanguageType]

i18n
  .use(initReactI18next)
  .init({
    lng: process.env.REACT_APP_DEFAULT_LANG ?? LanguageType.ja,
    fallbackLng: false,
    resources: {
      en: { translation: enJson },
      ja: { translation: jaJson },
      zh: { translation: zhJson },
      ko: { translation: koJson },
    },
    nsSeparator: false,
  })
  .then(() => {
    dayjs.locale(process.env.REACT_APP_DEFAULT_LANG ?? LanguageType.ja)
    moment.locale(process.env.REACT_APP_DEFAULT_LANG ?? LanguageType.ja)
  })

i18n.on('languageChanged', language => {
  localStorage.setItem('lang', language)
  if (i18n.languages.some(target => target === language)) {
    dayjs.locale(language)
    moment.locale(language)
  } else {
    dayjs.locale(process.env.REACT_APP_DEFAULT_LANG ?? LanguageType.ja)
    moment.locale(process.env.REACT_APP_DEFAULT_LANG ?? LanguageType.ja)
  }
})
export default i18n
