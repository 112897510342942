import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

type AssessmentProps = {
  reviews: any
  putAdminReadReview: (item) => void
  onColorChange: (item) => any
}

export const Assessment: React.FC<AssessmentProps> = ({ reviews, putAdminReadReview, onColorChange }) => {
  const { t, i18n } = useTranslation()
  return (
    <>
      <div>
        <div css={titleStyle}>
          <div css={titlePointStyle}>{t('Evaluation')}</div>
          <div css={titleNameStyle}>{t('Guest')}</div>
          <div css={titleDayStyle}>{t('Date')}</div>
        </div>
      </div>
      <ul css={assessmentDetailStyle}>
        {reviews &&
          reviews.map((item, index) => {
            return (
              <li
                css={Boolean(Number(item.reviewRead)) === true ? reviewReadStyle : ureviewUnreadStyle}
                key={index}
                onClick={() => {
                  putAdminReadReview(item)
                }}
              >
                <div css={onColorChange(item.point)}>{item.point === '' ? '-' : item.point}</div>
                <div css={dataNameStyle}>
                  <div css={nameStyle}>{item?.name}</div>
                  {i18n.language === 'ja' && <p css={dataNameJaStyle}>{t('Mr / Ms')}</p>}
                </div>
                <div css={dataDayStyle}>
                  <div css={dayStyle}>{dayjs(item.updatedAt).format(t(i18n.language === 'ja' ? 'MM-DD' : 'MMM DD'))}</div>
                  {Number(item.point) <= 4 && item.point !== '' && (
                    <div css={alertStyle}>
                      <img css={alertImageStyle} src={require('@/static/images/Alert.svg')} />
                    </div>
                  )}
                </div>
              </li>
            )
          })}
      </ul>
    </>
  )
}

const titleStyle = css({
  display: 'flex',
  width: '100%',
  background: '#fafafa',
  height: 28,
  borderTop: '1px solid #f2f2f2',
  borderBottom: '1px solid #f2f2f2',
  color: '#676767',
  fontSize: 10,
  fontWeight: 'bold',
  letterSpacing: 0.5,
  alignItems: 'center',
})

const titlePointStyle = css({
  marginLeft: 32,
  width: 22,
  textAlign: 'center',
})

const titleNameStyle = css({
  marginLeft: 32,
  width: 115,
  minWidth: 115,
})

const titleDayStyle = css({
  marginLeft: 32,
  width: 80,
  minWidth: 80,
})

const assessmentDetailStyle = css({
  overflow: 'scroll',
  height: 225,
  borderRadius: 5,
  overflowX: 'auto',
  overflowY: 'scroll',
})

const reviewReadStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 46,
  minHeight: 46,
  background: '#f5f5f5',
  fontWeight: 'normal',
  borderBottom: '1px solid #f2f2f2',
  cursor: 'pointer',
  '&:hover': {
    filter: 'drop-shadow(0px 3px 6px #0000000f)',
  },
})

const ureviewUnreadStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 46,
  minHeight: 46,
  background: '#ffffff',
  borderBottom: '1px solid #f2f2f2',
  cursor: 'pointer',
  fontWeight: 'bold',
  '&:hover': {
    filter: 'drop-shadow(0px 3px 6px #0000000f)',
  },
})

const dataNameStyle = css({
  fontSize: 12,
  width: 115,
  minWidth: 115,
  marginLeft: 32,
  display: 'flex',
  justifyContent: 'space-between',
})

const nameStyle = css({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const dataNameJaStyle = css({
  fontSize: 12,
  paddingLeft: 11,
})

const dataDayStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: 32,
  width: 88,
  minWidth: 88,
  letterSpacing: 0.6,
})

const dayStyle = css({
  fontSize: 12,
  marginRight: 16,
  letterSpacing: 0.6,
})

const alertStyle = css({})

const alertImageStyle = css({
  width: 16,
  height: 16,
})
