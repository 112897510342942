import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import getCroppedImg from '@/components/pages/setting/account/cropImage'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// components
import { Button } from '@/components/atoms/button'
import { LoadedFileType } from '../atoms/fileInput'

type Props = {
  value?: LoadedFileType
  onChange: React.Dispatch<LoadedFileType>
  onClose: React.Dispatch<void>
}

export const ImageCropModal: React.FC<Props> = ({ value, onChange, onClose }) => {
  const { t } = useTranslation()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const saveResult = async () => {
    onChange(await getCroppedImg(value, croppedAreaPixels))
  }

  return (
    <div css={cropModalContainerStyle}>
      <div css={cropModalBackgroundStyle} />
      <div css={cropModalMainContainerStyle}>
        <div className="crop-container" css={cropperProductPictureInsideStyle}>
          <div css={cropModalHeaderStyle}>{t('Set image')}</div>
          <div css={cropWrapperStyle}>
            <div css={cropTopSpaceStyle} />
            <div css={cropperStyle}>
              <Cropper
                image={String(value)}
                crop={crop}
                zoom={zoom}
                aspect={2 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                style={{ cropAreaStyle: { border: '2px solid rgb(242, 164, 11)' } }}
              />
            </div>
            <div css={cropBottomSpaceStyle} />
          </div>
          <div css={cropModalFooterStyle}>
            <div css={cropModalSliderContainerStyle}>
              <img src={require('@/static/images/picture.svg')} css={sliderIconSmStyle} />
              <Slider value={zoom} min={1} max={3} step={0.1} aria-labelledby="Zoom" onChange={(e, zoom: any) => setZoom(zoom)} />
              <img src={require('@/static/images/picture.svg')} css={sliderIconLgStyle} />
            </div>
            <div css={cropModalButtonContainerStyle}>
              <Button buttonType={3} width={110} height={38} marginLeft={16} onClick={() => onClose()}>
                {t('Cancel')}
              </Button>
              <Button buttonType={1} width={110} height={38} marginLeft={16} onClick={() => saveResult()}>
                {t('Apply')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// cropModal
const cropModalBackgroundStyle = css({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  background: 'black',
  opacity: 0.5,
})

const cropModalContainerStyle = css({
  zIndex: 102,
})

const cropModalMainContainerStyle = css({
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '-webkit-transform': 'translate(-50%, -50%)',
  '-ms-transform': 'translate(-50%, -50%)',
  width: 600,
  height: 450,
  background: 'white',
  borderRadius: '5px',
  zIndex: 1,
})

const cropperProductPictureInsideStyle = css({
  height: '100%',
  position: 'relative',
  '.reactEasyCrop': {
    '&_CropArea': {
      boxShadow: '0 0 0 9999em rgba(0,0,0,0.1)',
      border: '2px solid red',
      ':before': {
        border: 'none',
      },
      ':after': {
        border: 'none',
      },
    },
  },
})

const cropModalHeaderStyle = css({
  zIndex: 1,
  background: '#FFF',
  width: '100%',
  height: 50,
  padding: '16px 0px 16px 32px',
  fontSize: '18px',
  color: '#272727',
  fontWeight: 'bold',
  borderRadius: '5px 5px 0 0',
  boxShadow: '0px 1px 6px rgba(0,0,0,0.10)',
})

const cropModalFooterStyle = css({
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  padding: '14px 32px',
  width: '100%',
  justifyContent: 'space-between',
  background: '#FFF',
  borderRadius: '0 0 5px 5px',
})

const cropModalSliderContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  width: 210,
  '.MuiSlider': {
    '&-rail': {
      height: 1,
      color: '#CCC',
    },
    '&-root': {
      color: 'rgb(255, 85, 0)',
    },
    '&-thumb': {
      color: '#FFF',
      border: '2px solid rgb(242, 164, 11)',
      borderRadius: '50%',
      ':hover': {
        boxShadow: '0px 0px 0px 8px rgb(255, 85, 0, 0.2)',
      },
    },
  },
})

const cropWrapperStyle = css({
  width: 600,
  height: 340,
  position: 'relative',
  padding: '40px 0',
})

const cropTopSpaceStyle = css({
  width: '100%',
  height: 40,
  backgroundColor: '#e5e5e5',
  top: '0px',
  position: 'absolute',
})

const cropBottomSpaceStyle = css({
  width: '100%',
  height: 40,
  backgroundColor: '#e5e5e5',
  bottom: '0px',
  position: 'absolute',
})

const cropperStyle = css({
  position: 'absolute',
  top: '40px',
  left: 0,
  right: 0,
  bottom: '40px',
  height: 260,
})

const sliderIconSmStyle = css({
  width: 20,
  paddingRight: 8,
})

const sliderIconLgStyle = css({
  paddingLeft: 8,
})

const cropModalButtonContainerStyle = css({
  display: 'flex',
})
