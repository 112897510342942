import React from 'react'
import { css } from '@emotion/core'

type Props = {
  onClick: () => void
}
export const BackIcon: React.FC<Props> = ({ onClick }) => {
  return (
    <div css={buttonStyle}>
      <img src={require('@/static/images/arrow_left.svg')} className="icon" onClick={onClick} />
    </div>
  )
}

const buttonStyle = css({
  display: 'flex',
  '.icon': {
    width: 30,
    cursor: 'pointer',
  },
})
