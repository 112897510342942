import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@/components/atoms/modal'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms'
import { RadioButtonSquare } from '@/components/atoms/radio-button-square'
import { css } from '@emotion/core'
import { CustomerInfoInputs } from './customer-info-inputs'
import { SpaceSearchReservationModal } from './space-search-reservation-modal'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AssignSpaceInput, NewReservationInput, SpaceReservationInput } from '@/models/space-manager'
import { NewSpace } from '@/models/space-manager/timeline'
import dayjs from 'dayjs'
import { CreateSpaceReservation } from '@/apis/aipass'
import { errorHandler } from '@/libs/errors'
import { LoadingFull } from '@/components/molecules/loading-full'

interface SpaceReservationModalProps {
  onClose: () => void
  spaces: NewSpace
  date: dayjs.Dayjs
}
export const SpaceReservationModal: React.FC<SpaceReservationModalProps> = ({ date, onClose, spaces }) => {
  const [reservationsType, setReservationType] = useState<'lodging' | 'dayTrip'>()
  const [showSearch, setShowSearch] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const { t } = useTranslation()

  const useFormReturn = useForm<NewReservationInput>({})
  const {
    handleSubmit,
    formState: { isValid },
  } = useFormReturn

  const assignSpaces: AssignSpaceInput[] = []
  Object.keys(spaces).forEach(spaceName => {
    spaces[spaceName].forEach(space => {
      const assign = {
        usageFrom: dayjs(`${space?.date?.format('YYYY-MM-DD')} ${space.usageTime.usageFrom}`).format('YYYY-MM-DD HH:mm:ss'),
        usageTo: dayjs(`${space?.date?.format('YYYY-MM-DD')} ${space.usageTime.usageTo}`).format('YYYY-MM-DD HH:mm:ss'),
        spaceId: space.spaceId,
        spaceIndex: space.spaceIndex,
        pax: space.pax,
        reservationUserName: '', // 宿泊連携or日帰りsubmit時に入れる
        answer: space.answer || '',
        spaceTypeName: spaceName,
      }

      assignSpaces.push(assign)
    })
  })

  const onDayTripCreate: SubmitHandler<NewReservationInput> = async submitData => {
    const newReservation: SpaceReservationInput = {
      name: submitData.newReservation.name || '',
      nameKana: submitData.newReservation.nameKana || '',
      email: submitData.newReservation.email || '',
      tel: submitData.newReservation.tel || '',
      address: submitData.newReservation.address || '',
      checkinDate: date.format('YYYY-MM-DD'),
      checkoutDate: date.format('YYYY-MM-DD'),
    }

    try {
      setSubmitLoading(true)
      await CreateSpaceReservation({
        newReservation,
        assignSpaces: assignSpaces.map(assign => ({
          ...assign,
          reservationUserName: submitData.newReservation.name || '',
        })),
      })
    } catch (error) {
      errorHandler(error)
    } finally {
      onClose()
    }
  }

  return (
    <>
      <Modal customCss={modalStyle} style={{ visibility: showSearch ? 'hidden' : 'visible' }}>
        <ModalHeader>{t('HotelCuicinUser')}</ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="block">
              <div className="radio-group">
                <RadioButtonSquare
                  onChange={() => {
                    setShowSearch(true)
                    setReservationType('lodging')
                  }}
                  checked={reservationsType === 'lodging'}
                  value="lodging"
                  id="lodging"
                  text={t('Lodging')}
                />
                <RadioButtonSquare
                  onChange={() => setReservationType('dayTrip')}
                  checked={reservationsType === 'dayTrip'}
                  value="dayTrip"
                  id="dayTrip"
                  text={t('one day trip')}
                />
              </div>
            </div>

            <hr style={{ marginBlock: 30, border: 'none', backgroundColor: '#ccc' }} />

            <div className="block">{reservationsType === 'dayTrip' && <CustomerInfoInputs useFormReturn={useFormReturn} />}</div>
          </div>
        </ModalBody>
        {reservationsType === 'dayTrip' && (
          <form onSubmit={handleSubmit(onDayTripCreate)}>
            <ModalFooter>
              <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onClose()}>
                {t('Cancel')}
              </Button>
              <Button buttonType={isValid ? 1 : 5} type="submit" height="38px" width="110px" isDisabled={!isValid}>
                {t('Create')}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Modal>

      <LoadingFull isLoading={submitLoading} />

      {showSearch && (
        <SpaceSearchReservationModal
          assignSpaces={assignSpaces}
          onClose={() => {
            setShowSearch(false)
            onClose()
          }}
        />
      )}
    </>
  )
}

const modalStyle = css({
  top: '5%',
  height: 'auto',
  minHeight: 'auto',
  maxHeight: 'calc(100vh - 10%)',
  '.container': {
    paddingBlock: 30,
    border: '1px solid #ccc',
    borderRadius: 5,
    background: '#fff',
  },
  '.block': {
    paddingInline: 72,
  },
  '.radio-group': {
    display: 'flex',
    gap: 24,
    '> div': {
      width: 244,
      height: 43,
    },
  },
  '.reservation-info': {
    position: 'relative',
    border: '1px solid #ccc',
    padding: '24px 32px',
    '.name': {
      marginBottom: 18,
      fontWeight: 'bold',
      ruby: {
        fontSize: 12,
      },
      span: {
        display: 'block',
        marginTop: 6,
        fontSIze: 16,
      },
    },
    ul: {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    },
    li: {
      display: 'flex',
      gap: 12,
      fontSize: 12,
    },
  },
})
