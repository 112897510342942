import React, { useRef } from 'react'
import { css, CSSObject, SerializedStyles } from '@emotion/core'

type RadioButtonProps<T> = {
  value?: T
  checked: boolean
  id: string
  name?: string
  item: {
    value: T
    label: string
  }
  onChange: (event: React.ChangeEvent<HTMLInputElement & { value: T }>) => void
  style?: {
    radioSize?: number
    labelMargin?: number
  }
  itemCss?: CSSObject | SerializedStyles
}

export const RadioButton = <T,>({ id, item, name, itemCss, checked, onChange, style }: RadioButtonProps<T>) => {
  const hiddenInputRef = useRef<HTMLInputElement>(null)

  return (
    <div
      css={[radioItemStyle, itemCss]}
      onClick={e => {
        hiddenInputRef.current?.click()
      }}
      defaultValue={item.value as unknown as any}
    >
      <input
        ref={hiddenInputRef}
        type="radio"
        style={{ display: 'none' }}
        checked={checked}
        onChange={onChange}
        id={id}
        name={name}
        value={item?.value as unknown as any}
      />
      <img
        width={style?.radioSize || 26}
        height={style?.radioSize || 26}
        style={{ marginRight: style?.labelMargin === undefined ? 14 : style.labelMargin }}
        alt="radio"
        src={require(`@/static/images/radio_${checked ? 'on' : 'off'}.svg`)}
      />
      <label htmlFor={id} css={[radioLabelStyle]}>
        {item.label}
      </label>
    </div>
  )
}
const radioItemStyle = css({
  display: 'flex',
  alignItems: 'center',
  color: '#272727',
  fontSize: 14,
  cursor: 'pointer',
  float: 'left',
})

const radioLabelStyle = css({
  display: 'block',
  color: '#272727',
  cursor: 'pointer',
})
