import React, { useEffect, useMemo, useState } from 'react'
import { createCustomerTag, deleteCustomerTag, fetchCustomerTagMasters } from '@/apis/aipass'
import { CustomerTagMasterType, tagColors } from '@/models/customer-manager/customer-tag'
import { Select, Option } from '@/components/atoms/select'
import { css } from '@emotion/core'
import { ReactComponent as RemoveIcon } from '@/static/images/customer-manager/icon_remove.svg'
import '@/components/molecules/customer/ant-select-dropdown.css'
import { useTranslation } from 'react-i18next'

interface CustomerTagInputProps {
  customerTags: string[] | undefined
  customerId: string
}

export const CustomerTagInput: React.FC<CustomerTagInputProps> = ({ customerTags, customerId }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [customerTagMasters, setCustomerTagMasters] = useState<CustomerTagMasterType[]>([])
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const _fetchCustomerTagMasters = async () => {
    setLoading(true)
    await fetchCustomerTagMasters().then(res => res?.length && setCustomerTagMasters(res))
    setLoading(false)
  }

  const _saveCustomerTag = async (tagId: string) => {
    const changeTags = [...selectedTags, tagId]
    setSelectedTags(changeTags)
    await createCustomerTag({ customerId, customerTagMasterIds: changeTags })
  }

  const _deleteCustomerTag = async (tagId: string) => {
    setSelectedTags(selectedTags.filter(tag => tag !== tagId))
    await deleteCustomerTag({ customerId, customerTagMasterId: tagId })
  }

  const selectedTagStyle = useMemo(() => {
    const style = Object.keys(tagColors).reduce((acc, colorName) => {
      const style = {
        background: `${tagColors[colorName].bgColor} !important`,
        color: `${tagColors[colorName].fontColor} !important`,
        svg: {
          fill: `${tagColors[colorName].fontColor} !important`,
        },
        '.custom-select .ant-select-item-option-active:not(.ant-select-item-option-disabled)': {
          color: '#bae7ff !important',
        },
      }
      acc[`.ant-select-selection__choice[title=${colorName}]`] = style
      return acc
    }, {})

    return css({
      ...style,
      flex: 1,
      paddingBottom: 0,
      '.ant-select-selection__choice': {
        paddingRight: 7,
      },
      '.ant-select-selection--multiple': {
        '.ant-select-selection__choice__content': {
          fontSize: 12,
          fontWeight: 'bold',
          marginRight: isEdit ? 16 : 0,
        },
        '.ant-select-selection__rendered': {
          paddingLeft: '0 !important',
          marginLeft: '0 !important',
        },
      },
      '.ant-select-selection__choice__remove': {
        right: 10,
        display: isEdit ? 'inline-block' : 'none',
      },
      '.tag-select': {
        '> div': { width: '100%', minHeight: 62, borderRadius: 10, padding: 8 },
      },
    })
  }, [isEdit])

  useEffect(() => {
    _fetchCustomerTagMasters()
  }, [])

  useEffect(() => {
    setSelectedTags(customerTags ?? [])
  }, [customerTags])

  return loading ? (
    <></>
  ) : (
    <Select
      className="tag-select"
      value={selectedTags}
      onFocus={() => setIsEdit(true)}
      onBlur={() => setIsEdit(false)}
      customStyle={selectedTagStyle}
      menuItemSelectedIcon={<img src={require('@/static/images/customer-manager/icon_check.svg')} />}
      mode="multiple"
      onDeselect={v => _deleteCustomerTag(v as string)}
      onSelect={v => _saveCustomerTag(v as string)}
      removeIcon={<RemoveIcon />}
    >
      {customerTagMasters.map(customerTagMaster => (
        <Option key={customerTagMaster.id} title={customerTagMaster.tag.colorName} value={customerTagMaster.id}>
          {customerTagMaster.tag.name}
        </Option>
      ))}
    </Select>
  )
}
