import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'

// apis
import { fetchSalesAccountsReceivable } from '@/apis/aipass'

// components
import { SettingSalesManagerTab } from '@/components/organisms/settings/sales-manager/tab'

// models
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { SettingReceivableList } from '@/components/organisms/settings/sales-manager/accounts-receivable/list'

type ContainerProps = {}

export const SettingSalesManagerAccountsReceivable: React.FC<ContainerProps> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const initialAccountsReceivableSettings: AccountsReceivableSettingType[] = [
    {
      closingDate: 0,
      channelCode: '',
      channelName: '',
      code: '',
      id: '',
      name: '',
    },
  ]
  const [accountsReceivableSettings, setAccountsReceivableSettings] =
    useState<AccountsReceivableSettingType[]>(initialAccountsReceivableSettings)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const _fetchSalesAccountsReceivable = async () => {
    setIsLoading(true)
    await fetchSalesAccountsReceivable()
      .then(res => {
        setAccountsReceivableSettings(res?.accountsReceivables)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  useEffect(() => {
    _fetchSalesAccountsReceivable()
  }, [])

  const windows = useWindowSize()
  return (
    <SettingsLayout loading={isLoading}>
      <TabContainer tabComponent={<SettingSalesManagerTab currentTab="Accounts receivable" />}>
        <div
          css={settingListContainerStyle}
          style={{
            maxHeight: windows.height - 148,
          }}
        >
          <div css={editButtonWrapperStyle}>
            <div
              css={editButtonStyle}
              onClick={() => history.push({ pathname: '/setting/sales-manager/accounts-receivable/edit', search: location.search })}
            >
              <img src={require('@/static/images/edit.svg')} alt={t('Edit')} />
              <p>{t('Edit')}</p>
            </div>
          </div>
          <SettingReceivableList accountsReceivables={accountsReceivableSettings} />
        </div>
      </TabContainer>
    </SettingsLayout>
  )
}

const settingListContainerStyle = css({
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: '0 5px 5px 5px',
  background: '#FFF',
  position: 'relative',
})

const editButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '24px 32px 34px',
})

const editButtonStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid #CCCCCC',
  padding: '6px 12px',
  borderRadius: 22,
  cursor: 'pointer',
  width: 72,
  height: 32,
  p: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#F2A40B',
  },
  img: {
    width: 20,
  },
  ':hover': {
    backgroundColor: '#F2F2F2',
  },
})
