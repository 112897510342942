import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { css } from '@emotion/core'

type Props = {
  to?: string
  title: string
  activePattern?: string
  // @/static/images/${svgIconPath}
  svgIconPath: string
  isHover: boolean
}
export const SideMenuItem: React.FC<Props> = ({ to, title, activePattern, svgIconPath, isHover, children }) => {
  const [isActiveLink, setIsActiveLink] = useState<boolean>(false)
  const [computedIcon, setComputedIcon] = useState<string>()

  useEffect(() => {
    setIsActiveLink(window.location.pathname.match(new RegExp(activePattern || to || '')) !== null)
  }, [activePattern, to])

  useEffect(() => {
    const menuIconPath = svgIconPath.replace('.svg', `${isActiveLink ? '_active' : ''}.svg`)
    setComputedIcon(require(`@/static/images/${menuIconPath}`))
  }, [svgIconPath, isActiveLink])

  return (
    <div css={sideMenuContainerStyle}>
      <Link className={`linkWrapperStyle ${isActiveLink ? '_active' : ''} ${children ? '' : '_non-child'}`} to={to || '#'} aria-disabled>
        <img className="selectLinkLogoStyle" src={computedIcon} />
        <div className={`linkContentsStyle ${isActiveLink ? '_active' : ''}`} style={{ display: isHover ? 'block' : 'none' }}>
          {title}
        </div>
      </Link>
      <div style={{ display: isHover ? 'block' : 'none' }}>{children}</div>
    </div>
  )
}

type ChildProps = {
  to: string
  title: string
  activePattern?: string
  isHover: boolean
}
export const SideMenuItemChild: React.FC<ChildProps> = ({ to, title, activePattern, isHover }) => {
  const [isActiveLink, setIsActiveLink] = useState<boolean>(false)
  useEffect(() => {
    setIsActiveLink(window.location.pathname.match(new RegExp(activePattern || to)) !== null)
  }, [activePattern, to])

  return (
    <Link className={`linkWrapperStyle _child _non-child`} to={to}>
      <div className={`linkContentsStyle _child ${isActiveLink ? '_active' : ''}`} style={{ display: isHover ? 'block' : 'none' }}>
        {title}
      </div>
    </Link>
  )
}

const sideMenuContainerStyle = css({
  '.linkWrapperStyle': {
    minHeight: 44,
    display: 'flex',
    cursor: 'pointer',
    padding: '0 16px',
    '&._non-child:hover': {
      background: '#f2f2f2',
    },
    '&._active': {
      borderLeft: '3px solid #f2a40b',
      background: '#f2f2f2',
      padding: '0 16px 0 13px',
    },
    '&._active &._child': {
      border: 'none',
      background: '#ffffff',
    },
    '.linkContentsStyle': {
      display: 'none',
      lineHeight: '44px',
      marginLeft: 12,
      fontWeight: 'bold',
      color: '#676767',
      fontSize: 12,
      justifyContent: 'space-between',
      width: '100%',
      '&._active': {
        color: '#F2A40B',
      },
      '&._child': {
        // Subtract padding and border
        marginLeft: 'calc(42px - 13px - 3px)',
      },
    },
    '.selectLinkLogoStyle': {
      width: 16,
      height: 16,
      alignSelf: 'center',
    },
  },
})
