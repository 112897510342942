import { ContentBlock, EditorState } from 'draft-js'

export type CustomBlockquoteDataType = {
  defaultText: string
  role: 'assign-room'
}

const className = 'custom-design-block'

export const blockStartTag = '%部屋情報始点%'
export const blockEndTag = '%部屋情報終点%'

export const CustomBlockquoteToHtml = (block: ContentBlock, editorState: EditorState) => {
  
  // const entityKey = block.getEntityAt(0)
  // const entity = editorState.getCurrentContent().getEntity(entityKey)
  // const blockData = entity.getData() as CustomBlockquoteDataType
  const blockText = block.getText().replaceAll('\n', '<br />')
  return `${blockStartTag}\n<p class="${className}">${blockText}</p><br />\n${blockEndTag}`
}
