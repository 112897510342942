import React from 'react'
import { css } from '@emotion/core'
import * as IPopup from './popup-type'
import { Link } from 'react-router-dom'

export class EmptyRoomPopup implements IPopup.PopupType {
  height = 115
  width = 200
  Component: React.FC<IPopup.PopupProps> = ({ position, room, t }) => {
    return (
      <div css={[IPopup.popupStyle, css({ ...position })]}>
        <div css={headerStyle}>
          <p>{room.roomNumber}</p>
          <p>{t('cleaningManager.Vacant')}</p>
        </div>

        <div css={IPopup.bodyStyle}>
          <p className='not-assigned'>{t('Not assigned')}</p>
        </div>

        <div css={IPopup.footerStyle}>
          <p className="-disabled">{t('Indicators.ReservationDetails')}</p>
          <Link to={{ pathname: '/room-manager/daily' }}>{t('Indicators.WalkIn')}</Link>
        </div>
      </div>
    )
  }
}

const headerStyle = css(IPopup.headerStyle, {
  background: '#F2F2F2',
  p: {
    color: '#676767',
  },
})
