import React, { useContext, useRef } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms'
import { Controller, useForm } from 'react-hook-form'
import { InputField } from '@/components/molecules/input-field'
import { RadioGroupField } from '../../radio-group-field'
import { AccountContext } from '@/contexts/account'
import { useFileUpload } from '@/hooks/use-file-upload'

export type EditorButtonPopupForm = {
  name: string
  url: string
  linkType: 'URL' | 'IMAGE'
  filePath: string
  fileName: string
}

type Props = {
  form?: EditorButtonPopupForm
  onSave: (form: EditorButtonPopupForm) => void
  onCancel: () => void
}

export const EditorButtonPopup: React.FC<Props> = ({ onSave, onCancel, form }) => {
  const { t } = useTranslation()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const { account } = useContext<any>(AccountContext)
  const { upload } = useFileUpload()
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<EditorButtonPopupForm>({
    defaultValues: form || {
      name: '',
      url: '',
      filePath: '',
      fileName: '',
      linkType: 'URL',
    },
  })
  const formData = watch()
  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files?.length) {
      return
    }
    const file = files[0]
    const allowMineType = ['application/pdf', 'image/jpeg', 'image/png']
    if (!allowMineType.includes(file?.type)) {
      window.alert(t('UnsupportedFileFormats'))
      return
    }
    const allowMaxFileByte = 10 * 1024 * 1024
    if (file.size > allowMaxFileByte) {
      window.alert(t('FileSizeTooLarge'))
      return
    }

    const reader = new FileReader()
    reader.onload = async e => {
      const fileData = e.target ? e.target.result : null
      if (fileData) {
        const uploadedPath = await upload({
          hotelId: account.employee.hotelId,
          file,
          fileData: fileData.toString(),
        })
        setValue('filePath', uploadedPath)
        setValue('fileName', file.name)
      }
    }
    reader.readAsDataURL(file)
  }

  return (
    <div css={popupStyle}>
      <div className="input-area">
        <span className="input-label">{t('ButtonName')}</span>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, name } }) => (
            <InputField marginBottom={0} placeholder={t('ButtonName')} value={value} name={name} handleChangeData={onChange} />
          )}
        />
      </div>
      <div className="input-area">
        <span className="input-label">{t('ButtonLinkTo')}</span>
        <Controller
          name="linkType"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, name } }) => (
            <RadioGroupField
              value={value}
              name={name}
              onChange={onChange}
              items={[
                { value: 'URL', label: 'URL' },
                { value: 'IMAGE', label: t('Image') },
              ]}
              style={{ radioSize: 18, labelMargin: 12 }}
              groupCss={radioGroupWrapperStyle}
            />
          )}
        />
        {formData.linkType === 'URL' && (
          <Controller
            name="url"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, name } }) => (
              <InputField marginBottom={0} placeholder="URL" value={value} name={name} handleChangeData={onChange} />
            )}
          />
        )}
        {formData.linkType === 'IMAGE' && (
          <>
            {!formData.fileName && (
              <div
                css={uploadAreaStyle}
                onClick={() => {
                  if (inputFileRef.current) {
                    inputFileRef.current.value = ''
                    inputFileRef.current.click()
                  }
                }}
              >
                <img src={require('@/static/images/plus.svg')} width={13} />
                <div>{t('UploadImage')}</div>
                <input type="file" ref={inputFileRef} onChange={e => handleChangeFile(e)} style={{ display: 'none' }} />
              </div>
            )}
            {formData.fileName && (
              <div css={uploadedAreaStyle}>
                <div>{formData.fileName}</div>
                <img
                  src={require('@/static/images/delete_orange.svg')}
                  width={20}
                  onClick={() => {
                    setValue('filePath', '')
                    setValue('fileName', '')
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="button-area">
        <Button buttonType={3} width={97} height={28} fontSize={12} marginRight={12} onClick={() => onCancel()}>
          {t('Cancel')}
        </Button>
        <Button buttonType={isValid ? 1 : 5} width={97} height={28} fontSize={12} onClick={handleSubmit(onSave)} isDisabled={!isValid}>
          {t('ButtonCreation')}
        </Button>
      </div>
    </div>
  )
}

const popupStyle = css({
  width: 475,
  minWidth: 475,
  // height: 236,
  borderRadius: 5,
  background: '#ffffff',
  filter: 'drop-shadow(0px 3px 10px #00000029)',
  padding: 24,
  position: 'absolute',
  left: 'calc(-475px / 2 + 8px)',
  top: 38,
  '&::before': {
    content: "''",
    position: 'absolute',
    top: -25,
    left: 'calc(50% - 10px)',
    marginLeft: 4.1,
    border: '10px solid transparent',
    borderBottom: '19px solid #ffffff',
  },
  '.input-area': {
    display: 'flex',
    flexFlow: 'column',
    paddingBottom: 24,
    textAlign: 'left',
  },
  '.input-label': {
    paddingBottom: 12,
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: 0.6,
    color: '#676767',
  },
  '.button-area': {
    display: 'flex',
    justifyContent: 'end',
  },
})

const radioGroupWrapperStyle = css({
  alignSelf: 'start',
  marginBottom: 12,
  '> div:first-of-type': {
    marginRight: 24,
  },
})

const uploadAreaStyle = css({
  display: 'flex',
  justifyContent: 'center',
  border: '1px dashed #CCCCCC',
  background: '#FAFAFA',
  fontSize: 12,
  fontWeight: 'bold',
  color: '#F2A40B',
  height: 32,
  lineHeight: '30px',
  cursor: 'pointer',
  borderRadius: 5,
  '> img': {
    marginRight: 10,
  },
})
const uploadedAreaStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  border: '1px solid #CCCCCC',
  background: '#FAFAFA',
  fontSize: 12,
  fontWeight: 'bold',
  color: '#F2A40B',
  height: 32,
  lineHeight: '30px',
  padding: '0px 16px',
  borderRadius: 5,
  '> img': {
    cursor: 'pointer',
  },
})
