import { translateText } from '@/apis/google'

type FacilityTranslateTextType = {
  pageId: string
  pageIndex: string
  jpContent: string
}

type FacilityTranslateTextForAccessType = {
  pageId: string
  jpContent: string
  address: any
}

export const FacilityTranslateText = async ({ pageId, pageIndex, jpContent }: FacilityTranslateTextType) => {
  return await translateText(jpContent).then((response: any) => {
    if (response) {
      response = response.map(content => {
        content = content.replace(/<\/ /g, '</')
        content = content.replace(/< /g, '<')
        content = content.replace(/\/ /g, '/')
        return content.replace(/ \//g, '/')
      })
    }

    const data = {
      pageIndex,
      jpContent,
      enContent: response[0],
      zhContent: response[1],
      koContent: response[2],
      uploadedFileList: [],
      deleteFileList: [],
      pageId,
    }

    return data
  })
}

export const FacilityTranslateTextForAccess = async ({ pageId, jpContent, address }: FacilityTranslateTextForAccessType) => {
  return await translateText(jpContent).then((contentResponse: any) => {
    const data = translateText(address).then((addressResponse: any) => {
      if (contentResponse) {
        contentResponse = contentResponse.map(content => {
          content = content.replace(/<\/ /g, '</')
          content = content.replace(/< /g, '<')
          content = content.replace(/\/ /g, '/')
          return content.replace(/ \//g, '/')
        })
      }

      if (addressResponse) {
        addressResponse = addressResponse.map(address => {
          address = address.replace(/<\/ /g, '</')
          address = address.replace(/< /g, '<')
          address = address.replace(/\/ /g, '/')
          return address.replace(/ \//g, '/')
        })
      }

      return {
        address,
        addressLatin: addressResponse[0],
        addressZh: addressResponse[1],
        addressKo: addressResponse[2],
        jpContent,
        enContent: contentResponse[0],
        zhContent: contentResponse[1],
        koContent: contentResponse[2],
        uploadedFileList: [],
        deleteFileList: [],
        pageId,
      }
    })
    return data
  })
}

export const CustomCheckinTranslateText = async (jpTitle, jpContent, inputType, jpInputValue, customCheckinId?, isRequired = false) => {
  return await translateText(jpTitle).then((titleResponse: any) => {
    const contentData = translateText(jpContent).then((contentResponse: any) => {
      const InputData = translateText(jpInputValue).then((inputValueResponse: any) => {
        if (titleResponse) {
          titleResponse = titleResponse.map(title => {
            title = title.replace(/<\/ /g, '</')
            title = title.replace(/< /g, '<')
            title = title.replace(/\/ /g, '/')
            return title.replace(/ \//g, '/')
          })
        }

        if (contentResponse) {
          contentResponse = contentResponse.map(content => {
            content = content.replace(/<\/ /g, '</')
            content = content.replace(/< /g, '<')
            content = content.replace(/\/ /g, '/')
            return content.replace(/ \//g, '/')
          })
        }

        if (customCheckinId) {
          return {
            jpTitle,
            enTitle: titleResponse[0],
            zhTitle: titleResponse[1],
            koTitle: titleResponse[2],
            zhTwTitle: titleResponse[3],
            jpContent,
            enContent: contentResponse[0],
            zhContent: contentResponse[1],
            koContent: contentResponse[2],
            zhTwContent: contentResponse[3],
            inputType,
            jpInputValue,
            enInputValue: inputValueResponse[0],
            zhInputValue: inputValueResponse[1],
            koInputValue: inputValueResponse[2],
            zhTwInputValue: inputValueResponse[3],
            customCheckinId,
            isRequired,
          }
        }

        if (!customCheckinId) {
          return {
            jpTitle,
            enTitle: titleResponse[0],
            zhTitle: titleResponse[1],
            koTitle: titleResponse[2],
            zhTwTitle: titleResponse[3],
            jpContent,
            enContent: contentResponse[0],
            zhContent: contentResponse[1],
            koContent: contentResponse[2],
            zhTwContent: contentResponse[3],
            inputType,
            jpInputValue,
            enInputValue: inputValueResponse[0],
            zhInputValue: inputValueResponse[1],
            koInputValue: inputValueResponse[2],
            zhTwInputValue: inputValueResponse[3],
            isRequired,
          }
        }
      })
      return InputData
    })
    return contentData
  })
}
