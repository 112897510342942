import React, { MouseEvent } from 'react'
import { css } from '@emotion/core'
import { RoomStatusType, RoomType, ReservationType } from '@/models/room-manager/response/guest-room-indicator'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { useTranslation } from 'react-i18next'

type Props = {
  room: RoomType
  isSelected: boolean
  isDisable: boolean
  onClick: (event: MouseEvent<HTMLDivElement>) => void
  boxZoomRate: number
}

export const IndicatorRoom: React.FC<Props> = ({ room, isSelected, onClick, isDisable, boxZoomRate }) => {
  const { i18n } = useTranslation()
  const computedStatusStyle = (roomStatus: RoomStatusType): string => {
    const classNameMap: { [k in RoomStatusType]: string } = {
      [RoomStatusType.empty]: '-empty',
      [RoomStatusType.checkIn]: '-check-in',
      [RoomStatusType.stay]: '-stay',
      [RoomStatusType.checkOut]: '-check-out',
      [RoomStatusType.cleaning]: '-cleaning',
    }
    return classNameMap[roomStatus]
  }

  const getShowReservation = (): ReservationType | undefined => {
    if (room.currentReservation && room.currentReservation.approvedStatus !== ApprovedStatusType.Checkout) {
      return room.currentReservation
    }
    return room.nextReservation
  }

  return (
    <div
      css={[
        boxStyle,
        {
          zoom: boxZoomRate,
        },
      ]}
    >
      <div css={roomStyle} className={`${computedStatusStyle(room.roomStatus)} ${isSelected ? '-selected' : ''}`} onClick={onClick}>
        <div className={`room-header`} />
        <div className="room-body">
          <div className="room-row-1">
            <p>{room.roomNumber}</p>
            <p className="small-text">{room.roomShortName || (i18n.language === 'ja' ? room.roomName : room.roomNameEn)}</p>
          </div>
          <div className="room-row-2">
            <p>{getShowReservation()?.guestName || '-'}</p>
          </div>
          <div className="room-row-3">
            <p className="small-text">
              {!getShowReservation() && '-'}
              {getShowReservation() && `${getShowReservation()?.currentNights}/${getShowReservation()?.totalNights}`}
            </p>
            {getShowReservation()?.hasUnpaidSales &&
              (isSelected ? (
                <img src={require('@/static/images/room/settle_white.svg')} width={14} />
              ) : (
                <img src={require('@/static/images/room/settle_gray.svg')} width={14} />
              ))}
          </div>
        </div>
      </div>
      {isDisable && <div css={disableStyle}></div>}
    </div>
  )
}

const boxStyle = css({
  margin: 1,
  position: 'relative',
  minWidth: 83 - 2,
  width: 83 - 2,
  minHeight: 60 - 2,
  height: 60 - 2,
})

const roomStatusColor = css({
  '&.-selected': {
    '*': { color: '#FFFFFF !important' },
  },
  '&.-empty': {
    backgroundColor: '#FFFFFF',
    '.room-header': { backgroundColor: '#676767' },
    '.room-row-2 p': { color: '#676767' },
    '&.-selected': { backgroundColor: '#676767' },
  },
  '&.-check-in': {
    backgroundColor: '#FEF3F0',
    '.room-header': { backgroundColor: '#F47110' },
    '.room-row-2 p': { color: '#F47110' },
    '&.-selected': { backgroundColor: '#F47110' },
  },
  '&.-stay': {
    backgroundColor: '#EFF9FF',
    '.room-header': { backgroundColor: '#3E85CC' },
    '.room-row-2 p': { color: '#3E85CC' },
    '&.-selected': { backgroundColor: '#3E85CC' },
  },
  '&.-check-out': {
    backgroundColor: '#F6FDF2',
    '.room-header': { backgroundColor: '#7DC931' },
    '.room-row-2 p': { color: '#7DC931' },
    '&.-selected': { backgroundColor: '#7DC931' },
  },
  '&.-cleaning': {
    backgroundColor: '#FFFCDD',
    '.room-header': { backgroundColor: '#F2A40B' },
    '.room-row-2 p': { color: '#F2A40B' },
    '&.-selected': { backgroundColor: '#F2A40B' },
  },
})

const roomStyle = css(roomStatusColor, {
  height: '100%',
  width: '100%',
  position: 'absolute',
  cursor: 'pointer',
  '.room-header': {
    height: 3,
  },
  '.room-body': {
    padding: 5,
    display: 'flex',
    flexFlow: 'column',
    height: 'calc(100% - 2px)',
    justifyContent: 'space-between',
  },
  '.room-row-1': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: 37,
    },
  },
  '.room-row-2': {
    lineHeight: '100%',
    p: {
      whiteSpace: 'nowrap',
      textOverflow: 'clip',
      overflow: 'hidden',
      fontSize: 11,
    },
  },
  '.room-row-3': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  p: {
    color: '#A3A3A3',
    fontWeight: 'bold',
    fontSize: 10,
    letterSpacing: 0.6,
    '&.small-text': {
      fontSize: 9,
      letterSpacing: 0.45,
    },
  },
})

const disableStyle = css({
  width: 'calc(100% + 2px)',
  height: 'calc(100% + 2px)',
  background: '#000000',
  opacity: 0.3,
  position: 'absolute',
})
